<template>
    <div class="ecological">
        <div class="col-1-1 header_solution">
            <div class="grid rel">
                <div class="header_txt vw38">{{$t('title')}}</div>
            </div>
        </div>

        <div class="grid grid-pad">
            <div class="col-1-1 content">
                <h2>{{$t('sub_title')}}</h2> <br />
                <p v-html="$t('eco_text')"></p>

                <br /><br />
                <ul class="smalldot">
                    <li>{{$t('li1')}}</li>
                    <li>{{$t('li2')}}</li>
                    <li>{{$t('li3')}}</li>
                    <li>{{$t('li4')}}</li>
                    <li>{{$t('li5')}}</li>
                </ul>
            </div>
        </div>



        <div class="grid grid-pad center">
            <div class="col-1-2 mobile-col-1-1">
                <router-link to="/Dfci"> 
                <div class="box1">          
                    <h3>{{$t('box1_title')}}</h3>
                    <p v-html="$t('box1_text')"></p>       
                </div>
            </router-link>
            </div>
            <div class="col-1-2 mobile-col-1-1">
                <router-link to="/Agricultural"> 
                <div class="box2">
                    <h3>{{$t('box2_title')}}</h3>
                    <p v-html="$t('box2_text')"></p>
                </div>
            </router-link>
            </div>

            <div class="col-1-2 mobile-col-1-1">
                <router-link to="/Forestry"> 
                <div class="box3">
                    <h3>{{$t('box3_title')}}</h3>
                    <p v-html="$t('box3_text')"></p>

                </div>
            </router-link>
            </div>
            <div class="col-1-2 mobile-col-1-1">
                <router-link to="/Gestion">
                    <div class="box4">
                        <h3 v-html="$t('box4_title')"></h3>
                        <p v-html="$t('box4_text')"></p>
                    </div>
                </router-link>
            </div>


        </div>
        <div class="grid grid-pad">

            <div class="center content">
                <router-link to="/Contact">
                    <input type="button" class="button " v-bind:value="$t('btn_contact')">
               </router-link>
                <br /><br /><br />

                <div class="col-1-1 center note">
                    <p v-html="$t('note')"></p>
                </div>
                <br />
            </div>
        </div>
    </div>
</template>

  <i18n src="../../public/Resources/I18N/Screen.Ecological.json"></i18n>
  
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'ecological',
      data: () => ({
          }),
    }
    </script>
  