<template>
    <div class="s400">
            
			<div class="col-1-1 header_produits">
        <div class="grid rel">
          <div class="header_txt vw38"></div>
        </div>
      </div>
      





<div class="col-1-1">
  <div class="grid grid-pad">
 
    
  <div class="col-1-2 col-flex mobile-col-1-1">
    <div class="produits_txtzone">
      <h1>{{$t('title')}}</h1>
  <div class="produits_img_principale_s400">
      <img style="display: block;" class="full" src="/Resources/Images/produits/s400.png" alt="" />
  </div>
</div>
   </div>


<div class="col-1-2 col-flex mobile-col-1-1">
  <div class="produits_etiquette">
    <div class="produits_titre vw75 w600">{{$t('s400')}}</div>
  </div>

  <div class="vw27 produits_q">{{$t('sub_title')}}</div>
  <div class="produits_list">
      <ul>
        <li>{{$t('li1')}}</li>
        <li>{{$t('li2')}}</li>
        <li>{{$t('li3')}}</li>
      </ul>
  </div>

</div>

 </div>  
</div>

   
    <div class="col-1-1 content">
      <div class="grid grid-pad">
<div class="brochure col-1-3  moblile-center">
  <div class="txt-l title_vid_brochure">{{$t('brochure')}}</div>
  <div  class="border_brochure" >
    <a v-bind:href="$t('pdf')" target="_blank">
      <img v-bind:src="$t('brochure_img')" alt="" title=""  alt="" />
    </a>
    </div>
</div>

<div class=" col-1-3  moblile-center">
  <div class="txt-l title_vid_brochure">{{$t('video')}}</div>
  <a href="/Resources/video/s400.mp4" target="_blank">
  <img  class=" "  src="/Resources/Images/produits/vid1_s400.jpg" alt=""/></a>
</div>
<div class=" col-1-3  moblile-center">
  <div class="txt-l title_vid_brochure" v-html="$t('&nbsp;')"> </div>
  <a href="/Resources/video/s400_2.mp4" target="_blank">
  <img  class=" "  src="/Resources/Images/produits/vid1_s400_2.jpg" alt=""/></a>
</div>


</div>  
</div>
<div class="col-1-1 center vw17">
  <p v-html="$t('note')"></p></div>

<div class="center vw27">
  <router-link to="/Contact">
    <input type="button" class="button"   v-bind:value="$t('btn_contact')">     
  </router-link>
 
</div>

<br /><br /><br />

      <div class="col-1-2 "><img style="display: block;" class="full" src="/Resources/Images/produits/s400_3.jpg" alt=""/>
      </div>
      <div class="col-1-2 "><img style="display: block;" class="full" src="/Resources/Images/produits/s400_4.jpg" alt=""  />
   

 </div> 

    </div>
  </template>
 
  <i18n src="../../public/Resources/I18N/Screen.s400.json"></i18n>
  <i18n>
    {
        "fr": {
          "brochure_img" : "/Resources/Images/produits/BrochureFR_souche.jpg",
          "pdf" :  "/Resources/Documents/RogneusesSouches-CMI_BrochureFR2024.pdf"

        },
        "en": {
          "brochure_img" : "/Resources/Images/produits/BrochureEN_stump.jpg",
          "pdf" :  "/Resources/Documents/StumpGrinders-CMI_BrochureENG2024.pdf"
        }
      }
  </i18n>
  
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 's400',
      data: () => ({
        
          }),
    }
    </script>