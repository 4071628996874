
<template>
    <div >
        <div class="wo"  @click='reset()' >
        <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 251.834 127.667" enable-background="new 0 0 251.834 127.667"
        xml:space="preserve">
        
               <!-- amérique du sud  -->
                <a class="south_america" onclick='DealerShow("south_america_dealer","south_america");' > 
         
             <path fill="#808184" d="M41.534,60.957c-0.121,0.03-0.113,0.091-0.41,0
             c-0.298-0.092-0.373-0.214-0.701-0.29c-0.327-0.076-0.242-0.008-0.54-0.167l-0.008-0.008c0.015-0.015,0.03-0.038,0.038-0.053
             c0.03-0.045,0.038-0.084,0.054-0.114c0.015-0.061,0.03-0.099,0.19-0.168c0.151-0.069,0.213-0.168,0.273-0.259
             c0.023-0.038,0.046-0.068,0.076-0.107c0.038,0.016,0.099,0.039,0.129,0.054c0.008,0,0.023,0.015,0.038,0.03
             c0.016,0.03,0.038,0.061,0.062,0.114c0.045,0.092,0.114,0.123,0.19,0.16c0.047,0.023,0.084,0.038,0.121,0.069
             c0.106,0.076,0.184,0.046,0.26,0.008c0.015-0.008,0.037-0.023,0.061-0.023c0.022-0.016,0.046,0.007,0.068,0.03
             c0.03,0.022,0.062,0.053,0.099,0.069c0.039,0.015,0.069,0.023,0.1,0.023c0.03,0.007,0.068,0.014,0.106,0.052
             c0.023,0.031,0.008,0.069-0.015,0.123c-0.016,0.038-0.038,0.083-0.054,0.137C41.626,60.796,41.726,60.911,41.534,60.957"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M41.534,60.957c-0.121,0.03-0.113,0.091-0.41,0c-0.298-0.092-0.373-0.214-0.701-0.29c-0.327-0.076-0.242-0.008-0.54-0.167
             l-0.008-0.008c0.015-0.015,0.03-0.038,0.038-0.053c0.03-0.045,0.038-0.084,0.054-0.114c0.015-0.061,0.03-0.099,0.19-0.168
             c0.151-0.069,0.213-0.168,0.273-0.259c0.023-0.038,0.046-0.068,0.076-0.107c0.038,0.016,0.099,0.039,0.129,0.054
             c0.008,0,0.023,0.015,0.038,0.03c0.016,0.03,0.038,0.061,0.062,0.114c0.045,0.092,0.114,0.123,0.19,0.16
             c0.047,0.023,0.084,0.038,0.121,0.069c0.106,0.076,0.184,0.046,0.26,0.008c0.015-0.008,0.037-0.023,0.061-0.023
             c0.022-0.016,0.046,0.007,0.068,0.03c0.03,0.022,0.062,0.053,0.099,0.069c0.039,0.015,0.069,0.023,0.1,0.023
             c0.03,0.007,0.068,0.014,0.106,0.052c0.023,0.031,0.008,0.069-0.015,0.123c-0.016,0.038-0.038,0.083-0.054,0.137
             C41.626,60.796,41.726,60.911,41.534,60.957z"/>
             
           <path  fill="#808184" d="M45.607,64.299c0.115,0.121,0.244,0.229,0.404,0.313
             c0.434,0.228,0.015,0.243,0.13,0.334c0.112,0.101,0.205,0.183,0.388,0.152c0.176-0.037-0.022-0.062,0.427-0.106
             c0.457-0.053,0.7-0.473,0.937-0.539c0.235-0.076,0.402-0.245,0.563-0.115c0.159,0.123,0.19,0.152,0.38,0.152
             c0.184,0,0.244-0.008,0.344,0.075c0.091,0.077,0.357,0.031,0.441,0.282c0.09,0.251,0.236,0.318,0.236,0.318
             s-0.1,0.115-0.016,0.297c0.075,0.184,0.175,0.261,0.091,0.336c-0.084,0.076-0.099,0.184-0.151,0.242
             c-0.054,0.063-0.084-0.029-0.183,0.056c-0.1,0.084-0.1,0.182-0.168,0.243c-0.076,0.061-0.13,0.121-0.168,0.053
             c-0.03-0.066-0.319-0.51-0.243-0.607c0.084-0.093,0.067-0.047,0.16-0.184c0.083-0.13,0.099-0.251,0.022-0.236
             c-0.084,0.023-0.222-0.037-0.343-0.174c-0.122-0.139-0.168-0.298-0.351-0.313c-0.183-0.023-0.419-0.107-0.503,0.061
             c-0.091,0.176-0.137,0.352-0.228,0.418c-0.1,0.068-0.358,0.1-0.419,0.229c-0.068,0.121-0.122,0.229,0.061,0.365
             c0.19,0.145,0.441,0.282,0.25,0.342c-0.189,0.062-0.212,0.016-0.265,0.115c-0.053,0.092-0.076,0.145-0.198,0.166
             c-0.122,0.023-0.297-0.021-0.319-0.189c-0.03-0.168,0.083-0.266-0.107-0.266c-0.19,0-0.259,0.016-0.343-0.078
             c-0.083-0.099-0.029-0.334-0.205-0.379c-0.168-0.037-0.221,0.067-0.365-0.017c-0.152-0.074-0.19-0.175-0.32-0.151
             c-0.13,0.022-0.205,0.176-0.205,0.176s-0.107,0.067-0.131-0.084c0.017-0.03,0.04-0.053,0.063-0.068
             c0.03-0.023,0.061-0.047,0.091-0.061c0.098-0.062,0.176-0.106,0.145-0.229c-0.008-0.022-0.015-0.047-0.022-0.062
             c-0.024-0.076-0.046-0.138-0.024-0.158c0.055-0.055,0.039-0.094,0.009-0.153c-0.015-0.03-0.03-0.077-0.037-0.138v-0.021
             c-0.017-0.131-0.031-0.261,0.022-0.298C45.486,64.382,45.547,64.335,45.607,64.299"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M45.607,64.299c0.115,0.121,0.244,0.229,0.404,0.313c0.434,0.228,0.015,0.243,0.13,0.334c0.112,0.101,0.205,0.183,0.388,0.152
             c0.176-0.037-0.022-0.062,0.427-0.106c0.457-0.053,0.7-0.473,0.937-0.539c0.235-0.076,0.402-0.245,0.563-0.115
             c0.159,0.123,0.19,0.152,0.38,0.152c0.184,0,0.244-0.008,0.344,0.075c0.091,0.077,0.357,0.031,0.441,0.282
             c0.09,0.251,0.236,0.318,0.236,0.318s-0.1,0.115-0.016,0.297c0.075,0.184,0.175,0.261,0.091,0.336
             c-0.084,0.076-0.099,0.184-0.151,0.242c-0.054,0.063-0.084-0.029-0.183,0.056c-0.1,0.084-0.1,0.182-0.168,0.243
             c-0.076,0.061-0.13,0.121-0.168,0.053c-0.03-0.066-0.319-0.51-0.243-0.607c0.084-0.093,0.067-0.047,0.16-0.184
             c0.083-0.13,0.099-0.251,0.022-0.236c-0.084,0.023-0.222-0.037-0.343-0.174c-0.122-0.139-0.168-0.298-0.351-0.313
             c-0.183-0.023-0.419-0.107-0.503,0.061c-0.091,0.176-0.137,0.352-0.228,0.418c-0.1,0.068-0.358,0.1-0.419,0.229
             c-0.068,0.121-0.122,0.229,0.061,0.365c0.19,0.145,0.441,0.282,0.25,0.342c-0.189,0.062-0.212,0.016-0.265,0.115
             c-0.053,0.092-0.076,0.145-0.198,0.166c-0.122,0.023-0.297-0.021-0.319-0.189c-0.03-0.168,0.083-0.266-0.107-0.266
             c-0.19,0-0.259,0.016-0.343-0.078c-0.083-0.099-0.029-0.334-0.205-0.379c-0.168-0.037-0.221,0.067-0.365-0.017
             c-0.152-0.074-0.19-0.175-0.32-0.151c-0.13,0.022-0.205,0.176-0.205,0.176s-0.107,0.067-0.131-0.084
             c0.017-0.03,0.04-0.053,0.063-0.068c0.03-0.023,0.061-0.047,0.091-0.061c0.098-0.062,0.176-0.106,0.145-0.229
             c-0.008-0.022-0.015-0.047-0.022-0.062c-0.024-0.076-0.046-0.138-0.024-0.158c0.055-0.055,0.039-0.094,0.009-0.153
             c-0.015-0.03-0.03-0.077-0.037-0.138v-0.021c-0.017-0.131-0.031-0.261,0.022-0.298C45.486,64.382,45.547,64.335,45.607,64.299z"
             />
           <path fill="#808184" d="M44.915,63.224c0.062,0.131,0.129,0.313,0.236,0.465
             c0.1,0.153,0.221,0.351,0.381,0.534c-0.055,0.038-0.099,0.076-0.13,0.092c-0.106,0.075-0.092,0.235-0.076,0.396l0.008,0.022
             c0.008,0.076,0.03,0.122,0.046,0.16c0.008,0.021,0.014,0.038,0.008,0.046c-0.062,0.068-0.03,0.15,0,0.26
             c0.006,0.014,0.015,0.037,0.022,0.061c0.007,0.047-0.038,0.076-0.1,0.105c-0.03,0.023-0.06,0.038-0.099,0.068
             c-0.007,0.009-0.015,0.016-0.03,0.023c-0.022-0.115-0.068-0.229-0.138-0.26c-0.082-0.046-0.228-0.091-0.19,0.008
             c0.039,0.105,0.108,0.045,0.131,0.129c0.015,0.076-0.084,0.19-0.146,0.129c-0.061-0.061-0.175-0.228-0.129-0.342
             c0.045-0.123,0.114-0.152,0.053-0.281c-0.068-0.129-0.335-0.373-0.518-0.434c-0.184-0.07-0.268,0.021-0.319-0.206
             c-0.054-0.229-0.313-0.533-0.389-0.442c-0.076,0.099,0.145,0.168,0.122,0.296c-0.023,0.139-0.084,0.237-0.183,0.124
             c-0.099-0.115-0.13,0.022-0.343-0.215c-0.221-0.235-0.046-0.578-0.008-0.646c0.03-0.069-0.092-0.275-0.03-0.321
             c0.061-0.045,0.106-0.235,0.341-0.137c0.153,0.068,0.329,0.084,0.421,0.03c0.084,0.046,0.167,0.084,0.251,0.106
             c0.129,0.039,0.16,0.062,0.198,0.092c0.015,0.016,0.037,0.037,0.068,0.062c0.061,0.038,0.122,0.067,0.189,0.091
             c0.062,0.022,0.122,0.038,0.184,0.03C44.786,63.271,44.847,63.256,44.915,63.224"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M44.915,63.224c0.062,0.131,0.129,0.313,0.236,0.465c0.1,0.153,0.221,0.351,0.381,0.534c-0.055,0.038-0.099,0.076-0.13,0.092
             c-0.106,0.075-0.092,0.235-0.076,0.396l0.008,0.022c0.008,0.076,0.03,0.122,0.046,0.16c0.008,0.021,0.014,0.038,0.008,0.046
             c-0.062,0.068-0.03,0.15,0,0.26c0.006,0.014,0.015,0.037,0.022,0.061c0.007,0.047-0.038,0.076-0.1,0.105
             c-0.03,0.023-0.06,0.038-0.099,0.068c-0.007,0.009-0.015,0.016-0.03,0.023c-0.022-0.115-0.068-0.229-0.138-0.26
             c-0.082-0.046-0.228-0.091-0.19,0.008c0.039,0.105,0.108,0.045,0.131,0.129c0.015,0.076-0.084,0.19-0.146,0.129
             c-0.061-0.061-0.175-0.228-0.129-0.342c0.045-0.123,0.114-0.152,0.053-0.281c-0.068-0.129-0.335-0.373-0.518-0.434
             c-0.184-0.07-0.268,0.021-0.319-0.206c-0.054-0.229-0.313-0.533-0.389-0.442c-0.076,0.099,0.145,0.168,0.122,0.296
             c-0.023,0.139-0.084,0.237-0.183,0.124c-0.099-0.115-0.13,0.022-0.343-0.215c-0.221-0.235-0.046-0.578-0.008-0.646
             c0.03-0.069-0.092-0.275-0.03-0.321c0.061-0.045,0.106-0.235,0.341-0.137c0.153,0.068,0.329,0.084,0.421,0.03
             c0.084,0.046,0.167,0.084,0.251,0.106c0.129,0.039,0.16,0.062,0.198,0.092c0.015,0.016,0.037,0.037,0.068,0.062
             c0.061,0.038,0.122,0.067,0.189,0.091c0.062,0.022,0.122,0.038,0.184,0.03C44.786,63.271,44.847,63.256,44.915,63.224z"/>
           <path fill="#808184" d="M45.479,59.22c0.062,0.13,0.054,0.282,0.038,0.473
             c-0.008,0.243,0.062,0.327-0.054,0.494c-0.121,0.16-0.183,0.282-0.297,0.678c-0.105,0.396,0.031,0.631-0.113,0.83
             c-0.138,0.206-0.29,0.327-0.206,0.533c0.084,0.213,0.061,0.243-0.046,0.41c-0.106,0.169-0.099,0.42-0.008,0.428
             c0.023,0,0.046,0.03,0.069,0.067c-0.046,0.023-0.091,0.039-0.114,0.039c-0.046,0-0.1-0.008-0.152-0.022
             c-0.055-0.024-0.114-0.054-0.16-0.084c-0.03-0.023-0.046-0.038-0.068-0.054c-0.046-0.038-0.076-0.076-0.229-0.114
             c-0.068-0.016-0.145-0.045-0.213-0.084c0.008-0.022,0.008-0.053,0-0.084c-0.031-0.228-0.336-0.723-0.602-0.738
             c-0.274-0.016-0.343,0.167-0.26,0.365c0.084,0.198,0.184,0.358,0.145,0.412c-0.037,0.053-0.061,0.106-0.128,0.029
             c-0.077-0.083-0.442-0.441-0.497-0.593c-0.06-0.152-0.128-0.289-0.326-0.449c-0.197-0.168-0.578-0.526-0.502-0.609
             c0.016-0.024,0.03-0.038,0.053-0.046c0.062,0.054,0.168,0.106,0.343,0.091c0.213-0.015,0.244-0.114,0.29-0.228
             c0.015-0.054,0.037-0.106,0.083-0.153c0.084-0.083,0.084-0.197,0.084-0.304V60.43c0-0.037,0.016-0.068,0.045-0.06h0.054
             c0.183,0.023,0.313,0.031,0.418-0.091c0.031-0.038,0.047-0.076,0.069-0.107c0.008-0.015,0.015-0.031,0.015-0.038
             c0.017-0.007-0.015,0.023,0,0.03c0.046,0.069,0.084,0.107,0.122,0.122c0.039,0.023,0.076,0.016,0.122,0
             c0.062-0.015,0.129-0.091,0.198-0.174c0.054-0.062,0.106-0.123,0.152-0.168c0.054-0.039,0.068-0.046,0.084-0.046
             c0.038-0.008,0.068-0.023,0.167-0.107c0.029-0.023,0.046-0.038,0.062-0.052c0.137-0.123,0.273-0.245,0.456-0.222
             c0.168,0.023,0.214-0.007,0.298-0.053c0.03-0.023,0.067-0.046,0.121-0.069c0.046-0.022,0.069-0.029,0.084-0.037
             c0.022-0.008,0.03-0.016,0.038-0.016c0.008,0,0.022-0.008,0.038-0.008c0.03-0.007,0.061-0.015,0.122-0.03
             C45.357,59.282,45.425,59.25,45.479,59.22"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M45.479,59.22
             c0.062,0.13,0.054,0.282,0.038,0.473c-0.008,0.243,0.062,0.327-0.054,0.494c-0.121,0.16-0.183,0.282-0.297,0.678
             c-0.105,0.396,0.031,0.631-0.113,0.83c-0.138,0.206-0.29,0.327-0.206,0.533c0.084,0.213,0.061,0.243-0.046,0.41
             c-0.106,0.169-0.099,0.42-0.008,0.428c0.023,0,0.046,0.03,0.069,0.067c-0.046,0.023-0.091,0.039-0.114,0.039
             c-0.046,0-0.1-0.008-0.152-0.022c-0.055-0.024-0.114-0.054-0.16-0.084c-0.03-0.023-0.046-0.038-0.068-0.054
             c-0.046-0.038-0.076-0.076-0.229-0.114c-0.068-0.016-0.145-0.045-0.213-0.084c0.008-0.022,0.008-0.053,0-0.084
             c-0.031-0.228-0.336-0.723-0.602-0.738c-0.274-0.016-0.343,0.167-0.26,0.365c0.084,0.198,0.184,0.358,0.145,0.412
             c-0.037,0.053-0.061,0.106-0.128,0.029c-0.077-0.083-0.442-0.441-0.497-0.593c-0.06-0.152-0.128-0.289-0.326-0.449
             c-0.197-0.168-0.578-0.526-0.502-0.609c0.016-0.024,0.03-0.038,0.053-0.046c0.062,0.054,0.168,0.106,0.343,0.091
             c0.213-0.015,0.244-0.114,0.29-0.228c0.015-0.054,0.037-0.106,0.083-0.153c0.084-0.083,0.084-0.197,0.084-0.304V60.43
             c0-0.037,0.016-0.068,0.045-0.06h0.054c0.183,0.023,0.313,0.031,0.418-0.091c0.031-0.038,0.047-0.076,0.069-0.107
             c0.008-0.015,0.015-0.031,0.015-0.038c0.017-0.007-0.015,0.023,0,0.03c0.046,0.069,0.084,0.107,0.122,0.122
             c0.039,0.023,0.076,0.016,0.122,0c0.062-0.015,0.129-0.091,0.198-0.174c0.054-0.062,0.106-0.123,0.152-0.168
             c0.054-0.039,0.068-0.046,0.084-0.046c0.038-0.008,0.068-0.023,0.167-0.107c0.029-0.023,0.046-0.038,0.062-0.052
             c0.137-0.123,0.273-0.245,0.456-0.222c0.168,0.023,0.214-0.007,0.298-0.053c0.03-0.023,0.067-0.046,0.121-0.069
             c0.046-0.022,0.069-0.029,0.084-0.037c0.022-0.008,0.03-0.016,0.038-0.016c0.008,0,0.022-0.008,0.038-0.008
             c0.03-0.007,0.061-0.015,0.122-0.03C45.357,59.282,45.425,59.25,45.479,59.22z"/>
           <path  fill="#808184" d="M41.71,58.466c0.221,0.023,0.205-0.129,0.548-0.015
             c0.358,0.122,0.616-0.03,0.937-0.023c0.313,0.008,0.251-0.19,0.487-0.098c0.229,0.083,0.252,0.159,0.411,0.114
             c0.152-0.046,0.251-0.153,0.389-0.046c0.137,0.098,0.327,0.304,0.503,0.48c0.175,0.174,0.304,0.068,0.433,0.235l0.017,0.016
             c-0.046,0.023-0.107,0.053-0.19,0.076c-0.062,0.015-0.092,0.023-0.115,0.031c-0.021,0-0.03,0.007-0.045,0.007
             c-0.016,0.007-0.022,0.015-0.054,0.023c-0.015,0.008-0.045,0.023-0.076,0.038c-0.068,0.023-0.106,0.046-0.137,0.069
             c-0.062,0.038-0.1,0.06-0.236,0.045c-0.221-0.03-0.372,0.107-0.533,0.244c-0.022,0.022-0.046,0.038-0.053,0.053
             c-0.084,0.069-0.106,0.076-0.13,0.084c-0.029,0.007-0.061,0.007-0.129,0.069c-0.054,0.053-0.107,0.114-0.16,0.175
             c-0.061,0.076-0.114,0.136-0.145,0.144c-0.023,0.008-0.046,0.016-0.061,0.008c-0.023-0.008-0.046-0.038-0.076-0.083
             c-0.054-0.077-0.1-0.092-0.138-0.062c-0.022,0.016-0.038,0.045-0.053,0.077c-0.016,0.022-0.031,0.06-0.062,0.091
             c-0.061,0.076-0.175,0.068-0.327,0.053h-0.046c-0.122-0.016-0.152,0.053-0.159,0.152c-0.009,0.023-0.009,0.053-0.009,0.084
             c0,0.084,0,0.175-0.053,0.228c-0.061,0.061-0.084,0.13-0.106,0.19c-0.03,0.084-0.053,0.161-0.197,0.168
             c-0.123,0.008-0.198-0.023-0.252-0.054c0.038-0.03,0.069-0.068,0.054-0.114c-0.084-0.334-0.282-0.03-0.13-0.38
             c0.046-0.106,0.076-0.168-0.008-0.243c-0.061-0.054-0.106-0.062-0.16-0.077c-0.022,0-0.045-0.007-0.075-0.015
             c-0.023-0.015-0.046-0.031-0.062-0.053c-0.054-0.045-0.091-0.084-0.183-0.054c-0.022,0.009-0.046,0.016-0.068,0.031
             c-0.046,0.023-0.092,0.038-0.152,0c-0.046-0.039-0.092-0.054-0.137-0.077c-0.062-0.029-0.114-0.053-0.146-0.106
             c-0.022-0.061-0.046-0.099-0.068-0.137c-0.03-0.03-0.053-0.053-0.091-0.068c-0.016-0.008-0.046-0.016-0.077-0.031
             c0.1-0.084,0.092-0.19,0.077-0.297c-0.008-0.077-0.016-0.16,0.053-0.198c0.061-0.038,0.138-0.076,0.214-0.114
             c0.151-0.076,0.327-0.16,0.494-0.304C41.55,58.695,41.642,58.573,41.71,58.466"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M41.71,58.466
             c0.221,0.023,0.205-0.129,0.548-0.015c0.358,0.122,0.616-0.03,0.937-0.023c0.313,0.008,0.251-0.19,0.487-0.098
             c0.229,0.083,0.252,0.159,0.411,0.114c0.152-0.046,0.251-0.153,0.389-0.046c0.137,0.098,0.327,0.304,0.503,0.48
             c0.175,0.174,0.304,0.068,0.433,0.235l0.017,0.016c-0.046,0.023-0.107,0.053-0.19,0.076c-0.062,0.015-0.092,0.023-0.115,0.031
             c-0.021,0-0.03,0.007-0.045,0.007c-0.016,0.007-0.022,0.015-0.054,0.023c-0.015,0.008-0.045,0.023-0.076,0.038
             c-0.068,0.023-0.106,0.046-0.137,0.069c-0.062,0.038-0.1,0.06-0.236,0.045c-0.221-0.03-0.372,0.107-0.533,0.244
             c-0.022,0.022-0.046,0.038-0.053,0.053c-0.084,0.069-0.106,0.076-0.13,0.084c-0.029,0.007-0.061,0.007-0.129,0.069
             c-0.054,0.053-0.107,0.114-0.16,0.175c-0.061,0.076-0.114,0.136-0.145,0.144c-0.023,0.008-0.046,0.016-0.061,0.008
             c-0.023-0.008-0.046-0.038-0.076-0.083c-0.054-0.077-0.1-0.092-0.138-0.062c-0.022,0.016-0.038,0.045-0.053,0.077
             c-0.016,0.022-0.031,0.06-0.062,0.091c-0.061,0.076-0.175,0.068-0.327,0.053h-0.046c-0.122-0.016-0.152,0.053-0.159,0.152
             c-0.009,0.023-0.009,0.053-0.009,0.084c0,0.084,0,0.175-0.053,0.228c-0.061,0.061-0.084,0.13-0.106,0.19
             c-0.03,0.084-0.053,0.161-0.197,0.168c-0.123,0.008-0.198-0.023-0.252-0.054c0.038-0.03,0.069-0.068,0.054-0.114
             c-0.084-0.334-0.282-0.03-0.13-0.38c0.046-0.106,0.076-0.168-0.008-0.243c-0.061-0.054-0.106-0.062-0.16-0.077
             c-0.022,0-0.045-0.007-0.075-0.015c-0.023-0.015-0.046-0.031-0.062-0.053c-0.054-0.045-0.091-0.084-0.183-0.054
             c-0.022,0.009-0.046,0.016-0.068,0.031c-0.046,0.023-0.092,0.038-0.152,0c-0.046-0.039-0.092-0.054-0.137-0.077
             c-0.062-0.029-0.114-0.053-0.146-0.106c-0.022-0.061-0.046-0.099-0.068-0.137c-0.03-0.03-0.053-0.053-0.091-0.068
             c-0.016-0.008-0.046-0.016-0.077-0.031c0.1-0.084,0.092-0.19,0.077-0.297c-0.008-0.077-0.016-0.16,0.053-0.198
             c0.061-0.038,0.138-0.076,0.214-0.114c0.151-0.076,0.327-0.16,0.494-0.304C41.55,58.695,41.642,58.573,41.71,58.466z"/>
           <path  fill="#808184" d="M41.139,58.391c0.061,0.114,0.137,0.167,0.152,0.091
             c0.016-0.091,0.084-0.084,0.313-0.038c-0.062,0.092-0.146,0.198-0.244,0.282c-0.151,0.129-0.319,0.213-0.472,0.289
             c-0.076,0.038-0.152,0.077-0.221,0.115c-0.13,0.075-0.114,0.19-0.1,0.304c0.009,0.076,0.016,0.16-0.046,0.206
             c-0.083,0.061-0.129,0.129-0.175,0.198c-0.053,0.084-0.106,0.167-0.236,0.22c-0.189,0.092-0.212,0.145-0.243,0.236
             c-0.008,0.023-0.023,0.054-0.038,0.092c-0.015,0.015-0.022,0.03-0.038,0.053c-0.13-0.122,0.046-0.175-0.396-0.183
             c-0.503-0.008-0.457,0.084-0.701-0.106c-0.106-0.084-0.251-0.191-0.403-0.328c0.076-0.137,0.145-0.327,0.092-0.51l-0.031-0.107
             c-0.068-0.22-0.075-0.251-0.015-0.319c0.046-0.054,0.091-0.107,0.137-0.175c0.038-0.069,0.084-0.145,0.13-0.252
             c0.029-0.061,0.038-0.106,0.054-0.152c0.03-0.083,0.037-0.137,0.159-0.114c0.138,0.031,0.389,0.061,0.594,0.061
             c0.152,0,0.282-0.023,0.335-0.076c0.016-0.023,0.038-0.046,0.054-0.077c0.038-0.052,0.061-0.121,0.061-0.189
             c0.008-0.077-0.016-0.145-0.084-0.214c-0.015-0.008-0.038-0.022-0.053-0.03c-0.114-0.076-0.335-0.229-0.465-0.358
             c-0.03-0.039-0.062-0.077-0.076-0.099c-0.008-0.015-0.015-0.015-0.015-0.015h0.007c0.054-0.023,0.1-0.008,0.138,0.008
             c0.084,0.029,0.152,0.06,0.214-0.107c0.037-0.084,0.037-0.183,0.045-0.282c0-0.069,0-0.13,0.008-0.175
             c0.016-0.054,0.039-0.084,0.083-0.076c0.138,0.03,0.61,0.091,0.975,0.098c0.106,0,0.206,0,0.29-0.015
             c0.008,0.061,0.022,0.168,0.03,0.259c0,0.069-0.015,0.175-0.03,0.298c-0.023,0.128-0.046,0.274-0.076,0.417
             c-0.03,0.153-0.054,0.298-0.069,0.419c-0.007,0.13-0.015,0.245,0.009,0.32C40.812,58.474,40.986,58.444,41.139,58.391"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M41.139,58.391c0.061,0.114,0.137,0.167,0.152,0.091c0.016-0.091,0.084-0.084,0.313-0.038c-0.062,0.092-0.146,0.198-0.244,0.282
             c-0.151,0.129-0.319,0.213-0.472,0.289c-0.076,0.038-0.152,0.077-0.221,0.115c-0.13,0.075-0.114,0.19-0.1,0.304
             c0.009,0.076,0.016,0.16-0.046,0.206c-0.083,0.061-0.129,0.129-0.175,0.198c-0.053,0.084-0.106,0.167-0.236,0.22
             c-0.189,0.092-0.212,0.145-0.243,0.236c-0.008,0.023-0.023,0.054-0.038,0.092c-0.015,0.015-0.022,0.03-0.038,0.053
             c-0.13-0.122,0.046-0.175-0.396-0.183c-0.503-0.008-0.457,0.084-0.701-0.106c-0.106-0.084-0.251-0.191-0.403-0.328
             c0.076-0.137,0.145-0.327,0.092-0.51l-0.031-0.107c-0.068-0.22-0.075-0.251-0.015-0.319c0.046-0.054,0.091-0.107,0.137-0.175
             c0.038-0.069,0.084-0.145,0.13-0.252c0.029-0.061,0.038-0.106,0.054-0.152c0.03-0.083,0.037-0.137,0.159-0.114
             c0.138,0.031,0.389,0.061,0.594,0.061c0.152,0,0.282-0.023,0.335-0.076c0.016-0.023,0.038-0.046,0.054-0.077
             c0.038-0.052,0.061-0.121,0.061-0.189c0.008-0.077-0.016-0.145-0.084-0.214c-0.015-0.008-0.038-0.022-0.053-0.03
             c-0.114-0.076-0.335-0.229-0.465-0.358c-0.03-0.039-0.062-0.077-0.076-0.099c-0.008-0.015-0.015-0.015-0.015-0.015h0.007
             c0.054-0.023,0.1-0.008,0.138,0.008c0.084,0.029,0.152,0.06,0.214-0.107c0.037-0.084,0.037-0.183,0.045-0.282
             c0-0.069,0-0.13,0.008-0.175c0.016-0.054,0.039-0.084,0.083-0.076c0.138,0.03,0.61,0.091,0.975,0.098
             c0.106,0,0.206,0,0.29-0.015c0.008,0.061,0.022,0.168,0.03,0.259c0,0.069-0.015,0.175-0.03,0.298
             c-0.023,0.128-0.046,0.274-0.076,0.417c-0.03,0.153-0.054,0.298-0.069,0.419c-0.007,0.13-0.015,0.245,0.009,0.32
             C40.812,58.474,40.986,58.444,41.139,58.391z"/>
           <path  fill="#808184" d="M42.105,56.327c-0.137,0.282-0.243,0.198-0.297,0.579
             c-0.061,0.411-0.03,0.548-0.16,0.723c-0.129,0.175-0.632,0.442-0.57,0.632c0.006,0.015,0.015,0.03,0.015,0.045
             c-0.099,0.039-0.198,0.069-0.205,0.031c-0.016-0.069-0.016-0.168,0-0.289c0.007-0.122,0.031-0.259,0.061-0.404
             c0.03-0.144,0.054-0.296,0.076-0.426c0.022-0.13,0.03-0.244,0.03-0.32c0-0.099-0.022-0.205-0.03-0.266
             c0.046-0.015,0.076-0.039,0.092-0.061l0.022-0.031c0.03-0.038,0.046-0.053,0.054-0.061h0.022c0.022,0,0.054,0,0.092-0.007
             c0.106-0.008,0.228-0.168,0.335-0.305c0.075-0.107,0.145-0.19,0.175-0.198c0.008,0,0,0.015,0,0.031
             c0.016,0.083,0.038,0.205,0.213,0.289C42.053,56.304,42.084,56.319,42.105,56.327"/>
           <path  fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M42.105,56.327c-0.137,0.282-0.243,0.198-0.297,0.579c-0.061,0.411-0.03,0.548-0.16,0.723c-0.129,0.175-0.632,0.442-0.57,0.632
             c0.006,0.015,0.015,0.03,0.015,0.045c-0.099,0.039-0.198,0.069-0.205,0.031c-0.016-0.069-0.016-0.168,0-0.289
             c0.007-0.122,0.031-0.259,0.061-0.404c0.03-0.144,0.054-0.296,0.076-0.426c0.022-0.13,0.03-0.244,0.03-0.32
             c0-0.099-0.022-0.205-0.03-0.266c0.046-0.015,0.076-0.039,0.092-0.061l0.022-0.031c0.03-0.038,0.046-0.053,0.054-0.061h0.022
             c0.022,0,0.054,0,0.092-0.007c0.106-0.008,0.228-0.168,0.335-0.305c0.075-0.107,0.145-0.19,0.175-0.198c0.008,0,0,0.015,0,0.031
             c0.016,0.083,0.038,0.205,0.213,0.289C42.053,56.304,42.084,56.319,42.105,56.327z"/>
        
             <path  fill="#808184" d="M35.756,49.109c-0.062,0.221-0.183,0.32-0.244,0.464
               c-0.084,0.198-0.486,1.142-0.608,1.721c-0.123,0.586-0.282,0.936-0.214,1.256c0.068,0.328,0.068,0.389,0.1,0.701
               c0.038,0.304,0.114,0.51,0.327,0.89c0.205,0.373,0.373,0.457,0.335,0.663c-0.038,0.213-0.076,0.731,0.312,0.876
               c0.39,0.137,0.534,0.06,0.663,0.182c0.129,0.115,0.106,0.45,0.297,0.465c0.19,0.007,0.685-0.274,1.081-0.343
               c0.39-0.068,0.617-0.022,0.693-0.083c0.076-0.062,0.327-0.282,0.41-0.092c0.084,0.191,0.274,0.304,0.412,0.137
               c0.137-0.168-0.1-0.191,0.106-0.389c0.213-0.205,0.632-0.281,0.632-0.585c0-0.297,0.106-0.267,0.19-0.45
               c0.091-0.183,0.13-0.395,0.13-0.579c0-0.182,0.289-0.616,1.035-0.647c0.739-0.037,0.556-0.296,0.959-0.258
               c0.396,0.038,0.435,0.106,0.632,0.099c0.198-0.008,0.198-0.198,0.351,0.022c0.145,0.221,0.099,0.533-0.083,0.723
               c-0.184,0.191-0.488,0.374-0.534,0.587c-0.038,0.213,0.008,0.305-0.084,0.381c-0.099,0.084-0.137,0.167-0.099,0.282
               c0.046,0.114,0.03,0.258-0.015,0.342c-0.046,0.076-0.236,0.404-0.388,0.761c-0.031-0.007-0.054-0.023-0.077-0.038
               c-0.129-0.062-0.145-0.152-0.16-0.213c-0.015-0.076-0.022-0.129-0.114-0.114c-0.068,0.008-0.152,0.114-0.235,0.228
               c-0.1,0.13-0.206,0.267-0.274,0.275c-0.038,0.007-0.061,0.007-0.076,0.007c-0.03,0-0.054,0-0.076,0.015
               c-0.022,0.007-0.038,0.03-0.076,0.084l-0.022,0.03c-0.03,0.046-0.198,0.054-0.404,0.046c-0.357,0-0.814-0.061-0.951-0.091
               c-0.13-0.023-0.183,0.045-0.206,0.152c-0.007,0.061-0.007,0.122-0.015,0.191c0,0.091-0.008,0.19-0.03,0.25
               c-0.03,0.077-0.054,0.062-0.092,0.054c-0.054-0.023-0.114-0.045-0.205-0.015c-0.016,0.008-0.031,0.015-0.038,0.023
               c-0.038,0.038-0.038,0.084-0.008,0.137c0.022,0.038,0.053,0.076,0.099,0.122c0.13,0.136,0.358,0.297,0.479,0.373
               c0.016,0.008,0.024,0.015,0.039,0.023c0.037,0.045,0.053,0.091,0.053,0.137s-0.023,0.098-0.046,0.144
               c-0.015,0.023-0.03,0.038-0.046,0.054c-0.03,0.03-0.129,0.045-0.259,0.045c-0.197,0-0.441-0.03-0.57-0.061
               c-0.214-0.038-0.236,0.038-0.282,0.183c-0.008,0.045-0.023,0.091-0.046,0.145c-0.045,0.091-0.084,0.175-0.122,0.236
               c-0.046,0.061-0.084,0.114-0.129,0.16c-0.1,0.113-0.092,0.144-0.008,0.411l0.03,0.113c0.046,0.145-0.006,0.298-0.075,0.412
               c-0.184-0.16-0.373-0.365-0.526-0.617c-0.312-0.517-0.838-1.165-1.469-1.066c-0.633,0.092-0.716,0.373-1.135,0.45
               c-0.419,0.076-0.616-0.031-0.861-0.122c-0.25-0.084-0.304,0.007-0.494-0.084c-0.19-0.099-0.122-0.205-0.327-0.251
               c-0.214-0.046-0.222-0.161-0.472-0.282c-0.244-0.122-0.297,0.015-0.739-0.229c-0.45-0.235-0.365-0.274-0.677-0.357
               c-0.313-0.091-0.534-0.35-0.655-0.51c-0.122-0.16-0.229-0.251-0.336-0.237c-0.106,0.016-0.259,0.077-0.441-0.023
               c-0.182-0.106-0.524-0.213-0.602-0.327c-0.084-0.114-0.137-0.266-0.259-0.38c-0.121-0.115-0.556-0.32-0.769-0.556
               c-0.212-0.236-0.396-0.541-0.381-0.716c0.015-0.175-0.16-0.327-0.053-0.38c0.106-0.054,0.403-0.031,0.35-0.161
               c-0.053-0.129-0.251-0.114-0.152-0.236c0.1-0.121,0.252-0.198,0.259-0.373c0-0.175-0.145-0.228-0.129-0.388
               c0.007-0.16-0.198-0.259-0.137-0.426c0.06-0.168-0.062-0.746-0.441-1.143c-0.374-0.388-0.48-0.494-0.647-0.791
               c-0.161-0.297-0.229-0.418-0.305-0.662c-0.084-0.245-0.67-0.404-0.747-0.647c-0.075-0.235,0.047-0.342,0.1-0.586
               c0.054-0.244,0.084-0.373-0.076-0.396c-0.16-0.023-0.26,0.053-0.305-0.106c-0.038-0.161,0.022-0.298-0.114-0.396
               c-0.145-0.092-0.229-0.039-0.243-0.297c-0.016-0.252,0.099-0.359-0.145-0.434c-0.244-0.084-0.29-0.031-0.351-0.175
               c-0.061-0.138-0.221-0.488-0.313-0.61c-0.083-0.122-0.152-0.107-0.206-0.023c-0.053,0.092-0.189,0.069-0.151-0.084
               c0.046-0.16,0.137-0.35,0.243-0.304c0.114,0.038,0.222-0.106,0.122-0.183c-0.091-0.083-0.183,0.069-0.19-0.228
               c0-0.297,0.083-0.655,0-0.814c-0.076-0.153-0.068-0.206-0.016-0.351c0.054-0.137,0.153-0.19,0.016-0.26
               c-0.145-0.068-0.319-0.121-0.357-0.205c-0.046-0.084,0.015-0.114-0.205-0.084c-0.222,0.039-0.381-0.152-0.465-0.236
               c-0.092-0.075-0.344,0.077-0.289,0.336c0.053,0.259,0.015,0.411-0.023,0.487c-0.038,0.068-0.016,0.206-0.008,0.365
               c0.008,0.168-0.137,0.138-0.068,0.396c0.076,0.259,0.114,0.442,0.29,0.602c0.183,0.153,0.197,0.289,0.183,0.403
               c-0.009,0.115,0.151,0.206,0.213,0.313c0.061,0.099-0.022,0.068,0.045,0.228c0.07,0.153,0.183,0.076,0.146,0.274
               c-0.047,0.205-0.069,0.419,0.045,0.549c0.115,0.128,0.198,0.159,0.19,0.289c-0.008,0.129,0.038,0.183,0.069,0.267
               c0.036,0.091,0.091,0.296,0.121,0.266c0.038-0.031,0.114-0.145,0.168,0.007c0.046,0.145,0.061,0.442,0.022,0.594
               c-0.03,0.153,0.016,0.373,0.076,0.442c0.061,0.075,0,0.373,0.084,0.564c0.076,0.19-0.03,0.227-0.023,0.41
               c0.008,0.183,0.138,0.412,0.198,0.351c0.062-0.061,0.015-0.313,0.106-0.236c0.084,0.075-0.037,0.152,0.152,0.297
               c0.198,0.151,0.236,0.206,0.244,0.319c0.007,0.114,0.151,0.244,0.091,0.396c-0.061,0.153-0.388,0.51-0.51,0.419
               c-0.121-0.091-0.092-0.145-0.151-0.442c-0.062-0.296-0.229-0.281-0.45-0.548c-0.213-0.273-0.357-0.51-0.533-0.586
               c-0.175-0.069-0.343-0.115-0.289-0.274c0.053-0.152,0.076-0.152,0.145-0.373c0.068-0.213,0.197-0.624,0.138-0.746
               c-0.069-0.122-0.306-0.061-0.396-0.251c-0.083-0.191-0.083-0.473-0.206-0.473c-0.129,0-0.228,0.191-0.373,0.099
               c-0.145-0.099-0.045-0.16-0.197-0.235c-0.145-0.069-0.168-0.092-0.252-0.198c-0.075-0.107-0.075-0.046-0.182-0.222
               c-0.115-0.175-0.328-0.358-0.184-0.387c0.138-0.023,0.374,0.037,0.48-0.038c0.099-0.084,0.273-0.168,0.365-0.313
               c0.099-0.145,0.167-0.167,0.091-0.335s-0.419-0.366-0.456-0.594c-0.047-0.229-0.123-0.281-0.243-0.342
               c-0.123-0.062-0.306-0.123-0.268-0.297c0.038-0.176,0.106-0.282,0.091-0.343c-0.022-0.061-0.175-0.137-0.129-0.229
               c0.038-0.083,0.084-0.19,0.061-0.281c-0.03-0.099-0.122-0.16-0.106-0.274c0.016-0.115,0.038-0.153,0.016-0.244
               c-0.016-0.084-0.168-0.092-0.091-0.244c0.084-0.152,0.137-0.236,0.099-0.289c-0.039-0.053-0.153-0.206-0.122-0.289
               c0.038-0.084-0.007-0.183-0.007-0.206c0,0,0.007-0.038,0.022-0.092c0.563-0.06,1.742-0.183,1.804-0.121
               c0.031,0.038,0.022,0.076,0.016,0.114c-0.008,0.061-0.016,0.114,0.062,0.16c0.061,0.038,0.989,0.449,1.728,0.746
               c0.418,0.168,0.785,0.297,0.922,0.297c0.076,0.008,0.221,0.015,0.404,0.03c0.616,0.046,1.612,0.123,1.696-0.03
               c0.038-0.069,0.038-0.137,0.038-0.213c0-0.099,0-0.198,0.168-0.198c0.091,0,0.244-0.008,0.411-0.015
               c0.365-0.023,0.8-0.046,0.846,0c0.022,0.022,0.022,0.053,0.022,0.083c0.008,0.084,0.015,0.183,0.176,0.29
               c0.098,0.076,0.121,0.114,0.143,0.174c0.031,0.062,0.063,0.138,0.191,0.282c0.13,0.138,0.221,0.245,0.281,0.343
               c0.062,0.092,0.084,0.176,0.076,0.275c0,0.045-0.015,0.106-0.029,0.167c-0.047,0.19-0.1,0.396,0.129,0.601
               c0.168,0.153,0.381,0.214,0.556,0.267c0.114,0.037,0.214,0.068,0.251,0.114c0.054,0.061,0.122,0.038,0.198-0.023
               c0.076-0.069,0.152-0.19,0.229-0.296c0.062-0.084,0.114-0.161,0.152-0.198c0.068-0.061,0.259-0.175,0.473-0.214
               c0.137-0.023,0.289-0.015,0.411,0.053c0.174,0.092,0.266,0.145,0.319,0.198c0.045,0.038,0.053,0.084,0.068,0.161
               c0.022,0.091,0.069,0.189,0.114,0.289c0.061,0.114,0.123,0.236,0.137,0.349c0.008,0.077,0.008,0.138,0,0.191
               c-0.008,0.107-0.008,0.198,0.084,0.366c0.046,0.084,0.092,0.145,0.13,0.213c0.091,0.129,0.167,0.236,0.183,0.411
               c0.015,0.259,0.068,0.464,0.113,0.624l0.008,0.023c0.031,0.099,0.13,0.259,0.267,0.388c0.114,0.107,0.244,0.199,0.381,0.237
               c0.13,0.03,0.183,0.061,0.237,0.09c0.052,0.032,0.105,0.061,0.22,0.084C35.497,49.094,35.626,49.102,35.756,49.109"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M35.756,49.109c-0.062,0.221-0.183,0.32-0.244,0.464c-0.084,0.198-0.486,1.142-0.608,1.721
               c-0.123,0.586-0.282,0.936-0.214,1.256c0.068,0.328,0.068,0.389,0.1,0.701c0.038,0.304,0.114,0.51,0.327,0.89
               c0.205,0.373,0.373,0.457,0.335,0.663c-0.038,0.213-0.076,0.731,0.312,0.876c0.39,0.137,0.534,0.06,0.663,0.182
               c0.129,0.115,0.106,0.45,0.297,0.465c0.19,0.007,0.685-0.274,1.081-0.343c0.39-0.068,0.617-0.022,0.693-0.083
               c0.076-0.062,0.327-0.282,0.41-0.092c0.084,0.191,0.274,0.304,0.412,0.137c0.137-0.168-0.1-0.191,0.106-0.389
               c0.213-0.205,0.632-0.281,0.632-0.585c0-0.297,0.106-0.267,0.19-0.45c0.091-0.183,0.13-0.395,0.13-0.579
               c0-0.182,0.289-0.616,1.035-0.647c0.739-0.037,0.556-0.296,0.959-0.258c0.396,0.038,0.435,0.106,0.632,0.099
               c0.198-0.008,0.198-0.198,0.351,0.022c0.145,0.221,0.099,0.533-0.083,0.723c-0.184,0.191-0.488,0.374-0.534,0.587
               c-0.038,0.213,0.008,0.305-0.084,0.381c-0.099,0.084-0.137,0.167-0.099,0.282c0.046,0.114,0.03,0.258-0.015,0.342
               c-0.046,0.076-0.236,0.404-0.388,0.761c-0.031-0.007-0.054-0.023-0.077-0.038c-0.129-0.062-0.145-0.152-0.16-0.213
               c-0.015-0.076-0.022-0.129-0.114-0.114c-0.068,0.008-0.152,0.114-0.235,0.228c-0.1,0.13-0.206,0.267-0.274,0.275
               c-0.038,0.007-0.061,0.007-0.076,0.007c-0.03,0-0.054,0-0.076,0.015c-0.022,0.007-0.038,0.03-0.076,0.084l-0.022,0.03
               c-0.03,0.046-0.198,0.054-0.404,0.046c-0.357,0-0.814-0.061-0.951-0.091c-0.13-0.023-0.183,0.045-0.206,0.152
               c-0.007,0.061-0.007,0.122-0.015,0.191c0,0.091-0.008,0.19-0.03,0.25c-0.03,0.077-0.054,0.062-0.092,0.054
               c-0.054-0.023-0.114-0.045-0.205-0.015c-0.016,0.008-0.031,0.015-0.038,0.023c-0.038,0.038-0.038,0.084-0.008,0.137
               c0.022,0.038,0.053,0.076,0.099,0.122c0.13,0.136,0.358,0.297,0.479,0.373c0.016,0.008,0.024,0.015,0.039,0.023
               c0.037,0.045,0.053,0.091,0.053,0.137s-0.023,0.098-0.046,0.144c-0.015,0.023-0.03,0.038-0.046,0.054
               c-0.03,0.03-0.129,0.045-0.259,0.045c-0.197,0-0.441-0.03-0.57-0.061c-0.214-0.038-0.236,0.038-0.282,0.183
               c-0.008,0.045-0.023,0.091-0.046,0.145c-0.045,0.091-0.084,0.175-0.122,0.236c-0.046,0.061-0.084,0.114-0.129,0.16
               c-0.1,0.113-0.092,0.144-0.008,0.411l0.03,0.113c0.046,0.145-0.006,0.298-0.075,0.412c-0.184-0.16-0.373-0.365-0.526-0.617
               c-0.312-0.517-0.838-1.165-1.469-1.066c-0.633,0.092-0.716,0.373-1.135,0.45c-0.419,0.076-0.616-0.031-0.861-0.122
               c-0.25-0.084-0.304,0.007-0.494-0.084c-0.19-0.099-0.122-0.205-0.327-0.251c-0.214-0.046-0.222-0.161-0.472-0.282
               c-0.244-0.122-0.297,0.015-0.739-0.229c-0.45-0.235-0.365-0.274-0.677-0.357c-0.313-0.091-0.534-0.35-0.655-0.51
               c-0.122-0.16-0.229-0.251-0.336-0.237c-0.106,0.016-0.259,0.077-0.441-0.023c-0.182-0.106-0.524-0.213-0.602-0.327
               c-0.084-0.114-0.137-0.266-0.259-0.38c-0.121-0.115-0.556-0.32-0.769-0.556c-0.212-0.236-0.396-0.541-0.381-0.716
               c0.015-0.175-0.16-0.327-0.053-0.38c0.106-0.054,0.403-0.031,0.35-0.161c-0.053-0.129-0.251-0.114-0.152-0.236
               c0.1-0.121,0.252-0.198,0.259-0.373c0-0.175-0.145-0.228-0.129-0.388c0.007-0.16-0.198-0.259-0.137-0.426
               c0.06-0.168-0.062-0.746-0.441-1.143c-0.374-0.388-0.48-0.494-0.647-0.791c-0.161-0.297-0.229-0.418-0.305-0.662
               c-0.084-0.245-0.67-0.404-0.747-0.647c-0.075-0.235,0.047-0.342,0.1-0.586c0.054-0.244,0.084-0.373-0.076-0.396
               c-0.16-0.023-0.26,0.053-0.305-0.106c-0.038-0.161,0.022-0.298-0.114-0.396c-0.145-0.092-0.229-0.039-0.243-0.297
               c-0.016-0.252,0.099-0.359-0.145-0.434c-0.244-0.084-0.29-0.031-0.351-0.175c-0.061-0.138-0.221-0.488-0.313-0.61
               c-0.083-0.122-0.152-0.107-0.206-0.023c-0.053,0.092-0.189,0.069-0.151-0.084c0.046-0.16,0.137-0.35,0.243-0.304
               c0.114,0.038,0.222-0.106,0.122-0.183c-0.091-0.083-0.183,0.069-0.19-0.228c0-0.297,0.083-0.655,0-0.814
               c-0.076-0.153-0.068-0.206-0.016-0.351c0.054-0.137,0.153-0.19,0.016-0.26c-0.145-0.068-0.319-0.121-0.357-0.205
               c-0.046-0.084,0.015-0.114-0.205-0.084c-0.222,0.039-0.381-0.152-0.465-0.236c-0.092-0.075-0.344,0.077-0.289,0.336
               c0.053,0.259,0.015,0.411-0.023,0.487c-0.038,0.068-0.016,0.206-0.008,0.365c0.008,0.168-0.137,0.138-0.068,0.396
               c0.076,0.259,0.114,0.442,0.29,0.602c0.183,0.153,0.197,0.289,0.183,0.403c-0.009,0.115,0.151,0.206,0.213,0.313
               c0.061,0.099-0.022,0.068,0.045,0.228c0.07,0.153,0.183,0.076,0.146,0.274c-0.047,0.205-0.069,0.419,0.045,0.549
               c0.115,0.128,0.198,0.159,0.19,0.289c-0.008,0.129,0.038,0.183,0.069,0.267c0.036,0.091,0.091,0.296,0.121,0.266
               c0.038-0.031,0.114-0.145,0.168,0.007c0.046,0.145,0.061,0.442,0.022,0.594c-0.03,0.153,0.016,0.373,0.076,0.442
               c0.061,0.075,0,0.373,0.084,0.564c0.076,0.19-0.03,0.227-0.023,0.41c0.008,0.183,0.138,0.412,0.198,0.351
               c0.062-0.061,0.015-0.313,0.106-0.236c0.084,0.075-0.037,0.152,0.152,0.297c0.198,0.151,0.236,0.206,0.244,0.319
               c0.007,0.114,0.151,0.244,0.091,0.396c-0.061,0.153-0.388,0.51-0.51,0.419c-0.121-0.091-0.092-0.145-0.151-0.442
               c-0.062-0.296-0.229-0.281-0.45-0.548c-0.213-0.273-0.357-0.51-0.533-0.586c-0.175-0.069-0.343-0.115-0.289-0.274
               c0.053-0.152,0.076-0.152,0.145-0.373c0.068-0.213,0.197-0.624,0.138-0.746c-0.069-0.122-0.306-0.061-0.396-0.251
               c-0.083-0.191-0.083-0.473-0.206-0.473c-0.129,0-0.228,0.191-0.373,0.099c-0.145-0.099-0.045-0.16-0.197-0.235
               c-0.145-0.069-0.168-0.092-0.252-0.198c-0.075-0.107-0.075-0.046-0.182-0.222c-0.115-0.175-0.328-0.358-0.184-0.387
               c0.138-0.023,0.374,0.037,0.48-0.038c0.099-0.084,0.273-0.168,0.365-0.313c0.099-0.145,0.167-0.167,0.091-0.335
               s-0.419-0.366-0.456-0.594c-0.047-0.229-0.123-0.281-0.243-0.342c-0.123-0.062-0.306-0.123-0.268-0.297
               c0.038-0.176,0.106-0.282,0.091-0.343c-0.022-0.061-0.175-0.137-0.129-0.229c0.038-0.083,0.084-0.19,0.061-0.281
               c-0.03-0.099-0.122-0.16-0.106-0.274c0.016-0.115,0.038-0.153,0.016-0.244c-0.016-0.084-0.168-0.092-0.091-0.244
               c0.084-0.152,0.137-0.236,0.099-0.289c-0.039-0.053-0.153-0.206-0.122-0.289c0.038-0.084-0.007-0.183-0.007-0.206
               c0,0,0.007-0.038,0.022-0.092c0.563-0.06,1.742-0.183,1.804-0.121c0.031,0.038,0.022,0.076,0.016,0.114
               c-0.008,0.061-0.016,0.114,0.062,0.16c0.061,0.038,0.989,0.449,1.728,0.746c0.418,0.168,0.785,0.297,0.922,0.297
               c0.076,0.008,0.221,0.015,0.404,0.03c0.616,0.046,1.612,0.123,1.696-0.03c0.038-0.069,0.038-0.137,0.038-0.213
               c0-0.099,0-0.198,0.168-0.198c0.091,0,0.244-0.008,0.411-0.015c0.365-0.023,0.8-0.046,0.846,0
               c0.022,0.022,0.022,0.053,0.022,0.083c0.008,0.084,0.015,0.183,0.176,0.29c0.098,0.076,0.121,0.114,0.143,0.174
               c0.031,0.062,0.063,0.138,0.191,0.282c0.13,0.138,0.221,0.245,0.281,0.343c0.062,0.092,0.084,0.176,0.076,0.275
               c0,0.045-0.015,0.106-0.029,0.167c-0.047,0.19-0.1,0.396,0.129,0.601c0.168,0.153,0.381,0.214,0.556,0.267
               c0.114,0.037,0.214,0.068,0.251,0.114c0.054,0.061,0.122,0.038,0.198-0.023c0.076-0.069,0.152-0.19,0.229-0.296
               c0.062-0.084,0.114-0.161,0.152-0.198c0.068-0.061,0.259-0.175,0.473-0.214c0.137-0.023,0.289-0.015,0.411,0.053
               c0.174,0.092,0.266,0.145,0.319,0.198c0.045,0.038,0.053,0.084,0.068,0.161c0.022,0.091,0.069,0.189,0.114,0.289
               c0.061,0.114,0.123,0.236,0.137,0.349c0.008,0.077,0.008,0.138,0,0.191c-0.008,0.107-0.008,0.198,0.084,0.366
               c0.046,0.084,0.092,0.145,0.13,0.213c0.091,0.129,0.167,0.236,0.183,0.411c0.015,0.259,0.068,0.464,0.113,0.624l0.008,0.023
               c0.031,0.099,0.13,0.259,0.267,0.388c0.114,0.107,0.244,0.199,0.381,0.237c0.13,0.03,0.183,0.061,0.237,0.09
               c0.052,0.032,0.105,0.061,0.22,0.084C35.497,49.094,35.626,49.102,35.756,49.109z"/>
                   
             <path  fill="#808184" d="M46.453,51.782c-0.03,0.015-0.32,0.16-0.54,0.244
             c-0.229,0.076-0.274,0.198-0.351,0.312c-0.068,0.122-0.016,0.183-0.129,0.282c-0.115,0.091-0.617,0.121-0.404,0.213
             c0.205,0.099,0.19-0.016,0.358,0.03c0.175,0.038,0.312-0.007,0.35-0.053c0.031-0.038,0.1-0.205,0.243-0.213
             c0.152-0.008,0.282-0.237,0.42-0.274c0.144-0.046,0.502-0.267,0.654-0.267c0.16,0,0.602-0.069,0.563,0.084
             c-0.038,0.144-0.335,0.053-0.289,0.206c0.038,0.159,0.175,0.213,0.281,0.228c0.106,0.008,0.86-0.091,1.097,0.083
             c0.229,0.176,0.122,0.26,0.381,0.328c0.259,0.069,0.518,0.084,0.662,0.084s0.229-0.076,0.328,0.099
             c0.091,0.175-0.252,0.244,0.113,0.502c0.358,0.259,0.533,0.176,0.746,0.259c0.214,0.076,0.351,0.137,0.268,0.228
             c-0.092,0.084-0.686,0.29-0.366,0.457c0.313,0.168,0.495-0.099,0.906-0.029c0.411,0.068,0.525,0.121,0.762,0.106
             c0.243-0.015,0.281-0.023,0.418-0.061c0.146-0.038,0.594,0.038,0.534-0.168c-0.063-0.213-0.184-0.243-0.336-0.358
             c-0.145-0.114,0.022-0.114-0.373-0.16c-0.389-0.038-0.472-0.068-0.457-0.243c0.016-0.175,0.152-0.122-0.205-0.266
             c-0.366-0.145-0.83-0.343-1.044-0.579c-0.205-0.236-0.632-0.671-0.677-0.655c-0.038,0.015,0.022,0.122,0.183,0.312
             c0.167,0.183,0.358,0.327,0.198,0.304c-0.16-0.014,0-0.014-0.381-0.266c-0.373-0.251-0.152-0.22-0.64-0.274
             c-0.487-0.061-0.479-0.35-0.609-0.388c-0.138-0.038-0.221,0.106-0.373-0.031c-0.152-0.145-0.19-0.228-0.357-0.198
             c-0.168,0.023-0.122,0.069-0.404,0.023c-0.281-0.053-0.273-0.168-0.64-0.069c-0.357,0.092-0.456,0.198-0.646,0.16
             C46.515,51.736,46.453,51.782,46.453,51.782 M46.521,52.855c0,0-0.099,0.015-0.037,0.099c0.053,0.084,0.105,0.122,0.076,0.152
             c-0.031,0.038-0.168-0.068-0.176,0.015c-0.015,0.084,0.092,0.145,0.19,0.145c0.106-0.007,0.319,0.015,0.327-0.145
             c0.016-0.16-0.045-0.365-0.183-0.365C46.59,52.756,46.521,52.855,46.521,52.855"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M46.453,51.782c-0.03,0.015-0.32,0.16-0.54,0.244c-0.229,0.076-0.274,0.198-0.351,0.312c-0.068,0.122-0.016,0.183-0.129,0.282
             c-0.115,0.091-0.617,0.121-0.404,0.213c0.205,0.099,0.19-0.016,0.358,0.03c0.175,0.038,0.312-0.007,0.35-0.053
             c0.031-0.038,0.1-0.205,0.243-0.213c0.152-0.008,0.282-0.237,0.42-0.274c0.144-0.046,0.502-0.267,0.654-0.267
             c0.16,0,0.602-0.069,0.563,0.084c-0.038,0.144-0.335,0.053-0.289,0.206c0.038,0.159,0.175,0.213,0.281,0.228
             c0.106,0.008,0.86-0.091,1.097,0.083c0.229,0.176,0.122,0.26,0.381,0.328c0.259,0.069,0.518,0.084,0.662,0.084
             s0.229-0.076,0.328,0.099c0.091,0.175-0.252,0.244,0.113,0.502c0.358,0.259,0.533,0.176,0.746,0.259
             c0.214,0.076,0.351,0.137,0.268,0.228c-0.092,0.084-0.686,0.29-0.366,0.457c0.313,0.168,0.495-0.099,0.906-0.029
             c0.411,0.068,0.525,0.121,0.762,0.106c0.243-0.015,0.281-0.023,0.418-0.061c0.146-0.038,0.594,0.038,0.534-0.168
             c-0.063-0.213-0.184-0.243-0.336-0.358c-0.145-0.114,0.022-0.114-0.373-0.16c-0.389-0.038-0.472-0.068-0.457-0.243
             c0.016-0.175,0.152-0.122-0.205-0.266c-0.366-0.145-0.83-0.343-1.044-0.579c-0.205-0.236-0.632-0.671-0.677-0.655
             c-0.038,0.015,0.022,0.122,0.183,0.312c0.167,0.183,0.358,0.327,0.198,0.304c-0.16-0.014,0-0.014-0.381-0.266
             c-0.373-0.251-0.152-0.22-0.64-0.274c-0.487-0.061-0.479-0.35-0.609-0.388c-0.138-0.038-0.221,0.106-0.373-0.031
             c-0.152-0.145-0.19-0.228-0.357-0.198c-0.168,0.023-0.122,0.069-0.404,0.023c-0.281-0.053-0.273-0.168-0.64-0.069
             c-0.357,0.092-0.456,0.198-0.646,0.16C46.515,51.736,46.453,51.782,46.453,51.782z M46.521,52.855c0,0-0.099,0.015-0.037,0.099
             c0.053,0.084,0.105,0.122,0.076,0.152c-0.031,0.038-0.168-0.068-0.176,0.015c-0.015,0.084,0.092,0.145,0.19,0.145
             c0.106-0.007,0.319,0.015,0.327-0.145c0.016-0.16-0.045-0.365-0.183-0.365C46.59,52.756,46.521,52.855,46.521,52.855z"/>
          
         
          
        
        
        
        
        
        
        
            <path   fill="#808184" d="M47.603,55.132c0,0-0.167,0.023-0.091,0.114
               c0.084,0.084,0.419-0.053,0.341-0.099C47.778,55.101,47.603,55.132,47.603,55.132 M48.707,54.789c0,0-0.229,0.099-0.106,0.145
               c0.114,0.038,0.313-0.114,0.273-0.152C48.844,54.751,48.707,54.789,48.707,54.789"/>
             <path   fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M47.603,55.132c0,0-0.167,0.023-0.091,0.114c0.084,0.084,0.419-0.053,0.341-0.099C47.778,55.101,47.603,55.132,47.603,55.132z
                M48.707,54.789c0,0-0.229,0.099-0.106,0.145c0.114,0.038,0.313-0.114,0.273-0.152C48.844,54.751,48.707,54.789,48.707,54.789z"
               />
             <path fill="#808184" d="M50.237,55.984c0,0-0.51-0.099-0.38,0.152
               c0.129,0.252,0.159,0.084,0.289,0.198c0.137,0.107,0.106,0.229,0.243,0.267c0.137,0.038,0.168-0.084,0.267,0.008
               c0.092,0.091,0.129,0.152,0.213,0.068c0.084-0.091-0.038-0.137,0.152-0.107c0.198,0.023,0.184,0.023,0.335,0.031
               c0.151,0.008,0.396,0.038,0.26-0.13c-0.13-0.167-0.168-0.19-0.336-0.251c-0.167-0.053-0.388-0.236-0.564-0.251
               C50.542,55.946,50.237,55.984,50.237,55.984"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M50.237,55.984c0,0-0.51-0.099-0.38,0.152c0.129,0.252,0.159,0.084,0.289,0.198c0.137,0.107,0.106,0.229,0.243,0.267
               c0.137,0.038,0.168-0.084,0.267,0.008c0.092,0.091,0.129,0.152,0.213,0.068c0.084-0.091-0.038-0.137,0.152-0.107
               c0.198,0.023,0.184,0.023,0.335,0.031c0.151,0.008,0.396,0.038,0.26-0.13c-0.13-0.167-0.168-0.19-0.336-0.251
               c-0.167-0.053-0.388-0.236-0.564-0.251C50.542,55.946,50.237,55.984,50.237,55.984z"/>
             <path fill="#808184" d="M50.923,49.703c0,0-0.092,0.137-0.076,0.252
               c0.007,0.113-0.106,0.159-0.122,0.281c-0.008,0.122,0.054,0.176,0.122,0.236c0.062,0.061,0.106,0.069,0.175,0.031
               c0.077-0.039,0.176,0.015,0.184-0.123c0-0.136-0.062-0.182-0.092-0.365c-0.029-0.183-0.054-0.304-0.054-0.304L50.923,49.703z
                M52.796,50.312c0,0,0.229,0.221,0.137,0.305c-0.099,0.084-0.015,0.152,0.022,0.152c0.045,0,0.244-0.107,0.168-0.236
               c-0.084-0.137-0.319-0.328-0.351-0.297C52.742,50.259,52.796,50.312,52.796,50.312 M53.078,51.256c0,0-0.123,0.046-0.054,0.16
               c0.076,0.106,0.015,0.099,0.015,0.175c-0.007,0.076,0.168,0.153,0.19,0.183c0.023,0.03,0.023,0.236,0.068,0.152
               c0.038-0.091-0.061-0.457-0.113-0.548c-0.054-0.084-0.016-0.16-0.054-0.16C53.085,51.21,53.078,51.256,53.078,51.256
                M53.679,52.01c0.016,0.008,0.213,0.068,0.184,0.167c-0.031,0.099-0.229,0.335-0.122,0.305c0.113-0.03,0.426-0.411,0.274-0.518
               c-0.152-0.107-0.404-0.129-0.404-0.045C53.61,52.002,53.679,52.01,53.679,52.01 M51.075,48.287c-0.069-0.016-0.145-0.1-0.214,0
               c-0.068,0.098-0.076,0.121-0.129,0.121c-0.046,0-0.213-0.007-0.152,0.069c0.062,0.076,0.19,0.076,0.281,0.015
               c0.1-0.053,0.191-0.06,0.282-0.06c0.084,0.007,0.267-0.115,0.16-0.145C51.197,48.264,51.075,48.287,51.075,48.287
                M51.691,48.256c0,0,0.137,0.046,0.16,0.168c0.016,0.129-0.038,0.198-0.046,0.274c-0.008,0.076-0.159,0.266-0.053,0.312
               c0.106,0.038,0.122,0.031,0.159-0.084c0.039-0.114,0.1-0.441,0.054-0.518c-0.046-0.083-0.197-0.289-0.274-0.25
               C51.623,48.187,51.691,48.256,51.691,48.256 M51.021,50.708c-0.021,0.031-0.053,0.106,0,0.206c0.054,0.098,0,0.182,0.1,0.175
               c0.091-0.015,0.152-0.015,0.159-0.122c0.016-0.107,0.062-0.35-0.038-0.35C51.144,50.609,51.021,50.708,51.021,50.708"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M50.923,49.703c0,0-0.092,0.137-0.076,0.252c0.007,0.113-0.106,0.159-0.122,0.281c-0.008,0.122,0.054,0.176,0.122,0.236
               c0.062,0.061,0.106,0.069,0.175,0.031c0.077-0.039,0.176,0.015,0.184-0.123c0-0.136-0.062-0.182-0.092-0.365
               c-0.029-0.183-0.054-0.304-0.054-0.304L50.923,49.703z M52.796,50.312c0,0,0.229,0.221,0.137,0.305
               c-0.099,0.084-0.015,0.152,0.022,0.152c0.045,0,0.244-0.107,0.168-0.236c-0.084-0.137-0.319-0.328-0.351-0.297
               C52.742,50.259,52.796,50.312,52.796,50.312z M53.078,51.256c0,0-0.123,0.046-0.054,0.16c0.076,0.106,0.015,0.099,0.015,0.175
               c-0.007,0.076,0.168,0.153,0.19,0.183c0.023,0.03,0.023,0.236,0.068,0.152c0.038-0.091-0.061-0.457-0.113-0.548
               c-0.054-0.084-0.016-0.16-0.054-0.16C53.085,51.21,53.078,51.256,53.078,51.256z M53.679,52.01
               c0.016,0.008,0.213,0.068,0.184,0.167c-0.031,0.099-0.229,0.335-0.122,0.305c0.113-0.03,0.426-0.411,0.274-0.518
               c-0.152-0.107-0.404-0.129-0.404-0.045C53.61,52.002,53.679,52.01,53.679,52.01z M51.075,48.287c-0.069-0.016-0.145-0.1-0.214,0
               c-0.068,0.098-0.076,0.121-0.129,0.121c-0.046,0-0.213-0.007-0.152,0.069c0.062,0.076,0.19,0.076,0.281,0.015
               c0.1-0.053,0.191-0.06,0.282-0.06c0.084,0.007,0.267-0.115,0.16-0.145C51.197,48.264,51.075,48.287,51.075,48.287z
                M51.691,48.256c0,0,0.137,0.046,0.16,0.168c0.016,0.129-0.038,0.198-0.046,0.274c-0.008,0.076-0.159,0.266-0.053,0.312
               c0.106,0.038,0.122,0.031,0.159-0.084c0.039-0.114,0.1-0.441,0.054-0.518c-0.046-0.083-0.197-0.289-0.274-0.25
               C51.623,48.187,51.691,48.256,51.691,48.256z M51.021,50.708c-0.021,0.031-0.053,0.106,0,0.206c0.054,0.098,0,0.182,0.1,0.175
               c0.091-0.015,0.152-0.015,0.159-0.122c0.016-0.107,0.062-0.35-0.038-0.35C51.144,50.609,51.021,50.708,51.021,50.708z"/>
             <path fill="#808184" d="M54.547,53.351c0,0-0.114,0.191-0.183,0.175
               c-0.068-0.023-0.274-0.031-0.313,0.007c-0.044,0.046-0.098,0.191,0.009,0.214c0.106,0.015,0.236-0.016,0.335-0.046
               c0.092-0.038,0.358-0.35,0.251-0.35H54.547z M55.399,52.771c0,0,0.495,0.449,0.449,0.251c-0.045-0.19-0.373-0.327-0.403-0.319
               C55.407,52.704,55.399,52.771,55.399,52.771 M54.653,52.253c0,0-0.152,0.153-0.037,0.153c0.105,0.008,0.189,0.053,0.229,0.06
               c0.037,0,0.007-0.19-0.047-0.205C54.737,52.247,54.653,52.253,54.653,52.253"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M54.547,53.351c0,0-0.114,0.191-0.183,0.175c-0.068-0.023-0.274-0.031-0.313,0.007c-0.044,0.046-0.098,0.191,0.009,0.214
               c0.106,0.015,0.236-0.016,0.335-0.046c0.092-0.038,0.358-0.35,0.251-0.35H54.547z M55.399,52.771c0,0,0.495,0.449,0.449,0.251
               c-0.045-0.19-0.373-0.327-0.403-0.319C55.407,52.704,55.399,52.771,55.399,52.771z M54.653,52.253c0,0-0.152,0.153-0.037,0.153
               c0.105,0.008,0.189,0.053,0.229,0.06c0.037,0,0.007-0.19-0.047-0.205C54.737,52.247,54.653,52.253,54.653,52.253z"/>
             <path fill="#808184" d="M55.194,56.746c0.046,0.053,0.091,0.084,0.152,0.099
               c0.235,0.03,0.235,0,0.351-0.228c0.113-0.229,0.03-0.427,0.281-0.442c0.251-0.015,0.183,0.03,0.357,0.076
               c0.176,0.038,0.282-0.122,0.389-0.152c0.114-0.023,0.229-0.092,0.419-0.054c0.197,0.038,0.389-0.091,0.464,0.015
               c0.069,0.1,0.023,0.29,0.13,0.244c0.113-0.045,0.122-0.083,0.205-0.198c0.084-0.114,0.214-0.205,0.038-0.381
               c-0.175-0.175-0.464-0.129-0.64-0.281c-0.175-0.145-0.076-0.289-0.304-0.327c-0.229-0.046-0.198-0.061-0.274-0.16
               c-0.076-0.091-0.054-0.168-0.328-0.153c-0.266,0.016-0.365-0.175-0.54-0.152c-0.175,0.015-0.281,0-0.38,0.107
               c-0.017,0.008-0.031,0.023-0.039,0.03c0.008,0.061,0.016,0.122,0.022,0.183c0.031,0.205-0.007,0.281-0.038,0.35
               c-0.015,0.03-0.022,0.053-0.037,0.084c-0.008,0.038,0,0.068,0,0.091c0.015,0.061,0.022,0.122-0.054,0.267
               c-0.016,0.045-0.038,0.091-0.062,0.121c-0.067,0.13-0.113,0.206-0.045,0.32l0.022,0.039c0.039,0.06,0.062,0.09,0.068,0.129
               c0.009,0.045-0.007,0.076-0.029,0.144l-0.009,0.023C55.301,56.593,55.247,56.67,55.194,56.746"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M55.194,56.746c0.046,0.053,0.091,0.084,0.152,0.099c0.235,0.03,0.235,0,0.351-0.228c0.113-0.229,0.03-0.427,0.281-0.442
               c0.251-0.015,0.183,0.03,0.357,0.076c0.176,0.038,0.282-0.122,0.389-0.152c0.114-0.023,0.229-0.092,0.419-0.054
               c0.197,0.038,0.389-0.091,0.464,0.015c0.069,0.1,0.023,0.29,0.13,0.244c0.113-0.045,0.122-0.083,0.205-0.198
               c0.084-0.114,0.214-0.205,0.038-0.381c-0.175-0.175-0.464-0.129-0.64-0.281c-0.175-0.145-0.076-0.289-0.304-0.327
               c-0.229-0.046-0.198-0.061-0.274-0.16c-0.076-0.091-0.054-0.168-0.328-0.153c-0.266,0.016-0.365-0.175-0.54-0.152
               c-0.175,0.015-0.281,0-0.38,0.107c-0.017,0.008-0.031,0.023-0.039,0.03c0.008,0.061,0.016,0.122,0.022,0.183
               c0.031,0.205-0.007,0.281-0.038,0.35c-0.015,0.03-0.022,0.053-0.037,0.084c-0.008,0.038,0,0.068,0,0.091
               c0.015,0.061,0.022,0.122-0.054,0.267c-0.016,0.045-0.038,0.091-0.062,0.121c-0.067,0.13-0.113,0.206-0.045,0.32l0.022,0.039
               c0.039,0.06,0.062,0.09,0.068,0.129c0.009,0.045-0.007,0.076-0.029,0.144l-0.009,0.023
               C55.301,56.593,55.247,56.67,55.194,56.746z"/>
             <path fill="#808184" d="M55.301,54.857c0,0-0.373-0.091-0.557-0.145
               c-0.174-0.06-0.327,0.008-0.38,0.023c-0.046,0.024-0.495,0.024-0.259,0.199c0.236,0.174,0.473,0.023,0.518,0.167
               c0.046,0.137-0.122,0.19-0.091,0.358c0.038,0.167,0.13,0.175,0.137,0.35c0.016,0.168,0.008,0.22-0.236,0.244
               c-0.235,0.015-0.243,0-0.608-0.061c-0.358-0.053-0.625-0.244-0.687-0.084c-0.06,0.16,0.101,0.343,0.207,0.388
               c0.113,0.054,0,0.168,0.205,0.183c0.198,0.015,0.061-0.22,0.335-0.183c0.281,0.046,0.441,0.084,0.632,0.084
               c0.198,0,0.365-0.114,0.426-0.106c0.076,0.038,0.122,0.228,0.199,0.38c0.037-0.053,0.067-0.106,0.083-0.152v-0.016
               c0.022-0.052,0.03-0.075,0.03-0.091c-0.008-0.022-0.022-0.045-0.054-0.099l-0.022-0.045c-0.099-0.153-0.045-0.251,0.038-0.412
               c0.023-0.03,0.038-0.068,0.063-0.114c0.06-0.122,0.052-0.168,0.045-0.213c-0.009-0.03-0.017-0.069,0-0.13
               c0.015-0.045,0.029-0.075,0.045-0.098c0.022-0.061,0.054-0.122,0.03-0.298c-0.008-0.052-0.015-0.098-0.022-0.144
               C55.331,54.857,55.301,54.857,55.301,54.857"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M55.301,54.857c0,0-0.373-0.091-0.557-0.145c-0.174-0.06-0.327,0.008-0.38,0.023c-0.046,0.024-0.495,0.024-0.259,0.199
               c0.236,0.174,0.473,0.023,0.518,0.167c0.046,0.137-0.122,0.19-0.091,0.358c0.038,0.167,0.13,0.175,0.137,0.35
               c0.016,0.168,0.008,0.22-0.236,0.244c-0.235,0.015-0.243,0-0.608-0.061c-0.358-0.053-0.625-0.244-0.687-0.084
               c-0.06,0.16,0.101,0.343,0.207,0.388c0.113,0.054,0,0.168,0.205,0.183c0.198,0.015,0.061-0.22,0.335-0.183
               c0.281,0.046,0.441,0.084,0.632,0.084c0.198,0,0.365-0.114,0.426-0.106c0.076,0.038,0.122,0.228,0.199,0.38
               c0.037-0.053,0.067-0.106,0.083-0.152v-0.016c0.022-0.052,0.03-0.075,0.03-0.091c-0.008-0.022-0.022-0.045-0.054-0.099
               l-0.022-0.045c-0.099-0.153-0.045-0.251,0.038-0.412c0.023-0.03,0.038-0.068,0.063-0.114c0.06-0.122,0.052-0.168,0.045-0.213
               c-0.009-0.03-0.017-0.069,0-0.13c0.015-0.045,0.029-0.075,0.045-0.098c0.022-0.061,0.054-0.122,0.03-0.298
               c-0.008-0.052-0.015-0.098-0.022-0.144C55.331,54.857,55.301,54.857,55.301,54.857z"/>
             <path fill="#808184" d="M59.215,56.015c-0.03-0.007-0.328,0-0.313,0.129
               c0.015,0.13,0.099,0.206,0.075,0.29c-0.015,0.076,0.069,0.144,0.275,0.099c0.197-0.046,0.205-0.16,0.487-0.107
               c0.273,0.046,0.479-0.015,0.479-0.221c0-0.197,0.053-0.274-0.275-0.274C59.625,55.931,59.215,56.015,59.215,56.015"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M59.215,56.015c-0.03-0.007-0.328,0-0.313,0.129c0.015,0.13,0.099,0.206,0.075,0.29c-0.015,0.076,0.069,0.144,0.275,0.099
               c0.197-0.046,0.205-0.16,0.487-0.107c0.273,0.046,0.479-0.015,0.479-0.221c0-0.197,0.053-0.274-0.275-0.274
               C59.625,55.931,59.215,56.015,59.215,56.015z"/>
             <path fill="#808184" d="M52.521,56.015c0,0-0.183,0.092-0.106,0.138
               c0.068,0.045,0.389-0.032,0.319-0.123C52.658,55.938,52.521,56.015,52.521,56.015"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M52.521,56.015c0,0-0.183,0.092-0.106,0.138c0.068,0.045,0.389-0.032,0.319-0.123C52.658,55.938,52.521,56.015,52.521,56.015z"
               />
            
          <path fill="#808184" d="M47.283,76.398c0.205-0.229,0.457-0.691,0.388-0.732
               c-0.083-0.045-0.151,0.115-0.304,0.115c-0.16,0-0.069-0.137-0.305-0.182c-0.236-0.039-0.495-0.131-0.343-0.291
               c0.16-0.159,0.229-0.184,0.152-0.355c-0.083-0.184-0.167-0.123-0.067-0.359c0.098-0.243,0.045-0.365,0.196-0.577
               c0.161-0.214,0.047-0.336,0.114-0.519c0.069-0.176,0.189,0.229,0.336-0.357c0.152-0.588-0.146-0.572,0.205-0.723
               c0.35-0.146,0.594-0.176,0.701-0.291c0.029-0.039,0.029-0.092,0.015-0.145c0.03,0.016,0.061,0.029,0.092,0.038
               c0.037,0.021,0.083,0.046,0.129,0.062c0.145,0.062,0.328,0.137,0.427,0.318c0.099,0.199,0.16,0.184,0.242,0.16
               c0.032-0.008,0.077-0.016,0.146,0.007c0.099,0.032,0.113,0.093,0.129,0.153c0.022,0.053,0.038,0.105,0.091,0.168
               c0.1,0.099,0.252,0.129,0.411,0.158c0.046,0.008,0.084,0.018,0.13,0.022c0.145,0.039,0.198-0.006,0.259-0.062
               c0.03-0.028,0.068-0.062,0.13-0.075c0.091-0.022,0.099,0,0.122,0.03c0.022,0.022,0.045,0.062,0.091,0.09
               c0.038,0.031,0.083,0.047,0.114,0.063c0.061,0.03,0.114,0.053,0.168,0.153c0.046,0.082,0.091,0.183,0.152,0.274
               c0.053,0.09,0.113,0.174,0.189,0.25c0.084,0.092,0.076,0.121,0.054,0.197c-0.008,0.023-0.008,0.047-0.016,0.076
               c-0.03,0.105-0.19,0.357-0.274,0.494c-0.022,0.039-0.045,0.068-0.053,0.084c0,0.018-0.016,0.039-0.03,0.076
               c-0.038,0.092-0.1,0.236-0.152,0.328c-0.038,0.052-0.138,0.144-0.243,0.234c-0.115,0.1-0.236,0.213-0.298,0.297
               c-0.045,0.068-0.19,0.146-0.358,0.229c-0.175,0.084-0.38,0.159-0.555,0.22c-0.243,0.092-0.252,0.137-0.259,0.197
               c0,0.023,0,0.047-0.054,0.084c-0.152,0.13-0.213,0.305-0.267,0.465l-0.016,0.055c-0.03,0.074-0.037,0.166-0.053,0.25
               c-0.008,0.115-0.022,0.223-0.061,0.273c-0.069,0.1-0.107,0.197-0.153,0.329c-0.007,0.028-0.022,0.06-0.037,0.099
               c-0.047,0.114-0.062,0.106-0.213,0.092c-0.023,0-0.047-0.008-0.076-0.008c-0.153-0.017-0.16-0.123-0.16-0.205
               c0-0.039,0-0.068-0.016-0.101c-0.03-0.106-0.152-0.129-0.26-0.144c-0.015-0.008-0.029-0.008-0.045-0.017
               c-0.03-0.008-0.053-0.022-0.068-0.047c-0.03-0.036-0.053-0.075-0.122-0.099c-0.084-0.037-0.137,0.022-0.197,0.092
               c-0.038,0.046-0.084,0.099-0.13,0.091c-0.008,0-0.008-0.007-0.008-0.007c0-0.016,0.016-0.038,0.029-0.068
               c0.032-0.054,0.063-0.113,0.039-0.176c-0.015-0.029-0.029-0.053-0.046-0.075c-0.007-0.015-0.015-0.03,0-0.069
               c0.023-0.031,0.062-0.054,0.1-0.061c0.054-0.021,0.099-0.045,0.114-0.1c0.008-0.045,0.008-0.1-0.016-0.158
               c-0.016-0.063-0.045-0.123-0.091-0.189C47.382,76.498,47.337,76.443,47.283,76.398"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M47.283,76.398c0.205-0.229,0.457-0.691,0.388-0.732c-0.083-0.045-0.151,0.115-0.304,0.115c-0.16,0-0.069-0.137-0.305-0.182
               c-0.236-0.039-0.495-0.131-0.343-0.291c0.16-0.159,0.229-0.184,0.152-0.355c-0.083-0.184-0.167-0.123-0.067-0.359
               c0.098-0.243,0.045-0.365,0.196-0.577c0.161-0.214,0.047-0.336,0.114-0.519c0.069-0.176,0.189,0.229,0.336-0.357
               c0.152-0.588-0.146-0.572,0.205-0.723c0.35-0.146,0.594-0.176,0.701-0.291c0.029-0.039,0.029-0.092,0.015-0.145
               c0.03,0.016,0.061,0.029,0.092,0.038c0.037,0.021,0.083,0.046,0.129,0.062c0.145,0.062,0.328,0.137,0.427,0.318
               c0.099,0.199,0.16,0.184,0.242,0.16c0.032-0.008,0.077-0.016,0.146,0.007c0.099,0.032,0.113,0.093,0.129,0.153
               c0.022,0.053,0.038,0.105,0.091,0.168c0.1,0.099,0.252,0.129,0.411,0.158c0.046,0.008,0.084,0.018,0.13,0.022
               c0.145,0.039,0.198-0.006,0.259-0.062c0.03-0.028,0.068-0.062,0.13-0.075c0.091-0.022,0.099,0,0.122,0.03
               c0.022,0.022,0.045,0.062,0.091,0.09c0.038,0.031,0.083,0.047,0.114,0.063c0.061,0.03,0.114,0.053,0.168,0.153
               c0.046,0.082,0.091,0.183,0.152,0.274c0.053,0.09,0.113,0.174,0.189,0.25c0.084,0.092,0.076,0.121,0.054,0.197
               c-0.008,0.023-0.008,0.047-0.016,0.076c-0.03,0.105-0.19,0.357-0.274,0.494c-0.022,0.039-0.045,0.068-0.053,0.084
               c0,0.018-0.016,0.039-0.03,0.076c-0.038,0.092-0.1,0.236-0.152,0.328c-0.038,0.052-0.138,0.144-0.243,0.234
               c-0.115,0.1-0.236,0.213-0.298,0.297c-0.045,0.068-0.19,0.146-0.358,0.229c-0.175,0.084-0.38,0.159-0.555,0.22
               c-0.243,0.092-0.252,0.137-0.259,0.197c0,0.023,0,0.047-0.054,0.084c-0.152,0.13-0.213,0.305-0.267,0.465l-0.016,0.055
               c-0.03,0.074-0.037,0.166-0.053,0.25c-0.008,0.115-0.022,0.223-0.061,0.273c-0.069,0.1-0.107,0.197-0.153,0.329
               c-0.007,0.028-0.022,0.06-0.037,0.099c-0.047,0.114-0.062,0.106-0.213,0.092c-0.023,0-0.047-0.008-0.076-0.008
               c-0.153-0.017-0.16-0.123-0.16-0.205c0-0.039,0-0.068-0.016-0.101c-0.03-0.106-0.152-0.129-0.26-0.144
               c-0.015-0.008-0.029-0.008-0.045-0.017c-0.03-0.008-0.053-0.022-0.068-0.047c-0.03-0.036-0.053-0.075-0.122-0.099
               c-0.084-0.037-0.137,0.022-0.197,0.092c-0.038,0.046-0.084,0.099-0.13,0.091c-0.008,0-0.008-0.007-0.008-0.007
               c0-0.016,0.016-0.038,0.029-0.068c0.032-0.054,0.063-0.113,0.039-0.176c-0.015-0.029-0.029-0.053-0.046-0.075
               c-0.007-0.015-0.015-0.03,0-0.069c0.023-0.031,0.062-0.054,0.1-0.061c0.054-0.021,0.099-0.045,0.114-0.1
               c0.008-0.045,0.008-0.1-0.016-0.158c-0.016-0.063-0.045-0.123-0.091-0.189C47.382,76.498,47.337,76.443,47.283,76.398z"/>
             <path fill="#808184" d="M66.79,122.218c0.031,0,0.062,0.016,0.099,0.046
               c0.13,0.12,0.48,0.252,0.32,0.401c-0.152,0.153-0.145,0.291,0.099,0.33c0.243,0.045,0.427-0.047,0.7,0.196
               c0.274,0.235,0.351,0.304,0.594,0.45c0.244,0.15,0.603,0.318,0.906,0.396c0.303,0.078,0.641-0.055,0.647,0.076
               c0.016,0.129,0.077,0.235-0.205,0.227c-0.281-0.016-0.319-0.016-0.449,0.063l-0.016,0.008c-0.145-0.061-0.289-0.129-0.403-0.176
               c-0.221-0.082-0.472-0.076-0.716-0.061c-0.106,0-0.213,0.008-0.306,0c-0.212-0.008-0.296-0.123-0.395-0.26
               c-0.054-0.068-0.099-0.138-0.168-0.205c-0.175-0.183-0.297-0.502-0.373-0.701c-0.023-0.045-0.037-0.092-0.054-0.121
               c-0.029-0.075-0.053-0.145-0.067-0.213c-0.023-0.068-0.038-0.131-0.069-0.19c-0.029-0.053-0.075-0.151-0.129-0.243
               L66.79,122.218z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M66.79,122.218c0.031,0,0.062,0.016,0.099,0.046c0.13,0.12,0.48,0.252,0.32,0.401c-0.152,0.153-0.145,0.291,0.099,0.33
               c0.243,0.045,0.427-0.047,0.7,0.196c0.274,0.235,0.351,0.304,0.594,0.45c0.244,0.15,0.603,0.318,0.906,0.396
               c0.303,0.078,0.641-0.055,0.647,0.076c0.016,0.129,0.077,0.235-0.205,0.227c-0.281-0.016-0.319-0.016-0.449,0.063l-0.016,0.008
               c-0.145-0.061-0.289-0.129-0.403-0.176c-0.221-0.082-0.472-0.076-0.716-0.061c-0.106,0-0.213,0.008-0.306,0
               c-0.212-0.008-0.296-0.123-0.395-0.26c-0.054-0.068-0.099-0.138-0.168-0.205c-0.175-0.183-0.297-0.502-0.373-0.701
               c-0.023-0.045-0.037-0.092-0.054-0.121c-0.029-0.075-0.053-0.145-0.067-0.213c-0.023-0.068-0.038-0.131-0.069-0.19
               c-0.029-0.053-0.075-0.151-0.129-0.243L66.79,122.218z"/>
             <path fill="#808184" d="M66.219,121.93c-0.007,0.015-0.015,0.022-0.022,0.045
               c-0.022,0.185,0,0.28,0.259,0.289c0.1,0,0.176-0.015,0.229-0.03c0.008,0.016,0.023,0.037,0.031,0.063
               c0.053,0.082,0.099,0.183,0.129,0.234c0.021,0.055,0.045,0.114,0.062,0.176c0.022,0.068,0.037,0.146,0.075,0.221
               c0.016,0.031,0.031,0.068,0.053,0.121c0.077,0.207,0.206,0.533,0.389,0.73c0.062,0.068,0.114,0.131,0.16,0.189
               c0.122,0.169,0.213,0.306,0.472,0.314c0.1,0,0.206,0,0.312-0.009c0.237-0.007,0.48-0.022,0.679,0.062
               c0.084,0.031,0.183,0.076,0.289,0.121c-0.099,0.01-0.205,0-0.319,0c-0.175,0-0.092,0.068-0.191,0.152
               c-0.09,0.084-0.235,0-0.41-0.053c-0.168-0.055-0.556-0.076-0.365,0.053c0.19,0.139,0.647,0.29,0.495,0.336
               c-0.16,0.047-0.237-0.152-0.495-0.129c-0.252,0.021-0.282-0.123-0.381-0.178c-0.099-0.051-0.068-0.051-0.19,0.01
               c-0.114,0.055-0.259,0.076-0.381-0.016c-0.122-0.1-0.015-0.281-0.189-0.352c-0.184-0.068-0.32-0.099-0.291,0.023
               c0.039,0.129,0.251,0.35-0.022,0.236c-0.268-0.115-0.25-0.236-0.418-0.229c-0.168,0.008-0.268-0.067-0.366-0.168
               c-0.106-0.091-0.373-0.052-0.472-0.114c-0.106-0.068-0.084-0.167-0.213-0.212c-0.131-0.046,0.258-0.327,0.563-0.175
               c0.312,0.15,0.357,0.197,0.35,0.037c-0.008-0.167-0.335-0.51-0.151-0.487c0.183,0.017,0.25,0.132,0.281,0.252
               c0.03,0.13,0.396,0.365,0.465,0.267c0.076-0.092-0.335-0.151-0.328-0.364c0.016-0.216,0.419-0.282,0.176-0.382
               c-0.252-0.1-0.321,0.137-0.549-0.031c-0.229-0.158-0.137-0.35-0.023-0.455c0.115-0.1,0.251-0.298,0.069-0.318
               c-0.183-0.018-0.221,0.074-0.472,0.15c-0.252,0.084-0.381,0.189-0.176,0.419s0.205,0.272,0.183,0.419
               c-0.022,0.137,0.114,0.221-0.114,0.221s-0.389-0.043-0.389,0.055c0,0.091,0.106,0.258-0.053,0.281
               c-0.152,0.022-0.419-0.244-0.586-0.307c-0.168-0.061-0.168,0.168-0.32-0.072c-0.152-0.246-0.137-0.346-0.411-0.438
               c-0.267-0.083-1.058-0.486-0.943-0.52c0.121-0.021,0.379,0.024,0.518,0.094c0.13,0.068,0.373,0.272,0.38,0.099
               c0-0.183-0.091-0.122-0.266-0.356c-0.175-0.229-0.107-0.146-0.396-0.313c-0.297-0.167-0.686-0.312-0.441-0.312
               c0.245,0,0.465,0.129,0.441-0.016c-0.03-0.146-0.151-0.152-0.289-0.184c-0.137-0.039-0.152-0.045-0.145-0.205
               c0.015-0.16-0.008-0.151-0.145-0.206c-0.426-0.183-0.381-0.015-0.746-0.197c-0.358-0.185-0.062-0.222-0.016-0.374
               c0.046-0.15,0.016-0.175-0.046-0.25c-0.061-0.083-0.114-0.223-0.175-0.139c-0.053,0.092-0.062,0.169-0.114,0.26
               c-0.046,0.092,0.106,0.252-0.091,0.122c-0.206-0.138-0.146-0.373-0.215-0.419c-0.067-0.047-0.174-0.26-0.212-0.352
               c-0.046-0.099-0.267,0.017-0.259-0.174c0.014-0.184,0.091-0.184-0.023-0.298c-0.122-0.112-0.343-0.259-0.205-0.304
               c0.137-0.047,0.197,0.021,0.289,0c0.092-0.016,0.236-0.123,0.099-0.153c-0.137-0.022-0.267-0.017-0.373-0.007
               c-0.113,0.007-0.197-0.016-0.197-0.113c-0.008-0.1-0.023-0.207-0.062-0.268c-0.037-0.053-0.045-0.221,0.084-0.229
               c0.076,0,0.259-0.412,0.396,0.037c0.068,0.221,0.236,0.235,0.297,0.313c0.068,0.068,0.023,0.115,0.123,0.16
               c0.091,0.045,0.211,0.084,0.159-0.037c-0.046-0.123-0.099-0.16-0.183-0.223c-0.092-0.066-0.229-0.066-0.259-0.189
               c-0.022-0.122,0.092-0.145-0.016-0.189c-0.106-0.046-0.243-0.068-0.243-0.137c-0.008-0.077,0.03-0.184,0.062-0.245
               c0.037-0.062,0.015-0.099,0.015-0.099s-0.03-0.106,0.022-0.129c0.062-0.031,0.151-0.069,0.145-0.121
               c-0.015-0.055-0.175-0.032-0.259-0.123c-0.084-0.092-0.152-0.1-0.229-0.092c-0.076,0.015-0.114,0.022-0.183-0.008
               c-0.061-0.029-0.273-0.221-0.404-0.146c-0.121,0.075,0.069,0.114,0.017,0.206c-0.061,0.1-0.168,0.16-0.222,0.016
               c-0.046-0.146-0.137-0.259-0.061-0.328c0.076-0.074,0.16-0.121,0.198-0.221c0.046-0.1,0.008-0.105-0.031-0.205
               c-0.037-0.1-0.175-0.242-0.045-0.268c0.129-0.014,0.206,0.008,0.213-0.092c0.008-0.09,0.038-0.099,0.062-0.168
               c0.022-0.06-0.031-0.021-0.047-0.144c-0.015-0.122-0.045-0.252,0.062-0.19c0.106,0.062,0.076,0.215,0.152,0.297
               c0.084,0.084,0.122,0.076,0.13,0.19c0.015,0.106,0.053,0.138,0.175,0.212c0.114,0.084,0.221,0.161,0.29,0.262
               c0.066,0.09,0.021,0.312,0.105,0.303c0.092-0.008,0.236-0.168,0.106-0.303c-0.129-0.139-0.068-0.224-0.159-0.313
               c-0.1-0.099-0.327,0-0.396-0.151c-0.076-0.144,0.054-0.121-0.038-0.244c-0.091-0.121-0.167-0.09-0.236-0.213
               c-0.06-0.121-0.183-0.084-0.159-0.176c0.016-0.092,0.008-0.167-0.053-0.167c-0.069,0-0.13,0.054-0.206,0.009
               c-0.068-0.045-0.13-0.099-0.205-0.068c-0.084,0.029-0.123,0.107-0.16,0.037c-0.038-0.066,0.037-0.168,0.167-0.19
               c0.13-0.022,0.145-0.121,0.214-0.128c0.075-0.01,0.128,0.029,0.243,0.053c0.114,0.016,0.229,0.023,0.313,0.092
               c0.083,0.068,0.045,0.145,0.037,0.229c-0.008,0.084,0.054,0.213,0.1,0.251c0.046,0.046,0.114,0.13,0.099,0.229
               c-0.022,0.1-0.016,0.246,0.084,0.213c0.099-0.021,0.099-0.184,0.13-0.268c0.037-0.083-0.107-0.158-0.152-0.297
               c-0.054-0.137-0.16-0.313-0.106-0.373c0.053-0.068,0.151-0.145,0.061-0.205c-0.092-0.068-0.152-0.084-0.122-0.145
               c0.03-0.055,0.084-0.313,0.022-0.396c-0.068-0.084-0.228-0.357-0.175-0.464c0.061-0.106,0.175-0.185,0.068-0.267
               c-0.107-0.084-0.213-0.207-0.175-0.389c0.031-0.176-0.145-0.168-0.122-0.25c0.023-0.078,0.206-0.078,0.145-0.177
               c-0.053-0.091-0.273,0.009-0.273-0.138c0-0.143-0.068-0.365-0.244-0.319c-0.167,0.045,0.062,0.183-0.106,0.313
               c-0.175,0.129-0.106,0.175-0.045,0.242c0.068,0.068,0.121,0.198,0.037,0.244c-0.083,0.053-0.146,0.137-0.037,0.235
               c0.106,0.092,0.221,0.122,0.235,0.26c0.008,0.13-0.038,0.159-0.015,0.251c0.015,0.092-0.055,0.131-0.152,0.131
               c-0.107,0-0.389,0.084-0.366-0.215c0.023-0.297,0.046-0.318-0.054-0.502c-0.098-0.184-0.113-0.222-0.152-0.396
               c-0.037-0.182-0.007-0.319,0.069-0.402c0.068-0.084,0.038-0.168-0.069-0.273c-0.106-0.107-0.312-0.313-0.304-0.412
               c0.015-0.099,0.099-0.344,0.046-0.738c-0.054-0.387,0.234-0.434,0.067-0.707c-0.168-0.268-0.258-0.428-0.38-0.624
               c-0.13-0.206-0.205-0.572-0.289-0.731c-0.084-0.166-0.229-0.068-0.252-0.273c-0.03-0.205-0.159-0.449-0.03-0.479
               c0.13-0.022,0.183,0.177,0.251,0c0.076-0.183-0.068-0.305-0.015-0.45c0.053-0.136,0.145-0.228,0.106-0.425
               c-0.031-0.198-0.084-0.243-0.068-0.321c0.015-0.074-0.084-0.172,0.037-0.342c0.123-0.16,0.161-0.365,0.161-0.479
               c0-0.115,0.053-0.223,0-0.404c-0.046-0.188-0.063-0.363-0.038-0.473c0.022-0.098,0.143-0.44,0.084-0.623
               c-0.069-0.176-0.13-0.176-0.062-0.457c0.068-0.289,0.068-0.381-0.062-0.578c-0.129-0.198-0.152-0.244-0.182-0.436
               c-0.023-0.188-0.275-0.556-0.365-0.836c-0.092-0.283-0.176-0.535-0.062-0.642c0.121-0.099,0.213-0.159,0.16-0.334
               c-0.054-0.176-0.183-0.358-0.259-0.569c-0.076-0.216-0.039-0.313,0.014-0.451c0.055-0.139,0.047-0.258,0.039-0.336
               c-0.016-0.076-0.092-0.494,0.008-0.713c0.1-0.23,0.159-0.177,0.083-0.39c-0.083-0.222-0.144-0.175-0.067-0.343
               c0.084-0.168,0.067-0.282,0.067-0.411c-0.007-0.138-0.121-0.39-0.121-0.771s0.183-0.518,0.022-0.82
               c-0.159-0.307-0.258-0.329-0.229-0.581c0.038-0.257-0.266-0.623-0.121-0.89c0.145-0.273,0.19-0.283,0.234-0.686
               c0.048-0.404-0.067-1.037-0.113-1.195c-0.037-0.16,0.016-0.359-0.054-0.617c-0.067-0.26-0.189-0.379-0.121-0.746
               c0.062-0.342-0.214-0.937-0.336-1.172c0.077-0.029,0.153-0.063,0.206-0.092c0.152-0.084,0.146-0.176,0.146-0.273
               c0-0.039,0-0.092,0.021-0.129c0.039-0.078,0.063-0.152,0.077-0.244c0.015-0.086,0.031-0.176,0.038-0.289
               c0.008-0.023,0.008-0.047,0.016-0.063c0.037,0.047,0.099,0.114,0.106,0.131c0.007,0.016,0.022,0.053,0.038,0.113
               s0.038,0.129,0.06,0.204h0.009c0.022,0.078,0.054,0.115,0.083,0.146c0.038,0.045,0.084,0.099,0.13,0.267
               c0.046,0.167,0.145,0.319,0.229,0.457c0.068,0.112,0.13,0.222,0.152,0.313c0.046,0.166,0.13,0.188,0.229,0.213
               c0.038,0.015,0.083,0.021,0.122,0.053c0.045,0.023,0.015,0.076-0.023,0.138c-0.038,0.06-0.076,0.121-0.068,0.188v0.023
               c0.014,0.105,0.014,0.16-0.061,0.244c-0.068,0.066-0.084,0.129-0.046,0.212c0.037,0.063,0.099,0.13,0.198,0.229
               c0.084,0.076,0.084,0.121,0.084,0.166c0,0.07,0,0.132,0.137,0.244c0.159,0.121,0.213,0.373,0.281,0.662
               c0.023,0.092,0.045,0.19,0.068,0.291c0.068,0.228,0.138,0.342,0.206,0.447c0.054,0.094,0.105,0.182,0.16,0.327
               c0.054,0.168,0.137,0.198,0.235,0.168c0.077-0.022,0.152-0.099,0.222-0.183c0.061,0.076,0.167,0.205,0.167,0.273
               c0.008,0.037-0.007,0.076-0.016,0.121c-0.016,0.063-0.03,0.131-0.016,0.229c0.016,0.1,0.024,0.25,0,0.389
               c-0.007,0.084-0.029,0.152-0.067,0.189c-0.039,0.03-0.114,0.063-0.198,0.084c-0.137,0.045-0.297,0.1-0.434,0.229
               c-0.122,0.105-0.198,0.215-0.236,0.313c-0.03,0.106-0.023,0.205,0.03,0.297c0.046,0.068,0.068,0.123,0.068,0.168
               c0,0.029-0.007,0.061-0.022,0.082c-0.054,0.076-0.022,0.16,0.022,0.274c0.016,0.038,0.03,0.083,0.046,0.122l0.008,0.016
               c0.046,0.145,0.068,0.213,0.099,0.403c0.008,0.054-0.015,0.062-0.03,0.069c-0.068,0.035-0.122,0.068-0.038,0.242
               c0.054,0.115,0.13,0.189,0.19,0.252c0.061,0.061,0.106,0.104,0.076,0.16c-0.022,0.053-0.061,0.066-0.099,0.076
               c-0.046,0.016-0.107,0.029-0.168,0.113c-0.068,0.084-0.076,0.16-0.084,0.234c-0.007,0.063-0.016,0.115-0.054,0.184
               c-0.037,0.068-0.061,0.146-0.083,0.229c-0.03,0.1-0.062,0.213-0.137,0.35c-0.076,0.139-0.138,0.244-0.176,0.357
               c-0.038,0.105-0.053,0.223-0.022,0.336c0.03,0.129,0.008,0.229-0.009,0.313c-0.015,0.055-0.029,0.105-0.029,0.16
               c0,0.1,0.054,0.213,0.129,0.365c0.031,0.076,0.076,0.158,0.115,0.252c0.067,0.166,0.015,0.242-0.054,0.342
               c-0.038,0.063-0.084,0.129-0.114,0.213c-0.068,0.176-0.047,0.32-0.022,0.449c0.007,0.054,0.022,0.113,0.015,0.16
               c-0.008,0.092,0.023,0.267,0.076,0.449c0.046,0.145,0.106,0.297,0.19,0.426c0.084,0.131,0.121,0.244,0.152,0.357
               c0.038,0.114,0.076,0.229,0.167,0.344c0.069,0.092,0.153,0.16,0.222,0.221c0.091,0.092,0.175,0.16,0.175,0.221
               c0,0.076,0.038,0.113,0.076,0.153c0.054,0.053,0.113,0.104,0.061,0.317c-0.038,0.139-0.084,0.223-0.121,0.299
               c-0.069,0.137-0.13,0.25-0.122,0.524l0.007,0.084c0.008,0.388,0.016,0.587,0.106,0.654c0.039,0.022,0.099,0.075,0.146,0.14
               c0.037,0.059,0.053,0.135,0.008,0.227c-0.03,0.054-0.069,0.113-0.099,0.175c-0.1,0.161-0.198,0.319-0.198,0.474
               c0,0.159,0.037,0.288,0.091,0.486c0.016,0.037,0.03,0.091,0.046,0.146c0.016,0.06,0.03,0.104,0.038,0.158
               c0.067,0.26,0.145,0.541,0.297,0.717c0.076,0.084,0.16,0.137,0.221,0.174c0.03,0.016,0.054,0.031,0.076,0.047
               c0.03,0.021,0.046,0.053,0.008,0.099c-0.038,0.054-0.106,0.054-0.168,0.062c-0.03,0-0.061,0.009-0.092,0.009
               c-0.099,0.028-0.159,0.091-0.099,0.327c0.039,0.145,0.076,0.25,0.099,0.334c0.055,0.138,0.077,0.205,0.023,0.298
               c-0.099,0.146-0.046,0.305,0,0.418c0.008,0.03,0.022,0.063,0.022,0.077c0,0.008,0,0.021,0.009,0.054
               c0.015,0.212,0.083,0.937,0.295,1.209c0.063,0.084,0.131,0.161,0.191,0.222c0.054,0.055,0.092,0.1,0.13,0.137
               c0.046,0.07,0.053,0.115,0,0.154c-0.1,0.074-0.16,0.166-0.183,0.266c-0.016,0.099,0.007,0.199,0.083,0.298l0.038,0.052
               c0.122,0.161,0.197,0.268,0.351,0.282c0.015,0,0.038,0,0.054,0.008c0.038,0.009,0.061,0.022,0.084,0.039
               c0.007,0.017,0.015,0.037,0.015,0.06v0.024c-0.03,0.112,0,0.149,0.076,0.228c0.016,0.016,0.03,0.029,0.053,0.055
               c0.039,0.045,0.076,0.06,0.107,0.074c0.03,0.016,0.045,0.024,0.038,0.092c-0.008,0.063-0.03,0.105-0.038,0.138
               c-0.023,0.063-0.04,0.099-0.016,0.168c0.023,0.054,0.046,0.106,0.106,0.144c0.061,0.047,0.152,0.078,0.306,0.092
               c0.121,0.023,0.197,0.063,0.234,0.115c0.016,0.008,0.023,0.023,0.023,0.039c0.008,0.006,0.008,0.014,0.008,0.021
               c0,0.009-0.008,0.009-0.008,0.015c-0.015,0.01-0.091,0.01-0.175,0.01c-0.068-0.01-0.146-0.01-0.198,0
               c-0.076,0.015-0.121,0.045-0.106,0.123c0.022,0.09,0.122,0.119,0.229,0.158c0.076,0.023,0.16,0.055,0.198,0.1l0.015,0.015
               c0.046,0.054,0.106,0.123,0.138,0.185c0.03,0.053,0.037,0.09,0.007,0.106c-0.053,0.028-0.099,0.073-0.121,0.112h-0.009
               c-0.015,0.031-0.029,0.062-0.029,0.101c-0.008,0.029,0,0.067,0.015,0.099c0.023,0.029,0.046,0.055,0.076,0.076l0.016,0.008
               c0.038,0.029,0.068,0.045,0.091,0.061c0.03,0.031,0.037,0.046,0.023,0.068c-0.053,0.062,0.014,0.184,0.091,0.313
               c0.062,0.114,0.138,0.243,0.122,0.307c-0.016,0.051-0.053,0.09-0.091,0.129c-0.062,0.076-0.108,0.138-0.062,0.228
               c0.022,0.039,0.054,0.069,0.075,0.108c0.055,0.052,0.101,0.113,0.069,0.149c-0.023,0.022-0.038,0.039-0.061,0.063
               c-0.068,0.068-0.113,0.119-0.113,0.25c0,0.13,0.104,0.244,0.182,0.318c0.023,0.03,0.046,0.055,0.061,0.069l0.016,0.022
               c0.038,0.055,0.084,0.121,0.022,0.189c-0.068,0.077-0.038,0.152-0.008,0.229c0.031,0.063,0.062,0.131,0.008,0.19
               c-0.03,0.031-0.061,0.067-0.092,0.101c-0.113,0.129-0.213,0.234-0.182,0.381c0.015,0.045,0.015,0.099,0.015,0.15
               c0,0.105,0,0.205,0.083,0.297c0.024,0.031,0.046,0.055,0.069,0.076c0.099,0.106,0.213,0.229,0.266,0.365
               c0.016,0.029,0.024,0.057,0.031,0.068c0.062,0.16,0.092,0.236,0.252,0.281c0.099,0.03,0.183-0.015,0.266-0.061
               c0.054-0.029,0.114-0.061,0.153-0.029c0.015,0.006,0.038,0.021,0.061,0.036c0.099,0.062,0.196,0.123,0.28,0.358
               c0.038,0.091,0.055,0.184,0.077,0.252c0.038,0.166,0.062,0.275,0.19,0.318c0.054,0.016,0.084,0.047,0.114,0.084
               c0.061,0.063,0.122,0.129,0.289,0.152c0.076,0.016,0.168,0.03,0.26,0.055c0.205,0.045,0.434,0.098,0.646,0.074
               c0.128-0.016,0.183-0.045,0.229-0.068c0.038-0.016,0.061-0.029,0.221,0.023c0.152,0.045,0.304,0.092,0.442,0.121
               C66.073,121.884,66.174,121.916,66.219,121.93"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M66.219,121.93c-0.007,0.015-0.015,0.022-0.022,0.045c-0.022,0.185,0,0.28,0.259,0.289c0.1,0,0.176-0.015,0.229-0.03
               c0.008,0.016,0.023,0.037,0.031,0.063c0.053,0.082,0.099,0.183,0.129,0.234c0.021,0.055,0.045,0.114,0.062,0.176
               c0.022,0.068,0.037,0.146,0.075,0.221c0.016,0.031,0.031,0.068,0.053,0.121c0.077,0.207,0.206,0.533,0.389,0.73
               c0.062,0.068,0.114,0.131,0.16,0.189c0.122,0.169,0.213,0.306,0.472,0.314c0.1,0,0.206,0,0.312-0.009
               c0.237-0.007,0.48-0.022,0.679,0.062c0.084,0.031,0.183,0.076,0.289,0.121c-0.099,0.01-0.205,0-0.319,0
               c-0.175,0-0.092,0.068-0.191,0.152c-0.09,0.084-0.235,0-0.41-0.053c-0.168-0.055-0.556-0.076-0.365,0.053
               c0.19,0.139,0.647,0.29,0.495,0.336c-0.16,0.047-0.237-0.152-0.495-0.129c-0.252,0.021-0.282-0.123-0.381-0.178
               c-0.099-0.051-0.068-0.051-0.19,0.01c-0.114,0.055-0.259,0.076-0.381-0.016c-0.122-0.1-0.015-0.281-0.189-0.352
               c-0.184-0.068-0.32-0.099-0.291,0.023c0.039,0.129,0.251,0.35-0.022,0.236c-0.268-0.115-0.25-0.236-0.418-0.229
               c-0.168,0.008-0.268-0.067-0.366-0.168c-0.106-0.091-0.373-0.052-0.472-0.114c-0.106-0.068-0.084-0.167-0.213-0.212
               c-0.131-0.046,0.258-0.327,0.563-0.175c0.312,0.15,0.357,0.197,0.35,0.037c-0.008-0.167-0.335-0.51-0.151-0.487
               c0.183,0.017,0.25,0.132,0.281,0.252c0.03,0.13,0.396,0.365,0.465,0.267c0.076-0.092-0.335-0.151-0.328-0.364
               c0.016-0.216,0.419-0.282,0.176-0.382c-0.252-0.1-0.321,0.137-0.549-0.031c-0.229-0.158-0.137-0.35-0.023-0.455
               c0.115-0.1,0.251-0.298,0.069-0.318c-0.183-0.018-0.221,0.074-0.472,0.15c-0.252,0.084-0.381,0.189-0.176,0.419
               s0.205,0.272,0.183,0.419c-0.022,0.137,0.114,0.221-0.114,0.221s-0.389-0.043-0.389,0.055c0,0.091,0.106,0.258-0.053,0.281
               c-0.152,0.022-0.419-0.244-0.586-0.307c-0.168-0.061-0.168,0.168-0.32-0.072c-0.152-0.246-0.137-0.346-0.411-0.438
               c-0.267-0.083-1.058-0.486-0.943-0.52c0.121-0.021,0.379,0.024,0.518,0.094c0.13,0.068,0.373,0.272,0.38,0.099
               c0-0.183-0.091-0.122-0.266-0.356c-0.175-0.229-0.107-0.146-0.396-0.313c-0.297-0.167-0.686-0.312-0.441-0.312
               c0.245,0,0.465,0.129,0.441-0.016c-0.03-0.146-0.151-0.152-0.289-0.184c-0.137-0.039-0.152-0.045-0.145-0.205
               c0.015-0.16-0.008-0.151-0.145-0.206c-0.426-0.183-0.381-0.015-0.746-0.197c-0.358-0.185-0.062-0.222-0.016-0.374
               c0.046-0.15,0.016-0.175-0.046-0.25c-0.061-0.083-0.114-0.223-0.175-0.139c-0.053,0.092-0.062,0.169-0.114,0.26
               c-0.046,0.092,0.106,0.252-0.091,0.122c-0.206-0.138-0.146-0.373-0.215-0.419c-0.067-0.047-0.174-0.26-0.212-0.352
               c-0.046-0.099-0.267,0.017-0.259-0.174c0.014-0.184,0.091-0.184-0.023-0.298c-0.122-0.112-0.343-0.259-0.205-0.304
               c0.137-0.047,0.197,0.021,0.289,0c0.092-0.016,0.236-0.123,0.099-0.153c-0.137-0.022-0.267-0.017-0.373-0.007
               c-0.113,0.007-0.197-0.016-0.197-0.113c-0.008-0.1-0.023-0.207-0.062-0.268c-0.037-0.053-0.045-0.221,0.084-0.229
               c0.076,0,0.259-0.412,0.396,0.037c0.068,0.221,0.236,0.235,0.297,0.313c0.068,0.068,0.023,0.115,0.123,0.16
               c0.091,0.045,0.211,0.084,0.159-0.037c-0.046-0.123-0.099-0.16-0.183-0.223c-0.092-0.066-0.229-0.066-0.259-0.189
               c-0.022-0.122,0.092-0.145-0.016-0.189c-0.106-0.046-0.243-0.068-0.243-0.137c-0.008-0.077,0.03-0.184,0.062-0.245
               c0.037-0.062,0.015-0.099,0.015-0.099s-0.03-0.106,0.022-0.129c0.062-0.031,0.151-0.069,0.145-0.121
               c-0.015-0.055-0.175-0.032-0.259-0.123c-0.084-0.092-0.152-0.1-0.229-0.092c-0.076,0.015-0.114,0.022-0.183-0.008
               c-0.061-0.029-0.273-0.221-0.404-0.146c-0.121,0.075,0.069,0.114,0.017,0.206c-0.061,0.1-0.168,0.16-0.222,0.016
               c-0.046-0.146-0.137-0.259-0.061-0.328c0.076-0.074,0.16-0.121,0.198-0.221c0.046-0.1,0.008-0.105-0.031-0.205
               c-0.037-0.1-0.175-0.242-0.045-0.268c0.129-0.014,0.206,0.008,0.213-0.092c0.008-0.09,0.038-0.099,0.062-0.168
               c0.022-0.06-0.031-0.021-0.047-0.144c-0.015-0.122-0.045-0.252,0.062-0.19c0.106,0.062,0.076,0.215,0.152,0.297
               c0.084,0.084,0.122,0.076,0.13,0.19c0.015,0.106,0.053,0.138,0.175,0.212c0.114,0.084,0.221,0.161,0.29,0.262
               c0.066,0.09,0.021,0.312,0.105,0.303c0.092-0.008,0.236-0.168,0.106-0.303c-0.129-0.139-0.068-0.224-0.159-0.313
               c-0.1-0.099-0.327,0-0.396-0.151c-0.076-0.144,0.054-0.121-0.038-0.244c-0.091-0.121-0.167-0.09-0.236-0.213
               c-0.06-0.121-0.183-0.084-0.159-0.176c0.016-0.092,0.008-0.167-0.053-0.167c-0.069,0-0.13,0.054-0.206,0.009
               c-0.068-0.045-0.13-0.099-0.205-0.068c-0.084,0.029-0.123,0.107-0.16,0.037c-0.038-0.066,0.037-0.168,0.167-0.19
               c0.13-0.022,0.145-0.121,0.214-0.128c0.075-0.01,0.128,0.029,0.243,0.053c0.114,0.016,0.229,0.023,0.313,0.092
               c0.083,0.068,0.045,0.145,0.037,0.229c-0.008,0.084,0.054,0.213,0.1,0.251c0.046,0.046,0.114,0.13,0.099,0.229
               c-0.022,0.1-0.016,0.246,0.084,0.213c0.099-0.021,0.099-0.184,0.13-0.268c0.037-0.083-0.107-0.158-0.152-0.297
               c-0.054-0.137-0.16-0.313-0.106-0.373c0.053-0.068,0.151-0.145,0.061-0.205c-0.092-0.068-0.152-0.084-0.122-0.145
               c0.03-0.055,0.084-0.313,0.022-0.396c-0.068-0.084-0.228-0.357-0.175-0.464c0.061-0.106,0.175-0.185,0.068-0.267
               c-0.107-0.084-0.213-0.207-0.175-0.389c0.031-0.176-0.145-0.168-0.122-0.25c0.023-0.078,0.206-0.078,0.145-0.177
               c-0.053-0.091-0.273,0.009-0.273-0.138c0-0.143-0.068-0.365-0.244-0.319c-0.167,0.045,0.062,0.183-0.106,0.313
               c-0.175,0.129-0.106,0.175-0.045,0.242c0.068,0.068,0.121,0.198,0.037,0.244c-0.083,0.053-0.146,0.137-0.037,0.235
               c0.106,0.092,0.221,0.122,0.235,0.26c0.008,0.13-0.038,0.159-0.015,0.251c0.015,0.092-0.055,0.131-0.152,0.131
               c-0.107,0-0.389,0.084-0.366-0.215c0.023-0.297,0.046-0.318-0.054-0.502c-0.098-0.184-0.113-0.222-0.152-0.396
               c-0.037-0.182-0.007-0.319,0.069-0.402c0.068-0.084,0.038-0.168-0.069-0.273c-0.106-0.107-0.312-0.313-0.304-0.412
               c0.015-0.099,0.099-0.344,0.046-0.738c-0.054-0.387,0.234-0.434,0.067-0.707c-0.168-0.268-0.258-0.428-0.38-0.624
               c-0.13-0.206-0.205-0.572-0.289-0.731c-0.084-0.166-0.229-0.068-0.252-0.273c-0.03-0.205-0.159-0.449-0.03-0.479
               c0.13-0.022,0.183,0.177,0.251,0c0.076-0.183-0.068-0.305-0.015-0.45c0.053-0.136,0.145-0.228,0.106-0.425
               c-0.031-0.198-0.084-0.243-0.068-0.321c0.015-0.074-0.084-0.172,0.037-0.342c0.123-0.16,0.161-0.365,0.161-0.479
               c0-0.115,0.053-0.223,0-0.404c-0.046-0.188-0.063-0.363-0.038-0.473c0.022-0.098,0.143-0.44,0.084-0.623
               c-0.069-0.176-0.13-0.176-0.062-0.457c0.068-0.289,0.068-0.381-0.062-0.578c-0.129-0.198-0.152-0.244-0.182-0.436
               c-0.023-0.188-0.275-0.556-0.365-0.836c-0.092-0.283-0.176-0.535-0.062-0.642c0.121-0.099,0.213-0.159,0.16-0.334
               c-0.054-0.176-0.183-0.358-0.259-0.569c-0.076-0.216-0.039-0.313,0.014-0.451c0.055-0.139,0.047-0.258,0.039-0.336
               c-0.016-0.076-0.092-0.494,0.008-0.713c0.1-0.23,0.159-0.177,0.083-0.39c-0.083-0.222-0.144-0.175-0.067-0.343
               c0.084-0.168,0.067-0.282,0.067-0.411c-0.007-0.138-0.121-0.39-0.121-0.771s0.183-0.518,0.022-0.82
               c-0.159-0.307-0.258-0.329-0.229-0.581c0.038-0.257-0.266-0.623-0.121-0.89c0.145-0.273,0.19-0.283,0.234-0.686
               c0.048-0.404-0.067-1.037-0.113-1.195c-0.037-0.16,0.016-0.359-0.054-0.617c-0.067-0.26-0.189-0.379-0.121-0.746
               c0.062-0.342-0.214-0.937-0.336-1.172c0.077-0.029,0.153-0.063,0.206-0.092c0.152-0.084,0.146-0.176,0.146-0.273
               c0-0.039,0-0.092,0.021-0.129c0.039-0.078,0.063-0.152,0.077-0.244c0.015-0.086,0.031-0.176,0.038-0.289
               c0.008-0.023,0.008-0.047,0.016-0.063c0.037,0.047,0.099,0.114,0.106,0.131c0.007,0.016,0.022,0.053,0.038,0.113
               s0.038,0.129,0.06,0.204h0.009c0.022,0.078,0.054,0.115,0.083,0.146c0.038,0.045,0.084,0.099,0.13,0.267
               c0.046,0.167,0.145,0.319,0.229,0.457c0.068,0.112,0.13,0.222,0.152,0.313c0.046,0.166,0.13,0.188,0.229,0.213
               c0.038,0.015,0.083,0.021,0.122,0.053c0.045,0.023,0.015,0.076-0.023,0.138c-0.038,0.06-0.076,0.121-0.068,0.188v0.023
               c0.014,0.105,0.014,0.16-0.061,0.244c-0.068,0.066-0.084,0.129-0.046,0.212c0.037,0.063,0.099,0.13,0.198,0.229
               c0.084,0.076,0.084,0.121,0.084,0.166c0,0.07,0,0.132,0.137,0.244c0.159,0.121,0.213,0.373,0.281,0.662
               c0.023,0.092,0.045,0.19,0.068,0.291c0.068,0.228,0.138,0.342,0.206,0.447c0.054,0.094,0.105,0.182,0.16,0.327
               c0.054,0.168,0.137,0.198,0.235,0.168c0.077-0.022,0.152-0.099,0.222-0.183c0.061,0.076,0.167,0.205,0.167,0.273
               c0.008,0.037-0.007,0.076-0.016,0.121c-0.016,0.063-0.03,0.131-0.016,0.229c0.016,0.1,0.024,0.25,0,0.389
               c-0.007,0.084-0.029,0.152-0.067,0.189c-0.039,0.03-0.114,0.063-0.198,0.084c-0.137,0.045-0.297,0.1-0.434,0.229
               c-0.122,0.105-0.198,0.215-0.236,0.313c-0.03,0.106-0.023,0.205,0.03,0.297c0.046,0.068,0.068,0.123,0.068,0.168
               c0,0.029-0.007,0.061-0.022,0.082c-0.054,0.076-0.022,0.16,0.022,0.274c0.016,0.038,0.03,0.083,0.046,0.122l0.008,0.016
               c0.046,0.145,0.068,0.213,0.099,0.403c0.008,0.054-0.015,0.062-0.03,0.069c-0.068,0.035-0.122,0.068-0.038,0.242
               c0.054,0.115,0.13,0.189,0.19,0.252c0.061,0.061,0.106,0.104,0.076,0.16c-0.022,0.053-0.061,0.066-0.099,0.076
               c-0.046,0.016-0.107,0.029-0.168,0.113c-0.068,0.084-0.076,0.16-0.084,0.234c-0.007,0.063-0.016,0.115-0.054,0.184
               c-0.037,0.068-0.061,0.146-0.083,0.229c-0.03,0.1-0.062,0.213-0.137,0.35c-0.076,0.139-0.138,0.244-0.176,0.357
               c-0.038,0.105-0.053,0.223-0.022,0.336c0.03,0.129,0.008,0.229-0.009,0.313c-0.015,0.055-0.029,0.105-0.029,0.16
               c0,0.1,0.054,0.213,0.129,0.365c0.031,0.076,0.076,0.158,0.115,0.252c0.067,0.166,0.015,0.242-0.054,0.342
               c-0.038,0.063-0.084,0.129-0.114,0.213c-0.068,0.176-0.047,0.32-0.022,0.449c0.007,0.054,0.022,0.113,0.015,0.16
               c-0.008,0.092,0.023,0.267,0.076,0.449c0.046,0.145,0.106,0.297,0.19,0.426c0.084,0.131,0.121,0.244,0.152,0.357
               c0.038,0.114,0.076,0.229,0.167,0.344c0.069,0.092,0.153,0.16,0.222,0.221c0.091,0.092,0.175,0.16,0.175,0.221
               c0,0.076,0.038,0.113,0.076,0.153c0.054,0.053,0.113,0.104,0.061,0.317c-0.038,0.139-0.084,0.223-0.121,0.299
               c-0.069,0.137-0.13,0.25-0.122,0.524l0.007,0.084c0.008,0.388,0.016,0.587,0.106,0.654c0.039,0.022,0.099,0.075,0.146,0.14
               c0.037,0.059,0.053,0.135,0.008,0.227c-0.03,0.054-0.069,0.113-0.099,0.175c-0.1,0.161-0.198,0.319-0.198,0.474
               c0,0.159,0.037,0.288,0.091,0.486c0.016,0.037,0.03,0.091,0.046,0.146c0.016,0.06,0.03,0.104,0.038,0.158
               c0.067,0.26,0.145,0.541,0.297,0.717c0.076,0.084,0.16,0.137,0.221,0.174c0.03,0.016,0.054,0.031,0.076,0.047
               c0.03,0.021,0.046,0.053,0.008,0.099c-0.038,0.054-0.106,0.054-0.168,0.062c-0.03,0-0.061,0.009-0.092,0.009
               c-0.099,0.028-0.159,0.091-0.099,0.327c0.039,0.145,0.076,0.25,0.099,0.334c0.055,0.138,0.077,0.205,0.023,0.298
               c-0.099,0.146-0.046,0.305,0,0.418c0.008,0.03,0.022,0.063,0.022,0.077c0,0.008,0,0.021,0.009,0.054
               c0.015,0.212,0.083,0.937,0.295,1.209c0.063,0.084,0.131,0.161,0.191,0.222c0.054,0.055,0.092,0.1,0.13,0.137
               c0.046,0.07,0.053,0.115,0,0.154c-0.1,0.074-0.16,0.166-0.183,0.266c-0.016,0.099,0.007,0.199,0.083,0.298l0.038,0.052
               c0.122,0.161,0.197,0.268,0.351,0.282c0.015,0,0.038,0,0.054,0.008c0.038,0.009,0.061,0.022,0.084,0.039
               c0.007,0.017,0.015,0.037,0.015,0.06v0.024c-0.03,0.112,0,0.149,0.076,0.228c0.016,0.016,0.03,0.029,0.053,0.055
               c0.039,0.045,0.076,0.06,0.107,0.074c0.03,0.016,0.045,0.024,0.038,0.092c-0.008,0.063-0.03,0.105-0.038,0.138
               c-0.023,0.063-0.04,0.099-0.016,0.168c0.023,0.054,0.046,0.106,0.106,0.144c0.061,0.047,0.152,0.078,0.306,0.092
               c0.121,0.023,0.197,0.063,0.234,0.115c0.016,0.008,0.023,0.023,0.023,0.039c0.008,0.006,0.008,0.014,0.008,0.021
               c0,0.009-0.008,0.009-0.008,0.015c-0.015,0.01-0.091,0.01-0.175,0.01c-0.068-0.01-0.146-0.01-0.198,0
               c-0.076,0.015-0.121,0.045-0.106,0.123c0.022,0.09,0.122,0.119,0.229,0.158c0.076,0.023,0.16,0.055,0.198,0.1l0.015,0.015
               c0.046,0.054,0.106,0.123,0.138,0.185c0.03,0.053,0.037,0.09,0.007,0.106c-0.053,0.028-0.099,0.073-0.121,0.112h-0.009
               c-0.015,0.031-0.029,0.062-0.029,0.101c-0.008,0.029,0,0.067,0.015,0.099c0.023,0.029,0.046,0.055,0.076,0.076l0.016,0.008
               c0.038,0.029,0.068,0.045,0.091,0.061c0.03,0.031,0.037,0.046,0.023,0.068c-0.053,0.062,0.014,0.184,0.091,0.313
               c0.062,0.114,0.138,0.243,0.122,0.307c-0.016,0.051-0.053,0.09-0.091,0.129c-0.062,0.076-0.108,0.138-0.062,0.228
               c0.022,0.039,0.054,0.069,0.075,0.108c0.055,0.052,0.101,0.113,0.069,0.149c-0.023,0.022-0.038,0.039-0.061,0.063
               c-0.068,0.068-0.113,0.119-0.113,0.25c0,0.13,0.104,0.244,0.182,0.318c0.023,0.03,0.046,0.055,0.061,0.069l0.016,0.022
               c0.038,0.055,0.084,0.121,0.022,0.189c-0.068,0.077-0.038,0.152-0.008,0.229c0.031,0.063,0.062,0.131,0.008,0.19
               c-0.03,0.031-0.061,0.067-0.092,0.101c-0.113,0.129-0.213,0.234-0.182,0.381c0.015,0.045,0.015,0.099,0.015,0.15
               c0,0.105,0,0.205,0.083,0.297c0.024,0.031,0.046,0.055,0.069,0.076c0.099,0.106,0.213,0.229,0.266,0.365
               c0.016,0.029,0.024,0.057,0.031,0.068c0.062,0.16,0.092,0.236,0.252,0.281c0.099,0.03,0.183-0.015,0.266-0.061
               c0.054-0.029,0.114-0.061,0.153-0.029c0.015,0.006,0.038,0.021,0.061,0.036c0.099,0.062,0.196,0.123,0.28,0.358
               c0.038,0.091,0.055,0.184,0.077,0.252c0.038,0.166,0.062,0.275,0.19,0.318c0.054,0.016,0.084,0.047,0.114,0.084
               c0.061,0.063,0.122,0.129,0.289,0.152c0.076,0.016,0.168,0.03,0.26,0.055c0.205,0.045,0.434,0.098,0.646,0.074
               c0.128-0.016,0.183-0.045,0.229-0.068c0.038-0.016,0.061-0.029,0.221,0.023c0.152,0.045,0.304,0.092,0.442,0.121
               C66.073,121.884,66.174,121.916,66.219,121.93z"/>
             <path fill="#808184" d="M68.595,105.337c-0.107,0.168-0.152,0.449,0.19,0.541
               c0.343,0.099,0.594,0.174,0.784,0.343c0.184,0.176,0.358,0.146,0.243,0.351c-0.121,0.213-0.152,0.563,0.152,0.67
               c0.297,0.106,0.343-0.052,0.411,0.146c0.076,0.197,0.023,0.35,0.062,0.473c0.038,0.113,0.106,0.083-0.022,0.28
               c-0.13,0.19-0.237,0.381-0.383,0.7c-0.136,0.32-0.196,0.502-0.463,0.586s-1.082,0.32-1.416,0.344
               c-0.329,0.029-0.921-0.037-1.065-0.061c-0.146-0.023-0.435-0.198-0.412,0.029c0.031,0.229,0.273,0.297,0.297,0.455
               c0.016,0.153-0.067,0.527,0.039,0.785c0.099,0.26,0.228,0.305,0.205,0.473c-0.022,0.168-0.435,0.373-0.692,0.412
               c-0.26,0.037-1.097-0.519-1.28-0.428c-0.182,0.092-0.273,0.281-0.061,0.495c0.205,0.213,0.244,0.304,0.274,0.464
               c0.03,0.168,0.205,0.329,0.388,0.381c0.183,0.047,0.206,0.008,0.289,0.105c0.077,0.093,0.168,0.115,0.26-0.037
               c0.098-0.145,0.13-0.176,0.213-0.131c0.084,0.048,0.389,0.392,0.214,0.535c-0.184,0.145-0.343,0.145-0.563-0.016
               c-0.222-0.152-0.427-0.176-0.473-0.084c-0.053,0.1,0.647,0.212,0.525,0.41c-0.121,0.207-0.274,0.113-0.358,0.357
               c-0.083,0.252-0.03,0.519,0.046,0.609c0.075,0.1,0.289,0.251,0.13,0.327c-0.167,0.076-0.176,0.168-0.106,0.343
               c0.068,0.176,0.13,0.221-0.107,0.281c-0.235,0.063-0.578-0.152-0.685,0.137c-0.106,0.282-0.351,0.436-0.281,0.68
               c0.068,0.25,0.738,0.922,1.065,0.928c0.328,0.01,0.549-0.122,0.647,0.084c0.099,0.207,0.244,0.214,0.236,0.428
               c-0.009,0.213-0.038,0.242-0.076,0.426c-0.031,0.189-0.534,0.381-0.625,0.709c-0.099,0.325-0.129,0.409-0.045,0.654
               c0.075,0.244,0.145,0.395-0.084,0.494c-0.236,0.098-0.519,0.273-0.381,0.641c0.145,0.365,0.213,0.662,0.555,0.875
               c0.336,0.213,0.572,0.252,0.496,0.342c-0.046,0.063-0.236,0.016-0.381,0.016c-0.022-0.016-0.038-0.021-0.054-0.029v-0.008
               c-0.038-0.021-0.16-0.055-0.319-0.1c-0.13-0.03-0.289-0.075-0.427-0.121c-0.205-0.068-0.243-0.045-0.313-0.01
               c-0.029,0.018-0.075,0.039-0.182,0.056c-0.198,0.015-0.419-0.029-0.625-0.076c-0.092-0.022-0.183-0.045-0.259-0.054
               c-0.137-0.022-0.183-0.075-0.229-0.123c-0.037-0.045-0.075-0.09-0.159-0.113c-0.075-0.021-0.092-0.105-0.121-0.242
               c-0.023-0.076-0.046-0.16-0.077-0.267c-0.1-0.269-0.213-0.337-0.327-0.412c-0.023-0.009-0.046-0.022-0.061-0.03
               c-0.092-0.063-0.168-0.021-0.259,0.03c-0.061,0.03-0.122,0.069-0.183,0.046c-0.114-0.031-0.138-0.091-0.19-0.214
               c-0.008-0.029-0.016-0.054-0.023-0.074c-0.061-0.161-0.189-0.289-0.289-0.397c-0.022-0.021-0.045-0.053-0.068-0.074
               c-0.061-0.063-0.061-0.146-0.061-0.229c0-0.054,0-0.113-0.016-0.177c-0.015-0.091,0.068-0.183,0.16-0.287
               c0.03-0.038,0.061-0.069,0.09-0.108c0.093-0.104,0.055-0.205,0.017-0.289c-0.022-0.053-0.038-0.092-0.017-0.121
               c0.115-0.129,0.047-0.236-0.014-0.32l-0.016-0.021v0.008c-0.015-0.03-0.046-0.053-0.068-0.084
               c-0.069-0.068-0.16-0.166-0.16-0.25c0-0.092,0.038-0.131,0.091-0.184c0.017-0.015,0.039-0.039,0.062-0.068
               c0.084-0.1,0.008-0.19-0.062-0.281c-0.029-0.029-0.053-0.063-0.067-0.084c-0.016-0.029,0.015-0.068,0.053-0.113
               c0.038-0.048,0.076-0.1,0.099-0.169c0.031-0.099-0.053-0.251-0.129-0.388c-0.053-0.1-0.106-0.189-0.099-0.198
               c0.068-0.084,0.046-0.138-0.038-0.206c-0.024-0.021-0.054-0.037-0.091-0.066l-0.023-0.008c-0.016-0.018-0.031-0.031-0.038-0.039
               c-0.008-0.017-0.008-0.031-0.008-0.045c0-0.017,0.008-0.031,0.022-0.047c0.016-0.029,0.054-0.061,0.092-0.084
               c0.099-0.053,0.084-0.145,0.03-0.242c-0.038-0.07-0.106-0.146-0.152-0.207l-0.015-0.016c-0.062-0.068-0.153-0.098-0.244-0.129
               c-0.076-0.021-0.152-0.053-0.16-0.084c0-0.008,0.016,0.008,0.023,0c0.045-0.008,0.114,0,0.183,0
               c0.099,0,0.198,0.008,0.243-0.027c0.023-0.018,0.031-0.047,0.038-0.08c0-0.021,0-0.045-0.007-0.074
               c-0.008-0.022-0.023-0.045-0.046-0.067c-0.046-0.062-0.145-0.123-0.297-0.146c-0.138-0.016-0.214-0.045-0.26-0.078
               c-0.037-0.021-0.053-0.051-0.068-0.09c-0.016-0.039-0.007-0.061,0.008-0.1c0.016-0.038,0.038-0.092,0.046-0.168
               c0.015-0.137-0.022-0.152-0.092-0.189c-0.022-0.008-0.053-0.022-0.076-0.054c-0.022-0.022-0.037-0.046-0.053-0.062
               c-0.054-0.045-0.068-0.066-0.054-0.129v-0.039c0.008-0.053-0.007-0.098-0.045-0.137c-0.031-0.029-0.068-0.054-0.123-0.068
               c-0.022-0.008-0.052-0.008-0.075-0.016c-0.106-0.007-0.175-0.099-0.274-0.234l-0.038-0.055
               c-0.061-0.076-0.076-0.146-0.068-0.213c0.016-0.076,0.068-0.146,0.145-0.205c0.121-0.093,0.099-0.183,0.015-0.298
               c-0.029-0.038-0.075-0.092-0.128-0.146c-0.062-0.061-0.123-0.137-0.191-0.213c-0.19-0.252-0.259-0.951-0.273-1.157
               c0-0.03-0.008-0.046-0.008-0.06c0-0.024-0.016-0.063-0.03-0.102c-0.031-0.092-0.076-0.221-0.008-0.326
               c0.083-0.13,0.054-0.214-0.009-0.389c-0.029-0.077-0.067-0.184-0.099-0.327c-0.037-0.146-0.015-0.183,0.023-0.198
               c0.022,0,0.046,0,0.075-0.008c0.084,0,0.176-0.008,0.237-0.1c0.091-0.113,0.061-0.174-0.023-0.243
               c-0.022-0.015-0.053-0.03-0.083-0.046c-0.062-0.037-0.138-0.084-0.198-0.16c-0.138-0.15-0.206-0.426-0.274-0.669
               c-0.015-0.063-0.022-0.115-0.038-0.161c-0.015-0.053-0.03-0.105-0.046-0.15c-0.053-0.184-0.091-0.307-0.091-0.457
               c0-0.123,0.091-0.273,0.183-0.418c0.038-0.063,0.076-0.123,0.106-0.185c0.069-0.13,0.038-0.235-0.015-0.327
               c-0.054-0.076-0.131-0.139-0.168-0.168c-0.053-0.038-0.062-0.221-0.068-0.57v-0.084c-0.008-0.25,0.046-0.351,0.105-0.479
               c0.039-0.077,0.084-0.168,0.131-0.319c0.068-0.269-0.016-0.351-0.084-0.42c-0.03-0.022-0.054-0.045-0.054-0.075
               c0-0.107-0.092-0.191-0.205-0.298c-0.068-0.053-0.145-0.12-0.214-0.204c-0.076-0.101-0.106-0.205-0.145-0.313
               c-0.037-0.113-0.068-0.236-0.167-0.381c-0.076-0.123-0.139-0.267-0.175-0.404c-0.054-0.176-0.077-0.326-0.069-0.41
               c0-0.063-0.008-0.122-0.022-0.189c-0.016-0.113-0.039-0.244,0.015-0.39c0.03-0.075,0.069-0.138,0.107-0.188
               c0.084-0.123,0.152-0.223,0.061-0.442c-0.038-0.106-0.084-0.188-0.114-0.259c-0.068-0.137-0.114-0.242-0.114-0.319
               c0-0.047,0.007-0.084,0.022-0.14c0.023-0.098,0.046-0.211,0.008-0.355c-0.023-0.099-0.016-0.189,0.016-0.281
               c0.038-0.099,0.099-0.207,0.167-0.336c0.092-0.152,0.122-0.267,0.152-0.373c0.022-0.076,0.038-0.146,0.077-0.211
               c0.045-0.084,0.052-0.154,0.061-0.215c0.008-0.068,0.015-0.121,0.061-0.191c0.046-0.053,0.084-0.066,0.122-0.073
               c0.061-0.017,0.113-0.039,0.159-0.14c0.062-0.112-0.007-0.183-0.099-0.273c-0.053-0.053-0.122-0.113-0.168-0.221
               c-0.045-0.084-0.03-0.098-0.007-0.105c0.053-0.021,0.106-0.055,0.084-0.184c-0.031-0.189-0.054-0.26-0.107-0.411v-0.015
               c-0.015-0.045-0.03-0.092-0.045-0.129c-0.038-0.084-0.061-0.146-0.046-0.177c0.037-0.036,0.054-0.092,0.046-0.153
               c0-0.06-0.031-0.129-0.076-0.213c-0.046-0.06-0.046-0.136-0.023-0.213c0.03-0.082,0.1-0.174,0.198-0.267
               c0.13-0.113,0.281-0.159,0.404-0.205c0.099-0.028,0.175-0.062,0.234-0.106c0.062-0.054,0.092-0.145,0.107-0.252
               c0.016-0.145,0.008-0.312-0.008-0.41c-0.008-0.092,0-0.145,0.016-0.199c0.015-0.045,0.023-0.09,0.023-0.15
               c-0.009-0.105-0.146-0.268-0.214-0.343c0.016-0.022,0.03-0.045,0.038-0.06h0.008c0.061-0.092,0.137-0.162,0.212-0.23
               c0.063-0.053,0.115-0.1,0.153-0.15c0.053-0.063,0.076-0.115,0.092-0.152c0.015-0.029,0.029-0.055,0.045-0.068
               c0.016-0.008,0.054,0.007,0.106,0.029c0.084,0.031,0.183,0.092,0.251,0.146c0.092,0.061,0.29,0.061,0.479,0.061
               c0.131,0,0.26,0,0.336,0.023c0.084,0.021,0.145,0.098,0.205,0.182c0.069,0.084,0.138,0.166,0.252,0.236
               c0.1,0.061,0.166,0.03,0.213-0.046c0.046-0.069,0.062-0.176,0.084-0.274c0.016-0.09,0.03-0.168,0.046-0.188
               c0.068-0.062,0.305-0.038,0.578-0.009l0.099,0.009h0.008c0.243,0.029,0.351,0.138,0.488,0.288
               c0.029,0.038,0.066,0.077,0.106,0.113c0.037,0.037,0.099,0.1,0.167,0.162c0.281,0.272,0.73,0.701,0.792,0.875
               c0.068,0.205,0.396,0.272,0.7,0.334c0.091,0.022,0.183,0.037,0.267,0.063c0.159,0.045,0.274,0.083,0.381,0.145
               c0.106,0.068,0.206,0.16,0.335,0.313c0.197,0.244,0.77,0.396,1.119,0.495c0.129,0.03,0.229,0.062,0.267,0.077
               c0.068,0.029,0.106,0.097,0.114,0.173c0.022,0.084,0.008,0.19-0.008,0.29c-0.009,0.084-0.054,0.152-0.099,0.214
               c-0.062,0.093-0.122,0.183-0.138,0.343c-0.008,0.131-0.022,0.204-0.061,0.273c-0.046,0.068-0.114,0.146-0.229,0.259
               c-0.145,0.146-0.129,0.245-0.061,0.312c0.053,0.063,0.159,0.084,0.236,0.1c0.053,0.01,0.145,0.055,0.251,0.107
               c0.129,0.061,0.289,0.138,0.44,0.184c0.222,0.061,0.397,0,0.572-0.055c0.061-0.021,0.12-0.045,0.182-0.061
               c0.1-0.023,0.184-0.009,0.26,0.008c0.106,0.016,0.205,0.03,0.327-0.031c0.031-0.014,0.06-0.029,0.092-0.045
               c0.167-0.076,0.319-0.152,0.502-0.396c0.054-0.075,0.091-0.129,0.129-0.167c0.084-0.113,0.107-0.139,0.153-0.336
               c0.022-0.137,0.008-0.26-0.008-0.365c-0.017-0.105-0.03-0.205,0-0.289c0.045-0.113,0.129-0.121,0.236-0.129
               c0.046-0.008,0.099-0.008,0.159-0.023h0.022c0.039,0,0.069,0.023,0.093,0.062c0.03,0.038,0.053,0.099,0.068,0.161
               c0.015,0.066,0.022,0.145,0.022,0.212c-0.008,0.13,0.046,0.222,0.114,0.336c0.053,0.091,0.114,0.19,0.144,0.327
               c0.023,0.1-0.068,0.213-0.196,0.336c-0.214,0.189-0.549,0.372-0.693,0.439c-0.237,0.105-0.251,0.152-0.321,0.357
               c-0.006,0.03-0.021,0.068-0.037,0.107c-0.061,0.183-0.152,0.25-0.273,0.35c-0.063,0.055-0.13,0.105-0.206,0.184
               c-0.145,0.146-0.297,0.428-0.419,0.654c-0.076,0.152-0.145,0.274-0.159,0.281c-0.07,0.029-0.1,0.122-0.13,0.229
               c-0.023,0.055-0.038,0.115-0.069,0.16c-0.038,0.076-0.067,0.106-0.091,0.137c-0.022,0.023-0.038,0.039-0.061,0.068
               c-0.031,0.049-0.054,0.123-0.061,0.215c-0.008,0.066-0.008,0.158,0.015,0.25l0.008,0.029c0.038,0.161,0.038,0.176,0.007,0.291
               c-0.015,0.084-0.015,0.15-0.007,0.242c0.007,0.076,0.016,0.169,0.016,0.297c0,0.161-0.009,0.161-0.016,0.168
               c-0.022,0.016-0.038,0.039-0.068,0.1c-0.038,0.092,0.015,0.19,0.075,0.313c0.047,0.1,0.107,0.221,0.123,0.357
               c0.007,0.09,0.015,0.152,0.021,0.188c0,0.024,0.009,0.039,0.009,0.047c0,0-0.016,0.009-0.038,0.03l-0.023,0.016
               c-0.075,0.061-0.092,0.152-0.075,0.288c0.015,0.123,0.061,0.283,0.121,0.479C68.496,105.125,68.549,105.248,68.595,105.337"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M68.595,105.337c-0.107,0.168-0.152,0.449,0.19,0.541c0.343,0.099,0.594,0.174,0.784,0.343c0.184,0.176,0.358,0.146,0.243,0.351
               c-0.121,0.213-0.152,0.563,0.152,0.67c0.297,0.106,0.343-0.052,0.411,0.146c0.076,0.197,0.023,0.35,0.062,0.473
               c0.038,0.113,0.106,0.083-0.022,0.28c-0.13,0.19-0.237,0.381-0.383,0.7c-0.136,0.32-0.196,0.502-0.463,0.586
               s-1.082,0.32-1.416,0.344c-0.329,0.029-0.921-0.037-1.065-0.061c-0.146-0.023-0.435-0.198-0.412,0.029
               c0.031,0.229,0.273,0.297,0.297,0.455c0.016,0.153-0.067,0.527,0.039,0.785c0.099,0.26,0.228,0.305,0.205,0.473
               c-0.022,0.168-0.435,0.373-0.692,0.412c-0.26,0.037-1.097-0.519-1.28-0.428c-0.182,0.092-0.273,0.281-0.061,0.495
               c0.205,0.213,0.244,0.304,0.274,0.464c0.03,0.168,0.205,0.329,0.388,0.381c0.183,0.047,0.206,0.008,0.289,0.105
               c0.077,0.093,0.168,0.115,0.26-0.037c0.098-0.145,0.13-0.176,0.213-0.131c0.084,0.048,0.389,0.392,0.214,0.535
               c-0.184,0.145-0.343,0.145-0.563-0.016c-0.222-0.152-0.427-0.176-0.473-0.084c-0.053,0.1,0.647,0.212,0.525,0.41
               c-0.121,0.207-0.274,0.113-0.358,0.357c-0.083,0.252-0.03,0.519,0.046,0.609c0.075,0.1,0.289,0.251,0.13,0.327
               c-0.167,0.076-0.176,0.168-0.106,0.343c0.068,0.176,0.13,0.221-0.107,0.281c-0.235,0.063-0.578-0.152-0.685,0.137
               c-0.106,0.282-0.351,0.436-0.281,0.68c0.068,0.25,0.738,0.922,1.065,0.928c0.328,0.01,0.549-0.122,0.647,0.084
               c0.099,0.207,0.244,0.214,0.236,0.428c-0.009,0.213-0.038,0.242-0.076,0.426c-0.031,0.189-0.534,0.381-0.625,0.709
               c-0.099,0.325-0.129,0.409-0.045,0.654c0.075,0.244,0.145,0.395-0.084,0.494c-0.236,0.098-0.519,0.273-0.381,0.641
               c0.145,0.365,0.213,0.662,0.555,0.875c0.336,0.213,0.572,0.252,0.496,0.342c-0.046,0.063-0.236,0.016-0.381,0.016
               c-0.022-0.016-0.038-0.021-0.054-0.029v-0.008c-0.038-0.021-0.16-0.055-0.319-0.1c-0.13-0.03-0.289-0.075-0.427-0.121
               c-0.205-0.068-0.243-0.045-0.313-0.01c-0.029,0.018-0.075,0.039-0.182,0.056c-0.198,0.015-0.419-0.029-0.625-0.076
               c-0.092-0.022-0.183-0.045-0.259-0.054c-0.137-0.022-0.183-0.075-0.229-0.123c-0.037-0.045-0.075-0.09-0.159-0.113
               c-0.075-0.021-0.092-0.105-0.121-0.242c-0.023-0.076-0.046-0.16-0.077-0.267c-0.1-0.269-0.213-0.337-0.327-0.412
               c-0.023-0.009-0.046-0.022-0.061-0.03c-0.092-0.063-0.168-0.021-0.259,0.03c-0.061,0.03-0.122,0.069-0.183,0.046
               c-0.114-0.031-0.138-0.091-0.19-0.214c-0.008-0.029-0.016-0.054-0.023-0.074c-0.061-0.161-0.189-0.289-0.289-0.397
               c-0.022-0.021-0.045-0.053-0.068-0.074c-0.061-0.063-0.061-0.146-0.061-0.229c0-0.054,0-0.113-0.016-0.177
               c-0.015-0.091,0.068-0.183,0.16-0.287c0.03-0.038,0.061-0.069,0.09-0.108c0.093-0.104,0.055-0.205,0.017-0.289
               c-0.022-0.053-0.038-0.092-0.017-0.121c0.115-0.129,0.047-0.236-0.014-0.32l-0.016-0.021v0.008
               c-0.015-0.03-0.046-0.053-0.068-0.084c-0.069-0.068-0.16-0.166-0.16-0.25c0-0.092,0.038-0.131,0.091-0.184
               c0.017-0.015,0.039-0.039,0.062-0.068c0.084-0.1,0.008-0.19-0.062-0.281c-0.029-0.029-0.053-0.063-0.067-0.084
               c-0.016-0.029,0.015-0.068,0.053-0.113c0.038-0.048,0.076-0.1,0.099-0.169c0.031-0.099-0.053-0.251-0.129-0.388
               c-0.053-0.1-0.106-0.189-0.099-0.198c0.068-0.084,0.046-0.138-0.038-0.206c-0.024-0.021-0.054-0.037-0.091-0.066l-0.023-0.008
               c-0.016-0.018-0.031-0.031-0.038-0.039c-0.008-0.017-0.008-0.031-0.008-0.045c0-0.017,0.008-0.031,0.022-0.047
               c0.016-0.029,0.054-0.061,0.092-0.084c0.099-0.053,0.084-0.145,0.03-0.242c-0.038-0.07-0.106-0.146-0.152-0.207l-0.015-0.016
               c-0.062-0.068-0.153-0.098-0.244-0.129c-0.076-0.021-0.152-0.053-0.16-0.084c0-0.008,0.016,0.008,0.023,0
               c0.045-0.008,0.114,0,0.183,0c0.099,0,0.198,0.008,0.243-0.027c0.023-0.018,0.031-0.047,0.038-0.08c0-0.021,0-0.045-0.007-0.074
               c-0.008-0.022-0.023-0.045-0.046-0.067c-0.046-0.062-0.145-0.123-0.297-0.146c-0.138-0.016-0.214-0.045-0.26-0.078
               c-0.037-0.021-0.053-0.051-0.068-0.09c-0.016-0.039-0.007-0.061,0.008-0.1c0.016-0.038,0.038-0.092,0.046-0.168
               c0.015-0.137-0.022-0.152-0.092-0.189c-0.022-0.008-0.053-0.022-0.076-0.054c-0.022-0.022-0.037-0.046-0.053-0.062
               c-0.054-0.045-0.068-0.066-0.054-0.129v-0.039c0.008-0.053-0.007-0.098-0.045-0.137c-0.031-0.029-0.068-0.054-0.123-0.068
               c-0.022-0.008-0.052-0.008-0.075-0.016c-0.106-0.007-0.175-0.099-0.274-0.234l-0.038-0.055
               c-0.061-0.076-0.076-0.146-0.068-0.213c0.016-0.076,0.068-0.146,0.145-0.205c0.121-0.093,0.099-0.183,0.015-0.298
               c-0.029-0.038-0.075-0.092-0.128-0.146c-0.062-0.061-0.123-0.137-0.191-0.213c-0.19-0.252-0.259-0.951-0.273-1.157
               c0-0.03-0.008-0.046-0.008-0.06c0-0.024-0.016-0.063-0.03-0.102c-0.031-0.092-0.076-0.221-0.008-0.326
               c0.083-0.13,0.054-0.214-0.009-0.389c-0.029-0.077-0.067-0.184-0.099-0.327c-0.037-0.146-0.015-0.183,0.023-0.198
               c0.022,0,0.046,0,0.075-0.008c0.084,0,0.176-0.008,0.237-0.1c0.091-0.113,0.061-0.174-0.023-0.243
               c-0.022-0.015-0.053-0.03-0.083-0.046c-0.062-0.037-0.138-0.084-0.198-0.16c-0.138-0.15-0.206-0.426-0.274-0.669
               c-0.015-0.063-0.022-0.115-0.038-0.161c-0.015-0.053-0.03-0.105-0.046-0.15c-0.053-0.184-0.091-0.307-0.091-0.457
               c0-0.123,0.091-0.273,0.183-0.418c0.038-0.063,0.076-0.123,0.106-0.185c0.069-0.13,0.038-0.235-0.015-0.327
               c-0.054-0.076-0.131-0.139-0.168-0.168c-0.053-0.038-0.062-0.221-0.068-0.57v-0.084c-0.008-0.25,0.046-0.351,0.105-0.479
               c0.039-0.077,0.084-0.168,0.131-0.319c0.068-0.269-0.016-0.351-0.084-0.42c-0.03-0.022-0.054-0.045-0.054-0.075
               c0-0.107-0.092-0.191-0.205-0.298c-0.068-0.053-0.145-0.12-0.214-0.204c-0.076-0.101-0.106-0.205-0.145-0.313
               c-0.037-0.113-0.068-0.236-0.167-0.381c-0.076-0.123-0.139-0.267-0.175-0.404c-0.054-0.176-0.077-0.326-0.069-0.41
               c0-0.063-0.008-0.122-0.022-0.189c-0.016-0.113-0.039-0.244,0.015-0.39c0.03-0.075,0.069-0.138,0.107-0.188
               c0.084-0.123,0.152-0.223,0.061-0.442c-0.038-0.106-0.084-0.188-0.114-0.259c-0.068-0.137-0.114-0.242-0.114-0.319
               c0-0.047,0.007-0.084,0.022-0.14c0.023-0.098,0.046-0.211,0.008-0.355c-0.023-0.099-0.016-0.189,0.016-0.281
               c0.038-0.099,0.099-0.207,0.167-0.336c0.092-0.152,0.122-0.267,0.152-0.373c0.022-0.076,0.038-0.146,0.077-0.211
               c0.045-0.084,0.052-0.154,0.061-0.215c0.008-0.068,0.015-0.121,0.061-0.191c0.046-0.053,0.084-0.066,0.122-0.073
               c0.061-0.017,0.113-0.039,0.159-0.14c0.062-0.112-0.007-0.183-0.099-0.273c-0.053-0.053-0.122-0.113-0.168-0.221
               c-0.045-0.084-0.03-0.098-0.007-0.105c0.053-0.021,0.106-0.055,0.084-0.184c-0.031-0.189-0.054-0.26-0.107-0.411v-0.015
               c-0.015-0.045-0.03-0.092-0.045-0.129c-0.038-0.084-0.061-0.146-0.046-0.177c0.037-0.036,0.054-0.092,0.046-0.153
               c0-0.06-0.031-0.129-0.076-0.213c-0.046-0.06-0.046-0.136-0.023-0.213c0.03-0.082,0.1-0.174,0.198-0.267
               c0.13-0.113,0.281-0.159,0.404-0.205c0.099-0.028,0.175-0.062,0.234-0.106c0.062-0.054,0.092-0.145,0.107-0.252
               c0.016-0.145,0.008-0.312-0.008-0.41c-0.008-0.092,0-0.145,0.016-0.199c0.015-0.045,0.023-0.09,0.023-0.15
               c-0.009-0.105-0.146-0.268-0.214-0.343c0.016-0.022,0.03-0.045,0.038-0.06h0.008c0.061-0.092,0.137-0.162,0.212-0.23
               c0.063-0.053,0.115-0.1,0.153-0.15c0.053-0.063,0.076-0.115,0.092-0.152c0.015-0.029,0.029-0.055,0.045-0.068
               c0.016-0.008,0.054,0.007,0.106,0.029c0.084,0.031,0.183,0.092,0.251,0.146c0.092,0.061,0.29,0.061,0.479,0.061
               c0.131,0,0.26,0,0.336,0.023c0.084,0.021,0.145,0.098,0.205,0.182c0.069,0.084,0.138,0.166,0.252,0.236
               c0.1,0.061,0.166,0.03,0.213-0.046c0.046-0.069,0.062-0.176,0.084-0.274c0.016-0.09,0.03-0.168,0.046-0.188
               c0.068-0.062,0.305-0.038,0.578-0.009l0.099,0.009h0.008c0.243,0.029,0.351,0.138,0.488,0.288
               c0.029,0.038,0.066,0.077,0.106,0.113c0.037,0.037,0.099,0.1,0.167,0.162c0.281,0.272,0.73,0.701,0.792,0.875
               c0.068,0.205,0.396,0.272,0.7,0.334c0.091,0.022,0.183,0.037,0.267,0.063c0.159,0.045,0.274,0.083,0.381,0.145
               c0.106,0.068,0.206,0.16,0.335,0.313c0.197,0.244,0.77,0.396,1.119,0.495c0.129,0.03,0.229,0.062,0.267,0.077
               c0.068,0.029,0.106,0.097,0.114,0.173c0.022,0.084,0.008,0.19-0.008,0.29c-0.009,0.084-0.054,0.152-0.099,0.214
               c-0.062,0.093-0.122,0.183-0.138,0.343c-0.008,0.131-0.022,0.204-0.061,0.273c-0.046,0.068-0.114,0.146-0.229,0.259
               c-0.145,0.146-0.129,0.245-0.061,0.312c0.053,0.063,0.159,0.084,0.236,0.1c0.053,0.01,0.145,0.055,0.251,0.107
               c0.129,0.061,0.289,0.138,0.44,0.184c0.222,0.061,0.397,0,0.572-0.055c0.061-0.021,0.12-0.045,0.182-0.061
               c0.1-0.023,0.184-0.009,0.26,0.008c0.106,0.016,0.205,0.03,0.327-0.031c0.031-0.014,0.06-0.029,0.092-0.045
               c0.167-0.076,0.319-0.152,0.502-0.396c0.054-0.075,0.091-0.129,0.129-0.167c0.084-0.113,0.107-0.139,0.153-0.336
               c0.022-0.137,0.008-0.26-0.008-0.365c-0.017-0.105-0.03-0.205,0-0.289c0.045-0.113,0.129-0.121,0.236-0.129
               c0.046-0.008,0.099-0.008,0.159-0.023h0.022c0.039,0,0.069,0.023,0.093,0.062c0.03,0.038,0.053,0.099,0.068,0.161
               c0.015,0.066,0.022,0.145,0.022,0.212c-0.008,0.13,0.046,0.222,0.114,0.336c0.053,0.091,0.114,0.19,0.144,0.327
               c0.023,0.1-0.068,0.213-0.196,0.336c-0.214,0.189-0.549,0.372-0.693,0.439c-0.237,0.105-0.251,0.152-0.321,0.357
               c-0.006,0.03-0.021,0.068-0.037,0.107c-0.061,0.183-0.152,0.25-0.273,0.35c-0.063,0.055-0.13,0.105-0.206,0.184
               c-0.145,0.146-0.297,0.428-0.419,0.654c-0.076,0.152-0.145,0.274-0.159,0.281c-0.07,0.029-0.1,0.122-0.13,0.229
               c-0.023,0.055-0.038,0.115-0.069,0.16c-0.038,0.076-0.067,0.106-0.091,0.137c-0.022,0.023-0.038,0.039-0.061,0.068
               c-0.031,0.049-0.054,0.123-0.061,0.215c-0.008,0.066-0.008,0.158,0.015,0.25l0.008,0.029c0.038,0.161,0.038,0.176,0.007,0.291
               c-0.015,0.084-0.015,0.15-0.007,0.242c0.007,0.076,0.016,0.169,0.016,0.297c0,0.161-0.009,0.161-0.016,0.168
               c-0.022,0.016-0.038,0.039-0.068,0.1c-0.038,0.092,0.015,0.19,0.075,0.313c0.047,0.1,0.107,0.221,0.123,0.357
               c0.007,0.09,0.015,0.152,0.021,0.188c0,0.024,0.009,0.039,0.009,0.047c0,0-0.016,0.009-0.038,0.03l-0.023,0.016
               c-0.075,0.061-0.092,0.152-0.075,0.288c0.015,0.123,0.061,0.283,0.121,0.479C68.496,105.125,68.549,105.248,68.595,105.337z"/>
             <path fill="#808184" d="M68.442,101.675c0.045,0.009,0.122,0.017,0.213,0.009
               c0.077-0.015,0.099-0.039,0.13-0.062c0.016-0.022,0.038-0.037,0.13-0.068c0.129-0.038,0.304,0.13,0.456,0.273
               c0.046,0.039,0.084,0.078,0.122,0.113c0.068,0.055,0.084,0.092,0.099,0.131c0.022,0.053,0.046,0.105,0.198,0.205
               c0.175,0.1,0.259,0.063,0.32,0.021c0.022-0.008,0.038-0.014,0.045,0l0.017,0.008c0.09,0.084,0.182,0.168,0.425,0.307
               c0.084,0.045,0.145,0.105,0.206,0.168c0.13,0.129,0.267,0.258,0.586,0.326c0.222,0.045,0.252,0.055,0.252,0.055
               c0,0.006-0.008,0.006-0.008,0.014c-0.016,0.039-0.03,0.084,0,0.152c0.046,0.084,0.106,0.168,0.19,0.236
               c0.068,0.061,0.152,0.114,0.251,0.137c0.092,0.023,0.152,0.068,0.183,0.123c0.038,0.059,0.046,0.137,0.046,0.227
               c-0.008,0.101-0.046,0.191-0.076,0.275c-0.03,0.084-0.061,0.166-0.068,0.258c-0.008,0.123,0.114,0.244,0.251,0.342
               c-0.038,0.048-0.076,0.109-0.106,0.207c-0.038,0.191-0.038,0.205-0.236,0.465c-0.19,0.251-0.365,0.365-0.487,0.396
               c-0.114,0.037-0.297-0.039-0.479-0.093c-0.19-0.062-0.381-0.084-0.456-0.022c-0.077,0.068-0.084,0.185-0.268,0.092
               c-0.175-0.092-0.441,0.017-0.586-0.176c-0.137-0.197-0.159-0.266-0.335-0.266c-0.175,0-0.267,0.008-0.457-0.076
               c-0.145-0.063-0.244-0.199-0.326-0.184c-0.039-0.084-0.084-0.189-0.13-0.344c-0.062-0.197-0.107-0.35-0.122-0.457
               c-0.008-0.098,0-0.166,0.045-0.205l0.016-0.016c0.054-0.037,0.068-0.051,0.076-0.09c0-0.023,0-0.048-0.008-0.078
               c-0.007-0.037-0.015-0.09-0.022-0.183c-0.016-0.159-0.076-0.289-0.13-0.396c-0.053-0.099-0.092-0.184-0.068-0.229
               c0.016-0.038,0.023-0.046,0.03-0.054c0.038-0.03,0.054-0.045,0.054-0.252c0.008-0.137,0-0.229-0.008-0.303
               c-0.008-0.086-0.016-0.146,0-0.207c0.038-0.146,0.03-0.16-0.008-0.342l-0.008-0.031c-0.015-0.084-0.022-0.16-0.015-0.221
               c0.008-0.076,0.022-0.13,0.046-0.16C68.428,101.692,68.435,101.684,68.442,101.675"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M68.442,101.675c0.045,0.009,0.122,0.017,0.213,0.009c0.077-0.015,0.099-0.039,0.13-0.062c0.016-0.022,0.038-0.037,0.13-0.068
               c0.129-0.038,0.304,0.13,0.456,0.273c0.046,0.039,0.084,0.078,0.122,0.113c0.068,0.055,0.084,0.092,0.099,0.131
               c0.022,0.053,0.046,0.105,0.198,0.205c0.175,0.1,0.259,0.063,0.32,0.021c0.022-0.008,0.038-0.014,0.045,0l0.017,0.008
               c0.09,0.084,0.182,0.168,0.425,0.307c0.084,0.045,0.145,0.105,0.206,0.168c0.13,0.129,0.267,0.258,0.586,0.326
               c0.222,0.045,0.252,0.055,0.252,0.055c0,0.006-0.008,0.006-0.008,0.014c-0.016,0.039-0.03,0.084,0,0.152
               c0.046,0.084,0.106,0.168,0.19,0.236c0.068,0.061,0.152,0.114,0.251,0.137c0.092,0.023,0.152,0.068,0.183,0.123
               c0.038,0.059,0.046,0.137,0.046,0.227c-0.008,0.101-0.046,0.191-0.076,0.275c-0.03,0.084-0.061,0.166-0.068,0.258
               c-0.008,0.123,0.114,0.244,0.251,0.342c-0.038,0.048-0.076,0.109-0.106,0.207c-0.038,0.191-0.038,0.205-0.236,0.465
               c-0.19,0.251-0.365,0.365-0.487,0.396c-0.114,0.037-0.297-0.039-0.479-0.093c-0.19-0.062-0.381-0.084-0.456-0.022
               c-0.077,0.068-0.084,0.185-0.268,0.092c-0.175-0.092-0.441,0.017-0.586-0.176c-0.137-0.197-0.159-0.266-0.335-0.266
               c-0.175,0-0.267,0.008-0.457-0.076c-0.145-0.063-0.244-0.199-0.326-0.184c-0.039-0.084-0.084-0.189-0.13-0.344
               c-0.062-0.197-0.107-0.35-0.122-0.457c-0.008-0.098,0-0.166,0.045-0.205l0.016-0.016c0.054-0.037,0.068-0.051,0.076-0.09
               c0-0.023,0-0.048-0.008-0.078c-0.007-0.037-0.015-0.09-0.022-0.183c-0.016-0.159-0.076-0.289-0.13-0.396
               c-0.053-0.099-0.092-0.184-0.068-0.229c0.016-0.038,0.023-0.046,0.03-0.054c0.038-0.03,0.054-0.045,0.054-0.252
               c0.008-0.137,0-0.229-0.008-0.303c-0.008-0.086-0.016-0.146,0-0.207c0.038-0.146,0.03-0.16-0.008-0.342l-0.008-0.031
               c-0.015-0.084-0.022-0.16-0.015-0.221c0.008-0.076,0.022-0.13,0.046-0.16C68.428,101.692,68.435,101.684,68.442,101.675z"/>
             <path fill="#808184" d="M63.189,94.033c-0.084-0.064-0.184-0.1-0.328-0.115l-0.106-0.008
               c-0.296-0.037-0.556-0.061-0.654,0.03c-0.046,0.047-0.062,0.146-0.084,0.244c-0.015,0.091-0.031,0.19-0.062,0.243
               c-0.015,0.023-0.037,0.039-0.076,0.015c-0.099-0.06-0.159-0.144-0.221-0.221c-0.076-0.091-0.145-0.175-0.259-0.214
               c-0.092-0.021-0.229-0.021-0.373-0.021c-0.167,0-0.351,0-0.411-0.047c-0.076-0.062-0.183-0.121-0.273-0.159
               c-0.084-0.029-0.161-0.046-0.206-0.008c-0.038,0.029-0.054,0.061-0.076,0.1c-0.016,0.037-0.038,0.084-0.076,0.129
               s-0.092,0.092-0.145,0.145c-0.076,0.068-0.16,0.146-0.229,0.244c-0.067,0.092-0.183,0.252-0.274,0.281
               c-0.038,0.016-0.076-0.008-0.106-0.1c-0.053-0.16-0.114-0.252-0.175-0.351c-0.061-0.104-0.129-0.214-0.19-0.425
               c-0.022-0.092-0.046-0.19-0.068-0.282c-0.068-0.306-0.13-0.57-0.32-0.724c-0.098-0.076-0.098-0.121-0.098-0.168
               c0-0.062,0-0.123-0.107-0.229c-0.092-0.1-0.153-0.161-0.183-0.207c-0.016-0.037-0.008-0.061,0.022-0.1
               c0.113-0.112,0.106-0.182,0.1-0.318l-0.009-0.021c0-0.039,0.031-0.084,0.062-0.139c0.061-0.1,0.115-0.189-0.016-0.273
               c-0.053-0.03-0.106-0.045-0.152-0.061c-0.068-0.018-0.121-0.031-0.152-0.146c-0.022-0.105-0.092-0.213-0.167-0.336
               c-0.084-0.127-0.168-0.271-0.214-0.434c-0.053-0.189-0.106-0.25-0.151-0.303c-0.022-0.023-0.046-0.055-0.068-0.107
               c-0.023-0.084-0.046-0.152-0.062-0.203c-0.022-0.068-0.038-0.115-0.045-0.133c-0.016-0.021-0.092-0.113-0.13-0.166
               c0.022-0.017,0.046-0.031,0.076-0.054c0.076-0.046,0.175-0.106,0.273-0.229c0.106-0.145,0.138-0.273,0.114-0.389
               c-0.015-0.076-0.037-0.145-0.083-0.206c0.075,0.056,0.267,0.084,0.235-0.03c-0.03-0.121-0.121-0.145-0.183-0.258
               c-0.053-0.107-0.197-0.267-0.197-0.267c-0.031-0.04-0.106-0.138-0.206-0.229c-0.008-0.062-0.015-0.123-0.03-0.191
               c-0.023-0.158-0.053-0.334-0.038-0.471c0.015-0.093,0.068-0.168,0.121-0.236c0.076-0.1,0.153-0.19,0.115-0.352
               c-0.023-0.121-0.046-0.242-0.062-0.365c-0.022-0.137-0.03-0.272-0.037-0.396c-0.008-0.129,0.053-0.272,0.105-0.411
               c0.038-0.092,0.077-0.189,0.092-0.281c0.022-0.099-0.022-0.252-0.099-0.41c-0.122-0.267-0.306-0.557-0.366-0.642
               c-0.03-0.036-0.06-0.09-0.091-0.151c-0.031-0.053-0.053-0.105-0.092-0.151c0.069-0.038,0.198-0.106,0.282-0.084
               c0.045,0.015,0.076,0.022,0.099,0.038c0.069,0.031,0.106,0.053,0.235,0.016c0.1-0.021,0.138-0.068,0.176-0.099
               c0.022-0.032,0.054-0.054,0.113-0.062c0.093-0.022,0.146-0.106,0.19-0.19c0.039-0.067,0.077-0.13,0.115-0.122
               c0.077,0.023,0.137,0.023,0.198,0.016c0.06-0.007,0.106-0.037,0.167-0.091c0.047-0.038,0.068-0.099,0.1-0.168
               c0.045-0.122,0.113-0.28,0.342-0.306c0.282-0.021,0.449-0.09,0.571-0.146c0.038-0.015,0.076-0.028,0.106-0.036
               c0.023-0.009,0.046-0.017,0.068-0.023c0.13-0.045,0.313-0.105,0.351-0.038c0.03,0.062,0.022,0.168,0.008,0.269
               c-0.008,0.061-0.008,0.119-0.008,0.174c0.008,0.047,0.03,0.1,0.054,0.152c0.03,0.092,0.075,0.197,0.046,0.281
               c-0.038,0.084-0.046,0.168-0.046,0.242c0.008,0.078,0.03,0.152,0.061,0.229c0.015,0.037,0.03,0.091,0.038,0.151
               c0.016,0.13,0.038,0.273,0.167,0.373c0.076,0.063,0.138,0.084,0.198,0.101c0.068,0.021,0.13,0.046,0.167,0.158
               c0.047,0.114,0.092,0.19,0.153,0.244c0.061,0.062,0.121,0.092,0.198,0.13c0.075,0.038,0.159,0.076,0.228,0.105
               c0.084,0.029,0.161,0.045,0.229,0.053h0.076c0.13-0.008,0.313-0.008,0.411,0.107c0.077,0.1,0.161,0.106,0.237,0.121
               c0.06,0.008,0.121,0.016,0.158,0.084c0.039,0.047,0.055,0.092,0.069,0.131c0.053,0.098,0.083,0.166,0.251,0.221
               c0.076,0.021,0.146,0.029,0.205,0.037c0.115,0.008,0.206,0.021,0.282,0.105c0.061,0.077,0.138,0.123,0.213,0.153
               c0.092,0.028,0.184,0.038,0.267,0.028c0.023-0.006,0.046-0.015,0.076-0.015c0.13-0.03,0.335-0.075,0.441,0.099
               c0.031,0.063,0.069,0.123,0.106,0.176c0.084,0.131,0.139,0.221,0.13,0.298c-0.007,0.069,0.009,0.151,0.031,0.229
               c0.022,0.084,0.053,0.158,0.075,0.213v0.006l0.008,0.023c0.038,0.084,0.076,0.168,0.038,0.189
               c-0.053,0.031-0.068,0.092-0.068,0.154c0,0.092,0.03,0.188,0.045,0.228c0,0,0,0.038-0.007,0.091
               c-0.008,0.236-0.038,0.762,0.319,0.83c0.16,0.031,0.396,0.018,0.625,0.01c0.327-0.025,0.662-0.039,0.716,0.059
               c0.061,0.123,0.053,0.199,0.045,0.283c-0.007,0.062-0.015,0.121,0,0.205c0.022,0.106,0.054,0.198,0.1,0.282
               c0.053,0.075,0.113,0.151,0.197,0.235c0.069,0.063,0.146,0.107,0.214,0.145c0.106,0.061,0.198,0.122,0.221,0.221
               c0.016,0.092,0.038,0.168,0.062,0.236c0.029,0.075,0.054,0.139,0.054,0.213c0,0.094-0.031,0.471-0.093,0.793
               c-0.038,0.205-0.076,0.373-0.129,0.434c-0.016,0.023-0.03,0.046-0.046,0.076c-0.145-0.082-0.563-0.328-0.693-0.426
               c-0.106-0.076-0.22-0.07-0.335-0.055c-0.061,0.008-0.12,0.016-0.167,0c-0.13-0.037-0.274-0.016-0.541,0.029
               c-0.061,0.009-0.137,0.023-0.205,0.031c-0.175,0.031-0.313,0.061-0.434,0.092c-0.145,0.029-0.26,0.061-0.336,0.053
               c-0.099-0.016-0.182,0.008-0.243,0.084c-0.062,0.068-0.1,0.184-0.13,0.357c-0.03,0.16-0.083,0.281-0.129,0.404
               c-0.053,0.113-0.1,0.235-0.122,0.379c-0.03,0.168-0.015,0.252,0.008,0.336c0.007,0.063,0.022,0.123,0.015,0.237
               C63.356,93.514,63.242,93.878,63.189,94.033"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M63.189,94.033c-0.084-0.064-0.184-0.1-0.328-0.115l-0.106-0.008c-0.296-0.037-0.556-0.061-0.654,0.03
               c-0.046,0.047-0.062,0.146-0.084,0.244c-0.015,0.091-0.031,0.19-0.062,0.243c-0.015,0.023-0.037,0.039-0.076,0.015
               c-0.099-0.06-0.159-0.144-0.221-0.221c-0.076-0.091-0.145-0.175-0.259-0.214c-0.092-0.021-0.229-0.021-0.373-0.021
               c-0.167,0-0.351,0-0.411-0.047c-0.076-0.062-0.183-0.121-0.273-0.159c-0.084-0.029-0.161-0.046-0.206-0.008
               c-0.038,0.029-0.054,0.061-0.076,0.1c-0.016,0.037-0.038,0.084-0.076,0.129s-0.092,0.092-0.145,0.145
               c-0.076,0.068-0.16,0.146-0.229,0.244c-0.067,0.092-0.183,0.252-0.274,0.281c-0.038,0.016-0.076-0.008-0.106-0.1
               c-0.053-0.16-0.114-0.252-0.175-0.351c-0.061-0.104-0.129-0.214-0.19-0.425c-0.022-0.092-0.046-0.19-0.068-0.282
               c-0.068-0.306-0.13-0.57-0.32-0.724c-0.098-0.076-0.098-0.121-0.098-0.168c0-0.062,0-0.123-0.107-0.229
               c-0.092-0.1-0.153-0.161-0.183-0.207c-0.016-0.037-0.008-0.061,0.022-0.1c0.113-0.112,0.106-0.182,0.1-0.318l-0.009-0.021
               c0-0.039,0.031-0.084,0.062-0.139c0.061-0.1,0.115-0.189-0.016-0.273c-0.053-0.03-0.106-0.045-0.152-0.061
               c-0.068-0.018-0.121-0.031-0.152-0.146c-0.022-0.105-0.092-0.213-0.167-0.336c-0.084-0.127-0.168-0.271-0.214-0.434
               c-0.053-0.189-0.106-0.25-0.151-0.303c-0.022-0.023-0.046-0.055-0.068-0.107c-0.023-0.084-0.046-0.152-0.062-0.203
               c-0.022-0.068-0.038-0.115-0.045-0.133c-0.016-0.021-0.092-0.113-0.13-0.166c0.022-0.017,0.046-0.031,0.076-0.054
               c0.076-0.046,0.175-0.106,0.273-0.229c0.106-0.145,0.138-0.273,0.114-0.389c-0.015-0.076-0.037-0.145-0.083-0.206
               c0.075,0.056,0.267,0.084,0.235-0.03c-0.03-0.121-0.121-0.145-0.183-0.258c-0.053-0.107-0.197-0.267-0.197-0.267
               c-0.031-0.04-0.106-0.138-0.206-0.229c-0.008-0.062-0.015-0.123-0.03-0.191c-0.023-0.158-0.053-0.334-0.038-0.471
               c0.015-0.093,0.068-0.168,0.121-0.236c0.076-0.1,0.153-0.19,0.115-0.352c-0.023-0.121-0.046-0.242-0.062-0.365
               c-0.022-0.137-0.03-0.272-0.037-0.396c-0.008-0.129,0.053-0.272,0.105-0.411c0.038-0.092,0.077-0.189,0.092-0.281
               c0.022-0.099-0.022-0.252-0.099-0.41c-0.122-0.267-0.306-0.557-0.366-0.642c-0.03-0.036-0.06-0.09-0.091-0.151
               c-0.031-0.053-0.053-0.105-0.092-0.151c0.069-0.038,0.198-0.106,0.282-0.084c0.045,0.015,0.076,0.022,0.099,0.038
               c0.069,0.031,0.106,0.053,0.235,0.016c0.1-0.021,0.138-0.068,0.176-0.099c0.022-0.032,0.054-0.054,0.113-0.062
               c0.093-0.022,0.146-0.106,0.19-0.19c0.039-0.067,0.077-0.13,0.115-0.122c0.077,0.023,0.137,0.023,0.198,0.016
               c0.06-0.007,0.106-0.037,0.167-0.091c0.047-0.038,0.068-0.099,0.1-0.168c0.045-0.122,0.113-0.28,0.342-0.306
               c0.282-0.021,0.449-0.09,0.571-0.146c0.038-0.015,0.076-0.028,0.106-0.036c0.023-0.009,0.046-0.017,0.068-0.023
               c0.13-0.045,0.313-0.105,0.351-0.038c0.03,0.062,0.022,0.168,0.008,0.269c-0.008,0.061-0.008,0.119-0.008,0.174
               c0.008,0.047,0.03,0.1,0.054,0.152c0.03,0.092,0.075,0.197,0.046,0.281c-0.038,0.084-0.046,0.168-0.046,0.242
               c0.008,0.078,0.03,0.152,0.061,0.229c0.015,0.037,0.03,0.091,0.038,0.151c0.016,0.13,0.038,0.273,0.167,0.373
               c0.076,0.063,0.138,0.084,0.198,0.101c0.068,0.021,0.13,0.046,0.167,0.158c0.047,0.114,0.092,0.19,0.153,0.244
               c0.061,0.062,0.121,0.092,0.198,0.13c0.075,0.038,0.159,0.076,0.228,0.105c0.084,0.029,0.161,0.045,0.229,0.053h0.076
               c0.13-0.008,0.313-0.008,0.411,0.107c0.077,0.1,0.161,0.106,0.237,0.121c0.06,0.008,0.121,0.016,0.158,0.084
               c0.039,0.047,0.055,0.092,0.069,0.131c0.053,0.098,0.083,0.166,0.251,0.221c0.076,0.021,0.146,0.029,0.205,0.037
               c0.115,0.008,0.206,0.021,0.282,0.105c0.061,0.077,0.138,0.123,0.213,0.153c0.092,0.028,0.184,0.038,0.267,0.028
               c0.023-0.006,0.046-0.015,0.076-0.015c0.13-0.03,0.335-0.075,0.441,0.099c0.031,0.063,0.069,0.123,0.106,0.176
               c0.084,0.131,0.139,0.221,0.13,0.298c-0.007,0.069,0.009,0.151,0.031,0.229c0.022,0.084,0.053,0.158,0.075,0.213v0.006
               l0.008,0.023c0.038,0.084,0.076,0.168,0.038,0.189c-0.053,0.031-0.068,0.092-0.068,0.154c0,0.092,0.03,0.188,0.045,0.228
               c0,0,0,0.038-0.007,0.091c-0.008,0.236-0.038,0.762,0.319,0.83c0.16,0.031,0.396,0.018,0.625,0.01
               c0.327-0.025,0.662-0.039,0.716,0.059c0.061,0.123,0.053,0.199,0.045,0.283c-0.007,0.062-0.015,0.121,0,0.205
               c0.022,0.106,0.054,0.198,0.1,0.282c0.053,0.075,0.113,0.151,0.197,0.235c0.069,0.063,0.146,0.107,0.214,0.145
               c0.106,0.061,0.198,0.122,0.221,0.221c0.016,0.092,0.038,0.168,0.062,0.236c0.029,0.075,0.054,0.139,0.054,0.213
               c0,0.094-0.031,0.471-0.093,0.793c-0.038,0.205-0.076,0.373-0.129,0.434c-0.016,0.023-0.03,0.046-0.046,0.076
               c-0.145-0.082-0.563-0.328-0.693-0.426c-0.106-0.076-0.22-0.07-0.335-0.055c-0.061,0.008-0.12,0.016-0.167,0
               c-0.13-0.037-0.274-0.016-0.541,0.029c-0.061,0.009-0.137,0.023-0.205,0.031c-0.175,0.031-0.313,0.061-0.434,0.092
               c-0.145,0.029-0.26,0.061-0.336,0.053c-0.099-0.016-0.182,0.008-0.243,0.084c-0.062,0.068-0.1,0.184-0.13,0.357
               c-0.03,0.16-0.083,0.281-0.129,0.404c-0.053,0.113-0.1,0.235-0.122,0.379c-0.03,0.168-0.015,0.252,0.008,0.336
               c0.007,0.063,0.022,0.123,0.015,0.237C63.356,93.514,63.242,93.878,63.189,94.033z"/>
             <path fill="#808184" d="M70.148,97.214c-0.038,0.029-0.077,0.068-0.106,0.146
               c-0.046,0.106-0.031,0.222-0.016,0.341c0.022,0.1,0.038,0.215,0.007,0.33c-0.037,0.174-0.053,0.196-0.129,0.297
               c-0.03,0.037-0.068,0.09-0.129,0.166c-0.161,0.229-0.305,0.289-0.465,0.367c-0.03,0.014-0.061,0.028-0.092,0.045
               c-0.092,0.045-0.175,0.037-0.267,0.021c-0.083-0.016-0.175-0.03-0.297-0.008c-0.061,0.017-0.121,0.039-0.19,0.062
               c-0.16,0.054-0.319,0.106-0.51,0.054c-0.152-0.039-0.297-0.115-0.427-0.174c-0.113-0.056-0.205-0.109-0.281-0.117
               c-0.061-0.015-0.145-0.029-0.183-0.066c-0.03-0.031-0.022-0.084,0.068-0.176c0.121-0.123,0.19-0.197,0.236-0.273V98.22
               c0.053-0.084,0.068-0.168,0.083-0.313c0.008-0.13,0.062-0.214,0.115-0.291c0.053-0.076,0.099-0.144,0.122-0.257
               c0.015-0.106,0.021-0.224,0.007-0.327c-0.022-0.107-0.076-0.191-0.175-0.244c-0.046-0.022-0.152-0.047-0.282-0.084
               c-0.342-0.092-0.891-0.244-1.073-0.465c-0.13-0.16-0.244-0.259-0.358-0.328c-0.113-0.076-0.243-0.113-0.411-0.158
               c-0.075-0.023-0.175-0.039-0.273-0.061c-0.274-0.055-0.571-0.115-0.625-0.268c-0.068-0.207-0.525-0.641-0.807-0.914
               c-0.068-0.061-0.122-0.114-0.167-0.159c-0.046-0.047-0.076-0.084-0.107-0.115c-0.053-0.053-0.091-0.1-0.137-0.146
               c0.038-0.121,0.175-0.547,0.198-0.76c0.007-0.131-0.008-0.199-0.016-0.269c-0.015-0.067-0.03-0.146-0.008-0.296
               c0.023-0.139,0.069-0.243,0.114-0.357c0.054-0.121,0.106-0.252,0.138-0.426c0.03-0.16,0.061-0.251,0.106-0.307
               c0.038-0.045,0.092-0.061,0.151-0.055c0.1,0.01,0.222-0.014,0.373-0.053c0.123-0.029,0.26-0.061,0.427-0.084
               c0.084-0.015,0.152-0.029,0.206-0.035c0.251-0.039,0.388-0.064,0.494-0.031c0.069,0.021,0.138,0.015,0.214,0.006
               c0.091-0.014,0.183-0.029,0.259,0.031c0.137,0.106,0.602,0.373,0.724,0.442c-0.008,0.03-0.016,0.065-0.016,0.104
               c0.008,0.138,0.053,0.267,0.099,0.357v0.008c0.008,0.023,0.023,0.047,0.038,0.076c0.069,0.131,0.146,0.281,0.137,0.434
               c-0.007,0.107,0.009,0.297,0.031,0.479c0.022,0.185,0.053,0.366,0.062,0.458c0.007,0.037,0.007,0.076,0.007,0.105
               c0,0.146,0,0.252,0.327,0.328c0.229,0.053,0.465-0.008,0.662-0.068c0.123-0.029,0.236-0.063,0.32-0.055
               c0.106,0.008,0.206,0.031,0.289,0.063c0.092,0.037,0.168,0.084,0.214,0.122c0.046,0.054,0.145,0.28,0.213,0.54
               c0.054,0.189,0.083,0.396,0.076,0.549c-0.022,0.206-0.008,0.313,0.038,0.381c0.046,0.068,0.13,0.084,0.244,0.092
               c0.105,0,0.221-0.076,0.327-0.146c0.038-0.03,0.076-0.053,0.114-0.066c0.053-0.039,0.106-0.054,0.121-0.018
               c0.046,0.076,0.084,0.123,0.121,0.168c0.047,0.063,0.084,0.105,0.115,0.206c0.038,0.138,0.031,0.159,0,0.28
               c-0.008,0.023-0.016,0.047-0.023,0.084c-0.029,0.101-0.052,0.185-0.061,0.26c-0.015,0.076-0.022,0.15-0.031,0.236
               C70.071,97.007,70.126,97.146,70.148,97.214"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M70.148,97.214c-0.038,0.029-0.077,0.068-0.106,0.146c-0.046,0.106-0.031,0.222-0.016,0.341c0.022,0.1,0.038,0.215,0.007,0.33
               c-0.037,0.174-0.053,0.196-0.129,0.297c-0.03,0.037-0.068,0.09-0.129,0.166c-0.161,0.229-0.305,0.289-0.465,0.367
               c-0.03,0.014-0.061,0.028-0.092,0.045c-0.092,0.045-0.175,0.037-0.267,0.021c-0.083-0.016-0.175-0.03-0.297-0.008
               c-0.061,0.017-0.121,0.039-0.19,0.062c-0.16,0.054-0.319,0.106-0.51,0.054c-0.152-0.039-0.297-0.115-0.427-0.174
               c-0.113-0.056-0.205-0.109-0.281-0.117c-0.061-0.015-0.145-0.029-0.183-0.066c-0.03-0.031-0.022-0.084,0.068-0.176
               c0.121-0.123,0.19-0.197,0.236-0.273V98.22c0.053-0.084,0.068-0.168,0.083-0.313c0.008-0.13,0.062-0.214,0.115-0.291
               c0.053-0.076,0.099-0.144,0.122-0.257c0.015-0.106,0.021-0.224,0.007-0.327c-0.022-0.107-0.076-0.191-0.175-0.244
               c-0.046-0.022-0.152-0.047-0.282-0.084c-0.342-0.092-0.891-0.244-1.073-0.465c-0.13-0.16-0.244-0.259-0.358-0.328
               c-0.113-0.076-0.243-0.113-0.411-0.158c-0.075-0.023-0.175-0.039-0.273-0.061c-0.274-0.055-0.571-0.115-0.625-0.268
               c-0.068-0.207-0.525-0.641-0.807-0.914c-0.068-0.061-0.122-0.114-0.167-0.159c-0.046-0.047-0.076-0.084-0.107-0.115
               c-0.053-0.053-0.091-0.1-0.137-0.146c0.038-0.121,0.175-0.547,0.198-0.76c0.007-0.131-0.008-0.199-0.016-0.269
               c-0.015-0.067-0.03-0.146-0.008-0.296c0.023-0.139,0.069-0.243,0.114-0.357c0.054-0.121,0.106-0.252,0.138-0.426
               c0.03-0.16,0.061-0.251,0.106-0.307c0.038-0.045,0.092-0.061,0.151-0.055c0.1,0.01,0.222-0.014,0.373-0.053
               c0.123-0.029,0.26-0.061,0.427-0.084c0.084-0.015,0.152-0.029,0.206-0.035c0.251-0.039,0.388-0.064,0.494-0.031
               c0.069,0.021,0.138,0.015,0.214,0.006c0.091-0.014,0.183-0.029,0.259,0.031c0.137,0.106,0.602,0.373,0.724,0.442
               c-0.008,0.03-0.016,0.065-0.016,0.104c0.008,0.138,0.053,0.267,0.099,0.357v0.008c0.008,0.023,0.023,0.047,0.038,0.076
               c0.069,0.131,0.146,0.281,0.137,0.434c-0.007,0.107,0.009,0.297,0.031,0.479c0.022,0.185,0.053,0.366,0.062,0.458
               c0.007,0.037,0.007,0.076,0.007,0.105c0,0.146,0,0.252,0.327,0.328c0.229,0.053,0.465-0.008,0.662-0.068
               c0.123-0.029,0.236-0.063,0.32-0.055c0.106,0.008,0.206,0.031,0.289,0.063c0.092,0.037,0.168,0.084,0.214,0.122
               c0.046,0.054,0.145,0.28,0.213,0.54c0.054,0.189,0.083,0.396,0.076,0.549c-0.022,0.206-0.008,0.313,0.038,0.381
               c0.046,0.068,0.13,0.084,0.244,0.092c0.105,0,0.221-0.076,0.327-0.146c0.038-0.03,0.076-0.053,0.114-0.066
               c0.053-0.039,0.106-0.054,0.121-0.018c0.046,0.076,0.084,0.123,0.121,0.168c0.047,0.063,0.084,0.105,0.115,0.206
               c0.038,0.138,0.031,0.159,0,0.28c-0.008,0.023-0.016,0.047-0.023,0.084c-0.029,0.101-0.052,0.185-0.061,0.26
               c-0.015,0.076-0.022,0.15-0.031,0.236C70.071,97.007,70.126,97.146,70.148,97.214z"/>
             <path fill="#808184" d="M71.282,69.384c0.084,0.064,0.152,0.146,0.183,0.273
               c0.106,0.381,0.022,1.037,0.129,1.325c0.1,0.291,0.13,0.44,0.473,0.608c0.335,0.168,0.389,0.146,0.402,0.306
               c0.01,0.151,0.131,0.13-0.06,0.356c-0.19,0.229-0.335,0.336-0.533,0.595c-0.205,0.267-0.273,0.403-0.494,0.579
               c-0.213,0.166-0.389,0.388-0.435,0.496c-0.038,0.099-0.062,0.234-0.106,0.371c-0.053,0.146-0.19,0.291-0.389,0.358
               c-0.205,0.062-0.312,0.151-0.22,0.183c0.091,0.031,0.129,0.082,0.312,0.008c0.19-0.068,0.297-0.189,0.389-0.342
               c0.091-0.146,0.145-0.496,0.343-0.563c0.205-0.076,0.494-0.327,0.556-0.429c0.053-0.09,0.061-0.188,0.319-0.174
               c0.259,0.015,0.464,0,0.754,0.07c0.281,0.073,0.662,0.02,0.73,0.182c0.069,0.158-0.075,0.228-0.113,0.486
               c-0.039,0.266-0.252,0.596-0.389,0.67c-0.137,0.076-0.335,0.356-0.206,0.381c0.13,0.022,0.297-0.061,0.435-0.258
               c0.129-0.205,0.427-0.688,0.502-0.785c0.076-0.1,0.115-0.158,0.427-0.158c0.313-0.01,0.555,0.113,0.769,0.281
               c0.221,0.168,0.815,0.334,1.143,0.494c0.327,0.168,0.487,0.113,0.578,0.434c0.092,0.32,0.1,0.35,0.122,0.632
               c0.022,0.28,0.077,0.243,0.268,0.168c0.183-0.076,0.524-0.466,0.859-0.317c0.343,0.143-0.175,0.188,0.533,0.332
               c0.708,0.146,0.808,0.207,1.135,0.161c0.327-0.047,0.586-0.26,0.913-0.146c0.319,0.108,0.647,0.479,0.717,0.596
               c0.067,0.113,1.126,0.775,1.361,1.111c0.236,0.334,0.686,0.531,1.014,0.51c0.327-0.021,0.426-0.16,0.647,0.054
               c0.228,0.216,0.273,0.44,0.296,0.745c0.023,0.3,0.381,1.326,0.313,1.63c-0.067,0.306-0.076,1.065-0.404,1.401
               c-0.326,0.343-0.616,0.699-0.753,0.967c-0.137,0.273-0.267,0.32-0.518,0.541c-0.243,0.229-0.419,0.708-0.518,1.098
               c-0.1,0.379-0.457,0.967-0.571,0.858c-0.114-0.099-0.319-0.289-0.35-0.151c-0.031,0.131,0.03,0.549-0.069,0.784
               c-0.099,0.235-0.145,0.356-0.076,0.608c0.068,0.244,0.373,1.166,0.29,1.607c-0.076,0.44-0.244,0.608-0.26,0.942
               c-0.008,0.335,0.213,0.465,0.084,0.7c-0.137,0.236-0.419,0.244-0.396,0.578c0.022,0.345,0.281,0.883,0.136,1.127
               c-0.151,0.25-0.517,0.443-0.517,0.776c0,0.335-0.138,0.511-0.297,0.776c-0.152,0.268-0.267,0.427-0.198,0.654
               c0.068,0.222,0,0.291-0.137,0.426c-0.138,0.131-0.419,0.01-0.64,0.246c-0.229,0.234-0.381,0.318-0.541,0.349
               c-0.159,0.03-0.518-0.091-0.723-0.099c-0.198-0.016-0.617-0.092-0.647,0.115c-0.039,0.197,0.03,0.266-0.229,0.387
               c-0.259,0.123-0.267,0.23-0.762,0.359c-0.494,0.137-0.776,0.303-0.913,0.563c-0.138,0.258-0.281,0.281-0.472,0.578
               c-0.19,0.289-0.442,0.357-0.465,0.646c-0.023,0.297,0,0.602-0.038,0.77c-0.047,0.168,0.273,0.549,0.289,0.975
               c0.007,0.428-0.076,0.602-0.114,0.73c-0.03,0.137-0.068,0.113-0.313,0.396c-0.251,0.28-0.556,1.034-0.617,1.393
               c-0.067,0.359-0.045,0.404-0.258,0.709c-0.214,0.305-0.473,0.723-0.511,0.594c-0.037-0.129,0.1-0.139,0.206-0.344
               c0.114-0.213,0.046-0.318,0.138-0.426c0.09-0.107,0.189-0.486-0.008-0.525c-0.198-0.037-0.344-0.029-0.344,0.221
               c0.008,0.244,0.016,0.336-0.061,0.42c-0.068,0.084-0.198,0.176-0.29,0.318c-0.098,0.146-0.121,0.404-0.06,0.465
               c0.053,0.068,0.083,0.168,0.022,0.273c-0.054,0.106-0.145,0.139-0.122,0.289c0.023,0.146-0.008,0.353-0.099,0.596
               c-0.1,0.242-0.236,0.371-0.313,0.486c-0.016,0.022-0.03,0.037-0.045,0.063c-0.114-0.086-0.222-0.184-0.222-0.26
               c0.008-0.076,0.038-0.152,0.068-0.229c0.03-0.092,0.069-0.19,0.076-0.304c0.008-0.113-0.007-0.215-0.061-0.29
               c-0.046-0.076-0.122-0.129-0.235-0.168c-0.084-0.022-0.161-0.06-0.222-0.113c-0.067-0.062-0.123-0.13-0.16-0.206
               c-0.015-0.031,0-0.054,0-0.068c0.009-0.008,0.009-0.015,0.016-0.029c0.016-0.068-0.022-0.115-0.327-0.176
               c-0.289-0.061-0.419-0.184-0.533-0.297c-0.068-0.068-0.138-0.131-0.229-0.183c-0.235-0.139-0.319-0.215-0.411-0.299
               l-0.016-0.009c-0.068-0.059-0.106-0.036-0.159-0.006c-0.038,0.015-0.092,0.045-0.221-0.03c-0.123-0.077-0.138-0.112-0.16-0.151
               c-0.015-0.045-0.038-0.101-0.122-0.177c-0.038-0.03-0.076-0.067-0.121-0.106c-0.168-0.158-0.366-0.349-0.549-0.297
               c-0.114,0.039-0.152,0.069-0.175,0.092c-0.016,0.016-0.03,0.022-0.084,0.029c-0.039,0.01-0.076,0.01-0.114,0.01
               c0.023-0.023,0.038-0.054,0.061-0.092c0.031-0.063,0.054-0.123,0.069-0.183c0.029-0.084,0.053-0.151,0.084-0.168
               c0.045-0.022,0.112-0.16,0.205-0.328c0.113-0.213,0.267-0.493,0.396-0.623c0.076-0.076,0.146-0.131,0.206-0.177
               c0.13-0.112,0.229-0.189,0.305-0.395c0.015-0.048,0.03-0.084,0.037-0.115c0.054-0.166,0.069-0.205,0.26-0.297
               c0.152-0.068,0.503-0.259,0.724-0.457c0.159-0.145,0.259-0.289,0.228-0.434c-0.03-0.152-0.099-0.26-0.152-0.357
               c-0.061-0.099-0.106-0.184-0.106-0.283c0.008-0.067,0-0.151-0.022-0.233c-0.015-0.077-0.046-0.146-0.084-0.198
               c-0.038-0.062-0.099-0.1-0.167-0.107c-0.016,0-0.038,0-0.054,0.009c-0.054,0.015-0.099,0.015-0.145,0.022
               c-0.046,0-0.076,0.008-0.114,0.016c-0.023-0.063-0.069-0.184-0.062-0.297c0.008-0.084,0.016-0.152,0.031-0.229
               c0.007-0.068,0.03-0.15,0.061-0.252c0.008-0.029,0.016-0.053,0.016-0.074c0.045-0.146,0.052-0.176,0-0.343
               c-0.032-0.114-0.076-0.177-0.13-0.235c-0.03-0.047-0.068-0.092-0.106-0.16c-0.061-0.106-0.16-0.084-0.267-0.024
               c-0.037,0.024-0.076,0.046-0.121,0.076c-0.092,0.063-0.19,0.131-0.26,0.123c-0.091,0-0.145-0.007-0.167-0.046
               c-0.03-0.047-0.038-0.139-0.023-0.313c0.016-0.168-0.022-0.38-0.076-0.578c-0.075-0.274-0.183-0.533-0.243-0.588
               c-0.055-0.054-0.139-0.107-0.244-0.145c-0.091-0.038-0.197-0.068-0.319-0.076c-0.099-0.007-0.221,0.029-0.357,0.068
               c-0.184,0.045-0.404,0.106-0.609,0.063c-0.244-0.063-0.244-0.132-0.244-0.23c0-0.037,0-0.075-0.008-0.121
               c-0.015-0.092-0.046-0.273-0.068-0.457c-0.015-0.176-0.038-0.356-0.03-0.456c0.008-0.185-0.076-0.351-0.145-0.487
               c-0.015-0.031-0.03-0.055-0.038-0.076c-0.038-0.084-0.083-0.205-0.083-0.32c0-0.084,0.015-0.16,0.061-0.221
               c0.061-0.066,0.114-0.26,0.152-0.473c0.061-0.325,0.099-0.723,0.091-0.82c0-0.086-0.022-0.16-0.053-0.236
               c-0.023-0.069-0.046-0.139-0.062-0.223c-0.03-0.15-0.145-0.212-0.267-0.287c-0.068-0.039-0.137-0.084-0.197-0.139
               c-0.076-0.076-0.137-0.145-0.176-0.213c-0.045-0.076-0.068-0.152-0.091-0.244c-0.008-0.076,0-0.129,0-0.184
               c0.007-0.098,0.015-0.189-0.061-0.334c-0.084-0.16-0.442-0.145-0.808-0.123c-0.221,0.016-0.449,0.029-0.602,0
               c-0.267-0.053-0.244-0.518-0.229-0.723c0-0.068,0-0.113-0.008-0.138c-0.008-0.03-0.037-0.112-0.037-0.188
               c0-0.031,0.007-0.053,0.022-0.063c0.114-0.076,0.061-0.196,0.007-0.326l-0.015-0.022c-0.022-0.053-0.055-0.131-0.069-0.197
               c-0.022-0.068-0.029-0.137-0.022-0.199c0.008-0.113-0.053-0.213-0.152-0.355c-0.03-0.055-0.067-0.107-0.106-0.176
               c-0.137-0.236-0.389-0.184-0.548-0.146c-0.023,0-0.054,0.009-0.068,0.009c-0.068,0.007-0.146,0.007-0.221-0.022
               c-0.054-0.022-0.116-0.062-0.168-0.123c-0.1-0.112-0.213-0.129-0.343-0.145c-0.062-0.007-0.13-0.016-0.191-0.029
               c-0.129-0.039-0.151-0.092-0.182-0.168c-0.022-0.037-0.046-0.084-0.076-0.137c-0.076-0.107-0.152-0.121-0.235-0.131
               c-0.062-0.008-0.122-0.021-0.175-0.084c-0.13-0.15-0.344-0.15-0.488-0.15H61.36c-0.054,0-0.122-0.016-0.19-0.046
               s-0.145-0.062-0.221-0.1c-0.068-0.03-0.129-0.068-0.175-0.106c-0.046-0.046-0.092-0.113-0.122-0.213
               c-0.061-0.16-0.137-0.184-0.236-0.215c-0.053-0.016-0.106-0.035-0.167-0.082c-0.092-0.076-0.114-0.199-0.13-0.313
               c-0.008-0.067-0.022-0.129-0.046-0.183c-0.03-0.062-0.045-0.13-0.045-0.19c-0.008-0.06,0-0.129,0.03-0.197
               c0.053-0.122,0-0.252-0.046-0.357c-0.016-0.054-0.038-0.092-0.038-0.129c-0.007-0.039,0-0.092,0.008-0.154
               c0.008-0.113,0.022-0.233-0.03-0.326c-0.076-0.137-0.305-0.06-0.464-0.006c-0.031,0.015-0.055,0.021-0.069,0.021
               c-0.038,0.017-0.069,0.03-0.115,0.045c-0.113,0.048-0.28,0.115-0.547,0.138c-0.282,0.023-0.366,0.221-0.427,0.367
               c-0.023,0.059-0.038,0.105-0.061,0.129c-0.047,0.039-0.084,0.061-0.13,0.067c-0.038,0.01-0.083,0-0.152-0.015
               c-0.114-0.022-0.167,0.069-0.221,0.168c-0.038,0.067-0.084,0.129-0.131,0.137c-0.09,0.021-0.12,0.055-0.159,0.091
               c-0.029,0.03-0.061,0.062-0.129,0.077c-0.099,0.021-0.13,0.016-0.175-0.008c-0.022-0.016-0.061-0.03-0.106-0.047
               c-0.13-0.029-0.297,0.055-0.373,0.1c-0.03-0.045-0.069-0.077-0.107-0.105c-0.083-0.045-0.189-0.045-0.304-0.039
               c-0.13,0.008-0.274,0.017-0.412-0.062c-0.191-0.099-0.191-0.188-0.197-0.372c0-0.053-0.008-0.113-0.008-0.177
               c-0.015-0.128-0.008-0.228,0-0.312c0.008-0.16,0.014-0.258-0.091-0.305c-0.092-0.046-0.176,0.016-0.29,0.1
               c-0.061,0.045-0.144,0.105-0.243,0.158c-0.19,0.084-0.327,0.063-0.472,0.031c-0.046-0.008-0.092-0.023-0.138-0.023
               c-0.084-0.016-0.091-0.09-0.091-0.166c0-0.068-0.008-0.138-0.039-0.199c-0.061-0.104-0.235-0.121-0.396-0.137
               c-0.091-0.008-0.175-0.008-0.229-0.029c-0.052-0.021-0.029-0.076-0.014-0.139c0.022-0.06,0.045-0.121,0.045-0.197
               c0-0.113-0.129-0.25-0.266-0.395c-0.115-0.131-0.236-0.268-0.281-0.382c-0.039-0.122-0.115-0.213-0.184-0.297
               c-0.076-0.099-0.152-0.19-0.176-0.336c-0.022-0.234,0.152-0.441,0.268-0.578l0.03-0.03c0.015-0.021,0.03-0.047,0.053-0.063
               c0.092-0.113,0.168-0.197,0.16-0.373c-0.009-0.066,0.022-0.137,0.046-0.211c0.046-0.107,0.092-0.229,0.092-0.404
               c0-0.176,0.053-0.251,0.129-0.357c0.046-0.068,0.098-0.152,0.152-0.273c0.106-0.223,0.495-0.282,0.792-0.336
               c0.083-0.016,0.16-0.021,0.22-0.037c0.198-0.047,0.291-0.152,0.373-0.236c0.039-0.044,0.077-0.092,0.115-0.107
               c0.046-0.006,0.137,0,0.243,0.017c0.152,0.022,0.327,0.054,0.441-0.017c0.077-0.045,0.145-0.342,0.206-0.713
               c0.098-0.647,0.182-1.539,0.182-1.625v-0.144c0-0.159,0.009-0.388,0-0.608c-0.007-0.184-0.12-0.268-0.235-0.356
               c-0.1-0.077-0.205-0.161-0.236-0.306c-0.068-0.282,0.009-0.473,0.054-0.588c0-0.021,0.008-0.037,0.016-0.053
               c0.022-0.061,0.084-0.076,0.267-0.1c0.038-0.008,0.084-0.016,0.129-0.021c0.305-0.055,0.32-0.145,0.297-0.289
               c0-0.016-0.008-0.031-0.008-0.047V72.4c-0.029-0.13-0.251-0.13-0.441-0.13c-0.106,0-0.205,0-0.235-0.015
               c0,0-0.008-0.008-0.008-0.016c-0.016-0.031-0.016-0.091-0.008-0.16c0.016-0.067,0.038-0.144,0.068-0.205
               c0.023-0.045,0.046-0.084,0.076-0.092c0.062-0.031,0.32-0.039,0.586-0.039c0.237-0.006,0.48-0.015,0.603-0.037
               c0.129-0.021,0.259-0.062,0.38-0.105c0.113-0.055,0.221-0.105,0.298-0.161c0.037-0.028,0.075-0.035,0.105-0.045
               c0.039,0.067,0.13,0.206,0.206,0.274c0.038,0.029,0.023,0.074,0,0.151c-0.022,0.068-0.046,0.146-0.061,0.259
               c-0.016,0.153,0.03,0.199,0.105,0.205c0.031,0,0.069-0.006,0.101-0.016c0.037-0.008,0.083-0.016,0.113-0.008
               c0.054,0.008,0.1,0.076,0.146,0.154c0.045,0.074,0.098,0.158,0.167,0.197c0.138,0.084,0.182,0.045,0.289-0.048
               c0.023-0.015,0.054-0.035,0.092-0.065c0.061-0.047,0.099-0.01,0.152,0.051c0.046,0.048,0.106,0.115,0.205,0.162
               c0.106,0.052,0.168,0.029,0.197-0.039c0.024-0.039,0.031-0.092,0.038-0.152c0.017-0.075,0.023-0.159,0.069-0.236
               c0.061-0.099,0.16-0.099,0.267-0.09c0.076,0,0.152,0,0.244-0.023c0.151-0.053,0.189-0.168,0.235-0.318
               c0.016-0.068,0.046-0.146,0.083-0.229c0.055-0.101,0.161-0.121,0.26-0.146c0.114-0.029,0.221-0.053,0.281-0.166
               c0.061-0.105,0.038-0.169-0.03-0.205c-0.046-0.031-0.129-0.039-0.213-0.047c-0.061-0.008-0.13-0.008-0.19-0.021
               c-0.076-0.016-0.084-0.055-0.076-0.092c0.008-0.023,0.016-0.063,0.029-0.092c0.017-0.039,0.039-0.084,0.047-0.129
               c0.022-0.152-0.03-0.223-0.114-0.318c-0.017-0.018-0.03-0.039-0.046-0.064c-0.068-0.075-0.055-0.188-0.046-0.305
               c0-0.037,0.008-0.074,0.008-0.113c0.008-0.152-0.1-0.184-0.221-0.223c-0.046-0.015-0.106-0.029-0.152-0.06
               c-0.038-0.022-0.061-0.054-0.068-0.084v-0.045c0-0.017,0.008-0.031,0.016-0.048c0.022-0.029,0.053-0.051,0.091-0.061
               c0.062-0.021,0.099-0.005,0.137,0.016c0.039,0.016,0.084,0.039,0.168,0.031c0.062,0,0.092,0.037,0.121,0.092
               c0.039,0.045,0.077,0.099,0.139,0.144c0.106,0.069,0.167,0.046,0.266,0c0.046-0.021,0.113-0.054,0.206-0.067
               c0.099-0.006,0.167,0.076,0.228,0.151c0.039,0.039,0.069,0.076,0.107,0.101c0.061,0.037,0.122,0.029,0.175-0.062
               c0.046-0.069,0.062-0.123,0.076-0.161c0.038-0.074,0.054-0.105,0.221-0.175c0.114-0.054,0.198-0.038,0.267-0.022
               c0.084,0.022,0.16,0.039,0.267-0.029c0.053-0.031,0.099-0.061,0.145-0.1c0.106-0.084,0.206-0.16,0.351-0.16
               c0.197-0.008,0.364-0.197,0.479-0.326c0.03-0.047,0.061-0.076,0.084-0.092c0.007-0.008,0.015-0.017,0.015-0.022
               c0.031-0.038,0.031-0.084,0.016-0.13c-0.008-0.031-0.03-0.068-0.054-0.1c0.075-0.016,0.176-0.031,0.229-0.037h0.046
               c0.061-0.008,0.122-0.017,0.129,0.014v0.055c-0.015,0.047-0.022,0.092,0.016,0.176c0.023,0.075,0.016,0.152,0,0.213
               c-0.008,0.03-0.008,0.062-0.016,0.084c0,0.076,0.039,0.098,0.092,0.145c0.031,0.023,0.054,0.046,0.092,0.076
               c0.046,0.047,0.046,0.068,0.046,0.099c0,0.039,0,0.076,0.022,0.138c0.03,0.053,0.062,0.092,0.084,0.114
               c0.015,0.009,0.015,0.009,0.007,0.022c-0.029,0.039-0.068,0.107-0.113,0.191c-0.031,0.074-0.069,0.158-0.101,0.258
               c-0.037,0.129-0.045,0.213-0.053,0.297c0,0.061-0.007,0.129-0.022,0.213c-0.022,0.1-0.046,0.184-0.046,0.261
               c0,0.083,0.016,0.159,0.068,0.228l0.016,0.022c0.076,0.114,0.183,0.267,0.145,0.39c-0.007,0.022-0.015,0.053-0.015,0.074
               c-0.016,0.077-0.009,0.152,0.008,0.221c0.015,0.078,0.061,0.131,0.113,0.153c0.008,0,0.016,0,0.022,0.009h0.055
               c0.083,0.014,0.183,0.021,0.221,0.113l0.015,0.046c0.046,0.121,0.046,0.136,0.251,0.158c0.053,0.01,0.092,0.017,0.122,0.024
               c0.13,0.03,0.175,0.037,0.236-0.069c0.022-0.046,0.022-0.099,0.022-0.151v-0.046c0-0.03,0.016-0.045,0.061-0.045
               c0.068,0.006,0.084,0.015,0.092,0.021c0.038,0.023,0.054,0.031,0.189-0.021c0.101-0.03,0.176-0.101,0.26-0.168
               c0.091-0.084,0.183-0.168,0.281-0.161c0.123,0.008,0.176-0.029,0.214-0.061c0.022-0.016,0.046-0.03,0.092-0.023
               c0.067,0.01,0.145,0.023,0.229,0.049c0.083,0.014,0.182,0.035,0.266,0.059c0.076,0.016,0.176,0.039,0.26,0.047
               c0.099,0.008,0.174-0.018,0.197-0.084c0.015-0.055,0-0.092-0.023-0.137c-0.023-0.047-0.053-0.107-0.045-0.199
               c0-0.076,0.015-0.121,0.037-0.143c0.023-0.025,0.054-0.033,0.092-0.039c0.046-0.009,0.138-0.009,0.251,0
               c0.13,0,0.282,0.006,0.419-0.009c0.19-0.017,0.198-0.008,0.252,0.054c0.015,0.016,0.038,0.031,0.061,0.061
               c0.075,0.063,0.183,0.102,0.298,0.107c0.151,0.016,0.304-0.015,0.357-0.055c0.053-0.029,0.137-0.016,0.229,0.016
               c0.022,0,0.046,0.008,0.067,0.017c0.107,0.03,0.184,0.022,0.29,0.022h0.092c0.091,0,0.235-0.152,0.365-0.343
               c0.159-0.221,0.305-0.495,0.327-0.616c0.03-0.183,0.046-0.199,0.136-0.306l0.039-0.037c0.062-0.077,0.069-0.139,0.077-0.2
               c0.007-0.066,0.015-0.137,0.183-0.197C71.114,69.539,71.206,69.455,71.282,69.384"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M71.282,69.384c0.084,0.064,0.152,0.146,0.183,0.273c0.106,0.381,0.022,1.037,0.129,1.325c0.1,0.291,0.13,0.44,0.473,0.608
               c0.335,0.168,0.389,0.146,0.402,0.306c0.01,0.151,0.131,0.13-0.06,0.356c-0.19,0.229-0.335,0.336-0.533,0.595
               c-0.205,0.267-0.273,0.403-0.494,0.579c-0.213,0.166-0.389,0.388-0.435,0.496c-0.038,0.099-0.062,0.234-0.106,0.371
               c-0.053,0.146-0.19,0.291-0.389,0.358c-0.205,0.062-0.312,0.151-0.22,0.183c0.091,0.031,0.129,0.082,0.312,0.008
               c0.19-0.068,0.297-0.189,0.389-0.342c0.091-0.146,0.145-0.496,0.343-0.563c0.205-0.076,0.494-0.327,0.556-0.429
               c0.053-0.09,0.061-0.188,0.319-0.174c0.259,0.015,0.464,0,0.754,0.07c0.281,0.073,0.662,0.02,0.73,0.182
               c0.069,0.158-0.075,0.228-0.113,0.486c-0.039,0.266-0.252,0.596-0.389,0.67c-0.137,0.076-0.335,0.356-0.206,0.381
               c0.13,0.022,0.297-0.061,0.435-0.258c0.129-0.205,0.427-0.688,0.502-0.785c0.076-0.1,0.115-0.158,0.427-0.158
               c0.313-0.01,0.555,0.113,0.769,0.281c0.221,0.168,0.815,0.334,1.143,0.494c0.327,0.168,0.487,0.113,0.578,0.434
               c0.092,0.32,0.1,0.35,0.122,0.632c0.022,0.28,0.077,0.243,0.268,0.168c0.183-0.076,0.524-0.466,0.859-0.317
               c0.343,0.143-0.175,0.188,0.533,0.332c0.708,0.146,0.808,0.207,1.135,0.161c0.327-0.047,0.586-0.26,0.913-0.146
               c0.319,0.108,0.647,0.479,0.717,0.596c0.067,0.113,1.126,0.775,1.361,1.111c0.236,0.334,0.686,0.531,1.014,0.51
               c0.327-0.021,0.426-0.16,0.647,0.054c0.228,0.216,0.273,0.44,0.296,0.745c0.023,0.3,0.381,1.326,0.313,1.63
               c-0.067,0.306-0.076,1.065-0.404,1.401c-0.326,0.343-0.616,0.699-0.753,0.967c-0.137,0.273-0.267,0.32-0.518,0.541
               c-0.243,0.229-0.419,0.708-0.518,1.098c-0.1,0.379-0.457,0.967-0.571,0.858c-0.114-0.099-0.319-0.289-0.35-0.151
               c-0.031,0.131,0.03,0.549-0.069,0.784c-0.099,0.235-0.145,0.356-0.076,0.608c0.068,0.244,0.373,1.166,0.29,1.607
               c-0.076,0.44-0.244,0.608-0.26,0.942c-0.008,0.335,0.213,0.465,0.084,0.7c-0.137,0.236-0.419,0.244-0.396,0.578
               c0.022,0.345,0.281,0.883,0.136,1.127c-0.151,0.25-0.517,0.443-0.517,0.776c0,0.335-0.138,0.511-0.297,0.776
               c-0.152,0.268-0.267,0.427-0.198,0.654c0.068,0.222,0,0.291-0.137,0.426c-0.138,0.131-0.419,0.01-0.64,0.246
               c-0.229,0.234-0.381,0.318-0.541,0.349c-0.159,0.03-0.518-0.091-0.723-0.099c-0.198-0.016-0.617-0.092-0.647,0.115
               c-0.039,0.197,0.03,0.266-0.229,0.387c-0.259,0.123-0.267,0.23-0.762,0.359c-0.494,0.137-0.776,0.303-0.913,0.563
               c-0.138,0.258-0.281,0.281-0.472,0.578c-0.19,0.289-0.442,0.357-0.465,0.646c-0.023,0.297,0,0.602-0.038,0.77
               c-0.047,0.168,0.273,0.549,0.289,0.975c0.007,0.428-0.076,0.602-0.114,0.73c-0.03,0.137-0.068,0.113-0.313,0.396
               c-0.251,0.28-0.556,1.034-0.617,1.393c-0.067,0.359-0.045,0.404-0.258,0.709c-0.214,0.305-0.473,0.723-0.511,0.594
               c-0.037-0.129,0.1-0.139,0.206-0.344c0.114-0.213,0.046-0.318,0.138-0.426c0.09-0.107,0.189-0.486-0.008-0.525
               c-0.198-0.037-0.344-0.029-0.344,0.221c0.008,0.244,0.016,0.336-0.061,0.42c-0.068,0.084-0.198,0.176-0.29,0.318
               c-0.098,0.146-0.121,0.404-0.06,0.465c0.053,0.068,0.083,0.168,0.022,0.273c-0.054,0.106-0.145,0.139-0.122,0.289
               c0.023,0.146-0.008,0.353-0.099,0.596c-0.1,0.242-0.236,0.371-0.313,0.486c-0.016,0.022-0.03,0.037-0.045,0.063
               c-0.114-0.086-0.222-0.184-0.222-0.26c0.008-0.076,0.038-0.152,0.068-0.229c0.03-0.092,0.069-0.19,0.076-0.304
               c0.008-0.113-0.007-0.215-0.061-0.29c-0.046-0.076-0.122-0.129-0.235-0.168c-0.084-0.022-0.161-0.06-0.222-0.113
               c-0.067-0.062-0.123-0.13-0.16-0.206c-0.015-0.031,0-0.054,0-0.068c0.009-0.008,0.009-0.015,0.016-0.029
               c0.016-0.068-0.022-0.115-0.327-0.176c-0.289-0.061-0.419-0.184-0.533-0.297c-0.068-0.068-0.138-0.131-0.229-0.183
               c-0.235-0.139-0.319-0.215-0.411-0.299l-0.016-0.009c-0.068-0.059-0.106-0.036-0.159-0.006c-0.038,0.015-0.092,0.045-0.221-0.03
               c-0.123-0.077-0.138-0.112-0.16-0.151c-0.015-0.045-0.038-0.101-0.122-0.177c-0.038-0.03-0.076-0.067-0.121-0.106
               c-0.168-0.158-0.366-0.349-0.549-0.297c-0.114,0.039-0.152,0.069-0.175,0.092c-0.016,0.016-0.03,0.022-0.084,0.029
               c-0.039,0.01-0.076,0.01-0.114,0.01c0.023-0.023,0.038-0.054,0.061-0.092c0.031-0.063,0.054-0.123,0.069-0.183
               c0.029-0.084,0.053-0.151,0.084-0.168c0.045-0.022,0.112-0.16,0.205-0.328c0.113-0.213,0.267-0.493,0.396-0.623
               c0.076-0.076,0.146-0.131,0.206-0.177c0.13-0.112,0.229-0.189,0.305-0.395c0.015-0.048,0.03-0.084,0.037-0.115
               c0.054-0.166,0.069-0.205,0.26-0.297c0.152-0.068,0.503-0.259,0.724-0.457c0.159-0.145,0.259-0.289,0.228-0.434
               c-0.03-0.152-0.099-0.26-0.152-0.357c-0.061-0.099-0.106-0.184-0.106-0.283c0.008-0.067,0-0.151-0.022-0.233
               c-0.015-0.077-0.046-0.146-0.084-0.198c-0.038-0.062-0.099-0.1-0.167-0.107c-0.016,0-0.038,0-0.054,0.009
               c-0.054,0.015-0.099,0.015-0.145,0.022c-0.046,0-0.076,0.008-0.114,0.016c-0.023-0.063-0.069-0.184-0.062-0.297
               c0.008-0.084,0.016-0.152,0.031-0.229c0.007-0.068,0.03-0.15,0.061-0.252c0.008-0.029,0.016-0.053,0.016-0.074
               c0.045-0.146,0.052-0.176,0-0.343c-0.032-0.114-0.076-0.177-0.13-0.235c-0.03-0.047-0.068-0.092-0.106-0.16
               c-0.061-0.106-0.16-0.084-0.267-0.024c-0.037,0.024-0.076,0.046-0.121,0.076c-0.092,0.063-0.19,0.131-0.26,0.123
               c-0.091,0-0.145-0.007-0.167-0.046c-0.03-0.047-0.038-0.139-0.023-0.313c0.016-0.168-0.022-0.38-0.076-0.578
               c-0.075-0.274-0.183-0.533-0.243-0.588c-0.055-0.054-0.139-0.107-0.244-0.145c-0.091-0.038-0.197-0.068-0.319-0.076
               c-0.099-0.007-0.221,0.029-0.357,0.068c-0.184,0.045-0.404,0.106-0.609,0.063c-0.244-0.063-0.244-0.132-0.244-0.23
               c0-0.037,0-0.075-0.008-0.121c-0.015-0.092-0.046-0.273-0.068-0.457c-0.015-0.176-0.038-0.356-0.03-0.456
               c0.008-0.185-0.076-0.351-0.145-0.487c-0.015-0.031-0.03-0.055-0.038-0.076c-0.038-0.084-0.083-0.205-0.083-0.32
               c0-0.084,0.015-0.16,0.061-0.221c0.061-0.066,0.114-0.26,0.152-0.473c0.061-0.325,0.099-0.723,0.091-0.82
               c0-0.086-0.022-0.16-0.053-0.236c-0.023-0.069-0.046-0.139-0.062-0.223c-0.03-0.15-0.145-0.212-0.267-0.287
               c-0.068-0.039-0.137-0.084-0.197-0.139c-0.076-0.076-0.137-0.145-0.176-0.213c-0.045-0.076-0.068-0.152-0.091-0.244
               c-0.008-0.076,0-0.129,0-0.184c0.007-0.098,0.015-0.189-0.061-0.334c-0.084-0.16-0.442-0.145-0.808-0.123
               c-0.221,0.016-0.449,0.029-0.602,0c-0.267-0.053-0.244-0.518-0.229-0.723c0-0.068,0-0.113-0.008-0.138
               c-0.008-0.03-0.037-0.112-0.037-0.188c0-0.031,0.007-0.053,0.022-0.063c0.114-0.076,0.061-0.196,0.007-0.326l-0.015-0.022
               c-0.022-0.053-0.055-0.131-0.069-0.197c-0.022-0.068-0.029-0.137-0.022-0.199c0.008-0.113-0.053-0.213-0.152-0.355
               c-0.03-0.055-0.067-0.107-0.106-0.176c-0.137-0.236-0.389-0.184-0.548-0.146c-0.023,0-0.054,0.009-0.068,0.009
               c-0.068,0.007-0.146,0.007-0.221-0.022c-0.054-0.022-0.116-0.062-0.168-0.123c-0.1-0.112-0.213-0.129-0.343-0.145
               c-0.062-0.007-0.13-0.016-0.191-0.029c-0.129-0.039-0.151-0.092-0.182-0.168c-0.022-0.037-0.046-0.084-0.076-0.137
               c-0.076-0.107-0.152-0.121-0.235-0.131c-0.062-0.008-0.122-0.021-0.175-0.084c-0.13-0.15-0.344-0.15-0.488-0.15H61.36
               c-0.054,0-0.122-0.016-0.19-0.046s-0.145-0.062-0.221-0.1c-0.068-0.03-0.129-0.068-0.175-0.106
               c-0.046-0.046-0.092-0.113-0.122-0.213c-0.061-0.16-0.137-0.184-0.236-0.215c-0.053-0.016-0.106-0.035-0.167-0.082
               c-0.092-0.076-0.114-0.199-0.13-0.313c-0.008-0.067-0.022-0.129-0.046-0.183c-0.03-0.062-0.045-0.13-0.045-0.19
               c-0.008-0.06,0-0.129,0.03-0.197c0.053-0.122,0-0.252-0.046-0.357c-0.016-0.054-0.038-0.092-0.038-0.129
               c-0.007-0.039,0-0.092,0.008-0.154c0.008-0.113,0.022-0.233-0.03-0.326c-0.076-0.137-0.305-0.06-0.464-0.006
               c-0.031,0.015-0.055,0.021-0.069,0.021c-0.038,0.017-0.069,0.03-0.115,0.045c-0.113,0.048-0.28,0.115-0.547,0.138
               c-0.282,0.023-0.366,0.221-0.427,0.367c-0.023,0.059-0.038,0.105-0.061,0.129c-0.047,0.039-0.084,0.061-0.13,0.067
               c-0.038,0.01-0.083,0-0.152-0.015c-0.114-0.022-0.167,0.069-0.221,0.168c-0.038,0.067-0.084,0.129-0.131,0.137
               c-0.09,0.021-0.12,0.055-0.159,0.091c-0.029,0.03-0.061,0.062-0.129,0.077c-0.099,0.021-0.13,0.016-0.175-0.008
               c-0.022-0.016-0.061-0.03-0.106-0.047c-0.13-0.029-0.297,0.055-0.373,0.1c-0.03-0.045-0.069-0.077-0.107-0.105
               c-0.083-0.045-0.189-0.045-0.304-0.039c-0.13,0.008-0.274,0.017-0.412-0.062c-0.191-0.099-0.191-0.188-0.197-0.372
               c0-0.053-0.008-0.113-0.008-0.177c-0.015-0.128-0.008-0.228,0-0.312c0.008-0.16,0.014-0.258-0.091-0.305
               c-0.092-0.046-0.176,0.016-0.29,0.1c-0.061,0.045-0.144,0.105-0.243,0.158c-0.19,0.084-0.327,0.063-0.472,0.031
               c-0.046-0.008-0.092-0.023-0.138-0.023c-0.084-0.016-0.091-0.09-0.091-0.166c0-0.068-0.008-0.138-0.039-0.199
               c-0.061-0.104-0.235-0.121-0.396-0.137c-0.091-0.008-0.175-0.008-0.229-0.029c-0.052-0.021-0.029-0.076-0.014-0.139
               c0.022-0.06,0.045-0.121,0.045-0.197c0-0.113-0.129-0.25-0.266-0.395c-0.115-0.131-0.236-0.268-0.281-0.382
               c-0.039-0.122-0.115-0.213-0.184-0.297c-0.076-0.099-0.152-0.19-0.176-0.336c-0.022-0.234,0.152-0.441,0.268-0.578l0.03-0.03
               c0.015-0.021,0.03-0.047,0.053-0.063c0.092-0.113,0.168-0.197,0.16-0.373c-0.009-0.066,0.022-0.137,0.046-0.211
               c0.046-0.107,0.092-0.229,0.092-0.404c0-0.176,0.053-0.251,0.129-0.357c0.046-0.068,0.098-0.152,0.152-0.273
               c0.106-0.223,0.495-0.282,0.792-0.336c0.083-0.016,0.16-0.021,0.22-0.037c0.198-0.047,0.291-0.152,0.373-0.236
               c0.039-0.044,0.077-0.092,0.115-0.107c0.046-0.006,0.137,0,0.243,0.017c0.152,0.022,0.327,0.054,0.441-0.017
               c0.077-0.045,0.145-0.342,0.206-0.713c0.098-0.647,0.182-1.539,0.182-1.625v-0.144c0-0.159,0.009-0.388,0-0.608
               c-0.007-0.184-0.12-0.268-0.235-0.356c-0.1-0.077-0.205-0.161-0.236-0.306c-0.068-0.282,0.009-0.473,0.054-0.588
               c0-0.021,0.008-0.037,0.016-0.053c0.022-0.061,0.084-0.076,0.267-0.1c0.038-0.008,0.084-0.016,0.129-0.021
               c0.305-0.055,0.32-0.145,0.297-0.289c0-0.016-0.008-0.031-0.008-0.047V72.4c-0.029-0.13-0.251-0.13-0.441-0.13
               c-0.106,0-0.205,0-0.235-0.015c0,0-0.008-0.008-0.008-0.016c-0.016-0.031-0.016-0.091-0.008-0.16
               c0.016-0.067,0.038-0.144,0.068-0.205c0.023-0.045,0.046-0.084,0.076-0.092c0.062-0.031,0.32-0.039,0.586-0.039
               c0.237-0.006,0.48-0.015,0.603-0.037c0.129-0.021,0.259-0.062,0.38-0.105c0.113-0.055,0.221-0.105,0.298-0.161
               c0.037-0.028,0.075-0.035,0.105-0.045c0.039,0.067,0.13,0.206,0.206,0.274c0.038,0.029,0.023,0.074,0,0.151
               c-0.022,0.068-0.046,0.146-0.061,0.259c-0.016,0.153,0.03,0.199,0.105,0.205c0.031,0,0.069-0.006,0.101-0.016
               c0.037-0.008,0.083-0.016,0.113-0.008c0.054,0.008,0.1,0.076,0.146,0.154c0.045,0.074,0.098,0.158,0.167,0.197
               c0.138,0.084,0.182,0.045,0.289-0.048c0.023-0.015,0.054-0.035,0.092-0.065c0.061-0.047,0.099-0.01,0.152,0.051
               c0.046,0.048,0.106,0.115,0.205,0.162c0.106,0.052,0.168,0.029,0.197-0.039c0.024-0.039,0.031-0.092,0.038-0.152
               c0.017-0.075,0.023-0.159,0.069-0.236c0.061-0.099,0.16-0.099,0.267-0.09c0.076,0,0.152,0,0.244-0.023
               c0.151-0.053,0.189-0.168,0.235-0.318c0.016-0.068,0.046-0.146,0.083-0.229c0.055-0.101,0.161-0.121,0.26-0.146
               c0.114-0.029,0.221-0.053,0.281-0.166c0.061-0.105,0.038-0.169-0.03-0.205c-0.046-0.031-0.129-0.039-0.213-0.047
               c-0.061-0.008-0.13-0.008-0.19-0.021c-0.076-0.016-0.084-0.055-0.076-0.092c0.008-0.023,0.016-0.063,0.029-0.092
               c0.017-0.039,0.039-0.084,0.047-0.129c0.022-0.152-0.03-0.223-0.114-0.318c-0.017-0.018-0.03-0.039-0.046-0.064
               c-0.068-0.075-0.055-0.188-0.046-0.305c0-0.037,0.008-0.074,0.008-0.113c0.008-0.152-0.1-0.184-0.221-0.223
               c-0.046-0.015-0.106-0.029-0.152-0.06c-0.038-0.022-0.061-0.054-0.068-0.084v-0.045c0-0.017,0.008-0.031,0.016-0.048
               c0.022-0.029,0.053-0.051,0.091-0.061c0.062-0.021,0.099-0.005,0.137,0.016c0.039,0.016,0.084,0.039,0.168,0.031
               c0.062,0,0.092,0.037,0.121,0.092c0.039,0.045,0.077,0.099,0.139,0.144c0.106,0.069,0.167,0.046,0.266,0
               c0.046-0.021,0.113-0.054,0.206-0.067c0.099-0.006,0.167,0.076,0.228,0.151c0.039,0.039,0.069,0.076,0.107,0.101
               c0.061,0.037,0.122,0.029,0.175-0.062c0.046-0.069,0.062-0.123,0.076-0.161c0.038-0.074,0.054-0.105,0.221-0.175
               c0.114-0.054,0.198-0.038,0.267-0.022c0.084,0.022,0.16,0.039,0.267-0.029c0.053-0.031,0.099-0.061,0.145-0.1
               c0.106-0.084,0.206-0.16,0.351-0.16c0.197-0.008,0.364-0.197,0.479-0.326c0.03-0.047,0.061-0.076,0.084-0.092
               c0.007-0.008,0.015-0.017,0.015-0.022c0.031-0.038,0.031-0.084,0.016-0.13c-0.008-0.031-0.03-0.068-0.054-0.1
               c0.075-0.016,0.176-0.031,0.229-0.037h0.046c0.061-0.008,0.122-0.017,0.129,0.014v0.055c-0.015,0.047-0.022,0.092,0.016,0.176
               c0.023,0.075,0.016,0.152,0,0.213c-0.008,0.03-0.008,0.062-0.016,0.084c0,0.076,0.039,0.098,0.092,0.145
               c0.031,0.023,0.054,0.046,0.092,0.076c0.046,0.047,0.046,0.068,0.046,0.099c0,0.039,0,0.076,0.022,0.138
               c0.03,0.053,0.062,0.092,0.084,0.114c0.015,0.009,0.015,0.009,0.007,0.022c-0.029,0.039-0.068,0.107-0.113,0.191
               c-0.031,0.074-0.069,0.158-0.101,0.258c-0.037,0.129-0.045,0.213-0.053,0.297c0,0.061-0.007,0.129-0.022,0.213
               c-0.022,0.1-0.046,0.184-0.046,0.261c0,0.083,0.016,0.159,0.068,0.228l0.016,0.022c0.076,0.114,0.183,0.267,0.145,0.39
               c-0.007,0.022-0.015,0.053-0.015,0.074c-0.016,0.077-0.009,0.152,0.008,0.221c0.015,0.078,0.061,0.131,0.113,0.153
               c0.008,0,0.016,0,0.022,0.009h0.055c0.083,0.014,0.183,0.021,0.221,0.113l0.015,0.046c0.046,0.121,0.046,0.136,0.251,0.158
               c0.053,0.01,0.092,0.017,0.122,0.024c0.13,0.03,0.175,0.037,0.236-0.069c0.022-0.046,0.022-0.099,0.022-0.151v-0.046
               c0-0.03,0.016-0.045,0.061-0.045c0.068,0.006,0.084,0.015,0.092,0.021c0.038,0.023,0.054,0.031,0.189-0.021
               c0.101-0.03,0.176-0.101,0.26-0.168c0.091-0.084,0.183-0.168,0.281-0.161c0.123,0.008,0.176-0.029,0.214-0.061
               c0.022-0.016,0.046-0.03,0.092-0.023c0.067,0.01,0.145,0.023,0.229,0.049c0.083,0.014,0.182,0.035,0.266,0.059
               c0.076,0.016,0.176,0.039,0.26,0.047c0.099,0.008,0.174-0.018,0.197-0.084c0.015-0.055,0-0.092-0.023-0.137
               c-0.023-0.047-0.053-0.107-0.045-0.199c0-0.076,0.015-0.121,0.037-0.143c0.023-0.025,0.054-0.033,0.092-0.039
               c0.046-0.009,0.138-0.009,0.251,0c0.13,0,0.282,0.006,0.419-0.009c0.19-0.017,0.198-0.008,0.252,0.054
               c0.015,0.016,0.038,0.031,0.061,0.061c0.075,0.063,0.183,0.102,0.298,0.107c0.151,0.016,0.304-0.015,0.357-0.055
               c0.053-0.029,0.137-0.016,0.229,0.016c0.022,0,0.046,0.008,0.067,0.017c0.107,0.03,0.184,0.022,0.29,0.022h0.092
               c0.091,0,0.235-0.152,0.365-0.343c0.159-0.221,0.305-0.495,0.327-0.616c0.03-0.183,0.046-0.199,0.136-0.306l0.039-0.037
               c0.062-0.077,0.069-0.139,0.077-0.2c0.007-0.066,0.015-0.137,0.183-0.197C71.114,69.539,71.206,69.455,71.282,69.384z"/>
             <path fill="#808184" d="M69.09,67.862c0.03,0.017,0.061,0.031,0.084,0.038
               c0.213,0.131,0.259,0.16,0.563,0.307c0.306,0.144,0.8,0.539,0.998,0.813c0.114,0.146,0.305,0.213,0.457,0.306
               c-0.062,0.061-0.138,0.121-0.252,0.166c-0.221,0.092-0.235,0.184-0.244,0.281c-0.007,0.046-0.015,0.1-0.061,0.146l-0.029,0.037
               c-0.115,0.129-0.122,0.146-0.162,0.351c-0.021,0.114-0.158,0.366-0.311,0.579c-0.114,0.166-0.229,0.304-0.281,0.304h-0.099
               c-0.101,0-0.161,0-0.26-0.022c-0.022-0.008-0.045-0.008-0.061-0.015c-0.122-0.03-0.221-0.062-0.313,0
               c-0.038,0.029-0.168,0.046-0.297,0.037c-0.084-0.008-0.16-0.03-0.213-0.067c0.076-0.076,0.25-0.267,0.281-0.373
               c0.023-0.068,0.016-0.113,0.008-0.152c0-0.045-0.008-0.076,0.03-0.137l0.022-0.039c0.075-0.121,0.115-0.182,0.115-0.403
               c0-0.144-0.04-0.183-0.107-0.25c-0.045-0.038-0.106-0.099-0.19-0.244c-0.106-0.188-0.083-0.272-0.068-0.388
               c0.016-0.068,0.031-0.152,0.023-0.281c-0.023-0.207,0.037-0.252,0.092-0.298c0.029-0.015,0.053-0.038,0.068-0.062
               c0.075-0.1,0.166-0.22,0.137-0.449C69.014,67.984,69.044,67.923,69.09,67.862"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M69.09,67.862
               c0.03,0.017,0.061,0.031,0.084,0.038c0.213,0.131,0.259,0.16,0.563,0.307c0.306,0.144,0.8,0.539,0.998,0.813
               c0.114,0.146,0.305,0.213,0.457,0.306c-0.062,0.061-0.138,0.121-0.252,0.166c-0.221,0.092-0.235,0.184-0.244,0.281
               c-0.007,0.046-0.015,0.1-0.061,0.146l-0.029,0.037c-0.115,0.129-0.122,0.146-0.162,0.351c-0.021,0.114-0.158,0.366-0.311,0.579
               c-0.114,0.166-0.229,0.304-0.281,0.304h-0.099c-0.101,0-0.161,0-0.26-0.022c-0.022-0.008-0.045-0.008-0.061-0.015
               c-0.122-0.03-0.221-0.062-0.313,0c-0.038,0.029-0.168,0.046-0.297,0.037c-0.084-0.008-0.16-0.03-0.213-0.067
               c0.076-0.076,0.25-0.267,0.281-0.373c0.023-0.068,0.016-0.113,0.008-0.152c0-0.045-0.008-0.076,0.03-0.137l0.022-0.039
               c0.075-0.121,0.115-0.182,0.115-0.403c0-0.144-0.04-0.183-0.107-0.25c-0.045-0.038-0.106-0.099-0.19-0.244
               c-0.106-0.188-0.083-0.272-0.068-0.388c0.016-0.068,0.031-0.152,0.023-0.281c-0.023-0.207,0.037-0.252,0.092-0.298
               c0.029-0.015,0.053-0.038,0.068-0.062c0.075-0.1,0.166-0.22,0.137-0.449C69.014,67.984,69.044,67.923,69.09,67.862z"/>
             <path fill="#808184" d="M66.874,71.487c-0.03-0.062-0.121-0.267-0.205-0.327
               c-0.031-0.023-0.031-0.053-0.038-0.092c-0.008-0.063-0.023-0.145-0.092-0.258c-0.068-0.131-0.114-0.281-0.152-0.413
               c-0.022-0.083-0.038-0.159-0.068-0.22c-0.047-0.1-0.1-0.122-0.167-0.146c-0.054-0.016-0.107-0.037-0.176-0.113
               s-0.114-0.168-0.145-0.25c-0.03-0.084-0.046-0.176-0.053-0.26c-0.009-0.068,0.022-0.139,0.053-0.191
               c0.038-0.082,0.076-0.15,0.03-0.242c-0.022-0.047-0.03-0.084-0.009-0.121c0.024-0.047,0.077-0.084,0.168-0.139
               c0.069-0.037,0.122-0.047,0.169-0.053c0.091-0.022,0.152-0.039,0.251-0.168c0.084-0.129,0.067-0.16,0.03-0.236
               c-0.022-0.045-0.054-0.105-0.046-0.26c0-0.053,0.008-0.099,0.016-0.15c0.053,0.029,0.106,0.053,0.167,0.053
               c0.381,0.039,0.473-0.121,0.822-0.084c0.351,0.031,0.465-0.229,0.708-0.15c0.198,0.062,0.587,0.099,0.854,0.166
               c-0.046,0.078-0.084,0.152-0.069,0.229c0.023,0.184-0.053,0.289-0.114,0.373c-0.015,0.016-0.03,0.029-0.053,0.046
               c-0.077,0.054-0.16,0.122-0.13,0.39c0.008,0.105-0.008,0.182-0.022,0.25c-0.023,0.131-0.046,0.229,0.083,0.449
               c0.084,0.16,0.153,0.229,0.206,0.273c0.045,0.047,0.076,0.068,0.076,0.175c0,0.19-0.038,0.245-0.099,0.344v0.009l-0.023,0.028
               v0.009c-0.061,0.092-0.053,0.145-0.045,0.205c0.007,0.031,0.007,0.063,0,0.105c-0.031,0.094-0.19,0.26-0.259,0.336l-0.008-0.008
               c-0.092-0.092-0.092-0.1-0.336-0.076c-0.138,0.008-0.289,0.008-0.411,0c-0.113,0-0.213-0.008-0.273,0.008
               c-0.054,0.016-0.106,0.021-0.145,0.068c-0.038,0.037-0.062,0.099-0.068,0.205c-0.008,0.123,0.03,0.189,0.052,0.252
               c0.017,0.021,0.032,0.054,0.024,0.068c0,0.008-0.039,0.008-0.092,0c-0.076,0-0.168-0.023-0.243-0.037
               C66.988,71.517,66.928,71.501,66.874,71.487"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M66.874,71.487c-0.03-0.062-0.121-0.267-0.205-0.327c-0.031-0.023-0.031-0.053-0.038-0.092
               c-0.008-0.063-0.023-0.145-0.092-0.258c-0.068-0.131-0.114-0.281-0.152-0.413c-0.022-0.083-0.038-0.159-0.068-0.22
               c-0.047-0.1-0.1-0.122-0.167-0.146c-0.054-0.016-0.107-0.037-0.176-0.113s-0.114-0.168-0.145-0.25
               c-0.03-0.084-0.046-0.176-0.053-0.26c-0.009-0.068,0.022-0.139,0.053-0.191c0.038-0.082,0.076-0.15,0.03-0.242
               c-0.022-0.047-0.03-0.084-0.009-0.121c0.024-0.047,0.077-0.084,0.168-0.139c0.069-0.037,0.122-0.047,0.169-0.053
               c0.091-0.022,0.152-0.039,0.251-0.168c0.084-0.129,0.067-0.16,0.03-0.236c-0.022-0.045-0.054-0.105-0.046-0.26
               c0-0.053,0.008-0.099,0.016-0.15c0.053,0.029,0.106,0.053,0.167,0.053c0.381,0.039,0.473-0.121,0.822-0.084
               c0.351,0.031,0.465-0.229,0.708-0.15c0.198,0.062,0.587,0.099,0.854,0.166c-0.046,0.078-0.084,0.152-0.069,0.229
               c0.023,0.184-0.053,0.289-0.114,0.373c-0.015,0.016-0.03,0.029-0.053,0.046c-0.077,0.054-0.16,0.122-0.13,0.39
               c0.008,0.105-0.008,0.182-0.022,0.25c-0.023,0.131-0.046,0.229,0.083,0.449c0.084,0.16,0.153,0.229,0.206,0.273
               c0.045,0.047,0.076,0.068,0.076,0.175c0,0.19-0.038,0.245-0.099,0.344v0.009l-0.023,0.028v0.009
               c-0.061,0.092-0.053,0.145-0.045,0.205c0.007,0.031,0.007,0.063,0,0.105c-0.031,0.094-0.19,0.26-0.259,0.336l-0.008-0.008
               c-0.092-0.092-0.092-0.1-0.336-0.076c-0.138,0.008-0.289,0.008-0.411,0c-0.113,0-0.213-0.008-0.273,0.008
               c-0.054,0.016-0.106,0.021-0.145,0.068c-0.038,0.037-0.062,0.099-0.068,0.205c-0.008,0.123,0.03,0.189,0.052,0.252
               c0.017,0.021,0.032,0.054,0.024,0.068c0,0.008-0.039,0.008-0.092,0c-0.076,0-0.168-0.023-0.243-0.037
               C66.988,71.517,66.928,71.501,66.874,71.487z"/>
             <path fill="#808184" d="M64.445,65.525l0.008,0.008c0.068,0.076,0.563,0.197,0.799,0.57
               s-0.152,0.701,0.168,0.854c0.313,0.16,0.365-0.021,0.518,0.183c0.106,0.146,0.214,0.473,0.411,0.647
               c-0.015,0.068-0.023,0.137-0.03,0.205c0,0.184,0.038,0.26,0.062,0.313c0.022,0.038,0.029,0.053-0.023,0.129
               c-0.076,0.099-0.122,0.114-0.19,0.122c-0.054,0.016-0.114,0.031-0.197,0.068c-0.107,0.062-0.168,0.121-0.207,0.183
               c-0.037,0.067-0.029,0.138,0.008,0.214c0.023,0.046-0.008,0.091-0.03,0.15c-0.038,0.07-0.076,0.154-0.061,0.252
               c0.008,0.093,0.022,0.184,0.061,0.275c0.03,0.098,0.084,0.197,0.16,0.288c0.084,0.099,0.152,0.122,0.213,0.146
               c0.046,0.017,0.084,0.021,0.114,0.082c0.023,0.063,0.046,0.131,0.061,0.215c0.038,0.137,0.084,0.289,0.168,0.427
               c0.053,0.104,0.069,0.175,0.077,0.228c0.007,0.062,0.015,0.106,0.075,0.152c0.046,0.04,0.107,0.153,0.146,0.229
               c-0.077-0.014-0.153-0.029-0.214-0.037c-0.091-0.016-0.122,0.008-0.168,0.037c-0.022,0.023-0.061,0.047-0.152,0.047
               c-0.137-0.008-0.243,0.084-0.35,0.183c-0.076,0.062-0.145,0.122-0.221,0.153c-0.1,0.029-0.1,0.029-0.106,0.021
               c-0.016-0.008-0.046-0.031-0.137-0.031c-0.138-0.006-0.161,0.055-0.168,0.139v0.053c0,0.039,0,0.076-0.016,0.107
               c-0.023,0.037-0.053,0.029-0.122,0.016c-0.03-0.008-0.075-0.016-0.129-0.023c-0.145-0.022-0.145-0.022-0.175-0.1
               c0-0.007-0.009-0.021-0.016-0.045c-0.062-0.146-0.19-0.16-0.297-0.176c-0.022,0-0.046,0-0.054-0.008
               c-0.022-0.008-0.045-0.037-0.053-0.084c-0.015-0.043-0.015-0.113-0.008-0.176c0-0.021,0.008-0.037,0.016-0.062
               c0.053-0.166-0.068-0.343-0.16-0.472l-0.016-0.03c-0.037-0.045-0.054-0.106-0.054-0.166c0.008-0.069,0.023-0.146,0.046-0.235
               c0.016-0.093,0.023-0.162,0.031-0.229c0-0.083,0.007-0.159,0.046-0.272c0.029-0.092,0.061-0.176,0.099-0.244
               c0.03-0.076,0.068-0.139,0.099-0.177c0.061-0.083,0.03-0.112-0.022-0.159h0.008c-0.023-0.016-0.047-0.037-0.069-0.084
               c-0.015-0.037-0.015-0.068-0.015-0.092c0-0.052,0-0.092-0.076-0.166c-0.038-0.039-0.068-0.068-0.1-0.084
               c-0.029-0.029-0.046-0.046-0.046-0.068c0-0.016,0-0.039,0.008-0.061c0.016-0.077,0.031-0.176-0.008-0.273
               c-0.022-0.063-0.015-0.092-0.007-0.115c0-0.03,0.007-0.061-0.008-0.106c-0.03-0.106-0.129-0.099-0.229-0.092
               c-0.016,0.008-0.022,0.008-0.046,0.008c-0.067,0-0.229,0.031-0.297,0.048c-0.062-0.07-0.144-0.146-0.251-0.223
               c-0.229-0.174-0.229-0.305-0.236-0.411v-0.03c0-0.054,0.023-0.084,0.054-0.121c0.03-0.045,0.068-0.093,0.083-0.17
               c0.023-0.066,0.023-0.119,0.023-0.172c0-0.056-0.008-0.101,0.03-0.14v-0.007c0.062-0.084,0.099-0.137,0.305-0.176
               c0.114-0.016,0.19-0.045,0.251-0.084c0.062-0.037,0.092-0.082,0.13-0.151c0.038-0.084,0.022-0.13-0.03-0.16
               c-0.031-0.008-0.062-0.015-0.106-0.022c-0.039-0.008-0.084-0.016-0.138-0.021c-0.046-0.016-0.053-0.031-0.053-0.039
               c0-0.007,0.007-0.029,0.022-0.045h-0.008c0.023-0.039,0.038-0.077,0.046-0.123c0.008-0.1,0.076-0.173,0.183-0.303l0.022-0.031
               c0.062-0.076,0.138-0.113,0.214-0.152c0.062-0.031,0.129-0.066,0.183-0.13C64.339,65.646,64.392,65.585,64.445,65.525"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M64.445,65.525l0.008,0.008c0.068,0.076,0.563,0.197,0.799,0.57s-0.152,0.701,0.168,0.854c0.313,0.16,0.365-0.021,0.518,0.183
               c0.106,0.146,0.214,0.473,0.411,0.647c-0.015,0.068-0.023,0.137-0.03,0.205c0,0.184,0.038,0.26,0.062,0.313
               c0.022,0.038,0.029,0.053-0.023,0.129c-0.076,0.099-0.122,0.114-0.19,0.122c-0.054,0.016-0.114,0.031-0.197,0.068
               c-0.107,0.062-0.168,0.121-0.207,0.183c-0.037,0.067-0.029,0.138,0.008,0.214c0.023,0.046-0.008,0.091-0.03,0.15
               c-0.038,0.07-0.076,0.154-0.061,0.252c0.008,0.093,0.022,0.184,0.061,0.275c0.03,0.098,0.084,0.197,0.16,0.288
               c0.084,0.099,0.152,0.122,0.213,0.146c0.046,0.017,0.084,0.021,0.114,0.082c0.023,0.063,0.046,0.131,0.061,0.215
               c0.038,0.137,0.084,0.289,0.168,0.427c0.053,0.104,0.069,0.175,0.077,0.228c0.007,0.062,0.015,0.106,0.075,0.152
               c0.046,0.04,0.107,0.153,0.146,0.229c-0.077-0.014-0.153-0.029-0.214-0.037c-0.091-0.016-0.122,0.008-0.168,0.037
               c-0.022,0.023-0.061,0.047-0.152,0.047c-0.137-0.008-0.243,0.084-0.35,0.183c-0.076,0.062-0.145,0.122-0.221,0.153
               c-0.1,0.029-0.1,0.029-0.106,0.021c-0.016-0.008-0.046-0.031-0.137-0.031c-0.138-0.006-0.161,0.055-0.168,0.139v0.053
               c0,0.039,0,0.076-0.016,0.107c-0.023,0.037-0.053,0.029-0.122,0.016c-0.03-0.008-0.075-0.016-0.129-0.023
               c-0.145-0.022-0.145-0.022-0.175-0.1c0-0.007-0.009-0.021-0.016-0.045c-0.062-0.146-0.19-0.16-0.297-0.176
               c-0.022,0-0.046,0-0.054-0.008c-0.022-0.008-0.045-0.037-0.053-0.084c-0.015-0.043-0.015-0.113-0.008-0.176
               c0-0.021,0.008-0.037,0.016-0.062c0.053-0.166-0.068-0.343-0.16-0.472l-0.016-0.03c-0.037-0.045-0.054-0.106-0.054-0.166
               c0.008-0.069,0.023-0.146,0.046-0.235c0.016-0.093,0.023-0.162,0.031-0.229c0-0.083,0.007-0.159,0.046-0.272
               c0.029-0.092,0.061-0.176,0.099-0.244c0.03-0.076,0.068-0.139,0.099-0.177c0.061-0.083,0.03-0.112-0.022-0.159h0.008
               c-0.023-0.016-0.047-0.037-0.069-0.084c-0.015-0.037-0.015-0.068-0.015-0.092c0-0.052,0-0.092-0.076-0.166
               c-0.038-0.039-0.068-0.068-0.1-0.084c-0.029-0.029-0.046-0.046-0.046-0.068c0-0.016,0-0.039,0.008-0.061
               c0.016-0.077,0.031-0.176-0.008-0.273c-0.022-0.063-0.015-0.092-0.007-0.115c0-0.03,0.007-0.061-0.008-0.106
               c-0.03-0.106-0.129-0.099-0.229-0.092c-0.016,0.008-0.022,0.008-0.046,0.008c-0.067,0-0.229,0.031-0.297,0.048
               c-0.062-0.07-0.144-0.146-0.251-0.223c-0.229-0.174-0.229-0.305-0.236-0.411v-0.03c0-0.054,0.023-0.084,0.054-0.121
               c0.03-0.045,0.068-0.093,0.083-0.17c0.023-0.066,0.023-0.119,0.023-0.172c0-0.056-0.008-0.101,0.03-0.14v-0.007
               c0.062-0.084,0.099-0.137,0.305-0.176c0.114-0.016,0.19-0.045,0.251-0.084c0.062-0.037,0.092-0.082,0.13-0.151
               c0.038-0.084,0.022-0.13-0.03-0.16c-0.031-0.008-0.062-0.015-0.106-0.022c-0.039-0.008-0.084-0.016-0.138-0.021
               c-0.046-0.016-0.053-0.031-0.053-0.039c0-0.007,0.007-0.029,0.022-0.045h-0.008c0.023-0.039,0.038-0.077,0.046-0.123
               c0.008-0.1,0.076-0.173,0.183-0.303l0.022-0.031c0.062-0.076,0.138-0.113,0.214-0.152c0.062-0.031,0.129-0.066,0.183-0.13
               C64.339,65.646,64.392,65.585,64.445,65.525z"/>
             <path fill="#808184" d="M54.555,62.67c0,0.015,0.007,0.021,0.016,0.037
               c0.106,0.146,0.099,0.32,0.183,0.48c0.092,0.159-0.305,0.662-0.358,0.852c-0.046,0.184,0.068,0.321,0.122,0.451
               c0.062,0.12-0.03,0.395,0.267,0.326c0.297-0.068,0.319-0.184,0.342-0.487c0.023-0.306-0.136-0.542-0.22-0.784
               c-0.084-0.252-0.054-0.389,0.084-0.518c0.145-0.129,0.479-0.298,0.699-0.336c0.222-0.038,0.306-0.029,0.306-0.145
               c0-0.114-0.146-0.463,0.007-0.579c0.16-0.121,0.267-0.166,0.327,0c0.062,0.168-0.243,0.328-0.183,0.503
               c0.054,0.167,0.084,0.167,0.229,0.122c0.145-0.053,0.297-0.107,0.557-0.007c0.267,0.099,0.524,0.289,0.556,0.502
               c0.03,0.22-0.076,0.304,0.121,0.396c0.19,0.084,0.716-0.229,1.257-0.107c0.533,0.123,0.479,0.138,0.724,0.312
               c0.243,0.183,0.502,0.253,0.745,0.169s0.442-0.511,0.808-0.48c0.366,0.031,0.472,0.015,0.724-0.015
               c0.251-0.038,0.495-0.19,0.556-0.016c0.061,0.174,0.061,0.184-0.205,0.229c-0.26,0.046-0.404,0.251-0.252,0.327
               c0.152,0.068,0.206,0.023,0.465,0.19c0.259,0.176,0.457-0.054,0.616,0.039c0.16,0.091,0.335,0.205,0.448,0.351
               c0.123,0.135,0.207,0.182,0.017,0.326c-0.183,0.145-0.412,0.299-0.213,0.357c0.189,0.068,0.243-0.105,0.373,0
               c0.13,0.113,0.282,0.039,0.396,0.045c0.084,0.008,0.221,0.139,0.313,0.235c-0.054,0.062-0.114,0.132-0.152,0.177
               c-0.046,0.053-0.107,0.076-0.168,0.105c-0.076,0.046-0.16,0.092-0.235,0.176l-0.022,0.03c-0.123,0.146-0.199,0.229-0.207,0.356
               c-0.007,0.031-0.022,0.069-0.037,0.092c-0.008,0.023-0.016,0.047-0.023,0.063c-0.015,0.075,0,0.129,0.129,0.159
               c0.062,0.022,0.107,0.022,0.146,0.03c0.038,0.008,0.068,0.008,0.076,0.016c0.008,0-0.008,0.008-0.016,0.021
               c-0.03,0.055-0.053,0.093-0.099,0.115c-0.038,0.029-0.107,0.053-0.213,0.068c-0.244,0.045-0.282,0.105-0.365,0.213v0.008
               c-0.054,0.068-0.054,0.129-0.054,0.198c0,0.045,0,0.091-0.016,0.144c-0.015,0.062-0.038,0.1-0.068,0.139
               c-0.038,0.053-0.076,0.099-0.068,0.184v0.037c0,0.131,0.008,0.281,0.274,0.486c0.121,0.093,0.221,0.184,0.281,0.259
               c0.03,0.038,0.053,0.069,0.061,0.101c0.008,0.016,0.008,0.029,0.008,0.039H63.6c-0.023,0.021-0.053,0.061-0.099,0.105
               c-0.099,0.113-0.251,0.289-0.396,0.298c-0.184,0-0.298,0.082-0.419,0.175c-0.038,0.037-0.084,0.067-0.13,0.098
               c-0.068,0.047-0.129,0.031-0.19,0.017c-0.083-0.017-0.183-0.038-0.334,0.022c-0.206,0.09-0.222,0.138-0.268,0.235
               c-0.015,0.037-0.03,0.084-0.068,0.146c-0.016,0.021-0.03,0.029-0.038,0.021c-0.022-0.007-0.053-0.037-0.076-0.076
               c-0.084-0.091-0.175-0.197-0.319-0.183c-0.114,0.017-0.183,0.046-0.244,0.077c-0.061,0.029-0.106,0.045-0.159,0.007
               c-0.054-0.038-0.084-0.076-0.115-0.122c-0.053-0.076-0.099-0.137-0.213-0.129c-0.053,0.008-0.091-0.016-0.121-0.021
               c-0.054-0.031-0.1-0.055-0.206-0.023c-0.061,0.016-0.114,0.054-0.145,0.107c-0.016,0.021-0.03,0.051-0.03,0.082
               c-0.008,0.03-0.008,0.062,0,0.092c0.015,0.054,0.053,0.099,0.114,0.138c0.061,0.038,0.121,0.06,0.175,0.075
               c0.084,0.023,0.152,0.046,0.152,0.115c-0.008,0.037-0.008,0.076-0.008,0.113c-0.015,0.137-0.022,0.266,0.062,0.381
               c0.015,0.016,0.029,0.039,0.052,0.062c0.062,0.075,0.107,0.129,0.093,0.235c-0.009,0.031-0.023,0.067-0.038,0.106
               c-0.016,0.038-0.03,0.076-0.038,0.106c-0.023,0.101,0,0.185,0.159,0.213c0.062,0.016,0.13,0.021,0.199,0.021
               c0.075,0.008,0.144,0.016,0.174,0.03c0.016,0.009,0.016,0.03-0.009,0.067c-0.044,0.078-0.127,0.102-0.219,0.115
               c-0.122,0.031-0.252,0.062-0.328,0.207c-0.045,0.092-0.068,0.174-0.091,0.241c-0.038,0.122-0.068,0.214-0.176,0.252
               c-0.062,0.022-0.137,0.022-0.205,0.015c-0.137,0-0.259-0.006-0.35,0.146c-0.054,0.091-0.069,0.189-0.084,0.273
               c-0.008,0.053-0.016,0.099-0.031,0.121c0,0.016-0.022,0.016-0.061-0.008c-0.083-0.039-0.138-0.091-0.175-0.146
               c-0.092-0.093-0.153-0.159-0.29-0.06c-0.038,0.028-0.068,0.051-0.099,0.073c-0.068,0.056-0.092,0.076-0.167,0.04
               c-0.054-0.029-0.092-0.1-0.138-0.169c-0.061-0.092-0.114-0.183-0.213-0.196c-0.054-0.017-0.1,0-0.145,0.006
               c-0.031,0.008-0.062,0.017-0.076,0.017c-0.016-0.009-0.023-0.022-0.016-0.101c0.016-0.099,0.038-0.174,0.061-0.228
               c0.031-0.122,0.054-0.188-0.03-0.267c-0.062-0.055-0.137-0.16-0.175-0.229c0.022-0.022,0.038-0.053,0.046-0.106
               c0.03-0.129-0.106-0.273-0.244-0.418c-0.068-0.063-0.129-0.129-0.175-0.19c-0.061-0.091,0.03-0.175,0.137-0.272
               c0.068-0.063,0.145-0.131,0.19-0.207c0.084-0.146-0.015-0.272-0.137-0.435c-0.077-0.099-0.16-0.213-0.198-0.333
               c-0.069-0.236-0.053-0.503-0.053-0.672c0.007-0.045,0.007-0.092,0.007-0.114c-0.007-0.054,0-0.092,0.016-0.129
               c0.008-0.038,0.038-0.075,0.076-0.138c0.084-0.113,0.084-0.151,0.084-0.235v-0.03c0-0.039,0.015-0.092,0.046-0.138
               c0.029-0.062,0.075-0.114,0.128-0.159c0.077-0.061,0.1-0.13,0.055-0.197c-0.03-0.063-0.1-0.115-0.214-0.168
               c-0.13-0.067-0.236-0.039-0.342,0c-0.077,0.023-0.161,0.053-0.244,0.028c-0.175-0.051-0.297,0-0.435,0.063
               c-0.046,0.023-0.099,0.037-0.152,0.062c-0.076,0.022-0.151,0.007-0.228-0.022c-0.114-0.053-0.221-0.151-0.282-0.215
               c-0.038-0.037-0.054-0.099-0.068-0.158c-0.03-0.092-0.068-0.197-0.175-0.281c-0.092-0.068-0.138-0.084-0.184-0.1
               c-0.037-0.017-0.076-0.03-0.144-0.084c-0.1-0.092-0.222-0.062-0.352-0.023c-0.091,0.031-0.189,0.055-0.28,0.031
               c-0.13-0.031-0.176-0.021-0.252-0.008c-0.053,0.008-0.121,0.016-0.274,0.008c-0.266-0.018-0.381-0.297-0.441-0.449
               c-0.007-0.016-0.015-0.021-0.022-0.045c-0.023-0.063-0.039-0.123-0.03-0.186c0.007-0.059,0.038-0.136,0.075-0.213
               c0.084-0.183,0.062-0.242-0.007-0.396l-0.022-0.037c-0.062-0.146-0.13-0.244-0.222-0.372l-0.045-0.07
               c-0.047-0.068-0.054-0.105-0.062-0.137c-0.008-0.046-0.016-0.075-0.069-0.145c-0.067-0.084-0.121-0.076-0.166-0.069
               c-0.016,0-0.031,0-0.055-0.021c-0.022-0.039,0.023-0.138,0.077-0.252c0.053-0.099,0.106-0.213,0.122-0.328
               c0.015-0.137,0.083-0.281,0.152-0.418c0.053-0.107,0.099-0.206,0.129-0.305c0.03-0.1,0.092-0.175,0.145-0.229
               c0.061-0.054,0.114-0.084,0.145-0.099c0.068-0.031,0.176-0.153,0.229-0.214l0.016-0.022C54.425,62.7,54.486,62.685,54.555,62.67
               "/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M54.555,62.67
               c0,0.015,0.007,0.021,0.016,0.037c0.106,0.146,0.099,0.32,0.183,0.48c0.092,0.159-0.305,0.662-0.358,0.852
               c-0.046,0.184,0.068,0.321,0.122,0.451c0.062,0.12-0.03,0.395,0.267,0.326c0.297-0.068,0.319-0.184,0.342-0.487
               c0.023-0.306-0.136-0.542-0.22-0.784c-0.084-0.252-0.054-0.389,0.084-0.518c0.145-0.129,0.479-0.298,0.699-0.336
               c0.222-0.038,0.306-0.029,0.306-0.145c0-0.114-0.146-0.463,0.007-0.579c0.16-0.121,0.267-0.166,0.327,0
               c0.062,0.168-0.243,0.328-0.183,0.503c0.054,0.167,0.084,0.167,0.229,0.122c0.145-0.053,0.297-0.107,0.557-0.007
               c0.267,0.099,0.524,0.289,0.556,0.502c0.03,0.22-0.076,0.304,0.121,0.396c0.19,0.084,0.716-0.229,1.257-0.107
               c0.533,0.123,0.479,0.138,0.724,0.312c0.243,0.183,0.502,0.253,0.745,0.169s0.442-0.511,0.808-0.48
               c0.366,0.031,0.472,0.015,0.724-0.015c0.251-0.038,0.495-0.19,0.556-0.016c0.061,0.174,0.061,0.184-0.205,0.229
               c-0.26,0.046-0.404,0.251-0.252,0.327c0.152,0.068,0.206,0.023,0.465,0.19c0.259,0.176,0.457-0.054,0.616,0.039
               c0.16,0.091,0.335,0.205,0.448,0.351c0.123,0.135,0.207,0.182,0.017,0.326c-0.183,0.145-0.412,0.299-0.213,0.357
               c0.189,0.068,0.243-0.105,0.373,0c0.13,0.113,0.282,0.039,0.396,0.045c0.084,0.008,0.221,0.139,0.313,0.235
               c-0.054,0.062-0.114,0.132-0.152,0.177c-0.046,0.053-0.107,0.076-0.168,0.105c-0.076,0.046-0.16,0.092-0.235,0.176l-0.022,0.03
               c-0.123,0.146-0.199,0.229-0.207,0.356c-0.007,0.031-0.022,0.069-0.037,0.092c-0.008,0.023-0.016,0.047-0.023,0.063
               c-0.015,0.075,0,0.129,0.129,0.159c0.062,0.022,0.107,0.022,0.146,0.03c0.038,0.008,0.068,0.008,0.076,0.016
               c0.008,0-0.008,0.008-0.016,0.021c-0.03,0.055-0.053,0.093-0.099,0.115c-0.038,0.029-0.107,0.053-0.213,0.068
               c-0.244,0.045-0.282,0.105-0.365,0.213v0.008c-0.054,0.068-0.054,0.129-0.054,0.198c0,0.045,0,0.091-0.016,0.144
               c-0.015,0.062-0.038,0.1-0.068,0.139c-0.038,0.053-0.076,0.099-0.068,0.184v0.037c0,0.131,0.008,0.281,0.274,0.486
               c0.121,0.093,0.221,0.184,0.281,0.259c0.03,0.038,0.053,0.069,0.061,0.101c0.008,0.016,0.008,0.029,0.008,0.039H63.6
               c-0.023,0.021-0.053,0.061-0.099,0.105c-0.099,0.113-0.251,0.289-0.396,0.298c-0.184,0-0.298,0.082-0.419,0.175
               c-0.038,0.037-0.084,0.067-0.13,0.098c-0.068,0.047-0.129,0.031-0.19,0.017c-0.083-0.017-0.183-0.038-0.334,0.022
               c-0.206,0.09-0.222,0.138-0.268,0.235c-0.015,0.037-0.03,0.084-0.068,0.146c-0.016,0.021-0.03,0.029-0.038,0.021
               c-0.022-0.007-0.053-0.037-0.076-0.076c-0.084-0.091-0.175-0.197-0.319-0.183c-0.114,0.017-0.183,0.046-0.244,0.077
               c-0.061,0.029-0.106,0.045-0.159,0.007c-0.054-0.038-0.084-0.076-0.115-0.122c-0.053-0.076-0.099-0.137-0.213-0.129
               c-0.053,0.008-0.091-0.016-0.121-0.021c-0.054-0.031-0.1-0.055-0.206-0.023c-0.061,0.016-0.114,0.054-0.145,0.107
               c-0.016,0.021-0.03,0.051-0.03,0.082c-0.008,0.03-0.008,0.062,0,0.092c0.015,0.054,0.053,0.099,0.114,0.138
               c0.061,0.038,0.121,0.06,0.175,0.075c0.084,0.023,0.152,0.046,0.152,0.115c-0.008,0.037-0.008,0.076-0.008,0.113
               c-0.015,0.137-0.022,0.266,0.062,0.381c0.015,0.016,0.029,0.039,0.052,0.062c0.062,0.075,0.107,0.129,0.093,0.235
               c-0.009,0.031-0.023,0.067-0.038,0.106c-0.016,0.038-0.03,0.076-0.038,0.106c-0.023,0.101,0,0.185,0.159,0.213
               c0.062,0.016,0.13,0.021,0.199,0.021c0.075,0.008,0.144,0.016,0.174,0.03c0.016,0.009,0.016,0.03-0.009,0.067
               c-0.044,0.078-0.127,0.102-0.219,0.115c-0.122,0.031-0.252,0.062-0.328,0.207c-0.045,0.092-0.068,0.174-0.091,0.241
               c-0.038,0.122-0.068,0.214-0.176,0.252c-0.062,0.022-0.137,0.022-0.205,0.015c-0.137,0-0.259-0.006-0.35,0.146
               c-0.054,0.091-0.069,0.189-0.084,0.273c-0.008,0.053-0.016,0.099-0.031,0.121c0,0.016-0.022,0.016-0.061-0.008
               c-0.083-0.039-0.138-0.091-0.175-0.146c-0.092-0.093-0.153-0.159-0.29-0.06c-0.038,0.028-0.068,0.051-0.099,0.073
               c-0.068,0.056-0.092,0.076-0.167,0.04c-0.054-0.029-0.092-0.1-0.138-0.169c-0.061-0.092-0.114-0.183-0.213-0.196
               c-0.054-0.017-0.1,0-0.145,0.006c-0.031,0.008-0.062,0.017-0.076,0.017c-0.016-0.009-0.023-0.022-0.016-0.101
               c0.016-0.099,0.038-0.174,0.061-0.228c0.031-0.122,0.054-0.188-0.03-0.267c-0.062-0.055-0.137-0.16-0.175-0.229
               c0.022-0.022,0.038-0.053,0.046-0.106c0.03-0.129-0.106-0.273-0.244-0.418c-0.068-0.063-0.129-0.129-0.175-0.19
               c-0.061-0.091,0.03-0.175,0.137-0.272c0.068-0.063,0.145-0.131,0.19-0.207c0.084-0.146-0.015-0.272-0.137-0.435
               c-0.077-0.099-0.16-0.213-0.198-0.333c-0.069-0.236-0.053-0.503-0.053-0.672c0.007-0.045,0.007-0.092,0.007-0.114
               c-0.007-0.054,0-0.092,0.016-0.129c0.008-0.038,0.038-0.075,0.076-0.138c0.084-0.113,0.084-0.151,0.084-0.235v-0.03
               c0-0.039,0.015-0.092,0.046-0.138c0.029-0.062,0.075-0.114,0.128-0.159c0.077-0.061,0.1-0.13,0.055-0.197
               c-0.03-0.063-0.1-0.115-0.214-0.168c-0.13-0.067-0.236-0.039-0.342,0c-0.077,0.023-0.161,0.053-0.244,0.028
               c-0.175-0.051-0.297,0-0.435,0.063c-0.046,0.023-0.099,0.037-0.152,0.062c-0.076,0.022-0.151,0.007-0.228-0.022
               c-0.114-0.053-0.221-0.151-0.282-0.215c-0.038-0.037-0.054-0.099-0.068-0.158c-0.03-0.092-0.068-0.197-0.175-0.281
               c-0.092-0.068-0.138-0.084-0.184-0.1c-0.037-0.017-0.076-0.03-0.144-0.084c-0.1-0.092-0.222-0.062-0.352-0.023
               c-0.091,0.031-0.189,0.055-0.28,0.031c-0.13-0.031-0.176-0.021-0.252-0.008c-0.053,0.008-0.121,0.016-0.274,0.008
               c-0.266-0.018-0.381-0.297-0.441-0.449c-0.007-0.016-0.015-0.021-0.022-0.045c-0.023-0.063-0.039-0.123-0.03-0.186
               c0.007-0.059,0.038-0.136,0.075-0.213c0.084-0.183,0.062-0.242-0.007-0.396l-0.022-0.037c-0.062-0.146-0.13-0.244-0.222-0.372
               l-0.045-0.07c-0.047-0.068-0.054-0.105-0.062-0.137c-0.008-0.046-0.016-0.075-0.069-0.145c-0.067-0.084-0.121-0.076-0.166-0.069
               c-0.016,0-0.031,0-0.055-0.021c-0.022-0.039,0.023-0.138,0.077-0.252c0.053-0.099,0.106-0.213,0.122-0.328
               c0.015-0.137,0.083-0.281,0.152-0.418c0.053-0.107,0.099-0.206,0.129-0.305c0.03-0.1,0.092-0.175,0.145-0.229
               c0.061-0.054,0.114-0.084,0.145-0.099c0.068-0.031,0.176-0.153,0.229-0.214l0.016-0.022C54.425,62.7,54.486,62.685,54.555,62.67
               z"/>
             <path fill="#808184" d="M55.826,77.189c-0.091,0.023-0.221,0-0.343-0.016
               c-0.106-0.016-0.214-0.029-0.282-0.006c-0.06,0.021-0.106,0.067-0.159,0.129c-0.068,0.074-0.152,0.166-0.319,0.205
               c-0.061,0.016-0.13,0.022-0.214,0.037c-0.319,0.055-0.738,0.123-0.867,0.396c-0.054,0.115-0.1,0.191-0.146,0.251
               c-0.091,0.122-0.151,0.214-0.151,0.42c0,0.159-0.04,0.267-0.077,0.364c-0.03,0.092-0.061,0.168-0.053,0.26
               c0.008,0.13-0.062,0.205-0.145,0.297c-0.016,0.018-0.031,0.038-0.046,0.063l-0.03,0.037c-0.13,0.146-0.32,0.373-0.29,0.654
               c0.023,0.168,0.106,0.273,0.198,0.389c0.061,0.076,0.13,0.151,0.168,0.26c0.045,0.138,0.175,0.281,0.304,0.419
               c0.115,0.129,0.229,0.25,0.229,0.327c0,0.061-0.015,0.113-0.03,0.168c-0.038,0.113-0.068,0.205,0.069,0.258
               c0.067,0.029,0.159,0.039,0.258,0.045c0.138,0.01,0.282,0.023,0.313,0.085c0.022,0.038,0.022,0.093,0.03,0.146
               c0,0.129,0.008,0.242,0.175,0.266c0.046,0.008,0.092,0.016,0.13,0.021c0.168,0.03,0.32,0.062,0.533-0.037
               c0.114-0.053,0.198-0.121,0.268-0.174c0.082-0.063,0.144-0.109,0.189-0.084c0.038,0.014,0.03,0.092,0.022,0.205
               c0,0.084-0.008,0.197,0,0.326c0.008,0.061,0.008,0.122,0.008,0.168c0.015,0.221,0.015,0.335,0.259,0.465
               c0.16,0.092,0.319,0.084,0.465,0.075c0.099-0.007,0.183-0.015,0.235,0.022c0.099,0.054,0.168,0.184,0.229,0.305
               c0.038,0.055,0.069,0.107,0.099,0.161c0.062,0.075,0.236,0.364,0.358,0.614c0.061,0.146,0.106,0.283,0.093,0.352
               c-0.017,0.083-0.055,0.176-0.093,0.268c-0.054,0.151-0.114,0.305-0.106,0.457c0,0.121,0.016,0.258,0.03,0.396
               c0.022,0.13,0.038,0.259,0.069,0.381c0.022,0.114-0.031,0.19-0.1,0.267c-0.062,0.084-0.122,0.16-0.137,0.289
               c-0.023,0.153,0.007,0.328,0.038,0.496l0.015,0.075c-0.084-0.062-0.168-0.106-0.243-0.106c-0.214,0-0.26,0.039-0.198,0.16
               c0.062,0.129-0.146,0.092-0.062,0.252s0.267,0.151,0.32,0.229c0.053,0.084,0.053,0.113,0.159,0.184
               c0.107,0.067,0.115,0.113,0.184,0.129c0.038,0.008,0.099,0,0.16,0c0.061,0.084,0.113,0.168,0.129,0.26
               c0.016,0.092-0.008,0.189-0.1,0.311c-0.083,0.107-0.174,0.161-0.243,0.207c-0.106,0.068-0.19,0.121-0.205,0.268
               c-0.008,0.113-0.023,0.205-0.038,0.281c-0.016,0.084-0.037,0.152-0.069,0.221c-0.03,0.068-0.03,0.121-0.03,0.176
               c0,0.068,0,0.129-0.099,0.183c-0.053,0.03-0.129,0.06-0.206,0.091c-0.106-0.137-0.228-0.111-0.411-0.281
               c-0.205-0.197-0.228,0.054-0.403-0.371c-0.183-0.428-0.373-0.572-0.556-0.656c-0.176-0.076-0.616-0.268-0.762-0.372
               c-0.144-0.101-0.449-0.22-0.845-0.427c-0.396-0.198-0.632-0.123-0.922-0.495c-0.289-0.365-0.396-0.571-0.563-0.716
               c-0.168-0.146-0.898-0.588-0.808-0.854c0.093-0.273,0.16-0.365,0.114-0.479c-0.046-0.106-0.914-0.815-0.982-1.303
               c-0.068-0.479-0.159-0.549-0.305-0.67c-0.145-0.129-0.167-0.199-0.281-0.541c-0.107-0.35-0.365-0.093-0.548-0.822
               c-0.184-0.729-0.441-1.135-0.563-1.432c-0.123-0.289-0.351-0.57-0.449-0.785c-0.1-0.211-0.305-0.752-0.633-0.92
               c-0.327-0.168-1.021-0.404-0.875-0.654c0.145-0.243,0.274-0.313,0.168-0.465c-0.1-0.146-0.214-0.121-0.222-0.426
               c-0.015-0.307-0.068-0.457,0.076-0.693c0.146-0.235,0.252-0.532,0.473-0.687c0.007-0.007,0.015-0.015,0.022-0.015
               c0.046,0.045,0.091,0.084,0.114,0.121c0.038,0.062,0.061,0.113,0.076,0.16c0.015,0.045,0.016,0.084,0.016,0.113
               c-0.008,0-0.031,0.016-0.054,0.022c-0.055,0.022-0.113,0.047-0.145,0.114c-0.053,0.09-0.03,0.123,0,0.174
               c0.016,0.009,0.022,0.033,0.031,0.056c0.007,0.015-0.016,0.054-0.031,0.093c-0.016,0.035-0.038,0.067-0.038,0.098
               c-0.015,0.061,0,0.106,0.084,0.121c0.106,0.022,0.167-0.053,0.228-0.121c0.038-0.037,0.069-0.076,0.084-0.068
               c0.038,0.016,0.061,0.046,0.076,0.068c0.031,0.037,0.062,0.068,0.13,0.084c0.022,0.008,0.038,0.008,0.053,0.016
               c0.077,0.016,0.168,0.029,0.184,0.076c0,0.015,0,0.045,0.007,0.068c0,0.121,0.008,0.281,0.244,0.305l0.076,0.008
               c0.221,0.029,0.251,0.029,0.32-0.16c0.015-0.029,0.029-0.063,0.037-0.1c0.046-0.113,0.084-0.213,0.145-0.297
               c0.054-0.076,0.069-0.197,0.084-0.318c0.008-0.086,0.016-0.168,0.038-0.236l0.022-0.055c0.047-0.145,0.1-0.305,0.237-0.418
               c0.075-0.063,0.083-0.105,0.091-0.146c0-0.029,0-0.045,0.183-0.113c0.183-0.061,0.39-0.145,0.571-0.229
               c0.183-0.084,0.335-0.175,0.396-0.259c0.054-0.077,0.168-0.183,0.282-0.28c0.105-0.101,0.213-0.191,0.258-0.262
               c0.062-0.091,0.13-0.249,0.168-0.341c0.008-0.031,0.016-0.054,0.023-0.069c0.008-0.007,0.022-0.037,0.053-0.076
               c0.084-0.138,0.251-0.396,0.282-0.525c0-0.021,0.007-0.045,0.015-0.066c0.031-0.115,0.046-0.168-0.083-0.305
               c-0.062-0.069-0.122-0.146-0.176-0.229l-0.008-0.022c0.084-0.038,0.267-0.104,0.32-0.084c0.03,0.008,0.062,0.03,0.084,0.062
               c0.053,0.045,0.106,0.1,0.213,0.114c0.076,0.015,0.122,0.091,0.167,0.175c0.038,0.053,0.069,0.114,0.122,0.168
               c0.077,0.068,0.077,0.098,0.077,0.151c0,0.022,0,0.047,0.007,0.084c0.015,0.084,0.106,0.137,0.221,0.198
               c0.107,0.054,0.229,0.113,0.289,0.205c0.062,0.092,0.053,0.138,0.053,0.183c0,0.062-0.007,0.114,0.077,0.222
               c0.084,0.1,0.175,0.107,0.252,0.123c0.045,0,0.083,0.006,0.099,0.035v0.01c0.007,0.03-0.016,0.075-0.031,0.123
               c-0.053,0.09-0.099,0.188,0.046,0.287c0.099,0.068,0.198,0.107,0.297,0.129c0.099,0.016,0.198,0.016,0.297,0.01
               c0.1-0.017,0.152-0.039,0.198-0.062c0.038-0.024,0.068-0.039,0.122-0.024c0.083,0.024,0.137-0.029,0.199-0.099
               c0.044-0.045,0.09-0.099,0.158-0.06c0.054,0.029,0.093,0.06,0.115,0.082c0.091,0.068,0.121,0.092,0.25,0.062
               c0.039-0.008,0.068-0.015,0.106-0.03c0.115-0.039,0.245-0.076,0.297,0.008c0.047,0.076,0.114,0.113,0.183,0.151
               c0.062,0.039,0.123,0.076,0.138,0.146c0.008,0.054-0.068,0.205-0.152,0.365c-0.114,0.228-0.236,0.473-0.145,0.57
               c0.068,0.076,0.137,0.084,0.19,0.092c0.038,0.008,0.068,0.008,0.084,0.055C55.658,77.023,55.758,77.128,55.826,77.189"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M55.826,77.189c-0.091,0.023-0.221,0-0.343-0.016c-0.106-0.016-0.214-0.029-0.282-0.006c-0.06,0.021-0.106,0.067-0.159,0.129
               c-0.068,0.074-0.152,0.166-0.319,0.205c-0.061,0.016-0.13,0.022-0.214,0.037c-0.319,0.055-0.738,0.123-0.867,0.396
               c-0.054,0.115-0.1,0.191-0.146,0.251c-0.091,0.122-0.151,0.214-0.151,0.42c0,0.159-0.04,0.267-0.077,0.364
               c-0.03,0.092-0.061,0.168-0.053,0.26c0.008,0.13-0.062,0.205-0.145,0.297c-0.016,0.018-0.031,0.038-0.046,0.063l-0.03,0.037
               c-0.13,0.146-0.32,0.373-0.29,0.654c0.023,0.168,0.106,0.273,0.198,0.389c0.061,0.076,0.13,0.151,0.168,0.26
               c0.045,0.138,0.175,0.281,0.304,0.419c0.115,0.129,0.229,0.25,0.229,0.327c0,0.061-0.015,0.113-0.03,0.168
               c-0.038,0.113-0.068,0.205,0.069,0.258c0.067,0.029,0.159,0.039,0.258,0.045c0.138,0.01,0.282,0.023,0.313,0.085
               c0.022,0.038,0.022,0.093,0.03,0.146c0,0.129,0.008,0.242,0.175,0.266c0.046,0.008,0.092,0.016,0.13,0.021
               c0.168,0.03,0.32,0.062,0.533-0.037c0.114-0.053,0.198-0.121,0.268-0.174c0.082-0.063,0.144-0.109,0.189-0.084
               c0.038,0.014,0.03,0.092,0.022,0.205c0,0.084-0.008,0.197,0,0.326c0.008,0.061,0.008,0.122,0.008,0.168
               c0.015,0.221,0.015,0.335,0.259,0.465c0.16,0.092,0.319,0.084,0.465,0.075c0.099-0.007,0.183-0.015,0.235,0.022
               c0.099,0.054,0.168,0.184,0.229,0.305c0.038,0.055,0.069,0.107,0.099,0.161c0.062,0.075,0.236,0.364,0.358,0.614
               c0.061,0.146,0.106,0.283,0.093,0.352c-0.017,0.083-0.055,0.176-0.093,0.268c-0.054,0.151-0.114,0.305-0.106,0.457
               c0,0.121,0.016,0.258,0.03,0.396c0.022,0.13,0.038,0.259,0.069,0.381c0.022,0.114-0.031,0.19-0.1,0.267
               c-0.062,0.084-0.122,0.16-0.137,0.289c-0.023,0.153,0.007,0.328,0.038,0.496l0.015,0.075c-0.084-0.062-0.168-0.106-0.243-0.106
               c-0.214,0-0.26,0.039-0.198,0.16c0.062,0.129-0.146,0.092-0.062,0.252s0.267,0.151,0.32,0.229
               c0.053,0.084,0.053,0.113,0.159,0.184c0.107,0.067,0.115,0.113,0.184,0.129c0.038,0.008,0.099,0,0.16,0
               c0.061,0.084,0.113,0.168,0.129,0.26c0.016,0.092-0.008,0.189-0.1,0.311c-0.083,0.107-0.174,0.161-0.243,0.207
               c-0.106,0.068-0.19,0.121-0.205,0.268c-0.008,0.113-0.023,0.205-0.038,0.281c-0.016,0.084-0.037,0.152-0.069,0.221
               c-0.03,0.068-0.03,0.121-0.03,0.176c0,0.068,0,0.129-0.099,0.183c-0.053,0.03-0.129,0.06-0.206,0.091
               c-0.106-0.137-0.228-0.111-0.411-0.281c-0.205-0.197-0.228,0.054-0.403-0.371c-0.183-0.428-0.373-0.572-0.556-0.656
               c-0.176-0.076-0.616-0.268-0.762-0.372c-0.144-0.101-0.449-0.22-0.845-0.427c-0.396-0.198-0.632-0.123-0.922-0.495
               c-0.289-0.365-0.396-0.571-0.563-0.716c-0.168-0.146-0.898-0.588-0.808-0.854c0.093-0.273,0.16-0.365,0.114-0.479
               c-0.046-0.106-0.914-0.815-0.982-1.303c-0.068-0.479-0.159-0.549-0.305-0.67c-0.145-0.129-0.167-0.199-0.281-0.541
               c-0.107-0.35-0.365-0.093-0.548-0.822c-0.184-0.729-0.441-1.135-0.563-1.432c-0.123-0.289-0.351-0.57-0.449-0.785
               c-0.1-0.211-0.305-0.752-0.633-0.92c-0.327-0.168-1.021-0.404-0.875-0.654c0.145-0.243,0.274-0.313,0.168-0.465
               c-0.1-0.146-0.214-0.121-0.222-0.426c-0.015-0.307-0.068-0.457,0.076-0.693c0.146-0.235,0.252-0.532,0.473-0.687
               c0.007-0.007,0.015-0.015,0.022-0.015c0.046,0.045,0.091,0.084,0.114,0.121c0.038,0.062,0.061,0.113,0.076,0.16
               c0.015,0.045,0.016,0.084,0.016,0.113c-0.008,0-0.031,0.016-0.054,0.022c-0.055,0.022-0.113,0.047-0.145,0.114
               c-0.053,0.09-0.03,0.123,0,0.174c0.016,0.009,0.022,0.033,0.031,0.056c0.007,0.015-0.016,0.054-0.031,0.093
               c-0.016,0.035-0.038,0.067-0.038,0.098c-0.015,0.061,0,0.106,0.084,0.121c0.106,0.022,0.167-0.053,0.228-0.121
               c0.038-0.037,0.069-0.076,0.084-0.068c0.038,0.016,0.061,0.046,0.076,0.068c0.031,0.037,0.062,0.068,0.13,0.084
               c0.022,0.008,0.038,0.008,0.053,0.016c0.077,0.016,0.168,0.029,0.184,0.076c0,0.015,0,0.045,0.007,0.068
               c0,0.121,0.008,0.281,0.244,0.305l0.076,0.008c0.221,0.029,0.251,0.029,0.32-0.16c0.015-0.029,0.029-0.063,0.037-0.1
               c0.046-0.113,0.084-0.213,0.145-0.297c0.054-0.076,0.069-0.197,0.084-0.318c0.008-0.086,0.016-0.168,0.038-0.236l0.022-0.055
               c0.047-0.145,0.1-0.305,0.237-0.418c0.075-0.063,0.083-0.105,0.091-0.146c0-0.029,0-0.045,0.183-0.113
               c0.183-0.061,0.39-0.145,0.571-0.229c0.183-0.084,0.335-0.175,0.396-0.259c0.054-0.077,0.168-0.183,0.282-0.28
               c0.105-0.101,0.213-0.191,0.258-0.262c0.062-0.091,0.13-0.249,0.168-0.341c0.008-0.031,0.016-0.054,0.023-0.069
               c0.008-0.007,0.022-0.037,0.053-0.076c0.084-0.138,0.251-0.396,0.282-0.525c0-0.021,0.007-0.045,0.015-0.066
               c0.031-0.115,0.046-0.168-0.083-0.305c-0.062-0.069-0.122-0.146-0.176-0.229l-0.008-0.022c0.084-0.038,0.267-0.104,0.32-0.084
               c0.03,0.008,0.062,0.03,0.084,0.062c0.053,0.045,0.106,0.1,0.213,0.114c0.076,0.015,0.122,0.091,0.167,0.175
               c0.038,0.053,0.069,0.114,0.122,0.168c0.077,0.068,0.077,0.098,0.077,0.151c0,0.022,0,0.047,0.007,0.084
               c0.015,0.084,0.106,0.137,0.221,0.198c0.107,0.054,0.229,0.113,0.289,0.205c0.062,0.092,0.053,0.138,0.053,0.183
               c0,0.062-0.007,0.114,0.077,0.222c0.084,0.1,0.175,0.107,0.252,0.123c0.045,0,0.083,0.006,0.099,0.035v0.01
               c0.007,0.03-0.016,0.075-0.031,0.123c-0.053,0.09-0.099,0.188,0.046,0.287c0.099,0.068,0.198,0.107,0.297,0.129
               c0.099,0.016,0.198,0.016,0.297,0.01c0.1-0.017,0.152-0.039,0.198-0.062c0.038-0.024,0.068-0.039,0.122-0.024
               c0.083,0.024,0.137-0.029,0.199-0.099c0.044-0.045,0.09-0.099,0.158-0.06c0.054,0.029,0.093,0.06,0.115,0.082
               c0.091,0.068,0.121,0.092,0.25,0.062c0.039-0.008,0.068-0.015,0.106-0.03c0.115-0.039,0.245-0.076,0.297,0.008
               c0.047,0.076,0.114,0.113,0.183,0.151c0.062,0.039,0.123,0.076,0.138,0.146c0.008,0.054-0.068,0.205-0.152,0.365
               c-0.114,0.228-0.236,0.473-0.145,0.57c0.068,0.076,0.137,0.084,0.19,0.092c0.038,0.008,0.068,0.008,0.084,0.055
               C55.658,77.023,55.758,77.128,55.826,77.189z"/>
             <path fill="#808184" d="M49.567,66.279c0.038-0.076,0.054-0.145,0.106-0.145
               c0.054-0.01,0.184,0.037,0.274-0.16c0.091-0.198,0.145-0.244,0.122-0.313c-0.016-0.076-0.168-0.306-0.168-0.381
               c0.008-0.084,0.084-0.063,0.114-0.008c0.03,0.061,0.251,0.313,0.273,0.372c0.031,0.054-0.037,0.229,0.054,0.229
               c0.084,0.008,0.145-0.281,0.084-0.403c-0.054-0.13-0.167-0.175-0.09-0.251c0.067-0.076,0.288-0.1,0.509-0.336
               c0.229-0.242,0.152-0.439,0.358-0.449c0.212,0,0.281,0.063,0.267-0.1c-0.016-0.158-0.146-0.158-0.046-0.38
               c0.098-0.22,0.068-0.341,0.152-0.471c0.091-0.13,0.106-0.275,0.327-0.381c0.221-0.107,0.335-0.366,0.434-0.176
               c0.106,0.183-0.099,0.313,0.084,0.328c0.176,0.015,0.205-0.19,0.251-0.373c0.039-0.191-0.046-0.306,0.221-0.222
               c0.268,0.084,0.479,0.152,0.701-0.03c0.213-0.184,0.296-0.404,0.563-0.427c0.266-0.022,0.167-0.335,0.319-0.38
               c0.152-0.054,0.259-0.183,0.479-0.13c0.221,0.061,0.267,0.061,0.289,0.213c0.016,0.145,0.016,0.236-0.113,0.297
               c-0.13,0.061-0.289,0.077-0.435,0.137c-0.099,0.046-0.175,0.138-0.175,0.236c-0.084,0.023-0.168,0.046-0.19,0.069l-0.016,0.023
               c-0.054,0.06-0.145,0.167-0.197,0.189c-0.039,0.023-0.1,0.053-0.168,0.122c-0.068,0.061-0.137,0.151-0.175,0.274
               c-0.024,0.083-0.069,0.183-0.122,0.281c-0.067,0.151-0.145,0.305-0.16,0.449c-0.016,0.099-0.068,0.206-0.114,0.305
               c-0.068,0.137-0.129,0.258-0.068,0.342s0.106,0.076,0.152,0.068c0.023,0,0.038-0.008,0.068,0.03
               c0.046,0.046,0.046,0.076,0.055,0.106c0.007,0.039,0.014,0.086,0.075,0.176l0.045,0.066c0.084,0.123,0.153,0.223,0.214,0.352
               l0.022,0.045c0.054,0.115,0.076,0.168,0.008,0.307c-0.046,0.09-0.076,0.174-0.084,0.252c-0.007,0.074,0,0.151,0.038,0.234
               l0.016,0.037c0.076,0.176,0.205,0.488,0.532,0.511c0.161,0.017,0.236,0,0.298-0.007c0.061-0.008,0.106-0.016,0.213,0.007
               c0.114,0.031,0.229,0,0.327-0.028c0.106-0.031,0.198-0.056,0.267,0c0.084,0.066,0.129,0.082,0.176,0.098
               c0.037,0.016,0.075,0.029,0.152,0.084c0.083,0.068,0.106,0.152,0.137,0.236c0.022,0.076,0.045,0.146,0.099,0.196
               c0.062,0.076,0.183,0.177,0.312,0.237c0.092,0.037,0.199,0.061,0.298,0.029c0.061-0.022,0.114-0.045,0.159-0.068
               c0.123-0.053,0.229-0.1,0.366-0.061c0.113,0.037,0.213,0.008,0.304-0.023c0.092-0.029,0.176-0.061,0.267-0.016
               c0.092,0.055,0.152,0.092,0.176,0.129c0.008,0.023,0,0.047-0.038,0.078c-0.054,0.053-0.106,0.113-0.145,0.183
               c-0.046,0.062-0.068,0.13-0.068,0.188v0.031c0,0.063,0,0.092-0.062,0.182c-0.046,0.063-0.076,0.115-0.091,0.162
               c-0.016,0.053-0.022,0.098-0.022,0.158c0.007,0.023,0,0.063,0,0.114c-0.009,0.168-0.017,0.448,0.053,0.7
               c0.046,0.138,0.137,0.259,0.22,0.365c0.092,0.129,0.176,0.235,0.123,0.319c-0.039,0.069-0.1,0.13-0.168,0.185
               c-0.137,0.129-0.267,0.25-0.146,0.41c0.047,0.067,0.107,0.131,0.176,0.197c0.122,0.131,0.244,0.251,0.222,0.327
               c-0.008,0.056-0.023,0.056-0.054,0.062c-0.038,0.008-0.092,0.008-0.16,0.062c-0.068,0.054-0.167,0.101-0.274,0.146
               c-0.114,0.045-0.236,0.084-0.356,0.105c-0.123,0.023-0.359,0.031-0.588,0.031c-0.273,0.007-0.547,0.015-0.631,0.053
               c-0.046,0.023-0.092,0.077-0.123,0.145c-0.038,0.068-0.061,0.152-0.074,0.229c-0.017,0.084-0.009,0.168,0.014,0.213
               c0.008,0.028,0.031,0.054,0.054,0.067c0.053,0.023,0.16,0.023,0.281,0.023c0.152,0.008,0.328,0.008,0.335,0.055l0.009,0.006
               c0,0.009,0,0.023,0.007,0.037c0.016,0.084,0,0.139-0.221,0.177c-0.054,0.007-0.091,0.017-0.13,0.022
               c-0.213,0.03-0.297,0.047-0.342,0.166c0,0.016-0.007,0.031-0.016,0.047c-0.046,0.121-0.13,0.334-0.053,0.646
               c0.037,0.184,0.159,0.273,0.273,0.357c0.099,0.084,0.19,0.152,0.19,0.281c0.016,0.223,0.008,0.449,0.008,0.609
               c0,0.061-0.008,0.122-0.008,0.145c0,0.084-0.076,0.961-0.175,1.605c-0.046,0.268-0.084,0.497-0.13,0.596
               c-0.053-0.054-0.152-0.152-0.182-0.244c-0.047-0.1-0.1-0.105-0.169-0.113c-0.037-0.008-0.084-0.016-0.129-0.063
               c-0.046-0.045,0.061-0.259,0.16-0.457c0.091-0.174,0.175-0.342,0.16-0.433c-0.022-0.106-0.1-0.161-0.184-0.207
               c-0.061-0.038-0.114-0.067-0.152-0.122c-0.092-0.15-0.266-0.1-0.41-0.053c-0.038,0.016-0.069,0.022-0.1,0.031
               c-0.076,0.014-0.099,0-0.168-0.045c-0.022-0.025-0.06-0.055-0.114-0.084c-0.144-0.093-0.22,0-0.296,0.074
               c-0.039,0.038-0.069,0.075-0.099,0.068c-0.092-0.022-0.131,0-0.191,0.031c-0.038,0.015-0.084,0.037-0.159,0.045
               c-0.092,0.008-0.184,0.015-0.273-0.008c-0.085-0.016-0.169-0.047-0.253-0.106c-0.075-0.054-0.046-0.104-0.015-0.159
               c0.023-0.061,0.053-0.122,0.038-0.197c-0.031-0.1-0.099-0.107-0.183-0.121c-0.062-0.009-0.138-0.018-0.19-0.086
               c-0.054-0.068-0.054-0.105-0.054-0.152c0.008-0.059,0.008-0.121-0.068-0.233c-0.076-0.114-0.206-0.185-0.32-0.245
               c-0.091-0.045-0.167-0.091-0.175-0.121c-0.008-0.031-0.008-0.054-0.008-0.076c0-0.076,0-0.119-0.105-0.221
               c-0.039-0.039-0.069-0.092-0.107-0.146c-0.053-0.098-0.113-0.197-0.229-0.219c-0.082-0.018-0.122-0.055-0.167-0.093
               c-0.031-0.03-0.062-0.062-0.114-0.084c-0.092-0.038-0.32,0.062-0.411,0.101c-0.03-0.056-0.062-0.106-0.084-0.162
               c-0.068-0.137-0.138-0.157-0.213-0.196c-0.03-0.017-0.069-0.03-0.1-0.054c-0.03-0.023-0.053-0.047-0.068-0.068
               c-0.046-0.068-0.076-0.105-0.228-0.068c-0.084,0.022-0.13,0.063-0.176,0.1c-0.038,0.029-0.076,0.062-0.168,0.039
               c-0.045-0.01-0.091-0.018-0.129-0.024c-0.152-0.03-0.281-0.052-0.365-0.136c-0.03-0.039-0.047-0.077-0.062-0.123
               c-0.022-0.083-0.053-0.167-0.198-0.221c-0.091-0.029-0.152-0.016-0.197-0.008c-0.038,0.016-0.068,0.016-0.137-0.106
               c-0.115-0.22-0.313-0.304-0.473-0.365c-0.046-0.021-0.084-0.037-0.122-0.052c-0.045-0.032-0.114-0.055-0.175-0.077
               c-0.023-0.062-0.03-0.13,0.008-0.199c0.099-0.166,0.305,0.084,0.357-0.196c0.062-0.283-0.259-0.274,0.023-0.488
               c0.281-0.213,0.434,0.214,0.67-0.303c0.235-0.51,0.267-0.533,0.411-0.717c0.145-0.184,0.235-0.16,0.122-0.334
               c-0.107-0.185-0.176-0.093-0.129-0.298c0.045-0.198-0.13-0.457-0.039-0.7c0.084-0.252,0-0.404-0.023-0.486
               c-0.029-0.078,0.13-0.207,0.138-0.307c0.016-0.105-0.183-0.203-0.159-0.287c0.021-0.084,0.129-0.123,0.129-0.261
               c0-0.137-0.016-0.183-0.122-0.272c-0.099-0.101-0.167-0.084-0.16-0.213c0.008-0.131-0.03-0.162-0.083-0.185
               c-0.046-0.03-0.138-0.03-0.122-0.075C49.385,66.417,49.507,66.41,49.567,66.279"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M49.567,66.279c0.038-0.076,0.054-0.145,0.106-0.145c0.054-0.01,0.184,0.037,0.274-0.16c0.091-0.198,0.145-0.244,0.122-0.313
               c-0.016-0.076-0.168-0.306-0.168-0.381c0.008-0.084,0.084-0.063,0.114-0.008c0.03,0.061,0.251,0.313,0.273,0.372
               c0.031,0.054-0.037,0.229,0.054,0.229c0.084,0.008,0.145-0.281,0.084-0.403c-0.054-0.13-0.167-0.175-0.09-0.251
               c0.067-0.076,0.288-0.1,0.509-0.336c0.229-0.242,0.152-0.439,0.358-0.449c0.212,0,0.281,0.063,0.267-0.1
               c-0.016-0.158-0.146-0.158-0.046-0.38c0.098-0.22,0.068-0.341,0.152-0.471c0.091-0.13,0.106-0.275,0.327-0.381
               c0.221-0.107,0.335-0.366,0.434-0.176c0.106,0.183-0.099,0.313,0.084,0.328c0.176,0.015,0.205-0.19,0.251-0.373
               c0.039-0.191-0.046-0.306,0.221-0.222c0.268,0.084,0.479,0.152,0.701-0.03c0.213-0.184,0.296-0.404,0.563-0.427
               c0.266-0.022,0.167-0.335,0.319-0.38c0.152-0.054,0.259-0.183,0.479-0.13c0.221,0.061,0.267,0.061,0.289,0.213
               c0.016,0.145,0.016,0.236-0.113,0.297c-0.13,0.061-0.289,0.077-0.435,0.137c-0.099,0.046-0.175,0.138-0.175,0.236
               c-0.084,0.023-0.168,0.046-0.19,0.069l-0.016,0.023c-0.054,0.06-0.145,0.167-0.197,0.189c-0.039,0.023-0.1,0.053-0.168,0.122
               c-0.068,0.061-0.137,0.151-0.175,0.274c-0.024,0.083-0.069,0.183-0.122,0.281c-0.067,0.151-0.145,0.305-0.16,0.449
               c-0.016,0.099-0.068,0.206-0.114,0.305c-0.068,0.137-0.129,0.258-0.068,0.342s0.106,0.076,0.152,0.068
               c0.023,0,0.038-0.008,0.068,0.03c0.046,0.046,0.046,0.076,0.055,0.106c0.007,0.039,0.014,0.086,0.075,0.176l0.045,0.066
               c0.084,0.123,0.153,0.223,0.214,0.352l0.022,0.045c0.054,0.115,0.076,0.168,0.008,0.307c-0.046,0.09-0.076,0.174-0.084,0.252
               c-0.007,0.074,0,0.151,0.038,0.234l0.016,0.037c0.076,0.176,0.205,0.488,0.532,0.511c0.161,0.017,0.236,0,0.298-0.007
               c0.061-0.008,0.106-0.016,0.213,0.007c0.114,0.031,0.229,0,0.327-0.028c0.106-0.031,0.198-0.056,0.267,0
               c0.084,0.066,0.129,0.082,0.176,0.098c0.037,0.016,0.075,0.029,0.152,0.084c0.083,0.068,0.106,0.152,0.137,0.236
               c0.022,0.076,0.045,0.146,0.099,0.196c0.062,0.076,0.183,0.177,0.312,0.237c0.092,0.037,0.199,0.061,0.298,0.029
               c0.061-0.022,0.114-0.045,0.159-0.068c0.123-0.053,0.229-0.1,0.366-0.061c0.113,0.037,0.213,0.008,0.304-0.023
               c0.092-0.029,0.176-0.061,0.267-0.016c0.092,0.055,0.152,0.092,0.176,0.129c0.008,0.023,0,0.047-0.038,0.078
               c-0.054,0.053-0.106,0.113-0.145,0.183c-0.046,0.062-0.068,0.13-0.068,0.188v0.031c0,0.063,0,0.092-0.062,0.182
               c-0.046,0.063-0.076,0.115-0.091,0.162c-0.016,0.053-0.022,0.098-0.022,0.158c0.007,0.023,0,0.063,0,0.114
               c-0.009,0.168-0.017,0.448,0.053,0.7c0.046,0.138,0.137,0.259,0.22,0.365c0.092,0.129,0.176,0.235,0.123,0.319
               c-0.039,0.069-0.1,0.13-0.168,0.185c-0.137,0.129-0.267,0.25-0.146,0.41c0.047,0.067,0.107,0.131,0.176,0.197
               c0.122,0.131,0.244,0.251,0.222,0.327c-0.008,0.056-0.023,0.056-0.054,0.062c-0.038,0.008-0.092,0.008-0.16,0.062
               c-0.068,0.054-0.167,0.101-0.274,0.146c-0.114,0.045-0.236,0.084-0.356,0.105c-0.123,0.023-0.359,0.031-0.588,0.031
               c-0.273,0.007-0.547,0.015-0.631,0.053c-0.046,0.023-0.092,0.077-0.123,0.145c-0.038,0.068-0.061,0.152-0.074,0.229
               c-0.017,0.084-0.009,0.168,0.014,0.213c0.008,0.028,0.031,0.054,0.054,0.067c0.053,0.023,0.16,0.023,0.281,0.023
               c0.152,0.008,0.328,0.008,0.335,0.055l0.009,0.006c0,0.009,0,0.023,0.007,0.037c0.016,0.084,0,0.139-0.221,0.177
               c-0.054,0.007-0.091,0.017-0.13,0.022c-0.213,0.03-0.297,0.047-0.342,0.166c0,0.016-0.007,0.031-0.016,0.047
               c-0.046,0.121-0.13,0.334-0.053,0.646c0.037,0.184,0.159,0.273,0.273,0.357c0.099,0.084,0.19,0.152,0.19,0.281
               c0.016,0.223,0.008,0.449,0.008,0.609c0,0.061-0.008,0.122-0.008,0.145c0,0.084-0.076,0.961-0.175,1.605
               c-0.046,0.268-0.084,0.497-0.13,0.596c-0.053-0.054-0.152-0.152-0.182-0.244c-0.047-0.1-0.1-0.105-0.169-0.113
               c-0.037-0.008-0.084-0.016-0.129-0.063c-0.046-0.045,0.061-0.259,0.16-0.457c0.091-0.174,0.175-0.342,0.16-0.433
               c-0.022-0.106-0.1-0.161-0.184-0.207c-0.061-0.038-0.114-0.067-0.152-0.122c-0.092-0.15-0.266-0.1-0.41-0.053
               c-0.038,0.016-0.069,0.022-0.1,0.031c-0.076,0.014-0.099,0-0.168-0.045c-0.022-0.025-0.06-0.055-0.114-0.084
               c-0.144-0.093-0.22,0-0.296,0.074c-0.039,0.038-0.069,0.075-0.099,0.068c-0.092-0.022-0.131,0-0.191,0.031
               c-0.038,0.015-0.084,0.037-0.159,0.045c-0.092,0.008-0.184,0.015-0.273-0.008c-0.085-0.016-0.169-0.047-0.253-0.106
               c-0.075-0.054-0.046-0.104-0.015-0.159c0.023-0.061,0.053-0.122,0.038-0.197c-0.031-0.1-0.099-0.107-0.183-0.121
               c-0.062-0.009-0.138-0.018-0.19-0.086c-0.054-0.068-0.054-0.105-0.054-0.152c0.008-0.059,0.008-0.121-0.068-0.233
               c-0.076-0.114-0.206-0.185-0.32-0.245c-0.091-0.045-0.167-0.091-0.175-0.121c-0.008-0.031-0.008-0.054-0.008-0.076
               c0-0.076,0-0.119-0.105-0.221c-0.039-0.039-0.069-0.092-0.107-0.146c-0.053-0.098-0.113-0.197-0.229-0.219
               c-0.082-0.018-0.122-0.055-0.167-0.093c-0.031-0.03-0.062-0.062-0.114-0.084c-0.092-0.038-0.32,0.062-0.411,0.101
               c-0.03-0.056-0.062-0.106-0.084-0.162c-0.068-0.137-0.138-0.157-0.213-0.196c-0.03-0.017-0.069-0.03-0.1-0.054
               c-0.03-0.023-0.053-0.047-0.068-0.068c-0.046-0.068-0.076-0.105-0.228-0.068c-0.084,0.022-0.13,0.063-0.176,0.1
               c-0.038,0.029-0.076,0.062-0.168,0.039c-0.045-0.01-0.091-0.018-0.129-0.024c-0.152-0.03-0.281-0.052-0.365-0.136
               c-0.03-0.039-0.047-0.077-0.062-0.123c-0.022-0.083-0.053-0.167-0.198-0.221c-0.091-0.029-0.152-0.016-0.197-0.008
               c-0.038,0.016-0.068,0.016-0.137-0.106c-0.115-0.22-0.313-0.304-0.473-0.365c-0.046-0.021-0.084-0.037-0.122-0.052
               c-0.045-0.032-0.114-0.055-0.175-0.077c-0.023-0.062-0.03-0.13,0.008-0.199c0.099-0.166,0.305,0.084,0.357-0.196
               c0.062-0.283-0.259-0.274,0.023-0.488c0.281-0.213,0.434,0.214,0.67-0.303c0.235-0.51,0.267-0.533,0.411-0.717
               c0.145-0.184,0.235-0.16,0.122-0.334c-0.107-0.185-0.176-0.093-0.129-0.298c0.045-0.198-0.13-0.457-0.039-0.7
               c0.084-0.252,0-0.404-0.023-0.486c-0.029-0.078,0.13-0.207,0.138-0.307c0.016-0.105-0.183-0.203-0.159-0.287
               c0.021-0.084,0.129-0.123,0.129-0.261c0-0.137-0.016-0.183-0.122-0.272c-0.099-0.101-0.167-0.084-0.16-0.213
               c0.008-0.131-0.03-0.162-0.083-0.185c-0.046-0.03-0.138-0.03-0.122-0.075C49.385,66.417,49.507,66.41,49.567,66.279z"/>
             <path fill="#808184" d="M63.296,63.188c0,0-0.222,0.029-0.206,0.167
               c0.022,0.136,0.03,0.19,0,0.243c-0.03,0.054-0.357,0.168-0.251,0.259c0.099,0.092-0.023,0.054,0.205,0.046
               c0.229-0.015,0.343,0.023,0.373-0.068c0.031-0.083,0.009-0.313,0.054-0.38c0.046-0.077,0.205-0.298,0.099-0.344
               C63.463,63.073,63.296,63.188,63.296,63.188"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M63.296,63.188c0,0-0.222,0.029-0.206,0.167c0.022,0.136,0.03,0.19,0,0.243c-0.03,0.054-0.357,0.168-0.251,0.259
               c0.099,0.092-0.023,0.054,0.205,0.046c0.229-0.015,0.343,0.023,0.373-0.068c0.031-0.083,0.009-0.313,0.054-0.38
               c0.046-0.077,0.205-0.298,0.099-0.344C63.463,63.073,63.296,63.188,63.296,63.188z"/>
             <path fill="#808184" d="M63.212,58.017c-0.031,0.115-0.138,0.321-0.008,0.358
               c0.138,0.047,0.167,0.008,0.167-0.076c0-0.083-0.007-0.167,0.062-0.175c0.06,0,0.251,0.023,0.167-0.084
               c-0.083-0.098-0.075-0.137-0.099-0.182c-0.03-0.046-0.13-0.084-0.137,0.008c0,0.083,0.007,0.174-0.03,0.159
               c-0.047-0.008-0.062-0.053-0.084-0.045C63.227,57.987,63.212,58.017,63.212,58.017 M63.044,61.756
               c-0.022,0.008-0.251,0.312-0.137,0.312c0.121,0,0.229-0.236,0.205-0.289C63.09,61.726,63.044,61.756,63.044,61.756
                M63.318,61.208c0,0.007,0.023,0.076,0.068,0.038c0.03-0.053,0.008-0.137-0.038-0.122C63.296,61.14,63.318,61.208,63.318,61.208
                M63.448,60.782c0,0-0.122,0.114-0.054,0.159c0.061,0.053,0.084,0.084,0.114,0.046c0.03-0.046,0.129-0.228,0.067-0.259
               C63.509,60.69,63.448,60.782,63.448,60.782 M64.719,60.896c-0.007,0.053-0.029,0.129,0.054,0.182
               c0.084,0.062,0.153,0.122,0.168,0.046c0.008-0.075,0.022-0.198-0.054-0.236c-0.084-0.03-0.076-0.167-0.114-0.145
               C64.727,60.774,64.719,60.896,64.719,60.896 M63.66,60.157c-0.022,0.054-0.159,0.198-0.061,0.274
               c0.092,0.069,0.168,0.084,0.168,0c0.008-0.091-0.016-0.335-0.038-0.335C63.707,60.089,63.66,60.157,63.66,60.157 M63.531,59.418
               c-0.007,0.015,0,0.13,0.061,0.19c0.068,0.061,0.008,0.099,0.084,0.153c0.084,0.06,0.176,0.167,0.184,0.076
               c0-0.092-0.046-0.328-0.1-0.389c-0.061-0.061-0.16-0.122-0.205-0.114C63.517,59.351,63.531,59.418,63.531,59.418 M63.387,58.657
               c0,0,0,0.091,0.03,0.168c0.038,0.083-0.022,0.137,0.031,0.19c0.061,0.054,0.099,0.099,0.106,0.031
               c0.015-0.076,0.021-0.389-0.038-0.427C63.448,58.574,63.387,58.657,63.387,58.657 M63.204,57.279
               c-0.023,0.031-0.053,0.122,0.054,0.13c0.113,0.015,0.183-0.106,0.129-0.168c-0.053-0.053-0.053-0.083-0.114-0.061
               C63.212,57.196,63.204,57.279,63.204,57.279 M63.555,58.345c0,0.062,0.045,0.137,0.091,0.106c0.038-0.037,0.054-0.22-0.015-0.22
               C63.562,58.231,63.555,58.345,63.555,58.345"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M63.212,58.017c-0.031,0.115-0.138,0.321-0.008,0.358c0.138,0.047,0.167,0.008,0.167-0.076c0-0.083-0.007-0.167,0.062-0.175
               c0.06,0,0.251,0.023,0.167-0.084c-0.083-0.098-0.075-0.137-0.099-0.182c-0.03-0.046-0.13-0.084-0.137,0.008
               c0,0.083,0.007,0.174-0.03,0.159c-0.047-0.008-0.062-0.053-0.084-0.045C63.227,57.987,63.212,58.017,63.212,58.017z
                M63.044,61.756c-0.022,0.008-0.251,0.312-0.137,0.312c0.121,0,0.229-0.236,0.205-0.289
               C63.09,61.726,63.044,61.756,63.044,61.756z M63.318,61.208c0,0.007,0.023,0.076,0.068,0.038
               c0.03-0.053,0.008-0.137-0.038-0.122C63.296,61.14,63.318,61.208,63.318,61.208z M63.448,60.782c0,0-0.122,0.114-0.054,0.159
               c0.061,0.053,0.084,0.084,0.114,0.046c0.03-0.046,0.129-0.228,0.067-0.259C63.509,60.69,63.448,60.782,63.448,60.782z
                M64.719,60.896c-0.007,0.053-0.029,0.129,0.054,0.182c0.084,0.062,0.153,0.122,0.168,0.046
               c0.008-0.075,0.022-0.198-0.054-0.236c-0.084-0.03-0.076-0.167-0.114-0.145C64.727,60.774,64.719,60.896,64.719,60.896z
                M63.66,60.157c-0.022,0.054-0.159,0.198-0.061,0.274c0.092,0.069,0.168,0.084,0.168,0c0.008-0.091-0.016-0.335-0.038-0.335
               C63.707,60.089,63.66,60.157,63.66,60.157z M63.531,59.418c-0.007,0.015,0,0.13,0.061,0.19c0.068,0.061,0.008,0.099,0.084,0.153
               c0.084,0.06,0.176,0.167,0.184,0.076c0-0.092-0.046-0.328-0.1-0.389c-0.061-0.061-0.16-0.122-0.205-0.114
               C63.517,59.351,63.531,59.418,63.531,59.418z M63.387,58.657c0,0,0,0.091,0.03,0.168c0.038,0.083-0.022,0.137,0.031,0.19
               c0.061,0.054,0.099,0.099,0.106,0.031c0.015-0.076,0.021-0.389-0.038-0.427C63.448,58.574,63.387,58.657,63.387,58.657z
                M63.204,57.279c-0.023,0.031-0.053,0.122,0.054,0.13c0.113,0.015,0.183-0.106,0.129-0.168
               c-0.053-0.053-0.053-0.083-0.114-0.061C63.212,57.196,63.204,57.279,63.204,57.279z M63.555,58.345
               c0,0.062,0.045,0.137,0.091,0.106c0.038-0.037,0.054-0.22-0.015-0.22C63.562,58.231,63.555,58.345,63.555,58.345z"/>
           
              </a>
        
            
          <!-- fin amérique du sud  -->
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
              
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
              <path fill="#808184" d="M83.609,2.032c-0.038,0-1.271,0.243-1.462,0.396
              c-0.198,0.146,0.046,0.243,0.229,0.243s0.487-0.182,0.479-0.007c-0.015,0.182-0.106,0.083-0.343,0.274
              c-0.235,0.19-0.357,0.32-0.761,0.32s-0.549-0.046-1.135,0.068c-0.586,0.115-0.875-0.023-1.219,0.092
              c-0.342,0.114-0.692,0.075-1.012,0.136c-0.32,0.061-0.595-0.029-0.533,0.237c0.061,0.259,0.016,0.349,0.359,0.349
              c0.341,0,0.41,0,0.661,0.069c0.251,0.062,0.221,0.092,0.586,0.062c0.365-0.024,0.312-0.016,0.556-0.016
              c0.244,0,0.662-0.16,0.563-0.023c-0.099,0.137-0.183,0.213-0.586,0.205c-0.405-0.007-0.579,0.061-1.165,0.039
              c-0.579-0.023-0.814-0.069-0.846-0.046c-0.038,0.015-0.495-0.091-0.434,0.099s0,0.16,0.229,0.221s0.465-0.008,0.646,0.03
              c0.184,0.038,0.389,0.105,0.244,0.183c-0.137,0.076-0.632-0.084-0.381,0.16c0.26,0.243,0.267,0.228,0.709,0.266
              c0.434,0.031,0.464-0.053,0.639-0.144c0.176-0.084,0.137-0.038,0.457,0c0.327,0.037,0.32-0.038,0.693-0.023
              c0.381,0.023,0.464-0.076,0.967-0.031c0.51,0.046,0.456,0.016,0.929,0.084c0.472,0.076,0.525,0.069,0.738,0.221
              c0.214,0.144,0.092,0.107,0.244,0.366c0.151,0.251,0.419,0.182,0.441,0.319c0.03,0.144-0.099,0.153-0.068,0.32
              c0.023,0.167,0.114,0.434,0.167,0.578c0.054,0.153-0.205,0.031-0.084,0.457c0.123,0.434,0.313,0.488-0.159,0.641
              c-0.472,0.144-0.747,0.464-0.51,0.593c0.235,0.122,0.426,0.167,0.654,0.098c0.236-0.067,0.274-0.304,0.503-0.174
              c0.228,0.129,0.327,0.243,0.472,0.274c0.152,0.038,0.73,0.411,0.427,0.502c-0.305,0.084-0.214-0.045-0.686-0.189
              c-0.472-0.146-0.617-0.091-0.776-0.091c-0.152,0-0.503-0.153-0.449,0.06c0.053,0.221,0.524,0.313,0.319,0.336
              c-0.213,0.022-0.457-0.076-0.594,0.037c-0.145,0.115-0.297,0.229-0.351,0.29c-0.053,0.061-0.213,0.221-0.03,0.32
              s0.152,0.145,0.457,0.175c0.312,0.03,0.465-0.053,0.691-0.068c0.229-0.016,0.459-0.008,0.488-0.176
              c0.023-0.168,0.062-0.259-0.121-0.281c-0.184-0.031-0.411-0.236-0.411-0.236s-0.038-0.107,0.145-0.069s0.358,0.099,0.472,0.153
              c0.114,0.052,0.236,0.06,0.389,0.052c0.145,0,0.357-0.068,0.281,0.062c-0.076,0.136-0.524,0.144-0.396,0.274
              c0.121,0.129,0.175,0.091,0.091,0.198c-0.091,0.114-0.121,0.107-0.197,0.266c-0.068,0.16-0.092,0.35-0.533,0.404
              c-0.434,0.053-0.746-0.069-0.906,0.121c-0.167,0.191-0.258,0.183-0.449,0.359c-0.19,0.175-0.343,0.22-0.381,0.418
              c-0.03,0.198-0.328,0.236-0.152,0.365c0.175,0.13,0.289,0.168,0.1,0.267c-0.183,0.091-0.411,0.602-0.298,0.709
              c0.122,0.114,0.153-0.092,0.215,0.296c0.06,0.381,0.09,0.464,0.029,0.601c-0.061,0.145-0.122,0.351,0.054,0.305
              c0.182-0.045,0.403-0.381,0.434-0.198c0.038,0.183,0.03,0.221-0.206,0.396c-0.235,0.175-0.456,0.381-0.273,0.533
              c0.183,0.153,0.243,0.145,0.243,0.259c0,0.106,0.274,0.122,0.19,0.282c-0.091,0.16-0.145,0.259-0.106,0.296
              c0.03,0.031,0.511-0.136,0.373,0.191c-0.129,0.32-0.175,0.365-0.068,0.419c0.1,0.061,0.13,0.075,0.19,0.191
              c0.062,0.113,0.175,0.007,0.19,0.227c0.016,0.229-0.222,0.298,0.076,0.359c0.297,0.052,0.259,0.014,0.441-0.016
              c0.19-0.038,0.198-0.061,0.351-0.045c0.159,0.022,0.403-0.176,0.586,0.022c0.183,0.198,0.114,0.267,0.19,0.351
              c0.084,0.091-0.122,0.152,0.16,0.229c0.274,0.083,0.258,0.007,0.457,0.129c0.198,0.121,0.251,0.229,0.403,0.152
              c0.16-0.084,0.197-0.145,0.319-0.229c0.122-0.076,0.138-0.16,0.32-0.251c0.175-0.099,0.175-0.533,0.319-0.617
              c0.146-0.084,0.389-0.335,0.548-0.533c0.16-0.198,0.26-0.267,0.26-0.418c0-0.146,0.092-0.229,0.137-0.343
              c0.047-0.114-0.076-0.053,0.198-0.206c0.273-0.144,0.434-0.289,0.593-0.35c0.16-0.061,0.267-0.183,0.419-0.251
              c0.145-0.061,0.344-0.312,0.298-0.457c-0.046-0.137-0.373-0.228-0.176-0.289c0.19-0.061,0.26,0.091,0.366-0.039
              c0.113-0.136-0.046-0.342,0.029-0.448c0.076-0.107,0.222-0.107,0.336-0.116c0.114-0.007,0.365-0.289,0.601-0.318
              c0.237-0.038,0.32,0.007,0.557-0.055c0.236-0.067,0.519-0.494,0.571-0.356c0.053,0.145-0.275,0.258-0.107,0.334
              c0.168,0.077,0.244,0.077,0.473-0.03c0.229-0.099,0.236-0.373,0.502-0.289c0.26,0.091,0.251,0.289,0.487,0.098
              c0.236-0.197,0.708-0.35,1.029-0.571c0.326-0.213,0.326-0.487,0.615-0.563c0.29-0.076,0.267,0.016,0.571-0.19
              c0.305-0.214,0.495-0.206,0.571-0.366c0.084-0.16,0.113-0.35,0.289-0.19c0.168,0.167,0.122,0.282,0.479,0.19
              c0.351-0.091,0.519-0.213,0.83-0.19c0.306,0.03,0.138,0.083,0.769-0.054c0.641-0.137,1.082-0.289,1.646-0.342
              c0.556-0.053,1.073-0.541,1.438-0.587c0.373-0.045,1.143-0.212,0.83-0.327c-0.305-0.106-0.145-0.083-0.586-0.069
              c-0.434,0.023-0.951-0.151-1.256-0.113c-0.305,0.03-0.556,0.037-0.48-0.092c0.069-0.129,0.419-0.008,0.647-0.213
              c0.229-0.214,0.076-0.464,0.343-0.487c0.267-0.031,0.403-0.024,0.434,0.228c0.023,0.252,0.259,0.289,0.503,0.358
              c0.243,0.069,0.396,0.099,0.594,0.084c0.205-0.008,0.465,0.152,0.495-0.069c0.022-0.229,0.259-0.487,0.076-0.701
              c-0.184-0.205-0.351-0.349-0.594-0.411c-0.252-0.061-0.503-0.205-0.647-0.259c-0.137-0.053-0.229-0.159-0.236-0.274
              c0-0.114-0.35-0.281-0.235-0.388c0.106-0.106,0.175-0.183,0.41-0.068c0.244,0.114,0.381,0.092,0.587,0.06
              c0.198-0.023-0.198-0.426,0.175-0.358c0.366,0.069,0.427,0.306,0.632,0.306c0.206,0,0.876-0.031,1.097-0.176
              c0.213-0.152,0.243-0.297,0.038-0.327c-0.204-0.023-0.411-0.107-0.16-0.152c0.244-0.046,0.441,0.075,0.762,0.022
              c0.313-0.053,0.449-0.289,0.259-0.35c-0.198-0.068-0.206-0.068-0.297-0.183c-0.1-0.114-0.259-0.235-0.061-0.243
              c0.19-0.007,0.175,0.19,0.372,0.076c0.198-0.122,0.252-0.282,0.168-0.412c-0.076-0.121,0.152-0.266-0.129-0.334
              c-0.289-0.069-0.503-0.031-0.709-0.099c-0.213-0.076-0.57-0.29-0.357-0.298c0.206-0.007,0.214,0.13,0.404,0.046
              c0.189-0.084,0.41-0.144,0.691-0.144c0.282,0,0.442-0.069,0.488,0.075c0.046,0.146-0.038,0.29,0.046,0.458
              c0.091,0.175,0.234,0.358,0.251,0.106c0.016-0.259-0.092-0.426,0.108-0.525c0.196-0.107,0.531-0.274,0.219-0.365
              c-0.304-0.1-0.259-0.024-0.487-0.062c-0.236-0.045-0.662-0.106-0.57-0.182c0.099-0.076,0.266-0.008,0.388,0
              c0.122,0.007,0.434-0.038,0.305-0.114c-0.122-0.084-0.571-0.304-0.807-0.213c-0.236,0.098-0.351,0.289-0.411,0.144
              c-0.062-0.152,0.189-0.221,0.259-0.289c0.068-0.069,0.053-0.343,0.357-0.343c0.305-0.008,0.64,0.114,0.655-0.068
              c0.008-0.183-0.008-0.785,0.518-0.663c0.525,0.122,0.784,0.107,1.134-0.083c0.351-0.191,0.457-0.435,0.968-0.472
              c0.518-0.039,2.284-0.161,1.773-0.42c-0.518-0.251-0.868-0.418-1.484-0.297c-0.625,0.122-0.884,0.138-1.324,0.153
              c-0.45,0.008-0.746,0.053-1.258,0.122c-0.509,0.069-1.453,0.51-1.416,0.297c0.039-0.221,1.014-0.503,0.558-0.557
              c-0.466-0.053-0.771-0.121-1.044,0.024c-0.267,0.137-0.274,0.167-0.456,0.106c-0.185-0.061-0.191-0.137-0.39-0.137
              c-0.19,0-0.754,0.015-0.518-0.092c0.236-0.113,0.396-0.121,0.693-0.083c0.304,0.038,0.776,0.015,1.188-0.015
              c0.411-0.023,1.119-0.054,0.921-0.152c-0.198-0.099-0.571-0.214-0.845-0.198c-0.267,0.015-0.587,0.075-0.8,0.007
              c-0.221-0.068-0.472-0.335-1.058-0.335c-0.595,0-0.687-0.045-1.188-0.007c-0.51,0.03-2.779,0.091-3.244,0.084
              c-0.463-0.016-0.769-0.061-0.951,0.037c-0.184,0.099-0.075,0.275-0.632,0.214c-0.556-0.061-0.914-0.122-1.188-0.145
              c-0.273-0.03-2.618-0.015-2.664,0.206c-0.046,0.221,0.373,0.091,0.67,0.205c0.297,0.114,0.494,0.289,0.198,0.297
              c-0.297,0.008-0.373-0.198-0.724-0.221c-0.344-0.015-0.48,0.061-0.814-0.053c-0.328-0.114-1.066-0.297-1.111-0.13
              c-0.046,0.161,0.19,0.237,0.045,0.313c-0.144,0.075-0.129,0.038-0.373-0.053c-0.243-0.084-0.77-0.366-0.875-0.161
              c-0.106,0.198-0.336,0.381-0.366,0.229c-0.022-0.145-0.068-0.259-0.304-0.282c-0.244-0.03-0.663,0.137-1.394,0.145
              c-0.731,0.015-1.014-0.008-1.363,0.069c-0.351,0.084-0.837,0-0.891,0.121c-0.053,0.122,0.175,0.229-0.152,0.313
              c-0.32,0.083-0.214,0.122-0.617,0.038c-0.403-0.077-0.472-0.122-0.7-0.054C84.287,1.871,83.609,2.032,83.609,2.032"/>
            <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M83.609,2.032
              c-0.038,0-1.271,0.243-1.462,0.396c-0.198,0.146,0.046,0.243,0.229,0.243s0.487-0.182,0.479-0.007
              c-0.015,0.182-0.106,0.083-0.343,0.274c-0.235,0.19-0.357,0.32-0.761,0.32s-0.549-0.046-1.135,0.068
              c-0.586,0.115-0.875-0.023-1.219,0.092c-0.342,0.114-0.692,0.075-1.012,0.136c-0.32,0.061-0.595-0.029-0.533,0.237
              c0.061,0.259,0.016,0.349,0.359,0.349c0.341,0,0.41,0,0.661,0.069c0.251,0.062,0.221,0.092,0.586,0.062
              c0.365-0.024,0.312-0.016,0.556-0.016c0.244,0,0.662-0.16,0.563-0.023c-0.099,0.137-0.183,0.213-0.586,0.205
              c-0.405-0.007-0.579,0.061-1.165,0.039c-0.579-0.023-0.814-0.069-0.846-0.046c-0.038,0.015-0.495-0.091-0.434,0.099
              s0,0.16,0.229,0.221s0.465-0.008,0.646,0.03c0.184,0.038,0.389,0.105,0.244,0.183c-0.137,0.076-0.632-0.084-0.381,0.16
              c0.26,0.243,0.267,0.228,0.709,0.266c0.434,0.031,0.464-0.053,0.639-0.144c0.176-0.084,0.137-0.038,0.457,0
              c0.327,0.037,0.32-0.038,0.693-0.023c0.381,0.023,0.464-0.076,0.967-0.031c0.51,0.046,0.456,0.016,0.929,0.084
              c0.472,0.076,0.525,0.069,0.738,0.221c0.214,0.144,0.092,0.107,0.244,0.366c0.151,0.251,0.419,0.182,0.441,0.319
              c0.03,0.144-0.099,0.153-0.068,0.32c0.023,0.167,0.114,0.434,0.167,0.578c0.054,0.153-0.205,0.031-0.084,0.457
              c0.123,0.434,0.313,0.488-0.159,0.641c-0.472,0.144-0.747,0.464-0.51,0.593c0.235,0.122,0.426,0.167,0.654,0.098
              c0.236-0.067,0.274-0.304,0.503-0.174c0.228,0.129,0.327,0.243,0.472,0.274c0.152,0.038,0.73,0.411,0.427,0.502
              c-0.305,0.084-0.214-0.045-0.686-0.189c-0.472-0.146-0.617-0.091-0.776-0.091c-0.152,0-0.503-0.153-0.449,0.06
              c0.053,0.221,0.524,0.313,0.319,0.336c-0.213,0.022-0.457-0.076-0.594,0.037c-0.145,0.115-0.297,0.229-0.351,0.29
              c-0.053,0.061-0.213,0.221-0.03,0.32s0.152,0.145,0.457,0.175c0.312,0.03,0.465-0.053,0.691-0.068
              c0.229-0.016,0.459-0.008,0.488-0.176c0.023-0.168,0.062-0.259-0.121-0.281c-0.184-0.031-0.411-0.236-0.411-0.236
              s-0.038-0.107,0.145-0.069s0.358,0.099,0.472,0.153c0.114,0.052,0.236,0.06,0.389,0.052c0.145,0,0.357-0.068,0.281,0.062
              c-0.076,0.136-0.524,0.144-0.396,0.274c0.121,0.129,0.175,0.091,0.091,0.198c-0.091,0.114-0.121,0.107-0.197,0.266
              c-0.068,0.16-0.092,0.35-0.533,0.404c-0.434,0.053-0.746-0.069-0.906,0.121c-0.167,0.191-0.258,0.183-0.449,0.359
              c-0.19,0.175-0.343,0.22-0.381,0.418c-0.03,0.198-0.328,0.236-0.152,0.365c0.175,0.13,0.289,0.168,0.1,0.267
              c-0.183,0.091-0.411,0.602-0.298,0.709c0.122,0.114,0.153-0.092,0.215,0.296c0.06,0.381,0.09,0.464,0.029,0.601
              c-0.061,0.145-0.122,0.351,0.054,0.305c0.182-0.045,0.403-0.381,0.434-0.198c0.038,0.183,0.03,0.221-0.206,0.396
              c-0.235,0.175-0.456,0.381-0.273,0.533c0.183,0.153,0.243,0.145,0.243,0.259c0,0.106,0.274,0.122,0.19,0.282
              c-0.091,0.16-0.145,0.259-0.106,0.296c0.03,0.031,0.511-0.136,0.373,0.191c-0.129,0.32-0.175,0.365-0.068,0.419
              c0.1,0.061,0.13,0.075,0.19,0.191c0.062,0.113,0.175,0.007,0.19,0.227c0.016,0.229-0.222,0.298,0.076,0.359
              c0.297,0.052,0.259,0.014,0.441-0.016c0.19-0.038,0.198-0.061,0.351-0.045c0.159,0.022,0.403-0.176,0.586,0.022
              c0.183,0.198,0.114,0.267,0.19,0.351c0.084,0.091-0.122,0.152,0.16,0.229c0.274,0.083,0.258,0.007,0.457,0.129
              c0.198,0.121,0.251,0.229,0.403,0.152c0.16-0.084,0.197-0.145,0.319-0.229c0.122-0.076,0.138-0.16,0.32-0.251
              c0.175-0.099,0.175-0.533,0.319-0.617c0.146-0.084,0.389-0.335,0.548-0.533c0.16-0.198,0.26-0.267,0.26-0.418
              c0-0.146,0.092-0.229,0.137-0.343c0.047-0.114-0.076-0.053,0.198-0.206c0.273-0.144,0.434-0.289,0.593-0.35
              c0.16-0.061,0.267-0.183,0.419-0.251c0.145-0.061,0.344-0.312,0.298-0.457c-0.046-0.137-0.373-0.228-0.176-0.289
              c0.19-0.061,0.26,0.091,0.366-0.039c0.113-0.136-0.046-0.342,0.029-0.448c0.076-0.107,0.222-0.107,0.336-0.116
              c0.114-0.007,0.365-0.289,0.601-0.318c0.237-0.038,0.32,0.007,0.557-0.055c0.236-0.067,0.519-0.494,0.571-0.356
              c0.053,0.145-0.275,0.258-0.107,0.334c0.168,0.077,0.244,0.077,0.473-0.03c0.229-0.099,0.236-0.373,0.502-0.289
              c0.26,0.091,0.251,0.289,0.487,0.098c0.236-0.197,0.708-0.35,1.029-0.571c0.326-0.213,0.326-0.487,0.615-0.563
              c0.29-0.076,0.267,0.016,0.571-0.19c0.305-0.214,0.495-0.206,0.571-0.366c0.084-0.16,0.113-0.35,0.289-0.19
              c0.168,0.167,0.122,0.282,0.479,0.19c0.351-0.091,0.519-0.213,0.83-0.19c0.306,0.03,0.138,0.083,0.769-0.054
              c0.641-0.137,1.082-0.289,1.646-0.342c0.556-0.053,1.073-0.541,1.438-0.587c0.373-0.045,1.143-0.212,0.83-0.327
              c-0.305-0.106-0.145-0.083-0.586-0.069c-0.434,0.023-0.951-0.151-1.256-0.113c-0.305,0.03-0.556,0.037-0.48-0.092
              c0.069-0.129,0.419-0.008,0.647-0.213c0.229-0.214,0.076-0.464,0.343-0.487c0.267-0.031,0.403-0.024,0.434,0.228
              c0.023,0.252,0.259,0.289,0.503,0.358c0.243,0.069,0.396,0.099,0.594,0.084c0.205-0.008,0.465,0.152,0.495-0.069
              c0.022-0.229,0.259-0.487,0.076-0.701c-0.184-0.205-0.351-0.349-0.594-0.411c-0.252-0.061-0.503-0.205-0.647-0.259
              c-0.137-0.053-0.229-0.159-0.236-0.274c0-0.114-0.35-0.281-0.235-0.388c0.106-0.106,0.175-0.183,0.41-0.068
              c0.244,0.114,0.381,0.092,0.587,0.06c0.198-0.023-0.198-0.426,0.175-0.358c0.366,0.069,0.427,0.306,0.632,0.306
              c0.206,0,0.876-0.031,1.097-0.176c0.213-0.152,0.243-0.297,0.038-0.327c-0.204-0.023-0.411-0.107-0.16-0.152
              c0.244-0.046,0.441,0.075,0.762,0.022c0.313-0.053,0.449-0.289,0.259-0.35c-0.198-0.068-0.206-0.068-0.297-0.183
              c-0.1-0.114-0.259-0.235-0.061-0.243c0.19-0.007,0.175,0.19,0.372,0.076c0.198-0.122,0.252-0.282,0.168-0.412
              c-0.076-0.121,0.152-0.266-0.129-0.334c-0.289-0.069-0.503-0.031-0.709-0.099c-0.213-0.076-0.57-0.29-0.357-0.298
              c0.206-0.007,0.214,0.13,0.404,0.046c0.189-0.084,0.41-0.144,0.691-0.144c0.282,0,0.442-0.069,0.488,0.075
              c0.046,0.146-0.038,0.29,0.046,0.458c0.091,0.175,0.234,0.358,0.251,0.106c0.016-0.259-0.092-0.426,0.108-0.525
              c0.196-0.107,0.531-0.274,0.219-0.365c-0.304-0.1-0.259-0.024-0.487-0.062c-0.236-0.045-0.662-0.106-0.57-0.182
              c0.099-0.076,0.266-0.008,0.388,0c0.122,0.007,0.434-0.038,0.305-0.114c-0.122-0.084-0.571-0.304-0.807-0.213
              c-0.236,0.098-0.351,0.289-0.411,0.144c-0.062-0.152,0.189-0.221,0.259-0.289c0.068-0.069,0.053-0.343,0.357-0.343
              c0.305-0.008,0.64,0.114,0.655-0.068c0.008-0.183-0.008-0.785,0.518-0.663c0.525,0.122,0.784,0.107,1.134-0.083
              c0.351-0.191,0.457-0.435,0.968-0.472c0.518-0.039,2.284-0.161,1.773-0.42c-0.518-0.251-0.868-0.418-1.484-0.297
              c-0.625,0.122-0.884,0.138-1.324,0.153c-0.45,0.008-0.746,0.053-1.258,0.122c-0.509,0.069-1.453,0.51-1.416,0.297
              c0.039-0.221,1.014-0.503,0.558-0.557c-0.466-0.053-0.771-0.121-1.044,0.024c-0.267,0.137-0.274,0.167-0.456,0.106
              c-0.185-0.061-0.191-0.137-0.39-0.137c-0.19,0-0.754,0.015-0.518-0.092c0.236-0.113,0.396-0.121,0.693-0.083
              c0.304,0.038,0.776,0.015,1.188-0.015c0.411-0.023,1.119-0.054,0.921-0.152c-0.198-0.099-0.571-0.214-0.845-0.198
              c-0.267,0.015-0.587,0.075-0.8,0.007c-0.221-0.068-0.472-0.335-1.058-0.335c-0.595,0-0.687-0.045-1.188-0.007
              c-0.51,0.03-2.779,0.091-3.244,0.084c-0.463-0.016-0.769-0.061-0.951,0.037c-0.184,0.099-0.075,0.275-0.632,0.214
              c-0.556-0.061-0.914-0.122-1.188-0.145c-0.273-0.03-2.618-0.015-2.664,0.206c-0.046,0.221,0.373,0.091,0.67,0.205
              c0.297,0.114,0.494,0.289,0.198,0.297c-0.297,0.008-0.373-0.198-0.724-0.221c-0.344-0.015-0.48,0.061-0.814-0.053
              c-0.328-0.114-1.066-0.297-1.111-0.13c-0.046,0.161,0.19,0.237,0.045,0.313c-0.144,0.075-0.129,0.038-0.373-0.053
              c-0.243-0.084-0.77-0.366-0.875-0.161c-0.106,0.198-0.336,0.381-0.366,0.229c-0.022-0.145-0.068-0.259-0.304-0.282
              c-0.244-0.03-0.663,0.137-1.394,0.145c-0.731,0.015-1.014-0.008-1.363,0.069c-0.351,0.084-0.837,0-0.891,0.121
              c-0.053,0.122,0.175,0.229-0.152,0.313c-0.32,0.083-0.214,0.122-0.617,0.038c-0.403-0.077-0.472-0.122-0.7-0.054
              C84.287,1.871,83.609,2.032,83.609,2.032z"/>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
              
        
        
        
        
        
           <!--    africa   -->
        
           
                <a class="africa" onclick='DealerShow("africa_dealer","africa");' > 
               <path fill="#808184" d="M142.253,51.888c-0.077-0.092-0.129-0.213-0.129-0.335
               c0-0.274,0.145-0.404,0.09-0.602c-0.053-0.19-0.402-0.212-0.523-0.517c-0.131-0.297-0.533-1.089-0.655-1.371
               c-0.121-0.281-0.335-0.243-0.335-0.738c0-0.495-0.067-0.64-0.213-0.838c-0.152-0.19-0.579-0.624-0.694-0.837
               c-0.104-0.214-0.062-0.229-0.213-0.427c-0.144-0.205-0.259-0.366-0.259-0.487c0-0.122,0.107-0.395,0.177-0.267
               c0.067,0.122,0.166,0.534,0.295,0.663c0.13,0.13,0.214,0.076,0.214,0.281c0,0.214-0.046,0.214,0.137,0.404
               c0.191,0.183,0.366,0.297,0.52,0.48c0.152,0.19,0.145,0.258,0.252,0.213c0.1-0.053,0.105,0.008,0.174-0.19
               c0.063-0.199-0.113-0.138-0.021-0.411c0.1-0.267,0.189-0.457,0.189-0.602c0-0.137,0.047-0.67-0.084-0.868
               c-0.129-0.198-0.494-1.18-0.549-1.188c-0.053-0.008,0.031,0.038-0.258,0.076c-0.289,0.03-0.449-0.061-0.578-0.022
               c-0.13,0.038-0.184,0.129-0.352,0.106c-0.168-0.03-0.213-0.145-0.336-0.145c-0.121-0.007-0.211,0-0.318-0.107
               c-0.105-0.113,0.046-0.19-0.235-0.167c-0.29,0.023-0.274-0.045-0.435-0.038c-0.168,0.008-0.107,0.046-0.297,0.099
               c-0.191,0.046-0.045-0.121-0.282,0.023c-0.235,0.145-0.197,0.129-0.319,0.229c-0.113,0.106-0.41,0.304-0.586,0.258
               c-0.176-0.053-0.121-0.206-0.473-0.197c-0.35,0.007-0.273,0.174-0.488,0.03c-0.221-0.137-0.045-0.153-0.287-0.182
               c-0.252-0.032-0.184-0.184-0.48-0.214c-0.291-0.038-0.291,0.046-0.494-0.038c-0.197-0.076-0.213-0.107-0.51-0.053
               c-0.205,0.038-0.306,0.045-0.344,0.023c-0.037,0.045-0.068,0.098-0.084,0.144c-0.037,0.099-0.045,0.19-0.007,0.244
               c0.099,0.152-0.008,0.426-0.101,0.662l-0.021,0.053c-0.076,0.191-0.031,0.335,0.066,0.647l0.039,0.107
               c0.063,0.198,0.101,0.427,0.121,0.716c0.023,0.274,0.039,0.616,0.047,1.05c0.008,0.176,0.016,0.557,0.031,1.043
               c0.037,1.159,0.09,2.94,0.121,4.241c0.28-0.007,1.813-0.03,2.381-0.03c0.397,0,1.221-0.007,1.838-0.03
               c0.396-0.008,0.707-0.031,0.746-0.046c0.115-0.06,0.174-0.022,0.281,0.046c0.03,0.023,0.061,0.046,0.105,0.068
               c0.092,0.054,0.382,0.023,0.654-0.008c0.16-0.014,0.319-0.03,0.428-0.022c0.175,0,0.251,0.076,0.318,0.152
               c0.062,0.062,0.123,0.122,0.244,0.129c0.176,0,0.184-0.076,0.184-0.159c0.007-0.038,0.007-0.076,0.015-0.114
               c0.039-0.16,0.122-0.168,0.351-0.198l0.076-0.008c0.129-0.015,0.146-0.045,0.16-0.091c0.008-0.038,0.021-0.092,0.075-0.145
               c0.055-0.061,0.062-0.099,0.062-0.13c0.008-0.053,0.008-0.098,0.084-0.152c0.062-0.053,0.106-0.053,0.166-0.053
               c0.047,0,0.108,0,0.198-0.061C142.176,51.957,142.214,51.926,142.253,51.888"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M142.253,51.888c-0.077-0.092-0.129-0.213-0.129-0.335c0-0.274,0.145-0.404,0.09-0.602c-0.053-0.19-0.402-0.212-0.523-0.517
               c-0.131-0.297-0.533-1.089-0.655-1.371c-0.121-0.281-0.335-0.243-0.335-0.738c0-0.495-0.067-0.64-0.213-0.838
               c-0.152-0.19-0.579-0.624-0.694-0.837c-0.104-0.214-0.062-0.229-0.213-0.427c-0.144-0.205-0.259-0.366-0.259-0.487
               c0-0.122,0.107-0.395,0.177-0.267c0.067,0.122,0.166,0.534,0.295,0.663c0.13,0.13,0.214,0.076,0.214,0.281
               c0,0.214-0.046,0.214,0.137,0.404c0.191,0.183,0.366,0.297,0.52,0.48c0.152,0.19,0.145,0.258,0.252,0.213
               c0.1-0.053,0.105,0.008,0.174-0.19c0.063-0.199-0.113-0.138-0.021-0.411c0.1-0.267,0.189-0.457,0.189-0.602
               c0-0.137,0.047-0.67-0.084-0.868c-0.129-0.198-0.494-1.18-0.549-1.188c-0.053-0.008,0.031,0.038-0.258,0.076
               c-0.289,0.03-0.449-0.061-0.578-0.022c-0.13,0.038-0.184,0.129-0.352,0.106c-0.168-0.03-0.213-0.145-0.336-0.145
               c-0.121-0.007-0.211,0-0.318-0.107c-0.105-0.113,0.046-0.19-0.235-0.167c-0.29,0.023-0.274-0.045-0.435-0.038
               c-0.168,0.008-0.107,0.046-0.297,0.099c-0.191,0.046-0.045-0.121-0.282,0.023c-0.235,0.145-0.197,0.129-0.319,0.229
               c-0.113,0.106-0.41,0.304-0.586,0.258c-0.176-0.053-0.121-0.206-0.473-0.197c-0.35,0.007-0.273,0.174-0.488,0.03
               c-0.221-0.137-0.045-0.153-0.287-0.182c-0.252-0.032-0.184-0.184-0.48-0.214c-0.291-0.038-0.291,0.046-0.494-0.038
               c-0.197-0.076-0.213-0.107-0.51-0.053c-0.205,0.038-0.306,0.045-0.344,0.023c-0.037,0.045-0.068,0.098-0.084,0.144
               c-0.037,0.099-0.045,0.19-0.007,0.244c0.099,0.152-0.008,0.426-0.101,0.662l-0.021,0.053c-0.076,0.191-0.031,0.335,0.066,0.647
               l0.039,0.107c0.063,0.198,0.101,0.427,0.121,0.716c0.023,0.274,0.039,0.616,0.047,1.05c0.008,0.176,0.016,0.557,0.031,1.043
               c0.037,1.159,0.09,2.94,0.121,4.241c0.28-0.007,1.813-0.03,2.381-0.03c0.397,0,1.221-0.007,1.838-0.03
               c0.396-0.008,0.707-0.031,0.746-0.046c0.115-0.06,0.174-0.022,0.281,0.046c0.03,0.023,0.061,0.046,0.105,0.068
               c0.092,0.054,0.382,0.023,0.654-0.008c0.16-0.014,0.319-0.03,0.428-0.022c0.175,0,0.251,0.076,0.318,0.152
               c0.062,0.062,0.123,0.122,0.244,0.129c0.176,0,0.184-0.076,0.184-0.159c0.007-0.038,0.007-0.076,0.015-0.114
               c0.039-0.16,0.122-0.168,0.351-0.198l0.076-0.008c0.129-0.015,0.146-0.045,0.16-0.091c0.008-0.038,0.021-0.092,0.075-0.145
               c0.055-0.061,0.062-0.099,0.062-0.13c0.008-0.053,0.008-0.098,0.084-0.152c0.062-0.053,0.106-0.053,0.166-0.053
               c0.047,0,0.108,0,0.198-0.061C142.176,51.957,142.214,51.926,142.253,51.888z"/>
             <path fill="#808184" d="M104.03,66.987c0.168,0.177,0.685,0.448,1.127,0.868
               c0.16,0.152,0.441,0.268,0.51,0.473c0.122,0.364,0.594,0.434,0.968,0.709c0.35,0.266,0.456,0.41,0.692,0.371
               c-0.03-0.184-0.061-0.41-0.038-0.602c0.015-0.076,0.023-0.145,0.03-0.205c0.038-0.252,0.068-0.436,0.054-0.541
               c0-0.037-0.031-0.053-0.061-0.063c-0.046-0.015-0.106-0.037-0.153-0.099c-0.053-0.053-0.061-0.099-0.076-0.151
               c-0.015-0.054-0.022-0.1-0.099-0.151c-0.068-0.038-0.151-0.046-0.221-0.046c-0.076-0.008-0.145-0.008-0.197-0.069
               c-0.047-0.054-0.062-0.104-0.062-0.151c0-0.053,0.022-0.1,0.047-0.152c0.015-0.045,0.029-0.129,0.045-0.213
               c0.015-0.092,0.029-0.19,0.038-0.25c0.007-0.055,0-0.094-0.009-0.131c-0.015-0.062-0.029-0.121-0.015-0.229
               c-0.068-0.021-0.213-0.061-0.267-0.036c-0.038,0.015-0.053,0.099-0.068,0.166c-0.008,0.054-0.016,0.106-0.038,0.138
               c-0.061,0.1-0.145,0.062-0.267,0.008c-0.022-0.008-0.045-0.022-0.075-0.039c-0.184-0.067-0.153-0.182-0.123-0.311l0.008-0.009
               c0.008-0.054,0-0.129-0.015-0.214c-0.016-0.075-0.023-0.159-0.023-0.234c0-0.078,0-0.123-0.015-0.152
               c-0.022-0.039-0.069-0.068-0.146-0.107c-0.068-0.029-0.228,0-0.389,0.023c-0.037,0.008-0.075,0.016-0.113,0.022
               c0.008,0.037,0.008,0.083,0.008,0.099c-0.008,0.063-0.114,0.106-0.236,0.168l-0.03,0.008c-0.03,0.016-0.023,0.029-0.023,0.054
               c0.016,0.054,0.023,0.115-0.045,0.188c-0.062,0.063-0.114,0.115-0.168,0.177c-0.054,0.062-0.099,0.114-0.137,0.159
               c-0.038,0.039-0.062,0.084-0.092,0.138c-0.045,0.067-0.1,0.151-0.167,0.222C104.129,66.88,104.076,66.941,104.03,66.987"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M104.03,66.987c0.168,0.177,0.685,0.448,1.127,0.868c0.16,0.152,0.441,0.268,0.51,0.473c0.122,0.364,0.594,0.434,0.968,0.709
               c0.35,0.266,0.456,0.41,0.692,0.371c-0.03-0.184-0.061-0.41-0.038-0.602c0.015-0.076,0.023-0.145,0.03-0.205
               c0.038-0.252,0.068-0.436,0.054-0.541c0-0.037-0.031-0.053-0.061-0.063c-0.046-0.015-0.106-0.037-0.153-0.099
               c-0.053-0.053-0.061-0.099-0.076-0.151c-0.015-0.054-0.022-0.1-0.099-0.151c-0.068-0.038-0.151-0.046-0.221-0.046
               c-0.076-0.008-0.145-0.008-0.197-0.069c-0.047-0.054-0.062-0.104-0.062-0.151c0-0.053,0.022-0.1,0.047-0.152
               c0.015-0.045,0.029-0.129,0.045-0.213c0.015-0.092,0.029-0.19,0.038-0.25c0.007-0.055,0-0.094-0.009-0.131
               c-0.015-0.062-0.029-0.121-0.015-0.229c-0.068-0.021-0.213-0.061-0.267-0.036c-0.038,0.015-0.053,0.099-0.068,0.166
               c-0.008,0.054-0.016,0.106-0.038,0.138c-0.061,0.1-0.145,0.062-0.267,0.008c-0.022-0.008-0.045-0.022-0.075-0.039
               c-0.184-0.067-0.153-0.182-0.123-0.311l0.008-0.009c0.008-0.054,0-0.129-0.015-0.214c-0.016-0.075-0.023-0.159-0.023-0.234
               c0-0.078,0-0.123-0.015-0.152c-0.022-0.039-0.069-0.068-0.146-0.107c-0.068-0.029-0.228,0-0.389,0.023
               c-0.037,0.008-0.075,0.016-0.113,0.022c0.008,0.037,0.008,0.083,0.008,0.099c-0.008,0.063-0.114,0.106-0.236,0.168l-0.03,0.008
               c-0.03,0.016-0.023,0.029-0.023,0.054c0.016,0.054,0.023,0.115-0.045,0.188c-0.062,0.063-0.114,0.115-0.168,0.177
               c-0.054,0.062-0.099,0.114-0.137,0.159c-0.038,0.039-0.062,0.084-0.092,0.138c-0.045,0.067-0.1,0.151-0.167,0.222
               C104.129,66.88,104.076,66.941,104.03,66.987z"/>
             <path fill="#808184" d="M102.743,64.968c0.008,0.039,0,0.084-0.015,0.121
               c-0.069,0.191,0,0.373,0.061,0.572c0.053,0.205-0.068,0.655,0.198,0.77c0.274,0.113,0.632,0.1,0.838,0.326
               c0.045,0.047,0.091,0.107,0.137,0.152c0.046-0.045,0.099-0.105,0.152-0.16c0.068-0.067,0.114-0.137,0.152-0.205
               c0.03-0.055,0.061-0.106,0.099-0.152c0.046-0.045,0.099-0.1,0.145-0.158c0.054-0.063,0.114-0.115,0.168-0.177
               c0.037-0.037,0.03-0.075,0.022-0.104c-0.008-0.063-0.022-0.113,0.076-0.162l0.03-0.015c0.099-0.038,0.183-0.075,0.183-0.075
               c0-0.016-0.007-0.047-0.007-0.076c-0.114,0.008-0.214,0-0.252-0.068c-0.053-0.107-0.007-0.182,0.038-0.244
               c0.016-0.021,0.03-0.037,0.023-0.037c-0.046-0.046-0.084-0.122-0.092-0.236c0-0.084,0.015-0.189,0.054-0.32
               c0.053-0.158-0.054-0.227-0.168-0.297c-0.061-0.037-0.122-0.075-0.167-0.128l-0.046-0.046c-0.084-0.099-0.092-0.099-0.229-0.145
               c-0.054-0.022-0.145,0-0.229,0.022c-0.138,0.039-0.274,0.069-0.396-0.008c-0.046-0.037-0.069-0.022-0.084,0
               c-0.016,0.017-0.022,0.038-0.038,0.063c-0.03,0.053-0.062,0.113-0.106,0.166c-0.054,0.054-0.092,0.115-0.122,0.183
               c-0.046,0.084-0.092,0.175-0.205,0.274C102.896,64.871,102.819,64.923,102.743,64.968"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M102.743,64.968c0.008,0.039,0,0.084-0.015,0.121c-0.069,0.191,0,0.373,0.061,0.572c0.053,0.205-0.068,0.655,0.198,0.77
               c0.274,0.113,0.632,0.1,0.838,0.326c0.045,0.047,0.091,0.107,0.137,0.152c0.046-0.045,0.099-0.105,0.152-0.16
               c0.068-0.067,0.114-0.137,0.152-0.205c0.03-0.055,0.061-0.106,0.099-0.152c0.046-0.045,0.099-0.1,0.145-0.158
               c0.054-0.063,0.114-0.115,0.168-0.177c0.037-0.037,0.03-0.075,0.022-0.104c-0.008-0.063-0.022-0.113,0.076-0.162l0.03-0.015
               c0.099-0.038,0.183-0.075,0.183-0.075c0-0.016-0.007-0.047-0.007-0.076c-0.114,0.008-0.214,0-0.252-0.068
               c-0.053-0.107-0.007-0.182,0.038-0.244c0.016-0.021,0.03-0.037,0.023-0.037c-0.046-0.046-0.084-0.122-0.092-0.236
               c0-0.084,0.015-0.189,0.054-0.32c0.053-0.158-0.054-0.227-0.168-0.297c-0.061-0.037-0.122-0.075-0.167-0.128l-0.046-0.046
               c-0.084-0.099-0.092-0.099-0.229-0.145c-0.054-0.022-0.145,0-0.229,0.022c-0.138,0.039-0.274,0.069-0.396-0.008
               c-0.046-0.037-0.069-0.022-0.084,0c-0.016,0.017-0.022,0.038-0.038,0.063c-0.03,0.053-0.062,0.113-0.106,0.166
               c-0.054,0.054-0.092,0.115-0.122,0.183c-0.046,0.084-0.092,0.175-0.205,0.274C102.896,64.871,102.819,64.923,102.743,64.968z"/>
             <path fill="#808184" d="M101.319,63.012c0.122,0.106,0.222,0.236,0.229,0.38
               c0.023,0.359,0.145,0.549,0.282,0.617c0.136,0.069,0.494,0.236,0.639,0.465c0.092,0.137,0.198,0.268,0.244,0.396
               c0.069-0.045,0.128-0.092,0.184-0.146c0.099-0.093,0.144-0.167,0.182-0.251c0.038-0.068,0.068-0.131,0.137-0.197
               c0.039-0.046,0.069-0.099,0.092-0.145c0.016-0.023,0.03-0.054,0.046-0.069c0.046-0.083,0.107-0.113,0.229-0.029
               c0.076,0.052,0.19,0.021,0.305-0.009c0.106-0.023,0.204-0.054,0.297-0.023c0.158,0.053,0.168,0.062,0.267,0.177l0.046,0.045
               c0.037,0.046,0.091,0.076,0.145,0.113c0.145,0.092,0.289,0.183,0.213,0.41c-0.038,0.115-0.055,0.215-0.046,0.291
               c0,0.076,0.023,0.129,0.054,0.152c0.068,0.061,0.03,0.114-0.008,0.176c-0.03,0.036-0.061,0.09-0.03,0.137
               c0.022,0.045,0.19,0.015,0.357-0.016c0.168-0.031,0.351-0.063,0.442-0.017c0.106,0.046,0.159,0.091,0.19,0.146
               c0.028,0.055,0.038,0.113,0.028,0.205c0,0.068,0.017,0.145,0.024,0.221c0.015,0.094,0.031,0.185,0.015,0.252l-0.007,0.008
               c-0.016,0.091-0.038,0.16,0.061,0.199c0.03,0.015,0.054,0.021,0.084,0.037c0.069,0.029,0.122,0.053,0.129,0.029
               c0.016-0.016,0.023-0.063,0.031-0.1c0.022-0.098,0.037-0.205,0.12-0.242c0.084-0.038,0.253,0,0.335,0.029
               c0.024-0.062,0.047-0.092,0.077-0.121c0.023-0.016,0.039-0.031,0.054-0.063c0.008-0.031,0.022-0.053,0.038-0.076
               c0.022-0.045,0.046-0.084,0.031-0.129c-0.023-0.045-0.047-0.099-0.062-0.16c-0.016-0.062-0.016-0.129,0.022-0.189
               c0.039-0.075,0.084-0.084,0.146-0.092c0.037-0.008,0.083-0.016,0.121-0.053c0.038-0.031-0.016-0.092-0.062-0.152
               c-0.03-0.039-0.068-0.076-0.083-0.113c-0.046-0.092-0.008-0.152,0.046-0.223c0.03-0.037,0.061-0.075,0.076-0.129
               c0.007-0.03-0.023-0.045-0.054-0.055c-0.062-0.015-0.129-0.037-0.145-0.137c-0.007-0.021-0.016-0.061-0.03-0.1
               c-0.054-0.182-0.138-0.464,0.13-0.569c0.066-0.03,0.091-0.039,0.091-0.039c-0.008-0.007-0.054-0.03-0.1-0.053
               c-0.045-0.017-0.083-0.038-0.121-0.062c-0.106-0.083-0.106-0.159-0.093-0.326c0.009-0.046,0.009-0.092,0.009-0.146
               c0.008-0.129-0.076-0.129-0.159-0.138c-0.062-0.008-0.115-0.008-0.161-0.037c-0.053-0.046-0.045-0.084-0.015-0.13
               c0.023-0.03,0.053-0.054,0.092-0.076c0.061-0.053,0.137-0.106,0.114-0.16c-0.03-0.06-0.1-0.129-0.168-0.198
               c-0.084-0.099-0.183-0.198-0.229-0.319c-0.046-0.115-0.03-0.168-0.015-0.206c0-0.015,0.007-0.031-0.031-0.076
               c-0.023-0.03-0.038-0.054-0.053-0.076c-0.046-0.062-0.062-0.069-0.152-0.114c-0.03-0.008-0.038-0.008-0.046,0
               c-0.007,0.021-0.007,0.052-0.016,0.084c-0.008,0.03-0.015,0.068-0.022,0.114c-0.053,0.167-0.114,0.182-0.213,0.213
               c-0.008,0-0.022,0.007-0.038,0.007c-0.084,0.031-0.138,0.007-0.221-0.03c-0.046-0.015-0.1-0.038-0.16-0.054
               c-0.138-0.038-0.259,0.062-0.313,0.115c-0.016,0.008-0.022,0.015-0.03,0.023c0,0-0.009,0-0.009,0.007
               c-0.037,0.016-0.075,0.008-0.113-0.015c-0.022-0.023-0.054-0.053-0.083-0.084c-0.023-0.037-0.046-0.076-0.069-0.114
               c-0.008,0.015-0.015,0.023-0.022,0.038c-0.022,0.053-0.054,0.107-0.091,0.145c-0.062,0.053-0.106,0.068-0.168,0.06
               c-0.046-0.007-0.1-0.037-0.152-0.091c-0.068-0.075-0.046-0.19-0.03-0.282c0.015-0.045,0.021-0.084,0.015-0.091
               c0-0.008-0.03-0.008-0.084,0.007c-0.03,0.009-0.076,0.016-0.129,0.024c-0.054,0.008-0.092,0.015-0.137,0.03
               c-0.092,0.023-0.19,0.046-0.403,0.023c-0.229-0.023-0.268-0.053-0.351-0.122c-0.031-0.015-0.061-0.038-0.114-0.069
               c-0.122-0.076-0.244-0.076-0.473-0.076h-0.03c0,0.046,0,0.107-0.007,0.145c-0.008,0.069-0.062,0.099-0.152,0.145
               c-0.038,0.023-0.084,0.046-0.138,0.083c-0.022,0.016-0.022,0.038-0.008,0.061c0.016,0.023,0.046,0.054,0.077,0.084
               c0.068,0.069,0.129,0.137,0.105,0.206c-0.022,0.068-0.075,0.106-0.137,0.137c-0.061,0.015-0.129,0.023-0.205,0.023
               c-0.062,0-0.092,0.008-0.145,0.03c-0.054,0.031-0.123,0.092-0.26,0.19C101.472,62.792,101.389,62.906,101.319,63.012"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M101.319,63.012c0.122,0.106,0.222,0.236,0.229,0.38c0.023,0.359,0.145,0.549,0.282,0.617c0.136,0.069,0.494,0.236,0.639,0.465
               c0.092,0.137,0.198,0.268,0.244,0.396c0.069-0.045,0.128-0.092,0.184-0.146c0.099-0.093,0.144-0.167,0.182-0.251
               c0.038-0.068,0.068-0.131,0.137-0.197c0.039-0.046,0.069-0.099,0.092-0.145c0.016-0.023,0.03-0.054,0.046-0.069
               c0.046-0.083,0.107-0.113,0.229-0.029c0.076,0.052,0.19,0.021,0.305-0.009c0.106-0.023,0.204-0.054,0.297-0.023
               c0.158,0.053,0.168,0.062,0.267,0.177l0.046,0.045c0.037,0.046,0.091,0.076,0.145,0.113c0.145,0.092,0.289,0.183,0.213,0.41
               c-0.038,0.115-0.055,0.215-0.046,0.291c0,0.076,0.023,0.129,0.054,0.152c0.068,0.061,0.03,0.114-0.008,0.176
               c-0.03,0.036-0.061,0.09-0.03,0.137c0.022,0.045,0.19,0.015,0.357-0.016c0.168-0.031,0.351-0.063,0.442-0.017
               c0.106,0.046,0.159,0.091,0.19,0.146c0.028,0.055,0.038,0.113,0.028,0.205c0,0.068,0.017,0.145,0.024,0.221
               c0.015,0.094,0.031,0.185,0.015,0.252l-0.007,0.008c-0.016,0.091-0.038,0.16,0.061,0.199c0.03,0.015,0.054,0.021,0.084,0.037
               c0.069,0.029,0.122,0.053,0.129,0.029c0.016-0.016,0.023-0.063,0.031-0.1c0.022-0.098,0.037-0.205,0.12-0.242
               c0.084-0.038,0.253,0,0.335,0.029c0.024-0.062,0.047-0.092,0.077-0.121c0.023-0.016,0.039-0.031,0.054-0.063
               c0.008-0.031,0.022-0.053,0.038-0.076c0.022-0.045,0.046-0.084,0.031-0.129c-0.023-0.045-0.047-0.099-0.062-0.16
               c-0.016-0.062-0.016-0.129,0.022-0.189c0.039-0.075,0.084-0.084,0.146-0.092c0.037-0.008,0.083-0.016,0.121-0.053
               c0.038-0.031-0.016-0.092-0.062-0.152c-0.03-0.039-0.068-0.076-0.083-0.113c-0.046-0.092-0.008-0.152,0.046-0.223
               c0.03-0.037,0.061-0.075,0.076-0.129c0.007-0.03-0.023-0.045-0.054-0.055c-0.062-0.015-0.129-0.037-0.145-0.137
               c-0.007-0.021-0.016-0.061-0.03-0.1c-0.054-0.182-0.138-0.464,0.13-0.569c0.066-0.03,0.091-0.039,0.091-0.039
               c-0.008-0.007-0.054-0.03-0.1-0.053c-0.045-0.017-0.083-0.038-0.121-0.062c-0.106-0.083-0.106-0.159-0.093-0.326
               c0.009-0.046,0.009-0.092,0.009-0.146c0.008-0.129-0.076-0.129-0.159-0.138c-0.062-0.008-0.115-0.008-0.161-0.037
               c-0.053-0.046-0.045-0.084-0.015-0.13c0.023-0.03,0.053-0.054,0.092-0.076c0.061-0.053,0.137-0.106,0.114-0.16
               c-0.03-0.06-0.1-0.129-0.168-0.198c-0.084-0.099-0.183-0.198-0.229-0.319c-0.046-0.115-0.03-0.168-0.015-0.206
               c0-0.015,0.007-0.031-0.031-0.076c-0.023-0.03-0.038-0.054-0.053-0.076c-0.046-0.062-0.062-0.069-0.152-0.114
               c-0.03-0.008-0.038-0.008-0.046,0c-0.007,0.021-0.007,0.052-0.016,0.084c-0.008,0.03-0.015,0.068-0.022,0.114
               c-0.053,0.167-0.114,0.182-0.213,0.213c-0.008,0-0.022,0.007-0.038,0.007c-0.084,0.031-0.138,0.007-0.221-0.03
               c-0.046-0.015-0.1-0.038-0.16-0.054c-0.138-0.038-0.259,0.062-0.313,0.115c-0.016,0.008-0.022,0.015-0.03,0.023
               c0,0-0.009,0-0.009,0.007c-0.037,0.016-0.075,0.008-0.113-0.015c-0.022-0.023-0.054-0.053-0.083-0.084
               c-0.023-0.037-0.046-0.076-0.069-0.114c-0.008,0.015-0.015,0.023-0.022,0.038c-0.022,0.053-0.054,0.107-0.091,0.145
               c-0.062,0.053-0.106,0.068-0.168,0.06c-0.046-0.007-0.1-0.037-0.152-0.091c-0.068-0.075-0.046-0.19-0.03-0.282
               c0.015-0.045,0.021-0.084,0.015-0.091c0-0.008-0.03-0.008-0.084,0.007c-0.03,0.009-0.076,0.016-0.129,0.024
               c-0.054,0.008-0.092,0.015-0.137,0.03c-0.092,0.023-0.19,0.046-0.403,0.023c-0.229-0.023-0.268-0.053-0.351-0.122
               c-0.031-0.015-0.061-0.038-0.114-0.069c-0.122-0.076-0.244-0.076-0.473-0.076h-0.03c0,0.046,0,0.107-0.007,0.145
               c-0.008,0.069-0.062,0.099-0.152,0.145c-0.038,0.023-0.084,0.046-0.138,0.083c-0.022,0.016-0.022,0.038-0.008,0.061
               c0.016,0.023,0.046,0.054,0.077,0.084c0.068,0.069,0.129,0.137,0.105,0.206c-0.022,0.068-0.075,0.106-0.137,0.137
               c-0.061,0.015-0.129,0.023-0.205,0.023c-0.062,0-0.092,0.008-0.145,0.03c-0.054,0.031-0.123,0.092-0.26,0.19
               C101.472,62.792,101.389,62.906,101.319,63.012z"/>
             <path fill="#808184" d="M100.032,61.923c0.084,0.045,0.13,0.045,0.176,0.175
               c0.084,0.191,0.068,0.168,0.281,0.191c0.214,0.022,0.541-0.099,0.396,0.084c-0.144,0.175-0.242,0.265-0.091,0.334
               c0.084,0.03,0.274,0.122,0.442,0.244c0.076-0.114,0.167-0.236,0.281-0.32c0.138-0.106,0.213-0.167,0.274-0.198
               c0.068-0.038,0.113-0.045,0.19-0.045c0.061,0,0.121-0.008,0.167-0.023c0.038-0.016,0.069-0.038,0.076-0.069
               c0.008-0.015-0.038-0.053-0.076-0.099c-0.038-0.038-0.068-0.068-0.091-0.106c-0.047-0.077-0.054-0.145,0.036-0.198
               c0.055-0.038,0.108-0.069,0.146-0.084c0.061-0.038,0.099-0.061,0.099-0.076c0.008-0.03,0.008-0.092,0-0.13h-0.152h-0.03
               c-0.419-0.015-0.815-0.023-0.959-0.015c-0.054,0.007-0.122,0.038-0.198,0.069c-0.138,0.068-0.305,0.145-0.511,0.145
               C100.344,61.793,100.17,61.855,100.032,61.923"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M100.032,61.923c0.084,0.045,0.13,0.045,0.176,0.175c0.084,0.191,0.068,0.168,0.281,0.191c0.214,0.022,0.541-0.099,0.396,0.084
               c-0.144,0.175-0.242,0.265-0.091,0.334c0.084,0.03,0.274,0.122,0.442,0.244c0.076-0.114,0.167-0.236,0.281-0.32
               c0.138-0.106,0.213-0.167,0.274-0.198c0.068-0.038,0.113-0.045,0.19-0.045c0.061,0,0.121-0.008,0.167-0.023
               c0.038-0.016,0.069-0.038,0.076-0.069c0.008-0.015-0.038-0.053-0.076-0.099c-0.038-0.038-0.068-0.068-0.091-0.106
               c-0.047-0.077-0.054-0.145,0.036-0.198c0.055-0.038,0.108-0.069,0.146-0.084c0.061-0.038,0.099-0.061,0.099-0.076
               c0.008-0.03,0.008-0.092,0-0.13h-0.152h-0.03c-0.419-0.015-0.815-0.023-0.959-0.015c-0.054,0.007-0.122,0.038-0.198,0.069
               c-0.138,0.068-0.305,0.145-0.511,0.145C100.344,61.793,100.17,61.855,100.032,61.923z"/>
             <path fill="#808184" d="M113.685,59.434l-0.045-0.008c-0.267-0.03-0.549-0.068-0.655-0.023
               c-0.106,0.038-0.197,0.107-0.297,0.176c-0.068,0.053-0.146,0.099-0.229,0.144c-0.068,0.039-0.152,0.053-0.229,0.069
               c-0.129,0.03-0.259,0.054-0.297,0.183c-0.015,0.061-0.022,0.106-0.03,0.145c-0.023,0.151-0.023,0.159-0.236,0.128
               c-0.099-0.015-0.168-0.044-0.229-0.068c-0.053-0.022-0.091-0.038-0.106-0.007c-0.03,0.053-0.084,0.106-0.137,0.159
               c-0.046,0.054-0.092,0.107-0.122,0.153c-0.008,0.023-0.023,0.068-0.03,0.129c-0.023,0.137-0.061,0.312-0.198,0.381
               c-0.114,0.061-0.205,0-0.297-0.061c-0.068-0.053-0.145-0.099-0.221-0.061l-0.031,0.015c-0.175,0.084-0.312,0.152-0.281,0.397
               c0.024,0.22-0.008,0.243-0.06,0.289c-0.024,0.007-0.047,0.029-0.069,0.068c-0.046,0.069-0.046,0.145-0.046,0.221
               c0,0.069,0,0.13-0.03,0.19c-0.061,0.114-0.312,0.206-0.473,0.275c-0.03,0.007-0.061,0.022-0.084,0.029
               c-0.015,0.009-0.029,0.016-0.045,0.032c-0.031,0.023-0.054,0.052-0.062,0.091c-0.015,0.038-0.015,0.076,0.008,0.121
               c0.008,0.016,0.015,0.031,0.031,0.054c0.091,0.121,0.053,0.189,0,0.272c-0.023,0.031-0.046,0.068-0.062,0.114
               c-0.038,0.13-0.03,0.214-0.03,0.313v0.068c0,0.083-0.016,0.13-0.038,0.167c-0.016,0.015-0.023,0.031-0.038,0.047
               c0.03,0.037,0.076,0.091,0.114,0.114c0.022,0.015,0.045,0.015,0.068,0.015c0.038,0,0.092,0.007,0.168,0.038
               c0.099,0.047,0.114,0.13,0.13,0.205c0.007,0.038,0.007,0.069,0.029,0.084c0.022,0.023,0.038,0.046,0.054,0.061
               c0.038,0.054,0.076,0.107,0.251,0.146c0.13,0.022,0.16-0.016,0.189-0.062c0.031-0.037,0.062-0.075,0.13-0.099
               c0.077-0.022,0.161-0.046,0.26-0.054c0.099-0.007,0.213-0.007,0.357-0.007c0.198,0,0.228,0.068,0.281,0.167
               c0.023,0.046,0.054,0.098,0.115,0.161c0.038-0.063,0.091-0.169,0.076-0.274c0-0.054-0.031-0.114-0.062-0.184
               c-0.046-0.114-0.099-0.243-0.076-0.403c0.008-0.039,0.016-0.076,0.022-0.114c0.031-0.19,0.054-0.327,0.176-0.343
               c0.046-0.008,0.205-0.016,0.41-0.016c0.306,0,0.709,0.008,0.884,0.016c0.092,0,0.152,0,0.198,0.008
               c0.1,0.008,0.129,0.008,0.198-0.03c0.068-0.038,0.137-0.069,0.197-0.091c0.062-0.016,0.115-0.023,0.161-0.016
               c0.045,0,0.121,0.016,0.197,0.037c0.099,0.023,0.213,0.054,0.283,0.076c0.067,0.016,0.143,0.031,0.211,0.022
               c0.054,0,0.106-0.016,0.146-0.046c0.03-0.015,0.053-0.03,0.084-0.046c0.099-0.061,0.213-0.129,0.259-0.183
               c0.015-0.015,0.022-0.038,0.03-0.061c0.016-0.084,0.038-0.153,0.259-0.091c0.114,0.029,0.206,0.037,0.281,0.021
               c0.068-0.021,0.123-0.068,0.168-0.145c0.076-0.114,0.084-0.159,0.099-0.243c0-0.023,0.008-0.061,0.016-0.107l0.007-0.038
               c-0.083-0.062-0.166-0.137-0.189-0.213c-0.016-0.069-0.016-0.107-0.016-0.137c0-0.046,0-0.084-0.076-0.198
               c-0.053-0.076-0.099-0.061-0.152-0.038c-0.061,0.022-0.13,0.053-0.221-0.008c-0.053-0.038-0.084-0.061-0.114-0.084
               c-0.038-0.038-0.076-0.069-0.206-0.137c-0.144-0.076-0.151-0.137-0.105-0.221c0.007-0.023,0.022-0.045,0.045-0.068
               c0.023-0.039,0.061-0.084,0.084-0.145c0.017-0.045-0.008-0.069-0.053-0.083c-0.031-0.008-0.069-0.023-0.107-0.031
               c-0.091-0.023-0.19-0.046-0.259-0.114c-0.076-0.069-0.091-0.145-0.105-0.221c-0.016-0.068-0.023-0.137-0.107-0.206
               c-0.092-0.076-0.138-0.16-0.152-0.266c-0.022-0.099-0.008-0.214,0.015-0.358C113.678,59.563,113.685,59.495,113.685,59.434"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M113.685,59.434l-0.045-0.008c-0.267-0.03-0.549-0.068-0.655-0.023c-0.106,0.038-0.197,0.107-0.297,0.176
               c-0.068,0.053-0.146,0.099-0.229,0.144c-0.068,0.039-0.152,0.053-0.229,0.069c-0.129,0.03-0.259,0.054-0.297,0.183
               c-0.015,0.061-0.022,0.106-0.03,0.145c-0.023,0.151-0.023,0.159-0.236,0.128c-0.099-0.015-0.168-0.044-0.229-0.068
               c-0.053-0.022-0.091-0.038-0.106-0.007c-0.03,0.053-0.084,0.106-0.137,0.159c-0.046,0.054-0.092,0.107-0.122,0.153
               c-0.008,0.023-0.023,0.068-0.03,0.129c-0.023,0.137-0.061,0.312-0.198,0.381c-0.114,0.061-0.205,0-0.297-0.061
               c-0.068-0.053-0.145-0.099-0.221-0.061l-0.031,0.015c-0.175,0.084-0.312,0.152-0.281,0.397c0.024,0.22-0.008,0.243-0.06,0.289
               c-0.024,0.007-0.047,0.029-0.069,0.068c-0.046,0.069-0.046,0.145-0.046,0.221c0,0.069,0,0.13-0.03,0.19
               c-0.061,0.114-0.312,0.206-0.473,0.275c-0.03,0.007-0.061,0.022-0.084,0.029c-0.015,0.009-0.029,0.016-0.045,0.032
               c-0.031,0.023-0.054,0.052-0.062,0.091c-0.015,0.038-0.015,0.076,0.008,0.121c0.008,0.016,0.015,0.031,0.031,0.054
               c0.091,0.121,0.053,0.189,0,0.272c-0.023,0.031-0.046,0.068-0.062,0.114c-0.038,0.13-0.03,0.214-0.03,0.313v0.068
               c0,0.083-0.016,0.13-0.038,0.167c-0.016,0.015-0.023,0.031-0.038,0.047c0.03,0.037,0.076,0.091,0.114,0.114
               c0.022,0.015,0.045,0.015,0.068,0.015c0.038,0,0.092,0.007,0.168,0.038c0.099,0.047,0.114,0.13,0.13,0.205
               c0.007,0.038,0.007,0.069,0.029,0.084c0.022,0.023,0.038,0.046,0.054,0.061c0.038,0.054,0.076,0.107,0.251,0.146
               c0.13,0.022,0.16-0.016,0.189-0.062c0.031-0.037,0.062-0.075,0.13-0.099c0.077-0.022,0.161-0.046,0.26-0.054
               c0.099-0.007,0.213-0.007,0.357-0.007c0.198,0,0.228,0.068,0.281,0.167c0.023,0.046,0.054,0.098,0.115,0.161
               c0.038-0.063,0.091-0.169,0.076-0.274c0-0.054-0.031-0.114-0.062-0.184c-0.046-0.114-0.099-0.243-0.076-0.403
               c0.008-0.039,0.016-0.076,0.022-0.114c0.031-0.19,0.054-0.327,0.176-0.343c0.046-0.008,0.205-0.016,0.41-0.016
               c0.306,0,0.709,0.008,0.884,0.016c0.092,0,0.152,0,0.198,0.008c0.1,0.008,0.129,0.008,0.198-0.03
               c0.068-0.038,0.137-0.069,0.197-0.091c0.062-0.016,0.115-0.023,0.161-0.016c0.045,0,0.121,0.016,0.197,0.037
               c0.099,0.023,0.213,0.054,0.283,0.076c0.067,0.016,0.143,0.031,0.211,0.022c0.054,0,0.106-0.016,0.146-0.046
               c0.03-0.015,0.053-0.03,0.084-0.046c0.099-0.061,0.213-0.129,0.259-0.183c0.015-0.015,0.022-0.038,0.03-0.061
               c0.016-0.084,0.038-0.153,0.259-0.091c0.114,0.029,0.206,0.037,0.281,0.021c0.068-0.021,0.123-0.068,0.168-0.145
               c0.076-0.114,0.084-0.159,0.099-0.243c0-0.023,0.008-0.061,0.016-0.107l0.007-0.038c-0.083-0.062-0.166-0.137-0.189-0.213
               c-0.016-0.069-0.016-0.107-0.016-0.137c0-0.046,0-0.084-0.076-0.198c-0.053-0.076-0.099-0.061-0.152-0.038
               c-0.061,0.022-0.13,0.053-0.221-0.008c-0.053-0.038-0.084-0.061-0.114-0.084c-0.038-0.038-0.076-0.069-0.206-0.137
               c-0.144-0.076-0.151-0.137-0.105-0.221c0.007-0.023,0.022-0.045,0.045-0.068c0.023-0.039,0.061-0.084,0.084-0.145
               c0.017-0.045-0.008-0.069-0.053-0.083c-0.031-0.008-0.069-0.023-0.107-0.031c-0.091-0.023-0.19-0.046-0.259-0.114
               c-0.076-0.069-0.091-0.145-0.105-0.221c-0.016-0.068-0.023-0.137-0.107-0.206c-0.092-0.076-0.138-0.16-0.152-0.266
               c-0.022-0.099-0.008-0.214,0.015-0.358C113.678,59.563,113.685,59.495,113.685,59.434z"/>
             <path fill="#808184" d="M125.563,52.125c-0.068,0.045-0.137,0.099-0.19,0.137
               c-0.106,0.076-0.183,0.13-0.213,0.145c-0.068,0.03-0.145,0.015-0.229-0.054c-0.068-0.053-0.146-0.152-0.229-0.304
               c-0.145-0.244-0.937-0.427-1.264-0.503c-0.046-0.015-0.076-0.023-0.099-0.023c-0.114-0.03-0.503,0.16-0.952,0.464
               c-0.381,0.252-0.8,0.587-1.104,0.922c-0.464,0.51-0.678,0.594-0.981,0.716c-0.145,0.053-0.313,0.114-0.541,0.243
               c-0.411,0.221-0.677,0.541-0.921,0.83c-0.168,0.191-0.327,0.373-0.51,0.51c-0.336,0.259-0.884,0.374-1.303,0.441
               c0,0.161,0.008,0.725,0.014,0.838c0,0.084-0.021,0.602-0.052,1.028c-0.016,0.282-0.046,0.534-0.068,0.594
               c-0.023,0.061-0.038,0.099-0.054,0.13c-0.016,0.046-0.03,0.068-0.045,0.152v0.007c-0.031,0.153-0.062,0.321-0.229,0.465
               c-0.145,0.115-0.19,0.152-0.252,0.189l-0.015,0.016c-0.046,0.038-0.114,0.03-0.19,0.03c-0.068,0-0.145-0.007-0.206,0.015
               c-0.06,0.023-0.222,0.023-0.418,0.016c-0.32,0-0.731-0.008-0.876,0.076c-0.1,0.053-0.145,0.099-0.183,0.136
               c-0.076,0.061-0.122,0.107-0.306,0.123c-0.083,0.007-0.212-0.007-0.364-0.024c0,0.069,0,0.138-0.016,0.206
               c-0.022,0.129-0.03,0.236-0.016,0.328c0.016,0.076,0.046,0.145,0.121,0.205c0.107,0.091,0.123,0.175,0.139,0.259
               c0.015,0.061,0.028,0.122,0.083,0.175c0.038,0.046,0.13,0.069,0.206,0.084c0.045,0.015,0.084,0.023,0.121,0.038
               c0.099,0.038,0.153,0.092,0.107,0.213c-0.023,0.069-0.062,0.122-0.092,0.168c-0.023,0.023-0.03,0.038-0.038,0.053
               c-0.015,0.031-0.008,0.045,0.062,0.084c0.144,0.077,0.183,0.106,0.228,0.152c0.023,0.016,0.055,0.046,0.106,0.076
               c0.038,0.031,0.084,0.015,0.122-0.007c0.092-0.031,0.176-0.069,0.274,0.076c0.091,0.144,0.091,0.19,0.091,0.259
               c0,0.023,0,0.053,0.016,0.107c0.016,0.045,0.061,0.098,0.114,0.136c0.038-0.121,0.1-0.228,0.313-0.236
               c0.16-0.007,0.228,0.084,0.297,0.191c0.046,0.06,0.091,0.137,0.175,0.167c0.062,0.023,0.106,0.046,0.152,0.069
               c0.137,0.068,0.1,0.068,0.084-0.023c-0.023-0.114-0.03-0.244-0.023-0.35c0.016-0.122,0.054-0.221,0.138-0.282
               c0.068-0.053,0.129-0.107,0.183-0.167c0.045-0.062,0.076-0.137,0.092-0.229c0.007-0.03,0.007-0.061,0.015-0.091
               c0.022-0.183,0.046-0.312,0.152-0.381c0.03-0.015,0.052-0.038,0.076-0.061c0.076-0.076,0.16-0.167,0.396-0.152
               c0.222,0.015,0.267,0,0.343-0.031c0.016-0.008,0.046-0.015,0.076-0.03c0.038-0.008,0.062-0.03,0.076-0.046
               c0.077-0.053,0.122-0.091,0.305,0.038c0.114,0.076,0.206,0.092,0.29,0.107c0.074,0.015,0.152,0.022,0.235,0.084
               c0.1,0.076,0.152,0.16,0.206,0.251c0.038,0.06,0.076,0.122,0.122,0.175c0.061,0.068,0.136,0.114,0.212,0.129
               c0.077,0.008,0.152-0.007,0.236-0.053c0.046-0.023,0.092-0.053,0.13-0.083c0.13-0.091,0.229-0.153,0.349-0.084
               c0.032,0.015,0.063,0.038,0.108,0.06c0.183,0.122,0.517,0.335,0.867,0.358c0.313,0.023,0.389-0.045,0.503-0.144
               c0.038-0.038,0.092-0.084,0.159-0.13c0.13-0.091,0.352-0.16,0.57-0.205c0.245-0.046,0.489-0.069,0.625-0.038
               c0.107,0.022,0.176,0.06,0.245,0.099c0.113,0.053,0.221,0.114,0.44,0.098c0.267-0.014,0.358-0.105,0.465-0.205
               c0.03-0.03,0.068-0.068,0.114-0.107c0.092-0.068,0.19-0.099,0.282-0.121c0.091-0.031,0.159-0.054,0.167-0.092
               c0-0.03-0.015-0.091-0.037-0.175c-0.069-0.205-0.161-0.51,0.037-0.708v-0.008c0.114-0.114,0.267-0.373,0.435-0.647
               c0.197-0.342,0.418-0.708,0.608-0.898c0.175-0.16,0.305-0.328,0.396-0.488c0.091-0.152,0.137-0.289,0.13-0.403
               c-0.008-0.153,0.007-0.914,0.045-1.538c0.022-0.404,0.054-0.754,0.092-0.876c0.022-0.068,0.046-0.121,0.061-0.175
               c0.076-0.183,0.128-0.319-0.038-0.403c-0.13-0.077-0.214-0.115-0.259-0.168c-0.046-0.061-0.062-0.129-0.062-0.243
               c0-0.046-0.022-0.061-0.053-0.077c-0.076-0.038-0.175-0.091-0.183-0.38c0-0.206-0.016-0.381-0.03-0.526
               c-0.008-0.168-0.03-0.297-0.054-0.395C125.653,52.308,125.601,52.185,125.563,52.125"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M125.563,52.125c-0.068,0.045-0.137,0.099-0.19,0.137c-0.106,0.076-0.183,0.13-0.213,0.145c-0.068,0.03-0.145,0.015-0.229-0.054
               c-0.068-0.053-0.146-0.152-0.229-0.304c-0.145-0.244-0.937-0.427-1.264-0.503c-0.046-0.015-0.076-0.023-0.099-0.023
               c-0.114-0.03-0.503,0.16-0.952,0.464c-0.381,0.252-0.8,0.587-1.104,0.922c-0.464,0.51-0.678,0.594-0.981,0.716
               c-0.145,0.053-0.313,0.114-0.541,0.243c-0.411,0.221-0.677,0.541-0.921,0.83c-0.168,0.191-0.327,0.373-0.51,0.51
               c-0.336,0.259-0.884,0.374-1.303,0.441c0,0.161,0.008,0.725,0.014,0.838c0,0.084-0.021,0.602-0.052,1.028
               c-0.016,0.282-0.046,0.534-0.068,0.594c-0.023,0.061-0.038,0.099-0.054,0.13c-0.016,0.046-0.03,0.068-0.045,0.152v0.007
               c-0.031,0.153-0.062,0.321-0.229,0.465c-0.145,0.115-0.19,0.152-0.252,0.189l-0.015,0.016c-0.046,0.038-0.114,0.03-0.19,0.03
               c-0.068,0-0.145-0.007-0.206,0.015c-0.06,0.023-0.222,0.023-0.418,0.016c-0.32,0-0.731-0.008-0.876,0.076
               c-0.1,0.053-0.145,0.099-0.183,0.136c-0.076,0.061-0.122,0.107-0.306,0.123c-0.083,0.007-0.212-0.007-0.364-0.024
               c0,0.069,0,0.138-0.016,0.206c-0.022,0.129-0.03,0.236-0.016,0.328c0.016,0.076,0.046,0.145,0.121,0.205
               c0.107,0.091,0.123,0.175,0.139,0.259c0.015,0.061,0.028,0.122,0.083,0.175c0.038,0.046,0.13,0.069,0.206,0.084
               c0.045,0.015,0.084,0.023,0.121,0.038c0.099,0.038,0.153,0.092,0.107,0.213c-0.023,0.069-0.062,0.122-0.092,0.168
               c-0.023,0.023-0.03,0.038-0.038,0.053c-0.015,0.031-0.008,0.045,0.062,0.084c0.144,0.077,0.183,0.106,0.228,0.152
               c0.023,0.016,0.055,0.046,0.106,0.076c0.038,0.031,0.084,0.015,0.122-0.007c0.092-0.031,0.176-0.069,0.274,0.076
               c0.091,0.144,0.091,0.19,0.091,0.259c0,0.023,0,0.053,0.016,0.107c0.016,0.045,0.061,0.098,0.114,0.136
               c0.038-0.121,0.1-0.228,0.313-0.236c0.16-0.007,0.228,0.084,0.297,0.191c0.046,0.06,0.091,0.137,0.175,0.167
               c0.062,0.023,0.106,0.046,0.152,0.069c0.137,0.068,0.1,0.068,0.084-0.023c-0.023-0.114-0.03-0.244-0.023-0.35
               c0.016-0.122,0.054-0.221,0.138-0.282c0.068-0.053,0.129-0.107,0.183-0.167c0.045-0.062,0.076-0.137,0.092-0.229
               c0.007-0.03,0.007-0.061,0.015-0.091c0.022-0.183,0.046-0.312,0.152-0.381c0.03-0.015,0.052-0.038,0.076-0.061
               c0.076-0.076,0.16-0.167,0.396-0.152c0.222,0.015,0.267,0,0.343-0.031c0.016-0.008,0.046-0.015,0.076-0.03
               c0.038-0.008,0.062-0.03,0.076-0.046c0.077-0.053,0.122-0.091,0.305,0.038c0.114,0.076,0.206,0.092,0.29,0.107
               c0.074,0.015,0.152,0.022,0.235,0.084c0.1,0.076,0.152,0.16,0.206,0.251c0.038,0.06,0.076,0.122,0.122,0.175
               c0.061,0.068,0.136,0.114,0.212,0.129c0.077,0.008,0.152-0.007,0.236-0.053c0.046-0.023,0.092-0.053,0.13-0.083
               c0.13-0.091,0.229-0.153,0.349-0.084c0.032,0.015,0.063,0.038,0.108,0.06c0.183,0.122,0.517,0.335,0.867,0.358
               c0.313,0.023,0.389-0.045,0.503-0.144c0.038-0.038,0.092-0.084,0.159-0.13c0.13-0.091,0.352-0.16,0.57-0.205
               c0.245-0.046,0.489-0.069,0.625-0.038c0.107,0.022,0.176,0.06,0.245,0.099c0.113,0.053,0.221,0.114,0.44,0.098
               c0.267-0.014,0.358-0.105,0.465-0.205c0.03-0.03,0.068-0.068,0.114-0.107c0.092-0.068,0.19-0.099,0.282-0.121
               c0.091-0.031,0.159-0.054,0.167-0.092c0-0.03-0.015-0.091-0.037-0.175c-0.069-0.205-0.161-0.51,0.037-0.708v-0.008
               c0.114-0.114,0.267-0.373,0.435-0.647c0.197-0.342,0.418-0.708,0.608-0.898c0.175-0.16,0.305-0.328,0.396-0.488
               c0.091-0.152,0.137-0.289,0.13-0.403c-0.008-0.153,0.007-0.914,0.045-1.538c0.022-0.404,0.054-0.754,0.092-0.876
               c0.022-0.068,0.046-0.121,0.061-0.175c0.076-0.183,0.128-0.319-0.038-0.403c-0.13-0.077-0.214-0.115-0.259-0.168
               c-0.046-0.061-0.062-0.129-0.062-0.243c0-0.046-0.022-0.061-0.053-0.077c-0.076-0.038-0.175-0.091-0.183-0.38
               c0-0.206-0.016-0.381-0.03-0.526c-0.008-0.168-0.03-0.297-0.054-0.395C125.653,52.308,125.601,52.185,125.563,52.125z"/>
             <path fill="#808184" d="M139.413,98.516c-0.092-0.084-0.068-0.183-0.055-0.281
               c0.017-0.069,0.031-0.131,0-0.196c-0.037-0.07-0.047-0.146-0.037-0.215c-0.03-0.023-0.107-0.061-0.184-0.099
               c-0.068-0.039-0.138-0.069-0.189-0.084l-0.031-0.009c-0.074-0.03-0.084-0.03-0.145,0.062c-0.03,0.039-0.068,0.084-0.107,0.138
               c-0.09,0.114-0.197,0.25-0.203,0.342c0,0.047-0.018,0.092-0.023,0.139c-0.022,0.076-0.039,0.137,0.008,0.175
               c0.062,0.046,0.091,0.083,0.121,0.13c0.023,0.037,0.054,0.076,0.115,0.129c0.029,0.031,0.045,0.047,0.061,0.062
               c0.015,0.017,0.023,0.03,0.031,0.03c0.015,0.006,0.037,0.017,0.099,0.031l0.069,0.021c0.037,0.008,0.066,0.023,0.104,0.029
               c0.13,0.039,0.183,0.063,0.213,0.031c0.017-0.015,0.017-0.054,0.023-0.099c0-0.069,0.008-0.146,0.054-0.222
               C139.375,98.58,139.396,98.539,139.413,98.516"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M139.413,98.516c-0.092-0.084-0.068-0.183-0.055-0.281c0.017-0.069,0.031-0.131,0-0.196c-0.037-0.07-0.047-0.146-0.037-0.215
               c-0.03-0.023-0.107-0.061-0.184-0.099c-0.068-0.039-0.138-0.069-0.189-0.084l-0.031-0.009c-0.074-0.03-0.084-0.03-0.145,0.062
               c-0.03,0.039-0.068,0.084-0.107,0.138c-0.09,0.114-0.197,0.25-0.203,0.342c0,0.047-0.018,0.092-0.023,0.139
               c-0.022,0.076-0.039,0.137,0.008,0.175c0.062,0.046,0.091,0.083,0.121,0.13c0.023,0.037,0.054,0.076,0.115,0.129
               c0.029,0.031,0.045,0.047,0.061,0.062c0.015,0.017,0.023,0.03,0.031,0.03c0.015,0.006,0.037,0.017,0.099,0.031l0.069,0.021
               c0.037,0.008,0.066,0.023,0.104,0.029c0.13,0.039,0.183,0.063,0.213,0.031c0.017-0.015,0.017-0.054,0.023-0.099
               c0-0.069,0.008-0.146,0.054-0.222C139.375,98.58,139.396,98.539,139.413,98.516z"/>
             <path fill="#808184" d="M137.183,94.175c-0.023-0.03-0.031-0.067-0.047-0.098
               c-0.015-0.055-0.037-0.107-0.084-0.168c-0.084-0.084-0.205-0.105-0.334-0.129c-0.092-0.017-0.184-0.031-0.268-0.07
               c-0.197-0.084-0.243-0.242-0.281-0.379c-0.008-0.023-0.016-0.055-0.023-0.069c-0.021-0.075-0.029-0.169-0.029-0.259
               c-0.008-0.076-0.008-0.151-0.022-0.229c-0.016-0.076-0.067-0.068-0.137-0.068c-0.054,0.008-0.106,0.008-0.175,0
               c-0.1-0.022-0.107-0.068-0.1-0.139c0.008-0.016,0.016-0.037,0.021-0.053c0.017-0.053,0.039-0.121-0.015-0.189
               c-0.084-0.1-0.197-0.139-0.32-0.184c-0.074-0.021-0.151-0.055-0.229-0.1c-0.145-0.076-0.197-0.183-0.267-0.319
               c-0.038-0.075-0.076-0.167-0.144-0.267c-0.123-0.183-0.193-0.342-0.252-0.472c-0.039-0.084-0.068-0.153-0.1-0.206
               c-0.029-0.054-0.063-0.138-0.1-0.229c-0.068-0.198-0.152-0.44-0.266-0.473l-0.017-0.008c-0.054,0.055-0.168,0.158-0.229,0.176
               c-0.008,0.008-0.023,0.014-0.037,0.021c-0.055,0.055-0.123,0.105-0.306,0.008c-0.083-0.047-0.221,0.105-0.335,0.244
               c-0.105,0.113-0.197,0.221-0.273,0.197c-0.145-0.038-0.153-0.176-0.16-0.297c0-0.023,0-0.039-0.008-0.053
               c0-0.009-0.021-0.009-0.076-0.009c-0.039,0.009-0.09,0.015-0.151,0.022c-0.069,0.015-0.153,0.031-0.244,0.039
               c-0.167,0.022-0.603,0.084-0.952,0.15c-0.242,0.039-0.44,0.084-0.486,0.105c-0.029,0.017-0.053,0.457-0.062,1.006
               c-0.007,0.914,0.022,2.117,0.039,2.23c0.022,0.092-0.046,0.137-0.153,0.16c-0.129,0.022-0.326,0.008-0.486-0.006
               c-0.083-0.01-0.16-0.018-0.189-0.01c-0.046,0-0.068,1.21-0.084,2.535c0.067,0,0.184,0.023,0.273,0.13
               c0.076,0.092,0.13,0.183,0.169,0.267c0.028,0.053,0.052,0.1,0.082,0.137c0.047,0.063,0.114,0.19,0.159,0.328
               c0.039,0.1,0.063,0.19,0.047,0.274c-0.016,0.121-0.04,0.174-0.068,0.233c-0.016,0.031-0.038,0.07-0.045,0.115
               c-0.01,0.032-0.023,0.053-0.031,0.076c-0.029,0.105-0.068,0.213,0,0.305c0.038,0.053,0.047,0.047,0.076,0.047
               c0.038-0.008,0.1-0.022,0.206,0c0.074,0.015,0.138,0.037,0.188,0.06c0.084,0.031,0.146,0.046,0.244,0.009
               c0.092-0.039,0.146-0.063,0.185-0.099c0.045-0.039,0.084-0.093,0.136-0.183c0.069-0.117,0.131-0.153,0.184-0.183
               c0.047-0.024,0.084-0.056,0.131-0.132c0.029-0.066,0.066-0.184,0.105-0.305c0.076-0.221,0.158-0.479,0.26-0.549
               c0.029-0.021,0.053-0.053,0.082-0.076c0.092-0.105,0.176-0.197,0.42-0.037c0.121,0.076,0.189,0.152,0.252,0.229
               c0.084,0.084,0.144,0.16,0.289,0.184c0.113,0.017,0.166,0.017,0.213,0.017c0.067,0.007,0.113,0.007,0.259,0.052
               c0.174,0.045,0.243,0.03,0.319,0.016c0.021-0.008,0.047-0.008,0.076-0.016c0.061-0.006,0.137-0.037,0.205-0.099
               c0.061-0.045,0.106-0.113,0.137-0.205c0.023-0.062,0.039-0.146,0.047-0.222c0.029-0.215,0.068-0.434,0.267-0.496
               c0.093-0.028,0.159-0.036,0.222-0.045c0.105-0.014,0.176-0.022,0.221-0.129c0.029-0.053,0.061-0.145,0.092-0.244
               c0.066-0.243,0.158-0.532,0.305-0.602c0.075-0.037,0.137-0.046,0.189-0.054c0.084-0.007,0.152-0.015,0.229-0.146
               c0.076-0.121,0.206-0.305,0.328-0.457c0.1-0.113,0.197-0.213,0.268-0.242c0.09-0.047,0.143-0.055,0.197-0.055
               c0.028-0.006,0.067-0.015,0.122-0.029c0.067-0.021,0.128-0.1,0.183-0.159c0.029-0.039,0.054-0.077,0.084-0.093
               C137.128,94.214,137.158,94.191,137.183,94.175"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M137.183,94.175c-0.023-0.03-0.031-0.067-0.047-0.098c-0.015-0.055-0.037-0.107-0.084-0.168
               c-0.084-0.084-0.205-0.105-0.334-0.129c-0.092-0.017-0.184-0.031-0.268-0.07c-0.197-0.084-0.243-0.242-0.281-0.379
               c-0.008-0.023-0.016-0.055-0.023-0.069c-0.021-0.075-0.029-0.169-0.029-0.259c-0.008-0.076-0.008-0.151-0.022-0.229
               c-0.016-0.076-0.067-0.068-0.137-0.068c-0.054,0.008-0.106,0.008-0.175,0c-0.1-0.022-0.107-0.068-0.1-0.139
               c0.008-0.016,0.016-0.037,0.021-0.053c0.017-0.053,0.039-0.121-0.015-0.189c-0.084-0.1-0.197-0.139-0.32-0.184
               c-0.074-0.021-0.151-0.055-0.229-0.1c-0.145-0.076-0.197-0.183-0.267-0.319c-0.038-0.075-0.076-0.167-0.144-0.267
               c-0.123-0.183-0.193-0.342-0.252-0.472c-0.039-0.084-0.068-0.153-0.1-0.206c-0.029-0.054-0.063-0.138-0.1-0.229
               c-0.068-0.198-0.152-0.44-0.266-0.473l-0.017-0.008c-0.054,0.055-0.168,0.158-0.229,0.176c-0.008,0.008-0.023,0.014-0.037,0.021
               c-0.055,0.055-0.123,0.105-0.306,0.008c-0.083-0.047-0.221,0.105-0.335,0.244c-0.105,0.113-0.197,0.221-0.273,0.197
               c-0.145-0.038-0.153-0.176-0.16-0.297c0-0.023,0-0.039-0.008-0.053c0-0.009-0.021-0.009-0.076-0.009
               c-0.039,0.009-0.09,0.015-0.151,0.022c-0.069,0.015-0.153,0.031-0.244,0.039c-0.167,0.022-0.603,0.084-0.952,0.15
               c-0.242,0.039-0.44,0.084-0.486,0.105c-0.029,0.017-0.053,0.457-0.062,1.006c-0.007,0.914,0.022,2.117,0.039,2.23
               c0.022,0.092-0.046,0.137-0.153,0.16c-0.129,0.022-0.326,0.008-0.486-0.006c-0.083-0.01-0.16-0.018-0.189-0.01
               c-0.046,0-0.068,1.21-0.084,2.535c0.067,0,0.184,0.023,0.273,0.13c0.076,0.092,0.13,0.183,0.169,0.267
               c0.028,0.053,0.052,0.1,0.082,0.137c0.047,0.063,0.114,0.19,0.159,0.328c0.039,0.1,0.063,0.19,0.047,0.274
               c-0.016,0.121-0.04,0.174-0.068,0.233c-0.016,0.031-0.038,0.07-0.045,0.115c-0.01,0.032-0.023,0.053-0.031,0.076
               c-0.029,0.105-0.068,0.213,0,0.305c0.038,0.053,0.047,0.047,0.076,0.047c0.038-0.008,0.1-0.022,0.206,0
               c0.074,0.015,0.138,0.037,0.188,0.06c0.084,0.031,0.146,0.046,0.244,0.009c0.092-0.039,0.146-0.063,0.185-0.099
               c0.045-0.039,0.084-0.093,0.136-0.183c0.069-0.117,0.131-0.153,0.184-0.183c0.047-0.024,0.084-0.056,0.131-0.132
               c0.029-0.066,0.066-0.184,0.105-0.305c0.076-0.221,0.158-0.479,0.26-0.549c0.029-0.021,0.053-0.053,0.082-0.076
               c0.092-0.105,0.176-0.197,0.42-0.037c0.121,0.076,0.189,0.152,0.252,0.229c0.084,0.084,0.144,0.16,0.289,0.184
               c0.113,0.017,0.166,0.017,0.213,0.017c0.067,0.007,0.113,0.007,0.259,0.052c0.174,0.045,0.243,0.03,0.319,0.016
               c0.021-0.008,0.047-0.008,0.076-0.016c0.061-0.006,0.137-0.037,0.205-0.099c0.061-0.045,0.106-0.113,0.137-0.205
               c0.023-0.062,0.039-0.146,0.047-0.222c0.029-0.215,0.068-0.434,0.267-0.496c0.093-0.028,0.159-0.036,0.222-0.045
               c0.105-0.014,0.176-0.022,0.221-0.129c0.029-0.053,0.061-0.145,0.092-0.244c0.066-0.243,0.158-0.532,0.305-0.602
               c0.075-0.037,0.137-0.046,0.189-0.054c0.084-0.007,0.152-0.015,0.229-0.146c0.076-0.121,0.206-0.305,0.328-0.457
               c0.1-0.113,0.197-0.213,0.268-0.242c0.09-0.047,0.143-0.055,0.197-0.055c0.028-0.006,0.067-0.015,0.122-0.029
               c0.067-0.021,0.128-0.1,0.183-0.159c0.029-0.039,0.054-0.077,0.084-0.093C137.128,94.214,137.158,94.191,137.183,94.175z"/>
             <path fill="#808184" d="M126.964,100.285c0.023,0.014,0.038,0.036,0.061,0.065
               c0.1,0.146,0.55,1.159,0.55,1.401c0,0.252,0.188,0.426,0.305,0.64c0.113,0.222,0.563,0.86,0.494,1.188
               c-0.067,0.318-0.122,0.479-0.235,0.479s-0.336,0.067-0.236,0.235c0.1,0.166,0.334,0.121,0.357,0.373
               c0.021,0.242,0.252,0.334,0.19,0.455c-0.054,0.131-0.046,0.52,0.038,0.465c0.076-0.053,0.197-0.381,0.326-0.213
               c0.123,0.168,0.222,0.358,0.449,0.457c0.223,0.106,0.307,0.297,0.563,0.129c0.252-0.166,0.13-0.389,0.493-0.342
               c0.359,0.045,0.604-0.1,0.886-0.197c0.28-0.1,0.213-0.381,0.632-0.207c0.418,0.184,1.043-0.334,1.248-0.137
               c0.198,0.205,0.436,0.26,0.607,0.184c0.168-0.076,0.436-0.047,0.625-0.092c0.19-0.047,0.748-0.213,0.968-0.457
               c0.229-0.252,0.297-0.09,0.793-0.541c0.493-0.447,1.18-1.104,1.599-1.561c0.41-0.464,0.73-1.173,0.943-1.5
               c0.213-0.328,0.52-0.518,0.663-0.654c0.146-0.129,0.288-0.365,0.409-0.617c0.131-0.242,0.215-0.502,0.32-0.807
               c0.038-0.115,0.063-0.207,0.076-0.289c-0.189-0.039-0.388-0.084-0.525-0.138c-0.021-0.015-0.045-0.022-0.066-0.03
               c-0.018,0.022-0.038,0.062-0.076,0.114c-0.031,0.045-0.031,0.104-0.039,0.168c0,0.067-0.007,0.121-0.053,0.166
               c-0.068,0.067-0.146,0.047-0.313-0.007c-0.029-0.01-0.067-0.023-0.105-0.03l-0.066-0.022c-0.064-0.016-0.095-0.023-0.115-0.039
               c-0.023-0.016-0.039-0.021-0.063-0.045c-0.006-0.016-0.028-0.039-0.052-0.062c-0.068-0.062-0.1-0.106-0.129-0.144
               c-0.022-0.039-0.055-0.077-0.101-0.108c-0.099-0.073-0.075-0.174-0.046-0.28c0.008-0.047,0.016-0.084,0.021-0.123
               c0.009-0.121,0.123-0.272,0.229-0.396c0.038-0.054,0.068-0.093,0.099-0.129c0.106-0.16,0.123-0.152,0.26-0.106l0.029,0.006
               c0.063,0.024,0.137,0.055,0.205,0.092c0.063,0.031,0.114,0.063,0.16,0.084c0.008-0.021,0.008-0.037,0.016-0.061
               c0.022-0.068,0.037-0.138,0.031-0.189c-0.023-0.076-0.023-0.236-0.023-0.412c0-0.197,0.016-0.41,0.029-0.586
               c0.024-0.213,0.008-0.252-0.029-0.365c-0.016-0.053-0.037-0.121-0.068-0.229c-0.037-0.16-0.105-0.373-0.158-0.57
               c-0.068-0.237-0.131-0.449-0.146-0.525c-0.009-0.037-0.009-0.076-0.009-0.113c0-0.084,0-0.168-0.113-0.236
               c-0.105-0.061-0.54-0.075-0.921-0.092c-0.267-0.017-0.503-0.021-0.597-0.045c-0.045-0.017-0.073-0.022-0.104-0.047
               c-0.023,0.023-0.063,0.047-0.093,0.069c-0.017,0.006-0.037,0.028-0.062,0.062c-0.061,0.066-0.13,0.158-0.229,0.196
               c-0.061,0.022-0.105,0.022-0.145,0.03c-0.047,0.008-0.084,0.008-0.16,0.047c-0.061,0.021-0.145,0.114-0.235,0.221
               c-0.115,0.146-0.244,0.32-0.313,0.441c-0.1,0.168-0.197,0.184-0.304,0.189c-0.048,0.008-0.101,0.016-0.161,0.045
               c-0.105,0.055-0.189,0.32-0.258,0.541c-0.031,0.1-0.063,0.189-0.092,0.26c-0.068,0.152-0.16,0.16-0.299,0.183
               c-0.061,0.007-0.129,0.022-0.205,0.046c-0.137,0.045-0.168,0.229-0.189,0.412c-0.016,0.084-0.029,0.166-0.053,0.242
               c-0.038,0.105-0.102,0.184-0.167,0.244c-0.084,0.076-0.183,0.113-0.261,0.121c-0.021,0.008-0.045,0.008-0.068,0.016
               c-0.091,0.021-0.168,0.039-0.365-0.023c-0.136-0.035-0.174-0.035-0.242-0.035c-0.047-0.01-0.105-0.01-0.223-0.024
               c-0.174-0.03-0.25-0.112-0.35-0.221c-0.053-0.062-0.123-0.136-0.229-0.205c-0.175-0.113-0.229-0.048-0.297,0.022
               c-0.021,0.029-0.053,0.062-0.091,0.092c-0.077,0.054-0.151,0.289-0.222,0.502c-0.045,0.121-0.084,0.234-0.113,0.313
               c-0.061,0.105-0.115,0.137-0.168,0.176c-0.047,0.021-0.092,0.053-0.145,0.137c-0.061,0.105-0.107,0.168-0.16,0.213
               c-0.054,0.046-0.114,0.076-0.207,0.113c-0.144,0.062-0.211,0.031-0.326-0.008c-0.047-0.016-0.098-0.037-0.176-0.053
               c-0.084-0.016-0.129-0.008-0.168,0c-0.067,0.016-0.099,0.016-0.174-0.084c-0.1-0.137-0.054-0.268-0.009-0.396
               c0.009-0.023,0.017-0.053,0.022-0.068c0.017-0.063,0.031-0.1,0.055-0.137c0.021-0.053,0.046-0.1,0.061-0.197
               c0.008-0.068-0.008-0.153-0.037-0.236c-0.047-0.121-0.114-0.244-0.151-0.297c-0.032-0.046-0.062-0.092-0.084-0.152
               c-0.045-0.076-0.093-0.17-0.161-0.25c-0.068-0.078-0.152-0.093-0.197-0.093c-0.016,1.097-0.021,2.245-0.021,2.847
               c0,0.252,0,0.412-0.008,0.437c0,0.138-0.121,0.157-0.244,0.173c-0.038,0.01-0.084,0.018-0.114,0.023
               c-0.038,0.023-0.083,0.107-0.121,0.183c-0.054,0.101-0.101,0.185-0.176,0.175c-0.03-0.007-0.068-0.015-0.13-0.015
               c-0.053,0-0.137,0-0.252,0.015c-0.228,0.018-0.258-0.007-0.356-0.082c-0.017-0.017-0.03-0.031-0.054-0.037
               c-0.068-0.056-0.121-0.045-0.189-0.031c-0.054,0.008-0.105,0.021-0.189,0.021c-0.122-0.007-0.19-0.061-0.229-0.136
               c-0.036-0.069-0.053-0.151-0.053-0.229c0-0.06-0.015-0.136-0.062-0.205c-0.037-0.053-0.092-0.099-0.176-0.129
               c-0.045-0.022-0.068,0-0.084,0.037c-0.015,0.023-0.021,0.055-0.029,0.084c-0.03,0.092-0.068,0.207-0.152,0.297
               C127.101,100.192,127.032,100.246,126.964,100.285 M135.963,100.473c0.151-0.123,0.335-0.252,0.366-0.274
               c0.029-0.021,0.137-0.13,0.266-0.121c0.107,0.008,0.152,0.084,0.198,0.174c0.022,0.047,0.047,0.094,0.083,0.131
               c0.031,0.021,0.039,0.031,0.055,0.045c0.084,0.076,0.152,0.139,0.252,0.281c0.1,0.131,0.037,0.19-0.023,0.269
               c-0.03,0.03-0.061,0.06-0.061,0.104c-0.009,0.024-0.009,0.047-0.009,0.069c-0.007,0.099-0.007,0.151-0.13,0.326
               c-0.1,0.153-0.136,0.161-0.242,0.168c-0.039,0-0.092,0.008-0.168,0.03c-0.16,0.047-0.175,0.062-0.197,0.093
               c-0.016,0.015-0.029,0.038-0.062,0.06c-0.038,0.039-0.054,0.1-0.067,0.168c-0.023,0.078-0.039,0.153-0.101,0.213
               c-0.099,0.1-0.19,0.016-0.29-0.068c-0.029-0.021-0.061-0.053-0.084-0.066c-0.066-0.047-0.137-0.1-0.174-0.176
               c-0.039-0.063-0.061-0.137-0.055-0.221c0.008-0.076-0.061-0.137-0.121-0.205c-0.053-0.047-0.105-0.094-0.146-0.16
               c-0.06-0.105-0.022-0.168,0.054-0.215c0.03-0.021,0.069-0.029,0.1-0.045c0.029-0.016,0.061-0.021,0.074-0.029
               c0.047-0.031,0.078-0.055,0.101-0.084c0.022-0.031,0.038-0.076,0.054-0.146c0.022-0.129,0.092-0.168,0.19-0.228
               C135.864,100.533,135.91,100.511,135.963,100.473"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M126.964,100.285c0.023,0.014,0.038,0.036,0.061,0.065c0.1,0.146,0.55,1.159,0.55,1.401c0,0.252,0.188,0.426,0.305,0.64
               c0.113,0.222,0.563,0.86,0.494,1.188c-0.067,0.318-0.122,0.479-0.235,0.479s-0.336,0.067-0.236,0.235
               c0.1,0.166,0.334,0.121,0.357,0.373c0.021,0.242,0.252,0.334,0.19,0.455c-0.054,0.131-0.046,0.52,0.038,0.465
               c0.076-0.053,0.197-0.381,0.326-0.213c0.123,0.168,0.222,0.358,0.449,0.457c0.223,0.106,0.307,0.297,0.563,0.129
               c0.252-0.166,0.13-0.389,0.493-0.342c0.359,0.045,0.604-0.1,0.886-0.197c0.28-0.1,0.213-0.381,0.632-0.207
               c0.418,0.184,1.043-0.334,1.248-0.137c0.198,0.205,0.436,0.26,0.607,0.184c0.168-0.076,0.436-0.047,0.625-0.092
               c0.19-0.047,0.748-0.213,0.968-0.457c0.229-0.252,0.297-0.09,0.793-0.541c0.493-0.447,1.18-1.104,1.599-1.561
               c0.41-0.464,0.73-1.173,0.943-1.5c0.213-0.328,0.52-0.518,0.663-0.654c0.146-0.129,0.288-0.365,0.409-0.617
               c0.131-0.242,0.215-0.502,0.32-0.807c0.038-0.115,0.063-0.207,0.076-0.289c-0.189-0.039-0.388-0.084-0.525-0.138
               c-0.021-0.015-0.045-0.022-0.066-0.03c-0.018,0.022-0.038,0.062-0.076,0.114c-0.031,0.045-0.031,0.104-0.039,0.168
               c0,0.067-0.007,0.121-0.053,0.166c-0.068,0.067-0.146,0.047-0.313-0.007c-0.029-0.01-0.067-0.023-0.105-0.03l-0.066-0.022
               c-0.064-0.016-0.095-0.023-0.115-0.039c-0.023-0.016-0.039-0.021-0.063-0.045c-0.006-0.016-0.028-0.039-0.052-0.062
               c-0.068-0.062-0.1-0.106-0.129-0.144c-0.022-0.039-0.055-0.077-0.101-0.108c-0.099-0.073-0.075-0.174-0.046-0.28
               c0.008-0.047,0.016-0.084,0.021-0.123c0.009-0.121,0.123-0.272,0.229-0.396c0.038-0.054,0.068-0.093,0.099-0.129
               c0.106-0.16,0.123-0.152,0.26-0.106l0.029,0.006c0.063,0.024,0.137,0.055,0.205,0.092c0.063,0.031,0.114,0.063,0.16,0.084
               c0.008-0.021,0.008-0.037,0.016-0.061c0.022-0.068,0.037-0.138,0.031-0.189c-0.023-0.076-0.023-0.236-0.023-0.412
               c0-0.197,0.016-0.41,0.029-0.586c0.024-0.213,0.008-0.252-0.029-0.365c-0.016-0.053-0.037-0.121-0.068-0.229
               c-0.037-0.16-0.105-0.373-0.158-0.57c-0.068-0.237-0.131-0.449-0.146-0.525c-0.009-0.037-0.009-0.076-0.009-0.113
               c0-0.084,0-0.168-0.113-0.236c-0.105-0.061-0.54-0.075-0.921-0.092c-0.267-0.017-0.503-0.021-0.597-0.045
               c-0.045-0.017-0.073-0.022-0.104-0.047c-0.023,0.023-0.063,0.047-0.093,0.069c-0.017,0.006-0.037,0.028-0.062,0.062
               c-0.061,0.066-0.13,0.158-0.229,0.196c-0.061,0.022-0.105,0.022-0.145,0.03c-0.047,0.008-0.084,0.008-0.16,0.047
               c-0.061,0.021-0.145,0.114-0.235,0.221c-0.115,0.146-0.244,0.32-0.313,0.441c-0.1,0.168-0.197,0.184-0.304,0.189
               c-0.048,0.008-0.101,0.016-0.161,0.045c-0.105,0.055-0.189,0.32-0.258,0.541c-0.031,0.1-0.063,0.189-0.092,0.26
               c-0.068,0.152-0.16,0.16-0.299,0.183c-0.061,0.007-0.129,0.022-0.205,0.046c-0.137,0.045-0.168,0.229-0.189,0.412
               c-0.016,0.084-0.029,0.166-0.053,0.242c-0.038,0.105-0.102,0.184-0.167,0.244c-0.084,0.076-0.183,0.113-0.261,0.121
               c-0.021,0.008-0.045,0.008-0.068,0.016c-0.091,0.021-0.168,0.039-0.365-0.023c-0.136-0.035-0.174-0.035-0.242-0.035
               c-0.047-0.01-0.105-0.01-0.223-0.024c-0.174-0.03-0.25-0.112-0.35-0.221c-0.053-0.062-0.123-0.136-0.229-0.205
               c-0.175-0.113-0.229-0.048-0.297,0.022c-0.021,0.029-0.053,0.062-0.091,0.092c-0.077,0.054-0.151,0.289-0.222,0.502
               c-0.045,0.121-0.084,0.234-0.113,0.313c-0.061,0.105-0.115,0.137-0.168,0.176c-0.047,0.021-0.092,0.053-0.145,0.137
               c-0.061,0.105-0.107,0.168-0.16,0.213c-0.054,0.046-0.114,0.076-0.207,0.113c-0.144,0.062-0.211,0.031-0.326-0.008
               c-0.047-0.016-0.098-0.037-0.176-0.053c-0.084-0.016-0.129-0.008-0.168,0c-0.067,0.016-0.099,0.016-0.174-0.084
               c-0.1-0.137-0.054-0.268-0.009-0.396c0.009-0.023,0.017-0.053,0.022-0.068c0.017-0.063,0.031-0.1,0.055-0.137
               c0.021-0.053,0.046-0.1,0.061-0.197c0.008-0.068-0.008-0.153-0.037-0.236c-0.047-0.121-0.114-0.244-0.151-0.297
               c-0.032-0.046-0.062-0.092-0.084-0.152c-0.045-0.076-0.093-0.17-0.161-0.25c-0.068-0.078-0.152-0.093-0.197-0.093
               c-0.016,1.097-0.021,2.245-0.021,2.847c0,0.252,0,0.412-0.008,0.437c0,0.138-0.121,0.157-0.244,0.173
               c-0.038,0.01-0.084,0.018-0.114,0.023c-0.038,0.023-0.083,0.107-0.121,0.183c-0.054,0.101-0.101,0.185-0.176,0.175
               c-0.03-0.007-0.068-0.015-0.13-0.015c-0.053,0-0.137,0-0.252,0.015c-0.228,0.018-0.258-0.007-0.356-0.082
               c-0.017-0.017-0.03-0.031-0.054-0.037c-0.068-0.056-0.121-0.045-0.189-0.031c-0.054,0.008-0.105,0.021-0.189,0.021
               c-0.122-0.007-0.19-0.061-0.229-0.136c-0.036-0.069-0.053-0.151-0.053-0.229c0-0.06-0.015-0.136-0.062-0.205
               c-0.037-0.053-0.092-0.099-0.176-0.129c-0.045-0.022-0.068,0-0.084,0.037c-0.015,0.023-0.021,0.055-0.029,0.084
               c-0.03,0.092-0.068,0.207-0.152,0.297C127.101,100.192,127.032,100.246,126.964,100.285z M135.963,100.473
               c0.151-0.123,0.335-0.252,0.366-0.274c0.029-0.021,0.137-0.13,0.266-0.121c0.107,0.008,0.152,0.084,0.198,0.174
               c0.022,0.047,0.047,0.094,0.083,0.131c0.031,0.021,0.039,0.031,0.055,0.045c0.084,0.076,0.152,0.139,0.252,0.281
               c0.1,0.131,0.037,0.19-0.023,0.269c-0.03,0.03-0.061,0.06-0.061,0.104c-0.009,0.024-0.009,0.047-0.009,0.069
               c-0.007,0.099-0.007,0.151-0.13,0.326c-0.1,0.153-0.136,0.161-0.242,0.168c-0.039,0-0.092,0.008-0.168,0.03
               c-0.16,0.047-0.175,0.062-0.197,0.093c-0.016,0.015-0.029,0.038-0.062,0.06c-0.038,0.039-0.054,0.1-0.067,0.168
               c-0.023,0.078-0.039,0.153-0.101,0.213c-0.099,0.1-0.19,0.016-0.29-0.068c-0.029-0.021-0.061-0.053-0.084-0.066
               c-0.066-0.047-0.137-0.1-0.174-0.176c-0.039-0.063-0.061-0.137-0.055-0.221c0.008-0.076-0.061-0.137-0.121-0.205
               c-0.053-0.047-0.105-0.094-0.146-0.16c-0.06-0.105-0.022-0.168,0.054-0.215c0.03-0.021,0.069-0.029,0.1-0.045
               c0.029-0.016,0.061-0.021,0.074-0.029c0.047-0.031,0.078-0.055,0.101-0.084c0.022-0.031,0.038-0.076,0.054-0.146
               c0.022-0.129,0.092-0.168,0.19-0.228C135.864,100.533,135.91,100.511,135.963,100.473z"/>
             <path fill="#808184" d="M123.202,89.541v0.112c0,0.214,0.008,0.524-0.016,0.642
               c-0.022,0.113,0.229,0.494,0.404,0.677c0.183,0.175,0.403,0.472,0.487,0.763c0.076,0.297,0.373,0.867,0.389,0.943
               c0.022,0.085,0.434,0.769,0.464,0.967c0.03,0.206,0.229,0.189,0.289,0.441c0.069,0.244,0.434,0.608,0.274,0.914
               c-0.16,0.297-0.114,0.897-0.068,1.18c0.046,0.281,0.09,0.641,0.168,0.839c0.083,0.204,0.228,0.296,0.183,0.563
               c-0.046,0.272,0.007,0.6,0.007,0.6s0.374,0.496,0.282,0.717c-0.093,0.229,0.037,0.486,0.183,0.617
               c0.144,0.137,0.213,0.137,0.356,0.389c0.092,0.15,0.19,0.229,0.283,0.305c0.067-0.031,0.137-0.084,0.197-0.146
               c0.066-0.081,0.105-0.189,0.129-0.266c0.016-0.037,0.021-0.067,0.03-0.09c0.046-0.102,0.099-0.152,0.222-0.102
               c0.1,0.047,0.174,0.107,0.221,0.176c0.062,0.084,0.084,0.184,0.084,0.26c0,0.063,0.008,0.129,0.029,0.183
               c0.031,0.047,0.068,0.077,0.152,0.077c0.061,0.007,0.113-0.008,0.168-0.016c0.092-0.016,0.167-0.031,0.268,0.045
               c0.021,0.017,0.037,0.029,0.053,0.039c0.074,0.061,0.1,0.084,0.281,0.061c0.129-0.008,0.213-0.016,0.273-0.008
               c0.061,0,0.107,0.008,0.137,0.008l0.069-0.114c0.046-0.099,0.104-0.196,0.175-0.228c0.046-0.018,0.091-0.025,0.137-0.032
               c0.077-0.015,0.161-0.021,0.161-0.084v-0.427c0.007-1.377-0.032-5.58,0.146-5.596c0.037,0,0.175,0.016,0.266,0.022
               c0.152,0.009,0.344,0.022,0.458,0.009c0.052-0.009,0.084-0.023,0.075-0.039c-0.023-0.121-0.055-1.332-0.039-2.261
               c0.009-0.579,0-1.065,0.068-1.097c0.063-0.03,0.313-0.068,0.558-0.115c0.356-0.061,0.791-0.121,0.966-0.143
               c0.084-0.011,0.168-0.025,0.237-0.039c0.06-0.009,0.112-0.023,0.157-0.023c0.115-0.016,0.184,0.007,0.19,0.099v0.056
               c0.009,0.082,0.017,0.182,0.084,0.205c0.024,0.008,0.091-0.078,0.175-0.168c0.139-0.16,0.305-0.352,0.465-0.269
               c0.115,0.069,0.153,0.039,0.19,0.011c0.022-0.018,0.046-0.039,0.076-0.047s0.106-0.068,0.153-0.122
               c-0.031-0.022-0.031-0.053-0.039-0.083c0-0.023,0-0.039-0.055-0.068c-0.091-0.055-0.272-0.023-0.51,0.021
               c-0.115,0.021-0.234,0.047-0.365,0.063c-0.221,0.029-0.502,0.09-0.783,0.151c-0.26,0.062-0.518,0.12-0.73,0.151
               c-0.122,0.016-0.206,0.037-0.268,0.046c-0.158,0.03-0.174,0.038-0.465-0.022c-0.205-0.045-0.312-0.037-0.402-0.022
               c-0.076,0-0.137,0.007-0.237-0.015c-0.09-0.018-0.25-0.023-0.425-0.039c-0.215-0.008-0.457-0.023-0.662-0.061
               c-0.282-0.047-0.282-0.131-0.29-0.236c0-0.045,0-0.1-0.046-0.15c-0.075-0.094-0.464-0.055-0.883-0.01
               c-0.252,0.023-0.51,0.055-0.73,0.055c-0.336,0-0.535-0.03-0.739-0.055c-0.16-0.016-0.327-0.037-0.556-0.037
               c-0.121-0.008-0.228,0-0.312,0c-0.29,0-0.412,0-0.534-0.146c-0.113-0.129-0.296-0.203-0.418-0.266
               c-0.03-0.008-0.063-0.021-0.084-0.038c-0.038-0.015-0.091,0.062-0.146,0.137c-0.038,0.048-0.075,0.101-0.113,0.13
               c-0.076,0.063-0.183,0.037-0.313,0.008c-0.099-0.022-0.221-0.045-0.35-0.037C123.369,89.501,123.286,89.516,123.202,89.541"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M123.202,89.541v0.112c0,0.214,0.008,0.524-0.016,0.642c-0.022,0.113,0.229,0.494,0.404,0.677
               c0.183,0.175,0.403,0.472,0.487,0.763c0.076,0.297,0.373,0.867,0.389,0.943c0.022,0.085,0.434,0.769,0.464,0.967
               c0.03,0.206,0.229,0.189,0.289,0.441c0.069,0.244,0.434,0.608,0.274,0.914c-0.16,0.297-0.114,0.897-0.068,1.18
               c0.046,0.281,0.09,0.641,0.168,0.839c0.083,0.204,0.228,0.296,0.183,0.563c-0.046,0.272,0.007,0.6,0.007,0.6
               s0.374,0.496,0.282,0.717c-0.093,0.229,0.037,0.486,0.183,0.617c0.144,0.137,0.213,0.137,0.356,0.389
               c0.092,0.15,0.19,0.229,0.283,0.305c0.067-0.031,0.137-0.084,0.197-0.146c0.066-0.081,0.105-0.189,0.129-0.266
               c0.016-0.037,0.021-0.067,0.03-0.09c0.046-0.102,0.099-0.152,0.222-0.102c0.1,0.047,0.174,0.107,0.221,0.176
               c0.062,0.084,0.084,0.184,0.084,0.26c0,0.063,0.008,0.129,0.029,0.183c0.031,0.047,0.068,0.077,0.152,0.077
               c0.061,0.007,0.113-0.008,0.168-0.016c0.092-0.016,0.167-0.031,0.268,0.045c0.021,0.017,0.037,0.029,0.053,0.039
               c0.074,0.061,0.1,0.084,0.281,0.061c0.129-0.008,0.213-0.016,0.273-0.008c0.061,0,0.107,0.008,0.137,0.008l0.069-0.114
               c0.046-0.099,0.104-0.196,0.175-0.228c0.046-0.018,0.091-0.025,0.137-0.032c0.077-0.015,0.161-0.021,0.161-0.084v-0.427
               c0.007-1.377-0.032-5.58,0.146-5.596c0.037,0,0.175,0.016,0.266,0.022c0.152,0.009,0.344,0.022,0.458,0.009
               c0.052-0.009,0.084-0.023,0.075-0.039c-0.023-0.121-0.055-1.332-0.039-2.261c0.009-0.579,0-1.065,0.068-1.097
               c0.063-0.03,0.313-0.068,0.558-0.115c0.356-0.061,0.791-0.121,0.966-0.143c0.084-0.011,0.168-0.025,0.237-0.039
               c0.06-0.009,0.112-0.023,0.157-0.023c0.115-0.016,0.184,0.007,0.19,0.099v0.056c0.009,0.082,0.017,0.182,0.084,0.205
               c0.024,0.008,0.091-0.078,0.175-0.168c0.139-0.16,0.305-0.352,0.465-0.269c0.115,0.069,0.153,0.039,0.19,0.011
               c0.022-0.018,0.046-0.039,0.076-0.047s0.106-0.068,0.153-0.122c-0.031-0.022-0.031-0.053-0.039-0.083
               c0-0.023,0-0.039-0.055-0.068c-0.091-0.055-0.272-0.023-0.51,0.021c-0.115,0.021-0.234,0.047-0.365,0.063
               c-0.221,0.029-0.502,0.09-0.783,0.151c-0.26,0.062-0.518,0.12-0.73,0.151c-0.122,0.016-0.206,0.037-0.268,0.046
               c-0.158,0.03-0.174,0.038-0.465-0.022c-0.205-0.045-0.312-0.037-0.402-0.022c-0.076,0-0.137,0.007-0.237-0.015
               c-0.09-0.018-0.25-0.023-0.425-0.039c-0.215-0.008-0.457-0.023-0.662-0.061c-0.282-0.047-0.282-0.131-0.29-0.236
               c0-0.045,0-0.1-0.046-0.15c-0.075-0.094-0.464-0.055-0.883-0.01c-0.252,0.023-0.51,0.055-0.73,0.055
               c-0.336,0-0.535-0.03-0.739-0.055c-0.16-0.016-0.327-0.037-0.556-0.037c-0.121-0.008-0.228,0-0.312,0
               c-0.29,0-0.412,0-0.534-0.146c-0.113-0.129-0.296-0.203-0.418-0.266c-0.03-0.008-0.063-0.021-0.084-0.038
               c-0.038-0.015-0.091,0.062-0.146,0.137c-0.038,0.048-0.075,0.101-0.113,0.13c-0.076,0.063-0.183,0.037-0.313,0.008
               c-0.099-0.022-0.221-0.045-0.35-0.037C123.369,89.501,123.286,89.516,123.202,89.541z"/>
             <path fill="#808184" d="M139.056,94.466c0.037,0.06,0.037,0.129,0.029,0.197
               c0,0.029,0,0.062,0.008,0.1c0.016,0.068,0.076,0.281,0.146,0.52c0.053,0.196,0.113,0.401,0.158,0.563
               c0.023,0.105,0.045,0.174,0.068,0.229c0.047,0.129,0.061,0.174,0.031,0.409c-0.018,0.168-0.023,0.382-0.031,0.569
               c0,0.169,0.008,0.322,0.021,0.392c0.018,0.074-0.008,0.158-0.028,0.242c-0.03,0.099-0.063,0.206-0.008,0.312
               c0.046,0.092,0.028,0.176,0.008,0.252c-0.017,0.1-0.03,0.184,0.146,0.26c0.129,0.053,0.317,0.1,0.51,0.137
               c0.021-0.113,0.045-0.221,0.091-0.381c0.099-0.318-0.351-0.365-0.167-0.669c0.175-0.306,0.73-0.642,1.203-0.823
               c0.472-0.184,1.013-0.518,1.08-0.846c0.062-0.326-0.196-0.447-0.092-0.67c0.101-0.227,0.244-0.723,0.076-1.035
               c-0.166-0.318-0.242-0.273-0.229-0.662c0.023-0.396-0.014-0.486-0.105-0.691c-0.092-0.197-0.306-0.496-0.046-0.785
               c0.259-0.289,0.472-0.313,0.664-0.586c0.188-0.267,0.349-0.549,0.47-0.525c0.123,0.023,0.596-0.578,0.777-0.813
               c0.184-0.238,0.434-0.525,0.829-0.642c0.396-0.114,1.053-0.333,1.265-0.646c0.213-0.32-0.021-0.236,0.45-0.586
               c0.472-0.353,0.501-0.664,0.501-0.802c0-0.137-0.1-1.088-0.1-1.463c0-0.371-0.09-0.53-0.036-0.958
               c0.062-0.426-0.039-1.143,0.036-1.355c0.039-0.104,0.039-0.219,0.016-0.318c-0.045,0.031-0.091,0.053-0.12,0.063
               c-0.045,0.006-0.069,0.021-0.106,0.029c-0.055,0.021-0.131,0.055-0.318,0.09c-0.146,0.032-0.207,0.115-0.26,0.176
               c-0.055,0.076-0.1,0.139-0.215,0.153c-0.06,0.007-0.137,0.037-0.213,0.067c-0.19,0.076-0.418,0.166-0.707,0.1
               c-0.207-0.047-0.229,0.047-0.244,0.145c-0.016,0.076-0.03,0.154-0.092,0.205c-0.129,0.101-0.289,0.039-0.42-0.006
               c-0.045-0.017-0.082-0.023-0.099-0.023c-0.022,0-0.052,0.015-0.09,0.029c-0.093,0.047-0.222,0.107-0.404,0.068
               c-0.235-0.053-0.252-0.068-0.319-0.175c-0.016-0.022-0.03-0.038-0.037-0.045c-0.039-0.054-0.123-0.009-0.207,0.036
               c-0.062,0.031-0.129,0.064-0.182,0.064c-0.017-0.01-0.039-0.01-0.063-0.01c-0.122-0.008-0.327-0.021-0.487-0.076
               c0,0.063-0.015,0.114-0.022,0.176c-0.008,0.084-0.053,0.113-0.105,0.152c0.008,0.008,0.008,0.021,0.008,0.03
               c-0.008,0.015-0.017,0.045-0.021,0.084c-0.016,0.083-0.047,0.213-0.03,0.272c0.015,0.068,0.022,0.176,0.022,0.268
               c0.008,0.054,0.008,0.1,0.008,0.137c0.006,0.045,0.015,0.114,0.021,0.191c0.016,0.13,0.037,0.281,0.047,0.416
               c0.006,0.102,0.053,0.193,0.121,0.268c0.076,0.084,0.176,0.152,0.281,0.223c0.189,0.105,0.205,0.213,0.229,0.357
               c0,0.038,0.008,0.083,0.021,0.129c0.023,0.092,0.039,0.215,0.046,0.342c0.017,0.17,0.017,0.344,0.009,0.457
               c0,0.092,0.008,0.176,0.022,0.268c0.015,0.107,0.03,0.222,0.022,0.357c-0.017,0.168-0.076,0.198-0.152,0.198
               c-0.022,0-0.045-0.008-0.061-0.017c-0.031,0-0.063-0.006-0.084-0.006c-0.046,0.006-0.084,0.028-0.121,0.067
               c-0.047,0.038-0.084,0.091-0.123,0.159c-0.029,0.063,0.016,0.189,0.053,0.307c0.039,0.098,0.076,0.196,0.07,0.272
               c-0.009,0.045-0.022,0.077-0.047,0.101c-0.015,0.021-0.046,0.028-0.076,0.028c-0.021,0-0.045-0.007-0.076-0.015
               c-0.047-0.022-0.092-0.063-0.105-0.106c-0.008-0.015-0.016-0.03-0.023-0.054c-0.037-0.092-0.09-0.229-0.145-0.273
               c-0.029-0.031-0.1-0.107-0.16-0.191c-0.053-0.074-0.105-0.157-0.145-0.221v-0.007c-0.076-0.138-0.151-0.28-0.131-0.479
               c0.017-0.137,0.084-0.221,0.168-0.328c0.039-0.036,0.076-0.082,0.106-0.145c0.062-0.091,0.031-0.166-0.008-0.25
               c-0.022-0.063-0.045-0.123-0.054-0.191c-0.006-0.084-0.014-0.137-0.037-0.176c-0.016-0.029-0.047-0.037-0.092-0.045
               c-0.022,0-0.074,0-0.137,0.008c-0.184,0.016-0.441,0.037-0.541-0.092c-0.054-0.076-0.129-0.152-0.197-0.213
               c-0.069-0.061-0.123-0.1-0.16-0.105c-0.022-0.01-0.145,0.053-0.281,0.13c-0.13,0.067-0.282,0.144-0.404,0.183
               c-0.091,0.03-0.188,0.067-0.305,0.113c-0.184,0.074-0.387,0.158-0.585,0.197c-0.237,0.045-0.39,0.123-0.578,0.207
               c-0.039,0.021-0.084,0.037-0.138,0.061c-0.086,0.045-0.063,0.137-0.023,0.229c0.016,0.022,0.031,0.055,0.038,0.076
               c0.022,0.03,0.039,0.062,0.046,0.092c0.066,0.055,0.137,0.139,0.151,0.236c0.009,0.006,0.009,0.021,0.009,0.037
               c0.007,0.03,0.007,0.063,0.007,0.092c0.009,0.1,0.009,0.145,0.085,0.151c0.028,0.007,0.092,0,0.159-0.009
               c0.198-0.021,0.495-0.053,0.692,0.154c0.229,0.234,0.236,0.234,0.533,0.318l0.029,0.008l0.106,0.03
               c0.26,0.067,0.297,0.084,0.358,0.25c0.037,0.101,0.021,0.138,0.014,0.199c-0.014,0.053-0.029,0.122-0.008,0.29
               c0.016,0.113,0.039,0.189,0.054,0.242c0.03,0.123,0.046,0.176-0.007,0.328c-0.016,0.053-0.039,0.105-0.068,0.168
               c-0.076,0.158-0.167,0.356-0.152,0.615c0,0.092,0.008,0.16,0.008,0.213c0,0.114,0,0.131,0.068,0.391
               c0.047,0.183,0.055,0.35,0.023,0.5c-0.03,0.154-0.092,0.298-0.191,0.459c-0.099,0.145-0.174,0.183-0.234,0.203
               c-0.063,0.031-0.092,0.047-0.101,0.222v0.19c0.009,0.236,0.009,0.351-0.251,0.596C139.487,93.988,139.222,94.275,139.056,94.466
               "/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M139.056,94.466c0.037,0.06,0.037,0.129,0.029,0.197c0,0.029,0,0.062,0.008,0.1c0.016,0.068,0.076,0.281,0.146,0.52
               c0.053,0.196,0.113,0.401,0.158,0.563c0.023,0.105,0.045,0.174,0.068,0.229c0.047,0.129,0.061,0.174,0.031,0.409
               c-0.018,0.168-0.023,0.382-0.031,0.569c0,0.169,0.008,0.322,0.021,0.392c0.018,0.074-0.008,0.158-0.028,0.242
               c-0.03,0.099-0.063,0.206-0.008,0.312c0.046,0.092,0.028,0.176,0.008,0.252c-0.017,0.1-0.03,0.184,0.146,0.26
               c0.129,0.053,0.317,0.1,0.51,0.137c0.021-0.113,0.045-0.221,0.091-0.381c0.099-0.318-0.351-0.365-0.167-0.669
               c0.175-0.306,0.73-0.642,1.203-0.823c0.472-0.184,1.013-0.518,1.08-0.846c0.062-0.326-0.196-0.447-0.092-0.67
               c0.101-0.227,0.244-0.723,0.076-1.035c-0.166-0.318-0.242-0.273-0.229-0.662c0.023-0.396-0.014-0.486-0.105-0.691
               c-0.092-0.197-0.306-0.496-0.046-0.785c0.259-0.289,0.472-0.313,0.664-0.586c0.188-0.267,0.349-0.549,0.47-0.525
               c0.123,0.023,0.596-0.578,0.777-0.813c0.184-0.238,0.434-0.525,0.829-0.642c0.396-0.114,1.053-0.333,1.265-0.646
               c0.213-0.32-0.021-0.236,0.45-0.586c0.472-0.353,0.501-0.664,0.501-0.802c0-0.137-0.1-1.088-0.1-1.463
               c0-0.371-0.09-0.53-0.036-0.958c0.062-0.426-0.039-1.143,0.036-1.355c0.039-0.104,0.039-0.219,0.016-0.318
               c-0.045,0.031-0.091,0.053-0.12,0.063c-0.045,0.006-0.069,0.021-0.106,0.029c-0.055,0.021-0.131,0.055-0.318,0.09
               c-0.146,0.032-0.207,0.115-0.26,0.176c-0.055,0.076-0.1,0.139-0.215,0.153c-0.06,0.007-0.137,0.037-0.213,0.067
               c-0.19,0.076-0.418,0.166-0.707,0.1c-0.207-0.047-0.229,0.047-0.244,0.145c-0.016,0.076-0.03,0.154-0.092,0.205
               c-0.129,0.101-0.289,0.039-0.42-0.006c-0.045-0.017-0.082-0.023-0.099-0.023c-0.022,0-0.052,0.015-0.09,0.029
               c-0.093,0.047-0.222,0.107-0.404,0.068c-0.235-0.053-0.252-0.068-0.319-0.175c-0.016-0.022-0.03-0.038-0.037-0.045
               c-0.039-0.054-0.123-0.009-0.207,0.036c-0.062,0.031-0.129,0.064-0.182,0.064c-0.017-0.01-0.039-0.01-0.063-0.01
               c-0.122-0.008-0.327-0.021-0.487-0.076c0,0.063-0.015,0.114-0.022,0.176c-0.008,0.084-0.053,0.113-0.105,0.152
               c0.008,0.008,0.008,0.021,0.008,0.03c-0.008,0.015-0.017,0.045-0.021,0.084c-0.016,0.083-0.047,0.213-0.03,0.272
               c0.015,0.068,0.022,0.176,0.022,0.268c0.008,0.054,0.008,0.1,0.008,0.137c0.006,0.045,0.015,0.114,0.021,0.191
               c0.016,0.13,0.037,0.281,0.047,0.416c0.006,0.102,0.053,0.193,0.121,0.268c0.076,0.084,0.176,0.152,0.281,0.223
               c0.189,0.105,0.205,0.213,0.229,0.357c0,0.038,0.008,0.083,0.021,0.129c0.023,0.092,0.039,0.215,0.046,0.342
               c0.017,0.17,0.017,0.344,0.009,0.457c0,0.092,0.008,0.176,0.022,0.268c0.015,0.107,0.03,0.222,0.022,0.357
               c-0.017,0.168-0.076,0.198-0.152,0.198c-0.022,0-0.045-0.008-0.061-0.017c-0.031,0-0.063-0.006-0.084-0.006
               c-0.046,0.006-0.084,0.028-0.121,0.067c-0.047,0.038-0.084,0.091-0.123,0.159c-0.029,0.063,0.016,0.189,0.053,0.307
               c0.039,0.098,0.076,0.196,0.07,0.272c-0.009,0.045-0.022,0.077-0.047,0.101c-0.015,0.021-0.046,0.028-0.076,0.028
               c-0.021,0-0.045-0.007-0.076-0.015c-0.047-0.022-0.092-0.063-0.105-0.106c-0.008-0.015-0.016-0.03-0.023-0.054
               c-0.037-0.092-0.09-0.229-0.145-0.273c-0.029-0.031-0.1-0.107-0.16-0.191c-0.053-0.074-0.105-0.157-0.145-0.221v-0.007
               c-0.076-0.138-0.151-0.28-0.131-0.479c0.017-0.137,0.084-0.221,0.168-0.328c0.039-0.036,0.076-0.082,0.106-0.145
               c0.062-0.091,0.031-0.166-0.008-0.25c-0.022-0.063-0.045-0.123-0.054-0.191c-0.006-0.084-0.014-0.137-0.037-0.176
               c-0.016-0.029-0.047-0.037-0.092-0.045c-0.022,0-0.074,0-0.137,0.008c-0.184,0.016-0.441,0.037-0.541-0.092
               c-0.054-0.076-0.129-0.152-0.197-0.213c-0.069-0.061-0.123-0.1-0.16-0.105c-0.022-0.01-0.145,0.053-0.281,0.13
               c-0.13,0.067-0.282,0.144-0.404,0.183c-0.091,0.03-0.188,0.067-0.305,0.113c-0.184,0.074-0.387,0.158-0.585,0.197
               c-0.237,0.045-0.39,0.123-0.578,0.207c-0.039,0.021-0.084,0.037-0.138,0.061c-0.086,0.045-0.063,0.137-0.023,0.229
               c0.016,0.022,0.031,0.055,0.038,0.076c0.022,0.03,0.039,0.062,0.046,0.092c0.066,0.055,0.137,0.139,0.151,0.236
               c0.009,0.006,0.009,0.021,0.009,0.037c0.007,0.03,0.007,0.063,0.007,0.092c0.009,0.1,0.009,0.145,0.085,0.151
               c0.028,0.007,0.092,0,0.159-0.009c0.198-0.021,0.495-0.053,0.692,0.154c0.229,0.234,0.236,0.234,0.533,0.318l0.029,0.008
               l0.106,0.03c0.26,0.067,0.297,0.084,0.358,0.25c0.037,0.101,0.021,0.138,0.014,0.199c-0.014,0.053-0.029,0.122-0.008,0.29
               c0.016,0.113,0.039,0.189,0.054,0.242c0.03,0.123,0.046,0.176-0.007,0.328c-0.016,0.053-0.039,0.105-0.068,0.168
               c-0.076,0.158-0.167,0.356-0.152,0.615c0,0.092,0.008,0.16,0.008,0.213c0,0.114,0,0.131,0.068,0.391
               c0.047,0.183,0.055,0.35,0.023,0.5c-0.03,0.154-0.092,0.298-0.191,0.459c-0.099,0.145-0.174,0.183-0.234,0.203
               c-0.063,0.031-0.092,0.047-0.101,0.222v0.19c0.009,0.236,0.009,0.351-0.251,0.596C139.487,93.988,139.222,94.275,139.056,94.466
               z"/>
             <path fill="#808184" d="M134.188,90.103c0.084,0.113,0.138,0.273,0.19,0.411
               c0.03,0.092,0.06,0.167,0.084,0.213c0.038,0.054,0.067,0.13,0.106,0.213c0.062,0.131,0.129,0.281,0.244,0.457
               c0.066,0.106,0.112,0.198,0.15,0.282c0.063,0.121,0.105,0.212,0.221,0.273c0.068,0.045,0.146,0.068,0.221,0.098
               c0.13,0.047,0.259,0.093,0.359,0.207c0.092,0.113,0.061,0.213,0.029,0.289c-0.008,0.016-0.008,0.031-0.016,0.047
               c0,0,0.008,0.007,0.022,0.007c0.046,0.015,0.099,0.008,0.144,0.008c0.123-0.008,0.216-0.015,0.244,0.144
               c0.017,0.084,0.022,0.168,0.022,0.244c0.009,0.084,0.009,0.168,0.031,0.237c0.008,0.029,0.016,0.045,0.022,0.067
               c0.029,0.115,0.068,0.252,0.221,0.32c0.076,0.029,0.16,0.045,0.244,0.061c0.145,0.029,0.289,0.054,0.389,0.16
               c0.068,0.076,0.091,0.146,0.113,0.197c0.022,0.068,0.037,0.123,0.146,0.151c0.084,0.022,0.319,0.03,0.571,0.038
               c0.396,0.016,0.845,0.039,0.974,0.113c0.022,0.018,0.046,0.031,0.062,0.047c0.176-0.198,0.436-0.479,0.617-0.654
               c0.229-0.221,0.221-0.318,0.221-0.525v-0.197c0.008-0.235,0.061-0.268,0.15-0.305c0.055-0.022,0.115-0.045,0.193-0.168
               c0.089-0.145,0.15-0.281,0.182-0.418c0.022-0.146,0.016-0.289-0.022-0.465c-0.069-0.268-0.069-0.283-0.069-0.412
               c-0.005-0.045-0.005-0.105-0.015-0.213c-0.017-0.283,0.084-0.488,0.167-0.662c0.023-0.062,0.055-0.113,0.068-0.16
               c0.039-0.113,0.03-0.16,0-0.258c-0.015-0.063-0.039-0.146-0.054-0.26c-0.03-0.189-0.006-0.268,0.009-0.326
               c0.006-0.047,0.016-0.076-0.009-0.138c-0.045-0.124-0.075-0.131-0.297-0.19l-0.106-0.031l-0.029-0.008
               c-0.32-0.092-0.328-0.092-0.578-0.35c-0.16-0.168-0.428-0.139-0.609-0.115c-0.076,0.008-0.139,0.018-0.184,0.008
               c-0.158-0.029-0.158-0.09-0.168-0.258c0-0.023-0.008-0.046-0.008-0.084c0-0.008,0-0.016-0.008-0.029
               c-0.006-0.047-0.029-0.084-0.06-0.113c0,0-0.009,0-0.009,0.006c-0.092,0.063-0.145,0.055-0.297,0.029
               c-0.029-0.006-0.062-0.006-0.084-0.014c-0.137-0.017-0.44,0.159-0.608,0.258c-0.062,0.037-0.106,0.062-0.128,0.068
               c-0.04,0.008-0.063,0.1-0.094,0.244l-0.016,0.045c-0.045,0.184-0.092,0.229-0.229,0.344l-0.038,0.03
               c-0.083,0.075-0.151,0.099-0.222,0.115c-0.053,0.015-0.112,0.028-0.182,0.099c-0.062,0.051-0.084,0.09-0.107,0.137
               c-0.037,0.067-0.068,0.137-0.229,0.244c-0.099,0.067-0.207,0.174-0.298,0.271c-0.144,0.16-0.259,0.313-0.274,0.352
               c-0.037,0.1-0.174,0.068-0.289,0.047l-0.028-0.008c-0.037-0.01-0.069-0.017-0.093-0.023c-0.045-0.016-0.084-0.03-0.129-0.016
               c-0.055,0.016-0.123,0.008-0.19-0.008s-0.13-0.047-0.167-0.076c-0.037-0.037-0.1-0.045-0.213-0.053l-0.046-0.008
               C134.351,90.132,134.267,90.119,134.188,90.103"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M134.188,90.103c0.084,0.113,0.138,0.273,0.19,0.411c0.03,0.092,0.06,0.167,0.084,0.213c0.038,0.054,0.067,0.13,0.106,0.213
               c0.062,0.131,0.129,0.281,0.244,0.457c0.066,0.106,0.112,0.198,0.15,0.282c0.063,0.121,0.105,0.212,0.221,0.273
               c0.068,0.045,0.146,0.068,0.221,0.098c0.13,0.047,0.259,0.093,0.359,0.207c0.092,0.113,0.061,0.213,0.029,0.289
               c-0.008,0.016-0.008,0.031-0.016,0.047c0,0,0.008,0.007,0.022,0.007c0.046,0.015,0.099,0.008,0.144,0.008
               c0.123-0.008,0.216-0.015,0.244,0.144c0.017,0.084,0.022,0.168,0.022,0.244c0.009,0.084,0.009,0.168,0.031,0.237
               c0.008,0.029,0.016,0.045,0.022,0.067c0.029,0.115,0.068,0.252,0.221,0.32c0.076,0.029,0.16,0.045,0.244,0.061
               c0.145,0.029,0.289,0.054,0.389,0.16c0.068,0.076,0.091,0.146,0.113,0.197c0.022,0.068,0.037,0.123,0.146,0.151
               c0.084,0.022,0.319,0.03,0.571,0.038c0.396,0.016,0.845,0.039,0.974,0.113c0.022,0.018,0.046,0.031,0.062,0.047
               c0.176-0.198,0.436-0.479,0.617-0.654c0.229-0.221,0.221-0.318,0.221-0.525v-0.197c0.008-0.235,0.061-0.268,0.15-0.305
               c0.055-0.022,0.115-0.045,0.193-0.168c0.089-0.145,0.15-0.281,0.182-0.418c0.022-0.146,0.016-0.289-0.022-0.465
               c-0.069-0.268-0.069-0.283-0.069-0.412c-0.005-0.045-0.005-0.105-0.015-0.213c-0.017-0.283,0.084-0.488,0.167-0.662
               c0.023-0.062,0.055-0.113,0.068-0.16c0.039-0.113,0.03-0.16,0-0.258c-0.015-0.063-0.039-0.146-0.054-0.26
               c-0.03-0.189-0.006-0.268,0.009-0.326c0.006-0.047,0.016-0.076-0.009-0.138c-0.045-0.124-0.075-0.131-0.297-0.19l-0.106-0.031
               l-0.029-0.008c-0.32-0.092-0.328-0.092-0.578-0.35c-0.16-0.168-0.428-0.139-0.609-0.115c-0.076,0.008-0.139,0.018-0.184,0.008
               c-0.158-0.029-0.158-0.09-0.168-0.258c0-0.023-0.008-0.046-0.008-0.084c0-0.008,0-0.016-0.008-0.029
               c-0.006-0.047-0.029-0.084-0.06-0.113c0,0-0.009,0-0.009,0.006c-0.092,0.063-0.145,0.055-0.297,0.029
               c-0.029-0.006-0.062-0.006-0.084-0.014c-0.137-0.017-0.44,0.159-0.608,0.258c-0.062,0.037-0.106,0.062-0.128,0.068
               c-0.04,0.008-0.063,0.1-0.094,0.244l-0.016,0.045c-0.045,0.184-0.092,0.229-0.229,0.344l-0.038,0.03
               c-0.083,0.075-0.151,0.099-0.222,0.115c-0.053,0.015-0.112,0.028-0.182,0.099c-0.062,0.051-0.084,0.09-0.107,0.137
               c-0.037,0.067-0.068,0.137-0.229,0.244c-0.099,0.067-0.207,0.174-0.298,0.271c-0.144,0.16-0.259,0.313-0.274,0.352
               c-0.037,0.1-0.174,0.068-0.289,0.047l-0.028-0.008c-0.037-0.01-0.069-0.017-0.093-0.023c-0.045-0.016-0.084-0.03-0.129-0.016
               c-0.055,0.016-0.123,0.008-0.19-0.008s-0.13-0.047-0.167-0.076c-0.037-0.037-0.1-0.045-0.213-0.053l-0.046-0.008
               C134.351,90.132,134.267,90.119,134.188,90.103z"/>
             <path fill="#808184" d="M140.569,86.539c0.021-0.006,0.053-0.015,0.068-0.006
               c0.053,0.006,0.129,0.061,0.207,0.12c0.074,0.069,0.151,0.153,0.211,0.238c0.063,0.084,0.291,0.067,0.442,0.053
               c0.067-0.009,0.121-0.015,0.16-0.009c0.083,0.009,0.136,0.029,0.167,0.092c0.037,0.047,0.053,0.12,0.053,0.221
               c0.008,0.053,0.031,0.106,0.053,0.162c0.047,0.104,0.084,0.211,0,0.35c-0.037,0.053-0.082,0.105-0.113,0.15
               c-0.075,0.092-0.137,0.168-0.152,0.275c-0.016,0.158,0.055,0.288,0.123,0.41v0.008c0.029,0.063,0.084,0.137,0.136,0.213
               c0.063,0.077,0.116,0.145,0.153,0.176c0.063,0.061,0.122,0.213,0.16,0.313c0.008,0.021,0.016,0.037,0.021,0.053
               c0.008,0.016,0.031,0.039,0.063,0.055c0.008,0,0.021,0.008,0.029,0.008c0,0,0.008,0,0.008-0.008
               c0.008,0,0.008-0.016,0.016-0.029c0-0.055-0.029-0.139-0.063-0.229c-0.053-0.146-0.104-0.288-0.045-0.396
               c0.039-0.068,0.084-0.137,0.137-0.184c0.054-0.047,0.115-0.084,0.176-0.092c0.046-0.008,0.084,0,0.123,0.008
               c0.014,0.008,0.029,0.008,0.045,0.008c0.016,0.008,0.029-0.016,0.039-0.1c0.006-0.129,0-0.236-0.016-0.341
               c-0.017-0.084-0.023-0.177-0.023-0.276c0-0.113,0-0.287-0.008-0.447c-0.016-0.123-0.023-0.244-0.045-0.32
               c-0.016-0.053-0.023-0.105-0.031-0.145c-0.016-0.113-0.021-0.205-0.176-0.289c-0.03-0.017-0.061-0.039-0.092-0.061
               c0.018,0.098,0.018,0.296,0.018,0.35c0,0.076-0.068-0.008-0.139-0.1c-0.067-0.083-0.146-0.152-0.176-0.046
               c-0.029,0.112-0.213,0.062-0.242-0.069c-0.023-0.129-0.039-0.205-0.129-0.523c-0.084-0.313-0.084-0.441-0.152-0.541
               c-0.063-0.092,0.045-0.213,0.021-0.381c-0.016-0.168-0.152-0.183-0.168-0.396c-0.015-0.222,0.235-0.472,0.235-0.578
               s-0.067-0.269-0.06-0.472c0.015-0.198,0.037-0.588-0.008-0.664c-0.039-0.075-0.138-0.121-0.189-0.364
               c-0.03-0.114-0.04-0.183-0.047-0.222c-0.047,0.008-0.106,0.015-0.166,0.015c-0.115,0-0.282-0.051-0.459-0.129
               c-0.006,0.03-0.006,0.069,0.009,0.084c0.015,0.017,0.045,0.03,0.075,0.054c0.084,0.045,0.183,0.107,0.16,0.198
               c-0.008,0.017-0.017,0.03-0.017,0.046c-0.021,0.045-0.028,0.084,0.009,0.121c0.016,0.016,0.029,0.031,0.055,0.047
               c0.074,0.069,0.168,0.15,0.175,0.243c0,0.054,0,0.082-0.007,0.121c-0.018,0.039-0.039,0.076-0.077,0.114
               c-0.022,0.023-0.046,0.045-0.07,0.063c-0.036,0.03-0.065,0.061-0.098,0.12c-0.037,0.093-0.022,0.168,0,0.237v0.015
               c0.008,0.038-0.007,0.104-0.03,0.19c-0.029,0.105-0.068,0.242-0.029,0.343c0.029,0.099,0.022,0.188,0.008,0.272
               c-0.008,0.055-0.018,0.106,0.006,0.138c0.017,0.03,0.047,0.054,0.084,0.075c0.031,0.031,0.068,0.056,0.084,0.084
               c0.039,0.056,0.039,0.107-0.045,0.16c-0.055,0.037-0.113,0.054-0.176,0.068c-0.092,0.021-0.184,0.053-0.197,0.129
               c-0.022,0.1-0.031,0.198-0.038,0.282c0,0.046-0.009,0.099-0.015,0.146c-0.01,0.068-0.054,0.137-0.101,0.197
               c-0.037,0.061-0.077,0.122-0.077,0.154C140.433,86.167,140.522,86.434,140.569,86.539"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M140.569,86.539c0.021-0.006,0.053-0.015,0.068-0.006c0.053,0.006,0.129,0.061,0.207,0.12c0.074,0.069,0.151,0.153,0.211,0.238
               c0.063,0.084,0.291,0.067,0.442,0.053c0.067-0.009,0.121-0.015,0.16-0.009c0.083,0.009,0.136,0.029,0.167,0.092
               c0.037,0.047,0.053,0.12,0.053,0.221c0.008,0.053,0.031,0.106,0.053,0.162c0.047,0.104,0.084,0.211,0,0.35
               c-0.037,0.053-0.082,0.105-0.113,0.15c-0.075,0.092-0.137,0.168-0.152,0.275c-0.016,0.158,0.055,0.288,0.123,0.41v0.008
               c0.029,0.063,0.084,0.137,0.136,0.213c0.063,0.077,0.116,0.145,0.153,0.176c0.063,0.061,0.122,0.213,0.16,0.313
               c0.008,0.021,0.016,0.037,0.021,0.053c0.008,0.016,0.031,0.039,0.063,0.055c0.008,0,0.021,0.008,0.029,0.008
               c0,0,0.008,0,0.008-0.008c0.008,0,0.008-0.016,0.016-0.029c0-0.055-0.029-0.139-0.063-0.229
               c-0.053-0.146-0.104-0.288-0.045-0.396c0.039-0.068,0.084-0.137,0.137-0.184c0.054-0.047,0.115-0.084,0.176-0.092
               c0.046-0.008,0.084,0,0.123,0.008c0.014,0.008,0.029,0.008,0.045,0.008c0.016,0.008,0.029-0.016,0.039-0.1
               c0.006-0.129,0-0.236-0.016-0.341c-0.017-0.084-0.023-0.177-0.023-0.276c0-0.113,0-0.287-0.008-0.447
               c-0.016-0.123-0.023-0.244-0.045-0.32c-0.016-0.053-0.023-0.105-0.031-0.145c-0.016-0.113-0.021-0.205-0.176-0.289
               c-0.03-0.017-0.061-0.039-0.092-0.061c0.018,0.098,0.018,0.296,0.018,0.35c0,0.076-0.068-0.008-0.139-0.1
               c-0.067-0.083-0.146-0.152-0.176-0.046c-0.029,0.112-0.213,0.062-0.242-0.069c-0.023-0.129-0.039-0.205-0.129-0.523
               c-0.084-0.313-0.084-0.441-0.152-0.541c-0.063-0.092,0.045-0.213,0.021-0.381c-0.016-0.168-0.152-0.183-0.168-0.396
               c-0.015-0.222,0.235-0.472,0.235-0.578s-0.067-0.269-0.06-0.472c0.015-0.198,0.037-0.588-0.008-0.664
               c-0.039-0.075-0.138-0.121-0.189-0.364c-0.03-0.114-0.04-0.183-0.047-0.222c-0.047,0.008-0.106,0.015-0.166,0.015
               c-0.115,0-0.282-0.051-0.459-0.129c-0.006,0.03-0.006,0.069,0.009,0.084c0.015,0.017,0.045,0.03,0.075,0.054
               c0.084,0.045,0.183,0.107,0.16,0.198c-0.008,0.017-0.017,0.03-0.017,0.046c-0.021,0.045-0.028,0.084,0.009,0.121
               c0.016,0.016,0.029,0.031,0.055,0.047c0.074,0.069,0.168,0.15,0.175,0.243c0,0.054,0,0.082-0.007,0.121
               c-0.018,0.039-0.039,0.076-0.077,0.114c-0.022,0.023-0.046,0.045-0.07,0.063c-0.036,0.03-0.065,0.061-0.098,0.12
               c-0.037,0.093-0.022,0.168,0,0.237v0.015c0.008,0.038-0.007,0.104-0.03,0.19c-0.029,0.105-0.068,0.242-0.029,0.343
               c0.029,0.099,0.022,0.188,0.008,0.272c-0.008,0.055-0.018,0.106,0.006,0.138c0.017,0.03,0.047,0.054,0.084,0.075
               c0.031,0.031,0.068,0.056,0.084,0.084c0.039,0.056,0.039,0.107-0.045,0.16c-0.055,0.037-0.113,0.054-0.176,0.068
               c-0.092,0.021-0.184,0.053-0.197,0.129c-0.022,0.1-0.031,0.198-0.038,0.282c0,0.046-0.009,0.099-0.015,0.146
               c-0.01,0.068-0.054,0.137-0.101,0.197c-0.037,0.061-0.077,0.122-0.077,0.154C140.433,86.167,140.522,86.434,140.569,86.539z"/>
             <path fill="#808184" d="M132.491,89.934c0.152-0.028,0.297-0.06,0.426-0.074
               c0.13-0.017,0.252-0.039,0.357-0.063c0.26-0.045,0.457-0.082,0.586-0.014c0.1,0.061,0.1,0.105,0.106,0.15
               c0,0.01,0,0.018,0.017,0.031v-0.008c0,0,0.273,0.062,0.435,0.076l0.054,0.008c0.137,0.017,0.205,0.022,0.266,0.084
               c0.023,0.022,0.078,0.045,0.123,0.053c0.053,0.017,0.099,0.017,0.137,0.008c0.076-0.021,0.13-0.008,0.189,0.016
               c0.023,0.008,0.055,0.016,0.084,0.022l0.03,0.007c0.077,0.018,0.167,0.031,0.177,0.01c0.015-0.039,0.137-0.207,0.289-0.373
               c0.092-0.105,0.203-0.215,0.313-0.289c0.136-0.093,0.167-0.152,0.197-0.213c0.029-0.047,0.054-0.101,0.129-0.16
               c0.091-0.084,0.16-0.102,0.229-0.123c0.053-0.015,0.105-0.031,0.176-0.092l0.045-0.029c0.113-0.1,0.152-0.139,0.189-0.289
               l0.016-0.045c0.039-0.176,0.068-0.291,0.152-0.32c0.016,0,0.061-0.021,0.114-0.063c0.183-0.099,0.51-0.289,0.677-0.268
               c0.039,0.01,0.063,0.01,0.084,0.018c0.123,0.014,0.168,0.022,0.223-0.018c0.006,0-0.031-0.045-0.055-0.105
               c-0.016-0.021-0.029-0.053-0.045-0.082c-0.063-0.131-0.092-0.281,0.076-0.358c0.038-0.017,0.092-0.038,0.129-0.062
               c0.197-0.092,0.352-0.168,0.609-0.213c0.183-0.039,0.379-0.123,0.563-0.197c0.113-0.039,0.212-0.084,0.312-0.113
               c0.113-0.031,0.26-0.108,0.389-0.178c0.068-0.037,0.13-0.067,0.182-0.09c-0.037-0.107-0.136-0.39-0.144-0.465
               c-0.009-0.068,0.038-0.146,0.092-0.222c0.037-0.054,0.075-0.114,0.083-0.159c0.008-0.046,0.008-0.092,0.016-0.139
               c0.008-0.09,0.016-0.182,0.039-0.297c0.021-0.137,0.145-0.168,0.266-0.205c0.062-0.016,0.115-0.03,0.152-0.053
               c0.016-0.008,0.016-0.016,0.016-0.016c-0.008-0.016-0.038-0.038-0.061-0.063c-0.047-0.029-0.084-0.061-0.107-0.1
               c-0.045-0.066-0.03-0.137-0.022-0.213c0.007-0.068,0.022-0.152-0.008-0.229c-0.045-0.123,0-0.28,0.03-0.404
               c0.023-0.068,0.039-0.12,0.031-0.137v-0.016c-0.022-0.082-0.047-0.175,0.008-0.298c0.037-0.082,0.076-0.12,0.129-0.159
               c0.015-0.015,0.039-0.029,0.054-0.054c0.03-0.028,0.045-0.054,0.054-0.074c0.006-0.017,0.006-0.046,0-0.084
               c0-0.055-0.076-0.114-0.138-0.176c-0.022-0.017-0.038-0.03-0.054-0.054c-0.09-0.084-0.068-0.146-0.037-0.221
               c0.007-0.016,0.007-0.031,0.015-0.045c0-0.017-0.062-0.055-0.114-0.086c-0.029-0.021-0.067-0.037-0.09-0.061
               c-0.056-0.053-0.046-0.145-0.031-0.197c-0.114-0.047-0.222-0.102-0.328-0.146c-0.168-0.074-0.318-0.144-0.435-0.183
               c-0.235-0.084-0.259-0.144-0.288-0.228c-0.007-0.031-0.023-0.063-0.054-0.1c-0.062-0.062-0.146-0.084-0.229-0.107
               c-0.053-0.008-0.099-0.021-0.144-0.045c-0.03,0.076-0.084,0.16-0.129,0.152c-0.069,0-0.092-0.076-0.161-0.176
               c-0.061-0.105-0.259-0.023-0.326-0.146c-0.023-0.037-0.023-0.09-0.008-0.144l-0.068,0.015c-0.197,0.03-0.441,0.076-0.625,0.092
               c-0.22,0.016-0.25,0.023-0.289,0.037c-0.023,0.009-0.045,0.016-0.099,0.031c-0.069,0.008-0.138,0.131-0.213,0.266
               c-0.054,0.093-0.108,0.191-0.176,0.275c-0.115,0.145-0.115,0.168-0.099,0.235c0.008,0.022,0.015,0.054,0.021,0.101
               c0,0.045,0.016,0.084,0.023,0.135c0.028,0.146,0.061,0.307-0.01,0.459c-0.029,0.084-0.021,0.197-0.007,0.313
               c0.017,0.129,0.037,0.271,0,0.418c-0.054,0.198-0.114,0.334-0.167,0.449c-0.016,0.037-0.031,0.066-0.047,0.105
               c-0.016,0.03,0.023,0.115,0.083,0.207c0.108,0.174,0.282,0.373,0.329,0.426c0.029,0.029,0.091,0.054,0.158,0.068
               c0.084,0.03,0.191,0.045,0.283,0.045c0.068,0.01,0.084-0.015,0.099-0.029c0.03-0.039,0.067-0.084,0.175-0.092
               c0.016,0,0.023,0,0.038,0.008c0.045,0.008,0.076,0.039,0.092,0.092c0.017,0.037,0.03,0.084,0.03,0.145
               c0.015,0.189-0.038,0.486-0.068,0.641l-0.016,0.046c-0.053,0.25-0.053,0.259-0.297,0.204c-0.092-0.014-0.122,0.009-0.151,0.039
               c-0.039,0.029-0.078,0.068-0.153,0.068c-0.151,0-0.242-0.189-0.305-0.328l-0.007-0.029c-0.031-0.063-0.084-0.145-0.138-0.237
               c-0.062-0.09-0.121-0.196-0.168-0.288c-0.045-0.092-0.114-0.092-0.221-0.092c-0.076,0-0.168,0-0.273-0.016
               c-0.26-0.053-0.313-0.281-0.366-0.519c-0.015-0.067-0.03-0.13-0.054-0.188c-0.006-0.031-0.006-0.047-0.006-0.047
               c-0.031,0.016-0.076,0.092-0.131,0.176c-0.068,0.105-0.137,0.222-0.205,0.259c-0.168,0.075-0.222,0.053-0.457-0.076
               c-0.029-0.015-0.068-0.028-0.1-0.054c-0.168-0.084-0.268-0.061-0.334-0.037c-0.068,0.015-0.123,0.021-0.191-0.016
               c-0.066-0.055-0.084-0.131-0.09-0.221c-0.017-0.084-0.031-0.183-0.107-0.26c-0.068-0.076-0.168-0.016-0.289,0.053
               c-0.092,0.055-0.191,0.115-0.305,0.139c-0.129,0.029-0.184,0-0.197-0.055c-0.008-0.045,0.015-0.1,0.037-0.15
               c0.017-0.039,0.031-0.078,0.022-0.086c-0.016-0.037-0.067-0.135-0.137-0.203c-0.022-0.018-0.046-0.039-0.069-0.047
               c0,0.115,0.008,0.234,0.031,0.297c0.029,0.1,0.008,0.189-0.018,0.289c-0.021,0.099-0.053,0.213-0.037,0.351
               c0.016,0.151,0.037,0.321,0.009,0.487c-0.022,0.176-0.093,0.334-0.252,0.472c-0.188,0.161-0.571,0.123-0.905,0.077
               c-0.206-0.023-0.396-0.046-0.494-0.008c-0.152,0.046-0.123,0.89-0.092,1.552c0.016,0.282,0.021,0.526,0.016,0.679
               c-0.016,0.403,0.06,0.563,0.183,0.814c0.03,0.068,0.069,0.146,0.106,0.236C131.958,89.212,132.271,89.646,132.491,89.934"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M132.491,89.934c0.152-0.028,0.297-0.06,0.426-0.074c0.13-0.017,0.252-0.039,0.357-0.063c0.26-0.045,0.457-0.082,0.586-0.014
               c0.1,0.061,0.1,0.105,0.106,0.15c0,0.01,0,0.018,0.017,0.031v-0.008c0,0,0.273,0.062,0.435,0.076l0.054,0.008
               c0.137,0.017,0.205,0.022,0.266,0.084c0.023,0.022,0.078,0.045,0.123,0.053c0.053,0.017,0.099,0.017,0.137,0.008
               c0.076-0.021,0.13-0.008,0.189,0.016c0.023,0.008,0.055,0.016,0.084,0.022l0.03,0.007c0.077,0.018,0.167,0.031,0.177,0.01
               c0.015-0.039,0.137-0.207,0.289-0.373c0.092-0.105,0.203-0.215,0.313-0.289c0.136-0.093,0.167-0.152,0.197-0.213
               c0.029-0.047,0.054-0.101,0.129-0.16c0.091-0.084,0.16-0.102,0.229-0.123c0.053-0.015,0.105-0.031,0.176-0.092l0.045-0.029
               c0.113-0.1,0.152-0.139,0.189-0.289l0.016-0.045c0.039-0.176,0.068-0.291,0.152-0.32c0.016,0,0.061-0.021,0.114-0.063
               c0.183-0.099,0.51-0.289,0.677-0.268c0.039,0.01,0.063,0.01,0.084,0.018c0.123,0.014,0.168,0.022,0.223-0.018
               c0.006,0-0.031-0.045-0.055-0.105c-0.016-0.021-0.029-0.053-0.045-0.082c-0.063-0.131-0.092-0.281,0.076-0.358
               c0.038-0.017,0.092-0.038,0.129-0.062c0.197-0.092,0.352-0.168,0.609-0.213c0.183-0.039,0.379-0.123,0.563-0.197
               c0.113-0.039,0.212-0.084,0.312-0.113c0.113-0.031,0.26-0.108,0.389-0.178c0.068-0.037,0.13-0.067,0.182-0.09
               c-0.037-0.107-0.136-0.39-0.144-0.465c-0.009-0.068,0.038-0.146,0.092-0.222c0.037-0.054,0.075-0.114,0.083-0.159
               c0.008-0.046,0.008-0.092,0.016-0.139c0.008-0.09,0.016-0.182,0.039-0.297c0.021-0.137,0.145-0.168,0.266-0.205
               c0.062-0.016,0.115-0.03,0.152-0.053c0.016-0.008,0.016-0.016,0.016-0.016c-0.008-0.016-0.038-0.038-0.061-0.063
               c-0.047-0.029-0.084-0.061-0.107-0.1c-0.045-0.066-0.03-0.137-0.022-0.213c0.007-0.068,0.022-0.152-0.008-0.229
               c-0.045-0.123,0-0.28,0.03-0.404c0.023-0.068,0.039-0.12,0.031-0.137v-0.016c-0.022-0.082-0.047-0.175,0.008-0.298
               c0.037-0.082,0.076-0.12,0.129-0.159c0.015-0.015,0.039-0.029,0.054-0.054c0.03-0.028,0.045-0.054,0.054-0.074
               c0.006-0.017,0.006-0.046,0-0.084c0-0.055-0.076-0.114-0.138-0.176c-0.022-0.017-0.038-0.03-0.054-0.054
               c-0.09-0.084-0.068-0.146-0.037-0.221c0.007-0.016,0.007-0.031,0.015-0.045c0-0.017-0.062-0.055-0.114-0.086
               c-0.029-0.021-0.067-0.037-0.09-0.061c-0.056-0.053-0.046-0.145-0.031-0.197c-0.114-0.047-0.222-0.102-0.328-0.146
               c-0.168-0.074-0.318-0.144-0.435-0.183c-0.235-0.084-0.259-0.144-0.288-0.228c-0.007-0.031-0.023-0.063-0.054-0.1
               c-0.062-0.062-0.146-0.084-0.229-0.107c-0.053-0.008-0.099-0.021-0.144-0.045c-0.03,0.076-0.084,0.16-0.129,0.152
               c-0.069,0-0.092-0.076-0.161-0.176c-0.061-0.105-0.259-0.023-0.326-0.146c-0.023-0.037-0.023-0.09-0.008-0.144l-0.068,0.015
               c-0.197,0.03-0.441,0.076-0.625,0.092c-0.22,0.016-0.25,0.023-0.289,0.037c-0.023,0.009-0.045,0.016-0.099,0.031
               c-0.069,0.008-0.138,0.131-0.213,0.266c-0.054,0.093-0.108,0.191-0.176,0.275c-0.115,0.145-0.115,0.168-0.099,0.235
               c0.008,0.022,0.015,0.054,0.021,0.101c0,0.045,0.016,0.084,0.023,0.135c0.028,0.146,0.061,0.307-0.01,0.459
               c-0.029,0.084-0.021,0.197-0.007,0.313c0.017,0.129,0.037,0.271,0,0.418c-0.054,0.198-0.114,0.334-0.167,0.449
               c-0.016,0.037-0.031,0.066-0.047,0.105c-0.016,0.03,0.023,0.115,0.083,0.207c0.108,0.174,0.282,0.373,0.329,0.426
               c0.029,0.029,0.091,0.054,0.158,0.068c0.084,0.03,0.191,0.045,0.283,0.045c0.068,0.01,0.084-0.015,0.099-0.029
               c0.03-0.039,0.067-0.084,0.175-0.092c0.016,0,0.023,0,0.038,0.008c0.045,0.008,0.076,0.039,0.092,0.092
               c0.017,0.037,0.03,0.084,0.03,0.145c0.015,0.189-0.038,0.486-0.068,0.641l-0.016,0.046c-0.053,0.25-0.053,0.259-0.297,0.204
               c-0.092-0.014-0.122,0.009-0.151,0.039c-0.039,0.029-0.078,0.068-0.153,0.068c-0.151,0-0.242-0.189-0.305-0.328l-0.007-0.029
               c-0.031-0.063-0.084-0.145-0.138-0.237c-0.062-0.09-0.121-0.196-0.168-0.288c-0.045-0.092-0.114-0.092-0.221-0.092
               c-0.076,0-0.168,0-0.273-0.016c-0.26-0.053-0.313-0.281-0.366-0.519c-0.015-0.067-0.03-0.13-0.054-0.188
               c-0.006-0.031-0.006-0.047-0.006-0.047c-0.031,0.016-0.076,0.092-0.131,0.176c-0.068,0.105-0.137,0.222-0.205,0.259
               c-0.168,0.075-0.222,0.053-0.457-0.076c-0.029-0.015-0.068-0.028-0.1-0.054c-0.168-0.084-0.268-0.061-0.334-0.037
               c-0.068,0.015-0.123,0.021-0.191-0.016c-0.066-0.055-0.084-0.131-0.09-0.221c-0.017-0.084-0.031-0.183-0.107-0.26
               c-0.068-0.076-0.168-0.016-0.289,0.053c-0.092,0.055-0.191,0.115-0.305,0.139c-0.129,0.029-0.184,0-0.197-0.055
               c-0.008-0.045,0.015-0.1,0.037-0.15c0.017-0.039,0.031-0.078,0.022-0.086c-0.016-0.037-0.067-0.135-0.137-0.203
               c-0.022-0.018-0.046-0.039-0.069-0.047c0,0.115,0.008,0.234,0.031,0.297c0.029,0.1,0.008,0.189-0.018,0.289
               c-0.021,0.099-0.053,0.213-0.037,0.351c0.016,0.151,0.037,0.321,0.009,0.487c-0.022,0.176-0.093,0.334-0.252,0.472
               c-0.188,0.161-0.571,0.123-0.905,0.077c-0.206-0.023-0.396-0.046-0.494-0.008c-0.152,0.046-0.123,0.89-0.092,1.552
               c0.016,0.282,0.021,0.526,0.016,0.679c-0.016,0.403,0.06,0.563,0.183,0.814c0.03,0.068,0.069,0.146,0.106,0.236
               C131.958,89.212,132.271,89.646,132.491,89.934z"/>
             <path fill="#808184" d="M123.599,79.193l0.007,0.016c0.076,0.121,0.236,0.524,0.236,0.524
               s0.402,0.959,0.495,1.202c0.091,0.251,0.243,0.563,0.121,0.709c-0.121,0.145-0.304,0.297-0.214,0.512
               c0.093,0.213,0.336,0.518,0.336,0.729c0,0.213,0.138,0.259,0.214,0.525c0.084,0.273,0.129,0.403,0.061,0.555
               c-0.069,0.146,0.023,0.74-0.205,0.953c-0.222,0.213-0.396,0.228-0.519,0.541c-0.121,0.313-0.267,0.357-0.327,0.641
               c-0.054,0.281-0.106,0.494-0.152,0.82c-0.046,0.328-0.084,1.082-0.273,1.195c-0.191,0.106-0.236,0.197-0.198,0.525
               c0.022,0.234,0.022,0.557,0.022,0.791c0.084-0.021,0.16-0.03,0.244-0.037c0.137,0,0.266,0.021,0.373,0.045
               c0.106,0.023,0.19,0.039,0.221,0.016c0.031-0.022,0.068-0.067,0.099-0.113c0.076-0.107,0.16-0.221,0.273-0.168
               c0.023,0.015,0.046,0.023,0.077,0.039c0.137,0.061,0.327,0.144,0.457,0.289c0.09,0.113,0.197,0.113,0.457,0.105h0.312
               c0.243,0.008,0.403,0.03,0.571,0.045c0.196,0.023,0.396,0.047,0.724,0.047c0.221,0,0.473-0.021,0.715-0.054
               c0.449-0.046,0.869-0.084,0.976,0.054c0.069,0.076,0.069,0.145,0.077,0.205c0,0.061,0,0.107,0.197,0.145
               c0.205,0.039,0.44,0.055,0.654,0.063c0.176,0.008,0.335,0.021,0.439,0.038c0.084,0.022,0.139,0.015,0.207,0.009
               c0.1-0.009,0.205-0.017,0.435,0.028c0.267,0.056,0.274,0.056,0.427,0.022c0.062-0.015,0.137-0.03,0.266-0.045
               c0.214-0.031,0.473-0.092,0.725-0.151c0.092-0.017,0.176-0.038,0.26-0.054c-0.222-0.297-0.519-0.709-0.625-0.961
               c-0.039-0.082-0.076-0.158-0.105-0.229c-0.131-0.266-0.215-0.434-0.19-0.859c0.009-0.152-0.007-0.396-0.015-0.67
               c-0.031-0.701-0.069-1.584,0.158-1.66c0.123-0.037,0.32-0.016,0.533,0.01c0.319,0.035,0.678,0.074,0.838-0.055
               c0.129-0.113,0.189-0.261,0.213-0.412c0.021-0.15,0.008-0.313-0.008-0.457c-0.022-0.158,0.008-0.281,0.039-0.388
               c0.021-0.084,0.037-0.161,0.016-0.229c-0.024-0.084-0.031-0.221-0.031-0.35c-0.107,0.016-0.29,0.075-0.441,0.129h-0.008
               c-0.082,0.03-0.158,0.053-0.205,0.068c-0.092,0.021-0.168,0-0.25-0.016c-0.063-0.016-0.132-0.03-0.207-0.03
               c-0.061,0-0.093,0.038-0.106,0.067c-0.037,0.054-0.077,0.099-0.175,0.076c-0.03-0.008-0.053-0.022-0.075-0.047
               c-0.032-0.037-0.048-0.082-0.048-0.129c-0.006-0.053,0-0.1,0.009-0.145c0.015-0.061,0.039-0.107,0.075-0.137
               c0.016-0.017,0.023-0.07,0.016-0.138c-0.007-0.104-0.036-0.243-0.068-0.351c-0.037-0.146-0.101-0.274-0.197-0.502
               c-0.037-0.076-0.074-0.16-0.1-0.223c-0.066-0.152-0.084-0.318-0.074-0.472c0.008-0.244,0.1-0.458,0.129-0.503
               c0.029-0.039,0-0.159-0.039-0.281l-0.016-0.039c-0.007-0.021-0.016-0.045-0.021-0.066c-0.038-0.131-0.076-0.252-0.029-0.373
               c0.016-0.063,0.029-0.106,0.029-0.153c0-0.045-0.016-0.083-0.053-0.136c-0.031-0.039-0.273-0.031-0.504-0.024
               c-0.189,0.009-0.365,0.019-0.436-0.015c-0.083-0.037-0.083-0.083-0.061-0.137c0.008-0.022,0.031-0.047,0.047-0.068
               c0.022-0.021,0.053-0.063,0.045-0.084c0-0.021-0.008-0.03-0.016-0.03c-0.022,0-0.061,0.022-0.106,0.038
               c-0.067,0.029-0.235,0.029-0.396,0.021c-0.092,0-0.184-0.008-0.236,0s-0.045,0.084-0.036,0.16c0,0.021,0.006,0.039,0.006,0.055
               c0.009,0.105,0,0.129-0.067,0.259c-0.021,0.038-0.046,0.083-0.068,0.146c-0.037,0.067-0.062,0.121-0.076,0.168
               c-0.053,0.129-0.068,0.166-0.213,0.189c-0.139,0.029-0.273,0.007-0.465-0.023c-0.037-0.008-0.076-0.015-0.137-0.022
               c-0.138-0.022-0.146-0.015-0.176,0.017c-0.029,0.03-0.076,0.067-0.184,0.114c-0.258,0.12-0.326,0.007-0.396-0.106
               c-0.016-0.023-0.022-0.037-0.03-0.055c-0.047-0.063-0.123-0.205-0.184-0.352c-0.061-0.129-0.105-0.256-0.137-0.365
               c-0.016-0.082-0.055-0.128-0.084-0.176c-0.061-0.081-0.106-0.15-0.092-0.334c0.022-0.252-0.114-0.44-0.26-0.646l-0.029-0.037
               c-0.084-0.123-0.198-0.084-0.357-0.039c-0.107,0.022-0.223,0.063-0.365,0.069c-0.198,0.007-0.571,0-0.937-0.015
               c-0.313-0.01-0.618-0.032-0.808-0.063c-0.268-0.036-0.29-0.022-0.352,0.022c-0.029,0.023-0.067,0.055-0.136,0.092
               c-0.099,0.054-0.205,0.084-0.313,0.107C123.728,79.184,123.659,79.193,123.599,79.193"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M123.599,79.193l0.007,0.016c0.076,0.121,0.236,0.524,0.236,0.524s0.402,0.959,0.495,1.202c0.091,0.251,0.243,0.563,0.121,0.709
               c-0.121,0.145-0.304,0.297-0.214,0.512c0.093,0.213,0.336,0.518,0.336,0.729c0,0.213,0.138,0.259,0.214,0.525
               c0.084,0.273,0.129,0.403,0.061,0.555c-0.069,0.146,0.023,0.74-0.205,0.953c-0.222,0.213-0.396,0.228-0.519,0.541
               c-0.121,0.313-0.267,0.357-0.327,0.641c-0.054,0.281-0.106,0.494-0.152,0.82c-0.046,0.328-0.084,1.082-0.273,1.195
               c-0.191,0.106-0.236,0.197-0.198,0.525c0.022,0.234,0.022,0.557,0.022,0.791c0.084-0.021,0.16-0.03,0.244-0.037
               c0.137,0,0.266,0.021,0.373,0.045c0.106,0.023,0.19,0.039,0.221,0.016c0.031-0.022,0.068-0.067,0.099-0.113
               c0.076-0.107,0.16-0.221,0.273-0.168c0.023,0.015,0.046,0.023,0.077,0.039c0.137,0.061,0.327,0.144,0.457,0.289
               c0.09,0.113,0.197,0.113,0.457,0.105h0.312c0.243,0.008,0.403,0.03,0.571,0.045c0.196,0.023,0.396,0.047,0.724,0.047
               c0.221,0,0.473-0.021,0.715-0.054c0.449-0.046,0.869-0.084,0.976,0.054c0.069,0.076,0.069,0.145,0.077,0.205
               c0,0.061,0,0.107,0.197,0.145c0.205,0.039,0.44,0.055,0.654,0.063c0.176,0.008,0.335,0.021,0.439,0.038
               c0.084,0.022,0.139,0.015,0.207,0.009c0.1-0.009,0.205-0.017,0.435,0.028c0.267,0.056,0.274,0.056,0.427,0.022
               c0.062-0.015,0.137-0.03,0.266-0.045c0.214-0.031,0.473-0.092,0.725-0.151c0.092-0.017,0.176-0.038,0.26-0.054
               c-0.222-0.297-0.519-0.709-0.625-0.961c-0.039-0.082-0.076-0.158-0.105-0.229c-0.131-0.266-0.215-0.434-0.19-0.859
               c0.009-0.152-0.007-0.396-0.015-0.67c-0.031-0.701-0.069-1.584,0.158-1.66c0.123-0.037,0.32-0.016,0.533,0.01
               c0.319,0.035,0.678,0.074,0.838-0.055c0.129-0.113,0.189-0.261,0.213-0.412c0.021-0.15,0.008-0.313-0.008-0.457
               c-0.022-0.158,0.008-0.281,0.039-0.388c0.021-0.084,0.037-0.161,0.016-0.229c-0.024-0.084-0.031-0.221-0.031-0.35
               c-0.107,0.016-0.29,0.075-0.441,0.129h-0.008c-0.082,0.03-0.158,0.053-0.205,0.068c-0.092,0.021-0.168,0-0.25-0.016
               c-0.063-0.016-0.132-0.03-0.207-0.03c-0.061,0-0.093,0.038-0.106,0.067c-0.037,0.054-0.077,0.099-0.175,0.076
               c-0.03-0.008-0.053-0.022-0.075-0.047c-0.032-0.037-0.048-0.082-0.048-0.129c-0.006-0.053,0-0.1,0.009-0.145
               c0.015-0.061,0.039-0.107,0.075-0.137c0.016-0.017,0.023-0.07,0.016-0.138c-0.007-0.104-0.036-0.243-0.068-0.351
               c-0.037-0.146-0.101-0.274-0.197-0.502c-0.037-0.076-0.074-0.16-0.1-0.223c-0.066-0.152-0.084-0.318-0.074-0.472
               c0.008-0.244,0.1-0.458,0.129-0.503c0.029-0.039,0-0.159-0.039-0.281l-0.016-0.039c-0.007-0.021-0.016-0.045-0.021-0.066
               c-0.038-0.131-0.076-0.252-0.029-0.373c0.016-0.063,0.029-0.106,0.029-0.153c0-0.045-0.016-0.083-0.053-0.136
               c-0.031-0.039-0.273-0.031-0.504-0.024c-0.189,0.009-0.365,0.019-0.436-0.015c-0.083-0.037-0.083-0.083-0.061-0.137
               c0.008-0.022,0.031-0.047,0.047-0.068c0.022-0.021,0.053-0.063,0.045-0.084c0-0.021-0.008-0.03-0.016-0.03
               c-0.022,0-0.061,0.022-0.106,0.038c-0.067,0.029-0.235,0.029-0.396,0.021c-0.092,0-0.184-0.008-0.236,0s-0.045,0.084-0.036,0.16
               c0,0.021,0.006,0.039,0.006,0.055c0.009,0.105,0,0.129-0.067,0.259c-0.021,0.038-0.046,0.083-0.068,0.146
               c-0.037,0.067-0.062,0.121-0.076,0.168c-0.053,0.129-0.068,0.166-0.213,0.189c-0.139,0.029-0.273,0.007-0.465-0.023
               c-0.037-0.008-0.076-0.015-0.137-0.022c-0.138-0.022-0.146-0.015-0.176,0.017c-0.029,0.03-0.076,0.067-0.184,0.114
               c-0.258,0.12-0.326,0.007-0.396-0.106c-0.016-0.023-0.022-0.037-0.03-0.055c-0.047-0.063-0.123-0.205-0.184-0.352
               c-0.061-0.129-0.105-0.256-0.137-0.365c-0.016-0.082-0.055-0.128-0.084-0.176c-0.061-0.081-0.106-0.15-0.092-0.334
               c0.022-0.252-0.114-0.44-0.26-0.646l-0.029-0.037c-0.084-0.123-0.198-0.084-0.357-0.039c-0.107,0.022-0.223,0.063-0.365,0.069
               c-0.198,0.007-0.571,0-0.937-0.015c-0.313-0.01-0.618-0.032-0.808-0.063c-0.268-0.036-0.29-0.022-0.352,0.022
               c-0.029,0.023-0.067,0.055-0.136,0.092c-0.099,0.054-0.205,0.084-0.313,0.107C123.728,79.184,123.659,79.193,123.599,79.193z"/>
             <path fill="#808184" d="M138.56,74.608c-0.029,0.017-0.076,0.03-0.145,0.046
               c-0.121,0.023-0.198,0.018-0.26,0.01c-0.084-0.01-0.137-0.018-0.252,0.06c-0.105,0.077-0.166,0.123-0.219,0.176
               c-0.047,0.045-0.078,0.099-0.107,0.168c-0.029,0.06-0.021,0.121-0.016,0.183c0.008,0.069,0.016,0.145-0.022,0.229
               c-0.022,0.076-0.062,0.113-0.101,0.145c-0.037,0.039-0.074,0.078-0.074,0.191v0.045c0.068-0.016,0.184-0.038,0.259-0.008
               c0.067,0.031,0.076,0.068,0.093,0.105c0,0.018,0.006,0.031,0.061,0.039c0.084,0.016,0.176,0.016,0.244-0.008
               c0.045-0.015,0.074-0.039,0.082-0.068c0-0.016,0.01-0.037,0.01-0.062c0.015-0.053,0.021-0.114,0.037-0.168
               c0.029-0.09,0.084-0.144,0.176-0.114c0.054,0.016,0.099,0.008,0.145-0.008c0.038-0.007,0.067-0.021,0.114-0.021
               c0.054,0.008,0.099,0.029,0.138,0.061c0.014,0.016,0.036,0.023,0.045,0.023c0.016-0.008,0.03-0.008,0.047-0.008
               c0.015-0.008,0.031-0.008,0.053-0.062c0.015-0.03,0.007-0.069-0.008-0.114c-0.023-0.054-0.045-0.105-0.03-0.183
               c0.007-0.076,0.017-0.222-0.009-0.328c-0.006-0.067-0.028-0.114-0.062-0.136C138.665,74.753,138.599,74.664,138.56,74.608"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M138.56,74.608c-0.029,0.017-0.076,0.03-0.145,0.046c-0.121,0.023-0.198,0.018-0.26,0.01c-0.084-0.01-0.137-0.018-0.252,0.06
               c-0.105,0.077-0.166,0.123-0.219,0.176c-0.047,0.045-0.078,0.099-0.107,0.168c-0.029,0.06-0.021,0.121-0.016,0.183
               c0.008,0.069,0.016,0.145-0.022,0.229c-0.022,0.076-0.062,0.113-0.101,0.145c-0.037,0.039-0.074,0.078-0.074,0.191v0.045
               c0.068-0.016,0.184-0.038,0.259-0.008c0.067,0.031,0.076,0.068,0.093,0.105c0,0.018,0.006,0.031,0.061,0.039
               c0.084,0.016,0.176,0.016,0.244-0.008c0.045-0.015,0.074-0.039,0.082-0.068c0-0.016,0.01-0.037,0.01-0.062
               c0.015-0.053,0.021-0.114,0.037-0.168c0.029-0.09,0.084-0.144,0.176-0.114c0.054,0.016,0.099,0.008,0.145-0.008
               c0.038-0.007,0.067-0.021,0.114-0.021c0.054,0.008,0.099,0.029,0.138,0.061c0.014,0.016,0.036,0.023,0.045,0.023
               c0.016-0.008,0.03-0.008,0.047-0.008c0.015-0.008,0.031-0.008,0.053-0.062c0.015-0.03,0.007-0.069-0.008-0.114
               c-0.023-0.054-0.045-0.105-0.03-0.183c0.007-0.076,0.017-0.222-0.009-0.328c-0.006-0.067-0.028-0.114-0.062-0.136
               C138.665,74.753,138.599,74.664,138.56,74.608z"/>
             <path fill="#808184" d="M138.629,75.666c-0.017-0.006-0.038-0.015-0.054-0.015
               c-0.023,0-0.055,0.009-0.077,0.015c-0.062,0.017-0.12,0.033-0.204,0c-0.023,0-0.031,0.023-0.046,0.056
               c-0.017,0.045-0.022,0.099-0.031,0.151c-0.007,0.022-0.007,0.045-0.015,0.068c-0.018,0.068-0.076,0.113-0.152,0.145
               c-0.084,0.023-0.189,0.023-0.281,0.008c-0.129-0.014-0.146-0.061-0.152-0.112c-0.008-0.019-0.008-0.024-0.03-0.039
               c-0.054-0.022-0.146,0.007-0.196,0.021c0.014,0.056,0.029,0.108,0.051,0.168c0.031,0.078,0.069,0.162,0.093,0.275
               c0.03,0.12,0.038,0.252,0.046,0.357v0.045c0.023-0.016,0.045-0.016,0.076,0c0.084,0.047,0.046,0.441,0.137,0.512
               c0.063,0.053,0.077,0.15,0.084,0.258c0.068,0,0.129-0.017,0.16-0.045c0.021-0.029,0.038-0.056,0.053-0.084
               c0.023-0.055,0.046-0.107,0.131-0.158c0.037-0.024,0.061-0.092,0.092-0.168c0.037-0.1,0.084-0.215,0.174-0.291
               c0.063-0.045,0.101-0.067,0.138-0.084c0.045-0.021,0.076-0.037,0.146-0.158c0.016-0.03,0.022-0.063,0.037-0.084
               c0.069-0.129,0.092-0.176,0-0.229c-0.054-0.022-0.091-0.038-0.121-0.054c-0.115-0.038-0.168-0.061-0.121-0.205
               C138.591,75.912,138.612,75.735,138.629,75.666"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M138.629,75.666c-0.017-0.006-0.038-0.015-0.054-0.015c-0.023,0-0.055,0.009-0.077,0.015c-0.062,0.017-0.12,0.033-0.204,0
               c-0.023,0-0.031,0.023-0.046,0.056c-0.017,0.045-0.022,0.099-0.031,0.151c-0.007,0.022-0.007,0.045-0.015,0.068
               c-0.018,0.068-0.076,0.113-0.152,0.145c-0.084,0.023-0.189,0.023-0.281,0.008c-0.129-0.014-0.146-0.061-0.152-0.112
               c-0.008-0.019-0.008-0.024-0.03-0.039c-0.054-0.022-0.146,0.007-0.196,0.021c0.014,0.056,0.029,0.108,0.051,0.168
               c0.031,0.078,0.069,0.162,0.093,0.275c0.03,0.12,0.038,0.252,0.046,0.357v0.045c0.023-0.016,0.045-0.016,0.076,0
               c0.084,0.047,0.046,0.441,0.137,0.512c0.063,0.053,0.077,0.15,0.084,0.258c0.068,0,0.129-0.017,0.16-0.045
               c0.021-0.029,0.038-0.056,0.053-0.084c0.023-0.055,0.046-0.107,0.131-0.158c0.037-0.024,0.061-0.092,0.092-0.168
               c0.037-0.1,0.084-0.215,0.174-0.291c0.063-0.045,0.101-0.067,0.138-0.084c0.045-0.021,0.076-0.037,0.146-0.158
               c0.016-0.03,0.022-0.063,0.037-0.084c0.069-0.129,0.092-0.176,0-0.229c-0.054-0.022-0.091-0.038-0.121-0.054
               c-0.115-0.038-0.168-0.061-0.121-0.205C138.591,75.912,138.612,75.735,138.629,75.666z"/>
             <path fill="#808184" d="M139.185,81.438L139.185,81.438c0.037,0.038,0.1,0.046,0.16,0.062
               c0.092,0.022,0.189,0.053,0.273,0.138c0.044,0.054,0.061,0.099,0.074,0.137c0.017,0.054,0.031,0.091,0.229,0.16
               c0.115,0.037,0.267,0.114,0.435,0.19c0.313,0.145,0.656,0.303,0.84,0.303c0.119,0,0.22-0.029,0.287-0.051
               c0.123-0.039,0.183-0.063,0.223,0.051c0.016,0.055,0.044,0.123,0.082,0.199c0.039,0.084,0.084,0.174,0.13,0.25
               c0.054,0.078,0.076,0.185,0.084,0.297c0,0.109-0.008,0.229-0.015,0.345c-0.008,0.03-0.008,0.062-0.008,0.099
               c0.015,0.055,0.029,0.106,0.054,0.146c0.075,0.158,0.151,0.326,0.175,0.41c0.145,0.054,0.373,0.076,0.502,0.084h0.063
               c0.029,0.008,0.076-0.022,0.129-0.047c0.121-0.061,0.242-0.129,0.334-0.008c0.018,0.023,0.022,0.031,0.031,0.047
               c0.061,0.076,0.068,0.092,0.268,0.137c0.145,0.029,0.258-0.021,0.342-0.061c0.045-0.023,0.091-0.045,0.129-0.045
               c0.039,0,0.084,0.014,0.139,0.035c0.1,0.031,0.236,0.084,0.312,0.023c0.039-0.03,0.056-0.09,0.062-0.145
               c0.022-0.152,0.055-0.297,0.365-0.229c0.26,0.062,0.473-0.03,0.646-0.09c0.084-0.041,0.168-0.069,0.236-0.076
               c0.075-0.008,0.106-0.063,0.152-0.115c0.063-0.076,0.137-0.176,0.313-0.214c0.183-0.037,0.252-0.067,0.313-0.083
               c0.037-0.016,0.066-0.031,0.121-0.038c0.021-0.009,0.063-0.03,0.105-0.067c-0.021-0.062-0.045-0.113-0.076-0.152
               c-0.075-0.1-0.281-0.19-0.381-0.289c-0.099-0.1-0.168-0.215-0.205-0.465c-0.029-0.244-0.146-0.412-0.213-0.617
               c-0.068-0.205-0.092-0.326-0.076-0.518c0.008-0.191,0.221-0.435,0.121-0.549c-0.1-0.113-0.166-0.371-0.076-0.472
               c0.093-0.106,0.169-0.388,0.093-0.442c-0.084-0.054-0.586-0.244-0.563-0.539c0.022-0.291,0.084-0.115,0.215-0.642
               c0.09-0.396,0.045-0.601,0.136-0.769c-0.167-0.166-0.427-0.41-0.624-0.539c-0.167-0.102-0.328-0.207-0.456-0.313
               c-0.131-0.114-0.222-0.235-0.222-0.358c0-0.092,0.045-0.158,0.076-0.211c0.015-0.023,0.03-0.045,0.037-0.07
               c0.01-0.022-0.016-0.053-0.152-0.098c-0.182-0.055-0.577-0.275-0.98-0.519c-0.488-0.298-0.997-0.624-1.143-0.755
               c-0.047-0.038-0.092-0.075-0.137-0.12c-0.152-0.153-0.328-0.328-0.625-0.412c-0.022,0.062-0.054,0.114-0.076,0.153
               c-0.061,0.066-0.114,0.326-0.189,0.486c-0.084,0.16-0.146,0.084-0.221,0.113c-0.077,0.039-0.131,0.152,0.007,0.26
               c0.13,0.105,0.168,0,0.274,0.1c0.105,0.091-0.229,0.234-0.336,0.22c-0.1-0.015-0.121-0.009-0.252-0.054
               c-0.129-0.045-0.25,0.045-0.418,0.054c-0.176,0.017-0.168-0.129-0.234-0.151c-0.077-0.029-0.168,0.198-0.238,0.297
               c-0.075,0.1-0.128,0.022-0.195-0.054c-0.063-0.067-0.017-0.235-0.031-0.374c-0.014-0.136-0.068-0.189-0.022-0.447
               c0.045-0.26,0.146-0.291,0.138-0.396c-0.009-0.068-0.016-0.16-0.031-0.234c-0.396-0.008-1.012-0.016-1.043,0.105
               c-0.008,0.008-0.008,0.015-0.008,0.023c0.016,0.037,0.084,0.129,0.168,0.176c0.062,0.035,0.1,0.111,0.113,0.211
               c0.023,0.123,0.016,0.268,0.008,0.352c-0.008,0.055,0.008,0.1,0.023,0.146c0.021,0.059,0.046,0.111,0.016,0.188
               c-0.047,0.102-0.085,0.107-0.138,0.115c-0.008,0-0.022,0-0.03,0.008c-0.029,0.008-0.054,0.008-0.076,0
               c-0.015,0.084-0.037,0.221-0.068,0.318c-0.016,0.055,0.015,0.063,0.063,0.076c0.036,0.016,0.084,0.031,0.137,0.062
               c0.183,0.108,0.146,0.177,0.045,0.366c-0.016,0.029-0.029,0.053-0.045,0.084c-0.076,0.15-0.121,0.174-0.185,0.205
               c-0.028,0.016-0.067,0.029-0.12,0.076c-0.068,0.053-0.107,0.151-0.138,0.242c-0.038,0.094-0.067,0.168-0.138,0.213
               c-0.053,0.039-0.076,0.076-0.092,0.123c-0.021,0.03-0.037,0.067-0.066,0.106c-0.047,0.052-0.139,0.067-0.229,0.076
               c0,0.022,0.006,0.045,0.006,0.067c0.017,0.168-0.021,0.174,0.023,0.281c0.055,0.107,0.091,0.251,0.13,0.313
               c0.038,0.062-0.022,0.207-0.022,0.336c0.009,0.121,0.1,0.183,0.093,0.267c0,0.084-0.123,0.16-0.13,0.236
               c0,0.075,0.104,0.243,0.242,0.272c0.138,0.039,0.19,0.176,0.273,0.229c0.077,0.062,0.107,0.153,0.131,0.364
               c0.03,0.222,0.184,0.603,0.273,0.861C138.985,81.166,139.161,81.371,139.185,81.438"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M139.185,81.438L139.185,81.438c0.037,0.038,0.1,0.046,0.16,0.062c0.092,0.022,0.189,0.053,0.273,0.138
               c0.044,0.054,0.061,0.099,0.074,0.137c0.017,0.054,0.031,0.091,0.229,0.16c0.115,0.037,0.267,0.114,0.435,0.19
               c0.313,0.145,0.656,0.303,0.84,0.303c0.119,0,0.22-0.029,0.287-0.051c0.123-0.039,0.183-0.063,0.223,0.051
               c0.016,0.055,0.044,0.123,0.082,0.199c0.039,0.084,0.084,0.174,0.13,0.25c0.054,0.078,0.076,0.185,0.084,0.297
               c0,0.109-0.008,0.229-0.015,0.345c-0.008,0.03-0.008,0.062-0.008,0.099c0.015,0.055,0.029,0.106,0.054,0.146
               c0.075,0.158,0.151,0.326,0.175,0.41c0.145,0.054,0.373,0.076,0.502,0.084h0.063c0.029,0.008,0.076-0.022,0.129-0.047
               c0.121-0.061,0.242-0.129,0.334-0.008c0.018,0.023,0.022,0.031,0.031,0.047c0.061,0.076,0.068,0.092,0.268,0.137
               c0.145,0.029,0.258-0.021,0.342-0.061c0.045-0.023,0.091-0.045,0.129-0.045c0.039,0,0.084,0.014,0.139,0.035
               c0.1,0.031,0.236,0.084,0.312,0.023c0.039-0.03,0.056-0.09,0.062-0.145c0.022-0.152,0.055-0.297,0.365-0.229
               c0.26,0.062,0.473-0.03,0.646-0.09c0.084-0.041,0.168-0.069,0.236-0.076c0.075-0.008,0.106-0.063,0.152-0.115
               c0.063-0.076,0.137-0.176,0.313-0.214c0.183-0.037,0.252-0.067,0.313-0.083c0.037-0.016,0.066-0.031,0.121-0.038
               c0.021-0.009,0.063-0.03,0.105-0.067c-0.021-0.062-0.045-0.113-0.076-0.152c-0.075-0.1-0.281-0.19-0.381-0.289
               c-0.099-0.1-0.168-0.215-0.205-0.465c-0.029-0.244-0.146-0.412-0.213-0.617c-0.068-0.205-0.092-0.326-0.076-0.518
               c0.008-0.191,0.221-0.435,0.121-0.549c-0.1-0.113-0.166-0.371-0.076-0.472c0.093-0.106,0.169-0.388,0.093-0.442
               c-0.084-0.054-0.586-0.244-0.563-0.539c0.022-0.291,0.084-0.115,0.215-0.642c0.09-0.396,0.045-0.601,0.136-0.769
               c-0.167-0.166-0.427-0.41-0.624-0.539c-0.167-0.102-0.328-0.207-0.456-0.313c-0.131-0.114-0.222-0.235-0.222-0.358
               c0-0.092,0.045-0.158,0.076-0.211c0.015-0.023,0.03-0.045,0.037-0.07c0.01-0.022-0.016-0.053-0.152-0.098
               c-0.182-0.055-0.577-0.275-0.98-0.519c-0.488-0.298-0.997-0.624-1.143-0.755c-0.047-0.038-0.092-0.075-0.137-0.12
               c-0.152-0.153-0.328-0.328-0.625-0.412c-0.022,0.062-0.054,0.114-0.076,0.153c-0.061,0.066-0.114,0.326-0.189,0.486
               c-0.084,0.16-0.146,0.084-0.221,0.113c-0.077,0.039-0.131,0.152,0.007,0.26c0.13,0.105,0.168,0,0.274,0.1
               c0.105,0.091-0.229,0.234-0.336,0.22c-0.1-0.015-0.121-0.009-0.252-0.054c-0.129-0.045-0.25,0.045-0.418,0.054
               c-0.176,0.017-0.168-0.129-0.234-0.151c-0.077-0.029-0.168,0.198-0.238,0.297c-0.075,0.1-0.128,0.022-0.195-0.054
               c-0.063-0.067-0.017-0.235-0.031-0.374c-0.014-0.136-0.068-0.189-0.022-0.447c0.045-0.26,0.146-0.291,0.138-0.396
               c-0.009-0.068-0.016-0.16-0.031-0.234c-0.396-0.008-1.012-0.016-1.043,0.105c-0.008,0.008-0.008,0.015-0.008,0.023
               c0.016,0.037,0.084,0.129,0.168,0.176c0.062,0.035,0.1,0.111,0.113,0.211c0.023,0.123,0.016,0.268,0.008,0.352
               c-0.008,0.055,0.008,0.1,0.023,0.146c0.021,0.059,0.046,0.111,0.016,0.188c-0.047,0.102-0.085,0.107-0.138,0.115
               c-0.008,0-0.022,0-0.03,0.008c-0.029,0.008-0.054,0.008-0.076,0c-0.015,0.084-0.037,0.221-0.068,0.318
               c-0.016,0.055,0.015,0.063,0.063,0.076c0.036,0.016,0.084,0.031,0.137,0.062c0.183,0.108,0.146,0.177,0.045,0.366
               c-0.016,0.029-0.029,0.053-0.045,0.084c-0.076,0.15-0.121,0.174-0.185,0.205c-0.028,0.016-0.067,0.029-0.12,0.076
               c-0.068,0.053-0.107,0.151-0.138,0.242c-0.038,0.094-0.067,0.168-0.138,0.213c-0.053,0.039-0.076,0.076-0.092,0.123
               c-0.021,0.03-0.037,0.067-0.066,0.106c-0.047,0.052-0.139,0.067-0.229,0.076c0,0.022,0.006,0.045,0.006,0.067
               c0.017,0.168-0.021,0.174,0.023,0.281c0.055,0.107,0.091,0.251,0.13,0.313c0.038,0.062-0.022,0.207-0.022,0.336
               c0.009,0.121,0.1,0.183,0.093,0.267c0,0.084-0.123,0.16-0.13,0.236c0,0.075,0.104,0.243,0.242,0.272
               c0.138,0.039,0.19,0.176,0.273,0.229c0.077,0.062,0.107,0.153,0.131,0.364c0.03,0.222,0.184,0.603,0.273,0.861
               C138.985,81.166,139.161,81.371,139.185,81.438z"/>
             <path fill="#808184" d="M141.545,74.341c0.319,0.084,0.502,0.267,0.669,0.428
               c0.047,0.045,0.084,0.084,0.131,0.121c0.137,0.121,0.641,0.449,1.127,0.746c0.396,0.234,0.783,0.449,0.959,0.502
               c0.213,0.076,0.243,0.146,0.219,0.236c-0.014,0.022-0.028,0.053-0.043,0.084c-0.03,0.045-0.062,0.1-0.062,0.158
               c0,0.092,0.067,0.184,0.183,0.282c0.123,0.099,0.28,0.205,0.441,0.306c0.204,0.121,0.456,0.365,0.623,0.539
               c0.023-0.029,0.055-0.053,0.094-0.082c0.281-0.215,0.264-0.549,0.356-0.717c0.092-0.168,0.196-0.396,0.267-0.64
               c0.068-0.253,0.215-0.427,0.32-0.509c0.1-0.078,0.268-0.069,0.434-0.33c0.168-0.258,0.215-0.174,0.396-0.311
               c0.075-0.056,0.13-0.139,0.183-0.238c-0.229-0.182-0.556-0.479-0.609-0.623c-0.045-0.129-0.045-0.989-0.035-1.773
               c0-0.594,0-1.143-0.024-1.294c-0.038-0.319,0.13-0.556,0.289-0.791c0.031-0.056,0.062-0.101,0.093-0.146
               c0.053-0.084,0.105-0.152,0.151-0.207c0.091-0.115,0.144-0.183,0.075-0.258c-0.053-0.068-0.159-0.1-0.288-0.13
               c-0.138-0.03-0.289-0.067-0.442-0.168c-0.175-0.122-0.371,0.101-0.509,0.259c-0.056,0.062-0.101,0.114-0.14,0.146
               c-0.045,0.036-0.09,0.092-0.129,0.151c-0.084,0.115-0.168,0.229-0.326,0.236c-0.107,0.008-0.176-0.023-0.252-0.061
               c-0.075-0.031-0.152-0.068-0.313-0.063c-0.145,0.016-0.326-0.037-0.494-0.121c-0.197-0.091-0.39-0.229-0.465-0.32
               c-0.068-0.084-0.137-0.137-0.221-0.189c-0.092-0.053-0.206-0.1-0.357-0.145c-0.129-0.047-0.205-0.047-0.268-0.055
               c-0.084,0-0.139,0-0.236-0.076c-0.068-0.045-0.167-0.068-0.281-0.076c-0.151-0.008-0.313,0.017-0.418,0.048
               c-0.106,0.028-0.221,0.016-0.357,0c-0.139-0.022-0.297-0.048-0.488-0.031c-0.167,0.017-0.266,0.146-0.342,0.282
               c0.046,0.045,0.129,0.152,0.152,0.242c0.006,0.039,0.029,0.055,0.054,0.076c0.036,0.03,0.083,0.068,0.129,0.153
               c0.045,0.076,0.084,0.197,0.122,0.326c0.038,0.138,0.068,0.298,0.091,0.427c0.015,0.122,0.084,0.205,0.16,0.289
               c0.067,0.066,0.137,0.15,0.183,0.268c0.069,0.182,0.008,0.465-0.037,0.678c-0.016,0.076-0.022,0.138-0.031,0.184
               c-0.016,0.145-0.084,0.168-0.159,0.196c-0.038,0.017-0.083,0.039-0.122,0.077c-0.063,0.053-0.076,0.1-0.092,0.158
               c-0.016,0.055-0.029,0.115-0.084,0.19c-0.068,0.091-0.121,0.244-0.168,0.365c-0.021,0.075-0.053,0.138-0.067,0.176
               c-0.03,0.046-0.054,0.106-0.067,0.183l-0.008,0.008v0.008c-0.016,0.093,0.105,0.115,0.235,0.16
               c0.13,0.047,0.137-0.092,0.305-0.129c0.16-0.031,0.062,0.129,0,0.183c-0.054,0.054-0.099,0.038-0.151,0.151
               c-0.045,0.123-0.129,0.016-0.221,0.029c-0.1,0.01-0.055,0.131-0.084,0.283C141.575,74.244,141.56,74.291,141.545,74.341"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M141.545,74.341c0.319,0.084,0.502,0.267,0.669,0.428c0.047,0.045,0.084,0.084,0.131,0.121c0.137,0.121,0.641,0.449,1.127,0.746
               c0.396,0.234,0.783,0.449,0.959,0.502c0.213,0.076,0.243,0.146,0.219,0.236c-0.014,0.022-0.028,0.053-0.043,0.084
               c-0.03,0.045-0.062,0.1-0.062,0.158c0,0.092,0.067,0.184,0.183,0.282c0.123,0.099,0.28,0.205,0.441,0.306
               c0.204,0.121,0.456,0.365,0.623,0.539c0.023-0.029,0.055-0.053,0.094-0.082c0.281-0.215,0.264-0.549,0.356-0.717
               c0.092-0.168,0.196-0.396,0.267-0.64c0.068-0.253,0.215-0.427,0.32-0.509c0.1-0.078,0.268-0.069,0.434-0.33
               c0.168-0.258,0.215-0.174,0.396-0.311c0.075-0.056,0.13-0.139,0.183-0.238c-0.229-0.182-0.556-0.479-0.609-0.623
               c-0.045-0.129-0.045-0.989-0.035-1.773c0-0.594,0-1.143-0.024-1.294c-0.038-0.319,0.13-0.556,0.289-0.791
               c0.031-0.056,0.062-0.101,0.093-0.146c0.053-0.084,0.105-0.152,0.151-0.207c0.091-0.115,0.144-0.183,0.075-0.258
               c-0.053-0.068-0.159-0.1-0.288-0.13c-0.138-0.03-0.289-0.067-0.442-0.168c-0.175-0.122-0.371,0.101-0.509,0.259
               c-0.056,0.062-0.101,0.114-0.14,0.146c-0.045,0.036-0.09,0.092-0.129,0.151c-0.084,0.115-0.168,0.229-0.326,0.236
               c-0.107,0.008-0.176-0.023-0.252-0.061c-0.075-0.031-0.152-0.068-0.313-0.063c-0.145,0.016-0.326-0.037-0.494-0.121
               c-0.197-0.091-0.39-0.229-0.465-0.32c-0.068-0.084-0.137-0.137-0.221-0.189c-0.092-0.053-0.206-0.1-0.357-0.145
               c-0.129-0.047-0.205-0.047-0.268-0.055c-0.084,0-0.139,0-0.236-0.076c-0.068-0.045-0.167-0.068-0.281-0.076
               c-0.151-0.008-0.313,0.017-0.418,0.048c-0.106,0.028-0.221,0.016-0.357,0c-0.139-0.022-0.297-0.048-0.488-0.031
               c-0.167,0.017-0.266,0.146-0.342,0.282c0.046,0.045,0.129,0.152,0.152,0.242c0.006,0.039,0.029,0.055,0.054,0.076
               c0.036,0.03,0.083,0.068,0.129,0.153c0.045,0.076,0.084,0.197,0.122,0.326c0.038,0.138,0.068,0.298,0.091,0.427
               c0.015,0.122,0.084,0.205,0.16,0.289c0.067,0.066,0.137,0.15,0.183,0.268c0.069,0.182,0.008,0.465-0.037,0.678
               c-0.016,0.076-0.022,0.138-0.031,0.184c-0.016,0.145-0.084,0.168-0.159,0.196c-0.038,0.017-0.083,0.039-0.122,0.077
               c-0.063,0.053-0.076,0.1-0.092,0.158c-0.016,0.055-0.029,0.115-0.084,0.19c-0.068,0.091-0.121,0.244-0.168,0.365
               c-0.021,0.075-0.053,0.138-0.067,0.176c-0.03,0.046-0.054,0.106-0.067,0.183l-0.008,0.008v0.008
               c-0.016,0.093,0.105,0.115,0.235,0.16c0.13,0.047,0.137-0.092,0.305-0.129c0.16-0.031,0.062,0.129,0,0.183
               c-0.054,0.054-0.099,0.038-0.151,0.151c-0.045,0.123-0.129,0.016-0.221,0.029c-0.1,0.01-0.055,0.131-0.084,0.283
               C141.575,74.244,141.56,74.291,141.545,74.341z"/>
             <path fill="#808184" d="M137.935,74.593c0.094-0.047,0.152-0.039,0.229-0.031
               c0.062,0.009,0.13,0.018,0.229-0.008c0.137-0.03,0.144-0.045,0.151-0.064c0.007-0.041,0.015-0.078,0.084-0.115
               c0.067-0.047,0.501-0.063,1.013-0.063c-0.047-0.062-0.022-0.196,0-0.267c0.022-0.076,0.1-0.168,0.205-0.252
               c0.106-0.084-0.037-0.129,0-0.242c0.029-0.123,0.159-0.031,0.281-0.115c0.129-0.084,0.129-0.195,0.168-0.234
               c0,0,0.038-0.092,0.129-0.084c0.092,0.008,0.047,0.16,0.145,0.146c0.1-0.015,0.207-0.19,0.32-0.214
               c0.113-0.022,0.113,0.183,0.191,0.152c0.066-0.031,0.167,0.045,0.25,0.062c0.037,0.008,0.084,0.045,0.122,0.09
               c0.015-0.028,0.022-0.054,0.038-0.074c0.016-0.03,0.037-0.092,0.061-0.168c0.055-0.122,0.107-0.282,0.184-0.389
               c0.039-0.062,0.055-0.114,0.068-0.16c0.022-0.069,0.047-0.129,0.121-0.206c0.055-0.053,0.107-0.074,0.152-0.091
               c0.047-0.022,0.084-0.039,0.099-0.121c0-0.046,0.017-0.115,0.031-0.19c0.046-0.199,0.104-0.465,0.046-0.625
               c-0.039-0.092-0.107-0.168-0.168-0.229c-0.084-0.1-0.168-0.189-0.189-0.342c-0.017-0.131-0.045-0.281-0.084-0.42
               c-0.031-0.121-0.068-0.228-0.105-0.297c-0.04-0.068-0.077-0.099-0.107-0.121c-0.045-0.039-0.076-0.068-0.091-0.137
               c-0.016-0.054-0.069-0.131-0.106-0.176c-0.068,0.122-0.131,0.229-0.221,0.26c-0.068,0.014-0.099,0.037-0.131,0.053
               c-0.051,0.037-0.083,0.063-0.229,0.037l-0.053-0.015c-0.146-0.022-0.19-0.03-0.365,0.009c-0.068,0.016-0.146,0.037-0.221,0.066
               c-0.16,0.057-0.313,0.107-0.412,0.076c-0.029-0.014-0.053-0.02-0.076-0.028c-0.075-0.032-0.137-0.063-0.35-0.063
               c-0.105,0-0.146,0.016-0.168,0.023c-0.047,0.016-0.068,0.021-0.221-0.023c-0.016,0.047-0.046,0.123-0.023,0.191
               c0.03,0.092,0.017,0.131,0,0.197c-0.016,0.029-0.021,0.068-0.029,0.141c-0.016,0.088,0.008,0.143,0.021,0.195
               c0.023,0.045,0.031,0.084,0.018,0.136l-0.01,0.022c-0.021,0.068-0.074,0.213,0,0.259c0.03,0.03,0.084,0.056,0.129,0.077
               c0.047,0.015,0.094,0.037,0.123,0.061c0.062,0.041,0.092,0.084,0.055,0.145c-0.018,0.016-0.018,0.054-0.024,0.107
               c-0.007,0.113-0.015,0.258-0.129,0.357c-0.084,0.068-0.175,0.15-0.259,0.229c-0.077,0.078-0.145,0.146-0.176,0.184
               c-0.03,0.068-0.1,0.121-0.184,0.183c-0.053,0.048-0.113,0.093-0.167,0.152c-0.122,0.114-0.122,0.196-0.13,0.305v0.022
               c0,0.054-0.021,0.121-0.038,0.183c-0.03,0.084-0.067,0.151-0.091,0.177c-0.008,0-0.008,0.053-0.016,0.137
               c-0.008,0.092-0.016,0.213-0.039,0.352c-0.021,0.104-0.036,0.221-0.053,0.326c-0.016,0.146-0.021,0.268-0.021,0.305
               C137.914,74.49,137.92,74.546,137.935,74.593"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M137.935,74.593c0.094-0.047,0.152-0.039,0.229-0.031c0.062,0.009,0.13,0.018,0.229-0.008c0.137-0.03,0.144-0.045,0.151-0.064
               c0.007-0.041,0.015-0.078,0.084-0.115c0.067-0.047,0.501-0.063,1.013-0.063c-0.047-0.062-0.022-0.196,0-0.267
               c0.022-0.076,0.1-0.168,0.205-0.252c0.106-0.084-0.037-0.129,0-0.242c0.029-0.123,0.159-0.031,0.281-0.115
               c0.129-0.084,0.129-0.195,0.168-0.234c0,0,0.038-0.092,0.129-0.084c0.092,0.008,0.047,0.16,0.145,0.146
               c0.1-0.015,0.207-0.19,0.32-0.214c0.113-0.022,0.113,0.183,0.191,0.152c0.066-0.031,0.167,0.045,0.25,0.062
               c0.037,0.008,0.084,0.045,0.122,0.09c0.015-0.028,0.022-0.054,0.038-0.074c0.016-0.03,0.037-0.092,0.061-0.168
               c0.055-0.122,0.107-0.282,0.184-0.389c0.039-0.062,0.055-0.114,0.068-0.16c0.022-0.069,0.047-0.129,0.121-0.206
               c0.055-0.053,0.107-0.074,0.152-0.091c0.047-0.022,0.084-0.039,0.099-0.121c0-0.046,0.017-0.115,0.031-0.19
               c0.046-0.199,0.104-0.465,0.046-0.625c-0.039-0.092-0.107-0.168-0.168-0.229c-0.084-0.1-0.168-0.189-0.189-0.342
               c-0.017-0.131-0.045-0.281-0.084-0.42c-0.031-0.121-0.068-0.228-0.105-0.297c-0.04-0.068-0.077-0.099-0.107-0.121
               c-0.045-0.039-0.076-0.068-0.091-0.137c-0.016-0.054-0.069-0.131-0.106-0.176c-0.068,0.122-0.131,0.229-0.221,0.26
               c-0.068,0.014-0.099,0.037-0.131,0.053c-0.051,0.037-0.083,0.063-0.229,0.037l-0.053-0.015c-0.146-0.022-0.19-0.03-0.365,0.009
               c-0.068,0.016-0.146,0.037-0.221,0.066c-0.16,0.057-0.313,0.107-0.412,0.076c-0.029-0.014-0.053-0.02-0.076-0.028
               c-0.075-0.032-0.137-0.063-0.35-0.063c-0.105,0-0.146,0.016-0.168,0.023c-0.047,0.016-0.068,0.021-0.221-0.023
               c-0.016,0.047-0.046,0.123-0.023,0.191c0.03,0.092,0.017,0.131,0,0.197c-0.016,0.029-0.021,0.068-0.029,0.141
               c-0.016,0.088,0.008,0.143,0.021,0.195c0.023,0.045,0.031,0.084,0.018,0.136l-0.01,0.022c-0.021,0.068-0.074,0.213,0,0.259
               c0.03,0.03,0.084,0.056,0.129,0.077c0.047,0.015,0.094,0.037,0.123,0.061c0.062,0.041,0.092,0.084,0.055,0.145
               c-0.018,0.016-0.018,0.054-0.024,0.107c-0.007,0.113-0.015,0.258-0.129,0.357c-0.084,0.068-0.175,0.15-0.259,0.229
               c-0.077,0.078-0.145,0.146-0.176,0.184c-0.03,0.068-0.1,0.121-0.184,0.183c-0.053,0.048-0.113,0.093-0.167,0.152
               c-0.122,0.114-0.122,0.196-0.13,0.305v0.022c0,0.054-0.021,0.121-0.038,0.183c-0.03,0.084-0.067,0.151-0.091,0.177
               c-0.008,0-0.008,0.053-0.016,0.137c-0.008,0.092-0.016,0.213-0.039,0.352c-0.021,0.104-0.036,0.221-0.053,0.326
               c-0.016,0.146-0.021,0.268-0.021,0.305C137.914,74.49,137.92,74.546,137.935,74.593z"/>
             <path fill="#808184" d="M123.53,78.857c0,0.084,0.007,0.168,0.029,0.236
               c0.062,0,0.138-0.008,0.222-0.022c0.091-0.016,0.19-0.046,0.281-0.101c0.061-0.029,0.1-0.061,0.122-0.084
               c0.092-0.067,0.114-0.082,0.426-0.037c0.184,0.027,0.488,0.047,0.8,0.063c0.358,0.014,0.731,0.021,0.93,0.008
               c0.121,0,0.235-0.041,0.334-0.063c0.198-0.061,0.351-0.1,0.474,0.076l0.029,0.037c0.16,0.229,0.305,0.436,0.274,0.717
               c-0.017,0.145,0.03,0.205,0.075,0.268c0.039,0.051,0.076,0.105,0.1,0.213c0.023,0.099,0.076,0.22,0.13,0.342
               c0.062,0.145,0.129,0.283,0.175,0.343c0.016,0.017,0.022,0.038,0.03,0.054c0.054,0.077,0.099,0.153,0.267,0.069
               c0.092-0.046,0.131-0.076,0.16-0.101c0.062-0.054,0.076-0.067,0.26-0.039c0.037,0,0.09,0.008,0.137,0.024
               c0.184,0.022,0.313,0.046,0.434,0.022c0.086-0.016,0.1-0.039,0.139-0.131c0.015-0.045,0.037-0.1,0.076-0.174
               c0.029-0.068,0.053-0.115,0.067-0.146c0.054-0.106,0.069-0.13,0.062-0.205c-0.008-0.017-0.008-0.03-0.008-0.046
               c-0.016-0.131-0.029-0.251,0.129-0.275c0.055-0.007,0.152-0.007,0.252,0c0.146,0,0.297,0.01,0.342-0.015
               c0.055-0.022,0.106-0.045,0.152-0.045c0.062-0.007,0.106,0.022,0.123,0.112c0.008,0.069-0.039,0.123-0.076,0.168
               c-0.016,0.016-0.023,0.031-0.031,0.045c0,0,0-0.006,0.017,0c0.045,0.024,0.204,0.016,0.38,0.01
               c0.252-0.01,0.525-0.023,0.594,0.061c0.047,0.068,0.069,0.129,0.069,0.19c0.009,0.062-0.008,0.12-0.038,0.19
               c-0.031,0.099,0,0.196,0.038,0.312c0.009,0.022,0.015,0.047,0.021,0.067l0.009,0.039c0.046,0.152,0.091,0.289,0.03,0.373
               c-0.03,0.037-0.099,0.221-0.112,0.448c-0.011,0.138,0.008,0.291,0.067,0.421c0.045,0.105,0.076,0.168,0.099,0.227
               c0.106,0.23,0.159,0.359,0.204,0.512c0.033,0.113,0.07,0.26,0.078,0.373c0,0.1-0.008,0.184-0.062,0.221
               c-0.017,0.016-0.03,0.045-0.038,0.084c-0.009,0.037-0.016,0.076-0.009,0.106c0,0.028,0.009,0.062,0.024,0.075
               c0.006,0.009,0.015,0.017,0.029,0.017c0.029,0.007,0.045-0.008,0.061-0.03c0.031-0.054,0.076-0.115,0.189-0.115
               c0.092,0,0.16,0.017,0.229,0.031c0.067,0.016,0.137,0.03,0.196,0.016c0.045-0.008,0.123-0.037,0.207-0.068
               c0.183-0.063,0.41-0.137,0.519-0.137c0.073,0,0.136,0.037,0.196,0.099c0.084,0.076,0.146,0.183,0.168,0.235
               c0.016,0.046-0.008,0.105-0.031,0.158c-0.016,0.039-0.027,0.078-0.027,0.086s0.02,0,0.074-0.016
               c0.1-0.023,0.189-0.076,0.273-0.13c0.16-0.092,0.289-0.167,0.418-0.03c0.093,0.101,0.115,0.213,0.129,0.313
               c0.01,0.068,0.018,0.122,0.056,0.152c0.021,0.016,0.062,0.008,0.104,0c0.084-0.022,0.207-0.053,0.404,0.054
               c0.039,0.015,0.068,0.03,0.1,0.046c0.197,0.105,0.242,0.13,0.365,0.076c0.045-0.021,0.105-0.122,0.16-0.223
               c0.061-0.1,0.123-0.188,0.182-0.213c0.063-0.021,0.107,0.008,0.146,0.1c0.017,0.068,0.031,0.138,0.054,0.207
               c0.047,0.197,0.092,0.402,0.289,0.439c0.1,0.014,0.184,0.014,0.252,0.014c0.146,0,0.235,0,0.313,0.146
               c0.046,0.084,0.107,0.189,0.16,0.282c0.061,0.101,0.113,0.183,0.145,0.243v0.008l0.016,0.029c0.045,0.106,0.115,0.26,0.205,0.26
               c0.039,0,0.063-0.016,0.084-0.038c0.045-0.045,0.1-0.091,0.244-0.062c0.137,0.03,0.146,0.03,0.176-0.129l0.007-0.047
               c0.038-0.15,0.084-0.427,0.077-0.609c-0.01-0.045-0.017-0.09-0.023-0.112s-0.016-0.03-0.023-0.03h-0.006
               c-0.068,0-0.084,0.023-0.1,0.045c-0.039,0.047-0.068,0.084-0.189,0.077c-0.102-0.008-0.213-0.022-0.306-0.053
               c-0.083-0.024-0.151-0.056-0.198-0.101c-0.053-0.045-0.234-0.259-0.351-0.441c-0.065-0.122-0.113-0.229-0.093-0.299
               c0.017-0.035,0.031-0.074,0.055-0.113c0.055-0.105,0.114-0.234,0.16-0.434c0.031-0.121,0.016-0.25,0-0.373
               c-0.016-0.129-0.031-0.252,0.016-0.365c0.055-0.129,0.022-0.268,0-0.404c-0.016-0.045-0.021-0.1-0.029-0.145
               c0-0.039-0.008-0.062-0.018-0.084c-0.021-0.104-0.029-0.137,0.123-0.318c0.061-0.076,0.114-0.176,0.159-0.266
               c0.093-0.162,0.168-0.299,0.29-0.321c0.045-0.009,0.063-0.009,0.074-0.015c0.048-0.016,0.084-0.039,0.32-0.053
               c0.185-0.009,0.42-0.056,0.618-0.084c0.036-0.009,0.083-0.017,0.113-0.025c0.022-0.059,0.045-0.104,0.03-0.112
               c-0.03-0.03-0.107-0.054-0.168-0.177c-0.053-0.112-0.061-0.234-0.045-0.395c0.015-0.16-0.068-0.169-0.199-0.244
               c-0.129-0.076-0.174-0.106-0.273-0.328c-0.1-0.228-0.326-0.607-0.365-0.653c-0.031-0.046-0.037-0.183,0.022-0.267
               c0.062-0.084,0.062-0.242,0.017-0.39c-0.046-0.151-0.101-0.289-0.106-0.464c-0.017-0.176-0.054-0.221-0.022-0.396
               c0.022-0.183,0.029-0.229-0.031-0.418c-0.037-0.131,0.008-0.275,0.055-0.357c-0.008-0.055-0.008-0.105-0.016-0.16
               c0-0.105-0.008-0.229-0.039-0.344c-0.022-0.106-0.053-0.184-0.082-0.26c-0.047-0.113-0.084-0.213-0.084-0.355
               c0-0.161,0.053-0.207,0.105-0.26c0.03-0.03,0.055-0.063,0.076-0.106v-0.008c0.021-0.062,0.016-0.12,0.008-0.183
               c-0.008-0.076-0.018-0.146,0.03-0.236c0.03-0.076,0.067-0.137,0.12-0.189c0.063-0.054,0.131-0.113,0.236-0.184
               c-0.015-0.045-0.037-0.137-0.037-0.205c0-0.045,0.008-0.168,0.029-0.318c0.008-0.106,0.031-0.223,0.047-0.336
               c0.03-0.129,0.037-0.242,0.045-0.334c0.008-0.102,0.008-0.168,0.039-0.199c0.016-0.016,0.045-0.076,0.067-0.137
               c0.017-0.053,0.03-0.115,0.038-0.16v-0.022c0-0.121,0.008-0.228,0.152-0.373c0.062-0.06,0.121-0.106,0.183-0.151
               c0.069-0.062,0.129-0.107,0.159-0.152c0.031-0.055,0.109-0.131,0.189-0.215c0.088-0.074,0.176-0.158,0.26-0.228
               c0.084-0.076,0.092-0.198,0.1-0.29c0.009-0.061,0.009-0.12,0.039-0.158c0,0-0.016,0.008-0.024,0
               c-0.03-0.017-0.067-0.039-0.104-0.054c-0.055-0.022-0.114-0.056-0.152-0.084c-0.13-0.093-0.068-0.282-0.027-0.381l0.004-0.017
               c0-0.016-0.004-0.045-0.016-0.074c-0.021-0.055-0.053-0.131-0.037-0.244c0.016-0.069,0.03-0.115,0.037-0.152
               c0.016-0.046,0.023-0.076,0-0.138c-0.029-0.091,0-0.197,0.031-0.259c-0.152-0.067-0.313-0.205-0.441-0.319
               c-0.084-0.067-0.152-0.129-0.213-0.168c-0.084-0.06-0.145-0.091-0.207-0.099c-0.052-0.016-0.099-0.008-0.15,0.015
               c-0.076,0.024-0.152,0.01-0.236-0.015c-0.084-0.016-0.176-0.039-0.281-0.022c-0.076,0.008-0.113,0.022-0.16,0.03
               c-0.076,0.031-0.145,0.053-0.389,0.017c-0.29-0.047-0.463-0.344-0.57-0.526c-0.037-0.054-0.061-0.1-0.076-0.105
               c-0.029-0.031-0.084-0.055-0.151-0.055c-0.076-0.008-0.16,0-0.259,0.031c-0.207,0.061-0.229,0.045-0.327-0.022
               c-0.017-0.017-0.039-0.038-0.068-0.056c-0.077-0.053-0.122-0.037-0.19-0.021c-0.047,0.008-0.105,0.021-0.189,0.021
               c-0.122,0-0.168-0.028-0.234-0.074c-0.056-0.047-0.115-0.092-0.27-0.131c-0.15-0.045-0.189,0.1-0.213,0.205
               c-0.021,0.094-0.037,0.168-0.113,0.186c-0.063,0.007-0.137,0.007-0.221-0.01c-0.092-0.016-0.198-0.045-0.336-0.09
               c-0.145-0.047-0.252,0.045-0.352,0.145c-0.066,0.055-0.129,0.105-0.197,0.145c-0.184,0.076-0.273,0.054-0.434,0.017
               c-0.023-0.009-0.054-0.017-0.076-0.022c-0.138-0.031-0.236,0.129-0.336,0.297c-0.015,0.016-0.029,0.037-0.052,0.076
               c-0.131,0.197-0.19,0.197-0.319,0.19h-0.055c-0.076,0-0.13-0.022-0.189-0.054c-0.053-0.023-0.129-0.063-0.267-0.069
               c-0.146-0.009-0.313-0.038-0.487-0.099c-0.139-0.045-0.281-0.113-0.412-0.207c-0.176-0.129-0.289-0.233-0.389-0.334
               c-0.063-0.053-0.121-0.113-0.189-0.168c-0.075-0.062-0.121-0.029-0.221,0.047c-0.084,0.061-0.205,0.145-0.404,0.236
               c-0.318,0.143-0.303,0.531-0.289,0.782c0.009,0.099,0.009,0.177-0.008,0.229c-0.015,0.061-0.037,0.1-0.076,0.129
               c0.017,0.046,0.039,0.113,0.039,0.205c0,0.076,0,0.145-0.016,0.213c-0.016,0.076-0.046,0.152-0.084,0.236
               c-0.037,0.084-0.068,0.146-0.113,0.229c-0.053,0.084-0.123,0.189-0.235,0.365c-0.13,0.197-0.101,0.381-0.077,0.549
               c0.021,0.121,0.038,0.234-0.007,0.344c-0.046,0.1-0.046,0.189-0.039,0.273c0,0.113,0,0.213-0.067,0.326
               c-0.076,0.121-0.062,0.289-0.031,0.512c0.009,0.09,0.023,0.189,0.023,0.304c0.021,0.335-0.054,0.396-0.305,0.571
               c-0.055,0.037-0.114,0.084-0.184,0.136c-0.166,0.132-0.297,0.261-0.389,0.381c-0.107,0.132-0.16,0.237-0.152,0.29
               c0.022,0.122-0.054,0.184-0.175,0.273c-0.015,0.018-0.038,0.031-0.054,0.047c-0.068,0.045-0.068,0.099-0.061,0.16
               c0,0.045,0,0.092-0.017,0.152c-0.022,0.074-0.008,0.151,0,0.258c0.009,0.055,0.022,0.121,0.022,0.197
               c0.009,0.105,0,0.245-0.022,0.381c-0.03,0.168-0.067,0.352-0.106,0.457c-0.045,0.151-0.076,0.16-0.16,0.19
               c-0.029,0.008-0.075,0.022-0.145,0.062c-0.121,0.084-0.274,0.242-0.396,0.373c-0.054,0.061-0.099,0.106-0.138,0.145
               c-0.061,0.063-0.084,0.099-0.099,0.146c-0.023,0.045-0.046,0.09-0.114,0.15c-0.145,0.131-0.167,0.1-0.282-0.021l-0.015-0.022
               c-0.069-0.069-0.069-0.19-0.069-0.304c0-0.101,0-0.201-0.029-0.223c-0.023-0.016-0.054,0.016-0.1,0.047
               c-0.061,0.053-0.137,0.121-0.267,0.121c-0.106,0-0.151,0.092-0.19,0.176c-0.029,0.06-0.053,0.105-0.106,0.137h-0.007
               c-0.047,0.023-0.122,0.037-0.198,0.029c-0.068-0.015-0.13-0.045-0.175-0.106l-0.122,0.077c0,0-0.29,0.175-0.236,0.229
               c0.046,0.061,0.061,0.184,0.054,0.305c-0.016,0.16-0.062,0.326-0.107,0.365C123.826,78.865,123.674,78.865,123.53,78.857"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M123.53,78.857c0,0.084,0.007,0.168,0.029,0.236c0.062,0,0.138-0.008,0.222-0.022c0.091-0.016,0.19-0.046,0.281-0.101
               c0.061-0.029,0.1-0.061,0.122-0.084c0.092-0.067,0.114-0.082,0.426-0.037c0.184,0.027,0.488,0.047,0.8,0.063
               c0.358,0.014,0.731,0.021,0.93,0.008c0.121,0,0.235-0.041,0.334-0.063c0.198-0.061,0.351-0.1,0.474,0.076l0.029,0.037
               c0.16,0.229,0.305,0.436,0.274,0.717c-0.017,0.145,0.03,0.205,0.075,0.268c0.039,0.051,0.076,0.105,0.1,0.213
               c0.023,0.099,0.076,0.22,0.13,0.342c0.062,0.145,0.129,0.283,0.175,0.343c0.016,0.017,0.022,0.038,0.03,0.054
               c0.054,0.077,0.099,0.153,0.267,0.069c0.092-0.046,0.131-0.076,0.16-0.101c0.062-0.054,0.076-0.067,0.26-0.039
               c0.037,0,0.09,0.008,0.137,0.024c0.184,0.022,0.313,0.046,0.434,0.022c0.086-0.016,0.1-0.039,0.139-0.131
               c0.015-0.045,0.037-0.1,0.076-0.174c0.029-0.068,0.053-0.115,0.067-0.146c0.054-0.106,0.069-0.13,0.062-0.205
               c-0.008-0.017-0.008-0.03-0.008-0.046c-0.016-0.131-0.029-0.251,0.129-0.275c0.055-0.007,0.152-0.007,0.252,0
               c0.146,0,0.297,0.01,0.342-0.015c0.055-0.022,0.106-0.045,0.152-0.045c0.062-0.007,0.106,0.022,0.123,0.112
               c0.008,0.069-0.039,0.123-0.076,0.168c-0.016,0.016-0.023,0.031-0.031,0.045c0,0,0-0.006,0.017,0
               c0.045,0.024,0.204,0.016,0.38,0.01c0.252-0.01,0.525-0.023,0.594,0.061c0.047,0.068,0.069,0.129,0.069,0.19
               c0.009,0.062-0.008,0.12-0.038,0.19c-0.031,0.099,0,0.196,0.038,0.312c0.009,0.022,0.015,0.047,0.021,0.067l0.009,0.039
               c0.046,0.152,0.091,0.289,0.03,0.373c-0.03,0.037-0.099,0.221-0.112,0.448c-0.011,0.138,0.008,0.291,0.067,0.421
               c0.045,0.105,0.076,0.168,0.099,0.227c0.106,0.23,0.159,0.359,0.204,0.512c0.033,0.113,0.07,0.26,0.078,0.373
               c0,0.1-0.008,0.184-0.062,0.221c-0.017,0.016-0.03,0.045-0.038,0.084c-0.009,0.037-0.016,0.076-0.009,0.106
               c0,0.028,0.009,0.062,0.024,0.075c0.006,0.009,0.015,0.017,0.029,0.017c0.029,0.007,0.045-0.008,0.061-0.03
               c0.031-0.054,0.076-0.115,0.189-0.115c0.092,0,0.16,0.017,0.229,0.031c0.067,0.016,0.137,0.03,0.196,0.016
               c0.045-0.008,0.123-0.037,0.207-0.068c0.183-0.063,0.41-0.137,0.519-0.137c0.073,0,0.136,0.037,0.196,0.099
               c0.084,0.076,0.146,0.183,0.168,0.235c0.016,0.046-0.008,0.105-0.031,0.158c-0.016,0.039-0.027,0.078-0.027,0.086
               s0.02,0,0.074-0.016c0.1-0.023,0.189-0.076,0.273-0.13c0.16-0.092,0.289-0.167,0.418-0.03c0.093,0.101,0.115,0.213,0.129,0.313
               c0.01,0.068,0.018,0.122,0.056,0.152c0.021,0.016,0.062,0.008,0.104,0c0.084-0.022,0.207-0.053,0.404,0.054
               c0.039,0.015,0.068,0.03,0.1,0.046c0.197,0.105,0.242,0.13,0.365,0.076c0.045-0.021,0.105-0.122,0.16-0.223
               c0.061-0.1,0.123-0.188,0.182-0.213c0.063-0.021,0.107,0.008,0.146,0.1c0.017,0.068,0.031,0.138,0.054,0.207
               c0.047,0.197,0.092,0.402,0.289,0.439c0.1,0.014,0.184,0.014,0.252,0.014c0.146,0,0.235,0,0.313,0.146
               c0.046,0.084,0.107,0.189,0.16,0.282c0.061,0.101,0.113,0.183,0.145,0.243v0.008l0.016,0.029c0.045,0.106,0.115,0.26,0.205,0.26
               c0.039,0,0.063-0.016,0.084-0.038c0.045-0.045,0.1-0.091,0.244-0.062c0.137,0.03,0.146,0.03,0.176-0.129l0.007-0.047
               c0.038-0.15,0.084-0.427,0.077-0.609c-0.01-0.045-0.017-0.09-0.023-0.112s-0.016-0.03-0.023-0.03h-0.006
               c-0.068,0-0.084,0.023-0.1,0.045c-0.039,0.047-0.068,0.084-0.189,0.077c-0.102-0.008-0.213-0.022-0.306-0.053
               c-0.083-0.024-0.151-0.056-0.198-0.101c-0.053-0.045-0.234-0.259-0.351-0.441c-0.065-0.122-0.113-0.229-0.093-0.299
               c0.017-0.035,0.031-0.074,0.055-0.113c0.055-0.105,0.114-0.234,0.16-0.434c0.031-0.121,0.016-0.25,0-0.373
               c-0.016-0.129-0.031-0.252,0.016-0.365c0.055-0.129,0.022-0.268,0-0.404c-0.016-0.045-0.021-0.1-0.029-0.145
               c0-0.039-0.008-0.062-0.018-0.084c-0.021-0.104-0.029-0.137,0.123-0.318c0.061-0.076,0.114-0.176,0.159-0.266
               c0.093-0.162,0.168-0.299,0.29-0.321c0.045-0.009,0.063-0.009,0.074-0.015c0.048-0.016,0.084-0.039,0.32-0.053
               c0.185-0.009,0.42-0.056,0.618-0.084c0.036-0.009,0.083-0.017,0.113-0.025c0.022-0.059,0.045-0.104,0.03-0.112
               c-0.03-0.03-0.107-0.054-0.168-0.177c-0.053-0.112-0.061-0.234-0.045-0.395c0.015-0.16-0.068-0.169-0.199-0.244
               c-0.129-0.076-0.174-0.106-0.273-0.328c-0.1-0.228-0.326-0.607-0.365-0.653c-0.031-0.046-0.037-0.183,0.022-0.267
               c0.062-0.084,0.062-0.242,0.017-0.39c-0.046-0.151-0.101-0.289-0.106-0.464c-0.017-0.176-0.054-0.221-0.022-0.396
               c0.022-0.183,0.029-0.229-0.031-0.418c-0.037-0.131,0.008-0.275,0.055-0.357c-0.008-0.055-0.008-0.105-0.016-0.16
               c0-0.105-0.008-0.229-0.039-0.344c-0.022-0.106-0.053-0.184-0.082-0.26c-0.047-0.113-0.084-0.213-0.084-0.355
               c0-0.161,0.053-0.207,0.105-0.26c0.03-0.03,0.055-0.063,0.076-0.106v-0.008c0.021-0.062,0.016-0.12,0.008-0.183
               c-0.008-0.076-0.018-0.146,0.03-0.236c0.03-0.076,0.067-0.137,0.12-0.189c0.063-0.054,0.131-0.113,0.236-0.184
               c-0.015-0.045-0.037-0.137-0.037-0.205c0-0.045,0.008-0.168,0.029-0.318c0.008-0.106,0.031-0.223,0.047-0.336
               c0.03-0.129,0.037-0.242,0.045-0.334c0.008-0.102,0.008-0.168,0.039-0.199c0.016-0.016,0.045-0.076,0.067-0.137
               c0.017-0.053,0.03-0.115,0.038-0.16v-0.022c0-0.121,0.008-0.228,0.152-0.373c0.062-0.06,0.121-0.106,0.183-0.151
               c0.069-0.062,0.129-0.107,0.159-0.152c0.031-0.055,0.109-0.131,0.189-0.215c0.088-0.074,0.176-0.158,0.26-0.228
               c0.084-0.076,0.092-0.198,0.1-0.29c0.009-0.061,0.009-0.12,0.039-0.158c0,0-0.016,0.008-0.024,0
               c-0.03-0.017-0.067-0.039-0.104-0.054c-0.055-0.022-0.114-0.056-0.152-0.084c-0.13-0.093-0.068-0.282-0.027-0.381l0.004-0.017
               c0-0.016-0.004-0.045-0.016-0.074c-0.021-0.055-0.053-0.131-0.037-0.244c0.016-0.069,0.03-0.115,0.037-0.152
               c0.016-0.046,0.023-0.076,0-0.138c-0.029-0.091,0-0.197,0.031-0.259c-0.152-0.067-0.313-0.205-0.441-0.319
               c-0.084-0.067-0.152-0.129-0.213-0.168c-0.084-0.06-0.145-0.091-0.207-0.099c-0.052-0.016-0.099-0.008-0.15,0.015
               c-0.076,0.024-0.152,0.01-0.236-0.015c-0.084-0.016-0.176-0.039-0.281-0.022c-0.076,0.008-0.113,0.022-0.16,0.03
               c-0.076,0.031-0.145,0.053-0.389,0.017c-0.29-0.047-0.463-0.344-0.57-0.526c-0.037-0.054-0.061-0.1-0.076-0.105
               c-0.029-0.031-0.084-0.055-0.151-0.055c-0.076-0.008-0.16,0-0.259,0.031c-0.207,0.061-0.229,0.045-0.327-0.022
               c-0.017-0.017-0.039-0.038-0.068-0.056c-0.077-0.053-0.122-0.037-0.19-0.021c-0.047,0.008-0.105,0.021-0.189,0.021
               c-0.122,0-0.168-0.028-0.234-0.074c-0.056-0.047-0.115-0.092-0.27-0.131c-0.15-0.045-0.189,0.1-0.213,0.205
               c-0.021,0.094-0.037,0.168-0.113,0.186c-0.063,0.007-0.137,0.007-0.221-0.01c-0.092-0.016-0.198-0.045-0.336-0.09
               c-0.145-0.047-0.252,0.045-0.352,0.145c-0.066,0.055-0.129,0.105-0.197,0.145c-0.184,0.076-0.273,0.054-0.434,0.017
               c-0.023-0.009-0.054-0.017-0.076-0.022c-0.138-0.031-0.236,0.129-0.336,0.297c-0.015,0.016-0.029,0.037-0.052,0.076
               c-0.131,0.197-0.19,0.197-0.319,0.19h-0.055c-0.076,0-0.13-0.022-0.189-0.054c-0.053-0.023-0.129-0.063-0.267-0.069
               c-0.146-0.009-0.313-0.038-0.487-0.099c-0.139-0.045-0.281-0.113-0.412-0.207c-0.176-0.129-0.289-0.233-0.389-0.334
               c-0.063-0.053-0.121-0.113-0.189-0.168c-0.075-0.062-0.121-0.029-0.221,0.047c-0.084,0.061-0.205,0.145-0.404,0.236
               c-0.318,0.143-0.303,0.531-0.289,0.782c0.009,0.099,0.009,0.177-0.008,0.229c-0.015,0.061-0.037,0.1-0.076,0.129
               c0.017,0.046,0.039,0.113,0.039,0.205c0,0.076,0,0.145-0.016,0.213c-0.016,0.076-0.046,0.152-0.084,0.236
               c-0.037,0.084-0.068,0.146-0.113,0.229c-0.053,0.084-0.123,0.189-0.235,0.365c-0.13,0.197-0.101,0.381-0.077,0.549
               c0.021,0.121,0.038,0.234-0.007,0.344c-0.046,0.1-0.046,0.189-0.039,0.273c0,0.113,0,0.213-0.067,0.326
               c-0.076,0.121-0.062,0.289-0.031,0.512c0.009,0.09,0.023,0.189,0.023,0.304c0.021,0.335-0.054,0.396-0.305,0.571
               c-0.055,0.037-0.114,0.084-0.184,0.136c-0.166,0.132-0.297,0.261-0.389,0.381c-0.107,0.132-0.16,0.237-0.152,0.29
               c0.022,0.122-0.054,0.184-0.175,0.273c-0.015,0.018-0.038,0.031-0.054,0.047c-0.068,0.045-0.068,0.099-0.061,0.16
               c0,0.045,0,0.092-0.017,0.152c-0.022,0.074-0.008,0.151,0,0.258c0.009,0.055,0.022,0.121,0.022,0.197
               c0.009,0.105,0,0.245-0.022,0.381c-0.03,0.168-0.067,0.352-0.106,0.457c-0.045,0.151-0.076,0.16-0.16,0.19
               c-0.029,0.008-0.075,0.022-0.145,0.062c-0.121,0.084-0.274,0.242-0.396,0.373c-0.054,0.061-0.099,0.106-0.138,0.145
               c-0.061,0.063-0.084,0.099-0.099,0.146c-0.023,0.045-0.046,0.09-0.114,0.15c-0.145,0.131-0.167,0.1-0.282-0.021l-0.015-0.022
               c-0.069-0.069-0.069-0.19-0.069-0.304c0-0.101,0-0.201-0.029-0.223c-0.023-0.016-0.054,0.016-0.1,0.047
               c-0.061,0.053-0.137,0.121-0.267,0.121c-0.106,0-0.151,0.092-0.19,0.176c-0.029,0.06-0.053,0.105-0.106,0.137h-0.007
               c-0.047,0.023-0.122,0.037-0.198,0.029c-0.068-0.015-0.13-0.045-0.175-0.106l-0.122,0.077c0,0-0.29,0.175-0.236,0.229
               c0.046,0.061,0.061,0.184,0.054,0.305c-0.016,0.16-0.062,0.326-0.107,0.365C123.826,78.865,123.674,78.865,123.53,78.857z"/>
             <path fill="#808184" d="M123.347,78.186l0.031,0.056c0.137,0.234,0.145,0.196,0.145,0.402
               c0,0.037,0,0.076,0.008,0.113c0.121,0.008,0.251,0.008,0.266-0.01c0.03-0.021,0.062-0.158,0.068-0.295
               c0.008-0.099,0.008-0.197-0.022-0.228c-0.13-0.146,0.259-0.39,0.259-0.39l0.121-0.078v-0.014
               c-0.029-0.031-0.053-0.061-0.083-0.068c-0.038-0.008-0.068-0.008-0.114,0.016c-0.022,0.008-0.037,0.016-0.053,0.03
               C123.857,77.798,123.553,78.027,123.347,78.186"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M123.347,78.186l0.031,0.056c0.137,0.234,0.145,0.196,0.145,0.402c0,0.037,0,0.076,0.008,0.113
               c0.121,0.008,0.251,0.008,0.266-0.01c0.03-0.021,0.062-0.158,0.068-0.295c0.008-0.099,0.008-0.197-0.022-0.228
               c-0.13-0.146,0.259-0.39,0.259-0.39l0.121-0.078v-0.014c-0.029-0.031-0.053-0.061-0.083-0.068
               c-0.038-0.008-0.068-0.008-0.114,0.016c-0.022,0.008-0.037,0.016-0.053,0.03C123.857,77.798,123.553,78.027,123.347,78.186z"/>
             <path fill="#808184" d="M122.685,77.089c0.16,0.185,0.435,0.48,0.479,0.68
               c0.046,0.166,0.068,0.214,0.13,0.326c0.205-0.16,0.502-0.381,0.616-0.457c0.022-0.021,0.046-0.029,0.068-0.045
               c0.068-0.031,0.138-0.031,0.19-0.016c0.053,0.022,0.1,0.061,0.13,0.114c0.022,0.021,0.038,0.044,0.046,0.076l0.007,0.008
               c0.023,0.044,0.069,0.074,0.115,0.084c0.053,0.007,0.106-0.01,0.144-0.024h0.008c0.016-0.016,0.031-0.053,0.054-0.092
               c0.054-0.105,0.113-0.228,0.281-0.228c0.092,0,0.152-0.054,0.206-0.099c0.068-0.063,0.13-0.107,0.214-0.055
               c0.083,0.047,0.083,0.176,0.083,0.305c0,0.101,0,0.199,0.038,0.235l0.016,0.022c0.053,0.062,0.068,0.076,0.137,0.017
               c0.054-0.045,0.068-0.083,0.091-0.123c0.023-0.044,0.046-0.099,0.123-0.168c0.029-0.036,0.083-0.082,0.129-0.144
               c0.129-0.129,0.281-0.297,0.418-0.389c0.075-0.047,0.13-0.063,0.168-0.076c0.046-0.016,0.062-0.021,0.092-0.121
               c0.039-0.106,0.075-0.273,0.106-0.449c0.016-0.123,0.029-0.252,0.021-0.352c-0.007-0.076-0.016-0.137-0.021-0.197
               c-0.017-0.113-0.023-0.197,0-0.297c0.016-0.039,0.016-0.084,0.006-0.121c0-0.084-0.006-0.16,0.101-0.244
               c0.03-0.016,0.046-0.028,0.062-0.045c0.092-0.068,0.146-0.107,0.138-0.176c-0.009-0.076,0.045-0.215,0.167-0.373
               c0.1-0.123,0.236-0.26,0.411-0.396c0.067-0.054,0.13-0.101,0.184-0.138c0.22-0.151,0.281-0.197,0.267-0.479
               c-0.008-0.106-0.017-0.204-0.03-0.296c-0.022-0.244-0.045-0.428,0.054-0.58c0.053-0.083,0.053-0.175,0.045-0.273
               c0-0.09,0-0.198,0.047-0.319c0.037-0.076,0.021-0.175,0.008-0.282c-0.031-0.183-0.062-0.388,0.092-0.615
               c0.113-0.176,0.183-0.283,0.229-0.365c0.053-0.076,0.075-0.139,0.113-0.213c0.038-0.086,0.063-0.152,0.068-0.215
               c0.016-0.068,0.022-0.129,0.022-0.197s-0.022-0.131-0.038-0.168c-0.068,0.009-0.152,0.009-0.251,0.009
               c-0.046,0-0.093-0.009-0.151-0.009c-0.152,0-0.229-0.029-0.289-0.053c-0.047-0.015-0.069-0.031-0.131-0.015
               c-0.121,0.037-0.235,0.052-0.418,0.075c-0.076,0.01-0.16,0.015-0.244,0.029c-0.151,0.017-0.137,0.129-0.121,0.274
               c0.015,0.114,0.029,0.235-0.008,0.366c-0.084,0.279-0.184,0.466-0.274,0.639l-0.03,0.047c-0.029,0.061-0.046,0.113-0.061,0.15
               c-0.029,0.105-0.038,0.152-0.176,0.123c-0.146-0.023-0.251-0.068-0.402-0.13l-0.031-0.017c-0.068-0.021-0.13-0.029-0.198-0.037
               c-0.091-0.016-0.183-0.031-0.289-0.092c-0.099-0.062-0.175-0.055-0.297-0.037c-0.061,0.016-0.136,0.021-0.229,0.029
               c-0.175,0.008-0.419-0.016-0.655-0.038c-0.007,0.046-0.03,0.122-0.044,0.175l-0.009,0.03c-0.016,0.062-0.022,0.091-0.084,0.222
               c-0.045,0.107,0.016,0.166,0.146,0.297l0.053,0.045c0.076,0.084,0.122,0.039,0.183-0.021c0.061-0.053,0.13-0.113,0.236-0.145
               c0.221-0.055,0.251,0.017,0.297,0.113c0.016,0.03,0.03,0.053,0.046,0.076c0.083,0.121,0.083,0.189,0.067,0.336v0.061
               c-0.015,0.16-0.037,0.189-0.129,0.312c-0.022,0.039-0.053,0.078-0.083,0.122c-0.046,0.061-0.077,0.1-0.1,0.131
               c-0.053,0.068-0.053,0.076-0.068,0.168c-0.008,0.061,0.007,0.105,0.03,0.143c0.038,0.055,0.092,0.101,0.152,0.152
               c0.055,0.047,0.076,0.07,0.1,0.084c0.015,0.023,0.022,0.03,0.106,0.078c0.083,0.053,0.121,0.189,0.121,0.334
               c0,0.137-0.03,0.282-0.075,0.357v0.008c-0.039,0.063-0.062,0.1-0.055,0.146c0,0.052,0.031,0.119,0.084,0.248
               c0.107,0.207,0.023,0.26-0.06,0.315c-0.039,0.021-0.069,0.044-0.084,0.084c-0.023,0.035-0.047,0.099-0.062,0.149
               c-0.054,0.146-0.106,0.289-0.197,0.289c-0.062,0-0.084-0.045-0.122-0.098c-0.039-0.047-0.076-0.115-0.168-0.146
               c-0.068-0.023-0.106,0.008-0.152,0.046c-0.053,0.045-0.114,0.101-0.228,0.101c-0.161,0-0.176-0.056-0.198-0.146
               c-0.016-0.038-0.031-0.084-0.077-0.131c-0.045-0.051-0.075-0.098-0.106-0.129c-0.03-0.045-0.046-0.067-0.091-0.076
               c-0.017,0-0.023,0.009-0.03,0.023c-0.009,0.038,0,0.092,0,0.151v0.092v0.022c-0.016,0.092-0.076,0.146-0.168,0.176
               c-0.084,0.021-0.183,0.029-0.273,0.029c-0.076,0-0.152,0-0.206-0.008c-0.091-0.015-0.091,0-0.091,0.031
               c-0.009,0.021-0.009,0.053-0.017,0.092c-0.015,0.045-0.015,0.1,0.008,0.137c0.016,0.047,0.047,0.084,0.1,0.113
               c0.106,0.07,0.076,0.146,0.054,0.223c-0.008,0.021-0.016,0.052-0.008,0.066c0,0.008,0.008,0.022,0.008,0.039
               c0.037,0.092,0.083,0.213,0.007,0.319c-0.038,0.069-0.106,0.091-0.189,0.114c-0.069,0.022-0.146,0.045-0.236,0.104
               C122.776,76.96,122.723,77.024,122.685,77.089"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M122.685,77.089c0.16,0.185,0.435,0.48,0.479,0.68c0.046,0.166,0.068,0.214,0.13,0.326c0.205-0.16,0.502-0.381,0.616-0.457
               c0.022-0.021,0.046-0.029,0.068-0.045c0.068-0.031,0.138-0.031,0.19-0.016c0.053,0.022,0.1,0.061,0.13,0.114
               c0.022,0.021,0.038,0.044,0.046,0.076l0.007,0.008c0.023,0.044,0.069,0.074,0.115,0.084c0.053,0.007,0.106-0.01,0.144-0.024
               h0.008c0.016-0.016,0.031-0.053,0.054-0.092c0.054-0.105,0.113-0.228,0.281-0.228c0.092,0,0.152-0.054,0.206-0.099
               c0.068-0.063,0.13-0.107,0.214-0.055c0.083,0.047,0.083,0.176,0.083,0.305c0,0.101,0,0.199,0.038,0.235l0.016,0.022
               c0.053,0.062,0.068,0.076,0.137,0.017c0.054-0.045,0.068-0.083,0.091-0.123c0.023-0.044,0.046-0.099,0.123-0.168
               c0.029-0.036,0.083-0.082,0.129-0.144c0.129-0.129,0.281-0.297,0.418-0.389c0.075-0.047,0.13-0.063,0.168-0.076
               c0.046-0.016,0.062-0.021,0.092-0.121c0.039-0.106,0.075-0.273,0.106-0.449c0.016-0.123,0.029-0.252,0.021-0.352
               c-0.007-0.076-0.016-0.137-0.021-0.197c-0.017-0.113-0.023-0.197,0-0.297c0.016-0.039,0.016-0.084,0.006-0.121
               c0-0.084-0.006-0.16,0.101-0.244c0.03-0.016,0.046-0.028,0.062-0.045c0.092-0.068,0.146-0.107,0.138-0.176
               c-0.009-0.076,0.045-0.215,0.167-0.373c0.1-0.123,0.236-0.26,0.411-0.396c0.067-0.054,0.13-0.101,0.184-0.138
               c0.22-0.151,0.281-0.197,0.267-0.479c-0.008-0.106-0.017-0.204-0.03-0.296c-0.022-0.244-0.045-0.428,0.054-0.58
               c0.053-0.083,0.053-0.175,0.045-0.273c0-0.09,0-0.198,0.047-0.319c0.037-0.076,0.021-0.175,0.008-0.282
               c-0.031-0.183-0.062-0.388,0.092-0.615c0.113-0.176,0.183-0.283,0.229-0.365c0.053-0.076,0.075-0.139,0.113-0.213
               c0.038-0.086,0.063-0.152,0.068-0.215c0.016-0.068,0.022-0.129,0.022-0.197s-0.022-0.131-0.038-0.168
               c-0.068,0.009-0.152,0.009-0.251,0.009c-0.046,0-0.093-0.009-0.151-0.009c-0.152,0-0.229-0.029-0.289-0.053
               c-0.047-0.015-0.069-0.031-0.131-0.015c-0.121,0.037-0.235,0.052-0.418,0.075c-0.076,0.01-0.16,0.015-0.244,0.029
               c-0.151,0.017-0.137,0.129-0.121,0.274c0.015,0.114,0.029,0.235-0.008,0.366c-0.084,0.279-0.184,0.466-0.274,0.639l-0.03,0.047
               c-0.029,0.061-0.046,0.113-0.061,0.15c-0.029,0.105-0.038,0.152-0.176,0.123c-0.146-0.023-0.251-0.068-0.402-0.13l-0.031-0.017
               c-0.068-0.021-0.13-0.029-0.198-0.037c-0.091-0.016-0.183-0.031-0.289-0.092c-0.099-0.062-0.175-0.055-0.297-0.037
               c-0.061,0.016-0.136,0.021-0.229,0.029c-0.175,0.008-0.419-0.016-0.655-0.038c-0.007,0.046-0.03,0.122-0.044,0.175l-0.009,0.03
               c-0.016,0.062-0.022,0.091-0.084,0.222c-0.045,0.107,0.016,0.166,0.146,0.297l0.053,0.045c0.076,0.084,0.122,0.039,0.183-0.021
               c0.061-0.053,0.13-0.113,0.236-0.145c0.221-0.055,0.251,0.017,0.297,0.113c0.016,0.03,0.03,0.053,0.046,0.076
               c0.083,0.121,0.083,0.189,0.067,0.336v0.061c-0.015,0.16-0.037,0.189-0.129,0.312c-0.022,0.039-0.053,0.078-0.083,0.122
               c-0.046,0.061-0.077,0.1-0.1,0.131c-0.053,0.068-0.053,0.076-0.068,0.168c-0.008,0.061,0.007,0.105,0.03,0.143
               c0.038,0.055,0.092,0.101,0.152,0.152c0.055,0.047,0.076,0.07,0.1,0.084c0.015,0.023,0.022,0.03,0.106,0.078
               c0.083,0.053,0.121,0.189,0.121,0.334c0,0.137-0.03,0.282-0.075,0.357v0.008c-0.039,0.063-0.062,0.1-0.055,0.146
               c0,0.052,0.031,0.119,0.084,0.248c0.107,0.207,0.023,0.26-0.06,0.315c-0.039,0.021-0.069,0.044-0.084,0.084
               c-0.023,0.035-0.047,0.099-0.062,0.149c-0.054,0.146-0.106,0.289-0.197,0.289c-0.062,0-0.084-0.045-0.122-0.098
               c-0.039-0.047-0.076-0.115-0.168-0.146c-0.068-0.023-0.106,0.008-0.152,0.046c-0.053,0.045-0.114,0.101-0.228,0.101
               c-0.161,0-0.176-0.056-0.198-0.146c-0.016-0.038-0.031-0.084-0.077-0.131c-0.045-0.051-0.075-0.098-0.106-0.129
               c-0.03-0.045-0.046-0.067-0.091-0.076c-0.017,0-0.023,0.009-0.03,0.023c-0.009,0.038,0,0.092,0,0.151v0.092v0.022
               c-0.016,0.092-0.076,0.146-0.168,0.176c-0.084,0.021-0.183,0.029-0.273,0.029c-0.076,0-0.152,0-0.206-0.008
               c-0.091-0.015-0.091,0-0.091,0.031c-0.009,0.021-0.009,0.053-0.017,0.092c-0.015,0.045-0.015,0.1,0.008,0.137
               c0.016,0.047,0.047,0.084,0.1,0.113c0.106,0.07,0.076,0.146,0.054,0.223c-0.008,0.021-0.016,0.052-0.008,0.066
               c0,0.008,0.008,0.022,0.008,0.039c0.037,0.092,0.083,0.213,0.007,0.319c-0.038,0.069-0.106,0.091-0.189,0.114
               c-0.069,0.022-0.146,0.045-0.236,0.104C122.776,76.96,122.723,77.024,122.685,77.089z"/>
             <path fill="#808184" d="M121.405,72.5c-0.03,0.137-0.205,0.16-0.221,0.342
               c-0.007,0.189,0.121,0.131,0.016,0.541c-0.114,0.402-0.106,0.662-0.252,0.646c-0.145-0.007-0.281,0-0.235,0.235
               c0.045,0.237,0.304,0.489,0.326,0.64c0.023,0.161,0.047,0.389,0.184,0.52c0.129,0.138,0.305,0.236,0.389,0.373
               c0.091,0.137,0.487,0.471,0.631,0.754c0.146,0.281,0.252,0.281,0.374,0.447v0.01c0.046-0.068,0.099-0.131,0.167-0.184
               c0.106-0.068,0.19-0.099,0.268-0.123c0.068-0.014,0.113-0.029,0.136-0.066c0.046-0.069,0.008-0.16-0.015-0.229
               c-0.008-0.017-0.008-0.031-0.015-0.046c-0.016-0.047,0-0.094,0.015-0.139c0.008-0.029,0.022-0.068-0.015-0.093
               c-0.069-0.045-0.115-0.098-0.146-0.157c-0.022-0.069-0.03-0.146-0.007-0.216c0.007-0.021,0.007-0.051,0.007-0.067
               c0.008-0.106,0.016-0.151,0.205-0.128c0.054,0.006,0.123,0.014,0.199,0.014c0.083,0,0.175-0.008,0.235-0.029
               c0.061-0.016,0.099-0.047,0.099-0.092l0.008-0.008c0-0.037-0.008-0.068-0.008-0.083c0-0.077,0-0.137,0.008-0.185
               c0.016-0.076,0.062-0.112,0.146-0.098c0.091,0.016,0.113,0.053,0.159,0.112c0.022,0.032,0.054,0.069,0.1,0.124
               c0.06,0.061,0.083,0.121,0.099,0.165c0.008,0.047,0.016,0.078,0.1,0.078c0.075,0,0.121-0.039,0.159-0.078
               c0.068-0.052,0.13-0.104,0.251-0.06c0.122,0.037,0.176,0.121,0.221,0.182c0.016,0.023,0.031,0.046,0.039,0.046
               c0.022,0,0.061-0.104,0.099-0.22c0.022-0.055,0.045-0.113,0.068-0.159c0.022-0.063,0.076-0.093,0.114-0.123
               c0.053-0.03,0.099-0.062,0.03-0.188c-0.061-0.14-0.091-0.215-0.1-0.283c0-0.068,0.023-0.123,0.069-0.204h0.008
               c0.029-0.069,0.061-0.19,0.061-0.313c0-0.113-0.022-0.22-0.076-0.25c-0.091-0.054-0.099-0.062-0.13-0.093
               c-0.015-0.015-0.037-0.03-0.083-0.075c-0.076-0.063-0.13-0.105-0.176-0.168c-0.037-0.068-0.061-0.137-0.046-0.222
               c0.016-0.121,0.016-0.121,0.084-0.212c0.022-0.03,0.054-0.069,0.1-0.131c0.038-0.053,0.061-0.092,0.084-0.12
               c0.076-0.106,0.099-0.13,0.105-0.261l0.009-0.062c0.007-0.121,0.007-0.174-0.054-0.267c-0.023-0.029-0.038-0.061-0.054-0.092
               c-0.022-0.06-0.045-0.099-0.175-0.06c-0.084,0.021-0.145,0.074-0.198,0.121c-0.099,0.099-0.183,0.168-0.327,0.022l-0.046-0.045
               c-0.167-0.168-0.243-0.246-0.167-0.412c0.053-0.121,0.068-0.15,0.075-0.205l0.016-0.039c0.008-0.045,0.023-0.105,0.039-0.159
               l-0.115-0.009c-0.099-0.014-0.198-0.021-0.273-0.028c-0.19-0.022-0.427-0.039-0.625-0.056c-0.16-0.014-0.289-0.014-0.357-0.014
               c-0.046,0-0.084,0.021-0.122,0.045c0.016,0.176,0.054,1.027-0.062,1.129C122.707,72.583,121.84,72.531,121.405,72.5"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M121.405,72.5
               c-0.03,0.137-0.205,0.16-0.221,0.342c-0.007,0.189,0.121,0.131,0.016,0.541c-0.114,0.402-0.106,0.662-0.252,0.646
               c-0.145-0.007-0.281,0-0.235,0.235c0.045,0.237,0.304,0.489,0.326,0.64c0.023,0.161,0.047,0.389,0.184,0.52
               c0.129,0.138,0.305,0.236,0.389,0.373c0.091,0.137,0.487,0.471,0.631,0.754c0.146,0.281,0.252,0.281,0.374,0.447v0.01
               c0.046-0.068,0.099-0.131,0.167-0.184c0.106-0.068,0.19-0.099,0.268-0.123c0.068-0.014,0.113-0.029,0.136-0.066
               c0.046-0.069,0.008-0.16-0.015-0.229c-0.008-0.017-0.008-0.031-0.015-0.046c-0.016-0.047,0-0.094,0.015-0.139
               c0.008-0.029,0.022-0.068-0.015-0.093c-0.069-0.045-0.115-0.098-0.146-0.157c-0.022-0.069-0.03-0.146-0.007-0.216
               c0.007-0.021,0.007-0.051,0.007-0.067c0.008-0.106,0.016-0.151,0.205-0.128c0.054,0.006,0.123,0.014,0.199,0.014
               c0.083,0,0.175-0.008,0.235-0.029c0.061-0.016,0.099-0.047,0.099-0.092l0.008-0.008c0-0.037-0.008-0.068-0.008-0.083
               c0-0.077,0-0.137,0.008-0.185c0.016-0.076,0.062-0.112,0.146-0.098c0.091,0.016,0.113,0.053,0.159,0.112
               c0.022,0.032,0.054,0.069,0.1,0.124c0.06,0.061,0.083,0.121,0.099,0.165c0.008,0.047,0.016,0.078,0.1,0.078
               c0.075,0,0.121-0.039,0.159-0.078c0.068-0.052,0.13-0.104,0.251-0.06c0.122,0.037,0.176,0.121,0.221,0.182
               c0.016,0.023,0.031,0.046,0.039,0.046c0.022,0,0.061-0.104,0.099-0.22c0.022-0.055,0.045-0.113,0.068-0.159
               c0.022-0.063,0.076-0.093,0.114-0.123c0.053-0.03,0.099-0.062,0.03-0.188c-0.061-0.14-0.091-0.215-0.1-0.283
               c0-0.068,0.023-0.123,0.069-0.204h0.008c0.029-0.069,0.061-0.19,0.061-0.313c0-0.113-0.022-0.22-0.076-0.25
               c-0.091-0.054-0.099-0.062-0.13-0.093c-0.015-0.015-0.037-0.03-0.083-0.075c-0.076-0.063-0.13-0.105-0.176-0.168
               c-0.037-0.068-0.061-0.137-0.046-0.222c0.016-0.121,0.016-0.121,0.084-0.212c0.022-0.03,0.054-0.069,0.1-0.131
               c0.038-0.053,0.061-0.092,0.084-0.12c0.076-0.106,0.099-0.13,0.105-0.261l0.009-0.062c0.007-0.121,0.007-0.174-0.054-0.267
               c-0.023-0.029-0.038-0.061-0.054-0.092c-0.022-0.06-0.045-0.099-0.175-0.06c-0.084,0.021-0.145,0.074-0.198,0.121
               c-0.099,0.099-0.183,0.168-0.327,0.022l-0.046-0.045c-0.167-0.168-0.243-0.246-0.167-0.412c0.053-0.121,0.068-0.15,0.075-0.205
               l0.016-0.039c0.008-0.045,0.023-0.105,0.039-0.159l-0.115-0.009c-0.099-0.014-0.198-0.021-0.273-0.028
               c-0.19-0.022-0.427-0.039-0.625-0.056c-0.16-0.014-0.289-0.014-0.357-0.014c-0.046,0-0.084,0.021-0.122,0.045
               c0.016,0.176,0.054,1.027-0.062,1.129C122.707,72.583,121.84,72.531,121.405,72.5z"/>
             <path fill="#808184" d="M121.505,71.357c0.015,0.13,0.03,0.214-0.023,0.305
               c-0.084,0.123-0.069,0.221-0.205,0.412c-0.114,0.16,0.068,0.213,0.121,0.326c0.419,0.031,1.271,0.084,1.341,0.023
               c0.075-0.069,0.045-0.764,0.03-0.998c-0.038,0.015-0.084,0.031-0.146,0.031c-0.067,0-0.243-0.018-0.448-0.031
               C121.946,71.404,121.679,71.378,121.505,71.357"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M121.505,71.357c0.015,0.13,0.03,0.214-0.023,0.305c-0.084,0.123-0.069,0.221-0.205,0.412c-0.114,0.16,0.068,0.213,0.121,0.326
               c0.419,0.031,1.271,0.084,1.341,0.023c0.075-0.069,0.045-0.764,0.03-0.998c-0.038,0.015-0.084,0.031-0.146,0.031
               c-0.067,0-0.243-0.018-0.448-0.031C121.946,71.404,121.679,71.378,121.505,71.357z"/>
             <path fill="#808184" d="M148.786,61.528c-0.018-0.062-0.032-0.099-0.107-0.091
               c-0.121,0.007-0.381-0.214-0.503-0.336c-0.12-0.129-0.022-0.342-0.259-0.464c-0.234-0.122-0.335-0.046-0.494-0.335
               c-0.16-0.297-0.206-0.541-0.427-0.586c-0.229-0.046-0.298-0.138-0.397-0.236c-0.099-0.099-0.311-0.069-0.402-0.122
               c-0.092-0.061-0.028-0.32-0.182-0.373c-0.146-0.054-0.306-0.123-0.382-0.282c-0.075-0.16-0.146-0.221-0.19-0.472
               c-0.045-0.244-0.303-0.632-0.313-1.02c-0.007-0.282-0.083-0.427-0.205-0.556c-0.092,0.099-0.176,0.198-0.214,0.259
               c-0.062,0.107-0.12,0.191-0.196,0.251c-0.076,0.061-0.16,0.106-0.273,0.137c-0.122,0.03-0.168,0.092-0.206,0.144
               c-0.022,0.023-0.046,0.054-0.076,0.084c-0.062,0.054-0.122,0.022-0.183,0c-0.039-0.015-0.076-0.03-0.094,0.022
               c-0.029,0.062-0.015,0.099,0,0.146c0.023,0.069,0.055,0.153,0,0.312c-0.035,0.114-0.066,0.16-0.09,0.198
               c-0.023,0.046-0.037,0.076-0.068,0.266c-0.022,0.168-0.075,0.313-0.129,0.442c-0.055,0.122-0.1,0.236-0.106,0.351
               c0,0.114,0.008,0.182,0.022,0.251c0.015,0.083,0.029,0.159,0.022,0.312v0.22v0.023c0.099,0,0.327,0.016,0.435,0.023
               c0.037,0.008,0.037,0,0.037-0.007c0.01-0.039,0.01-0.084,0.047-0.145c0.053-0.084,0.121-0.068,0.196-0.022
               c0.038,0.022,0.077,0.053,0.115,0.083c0.046,0.045,0.099,0.084,0.104,0.084c0.01,0,0.01-0.061,0-0.13
               c0-0.068,0-0.152,0.024-0.22c0.007-0.031,0.021-0.054,0.045-0.077c0.022-0.022,0.054-0.038,0.091-0.038
               c0.022-0.008,0.055,0,0.084,0.008c0.055,0.015,0.1,0.045,0.122,0.068c0.023,0.023,0.085,0.069,0.159,0.114
               c0.055,0.039,0.122,0.069,0.191,0.091c0.084,0.039,0.099,0.017,0.137-0.022c0.021-0.023,0.046-0.053,0.084-0.084
               c0.12-0.091,0.214-0.03,0.298,0.031c0.029,0.023,0.06,0.038,0.082,0.053c0.054,0.022,0.122,0.015,0.229,0.008
               c0.074-0.008,0.174-0.015,0.297-0.015c0.183,0.007,0.287,0.076,0.427,0.159c0.075,0.054,0.166,0.107,0.296,0.152
               c0.221,0.084,0.251,0.161,0.305,0.282c0.022,0.062,0.063,0.137,0.129,0.236c0.108,0.16,0.229,0.282,0.352,0.396
               c0.091,0.099,0.189,0.198,0.281,0.312l0.047,0.053c0.066,0.084,0.105,0.13,0.129,0.153c0.015,0.008,0.029,0.008,0.054,0.008
               h0.03c0.076,0,0.137,0.053,0.197,0.114c0.045,0.045,0.092,0.084,0.129,0.084c0.031,0,0.039-0.039,0.055-0.076
               c0.016-0.054,0.037-0.107,0.098-0.146c0.039-0.03,0.094-0.053,0.139-0.068L148.786,61.528z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M148.786,61.528c-0.018-0.062-0.032-0.099-0.107-0.091c-0.121,0.007-0.381-0.214-0.503-0.336
               c-0.12-0.129-0.022-0.342-0.259-0.464c-0.234-0.122-0.335-0.046-0.494-0.335c-0.16-0.297-0.206-0.541-0.427-0.586
               c-0.229-0.046-0.298-0.138-0.397-0.236c-0.099-0.099-0.311-0.069-0.402-0.122c-0.092-0.061-0.028-0.32-0.182-0.373
               c-0.146-0.054-0.306-0.123-0.382-0.282c-0.075-0.16-0.146-0.221-0.19-0.472c-0.045-0.244-0.303-0.632-0.313-1.02
               c-0.007-0.282-0.083-0.427-0.205-0.556c-0.092,0.099-0.176,0.198-0.214,0.259c-0.062,0.107-0.12,0.191-0.196,0.251
               c-0.076,0.061-0.16,0.106-0.273,0.137c-0.122,0.03-0.168,0.092-0.206,0.144c-0.022,0.023-0.046,0.054-0.076,0.084
               c-0.062,0.054-0.122,0.022-0.183,0c-0.039-0.015-0.076-0.03-0.094,0.022c-0.029,0.062-0.015,0.099,0,0.146
               c0.023,0.069,0.055,0.153,0,0.312c-0.035,0.114-0.066,0.16-0.09,0.198c-0.023,0.046-0.037,0.076-0.068,0.266
               c-0.022,0.168-0.075,0.313-0.129,0.442c-0.055,0.122-0.1,0.236-0.106,0.351c0,0.114,0.008,0.182,0.022,0.251
               c0.015,0.083,0.029,0.159,0.022,0.312v0.22v0.023c0.099,0,0.327,0.016,0.435,0.023c0.037,0.008,0.037,0,0.037-0.007
               c0.01-0.039,0.01-0.084,0.047-0.145c0.053-0.084,0.121-0.068,0.196-0.022c0.038,0.022,0.077,0.053,0.115,0.083
               c0.046,0.045,0.099,0.084,0.104,0.084c0.01,0,0.01-0.061,0-0.13c0-0.068,0-0.152,0.024-0.22
               c0.007-0.031,0.021-0.054,0.045-0.077c0.022-0.022,0.054-0.038,0.091-0.038c0.022-0.008,0.055,0,0.084,0.008
               c0.055,0.015,0.1,0.045,0.122,0.068c0.023,0.023,0.085,0.069,0.159,0.114c0.055,0.039,0.122,0.069,0.191,0.091
               c0.084,0.039,0.099,0.017,0.137-0.022c0.021-0.023,0.046-0.053,0.084-0.084c0.12-0.091,0.214-0.03,0.298,0.031
               c0.029,0.023,0.06,0.038,0.082,0.053c0.054,0.022,0.122,0.015,0.229,0.008c0.074-0.008,0.174-0.015,0.297-0.015
               c0.183,0.007,0.287,0.076,0.427,0.159c0.075,0.054,0.166,0.107,0.296,0.152c0.221,0.084,0.251,0.161,0.305,0.282
               c0.022,0.062,0.063,0.137,0.129,0.236c0.108,0.16,0.229,0.282,0.352,0.396c0.091,0.099,0.189,0.198,0.281,0.312l0.047,0.053
               c0.066,0.084,0.105,0.13,0.129,0.153c0.015,0.008,0.029,0.008,0.054,0.008h0.03c0.076,0,0.137,0.053,0.197,0.114
               c0.045,0.045,0.092,0.084,0.129,0.084c0.031,0,0.039-0.039,0.055-0.076c0.016-0.054,0.037-0.107,0.098-0.146
               c0.039-0.03,0.094-0.053,0.139-0.068L148.786,61.528z"/>
             <path fill="#808184" d="M148.876,62.73c-0.053-0.03-0.113-0.053-0.229-0.068
               c-0.25-0.031-0.205-0.228-0.029-0.282c0.168-0.053,0.381-0.182,0.342-0.312c-0.029-0.137-0.074-0.229-0.129-0.373
               c-0.008-0.023-0.016-0.046-0.021-0.061c-0.039,0.008-0.076,0.031-0.115,0.053c-0.03,0.023-0.045,0.062-0.053,0.099
               c-0.022,0.069-0.045,0.13-0.146,0.137c-0.085,0.008-0.146-0.053-0.213-0.106l-0.017-0.024c-0.084,0.138-0.281,0.481-0.318,0.572
               c-0.024,0.038-0.047,0.076-0.076,0.122c-0.047,0.061-0.092,0.129-0.092,0.213c0.008,0.062-0.008,0.146-0.022,0.236
               c-0.022,0.13-0.047,0.267-0.022,0.273c0.039,0.008,0.138-0.021,0.236-0.053c0.113-0.03,0.228-0.068,0.313-0.053
               c0.105,0.007,0.259,0.068,0.335,0.099C148.702,63.058,148.815,62.845,148.876,62.73"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M148.876,62.73c-0.053-0.03-0.113-0.053-0.229-0.068c-0.25-0.031-0.205-0.228-0.029-0.282c0.168-0.053,0.381-0.182,0.342-0.312
               c-0.029-0.137-0.074-0.229-0.129-0.373c-0.008-0.023-0.016-0.046-0.021-0.061c-0.039,0.008-0.076,0.031-0.115,0.053
               c-0.03,0.023-0.045,0.062-0.053,0.099c-0.022,0.069-0.045,0.13-0.146,0.137c-0.085,0.008-0.146-0.053-0.213-0.106l-0.017-0.024
               c-0.084,0.138-0.281,0.481-0.318,0.572c-0.024,0.038-0.047,0.076-0.076,0.122c-0.047,0.061-0.092,0.129-0.092,0.213
               c0.008,0.062-0.008,0.146-0.022,0.236c-0.022,0.13-0.047,0.267-0.022,0.273c0.039,0.008,0.138-0.021,0.236-0.053
               c0.113-0.03,0.228-0.068,0.313-0.053c0.105,0.007,0.259,0.068,0.335,0.099C148.702,63.058,148.815,62.845,148.876,62.73z"/>
             <path fill="#808184" d="M147.909,69.828c0.047,0.104-0.021,0.188-0.121,0.32
               c-0.046,0.053-0.1,0.12-0.152,0.195c-0.029,0.054-0.061,0.1-0.093,0.146c-0.149,0.221-0.304,0.441-0.267,0.723
               c0.018,0.16,0.018,0.717,0.018,1.311c0,0.775-0.009,1.622,0.037,1.734c0.047,0.132,0.344,0.396,0.557,0.572
               c0.053-0.1,0.105-0.205,0.189-0.289c0.176-0.205,1.021-1.623,1.652-2.072c0.631-0.447,0.67-0.875,1.483-1.256
               c0.808-0.387,1.166-1.399,1.415-1.621c0.245-0.229,0.991-1.104,1.15-1.623c0.152-0.518,0.268-0.829,0.404-1.141
               c0.129-0.32,0.174-0.436,0.243-0.861c0.067-0.426,0.539-0.92,0.677-1.188c0.137-0.273,0.045-0.586,0.176-0.883
               c0.138-0.291,0.298-0.176,0.184-0.578c-0.115-0.412,0.114-0.861-0.046-0.922c-0.159-0.068-0.235-0.175-0.435-0.084
               c-0.205,0.092-0.106,0.168-0.441,0.305c-0.335,0.137-0.281,0.113-0.585,0.182c-0.306,0.069-0.168,0.198-0.435,0.19
               c-0.274-0.016-0.726-0.022-0.967,0.047c-0.253,0.068-0.298-0.063-0.441,0.045c-0.146,0.099-0.016,0.19-0.359,0.29
               c-0.352,0.099-0.372,0.046-0.697,0.029c-0.33-0.008-0.664,0.16-0.732,0.229c-0.068,0.068-0.236,0.267-0.526,0.045
               c-0.296-0.229-0.28-0.381-0.372-0.472c-0.092-0.092-0.213-0.236-0.357-0.335c-0.047-0.03-0.075-0.062-0.106-0.084
               c-0.091,0.168-0.274,0.51-0.319,0.594c-0.029,0.046-0.045,0.113-0.045,0.183c-0.009,0.077,0,0.153,0.022,0.198
               c0.022,0.046,0.052,0.099,0.084,0.152c0.045,0.076,0.084,0.137,0.06,0.175c0,0,0.123,0.191,0.282,0.388
               c0.091,0.123,0.198,0.229,0.306,0.306c0.207,0.137,0.313,0.198,0.434,0.273c0.063,0.037,0.131,0.076,0.229,0.139
               c0.151,0.09,0.625,0.258,1.065,0.402c0.305,0.105,0.586,0.205,0.746,0.273c0.183,0.075,0.419,0.068,0.602,0.068
               c0.204-0.008,0.357-0.008,0.404,0.091c0.045,0.115-0.017,0.153-0.116,0.214c-0.066,0.053-0.156,0.113-0.242,0.252
               c-0.113,0.196-0.738,0.98-1.203,1.553c-0.221,0.289-0.41,0.518-0.473,0.602c-0.054,0.068-0.084,0.13-0.105,0.184
               c-0.076,0.152-0.106,0.213-0.412,0.19c-0.121-0.017-0.234-0.038-0.35-0.062c-0.25-0.047-0.473-0.092-0.603,0.015
               c-0.099,0.067-0.183,0.146-0.259,0.214c-0.092,0.077-0.174,0.152-0.258,0.215c-0.084,0.061-0.154,0.098-0.223,0.128
               c-0.066,0.022-0.137,0.046-0.229,0.068C148.229,69.636,148.001,69.773,147.909,69.828"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M147.909,69.828c0.047,0.104-0.021,0.188-0.121,0.32c-0.046,0.053-0.1,0.12-0.152,0.195c-0.029,0.054-0.061,0.1-0.093,0.146
               c-0.149,0.221-0.304,0.441-0.267,0.723c0.018,0.16,0.018,0.717,0.018,1.311c0,0.775-0.009,1.622,0.037,1.734
               c0.047,0.132,0.344,0.396,0.557,0.572c0.053-0.1,0.105-0.205,0.189-0.289c0.176-0.205,1.021-1.623,1.652-2.072
               c0.631-0.447,0.67-0.875,1.483-1.256c0.808-0.387,1.166-1.399,1.415-1.621c0.245-0.229,0.991-1.104,1.15-1.623
               c0.152-0.518,0.268-0.829,0.404-1.141c0.129-0.32,0.174-0.436,0.243-0.861c0.067-0.426,0.539-0.92,0.677-1.188
               c0.137-0.273,0.045-0.586,0.176-0.883c0.138-0.291,0.298-0.176,0.184-0.578c-0.115-0.412,0.114-0.861-0.046-0.922
               c-0.159-0.068-0.235-0.175-0.435-0.084c-0.205,0.092-0.106,0.168-0.441,0.305c-0.335,0.137-0.281,0.113-0.585,0.182
               c-0.306,0.069-0.168,0.198-0.435,0.19c-0.274-0.016-0.726-0.022-0.967,0.047c-0.253,0.068-0.298-0.063-0.441,0.045
               c-0.146,0.099-0.016,0.19-0.359,0.29c-0.352,0.099-0.372,0.046-0.697,0.029c-0.33-0.008-0.664,0.16-0.732,0.229
               c-0.068,0.068-0.236,0.267-0.526,0.045c-0.296-0.229-0.28-0.381-0.372-0.472c-0.092-0.092-0.213-0.236-0.357-0.335
               c-0.047-0.03-0.075-0.062-0.106-0.084c-0.091,0.168-0.274,0.51-0.319,0.594c-0.029,0.046-0.045,0.113-0.045,0.183
               c-0.009,0.077,0,0.153,0.022,0.198c0.022,0.046,0.052,0.099,0.084,0.152c0.045,0.076,0.084,0.137,0.06,0.175
               c0,0,0.123,0.191,0.282,0.388c0.091,0.123,0.198,0.229,0.306,0.306c0.207,0.137,0.313,0.198,0.434,0.273
               c0.063,0.037,0.131,0.076,0.229,0.139c0.151,0.09,0.625,0.258,1.065,0.402c0.305,0.105,0.586,0.205,0.746,0.273
               c0.183,0.075,0.419,0.068,0.602,0.068c0.204-0.008,0.357-0.008,0.404,0.091c0.045,0.115-0.017,0.153-0.116,0.214
               c-0.066,0.053-0.156,0.113-0.242,0.252c-0.113,0.196-0.738,0.98-1.203,1.553c-0.221,0.289-0.41,0.518-0.473,0.602
               c-0.054,0.068-0.084,0.13-0.105,0.184c-0.076,0.152-0.106,0.213-0.412,0.19c-0.121-0.017-0.234-0.038-0.35-0.062
               c-0.25-0.047-0.473-0.092-0.603,0.015c-0.099,0.067-0.183,0.146-0.259,0.214c-0.092,0.077-0.174,0.152-0.258,0.215
               c-0.084,0.061-0.154,0.098-0.223,0.128c-0.066,0.022-0.137,0.046-0.229,0.068C148.229,69.636,148.001,69.773,147.909,69.828z"/>
             <path fill="#808184" d="M142.999,69.119h0.068c0.129,0.008,0.25,0.031,0.326,0.092
               c0.084,0.055,0.123,0.061,0.184,0.061c0.068,0,0.152,0.01,0.305,0.055c0.152,0.053,0.266,0.1,0.365,0.151
               c0.1,0.062,0.184,0.122,0.251,0.213c0.069,0.093,0.244,0.214,0.435,0.298c0.152,0.077,0.32,0.122,0.44,0.114
               c0.185-0.017,0.282,0.03,0.366,0.067c0.062,0.031,0.113,0.062,0.197,0.054c0.114-0.009,0.184-0.106,0.25-0.197
               c0.047-0.062,0.094-0.129,0.153-0.177c0.031-0.021,0.068-0.066,0.123-0.12c0.168-0.19,0.396-0.448,0.647-0.28
               c0.127,0.09,0.272,0.12,0.402,0.15c0.137,0.038,0.252,0.061,0.334,0.145c0.092-0.054,0.342-0.205,0.488-0.242
               c0.084-0.023,0.145-0.039,0.213-0.069c0.061-0.022,0.12-0.062,0.197-0.114c0.084-0.061,0.168-0.129,0.259-0.205
               c0.077-0.068,0.151-0.145,0.252-0.222c0.167-0.128,0.419-0.083,0.687-0.029c0.113,0.022,0.228,0.046,0.343,0.054
               c0.233,0.023,0.258-0.023,0.312-0.129c0.022-0.055,0.063-0.121,0.113-0.197c0.068-0.084,0.252-0.322,0.479-0.604
               c0.459-0.57,1.082-1.354,1.197-1.546c0.09-0.159,0.196-0.229,0.273-0.28c0.052-0.031,0.084-0.055,0.066-0.092
               c-0.016-0.039-0.136-0.029-0.297-0.029c-0.197,0.006-0.447,0.016-0.654-0.078c-0.145-0.061-0.434-0.158-0.729-0.267
               c-0.448-0.151-0.929-0.317-1.089-0.418c-0.092-0.054-0.161-0.094-0.229-0.138c-0.123-0.068-0.229-0.129-0.435-0.273
               c-0.122-0.076-0.237-0.197-0.336-0.318c-0.183-0.237-0.297-0.497-0.281-0.511c0.007-0.023-0.038-0.023-0.067-0.077
               c-0.023-0.045-0.062-0.105-0.084-0.158c-0.03-0.062-0.039-0.153-0.039-0.237c0.009-0.091,0.031-0.175,0.063-0.235
               c0.008-0.007,0.016-0.018,0.021-0.031c-0.075-0.03-0.205-0.084-0.297-0.091c-0.062-0.008-0.176,0.021-0.273,0.054
               c-0.113,0.037-0.229,0.068-0.297,0.051c-0.105-0.036-0.077-0.211-0.047-0.387c0.008-0.083,0.023-0.167,0.023-0.213
               c-0.007-0.121,0.053-0.205,0.106-0.282c0.022-0.037,0.053-0.069,0.069-0.106c0.035-0.092,0.248-0.457,0.326-0.587
               c-0.009,0-0.016-0.007-0.022-0.007h-0.022c-0.054,0.007-0.084,0.007-0.131-0.031h0.008c-0.037-0.022-0.076-0.068-0.15-0.167
               l-0.047-0.054c-0.084-0.114-0.176-0.205-0.274-0.296c-0.12-0.122-0.242-0.252-0.356-0.411c-0.074-0.107-0.113-0.191-0.138-0.252
               c-0.046-0.106-0.067-0.16-0.252-0.229c-0.136-0.053-0.228-0.113-0.319-0.167c-0.113-0.076-0.211-0.137-0.363-0.137
               c-0.114-0.007-0.213,0-0.291,0.008c-0.113,0.007-0.204,0.015-0.272-0.015c-0.038-0.016-0.069-0.038-0.1-0.062
               c-0.062-0.037-0.123-0.075-0.177-0.03c-0.037,0.023-0.061,0.046-0.074,0.069c-0.069,0.069-0.107,0.106-0.252,0.053
               c-0.068-0.03-0.145-0.061-0.205-0.099c-0.083-0.053-0.151-0.106-0.176-0.13c-0.016-0.014-0.047-0.037-0.084-0.045
               c-0.016-0.008-0.03-0.008-0.047-0.008c-0.014,0-0.021,0.008-0.028,0.016c-0.009,0.007-0.015,0.015-0.015,0.03
               c-0.023,0.053-0.016,0.129-0.016,0.19c0,0.115,0,0.214-0.084,0.228c-0.063,0.008-0.13-0.045-0.199-0.098
               c-0.029-0.031-0.066-0.061-0.099-0.084c-0.031-0.015-0.046-0.023-0.054-0.008c-0.029,0.039-0.029,0.077-0.029,0.099
               c-0.01,0.077-0.01,0.115-0.146,0.107c-0.113-0.016-0.334-0.023-0.434-0.03c-0.01,0.228-0.016,0.464-0.092,0.601
               c-0.09,0.159-0.106,0.275-0.129,0.434c-0.01,0.038-0.01,0.084-0.016,0.114c-0.039,0.244-0.039,0.244-0.275,0.381
               c-0.098,0.061-0.182,0.137-0.258,0.221s-0.138,0.19-0.176,0.297c-0.046,0.106-0.078,0.183-0.092,0.266
               c-0.021,0.077-0.029,0.152-0.045,0.267v0.19c0,0.221,0.007,0.434-0.191,0.487c-0.119,0.03-0.183-0.008-0.229-0.04
               c-0.021-0.014-0.029-0.021-0.066,0.04c-0.031,0.045-0.063,0.083-0.084,0.114c-0.076,0.099-0.123,0.168-0.092,0.312
               c0.008,0.024,0.016,0.062,0.021,0.092c0.037,0.168,0.063,0.267-0.067,0.413c-0.077,0.084-0.062,0.241-0.046,0.447
               c0.016,0.091,0.021,0.189,0.021,0.305c0.008,0.061,0.008,0.123,0.016,0.176c0.016,0.258,0.023,0.351-0.229,0.41
               c-0.113,0.03-0.244,0.008-0.365-0.015c-0.039-0.008-0.076-0.015-0.113-0.015c-0.092-0.018-0.161-0.008-0.168,0.066
               c-0.016,0.122-0.029,0.215-0.008,0.282c0.015,0.062,0.061,0.115,0.145,0.16c0.068,0.036,0.152,0.046,0.229,0.054
               c0.151,0.016,0.297,0.021,0.373,0.221c0.045,0.121,0.129,0.26,0.229,0.389c0.113,0.145,0.251,0.281,0.357,0.365
               c0.168,0.137,0.189,0.205,0.229,0.342c0.014,0.047,0.031,0.107,0.063,0.177c0.035,0.104,0.054,0.19,0.067,0.272
               c0.015,0.129,0.038,0.229,0.146,0.297c0.091,0.062,0.168,0.068,0.221,0.084c0.077,0.016,0.129,0.023,0.175,0.092
               C142.953,68.562,142.991,68.982,142.999,69.119"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M142.999,69.119h0.068c0.129,0.008,0.25,0.031,0.326,0.092c0.084,0.055,0.123,0.061,0.184,0.061c0.068,0,0.152,0.01,0.305,0.055
               c0.152,0.053,0.266,0.1,0.365,0.151c0.1,0.062,0.184,0.122,0.251,0.213c0.069,0.093,0.244,0.214,0.435,0.298
               c0.152,0.077,0.32,0.122,0.44,0.114c0.185-0.017,0.282,0.03,0.366,0.067c0.062,0.031,0.113,0.062,0.197,0.054
               c0.114-0.009,0.184-0.106,0.25-0.197c0.047-0.062,0.094-0.129,0.153-0.177c0.031-0.021,0.068-0.066,0.123-0.12
               c0.168-0.19,0.396-0.448,0.647-0.28c0.127,0.09,0.272,0.12,0.402,0.15c0.137,0.038,0.252,0.061,0.334,0.145
               c0.092-0.054,0.342-0.205,0.488-0.242c0.084-0.023,0.145-0.039,0.213-0.069c0.061-0.022,0.12-0.062,0.197-0.114
               c0.084-0.061,0.168-0.129,0.259-0.205c0.077-0.068,0.151-0.145,0.252-0.222c0.167-0.128,0.419-0.083,0.687-0.029
               c0.113,0.022,0.228,0.046,0.343,0.054c0.233,0.023,0.258-0.023,0.312-0.129c0.022-0.055,0.063-0.121,0.113-0.197
               c0.068-0.084,0.252-0.322,0.479-0.604c0.459-0.57,1.082-1.354,1.197-1.546c0.09-0.159,0.196-0.229,0.273-0.28
               c0.052-0.031,0.084-0.055,0.066-0.092c-0.016-0.039-0.136-0.029-0.297-0.029c-0.197,0.006-0.447,0.016-0.654-0.078
               c-0.145-0.061-0.434-0.158-0.729-0.267c-0.448-0.151-0.929-0.317-1.089-0.418c-0.092-0.054-0.161-0.094-0.229-0.138
               c-0.123-0.068-0.229-0.129-0.435-0.273c-0.122-0.076-0.237-0.197-0.336-0.318c-0.183-0.237-0.297-0.497-0.281-0.511
               c0.007-0.023-0.038-0.023-0.067-0.077c-0.023-0.045-0.062-0.105-0.084-0.158c-0.03-0.062-0.039-0.153-0.039-0.237
               c0.009-0.091,0.031-0.175,0.063-0.235c0.008-0.007,0.016-0.018,0.021-0.031c-0.075-0.03-0.205-0.084-0.297-0.091
               c-0.062-0.008-0.176,0.021-0.273,0.054c-0.113,0.037-0.229,0.068-0.297,0.051c-0.105-0.036-0.077-0.211-0.047-0.387
               c0.008-0.083,0.023-0.167,0.023-0.213c-0.007-0.121,0.053-0.205,0.106-0.282c0.022-0.037,0.053-0.069,0.069-0.106
               c0.035-0.092,0.248-0.457,0.326-0.587c-0.009,0-0.016-0.007-0.022-0.007h-0.022c-0.054,0.007-0.084,0.007-0.131-0.031h0.008
               c-0.037-0.022-0.076-0.068-0.15-0.167l-0.047-0.054c-0.084-0.114-0.176-0.205-0.274-0.296c-0.12-0.122-0.242-0.252-0.356-0.411
               c-0.074-0.107-0.113-0.191-0.138-0.252c-0.046-0.106-0.067-0.16-0.252-0.229c-0.136-0.053-0.228-0.113-0.319-0.167
               c-0.113-0.076-0.211-0.137-0.363-0.137c-0.114-0.007-0.213,0-0.291,0.008c-0.113,0.007-0.204,0.015-0.272-0.015
               c-0.038-0.016-0.069-0.038-0.1-0.062c-0.062-0.037-0.123-0.075-0.177-0.03c-0.037,0.023-0.061,0.046-0.074,0.069
               c-0.069,0.069-0.107,0.106-0.252,0.053c-0.068-0.03-0.145-0.061-0.205-0.099c-0.083-0.053-0.151-0.106-0.176-0.13
               c-0.016-0.014-0.047-0.037-0.084-0.045c-0.016-0.008-0.03-0.008-0.047-0.008c-0.014,0-0.021,0.008-0.028,0.016
               c-0.009,0.007-0.015,0.015-0.015,0.03c-0.023,0.053-0.016,0.129-0.016,0.19c0,0.115,0,0.214-0.084,0.228
               c-0.063,0.008-0.13-0.045-0.199-0.098c-0.029-0.031-0.066-0.061-0.099-0.084c-0.031-0.015-0.046-0.023-0.054-0.008
               c-0.029,0.039-0.029,0.077-0.029,0.099c-0.01,0.077-0.01,0.115-0.146,0.107c-0.113-0.016-0.334-0.023-0.434-0.03
               c-0.01,0.228-0.016,0.464-0.092,0.601c-0.09,0.159-0.106,0.275-0.129,0.434c-0.01,0.038-0.01,0.084-0.016,0.114
               c-0.039,0.244-0.039,0.244-0.275,0.381c-0.098,0.061-0.182,0.137-0.258,0.221s-0.138,0.19-0.176,0.297
               c-0.046,0.106-0.078,0.183-0.092,0.266c-0.021,0.077-0.029,0.152-0.045,0.267v0.19c0,0.221,0.007,0.434-0.191,0.487
               c-0.119,0.03-0.183-0.008-0.229-0.04c-0.021-0.014-0.029-0.021-0.066,0.04c-0.031,0.045-0.063,0.083-0.084,0.114
               c-0.076,0.099-0.123,0.168-0.092,0.312c0.008,0.024,0.016,0.062,0.021,0.092c0.037,0.168,0.063,0.267-0.067,0.413
               c-0.077,0.084-0.062,0.241-0.046,0.447c0.016,0.091,0.021,0.189,0.021,0.305c0.008,0.061,0.008,0.123,0.016,0.176
               c0.016,0.258,0.023,0.351-0.229,0.41c-0.113,0.03-0.244,0.008-0.365-0.015c-0.039-0.008-0.076-0.015-0.113-0.015
               c-0.092-0.018-0.161-0.008-0.168,0.066c-0.016,0.122-0.029,0.215-0.008,0.282c0.015,0.062,0.061,0.115,0.145,0.16
               c0.068,0.036,0.152,0.046,0.229,0.054c0.151,0.016,0.297,0.021,0.373,0.221c0.045,0.121,0.129,0.26,0.229,0.389
               c0.113,0.145,0.251,0.281,0.357,0.365c0.168,0.137,0.189,0.205,0.229,0.342c0.014,0.047,0.031,0.107,0.063,0.177
               c0.035,0.104,0.054,0.19,0.067,0.272c0.015,0.129,0.038,0.229,0.146,0.297c0.091,0.062,0.168,0.068,0.221,0.084
               c0.077,0.016,0.129,0.023,0.175,0.092C142.953,68.562,142.991,68.982,142.999,69.119z"/>
             <path fill="#808184" d="M135.956,68.608h0.038c0.091,0.009,0.168,0.038,0.213,0.084
               c0.03,0.022,0.054,0.069,0.091,0.13c0.1,0.168,0.26,0.442,0.503,0.479c0.214,0.039,0.274,0.016,0.343-0.008
               c0.047-0.015,0.092-0.028,0.184-0.045c0.122-0.008,0.222,0.017,0.306,0.03c0.074,0.015,0.136,0.03,0.188,0.015
               c0.068-0.022,0.131-0.028,0.207-0.015c0.066,0.015,0.144,0.046,0.242,0.106c0.063,0.046,0.138,0.105,0.213,0.183
               c0.152,0.13,0.328,0.281,0.488,0.335c0.137,0.038,0.145,0.03,0.176,0.021c0.029-0.006,0.074-0.021,0.197-0.021
               c0.229,0,0.305,0.03,0.389,0.066c0.021,0.011,0.045,0.018,0.067,0.025c0.068,0.021,0.205-0.025,0.343-0.076
               c0.084-0.022,0.168-0.055,0.236-0.069c0.189-0.046,0.242-0.037,0.403-0.007l0.062,0.007c0.1,0.022,0.113,0.008,0.152-0.017
               c0.029-0.021,0.066-0.045,0.15-0.066c0.055-0.016,0.106-0.107,0.168-0.215c0.1-0.182,0.213-0.395,0.473-0.418
               c0.197-0.016,0.365,0.008,0.503,0.023c0.13,0.021,0.235,0.037,0.319,0.014c0.076-0.021,0.176-0.045,0.289-0.053
               c-0.017-0.13-0.045-0.518-0.1-0.594c-0.023-0.029-0.054-0.038-0.105-0.046c-0.069-0.017-0.152-0.03-0.26-0.091
               c-0.145-0.092-0.168-0.223-0.197-0.375c-0.01-0.074-0.023-0.158-0.063-0.25c-0.021-0.076-0.037-0.137-0.052-0.184
               c-0.039-0.121-0.054-0.176-0.199-0.289c-0.114-0.092-0.26-0.236-0.38-0.389c-0.1-0.137-0.191-0.281-0.244-0.41
               c-0.053-0.139-0.16-0.146-0.283-0.16c-0.09-0.008-0.182-0.015-0.266-0.062c-0.113-0.062-0.175-0.129-0.197-0.22
               c-0.023-0.094-0.017-0.191,0-0.328c0.022-0.161,0.137-0.176,0.29-0.161c0.037,0.01,0.075,0.017,0.113,0.024
               c0.114,0.021,0.228,0.045,0.319,0.015c0.176-0.039,0.168-0.106,0.151-0.306c0-0.045-0.007-0.104-0.007-0.183
               c0-0.104-0.016-0.204-0.023-0.298c-0.021-0.229-0.037-0.402,0.068-0.524c0.1-0.106,0.077-0.183,0.046-0.32
               c-0.007-0.03-0.015-0.061-0.022-0.1c-0.037-0.183,0.017-0.267,0.107-0.387c0.029-0.03,0.053-0.068,0.084-0.107
               c0.092-0.144,0.137-0.121,0.204-0.069c0.03,0.017,0.069,0.047,0.146,0.024c0.123-0.031,0.123-0.207,0.123-0.381
               c-0.01-0.076-0.01-0.146,0-0.206c0.015-0.122,0.021-0.206,0.045-0.289c0.015-0.077,0.046-0.161,0.092-0.274
               c0.053-0.123,0.113-0.229,0.197-0.328c0.084-0.091,0.175-0.175,0.289-0.236c0.189-0.115,0.189-0.115,0.222-0.313v-0.008
               c0.007-0.045,0.007-0.076,0.015-0.107c0.023-0.167,0.038-0.289,0.139-0.464c0.076-0.144,0.076-0.434,0.076-0.678
               c0.006-0.084,0.006-0.159,0.006-0.228c0.008-0.137-0.006-0.213-0.021-0.281c-0.016-0.077-0.029-0.153-0.023-0.274
               c0.01-0.138,0.055-0.26,0.107-0.389c0.055-0.13,0.105-0.259,0.129-0.419c0.031-0.205,0.047-0.244,0.084-0.297
               c0.016-0.038,0.047-0.084,0.075-0.182c0.046-0.129,0.022-0.191,0-0.252c-0.015-0.06-0.036-0.114,0-0.213
               c0.063-0.153,0.146-0.114,0.229-0.076c0.029,0.007,0.061,0.023,0.074,0.007c0.024-0.015,0.047-0.037,0.063-0.06
               c0.053-0.069,0.105-0.138,0.267-0.183c0.093-0.023,0.168-0.061,0.235-0.115c0.063-0.053,0.115-0.122,0.168-0.22
               c0.045-0.076,0.129-0.175,0.229-0.282c-0.031-0.03-0.068-0.053-0.107-0.084c-0.221-0.183-0.356-0.388-0.594-0.533
               c-0.234-0.144-0.289-0.114-0.326-0.448c-0.031-0.336-0.008-0.541-0.115-0.8c-0.1-0.259-0.129-0.495-0.129-0.564
               c0-0.068,0.053-0.518-0.106-0.784c-0.151-0.266-0.022-0.64-0.235-0.731c-0.215-0.092-0.541-0.441-0.709-0.517
               c-0.061-0.031-0.145-0.084-0.213-0.153c-0.037,0.031-0.074,0.069-0.121,0.099c-0.129,0.077-0.198,0.077-0.26,0.077
               c-0.037,0-0.068,0-0.105,0.029c-0.038,0.031-0.038,0.062-0.047,0.084c0,0.054-0.006,0.107-0.084,0.191
               c-0.036,0.038-0.045,0.076-0.061,0.107c-0.023,0.083-0.037,0.136-0.236,0.159l-0.084,0.008c-0.166,0.022-0.234,0.03-0.258,0.122
               c-0.009,0.03-0.009,0.062-0.009,0.091c-0.007,0.137-0.015,0.266-0.289,0.259c-0.167-0.008-0.235-0.084-0.312-0.16
               c-0.063-0.061-0.122-0.122-0.252-0.122c-0.106,0-0.258,0.016-0.42,0.031c-0.289,0.022-0.594,0.053-0.707-0.008
               c-0.045-0.023-0.084-0.053-0.121-0.076c-0.069-0.046-0.115-0.076-0.168-0.046c-0.062,0.031-0.389,0.054-0.799,0.069
               c-0.627,0.015-1.447,0.023-1.838,0.023c-0.578,0-2.093,0.023-2.382,0.03c0.022,0.777,0.029,1.363,0.015,1.5
               c-0.021,0.305-0.166,0.35-0.325,0.343c-0.056-0.008-0.106-0.015-0.153-0.023c-0.061-0.015-0.121-0.023-0.158-0.023
               c-0.076,0.008-0.093,0.099-0.101,0.19c-0.009,0.069-0.015,0.13-0.045,0.183c0.007,0.213,0.129,2.376,0.159,2.894v0.09
               c0,0.244-0.062,0.404-0.139,0.511c-0.082,0.114-0.188,0.16-0.281,0.167h-0.015c-0.069,0-0.175,0.054-0.282,0.115
               c-0.121,0.076-0.234,0.167-0.272,0.221c-0.022,0.037-0.03,0.098-0.045,0.175c-0.024,0.137-0.054,0.304-0.198,0.426
               c-0.115,0.091-0.105,0.122-0.092,0.175c0.008,0.03,0.023,0.076,0.023,0.13c0,0.099-0.033,0.19-0.078,0.281
               c-0.045,0.084-0.105,0.16-0.183,0.213c-0.075,0.046-0.03,0.13,0.015,0.221c0.024,0.031,0.039,0.068,0.055,0.114
               c0.061,0.145-0.047,0.236-0.159,0.335c-0.03,0.023-0.054,0.046-0.084,0.077c-0.017,0.015-0.022,0.03-0.022,0.038
               c-0.008,0.022-0.008,0.045,0,0.068c0.015,0.023,0.029,0.045,0.063,0.077c0.052,0.052,0.145,0.106,0.249,0.136
               c0.237,0.069,0.229,0.213,0.222,0.404c0,0.076,0,0.152,0.01,0.236c0.006,0.045,0.015,0.083,0.021,0.122
               c0.015,0.16,0.022,0.213,0.183,0.289c0.198,0.083,0.191,0.229,0.176,0.418c-0.008,0.062-0.008,0.13-0.008,0.198
               c0.008,0.084,0.068,0.214,0.159,0.343c0.161,0.236,0.382,0.486,0.435,0.533c0.045,0.054,0.063,0.152,0.045,0.267
               c-0.006,0.121-0.045,0.267-0.105,0.396c-0.037,0.101-0.015,0.175,0.047,0.235c0.068,0.068,0.184,0.121,0.305,0.166
               c0.189,0.084,0.205,0.229,0.213,0.353c0,0.046,0,0.084,0.017,0.106c0.022,0.037,0.122,0.046,0.222,0.061
               c0.104,0.008,0.205,0.016,0.267,0.061c0.045,0.031,0.065,0.063,0.099,0.084c0.045,0.055,0.084,0.093,0.198,0.131
               c0.129,0.037,0.136,0.092,0.104,0.176c0,0.021-0.006,0.029-0.015,0.045c-0.017,0.055-0.038,0.123-0.038,0.205
               c0.008,0.13,0.229,0.252,0.428,0.365c0.112,0.063,0.219,0.123,0.297,0.184c0.09,0.076,0.145,0.16,0.175,0.235
               c0.046,0.106,0.046,0.206,0.028,0.266c-0.006,0.056,0,0.095,0.01,0.132c0.008,0.037,0.016,0.067,0.016,0.113
               c0.007,0.031,0.066,0.053,0.137,0.076c0.069,0.021,0.137,0.038,0.189,0.076c0.046,0.029,0.084,0.053,0.129,0.076
               c0.077,0.045,0.161,0.084,0.236,0.176C136.001,68.207,135.972,68.487,135.956,68.608"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M135.956,68.608h0.038c0.091,0.009,0.168,0.038,0.213,0.084c0.03,0.022,0.054,0.069,0.091,0.13
               c0.1,0.168,0.26,0.442,0.503,0.479c0.214,0.039,0.274,0.016,0.343-0.008c0.047-0.015,0.092-0.028,0.184-0.045
               c0.122-0.008,0.222,0.017,0.306,0.03c0.074,0.015,0.136,0.03,0.188,0.015c0.068-0.022,0.131-0.028,0.207-0.015
               c0.066,0.015,0.144,0.046,0.242,0.106c0.063,0.046,0.138,0.105,0.213,0.183c0.152,0.13,0.328,0.281,0.488,0.335
               c0.137,0.038,0.145,0.03,0.176,0.021c0.029-0.006,0.074-0.021,0.197-0.021c0.229,0,0.305,0.03,0.389,0.066
               c0.021,0.011,0.045,0.018,0.067,0.025c0.068,0.021,0.205-0.025,0.343-0.076c0.084-0.022,0.168-0.055,0.236-0.069
               c0.189-0.046,0.242-0.037,0.403-0.007l0.062,0.007c0.1,0.022,0.113,0.008,0.152-0.017c0.029-0.021,0.066-0.045,0.15-0.066
               c0.055-0.016,0.106-0.107,0.168-0.215c0.1-0.182,0.213-0.395,0.473-0.418c0.197-0.016,0.365,0.008,0.503,0.023
               c0.13,0.021,0.235,0.037,0.319,0.014c0.076-0.021,0.176-0.045,0.289-0.053c-0.017-0.13-0.045-0.518-0.1-0.594
               c-0.023-0.029-0.054-0.038-0.105-0.046c-0.069-0.017-0.152-0.03-0.26-0.091c-0.145-0.092-0.168-0.223-0.197-0.375
               c-0.01-0.074-0.023-0.158-0.063-0.25c-0.021-0.076-0.037-0.137-0.052-0.184c-0.039-0.121-0.054-0.176-0.199-0.289
               c-0.114-0.092-0.26-0.236-0.38-0.389c-0.1-0.137-0.191-0.281-0.244-0.41c-0.053-0.139-0.16-0.146-0.283-0.16
               c-0.09-0.008-0.182-0.015-0.266-0.062c-0.113-0.062-0.175-0.129-0.197-0.22c-0.023-0.094-0.017-0.191,0-0.328
               c0.022-0.161,0.137-0.176,0.29-0.161c0.037,0.01,0.075,0.017,0.113,0.024c0.114,0.021,0.228,0.045,0.319,0.015
               c0.176-0.039,0.168-0.106,0.151-0.306c0-0.045-0.007-0.104-0.007-0.183c0-0.104-0.016-0.204-0.023-0.298
               c-0.021-0.229-0.037-0.402,0.068-0.524c0.1-0.106,0.077-0.183,0.046-0.32c-0.007-0.03-0.015-0.061-0.022-0.1
               c-0.037-0.183,0.017-0.267,0.107-0.387c0.029-0.03,0.053-0.068,0.084-0.107c0.092-0.144,0.137-0.121,0.204-0.069
               c0.03,0.017,0.069,0.047,0.146,0.024c0.123-0.031,0.123-0.207,0.123-0.381c-0.01-0.076-0.01-0.146,0-0.206
               c0.015-0.122,0.021-0.206,0.045-0.289c0.015-0.077,0.046-0.161,0.092-0.274c0.053-0.123,0.113-0.229,0.197-0.328
               c0.084-0.091,0.175-0.175,0.289-0.236c0.189-0.115,0.189-0.115,0.222-0.313v-0.008c0.007-0.045,0.007-0.076,0.015-0.107
               c0.023-0.167,0.038-0.289,0.139-0.464c0.076-0.144,0.076-0.434,0.076-0.678c0.006-0.084,0.006-0.159,0.006-0.228
               c0.008-0.137-0.006-0.213-0.021-0.281c-0.016-0.077-0.029-0.153-0.023-0.274c0.01-0.138,0.055-0.26,0.107-0.389
               c0.055-0.13,0.105-0.259,0.129-0.419c0.031-0.205,0.047-0.244,0.084-0.297c0.016-0.038,0.047-0.084,0.075-0.182
               c0.046-0.129,0.022-0.191,0-0.252c-0.015-0.06-0.036-0.114,0-0.213c0.063-0.153,0.146-0.114,0.229-0.076
               c0.029,0.007,0.061,0.023,0.074,0.007c0.024-0.015,0.047-0.037,0.063-0.06c0.053-0.069,0.105-0.138,0.267-0.183
               c0.093-0.023,0.168-0.061,0.235-0.115c0.063-0.053,0.115-0.122,0.168-0.22c0.045-0.076,0.129-0.175,0.229-0.282
               c-0.031-0.03-0.068-0.053-0.107-0.084c-0.221-0.183-0.356-0.388-0.594-0.533c-0.234-0.144-0.289-0.114-0.326-0.448
               c-0.031-0.336-0.008-0.541-0.115-0.8c-0.1-0.259-0.129-0.495-0.129-0.564c0-0.068,0.053-0.518-0.106-0.784
               c-0.151-0.266-0.022-0.64-0.235-0.731c-0.215-0.092-0.541-0.441-0.709-0.517c-0.061-0.031-0.145-0.084-0.213-0.153
               c-0.037,0.031-0.074,0.069-0.121,0.099c-0.129,0.077-0.198,0.077-0.26,0.077c-0.037,0-0.068,0-0.105,0.029
               c-0.038,0.031-0.038,0.062-0.047,0.084c0,0.054-0.006,0.107-0.084,0.191c-0.036,0.038-0.045,0.076-0.061,0.107
               c-0.023,0.083-0.037,0.136-0.236,0.159l-0.084,0.008c-0.166,0.022-0.234,0.03-0.258,0.122c-0.009,0.03-0.009,0.062-0.009,0.091
               c-0.007,0.137-0.015,0.266-0.289,0.259c-0.167-0.008-0.235-0.084-0.312-0.16c-0.063-0.061-0.122-0.122-0.252-0.122
               c-0.106,0-0.258,0.016-0.42,0.031c-0.289,0.022-0.594,0.053-0.707-0.008c-0.045-0.023-0.084-0.053-0.121-0.076
               c-0.069-0.046-0.115-0.076-0.168-0.046c-0.062,0.031-0.389,0.054-0.799,0.069c-0.627,0.015-1.447,0.023-1.838,0.023
               c-0.578,0-2.093,0.023-2.382,0.03c0.022,0.777,0.029,1.363,0.015,1.5c-0.021,0.305-0.166,0.35-0.325,0.343
               c-0.056-0.008-0.106-0.015-0.153-0.023c-0.061-0.015-0.121-0.023-0.158-0.023c-0.076,0.008-0.093,0.099-0.101,0.19
               c-0.009,0.069-0.015,0.13-0.045,0.183c0.007,0.213,0.129,2.376,0.159,2.894v0.09c0,0.244-0.062,0.404-0.139,0.511
               c-0.082,0.114-0.188,0.16-0.281,0.167h-0.015c-0.069,0-0.175,0.054-0.282,0.115c-0.121,0.076-0.234,0.167-0.272,0.221
               c-0.022,0.037-0.03,0.098-0.045,0.175c-0.024,0.137-0.054,0.304-0.198,0.426c-0.115,0.091-0.105,0.122-0.092,0.175
               c0.008,0.03,0.023,0.076,0.023,0.13c0,0.099-0.033,0.19-0.078,0.281c-0.045,0.084-0.105,0.16-0.183,0.213
               c-0.075,0.046-0.03,0.13,0.015,0.221c0.024,0.031,0.039,0.068,0.055,0.114c0.061,0.145-0.047,0.236-0.159,0.335
               c-0.03,0.023-0.054,0.046-0.084,0.077c-0.017,0.015-0.022,0.03-0.022,0.038c-0.008,0.022-0.008,0.045,0,0.068
               c0.015,0.023,0.029,0.045,0.063,0.077c0.052,0.052,0.145,0.106,0.249,0.136c0.237,0.069,0.229,0.213,0.222,0.404
               c0,0.076,0,0.152,0.01,0.236c0.006,0.045,0.015,0.083,0.021,0.122c0.015,0.16,0.022,0.213,0.183,0.289
               c0.198,0.083,0.191,0.229,0.176,0.418c-0.008,0.062-0.008,0.13-0.008,0.198c0.008,0.084,0.068,0.214,0.159,0.343
               c0.161,0.236,0.382,0.486,0.435,0.533c0.045,0.054,0.063,0.152,0.045,0.267c-0.006,0.121-0.045,0.267-0.105,0.396
               c-0.037,0.101-0.015,0.175,0.047,0.235c0.068,0.068,0.184,0.121,0.305,0.166c0.189,0.084,0.205,0.229,0.213,0.353
               c0,0.046,0,0.084,0.017,0.106c0.022,0.037,0.122,0.046,0.222,0.061c0.104,0.008,0.205,0.016,0.267,0.061
               c0.045,0.031,0.065,0.063,0.099,0.084c0.045,0.055,0.084,0.093,0.198,0.131c0.129,0.037,0.136,0.092,0.104,0.176
               c0,0.021-0.006,0.029-0.015,0.045c-0.017,0.055-0.038,0.123-0.038,0.205c0.008,0.13,0.229,0.252,0.428,0.365
               c0.112,0.063,0.219,0.123,0.297,0.184c0.09,0.076,0.145,0.16,0.175,0.235c0.046,0.106,0.046,0.206,0.028,0.266
               c-0.006,0.056,0,0.095,0.01,0.132c0.008,0.037,0.016,0.067,0.016,0.113c0.007,0.031,0.066,0.053,0.137,0.076
               c0.069,0.021,0.137,0.038,0.189,0.076c0.046,0.029,0.084,0.053,0.129,0.076c0.077,0.045,0.161,0.084,0.236,0.176
               C136.001,68.207,135.972,68.487,135.956,68.608z"/>
             <path fill="#808184" d="M132.21,63.133c0-0.029,0-0.067,0.008-0.098
               c0.008-0.146,0.016-0.26-0.113-0.32c-0.215-0.099-0.223-0.167-0.252-0.366c0-0.038-0.008-0.075-0.016-0.121
               c-0.018-0.092-0.008-0.176-0.008-0.252c0.008-0.144,0.008-0.258-0.145-0.304c-0.129-0.038-0.235-0.099-0.306-0.168
               c-0.039-0.03-0.062-0.068-0.075-0.114c-0.016-0.038-0.023-0.084-0.009-0.122c0.009-0.03,0.022-0.061,0.045-0.084
               c0.032-0.03,0.062-0.061,0.093-0.083c0.09-0.076,0.168-0.144,0.137-0.228c-0.014-0.032-0.03-0.062-0.053-0.099
               c-0.067-0.13-0.129-0.252,0.014-0.351c0.07-0.046,0.123-0.106,0.154-0.183c0.037-0.068,0.061-0.152,0.061-0.228
               c0-0.046-0.008-0.076-0.014-0.107c-0.025-0.084-0.041-0.137,0.121-0.282c0.112-0.091,0.144-0.243,0.166-0.365
               c0.017-0.076,0.03-0.152,0.062-0.206c0.046-0.067,0.175-0.174,0.305-0.25c0.122-0.077,0.251-0.13,0.335-0.13h0.008
               c0.062-0.008,0.146-0.046,0.205-0.129c0.068-0.092,0.123-0.237,0.123-0.45c0-0.03,0-0.061-0.009-0.091
               c-0.03-0.488-0.137-2.474-0.151-2.848c-0.168-0.038-0.646-0.281-1.233-0.609c-1.05-0.586-2.451-1.408-2.931-1.706
               c-0.442-0.273-0.998-0.601-1.447-0.845c-0.352-0.19-0.641-0.335-0.76-0.364c-0.154-0.032-0.55,0.212-0.877,0.433
               c0.038,0.069,0.106,0.205,0.13,0.343c0.022,0.105,0.045,0.243,0.061,0.411c0.016,0.145,0.023,0.327,0.03,0.533
               c0,0.228,0.068,0.266,0.122,0.289c0.062,0.03,0.113,0.061,0.113,0.168c0,0.091,0,0.137,0.031,0.175
               c0.038,0.038,0.106,0.075,0.229,0.145c0.251,0.129,0.183,0.296,0.091,0.532c-0.021,0.054-0.046,0.115-0.061,0.168
               c-0.037,0.114-0.068,0.457-0.092,0.845c-0.038,0.632-0.053,1.378-0.045,1.531c0.007,0.136-0.046,0.297-0.138,0.464
               c-0.099,0.16-0.235,0.335-0.418,0.51c-0.184,0.175-0.396,0.541-0.587,0.868c-0.174,0.289-0.328,0.556-0.449,0.677
               c-0.16,0.161-0.076,0.427-0.022,0.61c0.03,0.091,0.053,0.168,0.045,0.213c0,0.038-0.015,0.068-0.037,0.091
               c0.053,0.062,0.145,0.183,0.197,0.297c0.031,0.069,0.062,0.115,0.084,0.153c0.022,0.03,0.045,0.068,0.068,0.114
               c0.076-0.023,0.236-0.061,0.313-0.023c0.061,0.045,0.076,0.084,0.084,0.144c0.008,0.023,0.015,0.054,0.037,0.092
               c0.016,0.038,0.046,0.061,0.084,0.083c0.046,0.039,0.106,0.084,0.152,0.176c0.046,0.076,0.038,0.137,0.038,0.198
               c0,0.053-0.007,0.106,0.038,0.19c0.016,0.038,0.038,0.061,0.054,0.092h-0.008c0.076,0.129,0.114,0.197,0.092,0.312
               c-0.016,0.076-0.046,0.297-0.039,0.54c0,0.146,0.023,0.297,0.062,0.419c0.062,0.167,0.152,0.305,0.229,0.411
               c0.083,0.121,0.145,0.205,0.106,0.274c-0.039,0.069-0.138,0.038-0.259,0.008c-0.121-0.038-0.267-0.077-0.343-0.038
               c-0.076,0.045-0.175,0.061-0.267,0.068c-0.13,0.008-0.251,0.022-0.289,0.122c-0.06,0.183,0.061,0.343,0.198,0.519l0.008,0.021
               c0.045,0.053,0.075,0.1,0.106,0.145c0.083,0.123,0.159,0.244,0.434,0.524c0.229,0.237,0.328,0.487,0.396,0.672
               c0.046,0.112,0.076,0.188,0.115,0.204c0.067,0.031,0.121,0.046,0.185,0.046c0.043,0.009,0.09-0.008,0.135-0.028
               c0.023-0.018,0.047-0.047,0.068-0.078c0.076-0.098,0.16-0.213,0.352-0.129c0.091,0.045,0.145,0.092,0.175,0.123
               c0.038,0.021,0.046,0.037,0.091,0c0.031-0.023,0.055-0.047,0.084-0.078c0.106-0.112,0.252-0.267,0.596-0.272
               c0.166-0.009,0.297,0,0.388,0c0.138,0.007,0.213,0.017,0.274-0.03c0.061-0.037,0.092-0.099,0.129-0.16
               c0.029-0.068,0.068-0.137,0.137-0.189c0.046-0.038,0.076-0.084,0.092-0.137c0.016-0.045,0.016-0.107,0.008-0.168
               c-0.008-0.045-0.008-0.092-0.008-0.138c0.008-0.046,0.031-0.084,0.053-0.122c0.031-0.029,0.077-0.061,0.131-0.066h0.113
               c0.102,0.021,0.206,0.007,0.336-0.024c0.129-0.03,0.274-0.084,0.448-0.151c0.235-0.084,0.274-0.121,0.343-0.189
               c0.029-0.039,0.077-0.076,0.152-0.139c0.113-0.09,0.251-0.205,0.374-0.312c0.114-0.107,0.198-0.206,0.213-0.267
               c0.007-0.038,0.007-0.084,0.007-0.122c-0.015-0.13-0.021-0.266,0.176-0.35c0.069-0.038,0.137-0.069,0.189-0.1
               c0.154-0.068,0.275-0.129,0.389-0.138C132.058,63.125,132.149,63.133,132.21,63.133"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M132.21,63.133c0-0.029,0-0.067,0.008-0.098c0.008-0.146,0.016-0.26-0.113-0.32c-0.215-0.099-0.223-0.167-0.252-0.366
               c0-0.038-0.008-0.075-0.016-0.121c-0.018-0.092-0.008-0.176-0.008-0.252c0.008-0.144,0.008-0.258-0.145-0.304
               c-0.129-0.038-0.235-0.099-0.306-0.168c-0.039-0.03-0.062-0.068-0.075-0.114c-0.016-0.038-0.023-0.084-0.009-0.122
               c0.009-0.03,0.022-0.061,0.045-0.084c0.032-0.03,0.062-0.061,0.093-0.083c0.09-0.076,0.168-0.144,0.137-0.228
               c-0.014-0.032-0.03-0.062-0.053-0.099c-0.067-0.13-0.129-0.252,0.014-0.351c0.07-0.046,0.123-0.106,0.154-0.183
               c0.037-0.068,0.061-0.152,0.061-0.228c0-0.046-0.008-0.076-0.014-0.107c-0.025-0.084-0.041-0.137,0.121-0.282
               c0.112-0.091,0.144-0.243,0.166-0.365c0.017-0.076,0.03-0.152,0.062-0.206c0.046-0.067,0.175-0.174,0.305-0.25
               c0.122-0.077,0.251-0.13,0.335-0.13h0.008c0.062-0.008,0.146-0.046,0.205-0.129c0.068-0.092,0.123-0.237,0.123-0.45
               c0-0.03,0-0.061-0.009-0.091c-0.03-0.488-0.137-2.474-0.151-2.848c-0.168-0.038-0.646-0.281-1.233-0.609
               c-1.05-0.586-2.451-1.408-2.931-1.706c-0.442-0.273-0.998-0.601-1.447-0.845c-0.352-0.19-0.641-0.335-0.76-0.364
               c-0.154-0.032-0.55,0.212-0.877,0.433c0.038,0.069,0.106,0.205,0.13,0.343c0.022,0.105,0.045,0.243,0.061,0.411
               c0.016,0.145,0.023,0.327,0.03,0.533c0,0.228,0.068,0.266,0.122,0.289c0.062,0.03,0.113,0.061,0.113,0.168
               c0,0.091,0,0.137,0.031,0.175c0.038,0.038,0.106,0.075,0.229,0.145c0.251,0.129,0.183,0.296,0.091,0.532
               c-0.021,0.054-0.046,0.115-0.061,0.168c-0.037,0.114-0.068,0.457-0.092,0.845c-0.038,0.632-0.053,1.378-0.045,1.531
               c0.007,0.136-0.046,0.297-0.138,0.464c-0.099,0.16-0.235,0.335-0.418,0.51c-0.184,0.175-0.396,0.541-0.587,0.868
               c-0.174,0.289-0.328,0.556-0.449,0.677c-0.16,0.161-0.076,0.427-0.022,0.61c0.03,0.091,0.053,0.168,0.045,0.213
               c0,0.038-0.015,0.068-0.037,0.091c0.053,0.062,0.145,0.183,0.197,0.297c0.031,0.069,0.062,0.115,0.084,0.153
               c0.022,0.03,0.045,0.068,0.068,0.114c0.076-0.023,0.236-0.061,0.313-0.023c0.061,0.045,0.076,0.084,0.084,0.144
               c0.008,0.023,0.015,0.054,0.037,0.092c0.016,0.038,0.046,0.061,0.084,0.083c0.046,0.039,0.106,0.084,0.152,0.176
               c0.046,0.076,0.038,0.137,0.038,0.198c0,0.053-0.007,0.106,0.038,0.19c0.016,0.038,0.038,0.061,0.054,0.092h-0.008
               c0.076,0.129,0.114,0.197,0.092,0.312c-0.016,0.076-0.046,0.297-0.039,0.54c0,0.146,0.023,0.297,0.062,0.419
               c0.062,0.167,0.152,0.305,0.229,0.411c0.083,0.121,0.145,0.205,0.106,0.274c-0.039,0.069-0.138,0.038-0.259,0.008
               c-0.121-0.038-0.267-0.077-0.343-0.038c-0.076,0.045-0.175,0.061-0.267,0.068c-0.13,0.008-0.251,0.022-0.289,0.122
               c-0.06,0.183,0.061,0.343,0.198,0.519l0.008,0.021c0.045,0.053,0.075,0.1,0.106,0.145c0.083,0.123,0.159,0.244,0.434,0.524
               c0.229,0.237,0.328,0.487,0.396,0.672c0.046,0.112,0.076,0.188,0.115,0.204c0.067,0.031,0.121,0.046,0.185,0.046
               c0.043,0.009,0.09-0.008,0.135-0.028c0.023-0.018,0.047-0.047,0.068-0.078c0.076-0.098,0.16-0.213,0.352-0.129
               c0.091,0.045,0.145,0.092,0.175,0.123c0.038,0.021,0.046,0.037,0.091,0c0.031-0.023,0.055-0.047,0.084-0.078
               c0.106-0.112,0.252-0.267,0.596-0.272c0.166-0.009,0.297,0,0.388,0c0.138,0.007,0.213,0.017,0.274-0.03
               c0.061-0.037,0.092-0.099,0.129-0.16c0.029-0.068,0.068-0.137,0.137-0.189c0.046-0.038,0.076-0.084,0.092-0.137
               c0.016-0.045,0.016-0.107,0.008-0.168c-0.008-0.045-0.008-0.092-0.008-0.138c0.008-0.046,0.031-0.084,0.053-0.122
               c0.031-0.029,0.077-0.061,0.131-0.066h0.113c0.102,0.021,0.206,0.007,0.336-0.024c0.129-0.03,0.274-0.084,0.448-0.151
               c0.235-0.084,0.274-0.121,0.343-0.189c0.029-0.039,0.077-0.076,0.152-0.139c0.113-0.09,0.251-0.205,0.374-0.312
               c0.114-0.107,0.198-0.206,0.213-0.267c0.007-0.038,0.007-0.084,0.007-0.122c-0.015-0.13-0.021-0.266,0.176-0.35
               c0.069-0.038,0.137-0.069,0.189-0.1c0.154-0.068,0.275-0.129,0.389-0.138C132.058,63.125,132.149,63.133,132.21,63.133z"/>
             <path fill="#808184" d="M126.78,71.312c0.076-0.146,0.16-0.307,0.229-0.534
               c0.03-0.104,0.016-0.22,0-0.317c-0.021-0.198-0.046-0.358,0.205-0.39c0.105-0.016,0.184-0.03,0.244-0.038
               c0.176-0.021,0.297-0.037,0.402-0.068c0.092-0.022,0.138-0.008,0.197,0.022c0.055,0.015,0.114,0.046,0.252,0.046h0.152
               c0.184,0.008,0.305,0.016,0.327-0.1c0.015-0.037,0.008-0.105,0.008-0.189c-0.017-0.281-0.039-0.717,0.351-0.884
               c0.184-0.091,0.305-0.168,0.381-0.229c0.152-0.105,0.213-0.151,0.351-0.037c0.069,0.061,0.13,0.115,0.188,0.176
               c0.101,0.091,0.207,0.197,0.381,0.318c0.123,0.084,0.253,0.152,0.39,0.199c0.16,0.053,0.319,0.075,0.457,0.09
               c0.161,0.008,0.235,0.047,0.306,0.076c0.045,0.022,0.09,0.047,0.145,0.047h0.061c0.092,0,0.131,0.006,0.229-0.146
               c0.007-0.017,0.03-0.046,0.045-0.076c0.122-0.198,0.235-0.389,0.448-0.342c0.031,0.007,0.055,0.015,0.076,0.021
               c0.138,0.03,0.222,0.055,0.365-0.015c0.061-0.022,0.114-0.075,0.176-0.122c0.121-0.113,0.25-0.234,0.457-0.168
               c0.129,0.047,0.229,0.076,0.313,0.092c0.076,0.016,0.137,0.016,0.191,0.01c0.006,0,0.015-0.055,0.028-0.108
               c0.038-0.151,0.085-0.351,0.337-0.28c0.174,0.045,0.243,0.098,0.303,0.145c0.055,0.031,0.084,0.062,0.177,0.062
               c0.065,0,0.12-0.016,0.167-0.022c0.092-0.022,0.158-0.039,0.273,0.039c0.03,0.021,0.053,0.037,0.076,0.053
               c0.061,0.046,0.067,0.063,0.229,0.015c0.053-0.022,0.107-0.03,0.152-0.038c0.016-0.091,0.062-0.365-0.022-0.449
               c-0.065-0.076-0.138-0.112-0.214-0.152c-0.037-0.021-0.084-0.045-0.131-0.074c-0.045-0.039-0.104-0.055-0.166-0.068
               c-0.105-0.039-0.197-0.068-0.205-0.168c-0.01-0.037-0.01-0.068-0.016-0.1c-0.016-0.053-0.023-0.105-0.009-0.175
               c0.015-0.046,0.015-0.121-0.021-0.206c-0.023-0.061-0.078-0.129-0.153-0.197c-0.06-0.054-0.167-0.113-0.272-0.175
               c-0.229-0.122-0.473-0.261-0.48-0.44c-0.008-0.107,0.023-0.191,0.047-0.244c0.007-0.016,0.007-0.029,0.016-0.039
               c0.006-0.029,0-0.045-0.047-0.061c-0.137-0.039-0.189-0.092-0.244-0.146c-0.021-0.029-0.045-0.053-0.082-0.075
               c-0.039-0.03-0.121-0.037-0.213-0.046c-0.13-0.016-0.253-0.022-0.298-0.106c-0.03-0.046-0.03-0.099-0.039-0.151
               c0-0.099-0.007-0.205-0.144-0.26c-0.131-0.053-0.26-0.113-0.336-0.189c-0.092-0.092-0.129-0.207-0.069-0.35
               c0.054-0.123,0.092-0.26,0.101-0.365c0.008-0.092,0.008-0.161-0.022-0.184c-0.045-0.054-0.281-0.305-0.44-0.556
               c-0.101-0.137-0.176-0.282-0.176-0.389v-0.015c-0.054,0-0.145-0.01-0.236,0c-0.1,0.007-0.213,0.06-0.352,0.129
               c-0.061,0.029-0.12,0.068-0.189,0.099c-0.137,0.061-0.129,0.153-0.121,0.252c0,0.045,0.008,0.099-0.008,0.151
               c-0.015,0.084-0.113,0.198-0.244,0.32c-0.121,0.114-0.266,0.229-0.381,0.313c-0.066,0.053-0.105,0.099-0.145,0.129
               c-0.075,0.084-0.122,0.129-0.372,0.221c-0.185,0.063-0.328,0.113-0.465,0.15c-0.138,0.031-0.259,0.047-0.374,0.031
               c-0.038-0.007-0.061-0.007-0.083-0.007c-0.03,0.007-0.054,0.022-0.062,0.038c-0.022,0.016-0.029,0.046-0.029,0.066
               c-0.01,0.039-0.01,0.07,0,0.107c0.007,0.075,0.007,0.145-0.01,0.213c-0.021,0.068-0.061,0.131-0.129,0.19
               c-0.045,0.038-0.076,0.091-0.105,0.151c-0.047,0.076-0.084,0.151-0.16,0.199c-0.092,0.061-0.184,0.061-0.335,0.051
               c-0.099-0.006-0.222-0.015-0.388-0.006c-0.299,0.016-0.42,0.146-0.52,0.243c-0.029,0.029-0.061,0.062-0.099,0.093
               c-0.106,0.075-0.138,0.053-0.214-0.009c-0.03-0.028-0.075-0.069-0.159-0.101c-0.114-0.061-0.175,0.023-0.229,0.092
               c-0.022,0.038-0.054,0.077-0.091,0.1c-0.062,0.039-0.123,0.053-0.198,0.053c-0.067-0.006-0.137-0.021-0.212-0.053
               c-0.023-0.007-0.039-0.021-0.054-0.037c-0.038,0.084-0.099,0.189-0.19,0.25c-0.084,0.063-0.106,0.16-0.13,0.236
               c-0.015,0.031-0.022,0.053-0.03,0.084c-0.015,0.023-0.037,0.061-0.061,0.1c-0.054,0.076-0.13,0.183-0.16,0.297
               c-0.03,0.099-0.068,0.168-0.114,0.221c-0.046,0.055-0.091,0.092-0.122,0.113c-0.022,0.017-0.068,0.063-0.083,0.107
               c0,0.006-0.009,0.022-0.009,0.03c0,0.007,0,0.007,0.009,0.017c0,0.006,0.015,0.014,0.03,0.014
               c0.183,0.068,0.145,0.191,0.129,0.244c-0.007,0.022-0.015,0.151-0.007,0.281c0.007,0.076,0.015,0.146,0.038,0.197
               c0.046,0.107,0.046,0.214,0.037,0.305c0,0.055,0,0.1,0.009,0.139c0,0.029,0.045,0.061,0.091,0.1
               c0.076,0.061,0.176,0.129,0.198,0.273c0.022,0.121,0.007,0.197,0,0.259c-0.008,0.053-0.016,0.084,0.007,0.114
               c0.023,0.045,0.106,0.145,0.198,0.25c0.099,0.115,0.221,0.252,0.313,0.344c0.053,0.063,0.114,0.1,0.159,0.139
               c0.107,0.074,0.176,0.129,0.176,0.258c0.008,0.068,0,0.121,0,0.184c-0.008,0.082-0.016,0.166,0.008,0.242
               C126.758,71.257,126.767,71.291,126.78,71.312"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M126.78,71.312c0.076-0.146,0.16-0.307,0.229-0.534c0.03-0.104,0.016-0.22,0-0.317c-0.021-0.198-0.046-0.358,0.205-0.39
               c0.105-0.016,0.184-0.03,0.244-0.038c0.176-0.021,0.297-0.037,0.402-0.068c0.092-0.022,0.138-0.008,0.197,0.022
               c0.055,0.015,0.114,0.046,0.252,0.046h0.152c0.184,0.008,0.305,0.016,0.327-0.1c0.015-0.037,0.008-0.105,0.008-0.189
               c-0.017-0.281-0.039-0.717,0.351-0.884c0.184-0.091,0.305-0.168,0.381-0.229c0.152-0.105,0.213-0.151,0.351-0.037
               c0.069,0.061,0.13,0.115,0.188,0.176c0.101,0.091,0.207,0.197,0.381,0.318c0.123,0.084,0.253,0.152,0.39,0.199
               c0.16,0.053,0.319,0.075,0.457,0.09c0.161,0.008,0.235,0.047,0.306,0.076c0.045,0.022,0.09,0.047,0.145,0.047h0.061
               c0.092,0,0.131,0.006,0.229-0.146c0.007-0.017,0.03-0.046,0.045-0.076c0.122-0.198,0.235-0.389,0.448-0.342
               c0.031,0.007,0.055,0.015,0.076,0.021c0.138,0.03,0.222,0.055,0.365-0.015c0.061-0.022,0.114-0.075,0.176-0.122
               c0.121-0.113,0.25-0.234,0.457-0.168c0.129,0.047,0.229,0.076,0.313,0.092c0.076,0.016,0.137,0.016,0.191,0.01
               c0.006,0,0.015-0.055,0.028-0.108c0.038-0.151,0.085-0.351,0.337-0.28c0.174,0.045,0.243,0.098,0.303,0.145
               c0.055,0.031,0.084,0.062,0.177,0.062c0.065,0,0.12-0.016,0.167-0.022c0.092-0.022,0.158-0.039,0.273,0.039
               c0.03,0.021,0.053,0.037,0.076,0.053c0.061,0.046,0.067,0.063,0.229,0.015c0.053-0.022,0.107-0.03,0.152-0.038
               c0.016-0.091,0.062-0.365-0.022-0.449c-0.065-0.076-0.138-0.112-0.214-0.152c-0.037-0.021-0.084-0.045-0.131-0.074
               c-0.045-0.039-0.104-0.055-0.166-0.068c-0.105-0.039-0.197-0.068-0.205-0.168c-0.01-0.037-0.01-0.068-0.016-0.1
               c-0.016-0.053-0.023-0.105-0.009-0.175c0.015-0.046,0.015-0.121-0.021-0.206c-0.023-0.061-0.078-0.129-0.153-0.197
               c-0.06-0.054-0.167-0.113-0.272-0.175c-0.229-0.122-0.473-0.261-0.48-0.44c-0.008-0.107,0.023-0.191,0.047-0.244
               c0.007-0.016,0.007-0.029,0.016-0.039c0.006-0.029,0-0.045-0.047-0.061c-0.137-0.039-0.189-0.092-0.244-0.146
               c-0.021-0.029-0.045-0.053-0.082-0.075c-0.039-0.03-0.121-0.037-0.213-0.046c-0.13-0.016-0.253-0.022-0.298-0.106
               c-0.03-0.046-0.03-0.099-0.039-0.151c0-0.099-0.007-0.205-0.144-0.26c-0.131-0.053-0.26-0.113-0.336-0.189
               c-0.092-0.092-0.129-0.207-0.069-0.35c0.054-0.123,0.092-0.26,0.101-0.365c0.008-0.092,0.008-0.161-0.022-0.184
               c-0.045-0.054-0.281-0.305-0.44-0.556c-0.101-0.137-0.176-0.282-0.176-0.389v-0.015c-0.054,0-0.145-0.01-0.236,0
               c-0.1,0.007-0.213,0.06-0.352,0.129c-0.061,0.029-0.12,0.068-0.189,0.099c-0.137,0.061-0.129,0.153-0.121,0.252
               c0,0.045,0.008,0.099-0.008,0.151c-0.015,0.084-0.113,0.198-0.244,0.32c-0.121,0.114-0.266,0.229-0.381,0.313
               c-0.066,0.053-0.105,0.099-0.145,0.129c-0.075,0.084-0.122,0.129-0.372,0.221c-0.185,0.063-0.328,0.113-0.465,0.15
               c-0.138,0.031-0.259,0.047-0.374,0.031c-0.038-0.007-0.061-0.007-0.083-0.007c-0.03,0.007-0.054,0.022-0.062,0.038
               c-0.022,0.016-0.029,0.046-0.029,0.066c-0.01,0.039-0.01,0.07,0,0.107c0.007,0.075,0.007,0.145-0.01,0.213
               c-0.021,0.068-0.061,0.131-0.129,0.19c-0.045,0.038-0.076,0.091-0.105,0.151c-0.047,0.076-0.084,0.151-0.16,0.199
               c-0.092,0.061-0.184,0.061-0.335,0.051c-0.099-0.006-0.222-0.015-0.388-0.006c-0.299,0.016-0.42,0.146-0.52,0.243
               c-0.029,0.029-0.061,0.062-0.099,0.093c-0.106,0.075-0.138,0.053-0.214-0.009c-0.03-0.028-0.075-0.069-0.159-0.101
               c-0.114-0.061-0.175,0.023-0.229,0.092c-0.022,0.038-0.054,0.077-0.091,0.1c-0.062,0.039-0.123,0.053-0.198,0.053
               c-0.067-0.006-0.137-0.021-0.212-0.053c-0.023-0.007-0.039-0.021-0.054-0.037c-0.038,0.084-0.099,0.189-0.19,0.25
               c-0.084,0.063-0.106,0.16-0.13,0.236c-0.015,0.031-0.022,0.053-0.03,0.084c-0.015,0.023-0.037,0.061-0.061,0.1
               c-0.054,0.076-0.13,0.183-0.16,0.297c-0.03,0.099-0.068,0.168-0.114,0.221c-0.046,0.055-0.091,0.092-0.122,0.113
               c-0.022,0.017-0.068,0.063-0.083,0.107c0,0.006-0.009,0.022-0.009,0.03c0,0.007,0,0.007,0.009,0.017
               c0,0.006,0.015,0.014,0.03,0.014c0.183,0.068,0.145,0.191,0.129,0.244c-0.007,0.022-0.015,0.151-0.007,0.281
               c0.007,0.076,0.015,0.146,0.038,0.197c0.046,0.107,0.046,0.214,0.037,0.305c0,0.055,0,0.1,0.009,0.139
               c0,0.029,0.045,0.061,0.091,0.1c0.076,0.061,0.176,0.129,0.198,0.273c0.022,0.121,0.007,0.197,0,0.259
               c-0.008,0.053-0.016,0.084,0.007,0.114c0.023,0.045,0.106,0.145,0.198,0.25c0.099,0.115,0.221,0.252,0.313,0.344
               c0.053,0.063,0.114,0.1,0.159,0.139c0.107,0.074,0.176,0.129,0.176,0.258c0.008,0.068,0,0.121,0,0.184
               c-0.008,0.082-0.016,0.166,0.008,0.242C126.758,71.257,126.767,71.291,126.78,71.312z"/>
             <path fill="#808184" d="M120.599,69.042c0.03,0.045,0.038,0.115,0.129,0.213
               c0.146,0.152,0.221,0.084,0.236,0.268c0.008,0.182,0.099,0.236,0.289,0.307c0.19,0.066,0.205,0.006,0.252,0.28
               c0.037,0.267,0.213,0.329,0.174,0.54c-0.029,0.213-0.189,0.282-0.189,0.518c0,0.031,0,0.063,0.006,0.092
               c0.169,0.018,0.451,0.047,0.688,0.068c0.205,0.016,0.38,0.03,0.44,0.03c0.084-0.008,0.13-0.037,0.176-0.067
               c0.054-0.031,0.106-0.061,0.19-0.061c0.068-0.009,0.205,0,0.365,0.014c0.198,0.008,0.435,0.032,0.624,0.047
               c0.092,0.016,0.183,0.023,0.274,0.03c0.298,0.037,0.632,0.075,0.853,0.06c0.092,0,0.16-0.014,0.221-0.021
               c0.146-0.016,0.236-0.029,0.366,0.047c0.091,0.053,0.168,0.068,0.251,0.075c0.068,0.017,0.145,0.022,0.221,0.054l0.03,0.016
               c0.152,0.054,0.251,0.098,0.381,0.123c0.045,0.007,0.054-0.01,0.062-0.047c0.016-0.045,0.03-0.106,0.068-0.176l0.016-0.029
               c-0.016-0.03-0.054-0.092-0.076-0.185c-0.023-0.092-0.023-0.183-0.016-0.274c0.008-0.053,0.008-0.112,0.008-0.166
               c0-0.084-0.054-0.121-0.138-0.184c-0.047-0.037-0.106-0.082-0.175-0.15c-0.092-0.092-0.214-0.229-0.313-0.344
               c-0.1-0.115-0.184-0.214-0.213-0.26c-0.038-0.068-0.03-0.122-0.016-0.189c0.008-0.053,0.016-0.121,0-0.221
               c-0.022-0.107-0.099-0.168-0.16-0.223c-0.068-0.045-0.121-0.083-0.129-0.15c-0.016-0.054-0.007-0.105-0.007-0.16
               c0-0.084,0.007-0.184-0.031-0.269c-0.03-0.06-0.046-0.144-0.046-0.233c-0.015-0.137,0-0.283,0.008-0.306v-0.007
               c0.008-0.023,0.022-0.086-0.069-0.115c-0.037-0.016-0.06-0.029-0.075-0.061c-0.016-0.016-0.022-0.047-0.022-0.068
               c0-0.023,0-0.046,0.015-0.068c0.023-0.061,0.076-0.129,0.114-0.152c0.03-0.022,0.068-0.053,0.107-0.09
               c0.029-0.047,0.067-0.108,0.09-0.189c0.039-0.132,0.115-0.245,0.176-0.329c0.023-0.03,0.038-0.069,0.053-0.084
               c0.008-0.015,0.016-0.038,0.022-0.069c0.024-0.09,0.062-0.205,0.177-0.287c0.098-0.068,0.159-0.221,0.182-0.267
               c-0.022-0.038-0.037-0.084-0.061-0.138c-0.061-0.169-0.152-0.41-0.373-0.633c-0.274-0.282-0.357-0.411-0.441-0.532
               c-0.03-0.046-0.061-0.091-0.099-0.146l-0.016-0.022c-0.152-0.19-0.289-0.382-0.206-0.609c0.062-0.159,0.214-0.175,0.366-0.19
               c0.091-0.008,0.175-0.022,0.228-0.053c0.115-0.068,0.282-0.015,0.42,0.023l0.145,0.036c0.008-0.007-0.039-0.067-0.099-0.159
               c-0.077-0.114-0.184-0.258-0.237-0.434c-0.045-0.137-0.069-0.297-0.069-0.457c-0.007-0.251,0.017-0.487,0.039-0.564
               c0.016-0.075-0.015-0.129-0.076-0.243c-0.015-0.023-0.03-0.053-0.054-0.084c-0.053-0.114-0.053-0.182-0.045-0.251
               c0-0.046,0-0.092-0.031-0.145c-0.037-0.069-0.083-0.107-0.121-0.138c-0.046-0.038-0.085-0.068-0.114-0.122
               c-0.022-0.045-0.038-0.083-0.046-0.114c-0.007-0.038-0.007-0.06-0.038-0.076c-0.045-0.022-0.152,0-0.213,0.023
               c0.038,0.084,0.061,0.16,0.075,0.244c0.023,0.084,0.031,0.183,0.023,0.289c-0.007,0.076,0.062,0.092,0.122,0.107
               c0.076,0.015,0.152,0.03,0.167,0.121c0,0.031,0.016,0.062,0.023,0.099c0.038,0.099,0.084,0.222,0,0.465
               c-0.092,0.243-0.236,0.289-0.403,0.335c-0.054,0.015-0.106,0.03-0.16,0.053c-0.13,0.062-0.198,0.366-0.236,0.556
               c-0.015,0.077-0.022,0.129-0.038,0.169c-0.008,0.014-0.016,0.029-0.03,0.052c-0.053,0.114-0.13,0.282-0.121,0.427
               c0.007,0.207-0.153,0.267-0.282,0.319c-0.008,0-0.023,0.009-0.03,0.009c-0.031,0.016-0.008,0.146,0.015,0.273
               c0.023,0.115,0.046,0.229,0.038,0.305c-0.022,0.151-0.197,0.236-0.365,0.319c-0.076,0.03-0.145,0.069-0.198,0.101
               c-0.129,0.084-0.129,0.305-0.121,0.493v0.168c-0.008,0.169-0.07,0.197-0.153,0.243c-0.037,0.023-0.083,0.046-0.137,0.107
               c-0.061,0.068-0.03,0.137,0,0.197c0.038,0.084,0.068,0.159-0.015,0.236c-0.023,0.021-0.046,0.037-0.061,0.053
               c-0.055,0.047-0.077,0.061-0.168,0.252c-0.122,0.242-0.168,0.213-0.289,0.15c-0.023-0.008-0.046-0.021-0.077-0.037
               c-0.121-0.044-0.159-0.13-0.19-0.214c-0.022-0.037-0.038-0.075-0.062-0.104c-0.029-0.039-0.045-0.078-0.06-0.115
               c-0.022-0.047-0.038-0.092-0.137-0.092c-0.084,0-0.152,0.022-0.223,0.045c-0.075,0.031-0.15,0.055-0.258,0.055
               c-0.091,0-0.114,0.063-0.145,0.152c-0.015,0.045-0.03,0.105-0.068,0.168c-0.061,0.121-0.267,0.272-0.434,0.396
               c-0.115,0.082-0.206,0.151-0.214,0.174c-0.015,0.031-0.008,0.13,0,0.244c0.008,0.175,0.023,0.39-0.038,0.502
               c-0.038,0.077-0.106,0.222-0.167,0.366L120.599,69.042z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M120.599,69.042c0.03,0.045,0.038,0.115,0.129,0.213c0.146,0.152,0.221,0.084,0.236,0.268c0.008,0.182,0.099,0.236,0.289,0.307
               c0.19,0.066,0.205,0.006,0.252,0.28c0.037,0.267,0.213,0.329,0.174,0.54c-0.029,0.213-0.189,0.282-0.189,0.518
               c0,0.031,0,0.063,0.006,0.092c0.169,0.018,0.451,0.047,0.688,0.068c0.205,0.016,0.38,0.03,0.44,0.03
               c0.084-0.008,0.13-0.037,0.176-0.067c0.054-0.031,0.106-0.061,0.19-0.061c0.068-0.009,0.205,0,0.365,0.014
               c0.198,0.008,0.435,0.032,0.624,0.047c0.092,0.016,0.183,0.023,0.274,0.03c0.298,0.037,0.632,0.075,0.853,0.06
               c0.092,0,0.16-0.014,0.221-0.021c0.146-0.016,0.236-0.029,0.366,0.047c0.091,0.053,0.168,0.068,0.251,0.075
               c0.068,0.017,0.145,0.022,0.221,0.054l0.03,0.016c0.152,0.054,0.251,0.098,0.381,0.123c0.045,0.007,0.054-0.01,0.062-0.047
               c0.016-0.045,0.03-0.106,0.068-0.176l0.016-0.029c-0.016-0.03-0.054-0.092-0.076-0.185c-0.023-0.092-0.023-0.183-0.016-0.274
               c0.008-0.053,0.008-0.112,0.008-0.166c0-0.084-0.054-0.121-0.138-0.184c-0.047-0.037-0.106-0.082-0.175-0.15
               c-0.092-0.092-0.214-0.229-0.313-0.344c-0.1-0.115-0.184-0.214-0.213-0.26c-0.038-0.068-0.03-0.122-0.016-0.189
               c0.008-0.053,0.016-0.121,0-0.221c-0.022-0.107-0.099-0.168-0.16-0.223c-0.068-0.045-0.121-0.083-0.129-0.15
               c-0.016-0.054-0.007-0.105-0.007-0.16c0-0.084,0.007-0.184-0.031-0.269c-0.03-0.06-0.046-0.144-0.046-0.233
               c-0.015-0.137,0-0.283,0.008-0.306v-0.007c0.008-0.023,0.022-0.086-0.069-0.115c-0.037-0.016-0.06-0.029-0.075-0.061
               c-0.016-0.016-0.022-0.047-0.022-0.068c0-0.023,0-0.046,0.015-0.068c0.023-0.061,0.076-0.129,0.114-0.152
               c0.03-0.022,0.068-0.053,0.107-0.09c0.029-0.047,0.067-0.108,0.09-0.189c0.039-0.132,0.115-0.245,0.176-0.329
               c0.023-0.03,0.038-0.069,0.053-0.084c0.008-0.015,0.016-0.038,0.022-0.069c0.024-0.09,0.062-0.205,0.177-0.287
               c0.098-0.068,0.159-0.221,0.182-0.267c-0.022-0.038-0.037-0.084-0.061-0.138c-0.061-0.169-0.152-0.41-0.373-0.633
               c-0.274-0.282-0.357-0.411-0.441-0.532c-0.03-0.046-0.061-0.091-0.099-0.146l-0.016-0.022c-0.152-0.19-0.289-0.382-0.206-0.609
               c0.062-0.159,0.214-0.175,0.366-0.19c0.091-0.008,0.175-0.022,0.228-0.053c0.115-0.068,0.282-0.015,0.42,0.023l0.145,0.036
               c0.008-0.007-0.039-0.067-0.099-0.159c-0.077-0.114-0.184-0.258-0.237-0.434c-0.045-0.137-0.069-0.297-0.069-0.457
               c-0.007-0.251,0.017-0.487,0.039-0.564c0.016-0.075-0.015-0.129-0.076-0.243c-0.015-0.023-0.03-0.053-0.054-0.084
               c-0.053-0.114-0.053-0.182-0.045-0.251c0-0.046,0-0.092-0.031-0.145c-0.037-0.069-0.083-0.107-0.121-0.138
               c-0.046-0.038-0.085-0.068-0.114-0.122c-0.022-0.045-0.038-0.083-0.046-0.114c-0.007-0.038-0.007-0.06-0.038-0.076
               c-0.045-0.022-0.152,0-0.213,0.023c0.038,0.084,0.061,0.16,0.075,0.244c0.023,0.084,0.031,0.183,0.023,0.289
               c-0.007,0.076,0.062,0.092,0.122,0.107c0.076,0.015,0.152,0.03,0.167,0.121c0,0.031,0.016,0.062,0.023,0.099
               c0.038,0.099,0.084,0.222,0,0.465c-0.092,0.243-0.236,0.289-0.403,0.335c-0.054,0.015-0.106,0.03-0.16,0.053
               c-0.13,0.062-0.198,0.366-0.236,0.556c-0.015,0.077-0.022,0.129-0.038,0.169c-0.008,0.014-0.016,0.029-0.03,0.052
               c-0.053,0.114-0.13,0.282-0.121,0.427c0.007,0.207-0.153,0.267-0.282,0.319c-0.008,0-0.023,0.009-0.03,0.009
               c-0.031,0.016-0.008,0.146,0.015,0.273c0.023,0.115,0.046,0.229,0.038,0.305c-0.022,0.151-0.197,0.236-0.365,0.319
               c-0.076,0.03-0.145,0.069-0.198,0.101c-0.129,0.084-0.129,0.305-0.121,0.493v0.168c-0.008,0.169-0.07,0.197-0.153,0.243
               c-0.037,0.023-0.083,0.046-0.137,0.107c-0.061,0.068-0.03,0.137,0,0.197c0.038,0.084,0.068,0.159-0.015,0.236
               c-0.023,0.021-0.046,0.037-0.061,0.053c-0.055,0.047-0.077,0.061-0.168,0.252c-0.122,0.242-0.168,0.213-0.289,0.15
               c-0.023-0.008-0.046-0.021-0.077-0.037c-0.121-0.044-0.159-0.13-0.19-0.214c-0.022-0.037-0.038-0.075-0.062-0.104
               c-0.029-0.039-0.045-0.078-0.06-0.115c-0.022-0.047-0.038-0.092-0.137-0.092c-0.084,0-0.152,0.022-0.223,0.045
               c-0.075,0.031-0.15,0.055-0.258,0.055c-0.091,0-0.114,0.063-0.145,0.152c-0.015,0.045-0.03,0.105-0.068,0.168
               c-0.061,0.121-0.267,0.272-0.434,0.396c-0.115,0.082-0.206,0.151-0.214,0.174c-0.015,0.031-0.008,0.13,0,0.244
               c0.008,0.175,0.023,0.39-0.038,0.502c-0.038,0.077-0.106,0.222-0.167,0.366L120.599,69.042z"/>
             <path fill="#808184" d="M115.862,67.419c0.16-0.021,0.32-0.037,0.487-0.021
               c0.465,0.028,0.867,0.006,0.99,0.175c0.121,0.174,0.312,0.273,0.418,0.532c0.1,0.259,0.146,0.282,0.244,0.754
               c0.106,0.474,0.281,0.495,0.678,0.519c0.396,0.023,0.435-0.039,0.799-0.146c0.358-0.114,0.358-0.138,0.541-0.092
               c0.176,0.046,0.259-0.158,0.449-0.146h0.038l0.023-0.06c0.068-0.146,0.137-0.298,0.175-0.375
               c0.045-0.091,0.038-0.288,0.022-0.448c-0.007-0.13-0.015-0.242,0.016-0.297c0.023-0.046,0.122-0.122,0.244-0.206
               c0.159-0.12,0.35-0.267,0.402-0.364c0.031-0.054,0.054-0.107,0.068-0.16c0.038-0.131,0.069-0.215,0.236-0.215
               c0.091,0,0.16-0.021,0.229-0.045c0.076-0.029,0.152-0.053,0.251-0.053c0.167,0,0.191,0.068,0.229,0.152
               c0.015,0.029,0.022,0.061,0.045,0.084c0.039,0.045,0.054,0.091,0.076,0.137c0.031,0.061,0.054,0.13,0.138,0.16
               c0.03,0.015,0.061,0.029,0.084,0.037c0.053,0.031,0.075,0.037,0.151-0.106c0.107-0.204,0.13-0.228,0.198-0.282
               c0.015-0.014,0.037-0.029,0.062-0.053c0.022-0.021,0.007-0.068-0.016-0.113c-0.046-0.09-0.093-0.199,0.016-0.313
               c0.06-0.066,0.121-0.1,0.167-0.129c0.054-0.029,0.092-0.047,0.092-0.159c0.007-0.046,0-0.101,0-0.161
               c0-0.22,0-0.465,0.167-0.578c0.068-0.037,0.137-0.074,0.213-0.105c0.145-0.068,0.296-0.145,0.305-0.234
               c0.008-0.07-0.009-0.177-0.03-0.275c-0.03-0.176-0.061-0.342,0.046-0.388c0.007-0.01,0.022-0.01,0.038-0.017
               c0.091-0.038,0.213-0.084,0.206-0.213c-0.009-0.176,0.083-0.357,0.136-0.473c0.008-0.022,0.023-0.045,0.031-0.061
               c0.008-0.022,0.015-0.077,0.03-0.145c0.045-0.205,0.114-0.541,0.297-0.625c0.061-0.022,0.114-0.037,0.168-0.053
               c0.145-0.046,0.267-0.084,0.335-0.281c0.075-0.205,0.038-0.304,0.007-0.396c-0.016-0.038-0.03-0.076-0.038-0.115
               c0-0.022-0.037-0.03-0.084-0.037c-0.099-0.03-0.205-0.054-0.197-0.214c0-0.098,0-0.182-0.022-0.259
               c-0.016-0.083-0.038-0.159-0.076-0.243c-0.038-0.084-0.068-0.13-0.1-0.175c-0.03-0.038-0.053-0.084-0.091-0.168
               c-0.054-0.106-0.153-0.228-0.198-0.281c-0.03,0.007-0.068,0.022-0.106,0.03c-0.084,0.03-0.175,0.054-0.252,0.114
               c-0.037,0.031-0.076,0.069-0.107,0.099c-0.113,0.115-0.22,0.214-0.524,0.229c-0.251,0.015-0.373-0.046-0.494-0.107
               c-0.068-0.038-0.13-0.069-0.221-0.091c-0.122-0.023-0.351-0.007-0.579,0.038c-0.214,0.037-0.419,0.106-0.533,0.19
               c-0.068,0.038-0.114,0.083-0.152,0.122c-0.137,0.122-0.221,0.198-0.578,0.175c-0.373-0.03-0.724-0.251-0.914-0.373
               c-0.038-0.03-0.076-0.053-0.1-0.061c-0.075-0.046-0.144,0.008-0.25,0.076c-0.039,0.031-0.084,0.061-0.13,0.091
               c-0.106,0.063-0.206,0.076-0.305,0.063c-0.106-0.016-0.197-0.077-0.274-0.161c-0.053-0.06-0.091-0.129-0.129-0.19
               c-0.054-0.084-0.099-0.159-0.183-0.221c-0.062-0.045-0.122-0.053-0.198-0.068c-0.091-0.015-0.197-0.031-0.327-0.122
               c-0.114-0.092-0.145-0.068-0.176-0.038c-0.03,0.016-0.061,0.046-0.114,0.061c-0.022,0.007-0.053,0.015-0.069,0.022
               c-0.09,0.039-0.144,0.062-0.388,0.039c-0.19-0.015-0.258,0.061-0.32,0.122c-0.021,0.03-0.053,0.061-0.091,0.083
               c-0.068,0.038-0.083,0.153-0.106,0.297c0,0.031-0.007,0.061-0.015,0.099c-0.015,0.114-0.054,0.198-0.114,0.275
               c-0.054,0.068-0.122,0.129-0.198,0.182c-0.061,0.046-0.084,0.122-0.099,0.213c-0.008,0.099,0.007,0.214,0.023,0.328
               c0.022,0.122,0,0.175-0.062,0.183h-0.016c0.008,0.083-0.007,0.22-0.106,0.312c-0.054,0.054,0.03,0.146,0.1,0.221
               c0.045,0.054,0.084,0.1,0.106,0.146c0.03,0.066,0.053,0.128,0.075,0.189c0.016,0.068,0.023,0.136,0.016,0.22
               c0,0.077-0.038,0.114-0.068,0.146c-0.03,0.03-0.054,0.061-0.03,0.159c0.053,0.206-0.054,0.289-0.183,0.389
               c-0.038,0.031-0.076,0.062-0.114,0.1c-0.062,0.061-0.054,0.13-0.046,0.205c0.008,0.1,0.016,0.213-0.084,0.343
               c-0.068,0.099-0.129,0.13-0.175,0.152c-0.046,0.022-0.075,0.038-0.106,0.229c-0.03,0.189-0.046,0.402-0.038,0.641
               c0.008,0.213,0.038,0.447,0.084,0.707C115.946,66.865,115.908,67.193,115.862,67.419"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M115.862,67.419c0.16-0.021,0.32-0.037,0.487-0.021c0.465,0.028,0.867,0.006,0.99,0.175c0.121,0.174,0.312,0.273,0.418,0.532
               c0.1,0.259,0.146,0.282,0.244,0.754c0.106,0.474,0.281,0.495,0.678,0.519c0.396,0.023,0.435-0.039,0.799-0.146
               c0.358-0.114,0.358-0.138,0.541-0.092c0.176,0.046,0.259-0.158,0.449-0.146h0.038l0.023-0.06
               c0.068-0.146,0.137-0.298,0.175-0.375c0.045-0.091,0.038-0.288,0.022-0.448c-0.007-0.13-0.015-0.242,0.016-0.297
               c0.023-0.046,0.122-0.122,0.244-0.206c0.159-0.12,0.35-0.267,0.402-0.364c0.031-0.054,0.054-0.107,0.068-0.16
               c0.038-0.131,0.069-0.215,0.236-0.215c0.091,0,0.16-0.021,0.229-0.045c0.076-0.029,0.152-0.053,0.251-0.053
               c0.167,0,0.191,0.068,0.229,0.152c0.015,0.029,0.022,0.061,0.045,0.084c0.039,0.045,0.054,0.091,0.076,0.137
               c0.031,0.061,0.054,0.13,0.138,0.16c0.03,0.015,0.061,0.029,0.084,0.037c0.053,0.031,0.075,0.037,0.151-0.106
               c0.107-0.204,0.13-0.228,0.198-0.282c0.015-0.014,0.037-0.029,0.062-0.053c0.022-0.021,0.007-0.068-0.016-0.113
               c-0.046-0.09-0.093-0.199,0.016-0.313c0.06-0.066,0.121-0.1,0.167-0.129c0.054-0.029,0.092-0.047,0.092-0.159
               c0.007-0.046,0-0.101,0-0.161c0-0.22,0-0.465,0.167-0.578c0.068-0.037,0.137-0.074,0.213-0.105
               c0.145-0.068,0.296-0.145,0.305-0.234c0.008-0.07-0.009-0.177-0.03-0.275c-0.03-0.176-0.061-0.342,0.046-0.388
               c0.007-0.01,0.022-0.01,0.038-0.017c0.091-0.038,0.213-0.084,0.206-0.213c-0.009-0.176,0.083-0.357,0.136-0.473
               c0.008-0.022,0.023-0.045,0.031-0.061c0.008-0.022,0.015-0.077,0.03-0.145c0.045-0.205,0.114-0.541,0.297-0.625
               c0.061-0.022,0.114-0.037,0.168-0.053c0.145-0.046,0.267-0.084,0.335-0.281c0.075-0.205,0.038-0.304,0.007-0.396
               c-0.016-0.038-0.03-0.076-0.038-0.115c0-0.022-0.037-0.03-0.084-0.037c-0.099-0.03-0.205-0.054-0.197-0.214
               c0-0.098,0-0.182-0.022-0.259c-0.016-0.083-0.038-0.159-0.076-0.243c-0.038-0.084-0.068-0.13-0.1-0.175
               c-0.03-0.038-0.053-0.084-0.091-0.168c-0.054-0.106-0.153-0.228-0.198-0.281c-0.03,0.007-0.068,0.022-0.106,0.03
               c-0.084,0.03-0.175,0.054-0.252,0.114c-0.037,0.031-0.076,0.069-0.107,0.099c-0.113,0.115-0.22,0.214-0.524,0.229
               c-0.251,0.015-0.373-0.046-0.494-0.107c-0.068-0.038-0.13-0.069-0.221-0.091c-0.122-0.023-0.351-0.007-0.579,0.038
               c-0.214,0.037-0.419,0.106-0.533,0.19c-0.068,0.038-0.114,0.083-0.152,0.122c-0.137,0.122-0.221,0.198-0.578,0.175
               c-0.373-0.03-0.724-0.251-0.914-0.373c-0.038-0.03-0.076-0.053-0.1-0.061c-0.075-0.046-0.144,0.008-0.25,0.076
               c-0.039,0.031-0.084,0.061-0.13,0.091c-0.106,0.063-0.206,0.076-0.305,0.063c-0.106-0.016-0.197-0.077-0.274-0.161
               c-0.053-0.06-0.091-0.129-0.129-0.19c-0.054-0.084-0.099-0.159-0.183-0.221c-0.062-0.045-0.122-0.053-0.198-0.068
               c-0.091-0.015-0.197-0.031-0.327-0.122c-0.114-0.092-0.145-0.068-0.176-0.038c-0.03,0.016-0.061,0.046-0.114,0.061
               c-0.022,0.007-0.053,0.015-0.069,0.022c-0.09,0.039-0.144,0.062-0.388,0.039c-0.19-0.015-0.258,0.061-0.32,0.122
               c-0.021,0.03-0.053,0.061-0.091,0.083c-0.068,0.038-0.083,0.153-0.106,0.297c0,0.031-0.007,0.061-0.015,0.099
               c-0.015,0.114-0.054,0.198-0.114,0.275c-0.054,0.068-0.122,0.129-0.198,0.182c-0.061,0.046-0.084,0.122-0.099,0.213
               c-0.008,0.099,0.007,0.214,0.023,0.328c0.022,0.122,0,0.175-0.062,0.183h-0.016c0.008,0.083-0.007,0.22-0.106,0.312
               c-0.054,0.054,0.03,0.146,0.1,0.221c0.045,0.054,0.084,0.1,0.106,0.146c0.03,0.066,0.053,0.128,0.075,0.189
               c0.016,0.068,0.023,0.136,0.016,0.22c0,0.077-0.038,0.114-0.068,0.146c-0.03,0.03-0.054,0.061-0.03,0.159
               c0.053,0.206-0.054,0.289-0.183,0.389c-0.038,0.031-0.076,0.062-0.114,0.1c-0.062,0.061-0.054,0.13-0.046,0.205
               c0.008,0.1,0.016,0.213-0.084,0.343c-0.068,0.099-0.129,0.13-0.175,0.152c-0.046,0.022-0.075,0.038-0.106,0.229
               c-0.03,0.189-0.046,0.402-0.038,0.641c0.008,0.213,0.038,0.447,0.084,0.707C115.946,66.865,115.908,67.193,115.862,67.419z"/>
             <path fill="#808184" d="M115.078,67.542c0.244-0.03,0.464-0.076,0.678-0.106
               c0.046-0.214,0.091-0.556,0.031-0.875c-0.055-0.261-0.077-0.495-0.084-0.724c-0.008-0.244,0.007-0.465,0.038-0.656
               c0.037-0.241,0.083-0.268,0.159-0.303c0.038-0.022,0.084-0.047,0.145-0.123c0.068-0.1,0.061-0.19,0.061-0.273
               c-0.007-0.1-0.015-0.19,0.076-0.281c0.039-0.038,0.084-0.077,0.122-0.107c0.1-0.084,0.183-0.145,0.146-0.28
               c-0.039-0.161,0.007-0.215,0.053-0.26c0.022-0.022,0.046-0.047,0.046-0.084c0.008-0.069,0-0.13-0.015-0.183
               c-0.018-0.063-0.047-0.114-0.069-0.176c-0.015-0.03-0.054-0.075-0.091-0.122c-0.107-0.113-0.222-0.243-0.092-0.365
               c0.092-0.076,0.084-0.205,0.076-0.267c-0.016-0.007-0.038-0.015-0.054-0.022c-0.045-0.023-0.091-0.045-0.144-0.068
               c-0.115-0.046-0.168-0.122-0.222-0.206c-0.053-0.077-0.099-0.144-0.214-0.144c-0.183,0.015-0.212,0.136-0.235,0.266
               l-0.008,0.038c-0.008,0.038-0.015,0.076-0.015,0.099c-0.016,0.092-0.023,0.152-0.107,0.282c-0.061,0.098-0.136,0.16-0.229,0.182
               c-0.098,0.031-0.205,0.023-0.342-0.014c-0.122-0.032-0.122-0.009-0.129,0.021c-0.016,0.03-0.023,0.068-0.055,0.099
               c-0.061,0.062-0.183,0.138-0.281,0.198c-0.03,0.022-0.053,0.037-0.076,0.054c-0.023,0.008-0.038,0.022-0.061,0.03
               c0.022,0.091,0.061,0.313,0.061,0.563c0,0.161,0.068,0.175,0.145,0.183c0.062,0.008,0.122,0.016,0.184,0.053
               c0.121,0.093,0.105,0.161,0.084,0.267c-0.009,0.046-0.023,0.092-0.023,0.152c0,0.16,0.038,0.221,0.129,0.357l0.023,0.039
               c0.099,0.158,0.106,0.295,0.121,0.547c0.008,0.045,0.008,0.092,0.008,0.153c0.023,0.257,0,0.486-0.022,0.784
               c-0.016,0.113-0.023,0.229-0.03,0.357c-0.023,0.326,0,0.365,0.045,0.472c0.022,0.047,0.054,0.106,0.084,0.206
               C115.025,67.39,115.056,67.474,115.078,67.542"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M115.078,67.542c0.244-0.03,0.464-0.076,0.678-0.106c0.046-0.214,0.091-0.556,0.031-0.875c-0.055-0.261-0.077-0.495-0.084-0.724
               c-0.008-0.244,0.007-0.465,0.038-0.656c0.037-0.241,0.083-0.268,0.159-0.303c0.038-0.022,0.084-0.047,0.145-0.123
               c0.068-0.1,0.061-0.19,0.061-0.273c-0.007-0.1-0.015-0.19,0.076-0.281c0.039-0.038,0.084-0.077,0.122-0.107
               c0.1-0.084,0.183-0.145,0.146-0.28c-0.039-0.161,0.007-0.215,0.053-0.26c0.022-0.022,0.046-0.047,0.046-0.084
               c0.008-0.069,0-0.13-0.015-0.183c-0.018-0.063-0.047-0.114-0.069-0.176c-0.015-0.03-0.054-0.075-0.091-0.122
               c-0.107-0.113-0.222-0.243-0.092-0.365c0.092-0.076,0.084-0.205,0.076-0.267c-0.016-0.007-0.038-0.015-0.054-0.022
               c-0.045-0.023-0.091-0.045-0.144-0.068c-0.115-0.046-0.168-0.122-0.222-0.206c-0.053-0.077-0.099-0.144-0.214-0.144
               c-0.183,0.015-0.212,0.136-0.235,0.266l-0.008,0.038c-0.008,0.038-0.015,0.076-0.015,0.099
               c-0.016,0.092-0.023,0.152-0.107,0.282c-0.061,0.098-0.136,0.16-0.229,0.182c-0.098,0.031-0.205,0.023-0.342-0.014
               c-0.122-0.032-0.122-0.009-0.129,0.021c-0.016,0.03-0.023,0.068-0.055,0.099c-0.061,0.062-0.183,0.138-0.281,0.198
               c-0.03,0.022-0.053,0.037-0.076,0.054c-0.023,0.008-0.038,0.022-0.061,0.03c0.022,0.091,0.061,0.313,0.061,0.563
               c0,0.161,0.068,0.175,0.145,0.183c0.062,0.008,0.122,0.016,0.184,0.053c0.121,0.093,0.105,0.161,0.084,0.267
               c-0.009,0.046-0.023,0.092-0.023,0.152c0,0.16,0.038,0.221,0.129,0.357l0.023,0.039c0.099,0.158,0.106,0.295,0.121,0.547
               c0.008,0.045,0.008,0.092,0.008,0.153c0.023,0.257,0,0.486-0.022,0.784c-0.016,0.113-0.023,0.229-0.03,0.357
               c-0.023,0.326,0,0.365,0.045,0.472c0.022,0.047,0.054,0.106,0.084,0.206C115.025,67.39,115.056,67.474,115.078,67.542z"/>
             <path fill="#808184" d="M113.578,63.111c-0.008,0.099-0.016,0.365,0.062,0.449
               c0.053,0.069,0.091,0.129,0.121,0.198c0.023,0.068,0.046,0.138,0.054,0.213c0.008,0.038,0.008,0.083,0.008,0.13
               c0.008,0.1,0.008,0.198,0.083,0.297c0.062,0.066,0.076,0.176,0.076,0.282c-0.008,0.151-0.053,0.317-0.092,0.418
               c-0.03,0.083,0.016,0.146,0.077,0.243c0.038,0.055,0.075,0.115,0.114,0.184c0.099,0.205,0.083,0.234,0.007,0.365
               c-0.015,0.029-0.037,0.068-0.061,0.121c-0.061,0.131-0.046,0.152-0.015,0.197c0.029,0.031,0.061,0.078,0.083,0.146
               c0.045,0.13-0.008,0.229-0.068,0.351c-0.038,0.076-0.084,0.168-0.099,0.282c-0.023,0.16,0.061,0.222,0.197,0.32
               c0.069,0.046,0.146,0.098,0.229,0.174h-0.008c0.092,0.077,0.16,0.151,0.214,0.222c0.022-0.092-0.008-0.129,0.357-0.152h0.054
               c-0.022-0.061-0.046-0.137-0.068-0.213c-0.038-0.092-0.062-0.145-0.084-0.189c-0.062-0.122-0.084-0.167-0.054-0.525
               c0.008-0.138,0.016-0.25,0.023-0.357c0.03-0.289,0.046-0.52,0.03-0.77c0-0.053-0.008-0.105-0.008-0.152
               c-0.016-0.234-0.022-0.357-0.106-0.502l-0.023-0.039c-0.105-0.158-0.145-0.219-0.145-0.41c0-0.075,0.016-0.129,0.023-0.176
               c0.016-0.067,0.022-0.105-0.046-0.16c-0.038-0.021-0.092-0.029-0.137-0.036c-0.122-0.017-0.237-0.031-0.237-0.282
               c0-0.235-0.029-0.449-0.053-0.541h-0.037c-0.084,0.008-0.168-0.007-0.244-0.022C113.746,63.15,113.662,63.125,113.578,63.111"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M113.578,63.111c-0.008,0.099-0.016,0.365,0.062,0.449c0.053,0.069,0.091,0.129,0.121,0.198
               c0.023,0.068,0.046,0.138,0.054,0.213c0.008,0.038,0.008,0.083,0.008,0.13c0.008,0.1,0.008,0.198,0.083,0.297
               c0.062,0.066,0.076,0.176,0.076,0.282c-0.008,0.151-0.053,0.317-0.092,0.418c-0.03,0.083,0.016,0.146,0.077,0.243
               c0.038,0.055,0.075,0.115,0.114,0.184c0.099,0.205,0.083,0.234,0.007,0.365c-0.015,0.029-0.037,0.068-0.061,0.121
               c-0.061,0.131-0.046,0.152-0.015,0.197c0.029,0.031,0.061,0.078,0.083,0.146c0.045,0.13-0.008,0.229-0.068,0.351
               c-0.038,0.076-0.084,0.168-0.099,0.282c-0.023,0.16,0.061,0.222,0.197,0.32c0.069,0.046,0.146,0.098,0.229,0.174h-0.008
               c0.092,0.077,0.16,0.151,0.214,0.222c0.022-0.092-0.008-0.129,0.357-0.152h0.054c-0.022-0.061-0.046-0.137-0.068-0.213
               c-0.038-0.092-0.062-0.145-0.084-0.189c-0.062-0.122-0.084-0.167-0.054-0.525c0.008-0.138,0.016-0.25,0.023-0.357
               c0.03-0.289,0.046-0.52,0.03-0.77c0-0.053-0.008-0.105-0.008-0.152c-0.016-0.234-0.022-0.357-0.106-0.502l-0.023-0.039
               c-0.105-0.158-0.145-0.219-0.145-0.41c0-0.075,0.016-0.129,0.023-0.176c0.016-0.067,0.022-0.105-0.046-0.16
               c-0.038-0.021-0.092-0.029-0.137-0.036c-0.122-0.017-0.237-0.031-0.237-0.282c0-0.235-0.029-0.449-0.053-0.541h-0.037
               c-0.084,0.008-0.168-0.007-0.244-0.022C113.746,63.15,113.662,63.125,113.578,63.111z"/>
             <path fill="#808184" d="M111.157,68.761c0.205,0.082,0.35,0.145,0.563,0.176
               c0.313,0.045,0.267,0.021,0.533-0.168c0.274-0.191,0.625-0.494,1.028-0.504c0.403-0.016,0.373-0.176,0.617-0.184
               c0.25-0.015,0.25,0.023,0.531-0.205c0.032-0.021,0.055-0.053,0.077-0.076c-0.053-0.074-0.129-0.158-0.229-0.242
               c-0.075-0.068-0.145-0.123-0.213-0.168c-0.16-0.122-0.267-0.197-0.235-0.419c0.015-0.121,0.061-0.228,0.106-0.313
               c0.053-0.099,0.091-0.183,0.061-0.267c-0.016-0.06-0.046-0.091-0.061-0.121c-0.062-0.076-0.092-0.121,0-0.306
               c0.021-0.053,0.045-0.092,0.061-0.127c0.054-0.094,0.068-0.115-0.008-0.268c-0.031-0.068-0.068-0.123-0.106-0.176
               c-0.076-0.122-0.13-0.205-0.084-0.335c0.031-0.093,0.076-0.245,0.076-0.381c0.008-0.085-0.008-0.169-0.045-0.222
               c-0.1-0.129-0.107-0.244-0.107-0.357c0-0.038,0-0.076-0.008-0.114c-0.007-0.068-0.022-0.13-0.053-0.189
               c-0.022-0.055-0.062-0.115-0.107-0.168c-0.091-0.123-0.084-0.45-0.075-0.541c-0.062-0.016-0.114-0.03-0.138-0.03
               c-0.038-0.008-0.075,0-0.122,0.015c-0.053,0.016-0.114,0.046-0.174,0.077c-0.1,0.06-0.138,0.053-0.259,0.045
               c-0.046,0-0.108-0.007-0.192-0.007c-0.167,0-0.563-0.009-0.883-0.009c-0.197,0-0.356,0-0.396,0.009
               c-0.053,0.007-0.068,0.105-0.091,0.258c-0.008,0.039-0.008,0.076-0.022,0.114v0.009c-0.024,0.12,0.029,0.235,0.075,0.342
               c0.031,0.076,0.062,0.144,0.069,0.213c0.008,0.138-0.061,0.283-0.106,0.343c0.159,0.13,0.152,0.176,0.145,0.281
               c0,0.039,0,0.092,0.008,0.166c0.007,0.039,0.015,0.084,0.022,0.131c0.038,0.267,0.091,0.617-0.046,0.809
               c-0.083,0.121-0.099,0.188-0.129,0.271c-0.016,0.063-0.038,0.131-0.076,0.229c-0.054,0.122-0.115,0.252-0.168,0.358
               c-0.054,0.113-0.099,0.213-0.138,0.273c-0.029,0.045-0.015,0.16,0.016,0.305c0.039,0.207,0.122,0.441,0.175,0.58
               c0.031,0.067,0.062,0.119,0.092,0.166c0.062,0.114,0.114,0.198,0.091,0.403C111.226,68.562,111.188,68.677,111.157,68.761"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M111.157,68.761c0.205,0.082,0.35,0.145,0.563,0.176c0.313,0.045,0.267,0.021,0.533-0.168c0.274-0.191,0.625-0.494,1.028-0.504
               c0.403-0.016,0.373-0.176,0.617-0.184c0.25-0.015,0.25,0.023,0.531-0.205c0.032-0.021,0.055-0.053,0.077-0.076
               c-0.053-0.074-0.129-0.158-0.229-0.242c-0.075-0.068-0.145-0.123-0.213-0.168c-0.16-0.122-0.267-0.197-0.235-0.419
               c0.015-0.121,0.061-0.228,0.106-0.313c0.053-0.099,0.091-0.183,0.061-0.267c-0.016-0.06-0.046-0.091-0.061-0.121
               c-0.062-0.076-0.092-0.121,0-0.306c0.021-0.053,0.045-0.092,0.061-0.127c0.054-0.094,0.068-0.115-0.008-0.268
               c-0.031-0.068-0.068-0.123-0.106-0.176c-0.076-0.122-0.13-0.205-0.084-0.335c0.031-0.093,0.076-0.245,0.076-0.381
               c0.008-0.085-0.008-0.169-0.045-0.222c-0.1-0.129-0.107-0.244-0.107-0.357c0-0.038,0-0.076-0.008-0.114
               c-0.007-0.068-0.022-0.13-0.053-0.189c-0.022-0.055-0.062-0.115-0.107-0.168c-0.091-0.123-0.084-0.45-0.075-0.541
               c-0.062-0.016-0.114-0.03-0.138-0.03c-0.038-0.008-0.075,0-0.122,0.015c-0.053,0.016-0.114,0.046-0.174,0.077
               c-0.1,0.06-0.138,0.053-0.259,0.045c-0.046,0-0.108-0.007-0.192-0.007c-0.167,0-0.563-0.009-0.883-0.009
               c-0.197,0-0.356,0-0.396,0.009c-0.053,0.007-0.068,0.105-0.091,0.258c-0.008,0.039-0.008,0.076-0.022,0.114v0.009
               c-0.024,0.12,0.029,0.235,0.075,0.342c0.031,0.076,0.062,0.144,0.069,0.213c0.008,0.138-0.061,0.283-0.106,0.343
               c0.159,0.13,0.152,0.176,0.145,0.281c0,0.039,0,0.092,0.008,0.166c0.007,0.039,0.015,0.084,0.022,0.131
               c0.038,0.267,0.091,0.617-0.046,0.809c-0.083,0.121-0.099,0.188-0.129,0.271c-0.016,0.063-0.038,0.131-0.076,0.229
               c-0.054,0.122-0.115,0.252-0.168,0.358c-0.054,0.113-0.099,0.213-0.138,0.273c-0.029,0.045-0.015,0.16,0.016,0.305
               c0.039,0.207,0.122,0.441,0.175,0.58c0.031,0.067,0.062,0.119,0.092,0.166c0.062,0.114,0.114,0.198,0.091,0.403
               C111.226,68.562,111.188,68.677,111.157,68.761z"/>
             <path fill="#808184" d="M107.106,63.797c0.008,0.007,0.008,0.015,0.016,0.022
               c0.022,0.062-0.008,0.114-0.146,0.167c-0.183,0.076-0.114,0.305-0.068,0.458c0.016,0.038,0.022,0.067,0.031,0.099
               c0.007,0.039,0.045,0.054,0.083,0.067c0.076,0.022,0.145,0.046,0.105,0.168c-0.014,0.076-0.052,0.122-0.084,0.167
               c-0.029,0.047-0.06,0.077-0.037,0.115c0.016,0.029,0.037,0.061,0.069,0.1c0.083,0.098,0.159,0.189,0.052,0.287
               c-0.06,0.063-0.121,0.069-0.174,0.078c-0.03,0-0.061,0.008-0.077,0.036c-0.015,0.039-0.015,0.077-0.007,0.123
               c0.016,0.053,0.03,0.099,0.054,0.144c0.037,0.084,0.007,0.146-0.038,0.223c-0.009,0.017-0.023,0.037-0.03,0.061
               c-0.022,0.055-0.046,0.077-0.069,0.1c-0.03,0.031-0.061,0.054-0.076,0.152c-0.007,0.092,0,0.152,0.016,0.197
               c0.008,0.055,0.016,0.1,0.008,0.168c-0.008,0.063-0.023,0.16-0.039,0.252c-0.015,0.091-0.029,0.184-0.053,0.234
               c-0.016,0.047-0.03,0.084-0.03,0.115c0,0.03,0.008,0.053,0.03,0.084c0.023,0.03,0.068,0.03,0.13,0.03
               c0.084,0.005,0.175,0.005,0.267,0.067c0.106,0.068,0.129,0.137,0.145,0.214c0.007,0.036,0.023,0.075,0.053,0.114
               c0.031,0.037,0.068,0.053,0.106,0.061c0.069,0.021,0.13,0.045,0.137,0.152c0.009,0.113-0.022,0.305-0.053,0.563
               c-0.015,0.069-0.022,0.139-0.029,0.206c-0.031,0.175,0,0.388,0.029,0.556c0.313-0.112,1.059-0.67,1.569-0.754
               c0.54-0.092,1.3-0.121,1.773,0.008c0.114,0.031,0.206,0.07,0.289,0.093c0.039-0.077,0.068-0.177,0.076-0.29
               c0.016-0.176-0.022-0.241-0.084-0.344c-0.03-0.053-0.061-0.104-0.091-0.182c-0.061-0.138-0.145-0.39-0.183-0.602
               c-0.038-0.16-0.046-0.307-0.008-0.373c0.038-0.063,0.091-0.152,0.137-0.26c0.054-0.114,0.114-0.243,0.168-0.366
               c0.038-0.091,0.053-0.151,0.076-0.212c0.022-0.092,0.046-0.168,0.138-0.297c0.113-0.16,0.061-0.488,0.022-0.738
               c-0.008-0.046-0.017-0.092-0.017-0.13c-0.015-0.093-0.006-0.146-0.006-0.188c0.006-0.078,0.006-0.101-0.138-0.224
               c-0.114-0.084-0.152-0.158-0.183-0.228c-0.038-0.061-0.062-0.106-0.198-0.113c-0.137,0-0.251,0-0.35,0.007
               c-0.092,0.016-0.168,0.03-0.236,0.046c-0.038,0.015-0.055,0.046-0.077,0.068c-0.053,0.069-0.099,0.136-0.289,0.099
               c-0.213-0.037-0.267-0.114-0.319-0.183c-0.008-0.015-0.022-0.03-0.038-0.046c-0.046-0.037-0.054-0.084-0.061-0.144
               c-0.016-0.046-0.022-0.107-0.077-0.123c-0.061-0.031-0.091-0.031-0.129-0.038c-0.03,0-0.061,0-0.107-0.022
               c-0.06-0.03-0.129-0.122-0.167-0.168c-0.03,0.015-0.061,0.015-0.091,0.023c-0.046,0.008-0.084,0.016-0.138,0.075
               c-0.037,0.047-0.091,0.085-0.137,0.108c-0.03,0.007-0.061,0.015-0.092,0.015c-0.029,0-0.061-0.015-0.084-0.038
               c-0.007-0.016-0.015-0.038-0.015-0.054c-0.007-0.031-0.007-0.062-0.007-0.099v-0.107c0-0.038-0.016-0.067-0.046-0.067
               c-0.061,0.007-0.1,0.021-0.13,0.038c-0.022,0.015-0.038,0.036-0.061,0.068c-0.03,0.052-0.068,0.052-0.115,0.062
               c-0.037,0.008-0.075,0.016-0.136,0.061c-0.069,0.054-0.122,0.092-0.168,0.106c-0.068,0.023-0.121,0.007-0.152-0.053h-0.008
               c-0.015-0.039-0.062-0.076-0.114-0.106c-0.053-0.023-0.106-0.031-0.145-0.008C107.221,63.736,107.151,63.766,107.106,63.797"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M107.106,63.797c0.008,0.007,0.008,0.015,0.016,0.022c0.022,0.062-0.008,0.114-0.146,0.167
               c-0.183,0.076-0.114,0.305-0.068,0.458c0.016,0.038,0.022,0.067,0.031,0.099c0.007,0.039,0.045,0.054,0.083,0.067
               c0.076,0.022,0.145,0.046,0.105,0.168c-0.014,0.076-0.052,0.122-0.084,0.167c-0.029,0.047-0.06,0.077-0.037,0.115
               c0.016,0.029,0.037,0.061,0.069,0.1c0.083,0.098,0.159,0.189,0.052,0.287c-0.06,0.063-0.121,0.069-0.174,0.078
               c-0.03,0-0.061,0.008-0.077,0.036c-0.015,0.039-0.015,0.077-0.007,0.123c0.016,0.053,0.03,0.099,0.054,0.144
               c0.037,0.084,0.007,0.146-0.038,0.223c-0.009,0.017-0.023,0.037-0.03,0.061c-0.022,0.055-0.046,0.077-0.069,0.1
               c-0.03,0.031-0.061,0.054-0.076,0.152c-0.007,0.092,0,0.152,0.016,0.197c0.008,0.055,0.016,0.1,0.008,0.168
               c-0.008,0.063-0.023,0.16-0.039,0.252c-0.015,0.091-0.029,0.184-0.053,0.234c-0.016,0.047-0.03,0.084-0.03,0.115
               c0,0.03,0.008,0.053,0.03,0.084c0.023,0.03,0.068,0.03,0.13,0.03c0.084,0.005,0.175,0.005,0.267,0.067
               c0.106,0.068,0.129,0.137,0.145,0.214c0.007,0.036,0.023,0.075,0.053,0.114c0.031,0.037,0.068,0.053,0.106,0.061
               c0.069,0.021,0.13,0.045,0.137,0.152c0.009,0.113-0.022,0.305-0.053,0.563c-0.015,0.069-0.022,0.139-0.029,0.206
               c-0.031,0.175,0,0.388,0.029,0.556c0.313-0.112,1.059-0.67,1.569-0.754c0.54-0.092,1.3-0.121,1.773,0.008
               c0.114,0.031,0.206,0.07,0.289,0.093c0.039-0.077,0.068-0.177,0.076-0.29c0.016-0.176-0.022-0.241-0.084-0.344
               c-0.03-0.053-0.061-0.104-0.091-0.182c-0.061-0.138-0.145-0.39-0.183-0.602c-0.038-0.16-0.046-0.307-0.008-0.373
               c0.038-0.063,0.091-0.152,0.137-0.26c0.054-0.114,0.114-0.243,0.168-0.366c0.038-0.091,0.053-0.151,0.076-0.212
               c0.022-0.092,0.046-0.168,0.138-0.297c0.113-0.16,0.061-0.488,0.022-0.738c-0.008-0.046-0.017-0.092-0.017-0.13
               c-0.015-0.093-0.006-0.146-0.006-0.188c0.006-0.078,0.006-0.101-0.138-0.224c-0.114-0.084-0.152-0.158-0.183-0.228
               c-0.038-0.061-0.062-0.106-0.198-0.113c-0.137,0-0.251,0-0.35,0.007c-0.092,0.016-0.168,0.03-0.236,0.046
               c-0.038,0.015-0.055,0.046-0.077,0.068c-0.053,0.069-0.099,0.136-0.289,0.099c-0.213-0.037-0.267-0.114-0.319-0.183
               c-0.008-0.015-0.022-0.03-0.038-0.046c-0.046-0.037-0.054-0.084-0.061-0.144c-0.016-0.046-0.022-0.107-0.077-0.123
               c-0.061-0.031-0.091-0.031-0.129-0.038c-0.03,0-0.061,0-0.107-0.022c-0.06-0.03-0.129-0.122-0.167-0.168
               c-0.03,0.015-0.061,0.015-0.091,0.023c-0.046,0.008-0.084,0.016-0.138,0.075c-0.037,0.047-0.091,0.085-0.137,0.108
               c-0.03,0.007-0.061,0.015-0.092,0.015c-0.029,0-0.061-0.015-0.084-0.038c-0.007-0.016-0.015-0.038-0.015-0.054
               c-0.007-0.031-0.007-0.062-0.007-0.099v-0.107c0-0.038-0.016-0.067-0.046-0.067c-0.061,0.007-0.1,0.021-0.13,0.038
               c-0.022,0.015-0.038,0.036-0.061,0.068c-0.03,0.052-0.068,0.052-0.115,0.062c-0.037,0.008-0.075,0.016-0.136,0.061
               c-0.069,0.054-0.122,0.092-0.168,0.106c-0.068,0.023-0.121,0.007-0.152-0.053h-0.008c-0.015-0.039-0.062-0.076-0.114-0.106
               c-0.053-0.023-0.106-0.031-0.145-0.008C107.221,63.736,107.151,63.766,107.106,63.797z"/>
             <path fill="#808184" d="M104.221,61.687c0.015,0.016,0.03,0.031,0.046,0.062
               c0.015,0.037,0,0.09-0.009,0.152c-0.016,0.068-0.037,0.152,0,0.19c0.039,0.038,0.069,0.053,0.093,0.061
               c0.022,0,0.053-0.008,0.083-0.038c0.03-0.023,0.054-0.069,0.076-0.114c0.022-0.046,0.046-0.092,0.076-0.106
               c0.045-0.024,0.084-0.009,0.122,0.06c0.016,0.038,0.038,0.076,0.061,0.106c0.023,0.024,0.038,0.046,0.062,0.061
               c0,0,0,0.008,0.007,0.008c0-0.008,0.008-0.015,0.016-0.015c0.068-0.062,0.221-0.191,0.403-0.137
               c0.077,0.015,0.13,0.038,0.176,0.061c0.061,0.022,0.106,0.046,0.151,0.03c0.016-0.008,0.031-0.015,0.047-0.015
               c0.06-0.022,0.105-0.031,0.137-0.145c0.007-0.038,0.015-0.069,0.022-0.098c0.008-0.046,0.008-0.077,0.023-0.107
               c0.029-0.069,0.075-0.1,0.175-0.054c0.122,0.054,0.137,0.069,0.198,0.153c0.007,0.015,0.03,0.037,0.053,0.068
               c0.068,0.084,0.054,0.122,0.038,0.168c-0.008,0.03-0.016,0.061,0.016,0.145c0.038,0.099,0.129,0.19,0.213,0.274
               c0.076,0.075,0.145,0.152,0.183,0.228c0.055,0.122-0.054,0.213-0.145,0.281c-0.023,0.022-0.046,0.038-0.061,0.054
               c0.022,0.008,0.053,0.008,0.091,0.016c0.13,0.007,0.267,0.015,0.26,0.235c-0.009,0.063-0.009,0.114-0.009,0.152
               c-0.015,0.13-0.015,0.182,0.046,0.236c0.03,0.015,0.069,0.038,0.106,0.054l0.03,0.015c0.038-0.022,0.138-0.061,0.222-0.113
               v-0.01c0.075-0.045,0.168-0.029,0.243,0.01c0.068,0.037,0.13,0.099,0.16,0.137v0.007c0.008,0.015,0.022,0.015,0.037,0.008
               c0.03-0.008,0.077-0.038,0.138-0.092c0.076-0.06,0.137-0.069,0.183-0.076c0.023,0,0.038-0.008,0.046-0.015
               c0.031-0.046,0.054-0.077,0.091-0.099c0.039-0.03,0.092-0.046,0.176-0.054c0.105-0.008,0.145,0.069,0.152,0.159
               c0.008,0.039,0.008,0.077,0.008,0.115c-0.008,0.03-0.008,0.061,0,0.082v0.01h0.008c0.008,0,0.022-0.01,0.037-0.017
               c0.039-0.015,0.076-0.038,0.107-0.075c0.075-0.091,0.137-0.101,0.198-0.107c0.029-0.008,0.053-0.008,0.075-0.015
               c0.046-0.015,0.068-0.03,0.084-0.046c0.016-0.022,0.023-0.053,0.023-0.114c0-0.031,0-0.046-0.008-0.061
               c0-0.114,0-0.206,0.038-0.343c0.022-0.061,0.045-0.107,0.068-0.145c0.038-0.054,0.061-0.092,0.008-0.16
               c-0.016-0.023-0.031-0.053-0.046-0.076c-0.023-0.068-0.023-0.129-0.008-0.191c0.015-0.053,0.054-0.106,0.099-0.145
               c0.022-0.015,0.046-0.03,0.068-0.038c0.016-0.008,0.054-0.023,0.084-0.038c0.152-0.053,0.381-0.144,0.419-0.221
               c0.015-0.038,0.015-0.091,0.022-0.145c0-0.091,0-0.182,0.054-0.274c0.038-0.06,0.068-0.084,0.1-0.099
               c0.022-0.023,0.037-0.03,0.022-0.198c-0.038-0.312,0.13-0.396,0.335-0.495l0.03-0.015c0.13-0.06,0.229,0,0.32,0.069
               c0.067,0.045,0.137,0.083,0.197,0.053c0.092-0.045,0.122-0.191,0.145-0.312c0.008-0.069,0.023-0.122,0.046-0.16
               c0.03-0.054,0.084-0.106,0.13-0.167c0.053-0.046,0.099-0.107,0.129-0.152c0.062-0.092,0.13-0.069,0.229-0.024
               c0.054,0.024,0.122,0.046,0.206,0.061c0.113,0.016,0.113,0.016,0.121-0.045c0.008-0.038,0.016-0.084,0.031-0.152
               c0.053-0.199,0.213-0.229,0.373-0.259c0.075-0.016,0.145-0.023,0.205-0.062c0.076-0.038,0.152-0.09,0.221-0.137
               c0.092-0.068,0.19-0.136,0.313-0.19c0.13-0.045,0.427-0.015,0.7,0.023c0.198,0.024,0.389,0.039,0.495,0.031
               c0.152-0.007,0.183-0.039,0.244-0.091c0.037-0.039,0.091-0.092,0.197-0.146c0.175-0.099,0.602-0.099,0.93-0.091
               c0.19,0,0.342,0.008,0.381-0.008c0.083-0.03,0.167-0.03,0.243-0.022c0.061,0,0.105,0,0.13-0.015l0.015-0.007v-0.009
               c0.061-0.038,0.106-0.075,0.243-0.191c0.145-0.113,0.175-0.258,0.199-0.395v-0.008c0.022-0.099,0.029-0.129,0.052-0.175
               c0.016-0.031,0.031-0.06,0.054-0.122c0.016-0.061,0.038-0.296,0.061-0.571c0.031-0.426,0.054-0.936,0.046-1.013
               c0-0.121-0.007-0.646-0.007-0.829l-0.084,0.015c-0.076,0.015-0.146,0.023-0.198,0.038c-0.046,0.008-0.091,0.015-0.122,0.023
               c-0.273,0.053-0.273,0.053-0.448-0.229c-0.115-0.182-0.062-0.282,0-0.387c0.037-0.069,0.075-0.138,0.037-0.252
               c-0.037-0.129-0.183-0.129-0.365-0.137c-0.168,0-0.35,0-0.524-0.084c-0.237-0.107-0.245-0.213-0.252-0.297
               c0-0.038,0-0.068-0.1-0.076c-0.273-0.046-0.319-0.236-0.373-0.442c-0.007-0.038-0.015-0.076-0.03-0.114
               c-0.03-0.122-2.688-2.078-4.865-3.677c-0.091,0-1.097-0.015-1.188,0.038c-0.038,0.022,0.137,2.146,0.297,4.126
               c0.138,1.645,0.267,3.199,0.243,3.396c-0.03,0.274,0.047,0.32,0.122,0.373c0.039,0.023,0.084,0.046,0.114,0.092
               c0.061,0.076,0.061,0.259,0.022,0.434c-0.029,0.191-0.106,0.389-0.175,0.449c-0.053,0.046-0.388,0.053-0.808,0.053
               c-0.729-0.007-1.72-0.053-1.85-0.068c-0.053,0-0.106-0.008-0.159-0.016c-0.13-0.007-0.244-0.022-0.351-0.007
               c-0.054,0-0.106,0.023-0.16,0.045c-0.076,0.039-0.167,0.077-0.335,0.084c-0.129,0-0.206-0.007-0.259-0.015
               c-0.046-0.015-0.068-0.015-0.106,0c-0.023,0.008-0.046,0.015-0.068,0.023c-0.138,0.068-0.358,0.159-0.503,0.038
               c-0.054-0.046-0.1-0.084-0.129-0.122c-0.055-0.053-0.1-0.099-0.168-0.13c-0.008,0-0.016,0.009-0.022,0.023
               c-0.016,0.016-0.023,0.039-0.039,0.069c-0.053,0.122-0.075,0.312-0.068,0.42c0.031,0.205-0.083,0.242-0.221,0.28
               c-0.022,0.009-0.045,0.016-0.068,0.024c-0.022,0.007-0.046,0.007-0.068,0.007c-0.016,0.084-0.038,0.236,0.016,0.297
               c0.053,0.054,0.091,0.099,0.114,0.137c0.022,0.046,0.03,0.091,0.03,0.137c0,0.031-0.016,0.092-0.023,0.161
               c-0.007,0.06-0.022,0.128-0.03,0.174c-0.008,0.038-0.008,0.069,0.008,0.107c0.022,0.037,0.068,0.075,0.145,0.129
               c0.076,0.046,0.122,0.062,0.16,0.076c0.068,0.031,0.091,0.038,0.122,0.114C104.221,61.078,104.221,61.558,104.221,61.687"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M104.221,61.687c0.015,0.016,0.03,0.031,0.046,0.062c0.015,0.037,0,0.09-0.009,0.152c-0.016,0.068-0.037,0.152,0,0.19
               c0.039,0.038,0.069,0.053,0.093,0.061c0.022,0,0.053-0.008,0.083-0.038c0.03-0.023,0.054-0.069,0.076-0.114
               c0.022-0.046,0.046-0.092,0.076-0.106c0.045-0.024,0.084-0.009,0.122,0.06c0.016,0.038,0.038,0.076,0.061,0.106
               c0.023,0.024,0.038,0.046,0.062,0.061c0,0,0,0.008,0.007,0.008c0-0.008,0.008-0.015,0.016-0.015
               c0.068-0.062,0.221-0.191,0.403-0.137c0.077,0.015,0.13,0.038,0.176,0.061c0.061,0.022,0.106,0.046,0.151,0.03
               c0.016-0.008,0.031-0.015,0.047-0.015c0.06-0.022,0.105-0.031,0.137-0.145c0.007-0.038,0.015-0.069,0.022-0.098
               c0.008-0.046,0.008-0.077,0.023-0.107c0.029-0.069,0.075-0.1,0.175-0.054c0.122,0.054,0.137,0.069,0.198,0.153
               c0.007,0.015,0.03,0.037,0.053,0.068c0.068,0.084,0.054,0.122,0.038,0.168c-0.008,0.03-0.016,0.061,0.016,0.145
               c0.038,0.099,0.129,0.19,0.213,0.274c0.076,0.075,0.145,0.152,0.183,0.228c0.055,0.122-0.054,0.213-0.145,0.281
               c-0.023,0.022-0.046,0.038-0.061,0.054c0.022,0.008,0.053,0.008,0.091,0.016c0.13,0.007,0.267,0.015,0.26,0.235
               c-0.009,0.063-0.009,0.114-0.009,0.152c-0.015,0.13-0.015,0.182,0.046,0.236c0.03,0.015,0.069,0.038,0.106,0.054l0.03,0.015
               c0.038-0.022,0.138-0.061,0.222-0.113v-0.01c0.075-0.045,0.168-0.029,0.243,0.01c0.068,0.037,0.13,0.099,0.16,0.137v0.007
               c0.008,0.015,0.022,0.015,0.037,0.008c0.03-0.008,0.077-0.038,0.138-0.092c0.076-0.06,0.137-0.069,0.183-0.076
               c0.023,0,0.038-0.008,0.046-0.015c0.031-0.046,0.054-0.077,0.091-0.099c0.039-0.03,0.092-0.046,0.176-0.054
               c0.105-0.008,0.145,0.069,0.152,0.159c0.008,0.039,0.008,0.077,0.008,0.115c-0.008,0.03-0.008,0.061,0,0.082v0.01h0.008
               c0.008,0,0.022-0.01,0.037-0.017c0.039-0.015,0.076-0.038,0.107-0.075c0.075-0.091,0.137-0.101,0.198-0.107
               c0.029-0.008,0.053-0.008,0.075-0.015c0.046-0.015,0.068-0.03,0.084-0.046c0.016-0.022,0.023-0.053,0.023-0.114
               c0-0.031,0-0.046-0.008-0.061c0-0.114,0-0.206,0.038-0.343c0.022-0.061,0.045-0.107,0.068-0.145
               c0.038-0.054,0.061-0.092,0.008-0.16c-0.016-0.023-0.031-0.053-0.046-0.076c-0.023-0.068-0.023-0.129-0.008-0.191
               c0.015-0.053,0.054-0.106,0.099-0.145c0.022-0.015,0.046-0.03,0.068-0.038c0.016-0.008,0.054-0.023,0.084-0.038
               c0.152-0.053,0.381-0.144,0.419-0.221c0.015-0.038,0.015-0.091,0.022-0.145c0-0.091,0-0.182,0.054-0.274
               c0.038-0.06,0.068-0.084,0.1-0.099c0.022-0.023,0.037-0.03,0.022-0.198c-0.038-0.312,0.13-0.396,0.335-0.495l0.03-0.015
               c0.13-0.06,0.229,0,0.32,0.069c0.067,0.045,0.137,0.083,0.197,0.053c0.092-0.045,0.122-0.191,0.145-0.312
               c0.008-0.069,0.023-0.122,0.046-0.16c0.03-0.054,0.084-0.106,0.13-0.167c0.053-0.046,0.099-0.107,0.129-0.152
               c0.062-0.092,0.13-0.069,0.229-0.024c0.054,0.024,0.122,0.046,0.206,0.061c0.113,0.016,0.113,0.016,0.121-0.045
               c0.008-0.038,0.016-0.084,0.031-0.152c0.053-0.199,0.213-0.229,0.373-0.259c0.075-0.016,0.145-0.023,0.205-0.062
               c0.076-0.038,0.152-0.09,0.221-0.137c0.092-0.068,0.19-0.136,0.313-0.19c0.13-0.045,0.427-0.015,0.7,0.023
               c0.198,0.024,0.389,0.039,0.495,0.031c0.152-0.007,0.183-0.039,0.244-0.091c0.037-0.039,0.091-0.092,0.197-0.146
               c0.175-0.099,0.602-0.099,0.93-0.091c0.19,0,0.342,0.008,0.381-0.008c0.083-0.03,0.167-0.03,0.243-0.022
               c0.061,0,0.105,0,0.13-0.015l0.015-0.007v-0.009c0.061-0.038,0.106-0.075,0.243-0.191c0.145-0.113,0.175-0.258,0.199-0.395
               v-0.008c0.022-0.099,0.029-0.129,0.052-0.175c0.016-0.031,0.031-0.06,0.054-0.122c0.016-0.061,0.038-0.296,0.061-0.571
               c0.031-0.426,0.054-0.936,0.046-1.013c0-0.121-0.007-0.646-0.007-0.829l-0.084,0.015c-0.076,0.015-0.146,0.023-0.198,0.038
               c-0.046,0.008-0.091,0.015-0.122,0.023c-0.273,0.053-0.273,0.053-0.448-0.229c-0.115-0.182-0.062-0.282,0-0.387
               c0.037-0.069,0.075-0.138,0.037-0.252c-0.037-0.129-0.183-0.129-0.365-0.137c-0.168,0-0.35,0-0.524-0.084
               c-0.237-0.107-0.245-0.213-0.252-0.297c0-0.038,0-0.068-0.1-0.076c-0.273-0.046-0.319-0.236-0.373-0.442
               c-0.007-0.038-0.015-0.076-0.03-0.114c-0.03-0.122-2.688-2.078-4.865-3.677c-0.091,0-1.097-0.015-1.188,0.038
               c-0.038,0.022,0.137,2.146,0.297,4.126c0.138,1.645,0.267,3.199,0.243,3.396c-0.03,0.274,0.047,0.32,0.122,0.373
               c0.039,0.023,0.084,0.046,0.114,0.092c0.061,0.076,0.061,0.259,0.022,0.434c-0.029,0.191-0.106,0.389-0.175,0.449
               c-0.053,0.046-0.388,0.053-0.808,0.053c-0.729-0.007-1.72-0.053-1.85-0.068c-0.053,0-0.106-0.008-0.159-0.016
               c-0.13-0.007-0.244-0.022-0.351-0.007c-0.054,0-0.106,0.023-0.16,0.045c-0.076,0.039-0.167,0.077-0.335,0.084
               c-0.129,0-0.206-0.007-0.259-0.015c-0.046-0.015-0.068-0.015-0.106,0c-0.023,0.008-0.046,0.015-0.068,0.023
               c-0.138,0.068-0.358,0.159-0.503,0.038c-0.054-0.046-0.1-0.084-0.129-0.122c-0.055-0.053-0.1-0.099-0.168-0.13
               c-0.008,0-0.016,0.009-0.022,0.023c-0.016,0.016-0.023,0.039-0.039,0.069c-0.053,0.122-0.075,0.312-0.068,0.42
               c0.031,0.205-0.083,0.242-0.221,0.28c-0.022,0.009-0.045,0.016-0.068,0.024c-0.022,0.007-0.046,0.007-0.068,0.007
               c-0.016,0.084-0.038,0.236,0.016,0.297c0.053,0.054,0.091,0.099,0.114,0.137c0.022,0.046,0.03,0.091,0.03,0.137
               c0,0.031-0.016,0.092-0.023,0.161c-0.007,0.06-0.022,0.128-0.03,0.174c-0.008,0.038-0.008,0.069,0.008,0.107
               c0.022,0.037,0.068,0.075,0.145,0.129c0.076,0.046,0.122,0.062,0.16,0.076c0.068,0.031,0.091,0.038,0.122,0.114
               C104.221,61.078,104.221,61.558,104.221,61.687z"/>
             <path fill="#808184" d="M99.888,60.621c-0.113,0.229-0.183,0.29-0.121,0.571
               c0.099-0.061,0.213-0.106,0.297-0.114c0.122-0.007,0.251-0.039,0.373-0.076c0.046-0.008,0.092-0.023,0.13-0.03h0.022
               c0.061-0.015,0.091-0.023,0.114-0.039c0.016-0.007,0.03-0.022,0.068-0.053c0.016-0.015,0.031-0.03,0.053-0.046
               c0.138-0.122,0.282-0.067,0.48,0.008c0.037,0.008,0.076,0.023,0.114,0.038c0.122,0.038,0.205,0.069,0.28,0.077
               c0.077,0.015,0.139,0.015,0.199-0.008c0.038-0.008,0.076-0.016,0.114-0.023c0.122-0.031,0.259-0.061,0.289-0.107
               c0-0.008-0.008-0.015-0.015-0.023c-0.023-0.015-0.038-0.022-0.068-0.029c-0.039-0.017-0.084-0.024-0.115-0.008
               c-0.075,0.03-0.129,0-0.213-0.047c-0.061-0.029-0.137-0.068-0.243-0.091c-0.122-0.022-0.183-0.045-0.244-0.068
               c-0.061-0.023-0.121-0.053-0.251-0.061c-0.106-0.016-0.152,0.008-0.206,0.038c-0.068,0.046-0.159,0.091-0.396,0.114
               C100.276,60.659,100.032,60.637,99.888,60.621"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M99.888,60.621c-0.113,0.229-0.183,0.29-0.121,0.571c0.099-0.061,0.213-0.106,0.297-0.114c0.122-0.007,0.251-0.039,0.373-0.076
               c0.046-0.008,0.092-0.023,0.13-0.03h0.022c0.061-0.015,0.091-0.023,0.114-0.039c0.016-0.007,0.03-0.022,0.068-0.053
               c0.016-0.015,0.031-0.03,0.053-0.046c0.138-0.122,0.282-0.067,0.48,0.008c0.037,0.008,0.076,0.023,0.114,0.038
               c0.122,0.038,0.205,0.069,0.28,0.077c0.077,0.015,0.139,0.015,0.199-0.008c0.038-0.008,0.076-0.016,0.114-0.023
               c0.122-0.031,0.259-0.061,0.289-0.107c0-0.008-0.008-0.015-0.015-0.023c-0.023-0.015-0.038-0.022-0.068-0.029
               c-0.039-0.017-0.084-0.024-0.115-0.008c-0.075,0.03-0.129,0-0.213-0.047c-0.061-0.029-0.137-0.068-0.243-0.091
               c-0.122-0.022-0.183-0.045-0.244-0.068c-0.061-0.023-0.121-0.053-0.251-0.061c-0.106-0.016-0.152,0.008-0.206,0.038
               c-0.068,0.046-0.159,0.091-0.396,0.114C100.276,60.659,100.032,60.637,99.888,60.621z"/>
             <path fill="#808184" d="M100.049,58.337c-0.031,0.335-0.023,0.472-0.152,0.632
               c-0.146,0.182-0.595,0.624-0.457,0.723c0.129,0.099,0.289,0.122,0.318,0.374c0.039,0.243,0.32,0.137,0.176,0.456
               c0.137,0.015,0.365,0.03,0.609,0.015c0.213-0.015,0.289-0.061,0.358-0.091c0.067-0.046,0.122-0.076,0.259-0.061
               c0.145,0.015,0.213,0.045,0.281,0.076c0.054,0.015,0.114,0.046,0.221,0.061c0.13,0.023,0.214,0.069,0.274,0.099
               c0.061,0.03,0.099,0.054,0.129,0.038c0.062-0.023,0.129-0.015,0.19,0.008c0.038,0.015,0.068,0.03,0.092,0.053
               c0.03,0.023,0.054,0.054,0.054,0.084c0.007,0.023,0,0.054-0.017,0.069c-0.045,0.084-0.205,0.113-0.35,0.152
               c-0.038,0.007-0.075,0.014-0.106,0.023c-0.084,0.022-0.159,0.022-0.243,0.015c-0.084-0.015-0.176-0.046-0.297-0.092
               c-0.047-0.007-0.084-0.023-0.114-0.038c-0.168-0.06-0.29-0.106-0.381-0.023c-0.023,0.015-0.038,0.031-0.054,0.047
               c-0.037,0.029-0.063,0.052-0.091,0.068c-0.03,0.014-0.069,0.023-0.138,0.045h-0.022c-0.038,0.016-0.084,0.023-0.122,0.031
               c-0.137,0.038-0.267,0.068-0.403,0.076c-0.069,0.007-0.183,0.061-0.282,0.114c0.054,0.304-0.099,0.335,0.092,0.518
               c0.023,0.015,0.046,0.038,0.061,0.053c0.16-0.084,0.373-0.168,0.555-0.168c0.185,0.009,0.344-0.068,0.474-0.128
               c0.084-0.039,0.16-0.07,0.229-0.077c0.145-0.015,0.548,0,0.967,0.007h0.037c0.107,0.008,0.199,0.008,0.282,0.008
               c0.244,0,0.381-0.008,0.526,0.084c0.053,0.038,0.091,0.061,0.121,0.084c0.068,0.053,0.099,0.076,0.305,0.099
               c0.19,0.022,0.274,0,0.365-0.023c0.039-0.015,0.084-0.023,0.146-0.03c0.053-0.008,0.09-0.015,0.129-0.024
               c0.015-0.007,0.038-0.007,0.054-0.015c0-0.144,0-0.586-0.023-0.654c-0.008-0.031-0.022-0.031-0.061-0.046
               s-0.093-0.038-0.177-0.092c-0.098-0.06-0.151-0.113-0.182-0.167c-0.029-0.061-0.029-0.114-0.015-0.168
               c0.008-0.045,0.015-0.106,0.029-0.175c0.009-0.061,0.016-0.122,0.023-0.152c0-0.031-0.008-0.053-0.023-0.076
               c-0.015-0.038-0.044-0.076-0.091-0.122c-0.091-0.091-0.068-0.289-0.045-0.388c-0.031-0.008-0.062-0.023-0.084-0.046
               c-0.092-0.061-0.176-0.16-0.229-0.274c-0.092-0.16-0.122-0.175-0.26-0.251l-0.061-0.038c-0.19-0.107-0.221-0.282-0.267-0.503
               c-0.007-0.038-0.015-0.076-0.022-0.106c-0.023-0.107-0.054-0.1-0.107-0.084c-0.053,0.015-0.121,0.03-0.212,0
               c-0.183-0.069-0.19-0.107-0.19-0.236v-0.092c-0.016-0.107-0.223-0.129-0.357-0.144c-0.047-0.008-0.084-0.015-0.115-0.023
               c-0.038-0.008-0.106,0-0.183,0.015c-0.099,0.016-0.213,0.054-0.312,0.091c-0.092,0.031-0.183,0.046-0.282,0.046
               c-0.122,0-0.235-0.03-0.305-0.053c-0.062-0.016-0.091,0.023-0.159,0.122c-0.023,0.03-0.054,0.076-0.092,0.114
               C100.246,58.275,100.14,58.321,100.049,58.337"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M100.049,58.337c-0.031,0.335-0.023,0.472-0.152,0.632c-0.146,0.182-0.595,0.624-0.457,0.723
               c0.129,0.099,0.289,0.122,0.318,0.374c0.039,0.243,0.32,0.137,0.176,0.456c0.137,0.015,0.365,0.03,0.609,0.015
               c0.213-0.015,0.289-0.061,0.358-0.091c0.067-0.046,0.122-0.076,0.259-0.061c0.145,0.015,0.213,0.045,0.281,0.076
               c0.054,0.015,0.114,0.046,0.221,0.061c0.13,0.023,0.214,0.069,0.274,0.099c0.061,0.03,0.099,0.054,0.129,0.038
               c0.062-0.023,0.129-0.015,0.19,0.008c0.038,0.015,0.068,0.03,0.092,0.053c0.03,0.023,0.054,0.054,0.054,0.084
               c0.007,0.023,0,0.054-0.017,0.069c-0.045,0.084-0.205,0.113-0.35,0.152c-0.038,0.007-0.075,0.014-0.106,0.023
               c-0.084,0.022-0.159,0.022-0.243,0.015c-0.084-0.015-0.176-0.046-0.297-0.092c-0.047-0.007-0.084-0.023-0.114-0.038
               c-0.168-0.06-0.29-0.106-0.381-0.023c-0.023,0.015-0.038,0.031-0.054,0.047c-0.037,0.029-0.063,0.052-0.091,0.068
               c-0.03,0.014-0.069,0.023-0.138,0.045h-0.022c-0.038,0.016-0.084,0.023-0.122,0.031c-0.137,0.038-0.267,0.068-0.403,0.076
               c-0.069,0.007-0.183,0.061-0.282,0.114c0.054,0.304-0.099,0.335,0.092,0.518c0.023,0.015,0.046,0.038,0.061,0.053
               c0.16-0.084,0.373-0.168,0.555-0.168c0.185,0.009,0.344-0.068,0.474-0.128c0.084-0.039,0.16-0.07,0.229-0.077
               c0.145-0.015,0.548,0,0.967,0.007h0.037c0.107,0.008,0.199,0.008,0.282,0.008c0.244,0,0.381-0.008,0.526,0.084
               c0.053,0.038,0.091,0.061,0.121,0.084c0.068,0.053,0.099,0.076,0.305,0.099c0.19,0.022,0.274,0,0.365-0.023
               c0.039-0.015,0.084-0.023,0.146-0.03c0.053-0.008,0.09-0.015,0.129-0.024c0.015-0.007,0.038-0.007,0.054-0.015
               c0-0.144,0-0.586-0.023-0.654c-0.008-0.031-0.022-0.031-0.061-0.046s-0.093-0.038-0.177-0.092
               c-0.098-0.06-0.151-0.113-0.182-0.167c-0.029-0.061-0.029-0.114-0.015-0.168c0.008-0.045,0.015-0.106,0.029-0.175
               c0.009-0.061,0.016-0.122,0.023-0.152c0-0.031-0.008-0.053-0.023-0.076c-0.015-0.038-0.044-0.076-0.091-0.122
               c-0.091-0.091-0.068-0.289-0.045-0.388c-0.031-0.008-0.062-0.023-0.084-0.046c-0.092-0.061-0.176-0.16-0.229-0.274
               c-0.092-0.16-0.122-0.175-0.26-0.251l-0.061-0.038c-0.19-0.107-0.221-0.282-0.267-0.503c-0.007-0.038-0.015-0.076-0.022-0.106
               c-0.023-0.107-0.054-0.1-0.107-0.084c-0.053,0.015-0.121,0.03-0.212,0c-0.183-0.069-0.19-0.107-0.19-0.236v-0.092
               c-0.016-0.107-0.223-0.129-0.357-0.144c-0.047-0.008-0.084-0.015-0.115-0.023c-0.038-0.008-0.106,0-0.183,0.015
               c-0.099,0.016-0.213,0.054-0.312,0.091c-0.092,0.031-0.183,0.046-0.282,0.046c-0.122,0-0.235-0.03-0.305-0.053
               c-0.062-0.016-0.091,0.023-0.159,0.122c-0.023,0.03-0.054,0.076-0.092,0.114C100.246,58.275,100.14,58.321,100.049,58.337z"/>
             <path fill="#808184" d="M99.767,53.457c0,0.037,0.008,0.076,0.015,0.114
               c0.092,0.304,0.32,0.236,0.366,0.457c0.045,0.228,0.281,0.084,0.313,0.441c0.029,0.358-0.184,0.327-0.184,0.633
               c0,0.304,0.068,0.448,0.114,0.517c0.046,0.061,0.145,0.418,0.121,0.891c-0.022,0.472-0.083,0.7-0.167,0.845
               c-0.083,0.145-0.221,0.427-0.281,0.852v0.023c0.061-0.015,0.129-0.045,0.182-0.114c0.031-0.045,0.062-0.083,0.085-0.114
               c0.106-0.137,0.145-0.198,0.273-0.151c0.069,0.014,0.168,0.037,0.281,0.037c0.077,0.007,0.161-0.008,0.236-0.037
               c0.107-0.039,0.229-0.077,0.328-0.092c0.092-0.023,0.175-0.031,0.236-0.016c0.015,0.008,0.053,0.016,0.091,0.016
               c0.168,0.023,0.435,0.053,0.449,0.235c0,0.046,0,0.077,0.008,0.107c0,0.076,0,0.099,0.114,0.138c0.061,0.021,0.114,0.007,0.16,0
               c0.106-0.032,0.183-0.047,0.228,0.159c0.008,0.046,0.016,0.076,0.022,0.115c0.039,0.19,0.069,0.342,0.214,0.426
               c0.038,0.022,0.054,0.03,0.068,0.039c0.152,0.082,0.19,0.106,0.29,0.296c0.053,0.099,0.13,0.183,0.197,0.236
               c0.062,0.046,0.123,0.068,0.168,0.046c0.022-0.008,0.046-0.016,0.068-0.024c0.092-0.022,0.168-0.053,0.152-0.166
               c-0.016-0.13,0.016-0.336,0.076-0.472c0.016-0.039,0.031-0.069,0.054-0.1c0.022-0.03,0.053-0.046,0.083-0.053
               c0.023,0,0.038,0,0.054,0.007c0.091,0.039,0.137,0.084,0.198,0.153c0.038,0.03,0.075,0.068,0.129,0.114
               c0.092,0.084,0.274,0,0.396-0.054c0.023-0.007,0.055-0.022,0.069-0.03c0.068-0.023,0.106-0.015,0.16-0.008
               c0.053,0.008,0.121,0.023,0.243,0.016c0.146,0,0.221-0.038,0.297-0.069c0.061-0.031,0.114-0.053,0.19-0.06
               c0.114-0.008,0.229,0,0.365,0.015c0.046,0.007,0.1,0.007,0.16,0.014c0.129,0.009,1.119,0.062,1.842,0.069
               c0.397,0,0.709-0.007,0.739-0.038c0.061-0.038,0.114-0.213,0.145-0.381c0.03-0.152,0.038-0.304-0.008-0.357
               c-0.022-0.031-0.053-0.046-0.083-0.069c-0.106-0.069-0.206-0.129-0.168-0.472c0.016-0.182-0.106-1.728-0.243-3.373
               c-0.168-2.01-0.389-4.165-0.298-4.218c0.092-0.061,0.838-0.061,1.143-0.053c-0.784-0.578-1.499-1.097-1.987-1.455
               c-0.289-0.213-0.503-0.365-0.602-0.441c0.008,0.213,0.008,0.67-0.129,0.945c-0.145,0.296-1.523,0.181-2.231,0.121
               c-0.197-0.015-0.343-0.031-0.389-0.031c-0.084,0.008-0.098,0.815-0.106,1.455c-0.007,0.365-0.007,0.669-0.037,0.769
               c-0.046,0.205-0.13,0.228-0.274,0.266c-0.053,0.023-0.13,0.038-0.214,0.084c-0.121,0.061-0.197,0.129-0.251,0.22
               c-0.053,0.092-0.092,0.206-0.137,0.359c-0.038,0.144-0.015,0.41,0,0.646c0.022,0.243,0.045,0.456-0.023,0.517
               C102.987,53.487,100.817,53.472,99.767,53.457"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M99.767,53.457c0,0.037,0.008,0.076,0.015,0.114c0.092,0.304,0.32,0.236,0.366,0.457c0.045,0.228,0.281,0.084,0.313,0.441
               c0.029,0.358-0.184,0.327-0.184,0.633c0,0.304,0.068,0.448,0.114,0.517c0.046,0.061,0.145,0.418,0.121,0.891
               c-0.022,0.472-0.083,0.7-0.167,0.845c-0.083,0.145-0.221,0.427-0.281,0.852v0.023c0.061-0.015,0.129-0.045,0.182-0.114
               c0.031-0.045,0.062-0.083,0.085-0.114c0.106-0.137,0.145-0.198,0.273-0.151c0.069,0.014,0.168,0.037,0.281,0.037
               c0.077,0.007,0.161-0.008,0.236-0.037c0.107-0.039,0.229-0.077,0.328-0.092c0.092-0.023,0.175-0.031,0.236-0.016
               c0.015,0.008,0.053,0.016,0.091,0.016c0.168,0.023,0.435,0.053,0.449,0.235c0,0.046,0,0.077,0.008,0.107
               c0,0.076,0,0.099,0.114,0.138c0.061,0.021,0.114,0.007,0.16,0c0.106-0.032,0.183-0.047,0.228,0.159
               c0.008,0.046,0.016,0.076,0.022,0.115c0.039,0.19,0.069,0.342,0.214,0.426c0.038,0.022,0.054,0.03,0.068,0.039
               c0.152,0.082,0.19,0.106,0.29,0.296c0.053,0.099,0.13,0.183,0.197,0.236c0.062,0.046,0.123,0.068,0.168,0.046
               c0.022-0.008,0.046-0.016,0.068-0.024c0.092-0.022,0.168-0.053,0.152-0.166c-0.016-0.13,0.016-0.336,0.076-0.472
               c0.016-0.039,0.031-0.069,0.054-0.1c0.022-0.03,0.053-0.046,0.083-0.053c0.023,0,0.038,0,0.054,0.007
               c0.091,0.039,0.137,0.084,0.198,0.153c0.038,0.03,0.075,0.068,0.129,0.114c0.092,0.084,0.274,0,0.396-0.054
               c0.023-0.007,0.055-0.022,0.069-0.03c0.068-0.023,0.106-0.015,0.16-0.008c0.053,0.008,0.121,0.023,0.243,0.016
               c0.146,0,0.221-0.038,0.297-0.069c0.061-0.031,0.114-0.053,0.19-0.06c0.114-0.008,0.229,0,0.365,0.015
               c0.046,0.007,0.1,0.007,0.16,0.014c0.129,0.009,1.119,0.062,1.842,0.069c0.397,0,0.709-0.007,0.739-0.038
               c0.061-0.038,0.114-0.213,0.145-0.381c0.03-0.152,0.038-0.304-0.008-0.357c-0.022-0.031-0.053-0.046-0.083-0.069
               c-0.106-0.069-0.206-0.129-0.168-0.472c0.016-0.182-0.106-1.728-0.243-3.373c-0.168-2.01-0.389-4.165-0.298-4.218
               c0.092-0.061,0.838-0.061,1.143-0.053c-0.784-0.578-1.499-1.097-1.987-1.455c-0.289-0.213-0.503-0.365-0.602-0.441
               c0.008,0.213,0.008,0.67-0.129,0.945c-0.145,0.296-1.523,0.181-2.231,0.121c-0.197-0.015-0.343-0.031-0.389-0.031
               c-0.084,0.008-0.098,0.815-0.106,1.455c-0.007,0.365-0.007,0.669-0.037,0.769c-0.046,0.205-0.13,0.228-0.274,0.266
               c-0.053,0.023-0.13,0.038-0.214,0.084c-0.121,0.061-0.197,0.129-0.251,0.22c-0.053,0.092-0.092,0.206-0.137,0.359
               c-0.038,0.144-0.015,0.41,0,0.646c0.022,0.243,0.045,0.456-0.023,0.517C102.987,53.487,100.817,53.472,99.767,53.457z"/>
             <path fill="#808184" d="M103.071,47.533c-0.008,0.015-0.008,0.023-0.016,0.031
               c-0.114,0.266-0.221,0.746-0.563,0.997c-0.335,0.259-0.503,0.343-0.541,0.655c-0.03,0.319-0.296,0.464-0.335,0.662
               c-0.029,0.206,0.168,0.45-0.053,0.64c-0.229,0.198-0.511,0.282-0.814,0.792c-0.305,0.502-0.32,0.845-0.519,1.149
               c-0.145,0.237-0.396,0.602-0.449,0.899c1.036,0.015,3.16,0.03,3.229-0.03c0.03-0.024,0.016-0.221-0.007-0.434
               c-0.023-0.252-0.046-0.526,0-0.686c0.045-0.159,0.084-0.281,0.152-0.38c0.061-0.107,0.144-0.191,0.289-0.259
               c0.1-0.046,0.167-0.069,0.229-0.084c0.105-0.038,0.167-0.053,0.213-0.198c0.015-0.084,0.022-0.396,0.03-0.746
               c0.008-0.685,0.016-1.545,0.206-1.554c0.053,0,0.197,0.009,0.396,0.031c0.686,0.053,2.018,0.168,2.132-0.069
               c0.221-0.457,0.045-0.822,0.1-1.195c0.022-0.167,0.015-0.183-0.13-0.198C106.116,47.511,103.924,47.526,103.071,47.533"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M103.071,47.533c-0.008,0.015-0.008,0.023-0.016,0.031c-0.114,0.266-0.221,0.746-0.563,0.997
               c-0.335,0.259-0.503,0.343-0.541,0.655c-0.03,0.319-0.296,0.464-0.335,0.662c-0.029,0.206,0.168,0.45-0.053,0.64
               c-0.229,0.198-0.511,0.282-0.814,0.792c-0.305,0.502-0.32,0.845-0.519,1.149c-0.145,0.237-0.396,0.602-0.449,0.899
               c1.036,0.015,3.16,0.03,3.229-0.03c0.03-0.024,0.016-0.221-0.007-0.434c-0.023-0.252-0.046-0.526,0-0.686
               c0.045-0.159,0.084-0.281,0.152-0.38c0.061-0.107,0.144-0.191,0.289-0.259c0.1-0.046,0.167-0.069,0.229-0.084
               c0.105-0.038,0.167-0.053,0.213-0.198c0.015-0.084,0.022-0.396,0.03-0.746c0.008-0.685,0.016-1.545,0.206-1.554
               c0.053,0,0.197,0.009,0.396,0.031c0.686,0.053,2.018,0.168,2.132-0.069c0.221-0.457,0.045-0.822,0.1-1.195
               c0.022-0.167,0.015-0.183-0.13-0.198C106.116,47.511,103.924,47.526,103.071,47.533z"/>
             <path fill="#808184" d="M122.715,42.409c-0.106-0.039-0.183-0.068-0.221-0.123
               c-0.092-0.106-0.092-0.57-0.259-0.524c-0.168,0.045-0.358,0.281-0.495-0.016c-0.137-0.289-0.235-0.411,0.054-0.532
               c0.297-0.13,0.555-0.298,0.563-0.511c0.015-0.213,0.19-0.396,0.015-0.563c-0.183-0.168-0.343-0.236-0.343-0.404
               s0.023-0.312,0.13-0.381c0.099-0.069,0.381-0.472,0.167-0.509c-0.213-0.032-0.235,0.129-0.418,0.151
               c-0.183,0.024,0.067-0.015-0.215-0.213c-0.281-0.206-0.425-0.259-0.516-0.198c-0.092,0.069-0.245,0.198-0.328,0.206
               c-0.038,0.008-0.175,0.031-0.335,0.054v0.014c-0.016,0.206-0.069,0.245-0.122,0.282c-0.031,0.023-0.068,0.053-0.091,0.213
               c-0.024,0.229-0.016,0.373,0,0.564c0.008,0.114,0.022,0.243,0.022,0.427c0.016,0.448,0,0.464-0.138,0.685l-0.037,0.061
               c-0.062,0.091-0.123,0.167-0.19,0.243c-0.114,0.13-0.229,0.252-0.236,0.465c-0.023,0.289,0.038,0.365,0.229,0.593l0.045,0.069
               c0.107,0.122,0.161,0.221,0.198,0.312c0.062,0.114,0.107,0.206,0.259,0.32c0.092,0.068,0.152,0.076,0.19,0.084
               c0.106,0.015,0.152,0.023,0.244,0.319c0.068,0.222,0.129,0.45,0.182,0.633c0.054,0.197,0.093,0.35,0.093,0.388
               c0,0.037,0.03,0.296,0.076,0.601c0.145-0.106,0.455-0.327,0.518-0.426c0.038-0.061,0.015-0.138-0.016-0.243
               c-0.03-0.084-0.062-0.184-0.068-0.306c-0.022-0.205,0.054-0.274,0.168-0.373c0.068-0.052,0.145-0.121,0.228-0.243
               c0.115-0.175,0.236-0.214,0.365-0.251c0.1-0.031,0.19-0.061,0.282-0.183c0.105-0.153,0.084-0.198,0.054-0.274
               c-0.03-0.054-0.062-0.115-0.062-0.221C122.707,42.531,122.715,42.462,122.715,42.409"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M122.715,42.409c-0.106-0.039-0.183-0.068-0.221-0.123c-0.092-0.106-0.092-0.57-0.259-0.524
               c-0.168,0.045-0.358,0.281-0.495-0.016c-0.137-0.289-0.235-0.411,0.054-0.532c0.297-0.13,0.555-0.298,0.563-0.511
               c0.015-0.213,0.19-0.396,0.015-0.563c-0.183-0.168-0.343-0.236-0.343-0.404s0.023-0.312,0.13-0.381
               c0.099-0.069,0.381-0.472,0.167-0.509c-0.213-0.032-0.235,0.129-0.418,0.151c-0.183,0.024,0.067-0.015-0.215-0.213
               c-0.281-0.206-0.425-0.259-0.516-0.198c-0.092,0.069-0.245,0.198-0.328,0.206c-0.038,0.008-0.175,0.031-0.335,0.054v0.014
               c-0.016,0.206-0.069,0.245-0.122,0.282c-0.031,0.023-0.068,0.053-0.091,0.213c-0.024,0.229-0.016,0.373,0,0.564
               c0.008,0.114,0.022,0.243,0.022,0.427c0.016,0.448,0,0.464-0.138,0.685l-0.037,0.061c-0.062,0.091-0.123,0.167-0.19,0.243
               c-0.114,0.13-0.229,0.252-0.236,0.465c-0.023,0.289,0.038,0.365,0.229,0.593l0.045,0.069c0.107,0.122,0.161,0.221,0.198,0.312
               c0.062,0.114,0.107,0.206,0.259,0.32c0.092,0.068,0.152,0.076,0.19,0.084c0.106,0.015,0.152,0.023,0.244,0.319
               c0.068,0.222,0.129,0.45,0.182,0.633c0.054,0.197,0.093,0.35,0.093,0.388c0,0.037,0.03,0.296,0.076,0.601
               c0.145-0.106,0.455-0.327,0.518-0.426c0.038-0.061,0.015-0.138-0.016-0.243c-0.03-0.084-0.062-0.184-0.068-0.306
               c-0.022-0.205,0.054-0.274,0.168-0.373c0.068-0.052,0.145-0.121,0.228-0.243c0.115-0.175,0.236-0.214,0.365-0.251
               c0.1-0.031,0.19-0.061,0.282-0.183c0.105-0.153,0.084-0.198,0.054-0.274c-0.03-0.054-0.062-0.115-0.062-0.221
               C122.707,42.531,122.715,42.462,122.715,42.409z"/>
             <path fill="#808184" d="M123.248,51.423c0.046-0.007,0.084-0.007,0.114,0
               c0.022,0,0.054,0.008,0.099,0.023c0.335,0.076,1.165,0.267,1.324,0.548c0.084,0.145,0.152,0.229,0.214,0.282
               c0.046,0.038,0.084,0.046,0.114,0.038c0.023-0.015,0.1-0.061,0.197-0.137c0.343-0.243,0.99-0.7,1.234-0.646
               c0.129,0.031,0.426,0.175,0.784,0.372c0.457,0.252,1.013,0.58,1.454,0.854c0.479,0.29,1.88,1.119,2.923,1.698
               c0.655,0.365,1.159,0.632,1.188,0.616c0.021-0.015,0.029-0.084,0.037-0.152c0.023-0.137,0.038-0.274,0.197-0.282
               c0.055-0.007,0.123,0.008,0.182,0.023c0.049,0.008,0.101,0.016,0.147,0.023c0.104,0.008,0.204-0.03,0.22-0.251
               c0.031-0.358-0.084-3.937-0.136-5.825c-0.017-0.495-0.031-0.868-0.031-1.051c-0.016-0.425-0.022-0.761-0.046-1.043
               c-0.021-0.282-0.063-0.502-0.121-0.693l-0.033-0.105c-0.104-0.336-0.156-0.488-0.064-0.717l0.015-0.053
               c0.084-0.213,0.183-0.464,0.114-0.563l-0.01-0.008c-0.045-0.084-0.045-0.206,0-0.327c0.029-0.084,0.086-0.168,0.146-0.237
               c0-0.114-0.023-0.289-0.313-0.32c-0.334-0.03-0.19,0-0.418,0c-0.223,0-0.262-0.175-0.557-0.175c-0.305,0-0.389-0.19-0.494-0.38
               c-0.115-0.198-0.131-0.304-0.654-0.282c-0.533,0.023-0.26,0.046-0.756,0.106c-0.494,0.069-0.752,0.488-0.83,0.602
               c-0.084,0.106-0.151-0.068-0.106,0.426c0.048,0.495,0.32,0.328,0.161,0.663c-0.161,0.335-0.381,0.48-0.419,0.571
               c-0.031,0.091-0.603,0.191-0.906-0.129c-0.305-0.328-0.426-0.419-0.876-0.518c-0.456-0.107-0.51-0.182-0.867-0.152
               c-0.357,0.037-0.7-0.45-0.746-0.808c-0.046-0.357-0.1-0.448-0.358-0.418c-0.259,0.037-0.639-0.122-0.944-0.191
               c-0.305-0.068-0.213-0.09-0.563-0.068c-0.35,0.022-0.312-0.038-0.662-0.175c-0.145-0.061-0.273-0.092-0.39-0.122v0.16
               c0,0.084,0.024,0.137,0.048,0.183c0.045,0.099,0.083,0.174-0.062,0.373c-0.106,0.152-0.221,0.183-0.343,0.221
               c-0.099,0.029-0.206,0.061-0.305,0.213c-0.084,0.129-0.176,0.205-0.243,0.259c-0.092,0.084-0.152,0.136-0.139,0.289
               c0.009,0.114,0.039,0.205,0.062,0.282c0.046,0.129,0.069,0.22,0.009,0.327c-0.077,0.122-0.465,0.396-0.587,0.488l0.03,0.22
               c0.038,0.289,0.068,0.427,0.106,0.617c0.016,0.068,0.039,0.152,0.062,0.266c0.038,0.19,0.067,0.358,0.091,0.518
               c0.016,0.168,0.023,0.335,0.023,0.541c-0.008,0.312-0.023,0.456-0.047,0.67c-0.007,0.061-0.014,0.136-0.022,0.213
               c-0.016,0.16-0.106,0.274-0.19,0.38c-0.091,0.115-0.175,0.221-0.105,0.389c0.061,0.137,0.175,0.244,0.28,0.343
               c0.169,0.144,0.328,0.282,0.298,0.517c-0.016,0.099-0.03,0.183-0.046,0.26c-0.038,0.159-0.061,0.251,0.062,0.318
               c0.083,0.046,0.167,0.024,0.243-0.007c0.114-0.038,0.229-0.076,0.366,0.007c0.091,0.055,0.167,0.092,0.242,0.13
               c0.131,0.061,0.244,0.114,0.366,0.229C123.119,50.951,123.194,51.218,123.248,51.423"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M123.248,51.423c0.046-0.007,0.084-0.007,0.114,0c0.022,0,0.054,0.008,0.099,0.023c0.335,0.076,1.165,0.267,1.324,0.548
               c0.084,0.145,0.152,0.229,0.214,0.282c0.046,0.038,0.084,0.046,0.114,0.038c0.023-0.015,0.1-0.061,0.197-0.137
               c0.343-0.243,0.99-0.7,1.234-0.646c0.129,0.031,0.426,0.175,0.784,0.372c0.457,0.252,1.013,0.58,1.454,0.854
               c0.479,0.29,1.88,1.119,2.923,1.698c0.655,0.365,1.159,0.632,1.188,0.616c0.021-0.015,0.029-0.084,0.037-0.152
               c0.023-0.137,0.038-0.274,0.197-0.282c0.055-0.007,0.123,0.008,0.182,0.023c0.049,0.008,0.101,0.016,0.147,0.023
               c0.104,0.008,0.204-0.03,0.22-0.251c0.031-0.358-0.084-3.937-0.136-5.825c-0.017-0.495-0.031-0.868-0.031-1.051
               c-0.016-0.425-0.022-0.761-0.046-1.043c-0.021-0.282-0.063-0.502-0.121-0.693l-0.033-0.105
               c-0.104-0.336-0.156-0.488-0.064-0.717l0.015-0.053c0.084-0.213,0.183-0.464,0.114-0.563l-0.01-0.008
               c-0.045-0.084-0.045-0.206,0-0.327c0.029-0.084,0.086-0.168,0.146-0.237c0-0.114-0.023-0.289-0.313-0.32
               c-0.334-0.03-0.19,0-0.418,0c-0.223,0-0.262-0.175-0.557-0.175c-0.305,0-0.389-0.19-0.494-0.38
               c-0.115-0.198-0.131-0.304-0.654-0.282c-0.533,0.023-0.26,0.046-0.756,0.106c-0.494,0.069-0.752,0.488-0.83,0.602
               c-0.084,0.106-0.151-0.068-0.106,0.426c0.048,0.495,0.32,0.328,0.161,0.663c-0.161,0.335-0.381,0.48-0.419,0.571
               c-0.031,0.091-0.603,0.191-0.906-0.129c-0.305-0.328-0.426-0.419-0.876-0.518c-0.456-0.107-0.51-0.182-0.867-0.152
               c-0.357,0.037-0.7-0.45-0.746-0.808c-0.046-0.357-0.1-0.448-0.358-0.418c-0.259,0.037-0.639-0.122-0.944-0.191
               c-0.305-0.068-0.213-0.09-0.563-0.068c-0.35,0.022-0.312-0.038-0.662-0.175c-0.145-0.061-0.273-0.092-0.39-0.122v0.16
               c0,0.084,0.024,0.137,0.048,0.183c0.045,0.099,0.083,0.174-0.062,0.373c-0.106,0.152-0.221,0.183-0.343,0.221
               c-0.099,0.029-0.206,0.061-0.305,0.213c-0.084,0.129-0.176,0.205-0.243,0.259c-0.092,0.084-0.152,0.136-0.139,0.289
               c0.009,0.114,0.039,0.205,0.062,0.282c0.046,0.129,0.069,0.22,0.009,0.327c-0.077,0.122-0.465,0.396-0.587,0.488l0.03,0.22
               c0.038,0.289,0.068,0.427,0.106,0.617c0.016,0.068,0.039,0.152,0.062,0.266c0.038,0.19,0.067,0.358,0.091,0.518
               c0.016,0.168,0.023,0.335,0.023,0.541c-0.008,0.312-0.023,0.456-0.047,0.67c-0.007,0.061-0.014,0.136-0.022,0.213
               c-0.016,0.16-0.106,0.274-0.19,0.38c-0.091,0.115-0.175,0.221-0.105,0.389c0.061,0.137,0.175,0.244,0.28,0.343
               c0.169,0.144,0.328,0.282,0.298,0.517c-0.016,0.099-0.03,0.183-0.046,0.26c-0.038,0.159-0.061,0.251,0.062,0.318
               c0.083,0.046,0.167,0.024,0.243-0.007c0.114-0.038,0.229-0.076,0.366,0.007c0.091,0.055,0.167,0.092,0.242,0.13
               c0.131,0.061,0.244,0.114,0.366,0.229C123.119,50.951,123.194,51.218,123.248,51.423z"/>
             <path fill="#808184" d="M106.726,47.479c0.03,0,0.046,0.008,0.053,0.008
               c0.1,0.068,0.084,0.175,0.069,0.274c-0.008,0.069-0.015,0.137,0.015,0.159c0.016,0.016,0.267,0.199,0.663,0.488
               c1.873,1.363,6.981,5.086,7.043,5.284c0.015,0.038,0.022,0.083,0.03,0.122c0.046,0.167,0.084,0.327,0.289,0.358
               c0.175,0.031,0.183,0.092,0.19,0.175c0,0.061,0.008,0.13,0.183,0.214c0.16,0.075,0.335,0.075,0.487,0.075
               c0.221,0,0.403,0,0.465,0.206c0.046,0.152,0,0.243-0.053,0.335c-0.039,0.076-0.077,0.146,0,0.281
               c0.136,0.222,0.144,0.222,0.342,0.183c0.038-0.007,0.076-0.015,0.122-0.03c0.061-0.008,0.129-0.015,0.205-0.03
               c0.411-0.069,1.074-0.176,1.439-0.449c0.175-0.138,0.327-0.313,0.487-0.503c0.251-0.297,0.533-0.617,0.959-0.854
               c0.229-0.129,0.396-0.19,0.548-0.25c0.297-0.114,0.495-0.191,0.944-0.686c0.313-0.342,0.739-0.677,1.127-0.944
               c0.32-0.205,0.617-0.365,0.815-0.441c-0.047-0.198-0.114-0.442-0.214-0.54c-0.099-0.1-0.205-0.153-0.327-0.206
               c-0.076-0.039-0.16-0.077-0.26-0.13c-0.099-0.068-0.189-0.037-0.273,0c-0.106,0.031-0.206,0.068-0.327,0
               c-0.19-0.114-0.169-0.228-0.114-0.433c0.016-0.07,0.03-0.153,0.038-0.252c0.022-0.175-0.106-0.297-0.251-0.426
               c-0.122-0.108-0.252-0.221-0.313-0.381c-0.099-0.221,0.008-0.35,0.122-0.495c0.068-0.091,0.152-0.191,0.167-0.327
               c0.008-0.084,0.015-0.153,0.015-0.214c0.023-0.205,0.039-0.35,0.046-0.654c0.009-0.206,0-0.366-0.022-0.526
               c-0.015-0.168-0.046-0.32-0.084-0.51c-0.022-0.099-0.046-0.19-0.061-0.266c-0.038-0.191-0.068-0.328-0.106-0.625l-0.046-0.32
               c-0.045-0.312-0.083-0.586-0.083-0.631c0-0.031-0.031-0.168-0.084-0.359c-0.054-0.19-0.114-0.418-0.183-0.631
               c-0.076-0.236-0.1-0.244-0.16-0.252c-0.053-0.007-0.122-0.015-0.236-0.106c-0.167-0.122-0.221-0.229-0.281-0.35
               c-0.046-0.084-0.092-0.175-0.19-0.298l-0.054-0.061c-0.205-0.251-0.273-0.342-0.251-0.669c0.016-0.244,0.137-0.381,0.267-0.526
               c0.061-0.068,0.13-0.145,0.183-0.228l0.03-0.061c0.13-0.198,0.137-0.213,0.13-0.632c-0.008-0.175-0.016-0.305-0.022-0.419
               c-0.017-0.198-0.031-0.342,0-0.578c0.022-0.206,0.075-0.252,0.129-0.29c0.037-0.022,0.068-0.045,0.084-0.19
               c-0.13,0.023-0.259,0.045-0.337,0.061c-0.196,0.046-0.204-0.106-0.372-0.138c-0.175-0.03-0.289,0.008-0.342,0.062
               c-0.046,0.053-0.046,0.183-0.244,0.099c-0.206-0.084-0.319-0.213-0.449-0.138c-0.13,0.077-0.183,0.207-0.313,0.222
               c-0.129,0.022-0.304,0.037-0.381,0.091c-0.083,0.045-0.105,0.084-0.335-0.046c-0.22-0.122-0.479-0.19-0.829-0.129
               c-0.351,0.069-0.396,0.152-0.625,0.182c-0.229,0.031-0.335,0.099-0.616,0.099c-0.282,0-0.525-0.099-0.731,0.015
               c-0.197,0.115-0.259,0.069-0.548,0.237c-0.289,0.16-0.259,0.312-0.457,0.357c-0.197,0.046-0.252-0.084-0.434,0.038
               c-0.176,0.115-0.343,0.213-0.602,0.419c-0.244,0.198-0.26,0.366-0.487,0.358c0.038,0.114,0.084,0.229,0.121,0.297
               c0.069,0.107,0.054,0.152,0.031,0.213c-0.023,0.046-0.046,0.107,0.007,0.267c0.106,0.335,0.1,0.563,0.092,0.723v0.015
               c-0.008,0.114,0.022,0.191,0.068,0.305c0.016,0.03,0.023,0.061,0.038,0.098c0.031,0.077,0.092,0.146,0.152,0.207
               c0.069,0.083,0.138,0.152,0.152,0.243c0.016,0.114-0.03,0.16-0.083,0.221c-0.023,0.023-0.054,0.046-0.069,0.083
               c-0.037,0.076-0.076,0.115-0.137,0.137c-0.062,0.023-0.13,0.023-0.244,0.031h-0.121c-0.221,0.007-0.526,0.015-0.701,0.031
               c-0.137,0.015-0.205,0.091-0.259,0.167c-0.022,0.023-0.046,0.054-0.075,0.076c-0.069,0.068-0.161,0.068-0.268,0.077
               c-0.061,0-0.137,0.007-0.197,0.029c-0.023,0.008-0.038,0.016-0.054,0.032c-0.03,0.023-0.045,0.06-0.054,0.106
               c-0.007,0.038-0.007,0.091,0.009,0.137c0.015,0.03,0.029,0.053,0.053,0.076c0.061,0.076,0.122,0.152,0.145,0.213
               c0.022,0.091,0.008,0.161-0.099,0.19c-0.069,0.023-0.138,0.016-0.206,0.008c-0.084-0.008-0.167-0.023-0.205,0.038
               c-0.046,0.076-0.183,0.146-0.328,0.221c-0.175,0.091-0.373,0.198-0.426,0.304c-0.031,0.069-0.038,0.123-0.046,0.168
               c-0.015,0.122-0.031,0.191-0.319,0.244c-0.176,0.03-0.32,0-0.441-0.031c-0.122-0.03-0.214-0.053-0.268,0.031
               c-0.022,0.045-0.046,0.091-0.068,0.129c-0.075,0.16-0.121,0.259-0.335,0.183c-0.076-0.023-0.129-0.061-0.184-0.084
               c-0.082-0.053-0.128-0.084-0.197,0.023c-0.106,0.153-0.236,0.259-0.411,0.404c-0.061,0.045-0.129,0.099-0.205,0.167
               c-0.13,0.107-0.229,0.168-0.297,0.214c-0.084,0.045-0.122,0.075-0.137,0.129C106.718,46.894,106.726,47.351,106.726,47.479"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M106.726,47.479c0.03,0,0.046,0.008,0.053,0.008c0.1,0.068,0.084,0.175,0.069,0.274c-0.008,0.069-0.015,0.137,0.015,0.159
               c0.016,0.016,0.267,0.199,0.663,0.488c1.873,1.363,6.981,5.086,7.043,5.284c0.015,0.038,0.022,0.083,0.03,0.122
               c0.046,0.167,0.084,0.327,0.289,0.358c0.175,0.031,0.183,0.092,0.19,0.175c0,0.061,0.008,0.13,0.183,0.214
               c0.16,0.075,0.335,0.075,0.487,0.075c0.221,0,0.403,0,0.465,0.206c0.046,0.152,0,0.243-0.053,0.335
               c-0.039,0.076-0.077,0.146,0,0.281c0.136,0.222,0.144,0.222,0.342,0.183c0.038-0.007,0.076-0.015,0.122-0.03
               c0.061-0.008,0.129-0.015,0.205-0.03c0.411-0.069,1.074-0.176,1.439-0.449c0.175-0.138,0.327-0.313,0.487-0.503
               c0.251-0.297,0.533-0.617,0.959-0.854c0.229-0.129,0.396-0.19,0.548-0.25c0.297-0.114,0.495-0.191,0.944-0.686
               c0.313-0.342,0.739-0.677,1.127-0.944c0.32-0.205,0.617-0.365,0.815-0.441c-0.047-0.198-0.114-0.442-0.214-0.54
               c-0.099-0.1-0.205-0.153-0.327-0.206c-0.076-0.039-0.16-0.077-0.26-0.13c-0.099-0.068-0.189-0.037-0.273,0
               c-0.106,0.031-0.206,0.068-0.327,0c-0.19-0.114-0.169-0.228-0.114-0.433c0.016-0.07,0.03-0.153,0.038-0.252
               c0.022-0.175-0.106-0.297-0.251-0.426c-0.122-0.108-0.252-0.221-0.313-0.381c-0.099-0.221,0.008-0.35,0.122-0.495
               c0.068-0.091,0.152-0.191,0.167-0.327c0.008-0.084,0.015-0.153,0.015-0.214c0.023-0.205,0.039-0.35,0.046-0.654
               c0.009-0.206,0-0.366-0.022-0.526c-0.015-0.168-0.046-0.32-0.084-0.51c-0.022-0.099-0.046-0.19-0.061-0.266
               c-0.038-0.191-0.068-0.328-0.106-0.625l-0.046-0.32c-0.045-0.312-0.083-0.586-0.083-0.631c0-0.031-0.031-0.168-0.084-0.359
               c-0.054-0.19-0.114-0.418-0.183-0.631c-0.076-0.236-0.1-0.244-0.16-0.252c-0.053-0.007-0.122-0.015-0.236-0.106
               c-0.167-0.122-0.221-0.229-0.281-0.35c-0.046-0.084-0.092-0.175-0.19-0.298l-0.054-0.061c-0.205-0.251-0.273-0.342-0.251-0.669
               c0.016-0.244,0.137-0.381,0.267-0.526c0.061-0.068,0.13-0.145,0.183-0.228l0.03-0.061c0.13-0.198,0.137-0.213,0.13-0.632
               c-0.008-0.175-0.016-0.305-0.022-0.419c-0.017-0.198-0.031-0.342,0-0.578c0.022-0.206,0.075-0.252,0.129-0.29
               c0.037-0.022,0.068-0.045,0.084-0.19c-0.13,0.023-0.259,0.045-0.337,0.061c-0.196,0.046-0.204-0.106-0.372-0.138
               c-0.175-0.03-0.289,0.008-0.342,0.062c-0.046,0.053-0.046,0.183-0.244,0.099c-0.206-0.084-0.319-0.213-0.449-0.138
               c-0.13,0.077-0.183,0.207-0.313,0.222c-0.129,0.022-0.304,0.037-0.381,0.091c-0.083,0.045-0.105,0.084-0.335-0.046
               c-0.22-0.122-0.479-0.19-0.829-0.129c-0.351,0.069-0.396,0.152-0.625,0.182c-0.229,0.031-0.335,0.099-0.616,0.099
               c-0.282,0-0.525-0.099-0.731,0.015c-0.197,0.115-0.259,0.069-0.548,0.237c-0.289,0.16-0.259,0.312-0.457,0.357
               c-0.197,0.046-0.252-0.084-0.434,0.038c-0.176,0.115-0.343,0.213-0.602,0.419c-0.244,0.198-0.26,0.366-0.487,0.358
               c0.038,0.114,0.084,0.229,0.121,0.297c0.069,0.107,0.054,0.152,0.031,0.213c-0.023,0.046-0.046,0.107,0.007,0.267
               c0.106,0.335,0.1,0.563,0.092,0.723v0.015c-0.008,0.114,0.022,0.191,0.068,0.305c0.016,0.03,0.023,0.061,0.038,0.098
               c0.031,0.077,0.092,0.146,0.152,0.207c0.069,0.083,0.138,0.152,0.152,0.243c0.016,0.114-0.03,0.16-0.083,0.221
               c-0.023,0.023-0.054,0.046-0.069,0.083c-0.037,0.076-0.076,0.115-0.137,0.137c-0.062,0.023-0.13,0.023-0.244,0.031h-0.121
               c-0.221,0.007-0.526,0.015-0.701,0.031c-0.137,0.015-0.205,0.091-0.259,0.167c-0.022,0.023-0.046,0.054-0.075,0.076
               c-0.069,0.068-0.161,0.068-0.268,0.077c-0.061,0-0.137,0.007-0.197,0.029c-0.023,0.008-0.038,0.016-0.054,0.032
               c-0.03,0.023-0.045,0.06-0.054,0.106c-0.007,0.038-0.007,0.091,0.009,0.137c0.015,0.03,0.029,0.053,0.053,0.076
               c0.061,0.076,0.122,0.152,0.145,0.213c0.022,0.091,0.008,0.161-0.099,0.19c-0.069,0.023-0.138,0.016-0.206,0.008
               c-0.084-0.008-0.167-0.023-0.205,0.038c-0.046,0.076-0.183,0.146-0.328,0.221c-0.175,0.091-0.373,0.198-0.426,0.304
               c-0.031,0.069-0.038,0.123-0.046,0.168c-0.015,0.122-0.031,0.191-0.319,0.244c-0.176,0.03-0.32,0-0.441-0.031
               c-0.122-0.03-0.214-0.053-0.268,0.031c-0.022,0.045-0.046,0.091-0.068,0.129c-0.075,0.16-0.121,0.259-0.335,0.183
               c-0.076-0.023-0.129-0.061-0.184-0.084c-0.082-0.053-0.128-0.084-0.197,0.023c-0.106,0.153-0.236,0.259-0.411,0.404
               c-0.061,0.045-0.129,0.099-0.205,0.167c-0.13,0.107-0.229,0.168-0.297,0.214c-0.084,0.045-0.122,0.075-0.137,0.129
               C106.718,46.894,106.726,47.351,106.726,47.479z"/>
             <path fill="#808184" d="M104.631,61.984L104.631,61.984
               C104.631,61.984,104.64,61.984,104.631,61.984"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M104.631,61.984L104.631,61.984C104.631,61.984,104.64,61.984,104.631,61.984z"/>
             <path fill="#808184" d="M136.39,100.285c0,0-0.205,0.135-0.365,0.266
               c-0.053,0.047-0.1,0.074-0.145,0.1c-0.076,0.045-0.131,0.076-0.146,0.158c-0.014,0.092-0.037,0.146-0.068,0.189
               c-0.037,0.047-0.074,0.078-0.13,0.108c-0.028,0.015-0.06,0.028-0.091,0.037c-0.028,0.017-0.061,0.022-0.084,0.037
               c-0.021,0.016-0.037,0.038-0.014,0.077c0.03,0.054,0.075,0.099,0.12,0.146c0.084,0.083,0.16,0.158,0.151,0.281
               c0,0.06,0.017,0.112,0.039,0.157c0.039,0.064,0.084,0.109,0.146,0.146c0.028,0.023,0.066,0.045,0.098,0.075
               c0.062,0.054,0.115,0.106,0.152,0.069c0.038-0.039,0.055-0.1,0.068-0.168c0.021-0.076,0.038-0.152,0.1-0.215
               c0.021-0.015,0.038-0.037,0.045-0.045c0.045-0.054,0.062-0.076,0.26-0.131c0.084-0.021,0.146-0.029,0.183-0.029
               c0.067-0.006,0.092-0.006,0.167-0.121c0.107-0.152,0.107-0.197,0.115-0.281v-0.076c0.016-0.076,0.053-0.121,0.092-0.16
               c0.029-0.037,0.061-0.074,0.016-0.145c-0.092-0.129-0.16-0.189-0.236-0.26l-0.053-0.053c-0.047-0.047-0.076-0.101-0.107-0.152
               c-0.029-0.062-0.061-0.113-0.105-0.121c-0.106,0-0.191,0.1-0.191,0.1l-0.008,0.008h-0.008V100.285z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M136.39,100.285c0,0-0.205,0.135-0.365,0.266c-0.053,0.047-0.1,0.074-0.145,0.1c-0.076,0.045-0.131,0.076-0.146,0.158
               c-0.014,0.092-0.037,0.146-0.068,0.189c-0.037,0.047-0.074,0.078-0.13,0.108c-0.028,0.015-0.06,0.028-0.091,0.037
               c-0.028,0.017-0.061,0.022-0.084,0.037c-0.021,0.016-0.037,0.038-0.014,0.077c0.03,0.054,0.075,0.099,0.12,0.146
               c0.084,0.083,0.16,0.158,0.151,0.281c0,0.06,0.017,0.112,0.039,0.157c0.039,0.064,0.084,0.109,0.146,0.146
               c0.028,0.023,0.066,0.045,0.098,0.075c0.062,0.054,0.115,0.106,0.152,0.069c0.038-0.039,0.055-0.1,0.068-0.168
               c0.021-0.076,0.038-0.152,0.1-0.215c0.021-0.015,0.038-0.037,0.045-0.045c0.045-0.054,0.062-0.076,0.26-0.131
               c0.084-0.021,0.146-0.029,0.183-0.029c0.067-0.006,0.092-0.006,0.167-0.121c0.107-0.152,0.107-0.197,0.115-0.281v-0.076
               c0.016-0.076,0.053-0.121,0.092-0.16c0.029-0.037,0.061-0.074,0.016-0.145c-0.092-0.129-0.16-0.189-0.236-0.26l-0.053-0.053
               c-0.047-0.047-0.076-0.101-0.107-0.152c-0.029-0.062-0.061-0.113-0.105-0.121c-0.106,0-0.191,0.1-0.191,0.1l-0.008,0.008h-0.008
               V100.285z"/>
             <path fill="#808184" d="M109.559,39.919c-0.069,0-0.358,0.152-0.496,0.495
               c-0.137,0.335-0.205,0.738-0.548,1.089c-0.35,0.35-0.51,0.403-0.891,0.571c-0.381,0.167-0.533,0.259-0.685,0.472
               c-0.161,0.213-0.496,0.343-0.519,0.632c-0.022,0.289,0.068,0.236-0.183,0.571c-0.243,0.342-0.479,0.701-0.373,0.99
               c0.114,0.297,0.32,0.327,0.046,0.662c-0.267,0.343-0.222,0.747-0.776,0.959c-0.549,0.214-0.808,0.83-1.234,0.853
               c-0.35,0.023-0.616,0.061-0.761,0.221c0.884-0.007,2.916-0.022,3.486,0.03c0-0.144-0.007-0.593,0.023-0.707
               c0.022-0.1,0.076-0.13,0.184-0.191c0.067-0.045,0.159-0.099,0.281-0.206c0.075-0.068,0.145-0.121,0.205-0.175
               c0.176-0.137,0.297-0.236,0.396-0.38c0.122-0.175,0.199-0.13,0.336-0.054c0.046,0.031,0.099,0.062,0.159,0.084
               c0.13,0.046,0.16-0.022,0.213-0.137c0.023-0.038,0.047-0.084,0.077-0.137c0.083-0.144,0.205-0.114,0.373-0.076
               c0.114,0.023,0.251,0.061,0.403,0.031c0.213-0.039,0.22-0.084,0.236-0.16c0.007-0.054,0.015-0.115,0.053-0.198
               c0.068-0.137,0.282-0.252,0.472-0.343c0.13-0.076,0.252-0.137,0.282-0.19c0.076-0.115,0.19-0.1,0.305-0.084
               c0.061,0.007,0.121,0.015,0.168,0c0.03-0.016,0.038-0.038,0.03-0.061c-0.016-0.054-0.069-0.114-0.122-0.183
               c-0.031-0.03-0.054-0.076-0.068-0.114c-0.023-0.061-0.03-0.13-0.016-0.191c0.008-0.06,0.038-0.121,0.084-0.159
               c0.023-0.023,0.053-0.046,0.084-0.054c0.083-0.022,0.159-0.03,0.235-0.03c0.084-0.008,0.16-0.008,0.198-0.046
               c0.023-0.023,0.046-0.045,0.061-0.076c0.077-0.083,0.152-0.183,0.327-0.198c0.184-0.022,0.488-0.022,0.709-0.031l0.129-0.007
               c0.099,0,0.16,0,0.206-0.015c0.038-0.016,0.061-0.038,0.083-0.091c0.023-0.054,0.062-0.084,0.092-0.115
               c0.031-0.031,0.062-0.061,0.046-0.129c-0.008-0.061-0.061-0.122-0.122-0.191c-0.068-0.068-0.137-0.144-0.168-0.243
               c-0.015-0.039-0.03-0.068-0.038-0.091c-0.053-0.13-0.083-0.214-0.083-0.35l0.008-0.008c0-0.153,0.007-0.373-0.092-0.693
               c-0.061-0.199-0.03-0.274-0.008-0.335c0.016-0.038,0.023-0.062-0.015-0.122c-0.054-0.084-0.1-0.221-0.145-0.35v-0.023
               c-0.16-0.039-0.236-0.122-0.427-0.13c-0.229,0-0.662-0.099-0.876-0.068c-0.213,0.038-0.402,0.068-0.586,0.023
               c-0.183-0.046-0.441-0.084-0.51-0.305c-0.061-0.228-0.016-0.281-0.092-0.289C109.642,39.858,109.559,39.919,109.559,39.919"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M109.559,39.919c-0.069,0-0.358,0.152-0.496,0.495c-0.137,0.335-0.205,0.738-0.548,1.089c-0.35,0.35-0.51,0.403-0.891,0.571
               c-0.381,0.167-0.533,0.259-0.685,0.472c-0.161,0.213-0.496,0.343-0.519,0.632c-0.022,0.289,0.068,0.236-0.183,0.571
               c-0.243,0.342-0.479,0.701-0.373,0.99c0.114,0.297,0.32,0.327,0.046,0.662c-0.267,0.343-0.222,0.747-0.776,0.959
               c-0.549,0.214-0.808,0.83-1.234,0.853c-0.35,0.023-0.616,0.061-0.761,0.221c0.884-0.007,2.916-0.022,3.486,0.03
               c0-0.144-0.007-0.593,0.023-0.707c0.022-0.1,0.076-0.13,0.184-0.191c0.067-0.045,0.159-0.099,0.281-0.206
               c0.075-0.068,0.145-0.121,0.205-0.175c0.176-0.137,0.297-0.236,0.396-0.38c0.122-0.175,0.199-0.13,0.336-0.054
               c0.046,0.031,0.099,0.062,0.159,0.084c0.13,0.046,0.16-0.022,0.213-0.137c0.023-0.038,0.047-0.084,0.077-0.137
               c0.083-0.144,0.205-0.114,0.373-0.076c0.114,0.023,0.251,0.061,0.403,0.031c0.213-0.039,0.22-0.084,0.236-0.16
               c0.007-0.054,0.015-0.115,0.053-0.198c0.068-0.137,0.282-0.252,0.472-0.343c0.13-0.076,0.252-0.137,0.282-0.19
               c0.076-0.115,0.19-0.1,0.305-0.084c0.061,0.007,0.121,0.015,0.168,0c0.03-0.016,0.038-0.038,0.03-0.061
               c-0.016-0.054-0.069-0.114-0.122-0.183c-0.031-0.03-0.054-0.076-0.068-0.114c-0.023-0.061-0.03-0.13-0.016-0.191
               c0.008-0.06,0.038-0.121,0.084-0.159c0.023-0.023,0.053-0.046,0.084-0.054c0.083-0.022,0.159-0.03,0.235-0.03
               c0.084-0.008,0.16-0.008,0.198-0.046c0.023-0.023,0.046-0.045,0.061-0.076c0.077-0.083,0.152-0.183,0.327-0.198
               c0.184-0.022,0.488-0.022,0.709-0.031l0.129-0.007c0.099,0,0.16,0,0.206-0.015c0.038-0.016,0.061-0.038,0.083-0.091
               c0.023-0.054,0.062-0.084,0.092-0.115c0.031-0.031,0.062-0.061,0.046-0.129c-0.008-0.061-0.061-0.122-0.122-0.191
               c-0.068-0.068-0.137-0.144-0.168-0.243c-0.015-0.039-0.03-0.068-0.038-0.091c-0.053-0.13-0.083-0.214-0.083-0.35l0.008-0.008
               c0-0.153,0.007-0.373-0.092-0.693c-0.061-0.199-0.03-0.274-0.008-0.335c0.016-0.038,0.023-0.062-0.015-0.122
               c-0.054-0.084-0.1-0.221-0.145-0.35v-0.023c-0.16-0.039-0.236-0.122-0.427-0.13c-0.229,0-0.662-0.099-0.876-0.068
               c-0.213,0.038-0.402,0.068-0.586,0.023c-0.183-0.046-0.441-0.084-0.51-0.305c-0.061-0.228-0.016-0.281-0.092-0.289
               C109.642,39.858,109.559,39.919,109.559,39.919z"/>
             <path fill="#808184" d="M102.881,46.132c-0.03,0.007-0.176,0.114-0.16,0.198
               c0.008,0.083,0.076,0.129,0.106,0.099c0.03-0.031,0.023-0.092,0.106-0.099c0.076-0.008,0.236-0.229,0.145-0.267
               C102.987,46.018,102.881,46.132,102.881,46.132 M102.561,46.573c0,0.031-0.046,0.168-0.099,0.259
               c-0.061,0.1-0.1,0.175-0.168,0.221c-0.068,0.045-0.19,0.084-0.129,0.115c0.061,0.029,0.084-0.009,0.152-0.009
               c0.067-0.007,0.151-0.174,0.235-0.174c0.084,0,0.19-0.046,0.19-0.168c0-0.13-0.022-0.328-0.069-0.328
               C102.629,46.482,102.561,46.573,102.561,46.573 M99.408,47.358c-0.022,0.007-0.083,0.038-0.145,0.045
               c-0.068,0.008-0.076,0.1-0.03,0.115c0.054,0.014,0.076,0.06,0.168,0.022c0.084-0.03,0.189-0.266,0.129-0.266
               C99.477,47.274,99.408,47.358,99.408,47.358 M99.919,47.046c-0.008,0.015-0.078,0.106,0.007,0.128
               c0.084,0.023,0.184-0.006,0.184-0.076c0-0.068-0.031-0.144-0.084-0.152C99.972,46.939,99.919,47.046,99.919,47.046
                M101.236,47.091c-0.063,0.015-0.236,0.077-0.222,0.167c0.016,0.092,0.152,0.282,0.268,0.214
               c0.106-0.069,0.205-0.304,0.144-0.366C101.365,47.039,101.236,47.091,101.236,47.091"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M102.881,46.132c-0.03,0.007-0.176,0.114-0.16,0.198c0.008,0.083,0.076,0.129,0.106,0.099c0.03-0.031,0.023-0.092,0.106-0.099
               c0.076-0.008,0.236-0.229,0.145-0.267C102.987,46.018,102.881,46.132,102.881,46.132z M102.561,46.573
               c0,0.031-0.046,0.168-0.099,0.259c-0.061,0.1-0.1,0.175-0.168,0.221c-0.068,0.045-0.19,0.084-0.129,0.115
               c0.061,0.029,0.084-0.009,0.152-0.009c0.067-0.007,0.151-0.174,0.235-0.174c0.084,0,0.19-0.046,0.19-0.168
               c0-0.13-0.022-0.328-0.069-0.328C102.629,46.482,102.561,46.573,102.561,46.573z M99.408,47.358
               c-0.022,0.007-0.083,0.038-0.145,0.045c-0.068,0.008-0.076,0.1-0.03,0.115c0.054,0.014,0.076,0.06,0.168,0.022
               c0.084-0.03,0.189-0.266,0.129-0.266C99.477,47.274,99.408,47.358,99.408,47.358z M99.919,47.046
               c-0.008,0.015-0.078,0.106,0.007,0.128c0.084,0.023,0.184-0.006,0.184-0.076c0-0.068-0.031-0.144-0.084-0.152
               C99.972,46.939,99.919,47.046,99.919,47.046z M101.236,47.091c-0.063,0.015-0.236,0.077-0.222,0.167
               c0.016,0.092,0.152,0.282,0.268,0.214c0.106-0.069,0.205-0.304,0.144-0.366C101.365,47.039,101.236,47.091,101.236,47.091z"/>
             <path fill="#808184" d="M149.265,84.895c-0.031,0-0.16,0.046-0.007,0.13
               c0.151,0.083,0.319,0.029,0.267-0.046C149.472,84.895,149.265,84.895,149.265,84.895 M150.438,85.259
               c-0.023,0.068,0.035,0.062,0.021,0.199c-0.015,0.137,0.011,0.26,0.056,0.205c0.043-0.055,0.062-0.168,0.121-0.197
               c0.061-0.031-0.029-0.291-0.1-0.291C150.459,85.184,150.438,85.259,150.438,85.259 M149.729,84.819c0,0,0.205,0.046,0.234,0.138
               c0.023,0.092,0.1,0.188,0.122,0.099c0.022-0.091-0.053-0.524-0.167-0.479c-0.123,0.047-0.252,0.168-0.243,0.198
               C149.69,84.802,149.729,84.819,149.729,84.819 M149.036,84.027c0,0-0.114,0.023-0.066,0.146c0.045,0.121-0.01,0.211,0.113,0.295
               c0.129,0.084,0.213,0.078,0.145-0.09s0.053-0.381-0.016-0.428C149.135,83.906,149.036,84.027,149.036,84.027"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M149.265,84.895c-0.031,0-0.16,0.046-0.007,0.13c0.151,0.083,0.319,0.029,0.267-0.046
               C149.472,84.895,149.265,84.895,149.265,84.895z M150.438,85.259c-0.023,0.068,0.035,0.062,0.021,0.199
               c-0.015,0.137,0.011,0.26,0.056,0.205c0.043-0.055,0.062-0.168,0.121-0.197c0.061-0.031-0.029-0.291-0.1-0.291
               C150.459,85.184,150.438,85.259,150.438,85.259z M149.729,84.819c0,0,0.205,0.046,0.234,0.138
               c0.023,0.092,0.1,0.188,0.122,0.099c0.022-0.091-0.053-0.524-0.167-0.479c-0.123,0.047-0.252,0.168-0.243,0.198
               C149.69,84.802,149.729,84.819,149.729,84.819z M149.036,84.027c0,0-0.114,0.023-0.066,0.146
               c0.045,0.121-0.01,0.211,0.113,0.295c0.129,0.084,0.213,0.078,0.145-0.09s0.053-0.381-0.016-0.428
               C149.135,83.906,149.036,84.027,149.036,84.027z"/>
             <path fill="#808184" d="M149.874,88.557c-0.053,0.069-0.092,0.167-0.038,0.335
               c0.045,0.167-0.039,0.205-0.099,0.352c-0.062,0.137-0.289,0.379-0.336,0.493c-0.053,0.123-0.053,0.138-0.008,0.343
               c0.045,0.215,0.016,0.375,0.03,0.412c0.007,0.03-0.038,0.235,0.076,0.426c0.112,0.199,0.067,0.342,0.054,0.441
               c-0.007,0.1,0.158,0.206,0.158,0.35c0,0.146-0.145,0.224-0.091,0.358c0.062,0.138,0.017,0.261-0.054,0.328
               c-0.067,0.067-0.137,0.349-0.297,0.448c-0.151,0.101-0.213,0.205-0.222,0.328c-0.015,0.121-0.144,0.373-0.249,0.447
               c-0.1,0.078-0.031,0.328-0.107,0.428c-0.084,0.099-0.229,0.252-0.129,0.57c0.104,0.328,0.371,0.473,0.371,0.68
               c0,0.205-0.099,0.287-0.099,0.517c0,0.222-0.084,0.38,0.047,0.541c0.121,0.16,0.22,0.076,0.214,0.336
               c-0.016,0.259,0.053,0.317,0.281,0.381c0.221,0.066,0.313,0.091,0.41,0.204c0.105,0.114,0.214,0.184,0.373,0.139
               c0.16-0.047,0.281-0.236,0.662-0.343c0.381-0.101,0.542,0,0.654-0.131c0.115-0.138,0.168-0.259,0.313-0.601
               c0.144-0.336,0.449-1.258,0.54-1.639c0.092-0.382,0.206-0.662,0.328-0.912c0.121-0.244,0.396-1.182,0.517-1.531
               c0.123-0.352-0.052-0.373,0.183-0.715c0.238-0.352,0.496-0.869,0.52-1.183c0.016-0.317-0.13-0.555,0.008-0.792
               c0.137-0.234,0.19-0.381,0.244-0.355c0.061,0.021,0.066-0.078,0.083-0.178c0.015-0.098,0.104-0.182,0.069-0.287
               c-0.03-0.106-0.184-0.215-0.168-0.289c0.016-0.076-0.023-0.185,0-0.306c0.016-0.122,0-0.267,0.13-0.229
               c0.137,0.031,0.121,0.268,0.19,0.351c0.06,0.083,0.149,0.101,0.213-0.007c0.067-0.107,0.228-0.479,0.084-0.77
               c-0.146-0.297-0.207-0.556-0.207-0.754c0-0.207,0.061-0.729-0.03-0.892c-0.093-0.16-0.045-0.442-0.19-0.502
               c-0.15-0.067-0.129-0.198-0.183-0.306c-0.052-0.113,0.047-0.396-0.159-0.418c-0.197-0.022-0.105,0.158-0.184,0.273
               c-0.075,0.122-0.145,0.145-0.189,0.174c-0.054,0.031-0.105,0.055-0.038,0.168c0.062,0.107,0.204,0.146,0.122,0.252
               c-0.084,0.106-0.131,0.113-0.146,0.26c-0.008,0.145-0.036,0.229-0.104,0.25c-0.063,0.023-0.13-0.045-0.153,0
               c-0.029,0.047-0.189-0.229-0.234-0.184c-0.039,0.055-0.122,0.131-0.023,0.213c0.092,0.084,0.115,0.178,0.076,0.236
               c-0.038,0.063-0.113-0.083-0.206-0.113c-0.084-0.029-0.168-0.053-0.188,0.068c-0.023,0.122,0.021,0.197,0.084,0.313
               c0.068,0.113,0.099,0.152,0,0.19c-0.107,0.03-0.221,0.038-0.236,0.13c-0.023,0.09,0.305,0.189,0.229,0.271
               c-0.076,0.084-0.129,0.016-0.197,0.024c-0.068,0.015-0.008,0.13-0.076,0.212c-0.068,0.094-0.146,0.207-0.146,0.114
               c0-0.091,0.084-0.372-0.021-0.297c-0.1,0.075-0.105,0.183-0.197,0.313c-0.099,0.129-0.145,0.176-0.113,0.273
               c0.03,0.098-0.084,0.289-0.1,0.145c-0.008-0.145,0.045-0.273-0.055-0.229c-0.098,0.045-0.41,0.229-0.439,0.352
               c-0.039,0.129,0.105,0.258-0.008,0.281c-0.115,0.021-0.055-0.176-0.146-0.176c-0.092-0.008-0.105,0.092-0.168,0.107
               c-0.067,0.021-0.06-0.078-0.16-0.063c-0.1,0.016-0.1,0.053-0.158,0.145c-0.069,0.092-0.055-0.022-0.153,0.062
               c-0.091,0.084-0.157,0.075-0.228,0.038c-0.068-0.038-0.176-0.066-0.223,0.031c-0.052,0.104-0.037,0.091-0.158,0.06
               C149.896,88.49,149.874,88.557,149.874,88.557"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M149.874,88.557c-0.053,0.069-0.092,0.167-0.038,0.335c0.045,0.167-0.039,0.205-0.099,0.352
               c-0.062,0.137-0.289,0.379-0.336,0.493c-0.053,0.123-0.053,0.138-0.008,0.343c0.045,0.215,0.016,0.375,0.03,0.412
               c0.007,0.03-0.038,0.235,0.076,0.426c0.112,0.199,0.067,0.342,0.054,0.441c-0.007,0.1,0.158,0.206,0.158,0.35
               c0,0.146-0.145,0.224-0.091,0.358c0.062,0.138,0.017,0.261-0.054,0.328c-0.067,0.067-0.137,0.349-0.297,0.448
               c-0.151,0.101-0.213,0.205-0.222,0.328c-0.015,0.121-0.144,0.373-0.249,0.447c-0.1,0.078-0.031,0.328-0.107,0.428
               c-0.084,0.099-0.229,0.252-0.129,0.57c0.104,0.328,0.371,0.473,0.371,0.68c0,0.205-0.099,0.287-0.099,0.517
               c0,0.222-0.084,0.38,0.047,0.541c0.121,0.16,0.22,0.076,0.214,0.336c-0.016,0.259,0.053,0.317,0.281,0.381
               c0.221,0.066,0.313,0.091,0.41,0.204c0.105,0.114,0.214,0.184,0.373,0.139c0.16-0.047,0.281-0.236,0.662-0.343
               c0.381-0.101,0.542,0,0.654-0.131c0.115-0.138,0.168-0.259,0.313-0.601c0.144-0.336,0.449-1.258,0.54-1.639
               c0.092-0.382,0.206-0.662,0.328-0.912c0.121-0.244,0.396-1.182,0.517-1.531c0.123-0.352-0.052-0.373,0.183-0.715
               c0.238-0.352,0.496-0.869,0.52-1.183c0.016-0.317-0.13-0.555,0.008-0.792c0.137-0.234,0.19-0.381,0.244-0.355
               c0.061,0.021,0.066-0.078,0.083-0.178c0.015-0.098,0.104-0.182,0.069-0.287c-0.03-0.106-0.184-0.215-0.168-0.289
               c0.016-0.076-0.023-0.185,0-0.306c0.016-0.122,0-0.267,0.13-0.229c0.137,0.031,0.121,0.268,0.19,0.351
               c0.06,0.083,0.149,0.101,0.213-0.007c0.067-0.107,0.228-0.479,0.084-0.77c-0.146-0.297-0.207-0.556-0.207-0.754
               c0-0.207,0.061-0.729-0.03-0.892c-0.093-0.16-0.045-0.442-0.19-0.502c-0.15-0.067-0.129-0.198-0.183-0.306
               c-0.052-0.113,0.047-0.396-0.159-0.418c-0.197-0.022-0.105,0.158-0.184,0.273c-0.075,0.122-0.145,0.145-0.189,0.174
               c-0.054,0.031-0.105,0.055-0.038,0.168c0.062,0.107,0.204,0.146,0.122,0.252c-0.084,0.106-0.131,0.113-0.146,0.26
               c-0.008,0.145-0.036,0.229-0.104,0.25c-0.063,0.023-0.13-0.045-0.153,0c-0.029,0.047-0.189-0.229-0.234-0.184
               c-0.039,0.055-0.122,0.131-0.023,0.213c0.092,0.084,0.115,0.178,0.076,0.236c-0.038,0.063-0.113-0.083-0.206-0.113
               c-0.084-0.029-0.168-0.053-0.188,0.068c-0.023,0.122,0.021,0.197,0.084,0.313c0.068,0.113,0.099,0.152,0,0.19
               c-0.107,0.03-0.221,0.038-0.236,0.13c-0.023,0.09,0.305,0.189,0.229,0.271c-0.076,0.084-0.129,0.016-0.197,0.024
               c-0.068,0.015-0.008,0.13-0.076,0.212c-0.068,0.094-0.146,0.207-0.146,0.114c0-0.091,0.084-0.372-0.021-0.297
               c-0.1,0.075-0.105,0.183-0.197,0.313c-0.099,0.129-0.145,0.176-0.113,0.273c0.03,0.098-0.084,0.289-0.1,0.145
               c-0.008-0.145,0.045-0.273-0.055-0.229c-0.098,0.045-0.41,0.229-0.439,0.352c-0.039,0.129,0.105,0.258-0.008,0.281
               c-0.115,0.021-0.055-0.176-0.146-0.176c-0.092-0.008-0.105,0.092-0.168,0.107c-0.067,0.021-0.06-0.078-0.16-0.063
               c-0.1,0.016-0.1,0.053-0.158,0.145c-0.069,0.092-0.055-0.022-0.153,0.062c-0.091,0.084-0.157,0.075-0.228,0.038
               c-0.068-0.038-0.176-0.066-0.223,0.031c-0.052,0.104-0.037,0.091-0.158,0.06C149.896,88.49,149.874,88.557,149.874,88.557z"/>
             <path fill="#808184" d="M158.95,77.585c-0.023,0.031-0.108,0.174,0.021,0.158
               c0.121-0.008,0.229-0.189,0.121-0.22C158.987,77.501,158.95,77.585,158.95,77.585 M157.701,78.819
               c0.008,0.015,0.046,0.106,0.083,0.038c0.031-0.061-0.021-0.176-0.069-0.129C157.67,78.773,157.701,78.819,157.701,78.819
                M157.502,78.082c0.011,0.015,0.047,0.104,0.084,0.037c0.031-0.062-0.021-0.175-0.067-0.129
               C157.474,78.035,157.502,78.082,157.502,78.082 M157.403,78.263c-0.008,0.015-0.061,0.1,0.017,0.092
               c0.067-0.008,0.129-0.113,0.067-0.129C157.429,78.208,157.403,78.263,157.403,78.263 M157.42,78.515
               c-0.017,0.016-0.062,0.1,0.009,0.091c0.066-0.009,0.137-0.106,0.066-0.123C157.435,78.46,157.42,78.515,157.42,78.515
                M157.39,78.917c0.008,0.029,0.084,0.162,0.129,0.061c0.047-0.105-0.045-0.266-0.115-0.197
               C157.336,78.849,157.39,78.917,157.39,78.917 M157.161,78.957c-0.016,0.015-0.063,0.09,0.008,0.09
               c0.066-0.006,0.137-0.112,0.066-0.127C157.176,78.903,157.161,78.957,157.161,78.957 M157.214,79.238
               c-0.015,0.016-0.076,0.122,0.016,0.113c0.084-0.016,0.16-0.137,0.084-0.158C157.235,79.169,157.214,79.238,157.214,79.238
                M156.871,80.089c0,0.039,0.022,0.215,0.114,0.123c0.1-0.092,0.068-0.313-0.037-0.258C156.84,80,156.871,80.089,156.871,80.089
                M159.772,80.123c-0.018,0.021-0.076,0.121,0.015,0.112c0.092-0.007,0.168-0.138,0.092-0.159
               C159.804,80.052,159.772,80.123,159.772,80.123 M159.087,78.896c-0.016,0.021-0.076,0.121,0.016,0.113
               c0.09-0.008,0.168-0.139,0.09-0.16C159.118,78.828,159.087,78.896,159.087,78.896 M159.276,77.013
               c-0.016,0.023-0.074,0.123,0.008,0.115c0.092-0.008,0.168-0.139,0.092-0.16C159.301,76.955,159.276,77.013,159.276,77.013
                M159.444,77.433c-0.029-0.016-0.189-0.045-0.137,0.068c0.051,0.115,0.26,0.145,0.25,0.037
               C159.543,77.425,159.444,77.433,159.444,77.433"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M158.95,77.585c-0.023,0.031-0.108,0.174,0.021,0.158c0.121-0.008,0.229-0.189,0.121-0.22
               C158.987,77.501,158.95,77.585,158.95,77.585z M157.701,78.819c0.008,0.015,0.046,0.106,0.083,0.038
               c0.031-0.061-0.021-0.176-0.069-0.129C157.67,78.773,157.701,78.819,157.701,78.819z M157.502,78.082
               c0.011,0.015,0.047,0.104,0.084,0.037c0.031-0.062-0.021-0.175-0.067-0.129C157.474,78.035,157.502,78.082,157.502,78.082z
                M157.403,78.263c-0.008,0.015-0.061,0.1,0.017,0.092c0.067-0.008,0.129-0.113,0.067-0.129
               C157.429,78.208,157.403,78.263,157.403,78.263z M157.42,78.515c-0.017,0.016-0.062,0.1,0.009,0.091
               c0.066-0.009,0.137-0.106,0.066-0.123C157.435,78.46,157.42,78.515,157.42,78.515z M157.39,78.917
               c0.008,0.029,0.084,0.162,0.129,0.061c0.047-0.105-0.045-0.266-0.115-0.197C157.336,78.849,157.39,78.917,157.39,78.917z
                M157.161,78.957c-0.016,0.015-0.063,0.09,0.008,0.09c0.066-0.006,0.137-0.112,0.066-0.127
               C157.176,78.903,157.161,78.957,157.161,78.957z M157.214,79.238c-0.015,0.016-0.076,0.122,0.016,0.113
               c0.084-0.016,0.16-0.137,0.084-0.158C157.235,79.169,157.214,79.238,157.214,79.238z M156.871,80.089
               c0,0.039,0.022,0.215,0.114,0.123c0.1-0.092,0.068-0.313-0.037-0.258C156.84,80,156.871,80.089,156.871,80.089z M159.772,80.123
               c-0.018,0.021-0.076,0.121,0.015,0.112c0.092-0.007,0.168-0.138,0.092-0.159C159.804,80.052,159.772,80.123,159.772,80.123z
                M159.087,78.896c-0.016,0.021-0.076,0.121,0.016,0.113c0.09-0.008,0.168-0.139,0.09-0.16
               C159.118,78.828,159.087,78.896,159.087,78.896z M159.276,77.013c-0.016,0.023-0.074,0.123,0.008,0.115
               c0.092-0.008,0.168-0.139,0.092-0.16C159.301,76.955,159.276,77.013,159.276,77.013z M159.444,77.433
               c-0.029-0.016-0.189-0.045-0.137,0.068c0.051,0.115,0.26,0.145,0.25,0.037C159.543,77.425,159.444,77.433,159.444,77.433z"/>
             <path fill="#808184" d="M151.521,82.201c-0.033,0.036-0.146,0.227,0.021,0.213
               c0.168-0.017,0.313-0.259,0.168-0.297C151.563,82.078,151.521,82.201,151.521,82.201 M152.517,82.519
               c-0.016,0.016-0.062,0.1,0.008,0.091c0.076-0.007,0.138-0.112,0.076-0.129C152.53,82.466,152.517,82.519,152.517,82.519
                M151.679,82.519c-0.016,0.021-0.082,0.13,0.016,0.122c0.09-0.007,0.168-0.146,0.09-0.167
               C151.702,82.453,151.679,82.519,151.679,82.519"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M151.521,82.201c-0.033,0.036-0.146,0.227,0.021,0.213c0.168-0.017,0.313-0.259,0.168-0.297
               C151.563,82.078,151.521,82.201,151.521,82.201z M152.517,82.519c-0.016,0.016-0.062,0.1,0.008,0.091
               c0.076-0.007,0.138-0.112,0.076-0.129C152.53,82.466,152.517,82.519,152.517,82.519z M151.679,82.519
               c-0.016,0.021-0.082,0.13,0.016,0.122c0.09-0.007,0.168-0.146,0.09-0.167C151.702,82.453,151.679,82.519,151.679,82.519z"/>
             <path fill="#808184" d="M160.168,92.302c-0.054,0.068-0.259,0.42,0.046,0.39
               s0.57-0.474,0.305-0.541C160.252,92.076,160.168,92.302,160.168,92.302"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M160.168,92.302c-0.054,0.068-0.259,0.42,0.046,0.39s0.57-0.474,0.305-0.541C160.252,92.076,160.168,92.302,160.168,92.302z"/>
            
            
           
              
              
              </a>
        
        <!--  fin africa  -->
        
        
        
        
         <path fill="#808184" d="M110.875,8.983c-0.038,0-0.259,0.061-0.335,0.129
               c-0.068,0.069-0.411,0.153-0.305,0.213c0.106,0.054,0.138-0.068,0.297-0.084c0.152-0.014,0.686-0.129,0.587-0.243
               C111.027,8.877,110.875,8.983,110.875,8.983"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M110.875,8.983c-0.038,0-0.259,0.061-0.335,0.129c-0.068,0.069-0.411,0.153-0.305,0.213c0.106,0.054,0.138-0.068,0.297-0.084
               c0.152-0.014,0.686-0.129,0.587-0.243C111.027,8.877,110.875,8.983,110.875,8.983z"/>
         <path fill="#808184" d="M110.449,16.125c-0.054,0.023-0.252,0.03-0.13,0.175
               c0.114,0.145,0.184,0,0.236,0.122c0.046,0.114,0.029,0.198,0.146,0.213c0.113,0.008,0.113-0.038,0.136-0.16
               c0.022-0.122,0.297-0.061,0.114-0.289C110.769,15.95,110.449,16.125,110.449,16.125 M110.472,16.757
               c0.061,0.091,0.358,0.381,0.259,0.183c-0.091-0.206-0.168-0.305-0.228-0.29C110.434,16.667,110.472,16.757,110.472,16.757"/>
          <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M110.449,16.125c-0.054,0.023-0.252,0.03-0.13,0.175c0.114,0.145,0.184,0,0.236,0.122c0.046,0.114,0.029,0.198,0.146,0.213
               c0.113,0.008,0.113-0.038,0.136-0.16c0.022-0.122,0.297-0.061,0.114-0.289C110.769,15.95,110.449,16.125,110.449,16.125z
                M110.472,16.757c0.061,0.091,0.358,0.381,0.259,0.183c-0.091-0.206-0.168-0.305-0.228-0.29
               C110.434,16.667,110.472,16.757,110.472,16.757z"/> 
        
        
        
        
        
        
             <path fill="#808184" d="M122.967,2.869c-0.267,0.069-0.236,0.046-0.419,0.023
               c-0.19-0.015-0.19-0.023-0.358,0.03c-0.167,0.054-0.396,0.016-0.35,0.146c0.045,0.121,0.091,0.045,0.145,0.167
               c0.061,0.114,0.106,0.137,0.175,0.129c0.061,0,0.221,0,0.114,0.061c-0.107,0.061-0.198,0.045-0.16,0.107
               c0.046,0.053,0.176,0.068,0.259,0.136c0.084,0.077,0.115,0.114,0.259,0.146c0.152,0.022,0.069,0.113,0.281,0.151
               c0.207,0.039,0.017,0.023,0.282,0c0.274-0.015,0.487-0.008,0.525-0.122c0.03-0.113,0.062-0.274,0.16-0.205
               c0.107,0.069,0.13,0.183,0.19,0.16c0.068-0.023,0.106,0.016,0.197,0.053c0.093,0.038,0.038,0.145-0.151,0.16
               c-0.19,0.023-0.381,0.069-0.449,0.069c-0.076,0-0.457-0.092-0.427,0.068c0.038,0.16,0.229,0.054,0.274,0.259
               c0.044,0.205,0.061,0.266,0.297,0.282c0.243,0.015,0.152,0.03,0.518,0.16c0.365,0.121,0.32,0.175,0.602,0.213
               c0.282,0.03,0.343,0.137,0.351-0.092c0-0.228,0-0.235,0.099-0.319c0.092-0.084,0.19-0.251,0.327-0.244
               c0.13,0.007,0.19,0.03,0.183-0.107c-0.007-0.137-0.068-0.251,0.062-0.251c0.129,0,0.213-0.244,0.373-0.297
               c0.159-0.061,0.229-0.022,0.35-0.038c0.114-0.007,0.076,0.045,0.214,0.145c0.146,0.106,0.327,0.114,0.418,0.167
               c0.092,0.046,0.274,0.061,0.162,0.161c-0.115,0.091-0.215,0.084-0.215,0.19c0,0.107,0.068,0.175,0.152,0.144
               c0.091-0.037,0.578-0.053,0.67-0.023c0.084,0.031,0.183,0.175,0.305,0.099c0.122-0.068,0.223-0.037,0.357-0.129
               c0.13-0.091,0.121-0.152,0.273-0.152s0.479-0.054,0.266-0.122c-0.203-0.069-0.104-0.137-0.439-0.062
               c-0.335,0.07-0.563,0.055-0.525-0.014c0.037-0.061,0.199-0.229,0.054-0.213c-0.146,0.021-0.411,0.068-0.464,0.021
               c-0.062-0.053-0.062-0.22-0.176-0.236c-0.106-0.016-0.19,0.061-0.252-0.016c-0.053-0.075-0.021-0.174-0.145-0.174
               s-0.389-0.015-0.571-0.076c-0.19-0.061-0.235-0.198-0.396-0.19c-0.16,0.015-0.335,0.038-0.411,0.008
               c-0.068-0.031-0.046-0.206-0.146-0.214c-0.099-0.008-0.099,0.068-0.221-0.008c-0.113-0.068-0.137-0.091-0.289-0.144
               c-0.153-0.046-0.373-0.069-0.479-0.084c-0.106-0.016-0.358-0.137-0.396,0.007c-0.046,0.138,0,0.198,0.114,0.305
               c0.121,0.107,0.365,0.365,0.221,0.328c-0.146-0.047-0.389-0.305-0.549-0.396c-0.16-0.092-0.267-0.168-0.434-0.122
               c-0.168,0.046-0.236,0.023-0.236,0.153c0,0.128-0.068,0.121-0.152,0.091c-0.091-0.031-0.175-0.183-0.076-0.191
               c0.1-0.007,0.29-0.053,0.267-0.084C123.491,2.786,123.073,2.839,122.967,2.869 M126.424,6.501c-0.023,0-0.328,0.053-0.13,0.122
               c0.197,0.068,0.213,0.145,0.289,0.091c0.075-0.061,0.167-0.213,0.091-0.228C126.59,6.47,126.424,6.501,126.424,6.501
                M125.913,2.61c0,0-0.609-0.069-0.556,0.046c0.053,0.114,0.159-0.016,0.244,0.121c0.082,0.145,0.129,0.184,0.312,0.184
               s0.487-0.031,0.563,0.015c0.078,0.045-0.083,0.084,0.237,0.129c0.319,0.038,0.243,0.023,0.502,0.031
               c0.259,0.007,0.435-0.016,0.687,0.076c0.242,0.091,0.35,0.114,0.602,0.061c0.243-0.046,0.305-0.084,0.457-0.046
               c0.15,0.03,0.434-0.091,0.532-0.099c0.1-0.008,0.114-0.091,0.297-0.175c0.185-0.084,0.503-0.023,0.448-0.176
               c-0.053-0.152-0.129-0.236-0.389-0.19c-0.258,0.053-0.586,0.061-0.774-0.015c-0.185-0.076-0.222-0.129-0.457-0.068
               c-0.236,0.061-0.175,0.175-0.374,0.099c-0.197-0.084-0.273-0.146-0.357-0.13c-0.084,0.015-0.205-0.075-0.205,0.068
               c0,0.138,0.076,0.214-0.054,0.168c-0.138-0.053-0.205-0.182-0.343-0.152c-0.131,0.03-0.305,0.068-0.426,0.046
               c-0.115-0.031-0.229-0.122-0.366-0.122c-0.137,0-0.305-0.008-0.365,0.06c-0.053,0.069-0.053,0.13-0.137,0.099L125.913,2.61z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M122.967,2.869c-0.267,0.069-0.236,0.046-0.419,0.023c-0.19-0.015-0.19-0.023-0.358,0.03c-0.167,0.054-0.396,0.016-0.35,0.146
               c0.045,0.121,0.091,0.045,0.145,0.167c0.061,0.114,0.106,0.137,0.175,0.129c0.061,0,0.221,0,0.114,0.061
               c-0.107,0.061-0.198,0.045-0.16,0.107c0.046,0.053,0.176,0.068,0.259,0.136c0.084,0.077,0.115,0.114,0.259,0.146
               c0.152,0.022,0.069,0.113,0.281,0.151c0.207,0.039,0.017,0.023,0.282,0c0.274-0.015,0.487-0.008,0.525-0.122
               c0.03-0.113,0.062-0.274,0.16-0.205c0.107,0.069,0.13,0.183,0.19,0.16c0.068-0.023,0.106,0.016,0.197,0.053
               c0.093,0.038,0.038,0.145-0.151,0.16c-0.19,0.023-0.381,0.069-0.449,0.069c-0.076,0-0.457-0.092-0.427,0.068
               c0.038,0.16,0.229,0.054,0.274,0.259c0.044,0.205,0.061,0.266,0.297,0.282c0.243,0.015,0.152,0.03,0.518,0.16
               c0.365,0.121,0.32,0.175,0.602,0.213c0.282,0.03,0.343,0.137,0.351-0.092c0-0.228,0-0.235,0.099-0.319
               c0.092-0.084,0.19-0.251,0.327-0.244c0.13,0.007,0.19,0.03,0.183-0.107c-0.007-0.137-0.068-0.251,0.062-0.251
               c0.129,0,0.213-0.244,0.373-0.297c0.159-0.061,0.229-0.022,0.35-0.038c0.114-0.007,0.076,0.045,0.214,0.145
               c0.146,0.106,0.327,0.114,0.418,0.167c0.092,0.046,0.274,0.061,0.162,0.161c-0.115,0.091-0.215,0.084-0.215,0.19
               c0,0.107,0.068,0.175,0.152,0.144c0.091-0.037,0.578-0.053,0.67-0.023c0.084,0.031,0.183,0.175,0.305,0.099
               c0.122-0.068,0.223-0.037,0.357-0.129c0.13-0.091,0.121-0.152,0.273-0.152s0.479-0.054,0.266-0.122
               c-0.203-0.069-0.104-0.137-0.439-0.062c-0.335,0.07-0.563,0.055-0.525-0.014c0.037-0.061,0.199-0.229,0.054-0.213
               c-0.146,0.021-0.411,0.068-0.464,0.021c-0.062-0.053-0.062-0.22-0.176-0.236c-0.106-0.016-0.19,0.061-0.252-0.016
               c-0.053-0.075-0.021-0.174-0.145-0.174s-0.389-0.015-0.571-0.076c-0.19-0.061-0.235-0.198-0.396-0.19
               c-0.16,0.015-0.335,0.038-0.411,0.008c-0.068-0.031-0.046-0.206-0.146-0.214c-0.099-0.008-0.099,0.068-0.221-0.008
               c-0.113-0.068-0.137-0.091-0.289-0.144c-0.153-0.046-0.373-0.069-0.479-0.084c-0.106-0.016-0.358-0.137-0.396,0.007
               c-0.046,0.138,0,0.198,0.114,0.305c0.121,0.107,0.365,0.365,0.221,0.328c-0.146-0.047-0.389-0.305-0.549-0.396
               c-0.16-0.092-0.267-0.168-0.434-0.122c-0.168,0.046-0.236,0.023-0.236,0.153c0,0.128-0.068,0.121-0.152,0.091
               c-0.091-0.031-0.175-0.183-0.076-0.191c0.1-0.007,0.29-0.053,0.267-0.084C123.491,2.786,123.073,2.839,122.967,2.869z
                M126.424,6.501c-0.023,0-0.328,0.053-0.13,0.122c0.197,0.068,0.213,0.145,0.289,0.091c0.075-0.061,0.167-0.213,0.091-0.228
               C126.59,6.47,126.424,6.501,126.424,6.501z M125.913,2.61c0,0-0.609-0.069-0.556,0.046c0.053,0.114,0.159-0.016,0.244,0.121
               c0.082,0.145,0.129,0.184,0.312,0.184s0.487-0.031,0.563,0.015c0.078,0.045-0.083,0.084,0.237,0.129
               c0.319,0.038,0.243,0.023,0.502,0.031c0.259,0.007,0.435-0.016,0.687,0.076c0.242,0.091,0.35,0.114,0.602,0.061
               c0.243-0.046,0.305-0.084,0.457-0.046c0.15,0.03,0.434-0.091,0.532-0.099c0.1-0.008,0.114-0.091,0.297-0.175
               c0.185-0.084,0.503-0.023,0.448-0.176c-0.053-0.152-0.129-0.236-0.389-0.19c-0.258,0.053-0.586,0.061-0.774-0.015
               c-0.185-0.076-0.222-0.129-0.457-0.068c-0.236,0.061-0.175,0.175-0.374,0.099c-0.197-0.084-0.273-0.146-0.357-0.13
               c-0.084,0.015-0.205-0.075-0.205,0.068c0,0.138,0.076,0.214-0.054,0.168c-0.138-0.053-0.205-0.182-0.343-0.152
               c-0.131,0.03-0.305,0.068-0.426,0.046c-0.115-0.031-0.229-0.122-0.366-0.122c-0.137,0-0.305-0.008-0.365,0.06
               c-0.053,0.069-0.053,0.13-0.137,0.099L125.913,2.61z"/>
        
               <path fill="#808184" d="M11.01,19.704c-0.029,0.03-0.343,0.038-0.502,0.122
               c-0.168,0.084-0.343,0.266-0.38,0.365c-0.031,0.099-0.199,0.404-0.009,0.411c0.198,0.008,0.343-0.228,0.533-0.259
               c0.19-0.022,0.183-0.03,0.479-0.091s0.457-0.114,0.571-0.221c0.122-0.099,0.053,0.122,0.289-0.114
               c0.236-0.244,0.533-0.419,0.152-0.442c-0.381-0.03-0.502-0.007-0.646,0.084C11.354,19.658,11.01,19.704,11.01,19.704"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M11.01,19.704
               c-0.029,0.03-0.343,0.038-0.502,0.122c-0.168,0.084-0.343,0.266-0.38,0.365c-0.031,0.099-0.199,0.404-0.009,0.411
               c0.198,0.008,0.343-0.228,0.533-0.259c0.19-0.022,0.183-0.03,0.479-0.091s0.457-0.114,0.571-0.221
               c0.122-0.099,0.053,0.122,0.289-0.114c0.236-0.244,0.533-0.419,0.152-0.442c-0.381-0.03-0.502-0.007-0.646,0.084
               C11.354,19.658,11.01,19.704,11.01,19.704z"/>
             <path fill="#808184" d="M12.525,19.156c-0.076,0.015-0.327,0.114-0.305,0.167
               c0.03,0.046,0.19,0.054,0.32,0.054c0.131,0.008,0.313,0.022,0.388-0.069c0.075-0.091,0.084-0.144,0.115-0.236
               c0.029-0.083,0.083-0.183-0.023-0.167c-0.106,0.015-0.19,0.091-0.267,0.145C12.678,19.103,12.525,19.156,12.525,19.156"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M12.525,19.156c-0.076,0.015-0.327,0.114-0.305,0.167c0.03,0.046,0.19,0.054,0.32,0.054c0.131,0.008,0.313,0.022,0.388-0.069
               c0.075-0.091,0.084-0.144,0.115-0.236c0.029-0.083,0.083-0.183-0.023-0.167c-0.106,0.015-0.19,0.091-0.267,0.145
               C12.678,19.103,12.525,19.156,12.525,19.156z"/>
        
         
        
        
        
        
        
        
        
        <!-- scandinavie_europe-est  -->
        
        
        
        
                <a class="scandinavie_europe-est" onclick='DealerShow("europe_est_dealer","scandinavie_europe-est");' > 
             <path fill="#808184" d="M133.876,10.156c0,0-0.221-0.19-0.41-0.129
               c-0.189,0.068-0.259,0.175-0.273,0.068c-0.022-0.114-0.029-0.152-0.152-0.167c-0.114-0.015-0.639-0.152-0.355-0.168
               c0.28-0.007,0.379,0.077,0.556,0.077c0.165,0,0.341-0.13,0.425-0.138c0.093-0.015,0.336-0.03,0.236-0.129
               c-0.107-0.099-0.289-0.183-0.411-0.167c-0.122,0.022-0.319-0.084-0.464-0.092c-0.152-0.008-0.32-0.122-0.42-0.076
               c-0.099,0.046-0.252,0.145-0.328,0.229c-0.074,0.083-0.174-0.07-0.112-0.13c0.054-0.061,0.213-0.251,0.054-0.275
               c-0.16-0.015-0.215,0.009-0.328,0.016c-0.113,0.008-0.373-0.007-0.381,0.084c-0.015,0.091,0.139,0.244-0.015,0.312
               c-0.153,0.061-0.282,0.084-0.252-0.039c0.03-0.121,0.099-0.357-0.037-0.258c-0.138,0.107-0.153,0.091-0.312,0.213
               c-0.153,0.114-0.162,0.335-0.367,0.396c-0.197,0.061-0.364-0.061-0.159-0.19c0.205-0.129,0.159-0.213,0.267-0.274
               c0.113-0.054,0.352-0.152,0.19-0.214c-0.168-0.068-0.29-0.106-0.421,0c-0.129,0.1-0.219,0.046-0.348,0.092
               c-0.131,0.053-0.162,0.144-0.199,0.197c-0.037,0.054-0.159,0.047-0.26,0.031c-0.105-0.022-0.356,0.145-0.381,0.091
               c-0.029-0.06,0.252-0.258,0.055-0.251c-0.197,0.008-0.381,0.115-0.512,0.145c-0.137,0.038-0.472,0.061-0.296,0.122
               c0.185,0.068,0.464,0.023,0.388,0.099c-0.082,0.076-0.342,0.046-0.41,0.061s-0.129,0.053-0.273,0.061
               c-0.137,0.015-0.113,0.038-0.092,0.129c0.029,0.099-0.068,0.068-0.189,0.137c-0.123,0.068-0.13,0.221-0.191,0.16
               c-0.053-0.061-0.113-0.106-0.029-0.183c0.076-0.075,0.122-0.289,0-0.228c-0.13,0.069-0.205,0.16-0.251,0.221
               c-0.046,0.053-0.267,0.144-0.229,0.03c0.03-0.114,0.121-0.274,0.016-0.281c-0.105-0.008-0.205,0.015-0.289,0.106
               c-0.076,0.091-0.122,0.137-0.32,0.229c-0.205,0.098-0.25,0.053-0.365,0.167c-0.106,0.114-0.129,0.076-0.273,0.099
               c-0.146,0.023-0.32,0.084-0.351,0.152c-0.022,0.069-0.106,0.183-0.016,0.214c0.092,0.037,0.023,0.037,0.168,0.007
               c0.137-0.03,0.298-0.129,0.229-0.007c-0.076,0.121,0.03,0.152-0.084,0.198c-0.121,0.045-0.297,0.22-0.426,0.068
               c-0.123-0.152-0.229-0.183-0.298-0.13c-0.075,0.046-0.205-0.022-0.137-0.114c0.068-0.091,0.297-0.274,0.1-0.243
               c-0.199,0.023-0.29,0.114-0.365,0.205c-0.077,0.092-0.214-0.038-0.32,0.077c-0.106,0.114-0.13,0.175-0.19,0.175
               c-0.062,0-0.251,0.015-0.183,0.091c0.061,0.076,0.251,0.053,0.335,0.068c0.083,0.008,0.038,0.092-0.084,0.084
               c-0.114-0.007-0.229,0.03-0.282,0.076c-0.06,0.045-0.167-0.038-0.266-0.007c-0.092,0.023-0.198,0.068-0.198,0.114
               c0,0.053-0.13,0.136,0.076,0.129c0.205-0.015,0.221-0.099,0.305-0.084c0.083,0.016,0.137-0.038,0.289-0.061
               c0.152-0.022,0.214,0,0.327-0.022c0.114-0.024,0.138-0.084,0.229-0.084c0.1,0,0.305-0.053,0.305-0.053s0.358-0.137,0.283-0.038
               c-0.077,0.099-0.184,0.053-0.291,0.137c-0.106,0.083-0.175,0.129-0.228,0.144c-0.054,0.015-0.145-0.03-0.244,0.084
               c-0.106,0.114-0.213,0.008-0.259,0.122c-0.046,0.121,0.083,0.16,0.008,0.221c-0.077,0.061-0.235,0.007-0.267,0.144
               c-0.023,0.129-0.069,0.282-0.069,0.282s-0.335,0.099-0.464,0.267c-0.137,0.167-0.251,0.373-0.319,0.518
               c-0.069,0.144,0,0.098-0.259,0.304c-0.259,0.198-0.1,0.281-0.336,0.48c-0.236,0.205-0.532,0.152-0.396,0.32
               c0.137,0.167,0.114,0.373-0.023,0.304c-0.137-0.069-0.304-0.282-0.357-0.153c-0.053,0.138-0.273,0.145-0.396,0.358
               c-0.121,0.214-0.205,0.183-0.318,0.267c-0.115,0.091-0.245,0.198-0.328,0.129c-0.076-0.075-0.183-0.281-0.281-0.152
               c-0.107,0.122-0.213,0.129-0.138,0.221c0.076,0.084,0.03,0.114-0.015,0.16c-0.039,0.046-0.206-0.145-0.282-0.076
               c-0.083,0.061-0.008,0.068,0,0.175c0.008,0.106-0.068,0.106-0.145,0.144c-0.068,0.039-0.084,0.069-0.19,0.039
               c-0.1-0.039-0.327-0.061-0.32,0.061c0.008,0.122,0.145,0.22-0.007,0.228c-0.146,0.015-0.16-0.069-0.289-0.008
               c-0.13,0.061-0.13,0.115-0.152,0.152c-0.023,0.031-0.274,0.084-0.351,0.152c-0.068,0.077-0.145,0.023-0.251,0.046
               s-0.069,0.152-0.122,0.229c-0.046,0.076-0.244,0.053-0.167,0.175c0.084,0.114,0.19,0.046,0.151,0.168
               c-0.03,0.114-0.168,0.114-0.091,0.22c0.083,0.115,0.03,0.183,0.023,0.267c-0.017,0.084,0.022,0.137,0.045,0.206
               c0.03,0.068,0.062,0.235,0.175,0.136c0.114-0.106,0.328-0.052,0.229,0.016c-0.106,0.076-0.259,0.023-0.274,0.114
               c-0.022,0.084-0.03,0.038,0.031,0.175c0.061,0.145-0.031,0.167-0.054,0.275c-0.016,0.106-0.114,0.166-0.068,0.342
               c0.046,0.167-0.061,0.35,0.068,0.313c0.138-0.039,0.32-0.237,0.389-0.176c0.061,0.06,0.189,0.084,0.038,0.19
               c-0.145,0.107-0.222,0.046-0.236,0.16c-0.015,0.115-0.152,0.221,0.015,0.267c0.168,0.053,0.335,0.182,0.64,0.327
               c0.305,0.145,0.487,0.282,0.686,0.236c0.206-0.045,0.357-0.146,0.678-0.267c0.313-0.121,0.479-0.631,0.784-0.655
               c0.305-0.022,0.396-0.022,0.404-0.19c0.014-0.167,0.014-0.494,0.113-0.304c0.099,0.19,0.046,0.335,0.16,0.373
               c0.106,0.03,0.274-0.023,0.259,0.168c0.091-0.008,0.198-0.023,0.259-0.047c0.038-0.015,0.031-0.06,0.022-0.121
               c-0.007-0.069-0.015-0.137,0.009-0.213c0.054-0.129,0.061-0.176,0.075-0.266l0.008-0.031c0.023-0.106,0.1-0.122,0.198-0.137
               c0.054-0.008,0.114-0.023,0.167-0.046c0.084-0.046,0.077-0.091,0.068-0.145c0-0.03-0.008-0.06,0-0.091
               c0.009-0.046-0.022-0.114-0.061-0.191c-0.03-0.06-0.061-0.128-0.092-0.212c-0.037-0.115-0.029-0.184,0.016-0.259
               c0.046-0.061,0.129-0.115,0.252-0.191c0.038-0.015,0.046-0.038,0.038-0.045c-0.031-0.054-0.145-0.107-0.236-0.146
               c-0.054-0.022-0.092-0.045-0.115-0.068c-0.075-0.053-0.037-0.152-0.007-0.251c0.023-0.061,0.046-0.122,0.038-0.144
               c-0.015-0.023-0.038-0.046-0.061-0.077c-0.062-0.061-0.122-0.137-0.106-0.251l0.007-0.015c0.022-0.13,0.046-0.266,0.016-0.488
               c-0.038-0.235-0.016-0.258,0.068-0.349c0.016-0.008,0.022-0.024,0.038-0.039c0.03-0.038,0.053-0.076,0.076-0.114
               c0.046-0.084,0.099-0.183,0.244-0.304c0.152-0.138,0.35-0.122,0.502-0.107c0.076,0,0.137,0.008,0.169-0.008
               c0.007,0,0.014-0.007,0.021-0.015c0.008-0.008,0.008-0.023,0.008-0.038c0-0.023-0.008-0.046-0.016-0.069
               c-0.022-0.053-0.062-0.106-0.106-0.144c-0.092-0.084-0.092-0.145-0.03-0.206c0.022-0.031,0.068-0.061,0.114-0.099
               c0.022-0.015,0.053-0.038,0.076-0.061c0.099-0.076,0.121-0.137,0.152-0.22c0.015-0.046,0.031-0.099,0.061-0.16
               c0.054-0.107,0.045-0.191,0.045-0.275c0-0.053-0.007-0.106,0.009-0.167c0.03-0.137,0.197-0.176,0.35-0.206
               c0.046-0.007,0.084-0.015,0.114-0.03c0.068-0.023,0.068-0.068,0.077-0.129c0.007-0.031,0.014-0.069,0.022-0.107
               c0.022-0.092,0.152-0.175,0.273-0.251c0.084-0.053,0.16-0.107,0.19-0.153c0.038-0.053,0.023-0.076,0-0.114
               c-0.022-0.023-0.045-0.054-0.061-0.099c-0.054-0.122,0.016-0.167,0.099-0.205c0.016-0.008,0.023-0.016,0.03-0.016
               c0.022-0.007,0.046-0.015,0.054-0.03c0.038-0.016,0.053-0.046,0.092-0.099c0.03-0.046,0.068-0.107,0.137-0.183
               c0.114-0.152,0.281-0.114,0.434-0.084c0.068,0.016,0.13,0.03,0.175,0.023c0.062-0.007,0.062-0.076,0.055-0.152
               c0-0.053,0-0.122,0.022-0.183c0.053-0.145,0.272-0.091,0.464-0.046c0.055,0.016,0.106,0.031,0.152,0.031
               c0.061,0.008,0.145,0.023,0.221,0.023c0.114,0.015,0.221,0.015,0.244,0.015c0,0,0.016-0.015,0.03-0.053
               c0.022-0.031,0.038-0.076,0.038-0.107c0.008-0.06,0.046-0.13,0.099-0.183c0.054-0.053,0.122-0.091,0.183-0.099h0.017
               c0.067-0.007,0.167-0.007,0.229-0.128c0.067-0.146,0.174-0.13,0.326-0.1c0.047,0.008,0.092,0.016,0.146,0.023
               c0.137,0.015,0.273,0.122,0.41,0.236c0.115,0.084,0.229,0.175,0.334,0.214c0.152,0.06,0.215,0.029,0.306-0.016
               c0.038-0.023,0.092-0.046,0.151-0.068c0.168-0.062,0.252-0.031,0.367,0.015c0.045,0.014,0.098,0.038,0.166,0.053
               c0.114,0.023,0.184,0.03,0.229,0.023c0.039-0.016,0.063-0.053,0.091-0.114c0.063-0.145,0.101-0.138,0.168-0.114
               c0.022,0.007,0.056,0.015,0.101,0.007c0.054-0.007,0.054-0.031,0.047-0.076c-0.01-0.03-0.017-0.076-0.017-0.122
               c0-0.084,0.017-0.16,0.037-0.229c0.024-0.068,0.056-0.129,0.093-0.197c0.068-0.137,0.182-0.13,0.35-0.122
               c0.037,0.007,0.084,0.007,0.13,0.007c0.114,0,0.175-0.03,0.245-0.068c0.052-0.031,0.105-0.053,0.182-0.077
               c0.184-0.045,0.327,0.039,0.479,0.123l0.045,0.03c0.115,0.069,0.214,0.061,0.322,0.061h0.073c0.024,0,0.048,0,0.063,0.015
               c0.029,0.008,0.054,0.031,0.061,0.061c0.016,0.031,0.016,0.053,0.016,0.083c0,0.1-0.047,0.237-0.076,0.298
               c-0.022,0.061-0.068,0.091-0.106,0.122c-0.028,0.03-0.062,0.053-0.062,0.091c0.008,0.015,0.062,0.03,0.131,0.03
               c0.076-0.129,0.015-0.068,0.158-0.136c0.146-0.077,0.152-0.282,0.291-0.298c0.129-0.007,0.242,0.038,0.365-0.053
               c0.129-0.091,0.188-0.03,0.28-0.046C133.968,10.255,133.876,10.156,133.876,10.156"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M133.876,10.156c0,0-0.221-0.19-0.41-0.129c-0.189,0.068-0.259,0.175-0.273,0.068c-0.022-0.114-0.029-0.152-0.152-0.167
               c-0.114-0.015-0.639-0.152-0.355-0.168c0.28-0.007,0.379,0.077,0.556,0.077c0.165,0,0.341-0.13,0.425-0.138
               c0.093-0.015,0.336-0.03,0.236-0.129c-0.107-0.099-0.289-0.183-0.411-0.167c-0.122,0.022-0.319-0.084-0.464-0.092
               c-0.152-0.008-0.32-0.122-0.42-0.076c-0.099,0.046-0.252,0.145-0.328,0.229c-0.074,0.083-0.174-0.07-0.112-0.13
               c0.054-0.061,0.213-0.251,0.054-0.275c-0.16-0.015-0.215,0.009-0.328,0.016c-0.113,0.008-0.373-0.007-0.381,0.084
               c-0.015,0.091,0.139,0.244-0.015,0.312c-0.153,0.061-0.282,0.084-0.252-0.039c0.03-0.121,0.099-0.357-0.037-0.258
               c-0.138,0.107-0.153,0.091-0.312,0.213c-0.153,0.114-0.162,0.335-0.367,0.396c-0.197,0.061-0.364-0.061-0.159-0.19
               c0.205-0.129,0.159-0.213,0.267-0.274c0.113-0.054,0.352-0.152,0.19-0.214c-0.168-0.068-0.29-0.106-0.421,0
               c-0.129,0.1-0.219,0.046-0.348,0.092c-0.131,0.053-0.162,0.144-0.199,0.197c-0.037,0.054-0.159,0.047-0.26,0.031
               c-0.105-0.022-0.356,0.145-0.381,0.091c-0.029-0.06,0.252-0.258,0.055-0.251c-0.197,0.008-0.381,0.115-0.512,0.145
               c-0.137,0.038-0.472,0.061-0.296,0.122c0.185,0.068,0.464,0.023,0.388,0.099c-0.082,0.076-0.342,0.046-0.41,0.061
               s-0.129,0.053-0.273,0.061c-0.137,0.015-0.113,0.038-0.092,0.129c0.029,0.099-0.068,0.068-0.189,0.137
               c-0.123,0.068-0.13,0.221-0.191,0.16c-0.053-0.061-0.113-0.106-0.029-0.183c0.076-0.075,0.122-0.289,0-0.228
               c-0.13,0.069-0.205,0.16-0.251,0.221c-0.046,0.053-0.267,0.144-0.229,0.03c0.03-0.114,0.121-0.274,0.016-0.281
               c-0.105-0.008-0.205,0.015-0.289,0.106c-0.076,0.091-0.122,0.137-0.32,0.229c-0.205,0.098-0.25,0.053-0.365,0.167
               c-0.106,0.114-0.129,0.076-0.273,0.099c-0.146,0.023-0.32,0.084-0.351,0.152c-0.022,0.069-0.106,0.183-0.016,0.214
               c0.092,0.037,0.023,0.037,0.168,0.007c0.137-0.03,0.298-0.129,0.229-0.007c-0.076,0.121,0.03,0.152-0.084,0.198
               c-0.121,0.045-0.297,0.22-0.426,0.068c-0.123-0.152-0.229-0.183-0.298-0.13c-0.075,0.046-0.205-0.022-0.137-0.114
               c0.068-0.091,0.297-0.274,0.1-0.243c-0.199,0.023-0.29,0.114-0.365,0.205c-0.077,0.092-0.214-0.038-0.32,0.077
               c-0.106,0.114-0.13,0.175-0.19,0.175c-0.062,0-0.251,0.015-0.183,0.091c0.061,0.076,0.251,0.053,0.335,0.068
               c0.083,0.008,0.038,0.092-0.084,0.084c-0.114-0.007-0.229,0.03-0.282,0.076c-0.06,0.045-0.167-0.038-0.266-0.007
               c-0.092,0.023-0.198,0.068-0.198,0.114c0,0.053-0.13,0.136,0.076,0.129c0.205-0.015,0.221-0.099,0.305-0.084
               c0.083,0.016,0.137-0.038,0.289-0.061c0.152-0.022,0.214,0,0.327-0.022c0.114-0.024,0.138-0.084,0.229-0.084
               c0.1,0,0.305-0.053,0.305-0.053s0.358-0.137,0.283-0.038c-0.077,0.099-0.184,0.053-0.291,0.137
               c-0.106,0.083-0.175,0.129-0.228,0.144c-0.054,0.015-0.145-0.03-0.244,0.084c-0.106,0.114-0.213,0.008-0.259,0.122
               c-0.046,0.121,0.083,0.16,0.008,0.221c-0.077,0.061-0.235,0.007-0.267,0.144c-0.023,0.129-0.069,0.282-0.069,0.282
               s-0.335,0.099-0.464,0.267c-0.137,0.167-0.251,0.373-0.319,0.518c-0.069,0.144,0,0.098-0.259,0.304
               c-0.259,0.198-0.1,0.281-0.336,0.48c-0.236,0.205-0.532,0.152-0.396,0.32c0.137,0.167,0.114,0.373-0.023,0.304
               c-0.137-0.069-0.304-0.282-0.357-0.153c-0.053,0.138-0.273,0.145-0.396,0.358c-0.121,0.214-0.205,0.183-0.318,0.267
               c-0.115,0.091-0.245,0.198-0.328,0.129c-0.076-0.075-0.183-0.281-0.281-0.152c-0.107,0.122-0.213,0.129-0.138,0.221
               c0.076,0.084,0.03,0.114-0.015,0.16c-0.039,0.046-0.206-0.145-0.282-0.076c-0.083,0.061-0.008,0.068,0,0.175
               c0.008,0.106-0.068,0.106-0.145,0.144c-0.068,0.039-0.084,0.069-0.19,0.039c-0.1-0.039-0.327-0.061-0.32,0.061
               c0.008,0.122,0.145,0.22-0.007,0.228c-0.146,0.015-0.16-0.069-0.289-0.008c-0.13,0.061-0.13,0.115-0.152,0.152
               c-0.023,0.031-0.274,0.084-0.351,0.152c-0.068,0.077-0.145,0.023-0.251,0.046s-0.069,0.152-0.122,0.229
               c-0.046,0.076-0.244,0.053-0.167,0.175c0.084,0.114,0.19,0.046,0.151,0.168c-0.03,0.114-0.168,0.114-0.091,0.22
               c0.083,0.115,0.03,0.183,0.023,0.267c-0.017,0.084,0.022,0.137,0.045,0.206c0.03,0.068,0.062,0.235,0.175,0.136
               c0.114-0.106,0.328-0.052,0.229,0.016c-0.106,0.076-0.259,0.023-0.274,0.114c-0.022,0.084-0.03,0.038,0.031,0.175
               c0.061,0.145-0.031,0.167-0.054,0.275c-0.016,0.106-0.114,0.166-0.068,0.342c0.046,0.167-0.061,0.35,0.068,0.313
               c0.138-0.039,0.32-0.237,0.389-0.176c0.061,0.06,0.189,0.084,0.038,0.19c-0.145,0.107-0.222,0.046-0.236,0.16
               c-0.015,0.115-0.152,0.221,0.015,0.267c0.168,0.053,0.335,0.182,0.64,0.327c0.305,0.145,0.487,0.282,0.686,0.236
               c0.206-0.045,0.357-0.146,0.678-0.267c0.313-0.121,0.479-0.631,0.784-0.655c0.305-0.022,0.396-0.022,0.404-0.19
               c0.014-0.167,0.014-0.494,0.113-0.304c0.099,0.19,0.046,0.335,0.16,0.373c0.106,0.03,0.274-0.023,0.259,0.168
               c0.091-0.008,0.198-0.023,0.259-0.047c0.038-0.015,0.031-0.06,0.022-0.121c-0.007-0.069-0.015-0.137,0.009-0.213
               c0.054-0.129,0.061-0.176,0.075-0.266l0.008-0.031c0.023-0.106,0.1-0.122,0.198-0.137c0.054-0.008,0.114-0.023,0.167-0.046
               c0.084-0.046,0.077-0.091,0.068-0.145c0-0.03-0.008-0.06,0-0.091c0.009-0.046-0.022-0.114-0.061-0.191
               c-0.03-0.06-0.061-0.128-0.092-0.212c-0.037-0.115-0.029-0.184,0.016-0.259c0.046-0.061,0.129-0.115,0.252-0.191
               c0.038-0.015,0.046-0.038,0.038-0.045c-0.031-0.054-0.145-0.107-0.236-0.146c-0.054-0.022-0.092-0.045-0.115-0.068
               c-0.075-0.053-0.037-0.152-0.007-0.251c0.023-0.061,0.046-0.122,0.038-0.144c-0.015-0.023-0.038-0.046-0.061-0.077
               c-0.062-0.061-0.122-0.137-0.106-0.251l0.007-0.015c0.022-0.13,0.046-0.266,0.016-0.488c-0.038-0.235-0.016-0.258,0.068-0.349
               c0.016-0.008,0.022-0.024,0.038-0.039c0.03-0.038,0.053-0.076,0.076-0.114c0.046-0.084,0.099-0.183,0.244-0.304
               c0.152-0.138,0.35-0.122,0.502-0.107c0.076,0,0.137,0.008,0.169-0.008c0.007,0,0.014-0.007,0.021-0.015
               c0.008-0.008,0.008-0.023,0.008-0.038c0-0.023-0.008-0.046-0.016-0.069c-0.022-0.053-0.062-0.106-0.106-0.144
               c-0.092-0.084-0.092-0.145-0.03-0.206c0.022-0.031,0.068-0.061,0.114-0.099c0.022-0.015,0.053-0.038,0.076-0.061
               c0.099-0.076,0.121-0.137,0.152-0.22c0.015-0.046,0.031-0.099,0.061-0.16c0.054-0.107,0.045-0.191,0.045-0.275
               c0-0.053-0.007-0.106,0.009-0.167c0.03-0.137,0.197-0.176,0.35-0.206c0.046-0.007,0.084-0.015,0.114-0.03
               c0.068-0.023,0.068-0.068,0.077-0.129c0.007-0.031,0.014-0.069,0.022-0.107c0.022-0.092,0.152-0.175,0.273-0.251
               c0.084-0.053,0.16-0.107,0.19-0.153c0.038-0.053,0.023-0.076,0-0.114c-0.022-0.023-0.045-0.054-0.061-0.099
               c-0.054-0.122,0.016-0.167,0.099-0.205c0.016-0.008,0.023-0.016,0.03-0.016c0.022-0.007,0.046-0.015,0.054-0.03
               c0.038-0.016,0.053-0.046,0.092-0.099c0.03-0.046,0.068-0.107,0.137-0.183c0.114-0.152,0.281-0.114,0.434-0.084
               c0.068,0.016,0.13,0.03,0.175,0.023c0.062-0.007,0.062-0.076,0.055-0.152c0-0.053,0-0.122,0.022-0.183
               c0.053-0.145,0.272-0.091,0.464-0.046c0.055,0.016,0.106,0.031,0.152,0.031c0.061,0.008,0.145,0.023,0.221,0.023
               c0.114,0.015,0.221,0.015,0.244,0.015c0,0,0.016-0.015,0.03-0.053c0.022-0.031,0.038-0.076,0.038-0.107
               c0.008-0.06,0.046-0.13,0.099-0.183c0.054-0.053,0.122-0.091,0.183-0.099h0.017c0.067-0.007,0.167-0.007,0.229-0.128
               c0.067-0.146,0.174-0.13,0.326-0.1c0.047,0.008,0.092,0.016,0.146,0.023c0.137,0.015,0.273,0.122,0.41,0.236
               c0.115,0.084,0.229,0.175,0.334,0.214c0.152,0.06,0.215,0.029,0.306-0.016c0.038-0.023,0.092-0.046,0.151-0.068
               c0.168-0.062,0.252-0.031,0.367,0.015c0.045,0.014,0.098,0.038,0.166,0.053c0.114,0.023,0.184,0.03,0.229,0.023
               c0.039-0.016,0.063-0.053,0.091-0.114c0.063-0.145,0.101-0.138,0.168-0.114c0.022,0.007,0.056,0.015,0.101,0.007
               c0.054-0.007,0.054-0.031,0.047-0.076c-0.01-0.03-0.017-0.076-0.017-0.122c0-0.084,0.017-0.16,0.037-0.229
               c0.024-0.068,0.056-0.129,0.093-0.197c0.068-0.137,0.182-0.13,0.35-0.122c0.037,0.007,0.084,0.007,0.13,0.007
               c0.114,0,0.175-0.03,0.245-0.068c0.052-0.031,0.105-0.053,0.182-0.077c0.184-0.045,0.327,0.039,0.479,0.123l0.045,0.03
               c0.115,0.069,0.214,0.061,0.322,0.061h0.073c0.024,0,0.048,0,0.063,0.015c0.029,0.008,0.054,0.031,0.061,0.061
               c0.016,0.031,0.016,0.053,0.016,0.083c0,0.1-0.047,0.237-0.076,0.298c-0.022,0.061-0.068,0.091-0.106,0.122
               c-0.028,0.03-0.062,0.053-0.062,0.091c0.008,0.015,0.062,0.03,0.131,0.03c0.076-0.129,0.015-0.068,0.158-0.136
               c0.146-0.077,0.152-0.282,0.291-0.298c0.129-0.007,0.242,0.038,0.365-0.053c0.129-0.091,0.188-0.03,0.28-0.046
               C133.968,10.255,133.876,10.156,133.876,10.156z"/>
             <path fill="#808184" d="M127.946,17.823c0,0-0.184,0.045-0.184,0.122
               c0,0.076,0.062,0.03,0.184,0.091c0.113,0.061,0.099,0.107,0.197,0.122c0.092,0.015,0.137-0.29,0.037-0.351
               C128.09,17.74,127.946,17.823,127.946,17.823 M130.269,13.323c0.213,0.008,0.473,0.038,0.625,0.16
               c0.184,0.145,0.129,0.152,0.121,0.297c-0.008,0.145-0.113,0.236-0.214,0.259c-0.091,0.023-0.213,0.114-0.297,0.243
               c-0.092,0.13-0.206,0.267-0.44,0.412c-0.236,0.145-0.358,0.32-0.465,0.373c-0.106,0.061-0.32,0.015-0.351,0.152
               c-0.031,0.146-0.274,0.106-0.403,0.282c-0.121,0.175-0.1,0.274-0.146,0.335c-0.046,0.053-0.113,0.13-0.03,0.236
               c0.076,0.114,0.32,0.091,0.268,0.495c-0.062,0.411-0.017,0.601,0.061,0.754c0.084,0.159-0.105,0.251,0.016,0.35
               c0.121,0.099,0.717-0.092,0.754,0.183c0.03,0.266,0.123,0.304,0.235,0.35c0.116,0.046,0.116,0.167,0.358,0.008
               c0.252-0.16,0.914-0.183,1.203-0.222c0.297-0.045,0.607-0.274,0.775-0.281c0.168-0.015,0.776-0.015,0.776-0.015
               s0.19-0.298,0.571-0.503s0.737-0.541,0.898-0.761c0.159-0.229,0.654-0.579,0.631-0.769c-0.021-0.191-0.205-0.221-0.396-0.336
               c-0.188-0.113-0.661-0.182-0.586-0.357c0.084-0.183,0.353-0.343,0.177-0.45c-0.185-0.113-0.437-0.136-0.388-0.274
               c0.045-0.137-0.023-0.159-0.131-0.235c-0.115-0.077-0.176-0.236-0.129-0.313c0.045-0.084,0.112-0.319,0.159-0.373
               c0.045-0.053-0.008-0.304-0.121-0.396c-0.114-0.091-0.663-0.54-0.579-0.646c0.084-0.106,0.282-0.343,0.258-0.503
               c-0.021-0.167-0.099-0.175-0.233-0.251c-0.131-0.083-0.123-0.137-0.336-0.182c-0.214-0.046-0.281-0.069-0.306-0.168
               c-0.015-0.083,0.045-0.183,0.115-0.289c-0.092-0.016-0.169-0.046-0.176-0.099c-0.016-0.099,0.038-0.138,0.1-0.183
               c0.03-0.023,0.061-0.053,0.076-0.084c0.028-0.061,0.068-0.175,0.068-0.259c0-0.015,0-0.03-0.008-0.045l-0.008-0.008
               c-0.008-0.007-0.018-0.007-0.024-0.007h-0.065c-0.131,0.007-0.238,0.007-0.381-0.069l-0.048-0.03
               c-0.129-0.076-0.259-0.153-0.401-0.115c-0.063,0.016-0.107,0.046-0.153,0.069c-0.084,0.038-0.151,0.076-0.298,0.076h-0.137
               c-0.123-0.008-0.213-0.015-0.252,0.061c-0.037,0.061-0.068,0.122-0.082,0.183c-0.022,0.06-0.031,0.121-0.031,0.198
               c0,0.045,0.009,0.075,0.009,0.106c0.022,0.107,0.03,0.16-0.123,0.19c-0.067,0.016-0.113,0-0.144-0.007
               c-0.016-0.007-0.023-0.007-0.055,0.053c-0.038,0.092-0.084,0.145-0.152,0.168c-0.067,0.022-0.151,0.015-0.281-0.016
               c-0.075-0.015-0.131-0.038-0.182-0.06c-0.093-0.031-0.16-0.061-0.291-0.008c-0.061,0.015-0.105,0.038-0.145,0.061
               c-0.114,0.06-0.189,0.091-0.389,0.023c-0.123-0.046-0.244-0.137-0.357-0.237c-0.13-0.099-0.259-0.198-0.357-0.213
               c-0.061-0.007-0.106-0.015-0.152-0.022c-0.113-0.023-0.184-0.031-0.221,0.045c-0.022,0.038-0.045,0.069-0.068,0.1
               c0.031,0.022,0.076,0.06,0.152,0.09c0.092,0.039,0.189,0.1,0.281,0.153c0.084,0.053,0.168,0.099,0.229,0.129
               c0.068,0.023,0.123,0.016,0.176,0.008c0.055-0.008,0.105-0.016,0.184,0c0.082,0.015,0.159,0.068,0.235,0.122
               c0.067,0.045,0.136,0.091,0.19,0.091c0.158,0.008,0.183,0.084,0.229,0.236l0.008,0.031c0.016,0.06,0.016,0.106,0.022,0.137
               c0,0.038,0.007,0.053,0.016,0.053c0.037,0.023,0.076,0.076,0.099,0.137c0.022,0.053,0.031,0.114,0.016,0.183
               c-0.008,0.053,0.031,0.076,0.076,0.113c0.037,0.032,0.076,0.062,0.113,0.1c0.113,0.122,0.053,0.206-0.016,0.289
               c-0.021,0.031-0.044,0.061-0.063,0.084c-0.021,0.068,0.047,0.152,0.162,0.281c0.021,0.024,0.045,0.055,0.067,0.092
               C130.229,13.156,130.253,13.24,130.269,13.323"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M127.946,17.823c0,0-0.184,0.045-0.184,0.122c0,0.076,0.062,0.03,0.184,0.091c0.113,0.061,0.099,0.107,0.197,0.122
               c0.092,0.015,0.137-0.29,0.037-0.351C128.09,17.74,127.946,17.823,127.946,17.823z M130.269,13.323
               c0.213,0.008,0.473,0.038,0.625,0.16c0.184,0.145,0.129,0.152,0.121,0.297c-0.008,0.145-0.113,0.236-0.214,0.259
               c-0.091,0.023-0.213,0.114-0.297,0.243c-0.092,0.13-0.206,0.267-0.44,0.412c-0.236,0.145-0.358,0.32-0.465,0.373
               c-0.106,0.061-0.32,0.015-0.351,0.152c-0.031,0.146-0.274,0.106-0.403,0.282c-0.121,0.175-0.1,0.274-0.146,0.335
               c-0.046,0.053-0.113,0.13-0.03,0.236c0.076,0.114,0.32,0.091,0.268,0.495c-0.062,0.411-0.017,0.601,0.061,0.754
               c0.084,0.159-0.105,0.251,0.016,0.35c0.121,0.099,0.717-0.092,0.754,0.183c0.03,0.266,0.123,0.304,0.235,0.35
               c0.116,0.046,0.116,0.167,0.358,0.008c0.252-0.16,0.914-0.183,1.203-0.222c0.297-0.045,0.607-0.274,0.775-0.281
               c0.168-0.015,0.776-0.015,0.776-0.015s0.19-0.298,0.571-0.503s0.737-0.541,0.898-0.761c0.159-0.229,0.654-0.579,0.631-0.769
               c-0.021-0.191-0.205-0.221-0.396-0.336c-0.188-0.113-0.661-0.182-0.586-0.357c0.084-0.183,0.353-0.343,0.177-0.45
               c-0.185-0.113-0.437-0.136-0.388-0.274c0.045-0.137-0.023-0.159-0.131-0.235c-0.115-0.077-0.176-0.236-0.129-0.313
               c0.045-0.084,0.112-0.319,0.159-0.373c0.045-0.053-0.008-0.304-0.121-0.396c-0.114-0.091-0.663-0.54-0.579-0.646
               c0.084-0.106,0.282-0.343,0.258-0.503c-0.021-0.167-0.099-0.175-0.233-0.251c-0.131-0.083-0.123-0.137-0.336-0.182
               c-0.214-0.046-0.281-0.069-0.306-0.168c-0.015-0.083,0.045-0.183,0.115-0.289c-0.092-0.016-0.169-0.046-0.176-0.099
               c-0.016-0.099,0.038-0.138,0.1-0.183c0.03-0.023,0.061-0.053,0.076-0.084c0.028-0.061,0.068-0.175,0.068-0.259
               c0-0.015,0-0.03-0.008-0.045l-0.008-0.008c-0.008-0.007-0.018-0.007-0.024-0.007h-0.065c-0.131,0.007-0.238,0.007-0.381-0.069
               l-0.048-0.03c-0.129-0.076-0.259-0.153-0.401-0.115c-0.063,0.016-0.107,0.046-0.153,0.069c-0.084,0.038-0.151,0.076-0.298,0.076
               h-0.137c-0.123-0.008-0.213-0.015-0.252,0.061c-0.037,0.061-0.068,0.122-0.082,0.183c-0.022,0.06-0.031,0.121-0.031,0.198
               c0,0.045,0.009,0.075,0.009,0.106c0.022,0.107,0.03,0.16-0.123,0.19c-0.067,0.016-0.113,0-0.144-0.007
               c-0.016-0.007-0.023-0.007-0.055,0.053c-0.038,0.092-0.084,0.145-0.152,0.168c-0.067,0.022-0.151,0.015-0.281-0.016
               c-0.075-0.015-0.131-0.038-0.182-0.06c-0.093-0.031-0.16-0.061-0.291-0.008c-0.061,0.015-0.105,0.038-0.145,0.061
               c-0.114,0.06-0.189,0.091-0.389,0.023c-0.123-0.046-0.244-0.137-0.357-0.237c-0.13-0.099-0.259-0.198-0.357-0.213
               c-0.061-0.007-0.106-0.015-0.152-0.022c-0.113-0.023-0.184-0.031-0.221,0.045c-0.022,0.038-0.045,0.069-0.068,0.1
               c0.031,0.022,0.076,0.06,0.152,0.09c0.092,0.039,0.189,0.1,0.281,0.153c0.084,0.053,0.168,0.099,0.229,0.129
               c0.068,0.023,0.123,0.016,0.176,0.008c0.055-0.008,0.105-0.016,0.184,0c0.082,0.015,0.159,0.068,0.235,0.122
               c0.067,0.045,0.136,0.091,0.19,0.091c0.158,0.008,0.183,0.084,0.229,0.236l0.008,0.031c0.016,0.06,0.016,0.106,0.022,0.137
               c0,0.038,0.007,0.053,0.016,0.053c0.037,0.023,0.076,0.076,0.099,0.137c0.022,0.053,0.031,0.114,0.016,0.183
               c-0.008,0.053,0.031,0.076,0.076,0.113c0.037,0.032,0.076,0.062,0.113,0.1c0.113,0.122,0.053,0.206-0.016,0.289
               c-0.021,0.031-0.044,0.061-0.063,0.084c-0.021,0.068,0.047,0.152,0.162,0.281c0.021,0.024,0.045,0.055,0.067,0.092
               C130.229,13.156,130.253,13.24,130.269,13.323z"/>
                  
           
            
            <!-- grece-->
               <path fill="#808184" d="M129.385,36.667c0.068,0.084,0.207,0.183,0.269,0.237
               c0.061,0.068,0.22-0.009,0.272,0.098c0.062,0.107,0.038,0.114-0.077,0.138c-0.113,0.022-0.136,0.022-0.136,0.06
               c0,0.031-0.121,0.198-0.022,0.213c0.105,0.016,0.221-0.145,0.281-0.038c0.053,0.115-0.113,0.198,0.106,0.252
               c0.213,0.053,0.312-0.046,0.409,0c0.107,0.045,0.168,0.03,0.223,0.007c0.061-0.014,0.396-0.046,0.518,0.038
               c0.123,0.084,0.213,0.061,0.305,0.099c0.091,0.038,0.138,0.183,0.055,0.168c-0.084-0.023-0.16-0.053-0.223-0.038
               c-0.061,0.015-0.35-0.168-0.448-0.176c-0.091,0-0.175-0.053-0.28-0.022c-0.107,0.038-0.176,0.084-0.223,0.092
               c-0.045,0.007-0.053-0.108-0.114-0.024c-0.052,0.084-0.045,0.19-0.112,0.229c-0.063,0.03-0.14,0.091-0.063,0.175
               c0.084,0.084,0.281,0.152,0.357,0.228c0.069,0.069,0.16,0.099,0.047,0.198c-0.115,0.106-0.039,0.183-0.016,0.236
               c0.021,0.061,0.145,0.297,0.229,0.297s-0.03-0.366,0.091-0.297c0.129,0.069,0.245,0.167,0.261,0.327
               c0.006,0.168,0.059,0.259,0.105,0.252c0.037-0.007,0.068-0.297,0.113-0.312c0.053-0.016,0.184-0.016,0.198,0.068
               s0.099,0.16,0.138,0.183c0.037,0.022,0.174,0.114,0.106,0c-0.069-0.122-0.085-0.183-0.093-0.274
               c-0.016-0.099-0.075-0.092-0.113-0.244c-0.038-0.144-0.197-0.281-0.139-0.404c0.055-0.129,0.107-0.136,0.191-0.091
               c0.075,0.053,0.021,0.251,0.188,0.168c0.168-0.084,0.101-0.144-0.007-0.274c-0.106-0.122-0.197-0.183-0.146-0.229
               c0.054-0.046,0.054-0.091,0.177-0.06c0.121,0.03,0.221-0.008,0.297,0.105c0.074,0.122,0.183,0.297,0.242,0.206
               c0.055-0.084-0.029-0.427-0.061-0.511c-0.023-0.076-0.107-0.091-0.176-0.136c-0.067-0.039-0.061-0.122,0.031-0.122
               c0.09,0,0.234,0.015,0.25,0.122c0.008,0.099,0.037,0.175,0.092,0.22c0.061,0.038,0.236,0.161,0.25,0.084
               c0.023-0.084-0.037-0.152-0.129-0.191c-0.084-0.03-0.112-0.182-0.183-0.304c-0.069-0.13-0.351-0.091-0.473-0.236
               c-0.113-0.137-0.228-0.228-0.312-0.221c-0.076,0.008-0.107,0.031-0.176-0.014c-0.076-0.046-0.168-0.046-0.099-0.199
               c0.06-0.145,0.045-0.229-0.009-0.403c-0.062-0.184-0.32-0.259-0.289-0.374c0.029-0.122,0.121-0.122,0.101-0.228
               c-0.024-0.099,0.029-0.221,0.12-0.198c0.092,0.03,0.008,0.069,0.077,0.152c0.075,0.077,0.241,0.061,0.259,0.145
               c0.007,0.084,0.021,0.229,0.113,0.221c0.092,0-0.113-0.236-0.008-0.236c0.1,0,0.082,0.022,0.184,0.099
               c0.091,0.076,0.25,0.266,0.243,0.129c-0.015-0.137-0.197-0.206-0.152-0.259c0.046-0.053,0.093-0.076,0.159-0.053
               c0.077,0.022,0.229,0.137,0.197,0.038c-0.029-0.091-0.184-0.198-0.273-0.198c-0.092,0-0.296-0.205-0.105-0.236
               c0.189-0.038,0.115,0.068,0.273,0.031c0.16-0.046,0.237-0.267,0.289-0.229c0.047,0.038-0.121,0.191-0.021,0.266
               c0.09,0.069,0.213,0.061,0.228-0.037c0.017-0.092,0.076-0.145,0.199-0.153c0.122,0,0.091-0.023,0.305,0.023
               c0.206,0.046,0.334,0.061,0.402,0.061c0.069,0,0.184-0.099,0.206-0.175c0.031-0.084-0.008-0.213,0.061-0.251
               c0.063-0.038,0.153-0.115,0.139-0.153c-0.017-0.045-0.184-0.129-0.129-0.243l0.014-0.024c-0.037-0.007-0.067-0.014-0.092-0.03
               c-0.006,0-0.015,0.008-0.015,0.023c-0.03,0.038-0.045,0.122-0.045,0.198c0,0.13-0.046,0.145-0.123,0.16
               c-0.022,0.008-0.053,0.023-0.084,0.046c-0.09,0.061-0.121,0.061-0.229,0.046c-0.037-0.008-0.098-0.016-0.188-0.016
               c-0.138-0.007-0.251-0.015-0.344-0.03c-0.091-0.015-0.168-0.054-0.233-0.107c-0.078-0.076-0.215-0.008-0.298,0.039l-0.017,0.007
               h-0.008c-0.092,0.053-0.151,0.076-0.563,0.038c-0.196-0.015-0.296,0.046-0.388,0.114c-0.1,0.068-0.205,0.137-0.403,0.137
               c-0.244,0-0.244,0-0.251,0.008c-0.009,0.015-0.023,0.038-0.061,0.061c-0.062,0.038-0.185,0.091-0.313,0.137
               c-0.099,0.03-0.204,0.053-0.297,0.053c-0.037,0-0.045,0.008-0.045,0.023s0,0.038,0.008,0.061
               c0.008,0.053,0.022,0.122,0.008,0.206c-0.022,0.091-0.077,0.182-0.146,0.266c-0.084,0.099-0.197,0.183-0.268,0.221
               c-0.074,0.03-0.099,0.106-0.121,0.205c-0.008,0.023-0.008,0.054-0.022,0.091c-0.015,0.062-0.022,0.153-0.03,0.237v0.007H129.385
               z M132.271,40.102c-0.092,0.084-0.184,0.008-0.205,0.16c-0.022,0.16,0.131,0.297,0.396,0.297c0.26,0,0.441,0.038,0.549,0.114
               c0.105,0.076,0.12,0.146,0.273,0.146c0.152-0.008,0.145-0.046,0.396-0.061c0.243-0.015,0.412-0.023,0.463-0.091
               c0.063-0.062,0.131-0.282-0.067-0.221c-0.188,0.06-0.228,0.144-0.298,0.099c-0.066-0.046-0.182-0.191-0.325-0.175
               c-0.153,0.015-0.313,0.045-0.39,0.023c-0.067-0.023-0.092-0.176-0.28-0.092c-0.189,0.084-0.274,0.069-0.274,0.031
               c0-0.039-0.046-0.221-0.099-0.229C132.354,40.094,132.271,40.102,132.271,40.102 M133.313,39.082
               c0,0.015,0.017,0.114,0.076,0.114c0.055,0,0.123,0.061,0.115,0c-0.018-0.068-0.107-0.182-0.146-0.182
               C133.313,39.014,133.313,39.082,133.313,39.082 M133.274,38.724c-0.037,0.031-0.112,0.061-0.075,0.114
               c0.038,0.054,0.067,0.092,0.099,0.061c0.039-0.023,0.1-0.075,0.113-0.061c0.018,0.008,0.063,0.084,0.107,0.076
               c0.045-0.015,0.092-0.068,0.1-0.122c0.008-0.052,0.023-0.151-0.061-0.151s-0.131,0.067-0.162,0.083
               C133.366,38.73,133.274,38.724,133.274,38.724 M132.833,38.837c0,0.023-0.029,0.046,0.023,0.069
               c0.054,0.03,0.045,0.053,0.076,0.076c0.022,0.022,0.105-0.016,0.076-0.076c-0.039-0.069-0.054-0.138-0.1-0.138
               C132.865,38.769,132.833,38.837,132.833,38.837 M132.683,39.097c0,0-0.168,0.115-0.068,0.129
               c0.091,0.008,0.113-0.045,0.152-0.045c0.037,0.007,0.092-0.16,0.016-0.16C132.704,39.021,132.683,39.097,132.683,39.097
                M132.583,38.267c-0.031,0.016-0.1,0.1-0.047,0.1c0.063,0,0.229-0.077,0.176-0.123
               C132.665,38.206,132.583,38.267,132.583,38.267 M132.604,38.451c0,0.022-0.006,0.234,0.039,0.174
               c0.053-0.068,0.084-0.174,0.053-0.198C132.665,38.412,132.604,38.451,132.604,38.451 M132.994,38.404
               c0,0,0.021,0.213,0.062,0.145c0.045-0.061,0.045-0.228,0-0.228C133.001,38.328,132.994,38.404,132.994,38.404 M132.926,37.948
               c-0.046,0.007-0.106,0.021-0.062,0.106c0.046,0.084,0.115,0.069,0.16,0.137c0.054,0.069,0.138,0.107,0.168,0.152
               c0.021,0.038,0.099,0.069,0.091,0.008c-0.009-0.061-0.076-0.091-0.101-0.114c-0.029-0.023-0.099-0.137-0.121-0.191
               c-0.022-0.045-0.037-0.091-0.067-0.098C132.964,37.932,132.926,37.948,132.926,37.948 M131.522,39.447
               c0.008,0.047-0.028,0.145,0.055,0.198c0.084,0.054,0.107,0.084,0.122,0.023c0.007-0.068-0.015-0.167-0.067-0.198
               C131.586,39.432,131.522,39.447,131.522,39.447 M129.575,37.514c0,0-0.152,0.107-0.092,0.212c0.062,0.107,0.138,0.07,0.205,0.13
               c0.07,0.054,0.215-0.023,0.138-0.107c-0.076-0.076-0.113-0.144-0.15-0.198C129.629,37.505,129.575,37.514,129.575,37.514
                M132.612,36.996c-0.021,0.015-0.047,0.083-0.014,0.114c0.021,0.031,0.014,0.091,0.065,0.114
               c0.056,0.023,0.138,0.031,0.131-0.015c-0.008-0.046-0.054-0.213-0.084-0.229C132.683,36.965,132.612,36.996,132.612,36.996"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M129.385,36.667c0.068,0.084,0.207,0.183,0.269,0.237c0.061,0.068,0.22-0.009,0.272,0.098c0.062,0.107,0.038,0.114-0.077,0.138
               c-0.113,0.022-0.136,0.022-0.136,0.06c0,0.031-0.121,0.198-0.022,0.213c0.105,0.016,0.221-0.145,0.281-0.038
               c0.053,0.115-0.113,0.198,0.106,0.252c0.213,0.053,0.312-0.046,0.409,0c0.107,0.045,0.168,0.03,0.223,0.007
               c0.061-0.014,0.396-0.046,0.518,0.038c0.123,0.084,0.213,0.061,0.305,0.099c0.091,0.038,0.138,0.183,0.055,0.168
               c-0.084-0.023-0.16-0.053-0.223-0.038c-0.061,0.015-0.35-0.168-0.448-0.176c-0.091,0-0.175-0.053-0.28-0.022
               c-0.107,0.038-0.176,0.084-0.223,0.092c-0.045,0.007-0.053-0.108-0.114-0.024c-0.052,0.084-0.045,0.19-0.112,0.229
               c-0.063,0.03-0.14,0.091-0.063,0.175c0.084,0.084,0.281,0.152,0.357,0.228c0.069,0.069,0.16,0.099,0.047,0.198
               c-0.115,0.106-0.039,0.183-0.016,0.236c0.021,0.061,0.145,0.297,0.229,0.297s-0.03-0.366,0.091-0.297
               c0.129,0.069,0.245,0.167,0.261,0.327c0.006,0.168,0.059,0.259,0.105,0.252c0.037-0.007,0.068-0.297,0.113-0.312
               c0.053-0.016,0.184-0.016,0.198,0.068s0.099,0.16,0.138,0.183c0.037,0.022,0.174,0.114,0.106,0
               c-0.069-0.122-0.085-0.183-0.093-0.274c-0.016-0.099-0.075-0.092-0.113-0.244c-0.038-0.144-0.197-0.281-0.139-0.404
               c0.055-0.129,0.107-0.136,0.191-0.091c0.075,0.053,0.021,0.251,0.188,0.168c0.168-0.084,0.101-0.144-0.007-0.274
               c-0.106-0.122-0.197-0.183-0.146-0.229c0.054-0.046,0.054-0.091,0.177-0.06c0.121,0.03,0.221-0.008,0.297,0.105
               c0.074,0.122,0.183,0.297,0.242,0.206c0.055-0.084-0.029-0.427-0.061-0.511c-0.023-0.076-0.107-0.091-0.176-0.136
               c-0.067-0.039-0.061-0.122,0.031-0.122c0.09,0,0.234,0.015,0.25,0.122c0.008,0.099,0.037,0.175,0.092,0.22
               c0.061,0.038,0.236,0.161,0.25,0.084c0.023-0.084-0.037-0.152-0.129-0.191c-0.084-0.03-0.112-0.182-0.183-0.304
               c-0.069-0.13-0.351-0.091-0.473-0.236c-0.113-0.137-0.228-0.228-0.312-0.221c-0.076,0.008-0.107,0.031-0.176-0.014
               c-0.076-0.046-0.168-0.046-0.099-0.199c0.06-0.145,0.045-0.229-0.009-0.403c-0.062-0.184-0.32-0.259-0.289-0.374
               c0.029-0.122,0.121-0.122,0.101-0.228c-0.024-0.099,0.029-0.221,0.12-0.198c0.092,0.03,0.008,0.069,0.077,0.152
               c0.075,0.077,0.241,0.061,0.259,0.145c0.007,0.084,0.021,0.229,0.113,0.221c0.092,0-0.113-0.236-0.008-0.236
               c0.1,0,0.082,0.022,0.184,0.099c0.091,0.076,0.25,0.266,0.243,0.129c-0.015-0.137-0.197-0.206-0.152-0.259
               c0.046-0.053,0.093-0.076,0.159-0.053c0.077,0.022,0.229,0.137,0.197,0.038c-0.029-0.091-0.184-0.198-0.273-0.198
               c-0.092,0-0.296-0.205-0.105-0.236c0.189-0.038,0.115,0.068,0.273,0.031c0.16-0.046,0.237-0.267,0.289-0.229
               c0.047,0.038-0.121,0.191-0.021,0.266c0.09,0.069,0.213,0.061,0.228-0.037c0.017-0.092,0.076-0.145,0.199-0.153
               c0.122,0,0.091-0.023,0.305,0.023c0.206,0.046,0.334,0.061,0.402,0.061c0.069,0,0.184-0.099,0.206-0.175
               c0.031-0.084-0.008-0.213,0.061-0.251c0.063-0.038,0.153-0.115,0.139-0.153c-0.017-0.045-0.184-0.129-0.129-0.243l0.014-0.024
               c-0.037-0.007-0.067-0.014-0.092-0.03c-0.006,0-0.015,0.008-0.015,0.023c-0.03,0.038-0.045,0.122-0.045,0.198
               c0,0.13-0.046,0.145-0.123,0.16c-0.022,0.008-0.053,0.023-0.084,0.046c-0.09,0.061-0.121,0.061-0.229,0.046
               c-0.037-0.008-0.098-0.016-0.188-0.016c-0.138-0.007-0.251-0.015-0.344-0.03c-0.091-0.015-0.168-0.054-0.233-0.107
               c-0.078-0.076-0.215-0.008-0.298,0.039l-0.017,0.007h-0.008c-0.092,0.053-0.151,0.076-0.563,0.038
               c-0.196-0.015-0.296,0.046-0.388,0.114c-0.1,0.068-0.205,0.137-0.403,0.137c-0.244,0-0.244,0-0.251,0.008
               c-0.009,0.015-0.023,0.038-0.061,0.061c-0.062,0.038-0.185,0.091-0.313,0.137c-0.099,0.03-0.204,0.053-0.297,0.053
               c-0.037,0-0.045,0.008-0.045,0.023s0,0.038,0.008,0.061c0.008,0.053,0.022,0.122,0.008,0.206
               c-0.022,0.091-0.077,0.182-0.146,0.266c-0.084,0.099-0.197,0.183-0.268,0.221c-0.074,0.03-0.099,0.106-0.121,0.205
               c-0.008,0.023-0.008,0.054-0.022,0.091c-0.015,0.062-0.022,0.153-0.03,0.237v0.007H129.385z M132.271,40.102
               c-0.092,0.084-0.184,0.008-0.205,0.16c-0.022,0.16,0.131,0.297,0.396,0.297c0.26,0,0.441,0.038,0.549,0.114
               c0.105,0.076,0.12,0.146,0.273,0.146c0.152-0.008,0.145-0.046,0.396-0.061c0.243-0.015,0.412-0.023,0.463-0.091
               c0.063-0.062,0.131-0.282-0.067-0.221c-0.188,0.06-0.228,0.144-0.298,0.099c-0.066-0.046-0.182-0.191-0.325-0.175
               c-0.153,0.015-0.313,0.045-0.39,0.023c-0.067-0.023-0.092-0.176-0.28-0.092c-0.189,0.084-0.274,0.069-0.274,0.031
               c0-0.039-0.046-0.221-0.099-0.229C132.354,40.094,132.271,40.102,132.271,40.102z M133.313,39.082
               c0,0.015,0.017,0.114,0.076,0.114c0.055,0,0.123,0.061,0.115,0c-0.018-0.068-0.107-0.182-0.146-0.182
               C133.313,39.014,133.313,39.082,133.313,39.082z M133.274,38.724c-0.037,0.031-0.112,0.061-0.075,0.114
               c0.038,0.054,0.067,0.092,0.099,0.061c0.039-0.023,0.1-0.075,0.113-0.061c0.018,0.008,0.063,0.084,0.107,0.076
               c0.045-0.015,0.092-0.068,0.1-0.122c0.008-0.052,0.023-0.151-0.061-0.151s-0.131,0.067-0.162,0.083
               C133.366,38.73,133.274,38.724,133.274,38.724z M132.833,38.837c0,0.023-0.029,0.046,0.023,0.069
               c0.054,0.03,0.045,0.053,0.076,0.076c0.022,0.022,0.105-0.016,0.076-0.076c-0.039-0.069-0.054-0.138-0.1-0.138
               C132.865,38.769,132.833,38.837,132.833,38.837z M132.683,39.097c0,0-0.168,0.115-0.068,0.129
               c0.091,0.008,0.113-0.045,0.152-0.045c0.037,0.007,0.092-0.16,0.016-0.16C132.704,39.021,132.683,39.097,132.683,39.097z
                M132.583,38.267c-0.031,0.016-0.1,0.1-0.047,0.1c0.063,0,0.229-0.077,0.176-0.123
               C132.665,38.206,132.583,38.267,132.583,38.267z M132.604,38.451c0,0.022-0.006,0.234,0.039,0.174
               c0.053-0.068,0.084-0.174,0.053-0.198C132.665,38.412,132.604,38.451,132.604,38.451z M132.994,38.404
               c0,0,0.021,0.213,0.062,0.145c0.045-0.061,0.045-0.228,0-0.228C133.001,38.328,132.994,38.404,132.994,38.404z M132.926,37.948
               c-0.046,0.007-0.106,0.021-0.062,0.106c0.046,0.084,0.115,0.069,0.16,0.137c0.054,0.069,0.138,0.107,0.168,0.152
               c0.021,0.038,0.099,0.069,0.091,0.008c-0.009-0.061-0.076-0.091-0.101-0.114c-0.029-0.023-0.099-0.137-0.121-0.191
               c-0.022-0.045-0.037-0.091-0.067-0.098C132.964,37.932,132.926,37.948,132.926,37.948z M131.522,39.447
               c0.008,0.047-0.028,0.145,0.055,0.198c0.084,0.054,0.107,0.084,0.122,0.023c0.007-0.068-0.015-0.167-0.067-0.198
               C131.586,39.432,131.522,39.447,131.522,39.447z M129.575,37.514c0,0-0.152,0.107-0.092,0.212
               c0.062,0.107,0.138,0.07,0.205,0.13c0.07,0.054,0.215-0.023,0.138-0.107c-0.076-0.076-0.113-0.144-0.15-0.198
               C129.629,37.505,129.575,37.514,129.575,37.514z M132.612,36.996c-0.021,0.015-0.047,0.083-0.014,0.114
               c0.021,0.031,0.014,0.091,0.065,0.114c0.056,0.023,0.138,0.031,0.131-0.015c-0.008-0.046-0.054-0.213-0.084-0.229
               C132.683,36.965,132.612,36.996,132.612,36.996z"/>
        
               
             <path fill="#808184" d="M128.623,34.368c0.076,0.016,0.13,0.031,0.122,0.114
               c0,0.145-0.129,0.061-0.1,0.214c0.03,0.152,0.166,0.205,0.092,0.304c-0.076,0.099-0.145,0.229-0.137,0.411
               c0.008,0.183,0.084,0.229,0.084,0.351c0,0.122,0.061,0.19,0.205,0.289c0.144,0.099,0.396,0.19,0.221,0.229
               c-0.176,0.045-0.268,0.03-0.184,0.129c0.084,0.106,0.092,0.106,0.16,0.228c0.068,0.114,0.168,0.099,0.145,0.031
               c-0.021-0.061-0.158-0.328-0.021-0.244c0.046,0.03,0.066,0.054,0.091,0.076c0-0.038,0.009-0.069,0.017-0.106
               c0.007-0.024,0.016-0.054,0.021-0.084c0.03-0.13,0.055-0.221,0.176-0.275c0.062-0.03,0.16-0.105,0.235-0.197
               c0.062-0.069,0.106-0.145,0.122-0.221c0.008-0.061,0-0.115-0.008-0.167c-0.008-0.023-0.016-0.054-0.016-0.077
               c-0.054-0.023-0.168-0.076-0.259-0.144c-0.084-0.053-0.092-0.107-0.101-0.175c-0.006-0.046-0.014-0.1-0.067-0.167
               c-0.101-0.13-0.062-0.191,0.009-0.29c0.029-0.046,0.067-0.106,0.098-0.183c0.039-0.107,0.031-0.107,0.017-0.114
               c-0.03-0.015-0.077-0.038-0.114-0.115c-0.029-0.052-0.047-0.091-0.062-0.121c-0.022-0.054-0.037-0.077-0.093-0.114
               c-0.021-0.016-0.045-0.031-0.066-0.047c-0.084-0.045-0.168-0.098-0.229-0.198c-0.008-0.007-0.021-0.007-0.037,0
               c-0.01,0.008-0.023,0.023-0.039,0.032c-0.045,0.03-0.1,0.068-0.151,0.075c-0.054,0.008-0.054,0.061-0.067,0.198
               c-0.008,0.054-0.008,0.115-0.023,0.199C128.653,34.277,128.646,34.33,128.623,34.368"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M128.623,34.368c0.076,0.016,0.13,0.031,0.122,0.114c0,0.145-0.129,0.061-0.1,0.214c0.03,0.152,0.166,0.205,0.092,0.304
               c-0.076,0.099-0.145,0.229-0.137,0.411c0.008,0.183,0.084,0.229,0.084,0.351c0,0.122,0.061,0.19,0.205,0.289
               c0.144,0.099,0.396,0.19,0.221,0.229c-0.176,0.045-0.268,0.03-0.184,0.129c0.084,0.106,0.092,0.106,0.16,0.228
               c0.068,0.114,0.168,0.099,0.145,0.031c-0.021-0.061-0.158-0.328-0.021-0.244c0.046,0.03,0.066,0.054,0.091,0.076
               c0-0.038,0.009-0.069,0.017-0.106c0.007-0.024,0.016-0.054,0.021-0.084c0.03-0.13,0.055-0.221,0.176-0.275
               c0.062-0.03,0.16-0.105,0.235-0.197c0.062-0.069,0.106-0.145,0.122-0.221c0.008-0.061,0-0.115-0.008-0.167
               c-0.008-0.023-0.016-0.054-0.016-0.077c-0.054-0.023-0.168-0.076-0.259-0.144c-0.084-0.053-0.092-0.107-0.101-0.175
               c-0.006-0.046-0.014-0.1-0.067-0.167c-0.101-0.13-0.062-0.191,0.009-0.29c0.029-0.046,0.067-0.106,0.098-0.183
               c0.039-0.107,0.031-0.107,0.017-0.114c-0.03-0.015-0.077-0.038-0.114-0.115c-0.029-0.052-0.047-0.091-0.062-0.121
               c-0.022-0.054-0.037-0.077-0.093-0.114c-0.021-0.016-0.045-0.031-0.066-0.047c-0.084-0.045-0.168-0.098-0.229-0.198
               c-0.008-0.007-0.021-0.007-0.037,0c-0.01,0.008-0.023,0.023-0.039,0.032c-0.045,0.03-0.1,0.068-0.151,0.075
               c-0.054,0.008-0.054,0.061-0.067,0.198c-0.008,0.054-0.008,0.115-0.023,0.199C128.653,34.277,128.646,34.33,128.623,34.368z"/>
             <path fill="#808184" d="M126.202,30.051c-0.106-0.106-0.213-0.205-0.289-0.175
               c-0.061,0.023-0.1,0.054-0.13,0.084c-0.061,0.054-0.114,0.099-0.235,0.099h-0.092c-0.16-0.008-0.351-0.008-0.481,0.061
               c-0.014,0.008-0.028,0.015-0.052,0.03c-0.16,0.084-0.297,0.16-0.533,0.069c-0.038-0.015-0.084-0.03-0.137-0.045
               c-0.008,0.075-0.022,0.205-0.061,0.266c-0.016,0.03-0.009,0.038,0.007,0.068c0.016,0.015,0.031,0.039,0.054,0.069
               c0.015,0.037,0.015,0.083,0.008,0.137c0.045-0.008,0.091-0.016,0.145,0.016c0.016,0.007,0.03,0.014,0.046,0.022
               c0.076,0.03,0.1,0.046,0.151,0.122c0.023,0.038,0.031,0.076,0.031,0.099c0,0,0.007-0.008,0.038-0.008
               c0.062-0.007,0.106-0.045,0.145-0.069c0.046-0.038,0.075-0.06,0.122-0.038c0.03,0.008,0.036,0.024,0.053,0.047
               c0.016,0.015,0.031,0.03,0.084,0.037c0.046,0,0.099,0.023,0.152,0.046c0.062,0.022,0.13,0.045,0.183,0.038
               c0.021-0.007,0.016-0.038,0.016-0.068c0-0.062-0.008-0.122,0.046-0.176c0.038-0.03,0.083-0.045,0.122-0.06
               c0.037-0.008,0.075-0.024,0.075-0.084c-0.007-0.084-0.016-0.152,0-0.206c0.008-0.075,0.046-0.122,0.122-0.122
               c0.038-0.007,0.084-0.023,0.13-0.045c0.046-0.024,0.099-0.053,0.136-0.091C126.103,30.128,126.164,30.082,126.202,30.051"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M126.202,30.051c-0.106-0.106-0.213-0.205-0.289-0.175c-0.061,0.023-0.1,0.054-0.13,0.084c-0.061,0.054-0.114,0.099-0.235,0.099
               h-0.092c-0.16-0.008-0.351-0.008-0.481,0.061c-0.014,0.008-0.028,0.015-0.052,0.03c-0.16,0.084-0.297,0.16-0.533,0.069
               c-0.038-0.015-0.084-0.03-0.137-0.045c-0.008,0.075-0.022,0.205-0.061,0.266c-0.016,0.03-0.009,0.038,0.007,0.068
               c0.016,0.015,0.031,0.039,0.054,0.069c0.015,0.037,0.015,0.083,0.008,0.137c0.045-0.008,0.091-0.016,0.145,0.016
               c0.016,0.007,0.03,0.014,0.046,0.022c0.076,0.03,0.1,0.046,0.151,0.122c0.023,0.038,0.031,0.076,0.031,0.099
               c0,0,0.007-0.008,0.038-0.008c0.062-0.007,0.106-0.045,0.145-0.069c0.046-0.038,0.075-0.06,0.122-0.038
               c0.03,0.008,0.036,0.024,0.053,0.047c0.016,0.015,0.031,0.03,0.084,0.037c0.046,0,0.099,0.023,0.152,0.046
               c0.062,0.022,0.13,0.045,0.183,0.038c0.021-0.007,0.016-0.038,0.016-0.068c0-0.062-0.008-0.122,0.046-0.176
               c0.038-0.03,0.083-0.045,0.122-0.06c0.037-0.008,0.075-0.024,0.075-0.084c-0.007-0.084-0.016-0.152,0-0.206
               c0.008-0.075,0.046-0.122,0.122-0.122c0.038-0.007,0.084-0.023,0.13-0.045c0.046-0.024,0.099-0.053,0.136-0.091
               C126.103,30.128,126.164,30.082,126.202,30.051z"/>
             <path fill="#808184" d="M131.418,34.78c0.076-0.031,0.168-0.054,0.304-0.039
               c0.391,0.039,0.436,0.016,0.512-0.029h0.008l0.015-0.008c0.106-0.054,0.281-0.145,0.41-0.023
               c0.056,0.045,0.116,0.068,0.192,0.083c0.084,0.016,0.188,0.023,0.326,0.023c0.092,0.009,0.152,0.016,0.197,0.023
               c0.077,0.008,0.092,0.008,0.152-0.03c0.045-0.039,0.084-0.046,0.113-0.054c0.031-0.007,0.045-0.014,0.045-0.069
               c0-0.09,0.023-0.197,0.063-0.25c0.017-0.023,0.029-0.039,0.046-0.054c0.016-0.014,0.037-0.022,0.061-0.022
               c0.015,0,0.039,0.008,0.054,0.015c0.024,0.015,0.06,0.022,0.101,0.03c0.045-0.045,0.099-0.076,0.183-0.091
               c0.137-0.03,0.213-0.122,0.312-0.107c0.106,0.007,0.123,0.039,0.19,0.069c0.075,0.031,0.356-0.008,0.343-0.076
               c-0.016-0.077-0.16-0.138-0.236-0.252c-0.082-0.121-0.1-0.259,0-0.305c0.092-0.037,0.168-0.052,0.139-0.174
               c-0.023-0.122-0.023-0.381,0.06-0.419c0.084-0.038,0.214-0.038,0.282-0.091c0.038-0.046,0.047-0.161,0.063-0.229
               c-0.084,0-0.168-0.007-0.235-0.03c-0.076-0.03-0.13-0.069-0.177-0.114c-0.053-0.046-0.105-0.092-0.183-0.099
               c-0.075-0.008-0.159-0.031-0.26-0.062c-0.151-0.045-0.319-0.098-0.44-0.068c-0.123,0.031-0.25,0.084-0.343,0.13
               c-0.075,0.03-0.131,0.053-0.159,0.061c-0.022,0-0.039,0.015-0.063,0.03c-0.045,0.03-0.105,0.076-0.235,0.129
               c-0.153,0.062-0.272,0.046-0.411,0.023c-0.076-0.015-0.166-0.023-0.267-0.015c-0.099,0-0.13,0.015-0.159,0.031
               c-0.055,0.015-0.092,0.03-0.329,0.007c-0.213-0.03-0.267-0.053-0.312-0.076c-0.03-0.015-0.055-0.031-0.16-0.046
               c-0.129-0.015-0.189,0-0.242,0.008c-0.055,0.007-0.092,0.007-0.152-0.008c-0.123-0.038-0.138-0.107-0.145-0.19
               c0-0.016-0.008-0.038-0.008-0.053c-0.01-0.031-0.031-0.038-0.055-0.047c-0.021-0.007-0.037-0.014-0.062-0.022
               c-0.045,0.053-0.129,0.168-0.138,0.205c0,0.031,0.009,0.062,0.031,0.107c0.021,0.046,0.053,0.107,0.105,0.182
               c0.046,0.084,0.091,0.146,0.13,0.191c0.038,0.046,0.084,0.084,0.129,0.123c0.015,0.007,0.022,0.015,0.03,0.03
               c0.023,0.038,0.023,0.076-0.008,0.121c-0.016,0.024-0.045,0.054-0.076,0.084c-0.066,0.054-0.166,0.099-0.229,0.13
               c-0.037,0.015-0.029,0.06-0.008,0.114c0.008,0.008,0.018,0.022,0.021,0.037c0.016,0.039,0.031,0.077,0.039,0.115
               c0.029,0.083,0.022,0.129,0.009,0.183c-0.009,0.03-0.017,0.068,0,0.145c0.015,0.076,0.052,0.098,0.104,0.121
               c0.045,0.016,0.094,0.039,0.146,0.084c0.092,0.084,0.092,0.137,0.092,0.213c0,0.031-0.008,0.061,0.007,0.107
               C131.356,34.673,131.396,34.741,131.418,34.78"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M131.418,34.78c0.076-0.031,0.168-0.054,0.304-0.039c0.391,0.039,0.436,0.016,0.512-0.029h0.008l0.015-0.008
               c0.106-0.054,0.281-0.145,0.41-0.023c0.056,0.045,0.116,0.068,0.192,0.083c0.084,0.016,0.188,0.023,0.326,0.023
               c0.092,0.009,0.152,0.016,0.197,0.023c0.077,0.008,0.092,0.008,0.152-0.03c0.045-0.039,0.084-0.046,0.113-0.054
               c0.031-0.007,0.045-0.014,0.045-0.069c0-0.09,0.023-0.197,0.063-0.25c0.017-0.023,0.029-0.039,0.046-0.054
               c0.016-0.014,0.037-0.022,0.061-0.022c0.015,0,0.039,0.008,0.054,0.015c0.024,0.015,0.06,0.022,0.101,0.03
               c0.045-0.045,0.099-0.076,0.183-0.091c0.137-0.03,0.213-0.122,0.312-0.107c0.106,0.007,0.123,0.039,0.19,0.069
               c0.075,0.031,0.356-0.008,0.343-0.076c-0.016-0.077-0.16-0.138-0.236-0.252c-0.082-0.121-0.1-0.259,0-0.305
               c0.092-0.037,0.168-0.052,0.139-0.174c-0.023-0.122-0.023-0.381,0.06-0.419c0.084-0.038,0.214-0.038,0.282-0.091
               c0.038-0.046,0.047-0.161,0.063-0.229c-0.084,0-0.168-0.007-0.235-0.03c-0.076-0.03-0.13-0.069-0.177-0.114
               c-0.053-0.046-0.105-0.092-0.183-0.099c-0.075-0.008-0.159-0.031-0.26-0.062c-0.151-0.045-0.319-0.098-0.44-0.068
               c-0.123,0.031-0.25,0.084-0.343,0.13c-0.075,0.03-0.131,0.053-0.159,0.061c-0.022,0-0.039,0.015-0.063,0.03
               c-0.045,0.03-0.105,0.076-0.235,0.129c-0.153,0.062-0.272,0.046-0.411,0.023c-0.076-0.015-0.166-0.023-0.267-0.015
               c-0.099,0-0.13,0.015-0.159,0.031c-0.055,0.015-0.092,0.03-0.329,0.007c-0.213-0.03-0.267-0.053-0.312-0.076
               c-0.03-0.015-0.055-0.031-0.16-0.046c-0.129-0.015-0.189,0-0.242,0.008c-0.055,0.007-0.092,0.007-0.152-0.008
               c-0.123-0.038-0.138-0.107-0.145-0.19c0-0.016-0.008-0.038-0.008-0.053c-0.01-0.031-0.031-0.038-0.055-0.047
               c-0.021-0.007-0.037-0.014-0.062-0.022c-0.045,0.053-0.129,0.168-0.138,0.205c0,0.031,0.009,0.062,0.031,0.107
               c0.021,0.046,0.053,0.107,0.105,0.182c0.046,0.084,0.091,0.146,0.13,0.191c0.038,0.046,0.084,0.084,0.129,0.123
               c0.015,0.007,0.022,0.015,0.03,0.03c0.023,0.038,0.023,0.076-0.008,0.121c-0.016,0.024-0.045,0.054-0.076,0.084
               c-0.066,0.054-0.166,0.099-0.229,0.13c-0.037,0.015-0.029,0.06-0.008,0.114c0.008,0.008,0.018,0.022,0.021,0.037
               c0.016,0.039,0.031,0.077,0.039,0.115c0.029,0.083,0.022,0.129,0.009,0.183c-0.009,0.03-0.017,0.068,0,0.145
               c0.015,0.076,0.052,0.098,0.104,0.121c0.045,0.016,0.094,0.039,0.146,0.084c0.092,0.084,0.092,0.137,0.092,0.213
               c0,0.031-0.008,0.061,0.007,0.107C131.356,34.673,131.396,34.741,131.418,34.78z"/>
             <path fill="#808184" d="M129.629,34.232c0.038,0.022,0.054,0.06-0.008,0.212
               c-0.03,0.092-0.069,0.161-0.106,0.206c-0.037,0.069-0.068,0.107-0.008,0.175c0.068,0.092,0.076,0.153,0.084,0.214
               c0.008,0.038,0.016,0.075,0.063,0.114c0.082,0.053,0.182,0.107,0.228,0.122c0.022-0.015,0.06-0.031,0.121-0.031
               c0.077,0,0.177-0.015,0.269-0.046c0.121-0.038,0.227-0.091,0.289-0.121c0.015-0.015,0.021-0.023,0.029-0.031
               c0.03-0.045,0.039-0.061,0.335-0.061c0.168,0,0.259-0.061,0.343-0.114c0.023-0.015,0.039-0.031,0.062-0.038
               c-0.03-0.046-0.069-0.129-0.091-0.228c-0.01-0.054-0.01-0.1-0.01-0.13c0-0.053,0-0.084-0.052-0.137
               c-0.038-0.03-0.077-0.053-0.116-0.069c-0.082-0.037-0.144-0.06-0.164-0.198c-0.008-0.022-0.008-0.045-0.008-0.061
               c-0.102,0.016-0.322,0.039-0.421,0.031c-0.067,0-0.114-0.008-0.151-0.015c-0.045-0.008-0.084-0.008-0.137-0.008
               c-0.031,0-0.076,0.023-0.121,0.053c-0.07,0.038-0.14,0.077-0.229,0.084C129.742,34.163,129.675,34.201,129.629,34.232"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M129.629,34.232c0.038,0.022,0.054,0.06-0.008,0.212c-0.03,0.092-0.069,0.161-0.106,0.206c-0.037,0.069-0.068,0.107-0.008,0.175
               c0.068,0.092,0.076,0.153,0.084,0.214c0.008,0.038,0.016,0.075,0.063,0.114c0.082,0.053,0.182,0.107,0.228,0.122
               c0.022-0.015,0.06-0.031,0.121-0.031c0.077,0,0.177-0.015,0.269-0.046c0.121-0.038,0.227-0.091,0.289-0.121
               c0.015-0.015,0.021-0.023,0.029-0.031c0.03-0.045,0.039-0.061,0.335-0.061c0.168,0,0.259-0.061,0.343-0.114
               c0.023-0.015,0.039-0.031,0.062-0.038c-0.03-0.046-0.069-0.129-0.091-0.228c-0.01-0.054-0.01-0.1-0.01-0.13
               c0-0.053,0-0.084-0.052-0.137c-0.038-0.03-0.077-0.053-0.116-0.069c-0.082-0.037-0.144-0.06-0.164-0.198
               c-0.008-0.022-0.008-0.045-0.008-0.061c-0.102,0.016-0.322,0.039-0.421,0.031c-0.067,0-0.114-0.008-0.151-0.015
               c-0.045-0.008-0.084-0.008-0.137-0.008c-0.031,0-0.076,0.023-0.121,0.053c-0.07,0.038-0.14,0.077-0.229,0.084
               C129.742,34.163,129.675,34.201,129.629,34.232z"/>
             <path fill="#808184" d="M129.058,33.637c0,0.008,0.008,0.016,0.008,0.023
               c0.045,0.068,0.121,0.115,0.19,0.16c0.029,0.015,0.054,0.03,0.076,0.046c0.083,0.053,0.099,0.083,0.129,0.152
               c0.007,0.031,0.03,0.068,0.062,0.115c0,0.015,0.008,0.022,0.015,0.029c0.055-0.029,0.161-0.098,0.282-0.106
               c0.068-0.007,0.131-0.046,0.183-0.076c0.062-0.031,0.116-0.061,0.177-0.061c0.029-0.008,0.053,0,0.082,0
               c0.016-0.076,0.038-0.221,0.038-0.289h-0.028c-0.056-0.009-0.115-0.016-0.146-0.076c-0.021-0.039-0.038-0.061-0.061-0.077
               c-0.018-0.022-0.054-0.038-0.1-0.053c-0.038-0.023-0.076-0.046-0.122-0.068c-0.067-0.031-0.139-0.069-0.167-0.069
               c-0.008,0-0.017,0.008-0.039,0.031c-0.053,0.06-0.145,0.159-0.373,0.159C129.05,33.485,129.05,33.607,129.058,33.637"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M129.058,33.637c0,0.008,0.008,0.016,0.008,0.023c0.045,0.068,0.121,0.115,0.19,0.16c0.029,0.015,0.054,0.03,0.076,0.046
               c0.083,0.053,0.099,0.083,0.129,0.152c0.007,0.031,0.03,0.068,0.062,0.115c0,0.015,0.008,0.022,0.015,0.029
               c0.055-0.029,0.161-0.098,0.282-0.106c0.068-0.007,0.131-0.046,0.183-0.076c0.062-0.031,0.116-0.061,0.177-0.061
               c0.029-0.008,0.053,0,0.082,0c0.016-0.076,0.038-0.221,0.038-0.289h-0.028c-0.056-0.009-0.115-0.016-0.146-0.076
               c-0.021-0.039-0.038-0.061-0.061-0.077c-0.018-0.022-0.054-0.038-0.1-0.053c-0.038-0.023-0.076-0.046-0.122-0.068
               c-0.067-0.031-0.139-0.069-0.167-0.069c-0.008,0-0.017,0.008-0.039,0.031c-0.053,0.06-0.145,0.159-0.373,0.159
               C129.05,33.485,129.05,33.607,129.058,33.637z"/>
             <path fill="#808184" d="M127.938,33.797c0.084,0.03,0.166,0.053,0.213,0.16
               c0.076,0.175,0.16,0.275,0.305,0.35c0.021,0.015,0.038,0.023,0.061,0.031c0.023-0.016,0.039-0.054,0.047-0.145
               c0.006-0.076,0.016-0.137,0.022-0.191c0.015-0.198,0.022-0.274,0.151-0.289c0.031-0.008,0.068-0.038,0.105-0.061
               c0.016-0.007,0.031-0.022,0.047-0.031c0.022-0.014,0.045-0.022,0.069-0.03c0.007-0.084,0.06-0.205,0.304-0.213
               c0.182-0.007,0.25-0.083,0.305-0.13c0.037-0.037,0.062-0.068,0.113-0.068c0.055,0.008,0.131,0.046,0.207,0.092
               c0.037,0.015,0.084,0.038,0.121,0.053c0.053,0.023,0.092,0.046,0.12,0.069c0.032,0.029,0.056,0.061,0.084,0.113
               c0.009,0.016,0.039,0.016,0.062,0.023c0.068,0.008,0.123,0.016,0.123,0.1c0,0.068-0.022,0.22-0.039,0.297
               c0.031,0.007,0.063,0.007,0.106,0.015c0.106,0,0.335-0.023,0.429-0.039v-0.015c0.006-0.038,0.015-0.068-0.009-0.129
               c-0.009-0.038-0.024-0.068-0.038-0.099c0-0.015-0.009-0.03-0.017-0.046c-0.046-0.114-0.062-0.198,0.063-0.251
               c0.051-0.023,0.135-0.061,0.196-0.115c0.03-0.014,0.052-0.037,0.06-0.053c0.009-0.007,0.009-0.015,0.009-0.015l-0.009-0.007
               c-0.053-0.039-0.099-0.084-0.144-0.13c-0.047-0.053-0.092-0.121-0.146-0.205c-0.045-0.077-0.084-0.145-0.106-0.198
               c-0.028-0.054-0.037-0.107-0.037-0.153c0.009-0.068,0.106-0.198,0.159-0.266c-0.038-0.038-0.061-0.084-0.066-0.137
               c-0.024-0.069-0.024-0.145-0.01-0.19c0.01-0.016,0.01-0.046,0-0.076c0-0.008-0.007-0.023-0.016-0.023v-0.008
               c-0.008,0-0.008,0.008-0.021,0.008c-0.017,0.015-0.037,0.03-0.054,0.045c-0.106,0.091-0.235,0.206-0.487,0.031
               c-0.166-0.115-0.229-0.205-0.283-0.282c-0.035-0.046-0.06-0.091-0.112-0.114c-0.084-0.046-0.099-0.084-0.13-0.137
               c-0.021-0.046-0.053-0.107-0.159-0.199c-0.114-0.106-0.146-0.212-0.168-0.296c-0.015-0.077-0.037-0.137-0.113-0.168
               c-0.03-0.007-0.054-0.023-0.084-0.038c-0.176-0.069-0.39-0.16-0.632-0.098c-0.107,0.029-0.221,0.068-0.327,0.106
               c-0.03,0.015-0.062,0.023-0.092,0.038c0.03,0.099,0.099,0.327,0.106,0.442c0,0.037,0.029,0.045,0.061,0.052
               c0.047,0.008,0.092,0.016,0.115,0.077c0.021,0.061-0.008,0.099-0.039,0.137c-0.016,0.015-0.029,0.038-0.029,0.068
               c0.007,0.054,0.061,0.069,0.137,0.1h0.008c0.068,0.023,0.084,0.068,0.062,0.121c-0.008,0.046-0.046,0.092-0.084,0.145
               c-0.03,0.046-0.067,0.091-0.084,0.13c-0.015,0.045-0.015,0.075,0,0.098c0.017,0.023,0.054,0.046,0.114,0.054
               c0.152,0.038,0.13,0.175,0.113,0.252c-0.007,0.007-0.007,0.014-0.007,0.022v0.007c-0.008,0.016-0.016,0.031-0.024,0.039
               c-0.006,0.022-0.028,0.053,0,0.075c0.107,0.069,0.093,0.13,0.078,0.207c-0.008,0.014-0.008,0.03-0.008,0.045
               c-0.017,0.106-0.107,0.091-0.197,0.084h-0.063c-0.016,0-0.016,0.03-0.031,0.084c0,0.03-0.007,0.061-0.014,0.083
               c-0.023,0.122-0.063,0.107-0.13,0.092c-0.031-0.008-0.068-0.023-0.13-0.008c-0.053,0.015-0.045,0.053-0.045,0.091
               c0.007,0.054,0.015,0.115-0.023,0.168c-0.008,0.015-0.021,0.03-0.031,0.038c-0.029,0.045-0.045,0.061-0.021,0.221
               C127.953,33.684,127.946,33.745,127.938,33.797"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M127.938,33.797c0.084,0.03,0.166,0.053,0.213,0.16c0.076,0.175,0.16,0.275,0.305,0.35c0.021,0.015,0.038,0.023,0.061,0.031
               c0.023-0.016,0.039-0.054,0.047-0.145c0.006-0.076,0.016-0.137,0.022-0.191c0.015-0.198,0.022-0.274,0.151-0.289
               c0.031-0.008,0.068-0.038,0.105-0.061c0.016-0.007,0.031-0.022,0.047-0.031c0.022-0.014,0.045-0.022,0.069-0.03
               c0.007-0.084,0.06-0.205,0.304-0.213c0.182-0.007,0.25-0.083,0.305-0.13c0.037-0.037,0.062-0.068,0.113-0.068
               c0.055,0.008,0.131,0.046,0.207,0.092c0.037,0.015,0.084,0.038,0.121,0.053c0.053,0.023,0.092,0.046,0.12,0.069
               c0.032,0.029,0.056,0.061,0.084,0.113c0.009,0.016,0.039,0.016,0.062,0.023c0.068,0.008,0.123,0.016,0.123,0.1
               c0,0.068-0.022,0.22-0.039,0.297c0.031,0.007,0.063,0.007,0.106,0.015c0.106,0,0.335-0.023,0.429-0.039v-0.015
               c0.006-0.038,0.015-0.068-0.009-0.129c-0.009-0.038-0.024-0.068-0.038-0.099c0-0.015-0.009-0.03-0.017-0.046
               c-0.046-0.114-0.062-0.198,0.063-0.251c0.051-0.023,0.135-0.061,0.196-0.115c0.03-0.014,0.052-0.037,0.06-0.053
               c0.009-0.007,0.009-0.015,0.009-0.015l-0.009-0.007c-0.053-0.039-0.099-0.084-0.144-0.13c-0.047-0.053-0.092-0.121-0.146-0.205
               c-0.045-0.077-0.084-0.145-0.106-0.198c-0.028-0.054-0.037-0.107-0.037-0.153c0.009-0.068,0.106-0.198,0.159-0.266
               c-0.038-0.038-0.061-0.084-0.066-0.137c-0.024-0.069-0.024-0.145-0.01-0.19c0.01-0.016,0.01-0.046,0-0.076
               c0-0.008-0.007-0.023-0.016-0.023v-0.008c-0.008,0-0.008,0.008-0.021,0.008c-0.017,0.015-0.037,0.03-0.054,0.045
               c-0.106,0.091-0.235,0.206-0.487,0.031c-0.166-0.115-0.229-0.205-0.283-0.282c-0.035-0.046-0.06-0.091-0.112-0.114
               c-0.084-0.046-0.099-0.084-0.13-0.137c-0.021-0.046-0.053-0.107-0.159-0.199c-0.114-0.106-0.146-0.212-0.168-0.296
               c-0.015-0.077-0.037-0.137-0.113-0.168c-0.03-0.007-0.054-0.023-0.084-0.038c-0.176-0.069-0.39-0.16-0.632-0.098
               c-0.107,0.029-0.221,0.068-0.327,0.106c-0.03,0.015-0.062,0.023-0.092,0.038c0.03,0.099,0.099,0.327,0.106,0.442
               c0,0.037,0.029,0.045,0.061,0.052c0.047,0.008,0.092,0.016,0.115,0.077c0.021,0.061-0.008,0.099-0.039,0.137
               c-0.016,0.015-0.029,0.038-0.029,0.068c0.007,0.054,0.061,0.069,0.137,0.1h0.008c0.068,0.023,0.084,0.068,0.062,0.121
               c-0.008,0.046-0.046,0.092-0.084,0.145c-0.03,0.046-0.067,0.091-0.084,0.13c-0.015,0.045-0.015,0.075,0,0.098
               c0.017,0.023,0.054,0.046,0.114,0.054c0.152,0.038,0.13,0.175,0.113,0.252c-0.007,0.007-0.007,0.014-0.007,0.022v0.007
               c-0.008,0.016-0.016,0.031-0.024,0.039c-0.006,0.022-0.028,0.053,0,0.075c0.107,0.069,0.093,0.13,0.078,0.207
               c-0.008,0.014-0.008,0.03-0.008,0.045c-0.017,0.106-0.107,0.091-0.197,0.084h-0.063c-0.016,0-0.016,0.03-0.031,0.084
               c0,0.03-0.007,0.061-0.014,0.083c-0.023,0.122-0.063,0.107-0.13,0.092c-0.031-0.008-0.068-0.023-0.13-0.008
               c-0.053,0.015-0.045,0.053-0.045,0.091c0.007,0.054,0.015,0.115-0.023,0.168c-0.008,0.015-0.021,0.03-0.031,0.038
               c-0.029,0.045-0.045,0.061-0.021,0.221C127.953,33.684,127.946,33.745,127.938,33.797z"/>
             <path fill="#808184" d="M127.108,33.371c0.008,0.007,0.021,0.016,0.037,0.03
               c0.076,0.038,0.184,0.099,0.336,0.153c0.16,0.06,0.168,0.099,0.297,0.183c0.021,0.007,0.037,0.023,0.062,0.03
               c0.007-0.046,0.015-0.091,0.007-0.146c-0.021-0.205,0-0.227,0.055-0.296c0.007-0.015,0.015-0.022,0.022-0.038
               c0.015-0.022,0.015-0.053,0.007-0.091c-0.007-0.084-0.022-0.168,0.114-0.205c0.093-0.024,0.151-0.008,0.19,0.007v-0.015
               c0.007-0.031,0.015-0.053,0.015-0.076c0.017-0.107,0.03-0.175,0.129-0.175c0.024,0,0.047,0.007,0.069,0.007
               c0.046,0,0.084,0.007,0.084,0c0.008-0.023,0.008-0.038,0.016-0.053c0.008-0.038,0.016-0.069-0.031-0.1
               c-0.113-0.068-0.061-0.152-0.029-0.205c0-0.008,0-0.008,0.008-0.015h-0.008c0.008,0,0.008-0.007,0.008-0.016l0.008-0.015
               c0.008-0.046,0.016-0.114-0.039-0.13c-0.084-0.014-0.145-0.045-0.175-0.098c-0.038-0.046-0.038-0.114-0.009-0.191
               c0.023-0.052,0.063-0.106,0.093-0.151c0.03-0.046,0.062-0.084,0.076-0.108h-0.007c-0.069-0.022-0.123-0.037-0.153-0.075
               c-0.062,0.022-0.205,0.068-0.281,0.03c-0.075-0.038-0.146-0.122-0.19-0.182c-0.016-0.016-0.021-0.031-0.037-0.046
               c-0.008-0.009-0.146,0.015-0.268,0.046c-0.053,0.014-0.105,0.029-0.16,0.037h-0.008c-0.074,0.016-0.105,0.016-0.137,0.008
               c-0.031-0.008-0.046-0.016-0.068-0.038c-0.008-0.007-0.021-0.023-0.046-0.038c-0.053-0.038-0.114-0.038-0.183-0.038
               c-0.047,0-0.092,0-0.145-0.008c-0.068-0.015-0.123-0.03-0.177-0.038c-0.053-0.007-0.106-0.007-0.166-0.015
               c-0.046,0-0.062,0.03-0.084,0.068c-0.031,0.046-0.062,0.099-0.123,0.13c-0.099,0.045-0.144,0.007-0.183-0.068
               c0-0.016-0.007-0.031-0.015-0.038c-0.008-0.031-0.023-0.069-0.046-0.092c-0.022,0.023-0.046,0.084-0.068,0.137
               c-0.016,0.038-0.03,0.077-0.045,0.106c-0.023,0.039-0.023,0.061-0.017,0.084c0.017,0.023,0.054,0.054,0.1,0.084
               c0.053,0.037,0.099,0.099,0.13,0.176c0.022,0.083,0.045,0.174,0.045,0.258c0,0.068,0.054,0.092,0.122,0.122
               c0.046,0.015,0.092,0.038,0.129,0.068c0.055,0.046,0.084,0.084,0.114,0.13v-0.008c0.031,0.045,0.062,0.092,0.129,0.137
               c0.084,0.047,0.115,0.092,0.146,0.146c0.017,0.03,0.039,0.069,0.084,0.091c0.068,0.045,0.121,0.092,0.16,0.145
               c0.045,0.046,0.076,0.107,0.106,0.167C127.146,33.211,127.131,33.295,127.108,33.371"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M127.108,33.371c0.008,0.007,0.021,0.016,0.037,0.03c0.076,0.038,0.184,0.099,0.336,0.153c0.16,0.06,0.168,0.099,0.297,0.183
               c0.021,0.007,0.037,0.023,0.062,0.03c0.007-0.046,0.015-0.091,0.007-0.146c-0.021-0.205,0-0.227,0.055-0.296
               c0.007-0.015,0.015-0.022,0.022-0.038c0.015-0.022,0.015-0.053,0.007-0.091c-0.007-0.084-0.022-0.168,0.114-0.205
               c0.093-0.024,0.151-0.008,0.19,0.007v-0.015c0.007-0.031,0.015-0.053,0.015-0.076c0.017-0.107,0.03-0.175,0.129-0.175
               c0.024,0,0.047,0.007,0.069,0.007c0.046,0,0.084,0.007,0.084,0c0.008-0.023,0.008-0.038,0.016-0.053
               c0.008-0.038,0.016-0.069-0.031-0.1c-0.113-0.068-0.061-0.152-0.029-0.205c0-0.008,0-0.008,0.008-0.015h-0.008
               c0.008,0,0.008-0.007,0.008-0.016l0.008-0.015c0.008-0.046,0.016-0.114-0.039-0.13c-0.084-0.014-0.145-0.045-0.175-0.098
               c-0.038-0.046-0.038-0.114-0.009-0.191c0.023-0.052,0.063-0.106,0.093-0.151c0.03-0.046,0.062-0.084,0.076-0.108h-0.007
               c-0.069-0.022-0.123-0.037-0.153-0.075c-0.062,0.022-0.205,0.068-0.281,0.03c-0.075-0.038-0.146-0.122-0.19-0.182
               c-0.016-0.016-0.021-0.031-0.037-0.046c-0.008-0.009-0.146,0.015-0.268,0.046c-0.053,0.014-0.105,0.029-0.16,0.037h-0.008
               c-0.074,0.016-0.105,0.016-0.137,0.008c-0.031-0.008-0.046-0.016-0.068-0.038c-0.008-0.007-0.021-0.023-0.046-0.038
               c-0.053-0.038-0.114-0.038-0.183-0.038c-0.047,0-0.092,0-0.145-0.008c-0.068-0.015-0.123-0.03-0.177-0.038
               c-0.053-0.007-0.106-0.007-0.166-0.015c-0.046,0-0.062,0.03-0.084,0.068c-0.031,0.046-0.062,0.099-0.123,0.13
               c-0.099,0.045-0.144,0.007-0.183-0.068c0-0.016-0.007-0.031-0.015-0.038c-0.008-0.031-0.023-0.069-0.046-0.092
               c-0.022,0.023-0.046,0.084-0.068,0.137c-0.016,0.038-0.03,0.077-0.045,0.106c-0.023,0.039-0.023,0.061-0.017,0.084
               c0.017,0.023,0.054,0.054,0.1,0.084c0.053,0.037,0.099,0.099,0.13,0.176c0.022,0.083,0.045,0.174,0.045,0.258
               c0,0.068,0.054,0.092,0.122,0.122c0.046,0.015,0.092,0.038,0.129,0.068c0.055,0.046,0.084,0.084,0.114,0.13v-0.008
               c0.031,0.045,0.062,0.092,0.129,0.137c0.084,0.047,0.115,0.092,0.146,0.146c0.017,0.03,0.039,0.069,0.084,0.091
               c0.068,0.045,0.121,0.092,0.16,0.145c0.045,0.046,0.076,0.107,0.106,0.167C127.146,33.211,127.131,33.295,127.108,33.371z"/>
             <path fill="#808184" d="M124.291,30.813c0.015,0.022,0.021,0.053,0.015,0.099
               c-0.029,0.205-0.16,0.243-0.099,0.38c0.069,0.129,0.023,0.236,0.168,0.313c0.145,0.076,0.235-0.267,0.327-0.161
               c0.099,0.107,0.092,0.42,0.138,0.343c0.045-0.083,0.083-0.304,0.014-0.403c-0.075-0.1-0.212-0.244-0.091-0.236
               c0.13,0.007,0.19,0.167,0.236,0.236c0.046,0.075,0.092-0.031,0.114,0.144c0.016,0.168-0.061,0.213-0.015,0.312
               c0.045,0.099,0.121,0.138,0.19,0.183c0.075,0.054-0.008,0.069,0.083,0.198c0.084,0.138,0.244,0.252,0.244,0.252
               s0.289,0.274,0.418,0.358c0.123,0.083,0.26-0.016,0.344,0.015c0.091,0.038-0.138,0.168,0.015,0.228
               c0.152,0.069,0.091-0.107,0.22-0.023c0.123,0.084-0.029,0.183,0.076,0.236c0.106,0.046-0.183,0.168,0,0.137
               c0.184-0.023,0.205-0.152,0.306-0.129h0.03c0.008-0.038,0.008-0.076-0.008-0.107c-0.022-0.053-0.054-0.106-0.092-0.152
               c-0.037-0.045-0.084-0.084-0.138-0.114c-0.062-0.046-0.091-0.084-0.121-0.13c-0.022-0.038-0.046-0.076-0.106-0.114
               c-0.084-0.046-0.123-0.107-0.16-0.159c-0.022-0.039-0.054-0.069-0.092-0.1c-0.03-0.03-0.068-0.045-0.106-0.06
               c-0.1-0.039-0.19-0.077-0.19-0.213c0-0.077-0.016-0.153-0.038-0.222c-0.022-0.053-0.053-0.106-0.091-0.129
               c-0.054-0.038-0.1-0.069-0.122-0.114c-0.03-0.054-0.038-0.107,0.008-0.183c0.015-0.031,0.03-0.061,0.044-0.099
               c0.031-0.068,0.063-0.145,0.101-0.175c0.038-0.046,0.083-0.046,0.129,0.007c0.038,0.031,0.054,0.077,0.076,0.122
               c0,0.008,0.008,0.022,0.016,0.031c0.008,0.023,0.016,0.03,0.038,0.023c0.038-0.015,0.061-0.054,0.084-0.092
               c0.038-0.061,0.068-0.121,0.168-0.114c0.067,0,0.129,0.008,0.182,0.015c0.063,0.008,0.115,0.023,0.176,0.038
               c0.046,0.008,0.092,0.008,0.13,0.008c0.084,0,0.159,0,0.243,0.053c0.022,0.016,0.039,0.031,0.054,0.046
               c0.008,0.007,0.015,0.014,0.022,0.014c0.008,0.009,0.039,0,0.1-0.007l0.008-0.007c0.046-0.008,0.1-0.015,0.152-0.03
               c0.152-0.031,0.312-0.069,0.363-0.016c0.017,0.016,0.031,0.038,0.047,0.053c0.037,0.053,0.1,0.13,0.146,0.153
               c0.045,0.022,0.146-0.007,0.197-0.031c-0.007-0.007-0.007-0.015-0.007-0.023c-0.008-0.068,0.021-0.106,0.053-0.144
               c0.008-0.015,0.023-0.023,0.016-0.031c0-0.008-0.016-0.008-0.037-0.015c-0.062-0.015-0.138-0.03-0.146-0.152
               c0-0.099-0.067-0.312-0.101-0.412c-0.121,0.046-0.252,0.077-0.379,0.084c-0.139,0.008-0.26-0.007-0.373-0.03
               c-0.152-0.037-0.274-0.099-0.344-0.145l-0.008-0.008c-0.122-0.083-0.343-0.235-0.486-0.342
               c-0.054-0.038-0.106-0.091-0.162-0.152c-0.037,0.03-0.098,0.084-0.144,0.129c-0.046,0.046-0.106,0.084-0.159,0.107
               c-0.062,0.031-0.123,0.046-0.176,0.053c-0.015,0-0.022,0.015-0.022,0.039c-0.008,0.045-0.008,0.106,0,0.182
               c0.008,0.145-0.062,0.167-0.146,0.191c-0.029,0.007-0.062,0.014-0.083,0.037c-0.016,0.016-0.016,0.054-0.016,0.092
               c0.008,0.083,0.016,0.153-0.099,0.175c-0.084,0.015-0.16-0.015-0.236-0.046c-0.046-0.015-0.084-0.037-0.122-0.037
               c-0.099-0.009-0.13-0.047-0.152-0.077c-0.007-0.007-0.015-0.015-0.022-0.015c-0.008-0.008,0,0.015-0.023,0.023
               c-0.037,0.038-0.1,0.076-0.189,0.091c-0.138,0.015-0.145-0.015-0.145-0.084c-0.008-0.015-0.008-0.03-0.016-0.053
               c-0.039-0.053-0.062-0.061-0.114-0.084c-0.009-0.008-0.022-0.015-0.045-0.03C124.337,30.805,124.313,30.805,124.291,30.813"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M124.291,30.813c0.015,0.022,0.021,0.053,0.015,0.099c-0.029,0.205-0.16,0.243-0.099,0.38c0.069,0.129,0.023,0.236,0.168,0.313
               c0.145,0.076,0.235-0.267,0.327-0.161c0.099,0.107,0.092,0.42,0.138,0.343c0.045-0.083,0.083-0.304,0.014-0.403
               c-0.075-0.1-0.212-0.244-0.091-0.236c0.13,0.007,0.19,0.167,0.236,0.236c0.046,0.075,0.092-0.031,0.114,0.144
               c0.016,0.168-0.061,0.213-0.015,0.312c0.045,0.099,0.121,0.138,0.19,0.183c0.075,0.054-0.008,0.069,0.083,0.198
               c0.084,0.138,0.244,0.252,0.244,0.252s0.289,0.274,0.418,0.358c0.123,0.083,0.26-0.016,0.344,0.015
               c0.091,0.038-0.138,0.168,0.015,0.228c0.152,0.069,0.091-0.107,0.22-0.023c0.123,0.084-0.029,0.183,0.076,0.236
               c0.106,0.046-0.183,0.168,0,0.137c0.184-0.023,0.205-0.152,0.306-0.129h0.03c0.008-0.038,0.008-0.076-0.008-0.107
               c-0.022-0.053-0.054-0.106-0.092-0.152c-0.037-0.045-0.084-0.084-0.138-0.114c-0.062-0.046-0.091-0.084-0.121-0.13
               c-0.022-0.038-0.046-0.076-0.106-0.114c-0.084-0.046-0.123-0.107-0.16-0.159c-0.022-0.039-0.054-0.069-0.092-0.1
               c-0.03-0.03-0.068-0.045-0.106-0.06c-0.1-0.039-0.19-0.077-0.19-0.213c0-0.077-0.016-0.153-0.038-0.222
               c-0.022-0.053-0.053-0.106-0.091-0.129c-0.054-0.038-0.1-0.069-0.122-0.114c-0.03-0.054-0.038-0.107,0.008-0.183
               c0.015-0.031,0.03-0.061,0.044-0.099c0.031-0.068,0.063-0.145,0.101-0.175c0.038-0.046,0.083-0.046,0.129,0.007
               c0.038,0.031,0.054,0.077,0.076,0.122c0,0.008,0.008,0.022,0.016,0.031c0.008,0.023,0.016,0.03,0.038,0.023
               c0.038-0.015,0.061-0.054,0.084-0.092c0.038-0.061,0.068-0.121,0.168-0.114c0.067,0,0.129,0.008,0.182,0.015
               c0.063,0.008,0.115,0.023,0.176,0.038c0.046,0.008,0.092,0.008,0.13,0.008c0.084,0,0.159,0,0.243,0.053
               c0.022,0.016,0.039,0.031,0.054,0.046c0.008,0.007,0.015,0.014,0.022,0.014c0.008,0.009,0.039,0,0.1-0.007l0.008-0.007
               c0.046-0.008,0.1-0.015,0.152-0.03c0.152-0.031,0.312-0.069,0.363-0.016c0.017,0.016,0.031,0.038,0.047,0.053
               c0.037,0.053,0.1,0.13,0.146,0.153c0.045,0.022,0.146-0.007,0.197-0.031c-0.007-0.007-0.007-0.015-0.007-0.023
               c-0.008-0.068,0.021-0.106,0.053-0.144c0.008-0.015,0.023-0.023,0.016-0.031c0-0.008-0.016-0.008-0.037-0.015
               c-0.062-0.015-0.138-0.03-0.146-0.152c0-0.099-0.067-0.312-0.101-0.412c-0.121,0.046-0.252,0.077-0.379,0.084
               c-0.139,0.008-0.26-0.007-0.373-0.03c-0.152-0.037-0.274-0.099-0.344-0.145l-0.008-0.008c-0.122-0.083-0.343-0.235-0.486-0.342
               c-0.054-0.038-0.106-0.091-0.162-0.152c-0.037,0.03-0.098,0.084-0.144,0.129c-0.046,0.046-0.106,0.084-0.159,0.107
               c-0.062,0.031-0.123,0.046-0.176,0.053c-0.015,0-0.022,0.015-0.022,0.039c-0.008,0.045-0.008,0.106,0,0.182
               c0.008,0.145-0.062,0.167-0.146,0.191c-0.029,0.007-0.062,0.014-0.083,0.037c-0.016,0.016-0.016,0.054-0.016,0.092
               c0.008,0.083,0.016,0.153-0.099,0.175c-0.084,0.015-0.16-0.015-0.236-0.046c-0.046-0.015-0.084-0.037-0.122-0.037
               c-0.099-0.009-0.13-0.047-0.152-0.077c-0.007-0.007-0.015-0.015-0.022-0.015c-0.008-0.008,0,0.015-0.023,0.023
               c-0.037,0.038-0.1,0.076-0.189,0.091c-0.138,0.015-0.145-0.015-0.145-0.084c-0.008-0.015-0.008-0.03-0.016-0.053
               c-0.039-0.053-0.062-0.061-0.114-0.084c-0.009-0.008-0.022-0.015-0.045-0.03C124.337,30.805,124.313,30.805,124.291,30.813z"/>
             
             <path fill="#808184" d="M129.149,30.523l0.061,0.023c0.03,0.016,0.061,0.023,0.084,0.039
               c0.121,0.045,0.145,0.128,0.174,0.228c0.023,0.076,0.047,0.167,0.139,0.251c0.121,0.106,0.152,0.175,0.183,0.229
               c0.017,0.038,0.03,0.06,0.084,0.091c0.077,0.037,0.106,0.091,0.151,0.152s0.104,0.145,0.252,0.251
               c0.189,0.13,0.289,0.046,0.365-0.022c0.021-0.016,0.045-0.039,0.068-0.054c0.029-0.015,0.053-0.022,0.075-0.022
               c0.039,0,0.062,0.015,0.084,0.037c0.017,0.015,0.024,0.039,0.03,0.069c0.016,0.045,0.009,0.099,0,0.129
               c-0.006,0.031-0.006,0.084,0.009,0.138c0.007,0.038,0.03,0.084,0.06,0.114c0.024,0.022,0.055,0.03,0.076,0.045
               c0.055,0.016,0.1,0.031,0.123,0.114c0,0.024,0.008,0.046,0.008,0.061c0.008,0.054,0.016,0.091,0.075,0.106
               c0.039,0.017,0.067,0.009,0.106,0c0.053-0.007,0.129-0.015,0.266,0c0.131,0.017,0.161,0.032,0.197,0.055
               c0.039,0.014,0.084,0.037,0.274,0.06c0.214,0.031,0.244,0.015,0.282,0c0.039-0.015,0.075-0.031,0.189-0.038
               c0.115-0.008,0.207,0.007,0.291,0.023c0.121,0.015,0.228,0.039,0.35-0.016c0.122-0.052,0.184-0.091,0.221-0.121
               c0.038-0.023,0.061-0.038,0.1-0.046c0.022-0.007,0.076-0.03,0.137-0.053c0.1-0.046,0.229-0.106,0.365-0.137
               c0.146-0.038,0.326,0.015,0.494,0.068c0.092,0.031,0.176,0.054,0.229,0.062c0.121,0.007,0.183,0.06,0.25,0.121
               c0.039,0.039,0.083,0.077,0.138,0.091c0.069,0.023,0.168,0.031,0.244,0.031c0.021-0.076,0.021-0.221,0.021-0.365
               c0-0.183,0.047-0.267,0.115-0.32c0.061-0.061-0.068-0.351,0.015-0.389c0.084-0.038,0.092,0.031,0.153,0.084
               c0.068,0.045,0.15,0.045,0.234,0.015c0.092-0.03,0.122-0.045,0.139-0.113c0.016-0.039-0.022-0.191-0.031-0.336
               c-0.068-0.03-0.168-0.053-0.297-0.03c-0.129,0.022-0.198,0.061-0.244,0.091c-0.074,0.045-0.122,0.076-0.236,0.03
               c-0.12-0.045-0.236-0.166-0.318-0.258c-0.045-0.053-0.082-0.092-0.1-0.099c-0.053-0.023-0.075-0.084-0.083-0.168
               s0-0.182,0.022-0.266c0.008-0.038,0.015-0.076,0.029-0.107c0.03-0.114,0.063-0.221,0.01-0.32
               c-0.039-0.075-0.092-0.175-0.146-0.274c-0.055-0.091-0.113-0.191-0.152-0.266c-0.045-0.084-0.092-0.122-0.16-0.175
               c-0.045-0.039-0.105-0.092-0.174-0.167c-0.068-0.077-0.131-0.168-0.19-0.26c-0.091-0.137-0.175-0.258-0.235-0.289
               c-0.054-0.015-0.138-0.015-0.222,0c-0.083,0.015-0.168,0.046-0.214,0.091c-0.016,0.015-0.029,0.038-0.037,0.054
               c-0.045,0.068-0.061,0.099-0.297,0.068c-0.237-0.03-0.436,0.099-0.51,0.145c-0.01,0.007-0.017,0.007-0.024,0.015
               c-0.052,0.03-0.128-0.015-0.234-0.061c-0.084-0.038-0.183-0.091-0.267-0.107c-0.1-0.015-0.161,0.008-0.213,0.031
               c-0.047,0.015-0.092,0.038-0.152,0.038c-0.077,0.007-0.236-0.076-0.411-0.107c-0.106-0.022-0.215,0-0.313-0.007
               c-0.051,0.061-0.228,0.251-0.357,0.259c-0.061,0.007-0.083,0.053-0.121,0.121c-0.029,0.046-0.063,0.099-0.105,0.153
               c-0.063,0.068-0.123,0.16-0.176,0.258c-0.056,0.099-0.105,0.206-0.137,0.305c-0.047,0.152-0.092,0.183-0.13,0.213
               c-0.008,0.008-0.022,0.016-0.03,0.031c-0.029,0.053-0.084,0.114-0.176,0.16c-0.068,0.038-0.145,0.06-0.244,0.069
               C129.256,30.356,129.179,30.462,129.149,30.523"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M129.149,30.523l0.061,0.023c0.03,0.016,0.061,0.023,0.084,0.039c0.121,0.045,0.145,0.128,0.174,0.228
               c0.023,0.076,0.047,0.167,0.139,0.251c0.121,0.106,0.152,0.175,0.183,0.229c0.017,0.038,0.03,0.06,0.084,0.091
               c0.077,0.037,0.106,0.091,0.151,0.152s0.104,0.145,0.252,0.251c0.189,0.13,0.289,0.046,0.365-0.022
               c0.021-0.016,0.045-0.039,0.068-0.054c0.029-0.015,0.053-0.022,0.075-0.022c0.039,0,0.062,0.015,0.084,0.037
               c0.017,0.015,0.024,0.039,0.03,0.069c0.016,0.045,0.009,0.099,0,0.129c-0.006,0.031-0.006,0.084,0.009,0.138
               c0.007,0.038,0.03,0.084,0.06,0.114c0.024,0.022,0.055,0.03,0.076,0.045c0.055,0.016,0.1,0.031,0.123,0.114
               c0,0.024,0.008,0.046,0.008,0.061c0.008,0.054,0.016,0.091,0.075,0.106c0.039,0.017,0.067,0.009,0.106,0
               c0.053-0.007,0.129-0.015,0.266,0c0.131,0.017,0.161,0.032,0.197,0.055c0.039,0.014,0.084,0.037,0.274,0.06
               c0.214,0.031,0.244,0.015,0.282,0c0.039-0.015,0.075-0.031,0.189-0.038c0.115-0.008,0.207,0.007,0.291,0.023
               c0.121,0.015,0.228,0.039,0.35-0.016c0.122-0.052,0.184-0.091,0.221-0.121c0.038-0.023,0.061-0.038,0.1-0.046
               c0.022-0.007,0.076-0.03,0.137-0.053c0.1-0.046,0.229-0.106,0.365-0.137c0.146-0.038,0.326,0.015,0.494,0.068
               c0.092,0.031,0.176,0.054,0.229,0.062c0.121,0.007,0.183,0.06,0.25,0.121c0.039,0.039,0.083,0.077,0.138,0.091
               c0.069,0.023,0.168,0.031,0.244,0.031c0.021-0.076,0.021-0.221,0.021-0.365c0-0.183,0.047-0.267,0.115-0.32
               c0.061-0.061-0.068-0.351,0.015-0.389c0.084-0.038,0.092,0.031,0.153,0.084c0.068,0.045,0.15,0.045,0.234,0.015
               c0.092-0.03,0.122-0.045,0.139-0.113c0.016-0.039-0.022-0.191-0.031-0.336c-0.068-0.03-0.168-0.053-0.297-0.03
               c-0.129,0.022-0.198,0.061-0.244,0.091c-0.074,0.045-0.122,0.076-0.236,0.03c-0.12-0.045-0.236-0.166-0.318-0.258
               c-0.045-0.053-0.082-0.092-0.1-0.099c-0.053-0.023-0.075-0.084-0.083-0.168s0-0.182,0.022-0.266
               c0.008-0.038,0.015-0.076,0.029-0.107c0.03-0.114,0.063-0.221,0.01-0.32c-0.039-0.075-0.092-0.175-0.146-0.274
               c-0.055-0.091-0.113-0.191-0.152-0.266c-0.045-0.084-0.092-0.122-0.16-0.175c-0.045-0.039-0.105-0.092-0.174-0.167
               c-0.068-0.077-0.131-0.168-0.19-0.26c-0.091-0.137-0.175-0.258-0.235-0.289c-0.054-0.015-0.138-0.015-0.222,0
               c-0.083,0.015-0.168,0.046-0.214,0.091c-0.016,0.015-0.029,0.038-0.037,0.054c-0.045,0.068-0.061,0.099-0.297,0.068
               c-0.237-0.03-0.436,0.099-0.51,0.145c-0.01,0.007-0.017,0.007-0.024,0.015c-0.052,0.03-0.128-0.015-0.234-0.061
               c-0.084-0.038-0.183-0.091-0.267-0.107c-0.1-0.015-0.161,0.008-0.213,0.031c-0.047,0.015-0.092,0.038-0.152,0.038
               c-0.077,0.007-0.236-0.076-0.411-0.107c-0.106-0.022-0.215,0-0.313-0.007c-0.051,0.061-0.228,0.251-0.357,0.259
               c-0.061,0.007-0.083,0.053-0.121,0.121c-0.029,0.046-0.063,0.099-0.105,0.153c-0.063,0.068-0.123,0.16-0.176,0.258
               c-0.056,0.099-0.105,0.206-0.137,0.305c-0.047,0.152-0.092,0.183-0.13,0.213c-0.008,0.008-0.022,0.016-0.03,0.031
               c-0.029,0.053-0.084,0.114-0.176,0.16c-0.068,0.038-0.145,0.06-0.244,0.069C129.256,30.356,129.179,30.462,129.149,30.523z"/>
             <path fill="#808184" d="M130.291,28.376c-0.031-0.106,0.007-0.206,0.054-0.319
               c0.008-0.023,0.022-0.046,0.022-0.054c0.028-0.076,0.054-0.137,0.083-0.182c0.009-0.016,0.016-0.024,0.023-0.039
               c-0.07-0.023-0.168-0.06-0.229-0.098c-0.039-0.023-0.129-0.061-0.223-0.1c-0.084-0.03-0.176-0.068-0.221-0.099
               c-0.045-0.023-0.113,0.016-0.189,0.047c-0.039,0.022-0.084,0.045-0.129,0.06c-0.069,0.023-0.168,0-0.269-0.023
               c-0.112-0.023-0.25-0.053-0.312-0.007c-0.146,0.106-0.26,0.061-0.344,0.022c-0.008,0-0.015-0.007-0.021-0.007
               c-0.046-0.015-0.063-0.045-0.084-0.076c-0.03-0.039-0.076-0.092-0.189-0.107c-0.093-0.015-0.146,0.015-0.184,0.03
               c-0.063,0.031-0.1,0.054-0.176,0.016c-0.045-0.031-0.129,0.03-0.197,0.083c-0.055,0.031-0.1,0.061-0.137,0.077
               c-0.055,0.015-0.123,0.098-0.19,0.197c-0.03,0.039-0.054,0.069-0.084,0.115c-0.101,0.114-0.183,0.107-0.282,0.107
               c-0.046,0-0.099-0.008-0.152,0.007c-0.067,0.015-0.121,0.038-0.182,0.053c-0.047,0.024-0.093,0.039-0.146,0.046
               c-0.017,0.062-0.046,0.183,0.008,0.274c0.022,0.053,0.054,0.092,0.076,0.122c0.03,0.046,0.062,0.076,0.067,0.13
               c0.03,0.022,0.114,0.075,0.236,0.159c0.084,0.061,0.152,0.107,0.229,0.138c0.067,0.03,0.146,0.053,0.229,0.053
               c0.098,0,0.213-0.031,0.297-0.046c0.061-0.015,0.114-0.031,0.145-0.031c0.017,0-0.008-0.015-0.008-0.038
               c0.017-0.076,0.03-0.167,0.131-0.19c0.068-0.023,0.105-0.023,0.144-0.023c0.024,0,0.047,0,0.084-0.007
               c0.022-0.008,0.054-0.031,0.084-0.054c0.067-0.052,0.146-0.114,0.252-0.069c0.06,0.024,0.091,0.017,0.121,0.008
               c0.03-0.008,0.062-0.015,0.106-0.015c0.031,0,0.045-0.031,0.067-0.06c0.038-0.055,0.077-0.115,0.175-0.168
               c0.146-0.084,0.223-0.069,0.297-0.054h0.069c0.022,0,0.076-0.007,0.13-0.03c0.159-0.037,0.357-0.092,0.428,0.023
               c0.029,0.038,0.045,0.076,0.053,0.106c0.021,0.046,0.029,0.077,0.084,0.077C130.123,28.43,130.229,28.399,130.291,28.376"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M130.291,28.376c-0.031-0.106,0.007-0.206,0.054-0.319c0.008-0.023,0.022-0.046,0.022-0.054
               c0.028-0.076,0.054-0.137,0.083-0.182c0.009-0.016,0.016-0.024,0.023-0.039c-0.07-0.023-0.168-0.06-0.229-0.098
               c-0.039-0.023-0.129-0.061-0.223-0.1c-0.084-0.03-0.176-0.068-0.221-0.099c-0.045-0.023-0.113,0.016-0.189,0.047
               c-0.039,0.022-0.084,0.045-0.129,0.06c-0.069,0.023-0.168,0-0.269-0.023c-0.112-0.023-0.25-0.053-0.312-0.007
               c-0.146,0.106-0.26,0.061-0.344,0.022c-0.008,0-0.015-0.007-0.021-0.007c-0.046-0.015-0.063-0.045-0.084-0.076
               c-0.03-0.039-0.076-0.092-0.189-0.107c-0.093-0.015-0.146,0.015-0.184,0.03c-0.063,0.031-0.1,0.054-0.176,0.016
               c-0.045-0.031-0.129,0.03-0.197,0.083c-0.055,0.031-0.1,0.061-0.137,0.077c-0.055,0.015-0.123,0.098-0.19,0.197
               c-0.03,0.039-0.054,0.069-0.084,0.115c-0.101,0.114-0.183,0.107-0.282,0.107c-0.046,0-0.099-0.008-0.152,0.007
               c-0.067,0.015-0.121,0.038-0.182,0.053c-0.047,0.024-0.093,0.039-0.146,0.046c-0.017,0.062-0.046,0.183,0.008,0.274
               c0.022,0.053,0.054,0.092,0.076,0.122c0.03,0.046,0.062,0.076,0.067,0.13c0.03,0.022,0.114,0.075,0.236,0.159
               c0.084,0.061,0.152,0.107,0.229,0.138c0.067,0.03,0.146,0.053,0.229,0.053c0.098,0,0.213-0.031,0.297-0.046
               c0.061-0.015,0.114-0.031,0.145-0.031c0.017,0-0.008-0.015-0.008-0.038c0.017-0.076,0.03-0.167,0.131-0.19
               c0.068-0.023,0.105-0.023,0.144-0.023c0.024,0,0.047,0,0.084-0.007c0.022-0.008,0.054-0.031,0.084-0.054
               c0.067-0.052,0.146-0.114,0.252-0.069c0.06,0.024,0.091,0.017,0.121,0.008c0.03-0.008,0.062-0.015,0.106-0.015
               c0.031,0,0.045-0.031,0.067-0.06c0.038-0.055,0.077-0.115,0.175-0.168c0.146-0.084,0.223-0.069,0.297-0.054h0.069
               c0.022,0,0.076-0.007,0.13-0.03c0.159-0.037,0.357-0.092,0.428,0.023c0.029,0.038,0.045,0.076,0.053,0.106
               c0.021,0.046,0.029,0.077,0.084,0.077C130.123,28.43,130.229,28.399,130.291,28.376z"/>
             <path fill="#808184" d="M126.11,29.838c0.076,0.053,0.146,0.129,0.214,0.198
               c0.061,0.061,0.122,0.122,0.167,0.152c0.16,0.114,0.373,0.267,0.479,0.343l0.008,0.008c0.063,0.038,0.176,0.091,0.313,0.129
               c0.099,0.023,0.222,0.038,0.343,0.03c0.184-0.015,0.357-0.076,0.54-0.144c0.114-0.039,0.222-0.084,0.343-0.114
               c0.207-0.054,0.381-0.016,0.533,0.045c0.045-0.076,0.145-0.228,0.327-0.236c0.084-0.008,0.151-0.031,0.206-0.061
               c0.068-0.03,0.113-0.076,0.131-0.114c0.021-0.038,0.036-0.053,0.061-0.069c0.022-0.015,0.053-0.038,0.092-0.16
               c0.037-0.105,0.084-0.22,0.145-0.319c0.061-0.107,0.121-0.206,0.189-0.282c0.037-0.046,0.068-0.092,0.092-0.137
               c0.053-0.092,0.091-0.16,0.204-0.167c0.077-0.009,0.19-0.123,0.252-0.191c-0.045-0.015-0.091-0.038-0.144-0.069
               c-0.108-0.061-0.215-0.144-0.267-0.213c-0.063,0.023-0.19,0.069-0.298,0.069c-0.123,0-0.146-0.061-0.176-0.145
               c-0.016-0.031-0.022-0.061-0.045-0.091c-0.031-0.054-0.19-0.007-0.313,0.023c-0.067,0.021-0.123,0.037-0.159,0.037
               c-0.031,0-0.063-0.007-0.085-0.007c-0.061-0.015-0.122-0.023-0.229,0.038c-0.074,0.046-0.113,0.099-0.145,0.137
               c-0.037,0.061-0.068,0.106-0.152,0.106c-0.029,0-0.053,0.008-0.075,0.017c-0.054,0.015-0.099,0.022-0.19-0.017
               c-0.053-0.022-0.105,0.024-0.15,0.061c-0.039,0.023-0.078,0.054-0.123,0.069c-0.053,0.015-0.082,0.015-0.113,0.015
               c-0.023,0-0.053-0.007-0.114,0.015c-0.03,0.008-0.045,0.062-0.054,0.107c-0.008,0.076-0.021,0.13-0.092,0.13
               c-0.022,0-0.066,0.008-0.121,0.021c-0.092,0.015-0.206,0.046-0.319,0.046c-0.101,0-0.185-0.023-0.267-0.06
               c-0.084-0.032-0.16-0.084-0.244-0.146c-0.084-0.052-0.145-0.091-0.189-0.121c0,0.038-0.009,0.084-0.023,0.129
               c-0.016,0.069-0.053,0.13-0.113,0.152c-0.038,0.015-0.084,0.015-0.13,0.015c-0.037-0.007-0.075-0.007-0.075,0
               c0,0.016,0.015,0.023,0.029,0.039c0.016,0.016,0.031,0.023,0.038,0.038c0.031,0.046,0.031,0.084-0.045,0.144
               c-0.046,0.039-0.092,0.069-0.114,0.099c-0.016,0.024-0.023,0.054,0,0.092c0.068,0.137,0.046,0.159-0.008,0.206v0.007
               C126.217,29.716,126.149,29.793,126.11,29.838"/> 
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M126.11,29.838c0.076,0.053,0.146,0.129,0.214,0.198c0.061,0.061,0.122,0.122,0.167,0.152c0.16,0.114,0.373,0.267,0.479,0.343
               l0.008,0.008c0.063,0.038,0.176,0.091,0.313,0.129c0.099,0.023,0.222,0.038,0.343,0.03c0.184-0.015,0.357-0.076,0.54-0.144
               c0.114-0.039,0.222-0.084,0.343-0.114c0.207-0.054,0.381-0.016,0.533,0.045c0.045-0.076,0.145-0.228,0.327-0.236
               c0.084-0.008,0.151-0.031,0.206-0.061c0.068-0.03,0.113-0.076,0.131-0.114c0.021-0.038,0.036-0.053,0.061-0.069
               c0.022-0.015,0.053-0.038,0.092-0.16c0.037-0.105,0.084-0.22,0.145-0.319c0.061-0.107,0.121-0.206,0.189-0.282
               c0.037-0.046,0.068-0.092,0.092-0.137c0.053-0.092,0.091-0.16,0.204-0.167c0.077-0.009,0.19-0.123,0.252-0.191
               c-0.045-0.015-0.091-0.038-0.144-0.069c-0.108-0.061-0.215-0.144-0.267-0.213c-0.063,0.023-0.19,0.069-0.298,0.069
               c-0.123,0-0.146-0.061-0.176-0.145c-0.016-0.031-0.022-0.061-0.045-0.091c-0.031-0.054-0.19-0.007-0.313,0.023
               c-0.067,0.021-0.123,0.037-0.159,0.037c-0.031,0-0.063-0.007-0.085-0.007c-0.061-0.015-0.122-0.023-0.229,0.038
               c-0.074,0.046-0.113,0.099-0.145,0.137c-0.037,0.061-0.068,0.106-0.152,0.106c-0.029,0-0.053,0.008-0.075,0.017
               c-0.054,0.015-0.099,0.022-0.19-0.017c-0.053-0.022-0.105,0.024-0.15,0.061c-0.039,0.023-0.078,0.054-0.123,0.069
               c-0.053,0.015-0.082,0.015-0.113,0.015c-0.023,0-0.053-0.007-0.114,0.015c-0.03,0.008-0.045,0.062-0.054,0.107
               c-0.008,0.076-0.021,0.13-0.092,0.13c-0.022,0-0.066,0.008-0.121,0.021c-0.092,0.015-0.206,0.046-0.319,0.046
               c-0.101,0-0.185-0.023-0.267-0.06c-0.084-0.032-0.16-0.084-0.244-0.146c-0.084-0.052-0.145-0.091-0.189-0.121
               c0,0.038-0.009,0.084-0.023,0.129c-0.016,0.069-0.053,0.13-0.113,0.152c-0.038,0.015-0.084,0.015-0.13,0.015
               c-0.037-0.007-0.075-0.007-0.075,0c0,0.016,0.015,0.023,0.029,0.039c0.016,0.016,0.031,0.023,0.038,0.038
               c0.031,0.046,0.031,0.084-0.045,0.144c-0.046,0.039-0.092,0.069-0.114,0.099c-0.016,0.024-0.023,0.054,0,0.092
               c0.068,0.137,0.046,0.159-0.008,0.206v0.007C126.217,29.716,126.149,29.793,126.11,29.838z"/>
             <path fill="#808184" d="M121.809,29.831c0.016-0.023,0.038-0.038,0.068-0.054
               c0.062-0.03,0.122-0.03,0.183-0.037c0.054,0,0.115,0,0.153-0.038c0.091-0.099,0.129-0.092,0.259-0.077
               c0.022,0,0.053,0.008,0.084,0.008c0.129,0.015,0.213-0.015,0.289-0.046c0.022-0.015,0.045-0.023,0.076-0.03h0.008
               c0.066-0.023,0.121,0,0.159,0.046c0.038,0.03,0.062,0.083,0.076,0.137l0.008,0.022c0.015,0.039,0.03,0.069,0.068,0.091
               c0.038,0.023,0.1,0.039,0.183,0.046c0.1,0.008,0.305,0.046,0.51,0.092c0.19,0.038,0.381,0.091,0.495,0.137
               c0.19,0.068,0.313,0.007,0.45-0.068c0.007-0.009,0.022-0.016,0.053-0.031c0.152-0.084,0.358-0.076,0.524-0.076h0.093
               c0.083,0,0.121-0.031,0.167-0.068c0.038-0.039,0.084-0.077,0.16-0.108c0.053-0.015,0.099-0.015,0.145,0.009
               c0.038-0.046,0.122-0.138,0.145-0.168l0.008-0.008c0.015-0.007,0.022-0.015-0.015-0.084c-0.039-0.075-0.031-0.137,0.007-0.19
               c0.03-0.046,0.084-0.091,0.145-0.13c0.008-0.007,0.023,0.008,0.016,0c0-0.007-0.008-0.015-0.023-0.022
               c-0.03-0.031-0.076-0.061-0.061-0.13c0.016-0.098,0.092-0.091,0.184-0.091c0.029,0.008,0.067,0.008,0.091,0
               c0.022-0.007,0.039-0.046,0.045-0.091c0.017-0.054,0.023-0.123,0.03-0.168c0-0.053-0.022-0.076-0.053-0.122
               c-0.022-0.038-0.054-0.076-0.084-0.137c-0.053-0.099-0.038-0.221-0.022-0.297h-0.045c-0.068-0.007-0.16-0.023-0.274-0.045
               c-0.167-0.031-0.365-0.069-0.487-0.062c-0.129,0.016-0.19-0.022-0.252-0.061c-0.045-0.029-0.082-0.052-0.151-0.06
               c-0.076,0-0.129,0.068-0.183,0.129c-0.053,0.069-0.106,0.13-0.183,0.153c-0.084,0.022-0.152,0.022-0.229,0.015
               c-0.075-0.008-0.145-0.03-0.229-0.069c-0.067-0.023-0.251,0.084-0.426,0.198c-0.176,0.122-0.335,0.251-0.343,0.274
               c-0.009,0.023-0.009,0.061,0.015,0.122c0.016,0.053,0.046,0.114,0.092,0.182c0.076,0.108,0.068,0.153,0.022,0.183
               c-0.022,0.016-0.046,0.024-0.076,0.024c-0.015,0.007-0.038,0.007-0.053,0.015c-0.046,0.03-0.106,0.03-0.176,0.007
               c-0.053-0.022-0.121-0.061-0.205-0.114c-0.068-0.045-0.16,0.008-0.243,0.061c-0.062,0.038-0.122,0.076-0.184,0.084
               c-0.129,0.022-0.213,0.03-0.312,0.091c-0.1,0.054-0.168,0.03-0.313-0.023c-0.054-0.022-0.114-0.038-0.19-0.061
               c-0.076-0.03-0.084-0.053-0.092-0.045v0.023c0.008,0.045,0.008,0.121-0.099,0.175c-0.138,0.076-0.205,0.038-0.32-0.023
               c-0.038-0.023-0.084-0.046-0.145-0.069c-0.021,0.053-0.068,0.13-0.137,0.213c-0.015,0.015-0.015,0.023-0.015,0.031
               c0.015,0.008,0.053,0.023,0.083,0.038c0.038,0.008,0.068,0.023,0.1,0.046c0.038,0.015,0.053,0.023,0.075,0.03
               c0.047,0.015,0.084,0.03,0.153,0.107C121.672,29.777,121.763,29.815,121.809,29.831"/>  
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M121.809,29.831c0.016-0.023,0.038-0.038,0.068-0.054c0.062-0.03,0.122-0.03,0.183-0.037c0.054,0,0.115,0,0.153-0.038
               c0.091-0.099,0.129-0.092,0.259-0.077c0.022,0,0.053,0.008,0.084,0.008c0.129,0.015,0.213-0.015,0.289-0.046
               c0.022-0.015,0.045-0.023,0.076-0.03h0.008c0.066-0.023,0.121,0,0.159,0.046c0.038,0.03,0.062,0.083,0.076,0.137l0.008,0.022
               c0.015,0.039,0.03,0.069,0.068,0.091c0.038,0.023,0.1,0.039,0.183,0.046c0.1,0.008,0.305,0.046,0.51,0.092
               c0.19,0.038,0.381,0.091,0.495,0.137c0.19,0.068,0.313,0.007,0.45-0.068c0.007-0.009,0.022-0.016,0.053-0.031
               c0.152-0.084,0.358-0.076,0.524-0.076h0.093c0.083,0,0.121-0.031,0.167-0.068c0.038-0.039,0.084-0.077,0.16-0.108
               c0.053-0.015,0.099-0.015,0.145,0.009c0.038-0.046,0.122-0.138,0.145-0.168l0.008-0.008c0.015-0.007,0.022-0.015-0.015-0.084
               c-0.039-0.075-0.031-0.137,0.007-0.19c0.03-0.046,0.084-0.091,0.145-0.13c0.008-0.007,0.023,0.008,0.016,0
               c0-0.007-0.008-0.015-0.023-0.022c-0.03-0.031-0.076-0.061-0.061-0.13c0.016-0.098,0.092-0.091,0.184-0.091
               c0.029,0.008,0.067,0.008,0.091,0c0.022-0.007,0.039-0.046,0.045-0.091c0.017-0.054,0.023-0.123,0.03-0.168
               c0-0.053-0.022-0.076-0.053-0.122c-0.022-0.038-0.054-0.076-0.084-0.137c-0.053-0.099-0.038-0.221-0.022-0.297h-0.045
               c-0.068-0.007-0.16-0.023-0.274-0.045c-0.167-0.031-0.365-0.069-0.487-0.062c-0.129,0.016-0.19-0.022-0.252-0.061
               c-0.045-0.029-0.082-0.052-0.151-0.06c-0.076,0-0.129,0.068-0.183,0.129c-0.053,0.069-0.106,0.13-0.183,0.153
               c-0.084,0.022-0.152,0.022-0.229,0.015c-0.075-0.008-0.145-0.03-0.229-0.069c-0.067-0.023-0.251,0.084-0.426,0.198
               c-0.176,0.122-0.335,0.251-0.343,0.274c-0.009,0.023-0.009,0.061,0.015,0.122c0.016,0.053,0.046,0.114,0.092,0.182
               c0.076,0.108,0.068,0.153,0.022,0.183c-0.022,0.016-0.046,0.024-0.076,0.024c-0.015,0.007-0.038,0.007-0.053,0.015
               c-0.046,0.03-0.106,0.03-0.176,0.007c-0.053-0.022-0.121-0.061-0.205-0.114c-0.068-0.045-0.16,0.008-0.243,0.061
               c-0.062,0.038-0.122,0.076-0.184,0.084c-0.129,0.022-0.213,0.03-0.312,0.091c-0.1,0.054-0.168,0.03-0.313-0.023
               c-0.054-0.022-0.114-0.038-0.19-0.061c-0.076-0.03-0.084-0.053-0.092-0.045v0.023c0.008,0.045,0.008,0.121-0.099,0.175
               c-0.138,0.076-0.205,0.038-0.32-0.023c-0.038-0.023-0.084-0.046-0.145-0.069c-0.021,0.053-0.068,0.13-0.137,0.213
               c-0.015,0.015-0.015,0.023-0.015,0.031c0.015,0.008,0.053,0.023,0.083,0.038c0.038,0.008,0.068,0.023,0.1,0.046
               c0.038,0.015,0.053,0.023,0.075,0.03c0.047,0.015,0.084,0.03,0.153,0.107C121.672,29.777,121.763,29.815,121.809,29.831z"/>
           <path fill="#808184" d="M124.321,28.057c0.039-0.016,0.076-0.016,0.106-0.008l0.008,0.008
               c0.076,0.03,0.138,0.053,0.206,0.061c0.053,0.007,0.114,0,0.183-0.015c0.045-0.016,0.091-0.062,0.129-0.115
               c0.069-0.083,0.146-0.174,0.267-0.167c0.1,0,0.152,0.038,0.205,0.068c0.047,0.031,0.1,0.069,0.191,0.053
               c0.129-0.014,0.335,0.031,0.517,0.062c0.107,0.022,0.199,0.037,0.252,0.037c0.085,0.008,0.175-0.022,0.267-0.053
               c0.063-0.022,0.123-0.046,0.19-0.06c0.068-0.016,0.122-0.016,0.175-0.016c0.075,0.008,0.137,0.008,0.198-0.068
               c0.03-0.03,0.054-0.069,0.084-0.107c0.084-0.114,0.16-0.213,0.243-0.236c0.031-0.007,0.068-0.03,0.107-0.061
               c0.053-0.038,0.113-0.076,0.166-0.099c0-0.031-0.008-0.068-0.021-0.091c-0.008-0.016-0.031-0.031-0.054-0.038
               c-0.054-0.038-0.105-0.077-0.114-0.16c0-0.008-0.021,0-0.038,0c-0.062,0.008-0.121,0.015-0.19-0.031
               c-0.076-0.052-0.099-0.052-0.129-0.052h-0.037h-0.023c-0.076,0.007-0.175,0.007-0.289-0.032
               c-0.076-0.03-0.092-0.076-0.113-0.121c-0.017-0.038-0.031-0.069-0.076-0.107c-0.062-0.038-0.19,0.023-0.305,0.077
               c-0.077,0.038-0.153,0.067-0.207,0.075c-0.084,0-0.137-0.045-0.167-0.106c-0.03-0.053-0.045-0.122-0.054-0.183
               c-0.007-0.038-0.029-0.062-0.061-0.084c-0.03-0.023-0.092-0.037-0.159-0.06l-0.062-0.016c-0.145-0.03-0.297-0.068-0.366-0.16
               c-0.029-0.038-0.121-0.053-0.189-0.069c-0.046-0.007-0.084-0.015-0.106-0.03c-0.038,0.015-0.084,0.038-0.122,0.046
               c-0.038,0.007-0.091-0.008-0.137-0.046c-0.038-0.03-0.084-0.075-0.114-0.145c-0.016-0.022-0.023-0.037-0.023-0.037
               c-0.007,0.007-0.03,0.045-0.046,0.076c-0.022,0.053-0.075,0.099-0.137,0.129c-0.061,0.046-0.137,0.069-0.183,0.069
               c-0.015,0-0.03,0.007-0.046,0.022c-0.046,0.023-0.106,0.061-0.221,0.061c-0.092,0.008-0.121,0.039-0.16,0.077
               c-0.016,0.023-0.038,0.046-0.068,0.068c-0.046,0.031-0.091,0.053-0.137,0.076c-0.054,0.015-0.114,0.015-0.198,0.008
               c-0.092-0.016-0.137,0.038-0.175,0.083c-0.008,0.016-0.022,0.031-0.03,0.039c-0.054,0.045-0.084,0.038-0.13,0.03
               c-0.016-0.007-0.023-0.007-0.038-0.007c0,0.015,0.008,0.03,0.022,0.053c0.031,0.038,0.069,0.084,0.084,0.091
               c0.046,0.015,0.092,0.039,0.122,0.076c0.023,0.038,0.038,0.084,0.03,0.138c0,0.06,0.016,0.106,0.031,0.152
               c0.007,0.015,0.015,0.03,0.022,0.053c0.008,0.038,0.022,0.068,0.046,0.091c0.022,0.031,0.053,0.054,0.106,0.077
               c0.068,0.022,0.129,0.083,0.189,0.145c0.054,0.06,0.106,0.128,0.146,0.174c0.03,0.046,0.114,0.062,0.175,0.076
               c0.054,0.016,0.1,0.023,0.137,0.046C124.261,27.912,124.299,28.003,124.321,28.057"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M124.321,28.057c0.039-0.016,0.076-0.016,0.106-0.008l0.008,0.008c0.076,0.03,0.138,0.053,0.206,0.061
               c0.053,0.007,0.114,0,0.183-0.015c0.045-0.016,0.091-0.062,0.129-0.115c0.069-0.083,0.146-0.174,0.267-0.167
               c0.1,0,0.152,0.038,0.205,0.068c0.047,0.031,0.1,0.069,0.191,0.053c0.129-0.014,0.335,0.031,0.517,0.062
               c0.107,0.022,0.199,0.037,0.252,0.037c0.085,0.008,0.175-0.022,0.267-0.053c0.063-0.022,0.123-0.046,0.19-0.06
               c0.068-0.016,0.122-0.016,0.175-0.016c0.075,0.008,0.137,0.008,0.198-0.068c0.03-0.03,0.054-0.069,0.084-0.107
               c0.084-0.114,0.16-0.213,0.243-0.236c0.031-0.007,0.068-0.03,0.107-0.061c0.053-0.038,0.113-0.076,0.166-0.099
               c0-0.031-0.008-0.068-0.021-0.091c-0.008-0.016-0.031-0.031-0.054-0.038c-0.054-0.038-0.105-0.077-0.114-0.16
               c0-0.008-0.021,0-0.038,0c-0.062,0.008-0.121,0.015-0.19-0.031c-0.076-0.052-0.099-0.052-0.129-0.052h-0.037h-0.023
               c-0.076,0.007-0.175,0.007-0.289-0.032c-0.076-0.03-0.092-0.076-0.113-0.121c-0.017-0.038-0.031-0.069-0.076-0.107
               c-0.062-0.038-0.19,0.023-0.305,0.077c-0.077,0.038-0.153,0.067-0.207,0.075c-0.084,0-0.137-0.045-0.167-0.106
               c-0.03-0.053-0.045-0.122-0.054-0.183c-0.007-0.038-0.029-0.062-0.061-0.084c-0.03-0.023-0.092-0.037-0.159-0.06l-0.062-0.016
               c-0.145-0.03-0.297-0.068-0.366-0.16c-0.029-0.038-0.121-0.053-0.189-0.069c-0.046-0.007-0.084-0.015-0.106-0.03
               c-0.038,0.015-0.084,0.038-0.122,0.046c-0.038,0.007-0.091-0.008-0.137-0.046c-0.038-0.03-0.084-0.075-0.114-0.145
               c-0.016-0.022-0.023-0.037-0.023-0.037c-0.007,0.007-0.03,0.045-0.046,0.076c-0.022,0.053-0.075,0.099-0.137,0.129
               c-0.061,0.046-0.137,0.069-0.183,0.069c-0.015,0-0.03,0.007-0.046,0.022c-0.046,0.023-0.106,0.061-0.221,0.061
               c-0.092,0.008-0.121,0.039-0.16,0.077c-0.016,0.023-0.038,0.046-0.068,0.068c-0.046,0.031-0.091,0.053-0.137,0.076
               c-0.054,0.015-0.114,0.015-0.198,0.008c-0.092-0.016-0.137,0.038-0.175,0.083c-0.008,0.016-0.022,0.031-0.03,0.039
               c-0.054,0.045-0.084,0.038-0.13,0.03c-0.016-0.007-0.023-0.007-0.038-0.007c0,0.015,0.008,0.03,0.022,0.053
               c0.031,0.038,0.069,0.084,0.084,0.091c0.046,0.015,0.092,0.039,0.122,0.076c0.023,0.038,0.038,0.084,0.03,0.138
               c0,0.06,0.016,0.106,0.031,0.152c0.007,0.015,0.015,0.03,0.022,0.053c0.008,0.038,0.022,0.068,0.046,0.091
               c0.022,0.031,0.053,0.054,0.106,0.077c0.068,0.022,0.129,0.083,0.189,0.145c0.054,0.06,0.106,0.128,0.146,0.174
               c0.03,0.046,0.114,0.062,0.175,0.076c0.054,0.016,0.1,0.023,0.137,0.046C124.261,27.912,124.299,28.003,124.321,28.057z"/>
           
             <path fill="#808184" d="M128.28,22.962c-0.008,0.008-0.016,0.015-0.022,0.015
               c-0.091,0.039-0.144,0.077-0.351,0.1c-0.197,0.015-0.479-0.023-0.502-0.115c-0.022-0.091-0.084-0.167,0-0.167
               s0.297,0.129,0.205,0.023c-0.1-0.114-0.1-0.16-0.305-0.175c-0.213-0.008-0.662,0.061-0.952,0.129
               c-0.297,0.069-0.281,0.167-0.465,0.259c-0.175,0.084-0.281,0.076-0.594,0.144c-0.312,0.069-0.678,0.099-0.686,0.183
               c-0.008,0.054-0.008,0.122-0.045,0.153c0.008,0.106,0.015,0.221,0.022,0.274c0.015,0.068,0.038,0.19,0.038,0.289
               c0.009,0.069-0.008,0.137-0.03,0.176c-0.016,0.014-0.038,0.037-0.061,0.052c-0.031,0.023-0.054,0.039-0.068,0.061
               c-0.009,0.008-0.009,0.023,0.015,0.038c0.038,0.031,0.092,0.039,0.137,0.046c0.107,0.015,0.19,0.03,0.167,0.183
               c-0.015,0.068,0.017,0.152,0.047,0.22c0.03,0.084,0.061,0.161,0.053,0.237c-0.007,0.091-0.053,0.129-0.1,0.16
               c-0.007,0.007-0.015,0.015-0.015,0.023c-0.008,0,0,0.015,0.031,0.045c0.053,0.053,0.099,0.084,0.129,0.107
               c0.068,0.046,0.106,0.076,0.122,0.16c0.007,0.038,0.015,0.068,0.022,0.098c0.023,0.077,0.038,0.137,0.023,0.214
               c-0.008,0.038-0.016,0.076-0.008,0.113c0.008,0.032,0.015,0.054,0.022,0.061c0,0,0.03,0.009,0.068,0.016
               c0.084,0.015,0.19,0.039,0.251,0.107c0.046,0.061,0.176,0.091,0.305,0.122l0.068,0.015c0.077,0.022,0.146,0.045,0.19,0.076
               c0.056,0.037,0.084,0.083,0.1,0.152c0.007,0.053,0.015,0.107,0.039,0.145c0.022,0.038,0.045,0.06,0.082,0.053
               c0.031,0,0.092-0.031,0.161-0.061c0.137-0.061,0.296-0.137,0.402-0.068c0.076,0.053,0.1,0.106,0.115,0.152
               c0.015,0.023,0.021,0.046,0.061,0.061c0.091,0.03,0.184,0.03,0.251,0.03h0.022h0.03c0.062,0,0.091-0.008,0.198,0.069
               c0.029,0.023,0.074,0.015,0.113,0.015c0.084-0.008,0.138-0.015,0.152,0.084c0.008,0.038,0.037,0.061,0.076,0.084
               c0.021,0.022,0.053,0.038,0.076,0.068c0.029,0.045,0.037,0.107,0.046,0.145c0.007,0,0.022,0.007,0.028,0.015
               c0.031,0.015,0.055,0.008,0.085-0.008c0.053-0.03,0.122-0.068,0.243-0.045c0.16,0.015,0.213,0.091,0.252,0.145
               c0.016,0.022,0.029,0.037,0.037,0.037c0.008,0.008,0.023,0.008,0.031,0.016c0.061,0.022,0.145,0.061,0.242-0.016
               c0.1-0.068,0.26-0.037,0.396-0.007c0.092,0.023,0.166,0.037,0.213,0.023c0.037-0.008,0.076-0.031,0.115-0.053
               c0.099-0.046,0.19-0.092,0.279-0.039c0.047,0.023,0.131,0.054,0.215,0.092c0.1,0.038,0.196,0.075,0.242,0.106
               c0.055,0.038,0.189,0.084,0.245,0.099c0.028-0.023,0.054-0.053,0.062-0.084c0.006-0.037,0-0.091-0.033-0.152l-0.006-0.03
               c-0.062-0.145-0.062-0.145,0.1-0.335c0.055-0.069,0.204-0.168,0.365-0.274c0.152-0.1,0.32-0.198,0.42-0.29
               c0.053-0.061,0.091-0.099,0.066-0.121c-0.092-0.099-0.092-0.153-0.099-0.221c0-0.046-0.008-0.092-0.085-0.175
               c-0.037-0.054-0.084-0.092-0.129-0.13c-0.114-0.114-0.198-0.198-0.168-0.35c0.008-0.053,0.031-0.107,0.047-0.152
               c0.037-0.099,0.076-0.191-0.055-0.267c-0.113-0.076-0.234-0.167-0.305-0.244c-0.098-0.106-0.113-0.205,0.039-0.266
               c0.061-0.023,0.129-0.046,0.197-0.061c0.145-0.038,0.281-0.076,0.273-0.129c-0.008-0.046-0.045-0.1-0.092-0.167
               c-0.093-0.115-0.205-0.275-0.242-0.518c-0.039-0.191-0.069-0.336-0.106-0.442c-0.039-0.099-0.083-0.16-0.146-0.183l-0.029-0.015
               c-0.049-0.015-0.069-0.024-0.115-0.031c-0.046,0-0.122,0.007-0.281,0.023h-0.054c-0.205,0.023-0.586,0.023-0.937,0.008
               c-0.344-0.008-0.662-0.024-0.754-0.053C128.41,23.017,128.342,22.994,128.28,22.962"/>
             <path fill="#808184" d="M128.28,22.962c-0.008,0.008-0.016,0.015-0.022,0.015
               c-0.091,0.039-0.144,0.077-0.351,0.1c-0.197,0.015-0.479-0.023-0.502-0.115c-0.022-0.091-0.084-0.167,0-0.167
               s0.297,0.129,0.205,0.023c-0.1-0.114-0.1-0.16-0.305-0.175c-0.213-0.008-0.662,0.061-0.952,0.129
               c-0.297,0.069-0.281,0.167-0.465,0.259c-0.175,0.084-0.281,0.076-0.594,0.144c-0.312,0.069-0.678,0.099-0.686,0.183
               c-0.008,0.054-0.008,0.122-0.045,0.153c0.008,0.106,0.015,0.221,0.022,0.274c0.015,0.068,0.038,0.19,0.038,0.289
               c0.009,0.069-0.008,0.137-0.03,0.176c-0.016,0.014-0.038,0.037-0.061,0.052c-0.031,0.023-0.054,0.039-0.068,0.061
               c-0.009,0.008-0.009,0.023,0.015,0.038c0.038,0.031,0.092,0.039,0.137,0.046c0.107,0.015,0.19,0.03,0.167,0.183
               c-0.015,0.068,0.017,0.152,0.047,0.22c0.03,0.084,0.061,0.161,0.053,0.237c-0.007,0.091-0.053,0.129-0.1,0.16
               c-0.007,0.007-0.015,0.015-0.015,0.023c-0.008,0,0,0.015,0.031,0.045c0.053,0.053,0.099,0.084,0.129,0.107
               c0.068,0.046,0.106,0.076,0.122,0.16c0.007,0.038,0.015,0.068,0.022,0.098c0.023,0.077,0.038,0.137,0.023,0.214
               c-0.008,0.038-0.016,0.076-0.008,0.113c0.008,0.032,0.015,0.054,0.022,0.061c0,0,0.03,0.009,0.068,0.016
               c0.084,0.015,0.19,0.039,0.251,0.107c0.046,0.061,0.176,0.091,0.305,0.122l0.068,0.015c0.077,0.022,0.146,0.045,0.19,0.076
               c0.056,0.037,0.084,0.083,0.1,0.152c0.007,0.053,0.015,0.107,0.039,0.145c0.022,0.038,0.045,0.06,0.082,0.053
               c0.031,0,0.092-0.031,0.161-0.061c0.137-0.061,0.296-0.137,0.402-0.068c0.076,0.053,0.1,0.106,0.115,0.152
               c0.015,0.023,0.021,0.046,0.061,0.061c0.091,0.03,0.184,0.03,0.251,0.03h0.022h0.03c0.062,0,0.091-0.008,0.198,0.069
               c0.029,0.023,0.074,0.015,0.113,0.015c0.084-0.008,0.138-0.015,0.152,0.084c0.008,0.038,0.037,0.061,0.076,0.084
               c0.021,0.022,0.053,0.038,0.076,0.068c0.029,0.045,0.037,0.107,0.046,0.145c0.007,0,0.022,0.007,0.028,0.015
               c0.031,0.015,0.055,0.008,0.085-0.008c0.053-0.03,0.122-0.068,0.243-0.045c0.16,0.015,0.213,0.091,0.252,0.145
               c0.016,0.022,0.029,0.037,0.037,0.037c0.008,0.008,0.023,0.008,0.031,0.016c0.061,0.022,0.145,0.061,0.242-0.016
               c0.1-0.068,0.26-0.037,0.396-0.007c0.092,0.023,0.166,0.037,0.213,0.023c0.037-0.008,0.076-0.031,0.115-0.053
               c0.099-0.046,0.19-0.092,0.279-0.039c0.047,0.023,0.131,0.054,0.215,0.092c0.1,0.038,0.196,0.075,0.242,0.106
               c0.055,0.038,0.189,0.084,0.245,0.099c0.028-0.023,0.054-0.053,0.062-0.084c0.006-0.037,0-0.091-0.033-0.152l-0.006-0.03
               c-0.062-0.145-0.062-0.145,0.1-0.335c0.055-0.069,0.204-0.168,0.365-0.274c0.152-0.1,0.32-0.198,0.42-0.29
               c0.053-0.061,0.091-0.099,0.066-0.121c-0.092-0.099-0.092-0.153-0.099-0.221c0-0.046-0.008-0.092-0.085-0.175
               c-0.037-0.054-0.084-0.092-0.129-0.13c-0.114-0.114-0.198-0.198-0.168-0.35c0.008-0.053,0.031-0.107,0.047-0.152
               c0.037-0.099,0.076-0.191-0.055-0.267c-0.113-0.076-0.234-0.167-0.305-0.244c-0.098-0.106-0.113-0.205,0.039-0.266
               c0.061-0.023,0.129-0.046,0.197-0.061c0.145-0.038,0.281-0.076,0.273-0.129c-0.008-0.046-0.045-0.1-0.092-0.167
               c-0.093-0.115-0.205-0.275-0.242-0.518c-0.039-0.191-0.069-0.336-0.106-0.442c-0.039-0.099-0.083-0.16-0.146-0.183l-0.029-0.015
               c-0.049-0.015-0.069-0.024-0.115-0.031c-0.046,0-0.122,0.007-0.281,0.023h-0.054c-0.205,0.023-0.586,0.023-0.937,0.008
               c-0.344-0.008-0.662-0.024-0.754-0.053C128.41,23.017,128.342,22.994,128.28,22.962z"/>
        
             <path fill="#808184" d="M133.634,28.505c0.061,0,0.113,0.008,0.158,0.023
               c0.094,0.039,0.184,0.175,0.282,0.32c0.062,0.092,0.122,0.183,0.184,0.251c0.062,0.076,0.122,0.122,0.167,0.16
               c0.068,0.054,0.131,0.107,0.184,0.205c0.039,0.077,0.092,0.168,0.146,0.267c0.054,0.091,0.106,0.19,0.151,0.267
               c0.076,0.144,0.039,0.274,0,0.395c-0.016,0.038-0.022,0.069-0.03,0.107c-0.015,0.077-0.021,0.16-0.015,0.236
               c0,0.046,0.008,0.084,0.021,0.084c0.03,0.023,0.076,0.061,0.121,0.114l0.01,0.008c0.082-0.107,0.305-0.358,0.396-0.435
               c0.022-0.022,0.016-0.045,0-0.06c-0.029-0.038-0.054-0.076-0.062-0.137c-0.008-0.152,0.146-0.183,0.26-0.213h0.006
               c0.07-0.015,0.162,0.007,0.238,0.03c0.037,0.007,0.074,0.015,0.098,0.015c0.031,0,0.068,0,0.093-0.015
               c0.022-0.007,0.038-0.015,0.038-0.023c0.007-0.015,0.016-0.037,0.021-0.068v-0.107c0-0.023-0.008-0.053-0.021-0.076
               c-0.016-0.031-0.031-0.053-0.047-0.068c-0.008,0-0.021-0.009-0.045-0.016c-0.069-0.015-0.153-0.046-0.184-0.137
               c-0.008-0.038-0.008-0.068-0.008-0.099c-0.008-0.038-0.008-0.069-0.068-0.106c-0.063-0.046-0.129-0.092-0.19-0.145
               c-0.067-0.061-0.12-0.122-0.144-0.175c-0.031-0.068-0.016-0.13,0.008-0.198c0.016-0.046,0.029-0.092,0.029-0.146
               c-0.008-0.061-0.084-0.084-0.168-0.106c-0.053-0.016-0.113-0.039-0.168-0.069c-0.076-0.039-0.129-0.039-0.183-0.03
               c-0.054,0-0.114,0-0.196-0.016c-0.178-0.03-0.199-0.06-0.236-0.129c-0.008-0.015-0.016-0.038-0.031-0.054
               c-0.029-0.053-0.158-0.022-0.273,0c-0.037,0.008-0.076,0.016-0.113,0.016c-0.045,0.008-0.086,0.008-0.131,0.008
               c-0.061,0-0.121,0-0.183,0.03C133.71,28.438,133.671,28.467,133.634,28.505"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M133.634,28.505c0.061,0,0.113,0.008,0.158,0.023c0.094,0.039,0.184,0.175,0.282,0.32c0.062,0.092,0.122,0.183,0.184,0.251
               c0.062,0.076,0.122,0.122,0.167,0.16c0.068,0.054,0.131,0.107,0.184,0.205c0.039,0.077,0.092,0.168,0.146,0.267
               c0.054,0.091,0.106,0.19,0.151,0.267c0.076,0.144,0.039,0.274,0,0.395c-0.016,0.038-0.022,0.069-0.03,0.107
               c-0.015,0.077-0.021,0.16-0.015,0.236c0,0.046,0.008,0.084,0.021,0.084c0.03,0.023,0.076,0.061,0.121,0.114l0.01,0.008
               c0.082-0.107,0.305-0.358,0.396-0.435c0.022-0.022,0.016-0.045,0-0.06c-0.029-0.038-0.054-0.076-0.062-0.137
               c-0.008-0.152,0.146-0.183,0.26-0.213h0.006c0.07-0.015,0.162,0.007,0.238,0.03c0.037,0.007,0.074,0.015,0.098,0.015
               c0.031,0,0.068,0,0.093-0.015c0.022-0.007,0.038-0.015,0.038-0.023c0.007-0.015,0.016-0.037,0.021-0.068v-0.107
               c0-0.023-0.008-0.053-0.021-0.076c-0.016-0.031-0.031-0.053-0.047-0.068c-0.008,0-0.021-0.009-0.045-0.016
               c-0.069-0.015-0.153-0.046-0.184-0.137c-0.008-0.038-0.008-0.068-0.008-0.099c-0.008-0.038-0.008-0.069-0.068-0.106
               c-0.063-0.046-0.129-0.092-0.19-0.145c-0.067-0.061-0.12-0.122-0.144-0.175c-0.031-0.068-0.016-0.13,0.008-0.198
               c0.016-0.046,0.029-0.092,0.029-0.146c-0.008-0.061-0.084-0.084-0.168-0.106c-0.053-0.016-0.113-0.039-0.168-0.069
               c-0.076-0.039-0.129-0.039-0.183-0.03c-0.054,0-0.114,0-0.196-0.016c-0.178-0.03-0.199-0.06-0.236-0.129
               c-0.008-0.015-0.016-0.038-0.031-0.054c-0.029-0.053-0.158-0.022-0.273,0c-0.037,0.008-0.076,0.016-0.113,0.016
               c-0.045,0.008-0.086,0.008-0.131,0.008c-0.061,0-0.121,0-0.183,0.03C133.71,28.438,133.671,28.467,133.634,28.505z"/>
             <path fill="#808184" d="M131.158,25.483c-0.009,0.022-0.017,0.046-0.017,0.069
               c-0.021,0.106,0.049,0.167,0.139,0.258c0.046,0.039,0.093,0.084,0.138,0.138c0.099,0.114,0.104,0.175,0.112,0.235
               c0,0.047,0,0.092,0.07,0.16c0.067,0.077,0.021,0.168-0.078,0.26c-0.104,0.098-0.272,0.205-0.433,0.311
               c-0.151,0.099-0.297,0.191-0.344,0.244c-0.113,0.145-0.113,0.152-0.084,0.244l0.017,0.022c0.03,0.084,0.038,0.153,0.03,0.206
               c-0.015,0.061-0.047,0.107-0.092,0.145c-0.039,0.03-0.062,0.061-0.084,0.099s-0.046,0.091-0.068,0.16
               c-0.016,0.03-0.016,0.045-0.022,0.061c-0.045,0.106-0.084,0.205-0.039,0.289v0.008c0.039,0.06,0.146,0.136,0.253,0.205
               c0.252,0.144,0.356,0.023,0.678,0.137c0.099,0.031,0.197,0.061,0.243,0.061c0.045,0,0.084-0.015,0.122-0.03
               c0.069-0.031,0.138-0.061,0.267-0.039c0.1,0.015,0.205,0.069,0.297,0.115c0.076,0.038,0.139,0.06,0.139,0.06l0.021-0.007
               c0.084-0.053,0.305-0.198,0.578-0.16c0.168,0.023,0.176,0.007,0.198-0.022c0.008-0.023,0.022-0.046,0.054-0.077
               c0.045-0.046,0.129-0.084,0.213-0.107c0,0,0-0.007,0.016-0.014c0.047-0.054,0.138-0.145,0.222-0.191
               c0.083-0.038,0.151-0.038,0.228-0.038c0.039,0,0.077,0,0.123-0.007c0.03-0.008,0.068-0.016,0.105-0.024
               c0.138-0.022,0.306-0.06,0.38,0.046c0.017,0.031,0.024,0.046,0.032,0.061c0.021,0.046,0.029,0.06,0.16,0.083
               c0.074,0.016,0.129,0.008,0.174,0.008c0.076,0,0.138-0.008,0.236,0.046c0.045,0.03,0.1,0.046,0.152,0.062
               c0.122,0.037,0.229,0.068,0.234,0.197c0,0.076-0.014,0.129-0.029,0.183c-0.018,0.045-0.031,0.091-0.018,0.129
               c0.018,0.038,0.063,0.084,0.117,0.129c0.06,0.054,0.129,0.099,0.188,0.138c0.099,0.068,0.106,0.129,0.106,0.198
               c0,0.023,0,0.045,0.008,0.069c0.015,0.045,0.066,0.06,0.105,0.067c0.038,0.008,0.069,0.024,0.084,0.039
               c0.03,0.023,0.053,0.06,0.076,0.099c0.016,0.038,0.029,0.076,0.029,0.113c0.009,0.047,0.009,0.084,0,0.13
               c-0.008,0.038-0.016,0.077-0.029,0.107c-0.016,0.031-0.055,0.054-0.091,0.069c-0.038,0.014-0.084,0.022-0.132,0.022
               c-0.029,0-0.074-0.015-0.129-0.022c-0.06-0.023-0.137-0.038-0.183-0.031h-0.008c-0.076,0.023-0.183,0.045-0.175,0.114
               c0,0.023,0.015,0.054,0.029,0.077c0.054,0.061,0.084,0.106-0.016,0.198c-0.083,0.075-0.304,0.335-0.388,0.434
               c0.069,0.06,0.146,0.129,0.214,0.159c0.066,0.023,0.1,0.007,0.145-0.022c0.053-0.03,0.129-0.084,0.281-0.106
               c0.121-0.023,0.229-0.007,0.313,0.022c0-0.038,0.009-0.076,0.022-0.106c0.085-0.152,0.107-0.137,0.236-0.228
               c0.13-0.099,0.267-0.198,0.259-0.282c0-0.076-0.008-0.214,0.045-0.168c0.063,0.046,0.063,0.13,0.146,0.023
               c0.076-0.106,0.045-0.289,0.152-0.305c0.098-0.022,0.221,0.009,0.28-0.014c0.062-0.023,0.106-0.175,0.131-0.07
               c0.021,0.107-0.022,0.191,0.12,0.191c0.146,0,0.197-0.167,0.281-0.129s0.244,0.008,0.191,0.084
               c-0.055,0.068-0.207,0.045-0.222,0.091c-0.017,0.054-0.038,0.083-0.114,0.107c-0.084,0.015-0.183-0.069-0.213-0.024
               c-0.039,0.039-0.016,0.084,0.075,0.092c0.093,0.007,0.235,0.038,0.327,0.038c0.1,0.008,0.115,0.107,0.229,0.114
               c0.121,0.008,0.189-0.045,0.435-0.045s0.243-0.069,0.313,0.008c0.061,0.068,0.137,0.037,0.182,0.068
               c0.047,0.038,0.085,0.114,0.008,0.144c-0.068,0.031-0.213,0.099-0.273,0.122c-0.053,0.031-0.281,0.107-0.396,0.191
               c-0.106,0.084-0.261,0.106-0.146,0.175c0.123,0.076,0.26-0.008,0.328,0c0.076,0.007,0.184,0.121,0.273,0.167
               c0.099,0.046,0.189-0.007,0.244,0.046c0.053,0.06,0.082,0.19,0.053,0.251c-0.038,0.061-0.053,0.129-0.045,0.191
               c0.007,0.053-0.168,0.068-0.046,0.152c0.114,0.075,0.297,0.145,0.44,0.114c0.137-0.023,0.184-0.046,0.273-0.213
               c0.093-0.175,0.093-0.175,0.236-0.199c0.151-0.014,0.306,0.024,0.365-0.106c0.068-0.137,0.152-0.213,0.213-0.198
               c0.062,0.015,0.092,0.13,0.183,0.137c0.092,0,0.176-0.023,0.282-0.023s0.151-0.068,0.168-0.152
               c0.015-0.076,0.106-0.106,0.106-0.16c0-0.052-0.009-0.106-0.093-0.084c-0.084,0.023-0.09,0.008-0.205,0
               c-0.113-0.014-0.084,0.008-0.184,0.054c-0.105,0.038-0.121-0.046-0.168-0.022c-0.053,0.014-0.112,0.067-0.174,0.082
               c-0.062,0.009-0.076,0.024-0.176-0.007c-0.099-0.038-0.289-0.22-0.229-0.228c0.061-0.015,0.175,0.061,0.145,0
               c-0.039-0.054-0.214-0.213-0.273-0.29c-0.068-0.075-0.176-0.06-0.252-0.053c-0.074,0.015-0.183-0.022-0.221-0.045
               c-0.047-0.016-0.183-0.031-0.222-0.069c-0.03-0.038-0.052-0.144,0.054-0.136c0.107,0.007,0.16,0.045,0.184,0.075
               c0.029,0.038,0.047,0.076,0.146,0.084c0.093,0.015,0.183,0.023,0.222-0.015c0.045-0.046,0.067-0.077,0.129-0.107
               c0.054-0.037,0.189-0.19,0.197-0.129c0,0.06-0.046,0.129-0.092,0.19c-0.053,0.053-0.182,0.137-0.092,0.13
               c0.092,0,0.168-0.092,0.229-0.183c0.063-0.092,0.106-0.183,0.106-0.183s0.137-0.152,0.252-0.183
               c0.121-0.031,0.168-0.076,0.25-0.068c0.084,0.008,0.252-0.061,0.252-0.061s0.121,0.007,0.22-0.031
               c0.102-0.045,0.115-0.129,0.244-0.122c0.131,0.015,0.184-0.06,0.26-0.099c0.069-0.038,0.115-0.06,0.221-0.052
               c0.105,0.014,0.184-0.008,0.189-0.069c0.016-0.069-0.021-0.358,0.069-0.396c0.084-0.038,0.151-0.024,0.235-0.13
               c0.076-0.107,0.168-0.129,0.235-0.107c0.076,0.023,0.146,0.031,0.313,0.023c0.16,0,0.188-0.023,0.205-0.129
               c0.016-0.106-0.006-0.267-0.022-0.365c-0.017-0.107-0.13-0.107-0.146-0.206c-0.006-0.092,0.162-0.16,0.113-0.244
               c-0.053-0.075-0.221-0.099-0.13-0.166c0.093-0.07,0.168-0.116,0.185-0.191c0.016-0.069,0.016-0.161-0.045-0.214
               c-0.063-0.053-0.094-0.152-0.199-0.136c-0.098,0.007-0.129,0.083-0.234,0.007c-0.102-0.077-0.152-0.137-0.29-0.168
               c-0.137-0.03-0.44-0.076-0.524-0.121c-0.092-0.039-0.115-0.092-0.229-0.054c-0.114,0.039-0.101,0.061-0.243-0.03
               c-0.138-0.099-0.222-0.198-0.273-0.275c-0.055-0.068-0.198-0.022-0.26,0.008c-0.062,0.03-0.121,0.076-0.197,0.069
               c-0.068-0.015-0.106,0.045-0.236,0.007c-0.122-0.029-0.221-0.145-0.335-0.152c-0.122,0-0.183,0.061-0.235,0.061
               c-0.054-0.008-0.121-0.068-0.168-0.114c-0.046-0.046-0.062-0.229-0.098-0.358c-0.047-0.129-0.123-0.16-0.236-0.236
               c-0.113-0.068-0.031-0.13-0.223-0.091c-0.188,0.03-0.258,0.045-0.313-0.008c-0.053-0.053-0.068-0.045-0.137-0.16
               c-0.068-0.106-0.105-0.145-0.053-0.213c0.053-0.068,0.137-0.129,0.053-0.174c-0.091-0.055-0.168-0.191-0.184-0.23
               c-0.221-0.441-0.782-0.136-0.891-0.16c-0.16-0.022-0.199-0.106-0.289-0.06c-0.1,0.053-0.076,0.137-0.146,0.175
               c-0.062,0.03-0.062,0.167-0.251-0.008l-0.016-0.023c-0.039,0.016-0.076,0.031-0.123,0.039c-0.053,0.007-0.104,0.007-0.15,0.015
               c-0.114,0.007-0.206,0.007-0.305,0.091c-0.076,0.061-0.168,0.137-0.236,0.213c-0.046,0.054-0.076,0.107-0.053,0.13
               c0.045,0.052,0.074,0.152,0.082,0.22c0.009,0.031,0,0.054-0.008,0.077c-0.006,0.03-0.029,0.053-0.061,0.06
               c-0.023,0.008-0.054,0.008-0.085-0.007c-0.067-0.03-0.099-0.076-0.138-0.122c-0.027-0.046-0.059-0.084-0.135-0.076
               c-0.092,0.015-0.139,0.03-0.184,0.045c-0.047,0.016-0.092,0.023-0.169,0.039c-0.028,0-0.045,0.007-0.062,0.007
               c-0.037,0.008-0.053,0.016-0.074,0.008c-0.016-0.008-0.03-0.023-0.055-0.038c-0.016-0.016-0.037-0.031-0.068-0.054
               c-0.03-0.03-0.061-0.052-0.084-0.068c-0.045-0.039-0.061-0.053-0.092-0.031c-0.021,0.023-0.037,0.054-0.045,0.076
               c-0.037,0.077-0.068,0.123-0.183,0.069c-0.053-0.023-0.077-0.061-0.106-0.091c-0.016-0.031-0.039-0.062-0.092-0.062
               c-0.076,0-0.16,0.008-0.229,0.016c-0.061,0.015-0.122,0.031-0.167,0.054c-0.062,0.022-0.146,0.022-0.222,0.007
               c-0.084-0.015-0.16-0.053-0.189-0.099c-0.031-0.053-0.068-0.061-0.168-0.076h-0.007c-0.054-0.007-0.095-0.03-0.13-0.053
               c-0.063-0.031-0.129-0.069-0.275-0.061c-0.136,0.007-0.25,0-0.38-0.008c-0.101-0.007-0.213-0.014-0.351-0.014
               c-0.114,0-0.213-0.017-0.297-0.024c-0.137-0.015-0.235-0.031-0.289,0.015c-0.045,0.031-0.092,0.062-0.129,0.084
               c-0.063,0.031-0.106,0.061-0.152,0.107c-0.038,0.06-0.084,0.091-0.129,0.091c-0.039,0.008-0.077,0-0.131-0.023
               C131.212,25.505,131.183,25.498,131.158,25.483"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M131.158,25.483c-0.009,0.022-0.017,0.046-0.017,0.069c-0.021,0.106,0.049,0.167,0.139,0.258
               c0.046,0.039,0.093,0.084,0.138,0.138c0.099,0.114,0.104,0.175,0.112,0.235c0,0.047,0,0.092,0.07,0.16
               c0.067,0.077,0.021,0.168-0.078,0.26c-0.104,0.098-0.272,0.205-0.433,0.311c-0.151,0.099-0.297,0.191-0.344,0.244
               c-0.113,0.145-0.113,0.152-0.084,0.244l0.017,0.022c0.03,0.084,0.038,0.153,0.03,0.206c-0.015,0.061-0.047,0.107-0.092,0.145
               c-0.039,0.03-0.062,0.061-0.084,0.099s-0.046,0.091-0.068,0.16c-0.016,0.03-0.016,0.045-0.022,0.061
               c-0.045,0.106-0.084,0.205-0.039,0.289v0.008c0.039,0.06,0.146,0.136,0.253,0.205c0.252,0.144,0.356,0.023,0.678,0.137
               c0.099,0.031,0.197,0.061,0.243,0.061c0.045,0,0.084-0.015,0.122-0.03c0.069-0.031,0.138-0.061,0.267-0.039
               c0.1,0.015,0.205,0.069,0.297,0.115c0.076,0.038,0.139,0.06,0.139,0.06l0.021-0.007c0.084-0.053,0.305-0.198,0.578-0.16
               c0.168,0.023,0.176,0.007,0.198-0.022c0.008-0.023,0.022-0.046,0.054-0.077c0.045-0.046,0.129-0.084,0.213-0.107
               c0,0,0-0.007,0.016-0.014c0.047-0.054,0.138-0.145,0.222-0.191c0.083-0.038,0.151-0.038,0.228-0.038
               c0.039,0,0.077,0,0.123-0.007c0.03-0.008,0.068-0.016,0.105-0.024c0.138-0.022,0.306-0.06,0.38,0.046
               c0.017,0.031,0.024,0.046,0.032,0.061c0.021,0.046,0.029,0.06,0.16,0.083c0.074,0.016,0.129,0.008,0.174,0.008
               c0.076,0,0.138-0.008,0.236,0.046c0.045,0.03,0.1,0.046,0.152,0.062c0.122,0.037,0.229,0.068,0.234,0.197
               c0,0.076-0.014,0.129-0.029,0.183c-0.018,0.045-0.031,0.091-0.018,0.129c0.018,0.038,0.063,0.084,0.117,0.129
               c0.06,0.054,0.129,0.099,0.188,0.138c0.099,0.068,0.106,0.129,0.106,0.198c0,0.023,0,0.045,0.008,0.069
               c0.015,0.045,0.066,0.06,0.105,0.067c0.038,0.008,0.069,0.024,0.084,0.039c0.03,0.023,0.053,0.06,0.076,0.099
               c0.016,0.038,0.029,0.076,0.029,0.113c0.009,0.047,0.009,0.084,0,0.13c-0.008,0.038-0.016,0.077-0.029,0.107
               c-0.016,0.031-0.055,0.054-0.091,0.069c-0.038,0.014-0.084,0.022-0.132,0.022c-0.029,0-0.074-0.015-0.129-0.022
               c-0.06-0.023-0.137-0.038-0.183-0.031h-0.008c-0.076,0.023-0.183,0.045-0.175,0.114c0,0.023,0.015,0.054,0.029,0.077
               c0.054,0.061,0.084,0.106-0.016,0.198c-0.083,0.075-0.304,0.335-0.388,0.434c0.069,0.06,0.146,0.129,0.214,0.159
               c0.066,0.023,0.1,0.007,0.145-0.022c0.053-0.03,0.129-0.084,0.281-0.106c0.121-0.023,0.229-0.007,0.313,0.022
               c0-0.038,0.009-0.076,0.022-0.106c0.085-0.152,0.107-0.137,0.236-0.228c0.13-0.099,0.267-0.198,0.259-0.282
               c0-0.076-0.008-0.214,0.045-0.168c0.063,0.046,0.063,0.13,0.146,0.023c0.076-0.106,0.045-0.289,0.152-0.305
               c0.098-0.022,0.221,0.009,0.28-0.014c0.062-0.023,0.106-0.175,0.131-0.07c0.021,0.107-0.022,0.191,0.12,0.191
               c0.146,0,0.197-0.167,0.281-0.129s0.244,0.008,0.191,0.084c-0.055,0.068-0.207,0.045-0.222,0.091
               c-0.017,0.054-0.038,0.083-0.114,0.107c-0.084,0.015-0.183-0.069-0.213-0.024c-0.039,0.039-0.016,0.084,0.075,0.092
               c0.093,0.007,0.235,0.038,0.327,0.038c0.1,0.008,0.115,0.107,0.229,0.114c0.121,0.008,0.189-0.045,0.435-0.045
               s0.243-0.069,0.313,0.008c0.061,0.068,0.137,0.037,0.182,0.068c0.047,0.038,0.085,0.114,0.008,0.144
               c-0.068,0.031-0.213,0.099-0.273,0.122c-0.053,0.031-0.281,0.107-0.396,0.191c-0.106,0.084-0.261,0.106-0.146,0.175
               c0.123,0.076,0.26-0.008,0.328,0c0.076,0.007,0.184,0.121,0.273,0.167c0.099,0.046,0.189-0.007,0.244,0.046
               c0.053,0.06,0.082,0.19,0.053,0.251c-0.038,0.061-0.053,0.129-0.045,0.191c0.007,0.053-0.168,0.068-0.046,0.152
               c0.114,0.075,0.297,0.145,0.44,0.114c0.137-0.023,0.184-0.046,0.273-0.213c0.093-0.175,0.093-0.175,0.236-0.199
               c0.151-0.014,0.306,0.024,0.365-0.106c0.068-0.137,0.152-0.213,0.213-0.198c0.062,0.015,0.092,0.13,0.183,0.137
               c0.092,0,0.176-0.023,0.282-0.023s0.151-0.068,0.168-0.152c0.015-0.076,0.106-0.106,0.106-0.16c0-0.052-0.009-0.106-0.093-0.084
               c-0.084,0.023-0.09,0.008-0.205,0c-0.113-0.014-0.084,0.008-0.184,0.054c-0.105,0.038-0.121-0.046-0.168-0.022
               c-0.053,0.014-0.112,0.067-0.174,0.082c-0.062,0.009-0.076,0.024-0.176-0.007c-0.099-0.038-0.289-0.22-0.229-0.228
               c0.061-0.015,0.175,0.061,0.145,0c-0.039-0.054-0.214-0.213-0.273-0.29c-0.068-0.075-0.176-0.06-0.252-0.053
               c-0.074,0.015-0.183-0.022-0.221-0.045c-0.047-0.016-0.183-0.031-0.222-0.069c-0.03-0.038-0.052-0.144,0.054-0.136
               c0.107,0.007,0.16,0.045,0.184,0.075c0.029,0.038,0.047,0.076,0.146,0.084c0.093,0.015,0.183,0.023,0.222-0.015
               c0.045-0.046,0.067-0.077,0.129-0.107c0.054-0.037,0.189-0.19,0.197-0.129c0,0.06-0.046,0.129-0.092,0.19
               c-0.053,0.053-0.182,0.137-0.092,0.13c0.092,0,0.168-0.092,0.229-0.183c0.063-0.092,0.106-0.183,0.106-0.183
               s0.137-0.152,0.252-0.183c0.121-0.031,0.168-0.076,0.25-0.068c0.084,0.008,0.252-0.061,0.252-0.061s0.121,0.007,0.22-0.031
               c0.102-0.045,0.115-0.129,0.244-0.122c0.131,0.015,0.184-0.06,0.26-0.099c0.069-0.038,0.115-0.06,0.221-0.052
               c0.105,0.014,0.184-0.008,0.189-0.069c0.016-0.069-0.021-0.358,0.069-0.396c0.084-0.038,0.151-0.024,0.235-0.13
               c0.076-0.107,0.168-0.129,0.235-0.107c0.076,0.023,0.146,0.031,0.313,0.023c0.16,0,0.188-0.023,0.205-0.129
               c0.016-0.106-0.006-0.267-0.022-0.365c-0.017-0.107-0.13-0.107-0.146-0.206c-0.006-0.092,0.162-0.16,0.113-0.244
               c-0.053-0.075-0.221-0.099-0.13-0.166c0.093-0.07,0.168-0.116,0.185-0.191c0.016-0.069,0.016-0.161-0.045-0.214
               c-0.063-0.053-0.094-0.152-0.199-0.136c-0.098,0.007-0.129,0.083-0.234,0.007c-0.102-0.077-0.152-0.137-0.29-0.168
               c-0.137-0.03-0.44-0.076-0.524-0.121c-0.092-0.039-0.115-0.092-0.229-0.054c-0.114,0.039-0.101,0.061-0.243-0.03
               c-0.138-0.099-0.222-0.198-0.273-0.275c-0.055-0.068-0.198-0.022-0.26,0.008c-0.062,0.03-0.121,0.076-0.197,0.069
               c-0.068-0.015-0.106,0.045-0.236,0.007c-0.122-0.029-0.221-0.145-0.335-0.152c-0.122,0-0.183,0.061-0.235,0.061
               c-0.054-0.008-0.121-0.068-0.168-0.114c-0.046-0.046-0.062-0.229-0.098-0.358c-0.047-0.129-0.123-0.16-0.236-0.236
               c-0.113-0.068-0.031-0.13-0.223-0.091c-0.188,0.03-0.258,0.045-0.313-0.008c-0.053-0.053-0.068-0.045-0.137-0.16
               c-0.068-0.106-0.105-0.145-0.053-0.213c0.053-0.068,0.137-0.129,0.053-0.174c-0.091-0.055-0.168-0.191-0.184-0.23
               c-0.221-0.441-0.782-0.136-0.891-0.16c-0.16-0.022-0.199-0.106-0.289-0.06c-0.1,0.053-0.076,0.137-0.146,0.175
               c-0.062,0.03-0.062,0.167-0.251-0.008l-0.016-0.023c-0.039,0.016-0.076,0.031-0.123,0.039c-0.053,0.007-0.104,0.007-0.15,0.015
               c-0.114,0.007-0.206,0.007-0.305,0.091c-0.076,0.061-0.168,0.137-0.236,0.213c-0.046,0.054-0.076,0.107-0.053,0.13
               c0.045,0.052,0.074,0.152,0.082,0.22c0.009,0.031,0,0.054-0.008,0.077c-0.006,0.03-0.029,0.053-0.061,0.06
               c-0.023,0.008-0.054,0.008-0.085-0.007c-0.067-0.03-0.099-0.076-0.138-0.122c-0.027-0.046-0.059-0.084-0.135-0.076
               c-0.092,0.015-0.139,0.03-0.184,0.045c-0.047,0.016-0.092,0.023-0.169,0.039c-0.028,0-0.045,0.007-0.062,0.007
               c-0.037,0.008-0.053,0.016-0.074,0.008c-0.016-0.008-0.03-0.023-0.055-0.038c-0.016-0.016-0.037-0.031-0.068-0.054
               c-0.03-0.03-0.061-0.052-0.084-0.068c-0.045-0.039-0.061-0.053-0.092-0.031c-0.021,0.023-0.037,0.054-0.045,0.076
               c-0.037,0.077-0.068,0.123-0.183,0.069c-0.053-0.023-0.077-0.061-0.106-0.091c-0.016-0.031-0.039-0.062-0.092-0.062
               c-0.076,0-0.16,0.008-0.229,0.016c-0.061,0.015-0.122,0.031-0.167,0.054c-0.062,0.022-0.146,0.022-0.222,0.007
               c-0.084-0.015-0.16-0.053-0.189-0.099c-0.031-0.053-0.068-0.061-0.168-0.076h-0.007c-0.054-0.007-0.095-0.03-0.13-0.053
               c-0.063-0.031-0.129-0.069-0.275-0.061c-0.136,0.007-0.25,0-0.38-0.008c-0.101-0.007-0.213-0.014-0.351-0.014
               c-0.114,0-0.213-0.017-0.297-0.024c-0.137-0.015-0.235-0.031-0.289,0.015c-0.045,0.031-0.092,0.062-0.129,0.084
               c-0.063,0.031-0.106,0.061-0.152,0.107c-0.038,0.06-0.084,0.091-0.129,0.091c-0.039,0.008-0.077,0-0.131-0.023
               C131.212,25.505,131.183,25.498,131.158,25.483z"/>
             <path fill="#808184" d="M130.968,23.526c0.018,0.061,0.024,0.122,0.039,0.19
               c0.037,0.221,0.145,0.366,0.221,0.473c0.063,0.084,0.106,0.144,0.113,0.213c0.023,0.145-0.15,0.198-0.342,0.244
               c-0.068,0.014-0.139,0.038-0.189,0.061c-0.055,0.015-0.047,0.052,0,0.099c0.061,0.068,0.176,0.152,0.289,0.22
               c0.184,0.122,0.151,0.236,0.1,0.366c0.021,0.008,0.053,0.022,0.084,0.038c0.037,0.007,0.06,0.023,0.076,0.015
               c0.021,0,0.037-0.015,0.066-0.053c0.055-0.069,0.106-0.099,0.176-0.137c0.039-0.023,0.084-0.046,0.121-0.077
               c0.084-0.06,0.207-0.053,0.358-0.03c0.084,0.007,0.183,0.015,0.289,0.015c0.138,0,0.251,0.008,0.358,0.015
               c0.121,0.009,0.235,0.023,0.364,0.016c0.175-0.007,0.259,0.03,0.328,0.068c0.03,0.023,0.06,0.038,0.099,0.046h0.008
               c0.137,0.022,0.184,0.03,0.236,0.114c0.014,0.023,0.067,0.054,0.129,0.061c0.053,0.015,0.114,0.015,0.151-0.007
               c0.063-0.023,0.123-0.039,0.198-0.054c0.076-0.008,0.16-0.015,0.244-0.015c0.105,0,0.137,0.046,0.175,0.098
               c0.017,0.024,0.038,0.047,0.069,0.062c0.021,0.015,0.029,0,0.045-0.023c0.015-0.031,0.029-0.068,0.067-0.099
               c0.093-0.099,0.13-0.061,0.229,0.023c0.021,0.015,0.046,0.037,0.076,0.061c0.037,0.023,0.062,0.046,0.076,0.06l0.016,0.016
               c0,0,0.008,0,0.021-0.008c0.016,0,0.038-0.008,0.069-0.008c0.067-0.014,0.112-0.022,0.149-0.037
               c0.047-0.016,0.101-0.031,0.199-0.047c0.137-0.022,0.184,0.047,0.235,0.115c0.024,0.031,0.054,0.069,0.092,0.092h0.017v-0.031
               c-0.008-0.053-0.03-0.121-0.068-0.168c-0.061-0.068-0.016-0.167,0.061-0.258c0.068-0.077,0.168-0.16,0.244-0.229
               c0.129-0.099,0.234-0.106,0.365-0.114c0.046,0,0.092-0.007,0.145-0.015c0.022,0,0.047-0.008,0.062-0.016
               c-0.106-0.144-0.146-0.273-0.168-0.349c-0.021-0.092-0.083-0.153-0.121-0.214c-0.037-0.061-0.114-0.251,0-0.274
               c0.114-0.016,0.106,0.045,0.229,0.084c0.121,0.03,0.197-0.031,0.259-0.061c0.061-0.039,0.085-0.107,0.176-0.114
               c0.091-0.008,0.183,0.03,0.175-0.084c-0.008-0.114-0.029-0.182-0.121-0.182c-0.101,0-0.054-0.1-0.184-0.146
               c-0.131-0.045-0.189-0.03-0.26-0.022c-0.068,0.007-0.068-0.191-0.221-0.275c-0.16-0.083-0.272-0.037-0.305-0.144
               c-0.038-0.107-0.023-0.145-0.129-0.282c-0.107-0.129-0.313-0.289-0.268-0.365c0.047-0.084,0.129-0.183,0.029-0.237
               c-0.092-0.06-0.061-0.113-0.066-0.205c-0.018-0.091-0.063-0.16-0.139-0.198c-0.068-0.038-0.062-0.205-0.396-0.137
               c-0.327,0.068-0.372,0.206-0.472,0.137c-0.092-0.069,0.053-0.221-0.106-0.244c-0.168-0.015-0.235,0.008-0.298,0.023
               c-0.061,0.008-0.061,0.084-0.145-0.023c-0.084-0.106-0.084-0.144-0.183-0.129c-0.069,0.008-0.153-0.015-0.198-0.069
               l-0.076,0.069l-0.055,0.045c-0.021,0.023-0.036,0.046-0.061,0.077c-0.029,0.061-0.068,0.122-0.174,0.16
               c-0.063,0.022-0.131,0.022-0.199,0.015c-0.092-0.007-0.176-0.015-0.252,0.046c-0.061,0.06-0.121,0.083-0.174,0.106
               c-0.054,0.023-0.101,0.046-0.084,0.115c0.017,0.068,0.053,0.076,0.084,0.084c0.062,0.022,0.105,0.03,0.076,0.128
               c-0.029,0.1-0.121,0.13-0.207,0.153c-0.029,0.008-0.061,0.015-0.074,0.031c-0.023,0.014-0.045,0.022-0.069,0.03
               c-0.044,0.023-0.093,0.046-0.183,0.13c-0.031,0.029-0.054,0.052-0.076,0.068c-0.075,0.061-0.114,0.091-0.084,0.167
               c0.037,0.084,0.037,0.145,0.031,0.206c-0.016,0.053-0.054,0.099-0.1,0.145c-0.053,0.053-0.1,0.046-0.152,0.046
               c-0.029-0.008-0.068-0.008-0.113,0.015c-0.023,0.023-0.055,0.037-0.084,0.06c-0.092,0.061-0.191,0.13-0.336,0.175
               c-0.112,0.039-0.152,0.031-0.197,0.015c-0.03-0.007-0.061-0.015-0.139,0.008C131.114,23.488,131.022,23.511,130.968,23.526"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M130.968,23.526c0.018,0.061,0.024,0.122,0.039,0.19c0.037,0.221,0.145,0.366,0.221,0.473c0.063,0.084,0.106,0.144,0.113,0.213
               c0.023,0.145-0.15,0.198-0.342,0.244c-0.068,0.014-0.139,0.038-0.189,0.061c-0.055,0.015-0.047,0.052,0,0.099
               c0.061,0.068,0.176,0.152,0.289,0.22c0.184,0.122,0.151,0.236,0.1,0.366c0.021,0.008,0.053,0.022,0.084,0.038
               c0.037,0.007,0.06,0.023,0.076,0.015c0.021,0,0.037-0.015,0.066-0.053c0.055-0.069,0.106-0.099,0.176-0.137
               c0.039-0.023,0.084-0.046,0.121-0.077c0.084-0.06,0.207-0.053,0.358-0.03c0.084,0.007,0.183,0.015,0.289,0.015
               c0.138,0,0.251,0.008,0.358,0.015c0.121,0.009,0.235,0.023,0.364,0.016c0.175-0.007,0.259,0.03,0.328,0.068
               c0.03,0.023,0.06,0.038,0.099,0.046h0.008c0.137,0.022,0.184,0.03,0.236,0.114c0.014,0.023,0.067,0.054,0.129,0.061
               c0.053,0.015,0.114,0.015,0.151-0.007c0.063-0.023,0.123-0.039,0.198-0.054c0.076-0.008,0.16-0.015,0.244-0.015
               c0.105,0,0.137,0.046,0.175,0.098c0.017,0.024,0.038,0.047,0.069,0.062c0.021,0.015,0.029,0,0.045-0.023
               c0.015-0.031,0.029-0.068,0.067-0.099c0.093-0.099,0.13-0.061,0.229,0.023c0.021,0.015,0.046,0.037,0.076,0.061
               c0.037,0.023,0.062,0.046,0.076,0.06l0.016,0.016c0,0,0.008,0,0.021-0.008c0.016,0,0.038-0.008,0.069-0.008
               c0.067-0.014,0.112-0.022,0.149-0.037c0.047-0.016,0.101-0.031,0.199-0.047c0.137-0.022,0.184,0.047,0.235,0.115
               c0.024,0.031,0.054,0.069,0.092,0.092h0.017v-0.031c-0.008-0.053-0.03-0.121-0.068-0.168c-0.061-0.068-0.016-0.167,0.061-0.258
               c0.068-0.077,0.168-0.16,0.244-0.229c0.129-0.099,0.234-0.106,0.365-0.114c0.046,0,0.092-0.007,0.145-0.015
               c0.022,0,0.047-0.008,0.062-0.016c-0.106-0.144-0.146-0.273-0.168-0.349c-0.021-0.092-0.083-0.153-0.121-0.214
               c-0.037-0.061-0.114-0.251,0-0.274c0.114-0.016,0.106,0.045,0.229,0.084c0.121,0.03,0.197-0.031,0.259-0.061
               c0.061-0.039,0.085-0.107,0.176-0.114c0.091-0.008,0.183,0.03,0.175-0.084c-0.008-0.114-0.029-0.182-0.121-0.182
               c-0.101,0-0.054-0.1-0.184-0.146c-0.131-0.045-0.189-0.03-0.26-0.022c-0.068,0.007-0.068-0.191-0.221-0.275
               c-0.16-0.083-0.272-0.037-0.305-0.144c-0.038-0.107-0.023-0.145-0.129-0.282c-0.107-0.129-0.313-0.289-0.268-0.365
               c0.047-0.084,0.129-0.183,0.029-0.237c-0.092-0.06-0.061-0.113-0.066-0.205c-0.018-0.091-0.063-0.16-0.139-0.198
               c-0.068-0.038-0.062-0.205-0.396-0.137c-0.327,0.068-0.372,0.206-0.472,0.137c-0.092-0.069,0.053-0.221-0.106-0.244
               c-0.168-0.015-0.235,0.008-0.298,0.023c-0.061,0.008-0.061,0.084-0.145-0.023c-0.084-0.106-0.084-0.144-0.183-0.129
               c-0.069,0.008-0.153-0.015-0.198-0.069l-0.076,0.069l-0.055,0.045c-0.021,0.023-0.036,0.046-0.061,0.077
               c-0.029,0.061-0.068,0.122-0.174,0.16c-0.063,0.022-0.131,0.022-0.199,0.015c-0.092-0.007-0.176-0.015-0.252,0.046
               c-0.061,0.06-0.121,0.083-0.174,0.106c-0.054,0.023-0.101,0.046-0.084,0.115c0.017,0.068,0.053,0.076,0.084,0.084
               c0.062,0.022,0.105,0.03,0.076,0.128c-0.029,0.1-0.121,0.13-0.207,0.153c-0.029,0.008-0.061,0.015-0.074,0.031
               c-0.023,0.014-0.045,0.022-0.069,0.03c-0.044,0.023-0.093,0.046-0.183,0.13c-0.031,0.029-0.054,0.052-0.076,0.068
               c-0.075,0.061-0.114,0.091-0.084,0.167c0.037,0.084,0.037,0.145,0.031,0.206c-0.016,0.053-0.054,0.099-0.1,0.145
               c-0.053,0.053-0.1,0.046-0.152,0.046c-0.029-0.008-0.068-0.008-0.113,0.015c-0.023,0.023-0.055,0.037-0.084,0.06
               c-0.092,0.061-0.191,0.13-0.336,0.175c-0.112,0.039-0.152,0.031-0.197,0.015c-0.03-0.007-0.061-0.015-0.139,0.008
               C131.114,23.488,131.022,23.511,130.968,23.526z"/>
             <path fill="#808184" d="M129.179,22.209c-0.121,0-0.273-0.015-0.166,0.061
               c0.113,0.084,0.258,0.076,0.204,0.16c-0.062,0.083-0.112,0.137-0.228,0.122c-0.122-0.015-0.305-0.122-0.396-0.061
               c-0.092,0.053-0.32-0.115-0.26,0.069c0.055,0.174,0.289,0.167,0.168,0.243c-0.055,0.038-0.092,0.068-0.122,0.091
               c0.038,0.016,0.084,0.039,0.13,0.045c0.092,0.023,0.396,0.047,0.738,0.054c0.343,0.008,0.716,0.008,0.914-0.007l0.061-0.008
               h0.03c0-0.084-0.007-0.251,0.017-0.305c0.006-0.023,0.014-0.038,0.021-0.045c0.016-0.016,0.023-0.031,0.007-0.046
               c-0.022-0.038-0.045-0.038-0.129-0.053h-0.007c-0.039-0.008-0.068-0.008-0.105-0.008c-0.078,0.008-0.162,0.008-0.291-0.038
               c-0.067-0.023-0.129-0.038-0.175-0.053c-0.084-0.023-0.151-0.046-0.235-0.1C129.31,22.3,129.248,22.255,129.179,22.209"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M129.179,22.209c-0.121,0-0.273-0.015-0.166,0.061c0.113,0.084,0.258,0.076,0.204,0.16c-0.062,0.083-0.112,0.137-0.228,0.122
               c-0.122-0.015-0.305-0.122-0.396-0.061c-0.092,0.053-0.32-0.115-0.26,0.069c0.055,0.174,0.289,0.167,0.168,0.243
               c-0.055,0.038-0.092,0.068-0.122,0.091c0.038,0.016,0.084,0.039,0.13,0.045c0.092,0.023,0.396,0.047,0.738,0.054
               c0.343,0.008,0.716,0.008,0.914-0.007l0.061-0.008h0.03c0-0.084-0.007-0.251,0.017-0.305c0.006-0.023,0.014-0.038,0.021-0.045
               c0.016-0.016,0.023-0.031,0.007-0.046c-0.022-0.038-0.045-0.038-0.129-0.053h-0.007c-0.039-0.008-0.068-0.008-0.105-0.008
               c-0.078,0.008-0.162,0.008-0.291-0.038c-0.067-0.023-0.129-0.038-0.175-0.053c-0.084-0.023-0.151-0.046-0.235-0.1
               C129.31,22.3,129.248,22.255,129.179,22.209z"/>
             <path fill="#808184" d="M128.989,21.577c0.007,0.007,0.007,0.015,0.015,0.023
               c0.046,0.099,0.009,0.228,0.091,0.357c0.038,0.054,0.084,0.107,0.115,0.153c0.084,0.053,0.159,0.106,0.197,0.137
               c0.068,0.046,0.13,0.06,0.205,0.083c0.055,0.016,0.115,0.031,0.191,0.061c0.105,0.039,0.182,0.031,0.241,0.031
               c0.048,0,0.084-0.008,0.13,0l0.016,0.008c0.106,0.015,0.146,0.022,0.19,0.099c0.046,0.068,0.03,0.099,0,0.152
               c-0.007,0.007-0.015,0.023-0.022,0.03c-0.016,0.039-0.008,0.183,0,0.259c0.076-0.008,0.121-0.008,0.16-0.008
               c0.061,0,0.084,0.015,0.145,0.039l0.031,0.008c0.082,0.037,0.152,0.113,0.199,0.243c0.021,0.046,0.037,0.107,0.051,0.175
               c0.055-0.015,0.146-0.038,0.261-0.061c0.099-0.03,0.136-0.016,0.183-0.007c0.03,0.007,0.063,0.015,0.145-0.009
               c0.13-0.045,0.223-0.106,0.306-0.167c0.03-0.023,0.062-0.038,0.091-0.061c0.075-0.045,0.131-0.038,0.184-0.038
               c0.023,0.008,0.046,0.008,0.068-0.015c0.03-0.03,0.053-0.06,0.068-0.092c0.007-0.038,0-0.083-0.023-0.144
               c-0.061-0.145,0-0.19,0.107-0.281c0.021-0.023,0.055-0.047,0.076-0.069c0.099-0.092,0.158-0.114,0.205-0.145
               c0.021-0.008,0.037-0.015,0.063-0.023c0.028-0.022,0.059-0.03,0.098-0.045c0.061-0.015,0.129-0.038,0.145-0.084h-0.016
               c-0.053-0.015-0.129-0.038-0.152-0.168c-0.016-0.068-0.007-0.114,0.018-0.144c-0.102-0.046-0.281-0.122-0.32-0.175
               c-0.029-0.023-0.068-0.069-0.121-0.107c-0.039-0.031-0.084-0.061-0.129-0.084c-0.076-0.046-0.153-0.038-0.223-0.031
               c-0.029,0-0.062,0.008-0.084,0.008c-0.084,0-0.105-0.053-0.136-0.129c-0.016-0.03-0.032-0.076-0.069-0.107
               c-0.029-0.03-0.09,0.023-0.146,0.069c-0.037,0.03-0.067,0.053-0.106,0.068c-0.074,0.039-0.221,0.016-0.379-0.008
               c-0.068-0.014-0.146-0.022-0.223-0.03c-0.1-0.007-0.229-0.015-0.372-0.03c-0.161-0.008-0.342-0.023-0.549-0.038
               c-0.274-0.023-0.381,0.046-0.503,0.122c-0.03,0.023-0.068,0.046-0.105,0.068C129.21,21.523,129.095,21.554,128.989,21.577"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M128.989,21.577c0.007,0.007,0.007,0.015,0.015,0.023c0.046,0.099,0.009,0.228,0.091,0.357c0.038,0.054,0.084,0.107,0.115,0.153
               c0.084,0.053,0.159,0.106,0.197,0.137c0.068,0.046,0.13,0.06,0.205,0.083c0.055,0.016,0.115,0.031,0.191,0.061
               c0.105,0.039,0.182,0.031,0.241,0.031c0.048,0,0.084-0.008,0.13,0l0.016,0.008c0.106,0.015,0.146,0.022,0.19,0.099
               c0.046,0.068,0.03,0.099,0,0.152c-0.007,0.007-0.015,0.023-0.022,0.03c-0.016,0.039-0.008,0.183,0,0.259
               c0.076-0.008,0.121-0.008,0.16-0.008c0.061,0,0.084,0.015,0.145,0.039l0.031,0.008c0.082,0.037,0.152,0.113,0.199,0.243
               c0.021,0.046,0.037,0.107,0.051,0.175c0.055-0.015,0.146-0.038,0.261-0.061c0.099-0.03,0.136-0.016,0.183-0.007
               c0.03,0.007,0.063,0.015,0.145-0.009c0.13-0.045,0.223-0.106,0.306-0.167c0.03-0.023,0.062-0.038,0.091-0.061
               c0.075-0.045,0.131-0.038,0.184-0.038c0.023,0.008,0.046,0.008,0.068-0.015c0.03-0.03,0.053-0.06,0.068-0.092
               c0.007-0.038,0-0.083-0.023-0.144c-0.061-0.145,0-0.19,0.107-0.281c0.021-0.023,0.055-0.047,0.076-0.069
               c0.099-0.092,0.158-0.114,0.205-0.145c0.021-0.008,0.037-0.015,0.063-0.023c0.028-0.022,0.059-0.03,0.098-0.045
               c0.061-0.015,0.129-0.038,0.145-0.084h-0.016c-0.053-0.015-0.129-0.038-0.152-0.168c-0.016-0.068-0.007-0.114,0.018-0.144
               c-0.102-0.046-0.281-0.122-0.32-0.175c-0.029-0.023-0.068-0.069-0.121-0.107c-0.039-0.031-0.084-0.061-0.129-0.084
               c-0.076-0.046-0.153-0.038-0.223-0.031c-0.029,0-0.062,0.008-0.084,0.008c-0.084,0-0.105-0.053-0.136-0.129
               c-0.016-0.03-0.032-0.076-0.069-0.107c-0.029-0.03-0.09,0.023-0.146,0.069c-0.037,0.03-0.067,0.053-0.106,0.068
               c-0.074,0.039-0.221,0.016-0.379-0.008c-0.068-0.014-0.146-0.022-0.223-0.03c-0.1-0.007-0.229-0.015-0.372-0.03
               c-0.161-0.008-0.342-0.023-0.549-0.038c-0.274-0.023-0.381,0.046-0.503,0.122c-0.03,0.023-0.068,0.046-0.105,0.068
               C129.21,21.523,129.095,21.554,128.989,21.577z"/>
             <path fill="#808184" d="M131.099,20.031c0.053,0.198,0.137,0.343,0.1,0.418
               c-0.047,0.092-0.213,0.13-0.328,0.214c-0.122,0.076-0.083,0.114-0.365-0.031c-0.281-0.144-0.297-0.296-0.402-0.395
               c-0.106-0.092-0.092-0.176-0.26-0.114c-0.167,0.061-0.318,0.015-0.486,0.175c-0.176,0.168-0.139,0.091-0.198,0.327
               c-0.062,0.243-0.152,0.214-0.188,0.419c-0.023,0.144-0.023,0.32,0,0.441c0.098-0.023,0.204-0.06,0.28-0.107
               c0.038-0.014,0.075-0.037,0.106-0.06c0.137-0.092,0.259-0.168,0.563-0.138c0.2,0.016,0.381,0.032,0.549,0.039
               c0.146,0.007,0.268,0.015,0.381,0.03c0.076,0.008,0.152,0.015,0.223,0.023c0.144,0.023,0.271,0.046,0.326,0.023
               c0.016-0.008,0.045-0.031,0.076-0.061c0.092-0.068,0.189-0.152,0.289-0.061c0.045,0.053,0.068,0.099,0.084,0.145
               c0.016,0.038,0.029,0.06,0.045,0.06h0.076c0.084-0.007,0.176-0.014,0.274,0.039c0.054,0.031,0.106,0.06,0.146,0.099
               c0.061,0.038,0.105,0.084,0.137,0.121c0.022,0.023,0.312,0.184,0.373,0.161c0.047-0.016,0.1-0.039,0.145-0.084
               c0.107-0.1,0.223-0.091,0.327-0.084c0.062,0.007,0.114,0.015,0.161,0c0.066-0.03,0.092-0.076,0.121-0.122
               c0.021-0.031,0.039-0.069,0.076-0.099l0.053-0.045c0.031-0.023,0.062-0.054,0.099-0.084c-0.037-0.092-0.112-0.213-0.196-0.35
               c-0.101-0.16-0.213-0.046-0.16-0.206c0.061-0.16,0.084-0.266-0.045-0.342c-0.115-0.069-0.19-0.061-0.199-0.145
               c-0.098,0-0.235-0.008-0.357-0.039c-0.091-0.022-0.113-0.007-0.136,0.008c-0.039,0.031-0.069,0.061-0.183,0.046
               c-0.139-0.023-0.162-0.076-0.19-0.138c-0.009-0.029-0.022-0.06-0.062-0.091c-0.045-0.037-0.1-0.037-0.162-0.037
               c-0.074,0-0.166,0-0.287-0.069c-0.137-0.076-0.145-0.076-0.207-0.061c-0.029,0-0.075,0.008-0.151,0.015
               c-0.114,0-0.243,0.054-0.349,0.115C131.158,20.001,131.128,20.016,131.099,20.031"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M131.099,20.031c0.053,0.198,0.137,0.343,0.1,0.418c-0.047,0.092-0.213,0.13-0.328,0.214c-0.122,0.076-0.083,0.114-0.365-0.031
               c-0.281-0.144-0.297-0.296-0.402-0.395c-0.106-0.092-0.092-0.176-0.26-0.114c-0.167,0.061-0.318,0.015-0.486,0.175
               c-0.176,0.168-0.139,0.091-0.198,0.327c-0.062,0.243-0.152,0.214-0.188,0.419c-0.023,0.144-0.023,0.32,0,0.441
               c0.098-0.023,0.204-0.06,0.28-0.107c0.038-0.014,0.075-0.037,0.106-0.06c0.137-0.092,0.259-0.168,0.563-0.138
               c0.2,0.016,0.381,0.032,0.549,0.039c0.146,0.007,0.268,0.015,0.381,0.03c0.076,0.008,0.152,0.015,0.223,0.023
               c0.144,0.023,0.271,0.046,0.326,0.023c0.016-0.008,0.045-0.031,0.076-0.061c0.092-0.068,0.189-0.152,0.289-0.061
               c0.045,0.053,0.068,0.099,0.084,0.145c0.016,0.038,0.029,0.06,0.045,0.06h0.076c0.084-0.007,0.176-0.014,0.274,0.039
               c0.054,0.031,0.106,0.06,0.146,0.099c0.061,0.038,0.105,0.084,0.137,0.121c0.022,0.023,0.312,0.184,0.373,0.161
               c0.047-0.016,0.1-0.039,0.145-0.084c0.107-0.1,0.223-0.091,0.327-0.084c0.062,0.007,0.114,0.015,0.161,0
               c0.066-0.03,0.092-0.076,0.121-0.122c0.021-0.031,0.039-0.069,0.076-0.099l0.053-0.045c0.031-0.023,0.062-0.054,0.099-0.084
               c-0.037-0.092-0.112-0.213-0.196-0.35c-0.101-0.16-0.213-0.046-0.16-0.206c0.061-0.16,0.084-0.266-0.045-0.342
               c-0.115-0.069-0.19-0.061-0.199-0.145c-0.098,0-0.235-0.008-0.357-0.039c-0.091-0.022-0.113-0.007-0.136,0.008
               c-0.039,0.031-0.069,0.061-0.183,0.046c-0.139-0.023-0.162-0.076-0.19-0.138c-0.009-0.029-0.022-0.06-0.062-0.091
               c-0.045-0.037-0.1-0.037-0.162-0.037c-0.074,0-0.166,0-0.287-0.069c-0.137-0.076-0.145-0.076-0.207-0.061
               c-0.029,0-0.075,0.008-0.151,0.015c-0.114,0-0.243,0.054-0.349,0.115C131.158,20.001,131.128,20.016,131.099,20.031z"/>
             <path fill="#808184" d="M130.61,18.767c-0.092,0.015-0.146,0.122-0.207,0.145
               c-0.053,0.03-0.234,0.053-0.213,0.114c0.031,0.061,0.152,0.007,0.207,0.046c0.052,0.038,0.045,0.06,0.076,0.129
               c0.029,0.061,0.075,0.106,0.113,0.19c0.046,0.092,0.008,0.191,0.13,0.222c0.121,0.03,0.158,0.007,0.267,0
               c0.106,0,0.236-0.1,0.213,0c-0.021,0.083-0.129,0.137-0.113,0.312c0.016-0.008,0.038-0.023,0.061-0.031
               c0.123-0.069,0.26-0.122,0.389-0.13c0.068,0,0.115-0.007,0.146-0.014c0.098-0.016,0.104-0.016,0.266,0.075
               c0.1,0.061,0.177,0.061,0.242,0.061c0.078,0,0.146,0,0.215,0.053c0.068,0.047,0.093,0.092,0.106,0.137
               c0.015,0.031,0.022,0.062,0.106,0.069c0.068,0.015,0.084,0,0.105-0.015c0.037-0.038,0.076-0.069,0.223-0.03
               c0.121,0.03,0.258,0.038,0.355,0.038c0.031-0.084,0.077-0.137,0.084-0.161c0.008-0.029,0.039-0.113-0.037-0.19
               c-0.068-0.068-0.076-0.083-0.131-0.243c-0.061-0.168,0-0.229-0.037-0.328c-0.037-0.099-0.068-0.137,0.047-0.243
               c0.121-0.114,0.137-0.229,0.137-0.297c0-0.069,0.021-0.115-0.022-0.115c-0.055,0-0.168,0.039-0.336,0.024
               c-0.16-0.024-0.28,0-0.388-0.062c-0.106-0.061-0.137-0.098-0.336-0.083c-0.19,0.015-0.229,0.038-0.448,0.038
               c-0.222,0-0.207-0.016-0.351,0.068c-0.146,0.077-0.176,0.099-0.313,0.099c-0.145-0.008-0.189-0.038-0.328,0.031
               C130.702,18.752,130.61,18.767,130.61,18.767 M129.804,18.927c0,0-0.062,0.106-0.129,0.106c-0.068,0-0.236,0.008-0.16,0.061
               c0.076,0.054,0.26,0.038,0.268,0.115c0,0.068-0.106,0.091-0.146,0.13c-0.037,0.037-0.062,0.098-0.137,0.113
               c-0.068,0.023-0.061,0.13-0.021,0.191c0.029,0.069,0,0.144,0.016,0.198c0.015,0.061,0.129,0.168,0.184,0.061
               c0.045-0.107,0.037-0.222,0.15-0.236c0.105-0.008,0.236-0.077,0.313-0.099c0.074-0.023,0.144-0.069,0.221-0.115
               c0.084-0.038,0.129-0.145,0.016-0.159c-0.115-0.016-0.189,0.038-0.273,0.053c-0.078,0.007-0.297,0-0.176-0.091
               c0.114-0.099,0.288-0.145,0.197-0.214c-0.091-0.061-0.167-0.023-0.189-0.061s0-0.152-0.067-0.106L129.804,18.927z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M130.61,18.767c-0.092,0.015-0.146,0.122-0.207,0.145c-0.053,0.03-0.234,0.053-0.213,0.114c0.031,0.061,0.152,0.007,0.207,0.046
               c0.052,0.038,0.045,0.06,0.076,0.129c0.029,0.061,0.075,0.106,0.113,0.19c0.046,0.092,0.008,0.191,0.13,0.222
               c0.121,0.03,0.158,0.007,0.267,0c0.106,0,0.236-0.1,0.213,0c-0.021,0.083-0.129,0.137-0.113,0.312
               c0.016-0.008,0.038-0.023,0.061-0.031c0.123-0.069,0.26-0.122,0.389-0.13c0.068,0,0.115-0.007,0.146-0.014
               c0.098-0.016,0.104-0.016,0.266,0.075c0.1,0.061,0.177,0.061,0.242,0.061c0.078,0,0.146,0,0.215,0.053
               c0.068,0.047,0.093,0.092,0.106,0.137c0.015,0.031,0.022,0.062,0.106,0.069c0.068,0.015,0.084,0,0.105-0.015
               c0.037-0.038,0.076-0.069,0.223-0.03c0.121,0.03,0.258,0.038,0.355,0.038c0.031-0.084,0.077-0.137,0.084-0.161
               c0.008-0.029,0.039-0.113-0.037-0.19c-0.068-0.068-0.076-0.083-0.131-0.243c-0.061-0.168,0-0.229-0.037-0.328
               c-0.037-0.099-0.068-0.137,0.047-0.243c0.121-0.114,0.137-0.229,0.137-0.297c0-0.069,0.021-0.115-0.022-0.115
               c-0.055,0-0.168,0.039-0.336,0.024c-0.16-0.024-0.28,0-0.388-0.062c-0.106-0.061-0.137-0.098-0.336-0.083
               c-0.19,0.015-0.229,0.038-0.448,0.038c-0.222,0-0.207-0.016-0.351,0.068c-0.146,0.077-0.176,0.099-0.313,0.099
               c-0.145-0.008-0.189-0.038-0.328,0.031C130.702,18.752,130.61,18.767,130.61,18.767z M129.804,18.927
               c0,0-0.062,0.106-0.129,0.106c-0.068,0-0.236,0.008-0.16,0.061c0.076,0.054,0.26,0.038,0.268,0.115
               c0,0.068-0.106,0.091-0.146,0.13c-0.037,0.037-0.062,0.098-0.137,0.113c-0.068,0.023-0.061,0.13-0.021,0.191
               c0.029,0.069,0,0.144,0.016,0.198c0.015,0.061,0.129,0.168,0.184,0.061c0.045-0.107,0.037-0.222,0.15-0.236
               c0.105-0.008,0.236-0.077,0.313-0.099c0.074-0.023,0.144-0.069,0.221-0.115c0.084-0.038,0.129-0.145,0.016-0.159
               c-0.115-0.016-0.189,0.038-0.273,0.053c-0.078,0.007-0.297,0-0.176-0.091c0.114-0.099,0.288-0.145,0.197-0.214
               c-0.091-0.061-0.167-0.023-0.189-0.061s0-0.152-0.067-0.106L129.804,18.927z"/>
             <path fill="#808184" d="M127.321,19.894c0,0-0.274,0.182-0.297,0.327
               c-0.016,0.153,0.145,0.168,0.037,0.259c-0.105,0.084-0.061,0.145-0.037,0.252c0.021,0.099,0.021,0.22,0.084,0.152
               c0.061-0.069,0.099-0.366,0.213-0.396c0.105-0.03,0.357-0.039,0.259-0.152c-0.106-0.115-0.235-0.206-0.146-0.251
               c0.1-0.046,0.236-0.252,0.106-0.267C127.421,19.81,127.321,19.894,127.321,19.894 M122.289,19.094
               c-0.008,0.122,0,0.168,0.068,0.274c0.091,0.137-0.068,0.191,0.137,0.373c0.206,0.184,0.122,0.191,0.213,0.443
               c0.092,0.243,0.358,0.265,0.403,0.479c0.047,0.214,0.236,0.274,0.306,0.335c0.068,0.069,0.091,0.152-0.047,0.236
               c-0.137,0.091-0.113,0.214-0.113,0.214s0.175,0.175,0.274,0.289c0.091,0.113-0.016,0.159,0,0.258
               c0.007,0.099,0.053,0.168,0.175,0.168c0.129,0.008,0.273-0.061,0.487-0.031c0.213,0.031,0.243,0.046,0.289-0.022
               c0.046-0.069-0.046-0.236-0.008-0.366c0.03-0.129,0.03-0.152,0.183-0.183c0.145-0.022,0.106-0.159,0.343-0.113
               c0.236,0.045,0.282,0.075,0.419,0.045c0.137-0.038,0.305-0.389,0.373-0.434c0.069-0.046,0.076,0.091,0.076,0.175
               s0.008,0.198,0.084,0.137c0.076-0.053,0.449-0.822,0.319-0.799c-0.128,0.015-0.175,0.243-0.266,0.274
               c-0.1,0.038-0.115-0.039-0.077-0.214c0.039-0.166,0.13-0.35,0.114-0.525c-0.015-0.175,0.092-0.182,0.046-0.343
               c-0.046-0.16-0.167-0.19-0.106-0.274c0.053-0.076,0.205-0.084,0.267-0.144c0.053-0.061,0.274-0.313,0.396-0.305
               c0.123,0,0.063,0.153,0.244,0.016c0.176-0.145,0.159-0.138,0.215-0.259c0.045-0.122-0.17-0.183,0.006-0.282
               c0.184-0.099,0.389-0.114,0.32-0.236c-0.076-0.122-0.023-0.114-0.197-0.259c-0.176-0.144-0.244-0.32-0.404-0.335
               c-0.168-0.023-0.113-0.008-0.305-0.053c-0.189-0.046-0.389-0.175-0.342-0.366c0.053-0.19-0.084-0.335-0.022-0.464
               c0.053-0.13,0.197-0.107,0.175-0.274c-0.023-0.176,0.046-0.259,0.037-0.313c-0.015-0.053-0.121-0.205,0.009-0.228
               c0.138-0.015,0.22-0.137,0.235-0.175c0.021-0.031,0.221-0.129,0.343-0.236c0.13-0.114,0.427-0.259,0.578-0.313
               c0.151-0.052,0.159-0.121,0.358-0.182c0.196-0.061,0.273-0.145,0.418-0.282c0.152-0.137,0.152-0.213,0.258-0.266
               c0.107-0.054,0.261-0.091,0.131-0.183c-0.131-0.084-0.335-0.236-0.131-0.381c0.199-0.137,0.048-0.243,0.185-0.327
               c0.138-0.091,0.222-0.015,0.343-0.129c0.121-0.122,0.146-0.305,0.396-0.213c0.259,0.083,0.411,0.007,0.632,0.015h0.039
               c-0.01-0.061-0.032-0.122-0.068-0.168c-0.023-0.03-0.048-0.061-0.076-0.084c-0.129-0.159-0.222-0.266-0.168-0.388
               c0.017-0.038,0.045-0.076,0.067-0.107c0.039-0.053,0.077-0.099,0.032-0.152c-0.039-0.038-0.077-0.069-0.107-0.092
               c-0.076-0.06-0.129-0.098-0.113-0.213c0.008-0.045,0-0.098-0.008-0.137c-0.017-0.038-0.039-0.061-0.054-0.075
               c-0.062-0.032-0.062-0.061-0.069-0.13c-0.007-0.03-0.007-0.069-0.021-0.122l-0.008-0.03c-0.03-0.107-0.045-0.16-0.137-0.168
               c-0.076,0-0.16-0.054-0.236-0.106c-0.068-0.046-0.137-0.1-0.198-0.107c-0.062-0.007-0.106,0-0.151,0.007
               c-0.068,0.008-0.13,0.016-0.229-0.023c-0.068-0.03-0.152-0.075-0.244-0.136c-0.092-0.054-0.184-0.107-0.258-0.137
               c-0.107-0.046-0.168-0.1-0.207-0.137c-0.053,0.022-0.113,0.03-0.167,0.03h-0.009c-0.029,0.007-0.075,0.038-0.121,0.076
               c-0.03,0.038-0.061,0.076-0.061,0.106c-0.009,0.054-0.031,0.115-0.055,0.161c-0.037,0.06-0.083,0.106-0.121,0.106
               c-0.023,0-0.13-0.008-0.252-0.015c-0.076-0.007-0.159-0.015-0.229-0.023c-0.047-0.008-0.106-0.022-0.16-0.038
               c-0.151-0.038-0.319-0.076-0.342-0.016c-0.015,0.046-0.015,0.1-0.015,0.146c0,0.122,0,0.229-0.146,0.25
               c-0.061,0.016-0.137,0-0.213-0.021c-0.122-0.023-0.252-0.053-0.334,0.046c-0.063,0.083-0.101,0.137-0.131,0.175
               c-0.045,0.068-0.067,0.107-0.122,0.137c-0.015,0.007-0.037,0.022-0.068,0.03c-0.007,0.008-0.022,0.008-0.03,0.015
               c-0.03,0.016-0.061,0.039-0.045,0.077c0.015,0.03,0.03,0.053,0.045,0.076c0.054,0.076,0.092,0.121,0.008,0.236
               c-0.038,0.061-0.129,0.114-0.221,0.175c-0.106,0.068-0.213,0.145-0.229,0.198c-0.008,0.031-0.015,0.061-0.015,0.091
               c-0.016,0.099-0.023,0.167-0.145,0.213c-0.039,0.016-0.084,0.023-0.138,0.031c-0.114,0.03-0.251,0.053-0.267,0.129
               c-0.008,0.046-0.008,0.092-0.008,0.138c0.008,0.098,0.008,0.197-0.054,0.327c-0.022,0.061-0.046,0.107-0.053,0.144
               c-0.038,0.107-0.068,0.183-0.183,0.275c-0.03,0.022-0.061,0.045-0.084,0.06c-0.045,0.031-0.084,0.061-0.1,0.076
               c-0.015,0.023-0.015,0.039,0.023,0.069c0.054,0.046,0.106,0.115,0.13,0.183c0.016,0.038,0.022,0.076,0.022,0.106
               c0,0.038-0.007,0.077-0.03,0.107c-0.016,0.015-0.038,0.031-0.062,0.038c-0.053,0.023-0.129,0.023-0.213,0.016
               c-0.137-0.008-0.304-0.023-0.434,0.083c-0.129,0.114-0.176,0.198-0.213,0.274c-0.031,0.046-0.054,0.092-0.092,0.137
               c-0.015,0.016-0.03,0.03-0.038,0.038c-0.061,0.061-0.076,0.076-0.046,0.267c0.031,0.236,0.008,0.388-0.015,0.518v0.022
               c-0.016,0.062,0.029,0.115,0.075,0.161c0.031,0.03,0.054,0.06,0.077,0.099c0.03,0.06,0,0.144-0.03,0.228
               c-0.023,0.061-0.047,0.129-0.031,0.136c0.016,0.009,0.054,0.031,0.1,0.054c0.099,0.046,0.228,0.107,0.273,0.183
               c0.038,0.069,0.031,0.129-0.068,0.19c-0.114,0.068-0.183,0.114-0.221,0.16c-0.022,0.038-0.022,0.092,0,0.168
               c0.022,0.075,0.053,0.144,0.085,0.198c0.044,0.099,0.075,0.175,0.067,0.243c0,0.024,0,0.046,0.008,0.069
               c0.009,0.091,0.015,0.175-0.13,0.251c-0.068,0.031-0.137,0.045-0.198,0.053c-0.061,0.008-0.106,0.024-0.113,0.053l-0.009,0.031
               c-0.015,0.099-0.021,0.153-0.075,0.289c-0.023,0.046-0.007,0.107,0,0.16c0.015,0.107,0.022,0.198-0.099,0.236
               C122.518,19.064,122.395,19.079,122.289,19.094"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M127.321,19.894c0,0-0.274,0.182-0.297,0.327c-0.016,0.153,0.145,0.168,0.037,0.259c-0.105,0.084-0.061,0.145-0.037,0.252
               c0.021,0.099,0.021,0.22,0.084,0.152c0.061-0.069,0.099-0.366,0.213-0.396c0.105-0.03,0.357-0.039,0.259-0.152
               c-0.106-0.115-0.235-0.206-0.146-0.251c0.1-0.046,0.236-0.252,0.106-0.267C127.421,19.81,127.321,19.894,127.321,19.894z
                M122.289,19.094c-0.008,0.122,0,0.168,0.068,0.274c0.091,0.137-0.068,0.191,0.137,0.373c0.206,0.184,0.122,0.191,0.213,0.443
               c0.092,0.243,0.358,0.265,0.403,0.479c0.047,0.214,0.236,0.274,0.306,0.335c0.068,0.069,0.091,0.152-0.047,0.236
               c-0.137,0.091-0.113,0.214-0.113,0.214s0.175,0.175,0.274,0.289c0.091,0.113-0.016,0.159,0,0.258
               c0.007,0.099,0.053,0.168,0.175,0.168c0.129,0.008,0.273-0.061,0.487-0.031c0.213,0.031,0.243,0.046,0.289-0.022
               c0.046-0.069-0.046-0.236-0.008-0.366c0.03-0.129,0.03-0.152,0.183-0.183c0.145-0.022,0.106-0.159,0.343-0.113
               c0.236,0.045,0.282,0.075,0.419,0.045c0.137-0.038,0.305-0.389,0.373-0.434c0.069-0.046,0.076,0.091,0.076,0.175
               s0.008,0.198,0.084,0.137c0.076-0.053,0.449-0.822,0.319-0.799c-0.128,0.015-0.175,0.243-0.266,0.274
               c-0.1,0.038-0.115-0.039-0.077-0.214c0.039-0.166,0.13-0.35,0.114-0.525c-0.015-0.175,0.092-0.182,0.046-0.343
               c-0.046-0.16-0.167-0.19-0.106-0.274c0.053-0.076,0.205-0.084,0.267-0.144c0.053-0.061,0.274-0.313,0.396-0.305
               c0.123,0,0.063,0.153,0.244,0.016c0.176-0.145,0.159-0.138,0.215-0.259c0.045-0.122-0.17-0.183,0.006-0.282
               c0.184-0.099,0.389-0.114,0.32-0.236c-0.076-0.122-0.023-0.114-0.197-0.259c-0.176-0.144-0.244-0.32-0.404-0.335
               c-0.168-0.023-0.113-0.008-0.305-0.053c-0.189-0.046-0.389-0.175-0.342-0.366c0.053-0.19-0.084-0.335-0.022-0.464
               c0.053-0.13,0.197-0.107,0.175-0.274c-0.023-0.176,0.046-0.259,0.037-0.313c-0.015-0.053-0.121-0.205,0.009-0.228
               c0.138-0.015,0.22-0.137,0.235-0.175c0.021-0.031,0.221-0.129,0.343-0.236c0.13-0.114,0.427-0.259,0.578-0.313
               c0.151-0.052,0.159-0.121,0.358-0.182c0.196-0.061,0.273-0.145,0.418-0.282c0.152-0.137,0.152-0.213,0.258-0.266
               c0.107-0.054,0.261-0.091,0.131-0.183c-0.131-0.084-0.335-0.236-0.131-0.381c0.199-0.137,0.048-0.243,0.185-0.327
               c0.138-0.091,0.222-0.015,0.343-0.129c0.121-0.122,0.146-0.305,0.396-0.213c0.259,0.083,0.411,0.007,0.632,0.015h0.039
               c-0.01-0.061-0.032-0.122-0.068-0.168c-0.023-0.03-0.048-0.061-0.076-0.084c-0.129-0.159-0.222-0.266-0.168-0.388
               c0.017-0.038,0.045-0.076,0.067-0.107c0.039-0.053,0.077-0.099,0.032-0.152c-0.039-0.038-0.077-0.069-0.107-0.092
               c-0.076-0.06-0.129-0.098-0.113-0.213c0.008-0.045,0-0.098-0.008-0.137c-0.017-0.038-0.039-0.061-0.054-0.075
               c-0.062-0.032-0.062-0.061-0.069-0.13c-0.007-0.03-0.007-0.069-0.021-0.122l-0.008-0.03c-0.03-0.107-0.045-0.16-0.137-0.168
               c-0.076,0-0.16-0.054-0.236-0.106c-0.068-0.046-0.137-0.1-0.198-0.107c-0.062-0.007-0.106,0-0.151,0.007
               c-0.068,0.008-0.13,0.016-0.229-0.023c-0.068-0.03-0.152-0.075-0.244-0.136c-0.092-0.054-0.184-0.107-0.258-0.137
               c-0.107-0.046-0.168-0.1-0.207-0.137c-0.053,0.022-0.113,0.03-0.167,0.03h-0.009c-0.029,0.007-0.075,0.038-0.121,0.076
               c-0.03,0.038-0.061,0.076-0.061,0.106c-0.009,0.054-0.031,0.115-0.055,0.161c-0.037,0.06-0.083,0.106-0.121,0.106
               c-0.023,0-0.13-0.008-0.252-0.015c-0.076-0.007-0.159-0.015-0.229-0.023c-0.047-0.008-0.106-0.022-0.16-0.038
               c-0.151-0.038-0.319-0.076-0.342-0.016c-0.015,0.046-0.015,0.1-0.015,0.146c0,0.122,0,0.229-0.146,0.25
               c-0.061,0.016-0.137,0-0.213-0.021c-0.122-0.023-0.252-0.053-0.334,0.046c-0.063,0.083-0.101,0.137-0.131,0.175
               c-0.045,0.068-0.067,0.107-0.122,0.137c-0.015,0.007-0.037,0.022-0.068,0.03c-0.007,0.008-0.022,0.008-0.03,0.015
               c-0.03,0.016-0.061,0.039-0.045,0.077c0.015,0.03,0.03,0.053,0.045,0.076c0.054,0.076,0.092,0.121,0.008,0.236
               c-0.038,0.061-0.129,0.114-0.221,0.175c-0.106,0.068-0.213,0.145-0.229,0.198c-0.008,0.031-0.015,0.061-0.015,0.091
               c-0.016,0.099-0.023,0.167-0.145,0.213c-0.039,0.016-0.084,0.023-0.138,0.031c-0.114,0.03-0.251,0.053-0.267,0.129
               c-0.008,0.046-0.008,0.092-0.008,0.138c0.008,0.098,0.008,0.197-0.054,0.327c-0.022,0.061-0.046,0.107-0.053,0.144
               c-0.038,0.107-0.068,0.183-0.183,0.275c-0.03,0.022-0.061,0.045-0.084,0.06c-0.045,0.031-0.084,0.061-0.1,0.076
               c-0.015,0.023-0.015,0.039,0.023,0.069c0.054,0.046,0.106,0.115,0.13,0.183c0.016,0.038,0.022,0.076,0.022,0.106
               c0,0.038-0.007,0.077-0.03,0.107c-0.016,0.015-0.038,0.031-0.062,0.038c-0.053,0.023-0.129,0.023-0.213,0.016
               c-0.137-0.008-0.304-0.023-0.434,0.083c-0.129,0.114-0.176,0.198-0.213,0.274c-0.031,0.046-0.054,0.092-0.092,0.137
               c-0.015,0.016-0.03,0.03-0.038,0.038c-0.061,0.061-0.076,0.076-0.046,0.267c0.031,0.236,0.008,0.388-0.015,0.518v0.022
               c-0.016,0.062,0.029,0.115,0.075,0.161c0.031,0.03,0.054,0.06,0.077,0.099c0.03,0.06,0,0.144-0.03,0.228
               c-0.023,0.061-0.047,0.129-0.031,0.136c0.016,0.009,0.054,0.031,0.1,0.054c0.099,0.046,0.228,0.107,0.273,0.183
               c0.038,0.069,0.031,0.129-0.068,0.19c-0.114,0.068-0.183,0.114-0.221,0.16c-0.022,0.038-0.022,0.092,0,0.168
               c0.022,0.075,0.053,0.144,0.085,0.198c0.044,0.099,0.075,0.175,0.067,0.243c0,0.024,0,0.046,0.008,0.069
               c0.009,0.091,0.015,0.175-0.13,0.251c-0.068,0.031-0.137,0.045-0.198,0.053c-0.061,0.008-0.106,0.024-0.113,0.053l-0.009,0.031
               c-0.015,0.099-0.021,0.153-0.075,0.289c-0.023,0.046-0.007,0.107,0,0.16c0.015,0.107,0.022,0.198-0.099,0.236
               C122.518,19.064,122.395,19.079,122.289,19.094z"/>
        </a>
        
        <!-- fin sandinavie_europe-est  -->
        
        
        
        
        
        
        
        
        <!-- danmark-->
             <path fill="#808184" d="M124.709,22.369c0,0,0.275,0.381,0.313,0.122
               c0.045-0.259-0.237-0.419-0.313-0.343C124.634,22.224,124.709,22.369,124.709,22.369 M121.52,22.575v-0.008
               c-0.063-0.098-0.146-0.129-0.19-0.228c-0.053-0.099,0.016-0.335,0.076-0.229c0.062,0.107,0.106,0.236,0.198,0.267
               c0.091,0.03,0.221,0,0.35,0.023c0.122,0.022,0.016,0.046,0.039,0.137c0.022,0.091,0.075,0.053,0.129-0.046
               c0.062-0.106,0.068-0.161,0.022-0.259c-0.038-0.099-0.084-0.198,0-0.22c0.092-0.023,0.176-0.084,0.176,0.045
               c-0.008,0.13-0.1,0.183,0.046,0.213c0.137,0.031,0.304,0,0.341,0.046c0.032,0.046-0.014,0.061-0.014,0.137
               c0,0.068,0.076,0.107-0.069,0.107c-0.136,0.007-0.243-0.084-0.312-0.039c-0.076,0.046-0.213,0.076-0.099,0.137
               c0.121,0.062,0.167-0.03,0.228,0.062c0.062,0.091,0.183,0.114,0.252,0.083c0.068-0.03,0.061,0.046,0.168,0
               c0.106-0.045,0.037-0.145,0.145-0.221c0.105-0.069,0.364-0.076,0.228-0.136c-0.137-0.061-0.297-0.116-0.197-0.168
               c0.099-0.046,0.229-0.084,0.167-0.153c-0.068-0.068-0.106-0.091-0.083-0.144c0.03-0.061,0.167-0.153,0.145-0.237
               c-0.023-0.084,0.037-0.22-0.13-0.235c-0.167-0.008-0.229,0.007-0.297,0.06c-0.076,0.046,0.037,0.214-0.106,0.161
               c-0.146-0.054-0.175-0.137-0.274-0.099c-0.099,0.037-0.076,0.174-0.137,0.205c-0.07,0.038-0.145-0.061-0.198,0.023
               c-0.046,0.083,0.092,0.091-0.084,0.122c-0.168,0.023-0.235-0.137-0.297-0.122c-0.068,0.015-0.19,0.083-0.199-0.031
               c-0.014-0.106,0.07-0.129,0.131-0.213c0.068-0.083-0.076-0.212,0.068-0.228c0.145-0.008,0.509-0.114,0.335-0.229
               c-0.183-0.114-0.213,0.069-0.29-0.121c-0.067-0.191-0.076-0.313-0.076-0.313s0.26-0.205,0.191-0.357
               c-0.076-0.152-0.054-0.229-0.054-0.267c0.008-0.038-0.129-0.091-0.16-0.038c-0.03,0.053-0.091,0.145-0.151,0.159
               c-0.068,0.016-0.168,0.07-0.19,0.123c-0.023,0.045-0.32,0.175-0.357,0.221c-0.031,0.053-0.153,0.457-0.176,0.251
               c-0.016-0.198,0.121-0.259,0.008-0.275c-0.114-0.007-0.237,0.008-0.289,0.077c-0.046,0.069-0.13,0.183-0.19,0.267
               c-0.069,0.075-0.221,0.182-0.183,0.35s0.022,0.25,0.075,0.312c0.046,0.068,0.122,0.022,0.054,0.152
               c-0.068,0.129-0.083,0.229-0.054,0.297c0.038,0.069,0.062-0.084,0.168,0.069c0.098,0.152,0.03,0.213,0.03,0.297
               s0.046,0.19,0.138,0.175c0.014,0,0.03,0.007,0.037,0.023h0.016c0.068-0.008,0.122-0.023,0.168-0.039
               c0.068-0.023,0.121-0.038,0.175,0c0.008,0.008,0.023,0.016,0.03,0.023c0.03,0.023,0.054,0.038,0.168,0.023
               C121.261,22.56,121.405,22.567,121.52,22.575"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M124.709,22.369c0,0,0.275,0.381,0.313,0.122c0.045-0.259-0.237-0.419-0.313-0.343
               C124.634,22.224,124.709,22.369,124.709,22.369z M121.52,22.575v-0.008c-0.063-0.098-0.146-0.129-0.19-0.228
               c-0.053-0.099,0.016-0.335,0.076-0.229c0.062,0.107,0.106,0.236,0.198,0.267c0.091,0.03,0.221,0,0.35,0.023
               c0.122,0.022,0.016,0.046,0.039,0.137c0.022,0.091,0.075,0.053,0.129-0.046c0.062-0.106,0.068-0.161,0.022-0.259
               c-0.038-0.099-0.084-0.198,0-0.22c0.092-0.023,0.176-0.084,0.176,0.045c-0.008,0.13-0.1,0.183,0.046,0.213
               c0.137,0.031,0.304,0,0.341,0.046c0.032,0.046-0.014,0.061-0.014,0.137c0,0.068,0.076,0.107-0.069,0.107
               c-0.136,0.007-0.243-0.084-0.312-0.039c-0.076,0.046-0.213,0.076-0.099,0.137c0.121,0.062,0.167-0.03,0.228,0.062
               c0.062,0.091,0.183,0.114,0.252,0.083c0.068-0.03,0.061,0.046,0.168,0c0.106-0.045,0.037-0.145,0.145-0.221
               c0.105-0.069,0.364-0.076,0.228-0.136c-0.137-0.061-0.297-0.116-0.197-0.168c0.099-0.046,0.229-0.084,0.167-0.153
               c-0.068-0.068-0.106-0.091-0.083-0.144c0.03-0.061,0.167-0.153,0.145-0.237c-0.023-0.084,0.037-0.22-0.13-0.235
               c-0.167-0.008-0.229,0.007-0.297,0.06c-0.076,0.046,0.037,0.214-0.106,0.161c-0.146-0.054-0.175-0.137-0.274-0.099
               c-0.099,0.037-0.076,0.174-0.137,0.205c-0.07,0.038-0.145-0.061-0.198,0.023c-0.046,0.083,0.092,0.091-0.084,0.122
               c-0.168,0.023-0.235-0.137-0.297-0.122c-0.068,0.015-0.19,0.083-0.199-0.031c-0.014-0.106,0.07-0.129,0.131-0.213
               c0.068-0.083-0.076-0.212,0.068-0.228c0.145-0.008,0.509-0.114,0.335-0.229c-0.183-0.114-0.213,0.069-0.29-0.121
               c-0.067-0.191-0.076-0.313-0.076-0.313s0.26-0.205,0.191-0.357c-0.076-0.152-0.054-0.229-0.054-0.267
               c0.008-0.038-0.129-0.091-0.16-0.038c-0.03,0.053-0.091,0.145-0.151,0.159c-0.068,0.016-0.168,0.07-0.19,0.123
               c-0.023,0.045-0.32,0.175-0.357,0.221c-0.031,0.053-0.153,0.457-0.176,0.251c-0.016-0.198,0.121-0.259,0.008-0.275
               c-0.114-0.007-0.237,0.008-0.289,0.077c-0.046,0.069-0.13,0.183-0.19,0.267c-0.069,0.075-0.221,0.182-0.183,0.35
               s0.022,0.25,0.075,0.312c0.046,0.068,0.122,0.022,0.054,0.152c-0.068,0.129-0.083,0.229-0.054,0.297
               c0.038,0.069,0.062-0.084,0.168,0.069c0.098,0.152,0.03,0.213,0.03,0.297s0.046,0.19,0.138,0.175
               c0.014,0,0.03,0.007,0.037,0.023h0.016c0.068-0.008,0.122-0.023,0.168-0.039c0.068-0.023,0.121-0.038,0.175,0
               c0.008,0.008,0.023,0.016,0.03,0.023c0.03,0.023,0.054,0.038,0.168,0.023C121.261,22.56,121.405,22.567,121.52,22.575z"/>
           <!-- fin danmark-->
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
         <!-- royaume unis -->
         <a class="uk" onclick='DealerShow("uk_dealer","uk");' > 
         
               <path fill="#808184" d="M111.469,22.955c-0.038,0.023-0.343,0.328-0.175,0.297
               c0.168-0.03,0.502-0.319,0.381-0.358C111.553,22.857,111.469,22.955,111.469,22.955 M111.324,24.95c0,0,0.16-0.038,0.244-0.099
               c0.083-0.053,0.092-0.183,0.106-0.304c0.046-0.328-0.023-0.214-0.244-0.161c-0.121,0.031-0.183-0.03-0.183-0.076
               c0-0.052,0.198-0.083,0.244-0.175c0.046-0.084-0.16-0.099-0.16-0.213c0-0.107,0.251-0.008,0.297,0.031
               c0.046,0.038,0.145-0.008,0.313-0.054c0.167-0.045,0.228-0.007,0.297-0.015c0.061-0.007,0.152-0.03,0.183-0.084
               c0.03-0.053,0.046-0.113,0.121-0.152c0.084-0.031-0.068-0.107-0.068-0.183c-0.007-0.083,0.168-0.137,0.168-0.235
               c0-0.107-0.106,0.029-0.213,0.037c-0.106,0.008-0.114-0.06-0.13-0.136c-0.007-0.069-0.137-0.168-0.183-0.229
               c-0.046-0.06,0-0.137,0.16-0.274c0.152-0.13-0.038-0.068-0.145-0.053c-0.1,0.015-0.305,0.015-0.411,0
               c-0.106-0.008-0.061,0.106-0.13,0.121c-0.061,0.023-0.106-0.038-0.205-0.106c-0.099-0.077-0.252-0.023-0.252-0.13
               c0-0.098,0.267-0.327,0.282-0.357c0.015-0.031,0.114-0.054,0.083-0.161c-0.037-0.106-0.06-0.083-0.099-0.152
               c-0.03-0.075-0.091-0.06-0.129-0.175c-0.046-0.106-0.106,0-0.168,0.031c-0.054,0.038-0.007,0.121,0.046,0.167
               c0.054,0.038,0.054,0.107,0.068,0.183c0.008,0.076-0.121,0.045-0.175-0.015c-0.054-0.061-0.114,0-0.138,0.122
               c-0.015,0.121-0.075,0.068-0.174,0.053c-0.092-0.023,0-0.137,0.045-0.199c0.054-0.06,0.008-0.106,0.061-0.182
               c0.046-0.068,0.084-0.16,0.022-0.19c-0.053-0.031-0.061,0.083-0.167,0.122c-0.099,0.03-0.099,0.144-0.175,0.228
               c-0.068,0.084-0.13,0.031-0.145-0.031c-0.008-0.061-0.069-0.03-0.092-0.121c-0.015-0.099,0.138-0.123,0.236-0.145
               c0.106-0.023,0.19-0.137,0.206-0.175c0.008-0.038,0.151-0.038,0.19-0.107c0.037-0.069,0.029-0.091,0.113-0.19
               c0.076-0.092-0.015-0.076-0.099-0.061c-0.084,0.007-0.038,0.168-0.092,0.175c-0.045,0.015-0.091,0.046-0.19,0.062
               c-0.106,0.022-0.152,0.044-0.243,0.037c-0.091-0.015,0.03-0.076,0.129-0.106c0.092-0.038-0.022-0.061-0.099-0.092
               c-0.083-0.038,0.047-0.069,0.038-0.167c0-0.107,0.152-0.1,0.229-0.1c0.068,0,0.031-0.068,0.061-0.106
               c0.023-0.03,0.054-0.091,0.031-0.121c-0.023-0.038-0.068-0.023-0.084-0.062c-0.023-0.038-0.084-0.022-0.123-0.03
               c-0.037-0.008-0.045,0.038-0.06,0.107c-0.022,0.068-0.084,0.03-0.122-0.053c-0.03-0.084,0.061-0.077,0.083-0.108
               c0.023-0.022-0.022-0.045-0.037-0.091c-0.023-0.046-0.107-0.023-0.183-0.061c-0.084-0.038-0.046-0.068,0-0.084
               c0.037-0.014,0.022-0.075,0.061-0.121c0.038-0.046,0.099,0.031,0.137,0.015c0.039-0.015,0.022-0.061,0.062-0.114
               c0.03-0.054,0.137-0.023,0.144,0.046c0.008,0.068,0.016,0.068,0.008,0.145c-0.008,0.067-0.008,0.144,0.031,0.151
               c0.045,0.008,0.076,0.008,0.106,0.046c0.038,0.038,0.053,0.092,0.091,0.015c0.031-0.068-0.091-0.114-0.068-0.197
               c0.016-0.084,0.046,0,0.062-0.123c0.016-0.114-0.008-0.335,0.137-0.311c0.137,0.022,0.197,0.06,0.175-0.024
               c-0.03-0.091-0.091-0.098-0.03-0.144c0.068-0.046,0.068-0.054,0.177-0.16c0.105-0.099,0.06-0.213,0.227-0.229
               c0.168-0.007,0.19,0.016,0.381,0.031c0.19,0.008,0.198-0.031,0.389-0.023c0.197,0.008,0.389-0.076,0.419,0.015
               c0.022,0.091-0.008,0.107-0.084,0.214c-0.084,0.106-0.32,0.182-0.435,0.258c-0.113,0.076-0.221,0.16-0.145,0.168
               c0.084,0.007,0.184-0.046,0.106,0.046c-0.068,0.099-0.19,0.183-0.075,0.167c0.121-0.008,0.228-0.076,0.327-0.061
               c0.099,0.008,0.616-0.023,0.829-0.023c0.214,0,0.373,0.054,0.289,0.184c-0.083,0.136-0.228,0.113-0.266,0.304
               c-0.038,0.182-0.183,0.297-0.251,0.366c-0.076,0.06-0.212,0.136-0.267,0.281c-0.061,0.144-0.267,0.19-0.152,0.274
               c0.114,0.076,0.197,0.023,0.29,0.046c0.084,0.022,0.235,0.137,0.426,0.274c0.183,0.129,0.235,0.061,0.267,0.213
               c0.03,0.153-0.076,0.16,0.015,0.38c0.1,0.221-0.046,0.305,0.221,0.381c0.274,0.069,0.343-0.022,0.404,0.115
               c0.053,0.137,0.022,0.243,0.144,0.418c0.13,0.176,0.282,0.252,0.236,0.305c-0.045,0.061-0.212-0.023-0.145,0.084
               c0.062,0.113,0.16,0.061,0.19,0.205c0.038,0.145,0.106,0.16,0.038,0.267c-0.075,0.106-0.099,0.197,0.009,0.205
               c0.098,0.008,0.387-0.03,0.479-0.052c0.091-0.024,0.464-0.009,0.494,0.121c0.03,0.129-0.022,0.304-0.022,0.304
               s-0.198,0.328-0.297,0.404c-0.1,0.076-0.198,0.053-0.252,0.183c-0.046,0.122-0.137,0.023-0.152,0.144
               c-0.016,0.13,0.008,0.168,0.175,0.168c0.168,0,0.336-0.015,0.26,0.076c-0.069,0.084-0.267,0.114-0.366,0.251
               c-0.106,0.145-0.129,0.153-0.335,0.175c-0.206,0.023-0.076,0.114-0.426,0.03c-0.358-0.083-0.427-0.045-0.503-0.007
               c-0.067,0.031-0.152-0.068-0.214,0.023c-0.052,0.099-0.151,0.16-0.197,0.122c-0.053-0.038-0.053-0.099-0.175-0.084
               c-0.13,0.007-0.343,0.145-0.503,0.145s-0.16-0.069-0.251-0.099c-0.084-0.039-0.319-0.016-0.365,0
               c-0.038,0.023-0.175,0.114-0.259,0.251c-0.084,0.138,0.03,0.183-0.19,0.138c-0.214-0.039-0.229-0.108-0.381-0.084
               c-0.152,0.022-0.381,0.106-0.457,0.159c-0.068,0.054-0.106,0.251-0.167,0.19c-0.062-0.06-0.092-0.152-0.176-0.113
               c-0.091,0.03-0.259,0.007-0.175-0.092c0.084-0.092,0.259,0.023,0.411-0.144c0.152-0.176,0.214-0.198,0.343-0.305
               c0.122-0.107,0.129-0.328,0.259-0.343c0.138-0.022,0.175-0.091,0.213-0.129c0.046-0.046,0.068-0.053,0.175-0.03
               c0.114,0.022,0.313,0.075,0.465,0.046c0.152-0.032,0.138-0.115,0.221-0.168c0.084-0.061,0.213-0.244,0.038-0.161
               c-0.182,0.084-0.229,0.168-0.312,0.145c-0.077-0.023-0.092,0.038-0.198-0.068c-0.114-0.114-0.145-0.16-0.198-0.137
               c-0.061,0.023-0.122,0.068-0.259-0.008c-0.145-0.068-0.152-0.16-0.297-0.076c-0.152,0.084-0.19,0.114-0.244,0.068
               c-0.053-0.045-0.046-0.091-0.091-0.152c-0.046-0.061,0-0.251,0.129-0.281c0.13-0.038,0.122-0.016,0.206-0.016
               C111.241,24.988,111.324,24.95,111.324,24.95 M110.456,19.407c-0.015,0.061-0.083,0.13-0.152,0.16
               c-0.068,0.03-0.159-0.091-0.205,0.03c-0.053,0.13-0.008,0.183,0,0.252c0.016,0.068-0.1,0.205-0.022,0.205
               c0.083,0,0.106-0.03,0.175-0.114c0.068-0.076,0.213-0.061,0.274-0.137c0.053-0.076-0.039-0.228,0.06-0.274
               c0.1-0.053,0.168-0.213,0.069-0.229C110.563,19.293,110.456,19.407,110.456,19.407 M110.335,23.321
               c0.016,0,0.03-0.007,0.038-0.016c0.152-0.038,0.091-0.182,0.221-0.19c0.13-0.015,0.183,0.023,0.205-0.083
               c0.023-0.099,0.084-0.138-0.015-0.282c-0.092-0.153-0.1-0.153-0.152-0.259c-0.047-0.114-0.092-0.282-0.198-0.274
               c-0.106,0.015-0.167,0.068-0.343,0.022c-0.099-0.022-0.168-0.062-0.221-0.098c-0.014,0.046-0.038,0.091-0.068,0.129
               c-0.092,0.144-0.145,0.183-0.229,0.251c-0.023,0.016-0.054,0.046-0.091,0.076c-0.031,0.023-0.062,0.046-0.1,0.077
               c-0.122,0.099-0.259,0.205-0.251,0.289l0.008,0.038c0.007,0.076,0.007,0.099,0.106,0.145c0.106,0.046,0.145,0.03,0.251-0.023
               l0.016-0.008c0.046-0.023,0.068-0.068,0.084-0.106c0.007-0.023,0.022-0.046,0.037-0.069c0.038-0.046,0.084-0.054,0.168,0.023
               c0.099,0.084,0.121,0.106,0.16,0.137l0.091,0.084C110.114,23.23,110.235,23.283,110.335,23.321"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M111.469,22.955c-0.038,0.023-0.343,0.328-0.175,0.297c0.168-0.03,0.502-0.319,0.381-0.358
               C111.553,22.857,111.469,22.955,111.469,22.955z M111.324,24.95c0,0,0.16-0.038,0.244-0.099
               c0.083-0.053,0.092-0.183,0.106-0.304c0.046-0.328-0.023-0.214-0.244-0.161c-0.121,0.031-0.183-0.03-0.183-0.076
               c0-0.052,0.198-0.083,0.244-0.175c0.046-0.084-0.16-0.099-0.16-0.213c0-0.107,0.251-0.008,0.297,0.031
               c0.046,0.038,0.145-0.008,0.313-0.054c0.167-0.045,0.228-0.007,0.297-0.015c0.061-0.007,0.152-0.03,0.183-0.084
               c0.03-0.053,0.046-0.113,0.121-0.152c0.084-0.031-0.068-0.107-0.068-0.183c-0.007-0.083,0.168-0.137,0.168-0.235
               c0-0.107-0.106,0.029-0.213,0.037c-0.106,0.008-0.114-0.06-0.13-0.136c-0.007-0.069-0.137-0.168-0.183-0.229
               c-0.046-0.06,0-0.137,0.16-0.274c0.152-0.13-0.038-0.068-0.145-0.053c-0.1,0.015-0.305,0.015-0.411,0
               c-0.106-0.008-0.061,0.106-0.13,0.121c-0.061,0.023-0.106-0.038-0.205-0.106c-0.099-0.077-0.252-0.023-0.252-0.13
               c0-0.098,0.267-0.327,0.282-0.357c0.015-0.031,0.114-0.054,0.083-0.161c-0.037-0.106-0.06-0.083-0.099-0.152
               c-0.03-0.075-0.091-0.06-0.129-0.175c-0.046-0.106-0.106,0-0.168,0.031c-0.054,0.038-0.007,0.121,0.046,0.167
               c0.054,0.038,0.054,0.107,0.068,0.183c0.008,0.076-0.121,0.045-0.175-0.015c-0.054-0.061-0.114,0-0.138,0.122
               c-0.015,0.121-0.075,0.068-0.174,0.053c-0.092-0.023,0-0.137,0.045-0.199c0.054-0.06,0.008-0.106,0.061-0.182
               c0.046-0.068,0.084-0.16,0.022-0.19c-0.053-0.031-0.061,0.083-0.167,0.122c-0.099,0.03-0.099,0.144-0.175,0.228
               c-0.068,0.084-0.13,0.031-0.145-0.031c-0.008-0.061-0.069-0.03-0.092-0.121c-0.015-0.099,0.138-0.123,0.236-0.145
               c0.106-0.023,0.19-0.137,0.206-0.175c0.008-0.038,0.151-0.038,0.19-0.107c0.037-0.069,0.029-0.091,0.113-0.19
               c0.076-0.092-0.015-0.076-0.099-0.061c-0.084,0.007-0.038,0.168-0.092,0.175c-0.045,0.015-0.091,0.046-0.19,0.062
               c-0.106,0.022-0.152,0.044-0.243,0.037c-0.091-0.015,0.03-0.076,0.129-0.106c0.092-0.038-0.022-0.061-0.099-0.092
               c-0.083-0.038,0.047-0.069,0.038-0.167c0-0.107,0.152-0.1,0.229-0.1c0.068,0,0.031-0.068,0.061-0.106
               c0.023-0.03,0.054-0.091,0.031-0.121c-0.023-0.038-0.068-0.023-0.084-0.062c-0.023-0.038-0.084-0.022-0.123-0.03
               c-0.037-0.008-0.045,0.038-0.06,0.107c-0.022,0.068-0.084,0.03-0.122-0.053c-0.03-0.084,0.061-0.077,0.083-0.108
               c0.023-0.022-0.022-0.045-0.037-0.091c-0.023-0.046-0.107-0.023-0.183-0.061c-0.084-0.038-0.046-0.068,0-0.084
               c0.037-0.014,0.022-0.075,0.061-0.121c0.038-0.046,0.099,0.031,0.137,0.015c0.039-0.015,0.022-0.061,0.062-0.114
               c0.03-0.054,0.137-0.023,0.144,0.046c0.008,0.068,0.016,0.068,0.008,0.145c-0.008,0.067-0.008,0.144,0.031,0.151
               c0.045,0.008,0.076,0.008,0.106,0.046c0.038,0.038,0.053,0.092,0.091,0.015c0.031-0.068-0.091-0.114-0.068-0.197
               c0.016-0.084,0.046,0,0.062-0.123c0.016-0.114-0.008-0.335,0.137-0.311c0.137,0.022,0.197,0.06,0.175-0.024
               c-0.03-0.091-0.091-0.098-0.03-0.144c0.068-0.046,0.068-0.054,0.177-0.16c0.105-0.099,0.06-0.213,0.227-0.229
               c0.168-0.007,0.19,0.016,0.381,0.031c0.19,0.008,0.198-0.031,0.389-0.023c0.197,0.008,0.389-0.076,0.419,0.015
               c0.022,0.091-0.008,0.107-0.084,0.214c-0.084,0.106-0.32,0.182-0.435,0.258c-0.113,0.076-0.221,0.16-0.145,0.168
               c0.084,0.007,0.184-0.046,0.106,0.046c-0.068,0.099-0.19,0.183-0.075,0.167c0.121-0.008,0.228-0.076,0.327-0.061
               c0.099,0.008,0.616-0.023,0.829-0.023c0.214,0,0.373,0.054,0.289,0.184c-0.083,0.136-0.228,0.113-0.266,0.304
               c-0.038,0.182-0.183,0.297-0.251,0.366c-0.076,0.06-0.212,0.136-0.267,0.281c-0.061,0.144-0.267,0.19-0.152,0.274
               c0.114,0.076,0.197,0.023,0.29,0.046c0.084,0.022,0.235,0.137,0.426,0.274c0.183,0.129,0.235,0.061,0.267,0.213
               c0.03,0.153-0.076,0.16,0.015,0.38c0.1,0.221-0.046,0.305,0.221,0.381c0.274,0.069,0.343-0.022,0.404,0.115
               c0.053,0.137,0.022,0.243,0.144,0.418c0.13,0.176,0.282,0.252,0.236,0.305c-0.045,0.061-0.212-0.023-0.145,0.084
               c0.062,0.113,0.16,0.061,0.19,0.205c0.038,0.145,0.106,0.16,0.038,0.267c-0.075,0.106-0.099,0.197,0.009,0.205
               c0.098,0.008,0.387-0.03,0.479-0.052c0.091-0.024,0.464-0.009,0.494,0.121c0.03,0.129-0.022,0.304-0.022,0.304
               s-0.198,0.328-0.297,0.404c-0.1,0.076-0.198,0.053-0.252,0.183c-0.046,0.122-0.137,0.023-0.152,0.144
               c-0.016,0.13,0.008,0.168,0.175,0.168c0.168,0,0.336-0.015,0.26,0.076c-0.069,0.084-0.267,0.114-0.366,0.251
               c-0.106,0.145-0.129,0.153-0.335,0.175c-0.206,0.023-0.076,0.114-0.426,0.03c-0.358-0.083-0.427-0.045-0.503-0.007
               c-0.067,0.031-0.152-0.068-0.214,0.023c-0.052,0.099-0.151,0.16-0.197,0.122c-0.053-0.038-0.053-0.099-0.175-0.084
               c-0.13,0.007-0.343,0.145-0.503,0.145s-0.16-0.069-0.251-0.099c-0.084-0.039-0.319-0.016-0.365,0
               c-0.038,0.023-0.175,0.114-0.259,0.251c-0.084,0.138,0.03,0.183-0.19,0.138c-0.214-0.039-0.229-0.108-0.381-0.084
               c-0.152,0.022-0.381,0.106-0.457,0.159c-0.068,0.054-0.106,0.251-0.167,0.19c-0.062-0.06-0.092-0.152-0.176-0.113
               c-0.091,0.03-0.259,0.007-0.175-0.092c0.084-0.092,0.259,0.023,0.411-0.144c0.152-0.176,0.214-0.198,0.343-0.305
               c0.122-0.107,0.129-0.328,0.259-0.343c0.138-0.022,0.175-0.091,0.213-0.129c0.046-0.046,0.068-0.053,0.175-0.03
               c0.114,0.022,0.313,0.075,0.465,0.046c0.152-0.032,0.138-0.115,0.221-0.168c0.084-0.061,0.213-0.244,0.038-0.161
               c-0.182,0.084-0.229,0.168-0.312,0.145c-0.077-0.023-0.092,0.038-0.198-0.068c-0.114-0.114-0.145-0.16-0.198-0.137
               c-0.061,0.023-0.122,0.068-0.259-0.008c-0.145-0.068-0.152-0.16-0.297-0.076c-0.152,0.084-0.19,0.114-0.244,0.068
               c-0.053-0.045-0.046-0.091-0.091-0.152c-0.046-0.061,0-0.251,0.129-0.281c0.13-0.038,0.122-0.016,0.206-0.016
               C111.241,24.988,111.324,24.95,111.324,24.95z M110.456,19.407c-0.015,0.061-0.083,0.13-0.152,0.16
               c-0.068,0.03-0.159-0.091-0.205,0.03c-0.053,0.13-0.008,0.183,0,0.252c0.016,0.068-0.1,0.205-0.022,0.205
               c0.083,0,0.106-0.03,0.175-0.114c0.068-0.076,0.213-0.061,0.274-0.137c0.053-0.076-0.039-0.228,0.06-0.274
               c0.1-0.053,0.168-0.213,0.069-0.229C110.563,19.293,110.456,19.407,110.456,19.407z M110.335,23.321
               c0.016,0,0.03-0.007,0.038-0.016c0.152-0.038,0.091-0.182,0.221-0.19c0.13-0.015,0.183,0.023,0.205-0.083
               c0.023-0.099,0.084-0.138-0.015-0.282c-0.092-0.153-0.1-0.153-0.152-0.259c-0.047-0.114-0.092-0.282-0.198-0.274
               c-0.106,0.015-0.167,0.068-0.343,0.022c-0.099-0.022-0.168-0.062-0.221-0.098c-0.014,0.046-0.038,0.091-0.068,0.129
               c-0.092,0.144-0.145,0.183-0.229,0.251c-0.023,0.016-0.054,0.046-0.091,0.076c-0.031,0.023-0.062,0.046-0.1,0.077
               c-0.122,0.099-0.259,0.205-0.251,0.289l0.008,0.038c0.007,0.076,0.007,0.099,0.106,0.145c0.106,0.046,0.145,0.03,0.251-0.023
               l0.016-0.008c0.046-0.023,0.068-0.068,0.084-0.106c0.007-0.023,0.022-0.046,0.037-0.069c0.038-0.046,0.084-0.054,0.168,0.023
               c0.099,0.084,0.121,0.106,0.16,0.137l0.091,0.084C110.114,23.23,110.235,23.283,110.335,23.321z"/>
             <path fill="#808184" d="M109.626,22.079c0,0-0.281-0.015-0.426,0.137
               c-0.145,0.152-0.358,0.191-0.411,0.275c-0.046,0.084-0.282,0.19-0.137,0.259c0.137,0.068,0.259-0.023,0.259,0.06
               c0.008,0.092-0.138,0.061-0.206,0.152c-0.068,0.099-0.243,0.206-0.305,0.198c-0.068-0.015-0.235-0.144-0.373-0.121
               c-0.145,0.022-0.388,0.068-0.426,0.137c-0.031,0.076,0.084,0.091,0.099,0.152c0.008,0.054-0.068,0.031-0.099,0.129
               c-0.022,0.107,0.045,0.115-0.008,0.198c-0.054,0.077-0.175,0.191-0.038,0.213c0.13,0.031,0.175-0.03,0.222,0.031
               c0.037,0.061,0.007,0.061,0.144,0.061c0.146,0,0.305,0.023,0.198,0.099c-0.1,0.068-0.152,0.091-0.198,0.198
               c-0.038,0.107-0.16,0.129-0.197,0.205c-0.038,0.077,0.007,0.115,0.054,0.115c0.044-0.008,0.06,0.068-0.008,0.098
               c-0.069,0.031-0.123,0.031-0.298,0.092c-0.168,0.069-0.434,0.152-0.319,0.213c0.106,0.054,0.228-0.023,0.244,0.038
               c0.015,0.061-0.19,0.099-0.199,0.183c-0.008,0.084,0.16,0.069,0.183,0.122c0.023,0.053-0.099,0.107-0.022,0.137
               c0.076,0.038,0.146-0.038,0.19-0.008c0.046,0.031-0.016,0.069,0.099,0.092c0.106,0.023,0.054-0.039,0.274-0.031
               c0.22,0.008,0.625-0.106,0.754-0.198c0.129-0.092,0.229-0.008,0.328-0.092c0.091-0.083-0.008-0.144,0.174-0.197
               c0.191-0.046,0.274-0.092,0.442-0.076c0.168,0.015,0.19-0.122,0.297-0.061c0.105,0.061,0.13,0.076,0.152-0.008
               c0.022-0.076-0.076-0.152,0.054-0.259c0.129-0.106,0.167-0.038,0.197-0.228c0.038-0.191,0.016-0.267,0.016-0.389
               c0-0.121-0.031-0.289,0-0.357c0.022-0.076-0.183-0.183-0.122-0.259l0.008-0.007c-0.092-0.039-0.175-0.077-0.229-0.123
               l-0.092-0.083c-0.045-0.038-0.076-0.061-0.168-0.137c-0.022-0.023-0.022-0.03-0.022-0.03c-0.007,0.007-0.016,0.023-0.022,0.045
               c-0.022,0.046-0.054,0.114-0.129,0.145l-0.017,0.015c-0.137,0.069-0.189,0.091-0.342,0.023c-0.145-0.069-0.152-0.099-0.16-0.228
               l-0.008-0.039c-0.015-0.137,0.137-0.259,0.289-0.373c0.031-0.03,0.062-0.053,0.092-0.084c0.038-0.03,0.069-0.053,0.1-0.076
               c0.075-0.061,0.121-0.099,0.205-0.228c0.031-0.038,0.054-0.092,0.069-0.137h-0.009
               C109.749,22.041,109.626,22.079,109.626,22.079 M109.756,20.138c-0.084,0.03-0.159,0.076-0.084,0.121
               c0.077,0.054,0.084,0.091,0.054,0.145c-0.022,0.054-0.022,0.175-0.038,0.213c-0.008,0.039-0.046,0.084-0.076,0.13
               c-0.03,0.046-0.099,0.144,0.008,0.076c0.107-0.068,0.213-0.167,0.213-0.229c0-0.061,0.023-0.115,0.062-0.191
               c0.037-0.076,0.205-0.281,0.091-0.289C109.878,20.107,109.756,20.138,109.756,20.138"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M109.626,22.079c0,0-0.281-0.015-0.426,0.137c-0.145,0.152-0.358,0.191-0.411,0.275c-0.046,0.084-0.282,0.19-0.137,0.259
               c0.137,0.068,0.259-0.023,0.259,0.06c0.008,0.092-0.138,0.061-0.206,0.152c-0.068,0.099-0.243,0.206-0.305,0.198
               c-0.068-0.015-0.235-0.144-0.373-0.121c-0.145,0.022-0.388,0.068-0.426,0.137c-0.031,0.076,0.084,0.091,0.099,0.152
               c0.008,0.054-0.068,0.031-0.099,0.129c-0.022,0.107,0.045,0.115-0.008,0.198c-0.054,0.077-0.175,0.191-0.038,0.213
               c0.13,0.031,0.175-0.03,0.222,0.031c0.037,0.061,0.007,0.061,0.144,0.061c0.146,0,0.305,0.023,0.198,0.099
               c-0.1,0.068-0.152,0.091-0.198,0.198c-0.038,0.107-0.16,0.129-0.197,0.205c-0.038,0.077,0.007,0.115,0.054,0.115
               c0.044-0.008,0.06,0.068-0.008,0.098c-0.069,0.031-0.123,0.031-0.298,0.092c-0.168,0.069-0.434,0.152-0.319,0.213
               c0.106,0.054,0.228-0.023,0.244,0.038c0.015,0.061-0.19,0.099-0.199,0.183c-0.008,0.084,0.16,0.069,0.183,0.122
               c0.023,0.053-0.099,0.107-0.022,0.137c0.076,0.038,0.146-0.038,0.19-0.008c0.046,0.031-0.016,0.069,0.099,0.092
               c0.106,0.023,0.054-0.039,0.274-0.031c0.22,0.008,0.625-0.106,0.754-0.198c0.129-0.092,0.229-0.008,0.328-0.092
               c0.091-0.083-0.008-0.144,0.174-0.197c0.191-0.046,0.274-0.092,0.442-0.076c0.168,0.015,0.19-0.122,0.297-0.061
               c0.105,0.061,0.13,0.076,0.152-0.008c0.022-0.076-0.076-0.152,0.054-0.259c0.129-0.106,0.167-0.038,0.197-0.228
               c0.038-0.191,0.016-0.267,0.016-0.389c0-0.121-0.031-0.289,0-0.357c0.022-0.076-0.183-0.183-0.122-0.259l0.008-0.007
               c-0.092-0.039-0.175-0.077-0.229-0.123l-0.092-0.083c-0.045-0.038-0.076-0.061-0.168-0.137c-0.022-0.023-0.022-0.03-0.022-0.03
               c-0.007,0.007-0.016,0.023-0.022,0.045c-0.022,0.046-0.054,0.114-0.129,0.145l-0.017,0.015
               c-0.137,0.069-0.189,0.091-0.342,0.023c-0.145-0.069-0.152-0.099-0.16-0.228l-0.008-0.039c-0.015-0.137,0.137-0.259,0.289-0.373
               c0.031-0.03,0.062-0.053,0.092-0.084c0.038-0.03,0.069-0.053,0.1-0.076c0.075-0.061,0.121-0.099,0.205-0.228
               c0.031-0.038,0.054-0.092,0.069-0.137h-0.009C109.749,22.041,109.626,22.079,109.626,22.079z M109.756,20.138
               c-0.084,0.03-0.159,0.076-0.084,0.121c0.077,0.054,0.084,0.091,0.054,0.145c-0.022,0.054-0.022,0.175-0.038,0.213
               c-0.008,0.039-0.046,0.084-0.076,0.13c-0.03,0.046-0.099,0.144,0.008,0.076c0.107-0.068,0.213-0.167,0.213-0.229
               c0-0.061,0.023-0.115,0.062-0.191c0.037-0.076,0.205-0.281,0.091-0.289C109.878,20.107,109.756,20.138,109.756,20.138z"/>
              </a>
           <!-- fin royaume unis -->
           
        
             
        
        
        
        
        
        
        
        
           
               <path fill="#808184" d="M139.634,40.398c-0.039,0-0.273-0.076-0.289,0.031
               c-0.008,0.107,0.016,0.167-0.021,0.167c-0.039,0-0.207-0.092-0.244-0.03c-0.038,0.068-0.047,0.099-0.107,0.099
               c-0.068,0-0.076,0.121-0.021,0.19c0.053,0.068,0.015,0.114,0.167,0.168c0.146,0.052,0.251,0.061,0.306,0.052
               c0.053,0,0.084,0.077,0.168,0.039c0.082-0.03,0.137-0.122,0.203-0.146c0.068-0.015,0.161-0.075,0.176-0.13
               c0.023-0.053,0.084-0.06,0.107-0.053c0.021,0.008,0.305,0.084,0.206-0.053c-0.107-0.137-0.16-0.175-0.107-0.212
               c0.054-0.039,0.046-0.092,0.13-0.123c0.076-0.038,0.061-0.114,0.146-0.159c0.083-0.046,0.1-0.13,0.067-0.137
               c-0.022,0-0.067,0.068-0.213,0.121c-0.137,0.053-0.214,0.13-0.357,0.145c-0.137,0.015-0.168,0.053-0.213,0.061
               C139.679,40.429,139.634,40.398,139.634,40.398"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M139.634,40.398c-0.039,0-0.273-0.076-0.289,0.031c-0.008,0.107,0.016,0.167-0.021,0.167c-0.039,0-0.207-0.092-0.244-0.03
               c-0.038,0.068-0.047,0.099-0.107,0.099c-0.068,0-0.076,0.121-0.021,0.19c0.053,0.068,0.015,0.114,0.167,0.168
               c0.146,0.052,0.251,0.061,0.306,0.052c0.053,0,0.084,0.077,0.168,0.039c0.082-0.03,0.137-0.122,0.203-0.146
               c0.068-0.015,0.161-0.075,0.176-0.13c0.023-0.053,0.084-0.06,0.107-0.053c0.021,0.008,0.305,0.084,0.206-0.053
               c-0.107-0.137-0.16-0.175-0.107-0.212c0.054-0.039,0.046-0.092,0.13-0.123c0.076-0.038,0.061-0.114,0.146-0.159
               c0.083-0.046,0.1-0.13,0.067-0.137c-0.022,0-0.067,0.068-0.213,0.121c-0.137,0.053-0.214,0.13-0.357,0.145
               c-0.137,0.015-0.168,0.053-0.213,0.061C139.679,40.429,139.634,40.398,139.634,40.398z"/>
        
        
        
        
        
        
        
        
        
        
        
        
        
        <!-- asie -->
                <a class="asie" onclick='DealerShow("asie_dealer","asie");' > 
                
             <path fill="#808082" d="M133.358,35.572c-0.014,0-0.196,0.061-0.131,0.091
               c0.078,0.031,0.084,0.069,0.146,0.069c0.053,0,0.13-0.153,0.1-0.183C133.437,35.511,133.358,35.572,133.358,35.572
                M148.345,38.762c-0.076,0.03-0.205,0.084-0.273,0.114c-0.016,0.008-0.016,0.023-0.016,0.015
               c-0.009-0.007-0.009-0.045-0.009-0.099v-0.045c-0.007-0.107-0.046-0.13-0.106-0.13c-0.022,0-0.047,0.008-0.067,0.016
               c-0.031,0.007-0.062,0.015-0.091,0.015c-0.084-0.015-0.254,0-0.358,0.007h-0.092c-0.022,0-0.084-0.007-0.16-0.022
               c-0.106-0.023-0.228-0.046-0.29-0.046c-0.066,0-0.143,0.046-0.213,0.114c-0.029,0.022-0.053,0.054-0.083,0.084
               c-0.045-0.046-0.151-0.114-0.213-0.137c-0.062-0.015-0.106,0.007-0.168,0.037c-0.037,0.016-0.09,0.038-0.151,0.054
               c-0.054,0.008-0.093,0.03-0.123,0.046c-0.061,0.03-0.105,0.053-0.259,0.022c-0.205-0.045-0.358,0-0.51,0.054
               c-0.038,0.015-0.084,0.03-0.138,0.045c-0.062,0.016-0.106,0.039-0.151,0.054c-0.091,0.03-0.183,0.069-0.487,0.114
               c-0.183,0.031-0.289,0.054-0.357,0.068c-0.084,0.023-0.113,0.031-0.229,0.016c-0.092-0.008-0.145-0.045-0.189-0.076
               c-0.061-0.038-0.123-0.069-0.221-0.077c-0.1,0-0.184,0.031-0.272,0.069c-0.101,0.039-0.207,0.076-0.345,0.076
               c-0.151,0-0.182,0.023-0.205,0.038c0,0,0.008,0-0.069-0.03c-0.074-0.023-0.099-0.053-0.121-0.076
               c-0.037-0.038-0.067-0.069-0.19-0.084c-0.099-0.008-0.137,0.038-0.144,0.122c-0.017,0.076,0.015,0.19,0.045,0.289
               c0.015,0.053,0.03,0.099,0.03,0.137c0.009,0.061,0.009,0.061-0.008,0.061c-0.016,0-0.045,0.007-0.075,0.016
               c-0.076,0.03-0.101,0.106-0.115,0.189c-0.015,0.062-0.03,0.115-0.053,0.13c-0.022,0.016-0.038,0.007-0.076-0.023
               c-0.022-0.015-0.062-0.045-0.112-0.076c-0.011-0.068-0.039-0.145-0.078-0.198c-0.062-0.092-0.021-0.129,0.063-0.236
               c0.083-0.106,0.266-0.419,0.006-0.38c-0.257,0.038-0.229,0.212-0.439,0.258c-0.207,0.046-0.146-0.023-0.344-0.068
               c-0.197-0.046-0.389-0.106-0.457-0.007c-0.061,0.091-0.25,0.235-0.381,0.364c-0.129,0.13-0.335,0.138-0.494,0.153
               c-0.152,0.023-0.41,0.145-0.617-0.015c-0.205-0.161-0.197-0.183-0.317-0.297c-0.123-0.114-0.274-0.183-0.563-0.312
               c-0.289-0.122-0.549-0.076-0.572,0.015c-0.021,0.092,0.054,0.403-0.068,0.456c-0.121,0.061-0.167-0.03-0.326,0.047
               c-0.16,0.083-0.242,0.182-0.396,0.068c-0.145-0.115-0.402-0.168-0.434-0.244c-0.039-0.083,0.045-0.373-0.113-0.305
               c-0.16,0.07-0.229,0.092-0.313,0.023c-0.092-0.069-0.274,0.047-0.274,0.047s-0.053-0.07-0.189-0.062
               c-0.137,0.015-0.457,0.053-0.35-0.022c0.1-0.069,0.158-0.039,0.272-0.069c0.122-0.023,0.297-0.099,0.26-0.153
               c-0.03-0.06-0.313-0.022-0.358-0.007s-0.137,0.031-0.196-0.008c-0.062-0.045-0.138-0.084-0.062-0.136
               c0.084-0.061,0.166-0.13,0.062-0.161c-0.106-0.021-0.199,0.054-0.185-0.084c0.016-0.136,0.068-0.205-0.082-0.205
               c-0.152,0-0.252,0.008-0.291-0.015c-0.029-0.015-0.152-0.145-0.076-0.153c0.068,0,0.176,0.077,0.244,0.054
               c0.062-0.022,0.176-0.008,0.189-0.054c0.008-0.045,0.069-0.129-0.021-0.151c-0.092-0.024-0.213-0.061-0.328-0.138
               c-0.113-0.076-0.16-0.015-0.221-0.045c-0.053-0.031-0.189-0.069-0.121-0.137c0.068-0.069,0.054-0.092,0.022-0.198
               c-0.038-0.1-0.084-0.221,0.03-0.176c0.121,0.047,0.068,0.138,0.158,0.198c0.093,0.054,0.213,0.16,0.162,0.024
               c-0.047-0.146-0.168-0.229-0.069-0.259c0.091-0.023,0.243-0.031,0.146-0.13c-0.101-0.092-0.123-0.038-0.138-0.167
               c-0.008-0.123-0.176-0.13-0.114-0.244c0.061-0.115,0.114-0.252,0.016-0.252c-0.1-0.007-0.326,0.13-0.381,0.107
               c-0.062-0.023-0.189-0.083-0.129-0.16c0.053-0.076,0.022-0.236,0.029-0.312c0.008-0.068,0.184-0.198,0.32-0.305
               c0.128-0.106,0.229-0.167,0.342-0.121c0.107,0.053,0.313,0.007,0.389,0.068c0.069,0.061,0.038-0.122,0.176-0.129
               c0.139-0.008,0.197,0.061,0.244,0.091c0.045,0.031,0.229,0.023,0.313,0.023c0.083,0,0.258,0.045,0.281-0.091
               c0.029-0.138,0.082-0.138,0.183-0.16c0.091-0.015,0.205-0.077,0.065-0.191c-0.144-0.114-0.213-0.091-0.424-0.084
               c-0.213,0.008-0.238-0.106-0.473-0.075c-0.236,0.022-0.29,0.106-0.365,0.106c-0.084,0-0.26,0.107-0.306,0.16
               c-0.046,0.061-0.054,0.168-0.151,0.145c-0.106-0.023-0.076-0.061-0.236-0.031c-0.166,0.031-0.273,0.046-0.319,0.038
               c-0.053-0.007-0.198-0.099-0.122-0.136c0.077-0.039,0.237-0.062,0.252-0.168c0.008-0.107,0.008-0.252,0.061-0.29
               c0.063-0.038,0.16-0.114,0.152-0.167c-0.015-0.046-0.205-0.167-0.144-0.259c0.06-0.084,0.166-0.16,0.312-0.182
               c0.137-0.024,0.145-0.07,0.184-0.039c0.037,0.031,0.168,0.13,0.305,0.099c0.145-0.023,0.213-0.114,0.244-0.053
               c0.021,0.053,0.029,0.175,0.229,0.304c0.196,0.13,0.389,0.183,0.67,0.237c0.281,0.045,0.174,0.106,0.532,0.136
               c0.358,0.039,0.709,0.016,0.876,0.077c0.159,0.053,0.352,0.03,0.465-0.099c0.113-0.13,0.229-0.198,0.303-0.237
               c0.07-0.03,0.647-0.517,0.886-0.502c0.228,0.007,0.213,0.069,0.494,0.053c0.282-0.015,0.274-0.03,0.479-0.03
               c0.197,0.007,0.273-0.13,0.357-0.068c0.076,0.061,0.007,0.182,0.113,0.258c0.1,0.069,0.299,0.16,0.465,0.122
               c0.168-0.038,0.328-0.076,0.328,0.069c0,0.144-0.068,0.198,0.068,0.251c0.129,0.061,0.418-0.176,0.578-0.061
               c0.152,0.114,0.121,0.198,0.235,0.251c0.106,0.046,0.243-0.007,0.343,0.03c0.105,0.039,0.236,0.123,0.305,0.123h0.403
               c0.062,0,0.449-0.092,0.603-0.092c0.151,0.008,0.008,0.038,0.235,0.069c0.222,0.023,0.222,0.084,0.487,0.007
               c0.266-0.076,0.539-0.266,0.615-0.342c0.023-0.023,0.056-0.054,0.084-0.092c0.045,0.008,0.084,0.023,0.129,0.031
               c0.199,0.038,0.244,0.015,0.283-0.008c0.022-0.008,0.045-0.023,0.189,0.015c0.221,0.054,0.305,0,0.365-0.038
               c0.023-0.014,0.037-0.023,0.068-0.007c0.053,0.038,0.092,0.091,0.131,0.145c0.037,0.054,0.065,0.107,0.129,0.138
               c0.068,0.038,0.099,0.038,0.183,0.06c0.022,0,0.052,0.008,0.084,0.015c0.017,0.061,0.06,0.206,0.144,0.245
               c0.056,0.03,0.039,0.159,0.023,0.212v0.008c-0.007,0.038,0.016,0.107,0.038,0.183c0.022,0.068,0.045,0.137,0.045,0.19
               c0,0.092,0.054,0.137,0.146,0.16c0.069,0.015,0.16,0.015,0.244,0.015c0.074,0,0.129,0.015,0.168,0.046
               c0.045,0.03,0.084,0.076,0.129,0.137c0.022,0.023,0.045,0.046,0.067,0.068c-0.052,0.046-0.129,0.122-0.106,0.213
               c0.009,0.039,0.009,0.061,0,0.077c-0.006,0.007-0.016,0.015-0.028,0.015l-0.022,0.008c-0.068,0.023-0.146,0.045-0.113,0.159
               c0.006,0.038,0.037,0.069,0.074,0.107c0.055,0.046,0.113,0.114,0.092,0.206c-0.045,0.137,0.008,0.182,0.062,0.228
               c0.022,0.015,0.045,0.031,0.045,0.069c0.009,0.068,0.015,0.122,0.031,0.167c0.015,0.046,0.038,0.091,0.06,0.137
               c0.01,0.023-0.007,0.046-0.029,0.077c-0.029,0.038-0.053,0.068-0.047,0.121c0,0.008,0.01,0.023,0.01,0.038
               c0.008,0.077,0.021,0.152,0.145,0.152c0.039,0,0.092,0.008,0.131,0.023c0.021,0.016,0.037,0.046,0.021,0.114
               c-0.038,0.198,0.016,0.29,0.016,0.29l0.008,0.015l0.015,0.008C148.19,38.648,148.269,38.685,148.345,38.762 M135.354,39.508
               c-0.054,0.053-0.113,0.099-0.092,0.221c0.031,0.121,0.069,0.229,0.131,0.152c0.061-0.069,0.188-0.258,0.222-0.335
               c0.028-0.076,0.159-0.19,0-0.19C135.453,39.356,135.354,39.508,135.354,39.508 M133.093,36.051c-0.021,0-0.15,0.023-0.137,0.107
               c0.016,0.076,0.1,0.106,0.15,0.106c0.063,0.008,0.121-0.007,0.14-0.061c0.007-0.045,0.099-0.19,0.028-0.22
               C133.199,35.96,133.093,36.051,133.093,36.051"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M133.358,35.572c-0.014,0-0.196,0.061-0.131,0.091c0.078,0.031,0.084,0.069,0.146,0.069c0.053,0,0.13-0.153,0.1-0.183
               C133.437,35.511,133.358,35.572,133.358,35.572z M148.345,38.762c-0.076,0.03-0.205,0.084-0.273,0.114
               c-0.016,0.008-0.016,0.023-0.016,0.015c-0.009-0.007-0.009-0.045-0.009-0.099v-0.045c-0.007-0.107-0.046-0.13-0.106-0.13
               c-0.022,0-0.047,0.008-0.067,0.016c-0.031,0.007-0.062,0.015-0.091,0.015c-0.084-0.015-0.254,0-0.358,0.007h-0.092
               c-0.022,0-0.084-0.007-0.16-0.022c-0.106-0.023-0.228-0.046-0.29-0.046c-0.066,0-0.143,0.046-0.213,0.114
               c-0.029,0.022-0.053,0.054-0.083,0.084c-0.045-0.046-0.151-0.114-0.213-0.137c-0.062-0.015-0.106,0.007-0.168,0.037
               c-0.037,0.016-0.09,0.038-0.151,0.054c-0.054,0.008-0.093,0.03-0.123,0.046c-0.061,0.03-0.105,0.053-0.259,0.022
               c-0.205-0.045-0.358,0-0.51,0.054c-0.038,0.015-0.084,0.03-0.138,0.045c-0.062,0.016-0.106,0.039-0.151,0.054
               c-0.091,0.03-0.183,0.069-0.487,0.114c-0.183,0.031-0.289,0.054-0.357,0.068c-0.084,0.023-0.113,0.031-0.229,0.016
               c-0.092-0.008-0.145-0.045-0.189-0.076c-0.061-0.038-0.123-0.069-0.221-0.077c-0.1,0-0.184,0.031-0.272,0.069
               c-0.101,0.039-0.207,0.076-0.345,0.076c-0.151,0-0.182,0.023-0.205,0.038c0,0,0.008,0-0.069-0.03
               c-0.074-0.023-0.099-0.053-0.121-0.076c-0.037-0.038-0.067-0.069-0.19-0.084c-0.099-0.008-0.137,0.038-0.144,0.122
               c-0.017,0.076,0.015,0.19,0.045,0.289c0.015,0.053,0.03,0.099,0.03,0.137c0.009,0.061,0.009,0.061-0.008,0.061
               c-0.016,0-0.045,0.007-0.075,0.016c-0.076,0.03-0.101,0.106-0.115,0.189c-0.015,0.062-0.03,0.115-0.053,0.13
               c-0.022,0.016-0.038,0.007-0.076-0.023c-0.022-0.015-0.062-0.045-0.112-0.076c-0.011-0.068-0.039-0.145-0.078-0.198
               c-0.062-0.092-0.021-0.129,0.063-0.236c0.083-0.106,0.266-0.419,0.006-0.38c-0.257,0.038-0.229,0.212-0.439,0.258
               c-0.207,0.046-0.146-0.023-0.344-0.068c-0.197-0.046-0.389-0.106-0.457-0.007c-0.061,0.091-0.25,0.235-0.381,0.364
               c-0.129,0.13-0.335,0.138-0.494,0.153c-0.152,0.023-0.41,0.145-0.617-0.015c-0.205-0.161-0.197-0.183-0.317-0.297
               c-0.123-0.114-0.274-0.183-0.563-0.312c-0.289-0.122-0.549-0.076-0.572,0.015c-0.021,0.092,0.054,0.403-0.068,0.456
               c-0.121,0.061-0.167-0.03-0.326,0.047c-0.16,0.083-0.242,0.182-0.396,0.068c-0.145-0.115-0.402-0.168-0.434-0.244
               c-0.039-0.083,0.045-0.373-0.113-0.305c-0.16,0.07-0.229,0.092-0.313,0.023c-0.092-0.069-0.274,0.047-0.274,0.047
               s-0.053-0.07-0.189-0.062c-0.137,0.015-0.457,0.053-0.35-0.022c0.1-0.069,0.158-0.039,0.272-0.069
               c0.122-0.023,0.297-0.099,0.26-0.153c-0.03-0.06-0.313-0.022-0.358-0.007s-0.137,0.031-0.196-0.008
               c-0.062-0.045-0.138-0.084-0.062-0.136c0.084-0.061,0.166-0.13,0.062-0.161c-0.106-0.021-0.199,0.054-0.185-0.084
               c0.016-0.136,0.068-0.205-0.082-0.205c-0.152,0-0.252,0.008-0.291-0.015c-0.029-0.015-0.152-0.145-0.076-0.153
               c0.068,0,0.176,0.077,0.244,0.054c0.062-0.022,0.176-0.008,0.189-0.054c0.008-0.045,0.069-0.129-0.021-0.151
               c-0.092-0.024-0.213-0.061-0.328-0.138c-0.113-0.076-0.16-0.015-0.221-0.045c-0.053-0.031-0.189-0.069-0.121-0.137
               c0.068-0.069,0.054-0.092,0.022-0.198c-0.038-0.1-0.084-0.221,0.03-0.176c0.121,0.047,0.068,0.138,0.158,0.198
               c0.093,0.054,0.213,0.16,0.162,0.024c-0.047-0.146-0.168-0.229-0.069-0.259c0.091-0.023,0.243-0.031,0.146-0.13
               c-0.101-0.092-0.123-0.038-0.138-0.167c-0.008-0.123-0.176-0.13-0.114-0.244c0.061-0.115,0.114-0.252,0.016-0.252
               c-0.1-0.007-0.326,0.13-0.381,0.107c-0.062-0.023-0.189-0.083-0.129-0.16c0.053-0.076,0.022-0.236,0.029-0.312
               c0.008-0.068,0.184-0.198,0.32-0.305c0.128-0.106,0.229-0.167,0.342-0.121c0.107,0.053,0.313,0.007,0.389,0.068
               c0.069,0.061,0.038-0.122,0.176-0.129c0.139-0.008,0.197,0.061,0.244,0.091c0.045,0.031,0.229,0.023,0.313,0.023
               c0.083,0,0.258,0.045,0.281-0.091c0.029-0.138,0.082-0.138,0.183-0.16c0.091-0.015,0.205-0.077,0.065-0.191
               c-0.144-0.114-0.213-0.091-0.424-0.084c-0.213,0.008-0.238-0.106-0.473-0.075c-0.236,0.022-0.29,0.106-0.365,0.106
               c-0.084,0-0.26,0.107-0.306,0.16c-0.046,0.061-0.054,0.168-0.151,0.145c-0.106-0.023-0.076-0.061-0.236-0.031
               c-0.166,0.031-0.273,0.046-0.319,0.038c-0.053-0.007-0.198-0.099-0.122-0.136c0.077-0.039,0.237-0.062,0.252-0.168
               c0.008-0.107,0.008-0.252,0.061-0.29c0.063-0.038,0.16-0.114,0.152-0.167c-0.015-0.046-0.205-0.167-0.144-0.259
               c0.06-0.084,0.166-0.16,0.312-0.182c0.137-0.024,0.145-0.07,0.184-0.039c0.037,0.031,0.168,0.13,0.305,0.099
               c0.145-0.023,0.213-0.114,0.244-0.053c0.021,0.053,0.029,0.175,0.229,0.304c0.196,0.13,0.389,0.183,0.67,0.237
               c0.281,0.045,0.174,0.106,0.532,0.136c0.358,0.039,0.709,0.016,0.876,0.077c0.159,0.053,0.352,0.03,0.465-0.099
               c0.113-0.13,0.229-0.198,0.303-0.237c0.07-0.03,0.647-0.517,0.886-0.502c0.228,0.007,0.213,0.069,0.494,0.053
               c0.282-0.015,0.274-0.03,0.479-0.03c0.197,0.007,0.273-0.13,0.357-0.068c0.076,0.061,0.007,0.182,0.113,0.258
               c0.1,0.069,0.299,0.16,0.465,0.122c0.168-0.038,0.328-0.076,0.328,0.069c0,0.144-0.068,0.198,0.068,0.251
               c0.129,0.061,0.418-0.176,0.578-0.061c0.152,0.114,0.121,0.198,0.235,0.251c0.106,0.046,0.243-0.007,0.343,0.03
               c0.105,0.039,0.236,0.123,0.305,0.123h0.403c0.062,0,0.449-0.092,0.603-0.092c0.151,0.008,0.008,0.038,0.235,0.069
               c0.222,0.023,0.222,0.084,0.487,0.007c0.266-0.076,0.539-0.266,0.615-0.342c0.023-0.023,0.056-0.054,0.084-0.092
               c0.045,0.008,0.084,0.023,0.129,0.031c0.199,0.038,0.244,0.015,0.283-0.008c0.022-0.008,0.045-0.023,0.189,0.015
               c0.221,0.054,0.305,0,0.365-0.038c0.023-0.014,0.037-0.023,0.068-0.007c0.053,0.038,0.092,0.091,0.131,0.145
               c0.037,0.054,0.065,0.107,0.129,0.138c0.068,0.038,0.099,0.038,0.183,0.06c0.022,0,0.052,0.008,0.084,0.015
               c0.017,0.061,0.06,0.206,0.144,0.245c0.056,0.03,0.039,0.159,0.023,0.212v0.008c-0.007,0.038,0.016,0.107,0.038,0.183
               c0.022,0.068,0.045,0.137,0.045,0.19c0,0.092,0.054,0.137,0.146,0.16c0.069,0.015,0.16,0.015,0.244,0.015
               c0.074,0,0.129,0.015,0.168,0.046c0.045,0.03,0.084,0.076,0.129,0.137c0.022,0.023,0.045,0.046,0.067,0.068
               c-0.052,0.046-0.129,0.122-0.106,0.213c0.009,0.039,0.009,0.061,0,0.077c-0.006,0.007-0.016,0.015-0.028,0.015l-0.022,0.008
               c-0.068,0.023-0.146,0.045-0.113,0.159c0.006,0.038,0.037,0.069,0.074,0.107c0.055,0.046,0.113,0.114,0.092,0.206
               c-0.045,0.137,0.008,0.182,0.062,0.228c0.022,0.015,0.045,0.031,0.045,0.069c0.009,0.068,0.015,0.122,0.031,0.167
               c0.015,0.046,0.038,0.091,0.06,0.137c0.01,0.023-0.007,0.046-0.029,0.077c-0.029,0.038-0.053,0.068-0.047,0.121
               c0,0.008,0.01,0.023,0.01,0.038c0.008,0.077,0.021,0.152,0.145,0.152c0.039,0,0.092,0.008,0.131,0.023
               c0.021,0.016,0.037,0.046,0.021,0.114c-0.038,0.198,0.016,0.29,0.016,0.29l0.008,0.015l0.015,0.008
               C148.19,38.648,148.269,38.685,148.345,38.762z M135.354,39.508c-0.054,0.053-0.113,0.099-0.092,0.221
               c0.031,0.121,0.069,0.229,0.131,0.152c0.061-0.069,0.188-0.258,0.222-0.335c0.028-0.076,0.159-0.19,0-0.19
               C135.453,39.356,135.354,39.508,135.354,39.508z M133.093,36.051c-0.021,0-0.15,0.023-0.137,0.107
               c0.016,0.076,0.1,0.106,0.15,0.106c0.063,0.008,0.121-0.007,0.14-0.061c0.007-0.045,0.099-0.19,0.028-0.22
               C133.199,35.96,133.093,36.051,133.093,36.051z"/>
             <path fill="#808184" d="M156.72,57.941c-0.068,0.03-0.121,0.053-0.168,0.091
               c-0.251,0.168-0.51,0.419-0.51,0.579c0,0.167,0.145,0.213,0.029,0.328c-0.113,0.113-0.243,0.19-0.465,0.251
               c-0.228,0.068-0.234,0.068-0.465,0.175c-0.236,0.114-0.26-0.069-0.524,0.167c-0.267,0.229-0.229,0.175-0.464,0.183
               c-0.236,0.015-0.641,0.358-0.717,0.495c-0.084,0.137-0.168,0.274-0.344,0.258c-0.166-0.007-0.388-0.265-0.523-0.007
               c-0.139,0.259-0.114,0.32-0.525,0.38c-0.419,0.054-0.678,0.176-0.891,0.176c-0.215,0-0.396-0.06-0.495,0.114
               c-0.101,0.183-0.168,0.343-0.327,0.381c-0.16,0.045-0.214-0.03-0.357,0.045c-0.145,0.084-0.473,0.161-0.473,0.161
               s-0.184-0.092-0.352-0.138c-0.166-0.045-0.166-0.076-0.205-0.289c-0.029-0.213-0.197-0.35-0.176-0.533
               c0.023-0.175,0.101-0.319-0.016-0.51c-0.105-0.198-0.197-0.35-0.221-0.625c-0.021-0.265-0.168-0.258-0.159-0.494
               c0.015-0.236,0.075-0.495,0.075-0.594c0-0.069-0.014-0.16-0.045-0.26c0.107-0.068,0.199-0.159,0.25-0.296
               c0.055-0.167,0.039-0.343,0.031-0.495c-0.017-0.137-0.022-0.251,0.039-0.289c0.067-0.046,0.129-0.068,0.196-0.061
               c0.067,0,0.138,0.03,0.229,0.091c0.121,0.091,0.281,0.069,0.479,0.046c0.149-0.015,0.327-0.038,0.517-0.008
               c0.213,0.023,0.367,0.076,0.504,0.115c0.138,0.045,0.252,0.083,0.396,0.083h0.144c0.191-0.007,0.352-0.007,0.436,0.077
               c0.029,0.03,0.045,0.068,0.061,0.098c0,0.008,0.008,0.023,0.017,0.031c0.038,0.084,0.101,0.114,0.319,0.008
               c0.184-0.091,0.236-0.236,0.304-0.389c0.063-0.137,0.123-0.289,0.282-0.434c0.076-0.068,0.16-0.145,0.236-0.228
               c0.273-0.274,0.579-0.595,1.035-0.564c0.594,0.031,0.678,0.023,1.157-0.046h0.03c0.205-0.03,0.434-0.084,0.662-0.144
               C155.836,56.106,156.399,57.294,156.72,57.941"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M156.72,57.941c-0.068,0.03-0.121,0.053-0.168,0.091c-0.251,0.168-0.51,0.419-0.51,0.579c0,0.167,0.145,0.213,0.029,0.328
               c-0.113,0.113-0.243,0.19-0.465,0.251c-0.228,0.068-0.234,0.068-0.465,0.175c-0.236,0.114-0.26-0.069-0.524,0.167
               c-0.267,0.229-0.229,0.175-0.464,0.183c-0.236,0.015-0.641,0.358-0.717,0.495c-0.084,0.137-0.168,0.274-0.344,0.258
               c-0.166-0.007-0.388-0.265-0.523-0.007c-0.139,0.259-0.114,0.32-0.525,0.38c-0.419,0.054-0.678,0.176-0.891,0.176
               c-0.215,0-0.396-0.06-0.495,0.114c-0.101,0.183-0.168,0.343-0.327,0.381c-0.16,0.045-0.214-0.03-0.357,0.045
               c-0.145,0.084-0.473,0.161-0.473,0.161s-0.184-0.092-0.352-0.138c-0.166-0.045-0.166-0.076-0.205-0.289
               c-0.029-0.213-0.197-0.35-0.176-0.533c0.023-0.175,0.101-0.319-0.016-0.51c-0.105-0.198-0.197-0.35-0.221-0.625
               c-0.021-0.265-0.168-0.258-0.159-0.494c0.015-0.236,0.075-0.495,0.075-0.594c0-0.069-0.014-0.16-0.045-0.26
               c0.107-0.068,0.199-0.159,0.25-0.296c0.055-0.167,0.039-0.343,0.031-0.495c-0.017-0.137-0.022-0.251,0.039-0.289
               c0.067-0.046,0.129-0.068,0.196-0.061c0.067,0,0.138,0.03,0.229,0.091c0.121,0.091,0.281,0.069,0.479,0.046
               c0.149-0.015,0.327-0.038,0.517-0.008c0.213,0.023,0.367,0.076,0.504,0.115c0.138,0.045,0.252,0.083,0.396,0.083h0.144
               c0.191-0.007,0.352-0.007,0.436,0.077c0.029,0.03,0.045,0.068,0.061,0.098c0,0.008,0.008,0.023,0.017,0.031
               c0.038,0.084,0.101,0.114,0.319,0.008c0.184-0.091,0.236-0.236,0.304-0.389c0.063-0.137,0.123-0.289,0.282-0.434
               c0.076-0.068,0.16-0.145,0.236-0.228c0.273-0.274,0.579-0.595,1.035-0.564c0.594,0.031,0.678,0.023,1.157-0.046h0.03
               c0.205-0.03,0.434-0.084,0.662-0.144C155.836,56.106,156.399,57.294,156.72,57.941z"/>
             <path fill="#808184" d="M158.987,50.313c0.138,0.182,0.365,0.479,0.457,0.624
               c0.138,0.205,0.473,0.304,0.707,0.396c0.236,0.091,0.847-0.076,0.916,0.281c0.066,0.359,0.266,0.518,0.357,0.617
               c0.09,0.1,0.518,0.122,0.463,0.426c-0.061,0.305-0.113,0.321-0.205,0.533c-0.092,0.213-0.145,0.336-0.281,0.404
               c-0.129,0.069-0.213,0.168-0.221,0.282c-0.016,0.114-0.123,0.328-0.189,0.472c-0.063,0.145-0.069,0.236-0.16,0.152
               c-0.092-0.076-0.137-0.38-0.252-0.19c-0.105,0.183-0.229,0.366-0.189,0.548c0.045,0.183-0.084,0.396-0.07,0.465
               c0.01,0.06,0.391,0.403,0.07,0.487c-0.313,0.076-0.641,0.175-0.717,0.259c-0.083,0.076-0.137,0.152-0.189,0.433
               c-0.061,0.283-0.159,0.351-0.297,0.351c-0.129,0-0.487-0.091-0.647,0.099c-0.151,0.183-0.022,0.426-0.138,0.548
               c-0.121,0.122-0.228,0.206-0.402,0.152c-0.167-0.053-0.318-0.007-0.434,0.038c-0.084,0.039-0.465,0.107-0.754,0.213
               c-0.305-0.624-0.86-1.796-0.982-2.063l0.038-0.007c0.28-0.092,0.563-0.198,0.791-0.305c0.161-0.084,0.375-0.168,0.595-0.251
               c0.389-0.144,0.777-0.297,0.854-0.426c0.061-0.091,0.106-0.191,0.145-0.297c0.039-0.099,0.068-0.206,0.094-0.313
               c0.014-0.076,0.036-0.266,0.053-0.472c0.031-0.334,0.061-0.73,0.061-0.814c0.008-0.068-0.055-0.152-0.13-0.244
               c-0.093-0.129-0.197-0.281-0.168-0.411c0.047-0.198,0.055-0.335,0.062-0.442c0-0.038,0.008-0.068,0.008-0.091
               c0.008-0.054,0.017-0.114,0.047-0.175c0.022-0.053,0.053-0.107,0.091-0.168c0.016-0.015,0.038-0.038,0.063-0.068
               c0.084-0.107,0.166-0.214,0.182-0.305c0.007-0.061-0.023-0.145-0.053-0.236c-0.039-0.084-0.069-0.19-0.069-0.266v-0.13
               c-0.009-0.084-0.009-0.175,0.008-0.206c0,0,0.008,0,0.022,0.009c0.062,0.029,0.076,0.075,0.092,0.106
               c0.021,0.076,0.037,0.114,0.145,0.076C158.813,50.38,158.905,50.342,158.987,50.313"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M158.987,50.313c0.138,0.182,0.365,0.479,0.457,0.624c0.138,0.205,0.473,0.304,0.707,0.396c0.236,0.091,0.847-0.076,0.916,0.281
               c0.066,0.359,0.266,0.518,0.357,0.617c0.09,0.1,0.518,0.122,0.463,0.426c-0.061,0.305-0.113,0.321-0.205,0.533
               c-0.092,0.213-0.145,0.336-0.281,0.404c-0.129,0.069-0.213,0.168-0.221,0.282c-0.016,0.114-0.123,0.328-0.189,0.472
               c-0.063,0.145-0.069,0.236-0.16,0.152c-0.092-0.076-0.137-0.38-0.252-0.19c-0.105,0.183-0.229,0.366-0.189,0.548
               c0.045,0.183-0.084,0.396-0.07,0.465c0.01,0.06,0.391,0.403,0.07,0.487c-0.313,0.076-0.641,0.175-0.717,0.259
               c-0.083,0.076-0.137,0.152-0.189,0.433c-0.061,0.283-0.159,0.351-0.297,0.351c-0.129,0-0.487-0.091-0.647,0.099
               c-0.151,0.183-0.022,0.426-0.138,0.548c-0.121,0.122-0.228,0.206-0.402,0.152c-0.167-0.053-0.318-0.007-0.434,0.038
               c-0.084,0.039-0.465,0.107-0.754,0.213c-0.305-0.624-0.86-1.796-0.982-2.063l0.038-0.007c0.28-0.092,0.563-0.198,0.791-0.305
               c0.161-0.084,0.375-0.168,0.595-0.251c0.389-0.144,0.777-0.297,0.854-0.426c0.061-0.091,0.106-0.191,0.145-0.297
               c0.039-0.099,0.068-0.206,0.094-0.313c0.014-0.076,0.036-0.266,0.053-0.472c0.031-0.334,0.061-0.73,0.061-0.814
               c0.008-0.068-0.055-0.152-0.13-0.244c-0.093-0.129-0.197-0.281-0.168-0.411c0.047-0.198,0.055-0.335,0.062-0.442
               c0-0.038,0.008-0.068,0.008-0.091c0.008-0.054,0.017-0.114,0.047-0.175c0.022-0.053,0.053-0.107,0.091-0.168
               c0.016-0.015,0.038-0.038,0.063-0.068c0.084-0.107,0.166-0.214,0.182-0.305c0.007-0.061-0.023-0.145-0.053-0.236
               c-0.039-0.084-0.069-0.19-0.069-0.266v-0.13c-0.009-0.084-0.009-0.175,0.008-0.206c0,0,0.008,0,0.022,0.009
               c0.062,0.029,0.076,0.075,0.092,0.106c0.021,0.076,0.037,0.114,0.145,0.076C158.813,50.38,158.905,50.342,158.987,50.313z"/>
             <path fill="#808184" d="M155.128,50.891c0.084,0.076,0.121,0.296,0.281,0.274
               c0.189-0.038,0.244,0.022,0.343-0.084c0.099-0.114-0.106-0.259,0.272-0.19c0.373,0.068,0.359,0.03,0.611,0.076
               c0.25,0.045,0.188-0.107,0.342-0.107c0.152,0,0.19,0.061,0.273,0.008c0.084-0.046,0.168-0.122,0.189-0.259
               c0.023-0.145-0.021,0.053,0.151-0.206c0.185-0.258,0.321-0.441,0.427-0.548c0.107-0.114,0.275-0.274,0.366-0.38
               c0.099-0.099,0.06-0.069,0.16-0.267c0.099-0.205,0.166-0.426,0.243-0.328c0.076,0.092,0,0.214,0.007,0.313
               c0,0.092,0.069,0.251,0.017,0.313c-0.054,0.061-0.039,0.007,0.029,0.198c0.077,0.183,0,0.388,0.038,0.457
               c0,0.008,0.024,0.03,0.046,0.068c-0.074,0.031-0.158,0.061-0.205,0.076c-0.016,0.008-0.016,0-0.023-0.008
               c-0.014-0.052-0.036-0.113-0.137-0.166c-0.076-0.046-0.129-0.024-0.16,0.038c-0.029,0.053-0.021,0.16-0.021,0.258
               c0,0.038,0.008,0.084,0.008,0.122c-0.008,0.099,0.038,0.205,0.068,0.304c0.029,0.077,0.061,0.145,0.054,0.183
               c-0.016,0.068-0.093,0.16-0.168,0.259c-0.016,0.023-0.03,0.045-0.053,0.069c-0.056,0.069-0.084,0.129-0.114,0.19
               c-0.025,0.068-0.046,0.137-0.046,0.206c-0.008,0.03-0.008,0.061-0.008,0.091c-0.009,0.107-0.017,0.236-0.062,0.426
               c-0.009,0.016-0.009,0.031-0.009,0.046c-0.168-0.008-0.698-0.015-0.981-0.061c-0.159-0.022-0.25-0.053-0.342-0.084
               c-0.101-0.03-0.205-0.06-0.42-0.099c-0.197-0.03-0.298-0.045-0.371-0.076c-0.069-0.038-0.115-0.099-0.224-0.22
               c-0.045-0.061-0.091-0.115-0.129-0.161c-0.185-0.22-0.372-0.448-0.449-0.586C155.112,50.99,155.112,50.936,155.128,50.891"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M155.128,50.891c0.084,0.076,0.121,0.296,0.281,0.274c0.189-0.038,0.244,0.022,0.343-0.084c0.099-0.114-0.106-0.259,0.272-0.19
               c0.373,0.068,0.359,0.03,0.611,0.076c0.25,0.045,0.188-0.107,0.342-0.107c0.152,0,0.19,0.061,0.273,0.008
               c0.084-0.046,0.168-0.122,0.189-0.259c0.023-0.145-0.021,0.053,0.151-0.206c0.185-0.258,0.321-0.441,0.427-0.548
               c0.107-0.114,0.275-0.274,0.366-0.38c0.099-0.099,0.06-0.069,0.16-0.267c0.099-0.205,0.166-0.426,0.243-0.328
               c0.076,0.092,0,0.214,0.007,0.313c0,0.092,0.069,0.251,0.017,0.313c-0.054,0.061-0.039,0.007,0.029,0.198
               c0.077,0.183,0,0.388,0.038,0.457c0,0.008,0.024,0.03,0.046,0.068c-0.074,0.031-0.158,0.061-0.205,0.076
               c-0.016,0.008-0.016,0-0.023-0.008c-0.014-0.052-0.036-0.113-0.137-0.166c-0.076-0.046-0.129-0.024-0.16,0.038
               c-0.029,0.053-0.021,0.16-0.021,0.258c0,0.038,0.008,0.084,0.008,0.122c-0.008,0.099,0.038,0.205,0.068,0.304
               c0.029,0.077,0.061,0.145,0.054,0.183c-0.016,0.068-0.093,0.16-0.168,0.259c-0.016,0.023-0.03,0.045-0.053,0.069
               c-0.056,0.069-0.084,0.129-0.114,0.19c-0.025,0.068-0.046,0.137-0.046,0.206c-0.008,0.03-0.008,0.061-0.008,0.091
               c-0.009,0.107-0.017,0.236-0.062,0.426c-0.009,0.016-0.009,0.031-0.009,0.046c-0.168-0.008-0.698-0.015-0.981-0.061
               c-0.159-0.022-0.25-0.053-0.342-0.084c-0.101-0.03-0.205-0.06-0.42-0.099c-0.197-0.03-0.298-0.045-0.371-0.076
               c-0.069-0.038-0.115-0.099-0.224-0.22c-0.045-0.061-0.091-0.115-0.129-0.161c-0.185-0.22-0.372-0.448-0.449-0.586
               C155.112,50.99,155.112,50.936,155.128,50.891z"/>
             <path fill="#808184" d="M154.26,50.053c0.03,0.115,0.054,0.229,0.085,0.236
               c0.066,0.015,0.045-0.198,0.021-0.388s0.029-0.305,0.084-0.419c0.045-0.114,0-0.45,0.175-0.419
               c0.168,0.038,0.174,0.084,0.213,0.168c0.045,0.092,0.153-0.054,0.183,0.152c0.03,0.213-0.061,0.32-0.067,0.419
               c-0.007,0.099,0.196,0.16,0.146,0.335c-0.062,0.175-0.197,0.236-0.205,0.395c-0.055,0.009-0.123,0.016-0.153,0.016
               c-0.044,0-0.06,0.015-0.075,0.03c-0.007,0.008-0.021,0.023-0.105,0.016c-0.076-0.008-0.076-0.016-0.084-0.031
               c-0.008-0.031-0.022-0.06-0.07-0.099c-0.036-0.045-0.073-0.099-0.098-0.16c-0.021-0.061-0.037-0.122-0.045-0.19v-0.062H154.26z"
               />
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M154.26,50.053c0.03,0.115,0.054,0.229,0.085,0.236c0.066,0.015,0.045-0.198,0.021-0.388s0.029-0.305,0.084-0.419
               c0.045-0.114,0-0.45,0.175-0.419c0.168,0.038,0.174,0.084,0.213,0.168c0.045,0.092,0.153-0.054,0.183,0.152
               c0.03,0.213-0.061,0.32-0.067,0.419c-0.007,0.099,0.196,0.16,0.146,0.335c-0.062,0.175-0.197,0.236-0.205,0.395
               c-0.055,0.009-0.123,0.016-0.153,0.016c-0.044,0-0.06,0.015-0.075,0.03c-0.007,0.008-0.021,0.023-0.105,0.016
               c-0.076-0.008-0.076-0.016-0.084-0.031c-0.008-0.031-0.022-0.06-0.07-0.099c-0.036-0.045-0.073-0.099-0.098-0.16
               c-0.021-0.061-0.037-0.122-0.045-0.19v-0.062H154.26z"/>
             <path fill="#808184" d="M152.249,46.962c0.039,0.099,0.031,0.175,0.139,0.305
               c0.137,0.174,0.221,0.229,0.234,0.342c0.018,0.122-0.061,0.214,0.091,0.251c0.161,0.039,0.321,0.084,0.45,0.222
               c0.121,0.136,0.189,0.197,0.352,0.312c0.168,0.121,0.342,0.174,0.28,0.266c-0.062,0.099-0.104,0.084-0.028,0.19
               c0.066,0.099,0.06,0.146,0.037,0.252c-0.023,0.114-0.205,0.221-0.063,0.243c0.146,0.031,0.336,0.434,0.435,0.526
               c-0.015,0.076-0.015,0.168-0.015,0.243c0,0.084,0.021,0.16,0.053,0.229v0.007c0.031,0.069,0.069,0.137,0.123,0.183
               c0.021,0.031,0.037,0.045,0.037,0.061c0.021,0.053,0.037,0.083,0.176,0.099c0.129,0.015,0.159-0.016,0.184-0.038
               c0.006,0,0.006-0.008,0.006-0.008c0.031,0,0.1-0.008,0.16-0.015c0,0.007,0.146,0.434,0.151,0.449
               c0.069,0.145,0.274,0.381,0.458,0.602c0.047,0.061,0.1,0.121,0.131,0.16c0.105,0.137,0.166,0.198,0.25,0.243
               c0.084,0.046,0.189,0.069,0.403,0.099c0.206,0.031,0.306,0.061,0.403,0.091c0.099,0.031,0.189,0.061,0.357,0.084
               c0.297,0.046,0.846,0.061,1.004,0.061c0.023,0.122,0.115,0.244,0.19,0.351c0.054,0.076,0.106,0.144,0.106,0.183
               c-0.008,0.083-0.03,0.471-0.061,0.806c-0.023,0.206-0.038,0.381-0.055,0.457c-0.022,0.099-0.055,0.198-0.092,0.305
               c-0.029,0.091-0.074,0.19-0.131,0.273c-0.06,0.1-0.434,0.245-0.806,0.389c-0.222,0.084-0.442,0.168-0.603,0.244
               c-0.221,0.114-0.494,0.22-0.775,0.304c-0.268,0.084-0.54,0.145-0.785,0.175l-0.021,0.008c-0.479,0.062-0.557,0.076-1.149,0.038
               c-0.494-0.023-0.821,0.305-1.11,0.602c-0.076,0.076-0.154,0.16-0.229,0.22c-0.175,0.161-0.243,0.32-0.306,0.465
               c-0.061,0.145-0.113,0.266-0.26,0.343c-0.144,0.068-0.166,0.068-0.182,0.037c-0.01-0.007-0.01-0.014-0.016-0.022
               c-0.016-0.038-0.03-0.092-0.076-0.13c-0.122-0.121-0.299-0.114-0.518-0.107h-0.138c-0.13,0-0.235-0.037-0.365-0.075
               c-0.138-0.046-0.297-0.099-0.519-0.13c-0.205-0.023-0.389,0-0.549,0.015c-0.176,0.023-0.318,0.039-0.41-0.022
               c-0.102-0.077-0.19-0.115-0.274-0.122c-0.092,0-0.176,0.03-0.267,0.084c-0.106,0.068-0.092,0.205-0.084,0.38
               c0.017,0.137,0.031,0.304-0.022,0.457c-0.036,0.099-0.1,0.175-0.183,0.221l-0.008-0.03c-0.063-0.146-0.084-0.13-0.16-0.344
               c-0.075-0.22-0.258-0.258-0.35-0.578c-0.092-0.312-0.023-0.046-0.328-0.457c-0.305-0.419-0.563-0.563-0.615-0.944
               c-0.055-0.388-0.161-0.442-0.336-0.669c-0.184-0.222-0.442-0.58-0.725-0.693c-0.281-0.116-0.41-0.237-0.494-0.443
               c-0.076-0.197-0.357-0.289-0.281-0.502c0.084-0.214-0.055-0.396-0.076-0.578c-0.023-0.176,0.054-0.382,0.054-0.533
               c0-0.16-0.39-0.754-0.548-1.051c-0.161-0.29-0.762-0.495-0.898-0.671c-0.138-0.182-0.16-0.182-0.138-0.456
               c0.022-0.266-0.022-0.404-0.137-0.533c-0.106-0.138-0.755-1.005-0.913-1.256c-0.161-0.252-0.313-0.13-0.404-0.465
               c-0.092-0.328-0.342-0.685-0.426-0.708c-0.092-0.023-0.389-0.053-0.359-0.129c0.039-0.084,0.121-0.343,0.131-0.396
               c0.006-0.061-0.01-0.488,0-0.572V46.14c0.115,0.015,0.259,0.038,0.396,0.061c0.105,0.015,0.174,0.038,0.234,0.061
               c0.105,0.03,0.176,0.053,0.305,0.007c0.105-0.038,0.145-0.091,0.176-0.145c0.016-0.037,0.038-0.068,0.092-0.091
               c0.099-0.038,0.168-0.167,0.228-0.274c0.046-0.076,0.084-0.145,0.101-0.145h0.045c0.101,0,0.223,0.008,0.373-0.084l0.008-0.007
               c0.176-0.099,0.221-0.129,0.221-0.335c0-0.053,0.023-0.092,0.046-0.137c0.046-0.107,0.101-0.198-0.067-0.358
               c-0.022-0.023-0.053-0.053-0.084-0.076c-0.189-0.183-0.427-0.396-0.373-0.472c0.039-0.061,0.1-0.069,0.168-0.084
               c0.062-0.008,0.123-0.022,0.197-0.06c0.076-0.039,0.137-0.061,0.189-0.084c0.063-0.016,0.131-0.031,0.222-0.054
               c0.067-0.023,0.176-0.076,0.297-0.137c0.19-0.098,0.403-0.214,0.487-0.175c0.113,0.045,0.297,0.084,0.457,0.107
               c0.076,0.015,0.15,0.03,0.205,0.045c0.045,0.016,0.091,0.023,0.131,0.031c0.099,0.015,0.183,0.03,0.242,0.083
               c0.054,0.054,0.336,0.206,0.641,0.374c0.273,0.144,0.578,0.312,0.73,0.411c0.189,0.121,0.281,0.235,0.381,0.358
               c0.068,0.084,0.145,0.175,0.25,0.281c0.168,0.145,0.549,0.366,0.893,0.572c0.236,0.136,0.456,0.258,0.578,0.349
               c0.16,0.114,0.281,0.183,0.402,0.221c0.115,0.038,0.222,0.038,0.351,0.023c0.138-0.007,0.588,0,0.959,0.031
               c0.269,0.023,0.488,0.053,0.519,0.084c0.023,0.023,0.045,0.061,0.063,0.106c0.045,0.114,0.104,0.244,0.281,0.282
               C151.838,46.94,152.083,46.955,152.249,46.962"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M152.249,46.962c0.039,0.099,0.031,0.175,0.139,0.305c0.137,0.174,0.221,0.229,0.234,0.342c0.018,0.122-0.061,0.214,0.091,0.251
               c0.161,0.039,0.321,0.084,0.45,0.222c0.121,0.136,0.189,0.197,0.352,0.312c0.168,0.121,0.342,0.174,0.28,0.266
               c-0.062,0.099-0.104,0.084-0.028,0.19c0.066,0.099,0.06,0.146,0.037,0.252c-0.023,0.114-0.205,0.221-0.063,0.243
               c0.146,0.031,0.336,0.434,0.435,0.526c-0.015,0.076-0.015,0.168-0.015,0.243c0,0.084,0.021,0.16,0.053,0.229v0.007
               c0.031,0.069,0.069,0.137,0.123,0.183c0.021,0.031,0.037,0.045,0.037,0.061c0.021,0.053,0.037,0.083,0.176,0.099
               c0.129,0.015,0.159-0.016,0.184-0.038c0.006,0,0.006-0.008,0.006-0.008c0.031,0,0.1-0.008,0.16-0.015
               c0,0.007,0.146,0.434,0.151,0.449c0.069,0.145,0.274,0.381,0.458,0.602c0.047,0.061,0.1,0.121,0.131,0.16
               c0.105,0.137,0.166,0.198,0.25,0.243c0.084,0.046,0.189,0.069,0.403,0.099c0.206,0.031,0.306,0.061,0.403,0.091
               c0.099,0.031,0.189,0.061,0.357,0.084c0.297,0.046,0.846,0.061,1.004,0.061c0.023,0.122,0.115,0.244,0.19,0.351
               c0.054,0.076,0.106,0.144,0.106,0.183c-0.008,0.083-0.03,0.471-0.061,0.806c-0.023,0.206-0.038,0.381-0.055,0.457
               c-0.022,0.099-0.055,0.198-0.092,0.305c-0.029,0.091-0.074,0.19-0.131,0.273c-0.06,0.1-0.434,0.245-0.806,0.389
               c-0.222,0.084-0.442,0.168-0.603,0.244c-0.221,0.114-0.494,0.22-0.775,0.304c-0.268,0.084-0.54,0.145-0.785,0.175l-0.021,0.008
               c-0.479,0.062-0.557,0.076-1.149,0.038c-0.494-0.023-0.821,0.305-1.11,0.602c-0.076,0.076-0.154,0.16-0.229,0.22
               c-0.175,0.161-0.243,0.32-0.306,0.465c-0.061,0.145-0.113,0.266-0.26,0.343c-0.144,0.068-0.166,0.068-0.182,0.037
               c-0.01-0.007-0.01-0.014-0.016-0.022c-0.016-0.038-0.03-0.092-0.076-0.13c-0.122-0.121-0.299-0.114-0.518-0.107h-0.138
               c-0.13,0-0.235-0.037-0.365-0.075c-0.138-0.046-0.297-0.099-0.519-0.13c-0.205-0.023-0.389,0-0.549,0.015
               c-0.176,0.023-0.318,0.039-0.41-0.022c-0.102-0.077-0.19-0.115-0.274-0.122c-0.092,0-0.176,0.03-0.267,0.084
               c-0.106,0.068-0.092,0.205-0.084,0.38c0.017,0.137,0.031,0.304-0.022,0.457c-0.036,0.099-0.1,0.175-0.183,0.221l-0.008-0.03
               c-0.063-0.146-0.084-0.13-0.16-0.344c-0.075-0.22-0.258-0.258-0.35-0.578c-0.092-0.312-0.023-0.046-0.328-0.457
               c-0.305-0.419-0.563-0.563-0.615-0.944c-0.055-0.388-0.161-0.442-0.336-0.669c-0.184-0.222-0.442-0.58-0.725-0.693
               c-0.281-0.116-0.41-0.237-0.494-0.443c-0.076-0.197-0.357-0.289-0.281-0.502c0.084-0.214-0.055-0.396-0.076-0.578
               c-0.023-0.176,0.054-0.382,0.054-0.533c0-0.16-0.39-0.754-0.548-1.051c-0.161-0.29-0.762-0.495-0.898-0.671
               c-0.138-0.182-0.16-0.182-0.138-0.456c0.022-0.266-0.022-0.404-0.137-0.533c-0.106-0.138-0.755-1.005-0.913-1.256
               c-0.161-0.252-0.313-0.13-0.404-0.465c-0.092-0.328-0.342-0.685-0.426-0.708c-0.092-0.023-0.389-0.053-0.359-0.129
               c0.039-0.084,0.121-0.343,0.131-0.396c0.006-0.061-0.01-0.488,0-0.572V46.14c0.115,0.015,0.259,0.038,0.396,0.061
               c0.105,0.015,0.174,0.038,0.234,0.061c0.105,0.03,0.176,0.053,0.305,0.007c0.105-0.038,0.145-0.091,0.176-0.145
               c0.016-0.037,0.038-0.068,0.092-0.091c0.099-0.038,0.168-0.167,0.228-0.274c0.046-0.076,0.084-0.145,0.101-0.145h0.045
               c0.101,0,0.223,0.008,0.373-0.084l0.008-0.007c0.176-0.099,0.221-0.129,0.221-0.335c0-0.053,0.023-0.092,0.046-0.137
               c0.046-0.107,0.101-0.198-0.067-0.358c-0.022-0.023-0.053-0.053-0.084-0.076c-0.189-0.183-0.427-0.396-0.373-0.472
               c0.039-0.061,0.1-0.069,0.168-0.084c0.062-0.008,0.123-0.022,0.197-0.06c0.076-0.039,0.137-0.061,0.189-0.084
               c0.063-0.016,0.131-0.031,0.222-0.054c0.067-0.023,0.176-0.076,0.297-0.137c0.19-0.098,0.403-0.214,0.487-0.175
               c0.113,0.045,0.297,0.084,0.457,0.107c0.076,0.015,0.15,0.03,0.205,0.045c0.045,0.016,0.091,0.023,0.131,0.031
               c0.099,0.015,0.183,0.03,0.242,0.083c0.054,0.054,0.336,0.206,0.641,0.374c0.273,0.144,0.578,0.312,0.73,0.411
               c0.189,0.121,0.281,0.235,0.381,0.358c0.068,0.084,0.145,0.175,0.25,0.281c0.168,0.145,0.549,0.366,0.893,0.572
               c0.236,0.136,0.456,0.258,0.578,0.349c0.16,0.114,0.281,0.183,0.402,0.221c0.115,0.038,0.222,0.038,0.351,0.023
               c0.138-0.007,0.588,0,0.959,0.031c0.269,0.023,0.488,0.053,0.519,0.084c0.023,0.023,0.045,0.061,0.063,0.106
               c0.045,0.114,0.104,0.244,0.281,0.282C151.838,46.94,152.083,46.955,152.249,46.962z"/>
             <path fill="#808184" d="M151.899,45.553c-0.115,0.022-0.19,0.038-0.123,0.084
               c0.101,0.053,0.244-0.038,0.223,0.068c-0.032,0.107-0.107,0.176-0.161,0.198c-0.054,0.016-0.151,0.061-0.136,0.145
               c0.021,0.084,0.175,0.205,0.213,0.328c0.045,0.129,0.16,0.289,0.273,0.456c0.008,0.008,0.008,0.016,0.016,0.023
               c-0.16-0.007-0.366-0.023-0.479-0.046c-0.129-0.023-0.176-0.129-0.213-0.22c-0.025-0.054-0.047-0.107-0.076-0.145
               c-0.055-0.046-0.297-0.084-0.586-0.107l-0.076-0.007c0.037-0.114,0.129-0.396,0.175-0.427c0.06-0.053,0.099-0.213,0.12-0.335
               c0.009-0.03,0.017-0.06,0.022-0.083c0.017-0.061,0.198-0.046,0.442-0.023l0.045,0.007
               C151.692,45.477,151.81,45.515,151.899,45.553"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M151.899,45.553c-0.115,0.022-0.19,0.038-0.123,0.084c0.101,0.053,0.244-0.038,0.223,0.068
               c-0.032,0.107-0.107,0.176-0.161,0.198c-0.054,0.016-0.151,0.061-0.136,0.145c0.021,0.084,0.175,0.205,0.213,0.328
               c0.045,0.129,0.16,0.289,0.273,0.456c0.008,0.008,0.008,0.016,0.016,0.023c-0.16-0.007-0.366-0.023-0.479-0.046
               c-0.129-0.023-0.176-0.129-0.213-0.22c-0.025-0.054-0.047-0.107-0.076-0.145c-0.055-0.046-0.297-0.084-0.586-0.107l-0.076-0.007
               c0.037-0.114,0.129-0.396,0.175-0.427c0.06-0.053,0.099-0.213,0.12-0.335c0.009-0.03,0.017-0.06,0.022-0.083
               c0.017-0.061,0.198-0.046,0.442-0.023l0.045,0.007C151.692,45.477,151.81,45.515,151.899,45.553z"/>
             <path fill="#808184" d="M141.331,45.919V45.79c0.016-0.122-0.063-0.297-0.152-0.496
               c-0.092-0.189-0.473-1.103-0.473-1.103s0.123-0.13,0.281-0.442c0.168-0.305,0.131-0.403,0.207-0.396
               c0.074,0.007,0.1,0.213,0.082,0.289c-0.008,0.084-0.014,0.244,0.031,0.366c0.053,0.114,0.075,0.251,0.152,0.137
               c0.068-0.115,0.198-0.115,0.198-0.221c0-0.107-0.084-0.068-0.068-0.343c0.021-0.281,0-0.388-0.106-0.411
               c-0.115-0.031-0.152,0.061-0.215,0.061c-0.06,0.008-0.104,0.091-0.104-0.023s-0.069-0.259-0.009-0.342
               c0.055-0.084,0.131-0.061,0.131-0.175v-0.099c0.053,0.015,0.105,0.031,0.158,0.045c0.101,0.023,0.168,0.046,0.205,0.069
               c0.031,0.023,0.039,0.046,0.031,0.076c0,0.023-0.017,0.053-0.021,0.075c-0.039,0.084-0.084,0.168,0,0.351
               c0.061,0.138,0.045,0.366,0.037,0.533c-0.008,0.099-0.016,0.176-0.008,0.221l0.008,0.015c0.016,0.099,0.031,0.191-0.016,0.313
               c-0.031,0.076-0.045,0.122-0.062,0.174c-0.015,0.054-0.022,0.115-0.022,0.206c-0.007,0.084-0.031,0.145-0.053,0.206
               c-0.031,0.068-0.054,0.137-0.063,0.229c0,0.098-0.029,0.243-0.053,0.38c-0.008,0.083-0.021,0.16-0.031,0.229
               C141.384,45.782,141.36,45.857,141.331,45.919"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M141.331,45.919V45.79c0.016-0.122-0.063-0.297-0.152-0.496c-0.092-0.189-0.473-1.103-0.473-1.103s0.123-0.13,0.281-0.442
               c0.168-0.305,0.131-0.403,0.207-0.396c0.074,0.007,0.1,0.213,0.082,0.289c-0.008,0.084-0.014,0.244,0.031,0.366
               c0.053,0.114,0.075,0.251,0.152,0.137c0.068-0.115,0.198-0.115,0.198-0.221c0-0.107-0.084-0.068-0.068-0.343
               c0.021-0.281,0-0.388-0.106-0.411c-0.115-0.031-0.152,0.061-0.215,0.061c-0.06,0.008-0.104,0.091-0.104-0.023
               s-0.069-0.259-0.009-0.342c0.055-0.084,0.131-0.061,0.131-0.175v-0.099c0.053,0.015,0.105,0.031,0.158,0.045
               c0.101,0.023,0.168,0.046,0.205,0.069c0.031,0.023,0.039,0.046,0.031,0.076c0,0.023-0.017,0.053-0.021,0.075
               c-0.039,0.084-0.084,0.168,0,0.351c0.061,0.138,0.045,0.366,0.037,0.533c-0.008,0.099-0.016,0.176-0.008,0.221l0.008,0.015
               c0.016,0.099,0.031,0.191-0.016,0.313c-0.031,0.076-0.045,0.122-0.062,0.174c-0.015,0.054-0.022,0.115-0.022,0.206
               c-0.007,0.084-0.031,0.145-0.053,0.206c-0.031,0.068-0.054,0.137-0.063,0.229c0,0.098-0.029,0.243-0.053,0.38
               c-0.008,0.083-0.021,0.16-0.031,0.229C141.384,45.782,141.36,45.857,141.331,45.919z"/>
             <path fill="#808184" d="M141.628,42.584c-0.046-0.016-0.092-0.031-0.16-0.045
               c-0.053-0.016-0.113-0.031-0.167-0.055c0.009-0.037,0.009-0.06,0.009-0.06s-0.009-0.13,0.105-0.35
               c0.121-0.228,0.046-0.327,0.092-0.449c0.038-0.129,0.1-0.191,0.184-0.328c0.053-0.076,0.099-0.091,0.113-0.13
               c0.092-0.007,0.176-0.015,0.221,0.009c0.084,0.038,0.092,0.083,0.092,0.121c0.009,0.046,0.009,0.084,0.046,0.122
               c0.028,0.022,0.062,0.039,0.099,0.054c0.023,0.014,0.053,0.03,0.053,0.03c0,0.015,0,0.03,0.01,0.045
               c0,0.023,0.008,0.046-0.038,0.084c-0.03,0.023-0.046,0.031-0.069,0.038c-0.045,0.008-0.084,0.023-0.15,0.13
               c-0.047,0.068-0.076,0.106-0.101,0.137c-0.03,0.03-0.053,0.061-0.084,0.122c-0.022,0.052-0.022,0.091-0.022,0.129
               c-0.006,0.031-0.006,0.061-0.029,0.091C141.767,42.348,141.675,42.508,141.628,42.584"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M141.628,42.584c-0.046-0.016-0.092-0.031-0.16-0.045c-0.053-0.016-0.113-0.031-0.167-0.055c0.009-0.037,0.009-0.06,0.009-0.06
               s-0.009-0.13,0.105-0.35c0.121-0.228,0.046-0.327,0.092-0.449c0.038-0.129,0.1-0.191,0.184-0.328
               c0.053-0.076,0.099-0.091,0.113-0.13c0.092-0.007,0.176-0.015,0.221,0.009c0.084,0.038,0.092,0.083,0.092,0.121
               c0.009,0.046,0.009,0.084,0.046,0.122c0.028,0.022,0.062,0.039,0.099,0.054c0.023,0.014,0.053,0.03,0.053,0.03
               c0,0.015,0,0.03,0.01,0.045c0,0.023,0.008,0.046-0.038,0.084c-0.03,0.023-0.046,0.031-0.069,0.038
               c-0.045,0.008-0.084,0.023-0.15,0.13c-0.047,0.068-0.076,0.106-0.101,0.137c-0.03,0.03-0.053,0.061-0.084,0.122
               c-0.022,0.052-0.022,0.091-0.022,0.129c-0.006,0.031-0.006,0.061-0.029,0.091C141.767,42.348,141.675,42.508,141.628,42.584z"/>
             <path fill="#808184" d="M146.522,38.86l-0.015,0.015c-0.046,0.054-0.183,0.221-0.327,0.336
               c-0.076,0.06-0.152,0.106-0.214,0.106c-0.13,0-0.182,0.069-0.204,0.183c-0.011,0.099,0.008,0.22,0.045,0.35
               c0.029,0.122,0.045,0.198,0.053,0.274c0,0.084-0.016,0.16-0.045,0.289c-0.023,0.13,0,0.221,0.029,0.321
               c0.023,0.098,0.055,0.212,0.016,0.387c-0.068,0.305-0.334,0.366-0.691,0.45l-0.029,0.007c-0.236,0.053-0.396,0.206-0.572,0.374
               c-0.129,0.121-0.267,0.25-0.44,0.35c-0.198,0.107-0.504,0.313-0.785,0.502c-0.327,0.221-0.624,0.419-0.685,0.434
               c-0.068,0.008-0.122,0-0.168-0.008c-0.053-0.015-0.092-0.037-0.137-0.06c-0.068-0.031-0.146-0.099-0.207-0.16
               c-0.029-0.023-0.053-0.046-0.074-0.069c-0.063-0.053-0.206-0.099-0.29-0.121l0.008-0.016c0.017-0.076-0.008-0.129-0.067-0.174
               c0.045-0.077,0.129-0.229,0.182-0.282c0.047-0.054,0.055-0.099,0.055-0.153c0-0.03,0.007-0.061,0.021-0.099
               c0.016-0.046,0.037-0.068,0.063-0.099c0.03-0.031,0.061-0.068,0.105-0.144c0.054-0.076,0.076-0.076,0.1-0.084
               c0.029-0.008,0.061-0.015,0.1-0.054c0.098-0.084,0.084-0.128,0.075-0.19c0-0.007-0.007-0.015-0.007-0.022
               c0-0.069-0.055-0.092-0.115-0.122c-0.021-0.015-0.053-0.024-0.068-0.046c-0.008,0-0.008-0.023-0.008-0.046
               c-0.009-0.068-0.015-0.152-0.15-0.205c-0.069-0.031-0.168-0.031-0.276-0.016c0-0.007-0.006-0.015-0.014-0.03
               c-0.069-0.168-0.054-0.168-0.069-0.29c-0.015-0.121,0.077-0.227,0-0.311c-0.074-0.084-0.145-0.024-0.145-0.191
               c0-0.16-0.053-0.243,0.046-0.282c0.015-0.007,0.022-0.015,0.03-0.023c0.039,0.023,0.067,0.046,0.092,0.062
               c0.068,0.053,0.105,0.083,0.183,0.022c0.062-0.038,0.076-0.114,0.099-0.19c0.01-0.053,0.024-0.107,0.047-0.115
               c0.022-0.006,0.047-0.015,0.063-0.015c0.089-0.014,0.121-0.014,0.089-0.175c0-0.045-0.014-0.091-0.028-0.144
               c-0.022-0.092-0.056-0.198-0.045-0.252c0-0.022,0.006-0.038,0.037-0.029c0.084,0.007,0.1,0.029,0.121,0.052
               c0.031,0.031,0.068,0.061,0.168,0.099c0.122,0.046,0.129,0.038,0.159,0.015c0.009-0.007,0.031-0.015,0.146-0.015
               c0.151,0,0.273-0.045,0.383-0.091c0.082-0.031,0.158-0.06,0.227-0.06c0.077,0.007,0.122,0.037,0.176,0.067
               c0.053,0.031,0.113,0.069,0.229,0.084c0.13,0.023,0.167,0.015,0.267-0.015c0.068-0.015,0.168-0.038,0.351-0.069
               c0.312-0.045,0.411-0.083,0.51-0.122c0.047-0.014,0.084-0.029,0.146-0.045c0.045-0.016,0.101-0.03,0.146-0.046
               c0.138-0.046,0.272-0.091,0.449-0.053c0.188,0.038,0.252,0.007,0.327-0.031c0.022-0.015,0.054-0.03,0.1-0.037
               c0.075-0.015,0.129-0.046,0.167-0.061c0.046-0.023,0.075-0.038,0.099-0.031C146.388,38.761,146.472,38.823,146.522,38.86"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M146.522,38.86l-0.015,0.015c-0.046,0.054-0.183,0.221-0.327,0.336c-0.076,0.06-0.152,0.106-0.214,0.106
               c-0.13,0-0.182,0.069-0.204,0.183c-0.011,0.099,0.008,0.22,0.045,0.35c0.029,0.122,0.045,0.198,0.053,0.274
               c0,0.084-0.016,0.16-0.045,0.289c-0.023,0.13,0,0.221,0.029,0.321c0.023,0.098,0.055,0.212,0.016,0.387
               c-0.068,0.305-0.334,0.366-0.691,0.45l-0.029,0.007c-0.236,0.053-0.396,0.206-0.572,0.374c-0.129,0.121-0.267,0.25-0.44,0.35
               c-0.198,0.107-0.504,0.313-0.785,0.502c-0.327,0.221-0.624,0.419-0.685,0.434c-0.068,0.008-0.122,0-0.168-0.008
               c-0.053-0.015-0.092-0.037-0.137-0.06c-0.068-0.031-0.146-0.099-0.207-0.16c-0.029-0.023-0.053-0.046-0.074-0.069
               c-0.063-0.053-0.206-0.099-0.29-0.121l0.008-0.016c0.017-0.076-0.008-0.129-0.067-0.174c0.045-0.077,0.129-0.229,0.182-0.282
               c0.047-0.054,0.055-0.099,0.055-0.153c0-0.03,0.007-0.061,0.021-0.099c0.016-0.046,0.037-0.068,0.063-0.099
               c0.03-0.031,0.061-0.068,0.105-0.144c0.054-0.076,0.076-0.076,0.1-0.084c0.029-0.008,0.061-0.015,0.1-0.054
               c0.098-0.084,0.084-0.128,0.075-0.19c0-0.007-0.007-0.015-0.007-0.022c0-0.069-0.055-0.092-0.115-0.122
               c-0.021-0.015-0.053-0.024-0.068-0.046c-0.008,0-0.008-0.023-0.008-0.046c-0.009-0.068-0.015-0.152-0.15-0.205
               c-0.069-0.031-0.168-0.031-0.276-0.016c0-0.007-0.006-0.015-0.014-0.03c-0.069-0.168-0.054-0.168-0.069-0.29
               c-0.015-0.121,0.077-0.227,0-0.311c-0.074-0.084-0.145-0.024-0.145-0.191c0-0.16-0.053-0.243,0.046-0.282
               c0.015-0.007,0.022-0.015,0.03-0.023c0.039,0.023,0.067,0.046,0.092,0.062c0.068,0.053,0.105,0.083,0.183,0.022
               c0.062-0.038,0.076-0.114,0.099-0.19c0.01-0.053,0.024-0.107,0.047-0.115c0.022-0.006,0.047-0.015,0.063-0.015
               c0.089-0.014,0.121-0.014,0.089-0.175c0-0.045-0.014-0.091-0.028-0.144c-0.022-0.092-0.056-0.198-0.045-0.252
               c0-0.022,0.006-0.038,0.037-0.029c0.084,0.007,0.1,0.029,0.121,0.052c0.031,0.031,0.068,0.061,0.168,0.099
               c0.122,0.046,0.129,0.038,0.159,0.015c0.009-0.007,0.031-0.015,0.146-0.015c0.151,0,0.273-0.045,0.383-0.091
               c0.082-0.031,0.158-0.06,0.227-0.06c0.077,0.007,0.122,0.037,0.176,0.067c0.053,0.031,0.113,0.069,0.229,0.084
               c0.13,0.023,0.167,0.015,0.267-0.015c0.068-0.015,0.168-0.038,0.351-0.069c0.312-0.045,0.411-0.083,0.51-0.122
               c0.047-0.014,0.084-0.029,0.146-0.045c0.045-0.016,0.101-0.03,0.146-0.046c0.138-0.046,0.272-0.091,0.449-0.053
               c0.188,0.038,0.252,0.007,0.327-0.031c0.022-0.015,0.054-0.03,0.1-0.037c0.075-0.015,0.129-0.046,0.167-0.061
               c0.046-0.023,0.075-0.038,0.099-0.031C146.388,38.761,146.472,38.823,146.522,38.86z"/>
             <path fill="#808184" d="M145.337,34.719c0.036-0.062,0.076-0.129,0.084-0.19
               c0.021-0.13,0.016-0.351-0.1-0.472c-0.122-0.122-0.122-0.176-0.244-0.305c-0.114-0.13-0.268-0.236-0.335-0.282
               c-0.068-0.053-0.197-0.152-0.364-0.183c-0.168-0.03-0.146-0.075-0.275-0.182c-0.061-0.054-0.158-0.084-0.25-0.122
               c0.037-0.031,0.092-0.053,0.145-0.046c0.061,0,0.122,0,0.168-0.007c0.13,0,0.214-0.007,0.35,0.068
               c0.106,0.053,0.151,0.053,0.207,0.053c0.045,0,0.098,0,0.25,0.077c0.205,0.113,0.411,0.106,0.603,0.106
               c0.091,0,0.19,0,0.272,0.015c0.076,0.016,0.131,0.016,0.16,0.016c0.069,0.008,0.084,0.008,0.282,0.129
               c0.146,0.091,0.243,0.145,0.319,0.183c0.082,0.038,0.151,0.053,0.236,0.069c0.036,0.007,0.036,0.023,0.036,0.037
               c0.009,0.054,0.022,0.115,0.161,0.145c0.145,0.03,0.229-0.016,0.305-0.061c0.038-0.023,0.084-0.038,0.121-0.046
               c0.068-0.007,0.131,0,0.19,0.016c0.067,0.007,0.146,0.038,0.235,0.068c0.084,0.031,0.176,0.023,0.26,0.016
               c0.074-0.009,0.145-0.016,0.168,0.023c0.016,0.03,0.008,0.06,0,0.083c-0.023,0.061-0.047,0.122,0.053,0.213
               c0.146,0.115,0.313,0.175,0.313,0.175h0.008c0,0,0.076,0.016,0.176,0.038c-0.021,0.061-0.061,0.138-0.083,0.153
               c-0.017,0.008-0.024,0.023-0.032,0.038c-0.007,0.023,0,0.046,0.008,0.069c0.008,0.015,0.024,0.022,0.047,0.03
               c0.022,0.014,0.053,0.023,0.092,0.03c0,0,0.016,0.016,0.021,0.046c0.03,0.053,0.063,0.129,0.153,0.168
               c0.022,0.007,0.038,0.015,0.053,0.03c0.039,0.023,0.062,0.053,0.084,0.084c0.007,0.022,0.015,0.045,0.007,0.061
               c-0.021,0.038-0.113,0.007-0.273-0.046h-0.008c-0.09-0.031-0.145-0.031-0.189-0.023c-0.045,0-0.084,0-0.145-0.053
               c-0.101-0.076-0.131-0.084-0.183-0.099c-0.032-0.008-0.063-0.016-0.123-0.038c-0.159-0.069-0.221,0.015-0.282,0.106
               c-0.021,0.023-0.037,0.046-0.053,0.061l-0.007,0.008c-0.039,0.03-0.131,0.053-0.229,0.068c-0.113,0.016-0.244,0.023-0.356,0.016
               c-0.114-0.008-0.175,0-0.229,0.015c-0.054,0.015-0.099,0.023-0.213,0c-0.068-0.015-0.121-0.023-0.16-0.031
               c-0.076-0.015-0.105-0.023-0.16-0.053c-0.037-0.023-0.06-0.061-0.092-0.106c-0.037-0.054-0.082-0.123-0.158-0.168
               c-0.084-0.053-0.123-0.03-0.176,0.007c-0.047,0.031-0.113,0.07-0.281,0.023c-0.19-0.045-0.229-0.023-0.268-0.008
               c-0.029,0.016-0.054,0.031-0.212,0C145.39,34.734,145.356,34.726,145.337,34.719"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M145.337,34.719c0.036-0.062,0.076-0.129,0.084-0.19c0.021-0.13,0.016-0.351-0.1-0.472c-0.122-0.122-0.122-0.176-0.244-0.305
               c-0.114-0.13-0.268-0.236-0.335-0.282c-0.068-0.053-0.197-0.152-0.364-0.183c-0.168-0.03-0.146-0.075-0.275-0.182
               c-0.061-0.054-0.158-0.084-0.25-0.122c0.037-0.031,0.092-0.053,0.145-0.046c0.061,0,0.122,0,0.168-0.007
               c0.13,0,0.214-0.007,0.35,0.068c0.106,0.053,0.151,0.053,0.207,0.053c0.045,0,0.098,0,0.25,0.077
               c0.205,0.113,0.411,0.106,0.603,0.106c0.091,0,0.19,0,0.272,0.015c0.076,0.016,0.131,0.016,0.16,0.016
               c0.069,0.008,0.084,0.008,0.282,0.129c0.146,0.091,0.243,0.145,0.319,0.183c0.082,0.038,0.151,0.053,0.236,0.069
               c0.036,0.007,0.036,0.023,0.036,0.037c0.009,0.054,0.022,0.115,0.161,0.145c0.145,0.03,0.229-0.016,0.305-0.061
               c0.038-0.023,0.084-0.038,0.121-0.046c0.068-0.007,0.131,0,0.19,0.016c0.067,0.007,0.146,0.038,0.235,0.068
               c0.084,0.031,0.176,0.023,0.26,0.016c0.074-0.009,0.145-0.016,0.168,0.023c0.016,0.03,0.008,0.06,0,0.083
               c-0.023,0.061-0.047,0.122,0.053,0.213c0.146,0.115,0.313,0.175,0.313,0.175h0.008c0,0,0.076,0.016,0.176,0.038
               c-0.021,0.061-0.061,0.138-0.083,0.153c-0.017,0.008-0.024,0.023-0.032,0.038c-0.007,0.023,0,0.046,0.008,0.069
               c0.008,0.015,0.024,0.022,0.047,0.03c0.022,0.014,0.053,0.023,0.092,0.03c0,0,0.016,0.016,0.021,0.046
               c0.03,0.053,0.063,0.129,0.153,0.168c0.022,0.007,0.038,0.015,0.053,0.03c0.039,0.023,0.062,0.053,0.084,0.084
               c0.007,0.022,0.015,0.045,0.007,0.061c-0.021,0.038-0.113,0.007-0.273-0.046h-0.008c-0.09-0.031-0.145-0.031-0.189-0.023
               c-0.045,0-0.084,0-0.145-0.053c-0.101-0.076-0.131-0.084-0.183-0.099c-0.032-0.008-0.063-0.016-0.123-0.038
               c-0.159-0.069-0.221,0.015-0.282,0.106c-0.021,0.023-0.037,0.046-0.053,0.061l-0.007,0.008c-0.039,0.03-0.131,0.053-0.229,0.068
               c-0.113,0.016-0.244,0.023-0.356,0.016c-0.114-0.008-0.175,0-0.229,0.015c-0.054,0.015-0.099,0.023-0.213,0
               c-0.068-0.015-0.121-0.023-0.16-0.031c-0.076-0.015-0.105-0.023-0.16-0.053c-0.037-0.023-0.06-0.061-0.092-0.106
               c-0.037-0.054-0.082-0.123-0.158-0.168c-0.084-0.053-0.123-0.03-0.176,0.007c-0.047,0.031-0.113,0.07-0.281,0.023
               c-0.19-0.045-0.229-0.023-0.268-0.008c-0.029,0.016-0.054,0.031-0.212,0C145.39,34.734,145.356,34.726,145.337,34.719z"/>
             <path fill="#808184" d="M149.112,37.178c-0.092,0.023-0.168,0.016-0.266-0.038
               c-0.086-0.046-0.146-0.046-0.207-0.053c-0.062-0.008-0.105-0.015-0.16-0.107c-0.045-0.075-0.068-0.129-0.084-0.175
               c-0.037-0.076-0.06-0.129-0.129-0.19c-0.045-0.046-0.113-0.092-0.183-0.137c0.062-0.038,0.16-0.076,0.198-0.046
               c0.037,0.023,0.054,0.054,0.068,0.084c0.021,0.046,0.045,0.091,0.121,0.121c0.053,0.016,0.123,0.016,0.182,0.008
               c0.078,0,0.162-0.008,0.199,0.031c0.047,0.045,0.062,0.084,0.067,0.129c0.009,0.038,0.022,0.076,0.054,0.114
               C149.015,36.973,149.075,37.102,149.112,37.178"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M149.112,37.178c-0.092,0.023-0.168,0.016-0.266-0.038c-0.086-0.046-0.146-0.046-0.207-0.053
               c-0.062-0.008-0.105-0.015-0.16-0.107c-0.045-0.075-0.068-0.129-0.084-0.175c-0.037-0.076-0.06-0.129-0.129-0.19
               c-0.045-0.046-0.113-0.092-0.183-0.137c0.062-0.038,0.16-0.076,0.198-0.046c0.037,0.023,0.054,0.054,0.068,0.084
               c0.021,0.046,0.045,0.091,0.121,0.121c0.053,0.016,0.123,0.016,0.182,0.008c0.078,0,0.162-0.008,0.199,0.031
               c0.047,0.045,0.062,0.084,0.067,0.129c0.009,0.038,0.022,0.076,0.054,0.114C149.015,36.973,149.075,37.102,149.112,37.178z"/>
             <path fill="#808184" d="M149.472,37.079c-0.022,0.007-0.055,0.015-0.092,0.023
               c-0.056,0.015-0.1,0.03-0.138,0.038l-0.03,0.015c-0.029-0.076-0.107-0.236-0.161-0.297c-0.015-0.023-0.021-0.053-0.03-0.083
               c-0.015-0.054-0.027-0.107-0.099-0.176c-0.067-0.069-0.175-0.061-0.272-0.053c-0.053,0.007-0.106,0.007-0.137-0.008
               c-0.039-0.015-0.056-0.038-0.07-0.068c-0.021-0.038-0.045-0.083-0.098-0.122c-0.105-0.077-0.283,0.023-0.353,0.068
               c-0.06-0.038-0.113-0.084-0.146-0.121c-0.053-0.076-0.099-0.131-0.15-0.16c-0.062-0.038-0.131-0.061-0.229-0.061
               c-0.075,0-0.159,0-0.221-0.016c-0.037-0.007-0.067-0.03-0.067-0.06c0-0.061-0.023-0.145-0.047-0.221
               c-0.021-0.061-0.037-0.115-0.031-0.137v-0.008c0.01-0.075,0.039-0.259-0.082-0.32c-0.039-0.015-0.062-0.076-0.076-0.129
               c0.105,0.023,0.158,0.008,0.221-0.007c0.047-0.008,0.1-0.023,0.197-0.016c0.123,0.008,0.26,0,0.382-0.015
               c0.104-0.015,0.206-0.045,0.259-0.076c0,0.008,0.007,0.045,0.068,0.122c0.092,0.114,0.137,0.121,0.189,0.129
               c0.016,0,0.038,0.008,0.063,0.015c0.008,0.008,0.016,0.015,0.021,0.024c0.03,0.022,0.053,0.037,0.067,0.06
               c0,0.008,0.01,0.023,0,0.023v0.008c-0.029,0.038-0.044,0.084-0.037,0.129c0,0.038,0.015,0.083,0.054,0.114
               c0.016,0.015,0.029,0.039,0.053,0.061c0.031,0.038,0.069,0.076,0.131,0.13c0.06,0.046,0.113,0.046,0.166,0.046h0.024v0.007
               c0,0.045-0.024,0.068-0.048,0.091c-0.029,0.03-0.061,0.061-0.052,0.122c0.007,0.053,0.036,0.107,0.091,0.167
               c0.038,0.046,0.1,0.099,0.176,0.145h0.006c0.084,0.046,0.153,0.068,0.215,0.084c0.045,0.015,0.092,0.03,0.121,0.053
               c0.03,0.031,0.055,0.046,0.063,0.069c0.016,0.03,0.021,0.06,0.021,0.107C149.401,36.858,149.448,37.003,149.472,37.079"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M149.472,37.079c-0.022,0.007-0.055,0.015-0.092,0.023c-0.056,0.015-0.1,0.03-0.138,0.038l-0.03,0.015
               c-0.029-0.076-0.107-0.236-0.161-0.297c-0.015-0.023-0.021-0.053-0.03-0.083c-0.015-0.054-0.027-0.107-0.099-0.176
               c-0.067-0.069-0.175-0.061-0.272-0.053c-0.053,0.007-0.106,0.007-0.137-0.008c-0.039-0.015-0.056-0.038-0.07-0.068
               c-0.021-0.038-0.045-0.083-0.098-0.122c-0.105-0.077-0.283,0.023-0.353,0.068c-0.06-0.038-0.113-0.084-0.146-0.121
               c-0.053-0.076-0.099-0.131-0.15-0.16c-0.062-0.038-0.131-0.061-0.229-0.061c-0.075,0-0.159,0-0.221-0.016
               c-0.037-0.007-0.067-0.03-0.067-0.06c0-0.061-0.023-0.145-0.047-0.221c-0.021-0.061-0.037-0.115-0.031-0.137v-0.008
               c0.01-0.075,0.039-0.259-0.082-0.32c-0.039-0.015-0.062-0.076-0.076-0.129c0.105,0.023,0.158,0.008,0.221-0.007
               c0.047-0.008,0.1-0.023,0.197-0.016c0.123,0.008,0.26,0,0.382-0.015c0.104-0.015,0.206-0.045,0.259-0.076
               c0,0.008,0.007,0.045,0.068,0.122c0.092,0.114,0.137,0.121,0.189,0.129c0.016,0,0.038,0.008,0.063,0.015
               c0.008,0.008,0.016,0.015,0.021,0.024c0.03,0.022,0.053,0.037,0.067,0.06c0,0.008,0.01,0.023,0,0.023v0.008
               c-0.029,0.038-0.044,0.084-0.037,0.129c0,0.038,0.015,0.083,0.054,0.114c0.016,0.015,0.029,0.039,0.053,0.061
               c0.031,0.038,0.069,0.076,0.131,0.13c0.06,0.046,0.113,0.046,0.166,0.046h0.024v0.007c0,0.045-0.024,0.068-0.048,0.091
               c-0.029,0.03-0.061,0.061-0.052,0.122c0.007,0.053,0.036,0.107,0.091,0.167c0.038,0.046,0.1,0.099,0.176,0.145h0.006
               c0.084,0.046,0.153,0.068,0.215,0.084c0.045,0.015,0.092,0.03,0.121,0.053c0.03,0.031,0.055,0.046,0.063,0.069
               c0.016,0.03,0.021,0.06,0.021,0.107C149.401,36.858,149.448,37.003,149.472,37.079z"/>
             <path fill="#808184" d="M144.478,43.459c-0.123,0.03-0.261,0.107-0.396,0.175
               c-0.105,0.061-0.206,0.115-0.266,0.13c-0.1,0.022-0.168,0.038-0.23,0.061c-0.061,0.023-0.12,0.045-0.204,0.084
               c-0.062,0.03-0.114,0.045-0.168,0.052c-0.092,0.016-0.168,0.03-0.237,0.123c-0.104,0.144,0.168,0.403,0.391,0.609
               c0.029,0.022,0.054,0.045,0.074,0.068c0.115,0.107,0.084,0.175,0.055,0.243c-0.029,0.055-0.055,0.115-0.055,0.184
               c0,0.144-0.037,0.167-0.174,0.251l-0.009,0.007c-0.129,0.069-0.235,0.069-0.319,0.061h-0.045c-0.076,0-0.129,0.099-0.184,0.206
               c-0.053,0.092-0.113,0.198-0.184,0.221c-0.084,0.037-0.111,0.084-0.145,0.137c-0.023,0.038-0.045,0.076-0.121,0.099
               c-0.092,0.038-0.146,0.023-0.236-0.008c-0.062-0.023-0.137-0.046-0.25-0.068c-0.139-0.016-0.274-0.039-0.381-0.054
               c0.037-0.083,0.09-0.198,0.105-0.312c0.008-0.076,0.021-0.153,0.037-0.221c0.023-0.144,0.046-0.289,0.055-0.396
               c0-0.076,0.021-0.136,0.045-0.198c0.029-0.068,0.055-0.144,0.061-0.236c0.01-0.083,0.01-0.137,0.023-0.182
               c0.016-0.054,0.029-0.099,0.055-0.168c0.06-0.145,0.045-0.252,0.021-0.373l-0.006-0.015c-0.009-0.03,0-0.1,0.006-0.19
               c0.017-0.183,0.031-0.42-0.046-0.587c-0.053-0.13-0.029-0.19-0.007-0.251c0.075,0.023,0.213,0.069,0.267,0.107
               c0.016,0.022,0.038,0.045,0.068,0.068c0.068,0.061,0.15,0.137,0.221,0.175c0.055,0.031,0.105,0.053,0.168,0.068
               c0.061,0.016,0.123,0.016,0.198,0.008c0.091-0.015,0.396-0.221,0.729-0.449c0.252-0.167,0.524-0.35,0.707-0.457
               c0.063,0.198,0.139,0.434,0.192,0.579C144.339,43.14,144.407,43.308,144.478,43.459"/> 
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M144.478,43.459c-0.123,0.03-0.261,0.107-0.396,0.175c-0.105,0.061-0.206,0.115-0.266,0.13c-0.1,0.022-0.168,0.038-0.23,0.061
               c-0.061,0.023-0.12,0.045-0.204,0.084c-0.062,0.03-0.114,0.045-0.168,0.052c-0.092,0.016-0.168,0.03-0.237,0.123
               c-0.104,0.144,0.168,0.403,0.391,0.609c0.029,0.022,0.054,0.045,0.074,0.068c0.115,0.107,0.084,0.175,0.055,0.243
               c-0.029,0.055-0.055,0.115-0.055,0.184c0,0.144-0.037,0.167-0.174,0.251l-0.009,0.007c-0.129,0.069-0.235,0.069-0.319,0.061
               h-0.045c-0.076,0-0.129,0.099-0.184,0.206c-0.053,0.092-0.113,0.198-0.184,0.221c-0.084,0.037-0.111,0.084-0.145,0.137
               c-0.023,0.038-0.045,0.076-0.121,0.099c-0.092,0.038-0.146,0.023-0.236-0.008c-0.062-0.023-0.137-0.046-0.25-0.068
               c-0.139-0.016-0.274-0.039-0.381-0.054c0.037-0.083,0.09-0.198,0.105-0.312c0.008-0.076,0.021-0.153,0.037-0.221
               c0.023-0.144,0.046-0.289,0.055-0.396c0-0.076,0.021-0.136,0.045-0.198c0.029-0.068,0.055-0.144,0.061-0.236
               c0.01-0.083,0.01-0.137,0.023-0.182c0.016-0.054,0.029-0.099,0.055-0.168c0.06-0.145,0.045-0.252,0.021-0.373l-0.006-0.015
               c-0.009-0.03,0-0.1,0.006-0.19c0.017-0.183,0.031-0.42-0.046-0.587c-0.053-0.13-0.029-0.19-0.007-0.251
               c0.075,0.023,0.213,0.069,0.267,0.107c0.016,0.022,0.038,0.045,0.068,0.068c0.068,0.061,0.15,0.137,0.221,0.175
               c0.055,0.031,0.105,0.053,0.168,0.068c0.061,0.016,0.123,0.016,0.198,0.008c0.091-0.015,0.396-0.221,0.729-0.449
               c0.252-0.167,0.524-0.35,0.707-0.457c0.063,0.198,0.139,0.434,0.192,0.579C144.339,43.14,144.407,43.308,144.478,43.459z"/>
             <path fill="#808184" d="M151.999,45.485c-0.114-0.053-0.26-0.099-0.402-0.114l-0.056-0.008
               c-0.288-0.03-0.511-0.045-0.548,0.092c-0.008,0.022-0.016,0.053-0.021,0.091c-0.022,0.106-0.055,0.251-0.093,0.282
               c-0.062,0.045-0.175,0.388-0.213,0.495c-0.327-0.016-0.671-0.023-0.792-0.016c-0.122,0.016-0.213,0.008-0.318-0.022
               c-0.101-0.031-0.215-0.092-0.367-0.206c-0.129-0.092-0.351-0.221-0.586-0.358c-0.343-0.198-0.715-0.411-0.875-0.555
               c-0.101-0.099-0.176-0.183-0.244-0.267c-0.099-0.13-0.197-0.251-0.403-0.381c-0.151-0.106-0.457-0.267-0.738-0.419
               c-0.297-0.159-0.571-0.305-0.616-0.35c-0.076-0.084-0.184-0.099-0.289-0.121c-0.047,0-0.084-0.009-0.129-0.023
               c-0.054-0.016-0.131-0.031-0.215-0.047c-0.15-0.022-0.326-0.06-0.427-0.106c-0.029-0.008-0.054-0.008-0.083-0.008
               c-0.068-0.159-0.152-0.334-0.197-0.471c-0.054-0.145-0.13-0.389-0.189-0.595c0.175-0.099,0.313-0.228,0.44-0.35
               c0.159-0.16,0.32-0.305,0.525-0.35l0.03-0.007c0.396-0.092,0.685-0.161,0.769-0.526c0.046-0.206,0.017-0.327-0.022-0.441
               c-0.021-0.084-0.045-0.16-0.021-0.267c0.029-0.137,0.045-0.221,0.045-0.312c0-0.084-0.023-0.176-0.053-0.297
               c-0.031-0.122-0.055-0.229-0.038-0.312c0.007-0.061,0.038-0.099,0.099-0.099c0.084,0,0.184-0.054,0.274-0.13
               c0.151-0.122,0.298-0.289,0.343-0.35c0.037-0.046,0.092-0.106,0.152-0.16c0.053-0.054,0.107-0.092,0.144-0.092
               c0.056,0,0.168,0.023,0.274,0.046c0.076,0.016,0.146,0.031,0.176,0.031c0.016,0,0.054-0.007,0.092-0.007
               c0.105-0.008,0.273-0.016,0.344-0.008c0.053,0.008,0.098-0.008,0.137-0.016c0.015-0.008,0.031-0.015,0.037-0.015
               c0.009,0,0,0.007,0,0.031l0.009,0.053c0,0.06,0,0.106,0.015,0.137c0.023,0.061,0.068,0.068,0.145,0.03
               c0.076-0.03,0.229-0.098,0.297-0.121c0.018,0.03,0.031,0.068,0.031,0.106c0.021,0.122,0.045,0.206,0.076,0.267
               c0.03,0.068,0.053,0.106,0.075,0.129c0.017,0.015,0.022,0.03,0.031,0.054c0.007,0.015,0.017,0.038,0.017,0.045
               c0,0.061,0.028,0.061,0.082,0.061c0.037,0.008,0.106,0.008,0.137,0.129c0.017,0.046,0.022,0.092,0.039,0.13
               c0.029,0.145,0.045,0.206,0.158,0.236c0.039,0.015,0.092,0.007,0.137,0.007c0.07-0.007,0.146-0.007,0.153,0.039
               c0.031,0.114,0.099,0.099,0.175,0.083c0.039-0.007,0.078-0.022,0.1,0.023c0.008,0.023-0.016,0.046-0.045,0.061
               c-0.076,0.053-0.152,0.099-0.068,0.228c0.047,0.069,0.1,0.107,0.13,0.138h0.009c0.028,0.03,0.053,0.046,0.021,0.084
               c-0.047,0.053-0.077,0.06-0.105,0.068c-0.031,0.007-0.063,0.015-0.092,0.069c-0.009,0.007-0.016,0.015-0.023,0.03
               c-0.084,0.122-0.267,0.388-0.183,0.616c0.046,0.145,0.046,0.236,0.038,0.313c-0.008,0.053-0.008,0.091,0,0.137
               c0.016,0.076,0.084,0.129,0.145,0.175c0.037,0.031,0.076,0.068,0.084,0.092c0.023,0.053,0.091,0.083,0.175,0.128
               c0.112,0.062,0.26,0.145,0.215,0.222c-0.032,0.068-0.047,0.144-0.047,0.213c0,0.046,0.015,0.099,0.038,0.145
               c0.031,0.038,0.061,0.075,0.113,0.106c0.055,0.03,0.123,0.046,0.207,0.038c0.135-0.008,0.244,0.015,0.326,0.046
               c0.075,0.038,0.122,0.084,0.139,0.144c0.021,0.099,0.091,0.13,0.183,0.168c0.06,0.03,0.136,0.068,0.213,0.145
               c0.099,0.107,0.121,0.198,0.144,0.289c0.018,0.053,0.024,0.114,0.063,0.175l0.016,0.031c0.063,0.114,0.09,0.175,0.068,0.35
               c-0.029,0.167,0.039,0.205,0.113,0.251c0.031,0.015,0.063,0.031,0.084,0.068c0.023,0.038,0.029,0.1,0.039,0.16
               c0.015,0.107,0.021,0.229,0.137,0.305C151.831,45.203,151.922,45.356,151.999,45.485"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M151.999,45.485c-0.114-0.053-0.26-0.099-0.402-0.114l-0.056-0.008c-0.288-0.03-0.511-0.045-0.548,0.092
               c-0.008,0.022-0.016,0.053-0.021,0.091c-0.022,0.106-0.055,0.251-0.093,0.282c-0.062,0.045-0.175,0.388-0.213,0.495
               c-0.327-0.016-0.671-0.023-0.792-0.016c-0.122,0.016-0.213,0.008-0.318-0.022c-0.101-0.031-0.215-0.092-0.367-0.206
               c-0.129-0.092-0.351-0.221-0.586-0.358c-0.343-0.198-0.715-0.411-0.875-0.555c-0.101-0.099-0.176-0.183-0.244-0.267
               c-0.099-0.13-0.197-0.251-0.403-0.381c-0.151-0.106-0.457-0.267-0.738-0.419c-0.297-0.159-0.571-0.305-0.616-0.35
               c-0.076-0.084-0.184-0.099-0.289-0.121c-0.047,0-0.084-0.009-0.129-0.023c-0.054-0.016-0.131-0.031-0.215-0.047
               c-0.15-0.022-0.326-0.06-0.427-0.106c-0.029-0.008-0.054-0.008-0.083-0.008c-0.068-0.159-0.152-0.334-0.197-0.471
               c-0.054-0.145-0.13-0.389-0.189-0.595c0.175-0.099,0.313-0.228,0.44-0.35c0.159-0.16,0.32-0.305,0.525-0.35l0.03-0.007
               c0.396-0.092,0.685-0.161,0.769-0.526c0.046-0.206,0.017-0.327-0.022-0.441c-0.021-0.084-0.045-0.16-0.021-0.267
               c0.029-0.137,0.045-0.221,0.045-0.312c0-0.084-0.023-0.176-0.053-0.297c-0.031-0.122-0.055-0.229-0.038-0.312
               c0.007-0.061,0.038-0.099,0.099-0.099c0.084,0,0.184-0.054,0.274-0.13c0.151-0.122,0.298-0.289,0.343-0.35
               c0.037-0.046,0.092-0.106,0.152-0.16c0.053-0.054,0.107-0.092,0.144-0.092c0.056,0,0.168,0.023,0.274,0.046
               c0.076,0.016,0.146,0.031,0.176,0.031c0.016,0,0.054-0.007,0.092-0.007c0.105-0.008,0.273-0.016,0.344-0.008
               c0.053,0.008,0.098-0.008,0.137-0.016c0.015-0.008,0.031-0.015,0.037-0.015c0.009,0,0,0.007,0,0.031l0.009,0.053
               c0,0.06,0,0.106,0.015,0.137c0.023,0.061,0.068,0.068,0.145,0.03c0.076-0.03,0.229-0.098,0.297-0.121
               c0.018,0.03,0.031,0.068,0.031,0.106c0.021,0.122,0.045,0.206,0.076,0.267c0.03,0.068,0.053,0.106,0.075,0.129
               c0.017,0.015,0.022,0.03,0.031,0.054c0.007,0.015,0.017,0.038,0.017,0.045c0,0.061,0.028,0.061,0.082,0.061
               c0.037,0.008,0.106,0.008,0.137,0.129c0.017,0.046,0.022,0.092,0.039,0.13c0.029,0.145,0.045,0.206,0.158,0.236
               c0.039,0.015,0.092,0.007,0.137,0.007c0.07-0.007,0.146-0.007,0.153,0.039c0.031,0.114,0.099,0.099,0.175,0.083
               c0.039-0.007,0.078-0.022,0.1,0.023c0.008,0.023-0.016,0.046-0.045,0.061c-0.076,0.053-0.152,0.099-0.068,0.228
               c0.047,0.069,0.1,0.107,0.13,0.138h0.009c0.028,0.03,0.053,0.046,0.021,0.084c-0.047,0.053-0.077,0.06-0.105,0.068
               c-0.031,0.007-0.063,0.015-0.092,0.069c-0.009,0.007-0.016,0.015-0.023,0.03c-0.084,0.122-0.267,0.388-0.183,0.616
               c0.046,0.145,0.046,0.236,0.038,0.313c-0.008,0.053-0.008,0.091,0,0.137c0.016,0.076,0.084,0.129,0.145,0.175
               c0.037,0.031,0.076,0.068,0.084,0.092c0.023,0.053,0.091,0.083,0.175,0.128c0.112,0.062,0.26,0.145,0.215,0.222
               c-0.032,0.068-0.047,0.144-0.047,0.213c0,0.046,0.015,0.099,0.038,0.145c0.031,0.038,0.061,0.075,0.113,0.106
               c0.055,0.03,0.123,0.046,0.207,0.038c0.135-0.008,0.244,0.015,0.326,0.046c0.075,0.038,0.122,0.084,0.139,0.144
               c0.021,0.099,0.091,0.13,0.183,0.168c0.06,0.03,0.136,0.068,0.213,0.145c0.099,0.107,0.121,0.198,0.144,0.289
               c0.018,0.053,0.024,0.114,0.063,0.175l0.016,0.031c0.063,0.114,0.09,0.175,0.068,0.35c-0.029,0.167,0.039,0.205,0.113,0.251
               c0.031,0.015,0.063,0.031,0.084,0.068c0.023,0.038,0.029,0.1,0.039,0.16c0.015,0.107,0.021,0.229,0.137,0.305
               C151.831,45.203,151.922,45.356,151.999,45.485z"/>
             <path fill="#808184" d="M150.294,30.265c-0.031-0.023-0.092-0.046-0.199-0.091
               c-0.145-0.061-0.234-0.13-0.273-0.176c-0.008-0.007-0.016-0.015-0.016-0.022h0.008h0.054c0.092-0.008,0.19-0.016,0.235-0.062
               c0.03-0.045,0.023-0.098-0.054-0.159c-0.062-0.053-0.104-0.084-0.146-0.115c-0.045-0.03-0.074-0.045-0.106-0.098
               c-0.015-0.023-0.028-0.046-0.045-0.084c-0.075-0.145-0.204-0.396-0.448-0.457c-0.168-0.046-0.26-0.03-0.318-0.015
               c-0.032,0.008-0.055,0.008-0.077,0c-0.062-0.031-0.104-0.015-0.153-0.008c-0.021,0.008-0.052,0.016-0.091,0.008
               c-0.045-0.008-0.076-0.023-0.093-0.038c-0.014-0.016-0.02-0.038-0.02-0.069c0-0.061-0.018-0.114-0.039-0.167
               c-0.039-0.061-0.084-0.122-0.131-0.161c-0.053-0.053-0.113-0.06-0.176-0.068c-0.037-0.007-0.083-0.015-0.105-0.038
               c-0.022-0.015-0.029-0.046-0.039-0.069c-0.015-0.045-0.006-0.083,0-0.106c0,0,0.017-0.023,0.045-0.053
               c0.084-0.099,0.238-0.259,0.191-0.411c-0.023-0.107-0.084-0.122-0.137-0.13c-0.03-0.007-0.063-0.015-0.063-0.137
               c0-0.137,0.017-0.236,0.039-0.312c0.016-0.068,0.045-0.122,0.075-0.167c0.046-0.077,0.015-0.168-0.008-0.259
               c-0.022-0.054-0.037-0.107-0.028-0.114c0.006,0,0.006-0.008,0.014-0.008c0.031-0.023,0.106-0.031,0.184-0.023
               c0.068,0.007,0.146,0.023,0.189,0.076c0.008,0.008,0.016,0.015,0.016,0.03h0.008c0.092,0.191,0.122,0.252,0.335,0.313
               c0.099,0.038,0.177,0.053,0.236,0.046c0.067-0.008,0.122-0.031,0.167-0.084c0.063-0.061,0.047-0.191,0.023-0.32
               c-0.023-0.114-0.039-0.229-0.009-0.282c0.022-0.061,0.076-0.084,0.123-0.099c0.06-0.023,0.113-0.046,0.136-0.114
               c0.022-0.038,0.022-0.076,0.022-0.107c0-0.03,0-0.053,0.069-0.06c0.076-0.016,0.16-0.038,0.244-0.077
               c0.082-0.038,0.158-0.099,0.234-0.198c0.046-0.068,0.084-0.129,0.115-0.175c0.045-0.068,0.066-0.107,0.1-0.114
               c0.037,0,0.073,0.03,0.121,0.069c0.037,0.03,0.068,0.061,0.113,0.083c0.063,0.031,0.138,0.062,0.22,0.084
               c0.084,0.015,0.19,0.015,0.291-0.038c0.075-0.038,0.138-0.084,0.183-0.122c0.055-0.046,0.09-0.076,0.123-0.061
               c0.029,0.015,0.051,0.038,0.076,0.069c0.045,0.046,0.084,0.099,0.182,0.13c0.068,0.014,0.207,0.014,0.351,0.014
               c0.16-0.007,0.32-0.014,0.351,0.016c0.031,0.023,0.055,0.053,0.069,0.075c0.029,0.054,0.066,0.1,0.158,0.161
               c0.076,0.053,0.153,0.076,0.222,0.098c0.062,0.016,0.114,0.031,0.16,0.062c0.038,0.03,0.061,0.083,0.083,0.144
               c0.031,0.076,0.062,0.145,0.123,0.176c0.037,0.014,0.074,0.022,0.104,0.007c0.023-0.007,0.039-0.016,0.062-0.038
               c0.017-0.015,0.031-0.031,0.039-0.053c0.022-0.046,0.028-0.107,0.015-0.168c0.016,0.007,0.03,0.023,0.037,0.031
               c0.024,0.037,0.161,0.152,0.307,0.235c0.1,0.054,0.205,0.099,0.289,0.107c0.1,0.007,0.184,0,0.242-0.023
               c0.069-0.023,0.115-0.069,0.146-0.136c0.007-0.016,0.016-0.031,0.021-0.046c0.03-0.084,0.063-0.176,0.168-0.183
               c0.068,0,0.121-0.023,0.184-0.046c0.076-0.03,0.15-0.06,0.244-0.015c0.066,0.038,0.092,0.099,0.105,0.137
               c0.008,0.015,0.008,0.03,0.016,0.046c0.029,0.052,0.068,0.068,0.158,0.023c0.063-0.039,0.123-0.092,0.184-0.145
               c0.054-0.046,0.107-0.092,0.131-0.092c0.016,0,0.029,0.008,0.052,0.023c0.056,0.023,0.123,0.053,0.245,0.061
               c0.1,0.008,0.129-0.023,0.15-0.045c0.018-0.008-0.016,0.015,0.063,0.091l0.038,0.038c0.177,0.16,0.298,0.266,0.511,0.281
               c0.105,0.008,0.197,0.008,0.268,0c0.082-0.007,0.144-0.037,0.167-0.099c0.008-0.014,0.016-0.037,0.022-0.06
               c0.014-0.046,0.029-0.107,0.053-0.13c0.008-0.007,0.031-0.007,0.068,0.031c0.084,0.075,0.159,0.13,0.236,0.168
               c0.076,0.029,0.145,0.037,0.213,0.022c0.021-0.008,0.061-0.015,0.105-0.022c0.146-0.031,0.336-0.062,0.344-0.199
               c0.008-0.076-0.008-0.106-0.031-0.144c-0.016-0.03-0.029-0.061-0.029-0.137c0.008-0.1-0.008-0.175-0.054-0.236
               c-0.054-0.054-0.13-0.084-0.251-0.099c-0.115-0.015-0.161-0.054-0.195-0.084c-0.041-0.031-0.07-0.054-0.134-0.069
               c-0.052-0.007-0.099-0.007-0.136-0.007c-0.047,0-0.084,0-0.105-0.023c-0.009-0.016-0.017-0.03-0.023-0.046
               c-0.016-0.053-0.03-0.091-0.131-0.106c-0.037,0-0.09-0.015-0.129-0.038l-0.006-0.008c0.006-0.008,0.016-0.015,0.029-0.023
               c0.046-0.023,0.105-0.038,0.168-0.053c0.066-0.016,0.137-0.038,0.189-0.061c0.099-0.054,0.152-0.122,0.084-0.259
               c-0.062-0.114-0.131-0.183-0.184-0.228c-0.029-0.031-0.054-0.046-0.039-0.069c0.039-0.045,0.047-0.099,0.063-0.145
               s0.021-0.084,0.068-0.075c0.037,0.007,0.076,0.029,0.114,0.052c0.085,0.046,0.146,0.084,0.204-0.03
               c0.039-0.054,0.084-0.129,0.092-0.198c0.008-0.084-0.029-0.152-0.168-0.152h-0.066c-0.092,0-0.168,0-0.207-0.008
               c0-0.008,0.009-0.023,0.017-0.03c0.017-0.023,0.03-0.046,0.038-0.069c0.008-0.061-0.016-0.106-0.13-0.129
               c-0.067-0.008-0.13-0.008-0.167,0h-0.039c-0.016,0,0.023,0,0.039-0.031c0.029-0.068,0.029-0.129,0.037-0.182
               c0-0.046,0.008-0.076,0.055-0.091c0.045-0.009,0.061-0.017,0.075-0.024h0.008c0.008,0,0.022,0,0.062,0.007
               c0.022,0.008,0.054,0.017,0.106,0.032c0.159,0.037,0.267,0.015,0.351,0c0.045-0.008,0.082-0.008,0.121-0.008
               c0.055,0.008,0.168-0.031,0.297-0.076c0.19-0.069,0.42-0.153,0.557-0.13c0.152,0.015,0.221-0.015,0.305-0.053
               c0.069-0.03,0.145-0.061,0.305-0.061c0.168,0,0.229,0.031,0.268,0.046c0.063,0.03,0.084,0.038,0.175-0.023
               c0.062-0.045,0.091-0.106,0.122-0.16c0.023-0.046,0.046-0.084,0.076-0.084c0.121,0.015,0.41-0.03,0.41-0.03h0.008v-0.008
               c0,0,0.252-0.083,0.503-0.107c0.183-0.022,0.221-0.037,0.28-0.06c0.023-0.008,0.047-0.023,0.093-0.03
               c0.022-0.016,0.055-0.023,0.085-0.031c0.121-0.023,0.212-0.053,0.273-0.183c0.008-0.031,0.015-0.053,0.021-0.077
               c0.009-0.014,0.009-0.029,0.009-0.029c0.007,0,0.029,0,0.099-0.008c0.092,0,0.189-0.038,0.281-0.061
               c0.076-0.03,0.146-0.046,0.19-0.046c0.106,0,0.213,0.038,0.335,0.092l0.007,0.007c0.068,0.031,0.185,0.045,0.29,0.045
               c0.114,0.008,0.221-0.007,0.273-0.029c0.045-0.023,0.113-0.038,0.184-0.031c0.046,0,0.084,0.015,0.113,0.046
               c0.023,0.038,0.053,0.106,0.076,0.182c0.045,0.13,0.1,0.267,0.189,0.282c0.023,0.007,0.038,0.007,0.047,0.015
               c0.067,0.008,0.12,0.015,0.129,0.092c0.008,0.046-0.009,0.076-0.015,0.098c-0.03,0.054-0.047,0.084,0.015,0.146
               c0.03,0.037,0.068,0.075,0.106,0.091c0.046,0.023,0.099,0.023,0.161,0.008c0.035-0.024,0.074-0.031,0.119-0.039
               c0.039-0.008,0.078-0.015,0.123-0.015c0.062,0,0.099-0.045,0.138-0.084c0.017-0.015,0.03-0.03,0.045-0.045
               c0.007-0.008,0.022,0,0.045,0.03c0.069,0.091,0.168,0.175,0.274,0.229c0.101,0.052,0.205,0.084,0.306,0.084
               c0.053-0.009,0.099-0.017,0.138-0.017c0.09-0.015,0.151-0.022,0.182,0.017c0.063,0.075,0.123,0.038,0.188,0
               c0.033-0.017,0.063-0.032,0.078-0.017c0.007,0.024-0.045,0.062-0.092,0.092c-0.037,0.023-0.076,0.054-0.091,0.076
               c-0.039,0.046-0.03,0.092,0.029,0.138c0.03,0.023,0.055,0.045,0.074,0.06c0.057,0.054,0.085,0.084,0.215,0.069
               c0.038-0.007,0.102-0.007,0.168-0.007c0.205-0.016,0.473-0.023,0.578-0.092c0.068-0.038,0.092-0.106,0.115-0.168
               c0.021-0.053,0.037-0.113,0.129-0.113c0.137-0.008,0.184-0.062,0.234-0.107c0.023-0.031,0.048-0.054,0.092-0.069
               c0.063-0.022,0.123-0.061,0.191-0.106c0.104-0.077,0.229-0.161,0.297-0.114c0.029,0.015,0.029,0.023,0.022,0.037
               c-0.008,0.008-0.016,0.023-0.03,0.039c-0.039,0.053-0.084,0.106-0.045,0.19c0.045,0.092,0.168,0.144,0.258,0.183
               c0.029,0.015,0.054,0.03,0.063,0.03c0.006,0.015,0.066,0.053,0.159,0.107c0.272,0.152,0.853,0.487,1.173,0.822
               c0.373,0.396,0.532,0.488,0.746,0.624c0.038,0.023,0.084,0.054,0.105,0.069c0.137,0.091,0.252,0.183,0.352,0.281
               c0.1,0.1,0.174,0.207,0.242,0.312c0.076,0.115,0.189,0.245,0.313,0.343c0.115,0.099,0.237,0.168,0.32,0.176h0.045
               c0.055,0.007,0.1,0.007,0.146,0.007c0.092-0.007,0.15-0.046,0.144-0.168c-0.007-0.045-0.015-0.083-0.021-0.121
               c-0.009-0.016-0.009-0.03-0.009-0.046c-0.008-0.023-0.008-0.038,0-0.038c0.015-0.008,0.029-0.008,0.046-0.015
               c0.062-0.015,0.137-0.031,0.174,0.03c0.024,0.03,0.031,0.053,0.039,0.077c0.022,0.068,0.03,0.098,0.16,0.121
               c0.075,0.008,0.1,0.038,0.137,0.061c0.022,0.03,0.055,0.061,0.115,0.084l0.006,0.008c0.131,0.053,0.19,0.084,0.397,0.068
               c0.075,0,0.114-0.008,0.144-0.015c0.03-0.008,0.047-0.015,0.168,0.007c0.151,0.024,0.244-0.015,0.344-0.06
               c0.06-0.023,0.113-0.046,0.189-0.062c0.129-0.03,0.151-0.022,0.215,0.008c0.028,0.015,0.067,0.031,0.119,0.054
               c0.092,0.038,0.237,0.091,0.366,0.159c0.129,0.076,0.244,0.16,0.297,0.259c0.076,0.152,0.152,0.16,0.251,0.16
               c0.053,0.007,0.114,0.007,0.191,0.062c0.035,0.029,0.074,0.06,0.105,0.083c0.137,0.099,0.243,0.175,0.318,0.289
               c0.102,0.145,0.13,0.145,0.328,0.167c0.029,0,0.061,0.008,0.113,0.008c0.093,0.016,0.177,0.031,0.252,0.046
               c0.184,0.045,0.313,0.076,0.357-0.038c0.021-0.046,0.076-0.091,0.137-0.13c0.022-0.015,0.055-0.023,0.069-0.023h0.037
               c0.007,0.008,0.007,0.016,0.015,0.031c0.018,0.092,0.047,0.176,0.107,0.244c0.023,0.023,0.046,0.045,0.068,0.06
               c-0.037,0.069-0.121,0.199-0.146,0.252c-0.029,0.054,0,0.114,0.031,0.183c0.029,0.061,0.063,0.137,0.054,0.205
               c-0.022,0.091-0.123,0.076-0.259,0.068c-0.032-0.007-0.063-0.007-0.077-0.007c-0.167-0.015-0.196,0.061-0.204,0.168
               c-0.008,0.015-0.008,0.038-0.008,0.06c0,0.039,0,0.077-0.008,0.114c-0.021,0.138,0.061,0.29,0.145,0.442
               c0.03,0.046,0.055,0.092,0.075,0.138l0.03,0.053c0.063,0.13,0.092,0.182,0.047,0.243c-0.038,0.053-0.114,0.099-0.184,0.144
               c-0.039,0.032-0.076,0.054-0.105,0.077c-0.008,0.007-0.016,0.015-0.016,0.015c-0.023,0.015-0.039,0.015-0.062,0.008
               c-0.017,0-0.038-0.023-0.063-0.046c-0.006-0.015-0.021-0.03-0.028-0.054c-0.084-0.129-0.465-0.067-0.67-0.037
               c-0.048,0.008-0.077,0.008-0.099,0.008c-0.047,0.007-0.092-0.023-0.152-0.077c-0.063-0.038-0.131-0.091-0.229-0.137
               c-0.115-0.061-0.177-0.046-0.207,0.016c-0.029,0.053-0.016,0.144,0.008,0.251c0.009,0.053,0.017,0.106,0.022,0.167
               c0.015,0.107,0.009,0.229-0.007,0.358c-0.032,0.19-0.077,0.366-0.1,0.457c-0.031,0.099,0.066,0.19,0.189,0.289
               c0.107,0.099,0.243,0.221,0.282,0.358c0.015,0.053,0.022,0.069,0.015,0.069c-0.007,0-0.045-0.008-0.084-0.024
               c-0.091-0.03-0.189-0.068-0.289-0.03c-0.121,0.054-0.16,0-0.251-0.144l-0.046-0.061c-0.13-0.19-0.511-0.023-0.837,0.114
               c-0.122,0.053-0.229,0.099-0.313,0.121c-0.113,0.039-0.145,0.084-0.129,0.146c0.021,0.076,0.137,0.136,0.267,0.197
               c0.075,0.046,0.159,0.092,0.22,0.129c0.107,0.077,0.131,0.214,0.161,0.351c0.022,0.13,0.054,0.259,0.129,0.381
               c0.084,0.13,0.138,0.213,0.183,0.304c0.055,0.084,0.102,0.175,0.184,0.298c0.063,0.091-0.021,0.182-0.105,0.266
               c-0.092,0.091-0.176,0.183-0.152,0.297c0.009,0.045,0,0.06-0.017,0.06c-0.036,0.008-0.099,0-0.149-0.007
               c-0.055-0.008-0.101-0.015-0.139-0.015c-0.076,0-0.121-0.007-0.177-0.038c-0.06-0.031-0.144-0.099-0.265-0.206
               c-0.244-0.213-0.77-0.175-1.145-0.145c-0.045,0-0.091,0.009-0.129,0.009c-0.183,0.014-0.334,0.014-0.464,0.014
               c-0.13-0.007-0.229-0.023-0.306-0.053c-0.12-0.053-0.229-0.031-0.409,0.008c-0.077,0.015-0.177,0.038-0.3,0.053
               c-0.303,0.046-0.691-0.152-0.903-0.259c-0.047-0.023-0.085-0.038-0.115-0.053c-0.167-0.069-0.313,0.022-0.479,0.129
               l-0.053,0.031c-0.1,0.068-0.123,0.159-0.139,0.243c-0.015,0.077-0.029,0.153-0.121,0.191c-0.121,0.053-0.213,0.007-0.342-0.069
               c-0.092-0.045-0.191-0.099-0.335-0.144c-0.274-0.091-0.39-0.077-0.526-0.054c-0.052,0.008-0.105,0.016-0.175,0.016
               c-0.213,0.015-0.228,0.175-0.243,0.381c-0.015,0.098-0.023,0.212-0.062,0.319c-0.068,0.19-0.198,0.22-0.319,0.244
               c-0.068,0.015-0.138,0.03-0.197,0.068c-0.039,0.015-0.068,0.031-0.099,0.046c-0.146,0.061-0.252,0.107-0.336,0.473
               c-0.069,0.311-0.153,0.38-0.252,0.456c-0.022,0.015-0.045,0.03-0.067,0.053c-0.009-0.015-0.017-0.038-0.022-0.069
               c-0.046-0.159-0.13-0.418-0.474-0.35c-0.045,0.016-0.099,0.016-0.15,0.016c-0.199-0.008-0.412-0.106-0.58-0.229
               c-0.174-0.114-0.297-0.251-0.313-0.35c-0.008,0-0.008-0.008-0.008-0.015c0.053-0.107,0.032-0.16-0.037-0.198
               c-0.054-0.031-0.138-0.039-0.236-0.039c-0.045,0-0.092-0.007-0.137-0.007c-0.009,0-0.017-0.007-0.031-0.007
               c-0.061-0.016-0.105-0.077-0.129-0.154c-0.029-0.083-0.045-0.189-0.029-0.304c0.008-0.053,0.015-0.106,0.029-0.167
               c0.055-0.16-0.021-0.229-0.138-0.259c-0.038-0.015-0.083-0.023-0.122-0.023c-0.068-0.015-0.129-0.022-0.136-0.045
               c-0.017-0.122-0.251-0.289-0.474-0.434c-0.084-0.061-0.168-0.114-0.229-0.16c-0.167-0.137-0.25-0.069-0.334,0.008
               c-0.037,0.022-0.068,0.052-0.107,0.06h-0.258c-0.236,0-0.549,0-0.724,0.031c-0.138,0.022-0.356,0.045-0.556,0.053
               c-0.161,0-0.297-0.008-0.344-0.022c-0.067-0.039-0.244-0.161-0.389-0.298c-0.105-0.106-0.206-0.205-0.234-0.281
               c-0.063-0.145-0.244-0.252-0.441-0.373c-0.1-0.054-0.191-0.107-0.273-0.168c-0.093-0.061-0.26-0.153-0.457-0.244
               c-0.009-0.045-0.017-0.084-0.023-0.122c-0.029-0.114,0.029-0.274-0.1-0.327c-0.129-0.061-0.235,0.03-0.242-0.13
               c-0.01-0.151-0.077-0.281,0.104-0.258c0.183,0.03,0.404-0.031,0.358-0.1c-0.045-0.067-0.196-0.037-0.305-0.037
               c-0.113,0-0.197,0.03-0.229-0.053c-0.031-0.084-0.115-0.183-0.16-0.199c-0.038-0.015-0.084,0.046-0.084,0.046
               c0,0.031,0,0.099,0.092,0.176c0.092,0.076,0.113,0.114,0.092,0.205c-0.022,0.091-0.037,0.129-0.092,0.16
               c-0.055,0.03-0.113-0.015-0.084,0.084c0.038,0.099,0.061,0.159,0.1,0.236c0.008,0.015,0.016,0.037,0.016,0.068l-0.145-0.068
               c-0.139-0.055-0.252-0.107-0.336-0.146c0-0.021,0.008-0.045,0.021-0.06c0.031-0.054-0.075-0.167,0.063-0.183
               c0.129-0.007,0.205-0.038,0.176-0.099c-0.03-0.068-0.084-0.145-0.146-0.083c-0.061,0.061-0.266,0.091-0.266,0.091
               c-0.023,0.023-0.031,0.015-0.038,0.136c0,0.069-0.017,0.092-0.03,0.123c-0.267,0.015-0.877,0.198-1.303,0.334
               c-0.168,0.046-0.305,0.092-0.381,0.107c-0.236,0.069,0.076,1.416,0.305,2.444c0.131,0.549,0.236,0.998,0.229,1.074
               c-0.129,0.076-0.213,0.03-0.336-0.038c-0.037-0.015-0.068-0.038-0.105-0.053c-0.184-0.091-0.244-0.221-0.32-0.404l-0.008-0.03
               c-0.052-0.114-0.174-0.213-0.313-0.29c-0.129-0.076-0.266-0.129-0.342-0.16c-0.138-0.045-0.367,0.008-0.58,0.061
               c-0.045,0.015-0.082,0.023-0.127,0.031c-0.115,0.03-0.306,0.19-0.488,0.365c-0.068-0.091-0.122-0.091-0.092-0.251
               c0.022-0.168,0.138-0.548-0.008-0.579c-0.145-0.038-0.16-0.03-0.404-0.083c-0.234-0.046-0.189-0.321-0.381-0.328
               c-0.188,0-0.28,0.183-0.304-0.045c-0.022-0.221-0.137-0.412-0.28-0.549c-0.14-0.137-0.056-0.289-0.261-0.335
               c-0.213-0.053-0.45-0.068-0.456-0.183c-0.009-0.113-0.029-0.289,0.129-0.243c0.159,0.053,0.327,0.099,0.435,0.144
               c0.099,0.046,0.222,0.023,0.243-0.014c0.029-0.031-0.137-0.168-0.205-0.252c-0.061-0.076-0.229-0.175-0.107-0.198
               c0.123-0.023,0.161,0.084,0.176-0.023c0.023-0.107,0.018-0.206,0.047-0.228c0.029-0.031,0.176-0.236,0.326-0.16
               c0.146,0.069,0.281,0.129,0.57,0.136c0.292,0.016,0.42,0,0.58,0.046c0.158,0.046,0.343,0,0.365,0.054
               c0.031,0.053,0.061,0.098-0.038,0.152c-0.106,0.046-0.151,0.122-0.161,0.16c-0.008,0.045-0.229,0.342-0.1,0.29
               c0.123-0.047,0.177-0.412,0.322-0.389c0.143,0.023,0.197,0.008,0.204-0.045c0.007-0.046,0.091-0.222,0.015-0.305
               c-0.082-0.076-0.158-0.175-0.273-0.107c-0.105,0.069-0.242,0.152-0.357,0.092c-0.113-0.069-0.281-0.032-0.297-0.16
               c-0.015-0.129,0.092-0.426,0.047-0.609c-0.047-0.183-0.131-0.13-0.176-0.298c-0.047-0.167-0.092-0.373-0.236-0.388
               c-0.144-0.015-0.121,0.031-0.355,0.069c-0.236,0.045-0.52-0.03-0.746-0.091c-0.23-0.062-0.374-0.069-0.465,0.022
               c-0.084,0.091-0.215,0.13-0.42,0.267c-0.206,0.145-0.504,0.121-0.504,0.121S150.354,30.181,150.294,30.265 M158.312,30.082
               c-0.022,0-0.138,0-0.1,0.13c0.046,0.129-0.092,0.213,0.092,0.167c0.189-0.038,0.159-0.114,0.207-0.076
               c0.045,0.038,0.045,0.129,0.143,0.107c0.1-0.031,0.115-0.107,0.16-0.046c0.039,0.061,0.106,0.183,0.189,0.144
               c0.076-0.037,0.206-0.198,0.122-0.212c-0.083-0.008-0.214,0.053-0.259-0.016c-0.045-0.068-0.053-0.159-0.129-0.167
               c-0.069-0.008-0.039,0.037-0.146,0.022c-0.099-0.014-0.105-0.068-0.15-0.076C158.396,30.051,158.312,30.082,158.312,30.082
                M169.03,29.892c0,0-0.205-0.023-0.235,0.129c-0.022,0.153-0.129,0.145-0.213,0.198c-0.092,0.054-0.151,0.054-0.122,0.152
               c0.03,0.1-0.055,0.137-0.122,0.168c-0.069,0.039-0.168,0.046-0.075,0.167c0.084,0.115,0,0.168,0.014,0.252
               c0.01,0.084,0.063,0.076,0.199,0.19c0.129,0.114,0.342,0.183,0.39,0.289c0.052,0.107,0.112,0.221,0.136,0.107
               c0.021-0.107-0.052-0.191-0.105-0.297c-0.055-0.107-0.076-0.144-0.029-0.236c0.037-0.091,0.091-0.083,0.037-0.19
               c-0.063-0.107-0.129-0.266-0.03-0.243c0.099,0.014,0.122,0.152,0.206,0.022c0.091-0.129,0.021-0.373,0.175-0.373
               c0.144-0.008,0.212,0.076,0.261-0.008c0.035-0.084-0.07-0.129,0.174-0.091c0.242,0.038,0.617,0.022,0.707,0.038
               c0.1,0.023,0.047-0.107,0.252,0.008c0.197,0.122,0.045-0.031,0.44,0.075c0.397,0.108,0.397,0.061,0.595,0.084
               c0.19,0.024,0.168-0.015,0.327-0.037c0.169-0.031,0.298-0.206,0.229-0.282c-0.061-0.084-0.145-0.191-0.273-0.092
               c-0.138,0.092-0.229,0.199-0.306,0.199c-0.068,0-0.104-0.115-0.251-0.123c-0.139-0.007-0.335,0.023-0.449,0.031
               c-0.115,0.008-0.152-0.015-0.221,0.022c-0.068,0.031-0.244-0.06-0.344-0.06c-0.099,0-0.076,0.053-0.197,0.015
               c-0.113-0.038-0.197-0.145-0.26-0.145c-0.06,0-0.06,0.031-0.242,0.069c-0.182,0.03-0.152,0.076-0.252,0.015
               c-0.105-0.06-0.221-0.092-0.28-0.084C169.106,29.861,169.03,29.892,169.03,29.892"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M150.294,30.265c-0.031-0.023-0.092-0.046-0.199-0.091c-0.145-0.061-0.234-0.13-0.273-0.176
               c-0.008-0.007-0.016-0.015-0.016-0.022h0.008h0.054c0.092-0.008,0.19-0.016,0.235-0.062c0.03-0.045,0.023-0.098-0.054-0.159
               c-0.062-0.053-0.104-0.084-0.146-0.115c-0.045-0.03-0.074-0.045-0.106-0.098c-0.015-0.023-0.028-0.046-0.045-0.084
               c-0.075-0.145-0.204-0.396-0.448-0.457c-0.168-0.046-0.26-0.03-0.318-0.015c-0.032,0.008-0.055,0.008-0.077,0
               c-0.062-0.031-0.104-0.015-0.153-0.008c-0.021,0.008-0.052,0.016-0.091,0.008c-0.045-0.008-0.076-0.023-0.093-0.038
               c-0.014-0.016-0.02-0.038-0.02-0.069c0-0.061-0.018-0.114-0.039-0.167c-0.039-0.061-0.084-0.122-0.131-0.161
               c-0.053-0.053-0.113-0.06-0.176-0.068c-0.037-0.007-0.083-0.015-0.105-0.038c-0.022-0.015-0.029-0.046-0.039-0.069
               c-0.015-0.045-0.006-0.083,0-0.106c0,0,0.017-0.023,0.045-0.053c0.084-0.099,0.238-0.259,0.191-0.411
               c-0.023-0.107-0.084-0.122-0.137-0.13c-0.03-0.007-0.063-0.015-0.063-0.137c0-0.137,0.017-0.236,0.039-0.312
               c0.016-0.068,0.045-0.122,0.075-0.167c0.046-0.077,0.015-0.168-0.008-0.259c-0.022-0.054-0.037-0.107-0.028-0.114
               c0.006,0,0.006-0.008,0.014-0.008c0.031-0.023,0.106-0.031,0.184-0.023c0.068,0.007,0.146,0.023,0.189,0.076
               c0.008,0.008,0.016,0.015,0.016,0.03h0.008c0.092,0.191,0.122,0.252,0.335,0.313c0.099,0.038,0.177,0.053,0.236,0.046
               c0.067-0.008,0.122-0.031,0.167-0.084c0.063-0.061,0.047-0.191,0.023-0.32c-0.023-0.114-0.039-0.229-0.009-0.282
               c0.022-0.061,0.076-0.084,0.123-0.099c0.06-0.023,0.113-0.046,0.136-0.114c0.022-0.038,0.022-0.076,0.022-0.107
               c0-0.03,0-0.053,0.069-0.06c0.076-0.016,0.16-0.038,0.244-0.077c0.082-0.038,0.158-0.099,0.234-0.198
               c0.046-0.068,0.084-0.129,0.115-0.175c0.045-0.068,0.066-0.107,0.1-0.114c0.037,0,0.073,0.03,0.121,0.069
               c0.037,0.03,0.068,0.061,0.113,0.083c0.063,0.031,0.138,0.062,0.22,0.084c0.084,0.015,0.19,0.015,0.291-0.038
               c0.075-0.038,0.138-0.084,0.183-0.122c0.055-0.046,0.09-0.076,0.123-0.061c0.029,0.015,0.051,0.038,0.076,0.069
               c0.045,0.046,0.084,0.099,0.182,0.13c0.068,0.014,0.207,0.014,0.351,0.014c0.16-0.007,0.32-0.014,0.351,0.016
               c0.031,0.023,0.055,0.053,0.069,0.075c0.029,0.054,0.066,0.1,0.158,0.161c0.076,0.053,0.153,0.076,0.222,0.098
               c0.062,0.016,0.114,0.031,0.16,0.062c0.038,0.03,0.061,0.083,0.083,0.144c0.031,0.076,0.062,0.145,0.123,0.176
               c0.037,0.014,0.074,0.022,0.104,0.007c0.023-0.007,0.039-0.016,0.062-0.038c0.017-0.015,0.031-0.031,0.039-0.053
               c0.022-0.046,0.028-0.107,0.015-0.168c0.016,0.007,0.03,0.023,0.037,0.031c0.024,0.037,0.161,0.152,0.307,0.235
               c0.1,0.054,0.205,0.099,0.289,0.107c0.1,0.007,0.184,0,0.242-0.023c0.069-0.023,0.115-0.069,0.146-0.136
               c0.007-0.016,0.016-0.031,0.021-0.046c0.03-0.084,0.063-0.176,0.168-0.183c0.068,0,0.121-0.023,0.184-0.046
               c0.076-0.03,0.15-0.06,0.244-0.015c0.066,0.038,0.092,0.099,0.105,0.137c0.008,0.015,0.008,0.03,0.016,0.046
               c0.029,0.052,0.068,0.068,0.158,0.023c0.063-0.039,0.123-0.092,0.184-0.145c0.054-0.046,0.107-0.092,0.131-0.092
               c0.016,0,0.029,0.008,0.052,0.023c0.056,0.023,0.123,0.053,0.245,0.061c0.1,0.008,0.129-0.023,0.15-0.045
               c0.018-0.008-0.016,0.015,0.063,0.091l0.038,0.038c0.177,0.16,0.298,0.266,0.511,0.281c0.105,0.008,0.197,0.008,0.268,0
               c0.082-0.007,0.144-0.037,0.167-0.099c0.008-0.014,0.016-0.037,0.022-0.06c0.014-0.046,0.029-0.107,0.053-0.13
               c0.008-0.007,0.031-0.007,0.068,0.031c0.084,0.075,0.159,0.13,0.236,0.168c0.076,0.029,0.145,0.037,0.213,0.022
               c0.021-0.008,0.061-0.015,0.105-0.022c0.146-0.031,0.336-0.062,0.344-0.199c0.008-0.076-0.008-0.106-0.031-0.144
               c-0.016-0.03-0.029-0.061-0.029-0.137c0.008-0.1-0.008-0.175-0.054-0.236c-0.054-0.054-0.13-0.084-0.251-0.099
               c-0.115-0.015-0.161-0.054-0.195-0.084c-0.041-0.031-0.07-0.054-0.134-0.069c-0.052-0.007-0.099-0.007-0.136-0.007
               c-0.047,0-0.084,0-0.105-0.023c-0.009-0.016-0.017-0.03-0.023-0.046c-0.016-0.053-0.03-0.091-0.131-0.106
               c-0.037,0-0.09-0.015-0.129-0.038l-0.006-0.008c0.006-0.008,0.016-0.015,0.029-0.023c0.046-0.023,0.105-0.038,0.168-0.053
               c0.066-0.016,0.137-0.038,0.189-0.061c0.099-0.054,0.152-0.122,0.084-0.259c-0.062-0.114-0.131-0.183-0.184-0.228
               c-0.029-0.031-0.054-0.046-0.039-0.069c0.039-0.045,0.047-0.099,0.063-0.145s0.021-0.084,0.068-0.075
               c0.037,0.007,0.076,0.029,0.114,0.052c0.085,0.046,0.146,0.084,0.204-0.03c0.039-0.054,0.084-0.129,0.092-0.198
               c0.008-0.084-0.029-0.152-0.168-0.152h-0.066c-0.092,0-0.168,0-0.207-0.008c0-0.008,0.009-0.023,0.017-0.03
               c0.017-0.023,0.03-0.046,0.038-0.069c0.008-0.061-0.016-0.106-0.13-0.129c-0.067-0.008-0.13-0.008-0.167,0h-0.039
               c-0.016,0,0.023,0,0.039-0.031c0.029-0.068,0.029-0.129,0.037-0.182c0-0.046,0.008-0.076,0.055-0.091
               c0.045-0.009,0.061-0.017,0.075-0.024h0.008c0.008,0,0.022,0,0.062,0.007c0.022,0.008,0.054,0.017,0.106,0.032
               c0.159,0.037,0.267,0.015,0.351,0c0.045-0.008,0.082-0.008,0.121-0.008c0.055,0.008,0.168-0.031,0.297-0.076
               c0.19-0.069,0.42-0.153,0.557-0.13c0.152,0.015,0.221-0.015,0.305-0.053c0.069-0.03,0.145-0.061,0.305-0.061
               c0.168,0,0.229,0.031,0.268,0.046c0.063,0.03,0.084,0.038,0.175-0.023c0.062-0.045,0.091-0.106,0.122-0.16
               c0.023-0.046,0.046-0.084,0.076-0.084c0.121,0.015,0.41-0.03,0.41-0.03h0.008v-0.008c0,0,0.252-0.083,0.503-0.107
               c0.183-0.022,0.221-0.037,0.28-0.06c0.023-0.008,0.047-0.023,0.093-0.03c0.022-0.016,0.055-0.023,0.085-0.031
               c0.121-0.023,0.212-0.053,0.273-0.183c0.008-0.031,0.015-0.053,0.021-0.077c0.009-0.014,0.009-0.029,0.009-0.029
               c0.007,0,0.029,0,0.099-0.008c0.092,0,0.189-0.038,0.281-0.061c0.076-0.03,0.146-0.046,0.19-0.046
               c0.106,0,0.213,0.038,0.335,0.092l0.007,0.007c0.068,0.031,0.185,0.045,0.29,0.045c0.114,0.008,0.221-0.007,0.273-0.029
               c0.045-0.023,0.113-0.038,0.184-0.031c0.046,0,0.084,0.015,0.113,0.046c0.023,0.038,0.053,0.106,0.076,0.182
               c0.045,0.13,0.1,0.267,0.189,0.282c0.023,0.007,0.038,0.007,0.047,0.015c0.067,0.008,0.12,0.015,0.129,0.092
               c0.008,0.046-0.009,0.076-0.015,0.098c-0.03,0.054-0.047,0.084,0.015,0.146c0.03,0.037,0.068,0.075,0.106,0.091
               c0.046,0.023,0.099,0.023,0.161,0.008c0.035-0.024,0.074-0.031,0.119-0.039c0.039-0.008,0.078-0.015,0.123-0.015
               c0.062,0,0.099-0.045,0.138-0.084c0.017-0.015,0.03-0.03,0.045-0.045c0.007-0.008,0.022,0,0.045,0.03
               c0.069,0.091,0.168,0.175,0.274,0.229c0.101,0.052,0.205,0.084,0.306,0.084c0.053-0.009,0.099-0.017,0.138-0.017
               c0.09-0.015,0.151-0.022,0.182,0.017c0.063,0.075,0.123,0.038,0.188,0c0.033-0.017,0.063-0.032,0.078-0.017
               c0.007,0.024-0.045,0.062-0.092,0.092c-0.037,0.023-0.076,0.054-0.091,0.076c-0.039,0.046-0.03,0.092,0.029,0.138
               c0.03,0.023,0.055,0.045,0.074,0.06c0.057,0.054,0.085,0.084,0.215,0.069c0.038-0.007,0.102-0.007,0.168-0.007
               c0.205-0.016,0.473-0.023,0.578-0.092c0.068-0.038,0.092-0.106,0.115-0.168c0.021-0.053,0.037-0.113,0.129-0.113
               c0.137-0.008,0.184-0.062,0.234-0.107c0.023-0.031,0.048-0.054,0.092-0.069c0.063-0.022,0.123-0.061,0.191-0.106
               c0.104-0.077,0.229-0.161,0.297-0.114c0.029,0.015,0.029,0.023,0.022,0.037c-0.008,0.008-0.016,0.023-0.03,0.039
               c-0.039,0.053-0.084,0.106-0.045,0.19c0.045,0.092,0.168,0.144,0.258,0.183c0.029,0.015,0.054,0.03,0.063,0.03
               c0.006,0.015,0.066,0.053,0.159,0.107c0.272,0.152,0.853,0.487,1.173,0.822c0.373,0.396,0.532,0.488,0.746,0.624
               c0.038,0.023,0.084,0.054,0.105,0.069c0.137,0.091,0.252,0.183,0.352,0.281c0.1,0.1,0.174,0.207,0.242,0.312
               c0.076,0.115,0.189,0.245,0.313,0.343c0.115,0.099,0.237,0.168,0.32,0.176h0.045c0.055,0.007,0.1,0.007,0.146,0.007
               c0.092-0.007,0.15-0.046,0.144-0.168c-0.007-0.045-0.015-0.083-0.021-0.121c-0.009-0.016-0.009-0.03-0.009-0.046
               c-0.008-0.023-0.008-0.038,0-0.038c0.015-0.008,0.029-0.008,0.046-0.015c0.062-0.015,0.137-0.031,0.174,0.03
               c0.024,0.03,0.031,0.053,0.039,0.077c0.022,0.068,0.03,0.098,0.16,0.121c0.075,0.008,0.1,0.038,0.137,0.061
               c0.022,0.03,0.055,0.061,0.115,0.084l0.006,0.008c0.131,0.053,0.19,0.084,0.397,0.068c0.075,0,0.114-0.008,0.144-0.015
               c0.03-0.008,0.047-0.015,0.168,0.007c0.151,0.024,0.244-0.015,0.344-0.06c0.06-0.023,0.113-0.046,0.189-0.062
               c0.129-0.03,0.151-0.022,0.215,0.008c0.028,0.015,0.067,0.031,0.119,0.054c0.092,0.038,0.237,0.091,0.366,0.159
               c0.129,0.076,0.244,0.16,0.297,0.259c0.076,0.152,0.152,0.16,0.251,0.16c0.053,0.007,0.114,0.007,0.191,0.062
               c0.035,0.029,0.074,0.06,0.105,0.083c0.137,0.099,0.243,0.175,0.318,0.289c0.102,0.145,0.13,0.145,0.328,0.167
               c0.029,0,0.061,0.008,0.113,0.008c0.093,0.016,0.177,0.031,0.252,0.046c0.184,0.045,0.313,0.076,0.357-0.038
               c0.021-0.046,0.076-0.091,0.137-0.13c0.022-0.015,0.055-0.023,0.069-0.023h0.037c0.007,0.008,0.007,0.016,0.015,0.031
               c0.018,0.092,0.047,0.176,0.107,0.244c0.023,0.023,0.046,0.045,0.068,0.06c-0.037,0.069-0.121,0.199-0.146,0.252
               c-0.029,0.054,0,0.114,0.031,0.183c0.029,0.061,0.063,0.137,0.054,0.205c-0.022,0.091-0.123,0.076-0.259,0.068
               c-0.032-0.007-0.063-0.007-0.077-0.007c-0.167-0.015-0.196,0.061-0.204,0.168c-0.008,0.015-0.008,0.038-0.008,0.06
               c0,0.039,0,0.077-0.008,0.114c-0.021,0.138,0.061,0.29,0.145,0.442c0.03,0.046,0.055,0.092,0.075,0.138l0.03,0.053
               c0.063,0.13,0.092,0.182,0.047,0.243c-0.038,0.053-0.114,0.099-0.184,0.144c-0.039,0.032-0.076,0.054-0.105,0.077
               c-0.008,0.007-0.016,0.015-0.016,0.015c-0.023,0.015-0.039,0.015-0.062,0.008c-0.017,0-0.038-0.023-0.063-0.046
               c-0.006-0.015-0.021-0.03-0.028-0.054c-0.084-0.129-0.465-0.067-0.67-0.037c-0.048,0.008-0.077,0.008-0.099,0.008
               c-0.047,0.007-0.092-0.023-0.152-0.077c-0.063-0.038-0.131-0.091-0.229-0.137c-0.115-0.061-0.177-0.046-0.207,0.016
               c-0.029,0.053-0.016,0.144,0.008,0.251c0.009,0.053,0.017,0.106,0.022,0.167c0.015,0.107,0.009,0.229-0.007,0.358
               c-0.032,0.19-0.077,0.366-0.1,0.457c-0.031,0.099,0.066,0.19,0.189,0.289c0.107,0.099,0.243,0.221,0.282,0.358
               c0.015,0.053,0.022,0.069,0.015,0.069c-0.007,0-0.045-0.008-0.084-0.024c-0.091-0.03-0.189-0.068-0.289-0.03
               c-0.121,0.054-0.16,0-0.251-0.144l-0.046-0.061c-0.13-0.19-0.511-0.023-0.837,0.114c-0.122,0.053-0.229,0.099-0.313,0.121
               c-0.113,0.039-0.145,0.084-0.129,0.146c0.021,0.076,0.137,0.136,0.267,0.197c0.075,0.046,0.159,0.092,0.22,0.129
               c0.107,0.077,0.131,0.214,0.161,0.351c0.022,0.13,0.054,0.259,0.129,0.381c0.084,0.13,0.138,0.213,0.183,0.304
               c0.055,0.084,0.102,0.175,0.184,0.298c0.063,0.091-0.021,0.182-0.105,0.266c-0.092,0.091-0.176,0.183-0.152,0.297
               c0.009,0.045,0,0.06-0.017,0.06c-0.036,0.008-0.099,0-0.149-0.007c-0.055-0.008-0.101-0.015-0.139-0.015
               c-0.076,0-0.121-0.007-0.177-0.038c-0.06-0.031-0.144-0.099-0.265-0.206c-0.244-0.213-0.77-0.175-1.145-0.145
               c-0.045,0-0.091,0.009-0.129,0.009c-0.183,0.014-0.334,0.014-0.464,0.014c-0.13-0.007-0.229-0.023-0.306-0.053
               c-0.12-0.053-0.229-0.031-0.409,0.008c-0.077,0.015-0.177,0.038-0.3,0.053c-0.303,0.046-0.691-0.152-0.903-0.259
               c-0.047-0.023-0.085-0.038-0.115-0.053c-0.167-0.069-0.313,0.022-0.479,0.129l-0.053,0.031c-0.1,0.068-0.123,0.159-0.139,0.243
               c-0.015,0.077-0.029,0.153-0.121,0.191c-0.121,0.053-0.213,0.007-0.342-0.069c-0.092-0.045-0.191-0.099-0.335-0.144
               c-0.274-0.091-0.39-0.077-0.526-0.054c-0.052,0.008-0.105,0.016-0.175,0.016c-0.213,0.015-0.228,0.175-0.243,0.381
               c-0.015,0.098-0.023,0.212-0.062,0.319c-0.068,0.19-0.198,0.22-0.319,0.244c-0.068,0.015-0.138,0.03-0.197,0.068
               c-0.039,0.015-0.068,0.031-0.099,0.046c-0.146,0.061-0.252,0.107-0.336,0.473c-0.069,0.311-0.153,0.38-0.252,0.456
               c-0.022,0.015-0.045,0.03-0.067,0.053c-0.009-0.015-0.017-0.038-0.022-0.069c-0.046-0.159-0.13-0.418-0.474-0.35
               c-0.045,0.016-0.099,0.016-0.15,0.016c-0.199-0.008-0.412-0.106-0.58-0.229c-0.174-0.114-0.297-0.251-0.313-0.35
               c-0.008,0-0.008-0.008-0.008-0.015c0.053-0.107,0.032-0.16-0.037-0.198c-0.054-0.031-0.138-0.039-0.236-0.039
               c-0.045,0-0.092-0.007-0.137-0.007c-0.009,0-0.017-0.007-0.031-0.007c-0.061-0.016-0.105-0.077-0.129-0.154
               c-0.029-0.083-0.045-0.189-0.029-0.304c0.008-0.053,0.015-0.106,0.029-0.167c0.055-0.16-0.021-0.229-0.138-0.259
               c-0.038-0.015-0.083-0.023-0.122-0.023c-0.068-0.015-0.129-0.022-0.136-0.045c-0.017-0.122-0.251-0.289-0.474-0.434
               c-0.084-0.061-0.168-0.114-0.229-0.16c-0.167-0.137-0.25-0.069-0.334,0.008c-0.037,0.022-0.068,0.052-0.107,0.06h-0.258
               c-0.236,0-0.549,0-0.724,0.031c-0.138,0.022-0.356,0.045-0.556,0.053c-0.161,0-0.297-0.008-0.344-0.022
               c-0.067-0.039-0.244-0.161-0.389-0.298c-0.105-0.106-0.206-0.205-0.234-0.281c-0.063-0.145-0.244-0.252-0.441-0.373
               c-0.1-0.054-0.191-0.107-0.273-0.168c-0.093-0.061-0.26-0.153-0.457-0.244c-0.009-0.045-0.017-0.084-0.023-0.122
               c-0.029-0.114,0.029-0.274-0.1-0.327c-0.129-0.061-0.235,0.03-0.242-0.13c-0.01-0.151-0.077-0.281,0.104-0.258
               c0.183,0.03,0.404-0.031,0.358-0.1c-0.045-0.067-0.196-0.037-0.305-0.037c-0.113,0-0.197,0.03-0.229-0.053
               c-0.031-0.084-0.115-0.183-0.16-0.199c-0.038-0.015-0.084,0.046-0.084,0.046c0,0.031,0,0.099,0.092,0.176
               c0.092,0.076,0.113,0.114,0.092,0.205c-0.022,0.091-0.037,0.129-0.092,0.16c-0.055,0.03-0.113-0.015-0.084,0.084
               c0.038,0.099,0.061,0.159,0.1,0.236c0.008,0.015,0.016,0.037,0.016,0.068l-0.145-0.068c-0.139-0.055-0.252-0.107-0.336-0.146
               c0-0.021,0.008-0.045,0.021-0.06c0.031-0.054-0.075-0.167,0.063-0.183c0.129-0.007,0.205-0.038,0.176-0.099
               c-0.03-0.068-0.084-0.145-0.146-0.083c-0.061,0.061-0.266,0.091-0.266,0.091c-0.023,0.023-0.031,0.015-0.038,0.136
               c0,0.069-0.017,0.092-0.03,0.123c-0.267,0.015-0.877,0.198-1.303,0.334c-0.168,0.046-0.305,0.092-0.381,0.107
               c-0.236,0.069,0.076,1.416,0.305,2.444c0.131,0.549,0.236,0.998,0.229,1.074c-0.129,0.076-0.213,0.03-0.336-0.038
               c-0.037-0.015-0.068-0.038-0.105-0.053c-0.184-0.091-0.244-0.221-0.32-0.404l-0.008-0.03c-0.052-0.114-0.174-0.213-0.313-0.29
               c-0.129-0.076-0.266-0.129-0.342-0.16c-0.138-0.045-0.367,0.008-0.58,0.061c-0.045,0.015-0.082,0.023-0.127,0.031
               c-0.115,0.03-0.306,0.19-0.488,0.365c-0.068-0.091-0.122-0.091-0.092-0.251c0.022-0.168,0.138-0.548-0.008-0.579
               c-0.145-0.038-0.16-0.03-0.404-0.083c-0.234-0.046-0.189-0.321-0.381-0.328c-0.188,0-0.28,0.183-0.304-0.045
               c-0.022-0.221-0.137-0.412-0.28-0.549c-0.14-0.137-0.056-0.289-0.261-0.335c-0.213-0.053-0.45-0.068-0.456-0.183
               c-0.009-0.113-0.029-0.289,0.129-0.243c0.159,0.053,0.327,0.099,0.435,0.144c0.099,0.046,0.222,0.023,0.243-0.014
               c0.029-0.031-0.137-0.168-0.205-0.252c-0.061-0.076-0.229-0.175-0.107-0.198c0.123-0.023,0.161,0.084,0.176-0.023
               c0.023-0.107,0.018-0.206,0.047-0.228c0.029-0.031,0.176-0.236,0.326-0.16c0.146,0.069,0.281,0.129,0.57,0.136
               c0.292,0.016,0.42,0,0.58,0.046c0.158,0.046,0.343,0,0.365,0.054c0.031,0.053,0.061,0.098-0.038,0.152
               c-0.106,0.046-0.151,0.122-0.161,0.16c-0.008,0.045-0.229,0.342-0.1,0.29c0.123-0.047,0.177-0.412,0.322-0.389
               c0.143,0.023,0.197,0.008,0.204-0.045c0.007-0.046,0.091-0.222,0.015-0.305c-0.082-0.076-0.158-0.175-0.273-0.107
               c-0.105,0.069-0.242,0.152-0.357,0.092c-0.113-0.069-0.281-0.032-0.297-0.16c-0.015-0.129,0.092-0.426,0.047-0.609
               c-0.047-0.183-0.131-0.13-0.176-0.298c-0.047-0.167-0.092-0.373-0.236-0.388c-0.144-0.015-0.121,0.031-0.355,0.069
               c-0.236,0.045-0.52-0.03-0.746-0.091c-0.23-0.062-0.374-0.069-0.465,0.022c-0.084,0.091-0.215,0.13-0.42,0.267
               c-0.206,0.145-0.504,0.121-0.504,0.121S150.354,30.181,150.294,30.265z M158.312,30.082c-0.022,0-0.138,0-0.1,0.13
               c0.046,0.129-0.092,0.213,0.092,0.167c0.189-0.038,0.159-0.114,0.207-0.076c0.045,0.038,0.045,0.129,0.143,0.107
               c0.1-0.031,0.115-0.107,0.16-0.046c0.039,0.061,0.106,0.183,0.189,0.144c0.076-0.037,0.206-0.198,0.122-0.212
               c-0.083-0.008-0.214,0.053-0.259-0.016c-0.045-0.068-0.053-0.159-0.129-0.167c-0.069-0.008-0.039,0.037-0.146,0.022
               c-0.099-0.014-0.105-0.068-0.15-0.076C158.396,30.051,158.312,30.082,158.312,30.082z M169.03,29.892
               c0,0-0.205-0.023-0.235,0.129c-0.022,0.153-0.129,0.145-0.213,0.198c-0.092,0.054-0.151,0.054-0.122,0.152
               c0.03,0.1-0.055,0.137-0.122,0.168c-0.069,0.039-0.168,0.046-0.075,0.167c0.084,0.115,0,0.168,0.014,0.252
               c0.01,0.084,0.063,0.076,0.199,0.19c0.129,0.114,0.342,0.183,0.39,0.289c0.052,0.107,0.112,0.221,0.136,0.107
               c0.021-0.107-0.052-0.191-0.105-0.297c-0.055-0.107-0.076-0.144-0.029-0.236c0.037-0.091,0.091-0.083,0.037-0.19
               c-0.063-0.107-0.129-0.266-0.03-0.243c0.099,0.014,0.122,0.152,0.206,0.022c0.091-0.129,0.021-0.373,0.175-0.373
               c0.144-0.008,0.212,0.076,0.261-0.008c0.035-0.084-0.07-0.129,0.174-0.091c0.242,0.038,0.617,0.022,0.707,0.038
               c0.1,0.023,0.047-0.107,0.252,0.008c0.197,0.122,0.045-0.031,0.44,0.075c0.397,0.108,0.397,0.061,0.595,0.084
               c0.19,0.024,0.168-0.015,0.327-0.037c0.169-0.031,0.298-0.206,0.229-0.282c-0.061-0.084-0.145-0.191-0.273-0.092
               c-0.138,0.092-0.229,0.199-0.306,0.199c-0.068,0-0.104-0.115-0.251-0.123c-0.139-0.007-0.335,0.023-0.449,0.031
               c-0.115,0.008-0.152-0.015-0.221,0.022c-0.068,0.031-0.244-0.06-0.344-0.06c-0.099,0-0.076,0.053-0.197,0.015
               c-0.113-0.038-0.197-0.145-0.26-0.145c-0.06,0-0.06,0.031-0.242,0.069c-0.182,0.03-0.152,0.076-0.252,0.015
               c-0.105-0.06-0.221-0.092-0.28-0.084C169.106,29.861,169.03,29.892,169.03,29.892z"/> 
             <path fill="#808184" d="M151.403,37.711c-0.061-0.023-0.121-0.054-0.189-0.106
               c-0.167-0.123-0.229-0.207-0.266-0.267c-0.031-0.039-0.055-0.069-0.092-0.092c-0.008,0,0-0.014,0.008-0.031
               c0.021-0.038,0.045-0.091,0.021-0.159c-0.021-0.084-0.076-0.092-0.13-0.099c-0.022-0.007-0.038-0.007-0.038-0.031
               c0-0.069,0-0.159-0.015-0.236c-0.015-0.061-0.03-0.122-0.062-0.145c-0.031-0.03-0.1-0.067-0.175-0.084
               c-0.062-0.007-0.122-0.007-0.169,0.017c-0.037,0.022-0.061,0.045-0.09,0.083c-0.047,0.038-0.107,0.099-0.236,0.19
               c-0.061,0.038-0.106,0.084-0.16,0.115c-0.082,0.068-0.151,0.129-0.244,0.175c-0.021-0.077-0.062-0.206-0.067-0.274
               c-0.008-0.062-0.017-0.107-0.037-0.145c-0.017-0.038-0.047-0.069-0.092-0.099c-0.047-0.038-0.093-0.054-0.153-0.077
               c-0.052-0.015-0.121-0.03-0.197-0.076c-0.068-0.038-0.121-0.083-0.16-0.122c-0.029-0.037-0.053-0.076-0.061-0.114
               c0-0.007,0.016-0.022,0.03-0.038c0.03-0.03,0.069-0.068,0.076-0.152c0.008-0.091-0.038-0.114-0.099-0.114
               c-0.009-0.008-0.022-0.008-0.039-0.008c-0.031,0.008-0.067,0.008-0.092-0.015c-0.061-0.046-0.092-0.091-0.121-0.122
               c-0.016-0.023-0.037-0.045-0.062-0.06c-0.017-0.016-0.022-0.039-0.022-0.054c0-0.023,0.008-0.046,0.022-0.061v-0.008h0.007
               c0.023-0.045,0.023-0.091-0.007-0.136c-0.017-0.031-0.054-0.062-0.093-0.092c-0.008-0.007-0.021-0.015-0.029-0.022
               c-0.045-0.023-0.068-0.031-0.1-0.031c-0.029-0.008-0.062-0.008-0.129-0.092c-0.039-0.052-0.068-0.084-0.092-0.106
               c0.016-0.016,0.03-0.039,0.036-0.053c0.048-0.054,0.084-0.115,0.162-0.076c0.067,0.03,0.104,0.037,0.136,0.045
               c0.047,0.015,0.068,0.015,0.146,0.084c0.099,0.068,0.145,0.068,0.213,0.068c0.039,0,0.084,0,0.16,0.023h0.008
               c0.205,0.069,0.318,0.107,0.388,0.008c0-0.008,0.009-0.015,0.009-0.023c0.021-0.046,0.015-0.099-0.016-0.145
               c-0.023-0.045-0.063-0.091-0.113-0.129c-0.016-0.008-0.039-0.023-0.068-0.03c-0.055-0.023-0.077-0.077-0.1-0.123
               c-0.022-0.053-0.046-0.099-0.107-0.106h-0.037c0.037-0.045,0.076-0.129,0.105-0.183c0.107,0.038,0.223,0.083,0.297,0.145
               c0.067,0.061,0.115,0.099,0.151,0.137c0.093,0.076,0.153,0.13,0.229,0.19c0.045,0.031,0.068,0.061,0.084,0.084
               c0.037,0.054,0.06,0.091,0.158,0.114c0.023,0.008,0.047,0.015,0.068,0.015c0.092,0.031,0.146,0.046,0.26-0.045
               c0.092-0.069,0.168-0.152,0.221-0.213c0.023-0.015,0.039-0.038,0.047-0.046c0.029-0.031,0.053-0.092,0.074-0.145
               c0.024-0.053,0.039-0.106,0.055-0.114c0.016,0.016,0.029,0.031,0.046,0.045c0.067,0.077,0.474,0.359,0.519,0.541
               c0.045,0.176-0.039,0.183,0.152,0.297c0.197,0.122,0.26,0.198,0.342,0.26c0.075,0.06,0.176,0,0.371,0.121
               c0.192,0.122,0.207,0.243,0.033,0.22c-0.176-0.014-0.391-0.007-0.428,0.054c-0.037,0.062,0.008,0.228-0.084,0.312
               c-0.084,0.076-0.03,0.335,0.008,0.404c0.039,0.068-0.021,0.145-0.105,0.167c-0.076,0.024-0.115-0.114-0.146,0.031
               c-0.03,0.152-0.091,0.198-0.03,0.373C151.358,37.505,151.382,37.596,151.403,37.711"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M151.403,37.711c-0.061-0.023-0.121-0.054-0.189-0.106c-0.167-0.123-0.229-0.207-0.266-0.267
               c-0.031-0.039-0.055-0.069-0.092-0.092c-0.008,0,0-0.014,0.008-0.031c0.021-0.038,0.045-0.091,0.021-0.159
               c-0.021-0.084-0.076-0.092-0.13-0.099c-0.022-0.007-0.038-0.007-0.038-0.031c0-0.069,0-0.159-0.015-0.236
               c-0.015-0.061-0.03-0.122-0.062-0.145c-0.031-0.03-0.1-0.067-0.175-0.084c-0.062-0.007-0.122-0.007-0.169,0.017
               c-0.037,0.022-0.061,0.045-0.09,0.083c-0.047,0.038-0.107,0.099-0.236,0.19c-0.061,0.038-0.106,0.084-0.16,0.115
               c-0.082,0.068-0.151,0.129-0.244,0.175c-0.021-0.077-0.062-0.206-0.067-0.274c-0.008-0.062-0.017-0.107-0.037-0.145
               c-0.017-0.038-0.047-0.069-0.092-0.099c-0.047-0.038-0.093-0.054-0.153-0.077c-0.052-0.015-0.121-0.03-0.197-0.076
               c-0.068-0.038-0.121-0.083-0.16-0.122c-0.029-0.037-0.053-0.076-0.061-0.114c0-0.007,0.016-0.022,0.03-0.038
               c0.03-0.03,0.069-0.068,0.076-0.152c0.008-0.091-0.038-0.114-0.099-0.114c-0.009-0.008-0.022-0.008-0.039-0.008
               c-0.031,0.008-0.067,0.008-0.092-0.015c-0.061-0.046-0.092-0.091-0.121-0.122c-0.016-0.023-0.037-0.045-0.062-0.06
               c-0.017-0.016-0.022-0.039-0.022-0.054c0-0.023,0.008-0.046,0.022-0.061v-0.008h0.007c0.023-0.045,0.023-0.091-0.007-0.136
               c-0.017-0.031-0.054-0.062-0.093-0.092c-0.008-0.007-0.021-0.015-0.029-0.022c-0.045-0.023-0.068-0.031-0.1-0.031
               c-0.029-0.008-0.062-0.008-0.129-0.092c-0.039-0.052-0.068-0.084-0.092-0.106c0.016-0.016,0.03-0.039,0.036-0.053
               c0.048-0.054,0.084-0.115,0.162-0.076c0.067,0.03,0.104,0.037,0.136,0.045c0.047,0.015,0.068,0.015,0.146,0.084
               c0.099,0.068,0.145,0.068,0.213,0.068c0.039,0,0.084,0,0.16,0.023h0.008c0.205,0.069,0.318,0.107,0.388,0.008
               c0-0.008,0.009-0.015,0.009-0.023c0.021-0.046,0.015-0.099-0.016-0.145c-0.023-0.045-0.063-0.091-0.113-0.129
               c-0.016-0.008-0.039-0.023-0.068-0.03c-0.055-0.023-0.077-0.077-0.1-0.123c-0.022-0.053-0.046-0.099-0.107-0.106h-0.037
               c0.037-0.045,0.076-0.129,0.105-0.183c0.107,0.038,0.223,0.083,0.297,0.145c0.067,0.061,0.115,0.099,0.151,0.137
               c0.093,0.076,0.153,0.13,0.229,0.19c0.045,0.031,0.068,0.061,0.084,0.084c0.037,0.054,0.06,0.091,0.158,0.114
               c0.023,0.008,0.047,0.015,0.068,0.015c0.092,0.031,0.146,0.046,0.26-0.045c0.092-0.069,0.168-0.152,0.221-0.213
               c0.023-0.015,0.039-0.038,0.047-0.046c0.029-0.031,0.053-0.092,0.074-0.145c0.024-0.053,0.039-0.106,0.055-0.114
               c0.016,0.016,0.029,0.031,0.046,0.045c0.067,0.077,0.474,0.359,0.519,0.541c0.045,0.176-0.039,0.183,0.152,0.297
               c0.197,0.122,0.26,0.198,0.342,0.26c0.075,0.06,0.176,0,0.371,0.121c0.192,0.122,0.207,0.243,0.033,0.22
               c-0.176-0.014-0.391-0.007-0.428,0.054c-0.037,0.062,0.008,0.228-0.084,0.312c-0.084,0.076-0.03,0.335,0.008,0.404
               c0.039,0.068-0.021,0.145-0.105,0.167c-0.076,0.024-0.115-0.114-0.146,0.031c-0.03,0.152-0.091,0.198-0.03,0.373
               C151.358,37.505,151.382,37.596,151.403,37.711z"/>
             <path fill="#808184" d="M155.446,38.701c0.146-0.007,0.299-0.03,0.396-0.045
               c0.176-0.023,0.198-0.092,0.184-0.183c0-0.015-0.008-0.039-0.008-0.054c-0.01-0.038-0.023-0.076-0.017-0.114
               c0-0.045,0.054-0.068,0.123-0.099c0.067-0.03,0.144-0.061,0.205-0.137c0.062-0.083,0.106-0.068,0.22-0.038
               c0.039,0.015,0.092,0.03,0.153,0.045c0.205,0.047,0.259,0,0.373-0.083c0.038-0.031,0.075-0.061,0.137-0.091
               c0.122-0.076,0.175-0.054,0.236-0.023c0.052,0.015,0.106,0.038,0.19,0.038c0.092,0,0.158,0.061,0.205,0.106
               c0.045,0.046,0.084,0.077,0.137,0.084c0.037,0.008,0.1,0.031,0.174,0.069c0.07,0.038,0.16,0.091,0.253,0.137
               c0.114,0.068,0.168,0.068,0.274,0.076c0.084,0.007,0.205,0.015,0.402,0.053c0.206,0.038,0.289,0.046,0.318,0.054
               c0.018,0.007,0.031,0.022,0.063,0.045c0.046,0.031,0.121,0.084,0.196,0.137c0.122,0.099,0.259,0.198,0.336,0.236
               c0.061,0.031,0.137,0.107,0.213,0.183c0.046,0.053,0.1,0.106,0.145,0.145c0.107,0.084,0.313,0.076,0.496,0.076
               c0.037-0.008,0.082-0.008,0.121-0.008c0.101,0,0.114,0.107,0.114,0.229v0.076c-0.008,0.038-0.008,0.068-0.008,0.084
               c0,0.113,0.038,0.16,0.077,0.235c0.045,0.062,0.099,0.145,0.144,0.335c0.039,0.198,0.062,0.366,0.039,0.511
               c-0.017,0.122-0.055,0.228-0.13,0.289c-0.145,0.122-0.092,0.197-0.038,0.289c0.021,0.031,0.038,0.061,0.045,0.107
               c0.009,0.053-0.038,0.099-0.091,0.145c-0.053,0.046-0.107,0.091-0.13,0.167c-0.031,0.076-0.007,0.145,0.046,0.206
               c0.046,0.068,0.137,0.122,0.229,0.159c0.053,0.024,0.069,0.054,0.069,0.092c-0.009,0.06-0.055,0.137-0.108,0.243
               c-0.084,0.168-0.006,0.282,0.101,0.442c0.054,0.069,0.106,0.153,0.151,0.252c0.106,0.243,0.1,0.494,0.1,0.692
               c-0.008,0.046-0.008,0.084-0.008,0.107c0,0.213,0.229,0.236,0.486,0.236h0.107h0.113c0.146,0,0.152,0.045,0.16,0.129
               c0.008,0.069,0.023,0.153,0.076,0.26c0.092,0.183-0.205,0.547-0.396,0.783c-0.046,0.054-0.084,0.099-0.105,0.138
               c-0.139,0.183-0.047,0.258,0.074,0.38c0.055,0.053,0.115,0.107,0.168,0.183c0.084,0.129,0.176,0.259,0.268,0.381
               s0.19,0.236,0.281,0.327c0.152,0.153,0.221,0.175,0.52,0.267c0.066,0.022,0.145,0.053,0.242,0.084
               c0.312,0.106,0.259,0.289,0.205,0.479c-0.029,0.092-0.053,0.183-0.045,0.274c0,0.168,0.053,0.221,0.129,0.213
               c0.031,0,0.067-0.015,0.105-0.03c0.031-0.015,0.063-0.031,0.092-0.039h0.008c0.039,0,0.068,0.032,0.101,0.077
               c0.038,0.061,0.067,0.145,0.084,0.221c0.008,0.03,0.015,0.061,0.015,0.091c0.008,0.016,0.008,0.031,0,0.046
               c-0.007,0.03-0.023,0.054-0.046,0.076c-0.03,0.015-0.069,0.03-0.122,0.038c-0.068,0.008-0.145,0.008-0.244-0.015
               c-0.197-0.045-0.257,0.046-0.334,0.153c-0.045,0.075-0.106,0.151-0.229,0.182c-0.258,0.068-0.25,0.16-0.235,0.35
               c0,0.061,0.009,0.137,0,0.229c-0.015,0.167-0.007,0.373,0,0.548c-0.137,0-0.159,0.099-0.288,0.038
               c-0.131-0.069-0.359-0.099-0.473-0.16c-0.115-0.061-0.076-0.152-0.229-0.092c-0.16,0.061-0.106,0.031-0.319,0.024
               c-0.205-0.009-0.343-0.069-0.603-0.047c-0.26,0.016-0.197-0.152-0.38-0.152c-0.177,0-0.305,0.023-0.465-0.007
               c-0.167-0.038-0.099-0.145-0.305-0.152c-0.206-0.008-0.466,0.091-0.511-0.13c-0.039-0.221-0.145-0.373-0.176-0.594
               c-0.037-0.228-0.037-0.319-0.175-0.418c-0.146-0.099-0.228-0.237-0.465-0.19c-0.228,0.045-0.419,0.083-0.38,0.136
               c0.037,0.061,0.083,0.069,0.152,0.054c0.068-0.016,0.271,0.015,0.166,0.053c-0.098,0.046-0.166,0.121-0.221,0.144
               c-0.046,0.023-0.205,0.008-0.305,0.1c-0.1,0.099-0.299,0.076-0.206-0.008c0.084-0.076,0.319-0.214,0.174-0.198
               c-0.144,0.008-0.303,0.053-0.396,0.183c-0.084,0.129-0.272,0.182-0.402,0.107c-0.137-0.069-0.13-0.153-0.26-0.153
               c-0.137,0-0.204,0.098-0.387-0.008c-0.177-0.106-0.307-0.22-0.404-0.274c-0.092-0.045-0.266-0.053-0.389-0.183
               c-0.121-0.129-0.106-0.069-0.25-0.274c-0.146-0.205-0.283-0.297-0.526-0.319c-0.253-0.015-0.366,0.151-0.466-0.122
               c-0.099-0.267-0.183-0.366-0.272-0.586c-0.092-0.229-0.251-0.206-0.306-0.396c-0.053-0.19,0.055-0.205-0.053-0.311
               c-0.115-0.115-0.115-0.344-0.283-0.465c-0.174-0.122-0.446-0.381-0.53-0.312c-0.084,0.076-0.153,0.159-0.267,0.076
               c-0.107-0.084-0.336-0.122-0.336-0.122s-0.281-0.16-0.244-0.023c0.045,0.137,0.152,0.213,0.054,0.251
               c-0.075,0.031-0.114,0.023-0.198,0.023c-0.076-0.145-0.213-0.381-0.326-0.456c-0.076-0.054-0.092-0.146-0.1-0.237
               c-0.008-0.068-0.016-0.145-0.055-0.205c-0.037-0.053-0.075-0.077-0.121-0.099c-0.038-0.023-0.075-0.046-0.062-0.145
               c0.031-0.213-0.007-0.282-0.075-0.411l-0.022-0.038c-0.022-0.046-0.037-0.099-0.046-0.152c-0.03-0.1-0.055-0.206-0.174-0.328
               c-0.086-0.091-0.168-0.129-0.246-0.167c-0.059-0.031-0.113-0.046-0.12-0.1c-0.024-0.09-0.093-0.167-0.197-0.212
               c-0.092-0.039-0.224-0.061-0.374-0.054c-0.063,0-0.115-0.007-0.152-0.03c-0.029-0.015-0.053-0.039-0.068-0.068
               c-0.016-0.031-0.03-0.062-0.03-0.099c0-0.054,0.017-0.107,0.039-0.161c0.09-0.167-0.101-0.274-0.259-0.358
               c-0.063-0.03-0.123-0.061-0.123-0.075c-0.021-0.054-0.074-0.092-0.121-0.13c-0.046-0.038-0.092-0.076-0.105-0.122v-0.107
               c0.006-0.084,0.015-0.19-0.045-0.35c-0.063-0.182,0.098-0.418,0.174-0.525c0.008-0.016,0.015-0.03,0.023-0.038
               c0.016-0.016,0.021-0.023,0.036-0.023c0.038-0.015,0.084-0.023,0.153-0.107c0.099-0.106,0.045-0.152-0.039-0.221
               c-0.03-0.03-0.076-0.068-0.114-0.121c-0.029-0.038,0.008-0.061,0.038-0.077c0.068-0.045,0.138-0.091,0.076-0.197
               c-0.053-0.114-0.129-0.099-0.205-0.076c-0.022,0.007-0.045,0.007-0.055-0.008c-0.029-0.13-0.144-0.122-0.258-0.114
               c-0.039,0-0.076,0-0.105-0.008c-0.055-0.015-0.063-0.061-0.084-0.16c-0.018-0.038-0.022-0.084-0.039-0.137
               c-0.053-0.183-0.159-0.19-0.229-0.198c0-0.015-0.008-0.031-0.016-0.045c-0.008-0.031-0.023-0.062-0.046-0.084
               c-0.015-0.016-0.038-0.046-0.06-0.1c-0.024-0.052-0.048-0.129-0.063-0.243c-0.039-0.221-0.229-0.335-0.289-0.373
               c-0.008-0.023-0.023-0.084,0.007-0.213c0.022-0.114-0.015-0.175-0.075-0.213c-0.062-0.031-0.13-0.038-0.184-0.038
               c-0.038,0-0.038-0.039-0.045-0.069c0-0.015,0-0.023-0.008-0.038c0-0.007,0.015-0.031,0.029-0.045
               c0.037-0.054,0.076-0.107,0.037-0.184c-0.021-0.045-0.037-0.076-0.053-0.121c-0.016-0.038-0.023-0.084-0.023-0.145
               c-0.006-0.076-0.045-0.114-0.082-0.145c-0.031-0.022-0.055-0.038-0.031-0.113c0.039-0.153-0.053-0.244-0.121-0.313
               c-0.021-0.021-0.047-0.046-0.055-0.053c0-0.023,0.031-0.031,0.055-0.039l0.021-0.007c0.039-0.007,0.068-0.03,0.092-0.068
               c0.016-0.031,0.022-0.077,0.008-0.145c-0.008-0.046,0.054-0.099,0.092-0.129c0.039,0.03,0.076,0.053,0.113,0.083
               c0.062,0.038,0.123,0.076,0.16,0.114c0.063,0.054,0.084,0.1,0.106,0.168c0.022,0.045,0.054,0.107,0.099,0.183
               c0.076,0.137,0.146,0.144,0.238,0.152c0.044,0.008,0.098,0.008,0.166,0.046c0.174,0.092,0.273,0.068,0.473,0.008
               c0.037-0.008,0.092-0.024,0.137-0.039c0.222-0.053,0.328-0.144,0.472-0.25c0.039-0.039,0.092-0.077,0.153-0.116
               c0.137-0.098,0.197-0.159,0.242-0.205c0.031-0.023,0.047-0.045,0.076-0.061c0.022-0.015,0.062-0.015,0.1-0.007
               c0.055,0.014,0.099,0.037,0.123,0.053c0.007,0.015,0.021,0.053,0.029,0.099c0.008,0.069,0.016,0.153,0.016,0.213
               c0,0.107,0.054,0.114,0.113,0.13c0.023,0,0.047,0.007,0.055,0.03c0.016,0.03,0,0.061-0.016,0.084
               c-0.031,0.061-0.055,0.114,0.03,0.16c0.021,0.015,0.036,0.037,0.062,0.068c0.045,0.061,0.113,0.16,0.297,0.289
               c0.092,0.076,0.183,0.115,0.26,0.13c0.016,0.091,0.021,0.175,0.021,0.175s0.099,0.342,0.41,0.495
               c0.32,0.145,0.541-0.023,0.647,0.091c0.106,0.115-0.022,0.213,0.205,0.32c0.229,0.106,0.633,0.35,0.892,0.381
               c0.25,0.038,0.319-0.077,0.602-0.016c0.273,0.054,0.427-0.114,0.625-0.129c0.189-0.021,0.557-0.021,0.623-0.046
               c0.068-0.022,0.068-0.114,0.054-0.251C155.493,38.792,155.479,38.755,155.446,38.701"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M155.446,38.701c0.146-0.007,0.299-0.03,0.396-0.045c0.176-0.023,0.198-0.092,0.184-0.183c0-0.015-0.008-0.039-0.008-0.054
               c-0.01-0.038-0.023-0.076-0.017-0.114c0-0.045,0.054-0.068,0.123-0.099c0.067-0.03,0.144-0.061,0.205-0.137
               c0.062-0.083,0.106-0.068,0.22-0.038c0.039,0.015,0.092,0.03,0.153,0.045c0.205,0.047,0.259,0,0.373-0.083
               c0.038-0.031,0.075-0.061,0.137-0.091c0.122-0.076,0.175-0.054,0.236-0.023c0.052,0.015,0.106,0.038,0.19,0.038
               c0.092,0,0.158,0.061,0.205,0.106c0.045,0.046,0.084,0.077,0.137,0.084c0.037,0.008,0.1,0.031,0.174,0.069
               c0.07,0.038,0.16,0.091,0.253,0.137c0.114,0.068,0.168,0.068,0.274,0.076c0.084,0.007,0.205,0.015,0.402,0.053
               c0.206,0.038,0.289,0.046,0.318,0.054c0.018,0.007,0.031,0.022,0.063,0.045c0.046,0.031,0.121,0.084,0.196,0.137
               c0.122,0.099,0.259,0.198,0.336,0.236c0.061,0.031,0.137,0.107,0.213,0.183c0.046,0.053,0.1,0.106,0.145,0.145
               c0.107,0.084,0.313,0.076,0.496,0.076c0.037-0.008,0.082-0.008,0.121-0.008c0.101,0,0.114,0.107,0.114,0.229v0.076
               c-0.008,0.038-0.008,0.068-0.008,0.084c0,0.113,0.038,0.16,0.077,0.235c0.045,0.062,0.099,0.145,0.144,0.335
               c0.039,0.198,0.062,0.366,0.039,0.511c-0.017,0.122-0.055,0.228-0.13,0.289c-0.145,0.122-0.092,0.197-0.038,0.289
               c0.021,0.031,0.038,0.061,0.045,0.107c0.009,0.053-0.038,0.099-0.091,0.145c-0.053,0.046-0.107,0.091-0.13,0.167
               c-0.031,0.076-0.007,0.145,0.046,0.206c0.046,0.068,0.137,0.122,0.229,0.159c0.053,0.024,0.069,0.054,0.069,0.092
               c-0.009,0.06-0.055,0.137-0.108,0.243c-0.084,0.168-0.006,0.282,0.101,0.442c0.054,0.069,0.106,0.153,0.151,0.252
               c0.106,0.243,0.1,0.494,0.1,0.692c-0.008,0.046-0.008,0.084-0.008,0.107c0,0.213,0.229,0.236,0.486,0.236h0.107h0.113
               c0.146,0,0.152,0.045,0.16,0.129c0.008,0.069,0.023,0.153,0.076,0.26c0.092,0.183-0.205,0.547-0.396,0.783
               c-0.046,0.054-0.084,0.099-0.105,0.138c-0.139,0.183-0.047,0.258,0.074,0.38c0.055,0.053,0.115,0.107,0.168,0.183
               c0.084,0.129,0.176,0.259,0.268,0.381s0.19,0.236,0.281,0.327c0.152,0.153,0.221,0.175,0.52,0.267
               c0.066,0.022,0.145,0.053,0.242,0.084c0.312,0.106,0.259,0.289,0.205,0.479c-0.029,0.092-0.053,0.183-0.045,0.274
               c0,0.168,0.053,0.221,0.129,0.213c0.031,0,0.067-0.015,0.105-0.03c0.031-0.015,0.063-0.031,0.092-0.039h0.008
               c0.039,0,0.068,0.032,0.101,0.077c0.038,0.061,0.067,0.145,0.084,0.221c0.008,0.03,0.015,0.061,0.015,0.091
               c0.008,0.016,0.008,0.031,0,0.046c-0.007,0.03-0.023,0.054-0.046,0.076c-0.03,0.015-0.069,0.03-0.122,0.038
               c-0.068,0.008-0.145,0.008-0.244-0.015c-0.197-0.045-0.257,0.046-0.334,0.153c-0.045,0.075-0.106,0.151-0.229,0.182
               c-0.258,0.068-0.25,0.16-0.235,0.35c0,0.061,0.009,0.137,0,0.229c-0.015,0.167-0.007,0.373,0,0.548
               c-0.137,0-0.159,0.099-0.288,0.038c-0.131-0.069-0.359-0.099-0.473-0.16c-0.115-0.061-0.076-0.152-0.229-0.092
               c-0.16,0.061-0.106,0.031-0.319,0.024c-0.205-0.009-0.343-0.069-0.603-0.047c-0.26,0.016-0.197-0.152-0.38-0.152
               c-0.177,0-0.305,0.023-0.465-0.007c-0.167-0.038-0.099-0.145-0.305-0.152c-0.206-0.008-0.466,0.091-0.511-0.13
               c-0.039-0.221-0.145-0.373-0.176-0.594c-0.037-0.228-0.037-0.319-0.175-0.418c-0.146-0.099-0.228-0.237-0.465-0.19
               c-0.228,0.045-0.419,0.083-0.38,0.136c0.037,0.061,0.083,0.069,0.152,0.054c0.068-0.016,0.271,0.015,0.166,0.053
               c-0.098,0.046-0.166,0.121-0.221,0.144c-0.046,0.023-0.205,0.008-0.305,0.1c-0.1,0.099-0.299,0.076-0.206-0.008
               c0.084-0.076,0.319-0.214,0.174-0.198c-0.144,0.008-0.303,0.053-0.396,0.183c-0.084,0.129-0.272,0.182-0.402,0.107
               c-0.137-0.069-0.13-0.153-0.26-0.153c-0.137,0-0.204,0.098-0.387-0.008c-0.177-0.106-0.307-0.22-0.404-0.274
               c-0.092-0.045-0.266-0.053-0.389-0.183c-0.121-0.129-0.106-0.069-0.25-0.274c-0.146-0.205-0.283-0.297-0.526-0.319
               c-0.253-0.015-0.366,0.151-0.466-0.122c-0.099-0.267-0.183-0.366-0.272-0.586c-0.092-0.229-0.251-0.206-0.306-0.396
               c-0.053-0.19,0.055-0.205-0.053-0.311c-0.115-0.115-0.115-0.344-0.283-0.465c-0.174-0.122-0.446-0.381-0.53-0.312
               c-0.084,0.076-0.153,0.159-0.267,0.076c-0.107-0.084-0.336-0.122-0.336-0.122s-0.281-0.16-0.244-0.023
               c0.045,0.137,0.152,0.213,0.054,0.251c-0.075,0.031-0.114,0.023-0.198,0.023c-0.076-0.145-0.213-0.381-0.326-0.456
               c-0.076-0.054-0.092-0.146-0.1-0.237c-0.008-0.068-0.016-0.145-0.055-0.205c-0.037-0.053-0.075-0.077-0.121-0.099
               c-0.038-0.023-0.075-0.046-0.062-0.145c0.031-0.213-0.007-0.282-0.075-0.411l-0.022-0.038c-0.022-0.046-0.037-0.099-0.046-0.152
               c-0.03-0.1-0.055-0.206-0.174-0.328c-0.086-0.091-0.168-0.129-0.246-0.167c-0.059-0.031-0.113-0.046-0.12-0.1
               c-0.024-0.09-0.093-0.167-0.197-0.212c-0.092-0.039-0.224-0.061-0.374-0.054c-0.063,0-0.115-0.007-0.152-0.03
               c-0.029-0.015-0.053-0.039-0.068-0.068c-0.016-0.031-0.03-0.062-0.03-0.099c0-0.054,0.017-0.107,0.039-0.161
               c0.09-0.167-0.101-0.274-0.259-0.358c-0.063-0.03-0.123-0.061-0.123-0.075c-0.021-0.054-0.074-0.092-0.121-0.13
               c-0.046-0.038-0.092-0.076-0.105-0.122v-0.107c0.006-0.084,0.015-0.19-0.045-0.35c-0.063-0.182,0.098-0.418,0.174-0.525
               c0.008-0.016,0.015-0.03,0.023-0.038c0.016-0.016,0.021-0.023,0.036-0.023c0.038-0.015,0.084-0.023,0.153-0.107
               c0.099-0.106,0.045-0.152-0.039-0.221c-0.03-0.03-0.076-0.068-0.114-0.121c-0.029-0.038,0.008-0.061,0.038-0.077
               c0.068-0.045,0.138-0.091,0.076-0.197c-0.053-0.114-0.129-0.099-0.205-0.076c-0.022,0.007-0.045,0.007-0.055-0.008
               c-0.029-0.13-0.144-0.122-0.258-0.114c-0.039,0-0.076,0-0.105-0.008c-0.055-0.015-0.063-0.061-0.084-0.16
               c-0.018-0.038-0.022-0.084-0.039-0.137c-0.053-0.183-0.159-0.19-0.229-0.198c0-0.015-0.008-0.031-0.016-0.045
               c-0.008-0.031-0.023-0.062-0.046-0.084c-0.015-0.016-0.038-0.046-0.06-0.1c-0.024-0.052-0.048-0.129-0.063-0.243
               c-0.039-0.221-0.229-0.335-0.289-0.373c-0.008-0.023-0.023-0.084,0.007-0.213c0.022-0.114-0.015-0.175-0.075-0.213
               c-0.062-0.031-0.13-0.038-0.184-0.038c-0.038,0-0.038-0.039-0.045-0.069c0-0.015,0-0.023-0.008-0.038
               c0-0.007,0.015-0.031,0.029-0.045c0.037-0.054,0.076-0.107,0.037-0.184c-0.021-0.045-0.037-0.076-0.053-0.121
               c-0.016-0.038-0.023-0.084-0.023-0.145c-0.006-0.076-0.045-0.114-0.082-0.145c-0.031-0.022-0.055-0.038-0.031-0.113
               c0.039-0.153-0.053-0.244-0.121-0.313c-0.021-0.021-0.047-0.046-0.055-0.053c0-0.023,0.031-0.031,0.055-0.039l0.021-0.007
               c0.039-0.007,0.068-0.03,0.092-0.068c0.016-0.031,0.022-0.077,0.008-0.145c-0.008-0.046,0.054-0.099,0.092-0.129
               c0.039,0.03,0.076,0.053,0.113,0.083c0.062,0.038,0.123,0.076,0.16,0.114c0.063,0.054,0.084,0.1,0.106,0.168
               c0.022,0.045,0.054,0.107,0.099,0.183c0.076,0.137,0.146,0.144,0.238,0.152c0.044,0.008,0.098,0.008,0.166,0.046
               c0.174,0.092,0.273,0.068,0.473,0.008c0.037-0.008,0.092-0.024,0.137-0.039c0.222-0.053,0.328-0.144,0.472-0.25
               c0.039-0.039,0.092-0.077,0.153-0.116c0.137-0.098,0.197-0.159,0.242-0.205c0.031-0.023,0.047-0.045,0.076-0.061
               c0.022-0.015,0.062-0.015,0.1-0.007c0.055,0.014,0.099,0.037,0.123,0.053c0.007,0.015,0.021,0.053,0.029,0.099
               c0.008,0.069,0.016,0.153,0.016,0.213c0,0.107,0.054,0.114,0.113,0.13c0.023,0,0.047,0.007,0.055,0.03
               c0.016,0.03,0,0.061-0.016,0.084c-0.031,0.061-0.055,0.114,0.03,0.16c0.021,0.015,0.036,0.037,0.062,0.068
               c0.045,0.061,0.113,0.16,0.297,0.289c0.092,0.076,0.183,0.115,0.26,0.13c0.016,0.091,0.021,0.175,0.021,0.175
               s0.099,0.342,0.41,0.495c0.32,0.145,0.541-0.023,0.647,0.091c0.106,0.115-0.022,0.213,0.205,0.32
               c0.229,0.106,0.633,0.35,0.892,0.381c0.25,0.038,0.319-0.077,0.602-0.016c0.273,0.054,0.427-0.114,0.625-0.129
               c0.189-0.021,0.557-0.021,0.623-0.046c0.068-0.022,0.068-0.114,0.054-0.251C155.493,38.792,155.479,38.755,155.446,38.701z"/>
             <path fill="#808184" d="M161.388,40.186c-0.046-0.161-0.101-0.245-0.139-0.305
               c-0.037-0.061-0.061-0.099-0.061-0.183v-0.075v-0.084c0-0.176-0.022-0.328-0.213-0.328h-0.131
               c-0.16,0.008-0.351,0.015-0.418-0.046c-0.046-0.03-0.092-0.083-0.146-0.137c-0.075-0.084-0.159-0.168-0.234-0.205
               c-0.068-0.038-0.197-0.138-0.328-0.221c-0.076-0.061-0.146-0.115-0.197-0.145c-0.031-0.023-0.037-0.031-0.045-0.038
               c-0.008-0.016-0.018-0.016-0.031-0.023h-0.029c-0.03-0.007-0.107-0.015-0.307-0.053c-0.213-0.038-0.334-0.046-0.418-0.053
               c-0.092-0.008-0.137-0.015-0.229-0.062c-0.092-0.052-0.184-0.105-0.259-0.144c-0.084-0.038-0.16-0.068-0.214-0.076
               c-0.016,0-0.045-0.03-0.075-0.061c-0.063-0.061-0.138-0.129-0.274-0.129c-0.062,0-0.107-0.023-0.146-0.039
               c-0.093-0.038-0.167-0.068-0.334,0.031c-0.062,0.038-0.107,0.077-0.146,0.099c-0.093,0.069-0.13,0.099-0.289,0.069
               c-0.062-0.016-0.106-0.031-0.154-0.046c-0.149-0.045-0.219-0.069-0.325,0.068c-0.046,0.061-0.106,0.092-0.168,0.114
               c-0.091,0.038-0.168,0.077-0.183,0.183c0,0.054,0.008,0.099,0.022,0.145c0,0.015,0.007,0.031,0.007,0.045
               c0.01,0.031-0.007,0.062-0.099,0.069c-0.104,0.015-0.283,0.038-0.435,0.053c-0.037-0.084-0.076-0.19-0.099-0.358
               c-0.038-0.38-0.183-0.723-0.153-0.92c0.023-0.199,0.038-0.199-0.074-0.274c-0.117-0.077-0.215-0.229-0.328-0.26
               c-0.123-0.03-0.153,0.122-0.221,0.046c-0.063-0.076-0.138-0.365-0.008-0.335c0.129,0.022,0.355,0.152,0.287,0
               c-0.074-0.153-0.145-0.297-0.296-0.305c-0.151,0-0.159,0.129-0.272,0.061c-0.115-0.076-0.154-0.129-0.207-0.205
               c-0.061-0.084,0.047-0.533,0.039-0.655c-0.008-0.122,0.014-0.297,0.074-0.183c0.063,0.107,0.146,0.252,0.237,0.229
               c0.099-0.031,0.129-0.168,0.221-0.084c0.091,0.084,0.046,0.198,0.183,0.183c0.131-0.008,0.207-0.153,0.306-0.16
               c0.106,0,0.313-0.038,0.214-0.206c-0.092-0.167-0.329-0.19-0.45-0.328c-0.129-0.129-0.137-0.296-0.183-0.388
               c-0.047-0.099-0.184-0.282-0.358-0.251c-0.168,0.031-0.356,0.122-0.388,0.206c-0.023,0.083,0,0.327-0.016,0.396
               s0.205,0.327,0.076,0.282c-0.131-0.046-0.205-0.282-0.259-0.328c-0.038-0.031-0.054-0.129-0.084-0.221
               c0.167-0.167,0.356-0.335,0.456-0.358c0.045-0.008,0.084-0.015,0.129-0.03c0.199-0.046,0.413-0.099,0.526-0.061
               c0.069,0.031,0.204,0.083,0.327,0.152c0.113,0.069,0.222,0.153,0.267,0.244l0.007,0.023c0.084,0.198,0.145,0.35,0.365,0.457
               c0.031,0.015,0.068,0.03,0.1,0.052c0.16,0.084,0.274,0.146,0.457,0.016h0.008l0.01-0.016c0.007-0.014,0.007-0.06,0-0.121
               c0.045-0.008,0.121-0.008,0.203,0.015c0.084,0.023,0.25,0.061,0.382,0.069c0.106,0.007,0.204,0,0.235-0.038
               c0.055-0.061,0-0.145-0.061-0.236c-0.039-0.054-0.068-0.114-0.068-0.13c0.014-0.046,0.068-0.167,0.129-0.258
               c0.047-0.062,0.084-0.115,0.123-0.13c0.037-0.023,0.09-0.031,0.145-0.046c0.105-0.022,0.205-0.046,0.26-0.129
               c0.036-0.061,0.029-0.107,0.021-0.152c0-0.031-0.008-0.062,0.029-0.092c0.023-0.015,0.047-0.038,0.076-0.061
               c0.018-0.015,0.038-0.03,0.063-0.053c0.068-0.054,0.137-0.091,0.189-0.038c0.03,0.046,0.037,0.076,0.037,0.098
               c0.008,0.077,0.016,0.115,0.229,0.146c0.229,0.029,0.306,0.083,0.383,0.128c0.015,0.008,0.029,0.024,0.045,0.031
               c0.068,0.046,0.115,0.016,0.158-0.023c0.031-0.023,0.07-0.045,0.146,0.008c0.076,0.061,0.122,0.107,0.146,0.152
               c0.021,0.031,0.021,0.069,0.029,0.107c0.006,0.069,0.068,0.084,0.137,0.107c0.063,0.015,0.137,0.038,0.115,0.121
               c-0.009,0.061-0.047,0.107-0.078,0.138c-0.015,0.014-0.028,0.03-0.037,0.045c-0.039,0.061-0.021,0.115,0.121,0.199
               c0.069,0.037,0.129,0.083,0.177,0.121c0.146,0.106,0.243,0.183,0.434,0.122c0.038-0.016,0.077-0.03,0.114-0.046
               c0.146-0.054,0.243-0.091,0.373-0.023c0.038,0.016,0.074,0.039,0.113,0.053c0.093,0.046,0.146,0.069,0.199,0.176
               c0.037,0.091,0.205,0.342,0.396,0.585c0.146,0.191,0.297,0.382,0.411,0.48c0.183,0.168,0.365,0.268,0.518,0.343
               c0.055,0.03,0.1,0.053,0.145,0.077c0.07,0.045,0.177,0.083,0.283,0.121c0.16,0.061,0.326,0.122,0.418,0.206
               c0.084,0.068,0.168,0.145,0.26,0.205c0.092,0.061,0.189,0.115,0.297,0.152c0.084,0.023,0.13,0.055,0.183,0.092
               c0.068,0.038,0.138,0.084,0.289,0.129c0.138,0.039,0.222,0.068,0.291,0.084c0.065,0.023,0.121,0.046,0.204,0.068
               c0.094,0.031,0.152,0.092,0.198,0.13c0.046,0.046,0.077,0.077,0.12,0.084c0.017,0.007,0.031,0.007,0.056,0.015
               c0.052,0.008,0.129,0.015,0.129,0.053c0.007,0.038,0,0.069-0.008,0.099c-0.015,0.046-0.03,0.092-0.015,0.153
               c0,0.015,0,0.037,0.006,0.068c0,0.046,0.009,0.091,0.017,0.137c-0.03,0.023-0.092,0.046-0.114,0.046
               c-0.029,0-0.092-0.015-0.152-0.061c-0.045-0.023-0.092-0.069-0.129-0.13c-0.045-0.075-0.106-0.083-0.162-0.045
               c-0.051,0.031-0.098,0.106-0.143,0.183c-0.031,0.045-0.061,0.099-0.084,0.114c-0.037,0.03-0.076,0.03-0.13,0.023
               c-0.045,0-0.091-0.008-0.159,0.007c-0.099,0.015-0.152,0.123-0.176,0.251c-0.021,0.115-0.021,0.252-0.021,0.358
               c0,0.107,0,0.168-0.008,0.214c-0.009,0.03-0.017,0.045-0.039,0.061c-0.029,0.022-0.062,0.045-0.105,0.075
               c-0.055,0.031-0.113,0.069-0.197,0.115c-0.076,0.046-0.16,0.076-0.236,0.107c-0.092,0.03-0.176,0.052-0.242,0.068
               c-0.139,0.023-0.176,0.167-0.209,0.297c-0.014,0.053-0.02,0.099-0.036,0.13c-0.03,0.046-0.037,0.053-0.054,0.06
               c-0.022,0.008-0.053,0.015-0.099,0.046c-0.03,0.031-0.092,0.031-0.159,0.015c-0.092-0.015-0.184-0.053-0.252-0.091
               c-0.137-0.083-0.229-0.076-0.336-0.068h-0.008c-0.038,0-0.066,0-0.091-0.015c-0.022-0.009-0.046-0.039-0.075-0.077
               C161.569,40.178,161.446,40.178,161.388,40.186"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M161.388,40.186c-0.046-0.161-0.101-0.245-0.139-0.305c-0.037-0.061-0.061-0.099-0.061-0.183v-0.075v-0.084
               c0-0.176-0.022-0.328-0.213-0.328h-0.131c-0.16,0.008-0.351,0.015-0.418-0.046c-0.046-0.03-0.092-0.083-0.146-0.137
               c-0.075-0.084-0.159-0.168-0.234-0.205c-0.068-0.038-0.197-0.138-0.328-0.221c-0.076-0.061-0.146-0.115-0.197-0.145
               c-0.031-0.023-0.037-0.031-0.045-0.038c-0.008-0.016-0.018-0.016-0.031-0.023h-0.029c-0.03-0.007-0.107-0.015-0.307-0.053
               c-0.213-0.038-0.334-0.046-0.418-0.053c-0.092-0.008-0.137-0.015-0.229-0.062c-0.092-0.052-0.184-0.105-0.259-0.144
               c-0.084-0.038-0.16-0.068-0.214-0.076c-0.016,0-0.045-0.03-0.075-0.061c-0.063-0.061-0.138-0.129-0.274-0.129
               c-0.062,0-0.107-0.023-0.146-0.039c-0.093-0.038-0.167-0.068-0.334,0.031c-0.062,0.038-0.107,0.077-0.146,0.099
               c-0.093,0.069-0.13,0.099-0.289,0.069c-0.062-0.016-0.106-0.031-0.154-0.046c-0.149-0.045-0.219-0.069-0.325,0.068
               c-0.046,0.061-0.106,0.092-0.168,0.114c-0.091,0.038-0.168,0.077-0.183,0.183c0,0.054,0.008,0.099,0.022,0.145
               c0,0.015,0.007,0.031,0.007,0.045c0.01,0.031-0.007,0.062-0.099,0.069c-0.104,0.015-0.283,0.038-0.435,0.053
               c-0.037-0.084-0.076-0.19-0.099-0.358c-0.038-0.38-0.183-0.723-0.153-0.92c0.023-0.199,0.038-0.199-0.074-0.274
               c-0.117-0.077-0.215-0.229-0.328-0.26c-0.123-0.03-0.153,0.122-0.221,0.046c-0.063-0.076-0.138-0.365-0.008-0.335
               c0.129,0.022,0.355,0.152,0.287,0c-0.074-0.153-0.145-0.297-0.296-0.305c-0.151,0-0.159,0.129-0.272,0.061
               c-0.115-0.076-0.154-0.129-0.207-0.205c-0.061-0.084,0.047-0.533,0.039-0.655c-0.008-0.122,0.014-0.297,0.074-0.183
               c0.063,0.107,0.146,0.252,0.237,0.229c0.099-0.031,0.129-0.168,0.221-0.084c0.091,0.084,0.046,0.198,0.183,0.183
               c0.131-0.008,0.207-0.153,0.306-0.16c0.106,0,0.313-0.038,0.214-0.206c-0.092-0.167-0.329-0.19-0.45-0.328
               c-0.129-0.129-0.137-0.296-0.183-0.388c-0.047-0.099-0.184-0.282-0.358-0.251c-0.168,0.031-0.356,0.122-0.388,0.206
               c-0.023,0.083,0,0.327-0.016,0.396s0.205,0.327,0.076,0.282c-0.131-0.046-0.205-0.282-0.259-0.328
               c-0.038-0.031-0.054-0.129-0.084-0.221c0.167-0.167,0.356-0.335,0.456-0.358c0.045-0.008,0.084-0.015,0.129-0.03
               c0.199-0.046,0.413-0.099,0.526-0.061c0.069,0.031,0.204,0.083,0.327,0.152c0.113,0.069,0.222,0.153,0.267,0.244l0.007,0.023
               c0.084,0.198,0.145,0.35,0.365,0.457c0.031,0.015,0.068,0.03,0.1,0.052c0.16,0.084,0.274,0.146,0.457,0.016h0.008l0.01-0.016
               c0.007-0.014,0.007-0.06,0-0.121c0.045-0.008,0.121-0.008,0.203,0.015c0.084,0.023,0.25,0.061,0.382,0.069
               c0.106,0.007,0.204,0,0.235-0.038c0.055-0.061,0-0.145-0.061-0.236c-0.039-0.054-0.068-0.114-0.068-0.13
               c0.014-0.046,0.068-0.167,0.129-0.258c0.047-0.062,0.084-0.115,0.123-0.13c0.037-0.023,0.09-0.031,0.145-0.046
               c0.105-0.022,0.205-0.046,0.26-0.129c0.036-0.061,0.029-0.107,0.021-0.152c0-0.031-0.008-0.062,0.029-0.092
               c0.023-0.015,0.047-0.038,0.076-0.061c0.018-0.015,0.038-0.03,0.063-0.053c0.068-0.054,0.137-0.091,0.189-0.038
               c0.03,0.046,0.037,0.076,0.037,0.098c0.008,0.077,0.016,0.115,0.229,0.146c0.229,0.029,0.306,0.083,0.383,0.128
               c0.015,0.008,0.029,0.024,0.045,0.031c0.068,0.046,0.115,0.016,0.158-0.023c0.031-0.023,0.07-0.045,0.146,0.008
               c0.076,0.061,0.122,0.107,0.146,0.152c0.021,0.031,0.021,0.069,0.029,0.107c0.006,0.069,0.068,0.084,0.137,0.107
               c0.063,0.015,0.137,0.038,0.115,0.121c-0.009,0.061-0.047,0.107-0.078,0.138c-0.015,0.014-0.028,0.03-0.037,0.045
               c-0.039,0.061-0.021,0.115,0.121,0.199c0.069,0.037,0.129,0.083,0.177,0.121c0.146,0.106,0.243,0.183,0.434,0.122
               c0.038-0.016,0.077-0.03,0.114-0.046c0.146-0.054,0.243-0.091,0.373-0.023c0.038,0.016,0.074,0.039,0.113,0.053
               c0.093,0.046,0.146,0.069,0.199,0.176c0.037,0.091,0.205,0.342,0.396,0.585c0.146,0.191,0.297,0.382,0.411,0.48
               c0.183,0.168,0.365,0.268,0.518,0.343c0.055,0.03,0.1,0.053,0.145,0.077c0.07,0.045,0.177,0.083,0.283,0.121
               c0.16,0.061,0.326,0.122,0.418,0.206c0.084,0.068,0.168,0.145,0.26,0.205c0.092,0.061,0.189,0.115,0.297,0.152
               c0.084,0.023,0.13,0.055,0.183,0.092c0.068,0.038,0.138,0.084,0.289,0.129c0.138,0.039,0.222,0.068,0.291,0.084
               c0.065,0.023,0.121,0.046,0.204,0.068c0.094,0.031,0.152,0.092,0.198,0.13c0.046,0.046,0.077,0.077,0.12,0.084
               c0.017,0.007,0.031,0.007,0.056,0.015c0.052,0.008,0.129,0.015,0.129,0.053c0.007,0.038,0,0.069-0.008,0.099
               c-0.015,0.046-0.03,0.092-0.015,0.153c0,0.015,0,0.037,0.006,0.068c0,0.046,0.009,0.091,0.017,0.137
               c-0.03,0.023-0.092,0.046-0.114,0.046c-0.029,0-0.092-0.015-0.152-0.061c-0.045-0.023-0.092-0.069-0.129-0.13
               c-0.045-0.075-0.106-0.083-0.162-0.045c-0.051,0.031-0.098,0.106-0.143,0.183c-0.031,0.045-0.061,0.099-0.084,0.114
               c-0.037,0.03-0.076,0.03-0.13,0.023c-0.045,0-0.091-0.008-0.159,0.007c-0.099,0.015-0.152,0.123-0.176,0.251
               c-0.021,0.115-0.021,0.252-0.021,0.358c0,0.107,0,0.168-0.008,0.214c-0.009,0.03-0.017,0.045-0.039,0.061
               c-0.029,0.022-0.062,0.045-0.105,0.075c-0.055,0.031-0.113,0.069-0.197,0.115c-0.076,0.046-0.16,0.076-0.236,0.107
               c-0.092,0.03-0.176,0.052-0.242,0.068c-0.139,0.023-0.176,0.167-0.209,0.297c-0.014,0.053-0.02,0.099-0.036,0.13
               c-0.03,0.046-0.037,0.053-0.054,0.06c-0.022,0.008-0.053,0.015-0.099,0.046c-0.03,0.031-0.092,0.031-0.159,0.015
               c-0.092-0.015-0.184-0.053-0.252-0.091c-0.137-0.083-0.229-0.076-0.336-0.068h-0.008c-0.038,0-0.066,0-0.091-0.015
               c-0.022-0.009-0.046-0.039-0.075-0.077C161.569,40.178,161.446,40.178,161.388,40.186z"/>
             <path fill="#808184" d="M166.032,38.723c-0.032,0.008-0.063,0.016-0.094,0.016
               c-0.054,0.015-0.1,0.023-0.129,0.023c-0.084,0.007-0.093-0.023-0.121-0.069c-0.008-0.015-0.017-0.031-0.022-0.053
               c-0.062-0.091-0.214-0.062-0.345-0.039c-0.062,0.016-0.104,0.023-0.137,0.023c-0.029,0-0.045-0.114-0.053-0.198
               c0-0.03-0.008-0.06-0.008-0.083c-0.007-0.038,0-0.068,0.008-0.099c0.016-0.039,0.021-0.084,0.021-0.137
               c-0.016-0.114-0.129-0.137-0.221-0.153c-0.015,0-0.029,0-0.045-0.007c-0.017,0-0.047-0.023-0.076-0.053
               c-0.055-0.053-0.123-0.122-0.236-0.16c-0.084-0.023-0.145-0.046-0.205-0.061c-0.076-0.023-0.152-0.046-0.289-0.092
               c-0.145-0.038-0.205-0.076-0.268-0.122c-0.055-0.029-0.105-0.068-0.205-0.098c-0.092-0.031-0.183-0.084-0.267-0.138
               c-0.092-0.061-0.176-0.129-0.252-0.205c-0.114-0.091-0.29-0.152-0.448-0.213c-0.106-0.038-0.205-0.077-0.269-0.114
               c-0.054-0.031-0.099-0.054-0.151-0.084c-0.145-0.077-0.318-0.168-0.494-0.327c-0.106-0.1-0.26-0.282-0.403-0.465
               c-0.177-0.244-0.345-0.488-0.38-0.571c-0.069-0.137-0.131-0.167-0.252-0.221c-0.03-0.015-0.063-0.03-0.107-0.053
               c-0.168-0.092-0.281-0.046-0.455,0.023c-0.039,0.015-0.068,0.022-0.115,0.037c-0.13,0.047-0.213-0.015-0.326-0.099
               c-0.054-0.044-0.115-0.091-0.19-0.128c-0.075-0.046-0.093-0.062-0.083-0.062c0-0.007,0.014-0.022,0.021-0.03
               c0.039-0.046,0.084-0.092,0.105-0.19c0.029-0.175-0.091-0.206-0.189-0.236c-0.037-0.008-0.068-0.016-0.068-0.023
               c0-0.046-0.008-0.092-0.037-0.145c-0.03-0.053-0.084-0.107-0.176-0.175c-0.129-0.107-0.197-0.053-0.268-0.015
               c-0.015,0.015-0.029,0.03-0.045,0.023c-0.021-0.016-0.029-0.023-0.047-0.031c-0.084-0.053-0.166-0.114-0.417-0.144
               c-0.14-0.024-0.14-0.031-0.146-0.054c0-0.038-0.008-0.084-0.061-0.152c-0.105-0.13-0.222-0.069-0.336,0.023
               c-0.016,0.015-0.038,0.038-0.061,0.053c-0.023,0.023-0.045,0.038-0.068,0.053c-0.084,0.069-0.075,0.129-0.068,0.191
               c0,0.03,0.008,0.053-0.007,0.084c-0.03,0.045-0.114,0.068-0.198,0.083c-0.062,0.015-0.115,0.03-0.168,0.053
               c-0.054,0.024-0.105,0.092-0.16,0.16c-0.067,0.106-0.121,0.236-0.138,0.289c-0.021,0.061,0.031,0.138,0.077,0.214
               c0.038,0.06,0.068,0.115,0.068,0.115c-0.01,0.014-0.076,0.014-0.152,0.007c-0.13-0.016-0.281-0.046-0.357-0.069
               c-0.099-0.03-0.189-0.022-0.252-0.022c-0.029-0.206-0.105-0.541-0.197-0.922c-0.221-0.982-0.518-2.284-0.373-2.322
               c0.076-0.016,0.213-0.061,0.381-0.115c0.41-0.121,1.013-0.312,1.24-0.327c0,0.015-0.008,0.038-0.008,0.061
               c-0.015,0.19,0.017,0.213-0.015,0.327c-0.022,0.122-0.092,0.206-0.03,0.282c0.062,0.069,0.114,0.1,0.106,0.168
               c0,0.068-0.022,0.213,0.067,0.198c0.084-0.015,0.191-0.008,0.207-0.123c0.021-0.114,0.006-0.25-0.031-0.319
               c-0.038-0.076,0.017-0.175-0.016-0.236c-0.031-0.068-0.062-0.122-0.045-0.213c0-0.023,0-0.046,0.008-0.061
               c0.076,0.038,0.183,0.083,0.297,0.137l0.203,0.083c0.01,0.077,0,0.145-0.021,0.191c-0.054,0.084,0.015,0.319,0.021,0.365
               c0.018,0.046,0.207,0.358,0.375,0.396s0.174-0.014,0.244-0.091c0.075-0.077,0.098-0.236,0.014-0.388
               c-0.029-0.069-0.053-0.137-0.068-0.213c0.168,0.084,0.307,0.151,0.374,0.206c0.091,0.061,0.188,0.113,0.28,0.174
               c0.186,0.107,0.359,0.205,0.404,0.32c0.039,0.092,0.137,0.205,0.26,0.312c0.152,0.146,0.334,0.282,0.41,0.321
               c0.069,0.029,0.222,0.037,0.39,0.029c0.205-0.007,0.433-0.022,0.577-0.052c0.16-0.032,0.466-0.032,0.701-0.032h0.268
               c0.066-0.007,0.113-0.045,0.159-0.084c0.054-0.045,0.106-0.091,0.214-0.007c0.068,0.054,0.145,0.107,0.234,0.168
               c0.199,0.137,0.412,0.282,0.429,0.365c0.015,0.099,0.112,0.114,0.219,0.129c0.039,0.008,0.077,0.008,0.106,0.024
               c0.062,0.015,0.1,0.045,0.07,0.137c-0.017,0.06-0.031,0.121-0.031,0.182c-0.016,0.122,0,0.252,0.031,0.35
               c0.035,0.107,0.104,0.184,0.202,0.214c0.017,0,0.032,0.007,0.048,0.007c0.045,0.008,0.1,0.008,0.146,0.008
               c0.084,0.007,0.151,0.007,0.188,0.023c0.016,0.007,0.016,0.03-0.007,0.076l-0.008,0.015v0.008v0.053
               c0.03,0.121,0.168,0.282,0.358,0.411c0.183,0.13,0.418,0.237,0.64,0.244c0.06,0,0.121,0,0.176-0.015
               c0.249-0.054,0.312,0.152,0.349,0.282c0.022,0.053,0.033,0.099,0.063,0.129c0.029,0.038,0.068,0.038,0.121-0.008
               c0.021-0.015,0.047-0.038,0.068-0.061c0.113-0.083,0.213-0.159,0.297-0.517c0.063-0.305,0.152-0.344,0.275-0.396
               c0.028-0.015,0.067-0.031,0.098-0.054c0.047-0.023,0.1-0.037,0.152-0.045c0.045,0.084,0.151,0.274,0.274,0.342
               c0.099,0.061,0.167,0.046,0.228,0.038c0.031-0.007,0.063-0.015,0.084-0.007c0.053,0.023,0.106,0.045,0.161,0.06
               c0.052,0.024,0.104,0.031,0.158,0.031c0.062,0,0.09-0.046,0.138-0.099c0.03-0.046,0.075-0.099,0.146-0.13
               c0.062-0.022,0.122,0.039,0.182,0.099c0.056,0.062,0.114,0.13,0.19,0.13c0.063,0,0.107,0.023,0.152,0.053
               c0.055,0.039,0.106,0.084,0.162,0.138c0.066,0.068,0.104,0.098,0.165,0.114c0.054,0.023,0.115,0.015,0.205,0.007
               c0.031,0,0.046,0.008,0.054,0.008c0.017,0.008,0.023,0.015,0.023,0.023c0.006,0.015,0,0.038,0,0.061
               c-0.008,0.037-0.038,0.084-0.063,0.121c-0.037,0.039-0.112,0.069-0.188,0.099c-0.099,0.038-0.196,0.077-0.274,0.153
               c-0.1,0.092-0.113,0.092-0.189,0.084h-0.055c-0.158-0.008-0.176,0.008-0.258,0.083l-0.022,0.016
               c-0.017,0.014-0.038,0.022-0.054,0.03c-0.008,0.007-0.022,0.007-0.03,0c-0.009,0-0.017-0.008-0.024-0.016
               c-0.006-0.007-0.021-0.03-0.029-0.053c-0.029-0.098-0.082-0.107-0.145-0.083c-0.016,0.008-0.029,0.015-0.045,0.015
               c-0.032,0.016-0.063,0.03-0.092,0.03c-0.047,0-0.069-0.037-0.092-0.069c-0.023-0.037-0.047-0.068-0.084-0.09
               c-0.023-0.008-0.039-0.016-0.045-0.031v-0.015v-0.016c0.006-0.015,0.021-0.03,0.053-0.045c0.113-0.069,0.076-0.19,0.008-0.297
               c-0.03-0.061-0.077-0.114-0.105-0.152l-0.009-0.008c-0.084-0.107-0.19-0.023-0.304,0.068c-0.031,0.016-0.055,0.038-0.076,0.054
               c-0.055,0.038-0.107,0.061-0.162,0.061c-0.051,0-0.104-0.015-0.167-0.054c-0.021-0.007-0.045-0.015-0.065-0.015
               c-0.047,0-0.078,0.015-0.101,0.054c-0.022,0.022-0.03,0.061-0.038,0.099c-0.016,0.076-0.016,0.167,0,0.244
               c0.016,0.06,0.008,0.106-0.016,0.136c-0.03,0.031-0.092,0.054-0.168,0.076c-0.113,0.031-0.145,0.054-0.129,0.099
               c0.008,0.023,0.021,0.038,0.037,0.054c0.021,0.015,0.047,0.03,0.063,0.067c0.016,0.047-0.008,0.07-0.04,0.1
               c-0.028,0.023-0.052,0.053-0.084,0.099c-0.052,0.084-0.104,0.115-0.181,0.16L166,36.538c-0.067,0.038-0.146,0.008-0.289-0.045
               c-0.028-0.008-0.062-0.023-0.106-0.039c-0.168-0.06-0.235-0.015-0.267,0.084c-0.017,0.046-0.022,0.099-0.022,0.153
               c0,0.022-0.008,0.045-0.008,0.06c-0.015,0.138,0.053,0.183,0.151,0.191h0.101h0.053c0.023,0,0.031,0.023,0.031,0.045
               c0.015,0.054,0.022,0.099,0.092,0.115c0.037,0.007,0.074,0.022,0.13,0.038c0.053,0.015,0.104,0.038,0.175,0.053
               c0.029,0.008,0.029,0.03,0.021,0.061c-0.008,0.023-0.015,0.046-0.021,0.069c-0.018,0.03-0.023,0.061-0.031,0.099
               c-0.029,0.129,0.115,0.251,0.207,0.326c0.016,0.016,0.03,0.024,0.045,0.039c0.021,0.023,0.038,0.054,0.038,0.1
               c0.007,0.052,0,0.113-0.022,0.189c-0.029,0.1-0.068,0.145-0.113,0.206c-0.023,0.03-0.047,0.069-0.077,0.114
               C166.022,38.503,166.022,38.655,166.032,38.723"/>
             
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M166.032,38.723c-0.032,0.008-0.063,0.016-0.094,0.016c-0.054,0.015-0.1,0.023-0.129,0.023
               c-0.084,0.007-0.093-0.023-0.121-0.069c-0.008-0.015-0.017-0.031-0.022-0.053c-0.062-0.091-0.214-0.062-0.345-0.039
               c-0.062,0.016-0.104,0.023-0.137,0.023c-0.029,0-0.045-0.114-0.053-0.198c0-0.03-0.008-0.06-0.008-0.083
               c-0.007-0.038,0-0.068,0.008-0.099c0.016-0.039,0.021-0.084,0.021-0.137c-0.016-0.114-0.129-0.137-0.221-0.153
               c-0.015,0-0.029,0-0.045-0.007c-0.017,0-0.047-0.023-0.076-0.053c-0.055-0.053-0.123-0.122-0.236-0.16
               c-0.084-0.023-0.145-0.046-0.205-0.061c-0.076-0.023-0.152-0.046-0.289-0.092c-0.145-0.038-0.205-0.076-0.268-0.122
               c-0.055-0.029-0.105-0.068-0.205-0.098c-0.092-0.031-0.183-0.084-0.267-0.138c-0.092-0.061-0.176-0.129-0.252-0.205
               c-0.114-0.091-0.29-0.152-0.448-0.213c-0.106-0.038-0.205-0.077-0.269-0.114c-0.054-0.031-0.099-0.054-0.151-0.084
               c-0.145-0.077-0.318-0.168-0.494-0.327c-0.106-0.1-0.26-0.282-0.403-0.465c-0.177-0.244-0.345-0.488-0.38-0.571
               c-0.069-0.137-0.131-0.167-0.252-0.221c-0.03-0.015-0.063-0.03-0.107-0.053c-0.168-0.092-0.281-0.046-0.455,0.023
               c-0.039,0.015-0.068,0.022-0.115,0.037c-0.13,0.047-0.213-0.015-0.326-0.099c-0.054-0.044-0.115-0.091-0.19-0.128
               c-0.075-0.046-0.093-0.062-0.083-0.062c0-0.007,0.014-0.022,0.021-0.03c0.039-0.046,0.084-0.092,0.105-0.19
               c0.029-0.175-0.091-0.206-0.189-0.236c-0.037-0.008-0.068-0.016-0.068-0.023c0-0.046-0.008-0.092-0.037-0.145
               c-0.03-0.053-0.084-0.107-0.176-0.175c-0.129-0.107-0.197-0.053-0.268-0.015c-0.015,0.015-0.029,0.03-0.045,0.023
               c-0.021-0.016-0.029-0.023-0.047-0.031c-0.084-0.053-0.166-0.114-0.417-0.144c-0.14-0.024-0.14-0.031-0.146-0.054
               c0-0.038-0.008-0.084-0.061-0.152c-0.105-0.13-0.222-0.069-0.336,0.023c-0.016,0.015-0.038,0.038-0.061,0.053
               c-0.023,0.023-0.045,0.038-0.068,0.053c-0.084,0.069-0.075,0.129-0.068,0.191c0,0.03,0.008,0.053-0.007,0.084
               c-0.03,0.045-0.114,0.068-0.198,0.083c-0.062,0.015-0.115,0.03-0.168,0.053c-0.054,0.024-0.105,0.092-0.16,0.16
               c-0.067,0.106-0.121,0.236-0.138,0.289c-0.021,0.061,0.031,0.138,0.077,0.214c0.038,0.06,0.068,0.115,0.068,0.115
               c-0.01,0.014-0.076,0.014-0.152,0.007c-0.13-0.016-0.281-0.046-0.357-0.069c-0.099-0.03-0.189-0.022-0.252-0.022
               c-0.029-0.206-0.105-0.541-0.197-0.922c-0.221-0.982-0.518-2.284-0.373-2.322c0.076-0.016,0.213-0.061,0.381-0.115
               c0.41-0.121,1.013-0.312,1.24-0.327c0,0.015-0.008,0.038-0.008,0.061c-0.015,0.19,0.017,0.213-0.015,0.327
               c-0.022,0.122-0.092,0.206-0.03,0.282c0.062,0.069,0.114,0.1,0.106,0.168c0,0.068-0.022,0.213,0.067,0.198
               c0.084-0.015,0.191-0.008,0.207-0.123c0.021-0.114,0.006-0.25-0.031-0.319c-0.038-0.076,0.017-0.175-0.016-0.236
               c-0.031-0.068-0.062-0.122-0.045-0.213c0-0.023,0-0.046,0.008-0.061c0.076,0.038,0.183,0.083,0.297,0.137l0.203,0.083
               c0.01,0.077,0,0.145-0.021,0.191c-0.054,0.084,0.015,0.319,0.021,0.365c0.018,0.046,0.207,0.358,0.375,0.396
               s0.174-0.014,0.244-0.091c0.075-0.077,0.098-0.236,0.014-0.388c-0.029-0.069-0.053-0.137-0.068-0.213
               c0.168,0.084,0.307,0.151,0.374,0.206c0.091,0.061,0.188,0.113,0.28,0.174c0.186,0.107,0.359,0.205,0.404,0.32
               c0.039,0.092,0.137,0.205,0.26,0.312c0.152,0.146,0.334,0.282,0.41,0.321c0.069,0.029,0.222,0.037,0.39,0.029
               c0.205-0.007,0.433-0.022,0.577-0.052c0.16-0.032,0.466-0.032,0.701-0.032h0.268c0.066-0.007,0.113-0.045,0.159-0.084
               c0.054-0.045,0.106-0.091,0.214-0.007c0.068,0.054,0.145,0.107,0.234,0.168c0.199,0.137,0.412,0.282,0.429,0.365
               c0.015,0.099,0.112,0.114,0.219,0.129c0.039,0.008,0.077,0.008,0.106,0.024c0.062,0.015,0.1,0.045,0.07,0.137
               c-0.017,0.06-0.031,0.121-0.031,0.182c-0.016,0.122,0,0.252,0.031,0.35c0.035,0.107,0.104,0.184,0.202,0.214
               c0.017,0,0.032,0.007,0.048,0.007c0.045,0.008,0.1,0.008,0.146,0.008c0.084,0.007,0.151,0.007,0.188,0.023
               c0.016,0.007,0.016,0.03-0.007,0.076l-0.008,0.015v0.008v0.053c0.03,0.121,0.168,0.282,0.358,0.411
               c0.183,0.13,0.418,0.237,0.64,0.244c0.06,0,0.121,0,0.176-0.015c0.249-0.054,0.312,0.152,0.349,0.282
               c0.022,0.053,0.033,0.099,0.063,0.129c0.029,0.038,0.068,0.038,0.121-0.008c0.021-0.015,0.047-0.038,0.068-0.061
               c0.113-0.083,0.213-0.159,0.297-0.517c0.063-0.305,0.152-0.344,0.275-0.396c0.028-0.015,0.067-0.031,0.098-0.054
               c0.047-0.023,0.1-0.037,0.152-0.045c0.045,0.084,0.151,0.274,0.274,0.342c0.099,0.061,0.167,0.046,0.228,0.038
               c0.031-0.007,0.063-0.015,0.084-0.007c0.053,0.023,0.106,0.045,0.161,0.06c0.052,0.024,0.104,0.031,0.158,0.031
               c0.062,0,0.09-0.046,0.138-0.099c0.03-0.046,0.075-0.099,0.146-0.13c0.062-0.022,0.122,0.039,0.182,0.099
               c0.056,0.062,0.114,0.13,0.19,0.13c0.063,0,0.107,0.023,0.152,0.053c0.055,0.039,0.106,0.084,0.162,0.138
               c0.066,0.068,0.104,0.098,0.165,0.114c0.054,0.023,0.115,0.015,0.205,0.007c0.031,0,0.046,0.008,0.054,0.008
               c0.017,0.008,0.023,0.015,0.023,0.023c0.006,0.015,0,0.038,0,0.061c-0.008,0.037-0.038,0.084-0.063,0.121
               c-0.037,0.039-0.112,0.069-0.188,0.099c-0.099,0.038-0.196,0.077-0.274,0.153c-0.1,0.092-0.113,0.092-0.189,0.084h-0.055
               c-0.158-0.008-0.176,0.008-0.258,0.083l-0.022,0.016c-0.017,0.014-0.038,0.022-0.054,0.03c-0.008,0.007-0.022,0.007-0.03,0
               c-0.009,0-0.017-0.008-0.024-0.016c-0.006-0.007-0.021-0.03-0.029-0.053c-0.029-0.098-0.082-0.107-0.145-0.083
               c-0.016,0.008-0.029,0.015-0.045,0.015c-0.032,0.016-0.063,0.03-0.092,0.03c-0.047,0-0.069-0.037-0.092-0.069
               c-0.023-0.037-0.047-0.068-0.084-0.09c-0.023-0.008-0.039-0.016-0.045-0.031v-0.015v-0.016c0.006-0.015,0.021-0.03,0.053-0.045
               c0.113-0.069,0.076-0.19,0.008-0.297c-0.03-0.061-0.077-0.114-0.105-0.152l-0.009-0.008c-0.084-0.107-0.19-0.023-0.304,0.068
               c-0.031,0.016-0.055,0.038-0.076,0.054c-0.055,0.038-0.107,0.061-0.162,0.061c-0.051,0-0.104-0.015-0.167-0.054
               c-0.021-0.007-0.045-0.015-0.065-0.015c-0.047,0-0.078,0.015-0.101,0.054c-0.022,0.022-0.03,0.061-0.038,0.099
               c-0.016,0.076-0.016,0.167,0,0.244c0.016,0.06,0.008,0.106-0.016,0.136c-0.03,0.031-0.092,0.054-0.168,0.076
               c-0.113,0.031-0.145,0.054-0.129,0.099c0.008,0.023,0.021,0.038,0.037,0.054c0.021,0.015,0.047,0.03,0.063,0.067
               c0.016,0.047-0.008,0.07-0.04,0.1c-0.028,0.023-0.052,0.053-0.084,0.099c-0.052,0.084-0.104,0.115-0.181,0.16L166,36.538
               c-0.067,0.038-0.146,0.008-0.289-0.045c-0.028-0.008-0.062-0.023-0.106-0.039c-0.168-0.06-0.235-0.015-0.267,0.084
               c-0.017,0.046-0.022,0.099-0.022,0.153c0,0.022-0.008,0.045-0.008,0.06c-0.015,0.138,0.053,0.183,0.151,0.191h0.101h0.053
               c0.023,0,0.031,0.023,0.031,0.045c0.015,0.054,0.022,0.099,0.092,0.115c0.037,0.007,0.074,0.022,0.13,0.038
               c0.053,0.015,0.104,0.038,0.175,0.053c0.029,0.008,0.029,0.03,0.021,0.061c-0.008,0.023-0.015,0.046-0.021,0.069
               c-0.018,0.03-0.023,0.061-0.031,0.099c-0.029,0.129,0.115,0.251,0.207,0.326c0.016,0.016,0.03,0.024,0.045,0.039
               c0.021,0.023,0.038,0.054,0.038,0.1c0.007,0.052,0,0.113-0.022,0.189c-0.029,0.1-0.068,0.145-0.113,0.206
               c-0.023,0.03-0.047,0.069-0.077,0.114C166.022,38.503,166.022,38.655,166.032,38.723z"/>
             <path fill="#808184" d="M167.614,35.99c0.031,0.031,0.068,0.054,0.13,0.054
               c0.053,0,0.092-0.015,0.129-0.031c0.015-0.007,0.032-0.015,0.039-0.015c0.008,0,0.014,0,0.022,0.015
               c0.008,0.038,0.021,0.069,0.045,0.092s0.045,0.037,0.076,0.045c0.022,0.008,0.053,0.008,0.084,0
               c0.029-0.008,0.063-0.03,0.092-0.053l0.021-0.015c0.055-0.053,0.063-0.061,0.184-0.061c0.022,0,0.039,0.008,0.054,0.008
               c0.122,0.007,0.137,0.007,0.266-0.122c0.062-0.061,0.153-0.092,0.237-0.122c0.099-0.038,0.183-0.068,0.236-0.137
               c0.037-0.046,0.066-0.107,0.084-0.16c0.006-0.038,0.006-0.076,0-0.106c-0.018-0.038-0.039-0.069-0.078-0.092
               c-0.029-0.008-0.061-0.015-0.098-0.015c-0.092,0.007-0.139,0.007-0.184,0c-0.031-0.015-0.068-0.038-0.115-0.092h-0.007
               c-0.062-0.06-0.114-0.114-0.167-0.152c-0.063-0.046-0.131-0.068-0.206-0.068c-0.038-0.008-0.085-0.054-0.13-0.099
               c-0.076-0.084-0.16-0.175-0.281-0.123c-0.105,0.039-0.16,0.107-0.197,0.161c-0.022,0.03-0.047,0.061-0.055,0.061
               c-0.037,0-0.084-0.015-0.129-0.023c-0.045-0.015-0.1-0.038-0.145-0.061c-0.063-0.023-0.107-0.015-0.152,0
               c-0.038,0.008-0.083,0.015-0.152-0.023c-0.092-0.053-0.176-0.199-0.221-0.282c0.122-0.038,0.234-0.091,0.305-0.289
               c0.045-0.122,0.055-0.244,0.068-0.35c0.016-0.16,0.021-0.282,0.145-0.282c0.076-0.007,0.139-0.016,0.19-0.023
               c0.13-0.015,0.229-0.031,0.479,0.053c0.129,0.046,0.235,0.099,0.313,0.138c0.16,0.083,0.268,0.137,0.434,0.075
               c0.146-0.06,0.16-0.159,0.184-0.266c0.017-0.068,0.031-0.137,0.092-0.175l0.055-0.038c0.143-0.092,0.266-0.168,0.387-0.121
               c0.022,0.014,0.063,0.029,0.106,0.052c0.222,0.115,0.634,0.321,0.959,0.267c0.132-0.016,0.229-0.038,0.313-0.053
               c0.158-0.039,0.258-0.054,0.35-0.015c0.083,0.038,0.198,0.052,0.335,0.06c0.138,0.008,0.297,0,0.472-0.015
               c0.055,0,0.092,0,0.137-0.007c0.356-0.023,0.854-0.062,1.068,0.121c0.129,0.115,0.213,0.183,0.28,0.221
               c0.076,0.038,0.136,0.053,0.228,0.053c0.03,0,0.076,0,0.123,0.008c0.067,0.015,0.137,0.023,0.19,0.007
               c0.015,0,0.028-0.007,0.045-0.015c0.029,0.015,0.067,0.046,0.113,0.099c0.039,0.038,0.024,0.084,0.01,0.115
               c-0.01,0.023-0.017,0.038-0.017,0.053c-0.008,0.008-0.039,0.008-0.077,0.008c-0.037,0-0.082,0.007-0.12,0.014
               c-0.068,0.016-0.094,0.054-0.122,0.092c-0.022,0.038-0.046,0.076-0.138,0.084c-0.159,0.015-0.305,0.145-0.411,0.236
               c-0.03,0.023-0.062,0.045-0.076,0.06c-0.054,0.039-0.099,0.123-0.138,0.199c-0.038,0.061-0.067,0.122-0.075,0.122
               c-0.037,0-0.083,0.03-0.146,0.068c-0.061,0.038-0.145,0.091-0.234,0.107c-0.1,0.015-0.184,0-0.29-0.023
               c-0.068-0.016-0.146-0.031-0.236-0.046c-0.168-0.023-0.229,0.114-0.243,0.259c-0.015,0.076-0.015,0.152-0.007,0.206v0.045v0.008
               c-0.008,0.008-0.032,0.031-0.069,0.046c-0.039,0.023-0.084,0.03-0.129,0.023h-0.009c-0.099-0.031-0.167,0.014-0.228,0.06
               c-0.03,0.023-0.063,0.046-0.092,0.053c0,0-0.008-0.037-0.022-0.076c-0.015-0.075-0.039-0.167-0.112-0.22
               c-0.109-0.069-0.201,0.007-0.275,0.068c-0.023,0.016-0.023,0.038-0.03,0.031c-0.03-0.008-0.092,0.007-0.159,0.03
               c-0.068,0.031-0.152,0.077-0.222,0.114c-0.054,0.031-0.093,0.084-0.122,0.13c-0.037,0.053-0.068,0.106-0.121,0.099
               c-0.131-0.015-0.184,0.053-0.251,0.136c-0.022,0.023-0.038,0.046-0.063,0.069c-0.09,0.084-0.021,0.153,0.055,0.229
               c0.037,0.038,0.076,0.075,0.068,0.098c-0.006,0.031-0.031,0.047-0.068,0.061c-0.061,0.023-0.145,0.023-0.213,0.023
               c-0.084,0-0.229,0-0.357,0.023c-0.084,0.023-0.168,0.054-0.222,0.107c-0.03,0.031-0.054,0.015-0.075-0.008
               c-0.023-0.03-0.063-0.061-0.114-0.076c-0.062-0.015-0.106,0.008-0.151,0.031c-0.047,0.023-0.1,0.053-0.184,0.015
               c-0.063-0.023-0.099-0.031-0.123-0.038c-0.021-0.008-0.029-0.008-0.129-0.099c-0.137-0.122-0.167-0.114-0.221-0.084
               c-0.016,0-0.021,0.008-0.038,0.008c-0.062,0-0.101,0.03-0.151,0.068c-0.054,0.046-0.123,0.091-0.191,0.061
               c-0.119-0.045-0.182-0.053-0.258-0.069c-0.029-0.007-0.055-0.014-0.092-0.023c-0.016,0-0.037-0.007-0.047-0.007
               c-0.029-0.007-0.045-0.016-0.066-0.016c-0.022,0-0.039,0-0.076,0.016h-0.016c-0.039,0.007-0.068,0.016-0.106,0.016
               c-0.038-0.009-0.091-0.023-0.175-0.054c-0.146-0.045-0.207-0.008-0.268,0.038c-0.021,0.016-0.045,0.03-0.068,0.038
               c-0.029,0.008-0.053,0-0.067-0.008c-0.017-0.014-0.022-0.037-0.039-0.068c-0.007-0.023,0-0.053,0.009-0.091
               c0.016-0.038,0.022-0.084,0.016-0.13c-0.008-0.023-0.008-0.046-0.008-0.053c0.008-0.016,0.022-0.031,0.037-0.045
               c0.023-0.024,0.045-0.047,0.068-0.077c0.039-0.03,0.068-0.068,0.092-0.084c0.016-0.015,0.184,0.016,0.313,0.046
               c0.054,0.008,0.1,0.016,0.13,0.023C167.492,36.128,167.569,36.051,167.614,35.99"/> 
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M167.614,35.99c0.031,0.031,0.068,0.054,0.13,0.054c0.053,0,0.092-0.015,0.129-0.031c0.015-0.007,0.032-0.015,0.039-0.015
               c0.008,0,0.014,0,0.022,0.015c0.008,0.038,0.021,0.069,0.045,0.092s0.045,0.037,0.076,0.045c0.022,0.008,0.053,0.008,0.084,0
               c0.029-0.008,0.063-0.03,0.092-0.053l0.021-0.015c0.055-0.053,0.063-0.061,0.184-0.061c0.022,0,0.039,0.008,0.054,0.008
               c0.122,0.007,0.137,0.007,0.266-0.122c0.062-0.061,0.153-0.092,0.237-0.122c0.099-0.038,0.183-0.068,0.236-0.137
               c0.037-0.046,0.066-0.107,0.084-0.16c0.006-0.038,0.006-0.076,0-0.106c-0.018-0.038-0.039-0.069-0.078-0.092
               c-0.029-0.008-0.061-0.015-0.098-0.015c-0.092,0.007-0.139,0.007-0.184,0c-0.031-0.015-0.068-0.038-0.115-0.092h-0.007
               c-0.062-0.06-0.114-0.114-0.167-0.152c-0.063-0.046-0.131-0.068-0.206-0.068c-0.038-0.008-0.085-0.054-0.13-0.099
               c-0.076-0.084-0.16-0.175-0.281-0.123c-0.105,0.039-0.16,0.107-0.197,0.161c-0.022,0.03-0.047,0.061-0.055,0.061
               c-0.037,0-0.084-0.015-0.129-0.023c-0.045-0.015-0.1-0.038-0.145-0.061c-0.063-0.023-0.107-0.015-0.152,0
               c-0.038,0.008-0.083,0.015-0.152-0.023c-0.092-0.053-0.176-0.199-0.221-0.282c0.122-0.038,0.234-0.091,0.305-0.289
               c0.045-0.122,0.055-0.244,0.068-0.35c0.016-0.16,0.021-0.282,0.145-0.282c0.076-0.007,0.139-0.016,0.19-0.023
               c0.13-0.015,0.229-0.031,0.479,0.053c0.129,0.046,0.235,0.099,0.313,0.138c0.16,0.083,0.268,0.137,0.434,0.075
               c0.146-0.06,0.16-0.159,0.184-0.266c0.017-0.068,0.031-0.137,0.092-0.175l0.055-0.038c0.143-0.092,0.266-0.168,0.387-0.121
               c0.022,0.014,0.063,0.029,0.106,0.052c0.222,0.115,0.634,0.321,0.959,0.267c0.132-0.016,0.229-0.038,0.313-0.053
               c0.158-0.039,0.258-0.054,0.35-0.015c0.083,0.038,0.198,0.052,0.335,0.06c0.138,0.008,0.297,0,0.472-0.015
               c0.055,0,0.092,0,0.137-0.007c0.356-0.023,0.854-0.062,1.068,0.121c0.129,0.115,0.213,0.183,0.28,0.221
               c0.076,0.038,0.136,0.053,0.228,0.053c0.03,0,0.076,0,0.123,0.008c0.067,0.015,0.137,0.023,0.19,0.007
               c0.015,0,0.028-0.007,0.045-0.015c0.029,0.015,0.067,0.046,0.113,0.099c0.039,0.038,0.024,0.084,0.01,0.115
               c-0.01,0.023-0.017,0.038-0.017,0.053c-0.008,0.008-0.039,0.008-0.077,0.008c-0.037,0-0.082,0.007-0.12,0.014
               c-0.068,0.016-0.094,0.054-0.122,0.092c-0.022,0.038-0.046,0.076-0.138,0.084c-0.159,0.015-0.305,0.145-0.411,0.236
               c-0.03,0.023-0.062,0.045-0.076,0.06c-0.054,0.039-0.099,0.123-0.138,0.199c-0.038,0.061-0.067,0.122-0.075,0.122
               c-0.037,0-0.083,0.03-0.146,0.068c-0.061,0.038-0.145,0.091-0.234,0.107c-0.1,0.015-0.184,0-0.29-0.023
               c-0.068-0.016-0.146-0.031-0.236-0.046c-0.168-0.023-0.229,0.114-0.243,0.259c-0.015,0.076-0.015,0.152-0.007,0.206v0.045v0.008
               c-0.008,0.008-0.032,0.031-0.069,0.046c-0.039,0.023-0.084,0.03-0.129,0.023h-0.009c-0.099-0.031-0.167,0.014-0.228,0.06
               c-0.03,0.023-0.063,0.046-0.092,0.053c0,0-0.008-0.037-0.022-0.076c-0.015-0.075-0.039-0.167-0.112-0.22
               c-0.109-0.069-0.201,0.007-0.275,0.068c-0.023,0.016-0.023,0.038-0.03,0.031c-0.03-0.008-0.092,0.007-0.159,0.03
               c-0.068,0.031-0.152,0.077-0.222,0.114c-0.054,0.031-0.093,0.084-0.122,0.13c-0.037,0.053-0.068,0.106-0.121,0.099
               c-0.131-0.015-0.184,0.053-0.251,0.136c-0.022,0.023-0.038,0.046-0.063,0.069c-0.09,0.084-0.021,0.153,0.055,0.229
               c0.037,0.038,0.076,0.075,0.068,0.098c-0.006,0.031-0.031,0.047-0.068,0.061c-0.061,0.023-0.145,0.023-0.213,0.023
               c-0.084,0-0.229,0-0.357,0.023c-0.084,0.023-0.168,0.054-0.222,0.107c-0.03,0.031-0.054,0.015-0.075-0.008
               c-0.023-0.03-0.063-0.061-0.114-0.076c-0.062-0.015-0.106,0.008-0.151,0.031c-0.047,0.023-0.1,0.053-0.184,0.015
               c-0.063-0.023-0.099-0.031-0.123-0.038c-0.021-0.008-0.029-0.008-0.129-0.099c-0.137-0.122-0.167-0.114-0.221-0.084
               c-0.016,0-0.021,0.008-0.038,0.008c-0.062,0-0.101,0.03-0.151,0.068c-0.054,0.046-0.123,0.091-0.191,0.061
               c-0.119-0.045-0.182-0.053-0.258-0.069c-0.029-0.007-0.055-0.014-0.092-0.023c-0.016,0-0.037-0.007-0.047-0.007
               c-0.029-0.007-0.045-0.016-0.066-0.016c-0.022,0-0.039,0-0.076,0.016h-0.016c-0.039,0.007-0.068,0.016-0.106,0.016
               c-0.038-0.009-0.091-0.023-0.175-0.054c-0.146-0.045-0.207-0.008-0.268,0.038c-0.021,0.016-0.045,0.03-0.068,0.038
               c-0.029,0.008-0.053,0-0.067-0.008c-0.017-0.014-0.022-0.037-0.039-0.068c-0.007-0.023,0-0.053,0.009-0.091
               c0.016-0.038,0.022-0.084,0.016-0.13c-0.008-0.023-0.008-0.046-0.008-0.053c0.008-0.016,0.022-0.031,0.037-0.045
               c0.023-0.024,0.045-0.047,0.068-0.077c0.039-0.03,0.068-0.068,0.092-0.084c0.016-0.015,0.184,0.016,0.313,0.046
               c0.054,0.008,0.1,0.016,0.13,0.023C167.492,36.128,167.569,36.051,167.614,35.99z"/>
             <path fill="#808184" d="M171.49,38.663c-0.068-0.038-0.183-0.091-0.267-0.13
               c-0.139-0.053-0.229,0.016-0.344,0.1c-0.029,0.023-0.066,0.045-0.105,0.068c-0.045,0.023-0.063,0.03-0.069,0.015
               c-0.007-0.015-0.015-0.045-0.021-0.083c-0.01-0.023-0.017-0.046-0.023-0.076c0-0.008-0.008-0.024-0.016-0.031
               c-0.03-0.046-0.084-0.061-0.146-0.061c-0.054,0-0.114,0.015-0.176,0.031c-0.066,0.022-0.136,0.053-0.175,0.076
               c-0.039,0.03-0.084,0.069-0.123,0.113c-0.067,0.055-0.135,0.123-0.203,0.153c-0.016,0.008-0.038,0.008-0.055,0.015
               c-0.106,0.039-0.174,0.061-0.268,0.252c-0.053,0.107-0.09,0.091-0.166,0.06c-0.039-0.007-0.084-0.023-0.139-0.037
               c-0.045-0.008-0.084-0.077-0.113-0.168c-0.063-0.183-0.068-0.427-0.068-0.502c0-0.077-0.008-0.153-0.047-0.214
               c-0.029-0.053-0.074-0.091-0.15-0.106c-0.045-0.015-0.076-0.023-0.076-0.031c-0.008,0,0.009-0.007,0.031-0.022
               c0.066-0.062,0.053-0.123,0.008-0.175c-0.024-0.031-0.055-0.054-0.084-0.077c-0.03-0.023-0.063-0.053-0.084-0.076
               c-0.055-0.053-0.114-0.107-0.184-0.144c-0.076-0.031-0.152-0.046-0.253-0.024c-0.007,0.008-0.021,0.008-0.028,0.016
               c-0.084,0.045-0.178,0.152-0.235,0.259c-0.069,0.114-0.115,0.236-0.091,0.289v0.016c0.007,0.007,0.007,0.015,0.015,0.03
               c0.007,0.038,0.007,0.091,0,0.136c-0.008,0.046-0.03,0.092-0.069,0.107c-0.008,0-0.015,0.009-0.021,0.009
               c-0.055,0.014-0.1,0-0.145-0.024c-0.062-0.031-0.131-0.053-0.229,0c-0.099,0.053-0.113,0.19-0.13,0.304
               c-0.008,0.061-0.016,0.115-0.016,0.115c-0.008,0-0.021-0.022-0.037-0.054c-0.039-0.061-0.084-0.144-0.197-0.175
               c-0.139-0.03-0.282,0.107-0.405,0.229c-0.067,0.061-0.129,0.121-0.158,0.129c-0.055,0.008-0.069-0.038-0.1-0.099
               c-0.009-0.023-0.017-0.045-0.03-0.068c-0.031-0.068-0.092-0.092-0.167-0.092c-0.008-0.06-0.008-0.183,0.037-0.265
               c0.03-0.039,0.052-0.077,0.077-0.108c0.045-0.06,0.091-0.122,0.128-0.236c0.022-0.091,0.039-0.167,0.03-0.228
               c-0.008-0.076-0.039-0.13-0.075-0.167c-0.017-0.016-0.03-0.024-0.048-0.039c-0.074-0.06-0.188-0.159-0.174-0.228
               c0.008-0.031,0.017-0.061,0.029-0.084c0.01-0.03,0.018-0.053,0.024-0.076c0.021-0.091,0.015-0.16-0.101-0.191
               c-0.068-0.014-0.121-0.037-0.166-0.053c-0.056-0.015-0.101-0.03-0.146-0.038v-0.03c-0.017-0.068-0.022-0.138-0.138-0.138
               c-0.016,0-0.039,0-0.055,0.008c-0.029,0-0.061,0-0.09-0.008c-0.039,0-0.063-0.014-0.063-0.075
               c0.008-0.023,0.008-0.039,0.008-0.061c0.008-0.046,0.008-0.099,0.021-0.13c0.008-0.038,0.047-0.053,0.131-0.023
               c0.03,0.016,0.076,0.031,0.105,0.046c0.177,0.061,0.275,0.099,0.374,0.038l0.037-0.023c0.092-0.045,0.161-0.084,0.221-0.19
               c0.022-0.038,0.046-0.061,0.067-0.084c0.063-0.053,0.101-0.099,0.063-0.205c-0.007-0.038-0.031-0.061-0.054-0.084h0.017
               c0.098-0.023,0.174-0.061,0.221-0.114c0.045-0.054,0.061-0.122,0.037-0.221c-0.008-0.061-0.015-0.137,0-0.198
               c0-0.031,0.008-0.053,0.022-0.06c0-0.009,0.009-0.016,0.017-0.016c0,0,0.008,0.007,0.021,0.007
               c0.068,0.038,0.145,0.061,0.212,0.061c0.078,0,0.154-0.023,0.222-0.076c0.024-0.015,0.055-0.038,0.076-0.053
               c0.077-0.061,0.139-0.114,0.16-0.091l0.016,0.015c0.023,0.03,0.068,0.084,0.1,0.137c0.039,0.068,0.068,0.129,0.023,0.159
               c-0.047,0.024-0.068,0.054-0.093,0.084c-0.007,0.023-0.016,0.046-0.016,0.069c0.009,0.022,0.009,0.038,0.024,0.061
               c0.014,0.03,0.045,0.053,0.074,0.068c0.016,0.008,0.023,0.016,0.029,0.031c-0.021,0.038-0.075,0.122-0.129,0.114
               c-0.03-0.008-0.074-0.015-0.121-0.023c-0.152-0.031-0.344-0.068-0.396-0.031c-0.031,0.031-0.068,0.069-0.1,0.1
               c-0.03,0.03-0.055,0.053-0.068,0.068c-0.021,0.03-0.046,0.053-0.062,0.084c-0.015,0.03-0.015,0.069,0,0.114
               c0,0.023-0.009,0.054-0.015,0.084c-0.016,0.045-0.023,0.099,0,0.152c0.015,0.046,0.039,0.084,0.068,0.115
               c0.036,0.022,0.083,0.037,0.158,0.015c0.039-0.008,0.068-0.031,0.092-0.046c0.047-0.03,0.084-0.053,0.184-0.023
               c0.09,0.031,0.151,0.046,0.199,0.054c0.051,0.007,0.09,0,0.144-0.016h0.016c0.022-0.007,0.039-0.015,0.046-0.015
               c0.008,0,0.016,0.008,0.038,0.015c0.015,0,0.029,0.008,0.054,0.008c0.03,0.008,0.061,0.015,0.092,0.023
               c0.066,0.015,0.137,0.022,0.242,0.068c0.123,0.046,0.215-0.023,0.289-0.076c0.039-0.03,0.068-0.053,0.092-0.053
               c0.039,0,0.063-0.008,0.076-0.015c0.017-0.008,0.024-0.016,0.114,0.068c0.121,0.107,0.13,0.114,0.175,0.122
               c0.022,0.008,0.047,0.015,0.106,0.038c0.123,0.054,0.198,0.016,0.267-0.023c0.03-0.015,0.055-0.03,0.084-0.023
               c0.023,0.008,0.047,0.03,0.069,0.053c0.062,0.054,0.112,0.107,0.213,0c0.038-0.03,0.104-0.053,0.175-0.068
               c0.123-0.03,0.252-0.03,0.328-0.03c0.021,0.007,0.045,0.007,0.067,0.007c-0.007,0.054,0,0.122,0.062,0.198
               c0.053,0.053,0.092,0.076,0.129,0.099c0.024,0.016,0.039,0.023,0.039,0.053v0.061c0,0.046,0,0.069,0.037,0.137
               c0.008,0.016,0.016,0.046,0.021,0.077c0,0.03,0.009,0.053,0.025,0.084c0.028,0.091,0.074,0.152,0.188,0.113
               c0.068-0.022,0.123-0.06,0.168-0.091c0.039-0.022,0.068-0.045,0.076-0.038c0.047,0.054,0.113,0.054,0.176,0.061
               c0.039,0,0.084,0.008,0.105,0.023c0.039,0.03,0.084,0.038,0.131,0.045c0.029,0.008,0.066,0.016,0.06,0.032
               c0,0.029-0.007,0.06-0.022,0.083c-0.021,0.045-0.037,0.092-0.006,0.137c0,0.008,0,0.023,0.006,0.046
               c0.01,0.075,0.017,0.182,0.139,0.327c0.061,0.061,0.1,0.099,0.129,0.121c0.029,0.039,0.038,0.039,0.046,0.099
               c0.008,0.054-0.022,0.123-0.046,0.191L171.49,38.663z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M171.49,38.663c-0.068-0.038-0.183-0.091-0.267-0.13c-0.139-0.053-0.229,0.016-0.344,0.1c-0.029,0.023-0.066,0.045-0.105,0.068
               c-0.045,0.023-0.063,0.03-0.069,0.015c-0.007-0.015-0.015-0.045-0.021-0.083c-0.01-0.023-0.017-0.046-0.023-0.076
               c0-0.008-0.008-0.024-0.016-0.031c-0.03-0.046-0.084-0.061-0.146-0.061c-0.054,0-0.114,0.015-0.176,0.031
               c-0.066,0.022-0.136,0.053-0.175,0.076c-0.039,0.03-0.084,0.069-0.123,0.113c-0.067,0.055-0.135,0.123-0.203,0.153
               c-0.016,0.008-0.038,0.008-0.055,0.015c-0.106,0.039-0.174,0.061-0.268,0.252c-0.053,0.107-0.09,0.091-0.166,0.06
               c-0.039-0.007-0.084-0.023-0.139-0.037c-0.045-0.008-0.084-0.077-0.113-0.168c-0.063-0.183-0.068-0.427-0.068-0.502
               c0-0.077-0.008-0.153-0.047-0.214c-0.029-0.053-0.074-0.091-0.15-0.106c-0.045-0.015-0.076-0.023-0.076-0.031
               c-0.008,0,0.009-0.007,0.031-0.022c0.066-0.062,0.053-0.123,0.008-0.175c-0.024-0.031-0.055-0.054-0.084-0.077
               c-0.03-0.023-0.063-0.053-0.084-0.076c-0.055-0.053-0.114-0.107-0.184-0.144c-0.076-0.031-0.152-0.046-0.253-0.024
               c-0.007,0.008-0.021,0.008-0.028,0.016c-0.084,0.045-0.178,0.152-0.235,0.259c-0.069,0.114-0.115,0.236-0.091,0.289v0.016
               c0.007,0.007,0.007,0.015,0.015,0.03c0.007,0.038,0.007,0.091,0,0.136c-0.008,0.046-0.03,0.092-0.069,0.107
               c-0.008,0-0.015,0.009-0.021,0.009c-0.055,0.014-0.1,0-0.145-0.024c-0.062-0.031-0.131-0.053-0.229,0
               c-0.099,0.053-0.113,0.19-0.13,0.304c-0.008,0.061-0.016,0.115-0.016,0.115c-0.008,0-0.021-0.022-0.037-0.054
               c-0.039-0.061-0.084-0.144-0.197-0.175c-0.139-0.03-0.282,0.107-0.405,0.229c-0.067,0.061-0.129,0.121-0.158,0.129
               c-0.055,0.008-0.069-0.038-0.1-0.099c-0.009-0.023-0.017-0.045-0.03-0.068c-0.031-0.068-0.092-0.092-0.167-0.092
               c-0.008-0.06-0.008-0.183,0.037-0.265c0.03-0.039,0.052-0.077,0.077-0.108c0.045-0.06,0.091-0.122,0.128-0.236
               c0.022-0.091,0.039-0.167,0.03-0.228c-0.008-0.076-0.039-0.13-0.075-0.167c-0.017-0.016-0.03-0.024-0.048-0.039
               c-0.074-0.06-0.188-0.159-0.174-0.228c0.008-0.031,0.017-0.061,0.029-0.084c0.01-0.03,0.018-0.053,0.024-0.076
               c0.021-0.091,0.015-0.16-0.101-0.191c-0.068-0.014-0.121-0.037-0.166-0.053c-0.056-0.015-0.101-0.03-0.146-0.038v-0.03
               c-0.017-0.068-0.022-0.138-0.138-0.138c-0.016,0-0.039,0-0.055,0.008c-0.029,0-0.061,0-0.09-0.008
               c-0.039,0-0.063-0.014-0.063-0.075c0.008-0.023,0.008-0.039,0.008-0.061c0.008-0.046,0.008-0.099,0.021-0.13
               c0.008-0.038,0.047-0.053,0.131-0.023c0.03,0.016,0.076,0.031,0.105,0.046c0.177,0.061,0.275,0.099,0.374,0.038l0.037-0.023
               c0.092-0.045,0.161-0.084,0.221-0.19c0.022-0.038,0.046-0.061,0.067-0.084c0.063-0.053,0.101-0.099,0.063-0.205
               c-0.007-0.038-0.031-0.061-0.054-0.084h0.017c0.098-0.023,0.174-0.061,0.221-0.114c0.045-0.054,0.061-0.122,0.037-0.221
               c-0.008-0.061-0.015-0.137,0-0.198c0-0.031,0.008-0.053,0.022-0.06c0-0.009,0.009-0.016,0.017-0.016
               c0,0,0.008,0.007,0.021,0.007c0.068,0.038,0.145,0.061,0.212,0.061c0.078,0,0.154-0.023,0.222-0.076
               c0.024-0.015,0.055-0.038,0.076-0.053c0.077-0.061,0.139-0.114,0.16-0.091l0.016,0.015c0.023,0.03,0.068,0.084,0.1,0.137
               c0.039,0.068,0.068,0.129,0.023,0.159c-0.047,0.024-0.068,0.054-0.093,0.084c-0.007,0.023-0.016,0.046-0.016,0.069
               c0.009,0.022,0.009,0.038,0.024,0.061c0.014,0.03,0.045,0.053,0.074,0.068c0.016,0.008,0.023,0.016,0.029,0.031
               c-0.021,0.038-0.075,0.122-0.129,0.114c-0.03-0.008-0.074-0.015-0.121-0.023c-0.152-0.031-0.344-0.068-0.396-0.031
               c-0.031,0.031-0.068,0.069-0.1,0.1c-0.03,0.03-0.055,0.053-0.068,0.068c-0.021,0.03-0.046,0.053-0.062,0.084
               c-0.015,0.03-0.015,0.069,0,0.114c0,0.023-0.009,0.054-0.015,0.084c-0.016,0.045-0.023,0.099,0,0.152
               c0.015,0.046,0.039,0.084,0.068,0.115c0.036,0.022,0.083,0.037,0.158,0.015c0.039-0.008,0.068-0.031,0.092-0.046
               c0.047-0.03,0.084-0.053,0.184-0.023c0.09,0.031,0.151,0.046,0.199,0.054c0.051,0.007,0.09,0,0.144-0.016h0.016
               c0.022-0.007,0.039-0.015,0.046-0.015c0.008,0,0.016,0.008,0.038,0.015c0.015,0,0.029,0.008,0.054,0.008
               c0.03,0.008,0.061,0.015,0.092,0.023c0.066,0.015,0.137,0.022,0.242,0.068c0.123,0.046,0.215-0.023,0.289-0.076
               c0.039-0.03,0.068-0.053,0.092-0.053c0.039,0,0.063-0.008,0.076-0.015c0.017-0.008,0.024-0.016,0.114,0.068
               c0.121,0.107,0.13,0.114,0.175,0.122c0.022,0.008,0.047,0.015,0.106,0.038c0.123,0.054,0.198,0.016,0.267-0.023
               c0.03-0.015,0.055-0.03,0.084-0.023c0.023,0.008,0.047,0.03,0.069,0.053c0.062,0.054,0.112,0.107,0.213,0
               c0.038-0.03,0.104-0.053,0.175-0.068c0.123-0.03,0.252-0.03,0.328-0.03c0.021,0.007,0.045,0.007,0.067,0.007
               c-0.007,0.054,0,0.122,0.062,0.198c0.053,0.053,0.092,0.076,0.129,0.099c0.024,0.016,0.039,0.023,0.039,0.053v0.061
               c0,0.046,0,0.069,0.037,0.137c0.008,0.016,0.016,0.046,0.021,0.077c0,0.03,0.009,0.053,0.025,0.084
               c0.028,0.091,0.074,0.152,0.188,0.113c0.068-0.022,0.123-0.06,0.168-0.091c0.039-0.022,0.068-0.045,0.076-0.038
               c0.047,0.054,0.113,0.054,0.176,0.061c0.039,0,0.084,0.008,0.105,0.023c0.039,0.03,0.084,0.038,0.131,0.045
               c0.029,0.008,0.066,0.016,0.06,0.032c0,0.029-0.007,0.06-0.022,0.083c-0.021,0.045-0.037,0.092-0.006,0.137
               c0,0.008,0,0.023,0.006,0.046c0.01,0.075,0.017,0.182,0.139,0.327c0.061,0.061,0.1,0.099,0.129,0.121
               c0.029,0.039,0.038,0.039,0.046,0.099c0.008,0.054-0.022,0.123-0.046,0.191L171.49,38.663z"/>
             <path fill="#808184" d="M171.476,38.952c-0.091,0.053-0.283,0.144-0.396,0.114
               c-0.099-0.03-0.185-0.015-0.274-0.008c-0.084,0.008-0.166,0.016-0.26-0.007c-0.137-0.03-0.296,0.007-0.434,0.038
               c-0.075,0.023-0.146,0.038-0.197,0.038c-0.091,0-0.139,0.053-0.189,0.107c-0.055,0.06-0.113,0.129-0.252,0.167
               c-0.268,0.084-0.326,0.183-0.418,0.313c-0.016,0.022-0.031,0.045-0.047,0.068c-0.084,0.107-0.037,0.175,0.047,0.236
               c0.022,0.015,0.045,0.031,0.076,0.046c0.061,0.038,0.129,0.076,0.159,0.137c0.062,0.106,0.067,0.145,0.083,0.22
               c0.008,0.046,0.016,0.092,0.029,0.168c0.024,0.084,0,0.175-0.045,0.266c-0.053,0.122-0.152,0.229-0.228,0.282
               c-0.123,0.091-0.077,0.16-0.01,0.259c0.024,0.038,0.055,0.084,0.075,0.137c0.033,0.061,0.018,0.084-0.014,0.099
               c-0.016,0.007-0.037,0.007-0.053,0.016c-0.031,0.007-0.054,0.014-0.084,0.03c-0.054,0.023-0.131,0.045-0.199,0.038
               c-0.061-0.008-0.113-0.023-0.158-0.061c-0.039-0.031-0.076-0.046-0.106-0.038c-0.047,0-0.084,0.022-0.113,0.045
               c-0.032,0.031-0.054,0.068-0.069,0.107c-0.039,0.069-0.047,0.152-0.023,0.213c0.031,0.092,0.085,0.114,0.146,0.137
               c0.037,0.016,0.084,0.031,0.105,0.083c0.021,0.024,0.039,0.062,0.045,0.092c0.01,0.031,0.018,0.069,0,0.099
               c-0.006,0.031-0.029,0.054-0.074,0.084c-0.022,0.016-0.063,0.031-0.106,0.046l-0.075,0.023c-0.094,0.022-0.13,0.038-0.161,0.053
               c-0.03,0.023-0.037,0.046-0.045,0.076c-0.007,0.016-0.017,0.039-0.03,0.069c-0.039,0.091-0.023,0.19-0.017,0.274
               c0.008,0.046,0.017,0.091,0.008,0.106c-0.03,0.046-0.037,0.099-0.03,0.161c0.008,0.061,0.039,0.121,0.069,0.19
               c0.045,0.091-0.017,0.274-0.039,0.334c-0.045,0.023-0.198,0.107-0.235,0.069c-0.047-0.038-0.099-0.069-0.151-0.099
               c-0.054-0.022-0.114-0.038-0.177-0.053c-0.09-0.007-0.189,0.068-0.289,0.144c-0.068,0.054-0.137,0.107-0.182,0.114
               c-0.115,0.016-0.123,0.1-0.13,0.198c0,0.032,0,0.077-0.015,0.1c-0.018,0.023-0.055,0.015-0.108,0.007
               c-0.067-0.015-0.144-0.053-0.213-0.084c-0.073-0.03-0.137-0.038-0.175-0.014c-0.046,0.023-0.077,0.06-0.105,0.114
               c-0.017,0.038-0.054,0.076-0.092,0.114c-0.046,0.038-0.107,0.068-0.175,0.099c-0.146,0.061-0.106,0.221-0.069,0.38
               c0.016,0.084,0.03,0.161,0.023,0.214c-0.01,0.076,0.015,0.152,0.029,0.22c0.031,0.092,0.055,0.176,0,0.252
               c-0.039,0.06-0.184,0.114-0.342,0.16c-0.223,0.061-0.473,0.099-0.602,0.099c-0.223-0.008-0.298,0.014-0.489,0.083l-0.046,0.015
               c-0.129,0.046-0.287,0.023-0.455,0c-0.115-0.015-0.222-0.03-0.32-0.022c-0.1,0.007-0.151,0.015-0.197,0.022
               c-0.061,0.016-0.1,0.023-0.268,0.008c-0.205-0.023-0.357-0.076-0.549-0.144c-0.066-0.023-0.145-0.054-0.234-0.077
               c-0.221-0.076-0.389-0.16-0.465-0.205c0.021-0.023,0.045-0.054,0.068-0.084c0.205-0.251,0.525-0.654,0.402-0.891
               c-0.045-0.091-0.061-0.167-0.068-0.228c-0.016-0.138-0.021-0.221-0.259-0.221h-0.113c-0.03,0.008-0.063,0.008-0.106,0.008
               c-0.205,0-0.381-0.016-0.381-0.138v-0.106c0.008-0.205,0.008-0.465-0.106-0.731c-0.054-0.107-0.104-0.198-0.159-0.275
               c-0.084-0.129-0.152-0.22-0.092-0.334c0.063-0.114,0.105-0.198,0.115-0.274c0.006-0.091-0.023-0.16-0.131-0.198
               c-0.076-0.039-0.15-0.084-0.189-0.13c-0.03-0.037-0.038-0.075-0.023-0.114c0.016-0.045,0.053-0.084,0.1-0.122
               c0.068-0.068,0.146-0.129,0.121-0.236c-0.006-0.06-0.029-0.106-0.06-0.144c-0.03-0.054-0.054-0.091,0.021-0.161
               c0.093-0.083,0.146-0.205,0.168-0.358c0.017-0.144,0-0.311-0.037-0.501c0.045-0.008,0.105-0.008,0.146,0.037
               c0.037,0.047,0.065,0.084,0.104,0.099c0.045,0.023,0.084,0.031,0.146,0.023h0.008c0.092-0.007,0.166-0.014,0.273,0.053
               c0.075,0.046,0.184,0.092,0.288,0.115c0.095,0.015,0.177,0.007,0.237-0.038c0.037-0.023,0.061-0.031,0.076-0.038
               c0.037-0.015,0.053-0.023,0.099-0.099c0.022-0.039,0.038-0.099,0.053-0.152c0.024-0.099,0.054-0.214,0.123-0.229
               c0.084-0.016,0.168-0.038,0.259-0.069c0.084-0.03,0.176-0.068,0.26-0.114c0.082-0.046,0.145-0.084,0.199-0.114
               c0.045-0.038,0.082-0.061,0.112-0.092c0.045-0.03,0.067-0.06,0.075-0.121c0.009-0.053,0.009-0.115,0.009-0.229
               c0-0.098,0-0.236,0.022-0.335c0.015-0.091,0.045-0.168,0.092-0.175c0.053-0.007,0.099-0.007,0.137,0
               c0.076,0,0.137,0.007,0.205-0.045c0.029-0.031,0.068-0.092,0.1-0.145c0.039-0.068,0.084-0.13,0.113-0.152
               c0.01-0.008,0.016,0,0.023,0.015c0.045,0.076,0.099,0.129,0.152,0.16c0.082,0.061,0.158,0.076,0.197,0.076
               c0.053,0.007,0.121-0.023,0.168-0.046c0.021,0.031,0.053,0.053,0.098,0.053c0.039,0,0.092-0.007,0.152-0.022
               c0.1-0.016,0.221-0.039,0.236-0.008c0.008,0.015,0.016,0.03,0.023,0.046c0.045,0.084,0.074,0.13,0.211,0.13
               c0.045-0.009,0.101-0.016,0.153-0.023c0.104-0.023,0.22-0.054,0.242,0c0.017,0.023,0.024,0.045,0.03,0.067
               c0.045,0.115,0.068,0.183,0.207,0.161c0.062-0.016,0.137-0.084,0.213-0.161c0.105-0.099,0.228-0.212,0.305-0.197
               c0.083,0.023,0.115,0.076,0.137,0.13c0.038,0.06,0.069,0.106,0.145,0.098c0.084-0.007,0.093-0.098,0.102-0.205
               c0.016-0.091,0.021-0.19,0.074-0.221c0.063-0.031,0.1-0.015,0.146,0.007c0.062,0.024,0.112,0.046,0.205,0.024
               c0.016,0,0.029-0.008,0.045-0.016c0.068-0.038,0.107-0.099,0.123-0.175c0.016-0.061,0.008-0.137,0-0.19
               c-0.009-0.016-0.016-0.031-0.022-0.046c-0.007-0.03,0.03-0.114,0.083-0.206c0.054-0.098,0.13-0.19,0.197-0.22
               c0,0,0.01-0.008,0.017-0.008c0.067-0.015,0.12-0.008,0.176,0.015c0.052,0.031,0.104,0.076,0.15,0.122
               c0.032,0.03,0.063,0.062,0.092,0.084c0.03,0.022,0.062,0.045,0.076,0.069c0.008,0.007,0.016,0.022,0,0.03
               c-0.062,0.053-0.084,0.106-0.054,0.152c0.021,0.038,0.068,0.053,0.146,0.076c0.038,0.008,0.069,0.031,0.084,0.061
               c0.03,0.046,0.037,0.099,0.037,0.16c0,0.084,0.008,0.343,0.067,0.533c0.039,0.122,0.101,0.221,0.199,0.236
               c0.047,0.015,0.084,0.031,0.121,0.038c0.131,0.046,0.197,0.068,0.291-0.122c0.074-0.145,0.129-0.16,0.213-0.19
               c0.016-0.008,0.029-0.015,0.053-0.022c0.092-0.039,0.166-0.107,0.235-0.176c0.038-0.038,0.077-0.076,0.114-0.092
               c0.029-0.022,0.084-0.045,0.146-0.068c0.051-0.016,0.098-0.03,0.144-0.03c0.03,0,0.055,0.007,0.062,0.022
               c0.008,0.023,0.016,0.046,0.022,0.069c0.007,0.046,0.017,0.084,0.037,0.114c0.039,0.069,0.1,0.084,0.207,0.016
               c0.038-0.023,0.075-0.046,0.106-0.069c0.092-0.068,0.168-0.115,0.25-0.084c0.092,0.038,0.207,0.091,0.273,0.13
               c-0.008,0.038-0.016,0.076-0.008,0.107C171.459,38.898,171.459,38.93,171.476,38.952"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M171.476,38.952c-0.091,0.053-0.283,0.144-0.396,0.114c-0.099-0.03-0.185-0.015-0.274-0.008
               c-0.084,0.008-0.166,0.016-0.26-0.007c-0.137-0.03-0.296,0.007-0.434,0.038c-0.075,0.023-0.146,0.038-0.197,0.038
               c-0.091,0-0.139,0.053-0.189,0.107c-0.055,0.06-0.113,0.129-0.252,0.167c-0.268,0.084-0.326,0.183-0.418,0.313
               c-0.016,0.022-0.031,0.045-0.047,0.068c-0.084,0.107-0.037,0.175,0.047,0.236c0.022,0.015,0.045,0.031,0.076,0.046
               c0.061,0.038,0.129,0.076,0.159,0.137c0.062,0.106,0.067,0.145,0.083,0.22c0.008,0.046,0.016,0.092,0.029,0.168
               c0.024,0.084,0,0.175-0.045,0.266c-0.053,0.122-0.152,0.229-0.228,0.282c-0.123,0.091-0.077,0.16-0.01,0.259
               c0.024,0.038,0.055,0.084,0.075,0.137c0.033,0.061,0.018,0.084-0.014,0.099c-0.016,0.007-0.037,0.007-0.053,0.016
               c-0.031,0.007-0.054,0.014-0.084,0.03c-0.054,0.023-0.131,0.045-0.199,0.038c-0.061-0.008-0.113-0.023-0.158-0.061
               c-0.039-0.031-0.076-0.046-0.106-0.038c-0.047,0-0.084,0.022-0.113,0.045c-0.032,0.031-0.054,0.068-0.069,0.107
               c-0.039,0.069-0.047,0.152-0.023,0.213c0.031,0.092,0.085,0.114,0.146,0.137c0.037,0.016,0.084,0.031,0.105,0.083
               c0.021,0.024,0.039,0.062,0.045,0.092c0.01,0.031,0.018,0.069,0,0.099c-0.006,0.031-0.029,0.054-0.074,0.084
               c-0.022,0.016-0.063,0.031-0.106,0.046l-0.075,0.023c-0.094,0.022-0.13,0.038-0.161,0.053c-0.03,0.023-0.037,0.046-0.045,0.076
               c-0.007,0.016-0.017,0.039-0.03,0.069c-0.039,0.091-0.023,0.19-0.017,0.274c0.008,0.046,0.017,0.091,0.008,0.106
               c-0.03,0.046-0.037,0.099-0.03,0.161c0.008,0.061,0.039,0.121,0.069,0.19c0.045,0.091-0.017,0.274-0.039,0.334
               c-0.045,0.023-0.198,0.107-0.235,0.069c-0.047-0.038-0.099-0.069-0.151-0.099c-0.054-0.022-0.114-0.038-0.177-0.053
               c-0.09-0.007-0.189,0.068-0.289,0.144c-0.068,0.054-0.137,0.107-0.182,0.114c-0.115,0.016-0.123,0.1-0.13,0.198
               c0,0.032,0,0.077-0.015,0.1c-0.018,0.023-0.055,0.015-0.108,0.007c-0.067-0.015-0.144-0.053-0.213-0.084
               c-0.073-0.03-0.137-0.038-0.175-0.014c-0.046,0.023-0.077,0.06-0.105,0.114c-0.017,0.038-0.054,0.076-0.092,0.114
               c-0.046,0.038-0.107,0.068-0.175,0.099c-0.146,0.061-0.106,0.221-0.069,0.38c0.016,0.084,0.03,0.161,0.023,0.214
               c-0.01,0.076,0.015,0.152,0.029,0.22c0.031,0.092,0.055,0.176,0,0.252c-0.039,0.06-0.184,0.114-0.342,0.16
               c-0.223,0.061-0.473,0.099-0.602,0.099c-0.223-0.008-0.298,0.014-0.489,0.083l-0.046,0.015c-0.129,0.046-0.287,0.023-0.455,0
               c-0.115-0.015-0.222-0.03-0.32-0.022c-0.1,0.007-0.151,0.015-0.197,0.022c-0.061,0.016-0.1,0.023-0.268,0.008
               c-0.205-0.023-0.357-0.076-0.549-0.144c-0.066-0.023-0.145-0.054-0.234-0.077c-0.221-0.076-0.389-0.16-0.465-0.205
               c0.021-0.023,0.045-0.054,0.068-0.084c0.205-0.251,0.525-0.654,0.402-0.891c-0.045-0.091-0.061-0.167-0.068-0.228
               c-0.016-0.138-0.021-0.221-0.259-0.221h-0.113c-0.03,0.008-0.063,0.008-0.106,0.008c-0.205,0-0.381-0.016-0.381-0.138v-0.106
               c0.008-0.205,0.008-0.465-0.106-0.731c-0.054-0.107-0.104-0.198-0.159-0.275c-0.084-0.129-0.152-0.22-0.092-0.334
               c0.063-0.114,0.105-0.198,0.115-0.274c0.006-0.091-0.023-0.16-0.131-0.198c-0.076-0.039-0.15-0.084-0.189-0.13
               c-0.03-0.037-0.038-0.075-0.023-0.114c0.016-0.045,0.053-0.084,0.1-0.122c0.068-0.068,0.146-0.129,0.121-0.236
               c-0.006-0.06-0.029-0.106-0.06-0.144c-0.03-0.054-0.054-0.091,0.021-0.161c0.093-0.083,0.146-0.205,0.168-0.358
               c0.017-0.144,0-0.311-0.037-0.501c0.045-0.008,0.105-0.008,0.146,0.037c0.037,0.047,0.065,0.084,0.104,0.099
               c0.045,0.023,0.084,0.031,0.146,0.023h0.008c0.092-0.007,0.166-0.014,0.273,0.053c0.075,0.046,0.184,0.092,0.288,0.115
               c0.095,0.015,0.177,0.007,0.237-0.038c0.037-0.023,0.061-0.031,0.076-0.038c0.037-0.015,0.053-0.023,0.099-0.099
               c0.022-0.039,0.038-0.099,0.053-0.152c0.024-0.099,0.054-0.214,0.123-0.229c0.084-0.016,0.168-0.038,0.259-0.069
               c0.084-0.03,0.176-0.068,0.26-0.114c0.082-0.046,0.145-0.084,0.199-0.114c0.045-0.038,0.082-0.061,0.112-0.092
               c0.045-0.03,0.067-0.06,0.075-0.121c0.009-0.053,0.009-0.115,0.009-0.229c0-0.098,0-0.236,0.022-0.335
               c0.015-0.091,0.045-0.168,0.092-0.175c0.053-0.007,0.099-0.007,0.137,0c0.076,0,0.137,0.007,0.205-0.045
               c0.029-0.031,0.068-0.092,0.1-0.145c0.039-0.068,0.084-0.13,0.113-0.152c0.01-0.008,0.016,0,0.023,0.015
               c0.045,0.076,0.099,0.129,0.152,0.16c0.082,0.061,0.158,0.076,0.197,0.076c0.053,0.007,0.121-0.023,0.168-0.046
               c0.021,0.031,0.053,0.053,0.098,0.053c0.039,0,0.092-0.007,0.152-0.022c0.1-0.016,0.221-0.039,0.236-0.008
               c0.008,0.015,0.016,0.03,0.023,0.046c0.045,0.084,0.074,0.13,0.211,0.13c0.045-0.009,0.101-0.016,0.153-0.023
               c0.104-0.023,0.22-0.054,0.242,0c0.017,0.023,0.024,0.045,0.03,0.067c0.045,0.115,0.068,0.183,0.207,0.161
               c0.062-0.016,0.137-0.084,0.213-0.161c0.105-0.099,0.228-0.212,0.305-0.197c0.083,0.023,0.115,0.076,0.137,0.13
               c0.038,0.06,0.069,0.106,0.145,0.098c0.084-0.007,0.093-0.098,0.102-0.205c0.016-0.091,0.021-0.19,0.074-0.221
               c0.063-0.031,0.1-0.015,0.146,0.007c0.062,0.024,0.112,0.046,0.205,0.024c0.016,0,0.029-0.008,0.045-0.016
               c0.068-0.038,0.107-0.099,0.123-0.175c0.016-0.061,0.008-0.137,0-0.19c-0.009-0.016-0.016-0.031-0.022-0.046
               c-0.007-0.03,0.03-0.114,0.083-0.206c0.054-0.098,0.13-0.19,0.197-0.22c0,0,0.01-0.008,0.017-0.008
               c0.067-0.015,0.12-0.008,0.176,0.015c0.052,0.031,0.104,0.076,0.15,0.122c0.032,0.03,0.063,0.062,0.092,0.084
               c0.03,0.022,0.062,0.045,0.076,0.069c0.008,0.007,0.016,0.022,0,0.03c-0.062,0.053-0.084,0.106-0.054,0.152
               c0.021,0.038,0.068,0.053,0.146,0.076c0.038,0.008,0.069,0.031,0.084,0.061c0.03,0.046,0.037,0.099,0.037,0.16
               c0,0.084,0.008,0.343,0.067,0.533c0.039,0.122,0.101,0.221,0.199,0.236c0.047,0.015,0.084,0.031,0.121,0.038
               c0.131,0.046,0.197,0.068,0.291-0.122c0.074-0.145,0.129-0.16,0.213-0.19c0.016-0.008,0.029-0.015,0.053-0.022
               c0.092-0.039,0.166-0.107,0.235-0.176c0.038-0.038,0.077-0.076,0.114-0.092c0.029-0.022,0.084-0.045,0.146-0.068
               c0.051-0.016,0.098-0.03,0.144-0.03c0.03,0,0.055,0.007,0.062,0.022c0.008,0.023,0.016,0.046,0.022,0.069
               c0.007,0.046,0.017,0.084,0.037,0.114c0.039,0.069,0.1,0.084,0.207,0.016c0.038-0.023,0.075-0.046,0.106-0.069
               c0.092-0.068,0.168-0.115,0.25-0.084c0.092,0.038,0.207,0.091,0.273,0.13c-0.008,0.038-0.016,0.076-0.008,0.107
               C171.459,38.898,171.459,38.93,171.476,38.952z"/>
             <path fill="#808184" d="M173.911,40.459c-0.045,0.023-0.092,0.045-0.129,0.069
               c-0.084,0.038-0.092,0.076-0.107,0.129c-0.015,0.031-0.029,0.084-0.113,0.182c-0.084,0.099-0.244,0.199-0.412,0.282
               c-0.183,0.084-0.373,0.153-0.479,0.153c-0.092,0-0.137-0.031-0.176-0.061c-0.053-0.046-0.105-0.084-0.221-0.076
               c-0.105,0.007-0.189-0.023-0.297-0.076c-0.076-0.031-0.16-0.076-0.281-0.115c-0.131-0.037-0.19,0-0.213,0.076
               c-0.023,0.062-0.008,0.145,0.008,0.229c0.008,0.076,0.023,0.152,0,0.183c-0.047,0.084-0.055,0.144-0.023,0.205
               c0.031,0.054,0.1,0.099,0.206,0.137c0.022,0.008,0.046-0.007,0.038,0c0,0.008-0.016,0.023-0.038,0.038
               c-0.046,0.039-0.099,0.092-0.122,0.168c-0.037,0.122,0.023,0.145,0.106,0.175c0.038,0.015,0.099,0.031,0.122,0.107
               c0.016,0.038,0.021,0.061,0.029,0.091c0.069,0.198,0.146,0.434,0.289,0.457c0.084,0.015,0.114,0.114,0.161,0.236
               c0.015,0.03,0.029,0.068,0.046,0.114c0.053,0.137,0.137,0.114,0.242,0.084c0.069-0.016,0.153-0.038,0.236,0.015
               c0.016,0.015,0.031,0.023,0.039,0.031c0.015,0.022,0.015,0.053,0.007,0.076c-0.015,0.037-0.046,0.084-0.084,0.121
               c-0.076,0.069-0.168,0.13-0.228,0.16c-0.123,0.061-0.115,0.137-0.076,0.213c0.007,0.008,0.015,0.023,0.021,0.031
               c0.016,0.03,0.037,0.068,0.021,0.091c-0.021,0.061-0.008,0.115,0.008,0.175c0.018,0.062,0.039,0.145,0.023,0.275
               c-0.017,0.098-0.062,0.197-0.115,0.289c-0.066,0.114-0.144,0.213-0.189,0.236c-0.1,0.068-0.074,0.137-0.037,0.297l0.016,0.053
               c0.016,0.054-0.029,0.076-0.092,0.091c-0.021,0.008-0.045,0.008-0.076,0.016c-0.045,0.007-0.092,0.016-0.129,0.038
               c-0.093,0.038-0.1,0.099-0.1,0.19c-0.008,0.061-0.016,0.129-0.047,0.213c-0.021,0.061-0.045,0.107-0.066,0.161
               c-0.068,0.167-0.139,0.327-0.146,0.457c0,0.045-0.021,0.052-0.061,0.052c-0.054,0.008-0.123,0.008-0.214,0.046
               c-0.168,0.061-0.176,0.183-0.176,0.29c-0.007,0.045-0.007,0.091-0.03,0.106c-0.052,0.046-0.068,0.099-0.084,0.16
               c-0.021,0.053-0.037,0.107-0.099,0.176c-0.045,0.045-0.099,0.068-0.16,0.075c-0.091,0.023-0.183,0.008-0.228-0.007
               c-0.092-0.031-0.146,0.03-0.207,0.106c-0.029,0.031-0.061,0.068-0.098,0.107c-0.023,0.008-0.041,0.016-0.055,0.023
               c-0.007,0-0.016,0-0.021-0.007c-0.008,0-0.016-0.008-0.022-0.024c-0.017-0.015-0.031-0.046-0.031-0.068
               c0-0.008,0-0.015-0.008-0.031c-0.007-0.053-0.037-0.106-0.076-0.152c-0.045-0.054-0.106-0.084-0.182-0.076h-0.024
               c-0.091,0.022-0.121,0.083-0.175,0.183c-0.028,0.076-0.074,0.168-0.166,0.289c-0.168,0.22-0.214,0.343-0.269,0.486
               c-0.006,0.039-0.021,0.077-0.045,0.123c-0.076,0.191-0.008,0.221,0.1,0.274c0.023,0.015,0.061,0.038,0.091,0.061
               c0.084,0.069,0.146,0.069,0.222,0.061c0.053,0,0.121-0.007,0.213,0.038c0.092,0.039,0.076,0.205,0.068,0.365
               c-0.015,0.13-0.023,0.252,0.023,0.328c0.066,0.091,0.168,0.1,0.259,0.1c0.06,0.007,0.112,0.007,0.137,0.037
               c0.039,0.061,0.076,0.144,0.13,0.252c0.038,0.091,0.084,0.182,0.137,0.289c0.037,0.068,0.069,0.129,0.1,0.175
               c0.063,0.114,0.105,0.19,0.091,0.213v0.007c-0.021,0.077-0.084,0.221-0.228,0.275c-0.03,0.014-0.03,0.023-0.03,0.023
               c-0.009-0.009-0.009-0.009-0.009-0.016c-0.008-0.046-0.021-0.106-0.105-0.145c-0.146-0.068-0.275,0.061-0.381,0.168
               c-0.021,0.022-0.045,0.054-0.068,0.068c-0.054,0.046-0.093,0.015-0.158-0.029c-0.032-0.024-0.069-0.046-0.108-0.077
               c-0.129-0.076-0.222-0.061-0.351-0.046c-0.061,0.008-0.129,0.015-0.221,0.015c-0.137,0-0.183,0.039-0.183,0.099
               c0,0.031,0.022,0.061,0.038,0.1c0.021,0.037,0.045,0.075,0.037,0.114c0,0.022-0.016,0.022-0.053,0.015
               c-0.062-0.008-0.146-0.015-0.273,0.023c-0.13,0.046-0.207,0.16-0.252,0.281c-0.092-0.121-0.243-0.091-0.297-0.107
               c-0.055-0.014-0.137-0.19-0.16-0.227c-0.021-0.031-0.176-0.115-0.221-0.32c-0.047-0.198-0.062-0.312-0.123-0.351
               c-0.067-0.03-0.325-0.007-0.342-0.075c-0.021-0.069-0.039-0.29-0.099-0.366c-0.062-0.077-0.138-0.206-0.313-0.198
               c-0.166,0.015-0.19,0.076-0.344,0.084c-0.152,0.015-0.197,0-0.387,0c-0.197,0-0.146,0.099-0.307,0.083
               c-0.15-0.007-0.221-0.007-0.342,0c-0.112,0.016-0.054-0.023-0.289-0.06c-0.236-0.031-0.328-0.015-0.465,0.014
               c-0.146,0.039-0.022,0.077-0.259,0.077c-0.228,0-0.321-0.023-0.534,0c-0.22,0.023-0.388,0.114-0.471,0.137
               c-0.063,0.022-0.122-0.03-0.221-0.053c-0.01-0.175-0.016-0.381-0.01-0.549c0.01-0.098,0-0.175,0-0.243
               c-0.006-0.137-0.015-0.199,0.168-0.244c0.152-0.038,0.222-0.137,0.283-0.221c0.053-0.076,0.098-0.144,0.234-0.114
               c0.1,0.022,0.189,0.03,0.273,0.015c0.063-0.007,0.121-0.023,0.168-0.053c0.045-0.038,0.076-0.084,0.084-0.137
               c0.008-0.023,0.008-0.053,0.008-0.084c-0.008-0.031-0.016-0.06-0.023-0.099c-0.015-0.084-0.053-0.175-0.09-0.25
               c-0.054-0.077-0.123-0.139-0.207-0.123h-0.016c-0.037,0.007-0.075,0.031-0.113,0.046c-0.021,0.007-0.055,0.022-0.068,0.022
               c-0.016,0-0.022-0.022-0.022-0.121c-0.007-0.069,0.022-0.153,0.047-0.236c0.067-0.236,0.129-0.473-0.274-0.609
               c-0.101-0.031-0.175-0.054-0.244-0.077c-0.28-0.091-0.344-0.113-0.479-0.243c-0.084-0.092-0.176-0.206-0.268-0.32
               c-0.092-0.122-0.183-0.251-0.267-0.373c-0.062-0.084-0.122-0.152-0.175-0.198c-0.084-0.084-0.137-0.129-0.092-0.221
               c0.076,0.046,0.251,0.137,0.494,0.213c0.084,0.031,0.16,0.054,0.236,0.084c0.19,0.068,0.352,0.122,0.563,0.144
               c0.183,0.024,0.235,0.008,0.306-0.007c0.036-0.008,0.09-0.015,0.182-0.022c0.092,0,0.189,0.014,0.299,0.022
               c0.174,0.023,0.355,0.046,0.501,0l0.046-0.022c0.175-0.061,0.251-0.084,0.457-0.077c0.138,0.007,0.404-0.03,0.624-0.099
               c0.183-0.053,0.351-0.121,0.403-0.198c0.084-0.114,0.055-0.228,0.016-0.335c-0.016-0.068-0.038-0.129-0.03-0.183
               c0.015-0.076-0.008-0.159-0.022-0.243c-0.029-0.122-0.055-0.244,0.009-0.267c0.084-0.037,0.144-0.076,0.196-0.121
               c0.055-0.046,0.092-0.092,0.121-0.145c0.024-0.03,0.039-0.061,0.055-0.069c0.016-0.008,0.046,0,0.099,0.022
               c0.069,0.03,0.153,0.062,0.229,0.084c0.1,0.023,0.176,0.015,0.215-0.061c0.029-0.046,0.029-0.091,0.029-0.145
               c0.006-0.045,0.006-0.091,0.045-0.098c0.068-0.009,0.152-0.069,0.229-0.138c0.083-0.061,0.166-0.121,0.213-0.114
               c0.055,0.007,0.105,0.023,0.152,0.045c0.045,0.016,0.084,0.046,0.121,0.077c0.105,0.091,0.373-0.069,0.373-0.069l0.016-0.008
               l0.008-0.015c0,0,0.106-0.289,0.039-0.434c-0.031-0.06-0.054-0.114-0.063-0.159c0-0.039,0-0.069,0.016-0.092
               c0.031-0.046,0.021-0.106,0.008-0.175c-0.008-0.068-0.015-0.152,0.016-0.22c0.009-0.031,0.023-0.054,0.023-0.077
               c0.006-0.015,0.006-0.022,0.016-0.022c0.006-0.008,0.045-0.024,0.129-0.038l0.076-0.023c0.053-0.016,0.099-0.038,0.129-0.061
               c0.068-0.039,0.105-0.084,0.123-0.138c0.015-0.053,0.015-0.106,0-0.159c-0.018-0.038-0.031-0.084-0.055-0.115
               c-0.047-0.076-0.105-0.099-0.16-0.121c-0.037-0.016-0.074-0.031-0.09-0.076c-0.009-0.039,0-0.092,0.021-0.145
               c0.016-0.023,0.029-0.046,0.045-0.069c0.016-0.015,0.031-0.023,0.047-0.023s0.021,0,0.037,0.015
               c0.068,0.054,0.146,0.077,0.221,0.084c0.092,0.008,0.184-0.015,0.252-0.045c0.016-0.008,0.047-0.015,0.069-0.023
               c0.021-0.008,0.036-0.016,0.06-0.023c0.084-0.038,0.139-0.092,0.076-0.236c-0.031-0.061-0.061-0.107-0.091-0.145
               c-0.038-0.053-0.062-0.091-0.017-0.121c0.084-0.062,0.198-0.183,0.268-0.32c0.045-0.107,0.076-0.229,0.045-0.335
               c-0.016-0.069-0.021-0.122-0.029-0.16c-0.016-0.092-0.023-0.137-0.1-0.259c-0.045-0.084-0.121-0.129-0.191-0.175
               c-0.029-0.015-0.051-0.023-0.066-0.038c-0.037-0.031-0.053-0.054-0.03-0.092c0.022-0.022,0.038-0.045,0.054-0.068
               c0.076-0.122,0.137-0.198,0.364-0.274c0.16-0.054,0.237-0.13,0.298-0.198c0.038-0.038,0.068-0.076,0.122-0.076
               c0.062,0,0.136-0.015,0.213-0.038c0.13-0.031,0.272-0.061,0.388-0.039c0.106,0.031,0.207,0.016,0.291,0.008
               c0.082-0.008,0.158-0.015,0.235,0.008c0.144,0.038,0.365-0.06,0.472-0.122c0,0.007,0.008,0.015,0.008,0.015
               c0.047,0.054,0.1,0.084,0.168,0.084c0.045,0,0.1-0.015,0.145-0.023c0.063-0.007,0.107-0.023,0.115-0.014
               c0.021,0.014,0.061,0.029,0.122,0.044c0.129,0.031,0.343,0.092,0.364,0.191c0.031,0.106,0.092,0.16,0.137,0.205
               c0.023,0.031,0.047,0.054,0.063,0.077c0.008,0.023-0.009,0.045-0.016,0.076c-0.039,0.069-0.068,0.144,0.052,0.22
               c0.063,0.046,0.14,0.062,0.198,0.069c0.077,0.015,0.139,0.031,0.176,0.084c0.047,0.076,0.084,0.129,0.139,0.16
               c0.045,0.03,0.1,0.045,0.16,0.045c0.045,0.008,0.112,0.038,0.182,0.069c0.076,0.038,0.16,0.076,0.222,0.084
               C173.782,40.43,173.843,40.444,173.911,40.459"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M173.911,40.459c-0.045,0.023-0.092,0.045-0.129,0.069c-0.084,0.038-0.092,0.076-0.107,0.129
               c-0.015,0.031-0.029,0.084-0.113,0.182c-0.084,0.099-0.244,0.199-0.412,0.282c-0.183,0.084-0.373,0.153-0.479,0.153
               c-0.092,0-0.137-0.031-0.176-0.061c-0.053-0.046-0.105-0.084-0.221-0.076c-0.105,0.007-0.189-0.023-0.297-0.076
               c-0.076-0.031-0.16-0.076-0.281-0.115c-0.131-0.037-0.19,0-0.213,0.076c-0.023,0.062-0.008,0.145,0.008,0.229
               c0.008,0.076,0.023,0.152,0,0.183c-0.047,0.084-0.055,0.144-0.023,0.205c0.031,0.054,0.1,0.099,0.206,0.137
               c0.022,0.008,0.046-0.007,0.038,0c0,0.008-0.016,0.023-0.038,0.038c-0.046,0.039-0.099,0.092-0.122,0.168
               c-0.037,0.122,0.023,0.145,0.106,0.175c0.038,0.015,0.099,0.031,0.122,0.107c0.016,0.038,0.021,0.061,0.029,0.091
               c0.069,0.198,0.146,0.434,0.289,0.457c0.084,0.015,0.114,0.114,0.161,0.236c0.015,0.03,0.029,0.068,0.046,0.114
               c0.053,0.137,0.137,0.114,0.242,0.084c0.069-0.016,0.153-0.038,0.236,0.015c0.016,0.015,0.031,0.023,0.039,0.031
               c0.015,0.022,0.015,0.053,0.007,0.076c-0.015,0.037-0.046,0.084-0.084,0.121c-0.076,0.069-0.168,0.13-0.228,0.16
               c-0.123,0.061-0.115,0.137-0.076,0.213c0.007,0.008,0.015,0.023,0.021,0.031c0.016,0.03,0.037,0.068,0.021,0.091
               c-0.021,0.061-0.008,0.115,0.008,0.175c0.018,0.062,0.039,0.145,0.023,0.275c-0.017,0.098-0.062,0.197-0.115,0.289
               c-0.066,0.114-0.144,0.213-0.189,0.236c-0.1,0.068-0.074,0.137-0.037,0.297l0.016,0.053c0.016,0.054-0.029,0.076-0.092,0.091
               c-0.021,0.008-0.045,0.008-0.076,0.016c-0.045,0.007-0.092,0.016-0.129,0.038c-0.093,0.038-0.1,0.099-0.1,0.19
               c-0.008,0.061-0.016,0.129-0.047,0.213c-0.021,0.061-0.045,0.107-0.066,0.161c-0.068,0.167-0.139,0.327-0.146,0.457
               c0,0.045-0.021,0.052-0.061,0.052c-0.054,0.008-0.123,0.008-0.214,0.046c-0.168,0.061-0.176,0.183-0.176,0.29
               c-0.007,0.045-0.007,0.091-0.03,0.106c-0.052,0.046-0.068,0.099-0.084,0.16c-0.021,0.053-0.037,0.107-0.099,0.176
               c-0.045,0.045-0.099,0.068-0.16,0.075c-0.091,0.023-0.183,0.008-0.228-0.007c-0.092-0.031-0.146,0.03-0.207,0.106
               c-0.029,0.031-0.061,0.068-0.098,0.107c-0.023,0.008-0.041,0.016-0.055,0.023c-0.007,0-0.016,0-0.021-0.007
               c-0.008,0-0.016-0.008-0.022-0.024c-0.017-0.015-0.031-0.046-0.031-0.068c0-0.008,0-0.015-0.008-0.031
               c-0.007-0.053-0.037-0.106-0.076-0.152c-0.045-0.054-0.106-0.084-0.182-0.076h-0.024c-0.091,0.022-0.121,0.083-0.175,0.183
               c-0.028,0.076-0.074,0.168-0.166,0.289c-0.168,0.22-0.214,0.343-0.269,0.486c-0.006,0.039-0.021,0.077-0.045,0.123
               c-0.076,0.191-0.008,0.221,0.1,0.274c0.023,0.015,0.061,0.038,0.091,0.061c0.084,0.069,0.146,0.069,0.222,0.061
               c0.053,0,0.121-0.007,0.213,0.038c0.092,0.039,0.076,0.205,0.068,0.365c-0.015,0.13-0.023,0.252,0.023,0.328
               c0.066,0.091,0.168,0.1,0.259,0.1c0.06,0.007,0.112,0.007,0.137,0.037c0.039,0.061,0.076,0.144,0.13,0.252
               c0.038,0.091,0.084,0.182,0.137,0.289c0.037,0.068,0.069,0.129,0.1,0.175c0.063,0.114,0.105,0.19,0.091,0.213v0.007
               c-0.021,0.077-0.084,0.221-0.228,0.275c-0.03,0.014-0.03,0.023-0.03,0.023c-0.009-0.009-0.009-0.009-0.009-0.016
               c-0.008-0.046-0.021-0.106-0.105-0.145c-0.146-0.068-0.275,0.061-0.381,0.168c-0.021,0.022-0.045,0.054-0.068,0.068
               c-0.054,0.046-0.093,0.015-0.158-0.029c-0.032-0.024-0.069-0.046-0.108-0.077c-0.129-0.076-0.222-0.061-0.351-0.046
               c-0.061,0.008-0.129,0.015-0.221,0.015c-0.137,0-0.183,0.039-0.183,0.099c0,0.031,0.022,0.061,0.038,0.1
               c0.021,0.037,0.045,0.075,0.037,0.114c0,0.022-0.016,0.022-0.053,0.015c-0.062-0.008-0.146-0.015-0.273,0.023
               c-0.13,0.046-0.207,0.16-0.252,0.281c-0.092-0.121-0.243-0.091-0.297-0.107c-0.055-0.014-0.137-0.19-0.16-0.227
               c-0.021-0.031-0.176-0.115-0.221-0.32c-0.047-0.198-0.062-0.312-0.123-0.351c-0.067-0.03-0.325-0.007-0.342-0.075
               c-0.021-0.069-0.039-0.29-0.099-0.366c-0.062-0.077-0.138-0.206-0.313-0.198c-0.166,0.015-0.19,0.076-0.344,0.084
               c-0.152,0.015-0.197,0-0.387,0c-0.197,0-0.146,0.099-0.307,0.083c-0.15-0.007-0.221-0.007-0.342,0
               c-0.112,0.016-0.054-0.023-0.289-0.06c-0.236-0.031-0.328-0.015-0.465,0.014c-0.146,0.039-0.022,0.077-0.259,0.077
               c-0.228,0-0.321-0.023-0.534,0c-0.22,0.023-0.388,0.114-0.471,0.137c-0.063,0.022-0.122-0.03-0.221-0.053
               c-0.01-0.175-0.016-0.381-0.01-0.549c0.01-0.098,0-0.175,0-0.243c-0.006-0.137-0.015-0.199,0.168-0.244
               c0.152-0.038,0.222-0.137,0.283-0.221c0.053-0.076,0.098-0.144,0.234-0.114c0.1,0.022,0.189,0.03,0.273,0.015
               c0.063-0.007,0.121-0.023,0.168-0.053c0.045-0.038,0.076-0.084,0.084-0.137c0.008-0.023,0.008-0.053,0.008-0.084
               c-0.008-0.031-0.016-0.06-0.023-0.099c-0.015-0.084-0.053-0.175-0.09-0.25c-0.054-0.077-0.123-0.139-0.207-0.123h-0.016
               c-0.037,0.007-0.075,0.031-0.113,0.046c-0.021,0.007-0.055,0.022-0.068,0.022c-0.016,0-0.022-0.022-0.022-0.121
               c-0.007-0.069,0.022-0.153,0.047-0.236c0.067-0.236,0.129-0.473-0.274-0.609c-0.101-0.031-0.175-0.054-0.244-0.077
               c-0.28-0.091-0.344-0.113-0.479-0.243c-0.084-0.092-0.176-0.206-0.268-0.32c-0.092-0.122-0.183-0.251-0.267-0.373
               c-0.062-0.084-0.122-0.152-0.175-0.198c-0.084-0.084-0.137-0.129-0.092-0.221c0.076,0.046,0.251,0.137,0.494,0.213
               c0.084,0.031,0.16,0.054,0.236,0.084c0.19,0.068,0.352,0.122,0.563,0.144c0.183,0.024,0.235,0.008,0.306-0.007
               c0.036-0.008,0.09-0.015,0.182-0.022c0.092,0,0.189,0.014,0.299,0.022c0.174,0.023,0.355,0.046,0.501,0l0.046-0.022
               c0.175-0.061,0.251-0.084,0.457-0.077c0.138,0.007,0.404-0.03,0.624-0.099c0.183-0.053,0.351-0.121,0.403-0.198
               c0.084-0.114,0.055-0.228,0.016-0.335c-0.016-0.068-0.038-0.129-0.03-0.183c0.015-0.076-0.008-0.159-0.022-0.243
               c-0.029-0.122-0.055-0.244,0.009-0.267c0.084-0.037,0.144-0.076,0.196-0.121c0.055-0.046,0.092-0.092,0.121-0.145
               c0.024-0.03,0.039-0.061,0.055-0.069c0.016-0.008,0.046,0,0.099,0.022c0.069,0.03,0.153,0.062,0.229,0.084
               c0.1,0.023,0.176,0.015,0.215-0.061c0.029-0.046,0.029-0.091,0.029-0.145c0.006-0.045,0.006-0.091,0.045-0.098
               c0.068-0.009,0.152-0.069,0.229-0.138c0.083-0.061,0.166-0.121,0.213-0.114c0.055,0.007,0.105,0.023,0.152,0.045
               c0.045,0.016,0.084,0.046,0.121,0.077c0.105,0.091,0.373-0.069,0.373-0.069l0.016-0.008l0.008-0.015
               c0,0,0.106-0.289,0.039-0.434c-0.031-0.06-0.054-0.114-0.063-0.159c0-0.039,0-0.069,0.016-0.092
               c0.031-0.046,0.021-0.106,0.008-0.175c-0.008-0.068-0.015-0.152,0.016-0.22c0.009-0.031,0.023-0.054,0.023-0.077
               c0.006-0.015,0.006-0.022,0.016-0.022c0.006-0.008,0.045-0.024,0.129-0.038l0.076-0.023c0.053-0.016,0.099-0.038,0.129-0.061
               c0.068-0.039,0.105-0.084,0.123-0.138c0.015-0.053,0.015-0.106,0-0.159c-0.018-0.038-0.031-0.084-0.055-0.115
               c-0.047-0.076-0.105-0.099-0.16-0.121c-0.037-0.016-0.074-0.031-0.09-0.076c-0.009-0.039,0-0.092,0.021-0.145
               c0.016-0.023,0.029-0.046,0.045-0.069c0.016-0.015,0.031-0.023,0.047-0.023s0.021,0,0.037,0.015
               c0.068,0.054,0.146,0.077,0.221,0.084c0.092,0.008,0.184-0.015,0.252-0.045c0.016-0.008,0.047-0.015,0.069-0.023
               c0.021-0.008,0.036-0.016,0.06-0.023c0.084-0.038,0.139-0.092,0.076-0.236c-0.031-0.061-0.061-0.107-0.091-0.145
               c-0.038-0.053-0.062-0.091-0.017-0.121c0.084-0.062,0.198-0.183,0.268-0.32c0.045-0.107,0.076-0.229,0.045-0.335
               c-0.016-0.069-0.021-0.122-0.029-0.16c-0.016-0.092-0.023-0.137-0.1-0.259c-0.045-0.084-0.121-0.129-0.191-0.175
               c-0.029-0.015-0.051-0.023-0.066-0.038c-0.037-0.031-0.053-0.054-0.03-0.092c0.022-0.022,0.038-0.045,0.054-0.068
               c0.076-0.122,0.137-0.198,0.364-0.274c0.16-0.054,0.237-0.13,0.298-0.198c0.038-0.038,0.068-0.076,0.122-0.076
               c0.062,0,0.136-0.015,0.213-0.038c0.13-0.031,0.272-0.061,0.388-0.039c0.106,0.031,0.207,0.016,0.291,0.008
               c0.082-0.008,0.158-0.015,0.235,0.008c0.144,0.038,0.365-0.06,0.472-0.122c0,0.007,0.008,0.015,0.008,0.015
               c0.047,0.054,0.1,0.084,0.168,0.084c0.045,0,0.1-0.015,0.145-0.023c0.063-0.007,0.107-0.023,0.115-0.014
               c0.021,0.014,0.061,0.029,0.122,0.044c0.129,0.031,0.343,0.092,0.364,0.191c0.031,0.106,0.092,0.16,0.137,0.205
               c0.023,0.031,0.047,0.054,0.063,0.077c0.008,0.023-0.009,0.045-0.016,0.076c-0.039,0.069-0.068,0.144,0.052,0.22
               c0.063,0.046,0.14,0.062,0.198,0.069c0.077,0.015,0.139,0.031,0.176,0.084c0.047,0.076,0.084,0.129,0.139,0.16
               c0.045,0.03,0.1,0.045,0.16,0.045c0.045,0.008,0.112,0.038,0.182,0.069c0.076,0.038,0.16,0.076,0.222,0.084
               C173.782,40.43,173.843,40.444,173.911,40.459z"/>
             <path fill="#808184" d="M187.921,53.693c-0.053-0.061-0.084-0.115-0.084-0.183
               c0-0.137-0.045-0.365-0.063-0.403c-0.014-0.031-0.137-0.457-0.228-0.586c-0.099-0.13-0.151-0.206-0.251-0.214
               c-0.107,0-0.297-0.114-0.305-0.023c-0.016,0.099-0.084,0.122-0.137,0.13c-0.055,0.015,0.105,0.228,0.105,0.342
               c0,0.115-0.007,0.221-0.115,0.229c-0.099,0.007-0.174-0.137-0.318-0.062c-0.145,0.069-0.137,0.13-0.282,0.184
               c-0.144,0.045-0.183,0.007-0.281,0.106c-0.106,0.091-0.121,0.084-0.196,0.076c-0.031-0.007-0.076,0.015-0.131,0.039
               l-0.016-0.016c-0.038-0.045-0.084-0.099-0.09-0.251c0-0.069-0.01-0.122-0.01-0.167c0-0.176,0-0.214-0.09-0.351
               c-0.039-0.053-0.063-0.114-0.076-0.168c-0.031-0.091-0.031-0.174-0.031-0.205c0-0.008,0-0.022,0.008-0.038
               c0.031-0.091,0.066-0.213-0.053-0.289c-0.047-0.023-0.1-0.039-0.146-0.054c-0.053-0.015-0.1-0.03-0.1-0.053
               c0-0.045-0.022-0.122-0.046-0.213c-0.03-0.107-0.068-0.236-0.047-0.259c0.039-0.046,0.063-0.099,0.063-0.16
               c0-0.054-0.016-0.114-0.053-0.175c-0.038-0.061-0.1-0.084-0.167-0.114c-0.069-0.023-0.146-0.054-0.199-0.129
               c-0.062-0.084-0.106-0.16-0.13-0.229c-0.008-0.038-0.008-0.077,0.008-0.091c0.03-0.023,0.055-0.053,0.084-0.092
               c0.068-0.083,0.16-0.19,0.26-0.221c0.062-0.014,0.121-0.038,0.159-0.068c0.031-0.016,0.054-0.038,0.062-0.069
               c0.008-0.03,0.008-0.06-0.016-0.091c-0.015-0.031-0.046-0.053-0.092-0.084c-0.076-0.046-0.152-0.068-0.229-0.099
               c-0.101-0.038-0.19-0.068-0.267-0.144c-0.017-0.023-0.038-0.039-0.054-0.061c-0.084-0.077-0.122-0.122-0.092-0.183
               c0.022-0.031,0.062-0.061,0.092-0.084c0.068-0.046,0.121-0.083,0.1-0.19c-0.009-0.046-0.031-0.084-0.046-0.13
               c-0.022-0.053-0.038-0.106-0.038-0.136v0.007c0.047,0.008,0.092,0.084,0.145,0.16c0.061,0.091,0.115,0.175,0.189,0.19
               c0.077,0.016,0.114-0.015,0.146-0.045c0.016-0.016,0.029-0.023,0.084,0.045c0.099,0.13,0.259,0.152,0.402,0.175
               c0.076,0.008,0.152,0.015,0.19,0.046c0.083,0.068,0.075,0.191,0.075,0.275v0.007c-0.008,0.114,0.031,0.282,0.031,0.282v0.007
               c0.061,0.205,0.176,0.198,0.334,0.19c0.093-0.007,0.207-0.014,0.328,0.038c0.113,0.054,0.312,0.031,0.502,0.008
               c0.139-0.015,0.273-0.031,0.365-0.023l0.047,0.008c0.189,0.03,0.272,0.046,0.396,0.091c0.03,0.016,0.053,0.023,0.077,0.03
               c0.037,0.016,0.068,0.024,0.084,0.039c0.021,0.007,0.029,0.03,0,0.076c-0.032,0.053-0.069,0.099-0.102,0.152
               c-0.061,0.084-0.113,0.167-0.145,0.251c-0.008,0.023-0.047,0.023-0.092,0.031c-0.068,0-0.151,0-0.189,0.084
               c-0.021,0.038-0.046,0.076-0.063,0.113c-0.029,0.07-0.06,0.138-0.129,0.237c-0.099,0.137-0.099,0.137-0.076,0.259
               c0.009,0.03,0.017,0.06,0.022,0.099c0,0.038,0.009,0.068,0.015,0.099c0.011,0.084,0.018,0.175,0.039,0.236
               c0.045,0.114,0.106,0.183,0.244,0.137c0.145-0.054,0.182-0.122,0.221-0.19c0.022-0.031,0.037-0.062,0.07-0.092
               c0.051-0.045,0.082-0.115,0.104-0.175c0.022-0.046,0.038-0.092,0.063-0.092c0.021,0,0.045,0.016,0.065,0.046
               c0.047,0.046,0.084,0.115,0.099,0.152c0.008,0.016,0.023,0.077,0.047,0.161c0.053,0.213,0.137,0.547,0.221,0.707
               c0.016,0.024,0.037,0.061,0.055,0.084c0.066,0.137,0.092,0.175,0.075,0.282c-0.017,0.084-0.022,0.16-0.022,0.236
               c0.008,0.068,0.016,0.145,0.03,0.221c0.009,0.045,0.022,0.084,0.039,0.114c0.015,0.038,0.021,0.069,0.021,0.092h-0.035h-0.069
               C188.134,53.564,187.968,53.556,187.921,53.693"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M187.921,53.693c-0.053-0.061-0.084-0.115-0.084-0.183c0-0.137-0.045-0.365-0.063-0.403c-0.014-0.031-0.137-0.457-0.228-0.586
               c-0.099-0.13-0.151-0.206-0.251-0.214c-0.107,0-0.297-0.114-0.305-0.023c-0.016,0.099-0.084,0.122-0.137,0.13
               c-0.055,0.015,0.105,0.228,0.105,0.342c0,0.115-0.007,0.221-0.115,0.229c-0.099,0.007-0.174-0.137-0.318-0.062
               c-0.145,0.069-0.137,0.13-0.282,0.184c-0.144,0.045-0.183,0.007-0.281,0.106c-0.106,0.091-0.121,0.084-0.196,0.076
               c-0.031-0.007-0.076,0.015-0.131,0.039l-0.016-0.016c-0.038-0.045-0.084-0.099-0.09-0.251c0-0.069-0.01-0.122-0.01-0.167
               c0-0.176,0-0.214-0.09-0.351c-0.039-0.053-0.063-0.114-0.076-0.168c-0.031-0.091-0.031-0.174-0.031-0.205
               c0-0.008,0-0.022,0.008-0.038c0.031-0.091,0.066-0.213-0.053-0.289c-0.047-0.023-0.1-0.039-0.146-0.054
               c-0.053-0.015-0.1-0.03-0.1-0.053c0-0.045-0.022-0.122-0.046-0.213c-0.03-0.107-0.068-0.236-0.047-0.259
               c0.039-0.046,0.063-0.099,0.063-0.16c0-0.054-0.016-0.114-0.053-0.175c-0.038-0.061-0.1-0.084-0.167-0.114
               c-0.069-0.023-0.146-0.054-0.199-0.129c-0.062-0.084-0.106-0.16-0.13-0.229c-0.008-0.038-0.008-0.077,0.008-0.091
               c0.03-0.023,0.055-0.053,0.084-0.092c0.068-0.083,0.16-0.19,0.26-0.221c0.062-0.014,0.121-0.038,0.159-0.068
               c0.031-0.016,0.054-0.038,0.062-0.069c0.008-0.03,0.008-0.06-0.016-0.091c-0.015-0.031-0.046-0.053-0.092-0.084
               c-0.076-0.046-0.152-0.068-0.229-0.099c-0.101-0.038-0.19-0.068-0.267-0.144c-0.017-0.023-0.038-0.039-0.054-0.061
               c-0.084-0.077-0.122-0.122-0.092-0.183c0.022-0.031,0.062-0.061,0.092-0.084c0.068-0.046,0.121-0.083,0.1-0.19
               c-0.009-0.046-0.031-0.084-0.046-0.13c-0.022-0.053-0.038-0.106-0.038-0.136v0.007c0.047,0.008,0.092,0.084,0.145,0.16
               c0.061,0.091,0.115,0.175,0.189,0.19c0.077,0.016,0.114-0.015,0.146-0.045c0.016-0.016,0.029-0.023,0.084,0.045
               c0.099,0.13,0.259,0.152,0.402,0.175c0.076,0.008,0.152,0.015,0.19,0.046c0.083,0.068,0.075,0.191,0.075,0.275v0.007
               c-0.008,0.114,0.031,0.282,0.031,0.282v0.007c0.061,0.205,0.176,0.198,0.334,0.19c0.093-0.007,0.207-0.014,0.328,0.038
               c0.113,0.054,0.312,0.031,0.502,0.008c0.139-0.015,0.273-0.031,0.365-0.023l0.047,0.008c0.189,0.03,0.272,0.046,0.396,0.091
               c0.03,0.016,0.053,0.023,0.077,0.03c0.037,0.016,0.068,0.024,0.084,0.039c0.021,0.007,0.029,0.03,0,0.076
               c-0.032,0.053-0.069,0.099-0.102,0.152c-0.061,0.084-0.113,0.167-0.145,0.251c-0.008,0.023-0.047,0.023-0.092,0.031
               c-0.068,0-0.151,0-0.189,0.084c-0.021,0.038-0.046,0.076-0.063,0.113c-0.029,0.07-0.06,0.138-0.129,0.237
               c-0.099,0.137-0.099,0.137-0.076,0.259c0.009,0.03,0.017,0.06,0.022,0.099c0,0.038,0.009,0.068,0.015,0.099
               c0.011,0.084,0.018,0.175,0.039,0.236c0.045,0.114,0.106,0.183,0.244,0.137c0.145-0.054,0.182-0.122,0.221-0.19
               c0.022-0.031,0.037-0.062,0.07-0.092c0.051-0.045,0.082-0.115,0.104-0.175c0.022-0.046,0.038-0.092,0.063-0.092
               c0.021,0,0.045,0.016,0.065,0.046c0.047,0.046,0.084,0.115,0.099,0.152c0.008,0.016,0.023,0.077,0.047,0.161
               c0.053,0.213,0.137,0.547,0.221,0.707c0.016,0.024,0.037,0.061,0.055,0.084c0.066,0.137,0.092,0.175,0.075,0.282
               c-0.017,0.084-0.022,0.16-0.022,0.236c0.008,0.068,0.016,0.145,0.03,0.221c0.009,0.045,0.022,0.084,0.039,0.114
               c0.015,0.038,0.021,0.069,0.021,0.092h-0.035h-0.069C188.134,53.564,187.968,53.556,187.921,53.693z"/>
             <path fill="#808184" d="M177.726,45.348c0.008,0.007,0.017,0.007,0.022,0.015
               c0.077,0.03,0.198-0.008,0.328-0.054c0.092-0.03,0.19-0.06,0.274-0.075c0.129-0.016,0.174,0.015,0.221,0.045
               c0.015,0.015,0.037,0.03,0.068,0.046c0.036,0.016,0.075,0.03,0.113,0.053c0.031,0.031,0.075,0.069,0.121,0.13
               c0.055,0.068,0.176,0.106,0.313,0.144c0.19,0.053,0.428,0.122,0.533,0.289c0.03,0.053,0.063,0.099,0.084,0.145
               c0.122,0.199,0.146,0.237,0.268,0.251c0.054,0.008,0.113-0.023,0.183-0.052c0.099-0.046,0.205-0.092,0.235-0.023
               c0.023,0.061,0.023,0.114,0.023,0.151c0,0.099-0.009,0.168,0.159,0.214c0.114,0.03,0.198,0.083,0.274,0.137
               c0.046,0.03,0.099,0.061,0.15,0.084c0.092,0.045,0.16,0.015,0.213-0.016c0.039-0.015,0.069-0.023,0.1,0.008
               c0.017,0.015-0.008,0.053-0.021,0.092c-0.016,0.03-0.031,0.053-0.037,0.083c-0.023,0.076,0,0.145,0.15,0.183
               c0.13,0.038,0.236,0.03,0.32,0.023c0.092-0.016,0.15-0.023,0.189,0.045c0.022,0.039,0.045,0.092,0.069,0.145
               c0.037,0.084,0.067,0.167,0.121,0.213c0.062,0.038,0.129,0.031,0.205-0.068c0.054-0.069,0.075-0.129,0.099-0.176
               c0.03-0.075,0.047-0.098,0.223-0.014c0.113,0.061,0.281,0.098,0.449,0.129c0.25,0.045,0.502,0.068,0.615,0.076
               c0.016,0.114,0.053,0.457,0.063,0.594c0.008,0.099,0.067,0.183,0.129,0.259c0.055,0.076,0.106,0.144,0.121,0.22
               c0.018,0.1-0.037,0.122-0.084,0.145c-0.021,0.016-0.045,0.023-0.067,0.038c-0.015,0.016-0.046,0.016-0.083,0.008
               c-0.046-0.008-0.107-0.023-0.198,0c-0.075,0.023-0.099,0-0.146-0.046c-0.031-0.023-0.063-0.053-0.115-0.091
               c-0.112-0.084-0.266-0.046-0.41-0.008c-0.083,0.015-0.167,0.038-0.213,0.031c-0.061-0.016-0.105-0.023-0.15-0.039
               c-0.076-0.015-0.146-0.022-0.199-0.045c-0.037-0.007-0.09-0.046-0.152-0.084c-0.066-0.038-0.145-0.084-0.229-0.121
               c-0.105-0.054-0.16-0.039-0.229-0.016c-0.037,0.008-0.083,0.023-0.144,0.008c-0.139-0.031-0.236-0.16-0.404-0.389l-0.038-0.045
               c-0.13-0.167-0.281-0.145-0.486-0.106c-0.151,0.022-0.335,0.06-0.557,0.007c-0.251-0.061-0.351-0.068-0.418-0.068
               c-0.069-0.009-0.107-0.009-0.245-0.063c-0.137-0.06-0.184-0.114-0.229-0.159c-0.045-0.054-0.09-0.107-0.205-0.161
               c-0.099-0.038-0.151-0.053-0.197-0.061c-0.068-0.007-0.121-0.014-0.305-0.121c-0.244-0.153-0.252-0.153-0.32-0.168
               c-0.021-0.007-0.045-0.015-0.092-0.03c-0.066-0.023-0.092-0.076-0.113-0.122c-0.03-0.076-0.068-0.145-0.197-0.16
               c-0.092-0.015-0.176-0.038-0.26-0.077c-0.076-0.029-0.145-0.083-0.213-0.167c-0.061-0.076-0.055-0.083-0.047-0.091
               c0.031-0.023,0.062-0.053,0.055-0.121c-0.008-0.1,0.016-0.161,0.045-0.245l0.007-0.015c0.032-0.091,0.024-0.182,0.011-0.319
               l-0.011-0.054c0-0.06,0.017-0.068,0.04-0.076c0.037-0.015,0.083-0.03,0.128-0.091C177.688,45.454,177.711,45.393,177.726,45.348
               "/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M177.726,45.348c0.008,0.007,0.017,0.007,0.022,0.015c0.077,0.03,0.198-0.008,0.328-0.054c0.092-0.03,0.19-0.06,0.274-0.075
               c0.129-0.016,0.174,0.015,0.221,0.045c0.015,0.015,0.037,0.03,0.068,0.046c0.036,0.016,0.075,0.03,0.113,0.053
               c0.031,0.031,0.075,0.069,0.121,0.13c0.055,0.068,0.176,0.106,0.313,0.144c0.19,0.053,0.428,0.122,0.533,0.289
               c0.03,0.053,0.063,0.099,0.084,0.145c0.122,0.199,0.146,0.237,0.268,0.251c0.054,0.008,0.113-0.023,0.183-0.052
               c0.099-0.046,0.205-0.092,0.235-0.023c0.023,0.061,0.023,0.114,0.023,0.151c0,0.099-0.009,0.168,0.159,0.214
               c0.114,0.03,0.198,0.083,0.274,0.137c0.046,0.03,0.099,0.061,0.15,0.084c0.092,0.045,0.16,0.015,0.213-0.016
               c0.039-0.015,0.069-0.023,0.1,0.008c0.017,0.015-0.008,0.053-0.021,0.092c-0.016,0.03-0.031,0.053-0.037,0.083
               c-0.023,0.076,0,0.145,0.15,0.183c0.13,0.038,0.236,0.03,0.32,0.023c0.092-0.016,0.15-0.023,0.189,0.045
               c0.022,0.039,0.045,0.092,0.069,0.145c0.037,0.084,0.067,0.167,0.121,0.213c0.062,0.038,0.129,0.031,0.205-0.068
               c0.054-0.069,0.075-0.129,0.099-0.176c0.03-0.075,0.047-0.098,0.223-0.014c0.113,0.061,0.281,0.098,0.449,0.129
               c0.25,0.045,0.502,0.068,0.615,0.076c0.016,0.114,0.053,0.457,0.063,0.594c0.008,0.099,0.067,0.183,0.129,0.259
               c0.055,0.076,0.106,0.144,0.121,0.22c0.018,0.1-0.037,0.122-0.084,0.145c-0.021,0.016-0.045,0.023-0.067,0.038
               c-0.015,0.016-0.046,0.016-0.083,0.008c-0.046-0.008-0.107-0.023-0.198,0c-0.075,0.023-0.099,0-0.146-0.046
               c-0.031-0.023-0.063-0.053-0.115-0.091c-0.112-0.084-0.266-0.046-0.41-0.008c-0.083,0.015-0.167,0.038-0.213,0.031
               c-0.061-0.016-0.105-0.023-0.15-0.039c-0.076-0.015-0.146-0.022-0.199-0.045c-0.037-0.007-0.09-0.046-0.152-0.084
               c-0.066-0.038-0.145-0.084-0.229-0.121c-0.105-0.054-0.16-0.039-0.229-0.016c-0.037,0.008-0.083,0.023-0.144,0.008
               c-0.139-0.031-0.236-0.16-0.404-0.389l-0.038-0.045c-0.13-0.167-0.281-0.145-0.486-0.106c-0.151,0.022-0.335,0.06-0.557,0.007
               c-0.251-0.061-0.351-0.068-0.418-0.068c-0.069-0.009-0.107-0.009-0.245-0.063c-0.137-0.06-0.184-0.114-0.229-0.159
               c-0.045-0.054-0.09-0.107-0.205-0.161c-0.099-0.038-0.151-0.053-0.197-0.061c-0.068-0.007-0.121-0.014-0.305-0.121
               c-0.244-0.153-0.252-0.153-0.32-0.168c-0.021-0.007-0.045-0.015-0.092-0.03c-0.066-0.023-0.092-0.076-0.113-0.122
               c-0.03-0.076-0.068-0.145-0.197-0.16c-0.092-0.015-0.176-0.038-0.26-0.077c-0.076-0.029-0.145-0.083-0.213-0.167
               c-0.061-0.076-0.055-0.083-0.047-0.091c0.031-0.023,0.062-0.053,0.055-0.121c-0.008-0.1,0.016-0.161,0.045-0.245l0.007-0.015
               c0.032-0.091,0.024-0.182,0.011-0.319l-0.011-0.054c0-0.06,0.017-0.068,0.04-0.076c0.037-0.015,0.083-0.03,0.128-0.091
               C177.688,45.454,177.711,45.393,177.726,45.348z"/>
             <path fill="#808184" d="M188.438,52.81c-0.006-0.076-0.039-0.129-0.09-0.236
               c-0.018-0.023-0.033-0.046-0.057-0.091c-0.075-0.145-0.166-0.472-0.219-0.678c-0.023-0.092-0.039-0.152-0.047-0.175
               c-0.016-0.046-0.061-0.13-0.113-0.183c-0.039-0.046-0.092-0.076-0.145-0.076c-0.094,0-0.123,0.068-0.152,0.152
               c-0.024,0.054-0.047,0.107-0.077,0.137c-0.046,0.038-0.067,0.077-0.091,0.114c-0.031,0.054-0.061,0.107-0.168,0.145
               c-0.061,0.023-0.092-0.016-0.114-0.068c-0.022-0.062-0.028-0.138-0.037-0.213c-0.009-0.039-0.009-0.069-0.017-0.107
               c-0.008-0.046-0.016-0.076-0.022-0.107c-0.016-0.075-0.016-0.075,0.062-0.183c0.07-0.099,0.106-0.174,0.138-0.251
               c0.022-0.031,0.037-0.069,0.054-0.106c0.016-0.031,0.067-0.031,0.113-0.031c0.084,0,0.161-0.007,0.184-0.099
               c0.022-0.068,0.076-0.144,0.131-0.228c0.037-0.046,0.065-0.099,0.104-0.153c0.067-0.122,0.039-0.175-0.039-0.22
               c-0.028-0.015-0.06-0.031-0.099-0.046c-0.029-0.008-0.053-0.015-0.075-0.023c-0.129-0.053-0.222-0.068-0.419-0.099l-0.047-0.008
               c-0.105-0.015-0.241,0-0.389,0.023c-0.182,0.023-0.365,0.039-0.455,0c-0.138-0.068-0.268-0.053-0.373-0.053
               c-0.106,0.008-0.19,0.015-0.229-0.106c0-0.024-0.037-0.176-0.03-0.259v-0.008c0.01-0.115,0.018-0.259-0.113-0.358
               c-0.053-0.046-0.146-0.061-0.235-0.076c-0.12-0.016-0.267-0.038-0.335-0.129c-0.121-0.16-0.167-0.115-0.235-0.062
               c-0.015,0.016-0.03,0.031-0.054,0.023c-0.038-0.007-0.084-0.076-0.129-0.144c-0.063-0.099-0.131-0.198-0.223-0.206
               c-0.074-0.007-0.105,0.031-0.105,0.084c-0.008,0.053,0.016,0.114,0.045,0.183c0.018,0.038,0.031,0.083,0.039,0.114
               c0.008,0.038-0.021,0.061-0.063,0.092c-0.037,0.022-0.082,0.053-0.113,0.106c-0.074,0.13-0.016,0.191,0.1,0.312
               c0.016,0.015,0.037,0.038,0.061,0.054c0.084,0.091,0.19,0.129,0.306,0.174c0.069,0.024,0.138,0.046,0.206,0.091
               c0.029,0.017,0.046,0.032,0.061,0.046v0.009c0,0.007-0.008,0.014-0.015,0.022c-0.03,0.015-0.078,0.038-0.138,0.046
               c-0.129,0.038-0.229,0.159-0.305,0.251c-0.031,0.038-0.055,0.068-0.076,0.083c-0.053,0.046-0.063,0.123-0.039,0.198
               c0.023,0.077,0.078,0.168,0.146,0.26c0.076,0.099,0.168,0.137,0.244,0.167c0.054,0.023,0.099,0.038,0.122,0.069
               c0.021,0.045,0.038,0.084,0.038,0.121c0,0.031-0.017,0.061-0.038,0.092c-0.063,0.061-0.016,0.22,0.021,0.358
               c0.023,0.076,0.045,0.144,0.045,0.175c-0.006,0.106,0.069,0.129,0.168,0.16c0.047,0.007,0.084,0.023,0.123,0.046
               c0.053,0.03,0.03,0.106,0.008,0.167c-0.008,0.023-0.017,0.046-0.017,0.068c0,0.038,0.009,0.13,0.039,0.229
               c0.015,0.068,0.046,0.137,0.093,0.198c0.073,0.114,0.073,0.144,0.073,0.297v0.167c0.009,0.183,0.062,0.251,0.114,0.312
               c-0.021,0.008-0.045,0.015-0.066,0.023c-0.115,0.031-0.244,0.122-0.298-0.045c-0.046-0.168-0.101-0.221-0.153-0.122
               c-0.045,0.091-0.104,0.129-0.174,0.114c-0.068-0.023-0.113-0.13-0.152-0.13c-0.03,0-0.129,0.038-0.19,0.107
               c-0.067,0.061-0.335,0.137-0.463,0.221c-0.131,0.083-0.291,0.122-0.246,0.305c0.047,0.182,0.146,0.159,0.146,0.311
               c0.008,0.16-0.137,0.183-0.184,0.32c-0.045,0.145,0.115,0.176-0.008,0.274c-0.129,0.092-0.045,0.206-0.41,0.32
               c-0.357,0.114-0.775,0.312-0.859,0.465c-0.092,0.145-0.153,0.518-0.252,0.617c-0.1,0.098-0.381,0.426-0.519,0.494
               c-0.13,0.069-0.495,0.244-0.503,0.389c-0.016,0.152-0.426,0.403-0.518,0.51c-0.1,0.106-0.129,0.228-0.113,0.366
               c0.015,0.137-0.032,0.228-0.1,0.281c-0.068,0.054-0.146,0.069-0.328,0.099c-0.183,0.039-0.334,0.061-0.381,0.152
               c-0.054,0.099-0.1,0.335-0.236,0.359c-0.129,0.022-0.389-0.077-0.479,0.128c-0.099,0.199-0.16,0.693-0.099,0.861
               c0.054,0.16,0.151,0.19,0.084,0.366c-0.069,0.167,0.021,0.357,0.021,0.357s0.199,0.213,0.084,0.259
               c-0.104,0.046-0.113,0.16-0.021,0.198c0.099,0.031,0.159,0.038,0.159,0.16s0.053,0.251-0.008,0.411
               c-0.062,0.161-0.062,0.495-0.184,0.701c-0.122,0.197-0.184,0.266-0.167,0.411c0.007,0.137-0.032,0.297,0.062,0.426
               c0.092,0.122,0.151,0.488,0.008,0.678c-0.146,0.19-0.236,0.236-0.328,0.381s-0.305,0.352-0.281,0.488
               c0.021,0.136,0.055,0.188,0.244,0.256c0.197,0.07,0.105,0.215-0.077,0.146c-0.175-0.068-0.325-0.053-0.418,0.023
               c-0.084,0.074-0.389,0.099-0.389,0.335c0,0.235-0.085,0.342-0.214,0.465c-0.137,0.122-0.494,0.351-0.625,0.075
               c-0.121-0.266-0.715-0.54-0.807-1.104c-0.091-0.557-0.099-1.038-0.197-1.235c-0.106-0.205-0.16-0.381-0.365-0.73
               c-0.197-0.35-0.389-0.54-0.525-0.8c-0.137-0.257-0.557-1.766-0.799-2.214c-0.252-0.45-0.404-0.61-0.762-1.417
               c-0.365-0.807-0.815-2.474-0.815-2.764c0-0.297-0.138-1.31-0.138-1.53c0-0.229-0.342-0.533-0.258-0.723
               c0.074-0.191-0.016-0.138-0.055-0.245c-0.029-0.106,0-0.167-0.021-0.235c-0.022-0.076-0.161-0.175-0.214-0.084
               c-0.055,0.084-0.023,0.122-0.055,0.221c-0.037,0.098,0.069,0.091,0.069,0.228c0.007,0.145-0.077,0.115-0.092,0.259
               c-0.015,0.137,0.008,0.206-0.123,0.282c-0.129,0.068-0.433,0.343-0.737,0.381c-0.313,0.029-0.519-0.138-0.662-0.32
               c-0.152-0.191-0.617-0.632-0.771-0.792c-0.149-0.152-0.456-0.564-0.312-0.548c0.146,0.015,0.068,0.16,0.252,0.137
               c0.183-0.023,0.183-0.168,0.295-0.153c0.123,0.023,0.391-0.084,0.436-0.129c0.046-0.054,0.152-0.343-0.008-0.304
               c-0.151,0.03-0.221,0.137-0.403,0.144c-0.183,0.008-0.176,0.03-0.336-0.053c-0.166-0.084-0.28,0.114-0.38-0.084
               c-0.092-0.198-0.251-0.297-0.327-0.395c-0.084-0.1-0.146-0.191-0.244-0.214c-0.067-0.023-0.099,0.007-0.146-0.023
               c0.039-0.114,0.101-0.228,0.207-0.266c0.105-0.031,0.183-0.022,0.234-0.015c0.102,0.007,0.146,0.007,0.16-0.107
               c0.016-0.069-0.015-0.129-0.045-0.176c-0.016-0.029-0.023-0.045-0.023-0.053c0-0.008,0.017,0,0.076,0
               c0.092,0,0.168-0.008,0.229-0.015c0.113-0.015,0.189-0.022,0.289,0.039c0.037,0.022,0.068,0.045,0.099,0.067
               c0.114,0.077,0.177,0.122,0.282,0.024c0.029-0.024,0.053-0.046,0.084-0.077c0.084-0.084,0.184-0.182,0.26-0.144
               c0.037,0.023,0.045,0.053,0.053,0.076c0,0.008,0.008,0.015,0.008,0.022c0.021,0.061,0.054,0.084,0.168,0.046
               c0.182-0.068,0.252-0.251,0.289-0.335v-0.007c0.029-0.077-0.022-0.168-0.107-0.297c-0.021-0.054-0.061-0.114-0.091-0.175
               c-0.054-0.099-0.099-0.198-0.136-0.282c-0.055-0.114-0.092-0.206-0.139-0.267c-0.045-0.075-0.129-0.084-0.213-0.084
               c-0.068-0.007-0.146-0.007-0.184-0.06c-0.029-0.046-0.016-0.145-0.008-0.259c0.016-0.191,0.037-0.396-0.123-0.464
               c-0.121-0.054-0.197-0.054-0.265-0.046c-0.055,0-0.1,0-0.146-0.038c-0.045-0.038-0.084-0.053-0.121-0.077
               c-0.047-0.022-0.083-0.038-0.038-0.144c0.015-0.046,0.03-0.084,0.046-0.122c0.045-0.145,0.092-0.251,0.25-0.464
               c0.094-0.13,0.146-0.229,0.175-0.305c0.039-0.076,0.063-0.114,0.108-0.129h0.015c0.038-0.008,0.068,0.015,0.093,0.038v0.007
               c0.029,0.031,0.045,0.069,0.053,0.107c0.008,0.008,0.008,0.015,0.008,0.023c0,0.038,0.023,0.084,0.046,0.122
               c0.015,0.015,0.038,0.038,0.06,0.046c0.024,0.015,0.055,0.015,0.084,0.015c0.032-0.008,0.063-0.015,0.101-0.046
               c0.046-0.038,0.083-0.076,0.114-0.122c0.037-0.045,0.067-0.076,0.1-0.068c0.061,0.015,0.167,0.03,0.281,0.007
               c0.076-0.007,0.15-0.045,0.214-0.107c0.075-0.084,0.099-0.151,0.112-0.213c0.017-0.045,0.031-0.084,0.063-0.114
               c0.053-0.046,0.061-0.106,0.061-0.175c0-0.077,0.008-0.16,0.106-0.198c0.085-0.03,0.146-0.038,0.19-0.046
               c0.105-0.007,0.145-0.007,0.151-0.144c0.009-0.114,0.076-0.267,0.146-0.427c0.021-0.053,0.046-0.107,0.061-0.16
               c0.047-0.099,0.047-0.182,0.055-0.243c0-0.053,0.008-0.092,0.037-0.107c0.031-0.015,0.068-0.023,0.115-0.03
               c0.021-0.008,0.053-0.008,0.075-0.016c0.114-0.03,0.196-0.083,0.167-0.22l-0.016-0.046c-0.029-0.114-0.037-0.16,0-0.19
               c0.045-0.031,0.146-0.137,0.221-0.275c0.054-0.098,0.099-0.205,0.123-0.319c0.021-0.152-0.008-0.244-0.024-0.312
               c-0.015-0.046-0.021-0.084-0.007-0.115c0.023-0.068-0.008-0.122-0.038-0.174c0-0.016-0.007-0.023-0.015-0.031
               c-0.016-0.031-0.016-0.053,0.023-0.076c0.074-0.039,0.182-0.107,0.258-0.183c0.045-0.046,0.084-0.099,0.106-0.153
               c0.021-0.06,0.021-0.121-0.015-0.175c-0.016-0.015-0.039-0.037-0.069-0.053c-0.122-0.076-0.228-0.053-0.319-0.03
               c-0.054,0.015-0.099,0.03-0.123-0.023c-0.016-0.038-0.021-0.076-0.037-0.114c-0.062-0.153-0.105-0.274-0.244-0.297
               c-0.084-0.015-0.15-0.221-0.204-0.388c-0.017-0.039-0.022-0.077-0.03-0.092c-0.047-0.122-0.123-0.152-0.189-0.174
               c-0.031-0.009-0.055-0.023-0.039-0.055c0.008-0.045,0.054-0.083,0.092-0.113c0.031-0.023,0.054-0.046,0.068-0.077
               c0.016-0.053-0.008-0.091-0.106-0.13c-0.084-0.037-0.129-0.06-0.146-0.091c-0.017-0.03-0.008-0.061,0.023-0.105
               c0.029-0.069,0.021-0.16,0.007-0.251c-0.015-0.077-0.022-0.145-0.007-0.183c0-0.015,0.029-0.023,0.084-0.008
               c0.113,0.039,0.197,0.076,0.266,0.107c0.131,0.061,0.215,0.098,0.35,0.091c0.078-0.007,0.115,0.015,0.154,0.046
               c0.053,0.045,0.105,0.091,0.234,0.084c0.13,0,0.336-0.069,0.525-0.16c0.184-0.092,0.351-0.198,0.441-0.304
               c0.1-0.115,0.121-0.183,0.129-0.229c0.008-0.023,0.016-0.038,0.063-0.061c0.066-0.038,0.158-0.083,0.234-0.115
               c0.092,0.024,0.19,0.039,0.252,0.032c0.092-0.023,0.145-0.107,0.199-0.198c0.036-0.07,0.082-0.138,0.129-0.161
               c0.084-0.046,0.267-0.137,0.448-0.175c0.099-0.023,0.198-0.023,0.259,0.008c0.1,0.061,0.123,0.091,0.145,0.121
               c0.023,0.039,0.047,0.069,0.123,0.123c0.092,0.06,0.144,0.06,0.205,0.06c0.046,0,0.092,0,0.168,0.054
               c0.082,0.053,0.158,0.061,0.221,0.061c0.016,0,0.029,0.007,0.046,0.007c0.009,0,0.009,0.008,0,0.054
               c-0.017,0.068-0.038,0.106-0.062,0.144c-0.029,0.069-0.055,0.114-0.016,0.236c0.03,0.068,0.045,0.13,0.045,0.175
               c-0.007,0.046-0.021,0.084-0.076,0.138c-0.045,0.038-0.084,0.053-0.121,0.06c-0.075,0.023-0.129,0.046-0.159,0.168
               c-0.009,0.053-0.015,0.106-0.015,0.16c0,0.084-0.01,0.144-0.047,0.152h-0.022c-0.138,0-0.251,0.007-0.335,0.106v0.008
               c-0.047,0.054-0.055,0.16-0.031,0.274c0.018,0.107,0.068,0.214,0.146,0.282l0.015,0.008v0.008
               c0.069,0.052,0.153,0.067,0.229,0.083c0.075,0.015,0.15,0.03,0.158,0.091c0.008,0.1,0.055,0.168,0.084,0.228
               c0.031,0.054,0.061,0.107,0.061,0.153c0,0.031,0,0.053,0.01,0.084c0,0.023,0.007,0.046,0.007,0.068
               c0,0.039-0.022,0.068-0.121,0.068c-0.106,0-0.183-0.029-0.245-0.06c-0.091-0.03-0.15-0.061-0.234-0.015l-0.031,0.022
               c-0.053,0.024-0.105,0.062-0.145,0.099c-0.076,0.084-0.09,0.183,0.067,0.328c0.19,0.168,0.185,0.29,0.185,0.426v0.038
               c0,0.099,0.008,0.176,0.039,0.236c0.045,0.069,0.104,0.107,0.219,0.099c0.1,0.008,0.268,0.069,0.428,0.153
               c0.184,0.084,0.35,0.183,0.441,0.258c0.1,0.092,0.25,0.084,0.389,0.084c0.113,0,0.205,0,0.213,0.054
               c0.008,0.083,0.055,0.167,0.121,0.243c0.055,0.053,0.123,0.107,0.176,0.144c0,0.032-0.016,0.092-0.076,0.168
               c-0.021,0.038-0.061,0.046-0.085,0.061c-0.067,0.023-0.112,0.038-0.099,0.176l0.008,0.06c0.018,0.122,0.023,0.198-0.008,0.274
               l-0.008,0.015c-0.029,0.092-0.061,0.168-0.045,0.283c0,0.015-0.008,0.03-0.022,0.038c-0.045,0.045-0.093,0.084,0.03,0.228
               c0.084,0.099,0.168,0.16,0.259,0.206c0.084,0.038,0.183,0.061,0.282,0.075c0.074,0.008,0.099,0.055,0.121,0.107
               c0.029,0.069,0.062,0.137,0.168,0.168c0.045,0.022,0.074,0.03,0.1,0.03c0.061,0.023,0.066,0.023,0.296,0.16
               c0.198,0.122,0.269,0.13,0.337,0.146c0.045,0,0.09,0.008,0.174,0.045c0.099,0.046,0.137,0.092,0.176,0.137
               c0.054,0.054,0.105,0.115,0.267,0.183c0.151,0.061,0.19,0.061,0.274,0.068c0.06,0.009,0.158,0.016,0.402,0.069
               c0.244,0.053,0.441,0.023,0.602-0.007c0.169-0.031,0.289-0.053,0.381,0.068l0.038,0.046c0.184,0.244,0.29,0.396,0.465,0.427
               c0.092,0.015,0.146,0,0.198-0.009c0.046-0.015,0.082-0.03,0.16,0.009c0.076,0.03,0.151,0.075,0.213,0.121
               c0.068,0.038,0.121,0.077,0.175,0.092c0.054,0.014,0.123,0.03,0.206,0.045c0.046,0.015,0.099,0.024,0.15,0.038
               c0.068,0.015,0.162-0.007,0.26-0.031c0.121-0.03,0.259-0.06,0.328-0.007c0.045,0.031,0.084,0.061,0.105,0.084
               c0.068,0.069,0.107,0.099,0.236,0.069c0.068-0.016,0.121-0.008,0.16,0c0.061,0.007,0.104,0.022,0.158-0.023
               c0.016-0.008,0.039-0.023,0.055-0.031c0.084-0.038,0.16-0.084,0.138-0.251c-0.017-0.099-0.077-0.183-0.138-0.267
               c-0.055-0.069-0.107-0.137-0.113-0.205c-0.008-0.145-0.047-0.488-0.055-0.602c0.016-0.015,0.039-0.038,0.061-0.068
               c0.031-0.023,0.063-0.062,0.094-0.092c0.068-0.061,0.158-0.107,0.242-0.007c0.189,0.206,0.184,0.266,0.184,0.403v0.069
               c0,0.053-0.008,0.107-0.017,0.152c-0.028,0.145-0.046,0.259,0.129,0.35c0.13,0.077,0.161,0.084,0.222,0.099
               c0.045,0.008,0.101,0.023,0.213,0.069l0.093,0.03c0.229,0.084,0.342,0.122,0.479,0.054c0.047-0.023,0.082-0.054,0.113-0.084
               c0.055-0.046,0.107-0.084,0.199-0.038c0.09,0.046,0.204,0.068,0.326,0.083c0.152,0.016,0.312,0.016,0.457,0
               c0.062-0.007,0.121-0.007,0.182-0.007c0.084,0,0.169,0,0.236-0.015c0.1-0.03,0.16-0.092,0.123-0.237
               c-0.047-0.159-0.131-0.228-0.215-0.289c-0.045-0.038-0.099-0.076-0.137-0.136c-0.029-0.054-0.046-0.107-0.046-0.153
               c0-0.023,0.007-0.038,0.017-0.053c0.008-0.016,0.016-0.023,0.021-0.031c0.017-0.007,0.031-0.015,0.047-0.015
               c0.084,0,0.243-0.03,0.382-0.099c0.098-0.045,0.196-0.114,0.25-0.206c0.022-0.038,0.037-0.076,0.063-0.106
               c0.061-0.13,0.113-0.221,0.219-0.229c0.107-0.007,0.162-0.067,0.229-0.144c0.063-0.069,0.131-0.153,0.252-0.198
               c0.183-0.069,0.252-0.214,0.305-0.305c0.018-0.038,0.018-0.061,0.023-0.061c0.037,0,0.067-0.023,0.1-0.053
               c0.037-0.038,0.09-0.076,0.158-0.015c0.031,0.023,0.062,0.045,0.093,0.068c0.091,0.054,0.198,0.107,0.297,0.129
               c0.114,0.016,0.222,0,0.289-0.083c0.008-0.007,0.015-0.015,0.015-0.023c0.063-0.091,0.129-0.191,0.191-0.236
               c0.054-0.046,0.105-0.053,0.158,0c0.084,0.076,0.084,0.122,0.076,0.183c0,0.046-0.008,0.099,0.018,0.159
               c0.021,0.084,0.066,0.1,0.12,0.084c0.022-0.007,0.038-0.015,0.063-0.029c0.045-0.023,0.113-0.055,0.174,0.022
               c0.061,0.084,0.021,0.205-0.008,0.305c-0.017,0.053-0.031,0.098-0.031,0.137c-0.006,0.076,0.023,0.121,0.115,0.129
               c0.092,0.007,0.25,0.023,0.411,0.038c0.091,0.015,0.183,0.023,0.259,0.038v0.077l-0.009,0.022
               c-0.007,0.077-0.022,0.221-0.114,0.252c-0.028,0.015-0.052,0.022-0.067,0.045c-0.03,0.03-0.045,0.069-0.037,0.114
               c0,0.039,0.015,0.076,0.037,0.123c0.039,0.053,0.099,0.114,0.183,0.159c0.032,0.023,0.047,0.046,0.055,0.061
               c0,0-0.008,0.007-0.008,0.015c-0.008,0.015-0.023,0.023-0.039,0.03c-0.045,0.024-0.121,0.039-0.189,0.008
               c-0.066-0.03-0.105-0.053-0.137-0.075c-0.063-0.039-0.1-0.062-0.213-0.055c-0.115,0.009-0.244,0.176-0.358,0.328
               c-0.067,0.092-0.13,0.175-0.167,0.198c-0.03,0.015-0.063,0.03-0.092,0.046c-0.145,0.053-0.313,0.122-0.305,0.426
               c0.008,0.16,0.022,0.275,0.037,0.366c0.016,0.114,0.03,0.198,0.016,0.297c-0.016,0.076-0.045,0.083-0.092,0.099
               c-0.066,0.015-0.15,0.038-0.242,0.144c-0.084,0.091-0.076,0.145-0.031,0.191c0.031,0.023,0.068,0.03,0.107,0.045
               c0.053,0.008,0.113,0.031,0.129,0.077c0.008,0.053,0,0.136-0.022,0.228c-0.039,0.16-0.112,0.328-0.13,0.373
               c-0.03,0.069-0.084,0.182-0.069,0.403c0,0.031,0,0.054-0.007,0.062c-0.008,0.015-0.008,0.015-0.016,0.015
               c-0.016,0.007-0.03,0-0.053-0.008c-0.047-0.015-0.107-0.053-0.168-0.099c-0.084-0.061-0.152-0.084-0.222-0.061
               c-0.062,0.023-0.114,0.084-0.188,0.182c-0.123,0.161-0.023,0.374,0.045,0.511c0.016,0.03,0.029,0.061,0.029,0.076
               c0,0.008,0,0.015,0.008,0.023c0.01,0.053,0.018,0.099-0.099,0.137c-0.159,0.053-0.13,0.168-0.101,0.289
               c0.018,0.054,0.033,0.115,0.025,0.161c-0.025,0.106,0.021,0.159,0.066,0.22c0.023,0.023,0.045,0.054,0.054,0.084l0.007,0.031
               c-0.021,0.007-0.053,0.015-0.074,0.052c-0.017,0.031-0.03,0.046-0.039,0.054c-0.014,0.007-0.03,0.007-0.062,0
               C188.53,52.855,188.47,52.825,188.438,52.81"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M188.438,52.81c-0.006-0.076-0.039-0.129-0.09-0.236c-0.018-0.023-0.033-0.046-0.057-0.091
               c-0.075-0.145-0.166-0.472-0.219-0.678c-0.023-0.092-0.039-0.152-0.047-0.175c-0.016-0.046-0.061-0.13-0.113-0.183
               c-0.039-0.046-0.092-0.076-0.145-0.076c-0.094,0-0.123,0.068-0.152,0.152c-0.024,0.054-0.047,0.107-0.077,0.137
               c-0.046,0.038-0.067,0.077-0.091,0.114c-0.031,0.054-0.061,0.107-0.168,0.145c-0.061,0.023-0.092-0.016-0.114-0.068
               c-0.022-0.062-0.028-0.138-0.037-0.213c-0.009-0.039-0.009-0.069-0.017-0.107c-0.008-0.046-0.016-0.076-0.022-0.107
               c-0.016-0.075-0.016-0.075,0.062-0.183c0.07-0.099,0.106-0.174,0.138-0.251c0.022-0.031,0.037-0.069,0.054-0.106
               c0.016-0.031,0.067-0.031,0.113-0.031c0.084,0,0.161-0.007,0.184-0.099c0.022-0.068,0.076-0.144,0.131-0.228
               c0.037-0.046,0.065-0.099,0.104-0.153c0.067-0.122,0.039-0.175-0.039-0.22c-0.028-0.015-0.06-0.031-0.099-0.046
               c-0.029-0.008-0.053-0.015-0.075-0.023c-0.129-0.053-0.222-0.068-0.419-0.099l-0.047-0.008c-0.105-0.015-0.241,0-0.389,0.023
               c-0.182,0.023-0.365,0.039-0.455,0c-0.138-0.068-0.268-0.053-0.373-0.053c-0.106,0.008-0.19,0.015-0.229-0.106
               c0-0.024-0.037-0.176-0.03-0.259v-0.008c0.01-0.115,0.018-0.259-0.113-0.358c-0.053-0.046-0.146-0.061-0.235-0.076
               c-0.12-0.016-0.267-0.038-0.335-0.129c-0.121-0.16-0.167-0.115-0.235-0.062c-0.015,0.016-0.03,0.031-0.054,0.023
               c-0.038-0.007-0.084-0.076-0.129-0.144c-0.063-0.099-0.131-0.198-0.223-0.206c-0.074-0.007-0.105,0.031-0.105,0.084
               c-0.008,0.053,0.016,0.114,0.045,0.183c0.018,0.038,0.031,0.083,0.039,0.114c0.008,0.038-0.021,0.061-0.063,0.092
               c-0.037,0.022-0.082,0.053-0.113,0.106c-0.074,0.13-0.016,0.191,0.1,0.312c0.016,0.015,0.037,0.038,0.061,0.054
               c0.084,0.091,0.19,0.129,0.306,0.174c0.069,0.024,0.138,0.046,0.206,0.091c0.029,0.017,0.046,0.032,0.061,0.046v0.009
               c0,0.007-0.008,0.014-0.015,0.022c-0.03,0.015-0.078,0.038-0.138,0.046c-0.129,0.038-0.229,0.159-0.305,0.251
               c-0.031,0.038-0.055,0.068-0.076,0.083c-0.053,0.046-0.063,0.123-0.039,0.198c0.023,0.077,0.078,0.168,0.146,0.26
               c0.076,0.099,0.168,0.137,0.244,0.167c0.054,0.023,0.099,0.038,0.122,0.069c0.021,0.045,0.038,0.084,0.038,0.121
               c0,0.031-0.017,0.061-0.038,0.092c-0.063,0.061-0.016,0.22,0.021,0.358c0.023,0.076,0.045,0.144,0.045,0.175
               c-0.006,0.106,0.069,0.129,0.168,0.16c0.047,0.007,0.084,0.023,0.123,0.046c0.053,0.03,0.03,0.106,0.008,0.167
               c-0.008,0.023-0.017,0.046-0.017,0.068c0,0.038,0.009,0.13,0.039,0.229c0.015,0.068,0.046,0.137,0.093,0.198
               c0.073,0.114,0.073,0.144,0.073,0.297v0.167c0.009,0.183,0.062,0.251,0.114,0.312c-0.021,0.008-0.045,0.015-0.066,0.023
               c-0.115,0.031-0.244,0.122-0.298-0.045c-0.046-0.168-0.101-0.221-0.153-0.122c-0.045,0.091-0.104,0.129-0.174,0.114
               c-0.068-0.023-0.113-0.13-0.152-0.13c-0.03,0-0.129,0.038-0.19,0.107c-0.067,0.061-0.335,0.137-0.463,0.221
               c-0.131,0.083-0.291,0.122-0.246,0.305c0.047,0.182,0.146,0.159,0.146,0.311c0.008,0.16-0.137,0.183-0.184,0.32
               c-0.045,0.145,0.115,0.176-0.008,0.274c-0.129,0.092-0.045,0.206-0.41,0.32c-0.357,0.114-0.775,0.312-0.859,0.465
               c-0.092,0.145-0.153,0.518-0.252,0.617c-0.1,0.098-0.381,0.426-0.519,0.494c-0.13,0.069-0.495,0.244-0.503,0.389
               c-0.016,0.152-0.426,0.403-0.518,0.51c-0.1,0.106-0.129,0.228-0.113,0.366c0.015,0.137-0.032,0.228-0.1,0.281
               c-0.068,0.054-0.146,0.069-0.328,0.099c-0.183,0.039-0.334,0.061-0.381,0.152c-0.054,0.099-0.1,0.335-0.236,0.359
               c-0.129,0.022-0.389-0.077-0.479,0.128c-0.099,0.199-0.16,0.693-0.099,0.861c0.054,0.16,0.151,0.19,0.084,0.366
               c-0.069,0.167,0.021,0.357,0.021,0.357s0.199,0.213,0.084,0.259c-0.104,0.046-0.113,0.16-0.021,0.198
               c0.099,0.031,0.159,0.038,0.159,0.16s0.053,0.251-0.008,0.411c-0.062,0.161-0.062,0.495-0.184,0.701
               c-0.122,0.197-0.184,0.266-0.167,0.411c0.007,0.137-0.032,0.297,0.062,0.426c0.092,0.122,0.151,0.488,0.008,0.678
               c-0.146,0.19-0.236,0.236-0.328,0.381s-0.305,0.352-0.281,0.488c0.021,0.136,0.055,0.188,0.244,0.256
               c0.197,0.07,0.105,0.215-0.077,0.146c-0.175-0.068-0.325-0.053-0.418,0.023c-0.084,0.074-0.389,0.099-0.389,0.335
               c0,0.235-0.085,0.342-0.214,0.465c-0.137,0.122-0.494,0.351-0.625,0.075c-0.121-0.266-0.715-0.54-0.807-1.104
               c-0.091-0.557-0.099-1.038-0.197-1.235c-0.106-0.205-0.16-0.381-0.365-0.73c-0.197-0.35-0.389-0.54-0.525-0.8
               c-0.137-0.257-0.557-1.766-0.799-2.214c-0.252-0.45-0.404-0.61-0.762-1.417c-0.365-0.807-0.815-2.474-0.815-2.764
               c0-0.297-0.138-1.31-0.138-1.53c0-0.229-0.342-0.533-0.258-0.723c0.074-0.191-0.016-0.138-0.055-0.245
               c-0.029-0.106,0-0.167-0.021-0.235c-0.022-0.076-0.161-0.175-0.214-0.084c-0.055,0.084-0.023,0.122-0.055,0.221
               c-0.037,0.098,0.069,0.091,0.069,0.228c0.007,0.145-0.077,0.115-0.092,0.259c-0.015,0.137,0.008,0.206-0.123,0.282
               c-0.129,0.068-0.433,0.343-0.737,0.381c-0.313,0.029-0.519-0.138-0.662-0.32c-0.152-0.191-0.617-0.632-0.771-0.792
               c-0.149-0.152-0.456-0.564-0.312-0.548c0.146,0.015,0.068,0.16,0.252,0.137c0.183-0.023,0.183-0.168,0.295-0.153
               c0.123,0.023,0.391-0.084,0.436-0.129c0.046-0.054,0.152-0.343-0.008-0.304c-0.151,0.03-0.221,0.137-0.403,0.144
               c-0.183,0.008-0.176,0.03-0.336-0.053c-0.166-0.084-0.28,0.114-0.38-0.084c-0.092-0.198-0.251-0.297-0.327-0.395
               c-0.084-0.1-0.146-0.191-0.244-0.214c-0.067-0.023-0.099,0.007-0.146-0.023c0.039-0.114,0.101-0.228,0.207-0.266
               c0.105-0.031,0.183-0.022,0.234-0.015c0.102,0.007,0.146,0.007,0.16-0.107c0.016-0.069-0.015-0.129-0.045-0.176
               c-0.016-0.029-0.023-0.045-0.023-0.053c0-0.008,0.017,0,0.076,0c0.092,0,0.168-0.008,0.229-0.015
               c0.113-0.015,0.189-0.022,0.289,0.039c0.037,0.022,0.068,0.045,0.099,0.067c0.114,0.077,0.177,0.122,0.282,0.024
               c0.029-0.024,0.053-0.046,0.084-0.077c0.084-0.084,0.184-0.182,0.26-0.144c0.037,0.023,0.045,0.053,0.053,0.076
               c0,0.008,0.008,0.015,0.008,0.022c0.021,0.061,0.054,0.084,0.168,0.046c0.182-0.068,0.252-0.251,0.289-0.335v-0.007
               c0.029-0.077-0.022-0.168-0.107-0.297c-0.021-0.054-0.061-0.114-0.091-0.175c-0.054-0.099-0.099-0.198-0.136-0.282
               c-0.055-0.114-0.092-0.206-0.139-0.267c-0.045-0.075-0.129-0.084-0.213-0.084c-0.068-0.007-0.146-0.007-0.184-0.06
               c-0.029-0.046-0.016-0.145-0.008-0.259c0.016-0.191,0.037-0.396-0.123-0.464c-0.121-0.054-0.197-0.054-0.265-0.046
               c-0.055,0-0.1,0-0.146-0.038c-0.045-0.038-0.084-0.053-0.121-0.077c-0.047-0.022-0.083-0.038-0.038-0.144
               c0.015-0.046,0.03-0.084,0.046-0.122c0.045-0.145,0.092-0.251,0.25-0.464c0.094-0.13,0.146-0.229,0.175-0.305
               c0.039-0.076,0.063-0.114,0.108-0.129h0.015c0.038-0.008,0.068,0.015,0.093,0.038v0.007c0.029,0.031,0.045,0.069,0.053,0.107
               c0.008,0.008,0.008,0.015,0.008,0.023c0,0.038,0.023,0.084,0.046,0.122c0.015,0.015,0.038,0.038,0.06,0.046
               c0.024,0.015,0.055,0.015,0.084,0.015c0.032-0.008,0.063-0.015,0.101-0.046c0.046-0.038,0.083-0.076,0.114-0.122
               c0.037-0.045,0.067-0.076,0.1-0.068c0.061,0.015,0.167,0.03,0.281,0.007c0.076-0.007,0.15-0.045,0.214-0.107
               c0.075-0.084,0.099-0.151,0.112-0.213c0.017-0.045,0.031-0.084,0.063-0.114c0.053-0.046,0.061-0.106,0.061-0.175
               c0-0.077,0.008-0.16,0.106-0.198c0.085-0.03,0.146-0.038,0.19-0.046c0.105-0.007,0.145-0.007,0.151-0.144
               c0.009-0.114,0.076-0.267,0.146-0.427c0.021-0.053,0.046-0.107,0.061-0.16c0.047-0.099,0.047-0.182,0.055-0.243
               c0-0.053,0.008-0.092,0.037-0.107c0.031-0.015,0.068-0.023,0.115-0.03c0.021-0.008,0.053-0.008,0.075-0.016
               c0.114-0.03,0.196-0.083,0.167-0.22l-0.016-0.046c-0.029-0.114-0.037-0.16,0-0.19c0.045-0.031,0.146-0.137,0.221-0.275
               c0.054-0.098,0.099-0.205,0.123-0.319c0.021-0.152-0.008-0.244-0.024-0.312c-0.015-0.046-0.021-0.084-0.007-0.115
               c0.023-0.068-0.008-0.122-0.038-0.174c0-0.016-0.007-0.023-0.015-0.031c-0.016-0.031-0.016-0.053,0.023-0.076
               c0.074-0.039,0.182-0.107,0.258-0.183c0.045-0.046,0.084-0.099,0.106-0.153c0.021-0.06,0.021-0.121-0.015-0.175
               c-0.016-0.015-0.039-0.037-0.069-0.053c-0.122-0.076-0.228-0.053-0.319-0.03c-0.054,0.015-0.099,0.03-0.123-0.023
               c-0.016-0.038-0.021-0.076-0.037-0.114c-0.062-0.153-0.105-0.274-0.244-0.297c-0.084-0.015-0.15-0.221-0.204-0.388
               c-0.017-0.039-0.022-0.077-0.03-0.092c-0.047-0.122-0.123-0.152-0.189-0.174c-0.031-0.009-0.055-0.023-0.039-0.055
               c0.008-0.045,0.054-0.083,0.092-0.113c0.031-0.023,0.054-0.046,0.068-0.077c0.016-0.053-0.008-0.091-0.106-0.13
               c-0.084-0.037-0.129-0.06-0.146-0.091c-0.017-0.03-0.008-0.061,0.023-0.105c0.029-0.069,0.021-0.16,0.007-0.251
               c-0.015-0.077-0.022-0.145-0.007-0.183c0-0.015,0.029-0.023,0.084-0.008c0.113,0.039,0.197,0.076,0.266,0.107
               c0.131,0.061,0.215,0.098,0.35,0.091c0.078-0.007,0.115,0.015,0.154,0.046c0.053,0.045,0.105,0.091,0.234,0.084
               c0.13,0,0.336-0.069,0.525-0.16c0.184-0.092,0.351-0.198,0.441-0.304c0.1-0.115,0.121-0.183,0.129-0.229
               c0.008-0.023,0.016-0.038,0.063-0.061c0.066-0.038,0.158-0.083,0.234-0.115c0.092,0.024,0.19,0.039,0.252,0.032
               c0.092-0.023,0.145-0.107,0.199-0.198c0.036-0.07,0.082-0.138,0.129-0.161c0.084-0.046,0.267-0.137,0.448-0.175
               c0.099-0.023,0.198-0.023,0.259,0.008c0.1,0.061,0.123,0.091,0.145,0.121c0.023,0.039,0.047,0.069,0.123,0.123
               c0.092,0.06,0.144,0.06,0.205,0.06c0.046,0,0.092,0,0.168,0.054c0.082,0.053,0.158,0.061,0.221,0.061
               c0.016,0,0.029,0.007,0.046,0.007c0.009,0,0.009,0.008,0,0.054c-0.017,0.068-0.038,0.106-0.062,0.144
               c-0.029,0.069-0.055,0.114-0.016,0.236c0.03,0.068,0.045,0.13,0.045,0.175c-0.007,0.046-0.021,0.084-0.076,0.138
               c-0.045,0.038-0.084,0.053-0.121,0.06c-0.075,0.023-0.129,0.046-0.159,0.168c-0.009,0.053-0.015,0.106-0.015,0.16
               c0,0.084-0.01,0.144-0.047,0.152h-0.022c-0.138,0-0.251,0.007-0.335,0.106v0.008c-0.047,0.054-0.055,0.16-0.031,0.274
               c0.018,0.107,0.068,0.214,0.146,0.282l0.015,0.008v0.008c0.069,0.052,0.153,0.067,0.229,0.083
               c0.075,0.015,0.15,0.03,0.158,0.091c0.008,0.1,0.055,0.168,0.084,0.228c0.031,0.054,0.061,0.107,0.061,0.153
               c0,0.031,0,0.053,0.01,0.084c0,0.023,0.007,0.046,0.007,0.068c0,0.039-0.022,0.068-0.121,0.068c-0.106,0-0.183-0.029-0.245-0.06
               c-0.091-0.03-0.15-0.061-0.234-0.015l-0.031,0.022c-0.053,0.024-0.105,0.062-0.145,0.099c-0.076,0.084-0.09,0.183,0.067,0.328
               c0.19,0.168,0.185,0.29,0.185,0.426v0.038c0,0.099,0.008,0.176,0.039,0.236c0.045,0.069,0.104,0.107,0.219,0.099
               c0.1,0.008,0.268,0.069,0.428,0.153c0.184,0.084,0.35,0.183,0.441,0.258c0.1,0.092,0.25,0.084,0.389,0.084
               c0.113,0,0.205,0,0.213,0.054c0.008,0.083,0.055,0.167,0.121,0.243c0.055,0.053,0.123,0.107,0.176,0.144
               c0,0.032-0.016,0.092-0.076,0.168c-0.021,0.038-0.061,0.046-0.085,0.061c-0.067,0.023-0.112,0.038-0.099,0.176l0.008,0.06
               c0.018,0.122,0.023,0.198-0.008,0.274l-0.008,0.015c-0.029,0.092-0.061,0.168-0.045,0.283c0,0.015-0.008,0.03-0.022,0.038
               c-0.045,0.045-0.093,0.084,0.03,0.228c0.084,0.099,0.168,0.16,0.259,0.206c0.084,0.038,0.183,0.061,0.282,0.075
               c0.074,0.008,0.099,0.055,0.121,0.107c0.029,0.069,0.062,0.137,0.168,0.168c0.045,0.022,0.074,0.03,0.1,0.03
               c0.061,0.023,0.066,0.023,0.296,0.16c0.198,0.122,0.269,0.13,0.337,0.146c0.045,0,0.09,0.008,0.174,0.045
               c0.099,0.046,0.137,0.092,0.176,0.137c0.054,0.054,0.105,0.115,0.267,0.183c0.151,0.061,0.19,0.061,0.274,0.068
               c0.06,0.009,0.158,0.016,0.402,0.069c0.244,0.053,0.441,0.023,0.602-0.007c0.169-0.031,0.289-0.053,0.381,0.068l0.038,0.046
               c0.184,0.244,0.29,0.396,0.465,0.427c0.092,0.015,0.146,0,0.198-0.009c0.046-0.015,0.082-0.03,0.16,0.009
               c0.076,0.03,0.151,0.075,0.213,0.121c0.068,0.038,0.121,0.077,0.175,0.092c0.054,0.014,0.123,0.03,0.206,0.045
               c0.046,0.015,0.099,0.024,0.15,0.038c0.068,0.015,0.162-0.007,0.26-0.031c0.121-0.03,0.259-0.06,0.328-0.007
               c0.045,0.031,0.084,0.061,0.105,0.084c0.068,0.069,0.107,0.099,0.236,0.069c0.068-0.016,0.121-0.008,0.16,0
               c0.061,0.007,0.104,0.022,0.158-0.023c0.016-0.008,0.039-0.023,0.055-0.031c0.084-0.038,0.16-0.084,0.138-0.251
               c-0.017-0.099-0.077-0.183-0.138-0.267c-0.055-0.069-0.107-0.137-0.113-0.205c-0.008-0.145-0.047-0.488-0.055-0.602
               c0.016-0.015,0.039-0.038,0.061-0.068c0.031-0.023,0.063-0.062,0.094-0.092c0.068-0.061,0.158-0.107,0.242-0.007
               c0.189,0.206,0.184,0.266,0.184,0.403v0.069c0,0.053-0.008,0.107-0.017,0.152c-0.028,0.145-0.046,0.259,0.129,0.35
               c0.13,0.077,0.161,0.084,0.222,0.099c0.045,0.008,0.101,0.023,0.213,0.069l0.093,0.03c0.229,0.084,0.342,0.122,0.479,0.054
               c0.047-0.023,0.082-0.054,0.113-0.084c0.055-0.046,0.107-0.084,0.199-0.038c0.09,0.046,0.204,0.068,0.326,0.083
               c0.152,0.016,0.312,0.016,0.457,0c0.062-0.007,0.121-0.007,0.182-0.007c0.084,0,0.169,0,0.236-0.015
               c0.1-0.03,0.16-0.092,0.123-0.237c-0.047-0.159-0.131-0.228-0.215-0.289c-0.045-0.038-0.099-0.076-0.137-0.136
               c-0.029-0.054-0.046-0.107-0.046-0.153c0-0.023,0.007-0.038,0.017-0.053c0.008-0.016,0.016-0.023,0.021-0.031
               c0.017-0.007,0.031-0.015,0.047-0.015c0.084,0,0.243-0.03,0.382-0.099c0.098-0.045,0.196-0.114,0.25-0.206
               c0.022-0.038,0.037-0.076,0.063-0.106c0.061-0.13,0.113-0.221,0.219-0.229c0.107-0.007,0.162-0.067,0.229-0.144
               c0.063-0.069,0.131-0.153,0.252-0.198c0.183-0.069,0.252-0.214,0.305-0.305c0.018-0.038,0.018-0.061,0.023-0.061
               c0.037,0,0.067-0.023,0.1-0.053c0.037-0.038,0.09-0.076,0.158-0.015c0.031,0.023,0.062,0.045,0.093,0.068
               c0.091,0.054,0.198,0.107,0.297,0.129c0.114,0.016,0.222,0,0.289-0.083c0.008-0.007,0.015-0.015,0.015-0.023
               c0.063-0.091,0.129-0.191,0.191-0.236c0.054-0.046,0.105-0.053,0.158,0c0.084,0.076,0.084,0.122,0.076,0.183
               c0,0.046-0.008,0.099,0.018,0.159c0.021,0.084,0.066,0.1,0.12,0.084c0.022-0.007,0.038-0.015,0.063-0.029
               c0.045-0.023,0.113-0.055,0.174,0.022c0.061,0.084,0.021,0.205-0.008,0.305c-0.017,0.053-0.031,0.098-0.031,0.137
               c-0.006,0.076,0.023,0.121,0.115,0.129c0.092,0.007,0.25,0.023,0.411,0.038c0.091,0.015,0.183,0.023,0.259,0.038v0.077
               l-0.009,0.022c-0.007,0.077-0.022,0.221-0.114,0.252c-0.028,0.015-0.052,0.022-0.067,0.045c-0.03,0.03-0.045,0.069-0.037,0.114
               c0,0.039,0.015,0.076,0.037,0.123c0.039,0.053,0.099,0.114,0.183,0.159c0.032,0.023,0.047,0.046,0.055,0.061
               c0,0-0.008,0.007-0.008,0.015c-0.008,0.015-0.023,0.023-0.039,0.03c-0.045,0.024-0.121,0.039-0.189,0.008
               c-0.066-0.03-0.105-0.053-0.137-0.075c-0.063-0.039-0.1-0.062-0.213-0.055c-0.115,0.009-0.244,0.176-0.358,0.328
               c-0.067,0.092-0.13,0.175-0.167,0.198c-0.03,0.015-0.063,0.03-0.092,0.046c-0.145,0.053-0.313,0.122-0.305,0.426
               c0.008,0.16,0.022,0.275,0.037,0.366c0.016,0.114,0.03,0.198,0.016,0.297c-0.016,0.076-0.045,0.083-0.092,0.099
               c-0.066,0.015-0.15,0.038-0.242,0.144c-0.084,0.091-0.076,0.145-0.031,0.191c0.031,0.023,0.068,0.03,0.107,0.045
               c0.053,0.008,0.113,0.031,0.129,0.077c0.008,0.053,0,0.136-0.022,0.228c-0.039,0.16-0.112,0.328-0.13,0.373
               c-0.03,0.069-0.084,0.182-0.069,0.403c0,0.031,0,0.054-0.007,0.062c-0.008,0.015-0.008,0.015-0.016,0.015
               c-0.016,0.007-0.03,0-0.053-0.008c-0.047-0.015-0.107-0.053-0.168-0.099c-0.084-0.061-0.152-0.084-0.222-0.061
               c-0.062,0.023-0.114,0.084-0.188,0.182c-0.123,0.161-0.023,0.374,0.045,0.511c0.016,0.03,0.029,0.061,0.029,0.076
               c0,0.008,0,0.015,0.008,0.023c0.01,0.053,0.018,0.099-0.099,0.137c-0.159,0.053-0.13,0.168-0.101,0.289
               c0.018,0.054,0.033,0.115,0.025,0.161c-0.025,0.106,0.021,0.159,0.066,0.22c0.023,0.023,0.045,0.054,0.054,0.084l0.007,0.031
               c-0.021,0.007-0.053,0.015-0.074,0.052c-0.017,0.031-0.03,0.046-0.039,0.054c-0.014,0.007-0.03,0.007-0.062,0
               C188.53,52.855,188.47,52.825,188.438,52.81z"/>
           
             <path fill="#808184" d="M194.423,64.07c-0.008-0.045-0.021-0.099-0.029-0.136
               c-0.047-0.137-0.076-0.297-0.037-0.374c0.037-0.069,0.105-0.221,0.099-0.403c-0.007-0.182,0-0.441-0.039-0.472
               c-0.045-0.03-0.084,0.137-0.144,0.099c-0.062-0.03-0.055-0.152-0.115-0.113c-0.061,0.029-0.083,0.213-0.121,0.144
               c-0.037-0.068,0-0.205,0.008-0.334c0.007-0.123,0.084-0.069,0.139-0.039c0.045,0.039,0.014-0.19,0.014-0.19
               s0.023,0.015,0.13-0.099c0.106-0.122,0.007-0.152-0.022-0.358c-0.037-0.213,0-0.259-0.068-0.434
               c-0.067-0.183-0.15-0.35-0.205-0.411c-0.063-0.053-0.076,0.061-0.19-0.061c-0.104-0.114-0.104-0.221-0.104-0.221
               s-0.069-0.319-0.101-0.594c-0.03-0.266-0.054-0.243-0.159-0.457c-0.101-0.213-0.093-0.038-0.152-0.373
               c-0.067-0.335-0.151-0.456-0.182-0.609c-0.033-0.152,0.029-0.084-0.123-0.304c-0.152-0.214-0.1-0.183-0.199-0.343
               c-0.098-0.167-0.273-0.578-0.364-0.518c-0.099,0.062-0.007,0.168-0.038,0.32c-0.037,0.16,0.023,0.282-0.152,0.373
               c-0.166,0.092-0.523,0.084-0.607,0.252c-0.083,0.167-0.083,0.282-0.205,0.364c-0.116,0.084-0.131,0.047-0.236,0.024
               c-0.113-0.024-0.168,0.092-0.267,0.084c-0.101-0.016-0.122-0.138-0.185-0.153c-0.066-0.015-0.105-0.137-0.105-0.212
               c0-0.084-0.063-0.146-0.105-0.062c-0.055,0.084-0.068,0.267-0.139,0.183c-0.074-0.084-0.122-0.121-0.09-0.289
               c0.029-0.16,0-0.252,0.052-0.336c0.047-0.083,0.153-0.121,0.106-0.25c-0.053-0.137,0.029-0.541-0.061-0.792
               c-0.092-0.259-0.107-0.275-0.197-0.518c-0.084-0.252-0.183-0.533-0.283-0.518c-0.092,0.015-0.174,0.107-0.229,0.007
               c-0.045-0.098,0.021,0.175-0.068,0.191c-0.082,0.007-0.305-0.122-0.242-0.221c0.053-0.106,0.144-0.175,0.105-0.251
               c-0.037-0.084-0.244-0.343-0.068-0.274c0.176,0.068,0.189,0.205,0.281,0.152c0.092-0.053,0.168-0.205,0.039-0.228
               c-0.131-0.016-0.215-0.115-0.313-0.266c-0.107-0.153-0.25-0.222-0.319-0.191c-0.069,0.03,0.007,0.206-0.093,0.206
               c-0.1,0.007-0.12-0.099-0.189-0.229c-0.062-0.13-0.189-0.198-0.273-0.259c-0.092-0.061-0.244-0.396-0.436-0.632
               c-0.045-0.061-0.091-0.114-0.129-0.16c0-0.008,0.008-0.023,0.008-0.038c0.016-0.084,0.145-0.084,0.273-0.084
               c0.029,0.008,0.063,0.008,0.077,0.008c0.106,0,0.144-0.046,0.144-0.106c0-0.038-0.015-0.084-0.029-0.13
               c-0.016-0.031-0.029-0.061-0.039-0.099c-0.015-0.068-0.021-0.137-0.021-0.205c-0.008-0.069,0-0.138,0.018-0.206v-0.008
               c0.037,0.023,0.082,0.039,0.137,0.053c0.061,0.008,0.098,0.008,0.136-0.014c0.03-0.016,0.054-0.046,0.069-0.084
               c0.007-0.008,0.015-0.008,0.021-0.015c0.047-0.008,0.083-0.024,0.061-0.138c-0.008-0.061-0.036-0.099-0.067-0.136
               c-0.03-0.039-0.06-0.077-0.053-0.138c0.015-0.069-0.007-0.137-0.023-0.198c-0.016-0.076-0.038-0.152,0.031-0.168
               c0.196-0.068,0.183-0.159,0.168-0.25l-0.009-0.024c0-0.022-0.023-0.06-0.038-0.106c-0.054-0.114-0.146-0.289-0.054-0.404
               c0.062-0.083,0.106-0.136,0.146-0.152c0.03-0.008,0.067,0.008,0.121,0.053c0.067,0.046,0.138,0.092,0.199,0.115
               c0.036,0.015,0.075,0.015,0.104,0.007c0.038-0.007,0.068-0.03,0.086-0.076c0.014-0.023,0.014-0.061,0.014-0.107
               c-0.006-0.197,0.031-0.289,0.061-0.349v-0.008c0.023-0.038,0.1-0.221,0.139-0.389c0.022-0.099,0.037-0.197,0.022-0.274
               c-0.022-0.107-0.122-0.129-0.206-0.152c-0.021-0.007-0.053-0.015-0.061-0.022c0,0,0.008-0.016,0.039-0.046
               c0.074-0.084,0.136-0.107,0.188-0.115c0.083-0.022,0.144-0.038,0.167-0.175c0.016-0.121,0-0.213-0.014-0.335
               c-0.018-0.092-0.031-0.198-0.039-0.35c-0.008-0.236,0.129-0.289,0.242-0.335c0.039-0.015,0.076-0.031,0.107-0.054
               c0.053-0.03,0.121-0.122,0.197-0.22c0.1-0.13,0.213-0.282,0.281-0.289c0.084,0,0.106,0.014,0.145,0.038
               c0.039,0.023,0.084,0.053,0.161,0.083c0.099,0.046,0.204,0.031,0.28-0.008c0.031-0.022,0.054-0.038,0.07-0.06
               c0.015-0.023,0.021-0.053,0.021-0.084c-0.007-0.053-0.038-0.099-0.099-0.137c-0.069-0.046-0.123-0.092-0.146-0.137
               c-0.022-0.022-0.03-0.046-0.03-0.068c0-0.016,0-0.031,0.008-0.038c0.008-0.008,0.016-0.016,0.029-0.016
               c0.153-0.053,0.176-0.244,0.19-0.335v-0.03c0.008-0.023,0.008-0.046,0-0.069c0.03,0,0.055,0.008,0.069,0.008
               c0.039,0.007,0.084,0.007,0.145,0c0.129-0.008,0.313-0.023,0.32,0.114c0.015,0.13,0.045,0.236,0.099,0.335
               c0.046,0.099,0.121,0.183,0.205,0.251c0.03,0.023,0.075,0.053,0.116,0.076c0.144,0.099,0.311,0.207,0.326,0.419
               c0.006,0.077,0.021,0.183,0.035,0.297c0.049,0.297,0.101,0.647,0.024,0.799c-0.046,0.1-0.138,0.153-0.222,0.214
               c-0.1,0.061-0.198,0.129-0.243,0.251c-0.046,0.122-0.046,0.221-0.054,0.313c0,0.067,0,0.136-0.021,0.205
               c-0.038,0.099,0.016,0.175,0.068,0.252c0.052,0.067,0.105,0.144,0.066,0.25c-0.045,0.123-0.061,0.274-0.037,0.381
               c0.008,0.046,0.021,0.084,0.045,0.114c0.031,0.03,0.068,0.046,0.114,0.046c0.054-0.008,0.106-0.038,0.167-0.1
               c0.084-0.083,0.146-0.152,0.199-0.205c0.092-0.113,0.129-0.159,0.213-0.099c0.084,0.069,0.114,0.16,0.137,0.244
               c0.023,0.069,0.039,0.13,0.092,0.19c0.047,0.061,0.076,0.076,0.113,0.092c0.032,0.014,0.068,0.03,0.152,0.159
               c0.076,0.13,0.176,0.191,0.26,0.237c0.063,0.038,0.114,0.061,0.121,0.114c0.008,0.068-0.016,0.106-0.037,0.16
               c-0.031,0.061-0.063,0.129-0.054,0.235c0,0.07-0.03,0.122-0.062,0.161c-0.017,0.022-0.022,0.045-0.037,0.061
               c-0.03,0.068-0.023,0.129,0.09,0.182c0.1,0.046,0.197,0.039,0.291,0.039c0.091,0,0.174-0.008,0.203,0.052
               c0.033,0.054,0.039,0.107,0.047,0.153c0.017,0.099,0.022,0.19,0.176,0.266c0.145,0.068,0.236,0.084,0.313,0.1
               c0.029,0.007,0.063,0.015,0.092,0.023c0.076,0.022,0.168-0.039,0.268-0.092c0.068-0.046,0.145-0.092,0.159-0.084
               c0.022,0.023,0.062,0.038,0.1,0.053c-0.055,0.054-0.153,0.138-0.229,0.13c-0.137,0-0.137,0.076-0.137,0.183
               c0,0.045,0,0.114-0.029,0.167c-0.055,0.115-0.094,0.129-0.146,0.152c-0.022,0.008-0.047,0.023-0.077,0.038
               c-0.104,0.054-0.091,0.115-0.068,0.206c0.016,0.038,0.023,0.076,0.023,0.129c-0.009,0.061-0.214,0.054-0.342,0.046
               c-0.063-0.008-0.107-0.008-0.13,0c-0.062,0.015-0.062,0.076-0.062,0.144c0,0.039,0,0.084-0.016,0.084
               c-0.031,0.008-0.054,0.008-0.076,0.008c-0.084,0-0.146,0-0.197,0.091c-0.031,0.054-0.084,0.107-0.152,0.161
               c-0.045,0.037-0.092,0.084-0.137,0.129c-0.039,0.038-0.069,0.023-0.114,0c-0.062-0.038-0.146-0.084-0.29-0.092
               c-0.184-0.007-0.25,0.092-0.266,0.214c-0.018,0.114,0.016,0.236,0.029,0.327c0.022,0.091,0.008,0.114-0.015,0.145
               c-0.009,0.015-0.024,0.038-0.039,0.068c-0.054,0.107-0.017,0.198,0.039,0.305c0.015,0.023,0.021,0.038,0.03,0.06
               c0.015,0.023,0.035,0.023,0.029,0.023h-0.029h-0.039h-0.016c-0.076,0.009-0.205,0.016-0.205,0.229
               c0,0.144,0.082,0.168,0.174,0.198c0.031,0.007,0.069,0.015,0.101,0.038c0.03,0.031,0.021,0.068,0.021,0.122
               c-0.015,0.061-0.021,0.129,0.01,0.22c0.037,0.122,0.175,0.229,0.313,0.336c0.084,0.075,0.176,0.144,0.213,0.205
               c0.055,0.069,0.1,0.115,0.146,0.152c0.061,0.061,0.105,0.107,0.145,0.198c0.006,0.023,0.021,0.046,0.03,0.077
               c0.054,0.145,0.092,0.258,0.258,0.312c0.054,0.015,0.054,0.068,0.032,0.129c-0.023,0.069-0.068,0.145-0.107,0.206l-0.009,0.015
               c-0.06,0.106-0.052,0.281-0.045,0.411c0.009,0.054,0.009,0.099,0.009,0.129c-0.009,0.031-0.039,0.054-0.078,0.084
               c-0.052,0.038-0.104,0.084-0.144,0.161c-0.046,0.083-0.008,0.212,0.054,0.35c0.061,0.137,0.152,0.281,0.229,0.403
               c0.1,0.16,0.289,0.358,0.434,0.511c0.084,0.083,0.146,0.151,0.146,0.159c0.007,0.015,0.014,0.038,0.029,0.061
               c0.028,0.076,0.082,0.206,0.075,0.243l-0.008,0.016c-0.03,0.076-0.062,0.152,0.06,0.464c0.063,0.16,0.123,0.289,0.178,0.396
               c0.054,0.106,0.104,0.19,0.158,0.267c0.039,0.068,0.063,0.114,0.069,0.16c0,0.045-0.008,0.091-0.016,0.144
               c-0.015,0.054-0.039,0.122-0.062,0.198c-0.022,0.069-0.047,0.138-0.068,0.214c-0.022,0.076-0.067,0.159-0.137,0.243
               c-0.062,0.092-0.146,0.183-0.229,0.267c-0.114,0.114-0.106,0.206-0.093,0.297c0,0.054,0.01,0.106-0.021,0.16
               C194.483,63.963,194.454,64.018,194.423,64.07"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M194.423,64.07c-0.008-0.045-0.021-0.099-0.029-0.136c-0.047-0.137-0.076-0.297-0.037-0.374
               c0.037-0.069,0.105-0.221,0.099-0.403c-0.007-0.182,0-0.441-0.039-0.472c-0.045-0.03-0.084,0.137-0.144,0.099
               c-0.062-0.03-0.055-0.152-0.115-0.113c-0.061,0.029-0.083,0.213-0.121,0.144c-0.037-0.068,0-0.205,0.008-0.334
               c0.007-0.123,0.084-0.069,0.139-0.039c0.045,0.039,0.014-0.19,0.014-0.19s0.023,0.015,0.13-0.099
               c0.106-0.122,0.007-0.152-0.022-0.358c-0.037-0.213,0-0.259-0.068-0.434c-0.067-0.183-0.15-0.35-0.205-0.411
               c-0.063-0.053-0.076,0.061-0.19-0.061c-0.104-0.114-0.104-0.221-0.104-0.221s-0.069-0.319-0.101-0.594
               c-0.03-0.266-0.054-0.243-0.159-0.457c-0.101-0.213-0.093-0.038-0.152-0.373c-0.067-0.335-0.151-0.456-0.182-0.609
               c-0.033-0.152,0.029-0.084-0.123-0.304c-0.152-0.214-0.1-0.183-0.199-0.343c-0.098-0.167-0.273-0.578-0.364-0.518
               c-0.099,0.062-0.007,0.168-0.038,0.32c-0.037,0.16,0.023,0.282-0.152,0.373c-0.166,0.092-0.523,0.084-0.607,0.252
               c-0.083,0.167-0.083,0.282-0.205,0.364c-0.116,0.084-0.131,0.047-0.236,0.024c-0.113-0.024-0.168,0.092-0.267,0.084
               c-0.101-0.016-0.122-0.138-0.185-0.153c-0.066-0.015-0.105-0.137-0.105-0.212c0-0.084-0.063-0.146-0.105-0.062
               c-0.055,0.084-0.068,0.267-0.139,0.183c-0.074-0.084-0.122-0.121-0.09-0.289c0.029-0.16,0-0.252,0.052-0.336
               c0.047-0.083,0.153-0.121,0.106-0.25c-0.053-0.137,0.029-0.541-0.061-0.792c-0.092-0.259-0.107-0.275-0.197-0.518
               c-0.084-0.252-0.183-0.533-0.283-0.518c-0.092,0.015-0.174,0.107-0.229,0.007c-0.045-0.098,0.021,0.175-0.068,0.191
               c-0.082,0.007-0.305-0.122-0.242-0.221c0.053-0.106,0.144-0.175,0.105-0.251c-0.037-0.084-0.244-0.343-0.068-0.274
               c0.176,0.068,0.189,0.205,0.281,0.152c0.092-0.053,0.168-0.205,0.039-0.228c-0.131-0.016-0.215-0.115-0.313-0.266
               c-0.107-0.153-0.25-0.222-0.319-0.191c-0.069,0.03,0.007,0.206-0.093,0.206c-0.1,0.007-0.12-0.099-0.189-0.229
               c-0.062-0.13-0.189-0.198-0.273-0.259c-0.092-0.061-0.244-0.396-0.436-0.632c-0.045-0.061-0.091-0.114-0.129-0.16
               c0-0.008,0.008-0.023,0.008-0.038c0.016-0.084,0.145-0.084,0.273-0.084c0.029,0.008,0.063,0.008,0.077,0.008
               c0.106,0,0.144-0.046,0.144-0.106c0-0.038-0.015-0.084-0.029-0.13c-0.016-0.031-0.029-0.061-0.039-0.099
               c-0.015-0.068-0.021-0.137-0.021-0.205c-0.008-0.069,0-0.138,0.018-0.206v-0.008c0.037,0.023,0.082,0.039,0.137,0.053
               c0.061,0.008,0.098,0.008,0.136-0.014c0.03-0.016,0.054-0.046,0.069-0.084c0.007-0.008,0.015-0.008,0.021-0.015
               c0.047-0.008,0.083-0.024,0.061-0.138c-0.008-0.061-0.036-0.099-0.067-0.136c-0.03-0.039-0.06-0.077-0.053-0.138
               c0.015-0.069-0.007-0.137-0.023-0.198c-0.016-0.076-0.038-0.152,0.031-0.168c0.196-0.068,0.183-0.159,0.168-0.25l-0.009-0.024
               c0-0.022-0.023-0.06-0.038-0.106c-0.054-0.114-0.146-0.289-0.054-0.404c0.062-0.083,0.106-0.136,0.146-0.152
               c0.03-0.008,0.067,0.008,0.121,0.053c0.067,0.046,0.138,0.092,0.199,0.115c0.036,0.015,0.075,0.015,0.104,0.007
               c0.038-0.007,0.068-0.03,0.086-0.076c0.014-0.023,0.014-0.061,0.014-0.107c-0.006-0.197,0.031-0.289,0.061-0.349v-0.008
               c0.023-0.038,0.1-0.221,0.139-0.389c0.022-0.099,0.037-0.197,0.022-0.274c-0.022-0.107-0.122-0.129-0.206-0.152
               c-0.021-0.007-0.053-0.015-0.061-0.022c0,0,0.008-0.016,0.039-0.046c0.074-0.084,0.136-0.107,0.188-0.115
               c0.083-0.022,0.144-0.038,0.167-0.175c0.016-0.121,0-0.213-0.014-0.335c-0.018-0.092-0.031-0.198-0.039-0.35
               c-0.008-0.236,0.129-0.289,0.242-0.335c0.039-0.015,0.076-0.031,0.107-0.054c0.053-0.03,0.121-0.122,0.197-0.22
               c0.1-0.13,0.213-0.282,0.281-0.289c0.084,0,0.106,0.014,0.145,0.038c0.039,0.023,0.084,0.053,0.161,0.083
               c0.099,0.046,0.204,0.031,0.28-0.008c0.031-0.022,0.054-0.038,0.07-0.06c0.015-0.023,0.021-0.053,0.021-0.084
               c-0.007-0.053-0.038-0.099-0.099-0.137c-0.069-0.046-0.123-0.092-0.146-0.137c-0.022-0.022-0.03-0.046-0.03-0.068
               c0-0.016,0-0.031,0.008-0.038c0.008-0.008,0.016-0.016,0.029-0.016c0.153-0.053,0.176-0.244,0.19-0.335v-0.03
               c0.008-0.023,0.008-0.046,0-0.069c0.03,0,0.055,0.008,0.069,0.008c0.039,0.007,0.084,0.007,0.145,0
               c0.129-0.008,0.313-0.023,0.32,0.114c0.015,0.13,0.045,0.236,0.099,0.335c0.046,0.099,0.121,0.183,0.205,0.251
               c0.03,0.023,0.075,0.053,0.116,0.076c0.144,0.099,0.311,0.207,0.326,0.419c0.006,0.077,0.021,0.183,0.035,0.297
               c0.049,0.297,0.101,0.647,0.024,0.799c-0.046,0.1-0.138,0.153-0.222,0.214c-0.1,0.061-0.198,0.129-0.243,0.251
               c-0.046,0.122-0.046,0.221-0.054,0.313c0,0.067,0,0.136-0.021,0.205c-0.038,0.099,0.016,0.175,0.068,0.252
               c0.052,0.067,0.105,0.144,0.066,0.25c-0.045,0.123-0.061,0.274-0.037,0.381c0.008,0.046,0.021,0.084,0.045,0.114
               c0.031,0.03,0.068,0.046,0.114,0.046c0.054-0.008,0.106-0.038,0.167-0.1c0.084-0.083,0.146-0.152,0.199-0.205
               c0.092-0.113,0.129-0.159,0.213-0.099c0.084,0.069,0.114,0.16,0.137,0.244c0.023,0.069,0.039,0.13,0.092,0.19
               c0.047,0.061,0.076,0.076,0.113,0.092c0.032,0.014,0.068,0.03,0.152,0.159c0.076,0.13,0.176,0.191,0.26,0.237
               c0.063,0.038,0.114,0.061,0.121,0.114c0.008,0.068-0.016,0.106-0.037,0.16c-0.031,0.061-0.063,0.129-0.054,0.235
               c0,0.07-0.03,0.122-0.062,0.161c-0.017,0.022-0.022,0.045-0.037,0.061c-0.03,0.068-0.023,0.129,0.09,0.182
               c0.1,0.046,0.197,0.039,0.291,0.039c0.091,0,0.174-0.008,0.203,0.052c0.033,0.054,0.039,0.107,0.047,0.153
               c0.017,0.099,0.022,0.19,0.176,0.266c0.145,0.068,0.236,0.084,0.313,0.1c0.029,0.007,0.063,0.015,0.092,0.023
               c0.076,0.022,0.168-0.039,0.268-0.092c0.068-0.046,0.145-0.092,0.159-0.084c0.022,0.023,0.062,0.038,0.1,0.053
               c-0.055,0.054-0.153,0.138-0.229,0.13c-0.137,0-0.137,0.076-0.137,0.183c0,0.045,0,0.114-0.029,0.167
               c-0.055,0.115-0.094,0.129-0.146,0.152c-0.022,0.008-0.047,0.023-0.077,0.038c-0.104,0.054-0.091,0.115-0.068,0.206
               c0.016,0.038,0.023,0.076,0.023,0.129c-0.009,0.061-0.214,0.054-0.342,0.046c-0.063-0.008-0.107-0.008-0.13,0
               c-0.062,0.015-0.062,0.076-0.062,0.144c0,0.039,0,0.084-0.016,0.084c-0.031,0.008-0.054,0.008-0.076,0.008
               c-0.084,0-0.146,0-0.197,0.091c-0.031,0.054-0.084,0.107-0.152,0.161c-0.045,0.037-0.092,0.084-0.137,0.129
               c-0.039,0.038-0.069,0.023-0.114,0c-0.062-0.038-0.146-0.084-0.29-0.092c-0.184-0.007-0.25,0.092-0.266,0.214
               c-0.018,0.114,0.016,0.236,0.029,0.327c0.022,0.091,0.008,0.114-0.015,0.145c-0.009,0.015-0.024,0.038-0.039,0.068
               c-0.054,0.107-0.017,0.198,0.039,0.305c0.015,0.023,0.021,0.038,0.03,0.06c0.015,0.023,0.035,0.023,0.029,0.023h-0.029h-0.039
               h-0.016c-0.076,0.009-0.205,0.016-0.205,0.229c0,0.144,0.082,0.168,0.174,0.198c0.031,0.007,0.069,0.015,0.101,0.038
               c0.03,0.031,0.021,0.068,0.021,0.122c-0.015,0.061-0.021,0.129,0.01,0.22c0.037,0.122,0.175,0.229,0.313,0.336
               c0.084,0.075,0.176,0.144,0.213,0.205c0.055,0.069,0.1,0.115,0.146,0.152c0.061,0.061,0.105,0.107,0.145,0.198
               c0.006,0.023,0.021,0.046,0.03,0.077c0.054,0.145,0.092,0.258,0.258,0.312c0.054,0.015,0.054,0.068,0.032,0.129
               c-0.023,0.069-0.068,0.145-0.107,0.206l-0.009,0.015c-0.06,0.106-0.052,0.281-0.045,0.411c0.009,0.054,0.009,0.099,0.009,0.129
               c-0.009,0.031-0.039,0.054-0.078,0.084c-0.052,0.038-0.104,0.084-0.144,0.161c-0.046,0.083-0.008,0.212,0.054,0.35
               c0.061,0.137,0.152,0.281,0.229,0.403c0.1,0.16,0.289,0.358,0.434,0.511c0.084,0.083,0.146,0.151,0.146,0.159
               c0.007,0.015,0.014,0.038,0.029,0.061c0.028,0.076,0.082,0.206,0.075,0.243l-0.008,0.016c-0.03,0.076-0.062,0.152,0.06,0.464
               c0.063,0.16,0.123,0.289,0.178,0.396c0.054,0.106,0.104,0.19,0.158,0.267c0.039,0.068,0.063,0.114,0.069,0.16
               c0,0.045-0.008,0.091-0.016,0.144c-0.015,0.054-0.039,0.122-0.062,0.198c-0.022,0.069-0.047,0.138-0.068,0.214
               c-0.022,0.076-0.067,0.159-0.137,0.243c-0.062,0.092-0.146,0.183-0.229,0.267c-0.114,0.114-0.106,0.206-0.093,0.297
               c0,0.054,0.01,0.106-0.021,0.16C194.483,63.963,194.454,64.018,194.423,64.07z"/>
             <path fill="#808184" d="M194.712,54.5c0.016-0.023,0.023-0.038,0.023-0.069
               c0-0.06-0.01-0.114-0.023-0.152c-0.007-0.046-0.016-0.076,0.023-0.099c0.022-0.007,0.045-0.023,0.061-0.031
               c0.084-0.03,0.131-0.053,0.198-0.198c0.038-0.076,0.038-0.152,0.038-0.212c0-0.046,0-0.077,0.029-0.077
               c0.129,0,0.268-0.121,0.328-0.183c0.047,0.023,0.076,0.062,0.076,0.099c0,0.031,0,0.061,0.008,0.091
               c0.017,0.047,0.053,0.092,0.1,0.115c0.037,0.03,0.082,0.046,0.13,0.046c0.022,0.007,0.045,0,0.069-0.008h0.015
               c0.052-0.023,0.099-0.076,0.136-0.137c0.031-0.061,0.039-0.137,0.016-0.213c0-0.008-0.008-0.015-0.008-0.023
               c-0.029-0.061-0.076-0.114-0.129-0.167c-0.068-0.084-0.146-0.161-0.152-0.275c-0.008-0.038-0.008-0.075-0.016-0.113
               c-0.008-0.062-0.015-0.115-0.015-0.168c0-0.069,0.015-0.122,0.067-0.122c0.062,0,0.114,0.023,0.16,0.03
               c0.053,0.023,0.1,0.031,0.137,0.031c0.084,0.114,0.352,0.487,0.473,0.579c0.1,0.076,0.16,0.052,0.221,0.03
               c0.023-0.007,0.039-0.007,0.063-0.007c0.015,0.007,0.015,0.098,0.015,0.182c0,0.107,0,0.206,0.039,0.259
               c0.022,0.022,0.038,0.091,0.045,0.167c0.015,0.092,0.03,0.191,0.084,0.244c0.045,0.03,0.152,0.054,0.267,0.054
               c0.145,0,0.313-0.016,0.403-0.039c0.054-0.007,0.106-0.03,0.146-0.053c0.061-0.023,0.113-0.053,0.175-0.031
               c0.038,0.008,0.067,0.077,0.092,0.16c0.031,0.092,0.039,0.184,0.046,0.229c0.008,0.091,0.061,0.091,0.122,0.099
               c0.038,0.007,0.091,0.007,0.122,0.068c0.061,0.092,0.105,0.115,0.15,0.13c0.016,0.008,0.039,0.023,0.055,0.038
               c0.016,0.022,0,0.092-0.031,0.167c-0.015,0.031-0.037,0.069-0.061,0.1c-0.016,0.03-0.038,0.061-0.061,0.076
               c-0.023,0.023-0.046,0.03-0.07,0.023c-0.06-0.031-0.121-0.038-0.168-0.038c-0.051,0-0.09,0.007-0.119,0.023
               c-0.039,0.015-0.068,0.037-0.077,0.068c-0.009,0.007-0.009,0.015-0.009,0.023v0.03c0,0.038,0.009,0.099-0.06,0.16
               c-0.062,0.06-0.062,0.105-0.022,0.152c0.029,0.038,0.091,0.054,0.174,0.084c0.016,0.007,0.031,0.007,0.063,0.023
               c0.084,0.03,0.176,0.083,0.273,0.152c0.107,0.068,0.221,0.152,0.327,0.251c0.092,0.076,0.151,0.107,0.196,0.13
               c0.047,0.023,0.078,0.037,0.123,0.144c0.039,0.115,0.076,0.152,0.114,0.191c0.029,0.03,0.068,0.068,0.122,0.197
               c0.068,0.176,0.114,0.207,0.189,0.259c0.047,0.032,0.099,0.069,0.176,0.161c0.145,0.175,0.168,0.175,0.268,0.182
               c0.036,0,0.098,0.007,0.197,0.038c0.159,0.046,0.167,0.168,0.175,0.297c0.009,0.092,0.015,0.184,0.062,0.274
               c0.062,0.122,0.152,0.206,0.273,0.274c0.114,0.069,0.252,0.122,0.42,0.176c0.098,0.038,0.129,0.06,0.12,0.075
               c-0.007,0.023-0.036,0.055-0.061,0.077c-0.038,0.045-0.083,0.083-0.099,0.129c-0.068,0.16,0.006,0.206,0.174,0.32l0.068,0.038
               c0.16,0.099,0.152,0.213,0.152,0.312v0.016c0,0.06-0.021,0.183-0.061,0.312c-0.031,0.099-0.07,0.205-0.107,0.267
               c-0.045,0.067-0.084,0.098-0.121,0.113c-0.046,0.016-0.105,0.016-0.189,0.008c-0.084,0-0.123-0.03-0.152-0.054
               c-0.038-0.029-0.076-0.06-0.152-0.067c-0.076-0.008-0.137-0.008-0.184,0.022c-0.053,0.03-0.084,0.084-0.104,0.198
               c-0.017,0.053-0.024,0.107-0.024,0.153c-0.006,0.091-0.015,0.151-0.104,0.144c-0.046-0.008-0.092-0.008-0.146,0
               c-0.129,0-0.258,0.007-0.389-0.19l-0.015-0.023c0.054-0.069,0.183-0.259,0.221-0.366c0.022-0.091-0.021-0.198-0.075-0.297
               c-0.029-0.068-0.062-0.137-0.062-0.167c0.007-0.031,0.015-0.053,0.03-0.084c0.039-0.06,0.068-0.129-0.016-0.251
               c-0.039-0.061-0.023-0.069-0.009-0.084c0.031-0.023,0.063-0.053,0.054-0.114c-0.015-0.084-0.138-0.122-0.25-0.16
               c-0.076-0.015-0.154-0.038-0.16-0.053c-0.008-0.016-0.017-0.039-0.023-0.061c-0.029-0.083-0.061-0.19-0.221-0.29
               c-0.137-0.084-0.182-0.274-0.215-0.434c-0.006-0.038-0.015-0.068-0.021-0.098c-0.017-0.062-0.008-0.107,0.007-0.161
               c0.023-0.083,0.039-0.175-0.037-0.334c-0.054-0.092-0.137-0.183-0.221-0.259c-0.139-0.114-0.281-0.198-0.344-0.229
               c-0.053-0.023-0.098-0.106-0.145-0.206c-0.031-0.045-0.053-0.091-0.085-0.144c0-0.008-0.007-0.008-0.007-0.015
               c-0.054-0.069-0.152-0.069-0.251-0.038c-0.091,0.022-0.183,0.076-0.252,0.121c-0.054,0.031-0.099,0.061-0.104,0.069
               c-0.017,0.007-0.017,0.015-0.031,0.023c-0.061,0.053-0.197,0.167-0.448,0.205c-0.138,0.016-0.153-0.053-0.161-0.114
               c-0.016-0.068-0.029-0.129-0.1-0.167c-0.12-0.069-0.184,0.015-0.258,0.122c-0.03,0.038-0.068,0.084-0.107,0.121
               c-0.021,0.023-0.061,0.054-0.091,0.077c-0.106,0.098-0.214,0.197-0.281,0.289c-0.03,0.046-0.054,0.068-0.077,0.076
               c-0.021,0.008-0.052,0-0.091-0.008c-0.022-0.007-0.038-0.038-0.054-0.076c-0.016-0.046-0.021-0.106-0.029-0.159
               c0-0.038,0.029-0.069,0.061-0.1c0.047-0.038,0.092-0.076,0.106-0.159c0.015-0.123-0.054-0.229-0.101-0.298
               c-0.014-0.022-0.021-0.038-0.028-0.045c0-0.008,0.015-0.008,0.028-0.023c0.041-0.046,0.084-0.099,0.047-0.175
               c-0.016-0.031,0-0.069,0.016-0.107s0.03-0.076,0.038-0.122c0.007-0.083-0.022-0.19-0.06-0.289
               c-0.019-0.053-0.041-0.107-0.041-0.129V55.49c0.009-0.084,0.009-0.122-0.06-0.168c-0.032-0.015-0.062-0.038-0.092-0.053
               c-0.039-0.023-0.067-0.046-0.106-0.061c-0.053-0.031-0.101,0-0.16,0.031c-0.045,0.021-0.099,0.045-0.145,0.03
               c-0.054-0.016-0.076-0.023-0.093-0.023h-0.007c-0.008,0-0.016-0.007-0.038-0.023l-0.007-0.007
               c-0.047-0.039-0.039-0.099-0.039-0.168c0.008-0.015,0.008-0.03,0.008-0.038c0.009-0.046,0.023-0.084,0.038-0.114
               c0.016-0.03,0.022-0.061,0.03-0.092c0-0.068-0.068-0.114-0.129-0.152c-0.016-0.015-0.029-0.023-0.039-0.031
               c-0.037-0.03-0.061-0.03-0.092-0.037c-0.015,0-0.037-0.008-0.06-0.023C194.712,54.538,194.712,54.507,194.712,54.5"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M194.712,54.5
               c0.016-0.023,0.023-0.038,0.023-0.069c0-0.06-0.01-0.114-0.023-0.152c-0.007-0.046-0.016-0.076,0.023-0.099
               c0.022-0.007,0.045-0.023,0.061-0.031c0.084-0.03,0.131-0.053,0.198-0.198c0.038-0.076,0.038-0.152,0.038-0.212
               c0-0.046,0-0.077,0.029-0.077c0.129,0,0.268-0.121,0.328-0.183c0.047,0.023,0.076,0.062,0.076,0.099
               c0,0.031,0,0.061,0.008,0.091c0.017,0.047,0.053,0.092,0.1,0.115c0.037,0.03,0.082,0.046,0.13,0.046
               c0.022,0.007,0.045,0,0.069-0.008h0.015c0.052-0.023,0.099-0.076,0.136-0.137c0.031-0.061,0.039-0.137,0.016-0.213
               c0-0.008-0.008-0.015-0.008-0.023c-0.029-0.061-0.076-0.114-0.129-0.167c-0.068-0.084-0.146-0.161-0.152-0.275
               c-0.008-0.038-0.008-0.075-0.016-0.113c-0.008-0.062-0.015-0.115-0.015-0.168c0-0.069,0.015-0.122,0.067-0.122
               c0.062,0,0.114,0.023,0.16,0.03c0.053,0.023,0.1,0.031,0.137,0.031c0.084,0.114,0.352,0.487,0.473,0.579
               c0.1,0.076,0.16,0.052,0.221,0.03c0.023-0.007,0.039-0.007,0.063-0.007c0.015,0.007,0.015,0.098,0.015,0.182
               c0,0.107,0,0.206,0.039,0.259c0.022,0.022,0.038,0.091,0.045,0.167c0.015,0.092,0.03,0.191,0.084,0.244
               c0.045,0.03,0.152,0.054,0.267,0.054c0.145,0,0.313-0.016,0.403-0.039c0.054-0.007,0.106-0.03,0.146-0.053
               c0.061-0.023,0.113-0.053,0.175-0.031c0.038,0.008,0.067,0.077,0.092,0.16c0.031,0.092,0.039,0.184,0.046,0.229
               c0.008,0.091,0.061,0.091,0.122,0.099c0.038,0.007,0.091,0.007,0.122,0.068c0.061,0.092,0.105,0.115,0.15,0.13
               c0.016,0.008,0.039,0.023,0.055,0.038c0.016,0.022,0,0.092-0.031,0.167c-0.015,0.031-0.037,0.069-0.061,0.1
               c-0.016,0.03-0.038,0.061-0.061,0.076c-0.023,0.023-0.046,0.03-0.07,0.023c-0.06-0.031-0.121-0.038-0.168-0.038
               c-0.051,0-0.09,0.007-0.119,0.023c-0.039,0.015-0.068,0.037-0.077,0.068c-0.009,0.007-0.009,0.015-0.009,0.023v0.03
               c0,0.038,0.009,0.099-0.06,0.16c-0.062,0.06-0.062,0.105-0.022,0.152c0.029,0.038,0.091,0.054,0.174,0.084
               c0.016,0.007,0.031,0.007,0.063,0.023c0.084,0.03,0.176,0.083,0.273,0.152c0.107,0.068,0.221,0.152,0.327,0.251
               c0.092,0.076,0.151,0.107,0.196,0.13c0.047,0.023,0.078,0.037,0.123,0.144c0.039,0.115,0.076,0.152,0.114,0.191
               c0.029,0.03,0.068,0.068,0.122,0.197c0.068,0.176,0.114,0.207,0.189,0.259c0.047,0.032,0.099,0.069,0.176,0.161
               c0.145,0.175,0.168,0.175,0.268,0.182c0.036,0,0.098,0.007,0.197,0.038c0.159,0.046,0.167,0.168,0.175,0.297
               c0.009,0.092,0.015,0.184,0.062,0.274c0.062,0.122,0.152,0.206,0.273,0.274c0.114,0.069,0.252,0.122,0.42,0.176
               c0.098,0.038,0.129,0.06,0.12,0.075c-0.007,0.023-0.036,0.055-0.061,0.077c-0.038,0.045-0.083,0.083-0.099,0.129
               c-0.068,0.16,0.006,0.206,0.174,0.32l0.068,0.038c0.16,0.099,0.152,0.213,0.152,0.312v0.016c0,0.06-0.021,0.183-0.061,0.312
               c-0.031,0.099-0.07,0.205-0.107,0.267c-0.045,0.067-0.084,0.098-0.121,0.113c-0.046,0.016-0.105,0.016-0.189,0.008
               c-0.084,0-0.123-0.03-0.152-0.054c-0.038-0.029-0.076-0.06-0.152-0.067c-0.076-0.008-0.137-0.008-0.184,0.022
               c-0.053,0.03-0.084,0.084-0.104,0.198c-0.017,0.053-0.024,0.107-0.024,0.153c-0.006,0.091-0.015,0.151-0.104,0.144
               c-0.046-0.008-0.092-0.008-0.146,0c-0.129,0-0.258,0.007-0.389-0.19l-0.015-0.023c0.054-0.069,0.183-0.259,0.221-0.366
               c0.022-0.091-0.021-0.198-0.075-0.297c-0.029-0.068-0.062-0.137-0.062-0.167c0.007-0.031,0.015-0.053,0.03-0.084
               c0.039-0.06,0.068-0.129-0.016-0.251c-0.039-0.061-0.023-0.069-0.009-0.084c0.031-0.023,0.063-0.053,0.054-0.114
               c-0.015-0.084-0.138-0.122-0.25-0.16c-0.076-0.015-0.154-0.038-0.16-0.053c-0.008-0.016-0.017-0.039-0.023-0.061
               c-0.029-0.083-0.061-0.19-0.221-0.29c-0.137-0.084-0.182-0.274-0.215-0.434c-0.006-0.038-0.015-0.068-0.021-0.098
               c-0.017-0.062-0.008-0.107,0.007-0.161c0.023-0.083,0.039-0.175-0.037-0.334c-0.054-0.092-0.137-0.183-0.221-0.259
               c-0.139-0.114-0.281-0.198-0.344-0.229c-0.053-0.023-0.098-0.106-0.145-0.206c-0.031-0.045-0.053-0.091-0.085-0.144
               c0-0.008-0.007-0.008-0.007-0.015c-0.054-0.069-0.152-0.069-0.251-0.038c-0.091,0.022-0.183,0.076-0.252,0.121
               c-0.054,0.031-0.099,0.061-0.104,0.069c-0.017,0.007-0.017,0.015-0.031,0.023c-0.061,0.053-0.197,0.167-0.448,0.205
               c-0.138,0.016-0.153-0.053-0.161-0.114c-0.016-0.068-0.029-0.129-0.1-0.167c-0.12-0.069-0.184,0.015-0.258,0.122
               c-0.03,0.038-0.068,0.084-0.107,0.121c-0.021,0.023-0.061,0.054-0.091,0.077c-0.106,0.098-0.214,0.197-0.281,0.289
               c-0.03,0.046-0.054,0.068-0.077,0.076c-0.021,0.008-0.052,0-0.091-0.008c-0.022-0.007-0.038-0.038-0.054-0.076
               c-0.016-0.046-0.021-0.106-0.029-0.159c0-0.038,0.029-0.069,0.061-0.1c0.047-0.038,0.092-0.076,0.106-0.159
               c0.015-0.123-0.054-0.229-0.101-0.298c-0.014-0.022-0.021-0.038-0.028-0.045c0-0.008,0.015-0.008,0.028-0.023
               c0.041-0.046,0.084-0.099,0.047-0.175c-0.016-0.031,0-0.069,0.016-0.107s0.03-0.076,0.038-0.122
               c0.007-0.083-0.022-0.19-0.06-0.289c-0.019-0.053-0.041-0.107-0.041-0.129V55.49c0.009-0.084,0.009-0.122-0.06-0.168
               c-0.032-0.015-0.062-0.038-0.092-0.053c-0.039-0.023-0.067-0.046-0.106-0.061c-0.053-0.031-0.101,0-0.16,0.031
               c-0.045,0.021-0.099,0.045-0.145,0.03c-0.054-0.016-0.076-0.023-0.093-0.023h-0.007c-0.008,0-0.016-0.007-0.038-0.023
               l-0.007-0.007c-0.047-0.039-0.039-0.099-0.039-0.168c0.008-0.015,0.008-0.03,0.008-0.038c0.009-0.046,0.023-0.084,0.038-0.114
               c0.016-0.03,0.022-0.061,0.03-0.092c0-0.068-0.068-0.114-0.129-0.152c-0.016-0.015-0.029-0.023-0.039-0.031
               c-0.037-0.03-0.061-0.03-0.092-0.037c-0.015,0-0.037-0.008-0.06-0.023C194.712,54.538,194.712,54.507,194.712,54.5z"/>
             <path fill="#808184" d="M197.705,62.35c-0.084-0.045-0.167-0.061-0.206-0.022
               c-0.045,0.045-0.175,0.045-0.197-0.084c-0.021-0.13-0.197-0.45-0.336-0.495c-0.136-0.045-0.052,0.007-0.258,0.022
               c-0.207,0.008-0.252-0.091-0.42-0.06c-0.158,0.038-0.234-0.153-0.204-0.351c0.028-0.19,0.099-0.35-0.038-0.357
               c-0.146-0.008-0.39-0.07-0.511,0.014c-0.113,0.092,0.03,0.259-0.045,0.435c-0.068,0.175-0.123,0.335-0.093,0.48
               c0.029,0.144,0.129,0.258,0.076,0.373c-0.062,0.114-0.115,0.175-0.115,0.251c0,0.076-0.267,0.708-0.267,0.89
               c0,0.176-0.188,0.518-0.182,0.755c0.017,0.234,0.104,0.479,0.13,0.578c0.021,0.105,0.159,0.244,0.259,0.189
               c0.091-0.061,0.183-0.137,0.259-0.016c0.082,0.129,0.099,0.198,0.075,0.297c-0.022,0.106,0.114,0.344,0.253,0.479
               c0.135,0.146,0.243,0.577,0.168,0.624c-0.084,0.038-0.107,0.122-0.084,0.206c0.029,0.084,0.129,0.328,0.15,0.207
               c0.023-0.115-0.008-0.222,0.1-0.063c0.106,0.162,0.207,0.36,0.418,0.42c0.213,0.055,0.184,0.039,0.32,0.039
               c0.145,0,0.327,0.09,0.327,0.182c0,0.102,0.053,0.289,0.146,0.344c0.039,0.023,0.098,0.055,0.166,0.084
               c-0.054,0.045-0.104,0.091-0.136,0.113c-0.054,0.039-0.077,0.084-0.106,0.129c-0.023,0.039-0.039,0.076-0.076,0.107
               c0,0.008,0.023,0.03,0.016,0.022c-0.008-0.007-0.008-0.017-0.016-0.022c-0.021-0.031-0.053-0.084-0.113-0.138
               c-0.092-0.067-0.152-0.038-0.199,0.024c-0.029,0.035-0.045,0.082-0.06,0.119c-0.009,0.031-0.024,0.055-0.031,0.069
               c-0.008,0.015-0.015,0.022-0.022,0.022c0,0.008-0.008,0.008-0.016,0.008c-0.007-0.008-0.016-0.008-0.021-0.021
               c-0.017-0.016-0.031-0.039-0.047-0.078c-0.016-0.053-0.008-0.099-0.008-0.137c0.008-0.074,0.008-0.145-0.076-0.213
               c-0.029-0.021-0.016-0.037-0.008-0.053c0.023-0.031,0.039-0.063,0.018-0.107c-0.018-0.045-0.084-0.082-0.153-0.105
               c-0.069-0.021-0.138-0.031-0.188-0.016c-0.017,0.008-0.033,0.008-0.041-0.008c-0.021-0.023-0.043-0.076-0.082-0.16
               c-0.053-0.137-0.123-0.146-0.198-0.084c-0.054,0.039-0.099,0.123-0.144,0.221c-0.016,0.023-0.022,0.039-0.033,0.062
               c-0.08-0.122-0.166-0.243-0.213-0.312c-0.104-0.131,0.024-0.092-0.166-0.26c-0.199-0.166-0.373-0.473-0.518-0.67
               c-0.152-0.205-0.197-0.427-0.329-0.418c-0.136,0.006-0.159,0.158-0.183,0.258c-0.022,0.101-0.145,0.207-0.166,0.023
               c-0.022-0.184-0.161-0.396-0.053-0.754c0.098-0.357-0.039-0.473,0.06-0.663c0.106-0.188,0.151-0.403,0.151-0.403
               s0-0.03-0.008-0.077l0.023-0.037c0.053-0.083,0.105-0.176,0.145-0.244c0.047-0.083,0.037-0.151,0.029-0.22
               c-0.007-0.069-0.016-0.137,0.068-0.221c0.085-0.085,0.168-0.184,0.236-0.274c0.069-0.1,0.129-0.19,0.152-0.274
               c0.016-0.076,0.038-0.145,0.061-0.213c0.03-0.077,0.055-0.146,0.069-0.206c0.015-0.061,0.022-0.115,0.015-0.175
               c-0.006-0.061-0.029-0.13-0.084-0.206c-0.045-0.076-0.099-0.16-0.151-0.259c-0.054-0.107-0.106-0.237-0.177-0.388
               c-0.105-0.274-0.082-0.335-0.06-0.396h0.007V61.13c0.03-0.076-0.039-0.228-0.074-0.32c-0.017-0.023-0.023-0.045-0.023-0.053
               c-0.007-0.031-0.076-0.107-0.175-0.199c-0.13-0.144-0.319-0.349-0.419-0.494c-0.075-0.122-0.159-0.266-0.223-0.388
               c-0.052-0.114-0.084-0.221-0.052-0.266c0.022-0.061,0.076-0.092,0.114-0.123c0.061-0.045,0.105-0.084,0.113-0.159
               c0.008-0.031,0-0.084,0-0.145c-0.008-0.114-0.016-0.266,0.029-0.35l0.008-0.015c0.039-0.062,0.084-0.145,0.115-0.229
               c0.037-0.115,0.03-0.213-0.099-0.259c-0.116-0.038-0.153-0.129-0.198-0.251c-0.008-0.023-0.017-0.046-0.031-0.076
               c-0.037-0.115-0.1-0.168-0.168-0.229c-0.037-0.038-0.082-0.083-0.129-0.144c-0.045-0.069-0.145-0.145-0.236-0.221
               c-0.121-0.099-0.25-0.198-0.272-0.289c-0.024-0.069-0.017-0.13-0.008-0.176c0.008-0.083,0.022-0.145-0.054-0.213
               c-0.047-0.038-0.1-0.046-0.146-0.062c-0.045-0.015-0.091-0.03-0.091-0.099c0-0.113,0.062-0.122,0.106-0.122h0.017
               c0.007-0.007,0.015-0.007,0.03,0c0.021,0,0.045,0,0.067-0.015c0.046-0.015,0.068-0.06,0.022-0.152
               c-0.008-0.023-0.016-0.046-0.03-0.069c-0.039-0.084-0.069-0.152-0.039-0.213c0.009-0.023,0.024-0.038,0.032-0.054
               c0.045-0.06,0.066-0.09,0.029-0.235c-0.016-0.076-0.047-0.19-0.029-0.282c0.007-0.068,0.052-0.129,0.158-0.122
               c0.123,0,0.197,0.046,0.252,0.076c0.084,0.046,0.138,0.077,0.228-0.022c0.056-0.046,0.091-0.084,0.14-0.114v-0.008
               c0.074-0.061,0.136-0.114,0.174-0.183c0.023-0.046,0.061-0.046,0.107-0.046c0.029,0,0.061,0,0.09-0.007
               c0.1-0.016,0.1-0.1,0.1-0.184c0-0.022-0.021-0.044-0.008-0.044h0.101c0.099,0.007,0.228,0.007,0.326-0.016
               c0.007,0.023,0.022,0.053,0.062,0.076c0.045,0.039,0.076,0.046,0.106,0.046c0.015,0.008,0.028,0.008,0.038,0.015
               c0.016,0.015,0.031,0.023,0.053,0.038c0.039,0.024,0.077,0.053,0.077,0.061c0,0.015-0.008,0.031-0.017,0.053
               c-0.021,0.046-0.047,0.092-0.047,0.152c0,0.016-0.006,0.024-0.006,0.039c-0.009,0.092-0.023,0.183,0.075,0.259h0.009
               c0.03,0.023,0.045,0.038,0.061,0.038c0.009,0.008,0.016,0.008,0.023,0.008c0.014,0.007,0.029,0.007,0.084,0.022
               c0.09,0.031,0.159-0.008,0.228-0.045c0.03-0.015,0.055-0.031,0.062-0.023c0.029,0.015,0.068,0.038,0.1,0.053
               c0.039,0.022,0.068,0.038,0.092,0.053c0.015,0.016,0.015,0.038,0.015,0.076v0.046c0,0.038,0.022,0.099,0.046,0.168
               c0.023,0.083,0.062,0.175,0.047,0.228c0,0.046-0.017,0.076-0.031,0.106c-0.021,0.069-0.038,0.114-0.008,0.191
               c0.008,0.015-0.015,0.039-0.03,0.061c-0.037,0.038-0.067,0.076-0.046,0.13c0.009,0.014,0.022,0.037,0.039,0.06
               c0.037,0.062,0.091,0.146,0.076,0.229c-0.009,0.045-0.039,0.075-0.07,0.098c-0.051,0.047-0.098,0.092-0.09,0.183
               c0.008,0.061,0.016,0.13,0.039,0.191c0.021,0.068,0.061,0.114,0.121,0.137c0.053,0.007,0.099,0.015,0.146,0.007
               c0.045-0.014,0.083-0.046,0.129-0.113c0.062-0.084,0.159-0.176,0.267-0.275c0.03-0.023,0.062-0.054,0.092-0.076
               c0.053-0.045,0.084-0.091,0.121-0.137c0.047-0.069,0.084-0.122,0.131-0.092c0.021,0.009,0.029,0.054,0.037,0.092
               c0.021,0.114,0.047,0.228,0.281,0.198c0.273-0.039,0.434-0.168,0.502-0.229l0.023-0.015c0.008-0.015,0.045-0.037,0.1-0.069
               c0.061-0.037,0.145-0.084,0.229-0.106c0.063-0.023,0.113-0.023,0.138,0l0.007,0.007c0.022,0.047,0.047,0.092,0.076,0.138
               c0.055,0.114,0.105,0.213,0.189,0.251c0.054,0.031,0.199,0.107,0.32,0.214c0.076,0.068,0.152,0.152,0.197,0.235
               c0.061,0.122,0.045,0.191,0.03,0.259c-0.017,0.061-0.03,0.122-0.007,0.213c0.007,0.023,0.015,0.054,0.021,0.091
               c0.039,0.176,0.084,0.396,0.261,0.503c0.129,0.076,0.152,0.16,0.174,0.236c0.016,0.023,0.023,0.045,0.031,0.069
               c0.03,0.053,0.122,0.084,0.221,0.106c0.084,0.031,0.176,0.054,0.176,0.069c0.008,0.015-0.008,0.023-0.018,0.03
               c-0.045,0.038-0.09,0.084-0.007,0.214c0.054,0.075,0.031,0.114,0.007,0.152c-0.015,0.038-0.035,0.069-0.035,0.121
               c0,0.062,0.028,0.138,0.067,0.214c0.045,0.084,0.083,0.175,0.068,0.229c-0.023,0.083-0.13,0.235-0.19,0.319
               c-0.123-0.107-0.259-0.107-0.419-0.099c-0.106,0-0.221,0.008-0.351-0.03c-0.213-0.046-0.381-0.015-0.524,0.015
               c-0.114,0.023-0.223,0.045-0.321,0.023c-0.22-0.053-0.272,0.121-0.334,0.342c-0.031,0.084-0.054,0.176-0.091,0.26
               c-0.077,0.159-0.1,0.159-0.131,0.159c-0.037,0-0.075-0.007-0.144,0.031c-0.1,0.053-0.084,0.144-0.022,0.251
               c0.022,0.046,0.054,0.091,0.084,0.137c0.037,0.06,0.074,0.122,0.099,0.168c0.022,0.06,0.029,0.099,0.037,0.129
               c0.017,0.061,0.022,0.114,0.114,0.259c0.046,0.091,0.084,0.137,0.114,0.174c0.046,0.062,0.068,0.092,0.099,0.206
               C197.735,62.25,197.729,62.305,197.705,62.35"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M197.705,62.35c-0.084-0.045-0.167-0.061-0.206-0.022c-0.045,0.045-0.175,0.045-0.197-0.084c-0.021-0.13-0.197-0.45-0.336-0.495
               c-0.136-0.045-0.052,0.007-0.258,0.022c-0.207,0.008-0.252-0.091-0.42-0.06c-0.158,0.038-0.234-0.153-0.204-0.351
               c0.028-0.19,0.099-0.35-0.038-0.357c-0.146-0.008-0.39-0.07-0.511,0.014c-0.113,0.092,0.03,0.259-0.045,0.435
               c-0.068,0.175-0.123,0.335-0.093,0.48c0.029,0.144,0.129,0.258,0.076,0.373c-0.062,0.114-0.115,0.175-0.115,0.251
               c0,0.076-0.267,0.708-0.267,0.89c0,0.176-0.188,0.518-0.182,0.755c0.017,0.234,0.104,0.479,0.13,0.578
               c0.021,0.105,0.159,0.244,0.259,0.189c0.091-0.061,0.183-0.137,0.259-0.016c0.082,0.129,0.099,0.198,0.075,0.297
               c-0.022,0.106,0.114,0.344,0.253,0.479c0.135,0.146,0.243,0.577,0.168,0.624c-0.084,0.038-0.107,0.122-0.084,0.206
               c0.029,0.084,0.129,0.328,0.15,0.207c0.023-0.115-0.008-0.222,0.1-0.063c0.106,0.162,0.207,0.36,0.418,0.42
               c0.213,0.055,0.184,0.039,0.32,0.039c0.145,0,0.327,0.09,0.327,0.182c0,0.102,0.053,0.289,0.146,0.344
               c0.039,0.023,0.098,0.055,0.166,0.084c-0.054,0.045-0.104,0.091-0.136,0.113c-0.054,0.039-0.077,0.084-0.106,0.129
               c-0.023,0.039-0.039,0.076-0.076,0.107c0,0.008,0.023,0.03,0.016,0.022c-0.008-0.007-0.008-0.017-0.016-0.022
               c-0.021-0.031-0.053-0.084-0.113-0.138c-0.092-0.067-0.152-0.038-0.199,0.024c-0.029,0.035-0.045,0.082-0.06,0.119
               c-0.009,0.031-0.024,0.055-0.031,0.069c-0.008,0.015-0.015,0.022-0.022,0.022c0,0.008-0.008,0.008-0.016,0.008
               c-0.007-0.008-0.016-0.008-0.021-0.021c-0.017-0.016-0.031-0.039-0.047-0.078c-0.016-0.053-0.008-0.099-0.008-0.137
               c0.008-0.074,0.008-0.145-0.076-0.213c-0.029-0.021-0.016-0.037-0.008-0.053c0.023-0.031,0.039-0.063,0.018-0.107
               c-0.018-0.045-0.084-0.082-0.153-0.105c-0.069-0.021-0.138-0.031-0.188-0.016c-0.017,0.008-0.033,0.008-0.041-0.008
               c-0.021-0.023-0.043-0.076-0.082-0.16c-0.053-0.137-0.123-0.146-0.198-0.084c-0.054,0.039-0.099,0.123-0.144,0.221
               c-0.016,0.023-0.022,0.039-0.033,0.062c-0.08-0.122-0.166-0.243-0.213-0.312c-0.104-0.131,0.024-0.092-0.166-0.26
               c-0.199-0.166-0.373-0.473-0.518-0.67c-0.152-0.205-0.197-0.427-0.329-0.418c-0.136,0.006-0.159,0.158-0.183,0.258
               c-0.022,0.101-0.145,0.207-0.166,0.023c-0.022-0.184-0.161-0.396-0.053-0.754c0.098-0.357-0.039-0.473,0.06-0.663
               c0.106-0.188,0.151-0.403,0.151-0.403s0-0.03-0.008-0.077l0.023-0.037c0.053-0.083,0.105-0.176,0.145-0.244
               c0.047-0.083,0.037-0.151,0.029-0.22c-0.007-0.069-0.016-0.137,0.068-0.221c0.085-0.085,0.168-0.184,0.236-0.274
               c0.069-0.1,0.129-0.19,0.152-0.274c0.016-0.076,0.038-0.145,0.061-0.213c0.03-0.077,0.055-0.146,0.069-0.206
               c0.015-0.061,0.022-0.115,0.015-0.175c-0.006-0.061-0.029-0.13-0.084-0.206c-0.045-0.076-0.099-0.16-0.151-0.259
               c-0.054-0.107-0.106-0.237-0.177-0.388c-0.105-0.274-0.082-0.335-0.06-0.396h0.007V61.13c0.03-0.076-0.039-0.228-0.074-0.32
               c-0.017-0.023-0.023-0.045-0.023-0.053c-0.007-0.031-0.076-0.107-0.175-0.199c-0.13-0.144-0.319-0.349-0.419-0.494
               c-0.075-0.122-0.159-0.266-0.223-0.388c-0.052-0.114-0.084-0.221-0.052-0.266c0.022-0.061,0.076-0.092,0.114-0.123
               c0.061-0.045,0.105-0.084,0.113-0.159c0.008-0.031,0-0.084,0-0.145c-0.008-0.114-0.016-0.266,0.029-0.35l0.008-0.015
               c0.039-0.062,0.084-0.145,0.115-0.229c0.037-0.115,0.03-0.213-0.099-0.259c-0.116-0.038-0.153-0.129-0.198-0.251
               c-0.008-0.023-0.017-0.046-0.031-0.076c-0.037-0.115-0.1-0.168-0.168-0.229c-0.037-0.038-0.082-0.083-0.129-0.144
               c-0.045-0.069-0.145-0.145-0.236-0.221c-0.121-0.099-0.25-0.198-0.272-0.289c-0.024-0.069-0.017-0.13-0.008-0.176
               c0.008-0.083,0.022-0.145-0.054-0.213c-0.047-0.038-0.1-0.046-0.146-0.062c-0.045-0.015-0.091-0.03-0.091-0.099
               c0-0.113,0.062-0.122,0.106-0.122h0.017c0.007-0.007,0.015-0.007,0.03,0c0.021,0,0.045,0,0.067-0.015
               c0.046-0.015,0.068-0.06,0.022-0.152c-0.008-0.023-0.016-0.046-0.03-0.069c-0.039-0.084-0.069-0.152-0.039-0.213
               c0.009-0.023,0.024-0.038,0.032-0.054c0.045-0.06,0.066-0.09,0.029-0.235c-0.016-0.076-0.047-0.19-0.029-0.282
               c0.007-0.068,0.052-0.129,0.158-0.122c0.123,0,0.197,0.046,0.252,0.076c0.084,0.046,0.138,0.077,0.228-0.022
               c0.056-0.046,0.091-0.084,0.14-0.114v-0.008c0.074-0.061,0.136-0.114,0.174-0.183c0.023-0.046,0.061-0.046,0.107-0.046
               c0.029,0,0.061,0,0.09-0.007c0.1-0.016,0.1-0.1,0.1-0.184c0-0.022-0.021-0.044-0.008-0.044h0.101
               c0.099,0.007,0.228,0.007,0.326-0.016c0.007,0.023,0.022,0.053,0.062,0.076c0.045,0.039,0.076,0.046,0.106,0.046
               c0.015,0.008,0.028,0.008,0.038,0.015c0.016,0.015,0.031,0.023,0.053,0.038c0.039,0.024,0.077,0.053,0.077,0.061
               c0,0.015-0.008,0.031-0.017,0.053c-0.021,0.046-0.047,0.092-0.047,0.152c0,0.016-0.006,0.024-0.006,0.039
               c-0.009,0.092-0.023,0.183,0.075,0.259h0.009c0.03,0.023,0.045,0.038,0.061,0.038c0.009,0.008,0.016,0.008,0.023,0.008
               c0.014,0.007,0.029,0.007,0.084,0.022c0.09,0.031,0.159-0.008,0.228-0.045c0.03-0.015,0.055-0.031,0.062-0.023
               c0.029,0.015,0.068,0.038,0.1,0.053c0.039,0.022,0.068,0.038,0.092,0.053c0.015,0.016,0.015,0.038,0.015,0.076v0.046
               c0,0.038,0.022,0.099,0.046,0.168c0.023,0.083,0.062,0.175,0.047,0.228c0,0.046-0.017,0.076-0.031,0.106
               c-0.021,0.069-0.038,0.114-0.008,0.191c0.008,0.015-0.015,0.039-0.03,0.061c-0.037,0.038-0.067,0.076-0.046,0.13
               c0.009,0.014,0.022,0.037,0.039,0.06c0.037,0.062,0.091,0.146,0.076,0.229c-0.009,0.045-0.039,0.075-0.07,0.098
               c-0.051,0.047-0.098,0.092-0.09,0.183c0.008,0.061,0.016,0.13,0.039,0.191c0.021,0.068,0.061,0.114,0.121,0.137
               c0.053,0.007,0.099,0.015,0.146,0.007c0.045-0.014,0.083-0.046,0.129-0.113c0.062-0.084,0.159-0.176,0.267-0.275
               c0.03-0.023,0.062-0.054,0.092-0.076c0.053-0.045,0.084-0.091,0.121-0.137c0.047-0.069,0.084-0.122,0.131-0.092
               c0.021,0.009,0.029,0.054,0.037,0.092c0.021,0.114,0.047,0.228,0.281,0.198c0.273-0.039,0.434-0.168,0.502-0.229l0.023-0.015
               c0.008-0.015,0.045-0.037,0.1-0.069c0.061-0.037,0.145-0.084,0.229-0.106c0.063-0.023,0.113-0.023,0.138,0l0.007,0.007
               c0.022,0.047,0.047,0.092,0.076,0.138c0.055,0.114,0.105,0.213,0.189,0.251c0.054,0.031,0.199,0.107,0.32,0.214
               c0.076,0.068,0.152,0.152,0.197,0.235c0.061,0.122,0.045,0.191,0.03,0.259c-0.017,0.061-0.03,0.122-0.007,0.213
               c0.007,0.023,0.015,0.054,0.021,0.091c0.039,0.176,0.084,0.396,0.261,0.503c0.129,0.076,0.152,0.16,0.174,0.236
               c0.016,0.023,0.023,0.045,0.031,0.069c0.03,0.053,0.122,0.084,0.221,0.106c0.084,0.031,0.176,0.054,0.176,0.069
               c0.008,0.015-0.008,0.023-0.018,0.03c-0.045,0.038-0.09,0.084-0.007,0.214c0.054,0.075,0.031,0.114,0.007,0.152
               c-0.015,0.038-0.035,0.069-0.035,0.121c0,0.062,0.028,0.138,0.067,0.214c0.045,0.084,0.083,0.175,0.068,0.229
               c-0.023,0.083-0.13,0.235-0.19,0.319c-0.123-0.107-0.259-0.107-0.419-0.099c-0.106,0-0.221,0.008-0.351-0.03
               c-0.213-0.046-0.381-0.015-0.524,0.015c-0.114,0.023-0.223,0.045-0.321,0.023c-0.22-0.053-0.272,0.121-0.334,0.342
               c-0.031,0.084-0.054,0.176-0.091,0.26c-0.077,0.159-0.1,0.159-0.131,0.159c-0.037,0-0.075-0.007-0.144,0.031
               c-0.1,0.053-0.084,0.144-0.022,0.251c0.022,0.046,0.054,0.091,0.084,0.137c0.037,0.06,0.074,0.122,0.099,0.168
               c0.022,0.06,0.029,0.099,0.037,0.129c0.017,0.061,0.022,0.114,0.114,0.259c0.046,0.091,0.084,0.137,0.114,0.174
               c0.046,0.062,0.068,0.092,0.099,0.206C197.735,62.25,197.729,62.305,197.705,62.35z"/>
             <path fill="#808184" d="M199.151,63.804c-0.053-0.053-0.221-0.175-0.267-0.145
               c-0.054,0.046,0.161,0.525,0.022,0.41c-0.145-0.12-0.122-0.188-0.139-0.327c-0.015-0.145,0.017-0.037-0.158-0.068
               c-0.168-0.03-0.137-0.22-0.129-0.266c0.015-0.046-0.018-0.328-0.1-0.206c-0.084,0.115-0.223,0.273-0.281,0.16
               c-0.055-0.106,0.113-0.282-0.023-0.427c-0.121-0.129-0.205-0.419-0.297-0.518c0.039-0.076,0.068-0.16,0.039-0.252
               c-0.039-0.136-0.063-0.174-0.115-0.243c-0.03-0.03-0.063-0.084-0.114-0.16c-0.075-0.137-0.092-0.183-0.099-0.235
               c-0.008-0.032-0.016-0.077-0.047-0.145c-0.021-0.053-0.062-0.122-0.105-0.183c-0.029-0.045-0.061-0.091-0.076-0.129
               c-0.031-0.054-0.046-0.1-0.017-0.114c0.039-0.024,0.063-0.024,0.084-0.024c0.077,0,0.132,0.008,0.229-0.212
               c0.037-0.092,0.067-0.191,0.092-0.275c0.053-0.167,0.091-0.305,0.221-0.274c0.12,0.031,0.234,0,0.357-0.023
               c0.144-0.03,0.297-0.061,0.486-0.015c0.137,0.038,0.26,0.038,0.373,0.03c0.168,0,0.313-0.007,0.419,0.153
               c0.159,0.251,0.32,0.243,0.479,0.236h0.129c0.191,0.022,0.197-0.084,0.213-0.236c0.009-0.038,0.009-0.092,0.023-0.138
               c0.016-0.083,0.039-0.122,0.061-0.136c0.023-0.015,0.063-0.008,0.122-0.008c0.046,0.008,0.069,0.022,0.1,0.045
               c0.046,0.039,0.091,0.077,0.205,0.084c0.093,0,0.162,0.008,0.229-0.015c0.053-0.022,0.1-0.053,0.152-0.121
               c0.021,0.052,0.045,0.121,0.037,0.144c-0.029,0.053-0.007,0.114,0.008,0.19c0.021,0.061,0.047,0.138,0.039,0.198
               c-0.018,0.115,0.021,0.152,0.084,0.214c0.03,0.03,0.066,0.06,0.098,0.113c0.068,0.084,0.047,0.138,0.016,0.214
               c-0.016,0.038-0.029,0.076-0.046,0.129c-0.021,0.1-0.036,0.191-0.015,0.282c0.021,0.1,0.084,0.198,0.189,0.313
               c0.031,0.037,0.053,0.068,0.053,0.091c0.01,0.007,0,0.015,0,0.023c-0.007,0.007-0.021,0.015-0.045,0.022
               c-0.046,0.015-0.113,0.015-0.152,0.008c-0.137-0.023-0.243,0.091-0.356,0.213l-0.039,0.039
               c-0.053,0.053-0.114,0.068-0.167,0.075c-0.084,0.023-0.152,0.038-0.189,0.13c-0.017,0.03-0.022,0.045-0.022,0.053
               c-0.008,0.023-0.017,0.031-0.017,0.038l-0.016,0.008c-0.007,0-0.021,0.007-0.037,0.015c-0.031,0.015-0.067,0.015-0.115,0.015
               c-0.061-0.007-0.113-0.015-0.166,0.031c-0.084,0.069-0.068,0.189-0.053,0.326v0.008c0.014,0.146,0.021,0.146,0.158,0.213
               l0.03,0.017c0.084,0.038,0.062,0.091,0.022,0.168l-0.016,0.029c0,0.008,0.007,0.016,0,0.016c-0.008,0-0.021-0.016-0.046-0.03
               c-0.03-0.031-0.067-0.062-0.121-0.092c-0.106-0.063-0.146-0.023-0.19,0.008c-0.016,0.015-0.031,0.022-0.054,0.022
               c-0.09-0.015-0.204,0.008-0.304,0.03c-0.023,0.007-0.055,0.007-0.077,0.017c-0.099,0.015-0.091,0.091-0.083,0.173
               c0,0.04,0.007,0.078-0.008,0.107c-0.016,0.046-0.061,0.054-0.152,0.076c-0.045,0.015-0.113,0.023-0.184,0.054
               C199.22,63.751,199.183,63.773,199.151,63.804"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M199.151,63.804c-0.053-0.053-0.221-0.175-0.267-0.145c-0.054,0.046,0.161,0.525,0.022,0.41
               c-0.145-0.12-0.122-0.188-0.139-0.327c-0.015-0.145,0.017-0.037-0.158-0.068c-0.168-0.03-0.137-0.22-0.129-0.266
               c0.015-0.046-0.018-0.328-0.1-0.206c-0.084,0.115-0.223,0.273-0.281,0.16c-0.055-0.106,0.113-0.282-0.023-0.427
               c-0.121-0.129-0.205-0.419-0.297-0.518c0.039-0.076,0.068-0.16,0.039-0.252c-0.039-0.136-0.063-0.174-0.115-0.243
               c-0.03-0.03-0.063-0.084-0.114-0.16c-0.075-0.137-0.092-0.183-0.099-0.235c-0.008-0.032-0.016-0.077-0.047-0.145
               c-0.021-0.053-0.062-0.122-0.105-0.183c-0.029-0.045-0.061-0.091-0.076-0.129c-0.031-0.054-0.046-0.1-0.017-0.114
               c0.039-0.024,0.063-0.024,0.084-0.024c0.077,0,0.132,0.008,0.229-0.212c0.037-0.092,0.067-0.191,0.092-0.275
               c0.053-0.167,0.091-0.305,0.221-0.274c0.12,0.031,0.234,0,0.357-0.023c0.144-0.03,0.297-0.061,0.486-0.015
               c0.137,0.038,0.26,0.038,0.373,0.03c0.168,0,0.313-0.007,0.419,0.153c0.159,0.251,0.32,0.243,0.479,0.236h0.129
               c0.191,0.022,0.197-0.084,0.213-0.236c0.009-0.038,0.009-0.092,0.023-0.138c0.016-0.083,0.039-0.122,0.061-0.136
               c0.023-0.015,0.063-0.008,0.122-0.008c0.046,0.008,0.069,0.022,0.1,0.045c0.046,0.039,0.091,0.077,0.205,0.084
               c0.093,0,0.162,0.008,0.229-0.015c0.053-0.022,0.1-0.053,0.152-0.121c0.021,0.052,0.045,0.121,0.037,0.144
               c-0.029,0.053-0.007,0.114,0.008,0.19c0.021,0.061,0.047,0.138,0.039,0.198c-0.018,0.115,0.021,0.152,0.084,0.214
               c0.03,0.03,0.066,0.06,0.098,0.113c0.068,0.084,0.047,0.138,0.016,0.214c-0.016,0.038-0.029,0.076-0.046,0.129
               c-0.021,0.1-0.036,0.191-0.015,0.282c0.021,0.1,0.084,0.198,0.189,0.313c0.031,0.037,0.053,0.068,0.053,0.091
               c0.01,0.007,0,0.015,0,0.023c-0.007,0.007-0.021,0.015-0.045,0.022c-0.046,0.015-0.113,0.015-0.152,0.008
               c-0.137-0.023-0.243,0.091-0.356,0.213l-0.039,0.039c-0.053,0.053-0.114,0.068-0.167,0.075c-0.084,0.023-0.152,0.038-0.189,0.13
               c-0.017,0.03-0.022,0.045-0.022,0.053c-0.008,0.023-0.017,0.031-0.017,0.038l-0.016,0.008c-0.007,0-0.021,0.007-0.037,0.015
               c-0.031,0.015-0.067,0.015-0.115,0.015c-0.061-0.007-0.113-0.015-0.166,0.031c-0.084,0.069-0.068,0.189-0.053,0.326v0.008
               c0.014,0.146,0.021,0.146,0.158,0.213l0.03,0.017c0.084,0.038,0.062,0.091,0.022,0.168l-0.016,0.029
               c0,0.008,0.007,0.016,0,0.016c-0.008,0-0.021-0.016-0.046-0.03c-0.03-0.031-0.067-0.062-0.121-0.092
               c-0.106-0.063-0.146-0.023-0.19,0.008c-0.016,0.015-0.031,0.022-0.054,0.022c-0.09-0.015-0.204,0.008-0.304,0.03
               c-0.023,0.007-0.055,0.007-0.077,0.017c-0.099,0.015-0.091,0.091-0.083,0.173c0,0.04,0.007,0.078-0.008,0.107
               c-0.016,0.046-0.061,0.054-0.152,0.076c-0.045,0.015-0.113,0.023-0.184,0.054C199.22,63.751,199.183,63.773,199.151,63.804z"/>
             <path fill="#808184" d="M200.72,53.609c-0.105,0.19-0.197,0.327-0.426,0.464
               c-0.251,0.145-0.495,0.32-0.495,0.465c0,0.144-0.259,0.357-0.343,0.494c-0.076,0.138-0.046,0.473-0.068,0.609
               c-0.021,0.13-0.021,0.153,0.047,0.343c0.066,0.191,0.229,0.328,0.318,0.464c0.084,0.138,0.389,0.214,0.389,0.35
               c0,0.13,0.281,0.671,0.563,0.823c0.28,0.145,0.235,0.168,0.381,0.388c0.151,0.229,0.556,0.373,0.753,0.579
               c0.207,0.198,0.365,0.335,0.428,0.472c0.067,0.13,0.586,0.975,0.586,1.333c0,0.365,0.365,0.723,0.252,0.966
               c-0.114,0.252,0.113,0.564,0.045,0.77c-0.068,0.198-0.137,0.426-0.137,0.586c0,0.152-0.26,0.479-0.519,0.617
               c-0.259,0.137-0.42,0.289-0.588,0.403c-0.166,0.114-0.409,0.213-0.478,0.167c-0.07-0.045-0.322-0.076-0.322,0.016
               c0,0.083,0.108,0.26,0.063,0.327c-0.047,0.061-0.151,0.213-0.151,0.319c-0.008,0.1-0.152,0.107-0.19,0.063
               c-0.031-0.039-0.382-0.213-0.32-0.107c0.062,0.1,0.304,0.298,0.185,0.336c-0.123,0.038-0.297,0-0.435,0.152
               c-0.14,0.152-0.168,0.213-0.267,0.343c-0.094,0.128-0.275,0.222-0.307,0.205c-0.037-0.022,0.023-0.077,0.016-0.159
               c-0.016-0.085-0.067-0.076-0.067-0.252s-0.022-0.686,0.03-0.77c0.061-0.091,0.13-0.212,0.021-0.251
               c-0.113-0.038-0.053,0.069-0.242-0.045c-0.114-0.069-0.184-0.13-0.223-0.183c0.016-0.031,0.039-0.054,0.1-0.076
               c0.068-0.022,0.13-0.032,0.176-0.047c0.121-0.03,0.189-0.045,0.229-0.136c0.017-0.054,0.017-0.105,0.01-0.152
               c0-0.039-0.01-0.068,0.007-0.068c0.022,0,0.045-0.009,0.075-0.016c0.092-0.023,0.199-0.046,0.268-0.03
               c0.066,0.007,0.1-0.016,0.129-0.038c0.016-0.015,0.03-0.031,0.084,0c0.037,0.022,0.076,0.053,0.105,0.075
               c0.022,0.023,0.039,0.038,0.062,0.047c0.062,0.03,0.101,0.015,0.138-0.069l0.016-0.021c0.068-0.128,0.105-0.221-0.068-0.305
               l-0.031-0.016c-0.09-0.046-0.099-0.046-0.104-0.137c-0.009-0.107-0.022-0.213,0.022-0.243c0.015-0.015,0.053-0.015,0.092-0.008
               c0.054,0.008,0.113,0.008,0.168-0.023c0.015-0.008,0.029-0.015,0.035-0.015c0.024-0.015,0.033-0.023,0.047-0.038
               c0.017-0.015,0.022-0.031,0.031-0.061l0.022-0.053c0.022-0.039,0.067-0.053,0.121-0.061c0.069-0.023,0.146-0.038,0.222-0.114
               l0.028-0.039c0.103-0.099,0.192-0.198,0.269-0.183c0.055,0.017,0.146,0.017,0.205-0.007c0.038-0.015,0.068-0.03,0.092-0.053
               c0.022-0.031,0.039-0.068,0.022-0.114c-0.007-0.038-0.031-0.084-0.076-0.138c-0.099-0.106-0.144-0.182-0.168-0.266
               c-0.015-0.076-0.007-0.146,0.017-0.228c0.015-0.054,0.029-0.092,0.045-0.122c0.037-0.114,0.068-0.175-0.03-0.312
               c-0.037-0.061-0.075-0.099-0.106-0.13c-0.045-0.038-0.066-0.061-0.061-0.122c0.008-0.083-0.016-0.167-0.038-0.243
               c-0.015-0.054-0.022-0.099-0.022-0.107c0.047-0.076-0.029-0.251-0.062-0.304c0.03-0.062,0.062-0.145,0.084-0.221
               c0.038-0.137,0.069-0.275,0.069-0.342v-0.017c0-0.121,0-0.266-0.207-0.395c-0.031-0.023-0.045-0.038-0.061-0.045
               c-0.121-0.077-0.176-0.107-0.146-0.191c0.017-0.031,0.047-0.061,0.078-0.091c0.037-0.039,0.074-0.076,0.084-0.114
               c0.029-0.077-0.01-0.146-0.176-0.207c-0.168-0.06-0.299-0.106-0.404-0.167c-0.106-0.061-0.184-0.137-0.235-0.236
               c-0.039-0.076-0.039-0.16-0.047-0.236c-0.015-0.167-0.021-0.32-0.251-0.388c-0.113-0.031-0.176-0.038-0.221-0.038
               c-0.061-0.008-0.074-0.008-0.197-0.145c-0.084-0.106-0.145-0.145-0.19-0.183c-0.062-0.038-0.099-0.068-0.159-0.213
               c-0.063-0.144-0.106-0.19-0.146-0.228c-0.028-0.03-0.053-0.062-0.09-0.16c-0.055-0.145-0.1-0.168-0.168-0.198
               c-0.047-0.023-0.1-0.046-0.176-0.114c-0.105-0.099-0.229-0.191-0.334-0.267c-0.107-0.068-0.215-0.122-0.299-0.152
               c-0.015-0.007-0.045-0.016-0.068-0.03c-0.061-0.023-0.113-0.038-0.129-0.054c0,0,0.008,0,0.016-0.007
               c0.107-0.092,0.1-0.191,0.092-0.244v-0.015c0.008,0,0.016,0,0.021-0.008c0.018-0.007,0.047-0.007,0.084-0.007
               c0.039,0,0.084,0.007,0.131,0.022c0.063,0.023,0.113,0.008,0.167-0.03c0.03-0.023,0.062-0.061,0.084-0.099
               c0.03-0.038,0.053-0.084,0.067-0.122c0.047-0.106,0.055-0.22,0.023-0.266c-0.039-0.038-0.068-0.054-0.1-0.069
               c-0.03-0.015-0.062-0.023-0.107-0.091c-0.052-0.099-0.129-0.107-0.189-0.114c-0.022,0-0.037-0.008-0.037-0.008
               c0-0.046-0.016-0.152-0.045-0.251c-0.031-0.107-0.078-0.206-0.16-0.229c-0.1-0.03-0.168,0-0.244,0.038
               c-0.037,0.016-0.084,0.038-0.129,0.046c-0.084,0.022-0.245,0.038-0.381,0.031c-0.092,0-0.176-0.009-0.198-0.031
               c-0.022-0.023-0.038-0.099-0.054-0.176c-0.008-0.091-0.021-0.175-0.062-0.22c-0.022-0.023-0.022-0.107-0.022-0.19
               c0-0.122,0-0.244-0.074-0.274c-0.055-0.023-0.094-0.008-0.131,0.007c-0.037,0.008-0.068,0.023-0.129-0.023
               c-0.107-0.083-0.359-0.434-0.441-0.548c0.008-0.015,0.021-0.038,0.029-0.068c0.016-0.054,0.039-0.107,0.068-0.145
               c0.016-0.023,0.029-0.039,0.055-0.046c0.014-0.007,0.037-0.007,0.06-0.007c0.017,0.007,0.03,0.014,0.056,0.03
               c0.037,0.031,0.073,0.068,0.104,0.099c0.114,0.099,0.19,0.175,0.298,0.069c0.053-0.046,0.068-0.107,0.084-0.16
               c0.007-0.031,0.016-0.053,0.03-0.069c0.007-0.015,0.03-0.015,0.091,0.023c0.068,0.054,0.122,0.092,0.16,0.129
               c0.091,0.092,0.129,0.122,0.251,0.023c0.067-0.053,0.13-0.083,0.198-0.114c0.076-0.023,0.158-0.038,0.25-0.053
               c0.146-0.03,0.223-0.146,0.281-0.236c0.031-0.038,0.055-0.076,0.077-0.084c0.029-0.016,0.054-0.038,0.091-0.068
               c0.063-0.069,0.152-0.153,0.268-0.046c0.039,0.023,0.063,0.053,0.092,0.076c0.175,0.167,0.318,0.304,0.549,0.266
               c0.105-0.022,0.213-0.03,0.288-0.022c0.062,0.007,0.099,0.03,0.106,0.068c0.008,0.038-0.022,0.083-0.053,0.13
               c-0.068,0.121-0.146,0.25,0.029,0.388c0.1,0.076,0.184,0.114,0.252,0.152c0.09,0.046,0.167,0.083,0.251,0.191
               c0.099,0.114,0.197,0.159,0.28,0.198c0.055,0.022,0.1,0.038,0.138,0.084C200.507,53.464,200.612,53.542,200.72,53.609"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M200.72,53.609c-0.105,0.19-0.197,0.327-0.426,0.464c-0.251,0.145-0.495,0.32-0.495,0.465c0,0.144-0.259,0.357-0.343,0.494
               c-0.076,0.138-0.046,0.473-0.068,0.609c-0.021,0.13-0.021,0.153,0.047,0.343c0.066,0.191,0.229,0.328,0.318,0.464
               c0.084,0.138,0.389,0.214,0.389,0.35c0,0.13,0.281,0.671,0.563,0.823c0.28,0.145,0.235,0.168,0.381,0.388
               c0.151,0.229,0.556,0.373,0.753,0.579c0.207,0.198,0.365,0.335,0.428,0.472c0.067,0.13,0.586,0.975,0.586,1.333
               c0,0.365,0.365,0.723,0.252,0.966c-0.114,0.252,0.113,0.564,0.045,0.77c-0.068,0.198-0.137,0.426-0.137,0.586
               c0,0.152-0.26,0.479-0.519,0.617c-0.259,0.137-0.42,0.289-0.588,0.403c-0.166,0.114-0.409,0.213-0.478,0.167
               c-0.07-0.045-0.322-0.076-0.322,0.016c0,0.083,0.108,0.26,0.063,0.327c-0.047,0.061-0.151,0.213-0.151,0.319
               c-0.008,0.1-0.152,0.107-0.19,0.063c-0.031-0.039-0.382-0.213-0.32-0.107c0.062,0.1,0.304,0.298,0.185,0.336
               c-0.123,0.038-0.297,0-0.435,0.152c-0.14,0.152-0.168,0.213-0.267,0.343c-0.094,0.128-0.275,0.222-0.307,0.205
               c-0.037-0.022,0.023-0.077,0.016-0.159c-0.016-0.085-0.067-0.076-0.067-0.252s-0.022-0.686,0.03-0.77
               c0.061-0.091,0.13-0.212,0.021-0.251c-0.113-0.038-0.053,0.069-0.242-0.045c-0.114-0.069-0.184-0.13-0.223-0.183
               c0.016-0.031,0.039-0.054,0.1-0.076c0.068-0.022,0.13-0.032,0.176-0.047c0.121-0.03,0.189-0.045,0.229-0.136
               c0.017-0.054,0.017-0.105,0.01-0.152c0-0.039-0.01-0.068,0.007-0.068c0.022,0,0.045-0.009,0.075-0.016
               c0.092-0.023,0.199-0.046,0.268-0.03c0.066,0.007,0.1-0.016,0.129-0.038c0.016-0.015,0.03-0.031,0.084,0
               c0.037,0.022,0.076,0.053,0.105,0.075c0.022,0.023,0.039,0.038,0.062,0.047c0.062,0.03,0.101,0.015,0.138-0.069l0.016-0.021
               c0.068-0.128,0.105-0.221-0.068-0.305l-0.031-0.016c-0.09-0.046-0.099-0.046-0.104-0.137c-0.009-0.107-0.022-0.213,0.022-0.243
               c0.015-0.015,0.053-0.015,0.092-0.008c0.054,0.008,0.113,0.008,0.168-0.023c0.015-0.008,0.029-0.015,0.035-0.015
               c0.024-0.015,0.033-0.023,0.047-0.038c0.017-0.015,0.022-0.031,0.031-0.061l0.022-0.053c0.022-0.039,0.067-0.053,0.121-0.061
               c0.069-0.023,0.146-0.038,0.222-0.114l0.028-0.039c0.103-0.099,0.192-0.198,0.269-0.183c0.055,0.017,0.146,0.017,0.205-0.007
               c0.038-0.015,0.068-0.03,0.092-0.053c0.022-0.031,0.039-0.068,0.022-0.114c-0.007-0.038-0.031-0.084-0.076-0.138
               c-0.099-0.106-0.144-0.182-0.168-0.266c-0.015-0.076-0.007-0.146,0.017-0.228c0.015-0.054,0.029-0.092,0.045-0.122
               c0.037-0.114,0.068-0.175-0.03-0.312c-0.037-0.061-0.075-0.099-0.106-0.13c-0.045-0.038-0.066-0.061-0.061-0.122
               c0.008-0.083-0.016-0.167-0.038-0.243c-0.015-0.054-0.022-0.099-0.022-0.107c0.047-0.076-0.029-0.251-0.062-0.304
               c0.03-0.062,0.062-0.145,0.084-0.221c0.038-0.137,0.069-0.275,0.069-0.342v-0.017c0-0.121,0-0.266-0.207-0.395
               c-0.031-0.023-0.045-0.038-0.061-0.045c-0.121-0.077-0.176-0.107-0.146-0.191c0.017-0.031,0.047-0.061,0.078-0.091
               c0.037-0.039,0.074-0.076,0.084-0.114c0.029-0.077-0.01-0.146-0.176-0.207c-0.168-0.06-0.299-0.106-0.404-0.167
               c-0.106-0.061-0.184-0.137-0.235-0.236c-0.039-0.076-0.039-0.16-0.047-0.236c-0.015-0.167-0.021-0.32-0.251-0.388
               c-0.113-0.031-0.176-0.038-0.221-0.038c-0.061-0.008-0.074-0.008-0.197-0.145c-0.084-0.106-0.145-0.145-0.19-0.183
               c-0.062-0.038-0.099-0.068-0.159-0.213c-0.063-0.144-0.106-0.19-0.146-0.228c-0.028-0.03-0.053-0.062-0.09-0.16
               c-0.055-0.145-0.1-0.168-0.168-0.198c-0.047-0.023-0.1-0.046-0.176-0.114c-0.105-0.099-0.229-0.191-0.334-0.267
               c-0.107-0.068-0.215-0.122-0.299-0.152c-0.015-0.007-0.045-0.016-0.068-0.03c-0.061-0.023-0.113-0.038-0.129-0.054
               c0,0,0.008,0,0.016-0.007c0.107-0.092,0.1-0.191,0.092-0.244v-0.015c0.008,0,0.016,0,0.021-0.008
               c0.018-0.007,0.047-0.007,0.084-0.007c0.039,0,0.084,0.007,0.131,0.022c0.063,0.023,0.113,0.008,0.167-0.03
               c0.03-0.023,0.062-0.061,0.084-0.099c0.03-0.038,0.053-0.084,0.067-0.122c0.047-0.106,0.055-0.22,0.023-0.266
               c-0.039-0.038-0.068-0.054-0.1-0.069c-0.03-0.015-0.062-0.023-0.107-0.091c-0.052-0.099-0.129-0.107-0.189-0.114
               c-0.022,0-0.037-0.008-0.037-0.008c0-0.046-0.016-0.152-0.045-0.251c-0.031-0.107-0.078-0.206-0.16-0.229
               c-0.1-0.03-0.168,0-0.244,0.038c-0.037,0.016-0.084,0.038-0.129,0.046c-0.084,0.022-0.245,0.038-0.381,0.031
               c-0.092,0-0.176-0.009-0.198-0.031c-0.022-0.023-0.038-0.099-0.054-0.176c-0.008-0.091-0.021-0.175-0.062-0.22
               c-0.022-0.023-0.022-0.107-0.022-0.19c0-0.122,0-0.244-0.074-0.274c-0.055-0.023-0.094-0.008-0.131,0.007
               c-0.037,0.008-0.068,0.023-0.129-0.023c-0.107-0.083-0.359-0.434-0.441-0.548c0.008-0.015,0.021-0.038,0.029-0.068
               c0.016-0.054,0.039-0.107,0.068-0.145c0.016-0.023,0.029-0.039,0.055-0.046c0.014-0.007,0.037-0.007,0.06-0.007
               c0.017,0.007,0.03,0.014,0.056,0.03c0.037,0.031,0.073,0.068,0.104,0.099c0.114,0.099,0.19,0.175,0.298,0.069
               c0.053-0.046,0.068-0.107,0.084-0.16c0.007-0.031,0.016-0.053,0.03-0.069c0.007-0.015,0.03-0.015,0.091,0.023
               c0.068,0.054,0.122,0.092,0.16,0.129c0.091,0.092,0.129,0.122,0.251,0.023c0.067-0.053,0.13-0.083,0.198-0.114
               c0.076-0.023,0.158-0.038,0.25-0.053c0.146-0.03,0.223-0.146,0.281-0.236c0.031-0.038,0.055-0.076,0.077-0.084
               c0.029-0.016,0.054-0.038,0.091-0.068c0.063-0.069,0.152-0.153,0.268-0.046c0.039,0.023,0.063,0.053,0.092,0.076
               c0.175,0.167,0.318,0.304,0.549,0.266c0.105-0.022,0.213-0.03,0.288-0.022c0.062,0.007,0.099,0.03,0.106,0.068
               c0.008,0.038-0.022,0.083-0.053,0.13c-0.068,0.121-0.146,0.25,0.029,0.388c0.1,0.076,0.184,0.114,0.252,0.152
               c0.09,0.046,0.167,0.083,0.251,0.191c0.099,0.114,0.197,0.159,0.28,0.198c0.055,0.022,0.1,0.038,0.138,0.084
               C200.507,53.464,200.612,53.542,200.72,53.609z"/>
             <path fill="#808184" d="M177.627,27.805l0.038-0.008c0.16-0.031,0.16-0.114,0.167-0.198
               c0-0.038,0.008-0.076,0.114-0.067c0.16,0.007,0.314,0,0.435-0.032c0.121-0.03,0.222-0.076,0.269-0.152
               c0.021-0.045,0.074-0.098,0.137-0.145c0.083-0.068,0.182-0.129,0.236-0.152c0.028-0.015,0.104-0.076,0.197-0.144
               c0.168-0.138,0.401-0.32,0.508-0.343c0.094-0.023,0.131-0.069,0.17-0.107c0.029-0.038,0.061-0.076,0.182-0.076
               c0.105,0,0.16-0.031,0.189-0.053c0.023-0.015,0.031-0.015,0.153,0.091c0.214,0.168,0.312,0.168,0.472,0.168
               c0.047,0.008,0.092,0.008,0.152,0.008c0.053,0.007,0.131,0.007,0.213,0.015c0.281,0.015,0.655,0.029,0.655,0.084
               c0,0.015,0,0.037-0.008,0.06c-0.017,0.137-0.037,0.305,0.313,0.35c0.182,0.023,0.326,0.038,0.471,0.047
               c0.139,0.015,0.274,0.022,0.427,0.029c0.13,0.008,0.214,0,0.282-0.007c0.092-0.007,0.151-0.015,0.25,0.03
               c0.131,0.061,0.236,0.069,0.336,0.077c0.062,0,0.106,0.007,0.145,0.022c0.068,0.022,0.131,0.03,0.191,0.007
               c0.053-0.015,0.099-0.046,0.152-0.091c0.021-0.016,0.045-0.031,0.066-0.046c0.1-0.053,0.184-0.106,0.122-0.266
               c-0.015-0.039-0.015-0.084-0.022-0.115c-0.016-0.098-0.029-0.167-0.158-0.258c-0.047-0.031-0.107-0.054-0.168-0.084
               c-0.153-0.062-0.328-0.137-0.306-0.29c0.021-0.099,0.038-0.175,0.054-0.228c0.03-0.099,0.037-0.145-0.008-0.198
               c-0.008-0.008-0.008-0.023-0.008-0.03c0.008-0.024,0.021-0.054,0.038-0.069c0.021-0.031,0.106-0.144,0.159-0.251
               c0.037-0.077,0.068-0.145,0.068-0.19c0-0.008-0.022-0.008-0.016-0.008c0.016,0,0.046,0.015,0.084,0.037
               c0.016,0.016,0.029,0.024,0.038,0.031c0.121,0.084,0.327,0.221,0.524,0.237c0.068,0.007,0.168,0.03,0.26,0.053
               c0.152,0.038,0.297,0.083,0.352,0.106c0.028,0.008,0.145,0.03,0.295,0.054c0.275,0.046,0.687,0.122,0.893,0.198
               c0.242,0.091,0.357,0.273,0.449,0.419c0.021,0.045,0.045,0.084,0.067,0.113l0.022,0.023c0.099,0.145,0.129,0.191,0.435,0.343
               c0.252,0.121,0.366,0.145,0.532,0.175c0.046,0.008,0.092,0.016,0.152,0.03l0.022,0.008c0.251,0.054,0.396,0.084,0.729-0.008
               c0.168-0.045,0.268-0.098,0.344-0.144c0.076-0.038,0.129-0.076,0.221-0.069c0.123,0.008,0.428,0.031,0.701,0.069
               c0.174,0.023,0.342,0.053,0.439,0.084c0.123,0.046,0.168,0.076,0.213,0.114c0.056,0.038,0.109,0.083,0.277,0.137
               c0.129,0.046,0.256,0.054,0.362,0.061c0.138,0.007,0.252,0.007,0.306,0.084c0.062,0.099,0.32,0.251,0.603,0.365
               c0.252,0.107,0.532,0.19,0.708,0.19c0.121,0,0.229-0.008,0.335-0.015c0.222-0.015,0.427-0.031,0.647,0.024
               c0.336,0.075,0.356,0.06,0.577-0.084l0.046-0.039c0.1-0.068,0.258-0.122,0.411-0.167c0.214-0.069,0.403-0.13,0.457-0.244
               c0.03-0.068,0.121-0.16,0.214-0.244c0.114-0.106,0.235-0.197,0.266-0.197c0.047-0.009,0.244,0.007,0.428,0.061
               c0.122,0.03,0.234,0.076,0.297,0.129c0.122,0.099,0.26,0.06,0.402,0.015c0.121-0.038,0.26-0.076,0.389-0.031
               c0.146,0.047,0.223,0.099,0.29,0.153l0.017,0.015c-0.017,0.144-0.084,0.739-0.084,0.914c0,0.121,0.053,0.19,0.098,0.244
               c0.023,0.038,0.055,0.068,0.046,0.106c-0.007,0.038-0.021,0.076-0.037,0.106c-0.038,0.099-0.076,0.176,0.017,0.229
               c0.036,0.022,0.067,0.06,0.104,0.099c0.047,0.045,0.106,0.106,0.192,0.168c0.111,0.084,0.228,0.038,0.371-0.031
               c0.068-0.031,0.152-0.068,0.243-0.099c0.146-0.038,0.222,0.015,0.306,0.061c0.076,0.045,0.151,0.091,0.267,0.099
               c0.206,0.023,0.289-0.122,0.342-0.213c0.015-0.015,0.009-0.031,0.015-0.031c0.018-0.008,0.146,0.031,0.283,0.084
               c0.122,0.038,0.235,0.091,0.281,0.122c0.061,0.046,0.146,0.075,0.251,0.114c0.093,0.031,0.198,0.069,0.312,0.122
               c0.131,0.061,0.283,0.16,0.429,0.266c0.146,0.099,0.28,0.206,0.372,0.282c0.016,0.016,0.029,0.023,0.029,0.038v0.007
               c-0.006,0.009-0.029,0.024-0.052,0.032c-0.084,0.037-0.206,0.06-0.305,0.068c-0.123,0.016-0.183,0-0.291-0.031
               c-0.052-0.015-0.12-0.037-0.22-0.06c-0.205-0.047-0.289,0.023-0.373,0.083c-0.038,0.031-0.076,0.061-0.121,0.061
               c-0.023,0.008-0.047,0.008-0.077,0.008c-0.144,0.015-0.228,0.015-0.304,0.137c-0.047,0.068-0.047,0.068-0.084,0.053
               l-0.008-0.007c-0.055-0.022-0.113,0-0.184,0.045c-0.076,0.054-0.16,0.145-0.206,0.191c-0.053,0.06-0.062,0.121-0.053,0.198
               c0.015,0.076,0.046,0.151,0.091,0.25c0.016,0.039,0,0.062-0.038,0.077c-0.038,0.022-0.104,0.038-0.167,0.053
               c-0.076,0.015-0.15,0.031-0.197,0.053c-0.047,0.031-0.106,0.046-0.168,0.046c-0.076,0-0.146-0.015-0.183-0.038
               c-0.008-0.008-0.017-0.008-0.022-0.016c-0.047-0.014-0.1-0.007-0.146,0.023c-0.038,0.024-0.077,0.061-0.114,0.115
               c-0.015,0.038-0.037,0.068-0.053,0.114c-0.023,0.076-0.046,0.084-0.061,0.084c-0.039,0-0.069,0.007-0.115,0.053
               c-0.008,0.015-0.023,0.038-0.038,0.053c-0.062,0.076-0.138,0.176-0.306,0.206c-0.152,0.03-0.336-0.038-0.479-0.091
               c-0.063-0.023-0.104-0.046-0.152-0.053c-0.045-0.016-0.091-0.039-0.137-0.069c-0.092-0.046-0.205-0.107-0.381-0.122
               c-0.176-0.016-0.221,0.038-0.229,0.144v0.077c0,0.068,0,0.144-0.021,0.22c-0.068,0.198,0.053,0.289,0.189,0.396
               c0.053,0.038,0.121,0.092,0.168,0.153c0.053,0.069,0.121,0.114,0.183,0.145c0.092,0.053,0.168,0.099,0.129,0.281
               c-0.021,0.13-0.104,0.145-0.204,0.16c-0.138,0.031-0.29,0.061-0.359,0.336c-0.037,0.174-0.158,0.304-0.312,0.395
               c-0.222,0.137-0.502,0.205-0.669,0.213c-0.068,0-0.123,0.007-0.176,0.015c-0.168,0.016-0.305,0.023-0.831,0
               c-0.677-0.03-1.156,0.427-1.354,0.617l-0.039,0.03c-0.074,0.069-0.129,0.115-0.175,0.115c-0.03,0.008-0.077-0.024-0.129-0.099
               c-0.114-0.152-0.306-0.115-0.474-0.076c-0.062,0.008-0.12,0.022-0.168,0.022c-0.062,0-0.144-0.045-0.258-0.098
               c-0.138-0.077-0.32-0.168-0.549-0.214c-0.13-0.03-0.242-0.045-0.343-0.068c-0.212-0.03-0.373-0.054-0.617-0.168l-0.067-0.03
               c-0.32-0.153-0.487-0.236-0.808-0.236c-0.161,0-0.336,0.015-0.493,0.03c-0.162,0.015-0.313,0.038-0.436,0.061
               c-0.122,0.031-0.227,0.046-0.428,0.015c-0.197-0.023-0.494-0.099-0.975-0.258c-0.625-0.198-0.859-0.092-1.013-0.016
               c-0.077,0.039-0.121,0.061-0.206,0.008c-0.139-0.084-0.242-0.206-0.352-0.327c-0.09-0.099-0.183-0.206-0.305-0.305
               c-0.174-0.138-0.229-0.229-0.326-0.365c-0.054-0.077-0.115-0.176-0.221-0.305c-0.16-0.205-0.281-0.198-0.441-0.19
               c-0.121,0.007-0.267,0.015-0.479-0.115c-0.272-0.167-0.519-0.206-0.762-0.235c-0.183-0.031-0.372-0.055-0.587-0.137
               c-0.418-0.168-0.516-0.138-0.646-0.1c-0.03,0.008-0.063,0.023-0.1,0.031c-0.076,0.007-0.235-0.031-0.403-0.084
               c-0.197-0.076-0.41-0.175-0.54-0.259c-0.151-0.099-0.137-0.137-0.114-0.228c0.016-0.039,0.022-0.084,0.037-0.138
               c0.039-0.228,0-0.365-0.045-0.495l-0.015-0.045c-0.024-0.076-0.077-0.175-0.146-0.273c-0.084-0.107-0.184-0.222-0.274-0.313
               c-0.076-0.084-0.152-0.176-0.222-0.275c-0.076-0.099-0.151-0.198-0.244-0.289c-0.09-0.099-0.174-0.122-0.258-0.144
               c-0.082-0.023-0.16-0.039-0.229-0.145c-0.113-0.175-0.229-0.152-0.373-0.129c-0.076,0.014-0.152,0.03-0.236,0.022
               c-0.145-0.022-0.242-0.077-0.334-0.115c-0.068-0.03-0.132-0.061-0.19-0.076c-0.076-0.015-0.13-0.038-0.183-0.084
               c-0.063-0.045-0.115-0.114-0.184-0.221c-0.092-0.144-0.152-0.144-0.213-0.144c-0.039,0-0.076,0-0.153-0.092
               C177.703,27.942,177.649,27.858,177.627,27.805"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M177.627,27.805l0.038-0.008c0.16-0.031,0.16-0.114,0.167-0.198c0-0.038,0.008-0.076,0.114-0.067
               c0.16,0.007,0.314,0,0.435-0.032c0.121-0.03,0.222-0.076,0.269-0.152c0.021-0.045,0.074-0.098,0.137-0.145
               c0.083-0.068,0.182-0.129,0.236-0.152c0.028-0.015,0.104-0.076,0.197-0.144c0.168-0.138,0.401-0.32,0.508-0.343
               c0.094-0.023,0.131-0.069,0.17-0.107c0.029-0.038,0.061-0.076,0.182-0.076c0.105,0,0.16-0.031,0.189-0.053
               c0.023-0.015,0.031-0.015,0.153,0.091c0.214,0.168,0.312,0.168,0.472,0.168c0.047,0.008,0.092,0.008,0.152,0.008
               c0.053,0.007,0.131,0.007,0.213,0.015c0.281,0.015,0.655,0.029,0.655,0.084c0,0.015,0,0.037-0.008,0.06
               c-0.017,0.137-0.037,0.305,0.313,0.35c0.182,0.023,0.326,0.038,0.471,0.047c0.139,0.015,0.274,0.022,0.427,0.029
               c0.13,0.008,0.214,0,0.282-0.007c0.092-0.007,0.151-0.015,0.25,0.03c0.131,0.061,0.236,0.069,0.336,0.077
               c0.062,0,0.106,0.007,0.145,0.022c0.068,0.022,0.131,0.03,0.191,0.007c0.053-0.015,0.099-0.046,0.152-0.091
               c0.021-0.016,0.045-0.031,0.066-0.046c0.1-0.053,0.184-0.106,0.122-0.266c-0.015-0.039-0.015-0.084-0.022-0.115
               c-0.016-0.098-0.029-0.167-0.158-0.258c-0.047-0.031-0.107-0.054-0.168-0.084c-0.153-0.062-0.328-0.137-0.306-0.29
               c0.021-0.099,0.038-0.175,0.054-0.228c0.03-0.099,0.037-0.145-0.008-0.198c-0.008-0.008-0.008-0.023-0.008-0.03
               c0.008-0.024,0.021-0.054,0.038-0.069c0.021-0.031,0.106-0.144,0.159-0.251c0.037-0.077,0.068-0.145,0.068-0.19
               c0-0.008-0.022-0.008-0.016-0.008c0.016,0,0.046,0.015,0.084,0.037c0.016,0.016,0.029,0.024,0.038,0.031
               c0.121,0.084,0.327,0.221,0.524,0.237c0.068,0.007,0.168,0.03,0.26,0.053c0.152,0.038,0.297,0.083,0.352,0.106
               c0.028,0.008,0.145,0.03,0.295,0.054c0.275,0.046,0.687,0.122,0.893,0.198c0.242,0.091,0.357,0.273,0.449,0.419
               c0.021,0.045,0.045,0.084,0.067,0.113l0.022,0.023c0.099,0.145,0.129,0.191,0.435,0.343c0.252,0.121,0.366,0.145,0.532,0.175
               c0.046,0.008,0.092,0.016,0.152,0.03l0.022,0.008c0.251,0.054,0.396,0.084,0.729-0.008c0.168-0.045,0.268-0.098,0.344-0.144
               c0.076-0.038,0.129-0.076,0.221-0.069c0.123,0.008,0.428,0.031,0.701,0.069c0.174,0.023,0.342,0.053,0.439,0.084
               c0.123,0.046,0.168,0.076,0.213,0.114c0.056,0.038,0.109,0.083,0.277,0.137c0.129,0.046,0.256,0.054,0.362,0.061
               c0.138,0.007,0.252,0.007,0.306,0.084c0.062,0.099,0.32,0.251,0.603,0.365c0.252,0.107,0.532,0.19,0.708,0.19
               c0.121,0,0.229-0.008,0.335-0.015c0.222-0.015,0.427-0.031,0.647,0.024c0.336,0.075,0.356,0.06,0.577-0.084l0.046-0.039
               c0.1-0.068,0.258-0.122,0.411-0.167c0.214-0.069,0.403-0.13,0.457-0.244c0.03-0.068,0.121-0.16,0.214-0.244
               c0.114-0.106,0.235-0.197,0.266-0.197c0.047-0.009,0.244,0.007,0.428,0.061c0.122,0.03,0.234,0.076,0.297,0.129
               c0.122,0.099,0.26,0.06,0.402,0.015c0.121-0.038,0.26-0.076,0.389-0.031c0.146,0.047,0.223,0.099,0.29,0.153l0.017,0.015
               c-0.017,0.144-0.084,0.739-0.084,0.914c0,0.121,0.053,0.19,0.098,0.244c0.023,0.038,0.055,0.068,0.046,0.106
               c-0.007,0.038-0.021,0.076-0.037,0.106c-0.038,0.099-0.076,0.176,0.017,0.229c0.036,0.022,0.067,0.06,0.104,0.099
               c0.047,0.045,0.106,0.106,0.192,0.168c0.111,0.084,0.228,0.038,0.371-0.031c0.068-0.031,0.152-0.068,0.243-0.099
               c0.146-0.038,0.222,0.015,0.306,0.061c0.076,0.045,0.151,0.091,0.267,0.099c0.206,0.023,0.289-0.122,0.342-0.213
               c0.015-0.015,0.009-0.031,0.015-0.031c0.018-0.008,0.146,0.031,0.283,0.084c0.122,0.038,0.235,0.091,0.281,0.122
               c0.061,0.046,0.146,0.075,0.251,0.114c0.093,0.031,0.198,0.069,0.312,0.122c0.131,0.061,0.283,0.16,0.429,0.266
               c0.146,0.099,0.28,0.206,0.372,0.282c0.016,0.016,0.029,0.023,0.029,0.038v0.007c-0.006,0.009-0.029,0.024-0.052,0.032
               c-0.084,0.037-0.206,0.06-0.305,0.068c-0.123,0.016-0.183,0-0.291-0.031c-0.052-0.015-0.12-0.037-0.22-0.06
               c-0.205-0.047-0.289,0.023-0.373,0.083c-0.038,0.031-0.076,0.061-0.121,0.061c-0.023,0.008-0.047,0.008-0.077,0.008
               c-0.144,0.015-0.228,0.015-0.304,0.137c-0.047,0.068-0.047,0.068-0.084,0.053l-0.008-0.007c-0.055-0.022-0.113,0-0.184,0.045
               c-0.076,0.054-0.16,0.145-0.206,0.191c-0.053,0.06-0.062,0.121-0.053,0.198c0.015,0.076,0.046,0.151,0.091,0.25
               c0.016,0.039,0,0.062-0.038,0.077c-0.038,0.022-0.104,0.038-0.167,0.053c-0.076,0.015-0.15,0.031-0.197,0.053
               c-0.047,0.031-0.106,0.046-0.168,0.046c-0.076,0-0.146-0.015-0.183-0.038c-0.008-0.008-0.017-0.008-0.022-0.016
               c-0.047-0.014-0.1-0.007-0.146,0.023c-0.038,0.024-0.077,0.061-0.114,0.115c-0.015,0.038-0.037,0.068-0.053,0.114
               c-0.023,0.076-0.046,0.084-0.061,0.084c-0.039,0-0.069,0.007-0.115,0.053c-0.008,0.015-0.023,0.038-0.038,0.053
               c-0.062,0.076-0.138,0.176-0.306,0.206c-0.152,0.03-0.336-0.038-0.479-0.091c-0.063-0.023-0.104-0.046-0.152-0.053
               c-0.045-0.016-0.091-0.039-0.137-0.069c-0.092-0.046-0.205-0.107-0.381-0.122c-0.176-0.016-0.221,0.038-0.229,0.144v0.077
               c0,0.068,0,0.144-0.021,0.22c-0.068,0.198,0.053,0.289,0.189,0.396c0.053,0.038,0.121,0.092,0.168,0.153
               c0.053,0.069,0.121,0.114,0.183,0.145c0.092,0.053,0.168,0.099,0.129,0.281c-0.021,0.13-0.104,0.145-0.204,0.16
               c-0.138,0.031-0.29,0.061-0.359,0.336c-0.037,0.174-0.158,0.304-0.312,0.395c-0.222,0.137-0.502,0.205-0.669,0.213
               c-0.068,0-0.123,0.007-0.176,0.015c-0.168,0.016-0.305,0.023-0.831,0c-0.677-0.03-1.156,0.427-1.354,0.617l-0.039,0.03
               c-0.074,0.069-0.129,0.115-0.175,0.115c-0.03,0.008-0.077-0.024-0.129-0.099c-0.114-0.152-0.306-0.115-0.474-0.076
               c-0.062,0.008-0.12,0.022-0.168,0.022c-0.062,0-0.144-0.045-0.258-0.098c-0.138-0.077-0.32-0.168-0.549-0.214
               c-0.13-0.03-0.242-0.045-0.343-0.068c-0.212-0.03-0.373-0.054-0.617-0.168l-0.067-0.03c-0.32-0.153-0.487-0.236-0.808-0.236
               c-0.161,0-0.336,0.015-0.493,0.03c-0.162,0.015-0.313,0.038-0.436,0.061c-0.122,0.031-0.227,0.046-0.428,0.015
               c-0.197-0.023-0.494-0.099-0.975-0.258c-0.625-0.198-0.859-0.092-1.013-0.016c-0.077,0.039-0.121,0.061-0.206,0.008
               c-0.139-0.084-0.242-0.206-0.352-0.327c-0.09-0.099-0.183-0.206-0.305-0.305c-0.174-0.138-0.229-0.229-0.326-0.365
               c-0.054-0.077-0.115-0.176-0.221-0.305c-0.16-0.205-0.281-0.198-0.441-0.19c-0.121,0.007-0.267,0.015-0.479-0.115
               c-0.272-0.167-0.519-0.206-0.762-0.235c-0.183-0.031-0.372-0.055-0.587-0.137c-0.418-0.168-0.516-0.138-0.646-0.1
               c-0.03,0.008-0.063,0.023-0.1,0.031c-0.076,0.007-0.235-0.031-0.403-0.084c-0.197-0.076-0.41-0.175-0.54-0.259
               c-0.151-0.099-0.137-0.137-0.114-0.228c0.016-0.039,0.022-0.084,0.037-0.138c0.039-0.228,0-0.365-0.045-0.495l-0.015-0.045
               c-0.024-0.076-0.077-0.175-0.146-0.273c-0.084-0.107-0.184-0.222-0.274-0.313c-0.076-0.084-0.152-0.176-0.222-0.275
               c-0.076-0.099-0.151-0.198-0.244-0.289c-0.09-0.099-0.174-0.122-0.258-0.144c-0.082-0.023-0.16-0.039-0.229-0.145
               c-0.113-0.175-0.229-0.152-0.373-0.129c-0.076,0.014-0.152,0.03-0.236,0.022c-0.145-0.022-0.242-0.077-0.334-0.115
               c-0.068-0.03-0.132-0.061-0.19-0.076c-0.076-0.015-0.13-0.038-0.183-0.084c-0.063-0.045-0.115-0.114-0.184-0.221
               c-0.092-0.144-0.152-0.144-0.213-0.144c-0.039,0-0.076,0-0.153-0.092C177.703,27.942,177.649,27.858,177.627,27.805z"/>
             <path fill="#808184" d="M208.463,36.348h-0.062c-0.158,0.039-0.455,0-0.652,0.114
               c-0.207,0.114-0.236,0.145-0.375,0.282c-0.137,0.137-0.242,0.206-0.313,0.35c-0.068,0.146-0.252,0.175-0.326,0.213
               c-0.076,0.031-0.274-0.075-0.139-0.205c0.139-0.122,0.344-0.145,0.237-0.221c-0.101-0.084-0.282-0.114-0.328-0.19
               c-0.046-0.084,0.017-0.488,0.068-0.602c0.062-0.107,0.235-0.426-0.13-0.555c-0.373-0.138-0.42-0.222-0.653-0.175
               c-0.237,0.045-0.358,0.175-0.358,0.304c0,0.137,0.146,0.259,0.021,0.38c-0.128,0.13-0.252,0.137-0.389,0.252
               c-0.129,0.114-0.213,0.205-0.242,0.418c-0.037,0.214,0.045,0.283-0.159,0.344c-0.199,0.067-0.282,0.113-0.382,0.106
               c-0.1-0.015-0.357-0.19-0.35,0.069c0.014,0.258,0.09,0.403,0.229,0.532c0.129,0.137,0.289,0.229,0.472,0.244
               c0.175,0.008,0.198-0.221,0.435-0.069c0.236,0.145,0.236,0.175,0.351,0.259c0.114,0.077,0.076,0.312,0.206,0.457
               c0.12,0.145,0.029,0.205,0.355,0.205c0.328,0,0.26-0.205,0.42-0.304c0.152-0.099,0.268-0.373,0.388-0.305
               c0.132,0.069,0.306,0.031,0.397,0.122c0.09,0.092,0.28,0.145,0.396,0.145c0.106,0,0.327,0.038,0.448,0.083
               c0.123,0.047,0.609,0.031,0.519,0.168c-0.092,0.137-0.015,0.305-0.204,0.358c-0.191,0.061-0.527,0-0.763,0.214
               c-0.235,0.212-0.364,0.227-0.39,0.448c-0.015,0.229-0.053,0.366-0.197,0.427c-0.145,0.069-0.258,0.061-0.273,0.297
               c-0.008,0.235-0.335,0.35-0.099,0.525c0.235,0.183,0.532,0.092,0.632,0.236c0.1,0.152,0.83,0.777,1.034,1.158
               c0.214,0.388,0.329,0.48,0.366,0.601c0.047,0.122,0.281,0.114,0.357,0.19c0.077,0.077,0.077,0.16,0.184,0.267
               c0.105,0.106,0.738,0.342,0.549,0.381c-0.189,0.03-0.237-0.069-0.465-0.069c-0.236,0-0.273-0.008-0.366-0.061
               c-0.099-0.061-0.403-0.061-0.235,0.03c0.16,0.092,0.273,0.084,0.381,0.191c0.104,0.106,0.289,0.038,0.496,0.243
               c0.204,0.207,0.555,0.313,0.449,0.435c-0.107,0.114-0.199,0.083-0.252,0.137c-0.063,0.045-0.305,0.175-0.344,0.282
               c-0.03,0.114-0.077,0.19-0.189,0.182c-0.107-0.007-0.152,0.206,0.037,0.228c0.191,0.024,0.391-0.16,0.525-0.16
               c0.139,0,0.16,0.123,0.305,0.214c0.146,0.091,0.465,0,0.404,0.205c-0.055,0.198-0.357,0.191-0.153,0.312
               c0.198,0.122,0.39,0.351,0.222,0.358c-0.166,0.015-0.351-0.022-0.242,0.099c0.098,0.13,0.084,0.198,0.104,0.366
               c0.022,0.167,0.465,0.503,0.022,0.472c-0.434-0.038-0.434,0.053-0.434,0.152c0,0.107-0.061,0.206-0.068,0.343
               c-0.016,0.129,0.176,0.221-0.092,0.328c-0.267,0.098-0.146,0.472-0.267,0.593c-0.13,0.122-0.261,0.077-0.197,0.245
               c0.067,0.159-0.03,0.257-0.022,0.364c0.007,0.099,0.16,0.183,0.175,0.282c0.022,0.091-0.122,0.091-0.214,0.137
               c-0.084,0.046-0.068,0.221-0.077,0.297c-0.007,0.076-0.173,0.168-0.173,0.168s-0.092,0.403-0.223,0.433
               c-0.137,0.039-0.351,0.16-0.41,0.351c-0.053,0.19-0.267,0.281-0.381,0.335c-0.113,0.06-0.138,0.213-0.26,0.342
               c-0.121,0.123-0.197,0.054-0.305,0.244c-0.098,0.19,0.016,0.281-0.26,0.259c-0.266-0.024-0.213-0.137-0.493-0.024
               c-0.282,0.116-0.244,0.214-0.456,0.26c-0.215,0.046-0.512,0.046-0.656,0.099c-0.145,0.061-0.197-0.031-0.289-0.129
               c-0.092-0.107-0.38-0.297-0.373-0.114c0.008,0.182,0.19,0.373,0.206,0.502c0.007,0.137-0.159,0.16-0.282,0.205
               c-0.129,0.046,0.008,0.145-0.252,0.237c-0.258,0.091-0.267-0.229-0.607-0.016c-0.336,0.221-0.307,0.282-0.473,0.297
               c-0.168,0.008-0.457,0.008-0.563,0.191c-0.099,0.182-0.296,0.091-0.213,0.312c0.093,0.228,0.251,0.228,0.206,0.327
               c-0.047,0.099,0.029,0.328-0.19,0.328c-0.229,0-0.206-0.145-0.351-0.442c-0.145-0.29-0.236-0.282-0.221-0.548
               c0.008-0.267-0.062-0.481-0.328-0.351c-0.273,0.137-0.441,0.229-0.563,0.168c-0.113-0.046-0.427-0.137-0.563,0.015
               c-0.1-0.068-0.197-0.13-0.236-0.175c-0.045-0.061-0.105-0.083-0.176-0.114c-0.074-0.031-0.159-0.069-0.243-0.168
               c-0.091-0.114-0.183-0.159-0.281-0.213c-0.069-0.03-0.144-0.068-0.237-0.144c-0.104-0.077-0.052-0.168,0-0.252
               c0.039-0.061,0.077-0.129,0.069-0.198c-0.022-0.099-0.099-0.144-0.205-0.152c-0.084-0.015-0.196,0-0.319,0.023
               c-0.168,0.023-0.297-0.091-0.457-0.237c-0.031-0.029-0.062-0.06-0.092-0.084c-0.189-0.167-0.313-0.045-0.41,0.046
               c-0.022,0.023-0.045,0.046-0.054,0.055c-0.046,0.014-0.084,0.06-0.123,0.121c-0.045,0.076-0.114,0.168-0.213,0.183
               c-0.099,0.023-0.188,0.037-0.267,0.068c-0.084,0.023-0.16,0.068-0.229,0.13c-0.056,0.045-0.076,0.022-0.121-0.023
               c-0.039-0.039-0.092-0.084-0.176-0.138c-0.13-0.083-0.189-0.06-0.236,0c-0.016,0.031-0.021,0.061-0.037,0.099
               c-0.008,0.046-0.022,0.092-0.055,0.114c-0.037,0.039-0.092,0-0.15-0.068c-0.039-0.03-0.076-0.068-0.123-0.098
               c-0.031-0.024-0.061-0.039-0.092-0.047c-0.045-0.014-0.082-0.014-0.129,0.008c-0.029,0.015-0.068,0.039-0.092,0.076
               c-0.037,0.046-0.063,0.115-0.084,0.175c-0.016,0.062-0.068,0.046-0.129,0.031c-0.054-0.023-0.113-0.038-0.189-0.038
               c-0.139,0-0.168,0.091-0.168,0.22c0,0.054,0.008,0.123,0.016,0.184c0,0.038,0.007,0.068,0.007,0.106
               c0.017,0.145,0.099,0.244,0.185,0.335c0.047,0.045,0.092,0.091,0.105,0.137c0.007,0.007,0.007,0.007,0.007,0.015
               c0.015,0.054,0.009,0.099-0.007,0.137c-0.023,0.045-0.053,0.077-0.084,0.084l-0.008,0.007h-0.03
               c-0.03,0-0.062-0.014-0.084-0.029c-0.021-0.017-0.046-0.039-0.053-0.069c-0.007-0.008-0.007-0.023-0.007-0.046
               c0.014-0.137-0.115-0.198-0.223-0.251c-0.037-0.015-0.068-0.031-0.084-0.046c-0.076-0.053-0.175,0.015-0.273,0.076
               c-0.074,0.046-0.151,0.099-0.184,0.084c-0.029-0.008-0.066-0.015-0.099-0.023c-0.077-0.015-0.159-0.031-0.29-0.091
               c-0.105-0.053-0.113-0.114-0.12-0.191c-0.008-0.053-0.017-0.122-0.055-0.182c-0.067-0.123-0.177-0.114-0.306-0.107
               c-0.074,0-0.168,0.008-0.234-0.03c-0.047-0.023-0.047-0.031-0.038-0.046c0-0.015,0.015-0.031,0.021-0.046
               c0.039-0.06,0.084-0.129,0.078-0.228c0-0.084,0.021-0.137,0.045-0.183c0.03-0.069,0.062-0.121,0.045-0.221
               c-0.015-0.099-0.084-0.137-0.168-0.189c-0.074-0.039-0.158-0.092-0.229-0.199c-0.09-0.152-0.145-0.175-0.188-0.198
               c-0.023-0.015-0.055-0.023-0.084-0.068c-0.039-0.038-0.054-0.099-0.07-0.153c-0.028-0.099-0.059-0.198-0.176-0.289
               c-0.158-0.129-0.22-0.054-0.356,0.107c-0.045,0.053-0.104,0.121-0.183,0.197c-0.045,0.046-0.084,0.069-0.105,0.069
               c-0.018,0.007-0.023,0-0.031-0.007c-0.008-0.016-0.021-0.039-0.021-0.062c-0.022-0.091-0.008-0.22,0.029-0.327
               c0.061-0.16-0.016-0.259-0.074-0.35c-0.039-0.054-0.077-0.107-0.063-0.16c0.03-0.084,0.03-0.152,0.038-0.236
               c0-0.076,0-0.168,0.046-0.274c0.028-0.099,0.112-0.153,0.196-0.205c0.101-0.07,0.198-0.13,0.261-0.252
               c0.09-0.182,0.029-0.548-0.017-0.861c-0.015-0.106-0.03-0.212-0.037-0.289c-0.015-0.259-0.207-0.388-0.375-0.495
               c-0.036-0.023-0.074-0.046-0.104-0.075c-0.076-0.054-0.137-0.13-0.183-0.214c-0.038-0.084-0.069-0.183-0.077-0.297
               c-0.021-0.236-0.259-0.221-0.434-0.206c-0.047,0-0.092,0.008-0.116,0c-0.06-0.015-0.248-0.038-0.438-0.061
               c-0.152-0.015-0.313-0.038-0.412-0.038c-0.016-0.007-0.023-0.015-0.023-0.021c0.009-0.031,0.018-0.069,0.031-0.115
               c0.039-0.122,0.084-0.274-0.016-0.396c-0.113-0.144-0.221-0.091-0.297-0.053c-0.016,0.015-0.029,0.023-0.045,0.023
               c0,0,0.008,0,0.008-0.008c-0.017-0.053-0.017-0.091-0.017-0.129c0.009-0.084,0.017-0.16-0.106-0.266
               c-0.099-0.1-0.197-0.077-0.289-0.008c-0.076,0.061-0.146,0.167-0.213,0.267c0,0-0.008,0.007-0.008,0.014
               c-0.046,0.047-0.113,0.054-0.189,0.038c-0.092-0.014-0.184-0.061-0.26-0.106c-0.029-0.023-0.062-0.038-0.084-0.061
               c-0.145-0.122-0.228-0.046-0.297,0.015l-0.029,0.031c-0.055,0-0.076,0.046-0.114,0.114c-0.046,0.076-0.106,0.198-0.251,0.259
               c-0.146,0.053-0.223,0.144-0.29,0.221c-0.054,0.061-0.099,0.106-0.159,0.114c-0.16,0.008-0.221,0.122-0.305,0.274
               c-0.023,0.038-0.039,0.076-0.063,0.107c-0.021,0.046-0.061,0.084-0.113,0.114c-0.029-0.023-0.054-0.046-0.068-0.061
               c-0.053-0.053-0.105-0.084-0.197-0.099c-0.076-0.023-0.184-0.031-0.327-0.038h-0.161c-0.211-0.008-0.41-0.015-0.791-0.182
               c-0.352-0.161-0.548-0.084-0.707-0.024c-0.07,0.024-0.123,0.046-0.184,0.046c-0.063,0-0.107,0-0.146-0.008
               c-0.105-0.007-0.166-0.007-0.35,0.054c-0.093,0.03-0.152,0.084-0.206,0.137c-0.084-0.008-0.159,0.038-0.229,0.099
               c-0.037,0.03-0.067,0.068-0.098,0.107c-0.023,0.023-0.023,0.045-0.029,0.045c-0.039,0-0.352-0.015-0.655-0.076
               c-0.16-0.03-0.32-0.068-0.427-0.122c-0.274-0.137-0.297-0.076-0.358,0.069c-0.022,0.037-0.047,0.091-0.084,0.153
               c-0.038,0.044-0.054,0.052-0.067,0.044c-0.031-0.022-0.062-0.099-0.092-0.174c-0.023-0.046-0.046-0.107-0.076-0.153
               c-0.068-0.122-0.16-0.107-0.281-0.099c-0.076,0.007-0.176,0.023-0.289-0.008c-0.068-0.022-0.084-0.045-0.076-0.068
               c0-0.015,0.015-0.038,0.023-0.053c0.037-0.069,0.074-0.145,0.014-0.214c-0.076-0.091-0.145-0.061-0.213-0.03
               c-0.037,0.023-0.084,0.038-0.129,0.016c-0.054-0.023-0.1-0.054-0.146-0.084c-0.084-0.054-0.168-0.107-0.306-0.145
               c-0.082-0.023-0.082-0.053-0.082-0.107c0.007-0.053,0.007-0.121-0.031-0.198c-0.068-0.167-0.229-0.099-0.365-0.03
               c-0.054,0.023-0.105,0.046-0.138,0.046c-0.067-0.009-0.082-0.046-0.19-0.206c-0.021-0.038-0.045-0.084-0.084-0.144
               c-0.129-0.199-0.38-0.275-0.584-0.336c-0.123-0.03-0.23-0.06-0.261-0.106c-0.062-0.069-0.106-0.115-0.151-0.153
               c-0.037-0.03-0.084-0.045-0.131-0.068c-0.015-0.008-0.037-0.015-0.052-0.031c-0.054-0.037-0.114-0.083-0.29-0.06
               c-0.092,0.007-0.197,0.045-0.289,0.076c-0.115,0.046-0.214,0.076-0.251,0.06c-0.062-0.03-0.176-0.098-0.267-0.19
               c-0.053-0.061-0.092-0.122-0.1-0.183c-0.009-0.152-0.152-0.144-0.313-0.144c-0.123,0-0.252,0-0.32-0.061
               c-0.1-0.084-0.281-0.19-0.465-0.274c-0.176-0.084-0.357-0.152-0.464-0.168h-0.009c-0.075,0.008-0.105-0.007-0.129-0.037
               c-0.021-0.047-0.029-0.107-0.029-0.191v-0.039c0.008-0.159,0.008-0.304-0.215-0.502c-0.098-0.091-0.098-0.144-0.061-0.182
               c0.03-0.031,0.075-0.054,0.114-0.084c0.017,0,0.022-0.008,0.037-0.015c0.038-0.023,0.084-0.008,0.146,0.022
               c0.068,0.023,0.16,0.062,0.282,0.062c0.183,0,0.22-0.077,0.22-0.168c0-0.03-0.007-0.053-0.007-0.084
               c0-0.023-0.008-0.046-0.008-0.068c0-0.076-0.03-0.137-0.069-0.198c-0.029-0.053-0.068-0.122-0.076-0.191
               c-0.015-0.137-0.121-0.16-0.242-0.183c-0.063-0.015-0.131-0.03-0.182-0.068l-0.01-0.007c-0.061-0.054-0.1-0.145-0.113-0.229
               c-0.017-0.084-0.017-0.159,0.008-0.191c0.061-0.069,0.15-0.069,0.258-0.076h0.023c0.137-0.008,0.146-0.114,0.146-0.243
               c0.007-0.054,0.007-0.099,0.015-0.145c0.016-0.068,0.047-0.077,0.092-0.091c0.047-0.015,0.1-0.031,0.16-0.092
               c0.076-0.068,0.105-0.13,0.105-0.198c0.008-0.068-0.016-0.137-0.045-0.221c-0.029-0.075-0.016-0.106,0.008-0.152
               c0.021-0.038,0.045-0.091,0.066-0.167c0.024-0.122-0.006-0.16-0.074-0.175c-0.016-0.008-0.039-0.008-0.062-0.008
               c-0.046-0.008-0.106-0.008-0.168-0.045c-0.099-0.069-0.167-0.069-0.228-0.069c-0.039-0.008-0.084-0.008-0.146-0.046
               c-0.061-0.038-0.082-0.068-0.099-0.099c-0.029-0.038-0.06-0.075-0.175-0.144c-0.092-0.054-0.213-0.054-0.336-0.023
               c-0.188,0.037-0.387,0.136-0.472,0.182c-0.075,0.046-0.12,0.122-0.167,0.199c-0.046,0.068-0.084,0.136-0.137,0.152
               c0,0-0.291-0.053-0.336-0.068c-0.084-0.023-0.153-0.039-0.198-0.047c-0.046,0-0.112-0.03-0.183-0.068
               c-0.076-0.038-0.16-0.076-0.221-0.076c-0.047,0-0.084-0.016-0.115-0.03c-0.037-0.024-0.066-0.069-0.105-0.123
               c-0.053-0.098-0.145-0.121-0.236-0.136c-0.061-0.008-0.121-0.023-0.166-0.054c-0.045-0.03-0.031-0.06-0.017-0.099
               c0.017-0.045,0.038-0.091,0.017-0.144c-0.017-0.054-0.045-0.084-0.084-0.115c-0.037-0.038-0.084-0.083-0.106-0.167
               c-0.039-0.152-0.289-0.221-0.442-0.259c-0.045-0.015-0.076-0.023-0.084-0.03c-0.045-0.031-0.113-0.016-0.189,0
               c-0.045,0.007-0.092,0.015-0.129,0.015c-0.039,0-0.068-0.015-0.092-0.039c-0.021-0.038-0.045-0.084-0.054-0.137
               c-0.008-0.053,0.022-0.129,0.048-0.205c0.028-0.076,0.059-0.159,0.051-0.229c-0.006-0.098-0.015-0.106-0.074-0.167
               c-0.031-0.023-0.069-0.054-0.123-0.114c-0.1-0.114-0.105-0.206-0.113-0.267c0-0.03-0.007-0.06-0.021-0.083
               c0-0.008,0.006-0.031,0.016-0.054c0.016-0.03,0.036-0.068,0.036-0.121c0-0.092-0.066-0.106-0.144-0.13
               c-0.039-0.008-0.07-0.015-0.092-0.031c-0.047-0.037-0.105-0.037-0.16-0.037c-0.046-0.008-0.092-0.008-0.105-0.031
               c-0.069-0.076-0.129-0.03-0.213,0.023c-0.039,0.03-0.084,0.061-0.139,0.076c-0.037,0.015-0.053-0.008-0.068-0.046
               c-0.006-0.022-0.006-0.046-0.016-0.076c-0.008-0.038-0.022-0.068-0.029-0.099c-0.03-0.045-0.03-0.061-0.023-0.091v-0.069
               c-0.007-0.084-0.046-0.099-0.091-0.129c-0.029-0.015-0.068-0.038-0.106-0.084c-0.046-0.053-0.046-0.107-0.039-0.145
               c0.032,0,0.063-0.007,0.092-0.015c0.063-0.031,0.115-0.068,0.131-0.137c0.023-0.076-0.039-0.137-0.1-0.198
               c-0.031-0.03-0.068-0.069-0.054-0.084c0.022-0.022,0.047-0.046,0.067-0.069c0.045-0.06,0.093-0.113,0.168-0.106
               c0.107,0.016,0.16-0.061,0.214-0.137c0.03-0.046,0.053-0.083,0.092-0.106c0.062-0.039,0.144-0.076,0.205-0.107
               c0.047-0.015,0.084-0.023,0.092-0.023c0.045,0.016,0.084-0.015,0.129-0.053c0.046-0.038,0.106-0.091,0.153-0.061
               c0.035,0.03,0.061,0.099,0.068,0.16c0.029,0.092,0.045,0.167,0.136,0.152c0.054-0.008,0.092-0.037,0.138-0.068
               c0.038-0.031,0.092-0.069,0.137-0.053c0.008,0.007,0.017,0.007,0.022,0.007c0.069,0.015,0.138,0,0.19-0.03
               c0.062-0.031,0.105-0.077,0.114-0.107v-0.008h0.009v-0.076c0-0.053,0-0.13,0.006-0.198c0.011-0.091,0.045-0.174,0.129-0.159
               c0.093,0.007,0.162,0.022,0.23,0.037c0.113,0.023,0.204,0.047,0.326,0.023c0.114-0.015,0.205-0.075,0.273-0.122
               c0.045-0.022,0.084-0.045,0.092-0.045c0.068,0,0.113-0.091,0.16-0.183c0.037-0.069,0.074-0.137,0.106-0.16
               c0.021-0.015,0.052-0.038,0.091-0.068c0.092-0.084,0.229-0.199,0.358-0.214c0.136-0.015,0.174-0.076,0.213-0.129
               c0.007-0.023,0.021-0.039,0.052-0.046c0.032-0.007,0.069-0.007,0.1-0.015c0.084,0,0.152-0.007,0.176-0.076
               c0-0.015,0.008-0.023,0.008-0.038c0.031-0.069,0.063-0.137-0.029-0.237c-0.054-0.052-0.1-0.09-0.129-0.113
               c0-0.023,0-0.046-0.008-0.077c-0.018-0.061,0.053-0.129,0.121-0.198c0.113-0.114,0.229-0.236,0.121-0.403
               c-0.084-0.122-0.129-0.206-0.176-0.289c-0.053-0.084-0.1-0.176-0.184-0.305c-0.074-0.106-0.099-0.228-0.121-0.35
               c-0.029-0.16-0.062-0.305-0.197-0.403c-0.068-0.054-0.152-0.099-0.236-0.145c-0.105-0.053-0.205-0.107-0.213-0.13
               c0,0,0.016-0.007,0.063-0.023c0.092-0.023,0.196-0.068,0.318-0.122c0.289-0.129,0.633-0.274,0.717-0.152
               c0.016,0.023,0.029,0.046,0.047,0.061c0.129,0.19,0.174,0.267,0.379,0.183c0.055-0.031,0.139,0,0.205,0.023
               c0.062,0.023,0.115,0.038,0.152,0.03c0.063-0.015,0.084-0.068,0.055-0.197c-0.047-0.168-0.199-0.29-0.32-0.405
               c-0.092-0.075-0.168-0.144-0.159-0.182c0.028-0.092,0.075-0.282,0.106-0.48c0.016-0.129,0.021-0.266,0.008-0.38
               c0-0.061-0.017-0.115-0.022-0.168c-0.017-0.091-0.032-0.167-0.017-0.191c0-0.007,0.022,0,0.067,0.031
               c0.093,0.046,0.161,0.092,0.214,0.129c0.084,0.062,0.137,0.107,0.229,0.1c0.021-0.008,0.052-0.008,0.1-0.016
               c0.184-0.03,0.516-0.076,0.563-0.007c0.016,0.022,0.03,0.045,0.047,0.061c0.028,0.046,0.067,0.075,0.112,0.084
               c0.039,0.015,0.084,0.007,0.129-0.016c0.017-0.007,0.023-0.015,0.039-0.022c0.023-0.023,0.062-0.046,0.1-0.069
               c0.075-0.053,0.152-0.107,0.206-0.175c0.076-0.115,0.045-0.183-0.039-0.351l-0.028-0.053c-0.023-0.045-0.049-0.091-0.078-0.137
               c-0.076-0.137-0.152-0.274-0.136-0.38c0.007-0.039,0.015-0.084,0.015-0.123v-0.06c0.008-0.054,0.022-0.084,0.1-0.077
               c0.029,0,0.052,0.009,0.068,0.009c0.197,0.014,0.334,0.029,0.373-0.153c0.016-0.099-0.023-0.183-0.062-0.259
               c-0.015-0.045-0.038-0.091-0.03-0.099c0.03-0.069,0.1-0.182,0.145-0.244c0.021,0.008,0.055,0.023,0.084,0.03
               c0.152,0.032,0.222,0.047,0.357,0.032c0.022,0.052,0.084,0.152,0.205,0.311c0.1,0.13,0.168,0.13,0.229,0.13
               c0.038,0,0.067,0.007,0.129,0.099c0.075,0.114,0.138,0.198,0.196,0.251c0.07,0.054,0.14,0.076,0.222,0.099
               c0.062,0.015,0.114,0.038,0.175,0.069c0.1,0.045,0.207,0.091,0.366,0.121c0.106,0.015,0.19,0,0.267-0.015
               c0.106-0.022,0.198-0.037,0.274,0.076c0.091,0.138,0.183,0.168,0.282,0.191c0.074,0.023,0.145,0.038,0.22,0.114
               c0.084,0.091,0.16,0.19,0.229,0.281c0.076,0.1,0.151,0.198,0.234,0.29c0.092,0.083,0.184,0.198,0.26,0.297
               c0.063,0.091,0.114,0.175,0.139,0.244l0.008,0.045c0.045,0.122,0.084,0.244,0.045,0.442c-0.016,0.053-0.023,0.099-0.03,0.129
               c-0.046,0.153-0.062,0.199,0.153,0.343c0.129,0.083,0.35,0.19,0.563,0.266c0.185,0.069,0.351,0.107,0.449,0.092
               c0.047-0.008,0.076-0.023,0.113-0.03c0.107-0.031,0.199-0.062,0.58,0.091c0.229,0.091,0.418,0.121,0.607,0.144
               c0.236,0.031,0.465,0.061,0.723,0.221c0.246,0.153,0.404,0.145,0.543,0.13c0.129-0.008,0.228-0.008,0.355,0.152
               c0.1,0.13,0.16,0.221,0.215,0.297c0.098,0.152,0.168,0.244,0.342,0.388c0.123,0.092,0.215,0.198,0.299,0.298
               c0.113,0.129,0.22,0.25,0.371,0.35c0.129,0.075,0.199,0.045,0.306-0.009c0.138-0.068,0.351-0.167,0.937,0.023
               c0.488,0.161,0.785,0.236,0.99,0.267c0.215,0.031,0.334,0.008,0.463-0.023c0.123-0.022,0.267-0.045,0.42-0.061
               c0.162-0.015,0.326-0.022,0.487-0.022c0.298,0,0.457,0.075,0.769,0.221l0.068,0.03c0.252,0.122,0.42,0.145,0.641,0.183
               c0.091,0.015,0.199,0.029,0.334,0.062c0.223,0.045,0.39,0.129,0.527,0.205c0.12,0.061,0.219,0.107,0.303,0.107
               c0.054,0,0.123-0.015,0.184-0.023c0.146-0.031,0.297-0.061,0.374,0.038c0.084,0.106,0.151,0.144,0.228,0.137
               c0.068,0,0.139-0.053,0.229-0.145l0.037-0.03c0.19-0.175,0.647-0.617,1.286-0.587c0.527,0.031,0.672,0.016,0.84,0
               c0.052-0.007,0.105-0.007,0.175-0.015c0.183-0.008,0.479-0.076,0.715-0.228c0.175-0.106,0.313-0.252,0.358-0.457
               c0.054-0.213,0.176-0.236,0.281-0.259c0.129-0.023,0.244-0.046,0.281-0.236c0.063-0.259-0.053-0.32-0.176-0.388
               c-0.052-0.031-0.113-0.069-0.158-0.122c-0.054-0.076-0.123-0.129-0.185-0.175c-0.104-0.076-0.197-0.152-0.159-0.274
               c0.038-0.099,0.038-0.19,0.038-0.259c-0.008-0.023-0.008-0.046,0-0.069c0-0.038,0.022-0.061,0.106-0.053
               c0.16,0.015,0.258,0.068,0.343,0.114c0.054,0.031,0.106,0.06,0.168,0.077c0.03,0.007,0.084,0.022,0.136,0.045
               c0.152,0.061,0.359,0.137,0.534,0.106c0.212-0.045,0.304-0.159,0.373-0.25c0.007-0.016,0.021-0.031,0.03-0.046
               c0.022-0.016,0.039-0.023,0.045-0.023c0.055-0.008,0.1-0.015,0.152-0.153c0.016-0.03,0.03-0.06,0.045-0.091
               c0.023-0.038,0.045-0.069,0.077-0.084c0.015-0.007,0.037-0.015,0.052-0.007h0.008c0.056,0.038,0.146,0.053,0.238,0.053
               c0.075,0,0.159-0.015,0.219-0.053c0.031-0.023,0.1-0.038,0.162-0.046c0.067-0.015,0.145-0.031,0.196-0.061
               c0.091-0.038,0.138-0.098,0.084-0.205c-0.038-0.092-0.067-0.168-0.084-0.228c-0.008-0.046,0-0.084,0.031-0.115
               c0.038-0.046,0.112-0.13,0.188-0.175c0.031-0.023,0.063-0.045,0.076-0.039l0.016,0.009c0.115,0.053,0.115,0.053,0.205-0.092
               c0.053-0.076,0.115-0.076,0.229-0.091h0.076c0.084-0.008,0.129-0.046,0.183-0.092c0.063-0.045,0.123-0.099,0.282-0.06
               c0.093,0.022,0.16,0.037,0.213,0.053c0.122,0.038,0.183,0.053,0.335,0.038c0.1-0.008,0.236-0.031,0.327-0.069
               c0.039-0.022,0.077-0.038,0.093-0.068c0.03-0.03,0.037-0.069,0.014-0.114c-0.008-0.023-0.028-0.046-0.061-0.077
               c-0.091-0.068-0.229-0.175-0.373-0.282c-0.152-0.106-0.306-0.213-0.441-0.273c-0.121-0.062-0.234-0.099-0.326-0.13
               c-0.092-0.031-0.168-0.061-0.221-0.099c-0.055-0.038-0.185-0.091-0.306-0.137c-0.153-0.053-0.306-0.091-0.343-0.084
               c-0.038,0.007-0.055,0.039-0.084,0.084c-0.038,0.068-0.098,0.175-0.243,0.16c-0.092-0.008-0.159-0.053-0.222-0.084
               c-0.105-0.061-0.197-0.121-0.381-0.076c-0.106,0.031-0.19,0.068-0.266,0.107c-0.113,0.053-0.197,0.091-0.275,0.038
               c-0.076-0.054-0.129-0.105-0.166-0.153c-0.047-0.045-0.084-0.083-0.122-0.114c-0.023-0.007-0.009-0.053,0.016-0.099
               c0.015-0.046,0.037-0.084,0.045-0.137c0.015-0.076-0.022-0.121-0.069-0.182c-0.028-0.046-0.074-0.1-0.074-0.183
               c0-0.152,0.061-0.647,0.084-0.853c0.053,0.031,0.113,0.061,0.212,0.091l0.039,0.009c0.297,0.083,0.624,0.167,0.784,0
               c0.068-0.077,0.19-0.138,0.305-0.191c0.122-0.069,0.236-0.122,0.259-0.191c0.022-0.076-0.067-0.175-0.175-0.282
               c-0.1-0.106-0.214-0.228-0.189-0.273c0.037-0.069,0.037-0.19,0.037-0.336c0-0.183,0-0.395,0.084-0.51
               c0.055-0.068,0.121-0.114,0.168-0.145c0.022-0.007,0.037-0.022,0.055-0.037c0.074-0.054,0.098-0.107,0-0.229
               c-0.069-0.099-0.161-0.129-0.229-0.16c-0.062-0.022-0.114-0.045-0.131-0.106c-0.021-0.1-0.065-0.175-0.13-0.229
               c-0.059-0.053-0.137-0.076-0.219-0.061c-0.055,0.008-0.152-0.008-0.215-0.038c-0.021-0.007-0.037-0.023-0.053-0.03
               c0-0.008-0.008-0.015,0-0.023c0-0.015,0.016-0.031,0.037-0.053c0.084-0.062,0.131-0.145,0.168-0.221
               c0.063-0.107,0.105-0.198,0.273-0.16c0.229,0.045,0.328,0.015,0.457-0.031c0.062-0.015,0.13-0.038,0.222-0.053
               c0.099-0.007,0.175-0.038,0.251-0.069c0.13-0.045,0.251-0.091,0.457,0.008c0.16,0.069,0.426,0.153,0.691,0.213
               c0.307,0.069,0.617,0.115,0.731,0.107c0.099,0,0.183,0,0.298,0.038c0.112,0.046,0.26,0.129,0.494,0.304
               c0.16,0.115,0.35,0.23,0.541,0.336c0.357,0.205,0.708,0.411,0.813,0.655c0.131,0.304,0.281,0.434,0.429,0.556
               c0.045,0.037,0.09,0.067,0.129,0.113c0.038,0.038,0.075,0.077,0.122,0.115c0.205,0.22,0.531,0.555,0.662,0.601
               c0.061,0.023,0.13,0.076,0.214,0.137c0.104,0.077,0.233,0.168,0.401,0.245c0.199,0.099,0.352,0.083,0.504,0.067
               c0.084-0.015,0.174-0.023,0.273-0.007c0.1,0.015,0.168,0.007,0.235,0c0.101-0.008,0.167-0.016,0.267,0.16
               c0.176,0.289,0.52,0.365,0.646,0.395c0.018,0,0.031,0.009,0.039,0.009c0.031,0.007,0.076,0,0.129-0.009
               c0.125-0.014,0.313-0.037,0.406,0.1c0.066,0.098,0.099,0.182,0.119,0.244c0.039,0.091,0.063,0.151,0.129,0.182
               c0.055,0.023,0.101,0.015,0.139,0.008c0.009-0.008,0.023-0.008,0.029-0.008c0,0,0.009,0.015,0.017,0.077
               c0,0.06-0.008,0.106-0.008,0.143c-0.022,0.13-0.029,0.183,0.204,0.26c0.078,0.022,0.139,0.046,0.191,0.06
               c0.146,0.054,0.236,0.084,0.411,0.099c0.114,0.009,0.183-0.015,0.251-0.03c0.076-0.023,0.145-0.045,0.281-0.023
               c0.175,0.023,0.291-0.121,0.391-0.267c0.066-0.083,0.129-0.167,0.188-0.167c0.038,0,0.075,0.008,0.114,0.008
               c0.136,0.007,0.25,0.015,0.342-0.03c0.008,0,0.017-0.008,0.031-0.016c0.053-0.022,0.121-0.061,0.197-0.084
               c0.092-0.022,0.184-0.022,0.213,0.084c0.047,0.176,0.106,0.244,0.16,0.312c0.038,0.039,0.066,0.076,0.092,0.13
               c0.022,0.046-0.008,0.061-0.047,0.083c-0.063,0.046-0.129,0.1-0.091,0.229c0.039,0.129,0.091,0.252,0.138,0.373
               c0.022,0.053,0.045,0.115,0.067,0.16c0.022,0.061,0.047,0.1,0.067,0.137c0.039,0.069,0.07,0.122,0.047,0.22
               c-0.014,0.061-0.039,0.108-0.067,0.153c-0.069,0.107-0.13,0.206,0,0.373c0.113,0.16,0.099,0.221,0.091,0.297
               c0,0.023-0.008,0.039-0.008,0.069c0,0.03,0,0.06,0.008,0.106c0.008,0.062,0.016,0.129,0,0.16c0,0.008-0.029,0.008-0.084-0.023
               c-0.045-0.023-0.099-0.046-0.146-0.068c-0.204-0.115-0.396-0.214-0.569-0.122c-0.105,0.069-0.13,0.115-0.15,0.153
               c-0.008,0.022-0.017,0.045-0.063,0.068c-0.037,0.03-0.099,0.03-0.149,0.038c-0.033,0-0.063,0.007-0.093,0.015
               c-0.084,0.015-0.138,0.061-0.114,0.175c0.023,0.092,0.145,0.183,0.281,0.29c0.168,0.129,0.365,0.281,0.404,0.449
               c0.052,0.221,0.113,0.297,0.189,0.373c0.029,0.037,0.067,0.076,0.106,0.137l0.007,0.008c0.1,0.167,0.16,0.265,0.1,0.357
               c-0.016,0.024-0.053,0.031-0.1,0.038c-0.084,0.023-0.168,0.038-0.184,0.145c-0.068,0-0.182,0-0.235-0.023
               c-0.022-0.007-0.038-0.03-0.046-0.045c-0.046-0.061-0.084-0.115-0.229-0.099c-0.106,0.015-0.129,0.076-0.123,0.16
               c0.008,0.053,0.039,0.114,0.063,0.175c0.021,0.046,0.037,0.084,0.037,0.098c0,0.023,0,0.031-0.008,0.039
               c-0.021,0.007-0.067,0.007-0.137,0.007c-0.104,0-0.129,0.038-0.129,0.107c0,0.008,0.008,0.023,0.008,0.045
               c0.008,0.046,0.017,0.107-0.03,0.168c-0.067,0.099-0.13,0.091-0.198,0.084c-0.029-0.007-0.061-0.007-0.091-0.007h-0.045
               c-0.106,0-0.177,0-0.267,0.167c-0.078,0.129,0.021,0.182,0.135,0.251c0.07,0.038,0.146,0.084,0.177,0.153
               c0.007,0.022,0.015,0.029,0.015,0.029c-0.029,0.016-0.129-0.007-0.242-0.037c-0.055-0.015-0.107-0.031-0.168-0.038
               c-0.039-0.009-0.069-0.016-0.1-0.023c-0.16-0.03-0.189-0.038-0.26-0.16c-0.016-0.023-0.036-0.046-0.053-0.069
               c-0.053-0.03-0.115-0.046-0.176-0.038c-0.061,0-0.113,0.024-0.168,0.054c-0.046,0.022-0.082,0.053-0.105,0.084
               c-0.038,0.052-0.047,0.129-0.047,0.227c-0.006,0.1-0.006,0.229-0.067,0.359c-0.062,0.137-0.229,0.266-0.396,0.388
               c-0.129,0.106-0.267,0.206-0.334,0.312C208.519,36.021,208.479,36.196,208.463,36.348 M187.243,47.457
               c-0.113,0.053-0.251,0.083-0.319,0.083c-0.038,0-0.067,0.008-0.1,0.023c-0.022,0.023-0.046,0.046-0.062,0.076
               c-0.016,0.031-0.021,0.062-0.021,0.1c0,0.061,0.016,0.137,0.061,0.205c0.045,0.076,0.1,0.122,0.16,0.16
               c0.066,0.054,0.137,0.114,0.176,0.244c0.016,0.068-0.008,0.099-0.054,0.106c-0.056,0.015-0.129,0.015-0.206,0.015
               c-0.068,0-0.13,0-0.197,0.008c-0.138,0.008-0.289,0.008-0.427,0c-0.114-0.015-0.222-0.038-0.297-0.076
               c-0.153-0.076-0.229-0.016-0.312,0.053c-0.024,0.023-0.056,0.053-0.092,0.069c-0.102,0.053-0.199,0.015-0.404-0.061l-0.084-0.03
               c-0.121-0.047-0.183-0.061-0.221-0.069c-0.061-0.015-0.084-0.023-0.197-0.084c-0.114-0.061-0.107-0.145-0.084-0.251
               c0.008-0.053,0.015-0.107,0.015-0.168v-0.068c0.008-0.16,0.008-0.236-0.205-0.472c-0.017-0.015-0.03-0.031-0.047-0.046
               c0.03-0.038,0.077-0.061,0.129-0.084c0.162-0.053,0.215-0.053,0.306-0.045c0.039,0.007,0.092,0.007,0.153,0.007
               c0.076,0,0.144-0.021,0.221-0.053c0.146-0.053,0.313-0.122,0.616,0.015c0.402,0.183,0.616,0.191,0.846,0.191
               c0.047,0.007,0.1,0.007,0.151,0.007c0.146,0.008,0.244,0.016,0.313,0.031c0.061,0.015,0.105,0.037,0.137,0.069
               C187.213,47.427,187.229,47.441,187.243,47.457 M202.517,54.85c-0.121,0-0.242,0.076-0.272,0.122
               c-0.04,0.045-0.138-0.038-0.19,0.045c-0.046,0.084,0.016,0.222-0.046,0.26c-0.062,0.045-0.282,0.091-0.305,0.159
               c-0.022,0.069-0.076,0.183-0.022,0.244c0.045,0.061,0.084,0.183,0.068,0.274c-0.023,0.091,0.055,0.099,0.061,0.206
               c0,0.099,0.069,0.167,0.129,0.167c0.056-0.008,0.153,0.038,0.237,0.084s0.122,0.007,0.205,0.046
               c0.084,0.037,0.313,0.068,0.366-0.016c0.053-0.091,0.076-0.114,0.184-0.091c0.098,0.023,0.09-0.023,0.166-0.129
               c0.069-0.115,0.137-0.153,0.168-0.191c0.03-0.046,0.03-0.152,0.039-0.259c0.014-0.114,0.008-0.289,0.074-0.373
               c0.076-0.084,0.191-0.091,0.176-0.19c-0.023-0.107-0.107-0.015-0.076-0.145c0.03-0.137-0.022-0.243-0.1-0.243
               c-0.083,0-0.083-0.099-0.234-0.046c-0.146,0.053-0.291,0.076-0.358,0.076c-0.068,0-0.091-0.007-0.13-0.015
               S202.517,54.85,202.517,54.85"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M208.463,36.348h-0.062c-0.158,0.039-0.455,0-0.652,0.114c-0.207,0.114-0.236,0.145-0.375,0.282
               c-0.137,0.137-0.242,0.206-0.313,0.35c-0.068,0.146-0.252,0.175-0.326,0.213c-0.076,0.031-0.274-0.075-0.139-0.205
               c0.139-0.122,0.344-0.145,0.237-0.221c-0.101-0.084-0.282-0.114-0.328-0.19c-0.046-0.084,0.017-0.488,0.068-0.602
               c0.062-0.107,0.235-0.426-0.13-0.555c-0.373-0.138-0.42-0.222-0.653-0.175c-0.237,0.045-0.358,0.175-0.358,0.304
               c0,0.137,0.146,0.259,0.021,0.38c-0.128,0.13-0.252,0.137-0.389,0.252c-0.129,0.114-0.213,0.205-0.242,0.418
               c-0.037,0.214,0.045,0.283-0.159,0.344c-0.199,0.067-0.282,0.113-0.382,0.106c-0.1-0.015-0.357-0.19-0.35,0.069
               c0.014,0.258,0.09,0.403,0.229,0.532c0.129,0.137,0.289,0.229,0.472,0.244c0.175,0.008,0.198-0.221,0.435-0.069
               c0.236,0.145,0.236,0.175,0.351,0.259c0.114,0.077,0.076,0.312,0.206,0.457c0.12,0.145,0.029,0.205,0.355,0.205
               c0.328,0,0.26-0.205,0.42-0.304c0.152-0.099,0.268-0.373,0.388-0.305c0.132,0.069,0.306,0.031,0.397,0.122
               c0.09,0.092,0.28,0.145,0.396,0.145c0.106,0,0.327,0.038,0.448,0.083c0.123,0.047,0.609,0.031,0.519,0.168
               c-0.092,0.137-0.015,0.305-0.204,0.358c-0.191,0.061-0.527,0-0.763,0.214c-0.235,0.212-0.364,0.227-0.39,0.448
               c-0.015,0.229-0.053,0.366-0.197,0.427c-0.145,0.069-0.258,0.061-0.273,0.297c-0.008,0.235-0.335,0.35-0.099,0.525
               c0.235,0.183,0.532,0.092,0.632,0.236c0.1,0.152,0.83,0.777,1.034,1.158c0.214,0.388,0.329,0.48,0.366,0.601
               c0.047,0.122,0.281,0.114,0.357,0.19c0.077,0.077,0.077,0.16,0.184,0.267c0.105,0.106,0.738,0.342,0.549,0.381
               c-0.189,0.03-0.237-0.069-0.465-0.069c-0.236,0-0.273-0.008-0.366-0.061c-0.099-0.061-0.403-0.061-0.235,0.03
               c0.16,0.092,0.273,0.084,0.381,0.191c0.104,0.106,0.289,0.038,0.496,0.243c0.204,0.207,0.555,0.313,0.449,0.435
               c-0.107,0.114-0.199,0.083-0.252,0.137c-0.063,0.045-0.305,0.175-0.344,0.282c-0.03,0.114-0.077,0.19-0.189,0.182
               c-0.107-0.007-0.152,0.206,0.037,0.228c0.191,0.024,0.391-0.16,0.525-0.16c0.139,0,0.16,0.123,0.305,0.214
               c0.146,0.091,0.465,0,0.404,0.205c-0.055,0.198-0.357,0.191-0.153,0.312c0.198,0.122,0.39,0.351,0.222,0.358
               c-0.166,0.015-0.351-0.022-0.242,0.099c0.098,0.13,0.084,0.198,0.104,0.366c0.022,0.167,0.465,0.503,0.022,0.472
               c-0.434-0.038-0.434,0.053-0.434,0.152c0,0.107-0.061,0.206-0.068,0.343c-0.016,0.129,0.176,0.221-0.092,0.328
               c-0.267,0.098-0.146,0.472-0.267,0.593c-0.13,0.122-0.261,0.077-0.197,0.245c0.067,0.159-0.03,0.257-0.022,0.364
               c0.007,0.099,0.16,0.183,0.175,0.282c0.022,0.091-0.122,0.091-0.214,0.137c-0.084,0.046-0.068,0.221-0.077,0.297
               c-0.007,0.076-0.173,0.168-0.173,0.168s-0.092,0.403-0.223,0.433c-0.137,0.039-0.351,0.16-0.41,0.351
               c-0.053,0.19-0.267,0.281-0.381,0.335c-0.113,0.06-0.138,0.213-0.26,0.342c-0.121,0.123-0.197,0.054-0.305,0.244
               c-0.098,0.19,0.016,0.281-0.26,0.259c-0.266-0.024-0.213-0.137-0.493-0.024c-0.282,0.116-0.244,0.214-0.456,0.26
               c-0.215,0.046-0.512,0.046-0.656,0.099c-0.145,0.061-0.197-0.031-0.289-0.129c-0.092-0.107-0.38-0.297-0.373-0.114
               c0.008,0.182,0.19,0.373,0.206,0.502c0.007,0.137-0.159,0.16-0.282,0.205c-0.129,0.046,0.008,0.145-0.252,0.237
               c-0.258,0.091-0.267-0.229-0.607-0.016c-0.336,0.221-0.307,0.282-0.473,0.297c-0.168,0.008-0.457,0.008-0.563,0.191
               c-0.099,0.182-0.296,0.091-0.213,0.312c0.093,0.228,0.251,0.228,0.206,0.327c-0.047,0.099,0.029,0.328-0.19,0.328
               c-0.229,0-0.206-0.145-0.351-0.442c-0.145-0.29-0.236-0.282-0.221-0.548c0.008-0.267-0.062-0.481-0.328-0.351
               c-0.273,0.137-0.441,0.229-0.563,0.168c-0.113-0.046-0.427-0.137-0.563,0.015c-0.1-0.068-0.197-0.13-0.236-0.175
               c-0.045-0.061-0.105-0.083-0.176-0.114c-0.074-0.031-0.159-0.069-0.243-0.168c-0.091-0.114-0.183-0.159-0.281-0.213
               c-0.069-0.03-0.144-0.068-0.237-0.144c-0.104-0.077-0.052-0.168,0-0.252c0.039-0.061,0.077-0.129,0.069-0.198
               c-0.022-0.099-0.099-0.144-0.205-0.152c-0.084-0.015-0.196,0-0.319,0.023c-0.168,0.023-0.297-0.091-0.457-0.237
               c-0.031-0.029-0.062-0.06-0.092-0.084c-0.189-0.167-0.313-0.045-0.41,0.046c-0.022,0.023-0.045,0.046-0.054,0.055
               c-0.046,0.014-0.084,0.06-0.123,0.121c-0.045,0.076-0.114,0.168-0.213,0.183c-0.099,0.023-0.188,0.037-0.267,0.068
               c-0.084,0.023-0.16,0.068-0.229,0.13c-0.056,0.045-0.076,0.022-0.121-0.023c-0.039-0.039-0.092-0.084-0.176-0.138
               c-0.13-0.083-0.189-0.06-0.236,0c-0.016,0.031-0.021,0.061-0.037,0.099c-0.008,0.046-0.022,0.092-0.055,0.114
               c-0.037,0.039-0.092,0-0.15-0.068c-0.039-0.03-0.076-0.068-0.123-0.098c-0.031-0.024-0.061-0.039-0.092-0.047
               c-0.045-0.014-0.082-0.014-0.129,0.008c-0.029,0.015-0.068,0.039-0.092,0.076c-0.037,0.046-0.063,0.115-0.084,0.175
               c-0.016,0.062-0.068,0.046-0.129,0.031c-0.054-0.023-0.113-0.038-0.189-0.038c-0.139,0-0.168,0.091-0.168,0.22
               c0,0.054,0.008,0.123,0.016,0.184c0,0.038,0.007,0.068,0.007,0.106c0.017,0.145,0.099,0.244,0.185,0.335
               c0.047,0.045,0.092,0.091,0.105,0.137c0.007,0.007,0.007,0.007,0.007,0.015c0.015,0.054,0.009,0.099-0.007,0.137
               c-0.023,0.045-0.053,0.077-0.084,0.084l-0.008,0.007h-0.03c-0.03,0-0.062-0.014-0.084-0.029
               c-0.021-0.017-0.046-0.039-0.053-0.069c-0.007-0.008-0.007-0.023-0.007-0.046c0.014-0.137-0.115-0.198-0.223-0.251
               c-0.037-0.015-0.068-0.031-0.084-0.046c-0.076-0.053-0.175,0.015-0.273,0.076c-0.074,0.046-0.151,0.099-0.184,0.084
               c-0.029-0.008-0.066-0.015-0.099-0.023c-0.077-0.015-0.159-0.031-0.29-0.091c-0.105-0.053-0.113-0.114-0.12-0.191
               c-0.008-0.053-0.017-0.122-0.055-0.182c-0.067-0.123-0.177-0.114-0.306-0.107c-0.074,0-0.168,0.008-0.234-0.03
               c-0.047-0.023-0.047-0.031-0.038-0.046c0-0.015,0.015-0.031,0.021-0.046c0.039-0.06,0.084-0.129,0.078-0.228
               c0-0.084,0.021-0.137,0.045-0.183c0.03-0.069,0.062-0.121,0.045-0.221c-0.015-0.099-0.084-0.137-0.168-0.189
               c-0.074-0.039-0.158-0.092-0.229-0.199c-0.09-0.152-0.145-0.175-0.188-0.198c-0.023-0.015-0.055-0.023-0.084-0.068
               c-0.039-0.038-0.054-0.099-0.07-0.153c-0.028-0.099-0.059-0.198-0.176-0.289c-0.158-0.129-0.22-0.054-0.356,0.107
               c-0.045,0.053-0.104,0.121-0.183,0.197c-0.045,0.046-0.084,0.069-0.105,0.069c-0.018,0.007-0.023,0-0.031-0.007
               c-0.008-0.016-0.021-0.039-0.021-0.062c-0.022-0.091-0.008-0.22,0.029-0.327c0.061-0.16-0.016-0.259-0.074-0.35
               c-0.039-0.054-0.077-0.107-0.063-0.16c0.03-0.084,0.03-0.152,0.038-0.236c0-0.076,0-0.168,0.046-0.274
               c0.028-0.099,0.112-0.153,0.196-0.205c0.101-0.07,0.198-0.13,0.261-0.252c0.09-0.182,0.029-0.548-0.017-0.861
               c-0.015-0.106-0.03-0.212-0.037-0.289c-0.015-0.259-0.207-0.388-0.375-0.495c-0.036-0.023-0.074-0.046-0.104-0.075
               c-0.076-0.054-0.137-0.13-0.183-0.214c-0.038-0.084-0.069-0.183-0.077-0.297c-0.021-0.236-0.259-0.221-0.434-0.206
               c-0.047,0-0.092,0.008-0.116,0c-0.06-0.015-0.248-0.038-0.438-0.061c-0.152-0.015-0.313-0.038-0.412-0.038
               c-0.016-0.007-0.023-0.015-0.023-0.021c0.009-0.031,0.018-0.069,0.031-0.115c0.039-0.122,0.084-0.274-0.016-0.396
               c-0.113-0.144-0.221-0.091-0.297-0.053c-0.016,0.015-0.029,0.023-0.045,0.023c0,0,0.008,0,0.008-0.008
               c-0.017-0.053-0.017-0.091-0.017-0.129c0.009-0.084,0.017-0.16-0.106-0.266c-0.099-0.1-0.197-0.077-0.289-0.008
               c-0.076,0.061-0.146,0.167-0.213,0.267c0,0-0.008,0.007-0.008,0.014c-0.046,0.047-0.113,0.054-0.189,0.038
               c-0.092-0.014-0.184-0.061-0.26-0.106c-0.029-0.023-0.062-0.038-0.084-0.061c-0.145-0.122-0.228-0.046-0.297,0.015l-0.029,0.031
               c-0.055,0-0.076,0.046-0.114,0.114c-0.046,0.076-0.106,0.198-0.251,0.259c-0.146,0.053-0.223,0.144-0.29,0.221
               c-0.054,0.061-0.099,0.106-0.159,0.114c-0.16,0.008-0.221,0.122-0.305,0.274c-0.023,0.038-0.039,0.076-0.063,0.107
               c-0.021,0.046-0.061,0.084-0.113,0.114c-0.029-0.023-0.054-0.046-0.068-0.061c-0.053-0.053-0.105-0.084-0.197-0.099
               c-0.076-0.023-0.184-0.031-0.327-0.038h-0.161c-0.211-0.008-0.41-0.015-0.791-0.182c-0.352-0.161-0.548-0.084-0.707-0.024
               c-0.07,0.024-0.123,0.046-0.184,0.046c-0.063,0-0.107,0-0.146-0.008c-0.105-0.007-0.166-0.007-0.35,0.054
               c-0.093,0.03-0.152,0.084-0.206,0.137c-0.084-0.008-0.159,0.038-0.229,0.099c-0.037,0.03-0.067,0.068-0.098,0.107
               c-0.023,0.023-0.023,0.045-0.029,0.045c-0.039,0-0.352-0.015-0.655-0.076c-0.16-0.03-0.32-0.068-0.427-0.122
               c-0.274-0.137-0.297-0.076-0.358,0.069c-0.022,0.037-0.047,0.091-0.084,0.153c-0.038,0.044-0.054,0.052-0.067,0.044
               c-0.031-0.022-0.062-0.099-0.092-0.174c-0.023-0.046-0.046-0.107-0.076-0.153c-0.068-0.122-0.16-0.107-0.281-0.099
               c-0.076,0.007-0.176,0.023-0.289-0.008c-0.068-0.022-0.084-0.045-0.076-0.068c0-0.015,0.015-0.038,0.023-0.053
               c0.037-0.069,0.074-0.145,0.014-0.214c-0.076-0.091-0.145-0.061-0.213-0.03c-0.037,0.023-0.084,0.038-0.129,0.016
               c-0.054-0.023-0.1-0.054-0.146-0.084c-0.084-0.054-0.168-0.107-0.306-0.145c-0.082-0.023-0.082-0.053-0.082-0.107
               c0.007-0.053,0.007-0.121-0.031-0.198c-0.068-0.167-0.229-0.099-0.365-0.03c-0.054,0.023-0.105,0.046-0.138,0.046
               c-0.067-0.009-0.082-0.046-0.19-0.206c-0.021-0.038-0.045-0.084-0.084-0.144c-0.129-0.199-0.38-0.275-0.584-0.336
               c-0.123-0.03-0.23-0.06-0.261-0.106c-0.062-0.069-0.106-0.115-0.151-0.153c-0.037-0.03-0.084-0.045-0.131-0.068
               c-0.015-0.008-0.037-0.015-0.052-0.031c-0.054-0.037-0.114-0.083-0.29-0.06c-0.092,0.007-0.197,0.045-0.289,0.076
               c-0.115,0.046-0.214,0.076-0.251,0.06c-0.062-0.03-0.176-0.098-0.267-0.19c-0.053-0.061-0.092-0.122-0.1-0.183
               c-0.009-0.152-0.152-0.144-0.313-0.144c-0.123,0-0.252,0-0.32-0.061c-0.1-0.084-0.281-0.19-0.465-0.274
               c-0.176-0.084-0.357-0.152-0.464-0.168h-0.009c-0.075,0.008-0.105-0.007-0.129-0.037c-0.021-0.047-0.029-0.107-0.029-0.191
               v-0.039c0.008-0.159,0.008-0.304-0.215-0.502c-0.098-0.091-0.098-0.144-0.061-0.182c0.03-0.031,0.075-0.054,0.114-0.084
               c0.017,0,0.022-0.008,0.037-0.015c0.038-0.023,0.084-0.008,0.146,0.022c0.068,0.023,0.16,0.062,0.282,0.062
               c0.183,0,0.22-0.077,0.22-0.168c0-0.03-0.007-0.053-0.007-0.084c0-0.023-0.008-0.046-0.008-0.068
               c0-0.076-0.03-0.137-0.069-0.198c-0.029-0.053-0.068-0.122-0.076-0.191c-0.015-0.137-0.121-0.16-0.242-0.183
               c-0.063-0.015-0.131-0.03-0.182-0.068l-0.01-0.007c-0.061-0.054-0.1-0.145-0.113-0.229c-0.017-0.084-0.017-0.159,0.008-0.191
               c0.061-0.069,0.15-0.069,0.258-0.076h0.023c0.137-0.008,0.146-0.114,0.146-0.243c0.007-0.054,0.007-0.099,0.015-0.145
               c0.016-0.068,0.047-0.077,0.092-0.091c0.047-0.015,0.1-0.031,0.16-0.092c0.076-0.068,0.105-0.13,0.105-0.198
               c0.008-0.068-0.016-0.137-0.045-0.221c-0.029-0.075-0.016-0.106,0.008-0.152c0.021-0.038,0.045-0.091,0.066-0.167
               c0.024-0.122-0.006-0.16-0.074-0.175c-0.016-0.008-0.039-0.008-0.062-0.008c-0.046-0.008-0.106-0.008-0.168-0.045
               c-0.099-0.069-0.167-0.069-0.228-0.069c-0.039-0.008-0.084-0.008-0.146-0.046c-0.061-0.038-0.082-0.068-0.099-0.099
               c-0.029-0.038-0.06-0.075-0.175-0.144c-0.092-0.054-0.213-0.054-0.336-0.023c-0.188,0.037-0.387,0.136-0.472,0.182
               c-0.075,0.046-0.12,0.122-0.167,0.199c-0.046,0.068-0.084,0.136-0.137,0.152c0,0-0.291-0.053-0.336-0.068
               c-0.084-0.023-0.153-0.039-0.198-0.047c-0.046,0-0.112-0.03-0.183-0.068c-0.076-0.038-0.16-0.076-0.221-0.076
               c-0.047,0-0.084-0.016-0.115-0.03c-0.037-0.024-0.066-0.069-0.105-0.123c-0.053-0.098-0.145-0.121-0.236-0.136
               c-0.061-0.008-0.121-0.023-0.166-0.054c-0.045-0.03-0.031-0.06-0.017-0.099c0.017-0.045,0.038-0.091,0.017-0.144
               c-0.017-0.054-0.045-0.084-0.084-0.115c-0.037-0.038-0.084-0.083-0.106-0.167c-0.039-0.152-0.289-0.221-0.442-0.259
               c-0.045-0.015-0.076-0.023-0.084-0.03c-0.045-0.031-0.113-0.016-0.189,0c-0.045,0.007-0.092,0.015-0.129,0.015
               c-0.039,0-0.068-0.015-0.092-0.039c-0.021-0.038-0.045-0.084-0.054-0.137c-0.008-0.053,0.022-0.129,0.048-0.205
               c0.028-0.076,0.059-0.159,0.051-0.229c-0.006-0.098-0.015-0.106-0.074-0.167c-0.031-0.023-0.069-0.054-0.123-0.114
               c-0.1-0.114-0.105-0.206-0.113-0.267c0-0.03-0.007-0.06-0.021-0.083c0-0.008,0.006-0.031,0.016-0.054
               c0.016-0.03,0.036-0.068,0.036-0.121c0-0.092-0.066-0.106-0.144-0.13c-0.039-0.008-0.07-0.015-0.092-0.031
               c-0.047-0.037-0.105-0.037-0.16-0.037c-0.046-0.008-0.092-0.008-0.105-0.031c-0.069-0.076-0.129-0.03-0.213,0.023
               c-0.039,0.03-0.084,0.061-0.139,0.076c-0.037,0.015-0.053-0.008-0.068-0.046c-0.006-0.022-0.006-0.046-0.016-0.076
               c-0.008-0.038-0.022-0.068-0.029-0.099c-0.03-0.045-0.03-0.061-0.023-0.091v-0.069c-0.007-0.084-0.046-0.099-0.091-0.129
               c-0.029-0.015-0.068-0.038-0.106-0.084c-0.046-0.053-0.046-0.107-0.039-0.145c0.032,0,0.063-0.007,0.092-0.015
               c0.063-0.031,0.115-0.068,0.131-0.137c0.023-0.076-0.039-0.137-0.1-0.198c-0.031-0.03-0.068-0.069-0.054-0.084
               c0.022-0.022,0.047-0.046,0.067-0.069c0.045-0.06,0.093-0.113,0.168-0.106c0.107,0.016,0.16-0.061,0.214-0.137
               c0.03-0.046,0.053-0.083,0.092-0.106c0.062-0.039,0.144-0.076,0.205-0.107c0.047-0.015,0.084-0.023,0.092-0.023
               c0.045,0.016,0.084-0.015,0.129-0.053c0.046-0.038,0.106-0.091,0.153-0.061c0.035,0.03,0.061,0.099,0.068,0.16
               c0.029,0.092,0.045,0.167,0.136,0.152c0.054-0.008,0.092-0.037,0.138-0.068c0.038-0.031,0.092-0.069,0.137-0.053
               c0.008,0.007,0.017,0.007,0.022,0.007c0.069,0.015,0.138,0,0.19-0.03c0.062-0.031,0.105-0.077,0.114-0.107v-0.008h0.009v-0.076
               c0-0.053,0-0.13,0.006-0.198c0.011-0.091,0.045-0.174,0.129-0.159c0.093,0.007,0.162,0.022,0.23,0.037
               c0.113,0.023,0.204,0.047,0.326,0.023c0.114-0.015,0.205-0.075,0.273-0.122c0.045-0.022,0.084-0.045,0.092-0.045
               c0.068,0,0.113-0.091,0.16-0.183c0.037-0.069,0.074-0.137,0.106-0.16c0.021-0.015,0.052-0.038,0.091-0.068
               c0.092-0.084,0.229-0.199,0.358-0.214c0.136-0.015,0.174-0.076,0.213-0.129c0.007-0.023,0.021-0.039,0.052-0.046
               c0.032-0.007,0.069-0.007,0.1-0.015c0.084,0,0.152-0.007,0.176-0.076c0-0.015,0.008-0.023,0.008-0.038
               c0.031-0.069,0.063-0.137-0.029-0.237c-0.054-0.052-0.1-0.09-0.129-0.113c0-0.023,0-0.046-0.008-0.077
               c-0.018-0.061,0.053-0.129,0.121-0.198c0.113-0.114,0.229-0.236,0.121-0.403c-0.084-0.122-0.129-0.206-0.176-0.289
               c-0.053-0.084-0.1-0.176-0.184-0.305c-0.074-0.106-0.099-0.228-0.121-0.35c-0.029-0.16-0.062-0.305-0.197-0.403
               c-0.068-0.054-0.152-0.099-0.236-0.145c-0.105-0.053-0.205-0.107-0.213-0.13c0,0,0.016-0.007,0.063-0.023
               c0.092-0.023,0.196-0.068,0.318-0.122c0.289-0.129,0.633-0.274,0.717-0.152c0.016,0.023,0.029,0.046,0.047,0.061
               c0.129,0.19,0.174,0.267,0.379,0.183c0.055-0.031,0.139,0,0.205,0.023c0.062,0.023,0.115,0.038,0.152,0.03
               c0.063-0.015,0.084-0.068,0.055-0.197c-0.047-0.168-0.199-0.29-0.32-0.405c-0.092-0.075-0.168-0.144-0.159-0.182
               c0.028-0.092,0.075-0.282,0.106-0.48c0.016-0.129,0.021-0.266,0.008-0.38c0-0.061-0.017-0.115-0.022-0.168
               c-0.017-0.091-0.032-0.167-0.017-0.191c0-0.007,0.022,0,0.067,0.031c0.093,0.046,0.161,0.092,0.214,0.129
               c0.084,0.062,0.137,0.107,0.229,0.1c0.021-0.008,0.052-0.008,0.1-0.016c0.184-0.03,0.516-0.076,0.563-0.007
               c0.016,0.022,0.03,0.045,0.047,0.061c0.028,0.046,0.067,0.075,0.112,0.084c0.039,0.015,0.084,0.007,0.129-0.016
               c0.017-0.007,0.023-0.015,0.039-0.022c0.023-0.023,0.062-0.046,0.1-0.069c0.075-0.053,0.152-0.107,0.206-0.175
               c0.076-0.115,0.045-0.183-0.039-0.351l-0.028-0.053c-0.023-0.045-0.049-0.091-0.078-0.137c-0.076-0.137-0.152-0.274-0.136-0.38
               c0.007-0.039,0.015-0.084,0.015-0.123v-0.06c0.008-0.054,0.022-0.084,0.1-0.077c0.029,0,0.052,0.009,0.068,0.009
               c0.197,0.014,0.334,0.029,0.373-0.153c0.016-0.099-0.023-0.183-0.062-0.259c-0.015-0.045-0.038-0.091-0.03-0.099
               c0.03-0.069,0.1-0.182,0.145-0.244c0.021,0.008,0.055,0.023,0.084,0.03c0.152,0.032,0.222,0.047,0.357,0.032
               c0.022,0.052,0.084,0.152,0.205,0.311c0.1,0.13,0.168,0.13,0.229,0.13c0.038,0,0.067,0.007,0.129,0.099
               c0.075,0.114,0.138,0.198,0.196,0.251c0.07,0.054,0.14,0.076,0.222,0.099c0.062,0.015,0.114,0.038,0.175,0.069
               c0.1,0.045,0.207,0.091,0.366,0.121c0.106,0.015,0.19,0,0.267-0.015c0.106-0.022,0.198-0.037,0.274,0.076
               c0.091,0.138,0.183,0.168,0.282,0.191c0.074,0.023,0.145,0.038,0.22,0.114c0.084,0.091,0.16,0.19,0.229,0.281
               c0.076,0.1,0.151,0.198,0.234,0.29c0.092,0.083,0.184,0.198,0.26,0.297c0.063,0.091,0.114,0.175,0.139,0.244l0.008,0.045
               c0.045,0.122,0.084,0.244,0.045,0.442c-0.016,0.053-0.023,0.099-0.03,0.129c-0.046,0.153-0.062,0.199,0.153,0.343
               c0.129,0.083,0.35,0.19,0.563,0.266c0.185,0.069,0.351,0.107,0.449,0.092c0.047-0.008,0.076-0.023,0.113-0.03
               c0.107-0.031,0.199-0.062,0.58,0.091c0.229,0.091,0.418,0.121,0.607,0.144c0.236,0.031,0.465,0.061,0.723,0.221
               c0.246,0.153,0.404,0.145,0.543,0.13c0.129-0.008,0.228-0.008,0.355,0.152c0.1,0.13,0.16,0.221,0.215,0.297
               c0.098,0.152,0.168,0.244,0.342,0.388c0.123,0.092,0.215,0.198,0.299,0.298c0.113,0.129,0.22,0.25,0.371,0.35
               c0.129,0.075,0.199,0.045,0.306-0.009c0.138-0.068,0.351-0.167,0.937,0.023c0.488,0.161,0.785,0.236,0.99,0.267
               c0.215,0.031,0.334,0.008,0.463-0.023c0.123-0.022,0.267-0.045,0.42-0.061c0.162-0.015,0.326-0.022,0.487-0.022
               c0.298,0,0.457,0.075,0.769,0.221l0.068,0.03c0.252,0.122,0.42,0.145,0.641,0.183c0.091,0.015,0.199,0.029,0.334,0.062
               c0.223,0.045,0.39,0.129,0.527,0.205c0.12,0.061,0.219,0.107,0.303,0.107c0.054,0,0.123-0.015,0.184-0.023
               c0.146-0.031,0.297-0.061,0.374,0.038c0.084,0.106,0.151,0.144,0.228,0.137c0.068,0,0.139-0.053,0.229-0.145l0.037-0.03
               c0.19-0.175,0.647-0.617,1.286-0.587c0.527,0.031,0.672,0.016,0.84,0c0.052-0.007,0.105-0.007,0.175-0.015
               c0.183-0.008,0.479-0.076,0.715-0.228c0.175-0.106,0.313-0.252,0.358-0.457c0.054-0.213,0.176-0.236,0.281-0.259
               c0.129-0.023,0.244-0.046,0.281-0.236c0.063-0.259-0.053-0.32-0.176-0.388c-0.052-0.031-0.113-0.069-0.158-0.122
               c-0.054-0.076-0.123-0.129-0.185-0.175c-0.104-0.076-0.197-0.152-0.159-0.274c0.038-0.099,0.038-0.19,0.038-0.259
               c-0.008-0.023-0.008-0.046,0-0.069c0-0.038,0.022-0.061,0.106-0.053c0.16,0.015,0.258,0.068,0.343,0.114
               c0.054,0.031,0.106,0.06,0.168,0.077c0.03,0.007,0.084,0.022,0.136,0.045c0.152,0.061,0.359,0.137,0.534,0.106
               c0.212-0.045,0.304-0.159,0.373-0.25c0.007-0.016,0.021-0.031,0.03-0.046c0.022-0.016,0.039-0.023,0.045-0.023
               c0.055-0.008,0.1-0.015,0.152-0.153c0.016-0.03,0.03-0.06,0.045-0.091c0.023-0.038,0.045-0.069,0.077-0.084
               c0.015-0.007,0.037-0.015,0.052-0.007h0.008c0.056,0.038,0.146,0.053,0.238,0.053c0.075,0,0.159-0.015,0.219-0.053
               c0.031-0.023,0.1-0.038,0.162-0.046c0.067-0.015,0.145-0.031,0.196-0.061c0.091-0.038,0.138-0.098,0.084-0.205
               c-0.038-0.092-0.067-0.168-0.084-0.228c-0.008-0.046,0-0.084,0.031-0.115c0.038-0.046,0.112-0.13,0.188-0.175
               c0.031-0.023,0.063-0.045,0.076-0.039l0.016,0.009c0.115,0.053,0.115,0.053,0.205-0.092c0.053-0.076,0.115-0.076,0.229-0.091
               h0.076c0.084-0.008,0.129-0.046,0.183-0.092c0.063-0.045,0.123-0.099,0.282-0.06c0.093,0.022,0.16,0.037,0.213,0.053
               c0.122,0.038,0.183,0.053,0.335,0.038c0.1-0.008,0.236-0.031,0.327-0.069c0.039-0.022,0.077-0.038,0.093-0.068
               c0.03-0.03,0.037-0.069,0.014-0.114c-0.008-0.023-0.028-0.046-0.061-0.077c-0.091-0.068-0.229-0.175-0.373-0.282
               c-0.152-0.106-0.306-0.213-0.441-0.273c-0.121-0.062-0.234-0.099-0.326-0.13c-0.092-0.031-0.168-0.061-0.221-0.099
               c-0.055-0.038-0.185-0.091-0.306-0.137c-0.153-0.053-0.306-0.091-0.343-0.084c-0.038,0.007-0.055,0.039-0.084,0.084
               c-0.038,0.068-0.098,0.175-0.243,0.16c-0.092-0.008-0.159-0.053-0.222-0.084c-0.105-0.061-0.197-0.121-0.381-0.076
               c-0.106,0.031-0.19,0.068-0.266,0.107c-0.113,0.053-0.197,0.091-0.275,0.038c-0.076-0.054-0.129-0.105-0.166-0.153
               c-0.047-0.045-0.084-0.083-0.122-0.114c-0.023-0.007-0.009-0.053,0.016-0.099c0.015-0.046,0.037-0.084,0.045-0.137
               c0.015-0.076-0.022-0.121-0.069-0.182c-0.028-0.046-0.074-0.1-0.074-0.183c0-0.152,0.061-0.647,0.084-0.853
               c0.053,0.031,0.113,0.061,0.212,0.091l0.039,0.009c0.297,0.083,0.624,0.167,0.784,0c0.068-0.077,0.19-0.138,0.305-0.191
               c0.122-0.069,0.236-0.122,0.259-0.191c0.022-0.076-0.067-0.175-0.175-0.282c-0.1-0.106-0.214-0.228-0.189-0.273
               c0.037-0.069,0.037-0.19,0.037-0.336c0-0.183,0-0.395,0.084-0.51c0.055-0.068,0.121-0.114,0.168-0.145
               c0.022-0.007,0.037-0.022,0.055-0.037c0.074-0.054,0.098-0.107,0-0.229c-0.069-0.099-0.161-0.129-0.229-0.16
               c-0.062-0.022-0.114-0.045-0.131-0.106c-0.021-0.1-0.065-0.175-0.13-0.229c-0.059-0.053-0.137-0.076-0.219-0.061
               c-0.055,0.008-0.152-0.008-0.215-0.038c-0.021-0.007-0.037-0.023-0.053-0.03c0-0.008-0.008-0.015,0-0.023
               c0-0.015,0.016-0.031,0.037-0.053c0.084-0.062,0.131-0.145,0.168-0.221c0.063-0.107,0.105-0.198,0.273-0.16
               c0.229,0.045,0.328,0.015,0.457-0.031c0.062-0.015,0.13-0.038,0.222-0.053c0.099-0.007,0.175-0.038,0.251-0.069
               c0.13-0.045,0.251-0.091,0.457,0.008c0.16,0.069,0.426,0.153,0.691,0.213c0.307,0.069,0.617,0.115,0.731,0.107
               c0.099,0,0.183,0,0.298,0.038c0.112,0.046,0.26,0.129,0.494,0.304c0.16,0.115,0.35,0.23,0.541,0.336
               c0.357,0.205,0.708,0.411,0.813,0.655c0.131,0.304,0.281,0.434,0.429,0.556c0.045,0.037,0.09,0.067,0.129,0.113
               c0.038,0.038,0.075,0.077,0.122,0.115c0.205,0.22,0.531,0.555,0.662,0.601c0.061,0.023,0.13,0.076,0.214,0.137
               c0.104,0.077,0.233,0.168,0.401,0.245c0.199,0.099,0.352,0.083,0.504,0.067c0.084-0.015,0.174-0.023,0.273-0.007
               c0.1,0.015,0.168,0.007,0.235,0c0.101-0.008,0.167-0.016,0.267,0.16c0.176,0.289,0.52,0.365,0.646,0.395
               c0.018,0,0.031,0.009,0.039,0.009c0.031,0.007,0.076,0,0.129-0.009c0.125-0.014,0.313-0.037,0.406,0.1
               c0.066,0.098,0.099,0.182,0.119,0.244c0.039,0.091,0.063,0.151,0.129,0.182c0.055,0.023,0.101,0.015,0.139,0.008
               c0.009-0.008,0.023-0.008,0.029-0.008c0,0,0.009,0.015,0.017,0.077c0,0.06-0.008,0.106-0.008,0.143
               c-0.022,0.13-0.029,0.183,0.204,0.26c0.078,0.022,0.139,0.046,0.191,0.06c0.146,0.054,0.236,0.084,0.411,0.099
               c0.114,0.009,0.183-0.015,0.251-0.03c0.076-0.023,0.145-0.045,0.281-0.023c0.175,0.023,0.291-0.121,0.391-0.267
               c0.066-0.083,0.129-0.167,0.188-0.167c0.038,0,0.075,0.008,0.114,0.008c0.136,0.007,0.25,0.015,0.342-0.03
               c0.008,0,0.017-0.008,0.031-0.016c0.053-0.022,0.121-0.061,0.197-0.084c0.092-0.022,0.184-0.022,0.213,0.084
               c0.047,0.176,0.106,0.244,0.16,0.312c0.038,0.039,0.066,0.076,0.092,0.13c0.022,0.046-0.008,0.061-0.047,0.083
               c-0.063,0.046-0.129,0.1-0.091,0.229c0.039,0.129,0.091,0.252,0.138,0.373c0.022,0.053,0.045,0.115,0.067,0.16
               c0.022,0.061,0.047,0.1,0.067,0.137c0.039,0.069,0.07,0.122,0.047,0.22c-0.014,0.061-0.039,0.108-0.067,0.153
               c-0.069,0.107-0.13,0.206,0,0.373c0.113,0.16,0.099,0.221,0.091,0.297c0,0.023-0.008,0.039-0.008,0.069
               c0,0.03,0,0.06,0.008,0.106c0.008,0.062,0.016,0.129,0,0.16c0,0.008-0.029,0.008-0.084-0.023
               c-0.045-0.023-0.099-0.046-0.146-0.068c-0.204-0.115-0.396-0.214-0.569-0.122c-0.105,0.069-0.13,0.115-0.15,0.153
               c-0.008,0.022-0.017,0.045-0.063,0.068c-0.037,0.03-0.099,0.03-0.149,0.038c-0.033,0-0.063,0.007-0.093,0.015
               c-0.084,0.015-0.138,0.061-0.114,0.175c0.023,0.092,0.145,0.183,0.281,0.29c0.168,0.129,0.365,0.281,0.404,0.449
               c0.052,0.221,0.113,0.297,0.189,0.373c0.029,0.037,0.067,0.076,0.106,0.137l0.007,0.008c0.1,0.167,0.16,0.265,0.1,0.357
               c-0.016,0.024-0.053,0.031-0.1,0.038c-0.084,0.023-0.168,0.038-0.184,0.145c-0.068,0-0.182,0-0.235-0.023
               c-0.022-0.007-0.038-0.03-0.046-0.045c-0.046-0.061-0.084-0.115-0.229-0.099c-0.106,0.015-0.129,0.076-0.123,0.16
               c0.008,0.053,0.039,0.114,0.063,0.175c0.021,0.046,0.037,0.084,0.037,0.098c0,0.023,0,0.031-0.008,0.039
               c-0.021,0.007-0.067,0.007-0.137,0.007c-0.104,0-0.129,0.038-0.129,0.107c0,0.008,0.008,0.023,0.008,0.045
               c0.008,0.046,0.017,0.107-0.03,0.168c-0.067,0.099-0.13,0.091-0.198,0.084c-0.029-0.007-0.061-0.007-0.091-0.007h-0.045
               c-0.106,0-0.177,0-0.267,0.167c-0.078,0.129,0.021,0.182,0.135,0.251c0.07,0.038,0.146,0.084,0.177,0.153
               c0.007,0.022,0.015,0.029,0.015,0.029c-0.029,0.016-0.129-0.007-0.242-0.037c-0.055-0.015-0.107-0.031-0.168-0.038
               c-0.039-0.009-0.069-0.016-0.1-0.023c-0.16-0.03-0.189-0.038-0.26-0.16c-0.016-0.023-0.036-0.046-0.053-0.069
               c-0.053-0.03-0.115-0.046-0.176-0.038c-0.061,0-0.113,0.024-0.168,0.054c-0.046,0.022-0.082,0.053-0.105,0.084
               c-0.038,0.052-0.047,0.129-0.047,0.227c-0.006,0.1-0.006,0.229-0.067,0.359c-0.062,0.137-0.229,0.266-0.396,0.388
               c-0.129,0.106-0.267,0.206-0.334,0.312C208.519,36.021,208.479,36.196,208.463,36.348z M187.243,47.457
               c-0.113,0.053-0.251,0.083-0.319,0.083c-0.038,0-0.067,0.008-0.1,0.023c-0.022,0.023-0.046,0.046-0.062,0.076
               c-0.016,0.031-0.021,0.062-0.021,0.1c0,0.061,0.016,0.137,0.061,0.205c0.045,0.076,0.1,0.122,0.16,0.16
               c0.066,0.054,0.137,0.114,0.176,0.244c0.016,0.068-0.008,0.099-0.054,0.106c-0.056,0.015-0.129,0.015-0.206,0.015
               c-0.068,0-0.13,0-0.197,0.008c-0.138,0.008-0.289,0.008-0.427,0c-0.114-0.015-0.222-0.038-0.297-0.076
               c-0.153-0.076-0.229-0.016-0.312,0.053c-0.024,0.023-0.056,0.053-0.092,0.069c-0.102,0.053-0.199,0.015-0.404-0.061l-0.084-0.03
               c-0.121-0.047-0.183-0.061-0.221-0.069c-0.061-0.015-0.084-0.023-0.197-0.084c-0.114-0.061-0.107-0.145-0.084-0.251
               c0.008-0.053,0.015-0.107,0.015-0.168v-0.068c0.008-0.16,0.008-0.236-0.205-0.472c-0.017-0.015-0.03-0.031-0.047-0.046
               c0.03-0.038,0.077-0.061,0.129-0.084c0.162-0.053,0.215-0.053,0.306-0.045c0.039,0.007,0.092,0.007,0.153,0.007
               c0.076,0,0.144-0.021,0.221-0.053c0.146-0.053,0.313-0.122,0.616,0.015c0.402,0.183,0.616,0.191,0.846,0.191
               c0.047,0.007,0.1,0.007,0.151,0.007c0.146,0.008,0.244,0.016,0.313,0.031c0.061,0.015,0.105,0.037,0.137,0.069
               C187.213,47.427,187.229,47.441,187.243,47.457z M202.517,54.85c-0.121,0-0.242,0.076-0.272,0.122
               c-0.04,0.045-0.138-0.038-0.19,0.045c-0.046,0.084,0.016,0.222-0.046,0.26c-0.062,0.045-0.282,0.091-0.305,0.159
               c-0.022,0.069-0.076,0.183-0.022,0.244c0.045,0.061,0.084,0.183,0.068,0.274c-0.023,0.091,0.055,0.099,0.061,0.206
               c0,0.099,0.069,0.167,0.129,0.167c0.056-0.008,0.153,0.038,0.237,0.084s0.122,0.007,0.205,0.046
               c0.084,0.037,0.313,0.068,0.366-0.016c0.053-0.091,0.076-0.114,0.184-0.091c0.098,0.023,0.09-0.023,0.166-0.129
               c0.069-0.115,0.137-0.153,0.168-0.191c0.03-0.046,0.03-0.152,0.039-0.259c0.014-0.114,0.008-0.289,0.074-0.373
               c0.076-0.084,0.191-0.091,0.176-0.19c-0.023-0.107-0.107-0.015-0.076-0.145c0.03-0.137-0.022-0.243-0.1-0.243
               c-0.083,0-0.083-0.099-0.234-0.046c-0.146,0.053-0.291,0.076-0.358,0.076c-0.068,0-0.091-0.007-0.13-0.015
               S202.517,54.85,202.517,54.85z"/>
             <path fill="#808184" d="M212.385,37.635c0.177,0.351,0.663,0.632,0.663,0.632
               s0.578,0.525,0.678,0.769c0.099,0.236,0.174,0.48,0.267,0.624c0.084,0.138,0.206,0.45,0.237,0.45
               c0.037,0,0.233,0.472,0.099,0.57c-0.138,0.1-0.291,0.016-0.358,0.138c-0.068,0.121,0.008,0.35-0.113,0.335
               c-0.123-0.008-0.273-0.145-0.336-0.145c-0.068,0-0.365-0.137-0.396,0.077c-0.038,0.212-0.229,0.205-0.229,0.205
               s-0.121-0.084-0.189,0.031c-0.066,0.113-0.176,0.273-0.258,0.137c-0.076-0.137-0.357-0.031-0.352-0.192
               c0.016-0.159,0.092-0.463,0.016-0.57c-0.076-0.114-0.168-0.282-0.137-0.389c0.037-0.098,0.205-0.235,0.067-0.342
               c-0.136-0.115-0.313-0.563-0.479-0.647c-0.174-0.077-0.433-0.282-0.25-0.335c0.182-0.053,0.28-0.008,0.258-0.16
               c-0.022-0.146-0.19-0.259-0.235-0.335c-0.015-0.03-0.046-0.084-0.084-0.122c0.031-0.045,0.054-0.106,0.077-0.175
               c0.029-0.099,0.029-0.19,0.035-0.259v-0.084c0.01-0.045,0.031-0.068,0.107-0.061c0.168,0.023,0.244,0.023,0.313,0.023h0.106
               h0.006c0.115,0.015,0.199,0.023,0.328-0.053C212.271,37.726,212.323,37.688,212.385,37.635"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M212.385,37.635c0.177,0.351,0.663,0.632,0.663,0.632s0.578,0.525,0.678,0.769c0.099,0.236,0.174,0.48,0.267,0.624
               c0.084,0.138,0.206,0.45,0.237,0.45c0.037,0,0.233,0.472,0.099,0.57c-0.138,0.1-0.291,0.016-0.358,0.138
               c-0.068,0.121,0.008,0.35-0.113,0.335c-0.123-0.008-0.273-0.145-0.336-0.145c-0.068,0-0.365-0.137-0.396,0.077
               c-0.038,0.212-0.229,0.205-0.229,0.205s-0.121-0.084-0.189,0.031c-0.066,0.113-0.176,0.273-0.258,0.137
               c-0.076-0.137-0.357-0.031-0.352-0.192c0.016-0.159,0.092-0.463,0.016-0.57c-0.076-0.114-0.168-0.282-0.137-0.389
               c0.037-0.098,0.205-0.235,0.067-0.342c-0.136-0.115-0.313-0.563-0.479-0.647c-0.174-0.077-0.433-0.282-0.25-0.335
               c0.182-0.053,0.28-0.008,0.258-0.16c-0.022-0.146-0.19-0.259-0.235-0.335c-0.015-0.03-0.046-0.084-0.084-0.122
               c0.031-0.045,0.054-0.106,0.077-0.175c0.029-0.099,0.029-0.19,0.035-0.259v-0.084c0.01-0.045,0.031-0.068,0.107-0.061
               c0.168,0.023,0.244,0.023,0.313,0.023h0.106h0.006c0.115,0.015,0.199,0.023,0.328-0.053
               C212.271,37.726,212.323,37.688,212.385,37.635z"/>
             <path fill="#808184" d="M212.079,34.033c-0.104,0.092-0.196,0.221-0.196,0.374
               c0,0.327,0.099,0.403,0.19,0.631c0.09,0.222,0.213,0.237,0.045,0.434c-0.167,0.206-0.281,0.274-0.281,0.396
               s-0.184,0.259-0.336,0.305c-0.15,0.046-0.221,0.197-0.305,0.305c-0.084,0.098,0.054,0.235,0.084,0.349
               c0.039,0.123-0.008,0.198,0.137,0.237c0.146,0.038,0.822,0.045,0.922,0.449c0.01,0.008,0.01,0.015,0.01,0.023
               c-0.07,0.053-0.132,0.106-0.177,0.129c-0.099,0.061-0.167,0.054-0.267,0.046h-0.008c-0.047-0.008-0.076,0-0.113,0
               c-0.069,0-0.139,0-0.299-0.023c-0.158-0.023-0.205,0.038-0.221,0.144c-0.008,0.031-0.008,0.062-0.008,0.092
               c0,0.069,0,0.152-0.029,0.236c-0.017,0.06-0.039,0.107-0.063,0.137c-0.036-0.015-0.083-0.03-0.137-0.022
               c-0.159,0.022-0.411-0.123-0.495-0.123c-0.076,0-0.16,0.191-0.334,0.091c-0.183-0.106-0.077-0.174-0.281-0.205
               c-0.207-0.03-0.405-0.122-0.336-0.259c0.062-0.137,0.267-0.19,0.188-0.335c-0.082-0.152-0.168-0.16-0.158-0.342
               c0.015-0.175,0.099-0.518-0.19-0.541s-0.334,0.046-0.51-0.038c-0.106-0.061-0.236-0.136-0.351-0.168
               c0.015-0.129,0.047-0.297,0.131-0.411c0.06-0.091,0.188-0.182,0.312-0.282c0.175-0.136,0.356-0.274,0.427-0.426
               c0.068-0.152,0.074-0.289,0.074-0.404c0.008-0.075,0.008-0.136,0.032-0.167c0.015-0.023,0.037-0.038,0.075-0.06
               c0.037-0.016,0.076-0.031,0.123-0.039c0.035,0,0.074,0,0.099,0.023c0.015,0.007,0.021,0.023,0.028,0.038
               c0.093,0.16,0.14,0.168,0.328,0.205c0.031,0.008,0.062,0.016,0.107,0.023c0.045,0.008,0.105,0.023,0.15,0.039
               c0.138,0.03,0.252,0.06,0.313,0.03c0.061-0.023,0.075-0.076,0.037-0.16c-0.046-0.107-0.136-0.159-0.22-0.206
               c-0.069-0.037-0.123-0.067-0.093-0.114c0.062-0.114,0.106-0.114,0.177-0.114h0.045c0.03,0,0.054,0.008,0.076,0.008
               c0.099,0.015,0.196,0.03,0.297-0.122c0.067-0.099,0.054-0.183,0.047-0.244v-0.03h0.021c0.084,0,0.138-0.008,0.176-0.023
               c0.053-0.023,0.068-0.061,0.068-0.13c0-0.038-0.022-0.084-0.045-0.144c-0.023-0.046-0.047-0.099-0.047-0.145
               c-0.009-0.023,0-0.038,0.023-0.038c0.09-0.015,0.113,0.015,0.136,0.053c0.022,0.023,0.047,0.054,0.084,0.077
               c0.077,0.029,0.206,0.038,0.282,0.038c0,0.015,0,0.03,0.008,0.046C211.858,33.804,211.966,33.927,212.079,34.033"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M212.079,34.033c-0.104,0.092-0.196,0.221-0.196,0.374c0,0.327,0.099,0.403,0.19,0.631c0.09,0.222,0.213,0.237,0.045,0.434
               c-0.167,0.206-0.281,0.274-0.281,0.396s-0.184,0.259-0.336,0.305c-0.15,0.046-0.221,0.197-0.305,0.305
               c-0.084,0.098,0.054,0.235,0.084,0.349c0.039,0.123-0.008,0.198,0.137,0.237c0.146,0.038,0.822,0.045,0.922,0.449
               c0.01,0.008,0.01,0.015,0.01,0.023c-0.07,0.053-0.132,0.106-0.177,0.129c-0.099,0.061-0.167,0.054-0.267,0.046h-0.008
               c-0.047-0.008-0.076,0-0.113,0c-0.069,0-0.139,0-0.299-0.023c-0.158-0.023-0.205,0.038-0.221,0.144
               c-0.008,0.031-0.008,0.062-0.008,0.092c0,0.069,0,0.152-0.029,0.236c-0.017,0.06-0.039,0.107-0.063,0.137
               c-0.036-0.015-0.083-0.03-0.137-0.022c-0.159,0.022-0.411-0.123-0.495-0.123c-0.076,0-0.16,0.191-0.334,0.091
               c-0.183-0.106-0.077-0.174-0.281-0.205c-0.207-0.03-0.405-0.122-0.336-0.259c0.062-0.137,0.267-0.19,0.188-0.335
               c-0.082-0.152-0.168-0.16-0.158-0.342c0.015-0.175,0.099-0.518-0.19-0.541s-0.334,0.046-0.51-0.038
               c-0.106-0.061-0.236-0.136-0.351-0.168c0.015-0.129,0.047-0.297,0.131-0.411c0.06-0.091,0.188-0.182,0.312-0.282
               c0.175-0.136,0.356-0.274,0.427-0.426c0.068-0.152,0.074-0.289,0.074-0.404c0.008-0.075,0.008-0.136,0.032-0.167
               c0.015-0.023,0.037-0.038,0.075-0.06c0.037-0.016,0.076-0.031,0.123-0.039c0.035,0,0.074,0,0.099,0.023
               c0.015,0.007,0.021,0.023,0.028,0.038c0.093,0.16,0.14,0.168,0.328,0.205c0.031,0.008,0.062,0.016,0.107,0.023
               c0.045,0.008,0.105,0.023,0.15,0.039c0.138,0.03,0.252,0.06,0.313,0.03c0.061-0.023,0.075-0.076,0.037-0.16
               c-0.046-0.107-0.136-0.159-0.22-0.206c-0.069-0.037-0.123-0.067-0.093-0.114c0.062-0.114,0.106-0.114,0.177-0.114h0.045
               c0.03,0,0.054,0.008,0.076,0.008c0.099,0.015,0.196,0.03,0.297-0.122c0.067-0.099,0.054-0.183,0.047-0.244v-0.03h0.021
               c0.084,0,0.138-0.008,0.176-0.023c0.053-0.023,0.068-0.061,0.068-0.13c0-0.038-0.022-0.084-0.045-0.144
               c-0.023-0.046-0.047-0.099-0.047-0.145c-0.009-0.023,0-0.038,0.023-0.038c0.09-0.015,0.113,0.015,0.136,0.053
               c0.022,0.023,0.047,0.054,0.084,0.077c0.077,0.029,0.206,0.038,0.282,0.038c0,0.015,0,0.03,0.008,0.046
               C211.858,33.804,211.966,33.927,212.079,34.033z"/>
             <polygon fill="#808184" points="166.345,36.021 166.345,36.021 166.345,36.013 				"/>
             <polygon fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" points="
               166.345,36.021 166.345,36.021 166.345,36.013 				"/>
             <path fill="#808184" d="M166.345,36.021v-0.008V36.021z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M166.345,36.021v-0.008V36.021z"/>
             <path fill="#808184" d="M134.135,10.063c0,0,0.048,0.093,0.123,0.132
               c0.067,0.03,0.146-0.031,0.175-0.084c0.039-0.061,0.008-0.198,0.131-0.152c0.113,0.045,0.228,0.152,0.434,0.152
               c0.205,0.008,0.365-0.024,0.313,0.06c-0.061,0.092-0.418,0.008-0.435,0.1c-0.015,0.091,0.046,0.007,0.267,0.091
               c0.228,0.091,0.26,0.069,0.459,0.115c0.205,0.038,0.065-0.138,0.531,0.007c0.458,0.144,0.374-0.069,0.814,0.122
               c0.435,0.19,1.59,0.586,1.729,0.677c0.137,0.092,0.297,0.046,0.465,0.137c0.174,0.1,0.197,0.076,0.35,0.083
               c0.152,0.017,0.129,0.084,0.365,0.161c0.235,0.076,0.319,0.114,0.486,0.244c0.176,0.129,0.139,0.22,0.223,0.297
               c0.076,0.076,0.205,0.114,0.144,0.228c-0.054,0.107-0.214,0.32-0.532,0.472c-0.313,0.16-0.83,0.45-1.613,0.221
               c-0.776-0.228-0.809-0.106-1.256-0.244c-0.449-0.144-0.961-0.388-1.091-0.373c-0.12,0.008-0.364,0.008-0.556-0.076
               c-0.189-0.092-0.457-0.282-0.479-0.175c-0.016,0.099,0.114,0.083,0.303,0.251c0.191,0.168,0.367,0.061,0.504,0.244
               c0.146,0.175,0.1,0.137,0.418,0.274c0.328,0.137,0.441-0.007,0.52,0.267c0.084,0.266-0.152,0.38-0.021,0.532
               c0.129,0.16,0.234,0.153,0.228,0.312c-0.007,0.16-0.03,0.29,0.123,0.328c0.151,0.037,0.371-0.015,0.569,0.084
               c0.199,0.099,0.215,0.205,0.351,0.259c0.146,0.053,0.458,0.091,0.541,0.114c0.084,0.023,0.267,0.023,0.335-0.008
               c0.068-0.03,0.137-0.03,0.137-0.144c0-0.115-0.022-0.236-0.158-0.236c-0.137,0-0.229,0.061-0.35,0
               c-0.115-0.061-0.131-0.13-0.291-0.214c-0.152-0.084-0.343-0.183-0.268-0.235c0.076-0.046,0.207-0.031,0.229-0.115
               c0.016-0.083,0.053-0.145,0.184-0.13c0.131,0.016,0.479,0.206,0.586,0.289c0.113,0.084,0.105,0.039,0.373,0.077
               c0.273,0.038,0.373,0.069,0.496,0.107c0.121,0.038,0.508,0.076,0.601,0.053c0.093-0.023,0.205-0.076,0.062-0.19
               c-0.137-0.114-0.776-0.457-0.578-0.587c0.197-0.129,0.365-0.159,0.449-0.281c0.084-0.13,0.053-0.076,0.28-0.16
               c0.222-0.084,0.199-0.282,0.45-0.32c0.243-0.038,0.182,0.008,0.389,0.038c0.196,0.031,0.473-0.114,0.577,0.069
               c0.115,0.183,0.138,0.206,0.214,0.206c0.084,0.007,0.084-0.161,0.151-0.282c0.069-0.114,0.024-0.457-0.06-0.534
               c-0.084-0.068-0.436,0.069-0.436-0.152c0.008-0.221,0.189-0.251,0.129-0.464c-0.054-0.214-0.021-0.434-0.266-0.465
               c-0.252-0.023-0.617-0.167-0.413-0.19c0.207-0.023,0.313,0.023,0.442,0.053c0.121,0.031,0.26,0.008,0.436,0.008
               s0.434-0.084,0.631,0.061c0.198,0.137,0.268,0.092,0.389,0.243c0.123,0.16,0.352,0.183,0.344,0.297
               c-0.016,0.107-0.184,0.068-0.357,0.092c-0.167,0.015-0.275-0.024-0.412,0.045c-0.137,0.077-0.197,0.015-0.175,0.138
               c0.022,0.121,0.129,0.198,0.206,0.205c0.083,0.008,0.234-0.015,0.273,0.091c0.039,0.107,0.197,0.214,0.236,0.221
               c0.028,0.008,0.623,0.092,0.813,0.015c0.185-0.076,0.3-0.076,0.244-0.243c-0.061-0.175-0.129-0.343-0.037-0.373
               c0.098-0.039,0.182,0.038,0.312-0.046c0.129-0.084,0.069-0.152,0.374-0.206c0.313-0.061,0.753-0.304,0.967-0.335
               c0.215-0.03,0.272,0.046,0.42-0.038c0.144-0.084,0.234-0.167,0.28-0.076c0.045,0.084,0.093,0.16,0.177,0.137
               c0.09-0.023,0.121-0.159,0.036-0.213c-0.084-0.053-0.021-0.053,0.085-0.122c0.105-0.06,0.372-0.152,0.549-0.152
               c0.168-0.008,0.563-0.129,0.457,0c-0.107,0.137-0.52,0.152-0.397,0.236c0.114,0.092,0.222,0.114,0.131,0.183
               c-0.092,0.068-0.26,0.136-0.099,0.167c0.166,0.023,0.197-0.053,0.396,0c0.197,0.06,0.282,0.115,0.334,0.023
               c0.062-0.099,0.009-0.183,0.099-0.221c0.101-0.038,0.168-0.159,0.412-0.122c0.252,0.039,0.213-0.022,0.426,0.016
               c0.215,0.046,0.236,0.152,0.396,0.053c0.159-0.099,0.273-0.267,0.51-0.267c0.237,0,0.449-0.129,0.533-0.076
               c0.077,0.054,0.243,0.069,0.197,0.183c-0.045,0.107-0.243,0.182-0.098,0.259c0.144,0.069,0.234,0.084,0.365,0.069
               c0.129-0.016,0.157-0.077,0.121-0.168c-0.029-0.091,0.021-0.145,0.129-0.152c0.106-0.008,0.288-0.016,0.281-0.122
               c-0.016-0.106-0.129-0.19-0.206-0.221c-0.075-0.03-0.084-0.038-0.175-0.137c-0.092-0.107-0.137-0.107-0.221-0.191
               c-0.084-0.075-0.198-0.075-0.297-0.068c-0.1,0.015-0.062-0.015-0.258-0.076c-0.199-0.061-0.429-0.106-0.572-0.229
               c-0.146-0.114-0.328-0.273-0.105-0.236c0.221,0.046,0.25-0.053,0.365-0.022c0.113,0.022,0.169,0.144,0.281,0.183
               c0.121,0.038,0.381,0.075,0.502,0.122c0.121,0.044,0.221,0.106,0.197,0.174c-0.021,0.069-0.021,0.084,0.068,0.099
               c0.092,0.008,0.068-0.152,0.177-0.16c0.099-0.007,0.104,0.039,0.372,0.047c0.273,0.007,0.631,0.06,0.967,0.098
               c0.344,0.031,0.557,0.023,0.867,0.167c0.313,0.146,1.014,0.29,1.387,0.435c0.366,0.152,0.754,0.091,0.998,0.251
               c0.25,0.16,0.396,0.349,0.51,0.305c0.105-0.047,0.076-0.168,0.131-0.381c0.06-0.221-0.031-0.304-0.237-0.274
               c-0.196,0.037-0.351-0.03-0.471-0.168c-0.123-0.137,0.036-0.19-0.261-0.205c-0.289-0.008-0.351-0.099-0.524-0.099
               c-0.183,0-0.175,0.121-0.222-0.016c-0.053-0.137-0.053-0.251,0.016-0.281c0.063-0.039,0.076-0.13,0.023-0.183
               c-0.055-0.054,0.061-0.403-0.176-0.411c-0.229,0-0.441,0.023-0.428-0.153c0.016-0.167,0.129-0.212,0.26-0.273
               c0.129-0.062,0.176-0.054,0.297-0.153c0.114-0.107,0.084-0.602,0.1-0.761c0.008-0.16-0.038-0.267,0.085-0.275
               c0.12-0.014,0.188,0.046,0.196-0.06c0-0.114-0.168-0.328-0.037-0.343c0.129-0.023,0.234,0,0.365,0.015
               c0.138,0.008,0.25,0,0.326,0.069c0.077,0.069,0.168,0.038,0.223,0.03c0.052-0.007,0.281,0.053,0.166,0.107
               c-0.114,0.053-0.373,0.046-0.523,0.076c-0.152,0.038-0.458,0.069-0.466,0.099c-0.007,0.031-0.076,0.084,0.101,0.091
               c0.184,0.008,0.494-0.007,0.838-0.014c0.351,0,0.563,0.038,0.754,0.091c0.184,0.053,0.16,0.007,0.213,0.175
               c0.045,0.168,0.16,0.228,0.16,0.327c0,0.098-0.024,0.243-0.069,0.32c-0.054,0.076-0.253,0.137-0.076,0.221
               c0.175,0.083,0.608,0.121,0.692,0.296c0.084,0.176,0.039,0.229,0.139,0.305c0.091,0.068,0.045,0.152,0.045,0.244
               c0,0.084,0.152,0.137,0.205,0.212c0.046,0.069,0.008,0.176,0.046,0.268c0.046,0.091,0.069,0.144,0.16,0.327
               c0.099,0.183,0.008,0.175,0.213,0.282c0.206,0.106,0.815,0.167,0.785,0.267c-0.039,0.106-0.229,0.166-0.168,0.265
               c0.069,0.099,0.235,0.123,0.175,0.198c-0.068,0.069-0.084,0.221-0.152,0.29c-0.076,0.076-0.068,0.167-0.176,0.32
               c-0.1,0.16-0.342,0.243-0.593,0.289c-0.252,0.046-0.29,0.137-0.396,0.114c-0.101-0.022-0.336-0.077-0.496-0.091
               c-0.168-0.015-0.373,0.031-0.252,0.114c0.123,0.091,0.168-0.016,0.366,0.099c0.189,0.114,0.304,0.107,0.494,0.122
               c0.19,0.008,0.282,0.076,0.488,0.107c0.196,0.022,0.35-0.069,0.555-0.031c0.199,0.038,0.267,0.06,0.306-0.076
               c0.045-0.13,0.017-0.182,0.151-0.237c0.13-0.044,0.526-0.136,0.502-0.304c-0.021-0.175-0.104-0.282-0.015-0.365
               c0.092-0.092,0.244-0.32,0.113-0.45c-0.138-0.129-0.273-0.045-0.342-0.198c-0.062-0.159,0.022-0.205-0.084-0.258
               c-0.107-0.046-0.145-0.13,0.029-0.16c0.176-0.03,0.831-0.16,1.006-0.107c0.176,0.053,0.273,0.229,0.479,0.29
               c0.205,0.052,0.268,0.235,0.344,0.365c0.074,0.129,0.061,0.366,0.213,0.381c0.152,0.007,0.534,0.068,0.419-0.008
               c-0.122-0.068-0.356-0.122-0.382-0.244c-0.029-0.114,0.146-0.114,0.092-0.229c-0.053-0.114-0.129-0.068-0.258-0.229
               c-0.131-0.167-0.139-0.25-0.328-0.281c-0.184-0.03-0.326-0.053-0.434-0.122c-0.106-0.061-0.106-0.046-0.448-0.091
               c-0.336-0.046-0.366-0.046-0.526,0.007c-0.167,0.046-0.251,0.062-0.441,0.054c-0.189-0.008-0.305,0.129-0.41-0.008
               c-0.1-0.137-0.139-0.305-0.26-0.358c-0.129-0.053-0.273-0.122-0.273-0.244c0-0.121,0.021-0.197,0.068-0.281
               c0.045-0.092,0.076-0.305-0.114-0.404c-0.197-0.099-0.388-0.137-0.488-0.251c-0.099-0.122-0.517-0.107-0.455-0.221
               c0.06-0.114,0.188-0.175,0.188-0.244c0-0.067-0.037-0.151,0.068-0.151c0.106,0,0.641-0.077,0.533-0.274
               c-0.105-0.199-0.326-0.282-0.281-0.366c0.055-0.077,0.131-0.092,0.297,0.023c0.168,0.121,0.222,0.114,0.328,0.282
               c0.106,0.167-0.031,0.19,0.06,0.38c0.092,0.183,0.084,0.274,0.223,0.366c0.146,0.091,0.067,0.061,0.32,0.098
               c0.258,0.038,0.396,0.092,0.661,0.092s0.486-0.038,0.589,0.03c0.104,0.069,0.144,0.16,0.271,0.122
               c0.131-0.031,0.092-0.152-0.145-0.206c-0.229-0.053-0.273-0.121-0.494-0.121c-0.214,0-0.375-0.061-0.533-0.084
               c-0.168-0.023-0.289-0.023-0.336-0.114c-0.045-0.1-0.281-0.221-0.189-0.267c0.092-0.045,0.449-0.129,0.58-0.038
               c0.129,0.092,0.623,0.205,0.547,0.077c-0.084-0.13-0.281-0.108-0.35-0.108s-0.214-0.068-0.094-0.121
               c0.123-0.053,0.191-0.236,0.611-0.122c0.41,0.114,0.145-0.137,0.615,0.077c0.473,0.212,0.289,0.19,0.662,0.243
               c0.373,0.053,0.655,0.281,0.891,0.243c0.23-0.03,0.39-0.197,0.199-0.258c-0.19-0.062-0.222-0.252-0.474-0.252
               c-0.258,0-0.267-0.046-0.653-0.175c-0.39-0.129-0.328-0.365-0.488-0.396c-0.168-0.03-0.258-0.083-0.297-0.167
               c-0.039-0.076-0.112-0.274,0.32-0.213c0.434,0.068,0.365-0.039,0.793-0.023c0.426,0.015,0.949-0.038,1.18-0.069
               c0.221-0.038,0.41,0.016,0.563-0.008c0.158-0.023,0.371-0.023,0.388-0.091c0.008-0.076-0.039-0.266-0.229-0.258
               c-0.189,0-0.607,0.068-0.494-0.047c0.122-0.106,0.268-0.273,0.061-0.235c-0.198,0.045-0.28-0.115-0.221-0.153
               c0.068-0.037,0.328,0.115,0.404,0.023c0.075-0.084-0.122-0.129-0.018-0.19c0.108-0.069,0.336-0.084,0.504-0.13
               c0.168-0.038,0.352-0.152,0.41-0.167c0.063-0.015,0.754-0.252,1.182-0.228c0.426,0.022,0.531,0,0.57-0.091
               c0.045-0.092,0-0.222,0.25-0.146c0.244,0.077,1.067-0.076,1.295-0.015c0.223,0.069,0,0.236,0.382,0.183
               c0.381-0.053,0.44-0.282,0.8-0.213c0.357,0.068,0.875,0.258,0.607,0.053c-0.273-0.198-0.799-0.304-0.51-0.319
               c0.297-0.023,0.633,0.22,0.799,0.129c0.168-0.091,0.281-0.129,0.146-0.259c-0.129-0.122-0.077-0.411-0.008-0.473
               c0.068-0.052,0.541-0.312,0.867-0.213c0.32,0.099,0.457,0.039,0.731,0.099c0.267,0.069,0.647,0.016,0.548,0.107
               c-0.099,0.091-0.541,0.282-0.313,0.289c0.222,0.016,0.299-0.136,0.533-0.007c0.236,0.121,0.42-0.023,0.646,0.053
               c0.221,0.077,0.396-0.03,0.297,0.122c-0.105,0.145-0.105,0.237,0.023,0.237c0.13,0.007,0.389,0.075,0.449-0.024
               c0.054-0.091,0.121-0.174,0.205-0.167c0.084,0.015,0.679-0.038,1.408,0.038c0.73,0.069,1.021,0.281,1.264,0.32
               c0.244,0.03,0.488,0.06,0.771,0.175c0.281,0.106,0.601,0.098,0.616,0.221c0.023,0.121,0.214,0.152,0.077,0.319
               c-0.146,0.168-0.427,0.366-0.589,0.45c-0.157,0.076-0.295-0.008-0.471,0.129c-0.176,0.145-0.221,0.19-0.396,0.335
               c-0.176,0.144-0.205,0.16-0.335,0.228c-0.13,0.069-0.306-0.068-0.458,0.107c-0.158,0.175-0.166,0.236-0.28,0.244
               c-0.106,0.007-0.313,0.206-0.159,0.244c0.152,0.03,0.159-0.122,0.334-0.145c0.184-0.023,0.487-0.092,0.617-0.099
               c0.129-0.008,0.441,0.03,0.641-0.054c0.196-0.084,0.676-0.205,0.662-0.258c-0.017-0.062-0.322,0.022-0.466-0.046
               c-0.138-0.069-0.146-0.29,0.054-0.282c0.205,0.008,0.487-0.076,0.646,0.046c0.16,0.114,0.184,0.22,0.396,0.22
               c0.211,0,0.479,0.115,0.502,0.016c0.031-0.092,0.037-0.168-0.084-0.213c-0.113-0.046-0.176-0.13-0.305-0.084
               c-0.138,0.038-0.609-0.038-0.586-0.168c0.021-0.129,0.047-0.236,0.26-0.175c0.213,0.053,0.518,0.007,0.584,0.068
               c0.069,0.063,0,0.183,0.101,0.275c0.101,0.098,0.374,0.136,0.479,0.236c0.106,0.107,0.258,0.35,0.373,0.236
               c0.114-0.107,0.076-0.061,0.281-0.084c0.206-0.023,1.081-0.235,1.438-0.107c0.358,0.137,1.228,0.039,1.272,0.115
               c0.046,0.076-0.274,0.229,0,0.281c0.267,0.054,1.324,0.168,1.856,0.206c0.525,0.031,1.158,0.061,1.043-0.137
               c-0.113-0.205-0.776-0.472-0.486-0.472c0.298,0,0.305-0.038,0.457-0.038c0.145,0,0.296,0.114,0.494,0.16
               c0.191,0.046,0.708,0.099,1.013,0.053c0.298-0.038,0.153,0.023,0.815,0.114c0.662,0.092,1.097,0.137,1.173,0.351
               c0.075,0.213,0.325,0.243,0.474,0.349c0.143,0.1,0.547,0.176,0.28,0.244c-0.274,0.069-0.662,0.038-0.38,0.146
               c0.281,0.113,0.875,0.113,1.143,0.318c0.271,0.199,0.348,0.214,0.698,0.335c0.351,0.123,0.479,0.237,0.677,0.161
               c0.191-0.084,0.291-0.168,0.133-0.358c-0.153-0.198-0.437-0.579-0.092-0.488c0.35,0.092,0.325,0.213,0.532,0.252
               c0.196,0.03,0.167,0.038,0.631,0.061c0.457,0.023,0.753-0.076,0.976-0.1c0.229-0.022,0.473-0.037,0.707,0.077
               c0.236,0.106,0.365,0.251,0.558,0.228c0.183-0.023-0.153-0.296-0.009-0.312c0.139-0.008,0.222,0.046,0.373,0.069
               c0.145,0.022,0.486,0.213,0.496,0.061c0-0.145-0.047-0.252-0.223-0.282c-0.168-0.039-0.113-0.106-0.212-0.19
               c-0.108-0.084-0.259-0.039-0.343-0.069c-0.084-0.038-0.282-0.213-0.122-0.244c0.168-0.03,0.427,0.053,0.44-0.03
               c0.023-0.084-0.168-0.236,0-0.243c0.16-0.009,0.168,0.083,0.396,0.114c0.229,0.022,1.522,0,1.782,0.038
               c0.258,0.045,0.587,0.129,0.867,0.137c0.283,0.015,0.846,0.046,0.822,0.167c-0.023,0.13-0.44,0.106-0.396,0.259
               c0.046,0.16,0.235,0.328,0.403,0.16c0.166-0.167-0.068-0.427,0.229-0.442c0.289-0.008,0.876-0.03,1.271,0.061
               c0.389,0.092,1.098,0.077,0.853,0.191c-0.251,0.113-0.808,0.098-0.419,0.19c0.396,0.091,0.512-0.167,0.885,0.007
               c0.364,0.183,0.524,0.153,0.852,0.206c0.328,0.054,0.457,0.252,0.9,0.304c0.439,0.054,1.324,0.046,1.674-0.068
               c0.351-0.114,0.549,0.023,1.021,0.068c0.474,0.046,1.522,0.008,1.972,0.183c0.45,0.168,0.609,0.358,0.922,0.518
               c0.313,0.152,0.563,0.297,1.303,0.297c0.746,0,0.273-0.175,1.24-0.03c0.969,0.144,1.683,0.159,1.936,0.144
               c0.242-0.007,0.259-0.358,0.425-0.373c0.168-0.007,1.104-0.022,1.062,0.152c-0.047,0.168-0.746,0.023-0.512,0.221
               c0.235,0.206,0.512,0.252,0.7,0.259c0.19,0.015,0.373,0.092,0.524,0.183c0.16,0.091,0.588,0.312,0.693,0.076
               c0.1-0.236,0.061-0.35-0.281-0.449c-0.351-0.099-0.512-0.16-0.655-0.267c-0.146-0.114-0.617-0.35-0.183-0.297
               c0.441,0.061,1.734,0.213,2.474,0.252c0.748,0.03,0.776-0.16,1.387,0.083c0.608,0.252,1.942,0.343,2.619,0.541
               c0.679,0.205,0.899,0.251,1.554,0.32c0.654,0.068,0.494,0.312,1.303,0.517c0.813,0.198,1.865,0.222,2.299,0.541
               c0.427,0.312,0.715,0.45,0.967,0.579c0.245,0.137,0.688,0.129,0.58,0.022c-0.1-0.099-0.572-0.175-0.557-0.258
               c0.008-0.076,0.213-0.076,0.448-0.023c0.236,0.061,0.175,0.038,0.472,0.106c0.291,0.069,0.383,0.024,0.563,0.024
               c0.177,0,0.123,0.052,0.429,0.122c0.303,0.068,0.409,0.091,0.836,0.234c0.434,0.146,0.678,0.223,0.846,0.306
               c0.168,0.076,0.441,0.167,0.326,0.266c-0.113,0.107-0.445,0.015-0.494,0.137c-0.047,0.122-0.457-0.054-0.357,0.099
               c0.1,0.16,0.646,0.282,0.563,0.419c-0.092,0.137,0.146,0.214,0.268,0.373c0.121,0.152,0.068,0.343-0.137,0.221
               c-0.197-0.122-0.307-0.168-0.48-0.137c-0.182,0.038,0.123,0.091-0.555-0.122c-0.671-0.213-1.266-0.114-1.652-0.388
               c-0.381-0.282-0.875-0.396-1.119-0.396c-0.252,0-0.106,0.068-0.586,0c-0.486-0.069-0.596-0.076-0.754-0.206
               c-0.16-0.122-0.381-0.472-0.641-0.426c-0.26,0.045-0.541,0.145-0.229,0.327s0.448,0.137,0.707,0.328
               c0.26,0.19,0.42,0.099,0.305,0.282c-0.112,0.175-0.144,0.121-0.213,0.266c-0.067,0.144-0.168,0.099-0.44,0.099
               c-0.267,0-0.944-0.122-1.226-0.153c-0.282-0.037-0.647,0.267-0.221,0.267c0.424,0,0.684-0.091,0.844,0
               c0.152,0.091,0.595,0.229,0.801,0.251c0.197,0.023,0.334-0.083,0.48,0.092c0.143,0.167,1.438,0.716,1.727,0.883
               c0.297,0.168,0.678,0.19,0.646,0.373c-0.037,0.183,0.107,0.259-0.229,0.213s-1.112-0.305-1.416-0.122
               c-0.305,0.176-0.145,0.328-0.373,0.358c-0.221,0.038-0.206,0.198-0.465,0.29c-0.258,0.091-0.351,0.03-0.42,0.236
               c-0.074,0.198-0.127,0.144-0.174,0.342c-0.045,0.198-0.152,0.221-0.184,0.282c-0.029,0.069-0.212,0.365-0.113,0.434
               c0.092,0.076,0.319,0.312,0.129,0.282c-0.188-0.023,0.106-0.1-0.51-0.237c-0.617-0.144-0.633-0.364-0.914-0.342
               c-0.273,0.023-0.654-0.022-0.77,0.023c-0.113,0.046-0.344,0.099-0.336,0.259c0.008,0.16,0.017,0.335-0.098,0.274
               c-0.107-0.069-0.427-0.541-0.641-0.411c-0.205,0.129-0.159,0.175-0.198,0.289c-0.045,0.114-0.022,0.327-0.144,0.235
               c-0.115-0.09-0.29-0.311-0.375-0.212c-0.09,0.099-0.25-0.077-0.318,0.068c-0.076,0.137-0.054,0.26,0.092,0.366
               c0.145,0.106,0.396,0.168,0.313,0.35c-0.084,0.175-0.235,0.046-0.1,0.312c0.146,0.267,0.357,0.693,0.51,0.762
               c0.152,0.076,0.244,0.152,0.32,0.129c0.084-0.03,0.045-0.275,0.305-0.159c0.252,0.121,0.479,0.007,0.449,0.19
               c-0.039,0.19-0.16,0.319-0.039,0.335c0.114,0.015,0.237,0.395,0.426,0.457c0.199,0.053,0.404-0.016,0.496,0.098
               c0.1,0.114,0.541,0.313,0.488,0.419c-0.063,0.099-0.063,0.167-0.275,0.099c-0.213-0.076-0.266-0.114-0.305-0.214
               c-0.039-0.106-0.343-0.212-0.281-0.084c0.061,0.139,0.222,0.032,0.16,0.214c-0.068,0.191-0.054,0.35,0.062,0.472
               c0.121,0.123,0.799,0.518,0.937,0.64c0.139,0.114,0.205,0.221,0.099,0.29c-0.104,0.06-0.281,0.022-0.438-0.008
               c-0.162-0.038-0.193,0.038-0.299,0.091c-0.107,0.046-0.16,0.335,0.008,0.563c0.168,0.236,0.448,0.35,0.504,0.434
               c0.053,0.084,0.006,0.114-0.177,0.137c-0.184,0.024-0.267,0.069-0.319,0.167c-0.054,0.1-0.129,0.153,0.016,0.313
               c0.145,0.153,0.213,0.206,0.32,0.419c0.104,0.213,0.166,0.244,0.082,0.396c-0.082,0.152-0.152,0.106-0.152,0.259
               c0,0.159,0.023,0.388-0.145,0.388c-0.175,0-0.076-0.046-0.326-0.213c-0.243-0.175-1.213-1.119-1.547-1.378
               c-0.342-0.259-0.707-0.473-0.836-0.632c-0.123-0.16-0.809-0.746-0.945-0.944c-0.137-0.206-1.156-1.059-1.295-1.363
               c-0.129-0.305-0.402-0.457-0.336-0.693c0.07-0.236,0.131-0.457-0.06-0.587c-0.188-0.129-0.428-0.342-0.252-0.304
               c0.177,0.031,0.222,0.205,0.321,0.084c0.091-0.122,0.091-0.244,0.197-0.228c0.099,0.022,0.213,0.167,0.235-0.062
               c0.03-0.22,0.222-0.457,0.175-0.601c-0.045-0.137-0.221-0.168-0.139-0.342c0.077-0.176,0.068-0.305,0.168-0.457
               c0.1-0.161,0.139-0.221,0.077-0.366c-0.067-0.137-0.093-0.183-0.03-0.259c0.063-0.068,0.114-0.236,0.244-0.273
               c0.129-0.032,0.313,0.037,0.197-0.116c-0.114-0.144-0.152-0.159-0.139-0.273c0.016-0.122-0.068-0.191-0.104-0.175
               c-0.038,0.008-0.214,0.091-0.222-0.107c-0.014-0.191-0.137-0.259-0.244-0.373c-0.105-0.115-0.243-0.244-0.167-0.32
               c0.075-0.084,0.229-0.152,0.075-0.191c-0.144-0.029-0.303-0.113-0.371-0.075c-0.078,0.038-0.291,0.068-0.366,0.098
               c-0.076,0.023-0.213,0.039-0.146,0.138c0.078,0.099,0.252,0.03,0.244,0.205c-0.009,0.175,0.068,0.214,0.168,0.282
               c0.09,0.061,0.373,0.114,0.258,0.183c-0.123,0.068-0.25-0.015-0.334,0.053c-0.084,0.061-0.076,0.13-0.146,0.236
               c-0.062,0.107-0.183,0.236-0.19,0.358c-0.017,0.114,0.031,0.19-0.067,0.198c-0.106,0.015-0.106,0.129-0.214,0
               c-0.105-0.13-0.105-0.381-0.229-0.35c-0.114,0.03-0.151,0.174-0.235,0.076c-0.082-0.092-0.105-0.221-0.221-0.259
               c-0.121-0.031-0.191,0.038-0.205-0.129c-0.017-0.168-0.168-0.473-0.273-0.389c-0.107,0.084-0.198,0.236-0.32,0.167
               c-0.113-0.075-0.167-0.243-0.389-0.182c-0.229,0.061-0.215,0.091-0.389,0.091c-0.175,0-0.306-0.053-0.449-0.008
               c-0.137,0.046-0.252,0.123-0.244,0.237c0.009,0.107,0.129,0.175,0.07,0.251c-0.063,0.069-0.123,0.099-0.1,0.175
               c0.021,0.083,0.145,0.137,0.105,0.244c-0.047,0.107-0.352,0.251-0.297,0.495c0.061,0.243,0.099,0.13,0.19,0.305
               c0.099,0.174,0.149,0.183,0.288,0.205c0.137,0.022,0.313,0.022,0.51,0.121c0.207,0.1,0.374,0.191,0.161,0.199
               c-0.213,0.014-0.274-0.084-0.403-0.015c-0.123,0.068-0.213,0.083-0.373,0.045s-0.344-0.16-0.381-0.061
               c-0.031,0.099-0.105,0.206-0.215,0.206c-0.105,0-0.204-0.084-0.355-0.008c-0.152,0.076-0.412,0.069-0.457,0.053
               c-0.055-0.022-0.412-0.22-0.289-0.243c0.121-0.03,0.35-0.008,0.273-0.122c-0.076-0.114-0.092-0.069-0.273-0.13
               c-0.184-0.067-0.717-0.144-1.09-0.197c-0.365-0.061-0.717-0.206-0.775-0.061c-0.055,0.145,0.326,0.258,0.136,0.327
               c-0.188,0.069-0.372,0.023-0.426-0.023c-0.054-0.046-0.306,0.023-0.381,0.038c-0.084,0.008-0.091,0-0.259-0.023
               c-0.168-0.022-0.541-0.19-0.586-0.061c-0.047,0.138-0.076,0.213-0.244,0.168c-0.176-0.038-0.236-0.274-0.411-0.198
               c-0.166,0.076-0.884,0.091-1.226,0.091c-0.336,0-0.641,0.023-0.693,0.168c-0.061,0.144-0.106,0.282-0.114,0.411
               c-0.008,0.137-0.008,0.16-0.075,0.282c-0.068,0.129-0.023,0.319-0.017,0.396c0.017,0.076-0.075,0.137-0.12,0.236
               c-0.046,0.099-0.214,0.251-0.289,0.434c-0.068,0.182,0,0.266-0.077,0.479c-0.075,0.206-0.122,0.153-0.122,0.351
               c0.008,0.205-0.037,0.228-0.121,0.373c-0.076,0.144-0.184,0.152-0.26,0.327c-0.066,0.175-0.197,0.137-0.243,0.29
               c-0.054,0.144-0.084,0.258,0.077,0.297c0.157,0.045,0.518,0.068,0.715,0.091c0.198,0.03,0.396-0.138,0.479-0.015
               c0.084,0.122,0.046,0.258,0.222,0.35c0.177,0.091,0.062,0.137,0.206,0.258c0.152,0.114,0.402,0.023,0.252-0.091
               c-0.152-0.114-0.191-0.312-0.031-0.289c0.168,0.03,0.197,0.091,0.205,0.175c0.01,0.084,0.039,0.144,0.139,0.144
               c0.09,0,0.281-0.106,0.213,0.061c-0.076,0.161-0.283,0.343-0.115,0.351c0.169,0.015,0.402,0,0.457-0.13
               c0.063-0.137,0.13-0.327,0.168-0.198c0.037,0.13,0.145,0.412,0.184,0.274c0.029-0.136,0.008-0.464-0.102-0.502
               c-0.104-0.038-0.311-0.259-0.104-0.198s0.35,0.023,0.586,0.053c0.228,0.031,0.289,0.244,0.557,0.366
               c0.266,0.114,0.951,0.403,1.164,0.525c0.221,0.114,0.197,0.191,0.197,0.267c0.008,0.076,0.221,0.122,0.26,0.221
               c0.037,0.098,0.045,0.258,0.205,0.342s0.289,0.206,0.328,0.152c0.03-0.053,0.082-0.159-0.067-0.312
               c-0.153-0.159-0.14-0.137-0.229-0.327c-0.1-0.183-0.373-0.381-0.305-0.472c0.075-0.084,0.189,0.023,0.281,0.023
               c0.084-0.008,0.236-0.175,0.055-0.282c-0.184-0.099-0.161-0.13-0.336-0.244c-0.168-0.121-0.51-0.312-0.365-0.312
               c0.152-0.007,0.236-0.16,0.356,0.023c0.114,0.191,0.13,0.16,0.298,0.274c0.167,0.122,0.229,0.03,0.396,0.282
               c0.16,0.259,0.426,0.549,0.67,0.739c0.236,0.19,0.229,0.319,0.356,0.501c0.132,0.184,0.588,0.732,0.878,0.93
               c0.297,0.206,0.471,0.517,0.684,0.7c0.213,0.183,0.608,0.488,0.938,0.754c0.327,0.266,1.226,0.853,0.966,0.83
               c-0.258-0.023-0.518-0.381-0.791-0.403c-0.268-0.023-0.602-0.031-0.594,0.068c0.016,0.099,0.167,0.396,0.167,0.518
               s0.069,0.701,0.328,0.83c0.259,0.136,0.472,0.304,0.563,0.418c0.092,0.115,0.334,0.221,0.472,0.312
               c0.137,0.092,0.396,0.168,0.487,0.275c0.082,0.099,0.365,0.571,0.242,0.495c-0.121-0.084-0.287-0.442-0.502-0.442
               c-0.213,0-0.343,0.023-0.419-0.031c-0.075-0.061-0.188-0.167-0.213-0.068s0.356,0.548,0.122,0.586
               c-0.236,0.031-0.139-0.174-0.374-0.35c-0.235-0.183-0.243-0.236-0.319-0.51c-0.084-0.267-0.152-0.305-0.297-0.412
               c-0.145-0.113-0.242-0.418-0.234-0.578c0.015-0.152-0.245-0.365-0.406-0.472c-0.157-0.098-0.379-0.053-0.372-0.312
               c0.017-0.259-0.243-0.663-0.447-0.959c-0.198-0.29-0.519-0.64-0.618-0.838c-0.099-0.205-0.067-0.388-0.281-0.495
               c-0.213-0.114-0.891-0.647-0.989-0.624s-0.031,0.213-0.067,0.464c-0.029,0.244,0.1,0.441,0.233,0.701
               c0.139,0.258-0.021,0.358,0.229,0.578c0.243,0.228,0.426,0.419,0.602,0.671c0.183,0.243,0.412,0.578,0.419,0.738
               c0.017,0.16-0.007,0.403-0.091,0.526c-0.076,0.129-0.076,0.372-0.055,0.517c0.021,0.153-0.105,0.663-0.015,0.99
               c0.084,0.32,0.099,0.358-0.069,0.647c-0.168,0.281-0.342,0.228-0.342,0.579c0,0.342,0.145,0.365-0.055,0.54
               c-0.196,0.175,0.023,0.366-0.182,0.716c-0.199,0.35-0.313,0.312-0.479,0.518c-0.168,0.205-0.358,0.35-0.687,0.289
               c-0.326-0.053-0.326-0.13-0.533-0.175c-0.197-0.046-0.617-0.403-0.715-0.206c-0.101,0.206-0.047,0.564-0.137,0.594
               c-0.037,0.016-0.131,0.061-0.223,0.122c-0.113-0.092-0.205-0.205-0.229-0.32c-0.029-0.145,0.03-0.159,0.101-0.175
               c0.062-0.007,0.121-0.022,0.159-0.083c0.099-0.146,0.029-0.26-0.1-0.465l-0.007-0.007c-0.039-0.069-0.077-0.107-0.114-0.153
               c-0.068-0.068-0.123-0.129-0.168-0.328c-0.047-0.205-0.26-0.373-0.441-0.51c-0.122-0.091-0.229-0.175-0.234-0.228
               c-0.018-0.037,0.008-0.054,0.03-0.054c0.021-0.007,0.052-0.007,0.075-0.015c0.068-0.007,0.137-0.007,0.197-0.045
               c0.076-0.046,0.092-0.084,0.106-0.114c0.007-0.031,0.022-0.061,0.106-0.107c0.121-0.068,0.289,0.023,0.471,0.122
               c0.046,0.023,0.093,0.046,0.146,0.069c0.129,0.068,0.188,0.037,0.221-0.023c0.029-0.061,0.021-0.145,0.007-0.221
               c0-0.039-0.007-0.069-0.007-0.092c0.007-0.014,0.007-0.038,0.007-0.053c0.015-0.091,0.03-0.183-0.106-0.373
               c-0.092-0.114-0.046-0.183,0.008-0.258v-0.008c0.029-0.046,0.062-0.107,0.076-0.175c0.03-0.145-0.008-0.207-0.062-0.297
               c-0.015-0.031-0.038-0.069-0.06-0.115c-0.018-0.06-0.039-0.114-0.063-0.168c-0.054-0.121-0.099-0.243-0.138-0.364
               c-0.022-0.062,0.016-0.084,0.054-0.115c0.069-0.045,0.13-0.092,0.077-0.206c-0.031-0.076-0.068-0.114-0.1-0.16
               c-0.055-0.053-0.102-0.114-0.145-0.265c-0.047-0.184-0.198-0.199-0.336-0.161c-0.084,0.023-0.168,0.069-0.221,0.091
               c-0.017,0.008-0.022,0.016-0.031,0.016c-0.061,0.03-0.168,0.022-0.287,0.022c-0.041-0.007-0.077-0.007-0.125-0.007
               c-0.104-0.008-0.182,0.099-0.266,0.205v0.009c-0.084,0.114-0.183,0.235-0.305,0.22c-0.15-0.022-0.234,0-0.313,0.023
               c-0.062,0.015-0.121,0.03-0.221,0.022c-0.16-0.007-0.252-0.038-0.39-0.083c-0.054-0.023-0.113-0.046-0.188-0.068
               c-0.154-0.046-0.146-0.077-0.138-0.145c0.007-0.046,0.015-0.099,0.007-0.168c-0.007-0.144-0.062-0.175-0.121-0.167
               c-0.015,0-0.029,0-0.045,0.007c-0.023,0.008-0.054,0.008-0.068,0c-0.039-0.015-0.055-0.061-0.084-0.129
               c-0.023-0.068-0.055-0.152-0.131-0.258c-0.121-0.191-0.35-0.161-0.494-0.145c-0.045,0.007-0.084,0.015-0.092,0.007
               c-0.008,0-0.022-0.007-0.045-0.007c-0.123-0.031-0.435-0.1-0.578-0.351c-0.138-0.236-0.229-0.221-0.375-0.205
               c-0.053,0.007-0.121,0.015-0.205,0c-0.105-0.016-0.205-0.008-0.305,0c-0.137,0.015-0.273,0.03-0.441-0.054
               c-0.158-0.076-0.289-0.167-0.389-0.236c-0.091-0.068-0.158-0.114-0.234-0.144c-0.106-0.046-0.428-0.366-0.633-0.579
               c-0.047-0.053-0.092-0.092-0.114-0.122c-0.046-0.038-0.091-0.084-0.138-0.122c-0.137-0.107-0.281-0.228-0.403-0.51
               c-0.121-0.282-0.486-0.487-0.859-0.7c-0.184-0.107-0.373-0.221-0.524-0.336c-0.243-0.174-0.396-0.266-0.525-0.312
               c-0.13-0.053-0.221-0.053-0.335-0.045c-0.106,0.007-0.403-0.039-0.7-0.107c-0.26-0.061-0.523-0.137-0.671-0.206
               c-0.243-0.121-0.381-0.068-0.539-0.007c-0.069,0.023-0.146,0.054-0.229,0.061c-0.099,0.015-0.176,0.038-0.234,0.061
               c-0.115,0.03-0.207,0.06-0.404,0.023c-0.234-0.054-0.304,0.068-0.381,0.213c-0.037,0.061-0.076,0.137-0.145,0.183
               c-0.045,0.038-0.068,0.076-0.075,0.113c-0.017,0.039,0,0.077,0.021,0.107c0.023,0.023,0.055,0.046,0.084,0.061
               c0.084,0.046,0.198,0.061,0.275,0.046c0.053-0.008,0.1,0.008,0.145,0.046c0.039,0.03,0.075,0.091,0.09,0.167
               c0.024,0.114,0.1,0.145,0.191,0.175c0.061,0.031,0.129,0.053,0.189,0.13c0.039,0.053,0.039,0.076,0.016,0.091
               c-0.016,0.008-0.031,0.023-0.046,0.031c-0.06,0.038-0.129,0.083-0.196,0.167c-0.101,0.137-0.101,0.373-0.101,0.571
               c0,0.129,0,0.244-0.021,0.29c-0.07,0.106,0.075,0.251,0.203,0.388c0.077,0.083,0.153,0.167,0.146,0.183
               c-0.008,0.03-0.102,0.076-0.206,0.129c-0.122,0.061-0.253,0.122-0.334,0.213c-0.114,0.122-0.412,0.046-0.679-0.03l-0.045-0.008
               c-0.146-0.038-0.213-0.092-0.291-0.145c-0.076-0.06-0.158-0.121-0.312-0.175c-0.168-0.053-0.313-0.007-0.45,0.031
               c-0.121,0.038-0.236,0.068-0.313,0.007c-0.084-0.068-0.205-0.122-0.334-0.152c-0.205-0.053-0.42-0.068-0.473-0.061
               c-0.054,0.008-0.191,0.099-0.32,0.221c-0.1,0.091-0.197,0.199-0.235,0.274c-0.03,0.076-0.205,0.13-0.396,0.19
               c-0.16,0.053-0.328,0.108-0.441,0.183l-0.047,0.038c-0.182,0.122-0.205,0.137-0.495,0.069c-0.233-0.053-0.447-0.039-0.669-0.023
               c-0.105,0.007-0.213,0.007-0.336,0.007c-0.158,0-0.426-0.076-0.67-0.175c-0.266-0.114-0.502-0.243-0.557-0.327
               c-0.082-0.121-0.213-0.129-0.381-0.137c-0.105-0.008-0.221-0.008-0.342-0.053c-0.152-0.046-0.197-0.084-0.244-0.122
               c-0.052-0.038-0.099-0.077-0.242-0.122c-0.105-0.038-0.273-0.068-0.457-0.092c-0.281-0.038-0.588-0.06-0.717-0.068
               c-0.113-0.007-0.183,0.03-0.273,0.084c-0.068,0.038-0.16,0.091-0.313,0.129c-0.32,0.084-0.449,0.054-0.686,0.008l-0.022-0.008
               c-0.055-0.015-0.106-0.022-0.159-0.03c-0.152-0.031-0.267-0.053-0.504-0.167c-0.281-0.145-0.313-0.184-0.402-0.305l-0.016-0.031
               c-0.021-0.03-0.046-0.06-0.068-0.099c-0.1-0.16-0.221-0.365-0.494-0.472c-0.221-0.076-0.633-0.152-0.914-0.198
               c-0.146-0.022-0.252-0.038-0.282-0.053c-0.052-0.023-0.197-0.069-0.351-0.107c-0.099-0.022-0.205-0.045-0.281-0.053
               c-0.166-0.015-0.365-0.144-0.473-0.221l-0.037-0.03c-0.062-0.038-0.106-0.061-0.146-0.061c-0.055,0.007-0.084,0.038-0.084,0.114
               c0,0.023-0.022,0.084-0.062,0.137c-0.053,0.107-0.123,0.214-0.145,0.236c-0.031,0.031-0.053,0.077-0.061,0.122
               c-0.008,0.038,0,0.076,0.029,0.114c0.008,0.008,0,0.039-0.016,0.107c-0.016,0.053-0.031,0.13-0.055,0.236
               c-0.036,0.228,0.177,0.319,0.365,0.403c0.053,0.023,0.115,0.046,0.153,0.077c0.093,0.053,0.099,0.114,0.113,0.182
               c0,0.038,0.008,0.084,0.03,0.137c0.031,0.077-0.022,0.107-0.084,0.145c-0.022,0.015-0.045,0.031-0.076,0.053
               c-0.045,0.031-0.084,0.061-0.121,0.076c-0.038,0.008-0.076,0.008-0.121-0.015c-0.055-0.015-0.107-0.023-0.176-0.03
               c-0.092-0.007-0.19-0.015-0.297-0.061c-0.129-0.061-0.205-0.053-0.313-0.038c-0.063,0-0.138,0.015-0.259,0.008
               c-0.146-0.008-0.282-0.016-0.427-0.031c-0.146-0.015-0.291-0.03-0.473-0.053c-0.244-0.03-0.229-0.138-0.222-0.229
               c0.008-0.03,0.008-0.053,0.008-0.076c0-0.145-0.427-0.168-0.745-0.183c-0.076-0.007-0.154-0.007-0.207-0.015
               c-0.068-0.008-0.119-0.008-0.166-0.008c-0.138,0-0.222,0-0.404-0.152c-0.182-0.152-0.213-0.137-0.273-0.091
               c-0.023,0.015-0.063,0.038-0.139,0.038c-0.166,0-0.211,0.053-0.258,0.106c-0.03,0.031-0.053,0.062-0.114,0.076
               c-0.13,0.031-0.373,0.222-0.55,0.359c-0.09,0.068-0.157,0.129-0.183,0.137c-0.052,0.03-0.157,0.091-0.25,0.167
               c-0.067,0.053-0.131,0.114-0.167,0.168c-0.031,0.045-0.1,0.083-0.197,0.106c-0.117,0.031-0.252,0.039-0.403,0.031
               c-0.214-0.023-0.222,0.068-0.222,0.167c-0.008,0.038-0.008,0.084-0.084,0.099c-0.235,0.046-0.283,0.031-0.465-0.008
               c-0.084-0.022-0.145-0.06-0.189-0.113c-0.045-0.054-0.068-0.122-0.083-0.199c-0.009-0.038-0.031-0.068-0.054-0.083
               c-0.031-0.023-0.069-0.031-0.106-0.023c-0.039,0-0.077,0.015-0.116,0.038c-0.074,0.038-0.15,0.114-0.174,0.175
               c-0.016,0.03-0.113,0.008-0.243-0.023c-0.084-0.015-0.176-0.03-0.269-0.046c-0.036-0.007-0.075-0.007-0.112-0.015
               c-0.151-0.015-0.183-0.015-0.252-0.122c-0.084-0.121-0.197-0.205-0.343-0.311c-0.03-0.024-0.067-0.054-0.105-0.084
               c-0.099-0.069-0.183-0.077-0.252-0.077c-0.061,0-0.113-0.007-0.168-0.106c-0.061-0.122-0.189-0.221-0.326-0.297
               c-0.138-0.076-0.281-0.137-0.381-0.168c-0.054-0.023-0.084-0.038-0.115-0.053c-0.082-0.046-0.113-0.053-0.281-0.023
               c-0.084,0.023-0.144,0.046-0.205,0.076c-0.092,0.038-0.168,0.069-0.288,0.053c-0.138-0.022-0.161-0.022-0.206-0.007
               c-0.021,0.007-0.055,0.015-0.129,0.015c-0.184,0.008-0.237-0.015-0.344-0.061l-0.008-0.007
               c-0.046-0.023-0.068-0.046-0.091-0.061c-0.047-0.045-0.084-0.077-0.19-0.091c-0.068-0.016-0.068-0.031-0.076-0.054
               s-0.022-0.061-0.055-0.099c-0.074-0.122-0.183-0.099-0.281-0.076c-0.014,0.008-0.029,0.008-0.045,0.015
               c-0.092,0.015-0.092,0.077-0.076,0.16c0,0.016,0,0.031,0.008,0.045c0.008,0.031,0.016,0.069,0.016,0.107
               c0.008,0.038-0.016,0.054-0.045,0.054c-0.039,0.007-0.084,0-0.131,0l-0.045-0.008c-0.063,0-0.16-0.061-0.259-0.144
               c-0.114-0.092-0.229-0.214-0.298-0.32c-0.068-0.114-0.152-0.221-0.252-0.328c-0.105-0.107-0.228-0.206-0.373-0.297
               c-0.045-0.03-0.074-0.053-0.1-0.068c-0.213-0.137-0.365-0.228-0.729-0.609c-0.328-0.351-0.922-0.686-1.195-0.846
               c-0.084-0.045-0.13-0.068-0.13-0.076c-0.017-0.022-0.062-0.038-0.106-0.06c-0.074-0.032-0.184-0.084-0.205-0.137
               c-0.017-0.023,0.015-0.054,0.037-0.084c0.01-0.016,0.023-0.031,0.03-0.054c0.038-0.06,0.045-0.114-0.054-0.175
               c-0.121-0.068-0.273,0.031-0.402,0.122c-0.063,0.038-0.121,0.076-0.168,0.091c-0.068,0.023-0.107,0.061-0.137,0.092
               c-0.039,0.038-0.069,0.076-0.168,0.076c-0.152,0.015-0.189,0.099-0.221,0.19c-0.017,0.045-0.031,0.092-0.068,0.115
               c-0.084,0.045-0.335,0.06-0.525,0.068c-0.076,0.008-0.137,0.008-0.176,0.015c-0.084,0.008-0.1-0.007-0.137-0.046
               c-0.021-0.015-0.047-0.045-0.084-0.068c0.008-0.015,0.031-0.03,0.053-0.046c0.084-0.053,0.184-0.121,0.123-0.22
               c-0.063-0.115-0.139-0.069-0.213-0.031c-0.023,0.015-0.055,0.031-0.063,0.031c-0.062-0.084-0.152-0.076-0.272-0.062
               c-0.038,0.008-0.077,0.016-0.13,0.016c-0.076,0.008-0.166-0.023-0.252-0.069c-0.092-0.045-0.176-0.122-0.236-0.197
               c-0.074-0.099-0.137-0.092-0.197-0.046c-0.016,0.014-0.037,0.03-0.053,0.053c-0.023,0.023-0.047,0.053-0.063,0.053
               c-0.053,0-0.099,0.008-0.144,0.015c-0.047,0.008-0.092,0.023-0.138,0.039c-0.03,0.015-0.055,0.007-0.076,0
               c-0.031-0.016-0.053-0.039-0.084-0.069c-0.01-0.007,0-0.015,0.007-0.03c0.017-0.038,0.039-0.084,0.024-0.16
               c-0.018-0.145-0.1-0.161-0.207-0.183c-0.016,0-0.037,0-0.045-0.008c-0.039-0.007-0.076-0.114-0.114-0.213
               c-0.029-0.083-0.062-0.167-0.099-0.213c-0.047-0.061-0.117-0.084-0.184-0.091c-0.084-0.008-0.184,0.023-0.236,0.046
               c-0.037,0.022-0.129,0.022-0.229,0.022c-0.092,0-0.189-0.015-0.252-0.045h-0.007c-0.138-0.061-0.253-0.107-0.373-0.107
               c-0.062,0-0.14,0.023-0.222,0.053c-0.084,0.031-0.176,0.054-0.258,0.061c-0.084,0.008-0.123,0.008-0.154,0.03
               c-0.021,0.023-0.029,0.039-0.045,0.077c0,0.015-0.008,0.037-0.022,0.068c-0.03,0.084-0.104,0.099-0.196,0.122
               c-0.031,0.008-0.063,0.015-0.092,0.031c-0.054,0.015-0.077,0.022-0.107,0.038c-0.045,0.015-0.074,0.03-0.242,0.045
               c-0.244,0.023-0.489,0.106-0.525,0.122c-0.023,0-0.275,0.038-0.381,0.031c-0.092-0.008-0.13,0.053-0.176,0.129
               c-0.022,0.046-0.045,0.098-0.092,0.13c-0.037,0.03-0.045,0.022-0.068,0.015c-0.045-0.023-0.122-0.054-0.305-0.054
               c-0.184-0.007-0.273,0.031-0.351,0.069c-0.067,0.03-0.13,0.061-0.251,0.038c-0.167-0.016-0.402,0.068-0.604,0.137
               c-0.121,0.045-0.219,0.076-0.258,0.076c-0.047-0.007-0.092,0-0.146,0.008c-0.075,0.015-0.168,0.03-0.312,0
               c-0.047-0.015-0.084-0.023-0.106-0.031c-0.038-0.008-0.062-0.015-0.076-0.015c-0.022,0-0.03,0.007-0.046,0.015
               c-0.016,0-0.029,0.008-0.068,0.016c-0.123,0.03-0.129,0.09-0.138,0.182c0,0.046,0,0.099-0.021,0.153
               c-0.047,0.098-0.039,0.144,0.015,0.167c0.021,0.008,0.053,0.008,0.084,0c0.037,0,0.084-0.008,0.136,0
               c0.039,0.008,0.055,0.008,0.055,0.008c-0.008,0.007-0.016,0.022-0.022,0.037c-0.017,0.016-0.031,0.039-0.038,0.069
               c-0.016,0.061,0.016,0.092,0.068,0.114c0.054,0.016,0.146,0.016,0.244,0.016h0.066c0.055,0,0.069,0.015,0.063,0.037
               c0,0.046-0.039,0.107-0.069,0.16c-0.015,0.024-0.036,0.008-0.067-0.015c-0.045-0.023-0.091-0.052-0.16-0.061
               c-0.121-0.015-0.145,0.061-0.174,0.153c-0.009,0.038-0.023,0.076-0.047,0.106c-0.068,0.099-0.021,0.137,0.053,0.206
               c0.047,0.038,0.107,0.099,0.162,0.198c0.029,0.061,0.006,0.099-0.039,0.121c-0.045,0.023-0.106,0.039-0.168,0.054
               c-0.068,0.015-0.138,0.03-0.189,0.061c-0.038,0.023-0.068,0.046-0.076,0.069c-0.016,0.03-0.016,0.061-0.008,0.084
               c0.016,0.022,0.031,0.037,0.053,0.052c0.047,0.031,0.123,0.054,0.176,0.061c0.03,0,0.038,0.016,0.047,0.031
               c0.008,0.031,0.015,0.061,0.045,0.084c0.045,0.053,0.106,0.053,0.176,0.053c0.037,0,0.076,0,0.113,0.008
               c0.039,0.007,0.062,0.03,0.084,0.045c0.053,0.046,0.105,0.091,0.252,0.107c0.1,0.016,0.15,0.038,0.189,0.068
               c0.023,0.039,0.038,0.084,0.029,0.16c-0.006,0.106,0.016,0.152,0.039,0.19c0.016,0.023,0.029,0.046,0.021,0.092
               c-0.007,0.053-0.15,0.084-0.266,0.106c-0.047,0.007-0.084,0.016-0.115,0.023c-0.037,0.015-0.091,0.008-0.145-0.016
               c-0.061-0.029-0.129-0.083-0.205-0.151c-0.092-0.092-0.16-0.076-0.213-0.023c-0.039,0.046-0.063,0.114-0.078,0.167
               c-0.006,0.023-0.006,0.038-0.014,0.054c-0.009,0.023-0.039,0.03-0.092,0.037c-0.061,0.009-0.146,0-0.252,0
               c-0.167-0.014-0.281-0.113-0.441-0.258l-0.037-0.038c-0.146-0.138-0.152-0.129-0.197-0.084
               c-0.008,0.007-0.023,0.016-0.077,0.016c-0.104-0.009-0.16-0.038-0.205-0.061c-0.037-0.016-0.067-0.031-0.101-0.031
               c-0.061,0-0.121,0.061-0.196,0.122c-0.054,0.045-0.115,0.099-0.167,0.129c-0.016,0.008-0.016,0.023-0.016,0.016
               c0-0.008-0.008-0.016-0.017-0.031c-0.016-0.053-0.046-0.13-0.151-0.191c-0.139-0.068-0.229-0.03-0.328,0.009
               c-0.053,0.022-0.1,0.045-0.15,0.045c-0.168,0.015-0.214,0.137-0.26,0.244c-0.008,0.015-0.016,0.029-0.016,0.045
               c-0.016,0.039-0.047,0.068-0.092,0.084c-0.047,0.015-0.113,0.023-0.197,0.015c-0.068-0.008-0.152-0.045-0.244-0.099
               c-0.129-0.076-0.252-0.167-0.273-0.198c-0.023-0.038-0.077-0.068-0.113-0.076c-0.016-0.008-0.039-0.008-0.054,0
               c-0.024,0-0.039,0.015-0.054,0.038c-0.008,0.023-0.016,0.053,0,0.092c0.008,0.03,0.008,0.068-0.008,0.091
               c-0.008,0.015-0.008,0.023-0.016,0.03c-0.008,0.007-0.016,0.007-0.023,0.007c-0.008,0.009-0.022,0.009-0.029,0
               c-0.029-0.014-0.055-0.068-0.077-0.121c-0.021-0.068-0.052-0.145-0.112-0.19c-0.062-0.039-0.121-0.053-0.19-0.076
               c-0.062-0.023-0.13-0.046-0.198-0.084c-0.068-0.053-0.1-0.091-0.128-0.129c-0.024-0.039-0.047-0.069-0.084-0.1
               c-0.063-0.053-0.245-0.045-0.429-0.045c-0.129,0.008-0.258,0.008-0.312-0.008c-0.069-0.022-0.106-0.06-0.139-0.099
               c-0.029-0.03-0.06-0.068-0.104-0.091c-0.084-0.046-0.145,0.008-0.229,0.076c-0.047,0.031-0.1,0.076-0.168,0.107
               c-0.077,0.046-0.153,0.046-0.221,0.03c-0.076-0.015-0.146-0.053-0.19-0.076c-0.038-0.023-0.076-0.046-0.106-0.069
               c-0.061-0.053-0.115-0.091-0.19-0.091c-0.082,0.008-0.114,0.061-0.175,0.16c-0.03,0.046-0.062,0.099-0.114,0.167
               c-0.06,0.084-0.129,0.138-0.198,0.168c-0.066,0.031-0.145,0.054-0.221,0.062c-0.145,0.03-0.145,0.083-0.152,0.167
               c0,0.023,0,0.046-0.007,0.069c-0.008,0.023-0.045,0.038-0.077,0.053c-0.066,0.023-0.144,0.053-0.189,0.152
               c-0.037,0.084-0.016,0.221,0.01,0.343c0.016,0.107,0.029,0.206,0.007,0.237c-0.039,0.03-0.069,0.045-0.106,0.053
               c-0.047,0-0.106-0.008-0.199-0.039c-0.166-0.053-0.197-0.106-0.272-0.266h-0.009c-0.007-0.023-0.021-0.038-0.036-0.053
               c-0.063-0.069-0.161-0.107-0.252-0.115c-0.099-0.007-0.19,0.016-0.235,0.038c-0.016,0.008-0.022,0.016-0.039,0.023
               c-0.053,0.054-0.03,0.13,0,0.221c0.023,0.068,0.047,0.138,0.023,0.168c-0.039,0.061-0.068,0.114-0.091,0.205
               c-0.024,0.084-0.039,0.191-0.039,0.336c0,0.205,0.069,0.22,0.138,0.235c0.03,0.008,0.053,0.008,0.061,0.054
               c0.029,0.099-0.092,0.236-0.168,0.32c-0.038,0.038-0.067,0.068-0.067,0.091c-0.007,0.038-0.015,0.099,0,0.159
               c0.009,0.046,0.029,0.092,0.067,0.123c0.046,0.045,0.107,0.053,0.16,0.061c0.047,0.007,0.092,0.014,0.121,0.045
               c0.039,0.031,0.076,0.084,0.108,0.13c0.015,0.037,0.028,0.075,0.028,0.114c-0.008,0.069,0.008,0.114,0.047,0.152
               c0.029,0.03,0.083,0.053,0.145,0.061c0.062,0.015,0.106,0.007,0.146-0.008c0.022-0.008,0.053-0.015,0.084,0
               c0.046,0.023,0.082,0.015,0.137,0.008c0.054-0.008,0.122-0.023,0.274,0.015c0.197,0.046,0.313,0.274,0.38,0.412
               c0.022,0.03,0.038,0.06,0.046,0.083c0.046,0.069,0.084,0.092,0.146,0.137c0.028,0.023,0.076,0.045,0.129,0.099
               c0.022,0.015,0.047,0.015,0.037,0.015c-0.006,0.015-0.091,0.023-0.158,0.023c-0.023,0-0.039,0.008-0.055,0.008
               c-0.037,0-0.061,0.015-0.074,0.03c-0.033,0.023-0.039,0.046-0.033,0.084c0.009,0.023,0.016,0.046,0.039,0.069
               c0.054,0.061,0.16,0.136,0.32,0.205c0.113,0.053,0.159,0.068,0.184,0.092c-0.077,0.159-0.184,0.114-0.236,0.198
               c-0.061,0.083,0.075,0.152-0.091,0.19c-0.168,0.03-0.2,0.06-0.358,0.129c-0.152,0.061-0.32-0.03-0.356,0.031
               c-0.039,0.06,0.036,0.053-0.023,0.235c-0.062,0.183-0.13,0.411-0.229,0.527c-0.098,0.113-0.274,0.136-0.221,0.311
               c0.053,0.175,0.205,0.122,0.289,0.183s0.16,0.137,0.282,0.259c0.114,0.114,0.22,0.091,0.213,0.251
               c-0.017,0.16,0.022,0.297,0.046,0.419c0.021,0.129-0.115,0.388,0.029,0.449c0.146,0.053,0.32,0.091,0.396,0.289
               c0.082,0.198,0.092,0.244,0.319,0.382c0.13,0.068,0.206,0.144,0.259,0.212c-0.037,0.031-0.061,0.1-0.09,0.16
               c-0.019,0.046-0.031,0.091-0.047,0.107c-0.016,0.015-0.029,0.03-0.056,0.053c-0.052,0.054-0.12,0.137-0.212,0.206
               c-0.068,0.053-0.1,0.046-0.16,0.03c-0.021-0.007-0.053-0.015-0.082-0.023c-0.055-0.015-0.068-0.038-0.092-0.068
               c-0.024-0.038-0.047-0.076-0.108-0.114c-0.073-0.054-0.137-0.107-0.221-0.183c-0.044-0.039-0.099-0.084-0.159-0.137
               c-0.184-0.16-0.579-0.229-0.616-0.236c-0.022-0.007-0.159-0.061-0.274-0.16c-0.045-0.038-0.037-0.069-0.031-0.092
               c0.025-0.053,0.04-0.098-0.006-0.175c-0.053-0.091-0.145-0.084-0.26-0.076c-0.068,0.008-0.152,0.015-0.222-0.008
               c-0.091-0.03-0.166-0.053-0.241-0.076c-0.076-0.015-0.146-0.022-0.223-0.008c-0.066,0.008-0.113,0.031-0.159,0.062
               c-0.069,0.03-0.13,0.068-0.237,0.045c-0.074-0.015-0.074-0.038-0.074-0.06c-0.008-0.054-0.016-0.1-0.129-0.122
               c-0.077-0.016-0.129-0.031-0.207-0.062c-0.076-0.037-0.168-0.09-0.313-0.182c-0.221-0.129-0.236-0.129-0.326-0.137
               c-0.029,0-0.078-0.008-0.152-0.016c-0.092-0.015-0.19-0.015-0.297-0.015c-0.176,0-0.365,0-0.549-0.091
               c-0.166-0.099-0.235-0.099-0.297-0.099c-0.037,0-0.076,0-0.152-0.038c-0.168-0.084-0.266-0.084-0.41-0.076h-0.16
               c-0.1,0-0.182,0.038-0.244,0.099c-0.035-0.023-0.068-0.054-0.1-0.091c-0.098-0.145-0.434-0.419-0.615-0.526
               c-0.184-0.114-0.434-0.228-0.57-0.282c-0.138-0.06-0.16-0.229-0.343-0.229c-0.176,0-0.267,0.046-0.344-0.046
               c-0.084-0.09-0.343-0.304-0.427-0.342c-0.092-0.03-0.213-0.069-0.176-0.092c0.047-0.022,0.199-0.022,0.199-0.083
               c0-0.054,0.037-0.1,0.121-0.069c0.092,0.039,0.273,0.046,0.38,0.053c0.106,0.008,0.077-0.084,0.038-0.167
               c-0.029-0.084,0.039-0.061,0.1-0.206c0.068-0.152,0.055-0.282,0.152-0.282c0.092,0,0.045-0.128,0.152-0.075
               c0.1,0.053,0.152-0.031,0.061-0.1c-0.099-0.061-0.273-0.106-0.318-0.114c-0.047-0.015-0.244-0.22-0.198-0.251
               c0.046-0.023,0.274,0.076,0.35-0.023c0.084-0.107,0.16-0.137,0.236-0.152c0.069-0.015,0.259-0.122,0.342-0.183
               c0.084-0.061,0.199-0.206,0.063-0.206c-0.131,0-0.215,0.092-0.299,0.092c-0.082,0-0.299,0.059-0.291,0.036
               c0.008-0.022,0-0.269,0.029-0.307c0.03-0.046,0.053-0.03,0.15-0.067c0.092-0.038,0.045-0.179,0.181-0.164
               c0.138,0.015,0.351,0.033,0.442,0.025s0.236-0.118,0.236-0.118s0.058-0.244,0.034-0.373c-0.016-0.137-0.089-0.235-0.136-0.311
               c-0.044-0.076,0.029-0.175,0.038-0.266c0-0.084-0.048-0.175,0.014-0.221c0.062-0.038,0.078-0.366,0.056-0.419
               c-0.022-0.046-0.185-0.232-0.26-0.232c-0.078,0-0.214,0.007-0.273,0c-0.053-0.015-0.262-0.124-0.32-0.132
               c-0.055-0.015-0.213-0.059-0.343-0.089c-0.122-0.031-0.265-0.107-0.341-0.115c-0.076-0.014-0.243,0.039-0.317,0
               c-0.076-0.037-0.247-0.305-0.331-0.327c-0.076-0.023-0.266,0.005-0.35,0.043s-0.219,0.041-0.318,0.034
               c-0.1-0.015-0.238-0.11-0.309-0.118c-0.068-0.008-0.242,0.078-0.305,0.025c-0.055-0.045-0.078-0.199-0.104-0.237
               c-0.021-0.038,0.063-0.137-0.044-0.229c-0.099-0.092-0.154-0.115-0.185-0.145c-0.021-0.023-0.086-0.077-0.201-0.099
               c-0.112-0.024-0.207-0.066-0.291-0.058c-0.084,0.008-0.153-0.072-0.185-0.117c-0.022-0.046-0.026-0.105-0.005-0.135
               c0.023-0.022,0.07-0.079,0.012-0.147c-0.056-0.07-0.146-0.107-0.207-0.214c-0.062-0.107-0.109-0.234-0.156-0.28
               c-0.045-0.045-0.155-0.078-0.248-0.093c-0.09-0.016-0.196-0.046-0.328-0.038c-0.129,0.015-0.164,0.027-0.188,0.05
               c-0.023,0.023-0.109,0.014-0.141,0.006c-0.029-0.008-0.164-0.022-0.256-0.014c-0.082,0.015-0.145,0.059-0.166,0.089
               c-0.022,0.023-0.034,0.098-0.135,0.075c-0.063-0.015-0.084-0.156-0.115-0.263c-0.038-0.107-0.164-0.202-0.203-0.248
               c-0.029-0.046-0.112-0.137-0.092-0.16c0.023-0.015,0.111,0.07,0.227,0.108c0.121,0.039,0.292-0.154,0.354-0.199
               c0.063-0.038,0.106-0.038,0.176-0.053c0.061-0.008,0.158-0.077,0.128-0.175c-0.021-0.099-0.044-0.236-0.138-0.267
               c-0.09-0.031-0.078-0.076-0.101-0.099c-0.017-0.023-0.101-0.113-0.138-0.113c-0.041-0.007-0.047-0.08-0.152-0.084
               c-0.051-0.003-0.143-0.069-0.189-0.13c-0.051-0.069-0.148-0.106-0.217-0.151c-0.068-0.039-0.177,0.021-0.199-0.085
               c-0.029-0.107-0.075-0.145-0.113-0.205c-0.038-0.062-0.221-0.168-0.221-0.252s0.074-0.198,0.029-0.259
               c-0.045-0.061-0.084-0.136-0.084-0.174c0-0.031-0.046-0.207-0.099-0.245c-0.054-0.03-0.073-0.153-0.133-0.161
               c-0.034-0.004-0.118-0.085-0.164-0.101c-0.038-0.015-0.306-0.005-0.427,0.049c-0.113,0.053-0.172,0.09-0.238,0.039
               c-0.057-0.042,0.046-0.137-0.053-0.167c-0.1-0.031-0.25-0.056-0.311-0.026c-0.068,0.031-0.119,0.065-0.145,0.042
               c-0.021-0.023-0.078-0.109-0.146-0.124c-0.067-0.008-0.106,0.018-0.184-0.035c-0.075-0.045-0.087-0.093-0.095-0.139
               c-0.015-0.053-0.125-0.276-0.188-0.329c-0.061-0.061-0.129-0.121-0.113-0.198c0.01-0.084,0.084-0.205,0.022-0.258
               c-0.054-0.062-0.259-0.122-0.251-0.214c0.016-0.091,0.1-0.197,0.093-0.281c-0.017-0.084-0.206-0.183-0.206-0.328
               c0.008-0.137-0.031-0.289-0.031-0.335c0-0.045-0.008-0.213,0.076-0.274c0.077-0.053,0.131-0.213,0.146-0.282
               c0.008-0.068-0.055-0.159-0.055-0.197c0-0.039-0.205-0.183-0.053-0.229c0.15-0.045,0.114,0.084,0.235,0.091
               c0.129,0.008,0.235-0.167,0.267-0.175c0.037-0.007,0.1,0,0.189-0.03c0.093-0.023,0.146-0.122,0.222-0.084
               c0.076,0.046,0.435-0.023,0.272-0.084c-0.168-0.053-0.364-0.068-0.51-0.068c-0.138,0.008-0.158,0.015-0.229-0.099
               c-0.067-0.114-0.113-0.175-0.182-0.221c-0.07-0.053-0.094-0.023-0.176,0.015c-0.085,0.031-0.191,0.024-0.168,0
               c0.021-0.031,0.315-0.212,0.461-0.266c0.145-0.053,0.403-0.232,0.504-0.315c0.092-0.084,0.299-0.378,0.414-0.531
               c0.112-0.152,0.434-0.281,0.494-0.365c0.069-0.076,0.29-0.417,0.221-0.554c-0.06-0.145-0.41-0.275-0.494-0.322
               c-0.084-0.045-0.473-0.193-0.451-0.277c0.029-0.076,0.222-0.229,0.168-0.35c-0.055-0.114-0.045-0.256-0.151-0.293
               c-0.104-0.039-0.315-0.015-0.302-0.129c0.018-0.114,0.006-0.203-0.057-0.241c-0.061-0.046-0.274-0.156-0.213-0.285
               c0.068-0.13,0.387-0.379,0.242-0.47c-0.152-0.084-0.059-0.271-0.136-0.347c-0.075-0.084-0.448-0.382-0.485-0.442
               c-0.039-0.069-0.213-0.119-0.15-0.157c0.053-0.039,0.279-0.313,0.248-0.435c-0.022-0.114-0.116-0.306-0.17-0.36
               c-0.084-0.096-0.147-0.08-0.219-0.169c-0.039-0.045-0.297-0.027-0.365-0.05c-0.077-0.022-0.08-0.194-0.026-0.239
               c0.046-0.046,0.071-0.156,0.118-0.202c0.037-0.045,0.143-0.016,0.173-0.061c0.03-0.046,0.079-0.182,0.162-0.174
               c0.083,0.016,0.137-0.024,0.221-0.054c0.084-0.038,0.177-0.152,0.242-0.145c0.077,0.008,0.229,0.008,0.252-0.076
               c0.03-0.077,0.035-0.134,0.035-0.134L134.135,10.063z M225.549,26.427c0,0-0.021,0.053,0.009,0.144
               c0.03,0.092-0.029,0.145-0.084,0.19c-0.054,0.046-0.138,0.077-0.054,0.176c0.076,0.091,0.045,0.19,0.175,0.16
               c0.122-0.038,0-0.099,0.137-0.153c0.139-0.046,0.238-0.106,0.153-0.235c-0.084-0.123-0.206-0.389-0.282-0.389
               C225.526,26.329,225.549,26.427,225.549,26.427 M222.61,32.077c-0.068,0.053-0.146,0.107-0.129,0.267
               c0.021,0.167-0.063,0.236-0.084,0.313c-0.018,0.077,0.066,0.267,0.138,0.16c0.067-0.106,0.038-0.297,0.151-0.358
               c0.121-0.061,0.357-0.32,0.221-0.373C222.763,32.023,222.61,32.077,222.61,32.077 M223.41,31.27
               c-0.115,0.031-0.115,0.183-0.115,0.252c0,0.068-0.137,0.159-0.151,0.227c-0.016,0.069-0.061,0.191-0.023,0.252
               c0.031,0.061-0.189,0.152-0.028,0.16c0.167,0.008,0.138,0.092,0.281-0.137c0.146-0.236,0.038-0.343,0.204-0.389
               c0.177-0.038,0.093-0.213,0.252-0.228c0.16-0.008,0.357-0.244,0.13-0.229c-0.229,0.008-0.342,0.244-0.396,0.198
               c-0.045-0.045,0-0.205-0.076-0.213C223.41,31.148,223.41,31.27,223.41,31.27 M224.567,30.562
               c-0.032,0.03-0.084,0.076-0.139,0.183c-0.053,0.099-0.145-0.015-0.113,0.152c0.039,0.168-0.037,0.281,0.067,0.206
               c0.106-0.077,0.138-0.145,0.214-0.229s0.236-0.312,0.168-0.35C224.703,30.485,224.567,30.562,224.567,30.562 M225.481,29.152
               c0.015,0.008,0.197,0.092,0.166,0.008c-0.037-0.092-0.242-0.198-0.229-0.107C225.429,29.152,225.481,29.152,225.481,29.152
                M225.358,29.472c-0.022,0.084,0.008,0.176,0.076,0.13c0.068-0.054,0.168-0.214,0.068-0.229
               C225.412,29.351,225.358,29.472,225.358,29.472 M225.481,28.08c0,0.099,0.136,0.358,0.158,0.182
               c0.022-0.175-0.113-0.342-0.158-0.32C225.442,27.965,225.481,28.08,225.481,28.08 M225.558,27.44
               c-0.021,0.03-0.146,0.114-0.039,0.212c0.107,0.107,0.146,0.176,0.198,0.145c0.062-0.031,0.114-0.107,0.062-0.213
               C225.717,27.478,225.558,27.44,225.558,27.44 M192.069,5.558c0,0-0.439-0.198-0.563-0.061c-0.129,0.137-0.318,0.198-0.197,0.335
               c0.113,0.13,0.047,0.084,0.344,0.236c0.289,0.16,0.258,0.282,0.615,0.319c0.357,0.031,0.512,0.077,0.785,0.146
               c0.281,0.068,0.602,0.205,0.586,0.037c-0.021-0.167-0.055-0.327,0.068-0.244c0.122,0.077,0.038,0.176,0.389,0.168
               c0.356-0.015,0.67-0.228,0.867-0.137c0.198,0.092,0.364,0.167,0.563,0.114c0.199-0.053,0.457-0.228,0.474-0.281
               c0.021-0.054,0.021-0.129-0.198-0.251c-0.221-0.115-0.761-0.138-0.929-0.168c-0.168-0.038-0.938-0.114-1.241-0.183
               c-0.313-0.068-0.457-0.281-0.464-0.122c-0.017,0.168,0.317,0.321,0.144,0.358c-0.166,0.038-0.205-0.053-0.426-0.129
               c-0.229-0.076-0.548-0.122-0.587-0.114C192.261,5.587,192.069,5.558,192.069,5.558 M194.69,6.843
               c-0.045,0.023-0.297,0.077-0.107,0.13c0.184,0.046,0.152,0.076,0.344,0.099c0.189,0.022,0.389-0.076,0.305-0.198
               C195.146,6.759,194.69,6.843,194.69,6.843 M196.753,5.877c-0.205,0.075-0.557,0.19-0.084,0.289s0.814,0.19,1.166,0.236
               c0.342,0.046,0.251,0.061,0.686,0.099c0.441,0.038,0.494,0.076,0.647,0.022c0.158-0.045,0.494-0.114,0.304-0.22
               c-0.189-0.099-0.289-0.168-0.633-0.13c-0.334,0.031-0.41,0.115-0.602,0.023c-0.189-0.083-0.189-0.198-0.402-0.19
               c-0.223,0.015-0.473,0.03-0.609,0.03c-0.145,0-0.221,0.031-0.267-0.053C196.914,5.899,196.753,5.877,196.753,5.877
                M195.894,7.142c-0.084,0-0.373-0.031-0.402,0.046c-0.04,0.083-0.063,0.174-0.1,0.205c-0.039,0.03-0.268,0.183-0.039,0.152
               c0.222-0.031,0.205-0.206,0.52-0.092c0.304,0.123,0.761,0.145,0.882,0.145c0.114,0,0.8,0.099,0.474-0.106
               c-0.319-0.213-0.533-0.289-0.763-0.304C196.235,7.178,195.894,7.142,195.894,7.142 M190.351,5.709
               c0.121,0.129,0.145,0.229,0.342,0.312c0.191,0.084,0.244,0.138,0.215,0.023c-0.022-0.122-0.131-0.167-0.223-0.229
               c-0.092-0.061-0.318-0.266-0.318-0.266L190.351,5.709z M144.582,10.438c0,0-0.669,0.015-0.319,0.328
               c0.344,0.312,0.434,0.396,0.687,0.312c0.243-0.076,0.875-0.45,0.571-0.548c-0.307-0.099-0.474-0.092-0.549-0.115
               C144.888,10.393,144.582,10.438,144.582,10.438 M152.349,4.827c-0.35,0.091-0.563,0.182-0.646,0.236
               c-0.092,0.061-0.151,0.084-0.473,0.16c-0.326,0.076-0.351,0.137-0.869,0.137c-0.516,0-0.797-0.084-1.026,0.007
               c-0.22,0.092-0.053,0.206-0.457,0.214c-0.403,0.015-0.472-0.023-0.638,0.06c-0.168,0.076-0.346,0.122-0.543,0.221
               c-0.204,0.1-0.381,0.091-0.418,0.168c-0.03,0.084-0.053,0.189-0.121,0.167c-0.069-0.023-0.374-0.167-0.313-0.007
               c0.053,0.16,0.356,0.091,0.151,0.244c-0.199,0.159-0.214,0.281-0.356,0.441c-0.146,0.16-0.215,0.167-0.289,0.229
               c-0.084,0.052-0.177,0.175,0,0.212c0.166,0.031,0.271,0.176,0.084,0.214c-0.191,0.031-0.48-0.062-0.48,0.091
               c0,0.145-0.038,0.259-0.122,0.289C145.74,7.941,146,8.124,146,8.124s0.03,0.091-0.084,0.182
               c-0.106,0.084-0.403,0.061-0.435,0.19c-0.039,0.123-0.19,0.313,0.099,0.358c0.29,0.046,0.442-0.144,0.511,0
               c0.061,0.145,0.061,0.145,0.26,0.252c0.19,0.099,0.41,0.335,0.342,0.122s-0.426-0.381-0.236-0.374
               c0.191,0.008,0.449,0.123,0.441,0.236c-0.016,0.115,0.008,0.267,0.146,0.29c0.137,0.023,1.043,0.129,1.264,0.137
               c0.215,0.015,0.244-0.076,0.512-0.008c0.272,0.069,0.632,0.237,0.563,0c-0.068-0.235-0.473-0.251-0.717-0.365
               c-0.253-0.107-0.725-0.358-0.791-0.434c-0.063-0.084-0.214-0.304-0.189-0.45c0.021-0.144-0.199-0.273,0.021-0.418
               c0.229-0.145,0.365-0.122,0.396-0.221c0.03-0.107,0.09-0.091,0.222-0.327c0.136-0.236,0.63-0.297,0.653-0.518
               c0.023-0.229,0.068-0.282,0.229-0.327c0.152-0.046-0.021,0.045,0.288-0.123c0.313-0.166,0.185-0.212,0.595-0.342
               c0.42-0.122,0.32-0.168,0.633-0.168s1.104-0.175,1.416-0.235c0.319-0.054,1.149-0.199,1.225-0.373
               c0.084-0.168-0.455-0.343-0.584-0.389C152.653,4.773,152.349,4.827,152.349,4.827 M169.457,3.312
               c-0.19-0.008-0.502-0.061-0.541,0.03c-0.037,0.092,0.032,0.236-0.045,0.289c-0.076,0.054-0.031,0.175-0.022,0.229
               c0.008,0.061-0.054,0.16-0.062,0.228c-0.007,0.068,0.022,0.198,0.137,0.198c0.123,0,0.472-0.236,0.624-0.244
               c0.161-0.007,0.626,0.008,0.776,0.008c0.146,0,0.389,0.054,0.687,0c0.305-0.046,0.588-0.151,0.51-0.235
               c-0.076-0.092-0.396-0.13-0.625-0.184c-0.228-0.053-0.234-0.152-0.592-0.182c-0.367-0.039-0.396,0.236-0.48,0.121
               c-0.084-0.114-0.037-0.296-0.121-0.312C169.617,3.25,169.457,3.312,169.457,3.312 M164.104,2.13
               c-0.153,0.016-0.289,0.061-0.465,0.092c-0.176,0.023-0.441-0.008-0.389,0.099c0.061,0.106,0.26,0.068,0.13,0.129
               c-0.13,0.054-0.327,0.153-0.335,0.198c-0.008,0.039-0.046,0.061,0.053,0.107c0.092,0.045-0.183,0.022-0.106,0.122
               c0.084,0.091,0.223,0.106,0.344,0.136c0.113,0.024,0.306,0.047,0.662,0.047c0.351,0,0.221,0.007,0.435-0.092
               c0.213-0.107,0.297-0.16,0.364-0.13c0.069,0.023-0.06,0.138-0.144,0.183c-0.077,0.046-0.504,0.107-0.207,0.168
               c0.298,0.061,0.375-0.099,0.693,0.099c0.328,0.198,0.19,0.114,0.625,0.236c0.434,0.114,0.937,0.045,1.248,0.076
               c0.319,0.038,0.411,0.061,0.754,0.084c0.336,0.023,0.859-0.046,0.555-0.152c-0.303-0.099-0.479-0.107-0.5-0.214
               c-0.024-0.114-0.055-0.289-0.198-0.342c-0.146-0.046-0.261-0.092-0.488-0.099c-0.228-0.008,0.076-0.077-0.297,0.007
               c-0.381,0.092-0.409,0.213-0.448,0.092c-0.046-0.115,0.008-0.161-0.175-0.176c-0.176-0.023-0.252,0.023-0.298-0.068
               c-0.046-0.084-0.298-0.206-0.189-0.236c0.099-0.039,0.334-0.054,0.184-0.107c-0.154-0.046-0.16-0.015-0.367-0.038
               c-0.205-0.03-0.921-0.228-1.043-0.259C164.379,2.069,164.104,2.13,164.104,2.13 M137.304,15.698c0,0-0.076,0.138,0.076,0.199
               c0.158,0.061,0.252,0.007,0.336,0.053c0.09,0.052,0.25,0.167,0.174,0.228c-0.068,0.054-0.061,0.092-0.152,0.054
               c-0.084-0.039-0.234-0.221-0.266-0.115c-0.039,0.107,0.021,0.176-0.115,0.236c-0.099,0.008-0.166,0.267-0.016,0.313
               c0.151,0.045,0.26,0.053,0.307,0.121c0.045,0.076,0.334,0.153,0.41,0.252c0.068,0.091,0.137,0.159-0.008,0.159
               c-0.146,0.008-0.418-0.113-0.328,0.008c0.1,0.122,0.281,0.053,0.358,0.107c0.084,0.053,0.175,0.175,0.252,0.106
               c0.067-0.068,0.091-0.167,0.183-0.175c0.092-0.008,0.266,0.038,0.176-0.167c-0.092-0.206-0.092-0.267-0.268-0.35
               c-0.175-0.077-0.373-0.222-0.373-0.381c0-0.16,0.114-0.289-0.021-0.373c-0.139-0.092-0.207-0.122-0.344-0.153
               c-0.146-0.023-0.176-0.122-0.229-0.144C137.396,15.653,137.304,15.698,137.304,15.698 M134.464,17.031
               c0.008,0.038,0.066,0.114,0.252,0.228c0.182,0.107,0.182,0.122,0.204,0.237c0.022,0.106,0.183,0.19,0.259,0.281
               c0.084,0.084,0.139,0.191,0.188,0.297c0.049,0.107,0.114,0.198,0.192,0.145c0.084-0.053,0.104-0.122,0.129-0.183
               c0.023-0.068,0.076-0.122,0.129-0.122c0.063,0,0.184,0.031,0.261,0.046c0.065,0.015,0.167,0.038,0.198-0.038
               c0.035-0.084-0.063-0.198,0.029-0.236c0.09-0.038,0.106-0.114,0.03-0.213c-0.084-0.107-0.237-0.191-0.313-0.244
               s-0.113-0.244-0.258-0.251c-0.139-0.016-0.222-0.091-0.313-0.145c-0.086-0.061-0.13-0.13-0.252-0.153
               c-0.121-0.022-0.121-0.166-0.259-0.06c-0.129,0.107-0.09,0.175-0.174,0.22c-0.093,0.047-0.154,0.031-0.192,0.054
               c-0.038,0.03-0.099,0.053-0.12,0.076C134.433,17.001,134.464,17.031,134.464,17.031 M189.765,21.919
               c0,0-0.336-0.069-0.215,0.236c0.123,0.305,0.252,0.167,0.215,0.404c-0.031,0.235-0.023,0.358,0,0.541
               c0.022,0.182-0.055,0.213-0.047,0.464c0.016,0.243,0.022,0.213-0.105,0.357c-0.137,0.145-0.084,0.305-0.221,0.373
               c-0.131,0.062-0.213,0.031-0.176,0.19c0.028,0.153,0.053,0.191-0.121,0.306c-0.183,0.106-0.236,0.365-0.344,0.472
               c-0.1,0.099-0.055,0.144-0.221,0.175c-0.168,0.038-0.633-0.099-0.616,0.091c0.007,0.19-0.017,0.19,0.242,0.213
               c0.26,0.023,0.374,0.1,0.588,0.038c0.213-0.068,0.455-0.106,0.584-0.251c0.123-0.144,0.078-0.327,0.101-0.48
               c0.022-0.159,0.03-0.159,0.19-0.129c0.159,0.038,0.146-0.107,0.252-0.236c0.099-0.122,0.188-0.053,0.364-0.152
               c0.184-0.107,0.318-0.084,0.259-0.252c-0.054-0.167,0.168-0.22,0.108-0.335c-0.069-0.114-0.397-0.023-0.345-0.182
               c0.062-0.16,0.403-0.031,0.373-0.176c-0.028-0.152-0.099-0.296-0.229-0.411c-0.121-0.107-0.197-0.243-0.189-0.365
               c0.016-0.129-0.045-0.236-0.1-0.434c-0.053-0.19,0.008-0.358-0.1-0.403C189.899,21.927,189.765,21.919,189.765,21.919"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M134.135,10.063c0,0,0.048,0.093,0.123,0.132c0.067,0.03,0.146-0.031,0.175-0.084c0.039-0.061,0.008-0.198,0.131-0.152
               c0.113,0.045,0.228,0.152,0.434,0.152c0.205,0.008,0.365-0.024,0.313,0.06c-0.061,0.092-0.418,0.008-0.435,0.1
               c-0.015,0.091,0.046,0.007,0.267,0.091c0.228,0.091,0.26,0.069,0.459,0.115c0.205,0.038,0.065-0.138,0.531,0.007
               c0.458,0.144,0.374-0.069,0.814,0.122c0.435,0.19,1.59,0.586,1.729,0.677c0.137,0.092,0.297,0.046,0.465,0.137
               c0.174,0.1,0.197,0.076,0.35,0.083c0.152,0.017,0.129,0.084,0.365,0.161c0.235,0.076,0.319,0.114,0.486,0.244
               c0.176,0.129,0.139,0.22,0.223,0.297c0.076,0.076,0.205,0.114,0.144,0.228c-0.054,0.107-0.214,0.32-0.532,0.472
               c-0.313,0.16-0.83,0.45-1.613,0.221c-0.776-0.228-0.809-0.106-1.256-0.244c-0.449-0.144-0.961-0.388-1.091-0.373
               c-0.12,0.008-0.364,0.008-0.556-0.076c-0.189-0.092-0.457-0.282-0.479-0.175c-0.016,0.099,0.114,0.083,0.303,0.251
               c0.191,0.168,0.367,0.061,0.504,0.244c0.146,0.175,0.1,0.137,0.418,0.274c0.328,0.137,0.441-0.007,0.52,0.267
               c0.084,0.266-0.152,0.38-0.021,0.532c0.129,0.16,0.234,0.153,0.228,0.312c-0.007,0.16-0.03,0.29,0.123,0.328
               c0.151,0.037,0.371-0.015,0.569,0.084c0.199,0.099,0.215,0.205,0.351,0.259c0.146,0.053,0.458,0.091,0.541,0.114
               c0.084,0.023,0.267,0.023,0.335-0.008c0.068-0.03,0.137-0.03,0.137-0.144c0-0.115-0.022-0.236-0.158-0.236
               c-0.137,0-0.229,0.061-0.35,0c-0.115-0.061-0.131-0.13-0.291-0.214c-0.152-0.084-0.343-0.183-0.268-0.235
               c0.076-0.046,0.207-0.031,0.229-0.115c0.016-0.083,0.053-0.145,0.184-0.13c0.131,0.016,0.479,0.206,0.586,0.289
               c0.113,0.084,0.105,0.039,0.373,0.077c0.273,0.038,0.373,0.069,0.496,0.107c0.121,0.038,0.508,0.076,0.601,0.053
               c0.093-0.023,0.205-0.076,0.062-0.19c-0.137-0.114-0.776-0.457-0.578-0.587c0.197-0.129,0.365-0.159,0.449-0.281
               c0.084-0.13,0.053-0.076,0.28-0.16c0.222-0.084,0.199-0.282,0.45-0.32c0.243-0.038,0.182,0.008,0.389,0.038
               c0.196,0.031,0.473-0.114,0.577,0.069c0.115,0.183,0.138,0.206,0.214,0.206c0.084,0.007,0.084-0.161,0.151-0.282
               c0.069-0.114,0.024-0.457-0.06-0.534c-0.084-0.068-0.436,0.069-0.436-0.152c0.008-0.221,0.189-0.251,0.129-0.464
               c-0.054-0.214-0.021-0.434-0.266-0.465c-0.252-0.023-0.617-0.167-0.413-0.19c0.207-0.023,0.313,0.023,0.442,0.053
               c0.121,0.031,0.26,0.008,0.436,0.008s0.434-0.084,0.631,0.061c0.198,0.137,0.268,0.092,0.389,0.243
               c0.123,0.16,0.352,0.183,0.344,0.297c-0.016,0.107-0.184,0.068-0.357,0.092c-0.167,0.015-0.275-0.024-0.412,0.045
               c-0.137,0.077-0.197,0.015-0.175,0.138c0.022,0.121,0.129,0.198,0.206,0.205c0.083,0.008,0.234-0.015,0.273,0.091
               c0.039,0.107,0.197,0.214,0.236,0.221c0.028,0.008,0.623,0.092,0.813,0.015c0.185-0.076,0.3-0.076,0.244-0.243
               c-0.061-0.175-0.129-0.343-0.037-0.373c0.098-0.039,0.182,0.038,0.312-0.046c0.129-0.084,0.069-0.152,0.374-0.206
               c0.313-0.061,0.753-0.304,0.967-0.335c0.215-0.03,0.272,0.046,0.42-0.038c0.144-0.084,0.234-0.167,0.28-0.076
               c0.045,0.084,0.093,0.16,0.177,0.137c0.09-0.023,0.121-0.159,0.036-0.213c-0.084-0.053-0.021-0.053,0.085-0.122
               c0.105-0.06,0.372-0.152,0.549-0.152c0.168-0.008,0.563-0.129,0.457,0c-0.107,0.137-0.52,0.152-0.397,0.236
               c0.114,0.092,0.222,0.114,0.131,0.183c-0.092,0.068-0.26,0.136-0.099,0.167c0.166,0.023,0.197-0.053,0.396,0
               c0.197,0.06,0.282,0.115,0.334,0.023c0.062-0.099,0.009-0.183,0.099-0.221c0.101-0.038,0.168-0.159,0.412-0.122
               c0.252,0.039,0.213-0.022,0.426,0.016c0.215,0.046,0.236,0.152,0.396,0.053c0.159-0.099,0.273-0.267,0.51-0.267
               c0.237,0,0.449-0.129,0.533-0.076c0.077,0.054,0.243,0.069,0.197,0.183c-0.045,0.107-0.243,0.182-0.098,0.259
               c0.144,0.069,0.234,0.084,0.365,0.069c0.129-0.016,0.157-0.077,0.121-0.168c-0.029-0.091,0.021-0.145,0.129-0.152
               c0.106-0.008,0.288-0.016,0.281-0.122c-0.016-0.106-0.129-0.19-0.206-0.221c-0.075-0.03-0.084-0.038-0.175-0.137
               c-0.092-0.107-0.137-0.107-0.221-0.191c-0.084-0.075-0.198-0.075-0.297-0.068c-0.1,0.015-0.062-0.015-0.258-0.076
               c-0.199-0.061-0.429-0.106-0.572-0.229c-0.146-0.114-0.328-0.273-0.105-0.236c0.221,0.046,0.25-0.053,0.365-0.022
               c0.113,0.022,0.169,0.144,0.281,0.183c0.121,0.038,0.381,0.075,0.502,0.122c0.121,0.044,0.221,0.106,0.197,0.174
               c-0.021,0.069-0.021,0.084,0.068,0.099c0.092,0.008,0.068-0.152,0.177-0.16c0.099-0.007,0.104,0.039,0.372,0.047
               c0.273,0.007,0.631,0.06,0.967,0.098c0.344,0.031,0.557,0.023,0.867,0.167c0.313,0.146,1.014,0.29,1.387,0.435
               c0.366,0.152,0.754,0.091,0.998,0.251c0.25,0.16,0.396,0.349,0.51,0.305c0.105-0.047,0.076-0.168,0.131-0.381
               c0.06-0.221-0.031-0.304-0.237-0.274c-0.196,0.037-0.351-0.03-0.471-0.168c-0.123-0.137,0.036-0.19-0.261-0.205
               c-0.289-0.008-0.351-0.099-0.524-0.099c-0.183,0-0.175,0.121-0.222-0.016c-0.053-0.137-0.053-0.251,0.016-0.281
               c0.063-0.039,0.076-0.13,0.023-0.183c-0.055-0.054,0.061-0.403-0.176-0.411c-0.229,0-0.441,0.023-0.428-0.153
               c0.016-0.167,0.129-0.212,0.26-0.273c0.129-0.062,0.176-0.054,0.297-0.153c0.114-0.107,0.084-0.602,0.1-0.761
               c0.008-0.16-0.038-0.267,0.085-0.275c0.12-0.014,0.188,0.046,0.196-0.06c0-0.114-0.168-0.328-0.037-0.343
               c0.129-0.023,0.234,0,0.365,0.015c0.138,0.008,0.25,0,0.326,0.069c0.077,0.069,0.168,0.038,0.223,0.03
               c0.052-0.007,0.281,0.053,0.166,0.107c-0.114,0.053-0.373,0.046-0.523,0.076c-0.152,0.038-0.458,0.069-0.466,0.099
               c-0.007,0.031-0.076,0.084,0.101,0.091c0.184,0.008,0.494-0.007,0.838-0.014c0.351,0,0.563,0.038,0.754,0.091
               c0.184,0.053,0.16,0.007,0.213,0.175c0.045,0.168,0.16,0.228,0.16,0.327c0,0.098-0.024,0.243-0.069,0.32
               c-0.054,0.076-0.253,0.137-0.076,0.221c0.175,0.083,0.608,0.121,0.692,0.296c0.084,0.176,0.039,0.229,0.139,0.305
               c0.091,0.068,0.045,0.152,0.045,0.244c0,0.084,0.152,0.137,0.205,0.212c0.046,0.069,0.008,0.176,0.046,0.268
               c0.046,0.091,0.069,0.144,0.16,0.327c0.099,0.183,0.008,0.175,0.213,0.282c0.206,0.106,0.815,0.167,0.785,0.267
               c-0.039,0.106-0.229,0.166-0.168,0.265c0.069,0.099,0.235,0.123,0.175,0.198c-0.068,0.069-0.084,0.221-0.152,0.29
               c-0.076,0.076-0.068,0.167-0.176,0.32c-0.1,0.16-0.342,0.243-0.593,0.289c-0.252,0.046-0.29,0.137-0.396,0.114
               c-0.101-0.022-0.336-0.077-0.496-0.091c-0.168-0.015-0.373,0.031-0.252,0.114c0.123,0.091,0.168-0.016,0.366,0.099
               c0.189,0.114,0.304,0.107,0.494,0.122c0.19,0.008,0.282,0.076,0.488,0.107c0.196,0.022,0.35-0.069,0.555-0.031
               c0.199,0.038,0.267,0.06,0.306-0.076c0.045-0.13,0.017-0.182,0.151-0.237c0.13-0.044,0.526-0.136,0.502-0.304
               c-0.021-0.175-0.104-0.282-0.015-0.365c0.092-0.092,0.244-0.32,0.113-0.45c-0.138-0.129-0.273-0.045-0.342-0.198
               c-0.062-0.159,0.022-0.205-0.084-0.258c-0.107-0.046-0.145-0.13,0.029-0.16c0.176-0.03,0.831-0.16,1.006-0.107
               c0.176,0.053,0.273,0.229,0.479,0.29c0.205,0.052,0.268,0.235,0.344,0.365c0.074,0.129,0.061,0.366,0.213,0.381
               c0.152,0.007,0.534,0.068,0.419-0.008c-0.122-0.068-0.356-0.122-0.382-0.244c-0.029-0.114,0.146-0.114,0.092-0.229
               c-0.053-0.114-0.129-0.068-0.258-0.229c-0.131-0.167-0.139-0.25-0.328-0.281c-0.184-0.03-0.326-0.053-0.434-0.122
               c-0.106-0.061-0.106-0.046-0.448-0.091c-0.336-0.046-0.366-0.046-0.526,0.007c-0.167,0.046-0.251,0.062-0.441,0.054
               c-0.189-0.008-0.305,0.129-0.41-0.008c-0.1-0.137-0.139-0.305-0.26-0.358c-0.129-0.053-0.273-0.122-0.273-0.244
               c0-0.121,0.021-0.197,0.068-0.281c0.045-0.092,0.076-0.305-0.114-0.404c-0.197-0.099-0.388-0.137-0.488-0.251
               c-0.099-0.122-0.517-0.107-0.455-0.221c0.06-0.114,0.188-0.175,0.188-0.244c0-0.067-0.037-0.151,0.068-0.151
               c0.106,0,0.641-0.077,0.533-0.274c-0.105-0.199-0.326-0.282-0.281-0.366c0.055-0.077,0.131-0.092,0.297,0.023
               c0.168,0.121,0.222,0.114,0.328,0.282c0.106,0.167-0.031,0.19,0.06,0.38c0.092,0.183,0.084,0.274,0.223,0.366
               c0.146,0.091,0.067,0.061,0.32,0.098c0.258,0.038,0.396,0.092,0.661,0.092s0.486-0.038,0.589,0.03
               c0.104,0.069,0.144,0.16,0.271,0.122c0.131-0.031,0.092-0.152-0.145-0.206c-0.229-0.053-0.273-0.121-0.494-0.121
               c-0.214,0-0.375-0.061-0.533-0.084c-0.168-0.023-0.289-0.023-0.336-0.114c-0.045-0.1-0.281-0.221-0.189-0.267
               c0.092-0.045,0.449-0.129,0.58-0.038c0.129,0.092,0.623,0.205,0.547,0.077c-0.084-0.13-0.281-0.108-0.35-0.108
               s-0.214-0.068-0.094-0.121c0.123-0.053,0.191-0.236,0.611-0.122c0.41,0.114,0.145-0.137,0.615,0.077
               c0.473,0.212,0.289,0.19,0.662,0.243c0.373,0.053,0.655,0.281,0.891,0.243c0.23-0.03,0.39-0.197,0.199-0.258
               c-0.19-0.062-0.222-0.252-0.474-0.252c-0.258,0-0.267-0.046-0.653-0.175c-0.39-0.129-0.328-0.365-0.488-0.396
               c-0.168-0.03-0.258-0.083-0.297-0.167c-0.039-0.076-0.112-0.274,0.32-0.213c0.434,0.068,0.365-0.039,0.793-0.023
               c0.426,0.015,0.949-0.038,1.18-0.069c0.221-0.038,0.41,0.016,0.563-0.008c0.158-0.023,0.371-0.023,0.388-0.091
               c0.008-0.076-0.039-0.266-0.229-0.258c-0.189,0-0.607,0.068-0.494-0.047c0.122-0.106,0.268-0.273,0.061-0.235
               c-0.198,0.045-0.28-0.115-0.221-0.153c0.068-0.037,0.328,0.115,0.404,0.023c0.075-0.084-0.122-0.129-0.018-0.19
               c0.108-0.069,0.336-0.084,0.504-0.13c0.168-0.038,0.352-0.152,0.41-0.167c0.063-0.015,0.754-0.252,1.182-0.228
               c0.426,0.022,0.531,0,0.57-0.091c0.045-0.092,0-0.222,0.25-0.146c0.244,0.077,1.067-0.076,1.295-0.015
               c0.223,0.069,0,0.236,0.382,0.183c0.381-0.053,0.44-0.282,0.8-0.213c0.357,0.068,0.875,0.258,0.607,0.053
               c-0.273-0.198-0.799-0.304-0.51-0.319c0.297-0.023,0.633,0.22,0.799,0.129c0.168-0.091,0.281-0.129,0.146-0.259
               c-0.129-0.122-0.077-0.411-0.008-0.473c0.068-0.052,0.541-0.312,0.867-0.213c0.32,0.099,0.457,0.039,0.731,0.099
               c0.267,0.069,0.647,0.016,0.548,0.107c-0.099,0.091-0.541,0.282-0.313,0.289c0.222,0.016,0.299-0.136,0.533-0.007
               c0.236,0.121,0.42-0.023,0.646,0.053c0.221,0.077,0.396-0.03,0.297,0.122c-0.105,0.145-0.105,0.237,0.023,0.237
               c0.13,0.007,0.389,0.075,0.449-0.024c0.054-0.091,0.121-0.174,0.205-0.167c0.084,0.015,0.679-0.038,1.408,0.038
               c0.73,0.069,1.021,0.281,1.264,0.32c0.244,0.03,0.488,0.06,0.771,0.175c0.281,0.106,0.601,0.098,0.616,0.221
               c0.023,0.121,0.214,0.152,0.077,0.319c-0.146,0.168-0.427,0.366-0.589,0.45c-0.157,0.076-0.295-0.008-0.471,0.129
               c-0.176,0.145-0.221,0.19-0.396,0.335c-0.176,0.144-0.205,0.16-0.335,0.228c-0.13,0.069-0.306-0.068-0.458,0.107
               c-0.158,0.175-0.166,0.236-0.28,0.244c-0.106,0.007-0.313,0.206-0.159,0.244c0.152,0.03,0.159-0.122,0.334-0.145
               c0.184-0.023,0.487-0.092,0.617-0.099c0.129-0.008,0.441,0.03,0.641-0.054c0.196-0.084,0.676-0.205,0.662-0.258
               c-0.017-0.062-0.322,0.022-0.466-0.046c-0.138-0.069-0.146-0.29,0.054-0.282c0.205,0.008,0.487-0.076,0.646,0.046
               c0.16,0.114,0.184,0.22,0.396,0.22c0.211,0,0.479,0.115,0.502,0.016c0.031-0.092,0.037-0.168-0.084-0.213
               c-0.113-0.046-0.176-0.13-0.305-0.084c-0.138,0.038-0.609-0.038-0.586-0.168c0.021-0.129,0.047-0.236,0.26-0.175
               c0.213,0.053,0.518,0.007,0.584,0.068c0.069,0.063,0,0.183,0.101,0.275c0.101,0.098,0.374,0.136,0.479,0.236
               c0.106,0.107,0.258,0.35,0.373,0.236c0.114-0.107,0.076-0.061,0.281-0.084c0.206-0.023,1.081-0.235,1.438-0.107
               c0.358,0.137,1.228,0.039,1.272,0.115c0.046,0.076-0.274,0.229,0,0.281c0.267,0.054,1.324,0.168,1.856,0.206
               c0.525,0.031,1.158,0.061,1.043-0.137c-0.113-0.205-0.776-0.472-0.486-0.472c0.298,0,0.305-0.038,0.457-0.038
               c0.145,0,0.296,0.114,0.494,0.16c0.191,0.046,0.708,0.099,1.013,0.053c0.298-0.038,0.153,0.023,0.815,0.114
               c0.662,0.092,1.097,0.137,1.173,0.351c0.075,0.213,0.325,0.243,0.474,0.349c0.143,0.1,0.547,0.176,0.28,0.244
               c-0.274,0.069-0.662,0.038-0.38,0.146c0.281,0.113,0.875,0.113,1.143,0.318c0.271,0.199,0.348,0.214,0.698,0.335
               c0.351,0.123,0.479,0.237,0.677,0.161c0.191-0.084,0.291-0.168,0.133-0.358c-0.153-0.198-0.437-0.579-0.092-0.488
               c0.35,0.092,0.325,0.213,0.532,0.252c0.196,0.03,0.167,0.038,0.631,0.061c0.457,0.023,0.753-0.076,0.976-0.1
               c0.229-0.022,0.473-0.037,0.707,0.077c0.236,0.106,0.365,0.251,0.558,0.228c0.183-0.023-0.153-0.296-0.009-0.312
               c0.139-0.008,0.222,0.046,0.373,0.069c0.145,0.022,0.486,0.213,0.496,0.061c0-0.145-0.047-0.252-0.223-0.282
               c-0.168-0.039-0.113-0.106-0.212-0.19c-0.108-0.084-0.259-0.039-0.343-0.069c-0.084-0.038-0.282-0.213-0.122-0.244
               c0.168-0.03,0.427,0.053,0.44-0.03c0.023-0.084-0.168-0.236,0-0.243c0.16-0.009,0.168,0.083,0.396,0.114
               c0.229,0.022,1.522,0,1.782,0.038c0.258,0.045,0.587,0.129,0.867,0.137c0.283,0.015,0.846,0.046,0.822,0.167
               c-0.023,0.13-0.44,0.106-0.396,0.259c0.046,0.16,0.235,0.328,0.403,0.16c0.166-0.167-0.068-0.427,0.229-0.442
               c0.289-0.008,0.876-0.03,1.271,0.061c0.389,0.092,1.098,0.077,0.853,0.191c-0.251,0.113-0.808,0.098-0.419,0.19
               c0.396,0.091,0.512-0.167,0.885,0.007c0.364,0.183,0.524,0.153,0.852,0.206c0.328,0.054,0.457,0.252,0.9,0.304
               c0.439,0.054,1.324,0.046,1.674-0.068c0.351-0.114,0.549,0.023,1.021,0.068c0.474,0.046,1.522,0.008,1.972,0.183
               c0.45,0.168,0.609,0.358,0.922,0.518c0.313,0.152,0.563,0.297,1.303,0.297c0.746,0,0.273-0.175,1.24-0.03
               c0.969,0.144,1.683,0.159,1.936,0.144c0.242-0.007,0.259-0.358,0.425-0.373c0.168-0.007,1.104-0.022,1.062,0.152
               c-0.047,0.168-0.746,0.023-0.512,0.221c0.235,0.206,0.512,0.252,0.7,0.259c0.19,0.015,0.373,0.092,0.524,0.183
               c0.16,0.091,0.588,0.312,0.693,0.076c0.1-0.236,0.061-0.35-0.281-0.449c-0.351-0.099-0.512-0.16-0.655-0.267
               c-0.146-0.114-0.617-0.35-0.183-0.297c0.441,0.061,1.734,0.213,2.474,0.252c0.748,0.03,0.776-0.16,1.387,0.083
               c0.608,0.252,1.942,0.343,2.619,0.541c0.679,0.205,0.899,0.251,1.554,0.32c0.654,0.068,0.494,0.312,1.303,0.517
               c0.813,0.198,1.865,0.222,2.299,0.541c0.427,0.312,0.715,0.45,0.967,0.579c0.245,0.137,0.688,0.129,0.58,0.022
               c-0.1-0.099-0.572-0.175-0.557-0.258c0.008-0.076,0.213-0.076,0.448-0.023c0.236,0.061,0.175,0.038,0.472,0.106
               c0.291,0.069,0.383,0.024,0.563,0.024c0.177,0,0.123,0.052,0.429,0.122c0.303,0.068,0.409,0.091,0.836,0.234
               c0.434,0.146,0.678,0.223,0.846,0.306c0.168,0.076,0.441,0.167,0.326,0.266c-0.113,0.107-0.445,0.015-0.494,0.137
               c-0.047,0.122-0.457-0.054-0.357,0.099c0.1,0.16,0.646,0.282,0.563,0.419c-0.092,0.137,0.146,0.214,0.268,0.373
               c0.121,0.152,0.068,0.343-0.137,0.221c-0.197-0.122-0.307-0.168-0.48-0.137c-0.182,0.038,0.123,0.091-0.555-0.122
               c-0.671-0.213-1.266-0.114-1.652-0.388c-0.381-0.282-0.875-0.396-1.119-0.396c-0.252,0-0.106,0.068-0.586,0
               c-0.486-0.069-0.596-0.076-0.754-0.206c-0.16-0.122-0.381-0.472-0.641-0.426c-0.26,0.045-0.541,0.145-0.229,0.327
               s0.448,0.137,0.707,0.328c0.26,0.19,0.42,0.099,0.305,0.282c-0.112,0.175-0.144,0.121-0.213,0.266
               c-0.067,0.144-0.168,0.099-0.44,0.099c-0.267,0-0.944-0.122-1.226-0.153c-0.282-0.037-0.647,0.267-0.221,0.267
               c0.424,0,0.684-0.091,0.844,0c0.152,0.091,0.595,0.229,0.801,0.251c0.197,0.023,0.334-0.083,0.48,0.092
               c0.143,0.167,1.438,0.716,1.727,0.883c0.297,0.168,0.678,0.19,0.646,0.373c-0.037,0.183,0.107,0.259-0.229,0.213
               s-1.112-0.305-1.416-0.122c-0.305,0.176-0.145,0.328-0.373,0.358c-0.221,0.038-0.206,0.198-0.465,0.29
               c-0.258,0.091-0.351,0.03-0.42,0.236c-0.074,0.198-0.127,0.144-0.174,0.342c-0.045,0.198-0.152,0.221-0.184,0.282
               c-0.029,0.069-0.212,0.365-0.113,0.434c0.092,0.076,0.319,0.312,0.129,0.282c-0.188-0.023,0.106-0.1-0.51-0.237
               c-0.617-0.144-0.633-0.364-0.914-0.342c-0.273,0.023-0.654-0.022-0.77,0.023c-0.113,0.046-0.344,0.099-0.336,0.259
               c0.008,0.16,0.017,0.335-0.098,0.274c-0.107-0.069-0.427-0.541-0.641-0.411c-0.205,0.129-0.159,0.175-0.198,0.289
               c-0.045,0.114-0.022,0.327-0.144,0.235c-0.115-0.09-0.29-0.311-0.375-0.212c-0.09,0.099-0.25-0.077-0.318,0.068
               c-0.076,0.137-0.054,0.26,0.092,0.366c0.145,0.106,0.396,0.168,0.313,0.35c-0.084,0.175-0.235,0.046-0.1,0.312
               c0.146,0.267,0.357,0.693,0.51,0.762c0.152,0.076,0.244,0.152,0.32,0.129c0.084-0.03,0.045-0.275,0.305-0.159
               c0.252,0.121,0.479,0.007,0.449,0.19c-0.039,0.19-0.16,0.319-0.039,0.335c0.114,0.015,0.237,0.395,0.426,0.457
               c0.199,0.053,0.404-0.016,0.496,0.098c0.1,0.114,0.541,0.313,0.488,0.419c-0.063,0.099-0.063,0.167-0.275,0.099
               c-0.213-0.076-0.266-0.114-0.305-0.214c-0.039-0.106-0.343-0.212-0.281-0.084c0.061,0.139,0.222,0.032,0.16,0.214
               c-0.068,0.191-0.054,0.35,0.062,0.472c0.121,0.123,0.799,0.518,0.937,0.64c0.139,0.114,0.205,0.221,0.099,0.29
               c-0.104,0.06-0.281,0.022-0.438-0.008c-0.162-0.038-0.193,0.038-0.299,0.091c-0.107,0.046-0.16,0.335,0.008,0.563
               c0.168,0.236,0.448,0.35,0.504,0.434c0.053,0.084,0.006,0.114-0.177,0.137c-0.184,0.024-0.267,0.069-0.319,0.167
               c-0.054,0.1-0.129,0.153,0.016,0.313c0.145,0.153,0.213,0.206,0.32,0.419c0.104,0.213,0.166,0.244,0.082,0.396
               c-0.082,0.152-0.152,0.106-0.152,0.259c0,0.159,0.023,0.388-0.145,0.388c-0.175,0-0.076-0.046-0.326-0.213
               c-0.243-0.175-1.213-1.119-1.547-1.378c-0.342-0.259-0.707-0.473-0.836-0.632c-0.123-0.16-0.809-0.746-0.945-0.944
               c-0.137-0.206-1.156-1.059-1.295-1.363c-0.129-0.305-0.402-0.457-0.336-0.693c0.07-0.236,0.131-0.457-0.06-0.587
               c-0.188-0.129-0.428-0.342-0.252-0.304c0.177,0.031,0.222,0.205,0.321,0.084c0.091-0.122,0.091-0.244,0.197-0.228
               c0.099,0.022,0.213,0.167,0.235-0.062c0.03-0.22,0.222-0.457,0.175-0.601c-0.045-0.137-0.221-0.168-0.139-0.342
               c0.077-0.176,0.068-0.305,0.168-0.457c0.1-0.161,0.139-0.221,0.077-0.366c-0.067-0.137-0.093-0.183-0.03-0.259
               c0.063-0.068,0.114-0.236,0.244-0.273c0.129-0.032,0.313,0.037,0.197-0.116c-0.114-0.144-0.152-0.159-0.139-0.273
               c0.016-0.122-0.068-0.191-0.104-0.175c-0.038,0.008-0.214,0.091-0.222-0.107c-0.014-0.191-0.137-0.259-0.244-0.373
               c-0.105-0.115-0.243-0.244-0.167-0.32c0.075-0.084,0.229-0.152,0.075-0.191c-0.144-0.029-0.303-0.113-0.371-0.075
               c-0.078,0.038-0.291,0.068-0.366,0.098c-0.076,0.023-0.213,0.039-0.146,0.138c0.078,0.099,0.252,0.03,0.244,0.205
               c-0.009,0.175,0.068,0.214,0.168,0.282c0.09,0.061,0.373,0.114,0.258,0.183c-0.123,0.068-0.25-0.015-0.334,0.053
               c-0.084,0.061-0.076,0.13-0.146,0.236c-0.062,0.107-0.183,0.236-0.19,0.358c-0.017,0.114,0.031,0.19-0.067,0.198
               c-0.106,0.015-0.106,0.129-0.214,0c-0.105-0.13-0.105-0.381-0.229-0.35c-0.114,0.03-0.151,0.174-0.235,0.076
               c-0.082-0.092-0.105-0.221-0.221-0.259c-0.121-0.031-0.191,0.038-0.205-0.129c-0.017-0.168-0.168-0.473-0.273-0.389
               c-0.107,0.084-0.198,0.236-0.32,0.167c-0.113-0.075-0.167-0.243-0.389-0.182c-0.229,0.061-0.215,0.091-0.389,0.091
               c-0.175,0-0.306-0.053-0.449-0.008c-0.137,0.046-0.252,0.123-0.244,0.237c0.009,0.107,0.129,0.175,0.07,0.251
               c-0.063,0.069-0.123,0.099-0.1,0.175c0.021,0.083,0.145,0.137,0.105,0.244c-0.047,0.107-0.352,0.251-0.297,0.495
               c0.061,0.243,0.099,0.13,0.19,0.305c0.099,0.174,0.149,0.183,0.288,0.205c0.137,0.022,0.313,0.022,0.51,0.121
               c0.207,0.1,0.374,0.191,0.161,0.199c-0.213,0.014-0.274-0.084-0.403-0.015c-0.123,0.068-0.213,0.083-0.373,0.045
               s-0.344-0.16-0.381-0.061c-0.031,0.099-0.105,0.206-0.215,0.206c-0.105,0-0.204-0.084-0.355-0.008
               c-0.152,0.076-0.412,0.069-0.457,0.053c-0.055-0.022-0.412-0.22-0.289-0.243c0.121-0.03,0.35-0.008,0.273-0.122
               c-0.076-0.114-0.092-0.069-0.273-0.13c-0.184-0.067-0.717-0.144-1.09-0.197c-0.365-0.061-0.717-0.206-0.775-0.061
               c-0.055,0.145,0.326,0.258,0.136,0.327c-0.188,0.069-0.372,0.023-0.426-0.023c-0.054-0.046-0.306,0.023-0.381,0.038
               c-0.084,0.008-0.091,0-0.259-0.023c-0.168-0.022-0.541-0.19-0.586-0.061c-0.047,0.138-0.076,0.213-0.244,0.168
               c-0.176-0.038-0.236-0.274-0.411-0.198c-0.166,0.076-0.884,0.091-1.226,0.091c-0.336,0-0.641,0.023-0.693,0.168
               c-0.061,0.144-0.106,0.282-0.114,0.411c-0.008,0.137-0.008,0.16-0.075,0.282c-0.068,0.129-0.023,0.319-0.017,0.396
               c0.017,0.076-0.075,0.137-0.12,0.236c-0.046,0.099-0.214,0.251-0.289,0.434c-0.068,0.182,0,0.266-0.077,0.479
               c-0.075,0.206-0.122,0.153-0.122,0.351c0.008,0.205-0.037,0.228-0.121,0.373c-0.076,0.144-0.184,0.152-0.26,0.327
               c-0.066,0.175-0.197,0.137-0.243,0.29c-0.054,0.144-0.084,0.258,0.077,0.297c0.157,0.045,0.518,0.068,0.715,0.091
               c0.198,0.03,0.396-0.138,0.479-0.015c0.084,0.122,0.046,0.258,0.222,0.35c0.177,0.091,0.062,0.137,0.206,0.258
               c0.152,0.114,0.402,0.023,0.252-0.091c-0.152-0.114-0.191-0.312-0.031-0.289c0.168,0.03,0.197,0.091,0.205,0.175
               c0.01,0.084,0.039,0.144,0.139,0.144c0.09,0,0.281-0.106,0.213,0.061c-0.076,0.161-0.283,0.343-0.115,0.351
               c0.169,0.015,0.402,0,0.457-0.13c0.063-0.137,0.13-0.327,0.168-0.198c0.037,0.13,0.145,0.412,0.184,0.274
               c0.029-0.136,0.008-0.464-0.102-0.502c-0.104-0.038-0.311-0.259-0.104-0.198s0.35,0.023,0.586,0.053
               c0.228,0.031,0.289,0.244,0.557,0.366c0.266,0.114,0.951,0.403,1.164,0.525c0.221,0.114,0.197,0.191,0.197,0.267
               c0.008,0.076,0.221,0.122,0.26,0.221c0.037,0.098,0.045,0.258,0.205,0.342s0.289,0.206,0.328,0.152
               c0.03-0.053,0.082-0.159-0.067-0.312c-0.153-0.159-0.14-0.137-0.229-0.327c-0.1-0.183-0.373-0.381-0.305-0.472
               c0.075-0.084,0.189,0.023,0.281,0.023c0.084-0.008,0.236-0.175,0.055-0.282c-0.184-0.099-0.161-0.13-0.336-0.244
               c-0.168-0.121-0.51-0.312-0.365-0.312c0.152-0.007,0.236-0.16,0.356,0.023c0.114,0.191,0.13,0.16,0.298,0.274
               c0.167,0.122,0.229,0.03,0.396,0.282c0.16,0.259,0.426,0.549,0.67,0.739c0.236,0.19,0.229,0.319,0.356,0.501
               c0.132,0.184,0.588,0.732,0.878,0.93c0.297,0.206,0.471,0.517,0.684,0.7c0.213,0.183,0.608,0.488,0.938,0.754
               c0.327,0.266,1.226,0.853,0.966,0.83c-0.258-0.023-0.518-0.381-0.791-0.403c-0.268-0.023-0.602-0.031-0.594,0.068
               c0.016,0.099,0.167,0.396,0.167,0.518s0.069,0.701,0.328,0.83c0.259,0.136,0.472,0.304,0.563,0.418
               c0.092,0.115,0.334,0.221,0.472,0.312c0.137,0.092,0.396,0.168,0.487,0.275c0.082,0.099,0.365,0.571,0.242,0.495
               c-0.121-0.084-0.287-0.442-0.502-0.442c-0.213,0-0.343,0.023-0.419-0.031c-0.075-0.061-0.188-0.167-0.213-0.068
               s0.356,0.548,0.122,0.586c-0.236,0.031-0.139-0.174-0.374-0.35c-0.235-0.183-0.243-0.236-0.319-0.51
               c-0.084-0.267-0.152-0.305-0.297-0.412c-0.145-0.113-0.242-0.418-0.234-0.578c0.015-0.152-0.245-0.365-0.406-0.472
               c-0.157-0.098-0.379-0.053-0.372-0.312c0.017-0.259-0.243-0.663-0.447-0.959c-0.198-0.29-0.519-0.64-0.618-0.838
               c-0.099-0.205-0.067-0.388-0.281-0.495c-0.213-0.114-0.891-0.647-0.989-0.624s-0.031,0.213-0.067,0.464
               c-0.029,0.244,0.1,0.441,0.233,0.701c0.139,0.258-0.021,0.358,0.229,0.578c0.243,0.228,0.426,0.419,0.602,0.671
               c0.183,0.243,0.412,0.578,0.419,0.738c0.017,0.16-0.007,0.403-0.091,0.526c-0.076,0.129-0.076,0.372-0.055,0.517
               c0.021,0.153-0.105,0.663-0.015,0.99c0.084,0.32,0.099,0.358-0.069,0.647c-0.168,0.281-0.342,0.228-0.342,0.579
               c0,0.342,0.145,0.365-0.055,0.54c-0.196,0.175,0.023,0.366-0.182,0.716c-0.199,0.35-0.313,0.312-0.479,0.518
               c-0.168,0.205-0.358,0.35-0.687,0.289c-0.326-0.053-0.326-0.13-0.533-0.175c-0.197-0.046-0.617-0.403-0.715-0.206
               c-0.101,0.206-0.047,0.564-0.137,0.594c-0.037,0.016-0.131,0.061-0.223,0.122c-0.113-0.092-0.205-0.205-0.229-0.32
               c-0.029-0.145,0.03-0.159,0.101-0.175c0.062-0.007,0.121-0.022,0.159-0.083c0.099-0.146,0.029-0.26-0.1-0.465l-0.007-0.007
               c-0.039-0.069-0.077-0.107-0.114-0.153c-0.068-0.068-0.123-0.129-0.168-0.328c-0.047-0.205-0.26-0.373-0.441-0.51
               c-0.122-0.091-0.229-0.175-0.234-0.228c-0.018-0.037,0.008-0.054,0.03-0.054c0.021-0.007,0.052-0.007,0.075-0.015
               c0.068-0.007,0.137-0.007,0.197-0.045c0.076-0.046,0.092-0.084,0.106-0.114c0.007-0.031,0.022-0.061,0.106-0.107
               c0.121-0.068,0.289,0.023,0.471,0.122c0.046,0.023,0.093,0.046,0.146,0.069c0.129,0.068,0.188,0.037,0.221-0.023
               c0.029-0.061,0.021-0.145,0.007-0.221c0-0.039-0.007-0.069-0.007-0.092c0.007-0.014,0.007-0.038,0.007-0.053
               c0.015-0.091,0.03-0.183-0.106-0.373c-0.092-0.114-0.046-0.183,0.008-0.258v-0.008c0.029-0.046,0.062-0.107,0.076-0.175
               c0.03-0.145-0.008-0.207-0.062-0.297c-0.015-0.031-0.038-0.069-0.06-0.115c-0.018-0.06-0.039-0.114-0.063-0.168
               c-0.054-0.121-0.099-0.243-0.138-0.364c-0.022-0.062,0.016-0.084,0.054-0.115c0.069-0.045,0.13-0.092,0.077-0.206
               c-0.031-0.076-0.068-0.114-0.1-0.16c-0.055-0.053-0.102-0.114-0.145-0.265c-0.047-0.184-0.198-0.199-0.336-0.161
               c-0.084,0.023-0.168,0.069-0.221,0.091c-0.017,0.008-0.022,0.016-0.031,0.016c-0.061,0.03-0.168,0.022-0.287,0.022
               c-0.041-0.007-0.077-0.007-0.125-0.007c-0.104-0.008-0.182,0.099-0.266,0.205v0.009c-0.084,0.114-0.183,0.235-0.305,0.22
               c-0.15-0.022-0.234,0-0.313,0.023c-0.062,0.015-0.121,0.03-0.221,0.022c-0.16-0.007-0.252-0.038-0.39-0.083
               c-0.054-0.023-0.113-0.046-0.188-0.068c-0.154-0.046-0.146-0.077-0.138-0.145c0.007-0.046,0.015-0.099,0.007-0.168
               c-0.007-0.144-0.062-0.175-0.121-0.167c-0.015,0-0.029,0-0.045,0.007c-0.023,0.008-0.054,0.008-0.068,0
               c-0.039-0.015-0.055-0.061-0.084-0.129c-0.023-0.068-0.055-0.152-0.131-0.258c-0.121-0.191-0.35-0.161-0.494-0.145
               c-0.045,0.007-0.084,0.015-0.092,0.007c-0.008,0-0.022-0.007-0.045-0.007c-0.123-0.031-0.435-0.1-0.578-0.351
               c-0.138-0.236-0.229-0.221-0.375-0.205c-0.053,0.007-0.121,0.015-0.205,0c-0.105-0.016-0.205-0.008-0.305,0
               c-0.137,0.015-0.273,0.03-0.441-0.054c-0.158-0.076-0.289-0.167-0.389-0.236c-0.091-0.068-0.158-0.114-0.234-0.144
               c-0.106-0.046-0.428-0.366-0.633-0.579c-0.047-0.053-0.092-0.092-0.114-0.122c-0.046-0.038-0.091-0.084-0.138-0.122
               c-0.137-0.107-0.281-0.228-0.403-0.51c-0.121-0.282-0.486-0.487-0.859-0.7c-0.184-0.107-0.373-0.221-0.524-0.336
               c-0.243-0.174-0.396-0.266-0.525-0.312c-0.13-0.053-0.221-0.053-0.335-0.045c-0.106,0.007-0.403-0.039-0.7-0.107
               c-0.26-0.061-0.523-0.137-0.671-0.206c-0.243-0.121-0.381-0.068-0.539-0.007c-0.069,0.023-0.146,0.054-0.229,0.061
               c-0.099,0.015-0.176,0.038-0.234,0.061c-0.115,0.03-0.207,0.06-0.404,0.023c-0.234-0.054-0.304,0.068-0.381,0.213
               c-0.037,0.061-0.076,0.137-0.145,0.183c-0.045,0.038-0.068,0.076-0.075,0.113c-0.017,0.039,0,0.077,0.021,0.107
               c0.023,0.023,0.055,0.046,0.084,0.061c0.084,0.046,0.198,0.061,0.275,0.046c0.053-0.008,0.1,0.008,0.145,0.046
               c0.039,0.03,0.075,0.091,0.09,0.167c0.024,0.114,0.1,0.145,0.191,0.175c0.061,0.031,0.129,0.053,0.189,0.13
               c0.039,0.053,0.039,0.076,0.016,0.091c-0.016,0.008-0.031,0.023-0.046,0.031c-0.06,0.038-0.129,0.083-0.196,0.167
               c-0.101,0.137-0.101,0.373-0.101,0.571c0,0.129,0,0.244-0.021,0.29c-0.07,0.106,0.075,0.251,0.203,0.388
               c0.077,0.083,0.153,0.167,0.146,0.183c-0.008,0.03-0.102,0.076-0.206,0.129c-0.122,0.061-0.253,0.122-0.334,0.213
               c-0.114,0.122-0.412,0.046-0.679-0.03l-0.045-0.008c-0.146-0.038-0.213-0.092-0.291-0.145c-0.076-0.06-0.158-0.121-0.312-0.175
               c-0.168-0.053-0.313-0.007-0.45,0.031c-0.121,0.038-0.236,0.068-0.313,0.007c-0.084-0.068-0.205-0.122-0.334-0.152
               c-0.205-0.053-0.42-0.068-0.473-0.061c-0.054,0.008-0.191,0.099-0.32,0.221c-0.1,0.091-0.197,0.199-0.235,0.274
               c-0.03,0.076-0.205,0.13-0.396,0.19c-0.16,0.053-0.328,0.108-0.441,0.183l-0.047,0.038c-0.182,0.122-0.205,0.137-0.495,0.069
               c-0.233-0.053-0.447-0.039-0.669-0.023c-0.105,0.007-0.213,0.007-0.336,0.007c-0.158,0-0.426-0.076-0.67-0.175
               c-0.266-0.114-0.502-0.243-0.557-0.327c-0.082-0.121-0.213-0.129-0.381-0.137c-0.105-0.008-0.221-0.008-0.342-0.053
               c-0.152-0.046-0.197-0.084-0.244-0.122c-0.052-0.038-0.099-0.077-0.242-0.122c-0.105-0.038-0.273-0.068-0.457-0.092
               c-0.281-0.038-0.588-0.06-0.717-0.068c-0.113-0.007-0.183,0.03-0.273,0.084c-0.068,0.038-0.16,0.091-0.313,0.129
               c-0.32,0.084-0.449,0.054-0.686,0.008l-0.022-0.008c-0.055-0.015-0.106-0.022-0.159-0.03c-0.152-0.031-0.267-0.053-0.504-0.167
               c-0.281-0.145-0.313-0.184-0.402-0.305l-0.016-0.031c-0.021-0.03-0.046-0.06-0.068-0.099c-0.1-0.16-0.221-0.365-0.494-0.472
               c-0.221-0.076-0.633-0.152-0.914-0.198c-0.146-0.022-0.252-0.038-0.282-0.053c-0.052-0.023-0.197-0.069-0.351-0.107
               c-0.099-0.022-0.205-0.045-0.281-0.053c-0.166-0.015-0.365-0.144-0.473-0.221l-0.037-0.03c-0.062-0.038-0.106-0.061-0.146-0.061
               c-0.055,0.007-0.084,0.038-0.084,0.114c0,0.023-0.022,0.084-0.062,0.137c-0.053,0.107-0.123,0.214-0.145,0.236
               c-0.031,0.031-0.053,0.077-0.061,0.122c-0.008,0.038,0,0.076,0.029,0.114c0.008,0.008,0,0.039-0.016,0.107
               c-0.016,0.053-0.031,0.13-0.055,0.236c-0.036,0.228,0.177,0.319,0.365,0.403c0.053,0.023,0.115,0.046,0.153,0.077
               c0.093,0.053,0.099,0.114,0.113,0.182c0,0.038,0.008,0.084,0.03,0.137c0.031,0.077-0.022,0.107-0.084,0.145
               c-0.022,0.015-0.045,0.031-0.076,0.053c-0.045,0.031-0.084,0.061-0.121,0.076c-0.038,0.008-0.076,0.008-0.121-0.015
               c-0.055-0.015-0.107-0.023-0.176-0.03c-0.092-0.007-0.19-0.015-0.297-0.061c-0.129-0.061-0.205-0.053-0.313-0.038
               c-0.063,0-0.138,0.015-0.259,0.008c-0.146-0.008-0.282-0.016-0.427-0.031c-0.146-0.015-0.291-0.03-0.473-0.053
               c-0.244-0.03-0.229-0.138-0.222-0.229c0.008-0.03,0.008-0.053,0.008-0.076c0-0.145-0.427-0.168-0.745-0.183
               c-0.076-0.007-0.154-0.007-0.207-0.015c-0.068-0.008-0.119-0.008-0.166-0.008c-0.138,0-0.222,0-0.404-0.152
               c-0.182-0.152-0.213-0.137-0.273-0.091c-0.023,0.015-0.063,0.038-0.139,0.038c-0.166,0-0.211,0.053-0.258,0.106
               c-0.03,0.031-0.053,0.062-0.114,0.076c-0.13,0.031-0.373,0.222-0.55,0.359c-0.09,0.068-0.157,0.129-0.183,0.137
               c-0.052,0.03-0.157,0.091-0.25,0.167c-0.067,0.053-0.131,0.114-0.167,0.168c-0.031,0.045-0.1,0.083-0.197,0.106
               c-0.117,0.031-0.252,0.039-0.403,0.031c-0.214-0.023-0.222,0.068-0.222,0.167c-0.008,0.038-0.008,0.084-0.084,0.099
               c-0.235,0.046-0.283,0.031-0.465-0.008c-0.084-0.022-0.145-0.06-0.189-0.113c-0.045-0.054-0.068-0.122-0.083-0.199
               c-0.009-0.038-0.031-0.068-0.054-0.083c-0.031-0.023-0.069-0.031-0.106-0.023c-0.039,0-0.077,0.015-0.116,0.038
               c-0.074,0.038-0.15,0.114-0.174,0.175c-0.016,0.03-0.113,0.008-0.243-0.023c-0.084-0.015-0.176-0.03-0.269-0.046
               c-0.036-0.007-0.075-0.007-0.112-0.015c-0.151-0.015-0.183-0.015-0.252-0.122c-0.084-0.121-0.197-0.205-0.343-0.311
               c-0.03-0.024-0.067-0.054-0.105-0.084c-0.099-0.069-0.183-0.077-0.252-0.077c-0.061,0-0.113-0.007-0.168-0.106
               c-0.061-0.122-0.189-0.221-0.326-0.297c-0.138-0.076-0.281-0.137-0.381-0.168c-0.054-0.023-0.084-0.038-0.115-0.053
               c-0.082-0.046-0.113-0.053-0.281-0.023c-0.084,0.023-0.144,0.046-0.205,0.076c-0.092,0.038-0.168,0.069-0.288,0.053
               c-0.138-0.022-0.161-0.022-0.206-0.007c-0.021,0.007-0.055,0.015-0.129,0.015c-0.184,0.008-0.237-0.015-0.344-0.061
               l-0.008-0.007c-0.046-0.023-0.068-0.046-0.091-0.061c-0.047-0.045-0.084-0.077-0.19-0.091c-0.068-0.016-0.068-0.031-0.076-0.054
               s-0.022-0.061-0.055-0.099c-0.074-0.122-0.183-0.099-0.281-0.076c-0.014,0.008-0.029,0.008-0.045,0.015
               c-0.092,0.015-0.092,0.077-0.076,0.16c0,0.016,0,0.031,0.008,0.045c0.008,0.031,0.016,0.069,0.016,0.107
               c0.008,0.038-0.016,0.054-0.045,0.054c-0.039,0.007-0.084,0-0.131,0l-0.045-0.008c-0.063,0-0.16-0.061-0.259-0.144
               c-0.114-0.092-0.229-0.214-0.298-0.32c-0.068-0.114-0.152-0.221-0.252-0.328c-0.105-0.107-0.228-0.206-0.373-0.297
               c-0.045-0.03-0.074-0.053-0.1-0.068c-0.213-0.137-0.365-0.228-0.729-0.609c-0.328-0.351-0.922-0.686-1.195-0.846
               c-0.084-0.045-0.13-0.068-0.13-0.076c-0.017-0.022-0.062-0.038-0.106-0.06c-0.074-0.032-0.184-0.084-0.205-0.137
               c-0.017-0.023,0.015-0.054,0.037-0.084c0.01-0.016,0.023-0.031,0.03-0.054c0.038-0.06,0.045-0.114-0.054-0.175
               c-0.121-0.068-0.273,0.031-0.402,0.122c-0.063,0.038-0.121,0.076-0.168,0.091c-0.068,0.023-0.107,0.061-0.137,0.092
               c-0.039,0.038-0.069,0.076-0.168,0.076c-0.152,0.015-0.189,0.099-0.221,0.19c-0.017,0.045-0.031,0.092-0.068,0.115
               c-0.084,0.045-0.335,0.06-0.525,0.068c-0.076,0.008-0.137,0.008-0.176,0.015c-0.084,0.008-0.1-0.007-0.137-0.046
               c-0.021-0.015-0.047-0.045-0.084-0.068c0.008-0.015,0.031-0.03,0.053-0.046c0.084-0.053,0.184-0.121,0.123-0.22
               c-0.063-0.115-0.139-0.069-0.213-0.031c-0.023,0.015-0.055,0.031-0.063,0.031c-0.062-0.084-0.152-0.076-0.272-0.062
               c-0.038,0.008-0.077,0.016-0.13,0.016c-0.076,0.008-0.166-0.023-0.252-0.069c-0.092-0.045-0.176-0.122-0.236-0.197
               c-0.074-0.099-0.137-0.092-0.197-0.046c-0.016,0.014-0.037,0.03-0.053,0.053c-0.023,0.023-0.047,0.053-0.063,0.053
               c-0.053,0-0.099,0.008-0.144,0.015c-0.047,0.008-0.092,0.023-0.138,0.039c-0.03,0.015-0.055,0.007-0.076,0
               c-0.031-0.016-0.053-0.039-0.084-0.069c-0.01-0.007,0-0.015,0.007-0.03c0.017-0.038,0.039-0.084,0.024-0.16
               c-0.018-0.145-0.1-0.161-0.207-0.183c-0.016,0-0.037,0-0.045-0.008c-0.039-0.007-0.076-0.114-0.114-0.213
               c-0.029-0.083-0.062-0.167-0.099-0.213c-0.047-0.061-0.117-0.084-0.184-0.091c-0.084-0.008-0.184,0.023-0.236,0.046
               c-0.037,0.022-0.129,0.022-0.229,0.022c-0.092,0-0.189-0.015-0.252-0.045h-0.007c-0.138-0.061-0.253-0.107-0.373-0.107
               c-0.062,0-0.14,0.023-0.222,0.053c-0.084,0.031-0.176,0.054-0.258,0.061c-0.084,0.008-0.123,0.008-0.154,0.03
               c-0.021,0.023-0.029,0.039-0.045,0.077c0,0.015-0.008,0.037-0.022,0.068c-0.03,0.084-0.104,0.099-0.196,0.122
               c-0.031,0.008-0.063,0.015-0.092,0.031c-0.054,0.015-0.077,0.022-0.107,0.038c-0.045,0.015-0.074,0.03-0.242,0.045
               c-0.244,0.023-0.489,0.106-0.525,0.122c-0.023,0-0.275,0.038-0.381,0.031c-0.092-0.008-0.13,0.053-0.176,0.129
               c-0.022,0.046-0.045,0.098-0.092,0.13c-0.037,0.03-0.045,0.022-0.068,0.015c-0.045-0.023-0.122-0.054-0.305-0.054
               c-0.184-0.007-0.273,0.031-0.351,0.069c-0.067,0.03-0.13,0.061-0.251,0.038c-0.167-0.016-0.402,0.068-0.604,0.137
               c-0.121,0.045-0.219,0.076-0.258,0.076c-0.047-0.007-0.092,0-0.146,0.008c-0.075,0.015-0.168,0.03-0.312,0
               c-0.047-0.015-0.084-0.023-0.106-0.031c-0.038-0.008-0.062-0.015-0.076-0.015c-0.022,0-0.03,0.007-0.046,0.015
               c-0.016,0-0.029,0.008-0.068,0.016c-0.123,0.03-0.129,0.09-0.138,0.182c0,0.046,0,0.099-0.021,0.153
               c-0.047,0.098-0.039,0.144,0.015,0.167c0.021,0.008,0.053,0.008,0.084,0c0.037,0,0.084-0.008,0.136,0
               c0.039,0.008,0.055,0.008,0.055,0.008c-0.008,0.007-0.016,0.022-0.022,0.037c-0.017,0.016-0.031,0.039-0.038,0.069
               c-0.016,0.061,0.016,0.092,0.068,0.114c0.054,0.016,0.146,0.016,0.244,0.016h0.066c0.055,0,0.069,0.015,0.063,0.037
               c0,0.046-0.039,0.107-0.069,0.16c-0.015,0.024-0.036,0.008-0.067-0.015c-0.045-0.023-0.091-0.052-0.16-0.061
               c-0.121-0.015-0.145,0.061-0.174,0.153c-0.009,0.038-0.023,0.076-0.047,0.106c-0.068,0.099-0.021,0.137,0.053,0.206
               c0.047,0.038,0.107,0.099,0.162,0.198c0.029,0.061,0.006,0.099-0.039,0.121c-0.045,0.023-0.106,0.039-0.168,0.054
               c-0.068,0.015-0.138,0.03-0.189,0.061c-0.038,0.023-0.068,0.046-0.076,0.069c-0.016,0.03-0.016,0.061-0.008,0.084
               c0.016,0.022,0.031,0.037,0.053,0.052c0.047,0.031,0.123,0.054,0.176,0.061c0.03,0,0.038,0.016,0.047,0.031
               c0.008,0.031,0.015,0.061,0.045,0.084c0.045,0.053,0.106,0.053,0.176,0.053c0.037,0,0.076,0,0.113,0.008
               c0.039,0.007,0.062,0.03,0.084,0.045c0.053,0.046,0.105,0.091,0.252,0.107c0.1,0.016,0.15,0.038,0.189,0.068
               c0.023,0.039,0.038,0.084,0.029,0.16c-0.006,0.106,0.016,0.152,0.039,0.19c0.016,0.023,0.029,0.046,0.021,0.092
               c-0.007,0.053-0.15,0.084-0.266,0.106c-0.047,0.007-0.084,0.016-0.115,0.023c-0.037,0.015-0.091,0.008-0.145-0.016
               c-0.061-0.029-0.129-0.083-0.205-0.151c-0.092-0.092-0.16-0.076-0.213-0.023c-0.039,0.046-0.063,0.114-0.078,0.167
               c-0.006,0.023-0.006,0.038-0.014,0.054c-0.009,0.023-0.039,0.03-0.092,0.037c-0.061,0.009-0.146,0-0.252,0
               c-0.167-0.014-0.281-0.113-0.441-0.258l-0.037-0.038c-0.146-0.138-0.152-0.129-0.197-0.084
               c-0.008,0.007-0.023,0.016-0.077,0.016c-0.104-0.009-0.16-0.038-0.205-0.061c-0.037-0.016-0.067-0.031-0.101-0.031
               c-0.061,0-0.121,0.061-0.196,0.122c-0.054,0.045-0.115,0.099-0.167,0.129c-0.016,0.008-0.016,0.023-0.016,0.016
               c0-0.008-0.008-0.016-0.017-0.031c-0.016-0.053-0.046-0.13-0.151-0.191c-0.139-0.068-0.229-0.03-0.328,0.009
               c-0.053,0.022-0.1,0.045-0.15,0.045c-0.168,0.015-0.214,0.137-0.26,0.244c-0.008,0.015-0.016,0.029-0.016,0.045
               c-0.016,0.039-0.047,0.068-0.092,0.084c-0.047,0.015-0.113,0.023-0.197,0.015c-0.068-0.008-0.152-0.045-0.244-0.099
               c-0.129-0.076-0.252-0.167-0.273-0.198c-0.023-0.038-0.077-0.068-0.113-0.076c-0.016-0.008-0.039-0.008-0.054,0
               c-0.024,0-0.039,0.015-0.054,0.038c-0.008,0.023-0.016,0.053,0,0.092c0.008,0.03,0.008,0.068-0.008,0.091
               c-0.008,0.015-0.008,0.023-0.016,0.03c-0.008,0.007-0.016,0.007-0.023,0.007c-0.008,0.009-0.022,0.009-0.029,0
               c-0.029-0.014-0.055-0.068-0.077-0.121c-0.021-0.068-0.052-0.145-0.112-0.19c-0.062-0.039-0.121-0.053-0.19-0.076
               c-0.062-0.023-0.13-0.046-0.198-0.084c-0.068-0.053-0.1-0.091-0.128-0.129c-0.024-0.039-0.047-0.069-0.084-0.1
               c-0.063-0.053-0.245-0.045-0.429-0.045c-0.129,0.008-0.258,0.008-0.312-0.008c-0.069-0.022-0.106-0.06-0.139-0.099
               c-0.029-0.03-0.06-0.068-0.104-0.091c-0.084-0.046-0.145,0.008-0.229,0.076c-0.047,0.031-0.1,0.076-0.168,0.107
               c-0.077,0.046-0.153,0.046-0.221,0.03c-0.076-0.015-0.146-0.053-0.19-0.076c-0.038-0.023-0.076-0.046-0.106-0.069
               c-0.061-0.053-0.115-0.091-0.19-0.091c-0.082,0.008-0.114,0.061-0.175,0.16c-0.03,0.046-0.062,0.099-0.114,0.167
               c-0.06,0.084-0.129,0.138-0.198,0.168c-0.066,0.031-0.145,0.054-0.221,0.062c-0.145,0.03-0.145,0.083-0.152,0.167
               c0,0.023,0,0.046-0.007,0.069c-0.008,0.023-0.045,0.038-0.077,0.053c-0.066,0.023-0.144,0.053-0.189,0.152
               c-0.037,0.084-0.016,0.221,0.01,0.343c0.016,0.107,0.029,0.206,0.007,0.237c-0.039,0.03-0.069,0.045-0.106,0.053
               c-0.047,0-0.106-0.008-0.199-0.039c-0.166-0.053-0.197-0.106-0.272-0.266h-0.009c-0.007-0.023-0.021-0.038-0.036-0.053
               c-0.063-0.069-0.161-0.107-0.252-0.115c-0.099-0.007-0.19,0.016-0.235,0.038c-0.016,0.008-0.022,0.016-0.039,0.023
               c-0.053,0.054-0.03,0.13,0,0.221c0.023,0.068,0.047,0.138,0.023,0.168c-0.039,0.061-0.068,0.114-0.091,0.205
               c-0.024,0.084-0.039,0.191-0.039,0.336c0,0.205,0.069,0.22,0.138,0.235c0.03,0.008,0.053,0.008,0.061,0.054
               c0.029,0.099-0.092,0.236-0.168,0.32c-0.038,0.038-0.067,0.068-0.067,0.091c-0.007,0.038-0.015,0.099,0,0.159
               c0.009,0.046,0.029,0.092,0.067,0.123c0.046,0.045,0.107,0.053,0.16,0.061c0.047,0.007,0.092,0.014,0.121,0.045
               c0.039,0.031,0.076,0.084,0.108,0.13c0.015,0.037,0.028,0.075,0.028,0.114c-0.008,0.069,0.008,0.114,0.047,0.152
               c0.029,0.03,0.083,0.053,0.145,0.061c0.062,0.015,0.106,0.007,0.146-0.008c0.022-0.008,0.053-0.015,0.084,0
               c0.046,0.023,0.082,0.015,0.137,0.008c0.054-0.008,0.122-0.023,0.274,0.015c0.197,0.046,0.313,0.274,0.38,0.412
               c0.022,0.03,0.038,0.06,0.046,0.083c0.046,0.069,0.084,0.092,0.146,0.137c0.028,0.023,0.076,0.045,0.129,0.099
               c0.022,0.015,0.047,0.015,0.037,0.015c-0.006,0.015-0.091,0.023-0.158,0.023c-0.023,0-0.039,0.008-0.055,0.008
               c-0.037,0-0.061,0.015-0.074,0.03c-0.033,0.023-0.039,0.046-0.033,0.084c0.009,0.023,0.016,0.046,0.039,0.069
               c0.054,0.061,0.16,0.136,0.32,0.205c0.113,0.053,0.159,0.068,0.184,0.092c-0.077,0.159-0.184,0.114-0.236,0.198
               c-0.061,0.083,0.075,0.152-0.091,0.19c-0.168,0.03-0.2,0.06-0.358,0.129c-0.152,0.061-0.32-0.03-0.356,0.031
               c-0.039,0.06,0.036,0.053-0.023,0.235c-0.062,0.183-0.13,0.411-0.229,0.527c-0.098,0.113-0.274,0.136-0.221,0.311
               c0.053,0.175,0.205,0.122,0.289,0.183s0.16,0.137,0.282,0.259c0.114,0.114,0.22,0.091,0.213,0.251
               c-0.017,0.16,0.022,0.297,0.046,0.419c0.021,0.129-0.115,0.388,0.029,0.449c0.146,0.053,0.32,0.091,0.396,0.289
               c0.082,0.198,0.092,0.244,0.319,0.382c0.13,0.068,0.206,0.144,0.259,0.212c-0.037,0.031-0.061,0.1-0.09,0.16
               c-0.019,0.046-0.031,0.091-0.047,0.107c-0.016,0.015-0.029,0.03-0.056,0.053c-0.052,0.054-0.12,0.137-0.212,0.206
               c-0.068,0.053-0.1,0.046-0.16,0.03c-0.021-0.007-0.053-0.015-0.082-0.023c-0.055-0.015-0.068-0.038-0.092-0.068
               c-0.024-0.038-0.047-0.076-0.108-0.114c-0.073-0.054-0.137-0.107-0.221-0.183c-0.044-0.039-0.099-0.084-0.159-0.137
               c-0.184-0.16-0.579-0.229-0.616-0.236c-0.022-0.007-0.159-0.061-0.274-0.16c-0.045-0.038-0.037-0.069-0.031-0.092
               c0.025-0.053,0.04-0.098-0.006-0.175c-0.053-0.091-0.145-0.084-0.26-0.076c-0.068,0.008-0.152,0.015-0.222-0.008
               c-0.091-0.03-0.166-0.053-0.241-0.076c-0.076-0.015-0.146-0.022-0.223-0.008c-0.066,0.008-0.113,0.031-0.159,0.062
               c-0.069,0.03-0.13,0.068-0.237,0.045c-0.074-0.015-0.074-0.038-0.074-0.06c-0.008-0.054-0.016-0.1-0.129-0.122
               c-0.077-0.016-0.129-0.031-0.207-0.062c-0.076-0.037-0.168-0.09-0.313-0.182c-0.221-0.129-0.236-0.129-0.326-0.137
               c-0.029,0-0.078-0.008-0.152-0.016c-0.092-0.015-0.19-0.015-0.297-0.015c-0.176,0-0.365,0-0.549-0.091
               c-0.166-0.099-0.235-0.099-0.297-0.099c-0.037,0-0.076,0-0.152-0.038c-0.168-0.084-0.266-0.084-0.41-0.076h-0.16
               c-0.1,0-0.182,0.038-0.244,0.099c-0.035-0.023-0.068-0.054-0.1-0.091c-0.098-0.145-0.434-0.419-0.615-0.526
               c-0.184-0.114-0.434-0.228-0.57-0.282c-0.138-0.06-0.16-0.229-0.343-0.229c-0.176,0-0.267,0.046-0.344-0.046
               c-0.084-0.09-0.343-0.304-0.427-0.342c-0.092-0.03-0.213-0.069-0.176-0.092c0.047-0.022,0.199-0.022,0.199-0.083
               c0-0.054,0.037-0.1,0.121-0.069c0.092,0.039,0.273,0.046,0.38,0.053c0.106,0.008,0.077-0.084,0.038-0.167
               c-0.029-0.084,0.039-0.061,0.1-0.206c0.068-0.152,0.055-0.282,0.152-0.282c0.092,0,0.045-0.128,0.152-0.075
               c0.1,0.053,0.152-0.031,0.061-0.1c-0.099-0.061-0.273-0.106-0.318-0.114c-0.047-0.015-0.244-0.22-0.198-0.251
               c0.046-0.023,0.274,0.076,0.35-0.023c0.084-0.107,0.16-0.137,0.236-0.152c0.069-0.015,0.259-0.122,0.342-0.183
               c0.084-0.061,0.199-0.206,0.063-0.206c-0.131,0-0.215,0.092-0.299,0.092c-0.082,0-0.299,0.059-0.291,0.036
               c0.008-0.022,0-0.269,0.029-0.307c0.03-0.046,0.053-0.03,0.15-0.067c0.092-0.038,0.045-0.179,0.181-0.164
               c0.138,0.015,0.351,0.033,0.442,0.025s0.236-0.118,0.236-0.118s0.058-0.244,0.034-0.373c-0.016-0.137-0.089-0.235-0.136-0.311
               c-0.044-0.076,0.029-0.175,0.038-0.266c0-0.084-0.048-0.175,0.014-0.221c0.062-0.038,0.078-0.366,0.056-0.419
               c-0.022-0.046-0.185-0.232-0.26-0.232c-0.078,0-0.214,0.007-0.273,0c-0.053-0.015-0.262-0.124-0.32-0.132
               c-0.055-0.015-0.213-0.059-0.343-0.089c-0.122-0.031-0.265-0.107-0.341-0.115c-0.076-0.014-0.243,0.039-0.317,0
               c-0.076-0.037-0.247-0.305-0.331-0.327c-0.076-0.023-0.266,0.005-0.35,0.043s-0.219,0.041-0.318,0.034
               c-0.1-0.015-0.238-0.11-0.309-0.118c-0.068-0.008-0.242,0.078-0.305,0.025c-0.055-0.045-0.078-0.199-0.104-0.237
               c-0.021-0.038,0.063-0.137-0.044-0.229c-0.099-0.092-0.154-0.115-0.185-0.145c-0.021-0.023-0.086-0.077-0.201-0.099
               c-0.112-0.024-0.207-0.066-0.291-0.058c-0.084,0.008-0.153-0.072-0.185-0.117c-0.022-0.046-0.026-0.105-0.005-0.135
               c0.023-0.022,0.07-0.079,0.012-0.147c-0.056-0.07-0.146-0.107-0.207-0.214c-0.062-0.107-0.109-0.234-0.156-0.28
               c-0.045-0.045-0.155-0.078-0.248-0.093c-0.09-0.016-0.196-0.046-0.328-0.038c-0.129,0.015-0.164,0.027-0.188,0.05
               c-0.023,0.023-0.109,0.014-0.141,0.006c-0.029-0.008-0.164-0.022-0.256-0.014c-0.082,0.015-0.145,0.059-0.166,0.089
               c-0.022,0.023-0.034,0.098-0.135,0.075c-0.063-0.015-0.084-0.156-0.115-0.263c-0.038-0.107-0.164-0.202-0.203-0.248
               c-0.029-0.046-0.112-0.137-0.092-0.16c0.023-0.015,0.111,0.07,0.227,0.108c0.121,0.039,0.292-0.154,0.354-0.199
               c0.063-0.038,0.106-0.038,0.176-0.053c0.061-0.008,0.158-0.077,0.128-0.175c-0.021-0.099-0.044-0.236-0.138-0.267
               c-0.09-0.031-0.078-0.076-0.101-0.099c-0.017-0.023-0.101-0.113-0.138-0.113c-0.041-0.007-0.047-0.08-0.152-0.084
               c-0.051-0.003-0.143-0.069-0.189-0.13c-0.051-0.069-0.148-0.106-0.217-0.151c-0.068-0.039-0.177,0.021-0.199-0.085
               c-0.029-0.107-0.075-0.145-0.113-0.205c-0.038-0.062-0.221-0.168-0.221-0.252s0.074-0.198,0.029-0.259
               c-0.045-0.061-0.084-0.136-0.084-0.174c0-0.031-0.046-0.207-0.099-0.245c-0.054-0.03-0.073-0.153-0.133-0.161
               c-0.034-0.004-0.118-0.085-0.164-0.101c-0.038-0.015-0.306-0.005-0.427,0.049c-0.113,0.053-0.172,0.09-0.238,0.039
               c-0.057-0.042,0.046-0.137-0.053-0.167c-0.1-0.031-0.25-0.056-0.311-0.026c-0.068,0.031-0.119,0.065-0.145,0.042
               c-0.021-0.023-0.078-0.109-0.146-0.124c-0.067-0.008-0.106,0.018-0.184-0.035c-0.075-0.045-0.087-0.093-0.095-0.139
               c-0.015-0.053-0.125-0.276-0.188-0.329c-0.061-0.061-0.129-0.121-0.113-0.198c0.01-0.084,0.084-0.205,0.022-0.258
               c-0.054-0.062-0.259-0.122-0.251-0.214c0.016-0.091,0.1-0.197,0.093-0.281c-0.017-0.084-0.206-0.183-0.206-0.328
               c0.008-0.137-0.031-0.289-0.031-0.335c0-0.045-0.008-0.213,0.076-0.274c0.077-0.053,0.131-0.213,0.146-0.282
               c0.008-0.068-0.055-0.159-0.055-0.197c0-0.039-0.205-0.183-0.053-0.229c0.15-0.045,0.114,0.084,0.235,0.091
               c0.129,0.008,0.235-0.167,0.267-0.175c0.037-0.007,0.1,0,0.189-0.03c0.093-0.023,0.146-0.122,0.222-0.084
               c0.076,0.046,0.435-0.023,0.272-0.084c-0.168-0.053-0.364-0.068-0.51-0.068c-0.138,0.008-0.158,0.015-0.229-0.099
               c-0.067-0.114-0.113-0.175-0.182-0.221c-0.07-0.053-0.094-0.023-0.176,0.015c-0.085,0.031-0.191,0.024-0.168,0
               c0.021-0.031,0.315-0.212,0.461-0.266c0.145-0.053,0.403-0.232,0.504-0.315c0.092-0.084,0.299-0.378,0.414-0.531
               c0.112-0.152,0.434-0.281,0.494-0.365c0.069-0.076,0.29-0.417,0.221-0.554c-0.06-0.145-0.41-0.275-0.494-0.322
               c-0.084-0.045-0.473-0.193-0.451-0.277c0.029-0.076,0.222-0.229,0.168-0.35c-0.055-0.114-0.045-0.256-0.151-0.293
               c-0.104-0.039-0.315-0.015-0.302-0.129c0.018-0.114,0.006-0.203-0.057-0.241c-0.061-0.046-0.274-0.156-0.213-0.285
               c0.068-0.13,0.387-0.379,0.242-0.47c-0.152-0.084-0.059-0.271-0.136-0.347c-0.075-0.084-0.448-0.382-0.485-0.442
               c-0.039-0.069-0.213-0.119-0.15-0.157c0.053-0.039,0.279-0.313,0.248-0.435c-0.022-0.114-0.116-0.306-0.17-0.36
               c-0.084-0.096-0.147-0.08-0.219-0.169c-0.039-0.045-0.297-0.027-0.365-0.05c-0.077-0.022-0.08-0.194-0.026-0.239
               c0.046-0.046,0.071-0.156,0.118-0.202c0.037-0.045,0.143-0.016,0.173-0.061c0.03-0.046,0.079-0.182,0.162-0.174
               c0.083,0.016,0.137-0.024,0.221-0.054c0.084-0.038,0.177-0.152,0.242-0.145c0.077,0.008,0.229,0.008,0.252-0.076
               c0.03-0.077,0.035-0.134,0.035-0.134L134.135,10.063z M225.549,26.427c0,0-0.021,0.053,0.009,0.144
               c0.03,0.092-0.029,0.145-0.084,0.19c-0.054,0.046-0.138,0.077-0.054,0.176c0.076,0.091,0.045,0.19,0.175,0.16
               c0.122-0.038,0-0.099,0.137-0.153c0.139-0.046,0.238-0.106,0.153-0.235c-0.084-0.123-0.206-0.389-0.282-0.389
               C225.526,26.329,225.549,26.427,225.549,26.427z M222.61,32.077c-0.068,0.053-0.146,0.107-0.129,0.267
               c0.021,0.167-0.063,0.236-0.084,0.313c-0.018,0.077,0.066,0.267,0.138,0.16c0.067-0.106,0.038-0.297,0.151-0.358
               c0.121-0.061,0.357-0.32,0.221-0.373C222.763,32.023,222.61,32.077,222.61,32.077z M223.41,31.27
               c-0.115,0.031-0.115,0.183-0.115,0.252c0,0.068-0.137,0.159-0.151,0.227c-0.016,0.069-0.061,0.191-0.023,0.252
               c0.031,0.061-0.189,0.152-0.028,0.16c0.167,0.008,0.138,0.092,0.281-0.137c0.146-0.236,0.038-0.343,0.204-0.389
               c0.177-0.038,0.093-0.213,0.252-0.228c0.16-0.008,0.357-0.244,0.13-0.229c-0.229,0.008-0.342,0.244-0.396,0.198
               c-0.045-0.045,0-0.205-0.076-0.213C223.41,31.148,223.41,31.27,223.41,31.27z M224.567,30.562
               c-0.032,0.03-0.084,0.076-0.139,0.183c-0.053,0.099-0.145-0.015-0.113,0.152c0.039,0.168-0.037,0.281,0.067,0.206
               c0.106-0.077,0.138-0.145,0.214-0.229s0.236-0.312,0.168-0.35C224.703,30.485,224.567,30.562,224.567,30.562z M225.481,29.152
               c0.015,0.008,0.197,0.092,0.166,0.008c-0.037-0.092-0.242-0.198-0.229-0.107C225.429,29.152,225.481,29.152,225.481,29.152z
                M225.358,29.472c-0.022,0.084,0.008,0.176,0.076,0.13c0.068-0.054,0.168-0.214,0.068-0.229
               C225.412,29.351,225.358,29.472,225.358,29.472z M225.481,28.08c0,0.099,0.136,0.358,0.158,0.182
               c0.022-0.175-0.113-0.342-0.158-0.32C225.442,27.965,225.481,28.08,225.481,28.08z M225.558,27.44
               c-0.021,0.03-0.146,0.114-0.039,0.212c0.107,0.107,0.146,0.176,0.198,0.145c0.062-0.031,0.114-0.107,0.062-0.213
               C225.717,27.478,225.558,27.44,225.558,27.44z M192.069,5.558c0,0-0.439-0.198-0.563-0.061
               c-0.129,0.137-0.318,0.198-0.197,0.335c0.113,0.13,0.047,0.084,0.344,0.236c0.289,0.16,0.258,0.282,0.615,0.319
               c0.357,0.031,0.512,0.077,0.785,0.146c0.281,0.068,0.602,0.205,0.586,0.037c-0.021-0.167-0.055-0.327,0.068-0.244
               c0.122,0.077,0.038,0.176,0.389,0.168c0.356-0.015,0.67-0.228,0.867-0.137c0.198,0.092,0.364,0.167,0.563,0.114
               c0.199-0.053,0.457-0.228,0.474-0.281c0.021-0.054,0.021-0.129-0.198-0.251c-0.221-0.115-0.761-0.138-0.929-0.168
               c-0.168-0.038-0.938-0.114-1.241-0.183c-0.313-0.068-0.457-0.281-0.464-0.122c-0.017,0.168,0.317,0.321,0.144,0.358
               c-0.166,0.038-0.205-0.053-0.426-0.129c-0.229-0.076-0.548-0.122-0.587-0.114C192.261,5.587,192.069,5.558,192.069,5.558z
                M194.69,6.843c-0.045,0.023-0.297,0.077-0.107,0.13c0.184,0.046,0.152,0.076,0.344,0.099c0.189,0.022,0.389-0.076,0.305-0.198
               C195.146,6.759,194.69,6.843,194.69,6.843z M196.753,5.877c-0.205,0.075-0.557,0.19-0.084,0.289s0.814,0.19,1.166,0.236
               c0.342,0.046,0.251,0.061,0.686,0.099c0.441,0.038,0.494,0.076,0.647,0.022c0.158-0.045,0.494-0.114,0.304-0.22
               c-0.189-0.099-0.289-0.168-0.633-0.13c-0.334,0.031-0.41,0.115-0.602,0.023c-0.189-0.083-0.189-0.198-0.402-0.19
               c-0.223,0.015-0.473,0.03-0.609,0.03c-0.145,0-0.221,0.031-0.267-0.053C196.914,5.899,196.753,5.877,196.753,5.877z
                M195.894,7.142c-0.084,0-0.373-0.031-0.402,0.046c-0.04,0.083-0.063,0.174-0.1,0.205c-0.039,0.03-0.268,0.183-0.039,0.152
               c0.222-0.031,0.205-0.206,0.52-0.092c0.304,0.123,0.761,0.145,0.882,0.145c0.114,0,0.8,0.099,0.474-0.106
               c-0.319-0.213-0.533-0.289-0.763-0.304C196.235,7.178,195.894,7.142,195.894,7.142z M190.351,5.709
               c0.121,0.129,0.145,0.229,0.342,0.312c0.191,0.084,0.244,0.138,0.215,0.023c-0.022-0.122-0.131-0.167-0.223-0.229
               c-0.092-0.061-0.318-0.266-0.318-0.266L190.351,5.709z M144.582,10.438c0,0-0.669,0.015-0.319,0.328
               c0.344,0.312,0.434,0.396,0.687,0.312c0.243-0.076,0.875-0.45,0.571-0.548c-0.307-0.099-0.474-0.092-0.549-0.115
               C144.888,10.393,144.582,10.438,144.582,10.438z M152.349,4.827c-0.35,0.091-0.563,0.182-0.646,0.236
               c-0.092,0.061-0.151,0.084-0.473,0.16c-0.326,0.076-0.351,0.137-0.869,0.137c-0.516,0-0.797-0.084-1.026,0.007
               c-0.22,0.092-0.053,0.206-0.457,0.214c-0.403,0.015-0.472-0.023-0.638,0.06c-0.168,0.076-0.346,0.122-0.543,0.221
               c-0.204,0.1-0.381,0.091-0.418,0.168c-0.03,0.084-0.053,0.189-0.121,0.167c-0.069-0.023-0.374-0.167-0.313-0.007
               c0.053,0.16,0.356,0.091,0.151,0.244c-0.199,0.159-0.214,0.281-0.356,0.441c-0.146,0.16-0.215,0.167-0.289,0.229
               c-0.084,0.052-0.177,0.175,0,0.212c0.166,0.031,0.271,0.176,0.084,0.214c-0.191,0.031-0.48-0.062-0.48,0.091
               c0,0.145-0.038,0.259-0.122,0.289C145.74,7.941,146,8.124,146,8.124s0.03,0.091-0.084,0.182
               c-0.106,0.084-0.403,0.061-0.435,0.19c-0.039,0.123-0.19,0.313,0.099,0.358c0.29,0.046,0.442-0.144,0.511,0
               c0.061,0.145,0.061,0.145,0.26,0.252c0.19,0.099,0.41,0.335,0.342,0.122s-0.426-0.381-0.236-0.374
               c0.191,0.008,0.449,0.123,0.441,0.236c-0.016,0.115,0.008,0.267,0.146,0.29c0.137,0.023,1.043,0.129,1.264,0.137
               c0.215,0.015,0.244-0.076,0.512-0.008c0.272,0.069,0.632,0.237,0.563,0c-0.068-0.235-0.473-0.251-0.717-0.365
               c-0.253-0.107-0.725-0.358-0.791-0.434c-0.063-0.084-0.214-0.304-0.189-0.45c0.021-0.144-0.199-0.273,0.021-0.418
               c0.229-0.145,0.365-0.122,0.396-0.221c0.03-0.107,0.09-0.091,0.222-0.327c0.136-0.236,0.63-0.297,0.653-0.518
               c0.023-0.229,0.068-0.282,0.229-0.327c0.152-0.046-0.021,0.045,0.288-0.123c0.313-0.166,0.185-0.212,0.595-0.342
               c0.42-0.122,0.32-0.168,0.633-0.168s1.104-0.175,1.416-0.235c0.319-0.054,1.149-0.199,1.225-0.373
               c0.084-0.168-0.455-0.343-0.584-0.389C152.653,4.773,152.349,4.827,152.349,4.827z M169.457,3.312
               c-0.19-0.008-0.502-0.061-0.541,0.03c-0.037,0.092,0.032,0.236-0.045,0.289c-0.076,0.054-0.031,0.175-0.022,0.229
               c0.008,0.061-0.054,0.16-0.062,0.228c-0.007,0.068,0.022,0.198,0.137,0.198c0.123,0,0.472-0.236,0.624-0.244
               c0.161-0.007,0.626,0.008,0.776,0.008c0.146,0,0.389,0.054,0.687,0c0.305-0.046,0.588-0.151,0.51-0.235
               c-0.076-0.092-0.396-0.13-0.625-0.184c-0.228-0.053-0.234-0.152-0.592-0.182c-0.367-0.039-0.396,0.236-0.48,0.121
               c-0.084-0.114-0.037-0.296-0.121-0.312C169.617,3.25,169.457,3.312,169.457,3.312z M164.104,2.13
               c-0.153,0.016-0.289,0.061-0.465,0.092c-0.176,0.023-0.441-0.008-0.389,0.099c0.061,0.106,0.26,0.068,0.13,0.129
               c-0.13,0.054-0.327,0.153-0.335,0.198c-0.008,0.039-0.046,0.061,0.053,0.107c0.092,0.045-0.183,0.022-0.106,0.122
               c0.084,0.091,0.223,0.106,0.344,0.136c0.113,0.024,0.306,0.047,0.662,0.047c0.351,0,0.221,0.007,0.435-0.092
               c0.213-0.107,0.297-0.16,0.364-0.13c0.069,0.023-0.06,0.138-0.144,0.183c-0.077,0.046-0.504,0.107-0.207,0.168
               c0.298,0.061,0.375-0.099,0.693,0.099c0.328,0.198,0.19,0.114,0.625,0.236c0.434,0.114,0.937,0.045,1.248,0.076
               c0.319,0.038,0.411,0.061,0.754,0.084c0.336,0.023,0.859-0.046,0.555-0.152c-0.303-0.099-0.479-0.107-0.5-0.214
               c-0.024-0.114-0.055-0.289-0.198-0.342c-0.146-0.046-0.261-0.092-0.488-0.099c-0.228-0.008,0.076-0.077-0.297,0.007
               c-0.381,0.092-0.409,0.213-0.448,0.092c-0.046-0.115,0.008-0.161-0.175-0.176c-0.176-0.023-0.252,0.023-0.298-0.068
               c-0.046-0.084-0.298-0.206-0.189-0.236c0.099-0.039,0.334-0.054,0.184-0.107c-0.154-0.046-0.16-0.015-0.367-0.038
               c-0.205-0.03-0.921-0.228-1.043-0.259C164.379,2.069,164.104,2.13,164.104,2.13z M137.304,15.698c0,0-0.076,0.138,0.076,0.199
               c0.158,0.061,0.252,0.007,0.336,0.053c0.09,0.052,0.25,0.167,0.174,0.228c-0.068,0.054-0.061,0.092-0.152,0.054
               c-0.084-0.039-0.234-0.221-0.266-0.115c-0.039,0.107,0.021,0.176-0.115,0.236c-0.099,0.008-0.166,0.267-0.016,0.313
               c0.151,0.045,0.26,0.053,0.307,0.121c0.045,0.076,0.334,0.153,0.41,0.252c0.068,0.091,0.137,0.159-0.008,0.159
               c-0.146,0.008-0.418-0.113-0.328,0.008c0.1,0.122,0.281,0.053,0.358,0.107c0.084,0.053,0.175,0.175,0.252,0.106
               c0.067-0.068,0.091-0.167,0.183-0.175c0.092-0.008,0.266,0.038,0.176-0.167c-0.092-0.206-0.092-0.267-0.268-0.35
               c-0.175-0.077-0.373-0.222-0.373-0.381c0-0.16,0.114-0.289-0.021-0.373c-0.139-0.092-0.207-0.122-0.344-0.153
               c-0.146-0.023-0.176-0.122-0.229-0.144C137.396,15.653,137.304,15.698,137.304,15.698z M134.464,17.031
               c0.008,0.038,0.066,0.114,0.252,0.228c0.182,0.107,0.182,0.122,0.204,0.237c0.022,0.106,0.183,0.19,0.259,0.281
               c0.084,0.084,0.139,0.191,0.188,0.297c0.049,0.107,0.114,0.198,0.192,0.145c0.084-0.053,0.104-0.122,0.129-0.183
               c0.023-0.068,0.076-0.122,0.129-0.122c0.063,0,0.184,0.031,0.261,0.046c0.065,0.015,0.167,0.038,0.198-0.038
               c0.035-0.084-0.063-0.198,0.029-0.236c0.09-0.038,0.106-0.114,0.03-0.213c-0.084-0.107-0.237-0.191-0.313-0.244
               s-0.113-0.244-0.258-0.251c-0.139-0.016-0.222-0.091-0.313-0.145c-0.086-0.061-0.13-0.13-0.252-0.153
               c-0.121-0.022-0.121-0.166-0.259-0.06c-0.129,0.107-0.09,0.175-0.174,0.22c-0.093,0.047-0.154,0.031-0.192,0.054
               c-0.038,0.03-0.099,0.053-0.12,0.076C134.433,17.001,134.464,17.031,134.464,17.031z M189.765,21.919
               c0,0-0.336-0.069-0.215,0.236c0.123,0.305,0.252,0.167,0.215,0.404c-0.031,0.235-0.023,0.358,0,0.541
               c0.022,0.182-0.055,0.213-0.047,0.464c0.016,0.243,0.022,0.213-0.105,0.357c-0.137,0.145-0.084,0.305-0.221,0.373
               c-0.131,0.062-0.213,0.031-0.176,0.19c0.028,0.153,0.053,0.191-0.121,0.306c-0.183,0.106-0.236,0.365-0.344,0.472
               c-0.1,0.099-0.055,0.144-0.221,0.175c-0.168,0.038-0.633-0.099-0.616,0.091c0.007,0.19-0.017,0.19,0.242,0.213
               c0.26,0.023,0.374,0.1,0.588,0.038c0.213-0.068,0.455-0.106,0.584-0.251c0.123-0.144,0.078-0.327,0.101-0.48
               c0.022-0.159,0.03-0.159,0.19-0.129c0.159,0.038,0.146-0.107,0.252-0.236c0.099-0.122,0.188-0.053,0.364-0.152
               c0.184-0.107,0.318-0.084,0.259-0.252c-0.054-0.167,0.168-0.22,0.108-0.335c-0.069-0.114-0.397-0.023-0.345-0.182
               c0.062-0.16,0.403-0.031,0.373-0.176c-0.028-0.152-0.099-0.296-0.229-0.411c-0.121-0.107-0.197-0.243-0.189-0.365
               c0.016-0.129-0.045-0.236-0.1-0.434c-0.053-0.19,0.008-0.358-0.1-0.403C189.899,21.927,189.765,21.919,189.765,21.919z"/>
             <path fill="#808184" d="M154.784,26.045c-0.053,0.023-0.1,0.045-0.15,0.045
               c-0.168,0.016-0.214,0.138-0.26,0.244c-0.008,0.016-0.016,0.03-0.016,0.046c-0.016,0.038-0.047,0.068-0.092,0.083
               c-0.047,0.016-0.113,0.023-0.197,0.016c-0.068-0.008-0.152-0.045-0.244-0.099c-0.129-0.076-0.251-0.168-0.273-0.198
               c-0.023-0.038-0.076-0.068-0.113-0.076c-0.016-0.008-0.039-0.008-0.053,0c-0.025,0-0.039,0.015-0.055,0.038
               c-0.008,0.022-0.016,0.053,0,0.092c0.008,0.03,0.008,0.068-0.008,0.091c-0.008,0.015-0.008,0.023-0.016,0.03
               c-0.008,0.007-0.016,0.007-0.023,0.007c-0.01,0.008-0.021,0.008-0.029,0c-0.029-0.014-0.053-0.068-0.076-0.121
               c-0.021-0.069-0.053-0.145-0.113-0.19c-0.062-0.039-0.121-0.054-0.19-0.077c-0.062-0.022-0.129-0.045-0.198-0.084
               c-0.068-0.053-0.099-0.091-0.128-0.128c-0.024-0.039-0.047-0.069-0.084-0.1c-0.063-0.053-0.245-0.046-0.428-0.046
               c-0.129,0.008-0.259,0.008-0.312-0.007c-0.069-0.023-0.106-0.061-0.14-0.099c-0.028-0.03-0.062-0.069-0.104-0.092
               c-0.084-0.045-0.145,0.008-0.229,0.077c-0.047,0.03-0.1,0.076-0.168,0.106c-0.077,0.046-0.152,0.046-0.221,0.031
               c-0.076-0.016-0.146-0.053-0.19-0.076c-0.038-0.023-0.075-0.046-0.106-0.069c-0.061-0.053-0.114-0.091-0.19-0.091
               c-0.082,0.008-0.114,0.061-0.174,0.16c-0.031,0.045-0.062,0.099-0.115,0.167c-0.06,0.084-0.129,0.137-0.198,0.167
               c-0.066,0.031-0.144,0.054-0.221,0.062c-0.145,0.03-0.145,0.083-0.152,0.167c0,0.023,0,0.045-0.006,0.068
               c-0.009,0.023-0.046,0.039-0.078,0.054c-0.066,0.023-0.144,0.053-0.188,0.152c-0.038,0.084-0.017,0.221,0.009,0.343
               c0.016,0.107,0.029,0.205,0.008,0.237c-0.039,0.029-0.07,0.044-0.107,0.052c-0.047,0-0.106-0.008-0.198-0.038
               c-0.166-0.053-0.198-0.106-0.272-0.266h-0.01c-0.007-0.023-0.021-0.039-0.035-0.054c-0.063-0.068-0.162-0.106-0.252-0.114
               c-0.1-0.008-0.191,0.015-0.236,0.038c-0.016,0.008-0.022,0.016-0.039,0.023c-0.053,0.053-0.029,0.129,0,0.22
               c0.023,0.069,0.047,0.139,0.023,0.168c-0.039,0.062-0.068,0.114-0.091,0.205c-0.024,0.084-0.039,0.191-0.039,0.336
               c0,0.205,0.069,0.221,0.138,0.236c0.03,0.007,0.053,0.007,0.061,0.053c0.029,0.1-0.092,0.236-0.167,0.32
               c-0.038,0.039-0.067,0.069-0.067,0.092c-0.008,0.038-0.016,0.099,0,0.159c0.008,0.046,0.029,0.092,0.067,0.122
               c0.046,0.046,0.106,0.053,0.159,0.061c0.048,0.008,0.093,0.015,0.121,0.046c0.039,0.03,0.076,0.084,0.108,0.13
               c0.015,0.037,0.029,0.075,0.029,0.113c-0.007,0.069,0.007,0.114,0.046,0.152c0.031,0.031,0.083,0.053,0.145,0.062
               c0.063,0.015,0.106,0.007,0.146-0.009c0.021-0.007,0.053-0.014,0.083,0c0.046,0.024,0.083,0.016,0.138,0.009
               c0.053-0.009,0.121-0.023,0.273,0.015c0.197,0.046,0.313,0.274,0.381,0.411c0.021,0.03,0.037,0.061,0.045,0.084
               c0.046,0.068,0.084,0.091,0.146,0.137c0.029,0.023,0.076,0.045,0.129,0.098c0.022,0.016,0.047,0.016,0.037,0.016
               c-0.006,0.015-0.09,0.022-0.157,0.022c-0.024,0-0.039,0.008-0.056,0.008c-0.037,0-0.061,0.016-0.074,0.031
               c-0.032,0.023-0.039,0.045-0.032,0.084c0.008,0.022,0.015,0.045,0.039,0.068c0.053,0.061,0.159,0.137,0.319,0.206
               c0.113,0.053,0.16,0.068,0.184,0.092c-0.077,0.159-0.184,0.113-0.236,0.197c-0.061,0.084,0.075,0.152-0.091,0.19
               c-0.168,0.031-0.2,0.061-0.358,0.13c-0.151,0.061-0.319-0.03-0.356,0.03c-0.039,0.061,0.037,0.054-0.022,0.236
               c-0.062,0.183-0.131,0.411-0.229,0.526c-0.099,0.114-0.274,0.137-0.222,0.313c0.054,0.175,0.205,0.122,0.289,0.182
               c0.084,0.061,0.16,0.138,0.283,0.26c0.112,0.114,0.219,0.091,0.213,0.251c-0.017,0.16,0.021,0.297,0.045,0.419
               c0.022,0.129-0.115,0.388,0.029,0.448c0.146,0.054,0.32,0.092,0.396,0.29c0.082,0.198,0.092,0.244,0.32,0.381
               c0.129,0.068,0.205,0.144,0.258,0.213c-0.037,0.03-0.061,0.099-0.09,0.16c-0.018,0.046-0.031,0.091-0.047,0.107
               c-0.016,0.015-0.029,0.03-0.055,0.052c-0.053,0.055-0.121,0.138-0.213,0.207c-0.068,0.053-0.1,0.045-0.16,0.03
               c-0.021-0.008-0.052-0.015-0.082-0.023c-0.053-0.016-0.068-0.038-0.092-0.069c-0.023-0.038-0.046-0.076-0.107-0.114
               c-0.074-0.053-0.137-0.106-0.221-0.182c-0.045-0.039-0.1-0.084-0.16-0.138c-0.183-0.16-0.578-0.228-0.615-0.235
               c-0.023-0.008-0.16-0.061-0.273-0.16c-0.047-0.039-0.039-0.069-0.032-0.092c0.024-0.054,0.039-0.099-0.007-0.175
               c-0.053-0.091-0.145-0.084-0.26-0.076c-0.067,0.007-0.151,0.014-0.221-0.008c-0.092-0.03-0.166-0.053-0.242-0.076
               c-0.076-0.015-0.146-0.022-0.223-0.008c-0.066,0.008-0.113,0.031-0.158,0.061c-0.07,0.031-0.131,0.069-0.238,0.045
               c-0.074-0.014-0.074-0.037-0.074-0.059c-0.008-0.054-0.016-0.1-0.128-0.122c-0.078-0.016-0.13-0.031-0.207-0.062
               c-0.076-0.037-0.168-0.091-0.313-0.182c-0.221-0.129-0.236-0.129-0.326-0.138c-0.028,0-0.078-0.008-0.152-0.015
               c-0.092-0.015-0.189-0.015-0.297-0.015c-0.176,0-0.365,0-0.549-0.091c-0.166-0.099-0.235-0.099-0.297-0.099
               c-0.037,0-0.076,0-0.15-0.038c-0.169-0.084-0.268-0.084-0.412-0.076h-0.16c-0.1,0-0.182,0.037-0.244,0.099
               c-0.035-0.023-0.068-0.054-0.099-0.091c-0.099-0.146-0.435-0.419-0.616-0.527c-0.184-0.113-0.434-0.227-0.57-0.281
               c-0.138-0.061-0.16-0.228-0.343-0.228c-0.176,0-0.267,0.045-0.344-0.046c-0.084-0.091-0.343-0.305-0.427-0.343
               c-0.092-0.029-0.213-0.068-0.176-0.091c0.047-0.022,0.199-0.022,0.199-0.083c0-0.055,0.037-0.1,0.121-0.069
               c0.092,0.038,0.273,0.046,0.381,0.053c0.106,0.008,0.076-0.084,0.037-0.167c-0.029-0.084,0.039-0.062,0.1-0.206
               c0.068-0.152,0.055-0.282,0.152-0.282c0.092,0,0.045-0.129,0.152-0.076c0.1,0.054,0.152-0.03,0.061-0.099
               c-0.099-0.061-0.271-0.107-0.318-0.114c-0.047-0.015-0.244-0.221-0.198-0.251c0.046-0.023,0.274,0.076,0.351-0.023
               c0.084-0.107,0.159-0.138,0.235-0.153c0.069-0.014,0.259-0.121,0.342-0.182c0.084-0.061,0.199-0.206,0.063-0.206
               c-0.131,0-0.215,0.092-0.299,0.092c-0.082,0-0.299,0.058-0.291,0.036c0.009-0.022,0-0.27,0.029-0.308
               c0.03-0.046,0.053-0.029,0.15-0.067c0.092-0.038,0.045-0.178,0.181-0.164c0.138,0.016,0.351,0.033,0.442,0.026
               c0.091-0.008,0.236-0.118,0.236-0.118s0.058-0.244,0.035-0.373c-0.017-0.137-0.09-0.236-0.137-0.311
               c-0.045-0.077,0.031-0.175,0.038-0.267c0-0.083-0.047-0.174,0.015-0.22c0.063-0.038,0.077-0.366,0.055-0.419
               c-0.022-0.046-0.185-0.233-0.26-0.233c-0.078,0-0.213,0.008-0.273,0c-0.053-0.015-0.262-0.124-0.32-0.131
               c-0.054-0.015-0.213-0.059-0.343-0.09c-0.121-0.03-0.265-0.106-0.341-0.114c-0.076-0.015-0.243,0.039-0.317,0
               c-0.075-0.038-0.247-0.305-0.331-0.328c-0.076-0.023-0.265,0.005-0.35,0.044c-0.084,0.037-0.219,0.041-0.318,0.033
               c-0.098-0.015-0.238-0.11-0.308-0.118c-0.069-0.007-0.243,0.079-0.306,0.026c-0.055-0.046-0.078-0.199-0.104-0.238
               c-0.021-0.038,0.063-0.137-0.044-0.228c-0.099-0.092-0.154-0.115-0.185-0.145c-0.021-0.024-0.086-0.077-0.201-0.1
               c-0.112-0.023-0.207-0.065-0.291-0.057c-0.084,0.008-0.153-0.072-0.185-0.118c-0.022-0.045-0.026-0.104-0.005-0.134
               c0.023-0.023,0.07-0.079,0.012-0.148c-0.056-0.069-0.146-0.106-0.207-0.213c-0.062-0.107-0.11-0.234-0.156-0.28
               c-0.045-0.045-0.155-0.079-0.248-0.094c-0.09-0.015-0.197-0.045-0.327-0.038c-0.129,0.016-0.165,0.028-0.188,0.051
               c-0.022,0.022-0.109,0.014-0.14,0.005c-0.03-0.007-0.165-0.021-0.257-0.014c-0.082,0.016-0.145,0.06-0.166,0.09
               c-0.021,0.023-0.034,0.097-0.135,0.074c-0.063-0.014-0.084-0.155-0.115-0.262c-0.038-0.107-0.164-0.203-0.202-0.248
               c-0.03-0.046-0.112-0.137-0.092-0.161c0.022-0.014,0.11,0.071,0.226,0.109c0.121,0.038,0.292-0.154,0.354-0.2
               c0.063-0.038,0.107-0.038,0.176-0.053c0.061-0.007,0.158-0.076,0.129-0.174c-0.021-0.1-0.045-0.237-0.139-0.268
               c-0.09-0.03-0.077-0.076-0.1-0.098c-0.017-0.023-0.102-0.113-0.139-0.113c-0.04-0.008-0.047-0.081-0.152-0.084
               c-0.051-0.003-0.143-0.07-0.188-0.13c-0.052-0.069-0.149-0.106-0.218-0.152c-0.068-0.038-0.176,0.022-0.197-0.085
               c-0.031-0.107-0.077-0.144-0.115-0.205s-0.221-0.168-0.221-0.252s0.074-0.197,0.029-0.259c-0.045-0.061-0.084-0.136-0.084-0.174
               c0-0.031-0.045-0.206-0.099-0.244c-0.054-0.031-0.073-0.154-0.133-0.162c-0.034-0.003-0.118-0.085-0.163-0.1
               c-0.039-0.016-0.307-0.005-0.428,0.048c-0.114,0.053-0.172,0.09-0.238,0.04c-0.057-0.043,0.046-0.137-0.053-0.168
               c-0.1-0.03-0.248-0.055-0.311-0.026c-0.068,0.032-0.119,0.065-0.144,0.042c-0.022-0.022-0.078-0.109-0.146-0.124
               c-0.068-0.007-0.105,0.019-0.185-0.034c-0.075-0.046-0.086-0.093-0.095-0.139c-0.014-0.054-0.125-0.276-0.188-0.329
               c-0.061-0.061-0.129-0.121-0.113-0.198c0.01-0.084,0.084-0.205,0.023-0.259c-0.055-0.061-0.259-0.121-0.251-0.213
               c0.015-0.091,0.099-0.198,0.092-0.282c-0.016-0.084-0.206-0.182-0.206-0.327c0.008-0.137-0.031-0.289-0.031-0.335
               c0-0.046-0.008-0.214,0.076-0.274c0.077-0.053,0.131-0.214,0.146-0.282c0.007-0.069-0.055-0.16-0.055-0.198
               s-0.205-0.182-0.053-0.228c0.151-0.046,0.113,0.084,0.235,0.091c0.129,0.007,0.235-0.167,0.267-0.175
               c0.037-0.008,0.1,0,0.19-0.03c0.092-0.023,0.146-0.122,0.221-0.084c0.076,0.046,0.435-0.023,0.272-0.084
               c-0.168-0.053-0.364-0.068-0.51-0.068c-0.138,0.007-0.158,0.015-0.229-0.099c-0.066-0.115-0.113-0.175-0.182-0.221
               c-0.069-0.053-0.094-0.023-0.176,0.015c-0.084,0.031-0.191,0.023-0.168,0c0.021-0.031,0.315-0.213,0.461-0.266
               c0.145-0.053,0.403-0.233,0.504-0.316c0.092-0.084,0.299-0.378,0.414-0.53c0.112-0.152,0.435-0.282,0.494-0.366
               c0.069-0.075,0.291-0.417,0.221-0.553c-0.06-0.145-0.41-0.276-0.494-0.322c-0.084-0.045-0.473-0.193-0.45-0.277
               c0.028-0.077,0.221-0.229,0.168-0.351c-0.056-0.114-0.045-0.255-0.151-0.293c-0.105-0.038-0.316-0.015-0.303-0.128
               c0.018-0.115,0.006-0.203-0.055-0.242c-0.063-0.045-0.276-0.155-0.215-0.285c0.068-0.13,0.387-0.378,0.242-0.47
               c-0.152-0.083-0.059-0.27-0.136-0.347c-0.075-0.084-0.448-0.381-0.485-0.441c-0.039-0.069-0.213-0.12-0.15-0.158
               c0.053-0.038,0.279-0.313,0.248-0.434c-0.021-0.115-0.116-0.307-0.17-0.36c-0.084-0.096-0.146-0.08-0.219-0.169
               c-0.039-0.046-0.297-0.027-0.365-0.05c-0.076-0.022-0.079-0.194-0.026-0.24c0.046-0.046,0.071-0.155,0.118-0.201
               c0.037-0.046,0.143-0.016,0.173-0.061c0.03-0.047,0.079-0.182,0.162-0.174c0.083,0.015,0.138-0.025,0.222-0.054
               c0.084-0.039,0.176-0.153,0.241-0.145c0.077,0.007,0.229,0.007,0.252-0.076c0.03-0.077,0.035-0.134,0.035-0.134l0.056-0.112
               c0,0,0.047,0.093,0.123,0.132c0.068,0.03,0.146-0.031,0.176-0.084c0.039-0.061,0.006-0.198,0.129-0.153
               c0.115,0.046,0.229,0.153,0.435,0.153c0.206,0.007,0.365-0.024,0.313,0.06c-0.062,0.091-0.42,0.008-0.436,0.099
               c-0.014,0.092,0.047,0.008,0.268,0.092c0.228,0.091,0.26,0.068,0.457,0.114c0.205,0.038,0.066-0.137,0.533,0.008
               c0.457,0.144,0.373-0.069,0.813,0.121c0.435,0.191,1.591,0.587,1.729,0.678c0.137,0.091,0.295,0.046,0.463,0.137
               c0.174,0.099,0.198,0.076,0.351,0.083c0.151,0.016,0.13,0.084,0.366,0.161c0.234,0.076,0.318,0.114,0.486,0.243
               c0.176,0.13,0.137,0.221,0.221,0.297c0.076,0.077,0.205,0.115,0.145,0.228c-0.053,0.107-0.213,0.321-0.531,0.473
               c-0.313,0.16-0.83,0.449-1.615,0.221c-0.775-0.229-0.808-0.107-1.256-0.244c-0.449-0.144-0.959-0.388-1.09-0.373
               c-0.121,0.007-0.363,0.007-0.556-0.077c-0.19-0.091-0.456-0.281-0.479-0.174c-0.015,0.099,0.115,0.083,0.305,0.251
               c0.191,0.167,0.367,0.061,0.504,0.244c0.145,0.175,0.099,0.136,0.418,0.274c0.328,0.137,0.441-0.008,0.52,0.266
               c0.084,0.266-0.152,0.381-0.022,0.533c0.129,0.16,0.235,0.152,0.228,0.312c-0.007,0.16-0.029,0.29,0.123,0.328
               c0.151,0.037,0.371-0.016,0.569,0.083c0.198,0.1,0.214,0.206,0.351,0.259c0.146,0.054,0.459,0.092,0.541,0.115
               c0.084,0.023,0.268,0.023,0.336-0.008c0.067-0.03,0.137-0.03,0.137-0.145c0-0.114-0.022-0.236-0.159-0.236
               c-0.138,0-0.229,0.061-0.351,0c-0.115-0.061-0.129-0.129-0.289-0.213c-0.152-0.084-0.344-0.183-0.268-0.236
               c0.075-0.046,0.206-0.031,0.229-0.114c0.016-0.084,0.054-0.145,0.184-0.13c0.13,0.016,0.479,0.206,0.586,0.289
               c0.113,0.084,0.105,0.039,0.373,0.077c0.273,0.038,0.373,0.069,0.497,0.107c0.12,0.037,0.508,0.075,0.601,0.053
               c0.092-0.023,0.204-0.076,0.061-0.19c-0.137-0.115-0.776-0.458-0.577-0.587c0.196-0.13,0.364-0.16,0.448-0.281
               c0.084-0.13,0.053-0.077,0.281-0.16c0.221-0.084,0.198-0.282,0.449-0.32c0.243-0.038,0.182,0.008,0.389,0.038
               c0.196,0.03,0.474-0.114,0.578,0.068c0.114,0.183,0.137,0.206,0.213,0.206c0.084,0.008,0.084-0.16,0.151-0.282
               c0.069-0.114,0.024-0.456-0.06-0.533c-0.084-0.068-0.436,0.068-0.436-0.152c0.008-0.221,0.19-0.251,0.129-0.464
               c-0.053-0.214-0.021-0.434-0.266-0.465c-0.252-0.024-0.617-0.168-0.412-0.19c0.206-0.023,0.313,0.022,0.441,0.053
               c0.121,0.031,0.261,0.007,0.436,0.007c0.176,0,0.434-0.083,0.631,0.061c0.198,0.137,0.268,0.092,0.389,0.244
               c0.123,0.16,0.352,0.183,0.344,0.297c-0.016,0.107-0.184,0.068-0.357,0.091c-0.166,0.016-0.275-0.023-0.412,0.046
               c-0.137,0.076-0.197,0.015-0.175,0.137s0.13,0.198,0.206,0.206c0.083,0.007,0.234-0.015,0.273,0.091s0.197,0.213,0.236,0.221
               c0.029,0.007,0.623,0.091,0.813,0.015c0.185-0.076,0.298-0.076,0.243-0.243c-0.061-0.176-0.129-0.343-0.036-0.374
               c0.097-0.038,0.181,0.039,0.312-0.045c0.129-0.084,0.068-0.152,0.373-0.206c0.313-0.061,0.754-0.305,0.967-0.335
               c0.215-0.03,0.273,0.046,0.42-0.038c0.144-0.084,0.234-0.168,0.281-0.076c0.045,0.084,0.092,0.16,0.176,0.137
               c0.09-0.023,0.121-0.16,0.037-0.213c-0.084-0.054-0.021-0.054,0.084-0.122c0.105-0.06,0.373-0.152,0.549-0.152
               c0.168-0.009,0.563-0.13,0.457,0c-0.107,0.137-0.52,0.152-0.397,0.236c0.114,0.091,0.222,0.114,0.131,0.183
               c-0.092,0.068-0.26,0.136-0.099,0.167c0.166,0.023,0.197-0.054,0.396,0c0.196,0.06,0.28,0.114,0.334,0.023
               c0.062-0.099,0.008-0.183,0.098-0.221c0.102-0.038,0.168-0.16,0.412-0.122c0.252,0.038,0.213-0.023,0.427,0.016
               c0.214,0.045,0.235,0.152,0.396,0.053c0.159-0.099,0.273-0.267,0.51-0.267c0.237,0,0.449-0.129,0.533-0.077
               c0.077,0.055,0.243,0.07,0.198,0.184c-0.046,0.107-0.244,0.182-0.099,0.259c0.144,0.068,0.234,0.083,0.365,0.068
               c0.129-0.015,0.158-0.076,0.121-0.167c-0.029-0.092,0.021-0.145,0.129-0.152c0.105-0.008,0.289-0.016,0.281-0.122
               c-0.015-0.107-0.129-0.191-0.205-0.221c-0.076-0.03-0.084-0.039-0.176-0.138c-0.092-0.106-0.136-0.106-0.22-0.19
               c-0.085-0.075-0.198-0.075-0.298-0.068c-0.1,0.015-0.062-0.016-0.258-0.076c-0.199-0.061-0.429-0.107-0.572-0.229
               c-0.146-0.114-0.327-0.274-0.105-0.236c0.221,0.045,0.25-0.053,0.365-0.022c0.113,0.022,0.168,0.144,0.281,0.183
               c0.121,0.037,0.382,0.075,0.502,0.121c0.121,0.045,0.221,0.107,0.197,0.175c-0.022,0.068-0.021,0.084,0.068,0.099
               c0.092,0.008,0.069-0.152,0.178-0.16c0.099-0.007,0.104,0.039,0.371,0.046c0.274,0.008,0.631,0.06,0.967,0.099
               c0.344,0.031,0.557,0.023,0.867,0.167c0.313,0.145,1.014,0.29,1.387,0.435c0.367,0.152,0.754,0.091,0.998,0.251L154.784,26.045z
                M144.582,10.445c0,0-0.669,0.015-0.319,0.328c0.344,0.311,0.434,0.395,0.687,0.311c0.243-0.076,0.875-0.449,0.571-0.547
               c-0.307-0.1-0.474-0.092-0.549-0.115C144.888,10.398,144.582,10.445,144.582,10.445 M152.349,4.833
               c-0.35,0.092-0.563,0.183-0.646,0.236c-0.092,0.061-0.152,0.084-0.475,0.161c-0.326,0.076-0.35,0.137-0.867,0.137
               s-0.799-0.084-1.027,0.007c-0.221,0.091-0.053,0.206-0.457,0.213c-0.402,0.016-0.473-0.023-0.639,0.061
               c-0.168,0.076-0.344,0.122-0.541,0.221c-0.205,0.099-0.381,0.091-0.42,0.168c-0.029,0.084-0.053,0.189-0.121,0.167
               c-0.068-0.023-0.373-0.167-0.311-0.008c0.051,0.16,0.356,0.092,0.15,0.244c-0.199,0.16-0.215,0.282-0.357,0.441
               c-0.146,0.16-0.214,0.168-0.289,0.229c-0.084,0.053-0.176,0.175,0,0.213c0.166,0.03,0.272,0.175,0.084,0.214
               c-0.19,0.03-0.479-0.061-0.479,0.091c0,0.145-0.038,0.259-0.122,0.289c-0.092,0.031,0.168,0.213,0.168,0.213
               s0.029,0.092-0.084,0.182c-0.105,0.084-0.402,0.062-0.436,0.191c-0.037,0.122-0.189,0.312,0.1,0.358
               c0.29,0.046,0.441-0.144,0.511,0c0.062,0.145,0.062,0.145,0.261,0.251c0.189,0.1,0.41,0.335,0.342,0.122
               c-0.069-0.213-0.426-0.381-0.237-0.373c0.192,0.008,0.45,0.122,0.442,0.236c-0.017,0.115,0.008,0.267,0.146,0.29
               s1.043,0.129,1.263,0.136c0.215,0.016,0.245-0.076,0.512-0.007c0.273,0.069,0.633,0.236,0.563,0s-0.473-0.251-0.717-0.366
               c-0.252-0.106-0.724-0.358-0.791-0.433c-0.062-0.084-0.214-0.304-0.19-0.45c0.022-0.145-0.197-0.274,0.022-0.418
               c0.229-0.145,0.366-0.123,0.396-0.221c0.031-0.107,0.092-0.091,0.223-0.327c0.136-0.237,0.631-0.298,0.652-0.518
               c0.024-0.229,0.069-0.282,0.229-0.327c0.151-0.046-0.022,0.045,0.289-0.123c0.312-0.167,0.185-0.212,0.594-0.342
               c0.42-0.122,0.32-0.168,0.632-0.168c0.313,0,1.104-0.175,1.416-0.236c0.32-0.053,1.149-0.198,1.227-0.373
               c0.084-0.167-0.456-0.342-0.586-0.388C152.653,4.78,152.349,4.833,152.349,4.833 M137.304,15.706c0,0-0.076,0.137,0.076,0.198
               c0.158,0.061,0.252,0.008,0.336,0.053c0.09,0.053,0.25,0.168,0.174,0.228c-0.068,0.054-0.061,0.092-0.152,0.054
               c-0.084-0.038-0.234-0.221-0.266-0.114c-0.039,0.106,0.021,0.175-0.115,0.236c-0.099,0.008-0.166,0.267-0.016,0.312
               c0.152,0.046,0.26,0.054,0.307,0.122c0.045,0.076,0.334,0.152,0.41,0.251c0.068,0.091,0.137,0.16-0.008,0.16
               c-0.146,0.008-0.418-0.114-0.328,0.008c0.1,0.121,0.281,0.053,0.358,0.106c0.084,0.054,0.175,0.176,0.252,0.107
               c0.067-0.069,0.091-0.168,0.183-0.176c0.092-0.007,0.266,0.039,0.176-0.167c-0.092-0.206-0.092-0.266-0.268-0.35
               c-0.175-0.076-0.373-0.221-0.373-0.381s0.114-0.289-0.021-0.373c-0.139-0.091-0.207-0.122-0.344-0.152
               c-0.146-0.023-0.176-0.122-0.229-0.144C137.396,15.661,137.304,15.706,137.304,15.706 M134.464,17.039
               c0.008,0.038,0.066,0.114,0.252,0.228c0.182,0.107,0.182,0.122,0.204,0.236c0.022,0.107,0.183,0.19,0.259,0.282
               c0.084,0.084,0.139,0.19,0.189,0.297c0.048,0.106,0.113,0.198,0.191,0.144c0.084-0.053,0.104-0.122,0.129-0.182
               c0.023-0.069,0.076-0.122,0.129-0.122c0.063,0,0.184,0.031,0.261,0.045c0.065,0.016,0.167,0.038,0.196-0.037
               c0.037-0.084-0.062-0.199,0.031-0.237c0.09-0.037,0.106-0.113,0.03-0.213c-0.084-0.106-0.237-0.19-0.313-0.243
               c-0.076-0.053-0.113-0.244-0.258-0.252c-0.139-0.015-0.222-0.091-0.313-0.145c-0.085-0.06-0.13-0.129-0.251-0.152
               c-0.122-0.023-0.122-0.167-0.26-0.06c-0.129,0.106-0.09,0.175-0.174,0.22c-0.093,0.046-0.154,0.03-0.191,0.054
               c-0.039,0.03-0.1,0.053-0.121,0.075C134.433,17.008,134.464,17.039,134.464,17.039"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M154.784,26.045c-0.053,0.023-0.1,0.045-0.15,0.045c-0.168,0.016-0.214,0.138-0.26,0.244c-0.008,0.016-0.016,0.03-0.016,0.046
               c-0.016,0.038-0.047,0.068-0.092,0.083c-0.047,0.016-0.113,0.023-0.197,0.016c-0.068-0.008-0.152-0.045-0.244-0.099
               c-0.129-0.076-0.251-0.168-0.273-0.198c-0.023-0.038-0.076-0.068-0.113-0.076c-0.016-0.008-0.039-0.008-0.053,0
               c-0.025,0-0.039,0.015-0.055,0.038c-0.008,0.022-0.016,0.053,0,0.092c0.008,0.03,0.008,0.068-0.008,0.091
               c-0.008,0.015-0.008,0.023-0.016,0.03c-0.008,0.007-0.016,0.007-0.023,0.007c-0.01,0.008-0.021,0.008-0.029,0
               c-0.029-0.014-0.053-0.068-0.076-0.121c-0.021-0.069-0.053-0.145-0.113-0.19c-0.062-0.039-0.121-0.054-0.19-0.077
               c-0.062-0.022-0.129-0.045-0.198-0.084c-0.068-0.053-0.099-0.091-0.128-0.128c-0.024-0.039-0.047-0.069-0.084-0.1
               c-0.063-0.053-0.245-0.046-0.428-0.046c-0.129,0.008-0.259,0.008-0.312-0.007c-0.069-0.023-0.106-0.061-0.14-0.099
               c-0.028-0.03-0.062-0.069-0.104-0.092c-0.084-0.045-0.145,0.008-0.229,0.077c-0.047,0.03-0.1,0.076-0.168,0.106
               c-0.077,0.046-0.152,0.046-0.221,0.031c-0.076-0.016-0.146-0.053-0.19-0.076c-0.038-0.023-0.075-0.046-0.106-0.069
               c-0.061-0.053-0.114-0.091-0.19-0.091c-0.082,0.008-0.114,0.061-0.174,0.16c-0.031,0.045-0.062,0.099-0.115,0.167
               c-0.06,0.084-0.129,0.137-0.198,0.167c-0.066,0.031-0.144,0.054-0.221,0.062c-0.145,0.03-0.145,0.083-0.152,0.167
               c0,0.023,0,0.045-0.006,0.068c-0.009,0.023-0.046,0.039-0.078,0.054c-0.066,0.023-0.144,0.053-0.188,0.152
               c-0.038,0.084-0.017,0.221,0.009,0.343c0.016,0.107,0.029,0.205,0.008,0.237c-0.039,0.029-0.07,0.044-0.107,0.052
               c-0.047,0-0.106-0.008-0.198-0.038c-0.166-0.053-0.198-0.106-0.272-0.266h-0.01c-0.007-0.023-0.021-0.039-0.035-0.054
               c-0.063-0.068-0.162-0.106-0.252-0.114c-0.1-0.008-0.191,0.015-0.236,0.038c-0.016,0.008-0.022,0.016-0.039,0.023
               c-0.053,0.053-0.029,0.129,0,0.22c0.023,0.069,0.047,0.139,0.023,0.168c-0.039,0.062-0.068,0.114-0.091,0.205
               c-0.024,0.084-0.039,0.191-0.039,0.336c0,0.205,0.069,0.221,0.138,0.236c0.03,0.007,0.053,0.007,0.061,0.053
               c0.029,0.1-0.092,0.236-0.167,0.32c-0.038,0.039-0.067,0.069-0.067,0.092c-0.008,0.038-0.016,0.099,0,0.159
               c0.008,0.046,0.029,0.092,0.067,0.122c0.046,0.046,0.106,0.053,0.159,0.061c0.048,0.008,0.093,0.015,0.121,0.046
               c0.039,0.03,0.076,0.084,0.108,0.13c0.015,0.037,0.029,0.075,0.029,0.113c-0.007,0.069,0.007,0.114,0.046,0.152
               c0.031,0.031,0.083,0.053,0.145,0.062c0.063,0.015,0.106,0.007,0.146-0.009c0.021-0.007,0.053-0.014,0.083,0
               c0.046,0.024,0.083,0.016,0.138,0.009c0.053-0.009,0.121-0.023,0.273,0.015c0.197,0.046,0.313,0.274,0.381,0.411
               c0.021,0.03,0.037,0.061,0.045,0.084c0.046,0.068,0.084,0.091,0.146,0.137c0.029,0.023,0.076,0.045,0.129,0.098
               c0.022,0.016,0.047,0.016,0.037,0.016c-0.006,0.015-0.09,0.022-0.157,0.022c-0.024,0-0.039,0.008-0.056,0.008
               c-0.037,0-0.061,0.016-0.074,0.031c-0.032,0.023-0.039,0.045-0.032,0.084c0.008,0.022,0.015,0.045,0.039,0.068
               c0.053,0.061,0.159,0.137,0.319,0.206c0.113,0.053,0.16,0.068,0.184,0.092c-0.077,0.159-0.184,0.113-0.236,0.197
               c-0.061,0.084,0.075,0.152-0.091,0.19c-0.168,0.031-0.2,0.061-0.358,0.13c-0.151,0.061-0.319-0.03-0.356,0.03
               c-0.039,0.061,0.037,0.054-0.022,0.236c-0.062,0.183-0.131,0.411-0.229,0.526c-0.099,0.114-0.274,0.137-0.222,0.313
               c0.054,0.175,0.205,0.122,0.289,0.182c0.084,0.061,0.16,0.138,0.283,0.26c0.112,0.114,0.219,0.091,0.213,0.251
               c-0.017,0.16,0.021,0.297,0.045,0.419c0.022,0.129-0.115,0.388,0.029,0.448c0.146,0.054,0.32,0.092,0.396,0.29
               c0.082,0.198,0.092,0.244,0.32,0.381c0.129,0.068,0.205,0.144,0.258,0.213c-0.037,0.03-0.061,0.099-0.09,0.16
               c-0.018,0.046-0.031,0.091-0.047,0.107c-0.016,0.015-0.029,0.03-0.055,0.052c-0.053,0.055-0.121,0.138-0.213,0.207
               c-0.068,0.053-0.1,0.045-0.16,0.03c-0.021-0.008-0.052-0.015-0.082-0.023c-0.053-0.016-0.068-0.038-0.092-0.069
               c-0.023-0.038-0.046-0.076-0.107-0.114c-0.074-0.053-0.137-0.106-0.221-0.182c-0.045-0.039-0.1-0.084-0.16-0.138
               c-0.183-0.16-0.578-0.228-0.615-0.235c-0.023-0.008-0.16-0.061-0.273-0.16c-0.047-0.039-0.039-0.069-0.032-0.092
               c0.024-0.054,0.039-0.099-0.007-0.175c-0.053-0.091-0.145-0.084-0.26-0.076c-0.067,0.007-0.151,0.014-0.221-0.008
               c-0.092-0.03-0.166-0.053-0.242-0.076c-0.076-0.015-0.146-0.022-0.223-0.008c-0.066,0.008-0.113,0.031-0.158,0.061
               c-0.07,0.031-0.131,0.069-0.238,0.045c-0.074-0.014-0.074-0.037-0.074-0.059c-0.008-0.054-0.016-0.1-0.128-0.122
               c-0.078-0.016-0.13-0.031-0.207-0.062c-0.076-0.037-0.168-0.091-0.313-0.182c-0.221-0.129-0.236-0.129-0.326-0.138
               c-0.028,0-0.078-0.008-0.152-0.015c-0.092-0.015-0.189-0.015-0.297-0.015c-0.176,0-0.365,0-0.549-0.091
               c-0.166-0.099-0.235-0.099-0.297-0.099c-0.037,0-0.076,0-0.15-0.038c-0.169-0.084-0.268-0.084-0.412-0.076h-0.16
               c-0.1,0-0.182,0.037-0.244,0.099c-0.035-0.023-0.068-0.054-0.099-0.091c-0.099-0.146-0.435-0.419-0.616-0.527
               c-0.184-0.113-0.434-0.227-0.57-0.281c-0.138-0.061-0.16-0.228-0.343-0.228c-0.176,0-0.267,0.045-0.344-0.046
               c-0.084-0.091-0.343-0.305-0.427-0.343c-0.092-0.029-0.213-0.068-0.176-0.091c0.047-0.022,0.199-0.022,0.199-0.083
               c0-0.055,0.037-0.1,0.121-0.069c0.092,0.038,0.273,0.046,0.381,0.053c0.106,0.008,0.076-0.084,0.037-0.167
               c-0.029-0.084,0.039-0.062,0.1-0.206c0.068-0.152,0.055-0.282,0.152-0.282c0.092,0,0.045-0.129,0.152-0.076
               c0.1,0.054,0.152-0.03,0.061-0.099c-0.099-0.061-0.271-0.107-0.318-0.114c-0.047-0.015-0.244-0.221-0.198-0.251
               c0.046-0.023,0.274,0.076,0.351-0.023c0.084-0.107,0.159-0.138,0.235-0.153c0.069-0.014,0.259-0.121,0.342-0.182
               c0.084-0.061,0.199-0.206,0.063-0.206c-0.131,0-0.215,0.092-0.299,0.092c-0.082,0-0.299,0.058-0.291,0.036
               c0.009-0.022,0-0.27,0.029-0.308c0.03-0.046,0.053-0.029,0.15-0.067c0.092-0.038,0.045-0.178,0.181-0.164
               c0.138,0.016,0.351,0.033,0.442,0.026c0.091-0.008,0.236-0.118,0.236-0.118s0.058-0.244,0.035-0.373
               c-0.017-0.137-0.09-0.236-0.137-0.311c-0.045-0.077,0.031-0.175,0.038-0.267c0-0.083-0.047-0.174,0.015-0.22
               c0.063-0.038,0.077-0.366,0.055-0.419c-0.022-0.046-0.185-0.233-0.26-0.233c-0.078,0-0.213,0.008-0.273,0
               c-0.053-0.015-0.262-0.124-0.32-0.131c-0.054-0.015-0.213-0.059-0.343-0.09c-0.121-0.03-0.265-0.106-0.341-0.114
               c-0.076-0.015-0.243,0.039-0.317,0c-0.075-0.038-0.247-0.305-0.331-0.328c-0.076-0.023-0.265,0.005-0.35,0.044
               c-0.084,0.037-0.219,0.041-0.318,0.033c-0.098-0.015-0.238-0.11-0.308-0.118c-0.069-0.007-0.243,0.079-0.306,0.026
               c-0.055-0.046-0.078-0.199-0.104-0.238c-0.021-0.038,0.063-0.137-0.044-0.228c-0.099-0.092-0.154-0.115-0.185-0.145
               c-0.021-0.024-0.086-0.077-0.201-0.1c-0.112-0.023-0.207-0.065-0.291-0.057c-0.084,0.008-0.153-0.072-0.185-0.118
               c-0.022-0.045-0.026-0.104-0.005-0.134c0.023-0.023,0.07-0.079,0.012-0.148c-0.056-0.069-0.146-0.106-0.207-0.213
               c-0.062-0.107-0.11-0.234-0.156-0.28c-0.045-0.045-0.155-0.079-0.248-0.094c-0.09-0.015-0.197-0.045-0.327-0.038
               c-0.129,0.016-0.165,0.028-0.188,0.051c-0.022,0.022-0.109,0.014-0.14,0.005c-0.03-0.007-0.165-0.021-0.257-0.014
               c-0.082,0.016-0.145,0.06-0.166,0.09c-0.021,0.023-0.034,0.097-0.135,0.074c-0.063-0.014-0.084-0.155-0.115-0.262
               c-0.038-0.107-0.164-0.203-0.202-0.248c-0.03-0.046-0.112-0.137-0.092-0.161c0.022-0.014,0.11,0.071,0.226,0.109
               c0.121,0.038,0.292-0.154,0.354-0.2c0.063-0.038,0.107-0.038,0.176-0.053c0.061-0.007,0.158-0.076,0.129-0.174
               c-0.021-0.1-0.045-0.237-0.139-0.268c-0.09-0.03-0.077-0.076-0.1-0.098c-0.017-0.023-0.102-0.113-0.139-0.113
               c-0.04-0.008-0.047-0.081-0.152-0.084c-0.051-0.003-0.143-0.07-0.188-0.13c-0.052-0.069-0.149-0.106-0.218-0.152
               c-0.068-0.038-0.176,0.022-0.197-0.085c-0.031-0.107-0.077-0.144-0.115-0.205s-0.221-0.168-0.221-0.252s0.074-0.197,0.029-0.259
               c-0.045-0.061-0.084-0.136-0.084-0.174c0-0.031-0.045-0.206-0.099-0.244c-0.054-0.031-0.073-0.154-0.133-0.162
               c-0.034-0.003-0.118-0.085-0.163-0.1c-0.039-0.016-0.307-0.005-0.428,0.048c-0.114,0.053-0.172,0.09-0.238,0.04
               c-0.057-0.043,0.046-0.137-0.053-0.168c-0.1-0.03-0.248-0.055-0.311-0.026c-0.068,0.032-0.119,0.065-0.144,0.042
               c-0.022-0.022-0.078-0.109-0.146-0.124c-0.068-0.007-0.105,0.019-0.185-0.034c-0.075-0.046-0.086-0.093-0.095-0.139
               c-0.014-0.054-0.125-0.276-0.188-0.329c-0.061-0.061-0.129-0.121-0.113-0.198c0.01-0.084,0.084-0.205,0.023-0.259
               c-0.055-0.061-0.259-0.121-0.251-0.213c0.015-0.091,0.099-0.198,0.092-0.282c-0.016-0.084-0.206-0.182-0.206-0.327
               c0.008-0.137-0.031-0.289-0.031-0.335c0-0.046-0.008-0.214,0.076-0.274c0.077-0.053,0.131-0.214,0.146-0.282
               c0.007-0.069-0.055-0.16-0.055-0.198s-0.205-0.182-0.053-0.228c0.151-0.046,0.113,0.084,0.235,0.091
               c0.129,0.007,0.235-0.167,0.267-0.175c0.037-0.008,0.1,0,0.19-0.03c0.092-0.023,0.146-0.122,0.221-0.084
               c0.076,0.046,0.435-0.023,0.272-0.084c-0.168-0.053-0.364-0.068-0.51-0.068c-0.138,0.007-0.158,0.015-0.229-0.099
               c-0.066-0.115-0.113-0.175-0.182-0.221c-0.069-0.053-0.094-0.023-0.176,0.015c-0.084,0.031-0.191,0.023-0.168,0
               c0.021-0.031,0.315-0.213,0.461-0.266c0.145-0.053,0.403-0.233,0.504-0.316c0.092-0.084,0.299-0.378,0.414-0.53
               c0.112-0.152,0.435-0.282,0.494-0.366c0.069-0.075,0.291-0.417,0.221-0.553c-0.06-0.145-0.41-0.276-0.494-0.322
               c-0.084-0.045-0.473-0.193-0.45-0.277c0.028-0.077,0.221-0.229,0.168-0.351c-0.056-0.114-0.045-0.255-0.151-0.293
               c-0.105-0.038-0.316-0.015-0.303-0.128c0.018-0.115,0.006-0.203-0.055-0.242c-0.063-0.045-0.276-0.155-0.215-0.285
               c0.068-0.13,0.387-0.378,0.242-0.47c-0.152-0.083-0.059-0.27-0.136-0.347c-0.075-0.084-0.448-0.381-0.485-0.441
               c-0.039-0.069-0.213-0.12-0.15-0.158c0.053-0.038,0.279-0.313,0.248-0.434c-0.021-0.115-0.116-0.307-0.17-0.36
               c-0.084-0.096-0.146-0.08-0.219-0.169c-0.039-0.046-0.297-0.027-0.365-0.05c-0.076-0.022-0.079-0.194-0.026-0.24
               c0.046-0.046,0.071-0.155,0.118-0.201c0.037-0.046,0.143-0.016,0.173-0.061c0.03-0.047,0.079-0.182,0.162-0.174
               c0.083,0.015,0.138-0.025,0.222-0.054c0.084-0.039,0.176-0.153,0.241-0.145c0.077,0.007,0.229,0.007,0.252-0.076
               c0.03-0.077,0.035-0.134,0.035-0.134l0.056-0.112c0,0,0.047,0.093,0.123,0.132c0.068,0.03,0.146-0.031,0.176-0.084
               c0.039-0.061,0.006-0.198,0.129-0.153c0.115,0.046,0.229,0.153,0.435,0.153c0.206,0.007,0.365-0.024,0.313,0.06
               c-0.062,0.091-0.42,0.008-0.436,0.099c-0.014,0.092,0.047,0.008,0.268,0.092c0.228,0.091,0.26,0.068,0.457,0.114
               c0.205,0.038,0.066-0.137,0.533,0.008c0.457,0.144,0.373-0.069,0.813,0.121c0.435,0.191,1.591,0.587,1.729,0.678
               c0.137,0.091,0.295,0.046,0.463,0.137c0.174,0.099,0.198,0.076,0.351,0.083c0.151,0.016,0.13,0.084,0.366,0.161
               c0.234,0.076,0.318,0.114,0.486,0.243c0.176,0.13,0.137,0.221,0.221,0.297c0.076,0.077,0.205,0.115,0.145,0.228
               c-0.053,0.107-0.213,0.321-0.531,0.473c-0.313,0.16-0.83,0.449-1.615,0.221c-0.775-0.229-0.808-0.107-1.256-0.244
               c-0.449-0.144-0.959-0.388-1.09-0.373c-0.121,0.007-0.363,0.007-0.556-0.077c-0.19-0.091-0.456-0.281-0.479-0.174
               c-0.015,0.099,0.115,0.083,0.305,0.251c0.191,0.167,0.367,0.061,0.504,0.244c0.145,0.175,0.099,0.136,0.418,0.274
               c0.328,0.137,0.441-0.008,0.52,0.266c0.084,0.266-0.152,0.381-0.022,0.533c0.129,0.16,0.235,0.152,0.228,0.312
               c-0.007,0.16-0.029,0.29,0.123,0.328c0.151,0.037,0.371-0.016,0.569,0.083c0.198,0.1,0.214,0.206,0.351,0.259
               c0.146,0.054,0.459,0.092,0.541,0.115c0.084,0.023,0.268,0.023,0.336-0.008c0.067-0.03,0.137-0.03,0.137-0.145
               c0-0.114-0.022-0.236-0.159-0.236c-0.138,0-0.229,0.061-0.351,0c-0.115-0.061-0.129-0.129-0.289-0.213
               c-0.152-0.084-0.344-0.183-0.268-0.236c0.075-0.046,0.206-0.031,0.229-0.114c0.016-0.084,0.054-0.145,0.184-0.13
               c0.13,0.016,0.479,0.206,0.586,0.289c0.113,0.084,0.105,0.039,0.373,0.077c0.273,0.038,0.373,0.069,0.497,0.107
               c0.12,0.037,0.508,0.075,0.601,0.053c0.092-0.023,0.204-0.076,0.061-0.19c-0.137-0.115-0.776-0.458-0.577-0.587
               c0.196-0.13,0.364-0.16,0.448-0.281c0.084-0.13,0.053-0.077,0.281-0.16c0.221-0.084,0.198-0.282,0.449-0.32
               c0.243-0.038,0.182,0.008,0.389,0.038c0.196,0.03,0.474-0.114,0.578,0.068c0.114,0.183,0.137,0.206,0.213,0.206
               c0.084,0.008,0.084-0.16,0.151-0.282c0.069-0.114,0.024-0.456-0.06-0.533c-0.084-0.068-0.436,0.068-0.436-0.152
               c0.008-0.221,0.19-0.251,0.129-0.464c-0.053-0.214-0.021-0.434-0.266-0.465c-0.252-0.024-0.617-0.168-0.412-0.19
               c0.206-0.023,0.313,0.022,0.441,0.053c0.121,0.031,0.261,0.007,0.436,0.007c0.176,0,0.434-0.083,0.631,0.061
               c0.198,0.137,0.268,0.092,0.389,0.244c0.123,0.16,0.352,0.183,0.344,0.297c-0.016,0.107-0.184,0.068-0.357,0.091
               c-0.166,0.016-0.275-0.023-0.412,0.046c-0.137,0.076-0.197,0.015-0.175,0.137s0.13,0.198,0.206,0.206
               c0.083,0.007,0.234-0.015,0.273,0.091s0.197,0.213,0.236,0.221c0.029,0.007,0.623,0.091,0.813,0.015
               c0.185-0.076,0.298-0.076,0.243-0.243c-0.061-0.176-0.129-0.343-0.036-0.374c0.097-0.038,0.181,0.039,0.312-0.045
               c0.129-0.084,0.068-0.152,0.373-0.206c0.313-0.061,0.754-0.305,0.967-0.335c0.215-0.03,0.273,0.046,0.42-0.038
               c0.144-0.084,0.234-0.168,0.281-0.076c0.045,0.084,0.092,0.16,0.176,0.137c0.09-0.023,0.121-0.16,0.037-0.213
               c-0.084-0.054-0.021-0.054,0.084-0.122c0.105-0.06,0.373-0.152,0.549-0.152c0.168-0.009,0.563-0.13,0.457,0
               c-0.107,0.137-0.52,0.152-0.397,0.236c0.114,0.091,0.222,0.114,0.131,0.183c-0.092,0.068-0.26,0.136-0.099,0.167
               c0.166,0.023,0.197-0.054,0.396,0c0.196,0.06,0.28,0.114,0.334,0.023c0.062-0.099,0.008-0.183,0.098-0.221
               c0.102-0.038,0.168-0.16,0.412-0.122c0.252,0.038,0.213-0.023,0.427,0.016c0.214,0.045,0.235,0.152,0.396,0.053
               c0.159-0.099,0.273-0.267,0.51-0.267c0.237,0,0.449-0.129,0.533-0.077c0.077,0.055,0.243,0.07,0.198,0.184
               c-0.046,0.107-0.244,0.182-0.099,0.259c0.144,0.068,0.234,0.083,0.365,0.068c0.129-0.015,0.158-0.076,0.121-0.167
               c-0.029-0.092,0.021-0.145,0.129-0.152c0.105-0.008,0.289-0.016,0.281-0.122c-0.015-0.107-0.129-0.191-0.205-0.221
               c-0.076-0.03-0.084-0.039-0.176-0.138c-0.092-0.106-0.136-0.106-0.22-0.19c-0.085-0.075-0.198-0.075-0.298-0.068
               c-0.1,0.015-0.062-0.016-0.258-0.076c-0.199-0.061-0.429-0.107-0.572-0.229c-0.146-0.114-0.327-0.274-0.105-0.236
               c0.221,0.045,0.25-0.053,0.365-0.022c0.113,0.022,0.168,0.144,0.281,0.183c0.121,0.037,0.382,0.075,0.502,0.121
               c0.121,0.045,0.221,0.107,0.197,0.175c-0.022,0.068-0.021,0.084,0.068,0.099c0.092,0.008,0.069-0.152,0.178-0.16
               c0.099-0.007,0.104,0.039,0.371,0.046c0.274,0.008,0.631,0.06,0.967,0.099c0.344,0.031,0.557,0.023,0.867,0.167
               c0.313,0.145,1.014,0.29,1.387,0.435c0.367,0.152,0.754,0.091,0.998,0.251 M144.582,10.445c0,0-0.669,0.015-0.319,0.328
               c0.344,0.311,0.434,0.395,0.687,0.311c0.243-0.076,0.875-0.449,0.571-0.547c-0.307-0.1-0.474-0.092-0.549-0.115
               C144.888,10.398,144.582,10.445,144.582,10.445z M152.349,4.833c-0.35,0.092-0.563,0.183-0.646,0.236
               c-0.092,0.061-0.152,0.084-0.475,0.161c-0.326,0.076-0.35,0.137-0.867,0.137s-0.799-0.084-1.027,0.007
               c-0.221,0.091-0.053,0.206-0.457,0.213c-0.402,0.016-0.473-0.023-0.639,0.061c-0.168,0.076-0.344,0.122-0.541,0.221
               c-0.205,0.099-0.381,0.091-0.42,0.168c-0.029,0.084-0.053,0.189-0.121,0.167c-0.068-0.023-0.373-0.167-0.311-0.008
               c0.051,0.16,0.356,0.092,0.15,0.244c-0.199,0.16-0.215,0.282-0.357,0.441c-0.146,0.16-0.214,0.168-0.289,0.229
               c-0.084,0.053-0.176,0.175,0,0.213c0.166,0.03,0.272,0.175,0.084,0.214c-0.19,0.03-0.479-0.061-0.479,0.091
               c0,0.145-0.038,0.259-0.122,0.289c-0.092,0.031,0.168,0.213,0.168,0.213s0.029,0.092-0.084,0.182
               c-0.105,0.084-0.402,0.062-0.436,0.191c-0.037,0.122-0.189,0.312,0.1,0.358c0.29,0.046,0.441-0.144,0.511,0
               c0.062,0.145,0.062,0.145,0.261,0.251c0.189,0.1,0.41,0.335,0.342,0.122c-0.069-0.213-0.426-0.381-0.237-0.373
               c0.192,0.008,0.45,0.122,0.442,0.236c-0.017,0.115,0.008,0.267,0.146,0.29s1.043,0.129,1.263,0.136
               c0.215,0.016,0.245-0.076,0.512-0.007c0.273,0.069,0.633,0.236,0.563,0s-0.473-0.251-0.717-0.366
               c-0.252-0.106-0.724-0.358-0.791-0.433c-0.062-0.084-0.214-0.304-0.19-0.45c0.022-0.145-0.197-0.274,0.022-0.418
               c0.229-0.145,0.366-0.123,0.396-0.221c0.031-0.107,0.092-0.091,0.223-0.327c0.136-0.237,0.631-0.298,0.652-0.518
               c0.024-0.229,0.069-0.282,0.229-0.327c0.151-0.046-0.022,0.045,0.289-0.123c0.312-0.167,0.185-0.212,0.594-0.342
               c0.42-0.122,0.32-0.168,0.632-0.168c0.313,0,1.104-0.175,1.416-0.236c0.32-0.053,1.149-0.198,1.227-0.373
               c0.084-0.167-0.456-0.342-0.586-0.388C152.653,4.78,152.349,4.833,152.349,4.833z M137.304,15.706c0,0-0.076,0.137,0.076,0.198
               c0.158,0.061,0.252,0.008,0.336,0.053c0.09,0.053,0.25,0.168,0.174,0.228c-0.068,0.054-0.061,0.092-0.152,0.054
               c-0.084-0.038-0.234-0.221-0.266-0.114c-0.039,0.106,0.021,0.175-0.115,0.236c-0.099,0.008-0.166,0.267-0.016,0.312
               c0.152,0.046,0.26,0.054,0.307,0.122c0.045,0.076,0.334,0.152,0.41,0.251c0.068,0.091,0.137,0.16-0.008,0.16
               c-0.146,0.008-0.418-0.114-0.328,0.008c0.1,0.121,0.281,0.053,0.358,0.106c0.084,0.054,0.175,0.176,0.252,0.107
               c0.067-0.069,0.091-0.168,0.183-0.176c0.092-0.007,0.266,0.039,0.176-0.167c-0.092-0.206-0.092-0.266-0.268-0.35
               c-0.175-0.076-0.373-0.221-0.373-0.381s0.114-0.289-0.021-0.373c-0.139-0.091-0.207-0.122-0.344-0.152
               c-0.146-0.023-0.176-0.122-0.229-0.144C137.396,15.661,137.304,15.706,137.304,15.706z M134.464,17.039
               c0.008,0.038,0.066,0.114,0.252,0.228c0.182,0.107,0.182,0.122,0.204,0.236c0.022,0.107,0.183,0.19,0.259,0.282
               c0.084,0.084,0.139,0.19,0.189,0.297c0.048,0.106,0.113,0.198,0.191,0.144c0.084-0.053,0.104-0.122,0.129-0.182
               c0.023-0.069,0.076-0.122,0.129-0.122c0.063,0,0.184,0.031,0.261,0.045c0.065,0.016,0.167,0.038,0.196-0.037
               c0.037-0.084-0.062-0.199,0.031-0.237c0.09-0.037,0.106-0.113,0.03-0.213c-0.084-0.106-0.237-0.19-0.313-0.243
               c-0.076-0.053-0.113-0.244-0.258-0.252c-0.139-0.015-0.222-0.091-0.313-0.145c-0.085-0.06-0.13-0.129-0.251-0.152
               c-0.122-0.023-0.122-0.167-0.26-0.06c-0.129,0.106-0.09,0.175-0.174,0.22c-0.093,0.046-0.154,0.03-0.191,0.054
               c-0.039,0.03-0.1,0.053-0.121,0.075C134.433,17.008,134.464,17.039,134.464,17.039z"/>
             <path fill="#808184" d="M157.313,61.581c-0.03,0.022-0.184-0.03-0.113,0.152
               c0.074,0.176,0.007,0.199,0.167,0.229c0.168,0.031,0.091,0.098,0.243,0.022c0.152-0.075,0.123-0.068,0.236-0.075
               c0.12-0.015,0.258-0.061,0.219-0.13c-0.035-0.075-0.112-0.137-0.203-0.114c-0.084,0.022-0.114-0.03-0.221-0.015
               c-0.106,0.008-0.168,0.008-0.19-0.023C157.429,61.603,157.313,61.581,157.313,61.581"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M157.313,61.581c-0.03,0.022-0.184-0.03-0.113,0.152c0.074,0.176,0.007,0.199,0.167,0.229c0.168,0.031,0.091,0.098,0.243,0.022
               c0.152-0.075,0.123-0.068,0.236-0.075c0.12-0.015,0.258-0.061,0.219-0.13c-0.035-0.075-0.112-0.137-0.203-0.114
               c-0.084,0.022-0.114-0.03-0.221-0.015c-0.106,0.008-0.168,0.008-0.19-0.023C157.429,61.603,157.313,61.581,157.313,61.581z"/>
             <path fill="#808184" d="M174.086,69.066c-0.067,0.029-0.144-0.046-0.121,0.137
               c0.015,0.176,0.104,0.199,0.069,0.313c-0.047,0.112-0.01,0.09-0.032,0.212s-0.037,0.213,0.009,0.229
               c0.045,0.015,0.159-0.084,0.137-0.235c-0.015-0.16,0-0.183,0.031-0.253c0.038-0.065,0.067-0.213,0-0.272
               C174.117,69.142,174.086,69.066,174.086,69.066 M173.95,68.357c-0.039,0.038-0.131,0.13-0.047,0.152
               c0.076,0.021,0.084-0.03,0.115,0.037c0.029,0.076,0.228-0.084,0.183-0.129c-0.048-0.045-0.132-0.039-0.167-0.022
               c-0.039,0.009-0.023-0.038-0.039-0.054C173.979,68.328,173.95,68.357,173.95,68.357 M173.592,70.839
               c0,0.029,0,0.184,0.037,0.152c0.039-0.031,0.106-0.215,0.031-0.236C173.584,70.742,173.592,70.839,173.592,70.839
                M173.562,70.443c-0.016,0.039,0.03,0.076,0.039,0.131c0.006,0.045,0.061,0.121,0.067,0.092c0-0.039-0.067-0.137-0.022-0.168
               c0.053-0.031,0.067-0.16-0.017-0.146C173.556,70.36,173.562,70.443,173.562,70.443 M173.735,71.076
               c-0.016,0.016-0.189,0.168-0.166,0.252c0.021,0.076,0.029,0.084,0.084,0.129c0.052,0.053,0.137,0.062,0.15,0.039
               c0.01-0.022-0.053-0.076-0.085-0.139c-0.036-0.066-0.036-0.145,0.011-0.175c0.045-0.022,0.045-0.062,0.099-0.039
               c0.053,0.022,0.137-0.015,0.122,0.063c-0.008,0.084,0.021,0.174,0.037,0.135c0.008-0.036,0.092-0.105,0.037-0.166
               c-0.053-0.067-0.09-0.077-0.129-0.092c-0.029-0.008-0.029-0.061-0.067-0.061S173.735,71.076,173.735,71.076 M174.019,70.08
               c-0.062,0.045-0.123,0.084-0.067,0.166c0.052,0.084,0.091,0.076,0.099,0.131c0,0.062,0.092,0.076,0.021,0.158
               c-0.061,0.084-0.105,0.176-0.105,0.26c0.008,0.082,0.076,0.146,0.092,0.105c0.014-0.045-0.031-0.15-0.008-0.197
               c0.03-0.045,0.068-0.054,0.1-0.138c0.021-0.083-0.022-0.183-0.047-0.229c-0.016-0.053-0.137-0.121-0.076-0.168
               c0.062-0.045,0.168,0.018,0.191,0.055c0.016,0.046,0.15,0.023,0.084-0.008c-0.063-0.047-0.184-0.159-0.215-0.159
               C174.049,70.046,174.019,70.08,174.019,70.08"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M174.086,69.066c-0.067,0.029-0.144-0.046-0.121,0.137c0.015,0.176,0.104,0.199,0.069,0.313c-0.047,0.112-0.01,0.09-0.032,0.212
               s-0.037,0.213,0.009,0.229c0.045,0.015,0.159-0.084,0.137-0.235c-0.015-0.16,0-0.183,0.031-0.253
               c0.038-0.065,0.067-0.213,0-0.272C174.117,69.142,174.086,69.066,174.086,69.066z M173.95,68.357
               c-0.039,0.038-0.131,0.13-0.047,0.152c0.076,0.021,0.084-0.03,0.115,0.037c0.029,0.076,0.228-0.084,0.183-0.129
               c-0.048-0.045-0.132-0.039-0.167-0.022c-0.039,0.009-0.023-0.038-0.039-0.054C173.979,68.328,173.95,68.357,173.95,68.357z
                M173.592,70.839c0,0.029,0,0.184,0.037,0.152c0.039-0.031,0.106-0.215,0.031-0.236
               C173.584,70.742,173.592,70.839,173.592,70.839z M173.562,70.443c-0.016,0.039,0.03,0.076,0.039,0.131
               c0.006,0.045,0.061,0.121,0.067,0.092c0-0.039-0.067-0.137-0.022-0.168c0.053-0.031,0.067-0.16-0.017-0.146
               C173.556,70.36,173.562,70.443,173.562,70.443z M173.735,71.076c-0.016,0.016-0.189,0.168-0.166,0.252
               c0.021,0.076,0.029,0.084,0.084,0.129c0.052,0.053,0.137,0.062,0.15,0.039c0.01-0.022-0.053-0.076-0.085-0.139
               c-0.036-0.066-0.036-0.145,0.011-0.175c0.045-0.022,0.045-0.062,0.099-0.039c0.053,0.022,0.137-0.015,0.122,0.063
               c-0.008,0.084,0.021,0.174,0.037,0.135c0.008-0.036,0.092-0.105,0.037-0.166c-0.053-0.067-0.09-0.077-0.129-0.092
               c-0.029-0.008-0.029-0.061-0.067-0.061S173.735,71.076,173.735,71.076z M174.019,70.08c-0.062,0.045-0.123,0.084-0.067,0.166
               c0.052,0.084,0.091,0.076,0.099,0.131c0,0.062,0.092,0.076,0.021,0.158c-0.061,0.084-0.105,0.176-0.105,0.26
               c0.008,0.082,0.076,0.146,0.092,0.105c0.014-0.045-0.031-0.15-0.008-0.197c0.03-0.045,0.068-0.054,0.1-0.138
               c0.021-0.083-0.022-0.183-0.047-0.229c-0.016-0.053-0.137-0.121-0.076-0.168c0.062-0.045,0.168,0.018,0.191,0.055
               c0.016,0.046,0.15,0.023,0.084-0.008c-0.063-0.047-0.184-0.159-0.215-0.159C174.049,70.046,174.019,70.08,174.019,70.08z"/>
             <path fill="#808184" d="M179.34,64.33c-0.03,0-0.274-0.068-0.19,0.083
               c0.084,0.16,0.268,0.031,0.199,0.176c-0.068,0.138-0.115,0.145-0.084,0.25c0.03,0.106,0.053,0.131,0.016,0.177
               c-0.047,0.046-0.106,0.015-0.159,0c-0.063-0.017-0.168,0-0.084,0.062c0.091,0.061,0.221,0.053,0.151,0.182
               c-0.062,0.131-0.053,0.359-0.062,0.428c0,0.068-0.114,0.045-0.106,0.189c0.017,0.146,0.055,0.235,0.068,0.328
               c0.016,0.084,0.021,0.586,0.1,0.844c0.084,0.261,0.016,0.429,0.151,0.609c0.13,0.174,0.146,0.327,0.32,0.336
               c0.183,0.016,0.259-0.138,0.418-0.162c0.159-0.015,0.327-0.104,0.472-0.15s0.307-0.26,0.328-0.495s0.031-0.396,0-0.595
               c-0.031-0.204-0.205-0.342-0.289-0.486c-0.092-0.145-0.063-0.16-0.13-0.373c-0.067-0.214-0.092-0.268-0.19-0.355
               c-0.099-0.094-0.137-0.162-0.242-0.352c-0.113-0.189-0.344-0.307-0.426-0.449c-0.093-0.146-0.023-0.184-0.093-0.236
               C179.433,64.284,179.34,64.33,179.34,64.33"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M179.34,64.33
               c-0.03,0-0.274-0.068-0.19,0.083c0.084,0.16,0.268,0.031,0.199,0.176c-0.068,0.138-0.115,0.145-0.084,0.25
               c0.03,0.106,0.053,0.131,0.016,0.177c-0.047,0.046-0.106,0.015-0.159,0c-0.063-0.017-0.168,0-0.084,0.062
               c0.091,0.061,0.221,0.053,0.151,0.182c-0.062,0.131-0.053,0.359-0.062,0.428c0,0.068-0.114,0.045-0.106,0.189
               c0.017,0.146,0.055,0.235,0.068,0.328c0.016,0.084,0.021,0.586,0.1,0.844c0.084,0.261,0.016,0.429,0.151,0.609
               c0.13,0.174,0.146,0.327,0.32,0.336c0.183,0.016,0.259-0.138,0.418-0.162c0.159-0.015,0.327-0.104,0.472-0.15
               s0.307-0.26,0.328-0.495s0.031-0.396,0-0.595c-0.031-0.204-0.205-0.342-0.289-0.486c-0.092-0.145-0.063-0.16-0.13-0.373
               c-0.067-0.214-0.092-0.268-0.19-0.355c-0.099-0.094-0.137-0.162-0.242-0.352c-0.113-0.189-0.344-0.307-0.426-0.449
               c-0.093-0.146-0.023-0.184-0.093-0.236C179.433,64.284,179.34,64.33,179.34,64.33z"/>
             <path fill="#808184" d="M189.597,60.79c-0.047,0.022-0.168,0.053-0.138,0.228
               c0.031,0.175,0.115,0.183,0.054,0.267c-0.054,0.084-0.121,0.099-0.131,0.19c0,0.092,0.063,0.251,0.077,0.304
               c0.009,0.054-0.008,0.145-0.03,0.229c-0.021,0.091-0.047,0.206-0.029,0.259c0.008,0.061-0.039,0.083-0.055,0.182
               c-0.016,0.1,0.037,0.183,0.055,0.244c0.015,0.062,0.045,0.282,0.092,0.282c0.037,0,0.105-0.137,0.099-0.267
               c-0.015-0.129-0.075-0.197-0.039-0.312c0.039-0.121,0.046-0.22,0.084-0.311c0.031-0.092,0.091-0.153,0.062-0.26
               c-0.029-0.106-0.055-0.16-0.029-0.244c0.016-0.091-0.068-0.084-0.031-0.212c0.039-0.129,0.106-0.184,0.063-0.297
               c-0.047-0.114-0.022-0.161,0-0.213c0.021-0.054,0.053-0.077,0.008-0.108C189.657,60.72,189.597,60.79,189.597,60.79
                M190.494,66.865c0.015,0.047,0.099,0.038,0.114,0.199c0.022,0.15,0.06,0.305,0.123,0.197c0.062-0.106,0.104-0.526,0.006-0.541
               c-0.09-0.021-0.327,0.053-0.327,0.099S190.494,66.865,190.494,66.865 M189.313,63.385c-0.015,0.031-0.045,0.13-0.006,0.205
               c0.037,0.077-0.016,0.222,0.066,0.176c0.076-0.046,0.146-0.099,0.115-0.275C189.451,63.318,189.313,63.385,189.313,63.385"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M189.597,60.79c-0.047,0.022-0.168,0.053-0.138,0.228c0.031,0.175,0.115,0.183,0.054,0.267c-0.054,0.084-0.121,0.099-0.131,0.19
               c0,0.092,0.063,0.251,0.077,0.304c0.009,0.054-0.008,0.145-0.03,0.229c-0.021,0.091-0.047,0.206-0.029,0.259
               c0.008,0.061-0.039,0.083-0.055,0.182c-0.016,0.1,0.037,0.183,0.055,0.244c0.015,0.062,0.045,0.282,0.092,0.282
               c0.037,0,0.105-0.137,0.099-0.267c-0.015-0.129-0.075-0.197-0.039-0.312c0.039-0.121,0.046-0.22,0.084-0.311
               c0.031-0.092,0.091-0.153,0.062-0.26c-0.029-0.106-0.055-0.16-0.029-0.244c0.016-0.091-0.068-0.084-0.031-0.212
               c0.039-0.129,0.106-0.184,0.063-0.297c-0.047-0.114-0.022-0.161,0-0.213c0.021-0.054,0.053-0.077,0.008-0.108
               C189.657,60.72,189.597,60.79,189.597,60.79z M190.494,66.865c0.015,0.047,0.099,0.038,0.114,0.199
               c0.022,0.15,0.06,0.305,0.123,0.197c0.062-0.106,0.104-0.526,0.006-0.541c-0.09-0.021-0.327,0.053-0.327,0.099
               S190.494,66.865,190.494,66.865z M189.313,63.385c-0.015,0.031-0.045,0.13-0.006,0.205c0.037,0.077-0.016,0.222,0.066,0.176
               c0.076-0.046,0.146-0.099,0.115-0.275C189.451,63.318,189.313,63.385,189.313,63.385z"/>
            
             <path fill="#808184" d="M218.864,31.103c-0.084,0.007-0.297,0-0.235,0.129
               c0.067,0.136,0.114,0.114,0.151,0.235c0.029,0.13,0.062,0.038,0.197,0.199c0.137,0.167,0.252,0.296,0.336,0.448
               c0.076,0.161,0.021,0.191,0.092,0.344c0.06,0.152,0.197,0.167,0.121,0.243c-0.084,0.083-0.1,0.183,0.008,0.274
               c0.105,0.084,0.236,0.145,0.183,0.236c-0.052,0.084-0.272,0.137-0.387,0.084c-0.115-0.046-0.39-0.297-0.421-0.175
               c-0.021,0.114,0,0.251,0.123,0.304c0.121,0.046,0.304,0.054,0.158,0.168c-0.144,0.114-0.25,0.068-0.266,0.16
               c-0.017,0.091,0.151,0.137,0.1,0.243c-0.055,0.107,0.022,0.19,0.334,0.373c0.32,0.176,0.084,0.472,0.252,0.564
               c0.168,0.084,0.313,0.213,0.326,0.069c0.008-0.153-0.045-0.366,0.069-0.389c0.104-0.023,0.427,0.023,0.343-0.076
               c-0.076-0.099-0.244-0.145-0.381-0.19c-0.137-0.046-0.189-0.015-0.336-0.122c-0.145-0.115-0.318-0.442-0.113-0.412
               c0.205,0.039,0.168,0.183,0.313,0.229c0.15,0.045,0.305-0.054,0.457-0.122c0.145-0.068,0.411-0.016,0.548,0.091
               c0.136,0.099,0.548,0.259,0.784,0.336c0.234,0.076,0.54,0.159,0.38-0.023c-0.151-0.183-0.243-0.594-0.101-0.686
               c0.146-0.091,0.252-0.19,0.43-0.159c0.182,0.038,0.316-0.031,0.316-0.031s0.215-0.281,0-0.373
               c-0.213-0.091-0.351-0.122-0.416-0.327c-0.069-0.198,0.045-0.541-0.139-0.472c-0.175,0.068-0.175,0.327-0.357,0.311
               c-0.184-0.007-0.221-0.167-0.441-0.174c-0.213-0.016-0.379,0.114-0.555-0.069c-0.184-0.183-1.061-0.609-1.221-0.708
               c-0.158-0.099-0.334-0.228-0.494-0.381L218.864,31.103z M212.919,42.15c-0.092,0.023-0.794,0.16-0.428,0.297
               c0.357,0.129,0.625,0.259,0.646-0.015C213.161,42.166,212.919,42.15,212.919,42.15 M220.144,35.107
               c-0.021,0.007-0.084,0.046-0.031,0.129c0.063,0.077,0.115,0.115,0.109,0.183c-0.01,0.061-0.047,0.107-0.078,0.122
               c-0.031,0.016-0.099,0.03-0.067,0.099c0.03,0.061-0.022,0.122,0.052,0.175c0.078,0.053,0.131,0.069,0.168,0.16
               c0.031,0.091,0.1,0.167,0.039,0.205c-0.061,0.039-0.129,0.084-0.076,0.137c0.053,0.054,0.334,0.138,0.396,0.213
               c0.053,0.069,0.035,0.237,0.084,0.396c0.046,0.167,0.074,0.289,0.105,0.441c0.029,0.153-0.121,0.191-0.061,0.335
               c0.061,0.145,0.166,0.29,0.066,0.373c-0.092,0.084-0.344,0.069-0.26,0.237c0.092,0.16,0.047,0.266-0.037,0.372
               c-0.084,0.107-0.281,0.221-0.396,0.267s-0.306,0.206-0.337,0.251c-0.021,0.038-0.15,0.016-0.204-0.075
               c-0.056-0.092-0.076-0.176-0.138-0.184c-0.054-0.014-0.045-0.144,0.022-0.197c0.076-0.046,0.175-0.153,0.084-0.198
               c-0.092-0.046-0.19,0.045-0.267,0.083c-0.084,0.038-0.153-0.007-0.1,0.191c0.045,0.198,0.268,0.205,0.184,0.434
               c-0.084,0.228-0.123,0.251-0.146,0.373c-0.021,0.114-0.166,0.091-0.083,0.259c0.083,0.175,0.214,0.259,0.062,0.343
               c-0.152,0.09-0.282,0.174-0.352,0.083c-0.068-0.092-0.197-0.221-0.326-0.145c-0.131,0.069-0.646,0.039-0.808,0.191
               c-0.153,0.152-0.206,0.152-0.306,0.091c-0.098-0.068-0.486-0.021-0.524,0c-0.03,0.015-0.03-0.015-0.069,0.053
               c-0.028,0.069-0.197,0.328-0.312,0.427c-0.122,0.099,0.046-0.069-0.122,0.19c-0.158,0.259-0.223,0.282-0.297,0.297
               c-0.083,0.007-0.223-0.062-0.223,0.091c0,0.153,0.154,0.221,0.117,0.282c-0.039,0.061-0.154,0.099-0.236,0.16
               c-0.084,0.06-0.045,0.206-0.114,0.229c-0.063,0.023-0.245,0.138-0.252,0.183c0,0.054-0.044-0.062-0.183-0.022
               c-0.137,0.037-0.207,0.091-0.26,0.167c-0.053,0.076-0.045,0.244-0.031,0.304c0.017,0.054,0.1,0.023,0.123-0.114
               c0.031-0.137,0.045-0.205,0.115-0.205c0.067,0,0.166-0.023,0.19,0.083c0.015,0.107,0.021,0.244,0.104,0.274
               c0.076,0.031,0.16-0.007,0.16,0.115c0,0.129,0.053,0.174,0.082,0.137c0.032-0.031,0.039-0.236,0.092-0.221
               c0.055,0.023,0.139-0.024,0.139,0.068c0,0.099-0.146,0.084-0.106,0.19c0.046,0.1,0.152,0.405,0.19,0.282
               c0.045-0.114,0.053-0.273,0.062-0.35c0.007-0.084-0.017-0.205-0.069-0.229c-0.053-0.023-0.267-0.084-0.234-0.16
               c0.027-0.069,0.129-0.167,0.189-0.099c0.062,0.068,0.168,0.19,0.219,0.273c0.055,0.077,0.117,0.146,0.109,0.26
               c-0.017,0.122,0.022,0.19-0.047,0.32c-0.068,0.129-0.167,0.159-0.113,0.205c0.045,0.046,0.104,0.252,0.188,0.313
               s0.176,0.084,0.092,0.167c-0.075,0.076-0.151,0.092-0.047,0.175c0.101,0.076,0.168,0.122,0.237,0.129
               c0.076,0.015,0.138,0,0.168,0.024c0.037,0.014-0.047-0.077-0.092-0.252c-0.047-0.175,0.054-0.258,0.105-0.197
               c0.053,0.052-0.021,0.174,0.038,0.258c0.062,0.076,0.168,0.214,0.122,0.266c-0.046,0.054,0.016,0.13,0.105,0.062
               c0.1-0.069,0.153-0.214,0.123-0.297c-0.039-0.084,0.061-0.061,0.1-0.122c0.037-0.054,0.099-0.069,0.045-0.221
               c-0.055-0.153-0.236-0.274-0.205-0.449c0.037-0.183,0.182-0.35,0.174-0.503c-0.014-0.152-0.076-0.259-0.128-0.304
               c-0.063-0.046,0.046-0.267-0.153-0.221c-0.197,0.046-0.297,0.046-0.258-0.061c0.045-0.115,0.159-0.281,0-0.298
               c-0.152-0.006-0.152,0.084-0.236,0.062c-0.084-0.023-0.184-0.129-0.267-0.161c-0.084-0.037-0.235-0.113-0.167-0.166
               c0.067-0.061,0.129-0.099,0.213-0.046c0.084,0.046,0.113-0.015,0.289-0.015s0.243,0.061,0.319,0.106
               c0.083,0.039,0.099,0,0.106-0.091c0.015-0.092-0.191-0.259-0.031-0.304c0.16-0.047,0.221,0.091,0.32,0.098
               c0.098,0.015,0.098-0.175,0.243-0.175s0.191,0.114,0.267,0.039c0.067-0.069,0.017-0.222,0.19-0.153
               c0.183,0.069,0.312-0.16,0.474-0.191c0.149-0.038,0.242-0.022,0.305,0c0.061,0.023,0.175,0.046,0.175,0.107
               c0,0.069-0.077,0.084-0.091,0.198c-0.01,0.106-0.046,0.206-0.131,0.183c-0.084-0.023-0.159-0.129-0.297-0.137
               c-0.129-0.007-0.305,0.053-0.342,0.083c-0.033,0.039-0.039,0.1-0.047,0.175c-0.016,0.084-0.236,0.107-0.283,0.107
               c-0.051,0-0.287-0.122-0.326-0.023c-0.039,0.092,0.031,0.221-0.039,0.313c-0.059,0.091-0.213,0.137-0.227,0.198
               c-0.017,0.053,0.014,0.152,0.092,0.114c0.076-0.038,0.135-0.099,0.174-0.046c0.039,0.046,0.045,0.098,0.093,0.214
               c0.045,0.106,0.091,0.281,0.228,0.289c0.139,0.007,0.131-0.008,0.221,0.052c0.101,0.062,0.199,0.153,0.178-0.014
               c-0.031-0.175-0.115-0.335-0.01-0.404c0.107-0.068,0.152-0.145,0.244-0.182c0.1-0.039,0.26,0.022,0.344,0.076
               c0.091,0.053,0.137,0.022,0.15-0.115c0.008-0.144,0.139-0.258,0.176-0.32c0.029-0.052-0.092-0.083-0.145-0.175
               c-0.053-0.091,0.038-0.129,0.122-0.213c0.075-0.084-0.056-0.22-0.056-0.22s-0.008-0.252,0.207-0.206s0.215,0.114,0.131,0.221
               s-0.184,0.144-0.094,0.22c0.094,0.069,0.031,0.183,0.078,0.275c0.045,0.091,0.196,0.152,0.228,0.16
               c0.021,0.015,0.106,0.235,0.267,0.243c0.16,0.007,0.213,0.076,0.282,0.031c0.067-0.039,0.136-0.176,0.104-0.32
               c-0.029-0.138,0.078-0.183,0.07-0.282c-0.009-0.091,0.084-0.091,0.143-0.168c0.063-0.083,0.123-0.136-0.006-0.213
               c-0.129-0.069-0.291-0.129-0.252-0.282c0.039-0.144,0.106-0.411,0.184-0.213c0.084,0.199,0.045,0.267,0.206,0.282
               c0.159,0.015,0.114-0.03,0.396,0.061c0.282,0.091,0.365,0.099,0.434,0.076c0.062-0.023,0.123-0.015,0.152-0.167
               c0.039-0.145,0.063-0.366,0.185-0.313c0.12,0.054,0.084,0.16,0.112,0.282c0.039,0.122,0.138,0.274,0.223,0.198
               c0.083-0.076,0.083-0.29,0.029-0.403c-0.053-0.107-0.1-0.267,0.029-0.267c0.139,0,0.152,0.023,0.197-0.091
               c0.055-0.114,0.077-0.236,0.178-0.16c0.1,0.068-0.139,0.282,0.016,0.35c0.144,0.076,0.259,0.122,0.25,0.19
               c-0.008,0.077-0.022,0.153,0.105,0.046c0.131-0.107-0.22-0.342,0.016-0.472c0.236-0.137,0.42-0.175,0.191-0.327
               c-0.221-0.16-0.52-0.259-0.525-0.473c-0.016-0.212,0.076-0.494,0.067-0.662c-0.015-0.175-0.104-0.091-0.196-0.457
               c-0.084-0.357-0.512-0.715-0.313-0.769c0.207-0.061,0.274,0.107,0.252-0.084c-0.022-0.19-0.351-0.396-0.197-0.594
               c0.158-0.205,0.222-0.304,0.022-0.563c-0.19-0.259-0.168-0.312-0.479-0.548c-0.314-0.236-0.465-0.366-0.595-0.563
               c-0.138-0.206-0.174-0.411-0.381-0.45c-0.199-0.038-0.373-0.205-0.396-0.144c-0.017,0.053-0.062,0.259,0.092,0.305
               c0.145,0.045,0.351-0.093,0.365,0.022c0.006,0.115,0.105,0.206,0,0.236c-0.106,0.031-0.152-0.007-0.229-0.015
               c-0.077-0.007-0.131-0.046-0.184-0.137c-0.047-0.092-0.054-0.19-0.131-0.152L220.144,35.107z"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M218.864,31.103c-0.084,0.007-0.297,0-0.235,0.129c0.067,0.136,0.114,0.114,0.151,0.235c0.029,0.13,0.062,0.038,0.197,0.199
               c0.137,0.167,0.252,0.296,0.336,0.448c0.076,0.161,0.021,0.191,0.092,0.344c0.06,0.152,0.197,0.167,0.121,0.243
               c-0.084,0.083-0.1,0.183,0.008,0.274c0.105,0.084,0.236,0.145,0.183,0.236c-0.052,0.084-0.272,0.137-0.387,0.084
               c-0.115-0.046-0.39-0.297-0.421-0.175c-0.021,0.114,0,0.251,0.123,0.304c0.121,0.046,0.304,0.054,0.158,0.168
               c-0.144,0.114-0.25,0.068-0.266,0.16c-0.017,0.091,0.151,0.137,0.1,0.243c-0.055,0.107,0.022,0.19,0.334,0.373
               c0.32,0.176,0.084,0.472,0.252,0.564c0.168,0.084,0.313,0.213,0.326,0.069c0.008-0.153-0.045-0.366,0.069-0.389
               c0.104-0.023,0.427,0.023,0.343-0.076c-0.076-0.099-0.244-0.145-0.381-0.19c-0.137-0.046-0.189-0.015-0.336-0.122
               c-0.145-0.115-0.318-0.442-0.113-0.412c0.205,0.039,0.168,0.183,0.313,0.229c0.15,0.045,0.305-0.054,0.457-0.122
               c0.145-0.068,0.411-0.016,0.548,0.091c0.136,0.099,0.548,0.259,0.784,0.336c0.234,0.076,0.54,0.159,0.38-0.023
               c-0.151-0.183-0.243-0.594-0.101-0.686c0.146-0.091,0.252-0.19,0.43-0.159c0.182,0.038,0.316-0.031,0.316-0.031
               s0.215-0.281,0-0.373c-0.213-0.091-0.351-0.122-0.416-0.327c-0.069-0.198,0.045-0.541-0.139-0.472
               c-0.175,0.068-0.175,0.327-0.357,0.311c-0.184-0.007-0.221-0.167-0.441-0.174c-0.213-0.016-0.379,0.114-0.555-0.069
               c-0.184-0.183-1.061-0.609-1.221-0.708c-0.158-0.099-0.334-0.228-0.494-0.381L218.864,31.103z M212.919,42.15
               c-0.092,0.023-0.794,0.16-0.428,0.297c0.357,0.129,0.625,0.259,0.646-0.015C213.161,42.166,212.919,42.15,212.919,42.15z
                M220.144,35.107c-0.021,0.007-0.084,0.046-0.031,0.129c0.063,0.077,0.115,0.115,0.109,0.183
               c-0.01,0.061-0.047,0.107-0.078,0.122c-0.031,0.016-0.099,0.03-0.067,0.099c0.03,0.061-0.022,0.122,0.052,0.175
               c0.078,0.053,0.131,0.069,0.168,0.16c0.031,0.091,0.1,0.167,0.039,0.205c-0.061,0.039-0.129,0.084-0.076,0.137
               c0.053,0.054,0.334,0.138,0.396,0.213c0.053,0.069,0.035,0.237,0.084,0.396c0.046,0.167,0.074,0.289,0.105,0.441
               c0.029,0.153-0.121,0.191-0.061,0.335c0.061,0.145,0.166,0.29,0.066,0.373c-0.092,0.084-0.344,0.069-0.26,0.237
               c0.092,0.16,0.047,0.266-0.037,0.372c-0.084,0.107-0.281,0.221-0.396,0.267s-0.306,0.206-0.337,0.251
               c-0.021,0.038-0.15,0.016-0.204-0.075c-0.056-0.092-0.076-0.176-0.138-0.184c-0.054-0.014-0.045-0.144,0.022-0.197
               c0.076-0.046,0.175-0.153,0.084-0.198c-0.092-0.046-0.19,0.045-0.267,0.083c-0.084,0.038-0.153-0.007-0.1,0.191
               c0.045,0.198,0.268,0.205,0.184,0.434c-0.084,0.228-0.123,0.251-0.146,0.373c-0.021,0.114-0.166,0.091-0.083,0.259
               c0.083,0.175,0.214,0.259,0.062,0.343c-0.152,0.09-0.282,0.174-0.352,0.083c-0.068-0.092-0.197-0.221-0.326-0.145
               c-0.131,0.069-0.646,0.039-0.808,0.191c-0.153,0.152-0.206,0.152-0.306,0.091c-0.098-0.068-0.486-0.021-0.524,0
               c-0.03,0.015-0.03-0.015-0.069,0.053c-0.028,0.069-0.197,0.328-0.312,0.427c-0.122,0.099,0.046-0.069-0.122,0.19
               c-0.158,0.259-0.223,0.282-0.297,0.297c-0.083,0.007-0.223-0.062-0.223,0.091c0,0.153,0.154,0.221,0.117,0.282
               c-0.039,0.061-0.154,0.099-0.236,0.16c-0.084,0.06-0.045,0.206-0.114,0.229c-0.063,0.023-0.245,0.138-0.252,0.183
               c0,0.054-0.044-0.062-0.183-0.022c-0.137,0.037-0.207,0.091-0.26,0.167c-0.053,0.076-0.045,0.244-0.031,0.304
               c0.017,0.054,0.1,0.023,0.123-0.114c0.031-0.137,0.045-0.205,0.115-0.205c0.067,0,0.166-0.023,0.19,0.083
               c0.015,0.107,0.021,0.244,0.104,0.274c0.076,0.031,0.16-0.007,0.16,0.115c0,0.129,0.053,0.174,0.082,0.137
               c0.032-0.031,0.039-0.236,0.092-0.221c0.055,0.023,0.139-0.024,0.139,0.068c0,0.099-0.146,0.084-0.106,0.19
               c0.046,0.1,0.152,0.405,0.19,0.282c0.045-0.114,0.053-0.273,0.062-0.35c0.007-0.084-0.017-0.205-0.069-0.229
               c-0.053-0.023-0.267-0.084-0.234-0.16c0.027-0.069,0.129-0.167,0.189-0.099c0.062,0.068,0.168,0.19,0.219,0.273
               c0.055,0.077,0.117,0.146,0.109,0.26c-0.017,0.122,0.022,0.19-0.047,0.32c-0.068,0.129-0.167,0.159-0.113,0.205
               c0.045,0.046,0.104,0.252,0.188,0.313s0.176,0.084,0.092,0.167c-0.075,0.076-0.151,0.092-0.047,0.175
               c0.101,0.076,0.168,0.122,0.237,0.129c0.076,0.015,0.138,0,0.168,0.024c0.037,0.014-0.047-0.077-0.092-0.252
               c-0.047-0.175,0.054-0.258,0.105-0.197c0.053,0.052-0.021,0.174,0.038,0.258c0.062,0.076,0.168,0.214,0.122,0.266
               c-0.046,0.054,0.016,0.13,0.105,0.062c0.1-0.069,0.153-0.214,0.123-0.297c-0.039-0.084,0.061-0.061,0.1-0.122
               c0.037-0.054,0.099-0.069,0.045-0.221c-0.055-0.153-0.236-0.274-0.205-0.449c0.037-0.183,0.182-0.35,0.174-0.503
               c-0.014-0.152-0.076-0.259-0.128-0.304c-0.063-0.046,0.046-0.267-0.153-0.221c-0.197,0.046-0.297,0.046-0.258-0.061
               c0.045-0.115,0.159-0.281,0-0.298c-0.152-0.006-0.152,0.084-0.236,0.062c-0.084-0.023-0.184-0.129-0.267-0.161
               c-0.084-0.037-0.235-0.113-0.167-0.166c0.067-0.061,0.129-0.099,0.213-0.046c0.084,0.046,0.113-0.015,0.289-0.015
               s0.243,0.061,0.319,0.106c0.083,0.039,0.099,0,0.106-0.091c0.015-0.092-0.191-0.259-0.031-0.304
               c0.16-0.047,0.221,0.091,0.32,0.098c0.098,0.015,0.098-0.175,0.243-0.175s0.191,0.114,0.267,0.039
               c0.067-0.069,0.017-0.222,0.19-0.153c0.183,0.069,0.312-0.16,0.474-0.191c0.149-0.038,0.242-0.022,0.305,0
               c0.061,0.023,0.175,0.046,0.175,0.107c0,0.069-0.077,0.084-0.091,0.198c-0.01,0.106-0.046,0.206-0.131,0.183
               c-0.084-0.023-0.159-0.129-0.297-0.137c-0.129-0.007-0.305,0.053-0.342,0.083c-0.033,0.039-0.039,0.1-0.047,0.175
               c-0.016,0.084-0.236,0.107-0.283,0.107c-0.051,0-0.287-0.122-0.326-0.023c-0.039,0.092,0.031,0.221-0.039,0.313
               c-0.059,0.091-0.213,0.137-0.227,0.198c-0.017,0.053,0.014,0.152,0.092,0.114c0.076-0.038,0.135-0.099,0.174-0.046
               c0.039,0.046,0.045,0.098,0.093,0.214c0.045,0.106,0.091,0.281,0.228,0.289c0.139,0.007,0.131-0.008,0.221,0.052
               c0.101,0.062,0.199,0.153,0.178-0.014c-0.031-0.175-0.115-0.335-0.01-0.404c0.107-0.068,0.152-0.145,0.244-0.182
               c0.1-0.039,0.26,0.022,0.344,0.076c0.091,0.053,0.137,0.022,0.15-0.115c0.008-0.144,0.139-0.258,0.176-0.32
               c0.029-0.052-0.092-0.083-0.145-0.175c-0.053-0.091,0.038-0.129,0.122-0.213c0.075-0.084-0.056-0.22-0.056-0.22
               s-0.008-0.252,0.207-0.206s0.215,0.114,0.131,0.221s-0.184,0.144-0.094,0.22c0.094,0.069,0.031,0.183,0.078,0.275
               c0.045,0.091,0.196,0.152,0.228,0.16c0.021,0.015,0.106,0.235,0.267,0.243c0.16,0.007,0.213,0.076,0.282,0.031
               c0.067-0.039,0.136-0.176,0.104-0.32c-0.029-0.138,0.078-0.183,0.07-0.282c-0.009-0.091,0.084-0.091,0.143-0.168
               c0.063-0.083,0.123-0.136-0.006-0.213c-0.129-0.069-0.291-0.129-0.252-0.282c0.039-0.144,0.106-0.411,0.184-0.213
               c0.084,0.199,0.045,0.267,0.206,0.282c0.159,0.015,0.114-0.03,0.396,0.061c0.282,0.091,0.365,0.099,0.434,0.076
               c0.062-0.023,0.123-0.015,0.152-0.167c0.039-0.145,0.063-0.366,0.185-0.313c0.12,0.054,0.084,0.16,0.112,0.282
               c0.039,0.122,0.138,0.274,0.223,0.198c0.083-0.076,0.083-0.29,0.029-0.403c-0.053-0.107-0.1-0.267,0.029-0.267
               c0.139,0,0.152,0.023,0.197-0.091c0.055-0.114,0.077-0.236,0.178-0.16c0.1,0.068-0.139,0.282,0.016,0.35
               c0.144,0.076,0.259,0.122,0.25,0.19c-0.008,0.077-0.022,0.153,0.105,0.046c0.131-0.107-0.22-0.342,0.016-0.472
               c0.236-0.137,0.42-0.175,0.191-0.327c-0.221-0.16-0.52-0.259-0.525-0.473c-0.016-0.212,0.076-0.494,0.067-0.662
               c-0.015-0.175-0.104-0.091-0.196-0.457c-0.084-0.357-0.512-0.715-0.313-0.769c0.207-0.061,0.274,0.107,0.252-0.084
               c-0.022-0.19-0.351-0.396-0.197-0.594c0.158-0.205,0.222-0.304,0.022-0.563c-0.19-0.259-0.168-0.312-0.479-0.548
               c-0.314-0.236-0.465-0.366-0.595-0.563c-0.138-0.206-0.174-0.411-0.381-0.45c-0.199-0.038-0.373-0.205-0.396-0.144
               c-0.017,0.053-0.062,0.259,0.092,0.305c0.145,0.045,0.351-0.093,0.365,0.022c0.006,0.115,0.105,0.206,0,0.236
               c-0.106,0.031-0.152-0.007-0.229-0.015c-0.077-0.007-0.131-0.046-0.184-0.137c-0.047-0.092-0.054-0.19-0.131-0.152
               L220.144,35.107z"/>
             <path fill="#808184" d="M220.06,37.787c-0.037,0.107-0.099,0.198-0.031,0.274
               c0.078,0.076,0.115,0.015,0.109,0.107c-0.018,0.083,0.059,0.221,0.112,0.129c0.054-0.092,0.19-0.251,0.054-0.274
               c-0.145-0.023-0.138-0.038-0.129-0.136c0.016-0.108,0.037-0.237-0.023-0.222C220.099,37.68,220.06,37.787,220.06,37.787"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M220.06,37.787c-0.037,0.107-0.099,0.198-0.031,0.274c0.078,0.076,0.115,0.015,0.109,0.107
               c-0.018,0.083,0.059,0.221,0.112,0.129c0.054-0.092,0.19-0.251,0.054-0.274c-0.145-0.023-0.138-0.038-0.129-0.136
               c0.016-0.108,0.037-0.237-0.023-0.222C220.099,37.68,220.06,37.787,220.06,37.787z"/>
        
        
        
               
        </a>       
        <!-- fin asie   -->
        
        
        
        
        <path fill="#808082" d="M197.696,67.816l0.055,0.023c0.084,0.037,0.251,0.381,0.396,0.51
        c0.146,0.138,0.351,0.281,0.427,0.457c0.083,0.168,0.235,0.389,0.251,0.549c0.008,0.16-0.184,0.221-0.159,0.479
        c0.022,0.26,0.138,0.436,0.159,0.543c0.021,0.113-0.07,0.351-0.07,0.401c0,0.063,0.139,0.183,0.238,0.298
        c0.099,0.113,0.281,0.1,0.289,0.381c0.014,0.281,0.121,0.281,0.189,0.449s0.229,0.35-0.021,0.357
        c-0.245,0.016-0.389,0-0.504,0.045c-0.113,0.046-0.205-0.084-0.28-0.221c-0.077-0.137-0.396-0.137-0.608-0.319
        c-0.213-0.175-0.428-0.39-0.609-0.519c-0.176-0.121-0.473-0.174-0.473-0.356c0-0.177,0.022-0.373-0.106-0.524
        c-0.138-0.16-0.396-0.328-0.366-0.473c0.039-0.146-0.145-0.169-0.166-0.344c-0.023-0.168,0.184-0.221,0.044-0.434
        c-0.136-0.213-0.098-0.441-0.188-0.662c-0.092-0.229-0.068-0.42-0.146-0.603c-0.03-0.062-0.075-0.146-0.129-0.228
        c0.015-0.031,0.038-0.069,0.062-0.115c0.037-0.084,0.076-0.16,0.113-0.19c0.016-0.008,0.022,0,0.045,0.046
        c0.039,0.092,0.068,0.152,0.1,0.19c0.047,0.044,0.093,0.053,0.152,0.038c0.022-0.016,0.068-0.008,0.113,0.006
        c0.055,0.025,0.092,0.047,0.102,0.063c0.008,0-0.01,0-0.01,0.008c-0.037,0.045-0.074,0.1,0.031,0.189
        c0.038,0.031,0.038,0.077,0.038,0.122c-0.008,0.046-0.008,0.099,0.007,0.168c0.023,0.062,0.047,0.099,0.077,0.13
        c0.021,0.021,0.046,0.038,0.075,0.045c0.029,0,0.061,0,0.085-0.017c0.022-0.015,0.045-0.035,0.06-0.06
        c0.008-0.023,0.023-0.054,0.031-0.084c0.016-0.039,0.029-0.076,0.046-0.1c0.015-0.016,0.03-0.023,0.062,0
        c0.045,0.038,0.068,0.075,0.092,0.105c0.007,0.018,0.016,0.031,0.021,0.039c0.047,0.037,0.076,0.037,0.139-0.021
        c0.045-0.039,0.068-0.085,0.091-0.123c0.015-0.038,0.038-0.076,0.075-0.1C197.562,67.946,197.629,67.876,197.696,67.816
         M203.94,71.707c0,0.039,0.021,0.344,0.176,0.512c0.158,0.16,0.381,0.365,0.465,0.41c0.074,0.047,0.129,0.086,0.205,0.047
        c0.076-0.031,0.357-0.152,0.441-0.152c0.09,0,0.272,0.023,0.396,0c0.121-0.031,0.268-0.061,0.268-0.129s0.006-0.26,0.183-0.307
        c0.167-0.053,0.381-0.09,0.44-0.084c0.055,0.018,0.114,0.084,0.159,0.123c0.037,0.031,0.222,0.084,0.289,0.115
        c0.069,0.021,0.106,0.098,0.229,0.105c0.115,0.015,0.183-0.092,0.228-0.123c0.047-0.029,0.215-0.066,0.313-0.09
        c0.1-0.023,0.213,0,0.235-0.084c0.023-0.084,0.009-0.19,0.054-0.229c0.045-0.037,0.092-0.068,0.092-0.183
        c0-0.106-0.047-0.274,0.076-0.364c0.121-0.086,0.235-0.107,0.205-0.199c-0.022-0.084-0.101-0.205,0.017-0.266
        c0.114-0.063,0.174-0.068,0.213-0.139c0.03-0.076,0.076-0.389,0.069-0.57c0-0.184,0-0.396,0.035-0.441
        c0.031-0.054,0.168-0.121,0.283-0.168c0.113-0.045,0.493-0.1,0.661-0.068c0.168,0.023,0.336,0.023,0.474,0.062
        c0.138,0.046,0.373,0.282,0.42,0.274c0.045-0.008,0.129-0.115,0.084-0.159c-0.039-0.047,0.035-0.115,0.104-0.101
        c0.077,0.008,0.321-0.022,0.457-0.037c0.131-0.016,0.428,0.061,0.29-0.055c-0.138-0.113-0.351-0.229-0.448-0.222
        c-0.099,0-0.381-0.242-0.145-0.304c0.242-0.055,0.494,0.008,0.632-0.084c0.144-0.098,0.252-0.197,0.091-0.281
        c-0.16-0.076-0.486-0.365-0.646-0.365c-0.159-0.009-0.357,0.176-0.326,0.021c0.037-0.145,0.197-0.266,0.029-0.266
        s-0.319,0.1-0.328-0.054c-0.008-0.153,0.16-0.328,0.016-0.335c-0.137-0.008-0.411-0.327-0.447-0.419
        c-0.031-0.101-0.115-0.022-0.281,0.046c-0.168,0.068-0.299,0.1-0.358,0.251c-0.062,0.151-0.084,0.229-0.183,0.502
        c-0.106,0.274-0.213,0.449-0.306,0.466c-0.091,0.014-0.42-0.017-0.364,0.176c0.062,0.196,0.26,0.258,0.112,0.342
        c-0.146,0.076-0.075,0.259-0.062,0.404c0.016,0.137-0.008,0.205-0.113,0.137c-0.1-0.076-0.206-0.252-0.234-0.131
        c-0.023,0.115,0.053,0.375-0.047,0.42c-0.092,0.045-0.197-0.055-0.267-0.113c-0.07-0.068-0.138-0.244-0.396-0.176
        c-0.261,0.068-0.207,0.318-0.328,0.455c-0.115,0.138-0.235,0.512-0.365,0.596c-0.129,0.091-0.411,0.236-0.687,0.281
        c-0.282,0.045-0.708-0.022-0.769,0.229c-0.053,0.243-0.068,0.243-0.053,0.428c0.021,0.182-0.078,0.205-0.123,0.379
        c-0.045,0.178-0.114,0.32-0.242,0.283c-0.131-0.039-0.161-0.396-0.381-0.275c-0.23,0.123-0.344,0.177-0.381,0.123
        C204.063,71.86,203.94,71.707,203.94,71.707"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M197.696,67.816l0.055,0.023c0.084,0.037,0.251,0.381,0.396,0.51c0.146,0.138,0.351,0.281,0.427,0.457
        c0.083,0.168,0.235,0.389,0.251,0.549c0.008,0.16-0.184,0.221-0.159,0.479c0.022,0.26,0.138,0.436,0.159,0.543
        c0.021,0.113-0.07,0.351-0.07,0.401c0,0.063,0.139,0.183,0.238,0.298c0.099,0.113,0.281,0.1,0.289,0.381
        c0.014,0.281,0.121,0.281,0.189,0.449s0.229,0.35-0.021,0.357c-0.245,0.016-0.389,0-0.504,0.045
        c-0.113,0.046-0.205-0.084-0.28-0.221c-0.077-0.137-0.396-0.137-0.608-0.319c-0.213-0.175-0.428-0.39-0.609-0.519
        c-0.176-0.121-0.473-0.174-0.473-0.356c0-0.177,0.022-0.373-0.106-0.524c-0.138-0.16-0.396-0.328-0.366-0.473
        c0.039-0.146-0.145-0.169-0.166-0.344c-0.023-0.168,0.184-0.221,0.044-0.434c-0.136-0.213-0.098-0.441-0.188-0.662
        c-0.092-0.229-0.068-0.42-0.146-0.603c-0.03-0.062-0.075-0.146-0.129-0.228c0.015-0.031,0.038-0.069,0.062-0.115
        c0.037-0.084,0.076-0.16,0.113-0.19c0.016-0.008,0.022,0,0.045,0.046c0.039,0.092,0.068,0.152,0.1,0.19
        c0.047,0.044,0.093,0.053,0.152,0.038c0.022-0.016,0.068-0.008,0.113,0.006c0.055,0.025,0.092,0.047,0.102,0.063
        c0.008,0-0.01,0-0.01,0.008c-0.037,0.045-0.074,0.1,0.031,0.189c0.038,0.031,0.038,0.077,0.038,0.122
        c-0.008,0.046-0.008,0.099,0.007,0.168c0.023,0.062,0.047,0.099,0.077,0.13c0.021,0.021,0.046,0.038,0.075,0.045
        c0.029,0,0.061,0,0.085-0.017c0.022-0.015,0.045-0.035,0.06-0.06c0.008-0.023,0.023-0.054,0.031-0.084
        c0.016-0.039,0.029-0.076,0.046-0.1c0.015-0.016,0.03-0.023,0.062,0c0.045,0.038,0.068,0.075,0.092,0.105
        c0.007,0.018,0.016,0.031,0.021,0.039c0.047,0.037,0.076,0.037,0.139-0.021c0.045-0.039,0.068-0.085,0.091-0.123
        c0.015-0.038,0.038-0.076,0.075-0.1C197.562,67.946,197.629,67.876,197.696,67.816z M203.94,71.707
        c0,0.039,0.021,0.344,0.176,0.512c0.158,0.16,0.381,0.365,0.465,0.41c0.074,0.047,0.129,0.086,0.205,0.047
        c0.076-0.031,0.357-0.152,0.441-0.152c0.09,0,0.272,0.023,0.396,0c0.121-0.031,0.268-0.061,0.268-0.129s0.006-0.26,0.183-0.307
        c0.167-0.053,0.381-0.09,0.44-0.084c0.055,0.018,0.114,0.084,0.159,0.123c0.037,0.031,0.222,0.084,0.289,0.115
        c0.069,0.021,0.106,0.098,0.229,0.105c0.115,0.015,0.183-0.092,0.228-0.123c0.047-0.029,0.215-0.066,0.313-0.09
        c0.1-0.023,0.213,0,0.235-0.084c0.023-0.084,0.009-0.19,0.054-0.229c0.045-0.037,0.092-0.068,0.092-0.183
        c0-0.106-0.047-0.274,0.076-0.364c0.121-0.086,0.235-0.107,0.205-0.199c-0.022-0.084-0.101-0.205,0.017-0.266
        c0.114-0.063,0.174-0.068,0.213-0.139c0.03-0.076,0.076-0.389,0.069-0.57c0-0.184,0-0.396,0.035-0.441
        c0.031-0.054,0.168-0.121,0.283-0.168c0.113-0.045,0.493-0.1,0.661-0.068c0.168,0.023,0.336,0.023,0.474,0.062
        c0.138,0.046,0.373,0.282,0.42,0.274c0.045-0.008,0.129-0.115,0.084-0.159c-0.039-0.047,0.035-0.115,0.104-0.101
        c0.077,0.008,0.321-0.022,0.457-0.037c0.131-0.016,0.428,0.061,0.29-0.055c-0.138-0.113-0.351-0.229-0.448-0.222
        c-0.099,0-0.381-0.242-0.145-0.304c0.242-0.055,0.494,0.008,0.632-0.084c0.144-0.098,0.252-0.197,0.091-0.281
        c-0.16-0.076-0.486-0.365-0.646-0.365c-0.159-0.009-0.357,0.176-0.326,0.021c0.037-0.145,0.197-0.266,0.029-0.266
        s-0.319,0.1-0.328-0.054c-0.008-0.153,0.16-0.328,0.016-0.335c-0.137-0.008-0.411-0.327-0.447-0.419
        c-0.031-0.101-0.115-0.022-0.281,0.046c-0.168,0.068-0.299,0.1-0.358,0.251c-0.062,0.151-0.084,0.229-0.183,0.502
        c-0.106,0.274-0.213,0.449-0.306,0.466c-0.091,0.014-0.42-0.017-0.364,0.176c0.062,0.196,0.26,0.258,0.112,0.342
        c-0.146,0.076-0.075,0.259-0.062,0.404c0.016,0.137-0.008,0.205-0.113,0.137c-0.1-0.076-0.206-0.252-0.234-0.131
        c-0.023,0.115,0.053,0.375-0.047,0.42c-0.092,0.045-0.197-0.055-0.267-0.113c-0.07-0.068-0.138-0.244-0.396-0.176
        c-0.261,0.068-0.207,0.318-0.328,0.455c-0.115,0.138-0.235,0.512-0.365,0.596c-0.129,0.091-0.411,0.236-0.687,0.281
        c-0.282,0.045-0.708-0.022-0.769,0.229c-0.053,0.243-0.068,0.243-0.053,0.428c0.021,0.182-0.078,0.205-0.123,0.379
        c-0.045,0.178-0.114,0.32-0.242,0.283c-0.131-0.039-0.161-0.396-0.381-0.275c-0.23,0.123-0.344,0.177-0.381,0.123
        C204.063,71.86,203.94,71.707,203.94,71.707z"/>
        
        
        
        
        
        
        
        <path fill="#808184" d="M211.046,50.007c0,0-0.138-0.007-0.215,0.084
        c-0.076,0.091-0.082,0.114-0.114,0.244c-0.036,0.129-0.045,0.19-0.129,0.312c-0.075,0.121-0.188,0.244-0.188,0.335
        c0,0.091,0.029,0.297-0.039,0.411c-0.069,0.114-0.137,0.145-0.075,0.304c0.067,0.16,0.067,0.26,0.106,0.366
        c0.028,0.107,0.176,0.236,0.221,0.312c0.046,0.084,0.113,0.198,0.166,0.236c0.062,0.038,0.152,0.045,0.185,0.122
        c0.029,0.069,0.06,0.153,0.099,0.236c0.037,0.077,0.037,0.129,0.129,0.092c0.084-0.032,0.129-0.168,0.016-0.259
        c-0.121-0.099-0.175-0.084-0.113-0.198c0.061-0.122,0.129-0.16,0.159-0.267c0.038-0.107,0.177-0.175,0.19-0.305
        c0.008-0.129-0.023-0.075-0.016-0.311c0.016-0.236-0.023-0.465,0.022-0.58c0.038-0.121,0.022-0.227,0.015-0.342
        c0-0.114,0.168-0.16,0.092-0.289c-0.084-0.13-0.176-0.137-0.129-0.244c0.053-0.098,0.168-0.213,0.053-0.213
        c-0.121-0.007-0.189-0.015-0.221-0.053c-0.029-0.038-0.029-0.114-0.092-0.114C211.099,49.886,211.046,50.007,211.046,50.007"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M211.046,50.007c0,0-0.138-0.007-0.215,0.084c-0.076,0.091-0.082,0.114-0.114,0.244c-0.036,0.129-0.045,0.19-0.129,0.312
        c-0.075,0.121-0.188,0.244-0.188,0.335c0,0.091,0.029,0.297-0.039,0.411c-0.069,0.114-0.137,0.145-0.075,0.304
        c0.067,0.16,0.067,0.26,0.106,0.366c0.028,0.107,0.176,0.236,0.221,0.312c0.046,0.084,0.113,0.198,0.166,0.236
        c0.062,0.038,0.152,0.045,0.185,0.122c0.029,0.069,0.06,0.153,0.099,0.236c0.037,0.077,0.037,0.129,0.129,0.092
        c0.084-0.032,0.129-0.168,0.016-0.259c-0.121-0.099-0.175-0.084-0.113-0.198c0.061-0.122,0.129-0.16,0.159-0.267
        c0.038-0.107,0.177-0.175,0.19-0.305c0.008-0.129-0.023-0.075-0.016-0.311c0.016-0.236-0.023-0.465,0.022-0.58
        c0.038-0.121,0.022-0.227,0.015-0.342c0-0.114,0.168-0.16,0.092-0.289c-0.084-0.13-0.176-0.137-0.129-0.244
        c0.053-0.098,0.168-0.213,0.053-0.213c-0.121-0.007-0.189-0.015-0.221-0.053c-0.029-0.038-0.029-0.114-0.092-0.114
        C211.099,49.886,211.046,50.007,211.046,50.007z"/>
        <path fill="#808184" d="M211.692,56.137c-0.123,0.039-0.352,0.076-0.215,0.419
        c0.139,0.335,0.1,0.35,0.055,0.479c-0.039,0.138-0.063,0.206,0,0.511c0.066,0.305-0.17,0.357,0,0.594
        c0.176,0.235,0.365,0.357,0.129,0.357c-0.235,0-0.26-0.122-0.373-0.167c-0.113-0.046-0.403-0.023-0.213,0.191
        c0.189,0.212,0.336,0.274,0.313,0.494c-0.023,0.228-0.121,0.236,0.105,0.358c0.222,0.13,0.236,0.236,0.26,0.366
        c0.016,0.121,0.084,0.236,0.176,0.258c0.092,0.024-0.023-0.304,0.146-0.281c0.174,0.023,0.129,0.053,0.19,0.175
        c0.066,0.13-0.154,0.274-0.168,0.365c-0.008,0.092-0.131,0.312,0.066,0.365c0.207,0.062,0.186-0.258,0.275-0.029
        c0.082,0.228,0.145,0.327,0.234,0.266c0.084-0.054,0.176-0.175,0.282-0.244c0.099-0.068,0.151-0.122,0.26-0.046
        c0.099,0.076,0.233,0.016,0.312,0.183c0.076,0.168,0.184,0.221,0.236,0.327c0.053,0.099,0.022,0.221,0.075,0.168
        c0.062-0.061,0.084-0.19,0.039-0.305c-0.047-0.114-0.229-0.373-0.168-0.381c0.054-0.014,0.175,0.099,0.267,0.214
        c0.092,0.107,0.282,0.266,0.352,0.266c0.066,0,0.136-0.023,0.145,0.115c0.008,0.137,0.029,0.183,0.146,0.236
        c0.112,0.053,0.28,0.007,0.334,0.091c0.062,0.076-0.009,0.312,0.091,0.343c0.107,0.038,0.191-0.076,0.152-0.29
        c-0.045-0.213-0.014-0.213-0.182-0.305c-0.169-0.09-0.063-0.143-0.189-0.236c-0.139-0.09-0.297-0.167-0.086-0.167
        c0.214,0,0.305,0.015,0.396,0.046c0.092,0.031,0.299-0.274,0.139-0.396c-0.162-0.122-0.23-0.129-0.252,0
        c-0.023,0.137-0.047,0.182-0.146,0.16c-0.1-0.023-0.234-0.183-0.355-0.206c-0.123-0.023-0.19-0.007-0.207,0.115
        c-0.008,0.121-0.053,0.327-0.146,0.137c-0.091-0.191-0.046-0.397-0.167-0.442c-0.121-0.046-0.114-0.22-0.327-0.076
        c-0.214,0.145-0.138,0.221-0.289,0.213c-0.159-0.015-0.397-0.068-0.419-0.191c-0.023-0.121,0.092-0.517,0.016-0.631
        c-0.084-0.115-0.306-0.214-0.326-0.313c-0.024-0.106,0.099-0.296,0.099-0.403c0-0.114,0.259-0.297,0.312-0.373
        c0.062-0.076,0.252-0.358,0.205-0.594c-0.045-0.236-0.145-0.388-0.258-0.586c-0.115-0.206-0.123-0.495-0.123-0.64
        c0-0.153,0.047-0.678-0.136-0.442c-0.183,0.237-0.235,0.305-0.42,0.183c-0.175-0.128-0.312-0.327-0.427-0.342
        C211.792,56.037,211.692,56.137,211.692,56.137 M212.835,63.377c0.007,0.084,0.113,0.198,0.129,0.107
        c0.008-0.093-0.008-0.206-0.063-0.198C212.851,63.293,212.835,63.377,212.835,63.377 M212.778,63.088
        c-0.006,0.022,0.041,0.062,0.063,0.084c0.03,0.016,0.114-0.13,0.046-0.184c-0.068-0.029-0.166-0.015-0.176,0.031
        C212.704,63.066,212.778,63.088,212.778,63.088 M212.628,62.798c0,0-0.09,0.069-0.083,0.153c0,0.068,0.206-0.206,0.177-0.214
        C212.683,62.738,212.628,62.798,212.628,62.798 M211.7,62.046c0,0,0.008,0.106,0.06,0.159c0.054,0.054-0.007,0.137-0.007,0.214
        c0,0.076,0.046,0.273,0.105,0.258c0.063-0.016-0.039-0.19,0.063-0.251c0.105-0.061,0.268,0.069,0.268-0.084
        c0-0.152-0.1-0.258-0.168-0.25c-0.068,0.015-0.138-0.1-0.184-0.13C211.792,61.924,211.7,62.046,211.7,62.046 M209.622,65.99
        c0.006,0.053,0.016,0.357,0.09,0.297c0.084-0.053,0.252-0.365,0.13-0.389C209.712,65.869,209.622,65.99,209.622,65.99
         M211.448,62.868c-0.037,0.045-0.106,0.19-0.106,0.342c0,0.153,0.106,0.062,0.046,0.275c-0.068,0.221-0.046,0.213-0.107,0.281
        c-0.051,0.077-0.104-0.046-0.188,0.152s-0.092,0.297-0.267,0.479c-0.167,0.185-0.167,0.282-0.243,0.381
        c-0.077,0.106-0.297,0.146-0.345,0.22c-0.045,0.078-0.228,0.168-0.28,0.328c-0.054,0.161-0.175,0.291-0.138,0.352
        c0.031,0.066,0.185,0.13,0.267-0.008c0.084-0.137,0.069-0.223,0.138-0.252c0.067-0.039,0.168-0.092,0.235-0.183
        c0.069-0.092,0.046-0.282,0.123-0.29c0.076,0,0.112,0.084,0.228-0.007c0.123-0.092,0.19-0.29,0.205-0.328
        c0.017-0.038,0.008-0.319,0.116-0.365c0.104-0.045,0.158-0.03,0.212-0.076c0.047-0.053,0.106-0.13,0.121-0.267
        c0.016-0.137,0.184-0.007,0.245-0.183c0.067-0.167,0.037-0.183-0.039-0.32c-0.084-0.129-0.137-0.212-0.122-0.304
        c0.016-0.1,0.063-0.236,0.03-0.251C211.54,62.822,211.448,62.868,211.448,62.868 M213.839,67.307
        c-0.008,0.007-0.053,0.053-0.053,0.075c0,0.021,0.021,0.066,0.037,0.115c0.016,0.045,0.055,0.09,0.105,0.082
        c0.055-0.006,0.106-0.016,0.139-0.076c0.029-0.055,0.008-0.15-0.031-0.174c-0.029-0.031-0.082-0.078-0.129-0.068
        C213.854,67.277,213.839,67.307,213.839,67.307 M213.207,67.816c-0.046,0-0.351,0.13-0.222,0.176
        c0.131,0.053,0.465-0.068,0.389-0.13C213.29,67.802,213.207,67.816,213.207,67.816 M217.007,64.163
        c-0.016,0.016-0.063,0.106-0.068,0.174c-0.008,0.062-0.039,0.138,0.045,0.152c0.084,0.022,0.154,0,0.162-0.055
        c0-0.045-0.117-0.092-0.084-0.151c0.029-0.068,0.06-0.183,0.021-0.19C217.054,64.093,217.007,64.163,217.007,64.163
         M216.603,63.804c-0.082,0.061-0.099,0.167-0.067,0.274c0.022,0.106,0.151,0.114,0.112,0.205
        c-0.035,0.099-0.09,0.106-0.135,0.136c-0.039,0.031-0.016,0.106,0.016,0.244c0.029,0.129,0.068,0.176,0.084,0.281
        c0.015,0.107,0,0.198-0.077,0.198c-0.069,0-0.168-0.129-0.175-0.037c-0.016,0.099-0.03,0.158-0.1,0.144
        c-0.076-0.021-0.121-0.09-0.16-0.09c-0.037,0-0.062-0.306-0.166-0.215c-0.106,0.1,0.006,0.215,0.061,0.344
        c0.045,0.139,0.045,0.364-0.046,0.305c-0.099-0.068-0.222-0.221-0.229-0.092c0,0.138-0.113,0.16-0.129,0.281
        c-0.021,0.114-0.105,0.176-0.189,0.176c-0.084,0.008-0.045,0.037-0.113-0.199c-0.063-0.234-0.152-0.317-0.273-0.303
        c-0.129,0.015-0.121,0.015-0.283,0.114c-0.151,0.104-0.188,0-0.174,0.166c0.014,0.169,0.021,0.198-0.063,0.214
        c-0.075,0.008-0.229,0.09-0.266,0.102c-0.039,0.014-0.273-0.109-0.32,0.119c-0.053,0.221,0.122,0.389,0.055,0.473
        c-0.068,0.091-0.131,0.175-0.131,0.213c0,0.046-0.045,0.13,0.023,0.189c0.074,0.055,0.106,0.199,0.175,0.115
        c0.067-0.084,0.114-0.19,0.114-0.318c0-0.123,0.076-0.186,0.092-0.26c0.023-0.068,0.06-0.328,0.174-0.252
        c0.107,0.076-0.15,0.242,0.018,0.252c0.166,0,0.204-0.222,0.288-0.131c0.085,0.1,0.251,0.152,0.281,0.152
        c0.038,0.008,0.106,0.046,0.106-0.068c0-0.121,0.115-0.318,0.16-0.281c0.045,0.047,0.037,0.197,0.151,0.205
        c0.106,0.008,0.282,0.016,0.282,0.113c0,0.1-0.045,0.176-0.1,0.282c-0.054,0.114-0.113,0.244-0.022,0.373
        c0.099,0.123-0.007,0.229,0.015,0.289c0.023,0.062,0.358,0.381,0.479,0.458c0.123,0.066,0.207,0.137,0.336,0.105
        c0.138-0.039,0.061-0.305,0.191-0.189c0.129,0.12-0.039,0.234,0,0.305c0.036,0.067,0.066,0.213,0.15,0.168
        c0.092-0.039,0.077-0.13,0.123-0.244c0.045-0.123,0.259-0.236,0.156-0.336c-0.09-0.105-0.111-0.091-0.202-0.205
        c-0.093-0.113-0.13-0.313-0.093-0.388c0.031-0.077,0.113-0.243,0.121-0.29c0.008-0.053,0.024-0.229,0.153-0.175
        c0.13,0.045,0.046,0.213,0.104,0.289c0.063,0.084,0.177,0.062,0.154,0.221c-0.022,0.16,0.045,0.207,0.045,0.289
        c0,0.093,0.022,0.19,0.099,0.115c0.077-0.067,0.009-0.222,0.009-0.345c0-0.121,0.029-0.196,0.068-0.233
        c0.029-0.039,0.121-0.183,0.121-0.237c0.009-0.054,0.093-0.373,0-0.473c-0.082-0.1-0.189-0.007-0.151-0.159
        c0.03-0.153-0.054-0.366-0.067-0.457c-0.017-0.092,0.112-0.228-0.031-0.228c-0.146,0-0.26-0.039-0.176-0.114
        c0.076-0.085,0.236-0.146,0.106-0.235c-0.138-0.084-0.151-0.168-0.243-0.313c-0.102-0.146-0.146-0.19-0.251-0.244
        c-0.101-0.06-0.206-0.121-0.168-0.175c0.037-0.045,0.138-0.122,0.121-0.19c-0.017-0.068-0.084-0.22-0.045-0.282
        c0.028-0.06,0.045-0.235-0.009-0.242C216.642,63.698,216.603,63.804,216.603,63.804 M215.461,64.109
        c-0.028,0-0.101,0.024-0.114,0.076c-0.016,0.053-0.029,0.076-0.061,0.099c-0.03,0.016-0.105,0.1-0.105,0.129
        c0,0.031,0,0.252,0.221,0.221c0.22-0.022,0.356-0.037,0.371-0.137c0.023-0.1,0.045-0.121,0.023-0.251
        c-0.016-0.129-0.122-0.213-0.176-0.213C215.567,64.04,215.461,64.109,215.461,64.109 M214.563,63.325
        c0,0-0.168,0.015-0.146,0.16s0.075,0.243,0.015,0.274c-0.061,0.038-0.104,0.098-0.091,0.189
        c0.009,0.084,0.076,0.267-0.008,0.289c-0.084,0.023-0.298-0.022-0.313,0.084c-0.015,0.106,0.024,0.449,0.215,0.479
        c0.189,0.031,0.297-0.061,0.297,0.054c-0.008,0.114-0.022,0.297,0.092,0.28c0.105-0.014,0.129,0.031,0.166-0.127
        c0.031-0.161,0.107-0.176,0.152-0.307c0.039-0.121-0.1-0.25,0-0.411c0.106-0.152,0.168-0.357,0.26-0.343
        c0.099,0.007,0.197,0.062,0.168-0.099c-0.039-0.161-0.152-0.206-0.123-0.381c0.039-0.183,0.023-0.594-0.038-0.48
        c-0.062,0.122-0.099,0.184-0.136,0.374c-0.047,0.183-0.063,0.38-0.102,0.502c-0.037,0.122-0.113,0.183-0.136,0.313
        c-0.022,0.128-0.03,0.183-0.054,0.296s0.054,0.244-0.03,0.259c-0.084,0.009-0.138,0.009-0.129-0.16
        c0-0.167,0.007-0.204,0.037-0.365c0.039-0.153,0.138-0.28,0.114-0.41c-0.022-0.123,0.115-0.26,0.121-0.313
        c0-0.054-0.039-0.206-0.113-0.191c-0.075,0.009-0.061-0.069-0.129-0.053C214.585,63.249,214.563,63.325,214.563,63.325
         M213.481,62.396c-0.068,0.038-0.107,0.136-0.017,0.198s0.206,0.007,0.175,0.114c-0.029,0.106-0.06,0.244-0.045,0.358
        c0.016,0.122,0.03,0.297,0.016,0.351c-0.022,0.053-0.063,0.121-0.029,0.205c0.029,0.084-0.077,0.114,0.029,0.151
        c0.105,0.039,0.105-0.084,0.213-0.151c0.113-0.062,0.16-0.146,0.213-0.062c0.046,0.084-0.082,0.221,0.023,0.228
        c0.105,0.01,0.176-0.074,0.205-0.15c0.029-0.077-0.121-0.1-0.076-0.191c0.047-0.091,0.105-0.145,0.152-0.205
        c0.053-0.053,0.151-0.115,0.151-0.198c0.008-0.075,0.047-0.267-0.037-0.273c-0.084-0.008-0.177,0.106-0.222,0.061
        c-0.045-0.046-0.022-0.13-0.106-0.122c-0.092,0.016-0.114,0.03-0.166-0.016c-0.055-0.045-0.146-0.121-0.19-0.152
        c-0.047-0.03-0.076-0.144-0.131-0.159C213.588,62.357,213.481,62.396,213.481,62.396 M214.601,61.878
        c-0.016,0.016-0.106-0.03-0.09,0.145c0.016,0.175,0,0.22,0,0.297c0,0.068,0.066,0.114,0.145,0.015
        c0.068-0.099,0.168-0.191,0.183-0.121c0.015,0.075,0.037,0.182,0.104,0.212c0.07,0.039,0.107,0.13,0.199,0.138
        c0.092,0,0.176,0.023,0.092-0.13c-0.092-0.16-0.198-0.236-0.268-0.304c-0.068-0.077-0.099-0.153-0.176-0.236
        c-0.082-0.077-0.129-0.1-0.137-0.084C214.64,61.825,214.601,61.878,214.601,61.878 M215.386,61.87
        c-0.048,0.031-0.085,0.13-0.009,0.229c0.067,0.099,0.084,0.16,0.19,0.221c0.105,0.061,0.168,0.045,0.268,0.121
        c0.091,0.077,0.066,0.031,0.083,0.175c0.022,0.146-0.062,0.183-0.091,0.244c-0.031,0.061-0.031,0.251-0.146,0.152
        c-0.121-0.092-0.037-0.198-0.045-0.282c-0.017-0.083-0.183-0.22-0.191-0.06c-0.014,0.151,0.047,0.213,0.068,0.342
        c0.031,0.13-0.045,0.176,0,0.29c0.055,0.114,0.176,0.167,0.223,0.114c0.045-0.062,0.242-0.009,0.183,0.105
        c-0.062,0.115-0.16,0.152-0.083,0.244c0.083,0.084,0.145,0.13,0.15,0.221c0,0.092,0.131,0.274,0.146,0.183
        c0.007-0.084-0.017-0.183,0.104-0.068c0.115,0.114,0.168-0.016,0.138-0.121c-0.022-0.115-0.138-0.084-0.183-0.274
        s-0.016-0.289-0.03-0.373c-0.022-0.076-0.177-0.19-0.106-0.244c0.077-0.053,0.092-0.076,0.182,0.037
        c0.094,0.115,0.162,0.084,0.223,0.069c0.068-0.016,0.139,0.054,0.175,0.008c0.03-0.038-0.03-0.214-0.136-0.319
        c-0.107-0.107-0.178-0.1-0.123-0.274c0.046-0.175,0.13-0.358,0.007-0.457c-0.12-0.106-0.265-0.077-0.265-0.183
        c0-0.105-0.198-0.205-0.229-0.19c-0.031,0.007-0.15,0.091-0.221,0.069c-0.068-0.031-0.107-0.062-0.176-0.062
        C215.422,61.794,215.386,61.87,215.386,61.87 M213.45,61.749c0,0-0.151,0.159-0.084,0.235c0.068,0.084,0.017,0.298,0.092,0.275
        c0.076-0.023,0.123-0.115,0.123-0.236c0-0.115,0.1-0.343,0.016-0.366C213.513,61.635,213.45,61.749,213.45,61.749
         M213.216,60.972c-0.023,0.06-0.076,0.121,0.021,0.16c0.107,0.03,0.039,0.19,0.123,0.114c0.076-0.084,0.113-0.358-0.023-0.365
        C213.2,60.873,213.216,60.972,213.216,60.972 M211.966,61.01c0,0,0.084,0.046,0.307,0.259c0.22,0.205,0.228,0.259,0.258,0.426
        c0.031,0.16,0.123,0.328,0.222,0.389c0.099,0.053,0.183,0.175,0.197-0.076c0.016-0.252,0.091-0.663-0.037-0.762
        c-0.132-0.091-0.199-0.084-0.298-0.153c-0.092-0.061-0.221-0.189-0.366-0.189c-0.138-0.008-0.472,0-0.418,0.061
        C211.874,61.032,211.966,61.01,211.966,61.01"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M211.692,56.137c-0.123,0.039-0.352,0.076-0.215,0.419c0.139,0.335,0.1,0.35,0.055,0.479c-0.039,0.138-0.063,0.206,0,0.511
        c0.066,0.305-0.17,0.357,0,0.594c0.176,0.235,0.365,0.357,0.129,0.357c-0.235,0-0.26-0.122-0.373-0.167
        c-0.113-0.046-0.403-0.023-0.213,0.191c0.189,0.212,0.336,0.274,0.313,0.494c-0.023,0.228-0.121,0.236,0.105,0.358
        c0.222,0.13,0.236,0.236,0.26,0.366c0.016,0.121,0.084,0.236,0.176,0.258c0.092,0.024-0.023-0.304,0.146-0.281
        c0.174,0.023,0.129,0.053,0.19,0.175c0.066,0.13-0.154,0.274-0.168,0.365c-0.008,0.092-0.131,0.312,0.066,0.365
        c0.207,0.062,0.186-0.258,0.275-0.029c0.082,0.228,0.145,0.327,0.234,0.266c0.084-0.054,0.176-0.175,0.282-0.244
        c0.099-0.068,0.151-0.122,0.26-0.046c0.099,0.076,0.233,0.016,0.312,0.183c0.076,0.168,0.184,0.221,0.236,0.327
        c0.053,0.099,0.022,0.221,0.075,0.168c0.062-0.061,0.084-0.19,0.039-0.305c-0.047-0.114-0.229-0.373-0.168-0.381
        c0.054-0.014,0.175,0.099,0.267,0.214c0.092,0.107,0.282,0.266,0.352,0.266c0.066,0,0.136-0.023,0.145,0.115
        c0.008,0.137,0.029,0.183,0.146,0.236c0.112,0.053,0.28,0.007,0.334,0.091c0.062,0.076-0.009,0.312,0.091,0.343
        c0.107,0.038,0.191-0.076,0.152-0.29c-0.045-0.213-0.014-0.213-0.182-0.305c-0.169-0.09-0.063-0.143-0.189-0.236
        c-0.139-0.09-0.297-0.167-0.086-0.167c0.214,0,0.305,0.015,0.396,0.046c0.092,0.031,0.299-0.274,0.139-0.396
        c-0.162-0.122-0.23-0.129-0.252,0c-0.023,0.137-0.047,0.182-0.146,0.16c-0.1-0.023-0.234-0.183-0.355-0.206
        c-0.123-0.023-0.19-0.007-0.207,0.115c-0.008,0.121-0.053,0.327-0.146,0.137c-0.091-0.191-0.046-0.397-0.167-0.442
        c-0.121-0.046-0.114-0.22-0.327-0.076c-0.214,0.145-0.138,0.221-0.289,0.213c-0.159-0.015-0.397-0.068-0.419-0.191
        c-0.023-0.121,0.092-0.517,0.016-0.631c-0.084-0.115-0.306-0.214-0.326-0.313c-0.024-0.106,0.099-0.296,0.099-0.403
        c0-0.114,0.259-0.297,0.312-0.373c0.062-0.076,0.252-0.358,0.205-0.594c-0.045-0.236-0.145-0.388-0.258-0.586
        c-0.115-0.206-0.123-0.495-0.123-0.64c0-0.153,0.047-0.678-0.136-0.442c-0.183,0.237-0.235,0.305-0.42,0.183
        c-0.175-0.128-0.312-0.327-0.427-0.342C211.792,56.037,211.692,56.137,211.692,56.137z M212.835,63.377
        c0.007,0.084,0.113,0.198,0.129,0.107c0.008-0.093-0.008-0.206-0.063-0.198C212.851,63.293,212.835,63.377,212.835,63.377z
         M212.778,63.088c-0.006,0.022,0.041,0.062,0.063,0.084c0.03,0.016,0.114-0.13,0.046-0.184
        c-0.068-0.029-0.166-0.015-0.176,0.031C212.704,63.066,212.778,63.088,212.778,63.088z M212.628,62.798
        c0,0-0.09,0.069-0.083,0.153c0,0.068,0.206-0.206,0.177-0.214C212.683,62.738,212.628,62.798,212.628,62.798z M211.7,62.046
        c0,0,0.008,0.106,0.06,0.159c0.054,0.054-0.007,0.137-0.007,0.214c0,0.076,0.046,0.273,0.105,0.258
        c0.063-0.016-0.039-0.19,0.063-0.251c0.105-0.061,0.268,0.069,0.268-0.084c0-0.152-0.1-0.258-0.168-0.25
        c-0.068,0.015-0.138-0.1-0.184-0.13C211.792,61.924,211.7,62.046,211.7,62.046z M209.622,65.99
        c0.006,0.053,0.016,0.357,0.09,0.297c0.084-0.053,0.252-0.365,0.13-0.389C209.712,65.869,209.622,65.99,209.622,65.99z
         M211.448,62.868c-0.037,0.045-0.106,0.19-0.106,0.342c0,0.153,0.106,0.062,0.046,0.275c-0.068,0.221-0.046,0.213-0.107,0.281
        c-0.051,0.077-0.104-0.046-0.188,0.152s-0.092,0.297-0.267,0.479c-0.167,0.185-0.167,0.282-0.243,0.381
        c-0.077,0.106-0.297,0.146-0.345,0.22c-0.045,0.078-0.228,0.168-0.28,0.328c-0.054,0.161-0.175,0.291-0.138,0.352
        c0.031,0.066,0.185,0.13,0.267-0.008c0.084-0.137,0.069-0.223,0.138-0.252c0.067-0.039,0.168-0.092,0.235-0.183
        c0.069-0.092,0.046-0.282,0.123-0.29c0.076,0,0.112,0.084,0.228-0.007c0.123-0.092,0.19-0.29,0.205-0.328
        c0.017-0.038,0.008-0.319,0.116-0.365c0.104-0.045,0.158-0.03,0.212-0.076c0.047-0.053,0.106-0.13,0.121-0.267
        c0.016-0.137,0.184-0.007,0.245-0.183c0.067-0.167,0.037-0.183-0.039-0.32c-0.084-0.129-0.137-0.212-0.122-0.304
        c0.016-0.1,0.063-0.236,0.03-0.251C211.54,62.822,211.448,62.868,211.448,62.868z M213.839,67.307
        c-0.008,0.007-0.053,0.053-0.053,0.075c0,0.021,0.021,0.066,0.037,0.115c0.016,0.045,0.055,0.09,0.105,0.082
        c0.055-0.006,0.106-0.016,0.139-0.076c0.029-0.055,0.008-0.15-0.031-0.174c-0.029-0.031-0.082-0.078-0.129-0.068
        C213.854,67.277,213.839,67.307,213.839,67.307z M213.207,67.816c-0.046,0-0.351,0.13-0.222,0.176
        c0.131,0.053,0.465-0.068,0.389-0.13C213.29,67.802,213.207,67.816,213.207,67.816z M217.007,64.163
        c-0.016,0.016-0.063,0.106-0.068,0.174c-0.008,0.062-0.039,0.138,0.045,0.152c0.084,0.022,0.154,0,0.162-0.055
        c0-0.045-0.117-0.092-0.084-0.151c0.029-0.068,0.06-0.183,0.021-0.19C217.054,64.093,217.007,64.163,217.007,64.163z
         M216.603,63.804c-0.082,0.061-0.099,0.167-0.067,0.274c0.022,0.106,0.151,0.114,0.112,0.205
        c-0.035,0.099-0.09,0.106-0.135,0.136c-0.039,0.031-0.016,0.106,0.016,0.244c0.029,0.129,0.068,0.176,0.084,0.281
        c0.015,0.107,0,0.198-0.077,0.198c-0.069,0-0.168-0.129-0.175-0.037c-0.016,0.099-0.03,0.158-0.1,0.144
        c-0.076-0.021-0.121-0.09-0.16-0.09c-0.037,0-0.062-0.306-0.166-0.215c-0.106,0.1,0.006,0.215,0.061,0.344
        c0.045,0.139,0.045,0.364-0.046,0.305c-0.099-0.068-0.222-0.221-0.229-0.092c0,0.138-0.113,0.16-0.129,0.281
        c-0.021,0.114-0.105,0.176-0.189,0.176c-0.084,0.008-0.045,0.037-0.113-0.199c-0.063-0.234-0.152-0.317-0.273-0.303
        c-0.129,0.015-0.121,0.015-0.283,0.114c-0.151,0.104-0.188,0-0.174,0.166c0.014,0.169,0.021,0.198-0.063,0.214
        c-0.075,0.008-0.229,0.09-0.266,0.102c-0.039,0.014-0.273-0.109-0.32,0.119c-0.053,0.221,0.122,0.389,0.055,0.473
        c-0.068,0.091-0.131,0.175-0.131,0.213c0,0.046-0.045,0.13,0.023,0.189c0.074,0.055,0.106,0.199,0.175,0.115
        c0.067-0.084,0.114-0.19,0.114-0.318c0-0.123,0.076-0.186,0.092-0.26c0.023-0.068,0.06-0.328,0.174-0.252
        c0.107,0.076-0.15,0.242,0.018,0.252c0.166,0,0.204-0.222,0.288-0.131c0.085,0.1,0.251,0.152,0.281,0.152
        c0.038,0.008,0.106,0.046,0.106-0.068c0-0.121,0.115-0.318,0.16-0.281c0.045,0.047,0.037,0.197,0.151,0.205
        c0.106,0.008,0.282,0.016,0.282,0.113c0,0.1-0.045,0.176-0.1,0.282c-0.054,0.114-0.113,0.244-0.022,0.373
        c0.099,0.123-0.007,0.229,0.015,0.289c0.023,0.062,0.358,0.381,0.479,0.458c0.123,0.066,0.207,0.137,0.336,0.105
        c0.138-0.039,0.061-0.305,0.191-0.189c0.129,0.12-0.039,0.234,0,0.305c0.036,0.067,0.066,0.213,0.15,0.168
        c0.092-0.039,0.077-0.13,0.123-0.244c0.045-0.123,0.259-0.236,0.156-0.336c-0.09-0.105-0.111-0.091-0.202-0.205
        c-0.093-0.113-0.13-0.313-0.093-0.388c0.031-0.077,0.113-0.243,0.121-0.29c0.008-0.053,0.024-0.229,0.153-0.175
        c0.13,0.045,0.046,0.213,0.104,0.289c0.063,0.084,0.177,0.062,0.154,0.221c-0.022,0.16,0.045,0.207,0.045,0.289
        c0,0.093,0.022,0.19,0.099,0.115c0.077-0.067,0.009-0.222,0.009-0.345c0-0.121,0.029-0.196,0.068-0.233
        c0.029-0.039,0.121-0.183,0.121-0.237c0.009-0.054,0.093-0.373,0-0.473c-0.082-0.1-0.189-0.007-0.151-0.159
        c0.03-0.153-0.054-0.366-0.067-0.457c-0.017-0.092,0.112-0.228-0.031-0.228c-0.146,0-0.26-0.039-0.176-0.114
        c0.076-0.085,0.236-0.146,0.106-0.235c-0.138-0.084-0.151-0.168-0.243-0.313c-0.102-0.146-0.146-0.19-0.251-0.244
        c-0.101-0.06-0.206-0.121-0.168-0.175c0.037-0.045,0.138-0.122,0.121-0.19c-0.017-0.068-0.084-0.22-0.045-0.282
        c0.028-0.06,0.045-0.235-0.009-0.242C216.642,63.698,216.603,63.804,216.603,63.804z M215.461,64.109
        c-0.028,0-0.101,0.024-0.114,0.076c-0.016,0.053-0.029,0.076-0.061,0.099c-0.03,0.016-0.105,0.1-0.105,0.129
        c0,0.031,0,0.252,0.221,0.221c0.22-0.022,0.356-0.037,0.371-0.137c0.023-0.1,0.045-0.121,0.023-0.251
        c-0.016-0.129-0.122-0.213-0.176-0.213C215.567,64.04,215.461,64.109,215.461,64.109z M214.563,63.325
        c0,0-0.168,0.015-0.146,0.16s0.075,0.243,0.015,0.274c-0.061,0.038-0.104,0.098-0.091,0.189
        c0.009,0.084,0.076,0.267-0.008,0.289c-0.084,0.023-0.298-0.022-0.313,0.084c-0.015,0.106,0.024,0.449,0.215,0.479
        c0.189,0.031,0.297-0.061,0.297,0.054c-0.008,0.114-0.022,0.297,0.092,0.28c0.105-0.014,0.129,0.031,0.166-0.127
        c0.031-0.161,0.107-0.176,0.152-0.307c0.039-0.121-0.1-0.25,0-0.411c0.106-0.152,0.168-0.357,0.26-0.343
        c0.099,0.007,0.197,0.062,0.168-0.099c-0.039-0.161-0.152-0.206-0.123-0.381c0.039-0.183,0.023-0.594-0.038-0.48
        c-0.062,0.122-0.099,0.184-0.136,0.374c-0.047,0.183-0.063,0.38-0.102,0.502c-0.037,0.122-0.113,0.183-0.136,0.313
        c-0.022,0.128-0.03,0.183-0.054,0.296s0.054,0.244-0.03,0.259c-0.084,0.009-0.138,0.009-0.129-0.16
        c0-0.167,0.007-0.204,0.037-0.365c0.039-0.153,0.138-0.28,0.114-0.41c-0.022-0.123,0.115-0.26,0.121-0.313
        c0-0.054-0.039-0.206-0.113-0.191c-0.075,0.009-0.061-0.069-0.129-0.053C214.585,63.249,214.563,63.325,214.563,63.325z
         M213.481,62.396c-0.068,0.038-0.107,0.136-0.017,0.198s0.206,0.007,0.175,0.114c-0.029,0.106-0.06,0.244-0.045,0.358
        c0.016,0.122,0.03,0.297,0.016,0.351c-0.022,0.053-0.063,0.121-0.029,0.205c0.029,0.084-0.077,0.114,0.029,0.151
        c0.105,0.039,0.105-0.084,0.213-0.151c0.113-0.062,0.16-0.146,0.213-0.062c0.046,0.084-0.082,0.221,0.023,0.228
        c0.105,0.01,0.176-0.074,0.205-0.15c0.029-0.077-0.121-0.1-0.076-0.191c0.047-0.091,0.105-0.145,0.152-0.205
        c0.053-0.053,0.151-0.115,0.151-0.198c0.008-0.075,0.047-0.267-0.037-0.273c-0.084-0.008-0.177,0.106-0.222,0.061
        c-0.045-0.046-0.022-0.13-0.106-0.122c-0.092,0.016-0.114,0.03-0.166-0.016c-0.055-0.045-0.146-0.121-0.19-0.152
        c-0.047-0.03-0.076-0.144-0.131-0.159C213.588,62.357,213.481,62.396,213.481,62.396z M214.601,61.878
        c-0.016,0.016-0.106-0.03-0.09,0.145c0.016,0.175,0,0.22,0,0.297c0,0.068,0.066,0.114,0.145,0.015
        c0.068-0.099,0.168-0.191,0.183-0.121c0.015,0.075,0.037,0.182,0.104,0.212c0.07,0.039,0.107,0.13,0.199,0.138
        c0.092,0,0.176,0.023,0.092-0.13c-0.092-0.16-0.198-0.236-0.268-0.304c-0.068-0.077-0.099-0.153-0.176-0.236
        c-0.082-0.077-0.129-0.1-0.137-0.084C214.64,61.825,214.601,61.878,214.601,61.878z M215.386,61.87
        c-0.048,0.031-0.085,0.13-0.009,0.229c0.067,0.099,0.084,0.16,0.19,0.221c0.105,0.061,0.168,0.045,0.268,0.121
        c0.091,0.077,0.066,0.031,0.083,0.175c0.022,0.146-0.062,0.183-0.091,0.244c-0.031,0.061-0.031,0.251-0.146,0.152
        c-0.121-0.092-0.037-0.198-0.045-0.282c-0.017-0.083-0.183-0.22-0.191-0.06c-0.014,0.151,0.047,0.213,0.068,0.342
        c0.031,0.13-0.045,0.176,0,0.29c0.055,0.114,0.176,0.167,0.223,0.114c0.045-0.062,0.242-0.009,0.183,0.105
        c-0.062,0.115-0.16,0.152-0.083,0.244c0.083,0.084,0.145,0.13,0.15,0.221c0,0.092,0.131,0.274,0.146,0.183
        c0.007-0.084-0.017-0.183,0.104-0.068c0.115,0.114,0.168-0.016,0.138-0.121c-0.022-0.115-0.138-0.084-0.183-0.274
        s-0.016-0.289-0.03-0.373c-0.022-0.076-0.177-0.19-0.106-0.244c0.077-0.053,0.092-0.076,0.182,0.037
        c0.094,0.115,0.162,0.084,0.223,0.069c0.068-0.016,0.139,0.054,0.175,0.008c0.03-0.038-0.03-0.214-0.136-0.319
        c-0.107-0.107-0.178-0.1-0.123-0.274c0.046-0.175,0.13-0.358,0.007-0.457c-0.12-0.106-0.265-0.077-0.265-0.183
        c0-0.105-0.198-0.205-0.229-0.19c-0.031,0.007-0.15,0.091-0.221,0.069c-0.068-0.031-0.107-0.062-0.176-0.062
        C215.422,61.794,215.386,61.87,215.386,61.87z M213.45,61.749c0,0-0.151,0.159-0.084,0.235c0.068,0.084,0.017,0.298,0.092,0.275
        c0.076-0.023,0.123-0.115,0.123-0.236c0-0.115,0.1-0.343,0.016-0.366C213.513,61.635,213.45,61.749,213.45,61.749z
         M213.216,60.972c-0.023,0.06-0.076,0.121,0.021,0.16c0.107,0.03,0.039,0.19,0.123,0.114c0.076-0.084,0.113-0.358-0.023-0.365
        C213.2,60.873,213.216,60.972,213.216,60.972z M211.966,61.01c0,0,0.084,0.046,0.307,0.259c0.22,0.205,0.228,0.259,0.258,0.426
        c0.031,0.16,0.123,0.328,0.222,0.389c0.099,0.053,0.183,0.175,0.197-0.076c0.016-0.252,0.091-0.663-0.037-0.762
        c-0.132-0.091-0.199-0.084-0.298-0.153c-0.092-0.061-0.221-0.189-0.366-0.189c-0.138-0.008-0.472,0-0.418,0.061
        C211.874,61.032,211.966,61.01,211.966,61.01z"/>
        
        
               
        <!--  australie   -->
        <a class="aussie" onclick='DealerShow("australie_dealer","aussie");' > 
        
             <path fill="#808184" d="M207.724,93.194c0,0-0.447,0.373-0.852,0.558
               c-0.411,0.174-0.541,0.267-0.646,0.426c-0.1,0.152-0.343,0.691-0.381,0.357c-0.031-0.334,0.313-0.701,0.16-0.701
               c-0.16,0-0.238,0.344-0.427,0.603c-0.19,0.259-0.151,0.548-0.183,0.808s-0.207,0.336-0.351,0.609
               c-0.146,0.266-0.245,0.373-0.114,0.807c0.137,0.441,0.151,0.381,0.198,0.746c0.037,0.358,0.22,0.641,0.12,0.717
               c-0.1,0.084-0.189,0.158-0.229-0.008c-0.031-0.168-0.076-0.794-0.215-0.68c-0.136,0.115,0.084,0.609,0.07,0.73
               c-0.008,0.123-0.282,0.016-0.359-0.213c-0.082-0.228-0.228-0.806-0.25-0.472s0.151,0.867,0.327,1.065
               c0.183,0.205,0.274,0.205,0.212,0.533c-0.053,0.326-0.15,0.707,0.07,1.088c0.229,0.381,0.137,0.541,0.091,0.854
               c-0.046,0.319-0.114,0.959-0.091,1.271c0.021,0.32,0.158,0.352,0.145,0.846c-0.008,0.496-0.235,0.449-0.289,0.854
               c-0.062,0.404-0.137,0.428-0.281,0.684c-0.146,0.262-0.19,0.345-0.328,0.283c-0.137-0.053-0.221,0.047-0.313,0.215
               c-0.092,0.166-0.213,0.396,0.055,0.518c0.273,0.122,0.402-0.031,0.533,0.26c0.119,0.297,0.213,0.396,0.707,0.396
               c0.492,0,1.013-0.068,1.158-0.19c0.143-0.121,0.234-0.373,0.447-0.426c0.214-0.063,0.268,0.053,0.457-0.084
               c0.189-0.129,0.205-0.105,0.441-0.288c0.235-0.185,0.586-0.116,0.807-0.116c0.229,0,0.32-0.158,0.604-0.091
               c0.281,0.067,0.457,0.159,0.685,0.183c0.222,0.024,0.846-0.138,1.097-0.305c0.26-0.167,0.137-0.539,0.588-0.607
               c0.448-0.069,1.125-0.473,1.575-0.58c0.449-0.112,0.632-0.066,0.959-0.066c0.32,0,1.226-0.473,1.683-0.541
               c0.465-0.068,0.822-0.046,1.066-0.046c0.251,0,0.51-0.274,0.754-0.16c0.25,0.113,0.35,0.206,0.518,0.358
               c0.176,0.16,0.023,0.105,0.213,0.091c0.191-0.022,0.298,0.038,0.365,0.106c0.076,0.062,0.131,0.105,0.297,0.129
               c0.168,0.017,0.297-0.106,0.351,0c0.063,0.105-0.083,0.183,0.063,0.281c0.144,0.1,0.197,0.075,0.113,0.215
               c-0.078,0.144-0.229,0.287-0.085,0.373c0.146,0.082,0.296,0.067,0.32,0.158c0.021,0.092-0.091,0.336,0.03,0.594
               c0.121,0.26-0.01,0.472-0.03,0.656c-0.024,0.176-0.151,0.456,0.144,0.342c0.291-0.106,0.146-0.389,0.353-0.502
               c0.198-0.115,0.729-0.594,0.997-0.679c0.266-0.075,0.328-0.03,0.486-0.28c0.152-0.244,0.336-0.32,0.479-0.558
               c0.144-0.235,0.358-0.556,0.327-0.319c-0.037,0.236-0.092,0.473-0.189,0.541c-0.107,0.068-0.113,0.092-0.167,0.274
               c-0.062,0.175-0.092,0.272-0.176,0.317c-0.076,0.047-0.319,0.131-0.427,0.33c-0.1,0.203-0.045,0.295-0.189,0.531
               c-0.146,0.235-0.1,0.221-0.305,0.235c-0.198,0.009-0.428,0.176-0.428,0.26c0,0.076-0.008,0.129,0.281,0.045
               c0.297-0.092,0.282,0,0.465-0.106c0.176-0.1,0.281-0.41,0.381-0.541c0.1-0.121,0.168-0.389,0.259-0.221
               c0.093,0.168,0.022,0.389,0.009,0.504c-0.009,0.113-0.054,0.205-0.146,0.297c-0.092,0.082-0.494,0.268-0.402,0.365
               c0.091,0.105,0.184,0.129,0.357,0.084c0.183-0.045,0.533-0.396,0.533-0.207c0,0.191-0.139,0.162-0.084,0.336
               c0.061,0.184,0.129,0.342,0.092,0.563c-0.031,0.228-0.138,0.373-0.176,0.448c-0.045,0.084-0.062,0.175-0.105,0.306
               c-0.047,0.136-0.077,0.28,0.037,0.401c0.106,0.129-0.137,0.154,0.099,0.328c0.237,0.184,0.405-0.03,0.481,0.168
               c0.082,0.207,0.061,0.373,0.182,0.273c0.123-0.1,0.236-0.234,0.419-0.158c0.175,0.082,0.259,0.145,0.503,0.273
               c0.251,0.119,0.381,0.388,0.616,0.145c0.235-0.252,0.343-0.352,0.487-0.382c0.146-0.036,0.449,0.056,0.494-0.045
               c0.047-0.106,0.123-0.474,0.352-0.427c0.221,0.047,0.008,0.357-0.146,0.48c-0.158,0.119,0.244,0.137,0.366,0.25
               c0.13,0.107,0.067,0.473,0.351,0.426c0.281-0.045,0.289-0.379,0.473-0.44c0.184-0.053,0.754-0.616,1.302-0.669
               c0.556-0.063,0.625,0.016,0.906-0.039c0.282-0.055,0.403-0.044,0.594-0.184c0.19-0.129,0.312-0.344,0.373-0.449
               c0.054-0.1,0.146-0.737,0.571-0.967c0.427-0.221,0.479-0.411,0.662-0.662c0.183-0.244,0.328-0.328,0.494-0.594
               c0.168-0.273,0.396-0.441,0.488-0.596c0.084-0.158,0.268-0.439,0.6-0.724c0.345-0.28,0.756-0.502,1.015-0.752
               c0.26-0.243,0.228-0.481,0.533-0.609c0.305-0.122,0.473-0.708,0.622-0.989c0.162-0.281,0.52-0.457,0.589-0.709
               c0.065-0.244,0.235-0.808,0.555-1.097c0.313-0.296,0.222-0.509,0.244-0.823c0.022-0.318,0.235-0.578,0.235-0.744
               c0-0.168-0.213,0.067-0.266,0.106c-0.063,0.028-0.018-0.366,0.127-0.563c0.154-0.207,0.133-0.633,0.283-0.922
               c0.16-0.297,0.51-1.08,0.352-1.006c-0.162,0.077-0.352,0.359-0.428,0.42c-0.074,0.053-0.189-0.068-0.184-0.283
               c0.016-0.211-0.03-0.144-0.197-0.295c-0.168-0.145-0.085-0.357-0.273-0.435c-0.19-0.075-0.731-0.403-0.603-0.793
               c0.122-0.38,0.313-0.593,0.175-0.754c-0.137-0.151-0.266,0.116-0.426-0.037c-0.16-0.161-0.357-0.213-0.465-0.228
               c-0.1-0.01-0.15,0.09-0.129-0.307c0.023-0.396,0.281-0.563,0.008-0.775c-0.268-0.213-0.428-0.381-0.328-0.524
               c0.107-0.146-0.046-0.382-0.166-0.403c-0.123-0.023-0.428-0.084-0.496-0.26c-0.066-0.184-0.074-0.16-0.305-0.281
               c-0.221-0.123-0.197-0.215-0.365-0.352c-0.176-0.137-0.297-0.28-0.252-0.428c0.047-0.145-0.066-0.119-0.053-0.35
               c0.008-0.229,0.029-0.326,0.068-0.615c0.029-0.297,0.074-0.207-0.092-0.588c-0.168-0.381-0.344-0.586-0.205-0.959
               c0.137-0.365,0.158-0.897,0.045-0.998c-0.113-0.098-0.328-0.113-0.373-0.25c-0.045-0.131,0.039-0.273-0.151-0.273
               s-0.213,0.189-0.396,0.152c-0.185-0.031-0.236-0.123-0.252-0.289c-0.006-0.17-0.031-0.42,0.039-0.687
               c0.067-0.274,0.006-0.608-0.101-0.687c-0.113-0.084-0.151-0.328-0.151-0.42c0-0.09-0.154-0.188-0.168-0.371
               c-0.008-0.176,0.045-0.459,0.014-0.563c-0.037-0.101,0.056-0.458-0.234-0.312c-0.297,0.145-0.35,0.183-0.396,0.416
               c-0.045,0.238-0.045,0.475-0.135,0.664c-0.093,0.19-0.291,0.197-0.313,0.381c-0.022,0.184,0.047,0.396-0.076,0.563
               c-0.129,0.168-0.068,0.374-0.176,0.588c-0.098,0.211-0.129,0.196-0.106,0.563c0.024,0.357-0.151,0.318-0.106,0.624
               c0.047,0.305,0,0.35-0.129,0.655c-0.137,0.304-0.152,0.799-0.304,0.959c-0.161,0.152-0.274,0.449-0.495,0.646
               c-0.229,0.204-0.26,0.305-0.541,0.297c-0.281-0.015-0.418-0.129-0.539-0.259c-0.125-0.138-0.328-0.306-0.406-0.418
               c-0.084-0.114-0.168-0.114-0.402-0.207c-0.234-0.084-0.441-0.319-0.518-0.456c-0.084-0.139-0.16-0.184-0.373-0.273
               c-0.215-0.084-0.403-0.215-0.549-0.313c-0.15-0.102-0.137-0.146-0.259-0.244c-0.129-0.107-0.44-0.441-0.319-0.625
               c0.123-0.176,0.463-0.502,0.474-0.641c0.015-0.137,0.067-0.326,0.06-0.448c-0.015-0.121-0.021-0.289,0.153-0.313
               c0.183-0.021,0.372-0.188,0.472-0.318c0.106-0.122,0.418-0.457,0.26-0.57c-0.152-0.115-0.223-0.313-0.391-0.16
               c-0.174,0.16-0.271,0.273-0.396,0.236c-0.12-0.031-0.28-0.214-0.463-0.13c-0.176,0.075-0.305-0.091-0.58-0.138
               c-0.28-0.045-0.344,0.076-0.555-0.045c-0.214-0.123-0.563-0.215-0.563-0.215s-0.198-0.25-0.359-0.304
               c-0.159-0.054-0.075-0.229-0.381-0.235c-0.304-0.016-0.448-0.047-0.38,0.113c0.068,0.152,0.236,0.305,0.357,0.281
               c0.121-0.021,0.289-0.084,0.305,0.047c0.008,0.121,0.115,0.221-0.061,0.38c-0.168,0.153-0.381,0.077-0.525,0.144
               c-0.146,0.069-0.655,0.047-0.754,0.047c-0.1,0-0.26,0.184-0.373,0.289c-0.106,0.113-0.403,0.16-0.457,0.307
               c-0.061,0.145-0.069,0.279-0.069,0.38c0,0.104-0.067,0.151-0.212,0.235c-0.145,0.092-0.236,0.229-0.297,0.418
               c-0.055,0.191-0.428,0.281-0.26,0.441c0.176,0.151,0.352,0.168,0.236,0.313c-0.105,0.146-0.32,0.19-0.404,0.114
               c-0.075-0.084-0.235,0.021-0.356-0.016c-0.122-0.029-0.335-0.123-0.396-0.023c-0.053,0.106-0.258,0.093-0.235-0.068
               c0.021-0.15,0.114-0.242-0.093-0.389c-0.196-0.145-0.344-0.463-0.448-0.51c-0.101-0.045-0.19-0.084-0.335,0.022
               c-0.146,0.114-0.031,0.321-0.234,0.306c-0.207-0.009-0.396-0.306-0.496-0.113c-0.099,0.197,0,0.387-0.205,0.41
               c-0.199,0.021-0.404,0-0.496,0.174c-0.084,0.184-0.029,0.184-0.267,0.344c-0.235,0.152-0.372,0.273-0.419,0.428
               c-0.046,0.145-0.22,0.213-0.168,0.364c0.063,0.159,0.048,0.298-0.1,0.319c-0.144,0.022-0.228-0.168-0.35-0.062
               c-0.121,0.114-0.427,0.093-0.35,0.365c0.084,0.267,0.105,0.457,0.037,0.579c-0.068,0.13-0.326,0.205-0.344-0.038
               c-0.008-0.251-0.105-0.624-0.289-0.495c-0.183,0.124-0.158,0.275-0.396,0.411c-0.235,0.137-0.425,0.214-0.387,0.403
               c0.028,0.19,0.196,0.449,0.075,0.603c-0.121,0.146-0.281,0.084-0.396,0.356c-0.115,0.269-0.335,0.83-0.906,1.082
               c-0.578,0.244-0.914,0.312-1.082,0.289c-0.166-0.023-0.472,0.021-0.638,0.182c-0.176,0.154-0.153,0.33-0.396,0.283
               c-0.252-0.045-0.351-0.063-0.563,0.047c-0.214,0.099-0.351,0.22-0.474,0.15C208.364,92.843,207.724,93.194,207.724,93.194
                M226.942,110.73c-0.039,0.007-0.213,0.014-0.184,0.145c0.024,0.129,0.092,0.15,0.069,0.244
               c-0.022,0.098-0.063,0.113-0.101,0.145c-0.039,0.021-0.105,0.168-0.015,0.197c0.091,0.037,0.099-0.037,0.205-0.092
               c0.106-0.047-0.046-0.166,0.017-0.22c0.053-0.056,0.234-0.124,0.196-0.198c-0.045-0.076-0.196-0.054-0.151-0.146
               c0.037-0.101,0.121-0.152,0.045-0.167C226.957,110.615,226.942,110.73,226.942,110.73 M223.981,110.546
               c-0.031,0-0.26,0.092-0.274,0.205c-0.017,0.121-0.168,0.356-0.031,0.334c0.146-0.028,0.123-0.144,0.229-0.213
               c0.107-0.06,0.35-0.304,0.244-0.326C224.041,110.523,223.981,110.546,223.981,110.546 M223.966,111.598
               c0,0-0.35,0.241-0.357,0.509c-0.017,0.268,0.009,0.436-0.047,0.646c-0.054,0.213-0.144,0.244-0.188,0.404
               c-0.038,0.159-0.054,0.327-0.031,0.471c0.022,0.154-0.188,0.313,0.031,0.373c0.22,0.063,0.259-0.029,0.381,0.168
               c0.099,0.063,0.603-0.213,0.729-0.281c0.121-0.068,0.358-0.008,0.496-0.145c0.129-0.138,0.234-0.168,0.388-0.266
               c0.161-0.107,0.26-0.253,0.343-0.328c0.077-0.084,0.26-0.326,0.389-0.494c0.139-0.169,0.557-0.984,0.389-0.99
               c-0.176-0.017-0.137,0.092-0.465,0.16c-0.328,0.061-0.539-0.068-0.73-0.017c-0.189,0.054-0.518,0.146-0.609,0.101
               c-0.091-0.047-0.188-0.123-0.304-0.244c-0.106-0.123-0.176-0.235-0.237-0.215C224.071,111.475,223.966,111.598,223.966,111.598
                M225.336,86.449c-0.015,0.021-0.159,0.031-0.138,0.129c0.016,0.1-0.067,0.313,0.031,0.342c0.092,0.024,0.121,0.011,0.197,0.024
               c0.068,0.007,0.213-0.069,0.137-0.123c-0.082-0.053-0.1-0.053-0.061-0.112c0.03-0.069,0.092-0.313,0.023-0.32
               C225.45,86.38,225.336,86.449,225.336,86.449 M220.577,84.042c0,0-0.069,0.09-0.168,0.197c-0.09,0.114-0.243,0.198-0.213,0.297
               c0.039,0.1,0.046,0.184,0.222,0.107c0.174-0.078,0.251-0.039,0.366,0.006c0.119,0.055,0.099,0.176,0.234,0.039
               c0.137-0.139,0.435-0.344,0.455-0.404c0.024-0.068-0.021-0.402-0.289-0.234c-0.258,0.158-0.363,0.129-0.4,0.082
               C220.745,84.082,220.577,84.042,220.577,84.042"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M207.724,93.194c0,0-0.447,0.373-0.852,0.558c-0.411,0.174-0.541,0.267-0.646,0.426c-0.1,0.152-0.343,0.691-0.381,0.357
               c-0.031-0.334,0.313-0.701,0.16-0.701c-0.16,0-0.238,0.344-0.427,0.603c-0.19,0.259-0.151,0.548-0.183,0.808
               s-0.207,0.336-0.351,0.609c-0.146,0.266-0.245,0.373-0.114,0.807c0.137,0.441,0.151,0.381,0.198,0.746
               c0.037,0.358,0.22,0.641,0.12,0.717c-0.1,0.084-0.189,0.158-0.229-0.008c-0.031-0.168-0.076-0.794-0.215-0.68
               c-0.136,0.115,0.084,0.609,0.07,0.73c-0.008,0.123-0.282,0.016-0.359-0.213c-0.082-0.228-0.228-0.806-0.25-0.472
               s0.151,0.867,0.327,1.065c0.183,0.205,0.274,0.205,0.212,0.533c-0.053,0.326-0.15,0.707,0.07,1.088
               c0.229,0.381,0.137,0.541,0.091,0.854c-0.046,0.319-0.114,0.959-0.091,1.271c0.021,0.32,0.158,0.352,0.145,0.846
               c-0.008,0.496-0.235,0.449-0.289,0.854c-0.062,0.404-0.137,0.428-0.281,0.684c-0.146,0.262-0.19,0.345-0.328,0.283
               c-0.137-0.053-0.221,0.047-0.313,0.215c-0.092,0.166-0.213,0.396,0.055,0.518c0.273,0.122,0.402-0.031,0.533,0.26
               c0.119,0.297,0.213,0.396,0.707,0.396c0.492,0,1.013-0.068,1.158-0.19c0.143-0.121,0.234-0.373,0.447-0.426
               c0.214-0.063,0.268,0.053,0.457-0.084c0.189-0.129,0.205-0.105,0.441-0.288c0.235-0.185,0.586-0.116,0.807-0.116
               c0.229,0,0.32-0.158,0.604-0.091c0.281,0.067,0.457,0.159,0.685,0.183c0.222,0.024,0.846-0.138,1.097-0.305
               c0.26-0.167,0.137-0.539,0.588-0.607c0.448-0.069,1.125-0.473,1.575-0.58c0.449-0.112,0.632-0.066,0.959-0.066
               c0.32,0,1.226-0.473,1.683-0.541c0.465-0.068,0.822-0.046,1.066-0.046c0.251,0,0.51-0.274,0.754-0.16
               c0.25,0.113,0.35,0.206,0.518,0.358c0.176,0.16,0.023,0.105,0.213,0.091c0.191-0.022,0.298,0.038,0.365,0.106
               c0.076,0.062,0.131,0.105,0.297,0.129c0.168,0.017,0.297-0.106,0.351,0c0.063,0.105-0.083,0.183,0.063,0.281
               c0.144,0.1,0.197,0.075,0.113,0.215c-0.078,0.144-0.229,0.287-0.085,0.373c0.146,0.082,0.296,0.067,0.32,0.158
               c0.021,0.092-0.091,0.336,0.03,0.594c0.121,0.26-0.01,0.472-0.03,0.656c-0.024,0.176-0.151,0.456,0.144,0.342
               c0.291-0.106,0.146-0.389,0.353-0.502c0.198-0.115,0.729-0.594,0.997-0.679c0.266-0.075,0.328-0.03,0.486-0.28
               c0.152-0.244,0.336-0.32,0.479-0.558c0.144-0.235,0.358-0.556,0.327-0.319c-0.037,0.236-0.092,0.473-0.189,0.541
               c-0.107,0.068-0.113,0.092-0.167,0.274c-0.062,0.175-0.092,0.272-0.176,0.317c-0.076,0.047-0.319,0.131-0.427,0.33
               c-0.1,0.203-0.045,0.295-0.189,0.531c-0.146,0.235-0.1,0.221-0.305,0.235c-0.198,0.009-0.428,0.176-0.428,0.26
               c0,0.076-0.008,0.129,0.281,0.045c0.297-0.092,0.282,0,0.465-0.106c0.176-0.1,0.281-0.41,0.381-0.541
               c0.1-0.121,0.168-0.389,0.259-0.221c0.093,0.168,0.022,0.389,0.009,0.504c-0.009,0.113-0.054,0.205-0.146,0.297
               c-0.092,0.082-0.494,0.268-0.402,0.365c0.091,0.105,0.184,0.129,0.357,0.084c0.183-0.045,0.533-0.396,0.533-0.207
               c0,0.191-0.139,0.162-0.084,0.336c0.061,0.184,0.129,0.342,0.092,0.563c-0.031,0.228-0.138,0.373-0.176,0.448
               c-0.045,0.084-0.062,0.175-0.105,0.306c-0.047,0.136-0.077,0.28,0.037,0.401c0.106,0.129-0.137,0.154,0.099,0.328
               c0.237,0.184,0.405-0.03,0.481,0.168c0.082,0.207,0.061,0.373,0.182,0.273c0.123-0.1,0.236-0.234,0.419-0.158
               c0.175,0.082,0.259,0.145,0.503,0.273c0.251,0.119,0.381,0.388,0.616,0.145c0.235-0.252,0.343-0.352,0.487-0.382
               c0.146-0.036,0.449,0.056,0.494-0.045c0.047-0.106,0.123-0.474,0.352-0.427c0.221,0.047,0.008,0.357-0.146,0.48
               c-0.158,0.119,0.244,0.137,0.366,0.25c0.13,0.107,0.067,0.473,0.351,0.426c0.281-0.045,0.289-0.379,0.473-0.44
               c0.184-0.053,0.754-0.616,1.302-0.669c0.556-0.063,0.625,0.016,0.906-0.039c0.282-0.055,0.403-0.044,0.594-0.184
               c0.19-0.129,0.312-0.344,0.373-0.449c0.054-0.1,0.146-0.737,0.571-0.967c0.427-0.221,0.479-0.411,0.662-0.662
               c0.183-0.244,0.328-0.328,0.494-0.594c0.168-0.273,0.396-0.441,0.488-0.596c0.084-0.158,0.268-0.439,0.6-0.724
               c0.345-0.28,0.756-0.502,1.015-0.752c0.26-0.243,0.228-0.481,0.533-0.609c0.305-0.122,0.473-0.708,0.622-0.989
               c0.162-0.281,0.52-0.457,0.589-0.709c0.065-0.244,0.235-0.808,0.555-1.097c0.313-0.296,0.222-0.509,0.244-0.823
               c0.022-0.318,0.235-0.578,0.235-0.744c0-0.168-0.213,0.067-0.266,0.106c-0.063,0.028-0.018-0.366,0.127-0.563
               c0.154-0.207,0.133-0.633,0.283-0.922c0.16-0.297,0.51-1.08,0.352-1.006c-0.162,0.077-0.352,0.359-0.428,0.42
               c-0.074,0.053-0.189-0.068-0.184-0.283c0.016-0.211-0.03-0.144-0.197-0.295c-0.168-0.145-0.085-0.357-0.273-0.435
               c-0.19-0.075-0.731-0.403-0.603-0.793c0.122-0.38,0.313-0.593,0.175-0.754c-0.137-0.151-0.266,0.116-0.426-0.037
               c-0.16-0.161-0.357-0.213-0.465-0.228c-0.1-0.01-0.15,0.09-0.129-0.307c0.023-0.396,0.281-0.563,0.008-0.775
               c-0.268-0.213-0.428-0.381-0.328-0.524c0.107-0.146-0.046-0.382-0.166-0.403c-0.123-0.023-0.428-0.084-0.496-0.26
               c-0.066-0.184-0.074-0.16-0.305-0.281c-0.221-0.123-0.197-0.215-0.365-0.352c-0.176-0.137-0.297-0.28-0.252-0.428
               c0.047-0.145-0.066-0.119-0.053-0.35c0.008-0.229,0.029-0.326,0.068-0.615c0.029-0.297,0.074-0.207-0.092-0.588
               c-0.168-0.381-0.344-0.586-0.205-0.959c0.137-0.365,0.158-0.897,0.045-0.998c-0.113-0.098-0.328-0.113-0.373-0.25
               c-0.045-0.131,0.039-0.273-0.151-0.273s-0.213,0.189-0.396,0.152c-0.185-0.031-0.236-0.123-0.252-0.289
               c-0.006-0.17-0.031-0.42,0.039-0.687c0.067-0.274,0.006-0.608-0.101-0.687c-0.113-0.084-0.151-0.328-0.151-0.42
               c0-0.09-0.154-0.188-0.168-0.371c-0.008-0.176,0.045-0.459,0.014-0.563c-0.037-0.101,0.056-0.458-0.234-0.312
               c-0.297,0.145-0.35,0.183-0.396,0.416c-0.045,0.238-0.045,0.475-0.135,0.664c-0.093,0.19-0.291,0.197-0.313,0.381
               c-0.022,0.184,0.047,0.396-0.076,0.563c-0.129,0.168-0.068,0.374-0.176,0.588c-0.098,0.211-0.129,0.196-0.106,0.563
               c0.024,0.357-0.151,0.318-0.106,0.624c0.047,0.305,0,0.35-0.129,0.655c-0.137,0.304-0.152,0.799-0.304,0.959
               c-0.161,0.152-0.274,0.449-0.495,0.646c-0.229,0.204-0.26,0.305-0.541,0.297c-0.281-0.015-0.418-0.129-0.539-0.259
               c-0.125-0.138-0.328-0.306-0.406-0.418c-0.084-0.114-0.168-0.114-0.402-0.207c-0.234-0.084-0.441-0.319-0.518-0.456
               c-0.084-0.139-0.16-0.184-0.373-0.273c-0.215-0.084-0.403-0.215-0.549-0.313c-0.15-0.102-0.137-0.146-0.259-0.244
               c-0.129-0.107-0.44-0.441-0.319-0.625c0.123-0.176,0.463-0.502,0.474-0.641c0.015-0.137,0.067-0.326,0.06-0.448
               c-0.015-0.121-0.021-0.289,0.153-0.313c0.183-0.021,0.372-0.188,0.472-0.318c0.106-0.122,0.418-0.457,0.26-0.57
               c-0.152-0.115-0.223-0.313-0.391-0.16c-0.174,0.16-0.271,0.273-0.396,0.236c-0.12-0.031-0.28-0.214-0.463-0.13
               c-0.176,0.075-0.305-0.091-0.58-0.138c-0.28-0.045-0.344,0.076-0.555-0.045c-0.214-0.123-0.563-0.215-0.563-0.215
               s-0.198-0.25-0.359-0.304c-0.159-0.054-0.075-0.229-0.381-0.235c-0.304-0.016-0.448-0.047-0.38,0.113
               c0.068,0.152,0.236,0.305,0.357,0.281c0.121-0.021,0.289-0.084,0.305,0.047c0.008,0.121,0.115,0.221-0.061,0.38
               c-0.168,0.153-0.381,0.077-0.525,0.144c-0.146,0.069-0.655,0.047-0.754,0.047c-0.1,0-0.26,0.184-0.373,0.289
               c-0.106,0.113-0.403,0.16-0.457,0.307c-0.061,0.145-0.069,0.279-0.069,0.38c0,0.104-0.067,0.151-0.212,0.235
               c-0.145,0.092-0.236,0.229-0.297,0.418c-0.055,0.191-0.428,0.281-0.26,0.441c0.176,0.151,0.352,0.168,0.236,0.313
               c-0.105,0.146-0.32,0.19-0.404,0.114c-0.075-0.084-0.235,0.021-0.356-0.016c-0.122-0.029-0.335-0.123-0.396-0.023
               c-0.053,0.106-0.258,0.093-0.235-0.068c0.021-0.15,0.114-0.242-0.093-0.389c-0.196-0.145-0.344-0.463-0.448-0.51
               c-0.101-0.045-0.19-0.084-0.335,0.022c-0.146,0.114-0.031,0.321-0.234,0.306c-0.207-0.009-0.396-0.306-0.496-0.113
               c-0.099,0.197,0,0.387-0.205,0.41c-0.199,0.021-0.404,0-0.496,0.174c-0.084,0.184-0.029,0.184-0.267,0.344
               c-0.235,0.152-0.372,0.273-0.419,0.428c-0.046,0.145-0.22,0.213-0.168,0.364c0.063,0.159,0.048,0.298-0.1,0.319
               c-0.144,0.022-0.228-0.168-0.35-0.062c-0.121,0.114-0.427,0.093-0.35,0.365c0.084,0.267,0.105,0.457,0.037,0.579
               c-0.068,0.13-0.326,0.205-0.344-0.038c-0.008-0.251-0.105-0.624-0.289-0.495c-0.183,0.124-0.158,0.275-0.396,0.411
               c-0.235,0.137-0.425,0.214-0.387,0.403c0.028,0.19,0.196,0.449,0.075,0.603c-0.121,0.146-0.281,0.084-0.396,0.356
               c-0.115,0.269-0.335,0.83-0.906,1.082c-0.578,0.244-0.914,0.312-1.082,0.289c-0.166-0.023-0.472,0.021-0.638,0.182
               c-0.176,0.154-0.153,0.33-0.396,0.283c-0.252-0.045-0.351-0.063-0.563,0.047c-0.214,0.099-0.351,0.22-0.474,0.15
               C208.364,92.843,207.724,93.194,207.724,93.194z M226.942,110.73c-0.039,0.007-0.213,0.014-0.184,0.145
               c0.024,0.129,0.092,0.15,0.069,0.244c-0.022,0.098-0.063,0.113-0.101,0.145c-0.039,0.021-0.105,0.168-0.015,0.197
               c0.091,0.037,0.099-0.037,0.205-0.092c0.106-0.047-0.046-0.166,0.017-0.22c0.053-0.056,0.234-0.124,0.196-0.198
               c-0.045-0.076-0.196-0.054-0.151-0.146c0.037-0.101,0.121-0.152,0.045-0.167C226.957,110.615,226.942,110.73,226.942,110.73z
                M223.981,110.546c-0.031,0-0.26,0.092-0.274,0.205c-0.017,0.121-0.168,0.356-0.031,0.334c0.146-0.028,0.123-0.144,0.229-0.213
               c0.107-0.06,0.35-0.304,0.244-0.326C224.041,110.523,223.981,110.546,223.981,110.546z M223.966,111.598
               c0,0-0.35,0.241-0.357,0.509c-0.017,0.268,0.009,0.436-0.047,0.646c-0.054,0.213-0.144,0.244-0.188,0.404
               c-0.038,0.159-0.054,0.327-0.031,0.471c0.022,0.154-0.188,0.313,0.031,0.373c0.22,0.063,0.259-0.029,0.381,0.168
               c0.099,0.063,0.603-0.213,0.729-0.281c0.121-0.068,0.358-0.008,0.496-0.145c0.129-0.138,0.234-0.168,0.388-0.266
               c0.161-0.107,0.26-0.253,0.343-0.328c0.077-0.084,0.26-0.326,0.389-0.494c0.139-0.169,0.557-0.984,0.389-0.99
               c-0.176-0.017-0.137,0.092-0.465,0.16c-0.328,0.061-0.539-0.068-0.73-0.017c-0.189,0.054-0.518,0.146-0.609,0.101
               c-0.091-0.047-0.188-0.123-0.304-0.244c-0.106-0.123-0.176-0.235-0.237-0.215C224.071,111.475,223.966,111.598,223.966,111.598z
                M225.336,86.449c-0.015,0.021-0.159,0.031-0.138,0.129c0.016,0.1-0.067,0.313,0.031,0.342c0.092,0.024,0.121,0.011,0.197,0.024
               c0.068,0.007,0.213-0.069,0.137-0.123c-0.082-0.053-0.1-0.053-0.061-0.112c0.03-0.069,0.092-0.313,0.023-0.32
               C225.45,86.38,225.336,86.449,225.336,86.449z M220.577,84.042c0,0-0.069,0.09-0.168,0.197c-0.09,0.114-0.243,0.198-0.213,0.297
               c0.039,0.1,0.046,0.184,0.222,0.107c0.174-0.078,0.251-0.039,0.366,0.006c0.119,0.055,0.099,0.176,0.234,0.039
               c0.137-0.139,0.435-0.344,0.455-0.404c0.024-0.068-0.021-0.402-0.289-0.234c-0.258,0.158-0.363,0.129-0.4,0.082
               C220.745,84.082,220.577,84.042,220.577,84.042z"/>
             <path fill="#808184" d="M245.338,111.399c0,0-0.358,0.122-0.593,0.328
               c-0.236,0.205-0.26,0.312-0.436,0.479c-0.166,0.174-0.381,0.318-0.547,0.344c-0.168,0.021-0.236,0.098-0.421,0.265
               c-0.175,0.167-0.099,0.039-0.639,0.449c-0.542,0.42-1.462,0.647-1.743,0.884s-0.496,0.334-0.969,0.51
               c-0.473,0.184-0.98,0.525-1.348,0.692c-0.365,0.159-0.594,0.351-0.808,0.459c-0.213,0.104-0.235,0.149-0.411,0.333
               c-0.175,0.176-0.253,0.191-0.318,0.229c-0.076,0.039-0.229,0.26-0.105,0.313c0.113,0.06,0.121,0,0.326,0
               c0.206-0.009,0.252-0.153,0.313-0.063c0.063,0.084-0.145,0.153,0.008,0.184c0.146,0.031,0.272-0.016,0.313,0.046
               c0.037,0.062-0.197,0.159,0.008,0.176c0.197,0.024,0.229,0.039,0.365,0.046c0.145,0.016,0.166,0.038,0.365-0.055
               c0.189-0.092,0.813-0.417,1.059-0.508c0.242-0.092,0.313-0.008,0.441-0.146c0.129-0.145-0.01-0.229,0.221-0.352
               c0.221-0.127,0.775-0.434,0.959-0.729c0.184-0.289-0.016-0.205,0.334-0.418c0.359-0.215,0.945-0.23,1.09-0.283
               s0.305-0.105,0.449-0.113c0.145,0,0.26,0.015,0.236-0.152s-0.063-0.297,0.061-0.343c0.122-0.054,0.596-0.212,0.738-0.411
               c0.145-0.189,0.021-0.053,0.449-0.355c0.418-0.307,0.64-0.246,0.77-0.436c0.131-0.197,0.105-0.129,0.229-0.352
               c0.121-0.221,0.305-0.439,0.313-0.479c0.008-0.038,0.016-0.269-0.145-0.16c-0.151,0.106-0.351,0.244-0.435,0.281
               c-0.084,0.038-0.313,0.151-0.222-0.03c0.092-0.189,0.268-0.271,0.16-0.313c-0.114-0.03-0.275-0.068-0.168-0.131
               c0.105-0.066,0.357-0.196,0.299-0.211C245.476,111.361,245.338,111.399,245.338,111.399 M243.131,119.721
               c-0.03,0.015-0.223,0.196-0.093,0.183c0.13-0.021,0.441-0.297,0.335-0.326C243.274,119.552,243.131,119.721,243.131,119.721
                M244.942,117.789c-0.047,0.022-0.137,0.137-0.151,0.188c-0.017,0.054-0.13,0.151-0.031,0.16
               c0.104,0.007,0.146,0.022,0.189-0.085c0.055-0.098,0.138-0.15,0.161-0.205c0.021-0.045,0.037-0.184-0.031-0.184
               S244.942,117.789,244.942,117.789 M251.187,114.369c-0.084,0-0.281-0.054-0.244,0.047c0.039,0.105,0.16,0.061,0.123,0.105
               c-0.039,0.045-0.291,0.167-0.199,0.206c0.092,0.045,0.076,0.054,0.168-0.017c0.091-0.067,0.152-0.084,0.229-0.151
               c0.076-0.069,0.207-0.222,0.122-0.244C251.299,114.292,251.187,114.369,251.187,114.369 M233.862,122.318
               c-0.053,0.016-0.28,0.15-0.158,0.16c0.122,0.007,0.503-0.223,0.372-0.252c-0.121-0.021-0.175-0.105-0.204-0.046
               C233.842,122.234,233.862,122.318,233.862,122.318 M233.323,120.537c-0.069,0.076-0.092,0.151-0.184,0.19
               c-0.092,0.045-0.236,0.112-0.176,0.188c0.063,0.045,0.273-0.152,0.32-0.174c0.045-0.031,0.318-0.146,0.281-0.222
               C233.537,120.437,233.323,120.537,233.323,120.537 M235.735,118.244c-0.067,0.023-0.325,0.244-0.189,0.26
               c0.137,0.021,0.467-0.297,0.383-0.344C235.845,118.115,235.735,118.244,235.735,118.244 M236.924,116.958
               c-0.03,0.045-0.075,0.092-0.03,0.137c0.055,0.046,0.063,0.099,0.123,0.054c0.061-0.038,0.151-0.259,0.075-0.274
               C237.008,116.85,236.924,116.958,236.924,116.958 M237.321,117.117c-0.008,0.076,0.029,0.146-0.122,0.252
               c-0.153,0.1-0.435,0.229-0.365,0.268c0.067,0.039,0.168,0.015,0.267-0.037c0.1-0.063,0.184-0.039,0.289-0.077
               c0.114-0.039,0.319-0.175,0.243-0.328c-0.067-0.157-0.183-0.206-0.252-0.176C237.313,117.056,237.321,117.117,237.321,117.117
                M248.733,105.781c-0.008,0.037,0.054,0.289,0.068,0.426c0.008,0.145-0.037,0.1-0.076,0.221c-0.031,0.131-0.021,0.189,0,0.375
               c0.023,0.182-0.067,0.471,0.045,0.791c0.123,0.318,0.1,0.357,0.055,0.609c-0.055,0.25-0.016,0.396-0.167,0.639
               c-0.152,0.252-0.335,0.533-0.472,0.646c-0.146,0.114-0.261,0.252-0.391,0.344c-0.136,0.1-0.136,0.121-0.35,0.183
               c-0.205,0.062-0.344,0.106-0.434,0.213c-0.1,0.099-0.259,0.328-0.123,0.374c0.145,0.053,0.213-0.046,0.252,0.129
               c0.037,0.176,0.112,0.229,0.184,0.243c0.066,0.017,0.205,0.146,0.121,0.26s-0.549,0.595-0.686,0.679
               c-0.146,0.084-0.39,0.15-0.365,0.252c0.016,0.09,0.105,0.045,0.151,0.113c0.046,0.075,0.062,0.167,0.188,0.129
               c0.139-0.037,0.229-0.046,0.443-0.152c0.213-0.114,0.547-0.252,0.829-0.539c0.288-0.297,0.243-0.291,0.716-0.557
               c0.473-0.275,0.441-0.609,0.578-0.77c0.137-0.153,0.32-0.229,0.494-0.26c0.178-0.022,0.298-0.016,0.383,0.061
               c0.092,0.07,0.242,0.063,0.229-0.066c-0.018-0.139,0.029-0.297,0.074-0.352c0.047-0.055,0.273-0.076,0.404-0.229
               c0.137-0.146,0.234-0.447,0.389-0.556c0.15-0.101,0.344-0.221,0.221-0.288c-0.122-0.068-0.571,0.158-0.838,0.28
               c-0.268,0.114-0.352,0.092-0.479,0.03c-0.13-0.055-0.32-0.022-0.396-0.131c-0.076-0.112-0.146-0.234-0.121-0.279
               c0.029-0.055,0.229-0.381,0.229-0.549c0.006-0.16,0-0.153-0.016-0.236c-0.01-0.084-0.031-0.184-0.1-0.137
               c-0.078,0.046-0.1,0.066-0.1,0.15s-0.015,0.131-0.054,0.198c-0.03,0.067-0.045,0.13-0.106,0.222s-0.16,0.197-0.16,0.074
               c0-0.119,0.062-0.174,0-0.229c-0.054-0.053-0.174-0.137-0.151-0.204c0.022-0.076,0.083-0.167,0.122-0.214
               c0.037-0.053,0.113-0.053,0.076-0.166c-0.031-0.115-0.092-0.207-0.018-0.291c0.069-0.092,0.139-0.174,0.139-0.189
               c0-0.023,0.188-0.313,0.1-0.419c-0.092-0.114-0.114-0.053-0.206-0.213c-0.091-0.168-0.401-0.03-0.41-0.222
               c0-0.189-0.022-0.465-0.153-0.487C248.733,105.666,248.733,105.781,248.733,105.781"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M245.338,111.399c0,0-0.358,0.122-0.593,0.328c-0.236,0.205-0.26,0.312-0.436,0.479c-0.166,0.174-0.381,0.318-0.547,0.344
               c-0.168,0.021-0.236,0.098-0.421,0.265c-0.175,0.167-0.099,0.039-0.639,0.449c-0.542,0.42-1.462,0.647-1.743,0.884
               s-0.496,0.334-0.969,0.51c-0.473,0.184-0.98,0.525-1.348,0.692c-0.365,0.159-0.594,0.351-0.808,0.459
               c-0.213,0.104-0.235,0.149-0.411,0.333c-0.175,0.176-0.253,0.191-0.318,0.229c-0.076,0.039-0.229,0.26-0.105,0.313
               c0.113,0.06,0.121,0,0.326,0c0.206-0.009,0.252-0.153,0.313-0.063c0.063,0.084-0.145,0.153,0.008,0.184
               c0.146,0.031,0.272-0.016,0.313,0.046c0.037,0.062-0.197,0.159,0.008,0.176c0.197,0.024,0.229,0.039,0.365,0.046
               c0.145,0.016,0.166,0.038,0.365-0.055c0.189-0.092,0.813-0.417,1.059-0.508c0.242-0.092,0.313-0.008,0.441-0.146
               c0.129-0.145-0.01-0.229,0.221-0.352c0.221-0.127,0.775-0.434,0.959-0.729c0.184-0.289-0.016-0.205,0.334-0.418
               c0.359-0.215,0.945-0.23,1.09-0.283s0.305-0.105,0.449-0.113c0.145,0,0.26,0.015,0.236-0.152s-0.063-0.297,0.061-0.343
               c0.122-0.054,0.596-0.212,0.738-0.411c0.145-0.189,0.021-0.053,0.449-0.355c0.418-0.307,0.64-0.246,0.77-0.436
               c0.131-0.197,0.105-0.129,0.229-0.352c0.121-0.221,0.305-0.439,0.313-0.479c0.008-0.038,0.016-0.269-0.145-0.16
               c-0.151,0.106-0.351,0.244-0.435,0.281c-0.084,0.038-0.313,0.151-0.222-0.03c0.092-0.189,0.268-0.271,0.16-0.313
               c-0.114-0.03-0.275-0.068-0.168-0.131c0.105-0.066,0.357-0.196,0.299-0.211C245.476,111.361,245.338,111.399,245.338,111.399z
                M243.131,119.721c-0.03,0.015-0.223,0.196-0.093,0.183c0.13-0.021,0.441-0.297,0.335-0.326
               C243.274,119.552,243.131,119.721,243.131,119.721z M244.942,117.789c-0.047,0.022-0.137,0.137-0.151,0.188
               c-0.017,0.054-0.13,0.151-0.031,0.16c0.104,0.007,0.146,0.022,0.189-0.085c0.055-0.098,0.138-0.15,0.161-0.205
               c0.021-0.045,0.037-0.184-0.031-0.184S244.942,117.789,244.942,117.789z M251.187,114.369c-0.084,0-0.281-0.054-0.244,0.047
               c0.039,0.105,0.16,0.061,0.123,0.105c-0.039,0.045-0.291,0.167-0.199,0.206c0.092,0.045,0.076,0.054,0.168-0.017
               c0.091-0.067,0.152-0.084,0.229-0.151c0.076-0.069,0.207-0.222,0.122-0.244C251.299,114.292,251.187,114.369,251.187,114.369z
                M233.862,122.318c-0.053,0.016-0.28,0.15-0.158,0.16c0.122,0.007,0.503-0.223,0.372-0.252
               c-0.121-0.021-0.175-0.105-0.204-0.046C233.842,122.234,233.862,122.318,233.862,122.318z M233.323,120.537
               c-0.069,0.076-0.092,0.151-0.184,0.19c-0.092,0.045-0.236,0.112-0.176,0.188c0.063,0.045,0.273-0.152,0.32-0.174
               c0.045-0.031,0.318-0.146,0.281-0.222C233.537,120.437,233.323,120.537,233.323,120.537z M235.735,118.244
               c-0.067,0.023-0.325,0.244-0.189,0.26c0.137,0.021,0.467-0.297,0.383-0.344C235.845,118.115,235.735,118.244,235.735,118.244z
                M236.924,116.958c-0.03,0.045-0.075,0.092-0.03,0.137c0.055,0.046,0.063,0.099,0.123,0.054
               c0.061-0.038,0.151-0.259,0.075-0.274C237.008,116.85,236.924,116.958,236.924,116.958z M237.321,117.117
               c-0.008,0.076,0.029,0.146-0.122,0.252c-0.153,0.1-0.435,0.229-0.365,0.268c0.067,0.039,0.168,0.015,0.267-0.037
               c0.1-0.063,0.184-0.039,0.289-0.077c0.114-0.039,0.319-0.175,0.243-0.328c-0.067-0.157-0.183-0.206-0.252-0.176
               C237.313,117.056,237.321,117.117,237.321,117.117z M248.733,105.781c-0.008,0.037,0.054,0.289,0.068,0.426
               c0.008,0.145-0.037,0.1-0.076,0.221c-0.031,0.131-0.021,0.189,0,0.375c0.023,0.182-0.067,0.471,0.045,0.791
               c0.123,0.318,0.1,0.357,0.055,0.609c-0.055,0.25-0.016,0.396-0.167,0.639c-0.152,0.252-0.335,0.533-0.472,0.646
               c-0.146,0.114-0.261,0.252-0.391,0.344c-0.136,0.1-0.136,0.121-0.35,0.183c-0.205,0.062-0.344,0.106-0.434,0.213
               c-0.1,0.099-0.259,0.328-0.123,0.374c0.145,0.053,0.213-0.046,0.252,0.129c0.037,0.176,0.112,0.229,0.184,0.243
               c0.066,0.017,0.205,0.146,0.121,0.26s-0.549,0.595-0.686,0.679c-0.146,0.084-0.39,0.15-0.365,0.252
               c0.016,0.09,0.105,0.045,0.151,0.113c0.046,0.075,0.062,0.167,0.188,0.129c0.139-0.037,0.229-0.046,0.443-0.152
               c0.213-0.114,0.547-0.252,0.829-0.539c0.288-0.297,0.243-0.291,0.716-0.557c0.473-0.275,0.441-0.609,0.578-0.77
               c0.137-0.153,0.32-0.229,0.494-0.26c0.178-0.022,0.298-0.016,0.383,0.061c0.092,0.07,0.242,0.063,0.229-0.066
               c-0.018-0.139,0.029-0.297,0.074-0.352c0.047-0.055,0.273-0.076,0.404-0.229c0.137-0.146,0.234-0.447,0.389-0.556
               c0.15-0.101,0.344-0.221,0.221-0.288c-0.122-0.068-0.571,0.158-0.838,0.28c-0.268,0.114-0.352,0.092-0.479,0.03
               c-0.13-0.055-0.32-0.022-0.396-0.131c-0.076-0.112-0.146-0.234-0.121-0.279c0.029-0.055,0.229-0.381,0.229-0.549
               c0.006-0.16,0-0.153-0.016-0.236c-0.01-0.084-0.031-0.184-0.1-0.137c-0.078,0.046-0.1,0.066-0.1,0.15s-0.015,0.131-0.054,0.198
               c-0.03,0.067-0.045,0.13-0.106,0.222s-0.16,0.197-0.16,0.074c0-0.119,0.062-0.174,0-0.229c-0.054-0.053-0.174-0.137-0.151-0.204
               c0.022-0.076,0.083-0.167,0.122-0.214c0.037-0.053,0.113-0.053,0.076-0.166c-0.031-0.115-0.092-0.207-0.018-0.291
               c0.069-0.092,0.139-0.174,0.139-0.189c0-0.023,0.188-0.313,0.1-0.419c-0.092-0.114-0.114-0.053-0.206-0.213
               c-0.091-0.168-0.401-0.03-0.41-0.222c0-0.189-0.022-0.465-0.153-0.487C248.733,105.666,248.733,105.781,248.733,105.781z"/>
               <path fill="#808184" d="M234.573,75.393c0,0-0.139,0.053-0.176,0.114
               c-0.039,0.06-0.244,0.158-0.146,0.197c0.1,0.037,0.16-0.076,0.244-0.076c0.092,0,0.234,0.045,0.342,0.068
               c0.107,0.021,0.457-0.138,0.307-0.26C234.983,75.326,234.573,75.393,234.573,75.393 M237.017,82.222
               c-0.054,0.091-0.138,0.189-0.023,0.229c0.107,0.037,0.268,0.008,0.252,0.067c-0.008,0.054-0.105,0.091,0.029,0.114
               c0.131,0.029,0.047-0.055,0.168,0.068c0.123,0.12,0.139,0.174,0.152,0.243c0.008,0.076,0.008,0.197,0.107,0.16
               c0.099-0.03,0.143-0.114,0.061-0.174c-0.082-0.063-0.138-0.063-0.152-0.185c-0.008-0.123-0.037-0.229-0.075-0.281
               c-0.03-0.062-0.138-0.146-0.222-0.153c-0.084-0.006-0.146,0-0.168-0.045c-0.016-0.046-0.054-0.099-0.068-0.093
               C237.062,82.175,237.017,82.222,237.017,82.222 M240.976,78.3c-0.031,0.023-0.146,0.152-0.054,0.32
               c0.099,0.158,0.113,0.106,0.093,0.266c-0.023,0.169-0.054,0.244,0.014,0.357c0.07,0.123,0.207,0.152,0.26,0.236
               c0.054,0.094-0.038,0.152,0.046,0.268c0.083,0.121,0.235,0.221,0.29,0.235c0.045,0.015,0.213,0.113,0.266-0.038
               c0.062-0.152,0.215-0.076,0.031-0.304c-0.189-0.229-0.29-0.329-0.357-0.466c-0.068-0.137-0.138-0.403-0.25-0.403
               c-0.115,0-0.207-0.067-0.229-0.129c-0.03-0.062,0.091-0.19,0.007-0.312C241.015,78.208,240.976,78.3,240.976,78.3
                M229.897,76.033c0,0-0.168,1.957-0.069,2.467c0.106,0.502-0.093,1.006-0.113,1.125c-0.024,0.117-0.138,0.224-0.099,0.39
               c0.045,0.169-0.094,2.172-0.094,2.172s0.375-0.062,0.549-0.009c0.176,0.054,0.357-0.047,0.504,0
               c0.137,0.046,0.229,0.121,0.318,0.076c0.092-0.045,0.351-0.137,0.435-0.213c0.084-0.069,0.213-0.162,0.092-0.291
               c-0.112-0.127-0.305-0.119-0.228-0.234c0.074-0.115,0.174-0.082,0.304-0.167c0.129-0.077,0.077-0.252,0.228-0.261
               c0.153-0.014,0.207,0.017,0.237-0.045c0.022-0.061,0.168-0.213,0.25-0.19c0.084,0.022,0.138-0.044,0.396-0.044
               c0.27,0,0.238,0.15,0.541,0.195c0.307,0.045,0.488,0.063,0.633,0.291c0.146,0.229,0.113,0.44,0.213,0.539
               c0.1,0.092,0.008,0.069,0.215,0.307c0.205,0.229,0.121,0.137,0.258,0.35c0.139,0.205,0.268,0.328,0.381,0.426
               c0.115,0.107,0.153,0.115,0.457,0.153c0.305,0.046,0.236,0.168,0.488,0.188c0.242,0.025,0.396,0,0.523,0.084
               c0.131,0.078,0.344,0.039,0.436,0.113c0.092,0.069,0.076,0.185,0.242,0.131c0.16-0.045,0.428-0.15,0.297-0.221
               c-0.137-0.076-0.145-0.113-0.006-0.121c0.137,0,0.213-0.092,0.098-0.101c-0.121-0.007-0.242,0.106-0.373,0
               c-0.129-0.104-0.447-0.205-0.363-0.297c0.074-0.099,0.349-0.183,0.189-0.229c-0.168-0.045-0.168,0.047-0.313-0.021
               c-0.151-0.076-0.306-0.153-0.326-0.198c-0.016-0.037,0.168-0.222,0.066-0.312c-0.092-0.092-0.145,0.008-0.305-0.008
               c-0.152-0.008-0.229,0.066-0.258-0.076c-0.023-0.146-0.063-0.313-0.145-0.39c-0.093-0.075,0.006-0.099-0.093-0.313
               c-0.106-0.213-0.176-0.258-0.305-0.317c-0.13-0.054-0.313-0.345-0.419-0.45c-0.106-0.105,0.076-0.068-0.114-0.16
               c-0.188-0.092-0.235-0.531,0.047-0.523c0.281,0.015,0.531,0.105,0.578-0.055c0.045-0.168,0.101-0.229-0.054-0.342
               c-0.151-0.107-0.038-0.175-0.305-0.306c-0.268-0.128-0.197-0.045-0.457-0.177c-0.268-0.129-0.313-0.271-0.381-0.295
               s-0.435,0.166-0.42-0.146c0.01-0.319,0.069-0.221-0.006-0.442c-0.076-0.219,0.037-0.121-0.336-0.401
               c-0.381-0.282-0.436-0.267-0.73-0.429c-0.297-0.168-0.389-0.213-0.533-0.258c-0.137-0.054-0.174-0.207-0.342-0.236
               c-0.162-0.036-1.104-0.297-1.311-0.479S229.897,76.033,229.897,76.033 M234.647,78.455c0.008,0.063,0.074,0.318,0.129,0.213
               c0.055-0.101,0.062-0.344-0.016-0.357C234.687,78.287,234.647,78.455,234.647,78.455 M235.76,78.705c0,0-0.122,0.146,0.007,0.26
               c0.139,0.121,0.237,0.031,0.366,0.121c0.138,0.084,0.214,0.129,0.335,0.16c0.114,0.031,0.19-0.016,0.268,0.098
               c0.084,0.123,0.166,0.177,0.272,0.162c0.115-0.008,0,0.099,0.412,0.015c0.418-0.093,0.381-0.235,0.578-0.319
               c0.205-0.084,0.235-0.084,0.313-0.236c0.074-0.15,0.285-0.129,0.379-0.158c0.101-0.023,0.183-0.076,0.213-0.189
               c0.023-0.123-0.213-0.306-0.061-0.352c0.145-0.055,0.313-0.184,0.313-0.305c0-0.123,0.076-0.223-0.061-0.313
               c-0.146-0.099-0.207-0.067-0.344-0.045c-0.145,0.021-0.145-0.106-0.259-0.062c-0.122,0.037-0.138,0.219-0.062,0.344
               c0.067,0.113,0.114,0.234,0.022,0.303c-0.099,0.063-0.183-0.016-0.272,0.085c-0.093,0.093-0.07,0.076-0.199,0.245
               c-0.129,0.166-0.183,0.212-0.252,0.182c-0.066-0.039-0.076-0.115-0.196-0.031c-0.122,0.084-0.274,0.152-0.306,0.076
               c-0.021-0.084,0.008-0.221-0.138-0.129c-0.146,0.1-0.137,0.166-0.282,0.174c-0.144,0.018-0.267,0.055-0.396,0
               c-0.129-0.045-0.228-0.074-0.327-0.066c-0.091,0-0.167,0-0.206-0.031C235.845,78.66,235.76,78.705,235.76,78.705
                M235.623,75.673c0,0-0.267,0.068-0.237,0.123c0.032,0.061,0.047,0.061,0.146,0.066c0.104,0.008,0.297-0.175,0.248-0.203
               C235.745,75.628,235.623,75.673,235.623,75.673 M237.793,75.957c0.106,0.076,0.158,0.076,0.205,0.183
               c0.054,0.101,0.153,0.175,0.244,0.213c0.099,0.04,0.251,0.192,0.35,0.298c0.091,0.114,0.183,0.198,0.289,0.213
               c0.106,0.016,0.16,0.055,0.177,0.07c0.006,0.014,0.242,0.143,0.287,0.324c0.039,0.184,0.055,0.283,0.063,0.406
               c0,0.129,0,0.303,0.075,0.387c0.068,0.084,0.168,0.137,0.177,0.017c0.007-0.114,0.045-0.252,0.052-0.336
               c0.008-0.084,0.008-0.138-0.016-0.19c-0.029-0.062-0.158-0.084-0.189-0.258c-0.029-0.177-0.229-0.328-0.374-0.427
               c-0.151-0.099-0.251-0.183-0.272-0.222c-0.022-0.039-0.373-0.266-0.473-0.32c-0.1-0.061-0.197-0.045-0.25-0.15
               c-0.063-0.101-0.055-0.145-0.107-0.215c-0.061-0.066-0.258-0.234-0.326-0.182C237.633,75.828,237.793,75.957,237.793,75.957
                M237.283,75.873c0,0-0.138,0.084-0.031,0.114c0.114,0.038,0.062,0.038,0.153,0.067c0.091,0.039,0.287,0.123,0.205-0.061
               c-0.084-0.183-0.146-0.229-0.19-0.229C237.381,75.773,237.283,75.873,237.283,75.873"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M234.573,75.393c0,0-0.139,0.053-0.176,0.114c-0.039,0.06-0.244,0.158-0.146,0.197c0.1,0.037,0.16-0.076,0.244-0.076
               c0.092,0,0.234,0.045,0.342,0.068c0.107,0.021,0.457-0.138,0.307-0.26C234.983,75.326,234.573,75.393,234.573,75.393z
                M237.017,82.222c-0.054,0.091-0.138,0.189-0.023,0.229c0.107,0.037,0.268,0.008,0.252,0.067
               c-0.008,0.054-0.105,0.091,0.029,0.114c0.131,0.029,0.047-0.055,0.168,0.068c0.123,0.12,0.139,0.174,0.152,0.243
               c0.008,0.076,0.008,0.197,0.107,0.16c0.099-0.03,0.143-0.114,0.061-0.174c-0.082-0.063-0.138-0.063-0.152-0.185
               c-0.008-0.123-0.037-0.229-0.075-0.281c-0.03-0.062-0.138-0.146-0.222-0.153c-0.084-0.006-0.146,0-0.168-0.045
               c-0.016-0.046-0.054-0.099-0.068-0.093C237.062,82.175,237.017,82.222,237.017,82.222z M240.976,78.3
               c-0.031,0.023-0.146,0.152-0.054,0.32c0.099,0.158,0.113,0.106,0.093,0.266c-0.023,0.169-0.054,0.244,0.014,0.357
               c0.07,0.123,0.207,0.152,0.26,0.236c0.054,0.094-0.038,0.152,0.046,0.268c0.083,0.121,0.235,0.221,0.29,0.235
               c0.045,0.015,0.213,0.113,0.266-0.038c0.062-0.152,0.215-0.076,0.031-0.304c-0.189-0.229-0.29-0.329-0.357-0.466
               c-0.068-0.137-0.138-0.403-0.25-0.403c-0.115,0-0.207-0.067-0.229-0.129c-0.03-0.062,0.091-0.19,0.007-0.312
               C241.015,78.208,240.976,78.3,240.976,78.3z M229.897,76.033c0,0-0.168,1.957-0.069,2.467c0.106,0.502-0.093,1.006-0.113,1.125
               c-0.024,0.117-0.138,0.224-0.099,0.39c0.045,0.169-0.094,2.172-0.094,2.172s0.375-0.062,0.549-0.009
               c0.176,0.054,0.357-0.047,0.504,0c0.137,0.046,0.229,0.121,0.318,0.076c0.092-0.045,0.351-0.137,0.435-0.213
               c0.084-0.069,0.213-0.162,0.092-0.291c-0.112-0.127-0.305-0.119-0.228-0.234c0.074-0.115,0.174-0.082,0.304-0.167
               c0.129-0.077,0.077-0.252,0.228-0.261c0.153-0.014,0.207,0.017,0.237-0.045c0.022-0.061,0.168-0.213,0.25-0.19
               c0.084,0.022,0.138-0.044,0.396-0.044c0.27,0,0.238,0.15,0.541,0.195c0.307,0.045,0.488,0.063,0.633,0.291
               c0.146,0.229,0.113,0.44,0.213,0.539c0.1,0.092,0.008,0.069,0.215,0.307c0.205,0.229,0.121,0.137,0.258,0.35
               c0.139,0.205,0.268,0.328,0.381,0.426c0.115,0.107,0.153,0.115,0.457,0.153c0.305,0.046,0.236,0.168,0.488,0.188
               c0.242,0.025,0.396,0,0.523,0.084c0.131,0.078,0.344,0.039,0.436,0.113c0.092,0.069,0.076,0.185,0.242,0.131
               c0.16-0.045,0.428-0.15,0.297-0.221c-0.137-0.076-0.145-0.113-0.006-0.121c0.137,0,0.213-0.092,0.098-0.101
               c-0.121-0.007-0.242,0.106-0.373,0c-0.129-0.104-0.447-0.205-0.363-0.297c0.074-0.099,0.349-0.183,0.189-0.229
               c-0.168-0.045-0.168,0.047-0.313-0.021c-0.151-0.076-0.306-0.153-0.326-0.198c-0.016-0.037,0.168-0.222,0.066-0.312
               c-0.092-0.092-0.145,0.008-0.305-0.008c-0.152-0.008-0.229,0.066-0.258-0.076c-0.023-0.146-0.063-0.313-0.145-0.39
               c-0.093-0.075,0.006-0.099-0.093-0.313c-0.106-0.213-0.176-0.258-0.305-0.317c-0.13-0.054-0.313-0.345-0.419-0.45
               c-0.106-0.105,0.076-0.068-0.114-0.16c-0.188-0.092-0.235-0.531,0.047-0.523c0.281,0.015,0.531,0.105,0.578-0.055
               c0.045-0.168,0.101-0.229-0.054-0.342c-0.151-0.107-0.038-0.175-0.305-0.306c-0.268-0.128-0.197-0.045-0.457-0.177
               c-0.268-0.129-0.313-0.271-0.381-0.295s-0.435,0.166-0.42-0.146c0.01-0.319,0.069-0.221-0.006-0.442
               c-0.076-0.219,0.037-0.121-0.336-0.401c-0.381-0.282-0.436-0.267-0.73-0.429c-0.297-0.168-0.389-0.213-0.533-0.258
               c-0.137-0.054-0.174-0.207-0.342-0.236c-0.162-0.036-1.104-0.297-1.311-0.479S229.897,76.033,229.897,76.033z M234.647,78.455
               c0.008,0.063,0.074,0.318,0.129,0.213c0.055-0.101,0.062-0.344-0.016-0.357C234.687,78.287,234.647,78.455,234.647,78.455z
                M235.76,78.705c0,0-0.122,0.146,0.007,0.26c0.139,0.121,0.237,0.031,0.366,0.121c0.138,0.084,0.214,0.129,0.335,0.16
               c0.114,0.031,0.19-0.016,0.268,0.098c0.084,0.123,0.166,0.177,0.272,0.162c0.115-0.008,0,0.099,0.412,0.015
               c0.418-0.093,0.381-0.235,0.578-0.319c0.205-0.084,0.235-0.084,0.313-0.236c0.074-0.15,0.285-0.129,0.379-0.158
               c0.101-0.023,0.183-0.076,0.213-0.189c0.023-0.123-0.213-0.306-0.061-0.352c0.145-0.055,0.313-0.184,0.313-0.305
               c0-0.123,0.076-0.223-0.061-0.313c-0.146-0.099-0.207-0.067-0.344-0.045c-0.145,0.021-0.145-0.106-0.259-0.062
               c-0.122,0.037-0.138,0.219-0.062,0.344c0.067,0.113,0.114,0.234,0.022,0.303c-0.099,0.063-0.183-0.016-0.272,0.085
               c-0.093,0.093-0.07,0.076-0.199,0.245c-0.129,0.166-0.183,0.212-0.252,0.182c-0.066-0.039-0.076-0.115-0.196-0.031
               c-0.122,0.084-0.274,0.152-0.306,0.076c-0.021-0.084,0.008-0.221-0.138-0.129c-0.146,0.1-0.137,0.166-0.282,0.174
               c-0.144,0.018-0.267,0.055-0.396,0c-0.129-0.045-0.228-0.074-0.327-0.066c-0.091,0-0.167,0-0.206-0.031
               C235.845,78.66,235.76,78.705,235.76,78.705z M235.623,75.673c0,0-0.267,0.068-0.237,0.123c0.032,0.061,0.047,0.061,0.146,0.066
               c0.104,0.008,0.297-0.175,0.248-0.203C235.745,75.628,235.623,75.673,235.623,75.673z M237.793,75.957
               c0.106,0.076,0.158,0.076,0.205,0.183c0.054,0.101,0.153,0.175,0.244,0.213c0.099,0.04,0.251,0.192,0.35,0.298
               c0.091,0.114,0.183,0.198,0.289,0.213c0.106,0.016,0.16,0.055,0.177,0.07c0.006,0.014,0.242,0.143,0.287,0.324
               c0.039,0.184,0.055,0.283,0.063,0.406c0,0.129,0,0.303,0.075,0.387c0.068,0.084,0.168,0.137,0.177,0.017
               c0.007-0.114,0.045-0.252,0.052-0.336c0.008-0.084,0.008-0.138-0.016-0.19c-0.029-0.062-0.158-0.084-0.189-0.258
               c-0.029-0.177-0.229-0.328-0.374-0.427c-0.151-0.099-0.251-0.183-0.272-0.222c-0.022-0.039-0.373-0.266-0.473-0.32
               c-0.1-0.061-0.197-0.045-0.25-0.15c-0.063-0.101-0.055-0.145-0.107-0.215c-0.061-0.066-0.258-0.234-0.326-0.182
               C237.633,75.828,237.793,75.957,237.793,75.957z M237.283,75.873c0,0-0.138,0.084-0.031,0.114
               c0.114,0.038,0.062,0.038,0.153,0.067c0.091,0.039,0.287,0.123,0.205-0.061c-0.084-0.183-0.146-0.229-0.19-0.229
               C237.381,75.773,237.283,75.873,237.283,75.873z"/>
               <path fill="#808184" d="M249.785,87.317c0,0-0.185,0.19-0.106,0.326
               c0.069,0.138-0.039,0.317,0.021,0.428c0.054,0.104,0.085,0.251,0.177,0.204c0.091-0.046,0.075-0.145,0.151-0.061
               c0.084,0.092,0.13,0.305,0.16,0.084c0.028-0.213,0.09-0.707-0.03-0.707c-0.114,0-0.236,0.197-0.236,0.082
               c0-0.121,0.099-0.356-0.022-0.356H249.785z M251.117,91.669c-0.038,0.023-0.228,0.146-0.114,0.246
               c0.105,0.1,0.205,0.135,0.205,0.061c0.008-0.076,0.107-0.335,0.023-0.357C251.147,91.595,251.117,91.669,251.117,91.669
                M250.987,91.054c-0.022,0.084-0.062,0.214,0.016,0.267c0.076,0.048,0.198,0.146,0.213,0.038
               c0.016-0.113,0.016-0.441-0.084-0.436C251.024,90.923,250.987,91.054,250.987,91.054 M250.66,90.012
               c-0.022,0.021-0.106,0.196-0.054,0.219c0.045,0.017,0.054-0.015,0.115,0.101c0.059,0.114,0.084,0.121,0.137,0.062
               c0.045-0.062,0.021-0.435-0.047-0.463C250.752,89.906,250.66,90.012,250.66,90.012 M250.729,89.183
               c-0.016,0.027-0.1,0.128,0.008,0.15c0.113,0.031,0.175,0.067,0.189,0.031c0.008-0.047-0.062-0.262-0.121-0.269
               C250.752,89.083,250.729,89.183,250.729,89.183 M250.707,88.694c-0.024,0.03-0.146,0.13-0.024,0.177
               c0.123,0.045,0.176,0.075,0.237-0.009c0.067-0.084,0.037-0.305-0.03-0.312C250.813,88.541,250.707,88.694,250.707,88.694
                M250.735,87.925c-0.067,0.029-0.28,0.092-0.189,0.137c0.092,0.046,0.13,0.1,0.206,0.046c0.075-0.046,0.243-0.198,0.159-0.228
               C250.819,87.849,250.735,87.925,250.735,87.925 M250.052,88.565c-0.031,0.045,0.022,0.222,0.063,0.228
               c0.037,0,0.021,0,0.029,0.146c0.008,0.137,0.055,0.25,0.145,0.205c0.084-0.046,0.252-0.039,0.197-0.152
               c-0.061-0.104-0.029-0.121-0.106-0.197c-0.067-0.068-0.121-0.242-0.228-0.273C250.052,88.481,250.052,88.565,250.052,88.565"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M249.785,87.317c0,0-0.185,0.19-0.106,0.326c0.069,0.138-0.039,0.317,0.021,0.428c0.054,0.104,0.085,0.251,0.177,0.204
               c0.091-0.046,0.075-0.145,0.151-0.061c0.084,0.092,0.13,0.305,0.16,0.084c0.028-0.213,0.09-0.707-0.03-0.707
               c-0.114,0-0.236,0.197-0.236,0.082c0-0.121,0.099-0.356-0.022-0.356H249.785z M251.117,91.669
               c-0.038,0.023-0.228,0.146-0.114,0.246c0.105,0.1,0.205,0.135,0.205,0.061c0.008-0.076,0.107-0.335,0.023-0.357
               C251.147,91.595,251.117,91.669,251.117,91.669z M250.987,91.054c-0.022,0.084-0.062,0.214,0.016,0.267
               c0.076,0.048,0.198,0.146,0.213,0.038c0.016-0.113,0.016-0.441-0.084-0.436C251.024,90.923,250.987,91.054,250.987,91.054z
                M250.66,90.012c-0.022,0.021-0.106,0.196-0.054,0.219c0.045,0.017,0.054-0.015,0.115,0.101
               c0.059,0.114,0.084,0.121,0.137,0.062c0.045-0.062,0.021-0.435-0.047-0.463C250.752,89.906,250.66,90.012,250.66,90.012z
                M250.729,89.183c-0.016,0.027-0.1,0.128,0.008,0.15c0.113,0.031,0.175,0.067,0.189,0.031c0.008-0.047-0.062-0.262-0.121-0.269
               C250.752,89.083,250.729,89.183,250.729,89.183z M250.707,88.694c-0.024,0.03-0.146,0.13-0.024,0.177
               c0.123,0.045,0.176,0.075,0.237-0.009c0.067-0.084,0.037-0.305-0.03-0.312C250.813,88.541,250.707,88.694,250.707,88.694z
                M250.735,87.925c-0.067,0.029-0.28,0.092-0.189,0.137c0.092,0.046,0.13,0.1,0.206,0.046c0.075-0.046,0.243-0.198,0.159-0.228
               C250.819,87.849,250.735,87.925,250.735,87.925z M250.052,88.565c-0.031,0.045,0.022,0.222,0.063,0.228
               c0.037,0,0.021,0,0.029,0.146c0.008,0.137,0.055,0.25,0.145,0.205c0.084-0.046,0.252-0.039,0.197-0.152
               c-0.061-0.104-0.029-0.121-0.106-0.197c-0.067-0.068-0.121-0.242-0.228-0.273C250.052,88.481,250.052,88.565,250.052,88.565z"/>
               <path fill="#808184" d="M242.394,79.808c0,0-0.191,0.084,0.006,0.223
               c0.205,0.143,0.213,0.157,0.313,0.289c0.105,0.12,0.281,0.234,0.352,0.204c0.061-0.028,0.054-0.112-0.039-0.22
               c-0.09-0.116-0.221-0.282-0.272-0.328c-0.056-0.053-0.146-0.234-0.198-0.243C242.498,79.724,242.394,79.808,242.394,79.808
                M246.174,83.19c-0.028,0-0.233-0.054-0.157,0.067c0.075,0.121,0.151,0.076,0.184,0.152c0.021,0.076,0.091,0.221,0.174,0.26
               c0.077,0.031,0.268,0.115,0.357,0.099c0.092-0.017,0.199-0.106,0.1-0.175c-0.092-0.076-0.031-0.215-0.159-0.215
               c-0.13,0-0.214,0.039-0.272-0.029C246.342,83.272,246.174,83.19,246.174,83.19 M245.681,81.399
               c-0.084,0.106-0.029,0.132,0,0.269c0.039,0.138,0.023,0.205,0.077,0.373c0.052,0.174,0.099,0.252,0.166,0.313
               c0.068,0.061,0.152,0.068,0.206,0.184c0.061,0.105,0.114,0.281,0.168,0.213c0.055-0.076,0.021-0.113-0.015-0.229
               c-0.031-0.122-0.138-0.122-0.153-0.221c-0.016-0.106,0.061-0.205-0.029-0.305c-0.093-0.1-0.16-0.123-0.152-0.215
               c0.007-0.099,0.037-0.189-0.023-0.296c-0.061-0.101-0.09-0.19-0.145-0.19C245.726,81.287,245.681,81.399,245.681,81.399
                M244.836,82.292c-0.022,0.015-0.122,0.076-0.114,0.183c0.008,0.104,0.03,0.183,0.076,0.267c0.053,0.076,0.128,0.105,0.26,0.082
               c0.129-0.021,0.203,0.032,0.351,0.039c0.137,0.008,0.229,0.092,0.272,0.008c0.039-0.084,0.055-0.213-0.045-0.281
               c-0.099-0.063-0.145-0.168-0.328-0.16c-0.189,0-0.313,0.031-0.318-0.016c-0.01-0.053-0.016-0.183-0.069-0.168
               C244.865,82.251,244.836,82.292,244.836,82.292 M243.717,80.517c0,0.076,0.046,0.068,0.175,0.189s0.137,0.205,0.291,0.328
               c0.157,0.113,0.35,0.272,0.416,0.313c0.069,0.037,0.215,0.076,0.284,0.129c0.066,0.055,0.242,0.152,0.136-0.016
               c-0.1-0.16-0.106-0.121-0.213-0.236c-0.105-0.105-0.26-0.271-0.382-0.396c-0.114-0.113-0.259-0.183-0.319-0.228
               c-0.054-0.039-0.197-0.161-0.289-0.161C243.731,80.44,243.717,80.517,243.717,80.517 M242.817,80.998
               c-0.075,0.068-0.221,0.135-0.129,0.228c0.09,0.103,0.205,0.13,0.234,0.153c0.023,0.015,0.047-0.069,0.152,0.037
               c0.113,0.1,0.146,0.076,0.236,0.197c0.1,0.115,0.373,0.131,0.268,0.047c-0.107-0.093-0.207-0.037-0.244-0.176
               c-0.031-0.13-0.146-0.234-0.146-0.305c0-0.061-0.006-0.213-0.09-0.137c-0.076,0.076-0.191,0.144-0.191,0.076v-0.16
               L242.817,80.998z M242.36,80.722c0.016,0.099,0.16,0.105,0.054,0.205c-0.106,0.107-0.069,0.404-0.021,0.268
               c0.035-0.137,0.25-0.183,0.242-0.289c-0.009-0.113-0.061-0.373-0.169-0.352C242.36,80.578,242.36,80.722,242.36,80.722"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M242.394,79.808c0,0-0.191,0.084,0.006,0.223c0.205,0.143,0.213,0.157,0.313,0.289c0.105,0.12,0.281,0.234,0.352,0.204
               c0.061-0.028,0.054-0.112-0.039-0.22c-0.09-0.116-0.221-0.282-0.272-0.328c-0.056-0.053-0.146-0.234-0.198-0.243
               C242.498,79.724,242.394,79.808,242.394,79.808z M246.174,83.19c-0.028,0-0.233-0.054-0.157,0.067
               c0.075,0.121,0.151,0.076,0.184,0.152c0.021,0.076,0.091,0.221,0.174,0.26c0.077,0.031,0.268,0.115,0.357,0.099
               c0.092-0.017,0.199-0.106,0.1-0.175c-0.092-0.076-0.031-0.215-0.159-0.215c-0.13,0-0.214,0.039-0.272-0.029
               C246.342,83.272,246.174,83.19,246.174,83.19z M245.681,81.399c-0.084,0.106-0.029,0.132,0,0.269
               c0.039,0.138,0.023,0.205,0.077,0.373c0.052,0.174,0.099,0.252,0.166,0.313c0.068,0.061,0.152,0.068,0.206,0.184
               c0.061,0.105,0.114,0.281,0.168,0.213c0.055-0.076,0.021-0.113-0.015-0.229c-0.031-0.122-0.138-0.122-0.153-0.221
               c-0.016-0.106,0.061-0.205-0.029-0.305c-0.093-0.1-0.16-0.123-0.152-0.215c0.007-0.099,0.037-0.189-0.023-0.296
               c-0.061-0.101-0.09-0.19-0.145-0.19C245.726,81.287,245.681,81.399,245.681,81.399z M244.836,82.292
               c-0.022,0.015-0.122,0.076-0.114,0.183c0.008,0.104,0.03,0.183,0.076,0.267c0.053,0.076,0.128,0.105,0.26,0.082
               c0.129-0.021,0.203,0.032,0.351,0.039c0.137,0.008,0.229,0.092,0.272,0.008c0.039-0.084,0.055-0.213-0.045-0.281
               c-0.099-0.063-0.145-0.168-0.328-0.16c-0.189,0-0.313,0.031-0.318-0.016c-0.01-0.053-0.016-0.183-0.069-0.168
               C244.865,82.251,244.836,82.292,244.836,82.292z M243.717,80.517c0,0.076,0.046,0.068,0.175,0.189s0.137,0.205,0.291,0.328
               c0.157,0.113,0.35,0.272,0.416,0.313c0.069,0.037,0.215,0.076,0.284,0.129c0.066,0.055,0.242,0.152,0.136-0.016
               c-0.1-0.16-0.106-0.121-0.213-0.236c-0.105-0.105-0.26-0.271-0.382-0.396c-0.114-0.113-0.259-0.183-0.319-0.228
               c-0.054-0.039-0.197-0.161-0.289-0.161C243.731,80.44,243.717,80.517,243.717,80.517z M242.817,80.998
               c-0.075,0.068-0.221,0.135-0.129,0.228c0.09,0.103,0.205,0.13,0.234,0.153c0.023,0.015,0.047-0.069,0.152,0.037
               c0.113,0.1,0.146,0.076,0.236,0.197c0.1,0.115,0.373,0.131,0.268,0.047c-0.107-0.093-0.207-0.037-0.244-0.176
               c-0.031-0.13-0.146-0.234-0.146-0.305c0-0.061-0.006-0.213-0.09-0.137c-0.076,0.076-0.191,0.144-0.191,0.076v-0.16
               L242.817,80.998z M242.36,80.722c0.016,0.099,0.16,0.105,0.054,0.205c-0.106,0.107-0.069,0.404-0.021,0.268
               c0.035-0.137,0.25-0.183,0.242-0.289c-0.009-0.113-0.061-0.373-0.169-0.352C242.36,80.578,242.36,80.722,242.36,80.722z"/>
               <path fill="#808184" d="M246.519,92.348c0,0,0.076,0.106,0.184,0.442
               c0.105,0.326,0.129,0.365,0.365,0.64c0.234,0.274,0.447,0.473,0.586,0.577c0.146,0.107,0.336,0.291,0.42,0.404
               c0.074,0.105,0.136,0.131,0.234,0.139c0.092,0.007,0.139,0.052,0.236,0c0.092-0.055,0.176-0.176,0.066-0.291
               c-0.112-0.104-0.211-0.084-0.334-0.213c-0.114-0.129-0.137-0.25-0.243-0.396c-0.106-0.145-0.41-0.297-0.519-0.457
               c-0.104-0.158-0.145-0.342-0.281-0.418c-0.129-0.068-0.281-0.207-0.305-0.26c-0.022-0.045-0.146-0.053-0.205-0.1
               c-0.054-0.045-0.092-0.22-0.176-0.22C246.465,92.188,246.519,92.348,246.519,92.348 M249.435,93.69
               c0.037,0.083,0.16,0.205,0.221,0.183c0.062-0.022,0.131-0.336,0.062-0.318c-0.067,0.006-0.075-0.047-0.22-0.055
               C249.351,93.492,249.435,93.69,249.435,93.69 M249.038,92.951c-0.016,0.039,0.046,0.113-0.038,0.168
               c-0.092,0.045-0.138,0.114,0,0.174c0.13,0.063,0.138,0.176,0.19,0.139c0.045-0.038,0.061-0.305,0.054-0.357
               c-0.009-0.045-0.076-0.275-0.138-0.267C249.038,92.813,249.038,92.951,249.038,92.951"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M246.519,92.348c0,0,0.076,0.106,0.184,0.442c0.105,0.326,0.129,0.365,0.365,0.64c0.234,0.274,0.447,0.473,0.586,0.577
               c0.146,0.107,0.336,0.291,0.42,0.404c0.074,0.105,0.136,0.131,0.234,0.139c0.092,0.007,0.139,0.052,0.236,0
               c0.092-0.055,0.176-0.176,0.066-0.291c-0.112-0.104-0.211-0.084-0.334-0.213c-0.114-0.129-0.137-0.25-0.243-0.396
               c-0.106-0.145-0.41-0.297-0.519-0.457c-0.104-0.158-0.145-0.342-0.281-0.418c-0.129-0.068-0.281-0.207-0.305-0.26
               c-0.022-0.045-0.146-0.053-0.205-0.1c-0.054-0.045-0.092-0.22-0.176-0.22C246.465,92.188,246.519,92.348,246.519,92.348z
                M249.435,93.69c0.037,0.083,0.16,0.205,0.221,0.183c0.062-0.022,0.131-0.336,0.062-0.318c-0.067,0.006-0.075-0.047-0.22-0.055
               C249.351,93.492,249.435,93.69,249.435,93.69z M249.038,92.951c-0.016,0.039,0.046,0.113-0.038,0.168
               c-0.092,0.045-0.138,0.114,0,0.174c0.13,0.063,0.138,0.176,0.19,0.139c0.045-0.038,0.061-0.305,0.054-0.357
               c-0.009-0.045-0.076-0.275-0.138-0.267C249.038,92.813,249.038,92.951,249.038,92.951z"/>
              </a>
        <!-- fin australie   -->
        
        
        
        
        <path fill="#808184" d="M101.381,12.752c0,0-0.389-0.152-0.351,0.045
        c0.03,0.198,0.054,0.077,0.13,0.205c0.076,0.139,0,0.191-0.146,0.108c-0.145-0.084-0.112-0.213-0.266-0.13
        c-0.146,0.077-0.229,0.022-0.32,0.198c-0.084,0.183-0.365,0.106-0.335,0.229c0.03,0.122,0.121,0.159,0.335,0.159
        c0.206,0,0.251-0.083,0.456-0.091c0.207-0.008,0.382-0.053,0.397,0.061c0.007,0.114-0.122,0.046-0.198,0.175
        c-0.069,0.129,0,0.114-0.252,0.145c-0.259,0.031-0.829,0.023-0.7,0.167c0.122,0.153,0.273,0.153,0.435,0.099
        c0.152-0.053,0.518-0.022,0.601,0.046c0.092,0.068-0.022,0.107,0.031,0.206c0.053,0.099,0.091,0.16-0.031,0.251
        c-0.121,0.099-0.282,0.053-0.403,0.091c-0.121,0.031-0.19,0.214,0.092,0.198c0.289-0.023,0.267-0.228,0.548-0.129
        c0.29,0.099,0.351,0.038,0.571,0.106c0.221,0.076,0.221,0.191,0.602,0.282c0.389,0.091,0.57,0.008,0.783,0.031
        c0.214,0.023,0.291,0.099,0.396-0.054c0.106-0.144,0.259-0.319,0.792-0.335c0.533-0.015,0.503-0.183,0.754-0.274
        c0.259-0.098,0.404-0.023,0.662-0.129c0.26-0.107,0.16-0.259,0.373-0.327c0.214-0.077,0.351-0.062,0.381-0.275
        c0.038-0.205,0.176-0.411-0.092-0.418c-0.267-0.008-0.402,0.053-0.426-0.077c-0.015-0.137-0.038-0.236-0.054-0.296
        c-0.015-0.061,0.031-0.145-0.083-0.183c-0.106-0.046-0.267-0.046-0.329-0.008c-0.06,0.031-0.014-0.13-0.211-0.152
        c-0.205-0.015-0.382-0.007-0.42,0.076c-0.045,0.084,0.038,0.221-0.167,0.228c-0.198,0.008-0.1-0.137-0.29-0.053
        c-0.183,0.084-0.121,0.205-0.281,0.146c-0.159-0.062-0.274-0.252-0.342-0.146c-0.069,0.107,0.105,0.343-0.069,0.243
        c-0.176-0.098-0.114-0.41-0.319-0.289c-0.214,0.121-0.175,0.153-0.267,0.183c-0.091,0.022-0.038,0.213-0.213,0.152
        c-0.168-0.06-0.152-0.243-0.351-0.214c-0.206,0.024-0.213,0.024-0.259,0.207s-0.038,0.061-0.228,0.152
        c-0.191,0.084-0.352,0.183-0.358,0.076c-0.016-0.099,0.228-0.114,0.037-0.319c-0.19-0.214-0.312-0.229-0.41-0.321
        c-0.107-0.091-0.069-0.121-0.13-0.121C101.403,12.676,101.381,12.752,101.381,12.752"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M101.381,12.752c0,0-0.389-0.152-0.351,0.045c0.03,0.198,0.054,0.077,0.13,0.205c0.076,0.139,0,0.191-0.146,0.108
        c-0.145-0.084-0.112-0.213-0.266-0.13c-0.146,0.077-0.229,0.022-0.32,0.198c-0.084,0.183-0.365,0.106-0.335,0.229
        c0.03,0.122,0.121,0.159,0.335,0.159c0.206,0,0.251-0.083,0.456-0.091c0.207-0.008,0.382-0.053,0.397,0.061
        c0.007,0.114-0.122,0.046-0.198,0.175c-0.069,0.129,0,0.114-0.252,0.145c-0.259,0.031-0.829,0.023-0.7,0.167
        c0.122,0.153,0.273,0.153,0.435,0.099c0.152-0.053,0.518-0.022,0.601,0.046c0.092,0.068-0.022,0.107,0.031,0.206
        c0.053,0.099,0.091,0.16-0.031,0.251c-0.121,0.099-0.282,0.053-0.403,0.091c-0.121,0.031-0.19,0.214,0.092,0.198
        c0.289-0.023,0.267-0.228,0.548-0.129c0.29,0.099,0.351,0.038,0.571,0.106c0.221,0.076,0.221,0.191,0.602,0.282
        c0.389,0.091,0.57,0.008,0.783,0.031c0.214,0.023,0.291,0.099,0.396-0.054c0.106-0.144,0.259-0.319,0.792-0.335
        c0.533-0.015,0.503-0.183,0.754-0.274c0.259-0.098,0.404-0.023,0.662-0.129c0.26-0.107,0.16-0.259,0.373-0.327
        c0.214-0.077,0.351-0.062,0.381-0.275c0.038-0.205,0.176-0.411-0.092-0.418c-0.267-0.008-0.402,0.053-0.426-0.077
        c-0.015-0.137-0.038-0.236-0.054-0.296c-0.015-0.061,0.031-0.145-0.083-0.183c-0.106-0.046-0.267-0.046-0.329-0.008
        c-0.06,0.031-0.014-0.13-0.211-0.152c-0.205-0.015-0.382-0.007-0.42,0.076c-0.045,0.084,0.038,0.221-0.167,0.228
        c-0.198,0.008-0.1-0.137-0.29-0.053c-0.183,0.084-0.121,0.205-0.281,0.146c-0.159-0.062-0.274-0.252-0.342-0.146
        c-0.069,0.107,0.105,0.343-0.069,0.243c-0.176-0.098-0.114-0.41-0.319-0.289c-0.214,0.121-0.175,0.153-0.267,0.183
        c-0.091,0.022-0.038,0.213-0.213,0.152c-0.168-0.06-0.152-0.243-0.351-0.214c-0.206,0.024-0.213,0.024-0.259,0.207
        s-0.038,0.061-0.228,0.152c-0.191,0.084-0.352,0.183-0.358,0.076c-0.016-0.099,0.228-0.114,0.037-0.319
        c-0.19-0.214-0.312-0.229-0.41-0.321c-0.107-0.091-0.069-0.121-0.13-0.121C101.403,12.676,101.381,12.752,101.381,12.752z"/>
        
            
        <path fill="#808184" d="M64.62,31.292c-0.022,0.099-0.008,0.174-0.151,0.244
        c-0.206,0.098-0.442,0.228-0.709,0.296c-0.274,0.069-0.457,0.069-0.57,0.039c-0.115-0.039-0.191-0.054-0.274,0.067
        c-0.084,0.123-0.1,0.267-0.313,0.321c-0.206,0.053-0.243,0.03-0.411,0.053c-0.167,0.031-0.67,0.465-0.868,0.647
        c-0.198,0.19-0.319,0.297-0.297,0.427c0.022,0.129-0.137,0.243-0.167,0.327c-0.038,0.076,0.068,0.221,0.137,0.366
        c0.068,0.152,0.137,0.175,0.274,0.167c0.129-0.015,0.099,0.068-0.047,0.137c-0.151,0.069-0.243,0.16-0.402,0.222
        c-0.152,0.06-0.139-0.153-0.313-0.161c-0.176-0.007-0.213-0.015-0.357,0.099c-0.153,0.114-0.282,0.122-0.64,0.16
        c-0.358,0.038-1.211,0.16-1.121,0.251c0.101,0.084,0.237,0.076,0.504,0.046c0.259-0.03,0.64-0.183,0.525-0.038
        c-0.114,0.137-0.305,0.06-0.616,0.205c-0.306,0.153-0.373,0.221-0.625,0.221c-0.251,0-0.289-0.137-0.365-0.053
        c-0.076,0.091-0.061,0.152-0.023,0.228c0.046,0.069,0.16,0.084,0.023,0.191c-0.137,0.106-0.305,0.41-0.549,0.655
        c-0.243,0.243-0.494,0.738-0.57,0.541c-0.076-0.199-0.069-0.443-0.168-0.382c-0.106,0.069-0.167,0.084-0.106,0.298
        c0.054,0.22,0.137,0.152,0.061,0.365c-0.068,0.221-0.189,0.389-0.335,0.54c-0.145,0.16-0.357,0.343-0.457,0.442
        c-0.099,0.099-0.205,0.411-0.229,0.259c-0.029-0.145-0.007-0.229,0.115-0.419c0.122-0.198,0.183-0.289,0.105-0.426
        c-0.075-0.145-0.144-0.168-0.091-0.343c0.046-0.182,0.13-0.548,0-0.48c-0.137,0.076-0.228,0.137-0.236,0.358
        c0,0.213,0.009,0.381-0.084,0.388c-0.099,0.008-0.318-0.182-0.289-0.029c0.023,0.159,0.184,0.174,0.252,0.266
        c0.061,0.091,0.015,0.122,0,0.213c-0.009,0.099-0.068-0.007-0.084,0.251c-0.015,0.259-0.131,0.35-0.038,0.464
        c0.099,0.115,0.152,0.024,0.168,0.183c0.015,0.16-0.055,0.313-0.016,0.381c0.038,0.069,0.145,0.51,0,0.441
        c-0.146-0.068-0.594-0.418-0.556-0.182c0.046,0.236,0.388,0.167,0.328,0.335c-0.062,0.167-0.161,0.35-0.336,0.366
        c-0.167,0.007-0.465-0.16-0.411,0.007c0.054,0.175,0.076,0.206,0.083,0.312c0.009,0.107,0.207,0.153,0,0.267
        c-0.205,0.113-0.456-0.015-0.897,0.259c-0.435,0.282-0.411,0.396-0.571,0.464c-0.16,0.068-0.221-0.114-0.632,0.228
        c-0.419,0.343-0.327,0.373-0.716,0.633c-0.381,0.266-0.449,0.403-0.776,0.593c-0.335,0.191-0.533,0.305-0.587,0.418
        c-0.061,0.115-0.654,0.625-0.738,0.838c-0.076,0.214-0.121,0.769-0.099,1.242c0.022,0.471,0.204,0.936,0.189,1.218
        C49.529,47,49.515,47.16,49.567,47.45c0.046,0.289,0.229,1.165,0,1.477c-0.221,0.313-0.26,0.556-0.365,0.662
        c-0.106,0.099-0.152,0.152-0.365,0.176c-0.214,0.015-0.259,0.007-0.259-0.123c0-0.137,0.015-0.228-0.084-0.395
        c-0.106-0.16-0.313-0.077-0.313-0.267s0.092-0.327-0.031-0.411c-0.121-0.091-0.083-0.266-0.129-0.373
        c-0.053-0.107-0.137-0.061-0.19-0.228c-0.053-0.168-0.083-0.29-0.007-0.442c0.076-0.145,0.152-0.252,0.053-0.252
        s-0.259,0.13-0.175-0.128c0.084-0.268,0.258-0.503,0.274-0.754c0.022-0.26-0.008-0.328-0.138-0.465
        c-0.129-0.13-0.495-0.7-0.647-0.738c-0.159-0.046-0.228-0.084-0.44,0.091c-0.214,0.175-0.427,0.343-0.534,0.289
        c-0.106-0.053-0.076-0.22-0.243-0.327c-0.167-0.107-0.198-0.457-0.678-0.412c-0.472,0.039-0.692,0.183-0.838,0.138
        c-0.151-0.054-0.083-0.236-0.281-0.206c-0.19,0.023-0.13,0.145-0.319,0.198c-0.19,0.053-0.336-0.076-0.595-0.039
        c-0.266,0.047-0.273,0.123-0.456,0.13c-0.176,0.009-0.465-0.221-0.533-0.083c-0.077,0.129-0.1,0.167,0.236,0.289
        c0.273,0.092,0.221,0.183,0.281,0.434c0.038,0.167,0.319,0.167,0.251,0.312c-0.061,0.152-0.183,0.335-0.274,0.206
        c-0.084-0.137-0.091-0.244-0.235-0.305c-0.137-0.061-0.19-0.297-0.274-0.16c-0.03,0.053-0.205,0.541-0.343,0.335
        c-0.068-0.114-0.053-0.213-0.175-0.152c-0.122,0.068-0.137,0.137-0.282,0.076c-0.144-0.054-0.137-0.114-0.137-0.114
        c0.008-0.229-0.213-0.289-0.411-0.335c-0.106-0.031-0.167-0.038-0.274,0.015c-0.106,0.061-0.297,0.054-0.297,0.054
        s-0.167-0.023-0.319-0.115c-0.145-0.084-0.244-0.091-0.479-0.075c-0.236,0.014-0.344,0.045-0.435,0.106s-0.838,0.38-1.18,0.632
        c-0.351,0.25-0.403,0.289-0.776,0.434c-0.366,0.137-0.563,0.266-0.801,0.471c-0.234,0.198-0.555,0.221-0.57,0.655
        c-0.022,0.427,0.046,0.762,0.016,1.15c0,0.046-0.008,0.091-0.016,0.13c-0.129-0.008-0.259-0.024-0.343-0.039
        c-0.106-0.014-0.145-0.045-0.198-0.068c-0.054-0.038-0.113-0.076-0.258-0.107c-0.115-0.03-0.229-0.114-0.335-0.205
        c-0.122-0.122-0.214-0.259-0.237-0.343L34.4,48.226c-0.046-0.159-0.093-0.357-0.114-0.609c-0.015-0.198-0.099-0.32-0.198-0.464
        c-0.037-0.061-0.083-0.122-0.121-0.198c-0.084-0.145-0.077-0.221-0.068-0.312c0-0.061,0.007-0.122-0.009-0.213
        c-0.015-0.13-0.083-0.259-0.145-0.382c-0.045-0.09-0.091-0.182-0.099-0.258c-0.022-0.107-0.047-0.16-0.106-0.221
        c-0.061-0.061-0.159-0.114-0.335-0.213c-0.152-0.076-0.32-0.084-0.48-0.062c-0.235,0.047-0.448,0.168-0.524,0.237
        c-0.045,0.045-0.106,0.129-0.168,0.22c-0.068,0.1-0.145,0.214-0.213,0.275c-0.03,0.022-0.046,0.038-0.054,0.03
        c-0.061-0.061-0.167-0.098-0.297-0.137c-0.167-0.053-0.365-0.113-0.518-0.251c-0.184-0.16-0.137-0.342-0.099-0.502
        c0.015-0.061,0.03-0.122,0.037-0.19c0.009-0.115-0.022-0.214-0.091-0.328c-0.068-0.107-0.16-0.213-0.297-0.358
        c-0.114-0.13-0.145-0.198-0.176-0.259c-0.029-0.069-0.053-0.121-0.182-0.213c-0.114-0.076-0.122-0.152-0.122-0.213
        c-0.008-0.054-0.015-0.1-0.062-0.152c-0.076-0.077-0.532-0.054-0.921-0.032c-0.159,0.008-0.305,0.017-0.403,0.017
        c-0.267,0-0.267,0.151-0.267,0.304c0,0.061-0.008,0.121-0.03,0.159c-0.054,0.1-1.013,0.023-1.599-0.015
        c-0.185-0.015-0.328-0.03-0.411-0.03c-0.122,0-0.473-0.129-0.884-0.289c-0.738-0.297-1.652-0.709-1.713-0.746
        c-0.016-0.008-0.016-0.031-0.009-0.054c0.009-0.061,0.017-0.121-0.044-0.191c-0.084-0.098-1.265,0.016-1.858,0.077
        c0.03-0.152,0.061-0.373,0-0.525c-0.106-0.252-0.213-0.45-0.411-0.488c-0.206-0.046-0.167-0.114-0.259-0.251
        c-0.084-0.138-0.214-0.106-0.313-0.114c-0.092-0.008-0.106-0.145-0.289-0.213c-0.184-0.069-0.693-0.046-0.678-0.191
        c0.007-0.137,0.205-0.175,0.152-0.349c-0.054-0.183-0.084-0.26-0.062-0.335c0.03-0.077,0-0.093-0.075-0.214
        c-0.069-0.122-0.1-0.411-0.176-0.541c-0.068-0.129-0.167-0.22-0.053-0.395c0.113-0.176,0.281-0.29,0.113-0.389
        c-0.167-0.099-0.259-0.168-0.228-0.296c0.037-0.131,0.099-0.275,0.129-0.336c0.03-0.061,0.319-0.205,0.19-0.282
        c-0.129-0.084-0.221-0.106-0.244-0.327c-0.022-0.221-0.213-0.456-0.182-0.586c0.03-0.13,0.144-0.145,0.167-0.419
        c0.03-0.274,0.038-0.602,0.008-0.776c-0.031-0.175,0.084-0.389,0.243-0.541c0.16-0.145,0.313-0.336,0.51-0.647
        c0.206-0.312,0.189-0.145,0.222-0.61c0.022-0.456,0.243-0.67,0.549-0.929c0.312-0.258,0.486-0.632,0.769-0.905
        c0.289-0.267,0.715-0.998,1.004-1.416c0.282-0.419,0.473-0.229,0.473-0.564c0-0.343,0-0.488,0.198-0.784
        c0.206-0.298,0.427-1.104,0.525-1.112h0.015h0.039l0.067,0.008c0.236,0.045,0.709,0.16,0.793,0.243
        c0.099,0.114,0.259,0.182,0.396,0.007c0.137-0.174,0.327-0.266,0.343-0.403c0.015-0.099,0.007-0.228-0.038-0.373
        c0.099-0.008,0.273,0.007,0.449,0.024l0.076,0.007c0.152,0.007,4.332,0.053,8.032,0.083c2.444,0.016,4.684,0.023,5.414,0
        c0.776-0.015,1.896-0.007,2.961,0c1.478,0.016,2.833,0.023,2.971-0.045c0.091-0.054,0.167-0.137,0.235-0.213
        c0.046-0.046,0.084-0.092,0.114-0.115c0.023-0.015,0.038-0.015,0.053,0.008c0.038,0.046-0.007,0.122-0.046,0.191
        c-0.06,0.098-0.121,0.197-0.022,0.289c0.114,0.106,0.274,0.113,0.427,0.122c0.046,0.007,0.092,0.007,0.13,0.015
        c0.061,0.007,0.137-0.008,0.228-0.024c0.152-0.029,0.343-0.068,0.435,0.046c0.091,0.108,0.16,0.176,0.229,0.214
        c0.068,0.045,0.13,0.06,0.198,0.076c0.022,0.008,0.045,0.008,0.053,0.008c0.067,0.015,0.092,0.015,0.252,0.113
        c0.205,0.115,0.754,0.123,1.035,0.123c0.037,0.007,0.068,0.007,0.092,0.007c0.098,0,0.136-0.007,0.167-0.015h0.092
        c-0.046,0.03-0.092,0.061-0.138,0.091c-0.145,0.084-0.335,0.122-0.633,0.198c-0.289,0.069-0.677,0.328-0.943,0.533
        c-0.259,0.198-0.456,0.115-0.594,0.244c-0.138,0.13,0.366,0.046,0.487,0c0.114-0.038,0.221-0.008,0.213,0.076
        c-0.016,0.084,0.138,0.121,0.411,0.099c0.282-0.023,0.198-0.061,0.449-0.168c0.259-0.106,0.854-0.266,1.074-0.396
        c0.221-0.122,0.259-0.168,0.411-0.151c0.151,0.022-0.343,0.334-0.39,0.464c-0.044,0.129,0.184-0.046,0.373-0.016
        c0.191,0.031,0.146,0.222,0.274,0.335c0.13,0.115,0.222,0.099,0.404,0.099c0.175,0,0.197-0.076,0.457-0.19
        c0.251-0.106,0.677-0.106,0.792-0.122c0.113-0.007,0.037,0.176,0.053,0.289c0.016,0.123,0.236,0.039,0.335-0.007
        c0.016,0.015,0.023,0.03,0.038,0.046c0.031,0.03,0.068,0.068,0.114,0.122c0.023,0.038,0.046,0.061,0.069,0.09
        c-0.206,0.183-0.313,0.207-0.473,0.229c-0.175,0.015-0.198-0.046-0.411-0.083c-0.213-0.038-0.16,0.022-0.464,0.083
        c-0.313,0.053-0.625-0.046-0.717,0.084c-0.092,0.137-0.151-0.023-0.319,0.106c-0.16,0.13-0.305,0.198-0.472,0.344
        c-0.16,0.151-0.168,0.167-0.373,0.327c-0.206,0.167-0.519,0.427-0.358,0.418c0.152-0.015,0.67-0.495,0.739-0.472
        c0.076,0.024,0.061,0.031-0.092,0.221c-0.152,0.198-0.289,0.342-0.434,0.541c-0.145,0.19-0.298,0.312-0.32,0.388
        c-0.022,0.069-0.411,0.426-0.465,0.67c-0.053,0.251-0.053,0.396-0.16,0.563c-0.099,0.168-0.22,0.465-0.19,0.587
        c0.039,0.122-0.029,0.282,0.19,0.243c0.229-0.03,0.298-0.045,0.541-0.198c0.251-0.159,0.351-0.297,0.449-0.464
        c0.107-0.168,0.206-0.335,0.328-0.548c0.121-0.213-0.068-0.541,0.106-0.701c0.167-0.152,0.198,0.061,0.319-0.312
        c0.129-0.373,0.274-0.556,0.541-0.791c0.273-0.237,0.533-0.496,0.533-0.343c0,0.144-0.404,0.472-0.191,0.38
        c0.214-0.091,0.244-0.54,0.435-0.54s0.251,0.031,0.274-0.077c0.022-0.114,0.175-0.365,0.319-0.327
        c0.152,0.03,0.038,0.175,0.198,0.244c0.152,0.061,0.343-0.008,0.427,0.122c0.084,0.129,0.151,0.091,0.121,0.228
        c-0.03,0.145-0.229,0.358-0.251,0.533c-0.016,0.183-0.122,0.274-0.236,0.403c-0.121,0.13-0.434,0.198-0.449,0.366
        c-0.008,0.175-0.061,0.343,0.19,0.175c0.244-0.16,0.457-0.457,0.585-0.403c0.124,0.06,0.237,0.106,0.161,0.259
        c-0.077,0.152-0.198,0.51-0.121,0.548c0.022,0.015,0.044,0.023,0.075,0.03c-0.046,0.061-0.091,0.107-0.145,0.152
        c-0.099,0.084-0.183,0.153-0.251,0.214c-0.099,0.083-0.175,0.152-0.251,0.205c-0.068,0.038-0.19,0.152-0.274,0.274
        c-0.038,0.046-0.069,0.1-0.091,0.153c-0.055,0-0.106,0.007-0.106,0.007s-0.274,0.145-0.222,0.259
        c0.046,0.107,0.19,0.068,0.282,0.191c0.099,0.129,0.022,0.175,0.235,0.144c0.213-0.031,0.213-0.068,0.503-0.099
        c0.288-0.038,0.548-0.205,0.937-0.32c0.389-0.121,0.822-0.305,1.021-0.388c0.197-0.084,0.632-0.335,0.692-0.442
        c0.038-0.06,0.022-0.137-0.015-0.167c0.028-0.076,0.053-0.144,0.068-0.221c0.007-0.022,0.016-0.038,0.022-0.061
        c0.114-0.038,0.205-0.091,0.305-0.099c0.159-0.007,0.403-0.083,0.67-0.023c0.259,0.069,0.19,0.153,0.381,0.13
        c0.183-0.023,0.138,0.068,0.419-0.046c0.273-0.114,0.381-0.22,0.403-0.228c0.023-0.008,0.168,0.008,0.19-0.16
        c0.023-0.168,0.335-0.259,0.281-0.328c-0.015-0.015-0.038-0.038-0.061-0.061c0.015-0.015,0.03-0.03,0.053-0.046v-0.007
        c0.184-0.152,0.831-0.723,1.462-0.708c0.358,0,0.525,0,0.731-0.007c0.16,0,0.335-0.008,0.662-0.008
        c0.222,0,0.389,0.015,0.519,0.03c0.327,0.023,0.457,0.038,0.616-0.197c0.114-0.176,0.19-0.198,0.267-0.213
        c0.061-0.016,0.115-0.031,0.19-0.092c0.098-0.069,0.243-0.214,0.403-0.373c0.152-0.152,0.319-0.313,0.441-0.411
        c0.129-0.107,0.198-0.229,0.267-0.343c0.084-0.13,0.152-0.252,0.289-0.32c0.092-0.045,0.184-0.107,0.267-0.16
        c0.092-0.068,0.176-0.129,0.229-0.137c0.009,0,0.016,0.008,0.016,0.023c0.021,0.145,0.114,0.13,0.243,0.114
        c0.092-0.015,0.214-0.038,0.313,0.061c0.16,0.16,0.114,0.251,0.03,0.419c-0.015,0.046-0.037,0.099-0.068,0.16
        c-0.03,0.076-0.061,0.137-0.091,0.206c-0.106,0.198-0.19,0.357-0.046,0.563c0.053,0.076,0.106,0.122,0.137,0.152
        c0.038,0.039,0.038,0.039-0.039,0.114C64.369,30.996,64.475,31.156,64.62,31.292"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M64.62,31.292
        c-0.022,0.099-0.008,0.174-0.151,0.244c-0.206,0.098-0.442,0.228-0.709,0.296c-0.274,0.069-0.457,0.069-0.57,0.039
        c-0.115-0.039-0.191-0.054-0.274,0.067c-0.084,0.123-0.1,0.267-0.313,0.321c-0.206,0.053-0.243,0.03-0.411,0.053
        c-0.167,0.031-0.67,0.465-0.868,0.647c-0.198,0.19-0.319,0.297-0.297,0.427c0.022,0.129-0.137,0.243-0.167,0.327
        c-0.038,0.076,0.068,0.221,0.137,0.366c0.068,0.152,0.137,0.175,0.274,0.167c0.129-0.015,0.099,0.068-0.047,0.137
        c-0.151,0.069-0.243,0.16-0.402,0.222c-0.152,0.06-0.139-0.153-0.313-0.161c-0.176-0.007-0.213-0.015-0.357,0.099
        c-0.153,0.114-0.282,0.122-0.64,0.16c-0.358,0.038-1.211,0.16-1.121,0.251c0.101,0.084,0.237,0.076,0.504,0.046
        c0.259-0.03,0.64-0.183,0.525-0.038c-0.114,0.137-0.305,0.06-0.616,0.205c-0.306,0.153-0.373,0.221-0.625,0.221
        c-0.251,0-0.289-0.137-0.365-0.053c-0.076,0.091-0.061,0.152-0.023,0.228c0.046,0.069,0.16,0.084,0.023,0.191
        c-0.137,0.106-0.305,0.41-0.549,0.655c-0.243,0.243-0.494,0.738-0.57,0.541c-0.076-0.199-0.069-0.443-0.168-0.382
        c-0.106,0.069-0.167,0.084-0.106,0.298c0.054,0.22,0.137,0.152,0.061,0.365c-0.068,0.221-0.189,0.389-0.335,0.54
        c-0.145,0.16-0.357,0.343-0.457,0.442c-0.099,0.099-0.205,0.411-0.229,0.259c-0.029-0.145-0.007-0.229,0.115-0.419
        c0.122-0.198,0.183-0.289,0.105-0.426c-0.075-0.145-0.144-0.168-0.091-0.343c0.046-0.182,0.13-0.548,0-0.48
        c-0.137,0.076-0.228,0.137-0.236,0.358c0,0.213,0.009,0.381-0.084,0.388c-0.099,0.008-0.318-0.182-0.289-0.029
        c0.023,0.159,0.184,0.174,0.252,0.266c0.061,0.091,0.015,0.122,0,0.213c-0.009,0.099-0.068-0.007-0.084,0.251
        c-0.015,0.259-0.131,0.35-0.038,0.464c0.099,0.115,0.152,0.024,0.168,0.183c0.015,0.16-0.055,0.313-0.016,0.381
        c0.038,0.069,0.145,0.51,0,0.441c-0.146-0.068-0.594-0.418-0.556-0.182c0.046,0.236,0.388,0.167,0.328,0.335
        c-0.062,0.167-0.161,0.35-0.336,0.366c-0.167,0.007-0.465-0.16-0.411,0.007c0.054,0.175,0.076,0.206,0.083,0.312
        c0.009,0.107,0.207,0.153,0,0.267c-0.205,0.113-0.456-0.015-0.897,0.259c-0.435,0.282-0.411,0.396-0.571,0.464
        c-0.16,0.068-0.221-0.114-0.632,0.228c-0.419,0.343-0.327,0.373-0.716,0.633c-0.381,0.266-0.449,0.403-0.776,0.593
        c-0.335,0.191-0.533,0.305-0.587,0.418c-0.061,0.115-0.654,0.625-0.738,0.838c-0.076,0.214-0.121,0.769-0.099,1.242
        c0.022,0.471,0.204,0.936,0.189,1.218C49.529,47,49.515,47.16,49.567,47.45c0.046,0.289,0.229,1.165,0,1.477
        c-0.221,0.313-0.26,0.556-0.365,0.662c-0.106,0.099-0.152,0.152-0.365,0.176c-0.214,0.015-0.259,0.007-0.259-0.123
        c0-0.137,0.015-0.228-0.084-0.395c-0.106-0.16-0.313-0.077-0.313-0.267s0.092-0.327-0.031-0.411
        c-0.121-0.091-0.083-0.266-0.129-0.373c-0.053-0.107-0.137-0.061-0.19-0.228c-0.053-0.168-0.083-0.29-0.007-0.442
        c0.076-0.145,0.152-0.252,0.053-0.252s-0.259,0.13-0.175-0.128c0.084-0.268,0.258-0.503,0.274-0.754
        c0.022-0.26-0.008-0.328-0.138-0.465c-0.129-0.13-0.495-0.7-0.647-0.738c-0.159-0.046-0.228-0.084-0.44,0.091
        c-0.214,0.175-0.427,0.343-0.534,0.289c-0.106-0.053-0.076-0.22-0.243-0.327c-0.167-0.107-0.198-0.457-0.678-0.412
        c-0.472,0.039-0.692,0.183-0.838,0.138c-0.151-0.054-0.083-0.236-0.281-0.206c-0.19,0.023-0.13,0.145-0.319,0.198
        c-0.19,0.053-0.336-0.076-0.595-0.039c-0.266,0.047-0.273,0.123-0.456,0.13c-0.176,0.009-0.465-0.221-0.533-0.083
        c-0.077,0.129-0.1,0.167,0.236,0.289c0.273,0.092,0.221,0.183,0.281,0.434c0.038,0.167,0.319,0.167,0.251,0.312
        c-0.061,0.152-0.183,0.335-0.274,0.206c-0.084-0.137-0.091-0.244-0.235-0.305c-0.137-0.061-0.19-0.297-0.274-0.16
        c-0.03,0.053-0.205,0.541-0.343,0.335c-0.068-0.114-0.053-0.213-0.175-0.152c-0.122,0.068-0.137,0.137-0.282,0.076
        c-0.144-0.054-0.137-0.114-0.137-0.114c0.008-0.229-0.213-0.289-0.411-0.335c-0.106-0.031-0.167-0.038-0.274,0.015
        c-0.106,0.061-0.297,0.054-0.297,0.054s-0.167-0.023-0.319-0.115c-0.145-0.084-0.244-0.091-0.479-0.075
        c-0.236,0.014-0.344,0.045-0.435,0.106s-0.838,0.38-1.18,0.632c-0.351,0.25-0.403,0.289-0.776,0.434
        c-0.366,0.137-0.563,0.266-0.801,0.471c-0.234,0.198-0.555,0.221-0.57,0.655c-0.022,0.427,0.046,0.762,0.016,1.15
        c0,0.046-0.008,0.091-0.016,0.13c-0.129-0.008-0.259-0.024-0.343-0.039c-0.106-0.014-0.145-0.045-0.198-0.068
        c-0.054-0.038-0.113-0.076-0.258-0.107c-0.115-0.03-0.229-0.114-0.335-0.205c-0.122-0.122-0.214-0.259-0.237-0.343L34.4,48.226
        c-0.046-0.159-0.093-0.357-0.114-0.609c-0.015-0.198-0.099-0.32-0.198-0.464c-0.037-0.061-0.083-0.122-0.121-0.198
        c-0.084-0.145-0.077-0.221-0.068-0.312c0-0.061,0.007-0.122-0.009-0.213c-0.015-0.13-0.083-0.259-0.145-0.382
        c-0.045-0.09-0.091-0.182-0.099-0.258c-0.022-0.107-0.047-0.16-0.106-0.221c-0.061-0.061-0.159-0.114-0.335-0.213
        c-0.152-0.076-0.32-0.084-0.48-0.062c-0.235,0.047-0.448,0.168-0.524,0.237c-0.045,0.045-0.106,0.129-0.168,0.22
        c-0.068,0.1-0.145,0.214-0.213,0.275c-0.03,0.022-0.046,0.038-0.054,0.03c-0.061-0.061-0.167-0.098-0.297-0.137
        c-0.167-0.053-0.365-0.113-0.518-0.251c-0.184-0.16-0.137-0.342-0.099-0.502c0.015-0.061,0.03-0.122,0.037-0.19
        c0.009-0.115-0.022-0.214-0.091-0.328c-0.068-0.107-0.16-0.213-0.297-0.358c-0.114-0.13-0.145-0.198-0.176-0.259
        c-0.029-0.069-0.053-0.121-0.182-0.213c-0.114-0.076-0.122-0.152-0.122-0.213c-0.008-0.054-0.015-0.1-0.062-0.152
        c-0.076-0.077-0.532-0.054-0.921-0.032c-0.159,0.008-0.305,0.017-0.403,0.017c-0.267,0-0.267,0.151-0.267,0.304
        c0,0.061-0.008,0.121-0.03,0.159c-0.054,0.1-1.013,0.023-1.599-0.015c-0.185-0.015-0.328-0.03-0.411-0.03
        c-0.122,0-0.473-0.129-0.884-0.289c-0.738-0.297-1.652-0.709-1.713-0.746c-0.016-0.008-0.016-0.031-0.009-0.054
        c0.009-0.061,0.017-0.121-0.044-0.191c-0.084-0.098-1.265,0.016-1.858,0.077c0.03-0.152,0.061-0.373,0-0.525
        c-0.106-0.252-0.213-0.45-0.411-0.488c-0.206-0.046-0.167-0.114-0.259-0.251c-0.084-0.138-0.214-0.106-0.313-0.114
        c-0.092-0.008-0.106-0.145-0.289-0.213c-0.184-0.069-0.693-0.046-0.678-0.191c0.007-0.137,0.205-0.175,0.152-0.349
        c-0.054-0.183-0.084-0.26-0.062-0.335c0.03-0.077,0-0.093-0.075-0.214c-0.069-0.122-0.1-0.411-0.176-0.541
        c-0.068-0.129-0.167-0.22-0.053-0.395c0.113-0.176,0.281-0.29,0.113-0.389c-0.167-0.099-0.259-0.168-0.228-0.296
        c0.037-0.131,0.099-0.275,0.129-0.336c0.03-0.061,0.319-0.205,0.19-0.282c-0.129-0.084-0.221-0.106-0.244-0.327
        c-0.022-0.221-0.213-0.456-0.182-0.586c0.03-0.13,0.144-0.145,0.167-0.419c0.03-0.274,0.038-0.602,0.008-0.776
        c-0.031-0.175,0.084-0.389,0.243-0.541c0.16-0.145,0.313-0.336,0.51-0.647c0.206-0.312,0.189-0.145,0.222-0.61
        c0.022-0.456,0.243-0.67,0.549-0.929c0.312-0.258,0.486-0.632,0.769-0.905c0.289-0.267,0.715-0.998,1.004-1.416
        c0.282-0.419,0.473-0.229,0.473-0.564c0-0.343,0-0.488,0.198-0.784c0.206-0.298,0.427-1.104,0.525-1.112h0.015h0.039
        l0.067,0.008c0.236,0.045,0.709,0.16,0.793,0.243c0.099,0.114,0.259,0.182,0.396,0.007c0.137-0.174,0.327-0.266,0.343-0.403
        c0.015-0.099,0.007-0.228-0.038-0.373c0.099-0.008,0.273,0.007,0.449,0.024l0.076,0.007c0.152,0.007,4.332,0.053,8.032,0.083
        c2.444,0.016,4.684,0.023,5.414,0c0.776-0.015,1.896-0.007,2.961,0c1.478,0.016,2.833,0.023,2.971-0.045
        c0.091-0.054,0.167-0.137,0.235-0.213c0.046-0.046,0.084-0.092,0.114-0.115c0.023-0.015,0.038-0.015,0.053,0.008
        c0.038,0.046-0.007,0.122-0.046,0.191c-0.06,0.098-0.121,0.197-0.022,0.289c0.114,0.106,0.274,0.113,0.427,0.122
        c0.046,0.007,0.092,0.007,0.13,0.015c0.061,0.007,0.137-0.008,0.228-0.024c0.152-0.029,0.343-0.068,0.435,0.046
        c0.091,0.108,0.16,0.176,0.229,0.214c0.068,0.045,0.13,0.06,0.198,0.076c0.022,0.008,0.045,0.008,0.053,0.008
        c0.067,0.015,0.092,0.015,0.252,0.113c0.205,0.115,0.754,0.123,1.035,0.123c0.037,0.007,0.068,0.007,0.092,0.007
        c0.098,0,0.136-0.007,0.167-0.015h0.092c-0.046,0.03-0.092,0.061-0.138,0.091c-0.145,0.084-0.335,0.122-0.633,0.198
        c-0.289,0.069-0.677,0.328-0.943,0.533c-0.259,0.198-0.456,0.115-0.594,0.244c-0.138,0.13,0.366,0.046,0.487,0
        c0.114-0.038,0.221-0.008,0.213,0.076c-0.016,0.084,0.138,0.121,0.411,0.099c0.282-0.023,0.198-0.061,0.449-0.168
        c0.259-0.106,0.854-0.266,1.074-0.396c0.221-0.122,0.259-0.168,0.411-0.151c0.151,0.022-0.343,0.334-0.39,0.464
        c-0.044,0.129,0.184-0.046,0.373-0.016c0.191,0.031,0.146,0.222,0.274,0.335c0.13,0.115,0.222,0.099,0.404,0.099
        c0.175,0,0.197-0.076,0.457-0.19c0.251-0.106,0.677-0.106,0.792-0.122c0.113-0.007,0.037,0.176,0.053,0.289
        c0.016,0.123,0.236,0.039,0.335-0.007c0.016,0.015,0.023,0.03,0.038,0.046c0.031,0.03,0.068,0.068,0.114,0.122
        c0.023,0.038,0.046,0.061,0.069,0.09c-0.206,0.183-0.313,0.207-0.473,0.229c-0.175,0.015-0.198-0.046-0.411-0.083
        c-0.213-0.038-0.16,0.022-0.464,0.083c-0.313,0.053-0.625-0.046-0.717,0.084c-0.092,0.137-0.151-0.023-0.319,0.106
        c-0.16,0.13-0.305,0.198-0.472,0.344c-0.16,0.151-0.168,0.167-0.373,0.327c-0.206,0.167-0.519,0.427-0.358,0.418
        c0.152-0.015,0.67-0.495,0.739-0.472c0.076,0.024,0.061,0.031-0.092,0.221c-0.152,0.198-0.289,0.342-0.434,0.541
        c-0.145,0.19-0.298,0.312-0.32,0.388c-0.022,0.069-0.411,0.426-0.465,0.67c-0.053,0.251-0.053,0.396-0.16,0.563
        c-0.099,0.168-0.22,0.465-0.19,0.587c0.039,0.122-0.029,0.282,0.19,0.243c0.229-0.03,0.298-0.045,0.541-0.198
        c0.251-0.159,0.351-0.297,0.449-0.464c0.107-0.168,0.206-0.335,0.328-0.548c0.121-0.213-0.068-0.541,0.106-0.701
        c0.167-0.152,0.198,0.061,0.319-0.312c0.129-0.373,0.274-0.556,0.541-0.791c0.273-0.237,0.533-0.496,0.533-0.343
        c0,0.144-0.404,0.472-0.191,0.38c0.214-0.091,0.244-0.54,0.435-0.54s0.251,0.031,0.274-0.077
        c0.022-0.114,0.175-0.365,0.319-0.327c0.152,0.03,0.038,0.175,0.198,0.244c0.152,0.061,0.343-0.008,0.427,0.122
        c0.084,0.129,0.151,0.091,0.121,0.228c-0.03,0.145-0.229,0.358-0.251,0.533c-0.016,0.183-0.122,0.274-0.236,0.403
        c-0.121,0.13-0.434,0.198-0.449,0.366c-0.008,0.175-0.061,0.343,0.19,0.175c0.244-0.16,0.457-0.457,0.585-0.403
        c0.124,0.06,0.237,0.106,0.161,0.259c-0.077,0.152-0.198,0.51-0.121,0.548c0.022,0.015,0.044,0.023,0.075,0.03
        c-0.046,0.061-0.091,0.107-0.145,0.152c-0.099,0.084-0.183,0.153-0.251,0.214c-0.099,0.083-0.175,0.152-0.251,0.205
        c-0.068,0.038-0.19,0.152-0.274,0.274c-0.038,0.046-0.069,0.1-0.091,0.153c-0.055,0-0.106,0.007-0.106,0.007
        s-0.274,0.145-0.222,0.259c0.046,0.107,0.19,0.068,0.282,0.191c0.099,0.129,0.022,0.175,0.235,0.144
        c0.213-0.031,0.213-0.068,0.503-0.099c0.288-0.038,0.548-0.205,0.937-0.32c0.389-0.121,0.822-0.305,1.021-0.388
        c0.197-0.084,0.632-0.335,0.692-0.442c0.038-0.06,0.022-0.137-0.015-0.167c0.028-0.076,0.053-0.144,0.068-0.221
        c0.007-0.022,0.016-0.038,0.022-0.061c0.114-0.038,0.205-0.091,0.305-0.099c0.159-0.007,0.403-0.083,0.67-0.023
        c0.259,0.069,0.19,0.153,0.381,0.13c0.183-0.023,0.138,0.068,0.419-0.046c0.273-0.114,0.381-0.22,0.403-0.228
        c0.023-0.008,0.168,0.008,0.19-0.16c0.023-0.168,0.335-0.259,0.281-0.328c-0.015-0.015-0.038-0.038-0.061-0.061
        c0.015-0.015,0.03-0.03,0.053-0.046v-0.007c0.184-0.152,0.831-0.723,1.462-0.708c0.358,0,0.525,0,0.731-0.007
        c0.16,0,0.335-0.008,0.662-0.008c0.222,0,0.389,0.015,0.519,0.03c0.327,0.023,0.457,0.038,0.616-0.197
        c0.114-0.176,0.19-0.198,0.267-0.213c0.061-0.016,0.115-0.031,0.19-0.092c0.098-0.069,0.243-0.214,0.403-0.373
        c0.152-0.152,0.319-0.313,0.441-0.411c0.129-0.107,0.198-0.229,0.267-0.343c0.084-0.13,0.152-0.252,0.289-0.32
        c0.092-0.045,0.184-0.107,0.267-0.16c0.092-0.068,0.176-0.129,0.229-0.137c0.009,0,0.016,0.008,0.016,0.023
        c0.021,0.145,0.114,0.13,0.243,0.114c0.092-0.015,0.214-0.038,0.313,0.061c0.16,0.16,0.114,0.251,0.03,0.419
        c-0.015,0.046-0.037,0.099-0.068,0.16c-0.03,0.076-0.061,0.137-0.091,0.206c-0.106,0.198-0.19,0.357-0.046,0.563
        c0.053,0.076,0.106,0.122,0.137,0.152c0.038,0.039,0.038,0.039-0.039,0.114C64.369,30.996,64.475,31.156,64.62,31.292z"/>
        <path fill="#808184" d="M31.545,9.882c0.106,0.023,0.236,0.046,0.396,0.053
        c0.457,0.031,0.586-0.068,0.7,0.038c0.114,0.1,0.084,0.389,0.457,0.518c0.381,0.13,0.837,0.275,0.898,0.191
        c0.061-0.092-0.084-0.282,0.091-0.35c0.176-0.07,0.442-0.07,0.808-0.153c0.365-0.076,0.578-0.328,0.754-0.221
        c0.175,0.107-0.039,0.252,0.289,0.252c0.099,0,3.03-0.914,3.053-0.663c0.016,0.145-0.373,0.251-0.151,0.32
        c0.213,0.061,0.403-0.076,0.639-0.153c0.236-0.075,0.396-0.106,0.488-0.227c0.608-0.777,0.38,0.19,0.342,0.441
        c-0.03,0.167,0.168,0.373,0.297,0.35c0.13-0.03,0.267-0.411,0.495-0.441c0.03,0,1.729-0.405,0.608,0.052
        c-0.113,0.046-0.288,0.161-0.151,0.229c0.137,0.069,0.343,0.038,0.511-0.031c0.167-0.068,0.273-0.236,0.51-0.182
        c0.8,0.175,1.667,0.327,2.437,0.632c0.167,0.068,0.327,0.106,0.563,0.106c0.236,0,0.723-0.045,0.867,0.123
        c0.152,0.175,0,0.357-0.152,0.365c-0.151,0.008-0.319-0.061-0.464,0.046c-0.152,0.114-0.373,0.121-0.282,0.235
        c0.1,0.122-0.038,0.054,0.419,0.122c0.465,0.077,0.884,0.061,1.202,0.038c0.32-0.022,0.671-0.114,0.982-0.106
        c0.306,0.007,0.572-0.129,0.723,0.023c0.162,0.16,0.162,0.221,0.283,0.266c0.122,0.046,0.252,0.046,0.129,0.145
        c-0.121,0.106-0.426,0.251-0.328,0.342c0.101,0.084,0.328,0.099,0.496-0.076c0.16-0.175-0.16-0.274,0.076-0.488
        c0.236-0.212,0.351-0.327,0.541-0.38c0.19-0.045,0.35-0.06,0.54-0.091c0.19-0.03,0.944-0.206,0.884-0.297
        c-0.062-0.091-0.214-0.099-0.389,0.015c-0.175,0.115-0.511,0.115-0.662,0.137c-0.154,0.015-0.206,0.115-0.396,0.115
        c-0.198,0.007-0.51-0.008-0.342-0.13c0.175-0.122,0.289-0.167,0.594-0.228c0.312-0.061,0.296,0,0.57-0.061
        c0.267-0.061,0.547-0.228,0.784-0.198c0.229,0.023,0.366,0.023,0.304,0.19c-0.06,0.16-0.204,0.244-0.006,0.312
        c0.19,0.061,0.061,0.137,0.235,0.198c0.174,0.061,0.435-0.099,0.808,0.084c0.373,0.183,0.379,0.243,0.677,0.213
        c0.298-0.023,0.693-0.175,1.104-0.129c0.411,0.045,0.518,0.099,0.647,0.045c0.129-0.045,0.129-0.19,0.266-0.159
        c0.13,0.037,0.115,0.266,0.29,0.266s0.35-0.266,0.189-0.282c-0.151-0.022-0.433,0.007-0.41-0.099
        c0.03-0.106,0.106-0.198,0.274-0.228c0.167-0.03,0.341-0.03,0.457-0.068c0.113-0.031,0.129-0.229-0.092-0.26
        c-0.229-0.022-0.336,0.046-0.442-0.007c-0.106-0.053-0.297-0.19-0.113-0.229c0.183-0.045,0.357,0.077,0.615-0.091
        c0.251-0.167,0.185-0.32,0.428-0.335c0.251-0.015,0.297-0.13,0.457-0.13c0.152,0,0.259,0.115,0.402,0.229
        c0.146,0.115,0.229,0.206,0.297,0.327c0.069,0.123-0.121,0.145-0.099,0.252c0.031,0.107-0.114,0.16-0.305,0.19
        c-0.197,0.031-0.312,0.054-0.563,0.031c-0.251-0.015-0.434-0.054-0.381,0.069c0.054,0.121,0.007,0.228,0.265,0.197
        c0.269-0.031,0.345-0.069,0.299,0.038c-0.046,0.114-0.434,0.16-0.479,0.312c-0.046,0.16-0.289,0.206-0.221,0.289
        c0.066,0.092,0.175,0.039,0.373,0.084c0.19,0.046,0.213,0.054,0.334-0.167c0.123-0.229,0.373-0.472,0.678-0.472
        c0.313,0,0.503-0.031,0.716-0.122c0.206-0.099,0.541-0.457,0.487-0.487c-0.053-0.038-0.487,0.266-0.426,0.068
        c0.053-0.205,0.434-0.259,0.479-0.373c0.054-0.114-0.008-0.35-0.175-0.335c-0.168,0.015-0.602-0.152-0.565-0.335
        c0.039-0.182,0.261-0.197,0.359-0.297C58.91,9.258,58.895,9.059,59,9.006c0.107-0.053,0.298-0.274,0.42-0.311
        c0.121-0.031,0.19-0.024,0.365-0.008c0.176,0.008,0.176-0.046,0.19-0.145c0.022-0.099,0.145-0.153,0.221-0.129
        c0.068,0.022,0.419-0.061,0.541-0.229c0.129-0.168,0.052-0.243,0.289-0.517c0.244-0.282,0.716-0.869,0.868-0.785
        c0.145,0.084,0.244,0.137,0.273,0.039c0.038-0.092-0.158-0.176,0.115-0.183c0.281-0.008,0.807-0.145,1.096-0.099
        c0.289,0.045,0.389,0.106,0.64,0.129c0.252,0.023,0.883-0.152,0.853,0.054c-0.038,0.205-0.418,0.159-0.73,0.304
        c-0.313,0.136-0.822,0.495-1.06,0.526c-0.235,0.03-0.806-0.009-0.958,0.037c-0.145,0.053-0.062,0.16-0.152,0.229
        c-0.1,0.06-0.319,0.076-0.449,0.175c-0.13,0.106-0.427,0.015-0.495,0.16c-0.061,0.136,0.062,0.136,0.152,0.198
        c0.092,0.068,0.206,0.167,0.206,0.236c0.007,0.075-0.184,0.297-0.198,0.434c-0.016,0.137,0.114,0.167,0.121,0.273
        c0.009,0.1,0.009,0.13-0.151,0.229c-0.152,0.091-0.595,0.199-0.541,0.366c0.061,0.16,0.235,0.175,0.313,0.152
        c0.076-0.023,0.441-0.22,0.449-0.054c0.008,0.168-0.328,0.175-0.32,0.313c0.008,0.13,0.092,0.236,0.031,0.289
        c-0.054,0.053-0.412,0.275-0.206,0.327c0.206,0.054,0.435-0.235,0.708-0.373c0.274-0.144,0.326-0.334,0.57-0.258
        c0.252,0.076,0.199,0.146,0.32,0.258c0.122,0.123,0.1,0.335-0.016,0.427c-0.113,0.099-0.281,0-0.479,0.084
        c-0.189,0.084-0.205,0.16-0.221,0.228c-0.016,0.068,0.151,0.342,0.146,0.449c-0.016,0.115,0.137,0.175,0.312,0.107
        c0.175-0.069,0.297-0.046,0.411-0.205c0.106-0.161-0.106-0.161,0.075-0.396c0.184-0.237,0.222-0.373,0.352-0.267
        c0.137,0.114-0.099,0.319,0.068,0.342c0.168,0.016,0.472-0.273,0.587-0.403c0.113-0.122,0.524-0.16,0.67-0.297
        c0.144-0.129,0.038-0.244,0.121-0.388c0.084-0.145,0.32-0.48,0.533-0.48c0.205,0,0.267,0.099,0.64,0.152
        c0.381,0.054,0.737-0.099,0.77,0.084c0.037,0.182-0.283,0.266-0.023,0.335c0.251,0.069,0.532,0.145,0.373,0.244
        c-0.152,0.107-0.29,0.076-0.479,0.19c-0.184,0.115-0.549,0.038-0.624,0.206c-0.077,0.159-0.114,0.312-0.016,0.487
        c0.1,0.175,0.198,0.213,0.114,0.343c-0.076,0.129-0.16,0.251-0.441,0.289c-0.289,0.031-0.426,0.061-0.587,0.167
        c-0.167,0.107-0.525,0.168-0.724,0.236c-0.205,0.069-0.281,0.077-0.342-0.015c-0.068-0.084-0.1-0.281-0.175-0.182
        c-0.069,0.107-0.076,0.159-0.069,0.281c0.008,0.122,0.106,0.199-0.122,0.122c-0.228-0.069-0.175,0.145-0.091,0.206
        c0.091,0.061,0.243,0.137,0.16,0.19c-0.076,0.054-0.282,0.198-0.351,0.038c-0.076-0.16-0.023-0.434-0.183-0.472
        c-0.152-0.031-0.122,0.046-0.289-0.053c-0.16-0.099-0.313-0.16-0.479-0.153c-0.175,0.008-0.352,0.054-0.229,0.153
        c0.122,0.099,0.206,0.168,0.084,0.228c-0.122,0.061-0.616,0.274-0.807,0.373c-0.198,0.099-0.282,0.153-0.495,0.13
        c-0.213-0.031-0.305-0.061-0.495-0.153c-0.189-0.083-0.479-0.274-0.646-0.267c-0.168,0.008-0.282,0.115-0.046,0.306
        c0.235,0.182,0.837,0.129,1.073,0.182c0.236,0.061,0.289,0.099,0.16,0.183c-0.13,0.091-0.686,0.472-1.043,0.654
        c-0.358,0.183-0.579,0.282-0.814,0.229c-0.229-0.054-0.45-0.183-0.634-0.054c-0.182,0.138-0.311,0.313-0.509,0.336
        c-0.205,0.023-0.502,0.007-0.646-0.038c-0.153-0.039-0.382-0.183-0.328-0.031c0.054,0.153,0.356,0.198,0.457,0.267
        c0.106,0.076,0.122,0.16,0.016,0.221c-0.107,0.061-0.427,0.243-0.647,0.258c-0.222,0.023-0.718-0.099-0.679,0.031
        c0.031,0.13,0.168,0.13,0.138,0.198c-0.022,0.06-0.054,0.122-0.32,0.144c-0.274,0.031-0.502,0.062-0.639,0.214
        c-0.13,0.159-0.382,0.266-0.503,0.304c-0.122,0.046-0.792,0.51-0.944,0.632c-0.159,0.122-0.396,0.313-0.624,0.487
        c-0.221,0.168-0.464,0.236-0.654,0.518c-0.19,0.275-0.305,0.472-0.344,0.556c-0.029,0.077-0.121,0.282,0.145,0.342
        c0.26,0.062,0.557-0.144,0.572,0.039c0.015,0.183,0.007,0.502-0.099,0.67c-0.107,0.16-0.207,0.327-0.191,0.434
        c0.016,0.114-0.243,0.472,0.244,0.35c0.486-0.114,0.722-0.342,1.081-0.114c0.357,0.236,0.457,0.32,0.678,0.35
        c0.221,0.038,0.388-0.03,0.449,0.282s0.235,0.548,0.814,0.678c0.579,0.136,0.427,0.228,0.731,0.311
        c0.312,0.084,1.545,0.031,1.583,0.267c0.03,0.237-0.434,0.487-0.472,0.769c-0.039,0.274-0.114,0.427-0.221,0.541
        c-0.099,0.106-0.198,0.243-0.289,0.395c-0.084,0.146-0.048,0.214,0.03,0.389c0.076,0.175,0.068,0.229,0.128,0.412
        c0.063,0.19,0.177,0.098,0.251,0.349c0.084,0.259,0.078,0.359,0.222,0.472c0.138,0.122,0.244,0.069,0.403-0.053
        c0.16-0.121,0.267-0.358,0.382-0.221c0.114,0.138,0.229,0.236,0.335,0.1c0.106-0.13-0.03-0.259,0.145-0.427
        c0.175-0.168,0.357-0.35,0.411-0.685c0.06-0.328-0.054-0.427,0.213-0.822c0.267-0.396-0.159-0.8,0.106-0.922
        c0.274-0.121,1.447-0.319,2.338-0.982c0.891-0.662,1.104-1.576,1.035-1.828c-0.068-0.25-0.525-0.518-0.389-0.738
        c0.138-0.214,0.077-0.084,0.358-0.266c0.282-0.183,0.776-0.328,0.914-0.602c0.145-0.274,0.251-0.579,0.213-0.701
        c-0.038-0.121-0.251-0.197,0-0.311c0.251-0.115,0.473-0.267,0.495-0.472c0.03-0.207,0.067-0.359,0.114-0.488
        c0.053-0.129,0.853-0.449,1.096-0.29c0.244,0.161,0.351,0.153,0.784,0.198c0.435,0.046,0.549,0.031,0.739-0.045
        c0.197-0.084,0.128-0.221,0.411-0.084c0.273,0.137,0.319,0.106,0.441,0.336c0.121,0.227,0.343,0.258,0.357,0.373
        c0.016,0.114-0.327,0.159-0.145,0.319c0.175,0.153,0.518,0.122,0.701,0.183c0.183,0.061,0.19,0.168,0.318,0.106
        c0.131-0.061,0.215-0.205,0.252-0.061c0.038,0.138-0.243,0.191-0.35,0.366c-0.106,0.175-0.213,0.419-0.259,0.464
        c-0.038,0.047-0.19,0.244-0.214,0.365c-0.029,0.122-0.159,0.214-0.068,0.351c0.092,0.137,0.16,0.069,0.388,0.243
        c0.237,0.175,0.062,0.305,0.328,0.427c0.267,0.114,0.929-0.114,1.066-0.252c0.137-0.144,0.105-0.106,0.373-0.175
        c0.259-0.068,0.167,0.054,0.38-0.221c0.222-0.266,0.42-0.373,0.64-0.624c0.229-0.251,0.366-0.662,0.548-0.51
        c0.184,0.146,0.008,0.64,0.138,0.868c0.13,0.229,0.229,0.321,0.175,0.45c-0.053,0.129,0.122,0.266,0.023,0.35
        c-0.107,0.091-0.29,0.167-0.107,0.221c0.176,0.053,0.328,0.007,0.306,0.106c-0.016,0.099-0.1,0.304-0.054,0.48
        c0.046,0.175-0.183,0.19-0.016,0.305c0.176,0.114,0.29,0.213,0.176,0.304c-0.114,0.084-0.352,0.061-0.358,0.206
        c-0.015,0.146-0.046,0.228,0.046,0.274c0.084,0.052,0.389,0.297,0.351,0.517c-0.038,0.214,0.35,0.176,0.297,0.328
        c-0.054,0.16-0.114,0.274,0.114,0.282c0.229,0.015,0.289-0.107,0.586,0.084c0.297,0.19,0.365,0.159,0.532,0.213
        c0.167,0.045,0.496,0.091,0.268,0.205c-0.229,0.114-0.404,0.031-0.814,0.213c-0.413,0.183-0.754,0.312-1.074,0.381
        c-0.313,0.068-0.762,0.282-0.327,0.297c0.434,0.016,0.502-0.031,0.723-0.229c0.214-0.198,0.549-0.426,0.83-0.418
        c0.282,0.008,0.366-0.069,0.465,0.053c0.098,0.122,0.114,0.251,0.046,0.351c-0.068,0.098-0.427,0.304-0.16,0.273
        c0.259-0.023,0.38-0.35,0.585-0.228c0.207,0.122,0.625,0.335,0.436,0.579c-0.198,0.251-0.427,0.243-0.442,0.418
        c-0.015,0.168-0.022,0.32-0.136,0.412c-0.107,0.084,0.029,0.274-0.282,0.358c-0.313,0.09-0.464,0.266-0.968,0.258
        c-0.494-0.007-0.578,0.015-0.891,0.168c-0.319,0.16-0.662,0.494-1.134,0.685c-0.473,0.19-1.653,0.228-2.422,0.198
        c-0.776-0.023-1.111-0.008-1.759-0.023c-0.646-0.015-1.202-0.038-1.378,0.313c-0.175,0.35-0.198,0.594-0.472,0.54
        c-0.267-0.061-1.157,0.297-1.5,0.601c-0.351,0.305-0.77,0.556-0.892,0.648c-0.122,0.098-0.662,0.51-0.472,0.532
        c0.183,0.031,0.221-0.06,0.556-0.266c0.335-0.213,1.188-0.761,1.645-0.928c0.45-0.161,1.059-0.313,1.37-0.419
        c0.32-0.114,0.633-0.107,0.93-0.069c0.297,0.03,0.518,0.046,0.533,0.198c0.015,0.16-0.176,0.054-0.19,0.282
        c-0.016,0.22,0.015,0.251-0.283,0.403c-0.296,0.153-0.585,0.221-0.806,0.145c-0.213-0.069-0.61-0.213-0.427,0
        c0.19,0.22,0.106,0.243,0.282,0.35c0.175,0.114,0.533-0.214,0.661-0.175c0.123,0.031,0.306,0.06,0.077,0.236
        c-0.229,0.168-0.335,0.343-0.412,0.396c-0.075,0.053,0.032,0.091,0.047,0.167c0.008,0.077-0.047,0.176-0.084,0.282
        c-0.038,0.106-0.038,0.457,0.16,0.487c0.205,0.031,0.35-0.129,0.396,0.038c0.038,0.176-0.274,0.168,0.092,0.267
        c0.365,0.099,0.548,0.122,0.806,0.137c0.268,0.008,0.252-0.205,0.496-0.099c0.243,0.107,0.206,0.091,0.335-0.114
        c0.129-0.198,0.274-0.343,0.434-0.487c0.16-0.138,0.268-0.655,0.487-0.472c0.221,0.182,0.046,0.258,0.023,0.418
        c-0.016,0.168,0.121,0.237,0.183,0.328c0.061,0.084,0.128,0.183-0.13,0.312c-0.251,0.122-0.578,0.343-0.632,0.183
        c-0.053-0.168-0.145-0.274-0.197-0.198c-0.062,0.076-0.123,0.243-0.146,0.35s-0.022,0.16-0.251,0.236
        c-0.229,0.076-0.731,0.282-1.149,0.396c-0.419,0.113-0.548,0.266-0.738,0.266s-0.282-0.22-0.441-0.076
        c-0.153,0.145-0.526,0.472-0.724,0.594c-0.206,0.122-0.382,0.061-0.466,0.152c-0.091,0.084-0.258,0.229-0.296,0.084
        c-0.03-0.144-0.229,0.061-0.183-0.205c0.038-0.259,0.122-0.42,0.183-0.472c0.061-0.046,0.274-0.252,0.503-0.366
        c0.228-0.114,0.692-0.358,0.936-0.381s0.503,0.084,0.519-0.114c0.007-0.198-0.618,0.046-0.541-0.091
        c0.069-0.145,0.229-0.282,0.069-0.282c-0.168,0-0.32,0.046-0.587,0.221c-0.259,0.168-0.268,0.274-0.624,0.282
        c-0.358,0.007-0.571-0.13-0.687,0.015c0,0.014-0.007,0.023-0.014,0.03c-0.092-0.091-0.16-0.19-0.106-0.236
        c0.151-0.152,0.145-0.16,0.036-0.259c-0.029-0.03-0.067-0.075-0.12-0.144c-0.107-0.153-0.038-0.282,0.053-0.457
        c0.031-0.069,0.067-0.137,0.099-0.213c0.023-0.061,0.046-0.107,0.068-0.152c0.1-0.214,0.152-0.328-0.061-0.534
        c-0.137-0.137-0.281-0.114-0.403-0.091c-0.062,0.008-0.114,0.016-0.122-0.03c-0.015-0.092-0.061-0.122-0.138-0.107
        c-0.067,0.015-0.158,0.076-0.266,0.153c-0.076,0.052-0.167,0.113-0.251,0.151c-0.16,0.084-0.244,0.213-0.328,0.358
        c-0.069,0.107-0.137,0.221-0.243,0.312c-0.13,0.107-0.297,0.275-0.449,0.427c-0.16,0.153-0.305,0.297-0.396,0.365
        c-0.054,0.038-0.108,0.054-0.153,0.069c-0.099,0.023-0.19,0.045-0.328,0.259c-0.129,0.183-0.242,0.168-0.524,0.145
        c-0.129-0.008-0.304-0.023-0.525-0.023c-0.313,0-0.502,0-0.662,0.007c-0.207,0-0.374,0.008-0.73,0
        c-0.67-0.007-1.341,0.579-1.531,0.738v0.009c-0.03,0.021-0.053,0.045-0.075,0.068c-0.054-0.023-0.114-0.023-0.19-0.008
        c-0.19,0.054-0.335,0.092-0.525,0.161c-0.198,0.068-0.154-0.031-0.405-0.009c-0.25,0.023-0.524,0.069-0.676,0.107
        c-0.16,0.045-0.298,0-0.435,0.045c-0.139,0.054-0.222,0.116-0.297,0.153c-0.076,0.03-0.205,0.168-0.205,0.168
        s-0.199,0.084-0.145,0.19c0.046,0.107-0.038,0.091,0.189,0.091c0.022,0,0.054-0.007,0.076-0.007l-0.009,0.016
        c-0.021,0.075-0.045,0.167-0.083,0.243c-0.122,0.068-0.327,0.06-0.448,0.107c-0.114,0.045-0.236-0.047-0.435,0.068
        c-0.206,0.114-0.175,0.144-0.381,0.122c-0.198-0.023-0.099-0.122-0.373-0.023c-0.273,0.099-0.396,0.076-0.563,0.22
        c-0.167,0.138-0.266,0.252-0.396,0.275c-0.13,0.023-0.153,0.098-0.259,0.098c-0.107,0.008-0.138,0.016-0.19-0.022
        c-0.008-0.016-0.03-0.023-0.061-0.023c0.015-0.038,0.037-0.069,0.061-0.099c0.083-0.114,0.198-0.213,0.251-0.252
        c0.084-0.052,0.16-0.129,0.259-0.213c0.076-0.06,0.159-0.137,0.252-0.205c0.083-0.069,0.151-0.152,0.205-0.236
        c0.037-0.008,0.076-0.023,0.13-0.038c0.205-0.077,0.242-0.008,0.364-0.221c0.115-0.213,0.146-0.396,0.313-0.708
        c0.168-0.304,0.44-0.373,0.457-0.586c0.022-0.206-0.167-0.397,0.046-0.404c0.206-0.007,0.267,0.038,0.259,0.221
        c0,0.183,0.22,0.419,0.32,0.434c0.105,0.015,0.221,0.115,0.259-0.015c0.046-0.13-0.008-0.32,0.114-0.32
        c0.121-0.008,0.221,0.106,0.19-0.069c-0.022-0.167-0.069-0.183-0.16-0.381c-0.092-0.197-0.038-0.502-0.176-0.539
        c-0.137-0.032-0.38-0.092-0.51-0.008c-0.129,0.083-0.061,0.159-0.229,0.22c-0.167,0.053-0.167,0.107-0.389,0.023
        c-0.221-0.076-0.601-0.129-0.319-0.175c0.282-0.053,0.549,0.046,0.577-0.023c0.039-0.061,0.048-0.251-0.135-0.251
        c-1.044,0-0.922-0.046-1.196-0.061c-0.022-0.031-0.047-0.053-0.068-0.084c-0.053-0.061-0.084-0.099-0.114-0.13
        c-0.022-0.022-0.038-0.045-0.054-0.06c0.039-0.114,0.046-0.289,0.046-0.38c0-0.13,0.069-0.153,0.122-0.252
        c0.054-0.091-0.017-0.1-0.114-0.236c-0.091-0.137,0.13-0.153,0.236-0.335c0.099-0.183-0.101-0.091-0.251-0.084
        c-0.152,0.008-0.411-0.015-0.503-0.168c-0.091-0.152,0.076-0.387,0.016-0.539c-0.054-0.145-0.19-0.047-0.427-0.054
        c-0.229-0.008-0.16,0.076-0.435-0.069c-0.273-0.144-0.137-0.053-0.297-0.022c-0.151,0.022-0.365,0.259-0.448,0.342
        c-0.076,0.076-0.175,0.068-0.313-0.023c-0.144-0.099-0.525,0.343-0.539,0.373c0,0-0.031,0.031-0.076,0.069
        c-0.146-0.023-0.176-0.023-0.222-0.016c-0.03,0.008-0.068,0.016-0.146,0.008h-0.098c-0.267,0-0.808-0.015-0.984-0.114
        c-0.181-0.107-0.204-0.107-0.288-0.121c-0.015,0-0.029-0.009-0.053-0.009c-0.054-0.007-0.106-0.021-0.161-0.06
        c-0.06-0.039-0.12-0.092-0.204-0.19c-0.13-0.161-0.351-0.123-0.533-0.084c-0.076,0.015-0.145,0.022-0.19,0.022
        c-0.045-0.007-0.092-0.015-0.137-0.015c-0.138-0.007-0.282-0.015-0.366-0.099c-0.038-0.03,0-0.099,0.038-0.16
        c0.061-0.099,0.123-0.198,0.046-0.305c-0.061-0.084-0.122-0.075-0.197-0.029c-0.04,0.029-0.084,0.075-0.13,0.128
        c-0.061,0.069-0.13,0.153-0.213,0.191c-0.107,0.061-1.454,0.046-2.917,0.038c-1.065-0.008-2.185-0.015-2.961,0
        c-0.73,0.016-2.97,0.008-5.414-0.008c-3.692-0.022-7.872-0.068-8.025-0.083l-0.075-0.008c-0.184-0.015-0.366-0.03-0.487-0.008
        c-0.008-0.022-0.024-0.053-0.03-0.075c-0.084-0.206,0.053-0.373-0.123-0.359c-0.175,0.023-0.206-0.014-0.235-0.098
        c-0.022-0.084-0.076-0.259-0.152-0.091c-0.084,0.175-0.259,0.084-0.221-0.039c0.045-0.122,0.267-0.221,0.183-0.32
        c-0.076-0.091-0.304-0.091-0.358,0.015c-0.053,0.107-0.113,0.312-0.061,0.45c0.054,0.129,0.084,0.251,0.252,0.35
        c0.167,0.092,0.297,0.114,0.343,0.305c0.053,0.182-0.017,0.418-0.214,0.464c-0.199,0.046-0.381-0.038-0.557-0.046l-0.061-0.007
        c-0.038-0.008-0.099-0.016-0.167-0.023c-0.084-0.023-0.16-0.054-0.198-0.106c-0.083-0.107-0.015-0.237-0.046-0.336
        c-0.022-0.106-0.13-0.152-0.205-0.091c-0.076,0.061-0.167,0.122-0.19-0.031c-0.024-0.16,0.06-0.121-0.092-0.22
        c-0.159-0.092-0.268-0.298-0.274-0.404c-0.006-0.106-0.128-0.129-0.144-0.213c-0.022-0.092-0.168-0.069-0.176-0.175
        c-0.008-0.114,0.069-0.114,0.038-0.236c-0.03-0.122-0.092-0.435,0.145-0.396c0.244,0.045,0.395-0.084,0.602,0.16
        c0.214,0.251,0.373,0.205,0.525,0.258c0.145,0.054,0.511,0.191,0.457,0.046c-0.053-0.137-0.168-0.175-0.281-0.182
        c-0.115-0.016-0.008-0.084-0.145-0.168c-0.139-0.076-0.624,0.015-0.541-0.304c0.083-0.313,0.175-0.419,0.151-0.518
        c-0.015-0.099-0.113-0.198,0.176-0.305c0.289-0.098,0.351-0.259,0.214-0.304c-0.139-0.039-0.244,0.076-0.268-0.054
        c-0.029-0.129-0.151-0.091-0.166-0.182c-0.009-0.084,0.015-0.183,0.121-0.267s0.235-0.16,0.327-0.259
        c0.084-0.091,0.068-0.396-0.114-0.236c-0.183,0.167-0.281,0.351-0.327,0.176c-0.047-0.176-0.152-0.084-0.137-0.245
        c0.007-0.167-0.077-0.296,0.09-0.327c0.168-0.03,0.161-0.068,0.268-0.146c0.114-0.068,0.213-0.205,0.259-0.395
        c0.016-0.054,0.008-0.106-0.015-0.161c0.106-0.045,0.243-0.098,0.364-0.144l0.146-0.053c0.121-0.053,0.244-0.122,0.351-0.198
        c0.114-0.084,0.212-0.175,0.273-0.252c0.046-0.06,0.092-0.099,0.145-0.136c0.077-0.054,0.146-0.115,0.198-0.221
        c0.03-0.077,0.03-0.145,0.023-0.207c-0.023-0.091-0.084-0.159-0.115-0.174c-0.007-0.007-0.022-0.015-0.037-0.023
        c-0.076-0.03-0.237-0.106-0.373-0.251c-0.069-0.061-0.024-0.114,0.022-0.168c0.046-0.061,0.099-0.121,0.075-0.205
        c-0.021-0.068-0.015-0.138,0.009-0.229c0.022-0.091,0.068-0.198,0.137-0.334c0.068-0.153,0.046-0.427,0.03-0.678
        c-0.022-0.206-0.038-0.397,0.008-0.442c0.092-0.114,0.038-0.183-0.03-0.251c-0.031-0.038-0.061-0.077-0.07-0.122
        c-0.006-0.053,0-0.091,0.01-0.121c0.007-0.069,0.022-0.123-0.046-0.207c-0.068-0.083-0.175-0.06-0.313-0.022
        c-0.076,0.015-0.168,0.038-0.259,0.038c-0.16,0-0.236,0.076-0.319,0.152c-0.062,0.054-0.122,0.114-0.229,0.153
        c-0.138,0.045-0.282,0.113-0.404,0.168c-0.114,0.06-0.213,0.106-0.281,0.121l-0.038,0.007c-0.078,0.023-0.084,0.031-0.138-0.061
        c-0.03-0.053-0.015-0.099,0.016-0.151c0.022-0.055,0.054-0.115,0.054-0.198c0-0.13-0.046-0.214-0.1-0.305
        c-0.022-0.038-0.038-0.076-0.061-0.115c-0.017-0.052,0.008-0.076,0.038-0.107c0.045-0.037,0.091-0.091,0.068-0.182
        c-0.023-0.114-0.114-0.122-0.236-0.099c-0.068,0.015-0.144,0.046-0.222,0.076c-0.045,0.016-0.09,0.031-0.136,0.046
        c-0.106,0.031-0.205,0.046-0.297,0.031c-0.084-0.008-0.151-0.039-0.191-0.077c0-0.007,0.122-0.099,0.298-0.274
        c0.205-0.198,0.511-0.472,0.876-0.792c1.188-1.058,3.03-2.626,4.355-3.746C29.284,11.534,30.685,10.499,31.545,9.882
         M46.491,7.994c0,0-0.441,0.395-0.898,0.525c-0.457,0.122-0.595,0.054-0.922,0.122c-0.334,0.076-0.503,0.167-0.753,0.19
        c-0.259,0.031-0.357,0.16-0.351-0.061c0.008-0.213,0.145-0.388-0.038-0.411c-0.183-0.015-0.267-0.03-0.381-0.068
        c-0.122-0.046-0.411-0.038-0.342-0.122c0.066-0.076,0.35,0.008,0.691-0.259c0.352-0.274,0.586-0.175,0.884-0.312
        c0.298-0.137,0.48-0.366,0.892-0.495c0.411-0.137,0.495-0.114,0.533-0.312c0.03-0.206-0.223-0.221,0.197-0.313
        c0.419-0.091,0.609-0.205,1.202-0.136c0.595,0.075,0.687-0.031,0.93,0.061c0.252,0.098,0.168-0.031,0.617,0.167
        c0.441,0.19,0.678-0.092,1.08-0.008c0.404,0.077,0.488,0.16,0.511,0.244c0.023,0.076,0.137,0.137-0.055,0.175
        c-0.188,0.031,0,0.168-0.401,0.183c-0.403,0.023-1.051,0.152-1.614,0.312c-0.557,0.16-0.777,0.29-1.189,0.396
        c-0.41,0.099-0.509,0.061-0.531,0.077C46.529,7.956,46.491,7.994,46.491,7.994 M68.496,10.332
        c-0.039,0.022-0.313,0.167-0.419,0.198c-0.106,0.038-0.282,0.152-0.229,0.182c0.053,0.038,0.213,0.038,0.35-0.007
        c0.138-0.038,0.184-0.115,0.274-0.153c0.092-0.038,0.335-0.213,0.252-0.243C68.641,10.278,68.496,10.332,68.496,10.332
         M70.155,11.36c0,0-0.122,0.06-0.053,0.136c0.061,0.076,0.167,0.038,0.327,0.061c0.167,0.023,0.229,0.061,0.335,0.03
        c0.106-0.037,0.19-0.159,0.092-0.198c-0.108-0.038-0.206-0.015-0.297-0.038c-0.092-0.03-0.146-0.06-0.229-0.053
        C70.247,11.305,70.155,11.36,70.155,11.36 M70.155,10.865c-0.007,0-0.19,0.053-0.159,0.129c0.022,0.068,0.137,0.068,0.167,0.099
        c0.022,0.03,0.251-0.137,0.145-0.206C70.208,10.819,70.155,10.865,70.155,10.865 M69.258,11.154
        c-0.046,0-0.944,0.373-1.005,0.517c-0.062,0.153-0.039,0.373,0.12,0.351c0.153-0.023,0.26-0.084,0.504-0.076
        c0.243,0.007,0.525-0.054,0.677-0.184c0.145-0.136,0.19-0.22,0.29-0.35c0.099-0.121,0.091-0.213,0.061-0.266
        c-0.038-0.053-0.357-0.038-0.441-0.031C69.379,11.123,69.258,11.154,69.258,11.154 M65.588,7.111
        c-0.023,0.007-0.687,0.197-1.007,0.472c-0.326,0.274-0.569,0.35-0.676,0.465c-0.106,0.113-0.039,0.136-0.206,0.212
        c-0.16,0.077-0.175,0.198-0.244,0.274c-0.076,0.084-0.213,0.137-0.091,0.214c0.129,0.083,0.502-0.023,0.647,0.038
        c0.137,0.068,0.411,0.106,0.373,0.16c-0.039,0.045,0.091,0.205-0.236,0.175c-0.32-0.031-0.373-0.077-0.511-0.099
        c-0.129-0.023-0.373-0.153-0.389-0.053c-0.015,0.099-0.159,0.114-0.053,0.273c0.106,0.152,0.106,0.214,0.229,0.259
        c0.129,0.046,0.35,0,0.547,0.039c0.19,0.045,0.39-0.091,0.457,0c0.062,0.091-0.022,0.144,0.183,0.182
        c0.206,0.038,0.328,0.008,0.525,0.023c0.198,0.008,0.396,0.023,0.693,0.069s0.472-0.038,0.602-0.009
        c0.137,0.039,0.251,0.161,0.396,0.13c0.152-0.022,0.145-0.13,0.236-0.13c0.099-0.007,0.152,0.055,0.205,0.084
        c0.054,0.039,0.267,0.191,0.479,0.214c0.222,0.03,0.373,0.137,0.449,0c0.077-0.13-0.061-0.229-0.22-0.198
        c-0.168,0.022-0.503-0.091-0.465-0.16c0.046-0.061,0.183,0,0.357,0.008c0.176,0.007,0.45,0.076,0.541,0.129
        c0.099,0.053,0.343-0.022,0.473-0.06c0.137-0.039,0.175-0.146,0.106-0.229c-0.077-0.084-0.152-0.221,0.045-0.213
        c0.198,0.007,0.076,0.007,0.213,0.16c0.146,0.144,0.305,0.015,0.335,0.121c0.023,0.107-0.273,0.199-0.099,0.297
        c0.175,0.099,0.381,0.069,0.419,0.129c0.046,0.062,0.068,0.175,0.175,0.245c0.106,0.068,0.252,0.114,0.062,0.159
        c-0.19,0.046-0.473-0.015-0.533,0.053c-0.061,0.07-0.365,0.252-0.175,0.282c0.189,0.039,0.396-0.137,0.57-0.114
        c0.183,0.03,0.372-0.069,0.456,0.015s0.176,0.13,0.229,0.214c0.053,0.091,0.152,0.198,0.327,0.266
        c0.176,0.069,0.229,0.076,0.222,0.221c-0.008,0.152-0.046,0.38-0.107,0.518c-0.061,0.144-0.334,0.007-0.587,0.296
        c-0.258,0.29-0.981,0.412-1.148,0.541c-0.168,0.129-0.038,0.312,0.008,0.442c0.038,0.129,0.105,0.251-0.114,0.205
        c-0.229-0.038-0.19-0.175-0.351-0.076c-0.152,0.092-0.518,0.145-0.731,0.198c-0.213,0.054-0.221,0.099-0.457,0.007
        c-0.235-0.083-0.364-0.19-0.601-0.136c-0.236,0.045-0.359,0.19-0.549,0.251s-0.373,0.175-0.426,0.281
        c-0.054,0.099-0.26,0.222-0.022,0.343c0.229,0.122,0.411,0.099,0.722,0.069c0.321-0.039,0.496-0.289,0.762-0.168
        c0.274,0.122,0.351,0.137,0.579,0.099c0.229-0.045,0.449-0.19,0.671-0.068c0.228,0.121,0.235,0.152,0.25,0.259
        c0.016,0.114,0.069,0.403,0.26,0.426c0.189,0.031,0.388,0.061,0.259,0.167c-0.122,0.099-0.381,0.054-0.297,0.183
        c0.076,0.122,0.381,0.282,0.419,0.358c0.046,0.084-0.069,0.182,0.129,0.228c0.206,0.038,0.457-0.197,0.579-0.068
        c0.122,0.129,0.418,0.267,0.685,0.343c0.267,0.075,0.449,0.068,0.663,0.152c0.205,0.091,0.381,0.205,0.494,0.114
        c0.114-0.084,0.145-0.236,0.114-0.335c-0.023-0.099-0.305-0.525-0.601-0.708c-0.298-0.183-0.511-0.495-0.313-0.503
        c0.197-0.015,0.426,0.061,0.579,0.275c0.159,0.213,0.434,0.274,0.57,0.38c0.13,0.099,0.579,0.206,0.632,0.304
        c0.054,0.107,0.206,0.245,0.298,0.139c0.084-0.108-0.069-0.306-0.009-0.42c0.062-0.114,0.151-0.335,0.199-0.465
        c0.045-0.129,0.015,0.084,0.189,0.069c0.176-0.022,0.297-0.236,0.282-0.373c-0.015-0.137-0.023-0.236-0.19-0.434
        c-0.168-0.191-0.252-0.312-0.396-0.48c-0.152-0.175-0.335-0.243-0.335-0.395c0-0.153-0.046-0.13-0.145-0.32
        c-0.106-0.198,0.038-0.275,0.137-0.214c0.106,0.061,0.274,0.267,0.343,0.069c0.067-0.198,0.038-0.381,0.214-0.32
        c0.175,0.061,0.35,0.069,0.426,0.236c0.076,0.168,0.198,0.427,0.335,0.533c0.136,0.114,0.289,0.632,0.708,0.442
        s0.365-0.381,0.571-0.487c0.198-0.107,0.472-0.191,0.724-0.351c0.258-0.159,0.327-0.19,0.503-0.266
        c0.173-0.084,0.685-0.434,0.312-0.503c-0.381-0.068-0.716-0.038-0.716-0.175c0-0.137-0.053-0.244-0.274-0.266
        c-0.213-0.031-0.121-0.183-0.267-0.328c-0.151-0.152-0.121-0.159-0.434-0.152c-0.313,0.016-0.578,0.084-0.701-0.13
        c-0.122-0.22-0.51-0.205-0.578-0.342c-0.068-0.13,0.023-0.16,0.19-0.229c0.16-0.068,0.229-0.213,0.479-0.175
        c0.26,0.046,0.571-0.076,0.411-0.19c-0.168-0.107-0.692,0-0.646-0.114c0.053-0.122,0.327-0.137,0.495-0.152
        c0.167-0.016,0.235-0.221,0.182-0.313C75.551,9.608,75.423,9.54,75.3,9.593c-0.13,0.061-0.327,0.099-0.297,0.022
        c0.022-0.083,0.343-0.151,0.282-0.228c-0.062-0.068-0.465-0.198-0.701-0.213c-0.243-0.015-0.419,0.084-0.434,0.023
        c-0.023-0.062-0.031-0.191-0.137-0.175c-0.115,0.015-0.298,0.076-0.457,0.114c-0.16,0.038-0.305-0.061-0.168-0.13
        c0.137-0.068,0.365-0.099,0.487-0.121c0.129-0.031,0.167-0.229-0.047-0.274c-0.212-0.046-0.235-0.092-0.486,0.007
        c-0.251,0.107-0.061,0.228-0.305,0.152c-0.243-0.083-0.243-0.114-0.229-0.174c0.016-0.062,0.1-0.305-0.068-0.366
        c-0.16-0.061-0.221-0.061-0.297-0.175c-0.068-0.122,0.114-0.213-0.221-0.19c-0.335,0.015-0.556-0.146-0.655-0.153
        c-0.099-0.007-0.129-0.129,0.046-0.084c0.176,0.046,0.511,0,0.587-0.03c0.084-0.023,0.251-0.16-0.023-0.312
        c-0.273-0.16-0.267-0.38-0.67-0.305c-0.403,0.069-0.662,0.061-0.853,0.031c-0.199-0.023-0.389-0.091-0.525,0
        c-0.146,0.091-0.357,0.213-0.244,0.274c0.114,0.068,0.244,0.038,0.229,0.167c-0.022,0.13-0.084,0.236,0.016,0.252
        c0.106,0.022,0.571-0.069,0.746-0.077c0.175-0.007,0.541,0.008,0.479,0.069c-0.061,0.069-0.305,0.054-0.472,0.122
        C70.719,7.88,70.681,7.933,70.506,8c-0.175,0.077-0.273,0.047-0.396-0.007c-0.123-0.061-0.062-0.16-0.267-0.076
        c-0.198,0.076-0.563,0.312-0.625,0.213c-0.061-0.099-0.03-0.244,0.168-0.305c0.197-0.06,0.389-0.137,0.389-0.183
        c0-0.038,0.083-0.19-0.016-0.274c-0.106-0.083-0.084-0.129-0.054-0.22c0.031-0.092,0.229-0.176,0.038-0.198
        c-0.197-0.023-1.02,0.099-1.462,0.145C67.847,7.14,67.413,7.278,67.3,7.353c-0.114,0.076-0.177,0.206-0.403,0.267
        c-0.236,0.061-0.442,0.228-0.358,0.312c0.092,0.091,0.092,0.122-0.061,0.16c-0.146,0.046-0.176,0.137-0.282,0.167
        c-0.099,0.038-0.19,0.107-0.068,0.183c0.114,0.084,0.297,0.122,0.221,0.198c-0.076,0.076-0.152,0.175-0.327,0.023
        c-0.176-0.159-0.397-0.366-0.229-0.579c0.168-0.22,0.335-0.175,0.372-0.35c0.031-0.175,0.131-0.198,0.351-0.32
        c0.222-0.121,1.287-0.304,1.143-0.48c-0.152-0.175-0.937-0.061-1.18-0.038C66.234,6.928,65.588,7.111,65.588,7.111
         M63.151,16.446c0.129,0.083,0.365-0.023,0.479-0.138c0.113-0.122,0.235-0.48,0.084-0.495
        C63.349,15.776,62.938,16.308,63.151,16.446 M61.278,16.125c0.106-0.016,0.167-0.069,0.38-0.123
        c0.213-0.052,0.548-0.22,0.678-0.304c0.138-0.091,0.288-0.35,0.054-0.304c-0.236,0.046-0.335,0.137-0.479,0.084
        c-0.152-0.054-0.236,0-0.351,0.045C61.179,15.677,60.859,16.171,61.278,16.125 M61.012,13.925c0,0-0.274,0.252-0.297,0.389
        c-0.031,0.136-0.184,0.175-0.383,0.236c-0.196,0.06-1.11,0.128-0.524,0.289c0.587,0.16,0.86-0.106,0.86,0.038
        c-0.008,0.137-0.35,0.419-0.137,0.457c0.213,0.038,0.396,0.023,0.67-0.153c0.268-0.182,0.754-0.296,0.914-0.426
        c0.16-0.129,0.145-0.404,0.396-0.32c0.259,0.092,0,0.221,0.213,0.266c0.214,0.039,0.319,0.176,0.48,0.245
        c0.152,0.076,0.38,0.228,0.655,0.084c0.265-0.146,0.555-0.527,0.251-0.549c-0.297-0.015-0.571,0.129-0.556-0.13
        c0.015-0.251,0.145-0.419-0.062-0.441c-0.206-0.023-0.403-0.282-0.524-0.335c-0.123-0.045-0.297,0.031-0.352-0.038
        c-0.053-0.069-0.273-0.046-0.35-0.061c-0.084-0.016-0.137-0.191-0.038-0.243c0.099-0.054,0.213,0.06,0.327,0
        c0.115-0.062,0.236-0.275,0.046-0.29s-0.281,0.084-0.435,0.099c-0.144,0.008-0.319,0.061-0.434,0.183
        C61.62,13.354,61.012,13.925,61.012,13.925 M67.141,2.435c-0.053,0.099-0.129,0.099-0.252,0.252
        c-0.121,0.152-0.235,0.213-0.106,0.327c0.13,0.115,0.244,0.046,0.503,0.046c0.251,0,0.449-0.007,0.556-0.007
        c0.107,0,0.297,0,0.19,0.062c-0.107,0.06-0.503,0.075-0.624,0.136c-0.129,0.061-0.213,0.1-0.183,0.237
        c0.022,0.144,0.22,0.296,0.388,0.342s0.038-0.03,0.465,0.031c0.427,0.061,0.776-0.252,0.882-0.191
        c0.115,0.061-0.029,0.145,0.253,0.122c0.281-0.031,0.145-0.031,0.372-0.076c0.229-0.046,0.693-0.335,0.892-0.305
        c0.198,0.039,0.259-0.122,0.44-0.144c0.176-0.023,0.328-0.077,0.603-0.038c0.282,0.03,0.356-0.023,0.479-0.069
        c0.122-0.045,0.023-0.236,0.092-0.29c0.068-0.053,0.251-0.144,0.357,0c0.099,0.145-0.099,0.214-0.137,0.275
        s0.624,0.137,0.335,0.228c-0.29,0.092-0.281,0-0.617,0.008c-0.342,0.007-0.608-0.084-1.035,0.038
        c-0.427,0.13-0.876,0.205-0.906,0.312c-0.038,0.107,0.062,0.205,0.351,0.168c0.297-0.038,0.319-0.031,0.358,0.083
        c0.029,0.115,0.022,0.191,0.183,0.236c0.167,0.046,0.41,0.008,0.311,0.091c-0.091,0.077-0.197,0.07-0.493,0.077
        c-0.306,0.007-0.442-0.069-0.495-0.183c-0.054-0.106,0-0.168-0.214-0.168c-0.221,0-0.7-0.038-0.853,0.084
        c-0.16,0.122-0.122,0.084-0.084,0.267c0.046,0.183,0.152,0.213-0.145,0.205c-0.298-0.015-0.343-0.06-0.64,0.008
        c-0.297,0.061-0.45,0.137-0.586,0.175c-0.13,0.03-0.122,0.053-0.229,0.008c-0.106-0.039-0.364-0.114-0.356,0.008
        c0.007,0.121,0.106,0.129,0.205,0.175c0.091,0.045,0.168,0.084,0.381,0.06C68,5,68.008,5.055,68.237,5.032
        C68.465,5,69.242,4.926,69.387,5c0.152,0.061,0.662,0.054,0.853,0c0.19-0.052,0.251-0.198,0.502-0.106
        C71,4.994,71.054,5,71.253,5c0.197,0,0.753-0.068,0.775,0c0.015,0.061-0.365,0.191-0.045,0.237
        c0.327,0.038,0.479-0.039,0.601-0.077s0.184-0.061,0.419-0.068c0.236-0.008,0.305-0.137,0.456-0.106
        c0.146,0.022,0.603-0.054,0.526-0.206c-0.076-0.146-0.296-0.092-0.404-0.107c-0.113-0.015-0.206-0.296,0.031-0.282
        c0.236,0.015,0.16,0.107,0.404,0.031c0.243-0.069,0.281-0.138,0.456-0.175c0.176-0.031,0.206-0.244,0.297-0.244
        c0.1,0,0.105,0.069,0.267,0.046c0.16-0.031,0.753-0.054,0.982-0.168c0.236-0.106,0.327-0.228,0.678-0.243
        c0.35-0.008,0.8-0.077,0.418-0.229c-0.38-0.144-0.7-0.441-0.388-0.35c0.305,0.099,0.7-0.069,0.86-0.069s0.312,0.168,0.661,0.031
        c0.352-0.137,0.023-0.335,0.436-0.259c0.403,0.084,0.51,0.19,0.77,0.106c0.251-0.075,0.715-0.235,0.913-0.35
        c0.205-0.106,0.54-0.069,0.624-0.121c0.076-0.054,0.091,0,0.525-0.13c0.434-0.137,0.373-0.19,0.607-0.221
        c0.23-0.023,0.832-0.152,1.266-0.175c0.441-0.015,0.883-0.152,0.784-0.19c-0.099-0.031-0.54,0.023-0.839,0.023
        c-0.295,0-1.118-0.016-0.92-0.069c0.19-0.053,0.244-0.053,0.784-0.061c0.548-0.008,0.396-0.106,0.891-0.091
        c0.503,0.015,0.914,0.069,1.165-0.031c0.252-0.091,0.327-0.144,0.647-0.19c0.32-0.053,0.723-0.175,0.349-0.213
        c-0.372-0.038-0.265,0.007-0.836-0.076c-0.563-0.076-0.473-0.206-1.119-0.122c-0.647,0.076-1.188-0.152-2.071-0.107
        c-0.883,0.046-0.997,0.062-1.34,0.069c-0.335,0.008-0.069,0.145-0.419,0.114c-0.343-0.023-0.183-0.084-0.587-0.068
        c-0.396,0.023-0.524-0.061-0.807,0.061c-0.282,0.121-0.343,0.152-0.532,0.098c-0.19-0.052-0.274-0.052-0.518-0.045
        c-0.244,0-1.166-0.122-1.615,0.06c-0.449,0.176-0.449,0.229-0.761,0.229c-0.32,0-0.404-0.145-0.876-0.114
        c-0.464,0.038-0.319,0.152-0.822,0.167c-0.495,0.008-0.739,0.053-1.203,0.099c-0.464,0.046-1.127,0.069-1.462,0.084
        c-0.327,0.008-0.708,0.076-0.525,0.229c0.183,0.144,0.176,0.037,0.419,0.228c0.244,0.19,0.229,0.152,0.655,0.205
        c0.426,0.054,1.035,0.138,1.294,0.176c0.267,0.03,1.066-0.099,1.362-0.084c0.298,0.015,0.351,0.107,0.701,0.046
        c0.357-0.061,1.005-0.13,0.906-0.084c-0.092,0.053-0.449,0.168-0.883,0.206c-0.435,0.037-1.12-0.084-1.128-0.015
        C74.075,2.48,74.44,2.77,74.212,2.77s-0.373-0.275-0.563-0.275c-0.183,0-0.502,0.031-0.845,0.016
        c-0.343-0.023-0.7-0.105-0.808,0.015c-0.106,0.122-0.137,0.282-0.251,0.305c-0.106,0.03-0.313,0.037-0.221-0.061
        c0.099-0.099,0.213-0.244,0.16-0.282c-0.062-0.039-0.084-0.236-0.298-0.129c-0.205,0.106-0.281,0.228-0.373,0.152
        c-0.1-0.084,0.183-0.152-0.076-0.213S70.291,2.275,70.23,2.1c-0.062-0.175-0.1-0.298-0.313-0.313
        c-0.206-0.015-0.671-0.106-0.982,0.107c-0.313,0.213-0.366,0.244-0.564,0.259C68.26,2.161,67.187,2.344,67.141,2.435
         M65.115,2.58c-0.062,0.008-0.236,0.046-0.189,0.099c0.037,0.061,0.105,0,0.266,0.069c0.16,0.061,0.145,0.137,0.281,0.099
        c0.13-0.031,0.373-0.206,0.19-0.282C65.48,2.496,65.115,2.58,65.115,2.58 M64.796,3.41c0,0-0.358,0.016-0.251,0.259
        c0.106,0.237,0.213,0.122,0.35,0.266c0.137,0.145,0.441,0.176,0.266,0.214c-0.167,0.046-0.327,0.106-0.234,0.145
        c0.082,0.037,0.356,0.045,0.585,0.045c0.236,0,0.495,0.099,0.617,0.023c0.121-0.077,0.335-0.289-0.061-0.289
        c-0.389,0-0.694,0-0.61-0.092c0.092-0.083,0.549-0.236,0.404-0.38c-0.153-0.152-0.419-0.175-0.594-0.206
        C65.092,3.372,64.796,3.41,64.796,3.41 M61.926,3.921c0,0-0.306,0.06-0.184,0.136c0.122,0.069,0.152,0.023,0.351,0.061
        c0.196,0.046,0.381-0.068,0.297-0.114C62.313,3.958,61.926,3.921,61.926,3.921 M61.78,3.06c0,0-0.389-0.015-0.389,0.114
        c-0.007,0.129,0.176,0.107,0.236,0.183c0.053,0.076,0.037,0.16-0.03,0.183c-0.068,0.022-0.343,0-0.335,0.068
        c0.008,0.061-0.023,0.152,0.259,0.145c0.274-0.008,0.464-0.084,0.624-0.023c0.16,0.053,0.328,0.008,0.435,0.084
        c0.114,0.084,0.167,0.205,0.305,0.228c0.144,0.031,0.373-0.053,0.494-0.038c0.121,0.023,0.365,0.076,0.373-0.023
        c0.008-0.106-0.121-0.274-0.03-0.327c0.1-0.053,0.235-0.198,0.016-0.297c-0.221-0.091-0.335-0.091-0.572-0.114
        c-0.235-0.023-0.304-0.129-0.541-0.175c-0.234-0.046-0.577-0.099-0.698-0.092C61.811,2.984,61.78,3.06,61.78,3.06 M64.095,4.583
        c-0.046,0.007-0.357-0.008-0.335,0.129c0.016,0.145,0.183,0.168,0.19,0.229c0.015,0.068-0.168,0.099,0.145,0.159
        c0.319,0.061,0.402,0.123,0.647,0.084c0.236-0.03,0.221-0.114,0.35-0.106c0.13,0.007,0.244-0.016,0.252,0.076
        c0.015,0.084-0.03,0.114,0.015,0.183c0.047,0.069,0.099,0.168,0.008,0.19c-0.091,0.023-0.586,0.145-0.602,0.358
        c-0.016,0.206-0.312,0.22-0.046,0.366c0.26,0.136,0.496,0.121,0.662,0.091c0.168-0.031,0.19,0.083,0.381,0.053
        c0.199-0.03,0.457-0.16,0.541-0.122c0.092,0.038-0.099,0.137,0.183,0.152c0.282,0.024,0.982,0.076,1.34,0.024
        c0.358-0.054,1.006-0.016,1.203-0.024c0.206-0.015,0.343-0.289,0.465-0.236c0.121,0.062,0.076,0.153,0.296,0.176
        c0.229,0.03,0.808-0.054,1.014,0c0.213,0.045,0.305-0.077,0.403-0.107c0.092-0.023,0.328,0.038,0.411-0.076
        c0.076-0.114,0.115-0.198,0.244-0.275c0.122-0.075,0.411-0.152,0.152-0.273c-0.259-0.122-0.435-0.107-0.641-0.107
        c-0.212,0-0.175-0.069-0.541-0.084c-0.364-0.015-0.631-0.045-0.988,0.03c-0.358,0.069-0.206,0.069-0.679,0.092
        c-0.471,0.023-0.631,0.092-0.761,0.099c-0.129,0.015-0.365-0.137-0.602-0.076c-0.235,0.061-0.389,0.144-0.541,0.069
        c-0.145-0.069-0.145-0.061-0.266-0.054c-0.123,0.008-0.099-0.076-0.13-0.145c-0.038-0.068-0.106-0.205-0.068-0.235
        c0.038-0.039,0.273,0.099,0.351-0.024c0.076-0.121,0.121-0.205-0.061-0.205c-0.183,0-0.299-0.129-0.519-0.137
        c-0.221-0.008-0.495-0.015-0.64,0c-0.151,0.015-0.235,0.03-0.358-0.068c-0.114-0.092-0.137-0.191-0.388-0.161
        c-0.251,0.023-0.327,0.016-0.479-0.007c-0.145-0.023-0.137-0.038-0.297-0.038H64.095z M62.969,5.748
        c0,0-0.153,0.106-0.282,0.137c-0.13,0.022-0.449,0.015-0.335,0.159c0.113,0.153,0.297,0.108,0.456,0.176
        c0.153,0.076,0.45,0.114,0.663,0.083c0.205-0.021,0.373-0.114,0.426-0.159c0.046-0.053,0.32-0.259,0.236-0.389
        c-0.092-0.129-0.015-0.25-0.344-0.175c-0.334,0.084-0.486,0.107-0.615,0.107C63.043,5.687,62.969,5.748,62.969,5.748
         M59.367,5.466c0,0,0.174-0.091,0.365-0.091c0.182,0,0.273-0.038,0.388-0.008c0.114,0.031,0.183-0.038,0.212-0.099
        c0.032-0.061,0.199-0.29,0.344-0.312c0.138-0.023,0.244-0.069,0.298,0.038c0.053,0.099,0.091,0.205,0.19,0.182
        c0.106-0.022,0.106-0.167,0.068-0.227c-0.046-0.054-0.1-0.138,0.046-0.153c0.145-0.023,0.281-0.061,0.449-0.015
        c0.167,0.038,0.297,0.015,0.403,0.091c0.113,0.084,0.129,0.069,0.235,0.038c0.107-0.023,0.412-0.091,0.503,0.008
        c0.084,0.091,0.205,0.167,0.008,0.236c-0.198,0.069-0.092,0.099-0.236,0.16c-0.152,0.053,0.175,0.038-0.16,0.191
        c-0.342,0.159-0.388,0.091-0.548,0.266c-0.16,0.175-0.229,0.22-0.594,0.22c-0.365,0-0.731-0.029-0.808-0.022
        c-0.083,0.007-0.319-0.091-0.221-0.175c0.1-0.092,0.243-0.229,0.1-0.229c-0.152,0-0.198,0.069-0.389,0.061
        c-0.19,0-0.351-0.069-0.495-0.038c-0.145,0.03-0.259,0.038-0.259-0.008C59.268,5.542,59.367,5.466,59.367,5.466 M59.45,4.857
        c-0.016,0.008-0.305,0.038-0.213,0.167c0.099,0.13-0.389,0.183-0.251,0.244c0.137,0.069,0.327,0.015,0.594-0.008
        c0.267-0.022,0.449-0.007,0.479-0.076c0.03-0.061-0.038-0.327-0.274-0.358C59.541,4.796,59.45,4.857,59.45,4.857 M59.763,4.118
        c-0.022,0.008-0.273,0.153-0.222,0.26c0.055,0.113,0.191,0.167,0.336,0.144c0.152-0.023,0.304-0.313,0.16-0.396
        C59.885,4.042,59.763,4.118,59.763,4.118 M58.179,5.74c-0.015,0-0.441,0.129-0.319,0.206c0.129,0.084,0.364,0.03,0.479-0.008
        c0.12-0.038,0.175-0.243,0.12-0.251C58.407,5.679,58.179,5.74,58.179,5.74 M60.463,7.385c-0.037,0-0.183,0.061-0.16,0.106
        c0.023,0.046,0.084,0.061,0.16,0.061c0.076,0,0.236-0.061,0.229-0.114c-0.008-0.06-0.031-0.092-0.084-0.092
        C60.562,7.339,60.463,7.385,60.463,7.385 M59.26,6.822c-0.054,0-0.639-0.069-0.654,0.099c-0.008,0.167-0.13,0.197-0.236,0.205
        c-0.099,0.015-0.259,0.061-0.161,0.175c0.1,0.106,0.215,0.031,0.177,0.129c-0.038,0.107-0.106,0.222-0.29,0.229
        c-0.183,0.015-0.327-0.061-0.427-0.16c-0.09-0.099-0.304-0.084-0.357-0.061c-0.053,0.031-0.396,0.069-0.373,0.214
        c0.016,0.151,0.122,0.19,0.168,0.273c0.038,0.084,0.311,0.153,0.427,0.168c0.113,0.015,0.128,0.046,0.167,0.152
        c0.03,0.114,0.076,0.236,0.106,0.32c0.03,0.091,0.008,0.19,0.206,0.205c0.189,0.008,0.335,0.024,0.419-0.038
        c0.091-0.061,0.038-0.159,0.234-0.167c0.206-0.008,0.404-0.092,0.588-0.099c0.182-0.008,0.319-0.015,0.455-0.144
        c0.139-0.13,0.115-0.191,0.261-0.207C60.12,8.1,60.212,8,60.311,7.887c0.092-0.106,0.152-0.244,0.054-0.244
        c-0.1,0-0.184-0.053-0.221-0.113c-0.046-0.062-0.221-0.046-0.213-0.107c0.007-0.061-0.031-0.115,0.112-0.183
        c0.153-0.061,0.397-0.038,0.55-0.114c0.151-0.069,0.113-0.213,0.229-0.229c0.113-0.015,0.213-0.099,0.099-0.152
        c-0.121-0.053-0.168-0.167-0.373-0.084c-0.215,0.092-0.473,0.153-0.578,0.153c-0.115,0-0.221,0.068-0.328,0.053
        c-0.106-0.023-0.008-0.099-0.197-0.069L59.26,6.822z M55.459,6.966c0,0-0.219-0.015-0.196,0.091
        c0.016,0.114,0.183,0.168,0.196,0.237c0.017,0.075-0.074,0.243,0.116,0.243c0.189,0,1.126-0.441,0.959-0.548
        c-0.167-0.107-0.343-0.167-0.67-0.1C55.543,6.958,55.459,6.966,55.459,6.966 M56.656,3.593c0,0-0.19,0.015-0.16,0.076
        c0.03,0.061,0.114,0.099,0.244,0.069c0.129-0.031,0.022-0.092,0.297-0.054c0.267,0.039,0.463,0.054,0.57,0.016
        c0.107-0.039,0.107-0.092,0.282-0.054c0.175,0.038,0.434,0.061,0.541,0.054c0.106-0.016,0.25-0.092,0.258-0.138
        c0.016-0.037-0.008-0.182-0.151-0.197c-0.152-0.015-0.175,0.022-0.473,0.053c-0.297,0.038-0.289,0.076-0.594,0.092
        c-0.313,0.022-0.335-0.023-0.512,0C56.785,3.532,56.656,3.593,56.656,3.593 M55.247,3.89c0,0-0.189,0.016-0.091,0.099
        c0.099,0.084,0.137,0.107,0.235,0.107c0.092,0,0.151-0.039,0.229-0.069c0.067-0.038,0.183-0.167,0.044-0.198
        C55.529,3.799,55.247,3.89,55.247,3.89 M56.177,3.928c0,0-0.236,0.03-0.282,0.091c-0.053,0.061-0.197,0.122-0.167,0.176
        c0.021,0.053,0.021,0.076,0.144,0.09c0.129,0.009,0,0.046,0.244,0.054c0.252,0.007,0.609-0.008,0.7-0.031
        c0.092-0.023,0.214-0.091,0.412-0.091C57.425,4.209,57.5,4.179,57.5,4.111c-0.006-0.069,0.047-0.122,0.153-0.138
        c0.1-0.007,0.654-0.007,0.449-0.098c-0.197-0.084-0.16-0.13-0.457-0.046c-0.289,0.084-0.586,0.046-0.769,0.046
        c-0.176,0-0.449-0.015-0.504,0.015C56.321,3.913,56.177,3.928,56.177,3.928 M54.258,4.636c0,0-0.396,0.008-0.32,0.069
        c0.069,0.061,0.161,0.068,0.282,0.068c0.129,0,0.259-0.022,0.381-0.031c0.114-0.015,0.228-0.144,0.121-0.152
        C54.616,4.583,54.258,4.636,54.258,4.636 M51.158,5.869c-0.045-0.007-0.35-0.053-0.228-0.114
        c0.129-0.06-0.017,0.024,0.274-0.083c0.289-0.107,0.532-0.244,0.715-0.29c0.183-0.045,0.183-0.106,0.396-0.19
        c0.205-0.084,0.418-0.13,0.578-0.13c0.152,0,0.282-0.121,0.441-0.136c0.168-0.008,0.289-0.016,0.427-0.008
        c0.137,0.008,0.175,0.046,0.183,0.122c0.008,0.083,0.426,0.06,0.609,0.099c0.183,0.045,0.214,0.106,0.198,0.183
        c-0.009,0.076,0.022,0.167,0.145,0.197c0.121,0.031,1.271,0.138,1.135-0.014c-0.138-0.161-0.184-0.206-0.084-0.252
        c0.106-0.046,0.068-0.198,0.091-0.259c0.016-0.061,0.16-0.007,0.427-0.076c0.274-0.076,0.419-0.206,0.556-0.229
        c0.137-0.015,0.221-0.121,0.267,0c0.038,0.114,0.008,0.145-0.114,0.275c-0.121,0.129-0.137,0.182-0.215,0.22
        c-0.074,0.039-0.151,0.138-0.036,0.153c0.114,0.007,0.158,0,0.313,0.045c0.159,0.046,0.235-0.045,0.343-0.076
        c0.105-0.023,0.327-0.083,0.448-0.03c0.122,0.046,0.221,0.122,0.106,0.175c-0.114,0.054-0.327,0.122-0.54,0.274
        c-0.222,0.153-0.419,0.191-0.746,0.237c-0.32,0.045-0.763,0.037-0.944,0.029c-0.183-0.015-0.145-0.022-0.518-0.007
        c-0.381,0.007-0.67,0.06-1.112,0.168c-0.448,0.106-0.845,0.197-1.202,0.213c-0.358,0.022-0.625,0.045-0.754,0.022
        c-0.122-0.03-0.336-0.16-0.206-0.213c0.13-0.046,0.084,0,0.365-0.053c0.282-0.053,0.273-0.13,0.524-0.122
        c0.253,0.008,0.443,0.008,0.512-0.023c0.068-0.038,0.205-0.167,0.015-0.16c-0.19,0-0.426,0.053-0.532,0.053
        s-0.078-0.075-0.229-0.09c-0.151-0.016-0.151-0.024-0.396,0.083c-0.251,0.107-0.189,0.091-0.373,0.068
        c-0.183-0.03-0.221-0.084-0.396-0.03c-0.175,0.053-0.137,0.069-0.236,0.03C51.258,5.9,51.158,5.869,51.158,5.869 M50.488,5.473
        c0,0-0.267,0.016-0.167,0.084c0.099,0.077,0.289,0.069,0.457,0.046c0.167-0.023,0.38-0.175,0.495-0.213
        c0.121-0.038,0.448-0.084,0.403-0.145c-0.046-0.053-0.122-0.069-0.305-0.007c-0.175,0.053-0.487,0.091-0.602,0.129
        C50.664,5.413,50.488,5.473,50.488,5.473 M52.134,4.392c0,0-0.746,0.038-1.136,0.213c-0.388,0.176-0.753,0.115-0.981,0.237
        c-0.235,0.122-0.479,0.182-0.662,0.175c-0.184-0.008-0.594,0.053-0.465,0.167c0.137,0.115,0.235,0.023,0.524,0.069
        c0.283,0.038,0.435,0.061,0.603,0.038c0.168-0.031,0.229,0.053,0.487,0.015c0.251-0.03,0.556-0.35,0.64-0.258
        c0.076,0.091,0.137,0.144,0.259,0.06c0.214-0.091,0.487-0.274,0.609-0.243c0.122,0.03-0.031,0.22,0.159,0.205
        c0.19-0.022,0.465-0.205,0.571-0.236c0.106-0.038,0.313,0.061,0.464,0c0.161-0.068,0.184-0.206,0.29-0.266
        c0.1-0.061,0.016-0.168,0.244-0.183c0.219-0.016,0.608-0.1,0.373-0.198c-0.236-0.091-0.313,0-0.595,0.061
        c-0.273,0.06-0.351-0.008-0.7,0.015C52.469,4.285,52.134,4.392,52.134,4.392 M46.529,8.389c0,0,0-0.129,0.282-0.197
        c0.281-0.061,0.327-0.084,0.479-0.099c0.153-0.023,0.251-0.069,0.268-0.144c0.007-0.069-0.084-0.069,0.099-0.146
        c0.175-0.076,1.097-0.235,1.401-0.334c0.304-0.1,0.975-0.221,1.164-0.252c0.19-0.022,0.457-0.106,0.549-0.068
        c0.099,0.03,0.114,0.113,0.068,0.175c-0.038,0.061-0.327,0.137-0.396,0.19c-0.07,0.054-0.244,0.153-0.047,0.168
        c0.191,0.023,0.214,0.015,0.328-0.077c0.114-0.098,0.318-0.159,0.44-0.159c0.123,0,0.101-0.061,0.251,0
        c0.162,0.053,0.39,0.122,0.489,0.152c0.099,0.023,0.007,0.114,0,0.175c0,0.053,0.052,0.084,0.175,0.038
        c0.129-0.053,0.175-0.084,0.289-0.069c0.122,0.024,0.206,0.13,0.221,0.009c0.016-0.123-0.221-0.313-0.037-0.336
        c0.175-0.03,0.335-0.076,0.447,0.023c0.115,0.092,0.199,0.122,0.229,0.221c0.023,0.099,0.038,0.183-0.03,0.259
        C53.122,8,52.849,8.245,52.994,8.337c0.151,0.084,0.151,0.045,0.289-0.032c0.145-0.067,0.145-0.06,0.229-0.243
        c0.084-0.19,0.113-0.175,0.205-0.251c0.092-0.076,0.183-0.388,0.427-0.487c0.244-0.092,0.105-0.145,0.305-0.129
        c0.19,0.015,0.168,0.007,0.313,0.06c0.151,0.053,0.045-0.106,0.28,0.007c0.237,0.116,0.306,0.153,0.328,0.237
        c0.022,0.091-0.03,0.213-0.152,0.381c-0.129,0.168-0.243,0.509-0.243,0.632c0,0.121-0.289,0.213-0.366,0.282
        c-0.075,0.068-0.335,0.265-0.121,0.38c0.205,0.114,0.312,0.076,0.571,0.175c0.267,0.107,0.221,0.16,0.434,0.206
        c0.206,0.045,0.449-0.123,0.305,0.084c-0.152,0.198-0.281,0.319-0.457,0.311c-0.183-0.007-0.215-0.037-0.365-0.029
        c-0.16,0.007-0.176-0.123-0.306-0.054c-0.129,0.068-0.151,0.16-0.335,0.16c-0.182,0-0.304-0.092-0.326,0.023
        c-0.023,0.114,0.061,0.16,0.221,0.152c0.16-0.007,0.343-0.122,0.343,0.03c0,0.145-0.016,0.244-0.244,0.298
        c-0.221,0.045-0.457,0.03-0.64,0.098c-0.183,0.069-0.51,0.069-0.731-0.007c-0.228-0.077-0.479,0-0.563-0.016
        c-0.083-0.015,0.069-0.159-0.189-0.198c-0.251-0.045-0.267-0.106-0.434-0.129c-0.168-0.03-0.185,0.016-0.442,0.068
        c-0.259,0.061-0.502,0.1-1.233,0.297c-0.731,0.191-0.937,0.168-1.362,0.168c-0.427,0-1.371,0.19-1.493,0.069
        c-0.122-0.122,0.068-0.252,0.046-0.373c-0.015-0.122,0.038-0.1-0.313-0.183c-0.35-0.084-1.484-0.099-1.058-0.464
        c0.434-0.366,0.937-0.146,1.355-0.221c0.418-0.084,0.472-0.13,0.807-0.115c0.335,0.023,1.119,0.153,1.173,0.046
        C49.305,9.464,49.259,9.319,49,9.242c-0.251-0.075-0.305-0.137-0.814-0.068c-0.511,0.068-0.929,0.061-1.248,0.061
        c-0.313,0-0.533,0.099-0.595-0.053C46.282,9.037,46.16,8.9,46.29,8.862c0.128-0.046,0.518-0.251,0.837-0.198
        c0.32,0.053,0.724-0.084,0.586-0.137c-0.137-0.054-0.434,0.06-0.677,0.023c-0.236-0.038-0.389,0.061-0.45,0
        C46.515,8.489,46.529,8.389,46.529,8.389 M68.373,26.846c0,0-0.304,0.007-0.182,0.114c0.13,0.107,0.251,0,0.373,0.145
        c0.122,0.153,0.183,0.251,0.327,0.32c0.151,0.076,0.891,0.236,0.96,0.175c0.061-0.061-0.244-0.366-0.381-0.472
        c-0.139-0.115-0.541-0.304-0.716-0.312C68.587,26.808,68.373,26.846,68.373,26.846 M74.48,25.56
        c-0.091,0.022-0.648,0.373-0.846,0.616c-0.197,0.236-0.67,0.548-0.73,0.693c-0.061,0.137-0.19,0.297-0.32,0.427
        c-0.129,0.129-0.03,0.205-0.189,0.327c-0.16,0.121-0.26,0.053-0.32,0.159c-0.061,0.115-0.183,0.244-0.243,0.252
        c-0.062,0.007-0.236,0.045-0.145,0.099c0.091,0.061,0.221,0.054,0.152,0.137c-0.077,0.091-0.625,0.29-0.617,0.464
        c0.008,0.175-0.054,0.244,0.206,0.259c0.266,0.015,0.799-0.023,1.156,0.023c0.358,0.038,0.602-0.046,0.762-0.053
        c0.16-0.015,0.137-0.031,0.327,0.06c0.183,0.1,0.243,0.069,0.335,0.053c0.092-0.022,0.382-0.182,0.29-0.022
        c-0.092,0.16-0.259,0.106-0.335,0.213c-0.084,0.115-0.198,0.145-0.366,0.198c-0.167,0.054-0.335,0.138-0.152,0.183
        c0.176,0.046,0.282,0.03,0.411,0.015c0.138-0.023,0.152-0.023,0.298-0.175c0.136-0.16,0.145-0.258,0.304-0.266
        c0.168-0.008,0.29-0.023,0.343-0.13c0.054-0.099,0.122-0.289,0.198-0.228c0.083,0.06,0.122,0.259,0.045,0.342
        c-0.084,0.076-0.472,0.373-0.312,0.404c0.152,0.023,0.343-0.19,0.396-0.091c0.053,0.098-0.114,0.25-0.022,0.289
        c0.099,0.045,0.166,0.008,0.266,0.015c0.099,0.008,0.107,0.062,0.305-0.198c0.197-0.251,0.487-0.769,0.358-0.769
        c-0.13,0-0.412,0.313-0.396,0.114c0.008-0.205,0.343-0.41,0.267-0.449c-0.076-0.038-0.282,0.015-0.396,0.121
        c-0.113,0.107-0.38,0.298-0.273,0.084c0.106-0.205,0.327-0.517,0.541-0.548c0.221-0.037,0.41-0.182,0.212-0.22
        c-0.205-0.046-0.601,0-0.41-0.176c0.183-0.167,0.479-0.213,0.266-0.334c-0.213-0.123-0.197-0.214-0.388-0.206
        c-0.184,0.008-0.123-0.175-0.313-0.129c-0.19,0.038-0.175,0.145-0.352,0.174c-0.174,0.023-0.22-0.022-0.318-0.045
        c-0.092-0.03-0.229-0.16-0.046-0.312c0.183-0.16,0.092-0.343-0.075-0.297c-0.168,0.045-0.526,0.495-0.52,0.252
        c0.009-0.252,0.185-0.457,0.404-0.564c0.221-0.107,0.404-0.236,0.487-0.32c0.084-0.076,0.092-0.099,0.084-0.251
        c0-0.16,0.053-0.252,0.122-0.19c0.076,0.06,0.402-0.069,0.282-0.153c-0.123-0.091-0.19-0.077-0.32-0.038
        C74.762,25.407,74.48,25.56,74.48,25.56 M67.468,29.465c-0.03,0.008-0.313,0.206-0.243,0.282
        c0.068,0.084,0.106,0.129,0.106,0.198c0,0.069,0.121,0.091,0.289,0.205c0.16,0.115,0.152,0.146,0.259,0.16
        c0.106,0.015,0.114,0.047,0.259,0.077c0.145,0.022,0.176-0.016,0.313-0.107c0.129-0.084,0.457-0.19,0.251-0.266
        c-0.197-0.077-0.244-0.031-0.556-0.038c-0.313-0.016-0.411-0.108-0.495-0.145c-0.083-0.038-0.122-0.145-0.091-0.244
        C67.581,29.488,67.468,29.465,67.468,29.465 M38.618,12.615c-0.304,0.008-0.403,0.039-0.549,0.069
        c-0.144,0.03-0.433,0.153-0.319,0.205c0.122,0.061,0.16-0.038,0.344-0.022c0.175,0.008,0.266-0.053,0.312-0.053
        c0.046,0,0.092,0.037,0.274,0.037c0.175,0,0.274-0.129,0.411-0.083c0.129,0.046,0.175-0.091,0.365-0.091
        c0.19,0.007,0.465-0.039,0.525,0.084c0.06,0.128,0.023,0.197-0.145,0.189c-0.169-0.007-0.655-0.136-0.671-0.045
        c-0.015,0.091,0.198,0.189,0.084,0.244c-0.121,0.061-0.312,0.084-0.494,0.16c-0.183,0.076-0.146,0.205-0.382,0.25
        c-0.235,0.039-0.532,0.016-0.396,0.107c0.138,0.084,0.29,0.069,0.603,0.069c0.312,0,0.312-0.092,0.548-0.176
        c0.235-0.091,0.77-0.334,0.883-0.326c0.122,0.007,0.221,0.06,0.13,0.136c-0.101,0.084-0.298,0.099-0.396,0.161
        c-0.099,0.06-0.556,0.182-0.632,0.22c-0.068,0.046-0.099,0.137-0.008,0.137s0.632-0.229,0.906-0.281
        c0.281-0.047,0.578-0.023,0.609-0.168c0.03-0.137-0.008-0.228,0.137-0.259c0.137-0.03,0.198-0.16,0.503-0.114
        c0.297,0.038,0.28,0.152,0.525,0.076c0.243-0.076,0.084-0.107,0.327-0.236c0.244-0.129,0.73-0.381,0.479-0.396
        c-0.259-0.022-0.343,0.213-0.624,0.221c-0.274,0.008-0.99-0.069-0.86-0.183c0.129-0.107,0.198-0.144,0.389-0.167
        c0.19-0.031,0.556-0.183,0.281-0.259c-0.267-0.069-0.411,0.03-0.754,0.084c-0.343,0.045-0.708-0.039-0.967,0.136
        c-0.268,0.175-0.335,0.191-0.579,0.146C38.946,12.387,38.931,12.615,38.618,12.615 M40.689,15.593
        c-0.038,0.053-0.16,0.061-0.03,0.183c0.121,0.129,0.235,0.022,0.175,0.144c-0.054,0.114-0.068,0.213-0.045,0.32
        c0.022,0.106,0.068,0.122-0.023,0.167c-0.1,0.046-0.388-0.031-0.549,0.076c-0.159,0.114-0.197,0.099-0.38,0.237
        c-0.176,0.128-0.206,0.235-0.473,0.159c-0.259-0.076-0.213-0.159-0.35-0.068c-0.137,0.092-0.236,0.121-0.313,0.121
        c-0.069-0.007-0.251-0.258-0.281-0.106c-0.022,0.144,0,0.236,0.167,0.236c0.16,0,0.326-0.114,0.442,0.016
        c0.113,0.129,0.015,0.121,0.304,0.136c0.289,0.007,0.647-0.076,0.914-0.076c0.259,0,0.448,0.054,0.632-0.114
        c0.183-0.167-0.068-0.221,0.357-0.327c0.435-0.107,0.503-0.046,0.784-0.091c0.282-0.047,0.366-0.108,0.609-0.221
        c0.242-0.115,0.762-0.496,1.035-0.473c0.268,0.023,0.222-0.144,0.404-0.182c0.175-0.031,0.494-0.092,0.639-0.061
        c0.146,0.023,0.419-0.107,0.222-0.19c-0.205-0.076-0.616-0.13-0.982-0.038c-0.366,0.091-0.662,0.342-1.097,0.487
        c-0.434,0.145-0.791,0.342-1.059,0.258c-0.273-0.091-0.099-0.242-0.312-0.266c-0.213-0.031-0.267,0.122-0.397-0.06
        c-0.128-0.176-0.212-0.282-0.272-0.298C40.758,15.539,40.689,15.593,40.689,15.593 M46.561,22.803
        c-0.008,0.031-0.107,0.274-0.29,0.35c-0.19,0.084-0.389,0.039-0.495,0.137c-0.107,0.099-0.275,0.214-0.365,0.335
        c-0.084,0.115-0.343,0.237-0.213,0.335c0.129,0.1,0.297-0.068,0.167,0.1c-0.121,0.167-0.236,0.175-0.167,0.296
        c0.061,0.129,0.129,0.107,0.121,0.252c-0.015,0.136,0.092,0.144,0.062,0.236c-0.03,0.091-0.146,0.197,0,0.182
        c0.137-0.015,0.221-0.244,0.288-0.175c0.077,0.069,0.047,0.168,0.038,0.259c-0.015,0.091-0.136,0.191-0.105,0.29
        c0.022,0.098,0.206,0.03,0.206,0.098c0,0.077-0.168,0.122-0.252,0.275c-0.084,0.152-0.381,0.494-0.243,0.548
        c0.137,0.053,0.272,0.114,0.319-0.054c0.046-0.159,0.106-0.107,0.198-0.22c0.092-0.122,0.206-0.351,0.243-0.48
        c0.031-0.13,0.038-0.327,0.084-0.563c0.046-0.229,0.099-0.351,0.122-0.648c0.022-0.289,0.106-0.343,0.145-0.533
        c0.038-0.183-0.022-0.243,0-0.388c0.015-0.137,0.114-0.304,0.152-0.358c0.038-0.053,0.214-0.206,0.145-0.282
        C46.659,22.72,46.561,22.803,46.561,22.803"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M31.545,9.882
        c0.106,0.023,0.236,0.046,0.396,0.053c0.457,0.031,0.586-0.068,0.7,0.038c0.114,0.1,0.084,0.389,0.457,0.518
        c0.381,0.13,0.837,0.275,0.898,0.191c0.061-0.092-0.084-0.282,0.091-0.35c0.176-0.07,0.442-0.07,0.808-0.153
        c0.365-0.076,0.578-0.328,0.754-0.221c0.175,0.107-0.039,0.252,0.289,0.252c0.099,0,3.03-0.914,3.053-0.663
        c0.016,0.145-0.373,0.251-0.151,0.32c0.213,0.061,0.403-0.076,0.639-0.153c0.236-0.075,0.396-0.106,0.488-0.227
        c0.608-0.777,0.38,0.19,0.342,0.441c-0.03,0.167,0.168,0.373,0.297,0.35c0.13-0.03,0.267-0.411,0.495-0.441
        c0.03,0,1.729-0.405,0.608,0.052c-0.113,0.046-0.288,0.161-0.151,0.229c0.137,0.069,0.343,0.038,0.511-0.031
        c0.167-0.068,0.273-0.236,0.51-0.182c0.8,0.175,1.667,0.327,2.437,0.632c0.167,0.068,0.327,0.106,0.563,0.106
        c0.236,0,0.723-0.045,0.867,0.123c0.152,0.175,0,0.357-0.152,0.365c-0.151,0.008-0.319-0.061-0.464,0.046
        c-0.152,0.114-0.373,0.121-0.282,0.235c0.1,0.122-0.038,0.054,0.419,0.122c0.465,0.077,0.884,0.061,1.202,0.038
        c0.32-0.022,0.671-0.114,0.982-0.106c0.306,0.007,0.572-0.129,0.723,0.023c0.162,0.16,0.162,0.221,0.283,0.266
        c0.122,0.046,0.252,0.046,0.129,0.145c-0.121,0.106-0.426,0.251-0.328,0.342c0.101,0.084,0.328,0.099,0.496-0.076
        c0.16-0.175-0.16-0.274,0.076-0.488c0.236-0.212,0.351-0.327,0.541-0.38c0.19-0.045,0.35-0.06,0.54-0.091
        c0.19-0.03,0.944-0.206,0.884-0.297c-0.062-0.091-0.214-0.099-0.389,0.015c-0.175,0.115-0.511,0.115-0.662,0.137
        c-0.154,0.015-0.206,0.115-0.396,0.115c-0.198,0.007-0.51-0.008-0.342-0.13c0.175-0.122,0.289-0.167,0.594-0.228
        c0.312-0.061,0.296,0,0.57-0.061c0.267-0.061,0.547-0.228,0.784-0.198c0.229,0.023,0.366,0.023,0.304,0.19
        c-0.06,0.16-0.204,0.244-0.006,0.312c0.19,0.061,0.061,0.137,0.235,0.198c0.174,0.061,0.435-0.099,0.808,0.084
        c0.373,0.183,0.379,0.243,0.677,0.213c0.298-0.023,0.693-0.175,1.104-0.129c0.411,0.045,0.518,0.099,0.647,0.045
        c0.129-0.045,0.129-0.19,0.266-0.159c0.13,0.037,0.115,0.266,0.29,0.266s0.35-0.266,0.189-0.282
        c-0.151-0.022-0.433,0.007-0.41-0.099c0.03-0.106,0.106-0.198,0.274-0.228c0.167-0.03,0.341-0.03,0.457-0.068
        c0.113-0.031,0.129-0.229-0.092-0.26c-0.229-0.022-0.336,0.046-0.442-0.007c-0.106-0.053-0.297-0.19-0.113-0.229
        c0.183-0.045,0.357,0.077,0.615-0.091c0.251-0.167,0.185-0.32,0.428-0.335c0.251-0.015,0.297-0.13,0.457-0.13
        c0.152,0,0.259,0.115,0.402,0.229c0.146,0.115,0.229,0.206,0.297,0.327c0.069,0.123-0.121,0.145-0.099,0.252
        c0.031,0.107-0.114,0.16-0.305,0.19c-0.197,0.031-0.312,0.054-0.563,0.031c-0.251-0.015-0.434-0.054-0.381,0.069
        c0.054,0.121,0.007,0.228,0.265,0.197c0.269-0.031,0.345-0.069,0.299,0.038c-0.046,0.114-0.434,0.16-0.479,0.312
        c-0.046,0.16-0.289,0.206-0.221,0.289c0.066,0.092,0.175,0.039,0.373,0.084c0.19,0.046,0.213,0.054,0.334-0.167
        c0.123-0.229,0.373-0.472,0.678-0.472c0.313,0,0.503-0.031,0.716-0.122c0.206-0.099,0.541-0.457,0.487-0.487
        c-0.053-0.038-0.487,0.266-0.426,0.068c0.053-0.205,0.434-0.259,0.479-0.373c0.054-0.114-0.008-0.35-0.175-0.335
        c-0.168,0.015-0.602-0.152-0.565-0.335c0.039-0.182,0.261-0.197,0.359-0.297C58.91,9.258,58.895,9.059,59,9.006
        c0.107-0.053,0.298-0.274,0.42-0.311c0.121-0.031,0.19-0.024,0.365-0.008c0.176,0.008,0.176-0.046,0.19-0.145
        c0.022-0.099,0.145-0.153,0.221-0.129c0.068,0.022,0.419-0.061,0.541-0.229c0.129-0.168,0.052-0.243,0.289-0.517
        c0.244-0.282,0.716-0.869,0.868-0.785c0.145,0.084,0.244,0.137,0.273,0.039c0.038-0.092-0.158-0.176,0.115-0.183
        c0.281-0.008,0.807-0.145,1.096-0.099c0.289,0.045,0.389,0.106,0.64,0.129c0.252,0.023,0.883-0.152,0.853,0.054
        c-0.038,0.205-0.418,0.159-0.73,0.304c-0.313,0.136-0.822,0.495-1.06,0.526c-0.235,0.03-0.806-0.009-0.958,0.037
        c-0.145,0.053-0.062,0.16-0.152,0.229c-0.1,0.06-0.319,0.076-0.449,0.175c-0.13,0.106-0.427,0.015-0.495,0.16
        c-0.061,0.136,0.062,0.136,0.152,0.198c0.092,0.068,0.206,0.167,0.206,0.236c0.007,0.075-0.184,0.297-0.198,0.434
        c-0.016,0.137,0.114,0.167,0.121,0.273c0.009,0.1,0.009,0.13-0.151,0.229c-0.152,0.091-0.595,0.199-0.541,0.366
        c0.061,0.16,0.235,0.175,0.313,0.152c0.076-0.023,0.441-0.22,0.449-0.054c0.008,0.168-0.328,0.175-0.32,0.313
        c0.008,0.13,0.092,0.236,0.031,0.289c-0.054,0.053-0.412,0.275-0.206,0.327c0.206,0.054,0.435-0.235,0.708-0.373
        c0.274-0.144,0.326-0.334,0.57-0.258c0.252,0.076,0.199,0.146,0.32,0.258c0.122,0.123,0.1,0.335-0.016,0.427
        c-0.113,0.099-0.281,0-0.479,0.084c-0.189,0.084-0.205,0.16-0.221,0.228c-0.016,0.068,0.151,0.342,0.146,0.449
        c-0.016,0.115,0.137,0.175,0.312,0.107c0.175-0.069,0.297-0.046,0.411-0.205c0.106-0.161-0.106-0.161,0.075-0.396
        c0.184-0.237,0.222-0.373,0.352-0.267c0.137,0.114-0.099,0.319,0.068,0.342c0.168,0.016,0.472-0.273,0.587-0.403
        c0.113-0.122,0.524-0.16,0.67-0.297c0.144-0.129,0.038-0.244,0.121-0.388c0.084-0.145,0.32-0.48,0.533-0.48
        c0.205,0,0.267,0.099,0.64,0.152c0.381,0.054,0.737-0.099,0.77,0.084c0.037,0.182-0.283,0.266-0.023,0.335
        c0.251,0.069,0.532,0.145,0.373,0.244c-0.152,0.107-0.29,0.076-0.479,0.19c-0.184,0.115-0.549,0.038-0.624,0.206
        c-0.077,0.159-0.114,0.312-0.016,0.487c0.1,0.175,0.198,0.213,0.114,0.343c-0.076,0.129-0.16,0.251-0.441,0.289
        c-0.289,0.031-0.426,0.061-0.587,0.167c-0.167,0.107-0.525,0.168-0.724,0.236c-0.205,0.069-0.281,0.077-0.342-0.015
        c-0.068-0.084-0.1-0.281-0.175-0.182c-0.069,0.107-0.076,0.159-0.069,0.281c0.008,0.122,0.106,0.199-0.122,0.122
        c-0.228-0.069-0.175,0.145-0.091,0.206c0.091,0.061,0.243,0.137,0.16,0.19c-0.076,0.054-0.282,0.198-0.351,0.038
        c-0.076-0.16-0.023-0.434-0.183-0.472c-0.152-0.031-0.122,0.046-0.289-0.053c-0.16-0.099-0.313-0.16-0.479-0.153
        c-0.175,0.008-0.352,0.054-0.229,0.153c0.122,0.099,0.206,0.168,0.084,0.228c-0.122,0.061-0.616,0.274-0.807,0.373
        c-0.198,0.099-0.282,0.153-0.495,0.13c-0.213-0.031-0.305-0.061-0.495-0.153c-0.189-0.083-0.479-0.274-0.646-0.267
        c-0.168,0.008-0.282,0.115-0.046,0.306c0.235,0.182,0.837,0.129,1.073,0.182c0.236,0.061,0.289,0.099,0.16,0.183
        c-0.13,0.091-0.686,0.472-1.043,0.654c-0.358,0.183-0.579,0.282-0.814,0.229c-0.229-0.054-0.45-0.183-0.634-0.054
        c-0.182,0.138-0.311,0.313-0.509,0.336c-0.205,0.023-0.502,0.007-0.646-0.038c-0.153-0.039-0.382-0.183-0.328-0.031
        c0.054,0.153,0.356,0.198,0.457,0.267c0.106,0.076,0.122,0.16,0.016,0.221c-0.107,0.061-0.427,0.243-0.647,0.258
        c-0.222,0.023-0.718-0.099-0.679,0.031c0.031,0.13,0.168,0.13,0.138,0.198c-0.022,0.06-0.054,0.122-0.32,0.144
        c-0.274,0.031-0.502,0.062-0.639,0.214c-0.13,0.159-0.382,0.266-0.503,0.304c-0.122,0.046-0.792,0.51-0.944,0.632
        c-0.159,0.122-0.396,0.313-0.624,0.487c-0.221,0.168-0.464,0.236-0.654,0.518c-0.19,0.275-0.305,0.472-0.344,0.556
        c-0.029,0.077-0.121,0.282,0.145,0.342c0.26,0.062,0.557-0.144,0.572,0.039c0.015,0.183,0.007,0.502-0.099,0.67
        c-0.107,0.16-0.207,0.327-0.191,0.434c0.016,0.114-0.243,0.472,0.244,0.35c0.486-0.114,0.722-0.342,1.081-0.114
        c0.357,0.236,0.457,0.32,0.678,0.35c0.221,0.038,0.388-0.03,0.449,0.282s0.235,0.548,0.814,0.678
        c0.579,0.136,0.427,0.228,0.731,0.311c0.312,0.084,1.545,0.031,1.583,0.267c0.03,0.237-0.434,0.487-0.472,0.769
        c-0.039,0.274-0.114,0.427-0.221,0.541c-0.099,0.106-0.198,0.243-0.289,0.395c-0.084,0.146-0.048,0.214,0.03,0.389
        c0.076,0.175,0.068,0.229,0.128,0.412c0.063,0.19,0.177,0.098,0.251,0.349c0.084,0.259,0.078,0.359,0.222,0.472
        c0.138,0.122,0.244,0.069,0.403-0.053c0.16-0.121,0.267-0.358,0.382-0.221c0.114,0.138,0.229,0.236,0.335,0.1
        c0.106-0.13-0.03-0.259,0.145-0.427c0.175-0.168,0.357-0.35,0.411-0.685c0.06-0.328-0.054-0.427,0.213-0.822
        c0.267-0.396-0.159-0.8,0.106-0.922c0.274-0.121,1.447-0.319,2.338-0.982c0.891-0.662,1.104-1.576,1.035-1.828
        c-0.068-0.25-0.525-0.518-0.389-0.738c0.138-0.214,0.077-0.084,0.358-0.266c0.282-0.183,0.776-0.328,0.914-0.602
        c0.145-0.274,0.251-0.579,0.213-0.701c-0.038-0.121-0.251-0.197,0-0.311c0.251-0.115,0.473-0.267,0.495-0.472
        c0.03-0.207,0.067-0.359,0.114-0.488c0.053-0.129,0.853-0.449,1.096-0.29c0.244,0.161,0.351,0.153,0.784,0.198
        c0.435,0.046,0.549,0.031,0.739-0.045c0.197-0.084,0.128-0.221,0.411-0.084c0.273,0.137,0.319,0.106,0.441,0.336
        c0.121,0.227,0.343,0.258,0.357,0.373c0.016,0.114-0.327,0.159-0.145,0.319c0.175,0.153,0.518,0.122,0.701,0.183
        c0.183,0.061,0.19,0.168,0.318,0.106c0.131-0.061,0.215-0.205,0.252-0.061c0.038,0.138-0.243,0.191-0.35,0.366
        c-0.106,0.175-0.213,0.419-0.259,0.464c-0.038,0.047-0.19,0.244-0.214,0.365c-0.029,0.122-0.159,0.214-0.068,0.351
        c0.092,0.137,0.16,0.069,0.388,0.243c0.237,0.175,0.062,0.305,0.328,0.427c0.267,0.114,0.929-0.114,1.066-0.252
        c0.137-0.144,0.105-0.106,0.373-0.175c0.259-0.068,0.167,0.054,0.38-0.221c0.222-0.266,0.42-0.373,0.64-0.624
        c0.229-0.251,0.366-0.662,0.548-0.51c0.184,0.146,0.008,0.64,0.138,0.868c0.13,0.229,0.229,0.321,0.175,0.45
        c-0.053,0.129,0.122,0.266,0.023,0.35c-0.107,0.091-0.29,0.167-0.107,0.221c0.176,0.053,0.328,0.007,0.306,0.106
        c-0.016,0.099-0.1,0.304-0.054,0.48c0.046,0.175-0.183,0.19-0.016,0.305c0.176,0.114,0.29,0.213,0.176,0.304
        c-0.114,0.084-0.352,0.061-0.358,0.206c-0.015,0.146-0.046,0.228,0.046,0.274c0.084,0.052,0.389,0.297,0.351,0.517
        c-0.038,0.214,0.35,0.176,0.297,0.328c-0.054,0.16-0.114,0.274,0.114,0.282c0.229,0.015,0.289-0.107,0.586,0.084
        c0.297,0.19,0.365,0.159,0.532,0.213c0.167,0.045,0.496,0.091,0.268,0.205c-0.229,0.114-0.404,0.031-0.814,0.213
        c-0.413,0.183-0.754,0.312-1.074,0.381c-0.313,0.068-0.762,0.282-0.327,0.297c0.434,0.016,0.502-0.031,0.723-0.229
        c0.214-0.198,0.549-0.426,0.83-0.418c0.282,0.008,0.366-0.069,0.465,0.053c0.098,0.122,0.114,0.251,0.046,0.351
        c-0.068,0.098-0.427,0.304-0.16,0.273c0.259-0.023,0.38-0.35,0.585-0.228c0.207,0.122,0.625,0.335,0.436,0.579
        c-0.198,0.251-0.427,0.243-0.442,0.418c-0.015,0.168-0.022,0.32-0.136,0.412c-0.107,0.084,0.029,0.274-0.282,0.358
        c-0.313,0.09-0.464,0.266-0.968,0.258c-0.494-0.007-0.578,0.015-0.891,0.168c-0.319,0.16-0.662,0.494-1.134,0.685
        c-0.473,0.19-1.653,0.228-2.422,0.198c-0.776-0.023-1.111-0.008-1.759-0.023c-0.646-0.015-1.202-0.038-1.378,0.313
        c-0.175,0.35-0.198,0.594-0.472,0.54c-0.267-0.061-1.157,0.297-1.5,0.601c-0.351,0.305-0.77,0.556-0.892,0.648
        c-0.122,0.098-0.662,0.51-0.472,0.532c0.183,0.031,0.221-0.06,0.556-0.266c0.335-0.213,1.188-0.761,1.645-0.928
        c0.45-0.161,1.059-0.313,1.37-0.419c0.32-0.114,0.633-0.107,0.93-0.069c0.297,0.03,0.518,0.046,0.533,0.198
        c0.015,0.16-0.176,0.054-0.19,0.282c-0.016,0.22,0.015,0.251-0.283,0.403c-0.296,0.153-0.585,0.221-0.806,0.145
        c-0.213-0.069-0.61-0.213-0.427,0c0.19,0.22,0.106,0.243,0.282,0.35c0.175,0.114,0.533-0.214,0.661-0.175
        c0.123,0.031,0.306,0.06,0.077,0.236c-0.229,0.168-0.335,0.343-0.412,0.396c-0.075,0.053,0.032,0.091,0.047,0.167
        c0.008,0.077-0.047,0.176-0.084,0.282c-0.038,0.106-0.038,0.457,0.16,0.487c0.205,0.031,0.35-0.129,0.396,0.038
        c0.038,0.176-0.274,0.168,0.092,0.267c0.365,0.099,0.548,0.122,0.806,0.137c0.268,0.008,0.252-0.205,0.496-0.099
        c0.243,0.107,0.206,0.091,0.335-0.114c0.129-0.198,0.274-0.343,0.434-0.487c0.16-0.138,0.268-0.655,0.487-0.472
        c0.221,0.182,0.046,0.258,0.023,0.418c-0.016,0.168,0.121,0.237,0.183,0.328c0.061,0.084,0.128,0.183-0.13,0.312
        c-0.251,0.122-0.578,0.343-0.632,0.183c-0.053-0.168-0.145-0.274-0.197-0.198c-0.062,0.076-0.123,0.243-0.146,0.35
        s-0.022,0.16-0.251,0.236c-0.229,0.076-0.731,0.282-1.149,0.396c-0.419,0.113-0.548,0.266-0.738,0.266s-0.282-0.22-0.441-0.076
        c-0.153,0.145-0.526,0.472-0.724,0.594c-0.206,0.122-0.382,0.061-0.466,0.152c-0.091,0.084-0.258,0.229-0.296,0.084
        c-0.03-0.144-0.229,0.061-0.183-0.205c0.038-0.259,0.122-0.42,0.183-0.472c0.061-0.046,0.274-0.252,0.503-0.366
        c0.228-0.114,0.692-0.358,0.936-0.381s0.503,0.084,0.519-0.114c0.007-0.198-0.618,0.046-0.541-0.091
        c0.069-0.145,0.229-0.282,0.069-0.282c-0.168,0-0.32,0.046-0.587,0.221c-0.259,0.168-0.268,0.274-0.624,0.282
        c-0.358,0.007-0.571-0.13-0.687,0.015c0,0.014-0.007,0.023-0.014,0.03c-0.092-0.091-0.16-0.19-0.106-0.236
        c0.151-0.152,0.145-0.16,0.036-0.259c-0.029-0.03-0.067-0.075-0.12-0.144c-0.107-0.153-0.038-0.282,0.053-0.457
        c0.031-0.069,0.067-0.137,0.099-0.213c0.023-0.061,0.046-0.107,0.068-0.152c0.1-0.214,0.152-0.328-0.061-0.534
        c-0.137-0.137-0.281-0.114-0.403-0.091c-0.062,0.008-0.114,0.016-0.122-0.03c-0.015-0.092-0.061-0.122-0.138-0.107
        c-0.067,0.015-0.158,0.076-0.266,0.153c-0.076,0.052-0.167,0.113-0.251,0.151c-0.16,0.084-0.244,0.213-0.328,0.358
        c-0.069,0.107-0.137,0.221-0.243,0.312c-0.13,0.107-0.297,0.275-0.449,0.427c-0.16,0.153-0.305,0.297-0.396,0.365
        c-0.054,0.038-0.108,0.054-0.153,0.069c-0.099,0.023-0.19,0.045-0.328,0.259c-0.129,0.183-0.242,0.168-0.524,0.145
        c-0.129-0.008-0.304-0.023-0.525-0.023c-0.313,0-0.502,0-0.662,0.007c-0.207,0-0.374,0.008-0.73,0
        c-0.67-0.007-1.341,0.579-1.531,0.738v0.009c-0.03,0.021-0.053,0.045-0.075,0.068c-0.054-0.023-0.114-0.023-0.19-0.008
        c-0.19,0.054-0.335,0.092-0.525,0.161c-0.198,0.068-0.154-0.031-0.405-0.009c-0.25,0.023-0.524,0.069-0.676,0.107
        c-0.16,0.045-0.298,0-0.435,0.045c-0.139,0.054-0.222,0.116-0.297,0.153c-0.076,0.03-0.205,0.168-0.205,0.168
        s-0.199,0.084-0.145,0.19c0.046,0.107-0.038,0.091,0.189,0.091c0.022,0,0.054-0.007,0.076-0.007l-0.009,0.016
        c-0.021,0.075-0.045,0.167-0.083,0.243c-0.122,0.068-0.327,0.06-0.448,0.107c-0.114,0.045-0.236-0.047-0.435,0.068
        c-0.206,0.114-0.175,0.144-0.381,0.122c-0.198-0.023-0.099-0.122-0.373-0.023c-0.273,0.099-0.396,0.076-0.563,0.22
        c-0.167,0.138-0.266,0.252-0.396,0.275c-0.13,0.023-0.153,0.098-0.259,0.098c-0.107,0.008-0.138,0.016-0.19-0.022
        c-0.008-0.016-0.03-0.023-0.061-0.023c0.015-0.038,0.037-0.069,0.061-0.099c0.083-0.114,0.198-0.213,0.251-0.252
        c0.084-0.052,0.16-0.129,0.259-0.213c0.076-0.06,0.159-0.137,0.252-0.205c0.083-0.069,0.151-0.152,0.205-0.236
        c0.037-0.008,0.076-0.023,0.13-0.038c0.205-0.077,0.242-0.008,0.364-0.221c0.115-0.213,0.146-0.396,0.313-0.708
        c0.168-0.304,0.44-0.373,0.457-0.586c0.022-0.206-0.167-0.397,0.046-0.404c0.206-0.007,0.267,0.038,0.259,0.221
        c0,0.183,0.22,0.419,0.32,0.434c0.105,0.015,0.221,0.115,0.259-0.015c0.046-0.13-0.008-0.32,0.114-0.32
        c0.121-0.008,0.221,0.106,0.19-0.069c-0.022-0.167-0.069-0.183-0.16-0.381c-0.092-0.197-0.038-0.502-0.176-0.539
        c-0.137-0.032-0.38-0.092-0.51-0.008c-0.129,0.083-0.061,0.159-0.229,0.22c-0.167,0.053-0.167,0.107-0.389,0.023
        c-0.221-0.076-0.601-0.129-0.319-0.175c0.282-0.053,0.549,0.046,0.577-0.023c0.039-0.061,0.048-0.251-0.135-0.251
        c-1.044,0-0.922-0.046-1.196-0.061c-0.022-0.031-0.047-0.053-0.068-0.084c-0.053-0.061-0.084-0.099-0.114-0.13
        c-0.022-0.022-0.038-0.045-0.054-0.06c0.039-0.114,0.046-0.289,0.046-0.38c0-0.13,0.069-0.153,0.122-0.252
        c0.054-0.091-0.017-0.1-0.114-0.236c-0.091-0.137,0.13-0.153,0.236-0.335c0.099-0.183-0.101-0.091-0.251-0.084
        c-0.152,0.008-0.411-0.015-0.503-0.168c-0.091-0.152,0.076-0.387,0.016-0.539c-0.054-0.145-0.19-0.047-0.427-0.054
        c-0.229-0.008-0.16,0.076-0.435-0.069c-0.273-0.144-0.137-0.053-0.297-0.022c-0.151,0.022-0.365,0.259-0.448,0.342
        c-0.076,0.076-0.175,0.068-0.313-0.023c-0.144-0.099-0.525,0.343-0.539,0.373c0,0-0.031,0.031-0.076,0.069
        c-0.146-0.023-0.176-0.023-0.222-0.016c-0.03,0.008-0.068,0.016-0.146,0.008h-0.098c-0.267,0-0.808-0.015-0.984-0.114
        c-0.181-0.107-0.204-0.107-0.288-0.121c-0.015,0-0.029-0.009-0.053-0.009c-0.054-0.007-0.106-0.021-0.161-0.06
        c-0.06-0.039-0.12-0.092-0.204-0.19c-0.13-0.161-0.351-0.123-0.533-0.084c-0.076,0.015-0.145,0.022-0.19,0.022
        c-0.045-0.007-0.092-0.015-0.137-0.015c-0.138-0.007-0.282-0.015-0.366-0.099c-0.038-0.03,0-0.099,0.038-0.16
        c0.061-0.099,0.123-0.198,0.046-0.305c-0.061-0.084-0.122-0.075-0.197-0.029c-0.04,0.029-0.084,0.075-0.13,0.128
        c-0.061,0.069-0.13,0.153-0.213,0.191c-0.107,0.061-1.454,0.046-2.917,0.038c-1.065-0.008-2.185-0.015-2.961,0
        c-0.73,0.016-2.97,0.008-5.414-0.008c-3.692-0.022-7.872-0.068-8.025-0.083l-0.075-0.008c-0.184-0.015-0.366-0.03-0.487-0.008
        c-0.008-0.022-0.024-0.053-0.03-0.075c-0.084-0.206,0.053-0.373-0.123-0.359c-0.175,0.023-0.206-0.014-0.235-0.098
        c-0.022-0.084-0.076-0.259-0.152-0.091c-0.084,0.175-0.259,0.084-0.221-0.039c0.045-0.122,0.267-0.221,0.183-0.32
        c-0.076-0.091-0.304-0.091-0.358,0.015c-0.053,0.107-0.113,0.312-0.061,0.45c0.054,0.129,0.084,0.251,0.252,0.35
        c0.167,0.092,0.297,0.114,0.343,0.305c0.053,0.182-0.017,0.418-0.214,0.464c-0.199,0.046-0.381-0.038-0.557-0.046l-0.061-0.007
        c-0.038-0.008-0.099-0.016-0.167-0.023c-0.084-0.023-0.16-0.054-0.198-0.106c-0.083-0.107-0.015-0.237-0.046-0.336
        c-0.022-0.106-0.13-0.152-0.205-0.091c-0.076,0.061-0.167,0.122-0.19-0.031c-0.024-0.16,0.06-0.121-0.092-0.22
        c-0.159-0.092-0.268-0.298-0.274-0.404c-0.006-0.106-0.128-0.129-0.144-0.213c-0.022-0.092-0.168-0.069-0.176-0.175
        c-0.008-0.114,0.069-0.114,0.038-0.236c-0.03-0.122-0.092-0.435,0.145-0.396c0.244,0.045,0.395-0.084,0.602,0.16
        c0.214,0.251,0.373,0.205,0.525,0.258c0.145,0.054,0.511,0.191,0.457,0.046c-0.053-0.137-0.168-0.175-0.281-0.182
        c-0.115-0.016-0.008-0.084-0.145-0.168c-0.139-0.076-0.624,0.015-0.541-0.304c0.083-0.313,0.175-0.419,0.151-0.518
        c-0.015-0.099-0.113-0.198,0.176-0.305c0.289-0.098,0.351-0.259,0.214-0.304c-0.139-0.039-0.244,0.076-0.268-0.054
        c-0.029-0.129-0.151-0.091-0.166-0.182c-0.009-0.084,0.015-0.183,0.121-0.267s0.235-0.16,0.327-0.259
        c0.084-0.091,0.068-0.396-0.114-0.236c-0.183,0.167-0.281,0.351-0.327,0.176c-0.047-0.176-0.152-0.084-0.137-0.245
        c0.007-0.167-0.077-0.296,0.09-0.327c0.168-0.03,0.161-0.068,0.268-0.146c0.114-0.068,0.213-0.205,0.259-0.395
        c0.016-0.054,0.008-0.106-0.015-0.161c0.106-0.045,0.243-0.098,0.364-0.144l0.146-0.053c0.121-0.053,0.244-0.122,0.351-0.198
        c0.114-0.084,0.212-0.175,0.273-0.252c0.046-0.06,0.092-0.099,0.145-0.136c0.077-0.054,0.146-0.115,0.198-0.221
        c0.03-0.077,0.03-0.145,0.023-0.207c-0.023-0.091-0.084-0.159-0.115-0.174c-0.007-0.007-0.022-0.015-0.037-0.023
        c-0.076-0.03-0.237-0.106-0.373-0.251c-0.069-0.061-0.024-0.114,0.022-0.168c0.046-0.061,0.099-0.121,0.075-0.205
        c-0.021-0.068-0.015-0.138,0.009-0.229c0.022-0.091,0.068-0.198,0.137-0.334c0.068-0.153,0.046-0.427,0.03-0.678
        c-0.022-0.206-0.038-0.397,0.008-0.442c0.092-0.114,0.038-0.183-0.03-0.251c-0.031-0.038-0.061-0.077-0.07-0.122
        c-0.006-0.053,0-0.091,0.01-0.121c0.007-0.069,0.022-0.123-0.046-0.207c-0.068-0.083-0.175-0.06-0.313-0.022
        c-0.076,0.015-0.168,0.038-0.259,0.038c-0.16,0-0.236,0.076-0.319,0.152c-0.062,0.054-0.122,0.114-0.229,0.153
        c-0.138,0.045-0.282,0.113-0.404,0.168c-0.114,0.06-0.213,0.106-0.281,0.121l-0.038,0.007c-0.078,0.023-0.084,0.031-0.138-0.061
        c-0.03-0.053-0.015-0.099,0.016-0.151c0.022-0.055,0.054-0.115,0.054-0.198c0-0.13-0.046-0.214-0.1-0.305
        c-0.022-0.038-0.038-0.076-0.061-0.115c-0.017-0.052,0.008-0.076,0.038-0.107c0.045-0.037,0.091-0.091,0.068-0.182
        c-0.023-0.114-0.114-0.122-0.236-0.099c-0.068,0.015-0.144,0.046-0.222,0.076c-0.045,0.016-0.09,0.031-0.136,0.046
        c-0.106,0.031-0.205,0.046-0.297,0.031c-0.084-0.008-0.151-0.039-0.191-0.077c0-0.007,0.122-0.099,0.298-0.274
        c0.205-0.198,0.511-0.472,0.876-0.792c1.188-1.058,3.03-2.626,4.355-3.746C29.284,11.534,30.685,10.499,31.545,9.882z
         M46.491,7.994c0,0-0.441,0.395-0.898,0.525c-0.457,0.122-0.595,0.054-0.922,0.122c-0.334,0.076-0.503,0.167-0.753,0.19
        c-0.259,0.031-0.357,0.16-0.351-0.061c0.008-0.213,0.145-0.388-0.038-0.411c-0.183-0.015-0.267-0.03-0.381-0.068
        c-0.122-0.046-0.411-0.038-0.342-0.122c0.066-0.076,0.35,0.008,0.691-0.259c0.352-0.274,0.586-0.175,0.884-0.312
        c0.298-0.137,0.48-0.366,0.892-0.495c0.411-0.137,0.495-0.114,0.533-0.312c0.03-0.206-0.223-0.221,0.197-0.313
        c0.419-0.091,0.609-0.205,1.202-0.136c0.595,0.075,0.687-0.031,0.93,0.061c0.252,0.098,0.168-0.031,0.617,0.167
        c0.441,0.19,0.678-0.092,1.08-0.008c0.404,0.077,0.488,0.16,0.511,0.244c0.023,0.076,0.137,0.137-0.055,0.175
        c-0.188,0.031,0,0.168-0.401,0.183c-0.403,0.023-1.051,0.152-1.614,0.312c-0.557,0.16-0.777,0.29-1.189,0.396
        c-0.41,0.099-0.509,0.061-0.531,0.077C46.529,7.956,46.491,7.994,46.491,7.994z M68.496,10.332
        c-0.039,0.022-0.313,0.167-0.419,0.198c-0.106,0.038-0.282,0.152-0.229,0.182c0.053,0.038,0.213,0.038,0.35-0.007
        c0.138-0.038,0.184-0.115,0.274-0.153c0.092-0.038,0.335-0.213,0.252-0.243C68.641,10.278,68.496,10.332,68.496,10.332z
         M70.155,11.36c0,0-0.122,0.06-0.053,0.136c0.061,0.076,0.167,0.038,0.327,0.061c0.167,0.023,0.229,0.061,0.335,0.03
        c0.106-0.037,0.19-0.159,0.092-0.198c-0.108-0.038-0.206-0.015-0.297-0.038c-0.092-0.03-0.146-0.06-0.229-0.053
        C70.247,11.305,70.155,11.36,70.155,11.36z M70.155,10.865c-0.007,0-0.19,0.053-0.159,0.129
        c0.022,0.068,0.137,0.068,0.167,0.099c0.022,0.03,0.251-0.137,0.145-0.206C70.208,10.819,70.155,10.865,70.155,10.865z
         M69.258,11.154c-0.046,0-0.944,0.373-1.005,0.517c-0.062,0.153-0.039,0.373,0.12,0.351c0.153-0.023,0.26-0.084,0.504-0.076
        c0.243,0.007,0.525-0.054,0.677-0.184c0.145-0.136,0.19-0.22,0.29-0.35c0.099-0.121,0.091-0.213,0.061-0.266
        c-0.038-0.053-0.357-0.038-0.441-0.031C69.379,11.123,69.258,11.154,69.258,11.154z M65.588,7.111
        c-0.023,0.007-0.687,0.197-1.007,0.472c-0.326,0.274-0.569,0.35-0.676,0.465c-0.106,0.113-0.039,0.136-0.206,0.212
        c-0.16,0.077-0.175,0.198-0.244,0.274c-0.076,0.084-0.213,0.137-0.091,0.214c0.129,0.083,0.502-0.023,0.647,0.038
        c0.137,0.068,0.411,0.106,0.373,0.16c-0.039,0.045,0.091,0.205-0.236,0.175c-0.32-0.031-0.373-0.077-0.511-0.099
        c-0.129-0.023-0.373-0.153-0.389-0.053c-0.015,0.099-0.159,0.114-0.053,0.273c0.106,0.152,0.106,0.214,0.229,0.259
        c0.129,0.046,0.35,0,0.547,0.039c0.19,0.045,0.39-0.091,0.457,0c0.062,0.091-0.022,0.144,0.183,0.182
        c0.206,0.038,0.328,0.008,0.525,0.023c0.198,0.008,0.396,0.023,0.693,0.069s0.472-0.038,0.602-0.009
        c0.137,0.039,0.251,0.161,0.396,0.13c0.152-0.022,0.145-0.13,0.236-0.13c0.099-0.007,0.152,0.055,0.205,0.084
        c0.054,0.039,0.267,0.191,0.479,0.214c0.222,0.03,0.373,0.137,0.449,0c0.077-0.13-0.061-0.229-0.22-0.198
        c-0.168,0.022-0.503-0.091-0.465-0.16c0.046-0.061,0.183,0,0.357,0.008c0.176,0.007,0.45,0.076,0.541,0.129
        c0.099,0.053,0.343-0.022,0.473-0.06c0.137-0.039,0.175-0.146,0.106-0.229c-0.077-0.084-0.152-0.221,0.045-0.213
        c0.198,0.007,0.076,0.007,0.213,0.16c0.146,0.144,0.305,0.015,0.335,0.121c0.023,0.107-0.273,0.199-0.099,0.297
        c0.175,0.099,0.381,0.069,0.419,0.129c0.046,0.062,0.068,0.175,0.175,0.245c0.106,0.068,0.252,0.114,0.062,0.159
        c-0.19,0.046-0.473-0.015-0.533,0.053c-0.061,0.07-0.365,0.252-0.175,0.282c0.189,0.039,0.396-0.137,0.57-0.114
        c0.183,0.03,0.372-0.069,0.456,0.015s0.176,0.13,0.229,0.214c0.053,0.091,0.152,0.198,0.327,0.266
        c0.176,0.069,0.229,0.076,0.222,0.221c-0.008,0.152-0.046,0.38-0.107,0.518c-0.061,0.144-0.334,0.007-0.587,0.296
        c-0.258,0.29-0.981,0.412-1.148,0.541c-0.168,0.129-0.038,0.312,0.008,0.442c0.038,0.129,0.105,0.251-0.114,0.205
        c-0.229-0.038-0.19-0.175-0.351-0.076c-0.152,0.092-0.518,0.145-0.731,0.198c-0.213,0.054-0.221,0.099-0.457,0.007
        c-0.235-0.083-0.364-0.19-0.601-0.136c-0.236,0.045-0.359,0.19-0.549,0.251s-0.373,0.175-0.426,0.281
        c-0.054,0.099-0.26,0.222-0.022,0.343c0.229,0.122,0.411,0.099,0.722,0.069c0.321-0.039,0.496-0.289,0.762-0.168
        c0.274,0.122,0.351,0.137,0.579,0.099c0.229-0.045,0.449-0.19,0.671-0.068c0.228,0.121,0.235,0.152,0.25,0.259
        c0.016,0.114,0.069,0.403,0.26,0.426c0.189,0.031,0.388,0.061,0.259,0.167c-0.122,0.099-0.381,0.054-0.297,0.183
        c0.076,0.122,0.381,0.282,0.419,0.358c0.046,0.084-0.069,0.182,0.129,0.228c0.206,0.038,0.457-0.197,0.579-0.068
        c0.122,0.129,0.418,0.267,0.685,0.343c0.267,0.075,0.449,0.068,0.663,0.152c0.205,0.091,0.381,0.205,0.494,0.114
        c0.114-0.084,0.145-0.236,0.114-0.335c-0.023-0.099-0.305-0.525-0.601-0.708c-0.298-0.183-0.511-0.495-0.313-0.503
        c0.197-0.015,0.426,0.061,0.579,0.275c0.159,0.213,0.434,0.274,0.57,0.38c0.13,0.099,0.579,0.206,0.632,0.304
        c0.054,0.107,0.206,0.245,0.298,0.139c0.084-0.108-0.069-0.306-0.009-0.42c0.062-0.114,0.151-0.335,0.199-0.465
        c0.045-0.129,0.015,0.084,0.189,0.069c0.176-0.022,0.297-0.236,0.282-0.373c-0.015-0.137-0.023-0.236-0.19-0.434
        c-0.168-0.191-0.252-0.312-0.396-0.48c-0.152-0.175-0.335-0.243-0.335-0.395c0-0.153-0.046-0.13-0.145-0.32
        c-0.106-0.198,0.038-0.275,0.137-0.214c0.106,0.061,0.274,0.267,0.343,0.069c0.067-0.198,0.038-0.381,0.214-0.32
        c0.175,0.061,0.35,0.069,0.426,0.236c0.076,0.168,0.198,0.427,0.335,0.533c0.136,0.114,0.289,0.632,0.708,0.442
        s0.365-0.381,0.571-0.487c0.198-0.107,0.472-0.191,0.724-0.351c0.258-0.159,0.327-0.19,0.503-0.266
        c0.173-0.084,0.685-0.434,0.312-0.503c-0.381-0.068-0.716-0.038-0.716-0.175c0-0.137-0.053-0.244-0.274-0.266
        c-0.213-0.031-0.121-0.183-0.267-0.328c-0.151-0.152-0.121-0.159-0.434-0.152c-0.313,0.016-0.578,0.084-0.701-0.13
        c-0.122-0.22-0.51-0.205-0.578-0.342c-0.068-0.13,0.023-0.16,0.19-0.229c0.16-0.068,0.229-0.213,0.479-0.175
        c0.26,0.046,0.571-0.076,0.411-0.19c-0.168-0.107-0.692,0-0.646-0.114c0.053-0.122,0.327-0.137,0.495-0.152
        c0.167-0.016,0.235-0.221,0.182-0.313C75.551,9.608,75.423,9.54,75.3,9.593c-0.13,0.061-0.327,0.099-0.297,0.022
        c0.022-0.083,0.343-0.151,0.282-0.228c-0.062-0.068-0.465-0.198-0.701-0.213c-0.243-0.015-0.419,0.084-0.434,0.023
        c-0.023-0.062-0.031-0.191-0.137-0.175c-0.115,0.015-0.298,0.076-0.457,0.114c-0.16,0.038-0.305-0.061-0.168-0.13
        c0.137-0.068,0.365-0.099,0.487-0.121c0.129-0.031,0.167-0.229-0.047-0.274c-0.212-0.046-0.235-0.092-0.486,0.007
        c-0.251,0.107-0.061,0.228-0.305,0.152c-0.243-0.083-0.243-0.114-0.229-0.174c0.016-0.062,0.1-0.305-0.068-0.366
        c-0.16-0.061-0.221-0.061-0.297-0.175c-0.068-0.122,0.114-0.213-0.221-0.19c-0.335,0.015-0.556-0.146-0.655-0.153
        c-0.099-0.007-0.129-0.129,0.046-0.084c0.176,0.046,0.511,0,0.587-0.03c0.084-0.023,0.251-0.16-0.023-0.312
        c-0.273-0.16-0.267-0.38-0.67-0.305c-0.403,0.069-0.662,0.061-0.853,0.031c-0.199-0.023-0.389-0.091-0.525,0
        c-0.146,0.091-0.357,0.213-0.244,0.274c0.114,0.068,0.244,0.038,0.229,0.167c-0.022,0.13-0.084,0.236,0.016,0.252
        c0.106,0.022,0.571-0.069,0.746-0.077c0.175-0.007,0.541,0.008,0.479,0.069c-0.061,0.069-0.305,0.054-0.472,0.122
        C70.719,7.88,70.681,7.933,70.506,8c-0.175,0.077-0.273,0.047-0.396-0.007c-0.123-0.061-0.062-0.16-0.267-0.076
        c-0.198,0.076-0.563,0.312-0.625,0.213c-0.061-0.099-0.03-0.244,0.168-0.305c0.197-0.06,0.389-0.137,0.389-0.183
        c0-0.038,0.083-0.19-0.016-0.274c-0.106-0.083-0.084-0.129-0.054-0.22c0.031-0.092,0.229-0.176,0.038-0.198
        c-0.197-0.023-1.02,0.099-1.462,0.145C67.847,7.14,67.413,7.278,67.3,7.353c-0.114,0.076-0.177,0.206-0.403,0.267
        c-0.236,0.061-0.442,0.228-0.358,0.312c0.092,0.091,0.092,0.122-0.061,0.16c-0.146,0.046-0.176,0.137-0.282,0.167
        c-0.099,0.038-0.19,0.107-0.068,0.183c0.114,0.084,0.297,0.122,0.221,0.198c-0.076,0.076-0.152,0.175-0.327,0.023
        c-0.176-0.159-0.397-0.366-0.229-0.579c0.168-0.22,0.335-0.175,0.372-0.35c0.031-0.175,0.131-0.198,0.351-0.32
        c0.222-0.121,1.287-0.304,1.143-0.48c-0.152-0.175-0.937-0.061-1.18-0.038C66.234,6.928,65.588,7.111,65.588,7.111z
         M63.151,16.446c0.129,0.083,0.365-0.023,0.479-0.138c0.113-0.122,0.235-0.48,0.084-0.495
        C63.349,15.776,62.938,16.308,63.151,16.446z M61.278,16.125c0.106-0.016,0.167-0.069,0.38-0.123
        c0.213-0.052,0.548-0.22,0.678-0.304c0.138-0.091,0.288-0.35,0.054-0.304c-0.236,0.046-0.335,0.137-0.479,0.084
        c-0.152-0.054-0.236,0-0.351,0.045C61.179,15.677,60.859,16.171,61.278,16.125z M61.012,13.925c0,0-0.274,0.252-0.297,0.389
        c-0.031,0.136-0.184,0.175-0.383,0.236c-0.196,0.06-1.11,0.128-0.524,0.289c0.587,0.16,0.86-0.106,0.86,0.038
        c-0.008,0.137-0.35,0.419-0.137,0.457c0.213,0.038,0.396,0.023,0.67-0.153c0.268-0.182,0.754-0.296,0.914-0.426
        c0.16-0.129,0.145-0.404,0.396-0.32c0.259,0.092,0,0.221,0.213,0.266c0.214,0.039,0.319,0.176,0.48,0.245
        c0.152,0.076,0.38,0.228,0.655,0.084c0.265-0.146,0.555-0.527,0.251-0.549c-0.297-0.015-0.571,0.129-0.556-0.13
        c0.015-0.251,0.145-0.419-0.062-0.441c-0.206-0.023-0.403-0.282-0.524-0.335c-0.123-0.045-0.297,0.031-0.352-0.038
        c-0.053-0.069-0.273-0.046-0.35-0.061c-0.084-0.016-0.137-0.191-0.038-0.243c0.099-0.054,0.213,0.06,0.327,0
        c0.115-0.062,0.236-0.275,0.046-0.29s-0.281,0.084-0.435,0.099c-0.144,0.008-0.319,0.061-0.434,0.183
        C61.62,13.354,61.012,13.925,61.012,13.925z M67.141,2.435c-0.053,0.099-0.129,0.099-0.252,0.252
        c-0.121,0.152-0.235,0.213-0.106,0.327c0.13,0.115,0.244,0.046,0.503,0.046c0.251,0,0.449-0.007,0.556-0.007
        c0.107,0,0.297,0,0.19,0.062c-0.107,0.06-0.503,0.075-0.624,0.136c-0.129,0.061-0.213,0.1-0.183,0.237
        c0.022,0.144,0.22,0.296,0.388,0.342s0.038-0.03,0.465,0.031c0.427,0.061,0.776-0.252,0.882-0.191
        c0.115,0.061-0.029,0.145,0.253,0.122c0.281-0.031,0.145-0.031,0.372-0.076c0.229-0.046,0.693-0.335,0.892-0.305
        c0.198,0.039,0.259-0.122,0.44-0.144c0.176-0.023,0.328-0.077,0.603-0.038c0.282,0.03,0.356-0.023,0.479-0.069
        c0.122-0.045,0.023-0.236,0.092-0.29c0.068-0.053,0.251-0.144,0.357,0c0.099,0.145-0.099,0.214-0.137,0.275
        s0.624,0.137,0.335,0.228c-0.29,0.092-0.281,0-0.617,0.008c-0.342,0.007-0.608-0.084-1.035,0.038
        c-0.427,0.13-0.876,0.205-0.906,0.312c-0.038,0.107,0.062,0.205,0.351,0.168c0.297-0.038,0.319-0.031,0.358,0.083
        c0.029,0.115,0.022,0.191,0.183,0.236c0.167,0.046,0.41,0.008,0.311,0.091c-0.091,0.077-0.197,0.07-0.493,0.077
        c-0.306,0.007-0.442-0.069-0.495-0.183c-0.054-0.106,0-0.168-0.214-0.168c-0.221,0-0.7-0.038-0.853,0.084
        c-0.16,0.122-0.122,0.084-0.084,0.267c0.046,0.183,0.152,0.213-0.145,0.205c-0.298-0.015-0.343-0.06-0.64,0.008
        c-0.297,0.061-0.45,0.137-0.586,0.175c-0.13,0.03-0.122,0.053-0.229,0.008c-0.106-0.039-0.364-0.114-0.356,0.008
        c0.007,0.121,0.106,0.129,0.205,0.175c0.091,0.045,0.168,0.084,0.381,0.06C68,5,68.008,5.055,68.237,5.032
        C68.465,5,69.242,4.926,69.387,5c0.152,0.061,0.662,0.054,0.853,0c0.19-0.052,0.251-0.198,0.502-0.106
        C71,4.994,71.054,5,71.253,5c0.197,0,0.753-0.068,0.775,0c0.015,0.061-0.365,0.191-0.045,0.237
        c0.327,0.038,0.479-0.039,0.601-0.077s0.184-0.061,0.419-0.068c0.236-0.008,0.305-0.137,0.456-0.106
        c0.146,0.022,0.603-0.054,0.526-0.206c-0.076-0.146-0.296-0.092-0.404-0.107c-0.113-0.015-0.206-0.296,0.031-0.282
        c0.236,0.015,0.16,0.107,0.404,0.031c0.243-0.069,0.281-0.138,0.456-0.175c0.176-0.031,0.206-0.244,0.297-0.244
        c0.1,0,0.105,0.069,0.267,0.046c0.16-0.031,0.753-0.054,0.982-0.168c0.236-0.106,0.327-0.228,0.678-0.243
        c0.35-0.008,0.8-0.077,0.418-0.229c-0.38-0.144-0.7-0.441-0.388-0.35c0.305,0.099,0.7-0.069,0.86-0.069s0.312,0.168,0.661,0.031
        c0.352-0.137,0.023-0.335,0.436-0.259c0.403,0.084,0.51,0.19,0.77,0.106c0.251-0.075,0.715-0.235,0.913-0.35
        c0.205-0.106,0.54-0.069,0.624-0.121c0.076-0.054,0.091,0,0.525-0.13c0.434-0.137,0.373-0.19,0.607-0.221
        c0.23-0.023,0.832-0.152,1.266-0.175c0.441-0.015,0.883-0.152,0.784-0.19c-0.099-0.031-0.54,0.023-0.839,0.023
        c-0.295,0-1.118-0.016-0.92-0.069c0.19-0.053,0.244-0.053,0.784-0.061c0.548-0.008,0.396-0.106,0.891-0.091
        c0.503,0.015,0.914,0.069,1.165-0.031c0.252-0.091,0.327-0.144,0.647-0.19c0.32-0.053,0.723-0.175,0.349-0.213
        c-0.372-0.038-0.265,0.007-0.836-0.076c-0.563-0.076-0.473-0.206-1.119-0.122c-0.647,0.076-1.188-0.152-2.071-0.107
        c-0.883,0.046-0.997,0.062-1.34,0.069c-0.335,0.008-0.069,0.145-0.419,0.114c-0.343-0.023-0.183-0.084-0.587-0.068
        c-0.396,0.023-0.524-0.061-0.807,0.061c-0.282,0.121-0.343,0.152-0.532,0.098c-0.19-0.052-0.274-0.052-0.518-0.045
        c-0.244,0-1.166-0.122-1.615,0.06c-0.449,0.176-0.449,0.229-0.761,0.229c-0.32,0-0.404-0.145-0.876-0.114
        c-0.464,0.038-0.319,0.152-0.822,0.167c-0.495,0.008-0.739,0.053-1.203,0.099c-0.464,0.046-1.127,0.069-1.462,0.084
        c-0.327,0.008-0.708,0.076-0.525,0.229c0.183,0.144,0.176,0.037,0.419,0.228c0.244,0.19,0.229,0.152,0.655,0.205
        c0.426,0.054,1.035,0.138,1.294,0.176c0.267,0.03,1.066-0.099,1.362-0.084c0.298,0.015,0.351,0.107,0.701,0.046
        c0.357-0.061,1.005-0.13,0.906-0.084c-0.092,0.053-0.449,0.168-0.883,0.206c-0.435,0.037-1.12-0.084-1.128-0.015
        C74.075,2.48,74.44,2.77,74.212,2.77s-0.373-0.275-0.563-0.275c-0.183,0-0.502,0.031-0.845,0.016
        c-0.343-0.023-0.7-0.105-0.808,0.015c-0.106,0.122-0.137,0.282-0.251,0.305c-0.106,0.03-0.313,0.037-0.221-0.061
        c0.099-0.099,0.213-0.244,0.16-0.282c-0.062-0.039-0.084-0.236-0.298-0.129c-0.205,0.106-0.281,0.228-0.373,0.152
        c-0.1-0.084,0.183-0.152-0.076-0.213S70.291,2.275,70.23,2.1c-0.062-0.175-0.1-0.298-0.313-0.313
        c-0.206-0.015-0.671-0.106-0.982,0.107c-0.313,0.213-0.366,0.244-0.564,0.259C68.26,2.161,67.187,2.344,67.141,2.435z
         M65.115,2.58c-0.062,0.008-0.236,0.046-0.189,0.099c0.037,0.061,0.105,0,0.266,0.069c0.16,0.061,0.145,0.137,0.281,0.099
        c0.13-0.031,0.373-0.206,0.19-0.282C65.48,2.496,65.115,2.58,65.115,2.58z M64.796,3.41c0,0-0.358,0.016-0.251,0.259
        c0.106,0.237,0.213,0.122,0.35,0.266c0.137,0.145,0.441,0.176,0.266,0.214c-0.167,0.046-0.327,0.106-0.234,0.145
        c0.082,0.037,0.356,0.045,0.585,0.045c0.236,0,0.495,0.099,0.617,0.023c0.121-0.077,0.335-0.289-0.061-0.289
        c-0.389,0-0.694,0-0.61-0.092c0.092-0.083,0.549-0.236,0.404-0.38c-0.153-0.152-0.419-0.175-0.594-0.206
        C65.092,3.372,64.796,3.41,64.796,3.41z M61.926,3.921c0,0-0.306,0.06-0.184,0.136c0.122,0.069,0.152,0.023,0.351,0.061
        c0.196,0.046,0.381-0.068,0.297-0.114C62.313,3.958,61.926,3.921,61.926,3.921z M61.78,3.06c0,0-0.389-0.015-0.389,0.114
        c-0.007,0.129,0.176,0.107,0.236,0.183c0.053,0.076,0.037,0.16-0.03,0.183c-0.068,0.022-0.343,0-0.335,0.068
        c0.008,0.061-0.023,0.152,0.259,0.145c0.274-0.008,0.464-0.084,0.624-0.023c0.16,0.053,0.328,0.008,0.435,0.084
        c0.114,0.084,0.167,0.205,0.305,0.228c0.144,0.031,0.373-0.053,0.494-0.038c0.121,0.023,0.365,0.076,0.373-0.023
        c0.008-0.106-0.121-0.274-0.03-0.327c0.1-0.053,0.235-0.198,0.016-0.297c-0.221-0.091-0.335-0.091-0.572-0.114
        c-0.235-0.023-0.304-0.129-0.541-0.175c-0.234-0.046-0.577-0.099-0.698-0.092C61.811,2.984,61.78,3.06,61.78,3.06z
         M64.095,4.583c-0.046,0.007-0.357-0.008-0.335,0.129c0.016,0.145,0.183,0.168,0.19,0.229c0.015,0.068-0.168,0.099,0.145,0.159
        c0.319,0.061,0.402,0.123,0.647,0.084c0.236-0.03,0.221-0.114,0.35-0.106c0.13,0.007,0.244-0.016,0.252,0.076
        c0.015,0.084-0.03,0.114,0.015,0.183c0.047,0.069,0.099,0.168,0.008,0.19c-0.091,0.023-0.586,0.145-0.602,0.358
        c-0.016,0.206-0.312,0.22-0.046,0.366c0.26,0.136,0.496,0.121,0.662,0.091c0.168-0.031,0.19,0.083,0.381,0.053
        c0.199-0.03,0.457-0.16,0.541-0.122c0.092,0.038-0.099,0.137,0.183,0.152c0.282,0.024,0.982,0.076,1.34,0.024
        c0.358-0.054,1.006-0.016,1.203-0.024c0.206-0.015,0.343-0.289,0.465-0.236c0.121,0.062,0.076,0.153,0.296,0.176
        c0.229,0.03,0.808-0.054,1.014,0c0.213,0.045,0.305-0.077,0.403-0.107c0.092-0.023,0.328,0.038,0.411-0.076
        c0.076-0.114,0.115-0.198,0.244-0.275c0.122-0.075,0.411-0.152,0.152-0.273c-0.259-0.122-0.435-0.107-0.641-0.107
        c-0.212,0-0.175-0.069-0.541-0.084c-0.364-0.015-0.631-0.045-0.988,0.03c-0.358,0.069-0.206,0.069-0.679,0.092
        c-0.471,0.023-0.631,0.092-0.761,0.099c-0.129,0.015-0.365-0.137-0.602-0.076c-0.235,0.061-0.389,0.144-0.541,0.069
        c-0.145-0.069-0.145-0.061-0.266-0.054c-0.123,0.008-0.099-0.076-0.13-0.145c-0.038-0.068-0.106-0.205-0.068-0.235
        c0.038-0.039,0.273,0.099,0.351-0.024c0.076-0.121,0.121-0.205-0.061-0.205c-0.183,0-0.299-0.129-0.519-0.137
        c-0.221-0.008-0.495-0.015-0.64,0c-0.151,0.015-0.235,0.03-0.358-0.068c-0.114-0.092-0.137-0.191-0.388-0.161
        c-0.251,0.023-0.327,0.016-0.479-0.007c-0.145-0.023-0.137-0.038-0.297-0.038H64.095z M62.969,5.748
        c0,0-0.153,0.106-0.282,0.137c-0.13,0.022-0.449,0.015-0.335,0.159c0.113,0.153,0.297,0.108,0.456,0.176
        c0.153,0.076,0.45,0.114,0.663,0.083c0.205-0.021,0.373-0.114,0.426-0.159c0.046-0.053,0.32-0.259,0.236-0.389
        c-0.092-0.129-0.015-0.25-0.344-0.175c-0.334,0.084-0.486,0.107-0.615,0.107C63.043,5.687,62.969,5.748,62.969,5.748z
         M59.367,5.466c0,0,0.174-0.091,0.365-0.091c0.182,0,0.273-0.038,0.388-0.008c0.114,0.031,0.183-0.038,0.212-0.099
        c0.032-0.061,0.199-0.29,0.344-0.312c0.138-0.023,0.244-0.069,0.298,0.038c0.053,0.099,0.091,0.205,0.19,0.182
        c0.106-0.022,0.106-0.167,0.068-0.227c-0.046-0.054-0.1-0.138,0.046-0.153c0.145-0.023,0.281-0.061,0.449-0.015
        c0.167,0.038,0.297,0.015,0.403,0.091c0.113,0.084,0.129,0.069,0.235,0.038c0.107-0.023,0.412-0.091,0.503,0.008
        c0.084,0.091,0.205,0.167,0.008,0.236c-0.198,0.069-0.092,0.099-0.236,0.16c-0.152,0.053,0.175,0.038-0.16,0.191
        c-0.342,0.159-0.388,0.091-0.548,0.266c-0.16,0.175-0.229,0.22-0.594,0.22c-0.365,0-0.731-0.029-0.808-0.022
        c-0.083,0.007-0.319-0.091-0.221-0.175c0.1-0.092,0.243-0.229,0.1-0.229c-0.152,0-0.198,0.069-0.389,0.061
        c-0.19,0-0.351-0.069-0.495-0.038c-0.145,0.03-0.259,0.038-0.259-0.008C59.268,5.542,59.367,5.466,59.367,5.466z M59.45,4.857
        c-0.016,0.008-0.305,0.038-0.213,0.167c0.099,0.13-0.389,0.183-0.251,0.244c0.137,0.069,0.327,0.015,0.594-0.008
        c0.267-0.022,0.449-0.007,0.479-0.076c0.03-0.061-0.038-0.327-0.274-0.358C59.541,4.796,59.45,4.857,59.45,4.857z M59.763,4.118
        c-0.022,0.008-0.273,0.153-0.222,0.26c0.055,0.113,0.191,0.167,0.336,0.144c0.152-0.023,0.304-0.313,0.16-0.396
        C59.885,4.042,59.763,4.118,59.763,4.118z M58.179,5.74c-0.015,0-0.441,0.129-0.319,0.206c0.129,0.084,0.364,0.03,0.479-0.008
        c0.12-0.038,0.175-0.243,0.12-0.251C58.407,5.679,58.179,5.74,58.179,5.74z M60.463,7.385c-0.037,0-0.183,0.061-0.16,0.106
        c0.023,0.046,0.084,0.061,0.16,0.061c0.076,0,0.236-0.061,0.229-0.114c-0.008-0.06-0.031-0.092-0.084-0.092
        C60.562,7.339,60.463,7.385,60.463,7.385z M59.26,6.822c-0.054,0-0.639-0.069-0.654,0.099c-0.008,0.167-0.13,0.197-0.236,0.205
        c-0.099,0.015-0.259,0.061-0.161,0.175c0.1,0.106,0.215,0.031,0.177,0.129c-0.038,0.107-0.106,0.222-0.29,0.229
        c-0.183,0.015-0.327-0.061-0.427-0.16c-0.09-0.099-0.304-0.084-0.357-0.061c-0.053,0.031-0.396,0.069-0.373,0.214
        c0.016,0.151,0.122,0.19,0.168,0.273c0.038,0.084,0.311,0.153,0.427,0.168c0.113,0.015,0.128,0.046,0.167,0.152
        c0.03,0.114,0.076,0.236,0.106,0.32c0.03,0.091,0.008,0.19,0.206,0.205c0.189,0.008,0.335,0.024,0.419-0.038
        c0.091-0.061,0.038-0.159,0.234-0.167c0.206-0.008,0.404-0.092,0.588-0.099c0.182-0.008,0.319-0.015,0.455-0.144
        c0.139-0.13,0.115-0.191,0.261-0.207C60.12,8.1,60.212,8,60.311,7.887c0.092-0.106,0.152-0.244,0.054-0.244
        c-0.1,0-0.184-0.053-0.221-0.113c-0.046-0.062-0.221-0.046-0.213-0.107c0.007-0.061-0.031-0.115,0.112-0.183
        c0.153-0.061,0.397-0.038,0.55-0.114c0.151-0.069,0.113-0.213,0.229-0.229c0.113-0.015,0.213-0.099,0.099-0.152
        c-0.121-0.053-0.168-0.167-0.373-0.084c-0.215,0.092-0.473,0.153-0.578,0.153c-0.115,0-0.221,0.068-0.328,0.053
        c-0.106-0.023-0.008-0.099-0.197-0.069L59.26,6.822z M55.459,6.966c0,0-0.219-0.015-0.196,0.091
        c0.016,0.114,0.183,0.168,0.196,0.237c0.017,0.075-0.074,0.243,0.116,0.243c0.189,0,1.126-0.441,0.959-0.548
        c-0.167-0.107-0.343-0.167-0.67-0.1C55.543,6.958,55.459,6.966,55.459,6.966z M56.656,3.593c0,0-0.19,0.015-0.16,0.076
        c0.03,0.061,0.114,0.099,0.244,0.069c0.129-0.031,0.022-0.092,0.297-0.054c0.267,0.039,0.463,0.054,0.57,0.016
        c0.107-0.039,0.107-0.092,0.282-0.054c0.175,0.038,0.434,0.061,0.541,0.054c0.106-0.016,0.25-0.092,0.258-0.138
        c0.016-0.037-0.008-0.182-0.151-0.197c-0.152-0.015-0.175,0.022-0.473,0.053c-0.297,0.038-0.289,0.076-0.594,0.092
        c-0.313,0.022-0.335-0.023-0.512,0C56.785,3.532,56.656,3.593,56.656,3.593z M55.247,3.89c0,0-0.189,0.016-0.091,0.099
        c0.099,0.084,0.137,0.107,0.235,0.107c0.092,0,0.151-0.039,0.229-0.069c0.067-0.038,0.183-0.167,0.044-0.198
        C55.529,3.799,55.247,3.89,55.247,3.89z M56.177,3.928c0,0-0.236,0.03-0.282,0.091c-0.053,0.061-0.197,0.122-0.167,0.176
        c0.021,0.053,0.021,0.076,0.144,0.09c0.129,0.009,0,0.046,0.244,0.054c0.252,0.007,0.609-0.008,0.7-0.031
        c0.092-0.023,0.214-0.091,0.412-0.091C57.425,4.209,57.5,4.179,57.5,4.111c-0.006-0.069,0.047-0.122,0.153-0.138
        c0.1-0.007,0.654-0.007,0.449-0.098c-0.197-0.084-0.16-0.13-0.457-0.046c-0.289,0.084-0.586,0.046-0.769,0.046
        c-0.176,0-0.449-0.015-0.504,0.015C56.321,3.913,56.177,3.928,56.177,3.928z M54.258,4.636c0,0-0.396,0.008-0.32,0.069
        c0.069,0.061,0.161,0.068,0.282,0.068c0.129,0,0.259-0.022,0.381-0.031c0.114-0.015,0.228-0.144,0.121-0.152
        C54.616,4.583,54.258,4.636,54.258,4.636z M51.158,5.869c-0.045-0.007-0.35-0.053-0.228-0.114
        c0.129-0.06-0.017,0.024,0.274-0.083c0.289-0.107,0.532-0.244,0.715-0.29c0.183-0.045,0.183-0.106,0.396-0.19
        c0.205-0.084,0.418-0.13,0.578-0.13c0.152,0,0.282-0.121,0.441-0.136c0.168-0.008,0.289-0.016,0.427-0.008
        c0.137,0.008,0.175,0.046,0.183,0.122c0.008,0.083,0.426,0.06,0.609,0.099c0.183,0.045,0.214,0.106,0.198,0.183
        c-0.009,0.076,0.022,0.167,0.145,0.197c0.121,0.031,1.271,0.138,1.135-0.014c-0.138-0.161-0.184-0.206-0.084-0.252
        c0.106-0.046,0.068-0.198,0.091-0.259c0.016-0.061,0.16-0.007,0.427-0.076c0.274-0.076,0.419-0.206,0.556-0.229
        c0.137-0.015,0.221-0.121,0.267,0c0.038,0.114,0.008,0.145-0.114,0.275c-0.121,0.129-0.137,0.182-0.215,0.22
        c-0.074,0.039-0.151,0.138-0.036,0.153c0.114,0.007,0.158,0,0.313,0.045c0.159,0.046,0.235-0.045,0.343-0.076
        c0.105-0.023,0.327-0.083,0.448-0.03c0.122,0.046,0.221,0.122,0.106,0.175c-0.114,0.054-0.327,0.122-0.54,0.274
        c-0.222,0.153-0.419,0.191-0.746,0.237c-0.32,0.045-0.763,0.037-0.944,0.029c-0.183-0.015-0.145-0.022-0.518-0.007
        c-0.381,0.007-0.67,0.06-1.112,0.168c-0.448,0.106-0.845,0.197-1.202,0.213c-0.358,0.022-0.625,0.045-0.754,0.022
        c-0.122-0.03-0.336-0.16-0.206-0.213c0.13-0.046,0.084,0,0.365-0.053c0.282-0.053,0.273-0.13,0.524-0.122
        c0.253,0.008,0.443,0.008,0.512-0.023c0.068-0.038,0.205-0.167,0.015-0.16c-0.19,0-0.426,0.053-0.532,0.053
        s-0.078-0.075-0.229-0.09c-0.151-0.016-0.151-0.024-0.396,0.083c-0.251,0.107-0.189,0.091-0.373,0.068
        c-0.183-0.03-0.221-0.084-0.396-0.03c-0.175,0.053-0.137,0.069-0.236,0.03C51.258,5.9,51.158,5.869,51.158,5.869z M50.488,5.473
        c0,0-0.267,0.016-0.167,0.084c0.099,0.077,0.289,0.069,0.457,0.046c0.167-0.023,0.38-0.175,0.495-0.213
        c0.121-0.038,0.448-0.084,0.403-0.145c-0.046-0.053-0.122-0.069-0.305-0.007c-0.175,0.053-0.487,0.091-0.602,0.129
        C50.664,5.413,50.488,5.473,50.488,5.473z M52.134,4.392c0,0-0.746,0.038-1.136,0.213c-0.388,0.176-0.753,0.115-0.981,0.237
        c-0.235,0.122-0.479,0.182-0.662,0.175c-0.184-0.008-0.594,0.053-0.465,0.167c0.137,0.115,0.235,0.023,0.524,0.069
        c0.283,0.038,0.435,0.061,0.603,0.038c0.168-0.031,0.229,0.053,0.487,0.015c0.251-0.03,0.556-0.35,0.64-0.258
        c0.076,0.091,0.137,0.144,0.259,0.06c0.214-0.091,0.487-0.274,0.609-0.243c0.122,0.03-0.031,0.22,0.159,0.205
        c0.19-0.022,0.465-0.205,0.571-0.236c0.106-0.038,0.313,0.061,0.464,0c0.161-0.068,0.184-0.206,0.29-0.266
        c0.1-0.061,0.016-0.168,0.244-0.183c0.219-0.016,0.608-0.1,0.373-0.198c-0.236-0.091-0.313,0-0.595,0.061
        c-0.273,0.06-0.351-0.008-0.7,0.015C52.469,4.285,52.134,4.392,52.134,4.392z M46.529,8.389c0,0,0-0.129,0.282-0.197
        c0.281-0.061,0.327-0.084,0.479-0.099c0.153-0.023,0.251-0.069,0.268-0.144c0.007-0.069-0.084-0.069,0.099-0.146
        c0.175-0.076,1.097-0.235,1.401-0.334c0.304-0.1,0.975-0.221,1.164-0.252c0.19-0.022,0.457-0.106,0.549-0.068
        c0.099,0.03,0.114,0.113,0.068,0.175c-0.038,0.061-0.327,0.137-0.396,0.19c-0.07,0.054-0.244,0.153-0.047,0.168
        c0.191,0.023,0.214,0.015,0.328-0.077c0.114-0.098,0.318-0.159,0.44-0.159c0.123,0,0.101-0.061,0.251,0
        c0.162,0.053,0.39,0.122,0.489,0.152c0.099,0.023,0.007,0.114,0,0.175c0,0.053,0.052,0.084,0.175,0.038
        c0.129-0.053,0.175-0.084,0.289-0.069c0.122,0.024,0.206,0.13,0.221,0.009c0.016-0.123-0.221-0.313-0.037-0.336
        c0.175-0.03,0.335-0.076,0.447,0.023c0.115,0.092,0.199,0.122,0.229,0.221c0.023,0.099,0.038,0.183-0.03,0.259
        C53.122,8,52.849,8.245,52.994,8.337c0.151,0.084,0.151,0.045,0.289-0.032c0.145-0.067,0.145-0.06,0.229-0.243
        c0.084-0.19,0.113-0.175,0.205-0.251c0.092-0.076,0.183-0.388,0.427-0.487c0.244-0.092,0.105-0.145,0.305-0.129
        c0.19,0.015,0.168,0.007,0.313,0.06c0.151,0.053,0.045-0.106,0.28,0.007c0.237,0.116,0.306,0.153,0.328,0.237
        c0.022,0.091-0.03,0.213-0.152,0.381c-0.129,0.168-0.243,0.509-0.243,0.632c0,0.121-0.289,0.213-0.366,0.282
        c-0.075,0.068-0.335,0.265-0.121,0.38c0.205,0.114,0.312,0.076,0.571,0.175c0.267,0.107,0.221,0.16,0.434,0.206
        c0.206,0.045,0.449-0.123,0.305,0.084c-0.152,0.198-0.281,0.319-0.457,0.311c-0.183-0.007-0.215-0.037-0.365-0.029
        c-0.16,0.007-0.176-0.123-0.306-0.054c-0.129,0.068-0.151,0.16-0.335,0.16c-0.182,0-0.304-0.092-0.326,0.023
        c-0.023,0.114,0.061,0.16,0.221,0.152c0.16-0.007,0.343-0.122,0.343,0.03c0,0.145-0.016,0.244-0.244,0.298
        c-0.221,0.045-0.457,0.03-0.64,0.098c-0.183,0.069-0.51,0.069-0.731-0.007c-0.228-0.077-0.479,0-0.563-0.016
        c-0.083-0.015,0.069-0.159-0.189-0.198c-0.251-0.045-0.267-0.106-0.434-0.129c-0.168-0.03-0.185,0.016-0.442,0.068
        c-0.259,0.061-0.502,0.1-1.233,0.297c-0.731,0.191-0.937,0.168-1.362,0.168c-0.427,0-1.371,0.19-1.493,0.069
        c-0.122-0.122,0.068-0.252,0.046-0.373c-0.015-0.122,0.038-0.1-0.313-0.183c-0.35-0.084-1.484-0.099-1.058-0.464
        c0.434-0.366,0.937-0.146,1.355-0.221c0.418-0.084,0.472-0.13,0.807-0.115c0.335,0.023,1.119,0.153,1.173,0.046
        C49.305,9.464,49.259,9.319,49,9.242c-0.251-0.075-0.305-0.137-0.814-0.068c-0.511,0.068-0.929,0.061-1.248,0.061
        c-0.313,0-0.533,0.099-0.595-0.053C46.282,9.037,46.16,8.9,46.29,8.862c0.128-0.046,0.518-0.251,0.837-0.198
        c0.32,0.053,0.724-0.084,0.586-0.137c-0.137-0.054-0.434,0.06-0.677,0.023c-0.236-0.038-0.389,0.061-0.45,0
        C46.515,8.489,46.529,8.389,46.529,8.389z M68.373,26.846c0,0-0.304,0.007-0.182,0.114c0.13,0.107,0.251,0,0.373,0.145
        c0.122,0.153,0.183,0.251,0.327,0.32c0.151,0.076,0.891,0.236,0.96,0.175c0.061-0.061-0.244-0.366-0.381-0.472
        c-0.139-0.115-0.541-0.304-0.716-0.312C68.587,26.808,68.373,26.846,68.373,26.846z M74.48,25.56
        c-0.091,0.022-0.648,0.373-0.846,0.616c-0.197,0.236-0.67,0.548-0.73,0.693c-0.061,0.137-0.19,0.297-0.32,0.427
        c-0.129,0.129-0.03,0.205-0.189,0.327c-0.16,0.121-0.26,0.053-0.32,0.159c-0.061,0.115-0.183,0.244-0.243,0.252
        c-0.062,0.007-0.236,0.045-0.145,0.099c0.091,0.061,0.221,0.054,0.152,0.137c-0.077,0.091-0.625,0.29-0.617,0.464
        c0.008,0.175-0.054,0.244,0.206,0.259c0.266,0.015,0.799-0.023,1.156,0.023c0.358,0.038,0.602-0.046,0.762-0.053
        c0.16-0.015,0.137-0.031,0.327,0.06c0.183,0.1,0.243,0.069,0.335,0.053c0.092-0.022,0.382-0.182,0.29-0.022
        c-0.092,0.16-0.259,0.106-0.335,0.213c-0.084,0.115-0.198,0.145-0.366,0.198c-0.167,0.054-0.335,0.138-0.152,0.183
        c0.176,0.046,0.282,0.03,0.411,0.015c0.138-0.023,0.152-0.023,0.298-0.175c0.136-0.16,0.145-0.258,0.304-0.266
        c0.168-0.008,0.29-0.023,0.343-0.13c0.054-0.099,0.122-0.289,0.198-0.228c0.083,0.06,0.122,0.259,0.045,0.342
        c-0.084,0.076-0.472,0.373-0.312,0.404c0.152,0.023,0.343-0.19,0.396-0.091c0.053,0.098-0.114,0.25-0.022,0.289
        c0.099,0.045,0.166,0.008,0.266,0.015c0.099,0.008,0.107,0.062,0.305-0.198c0.197-0.251,0.487-0.769,0.358-0.769
        c-0.13,0-0.412,0.313-0.396,0.114c0.008-0.205,0.343-0.41,0.267-0.449c-0.076-0.038-0.282,0.015-0.396,0.121
        c-0.113,0.107-0.38,0.298-0.273,0.084c0.106-0.205,0.327-0.517,0.541-0.548c0.221-0.037,0.41-0.182,0.212-0.22
        c-0.205-0.046-0.601,0-0.41-0.176c0.183-0.167,0.479-0.213,0.266-0.334c-0.213-0.123-0.197-0.214-0.388-0.206
        c-0.184,0.008-0.123-0.175-0.313-0.129c-0.19,0.038-0.175,0.145-0.352,0.174c-0.174,0.023-0.22-0.022-0.318-0.045
        c-0.092-0.03-0.229-0.16-0.046-0.312c0.183-0.16,0.092-0.343-0.075-0.297c-0.168,0.045-0.526,0.495-0.52,0.252
        c0.009-0.252,0.185-0.457,0.404-0.564c0.221-0.107,0.404-0.236,0.487-0.32c0.084-0.076,0.092-0.099,0.084-0.251
        c0-0.16,0.053-0.252,0.122-0.19c0.076,0.06,0.402-0.069,0.282-0.153c-0.123-0.091-0.19-0.077-0.32-0.038
        C74.762,25.407,74.48,25.56,74.48,25.56z M67.468,29.465c-0.03,0.008-0.313,0.206-0.243,0.282
        c0.068,0.084,0.106,0.129,0.106,0.198c0,0.069,0.121,0.091,0.289,0.205c0.16,0.115,0.152,0.146,0.259,0.16
        c0.106,0.015,0.114,0.047,0.259,0.077c0.145,0.022,0.176-0.016,0.313-0.107c0.129-0.084,0.457-0.19,0.251-0.266
        c-0.197-0.077-0.244-0.031-0.556-0.038c-0.313-0.016-0.411-0.108-0.495-0.145c-0.083-0.038-0.122-0.145-0.091-0.244
        C67.581,29.488,67.468,29.465,67.468,29.465z M38.618,12.615c-0.304,0.008-0.403,0.039-0.549,0.069
        c-0.144,0.03-0.433,0.153-0.319,0.205c0.122,0.061,0.16-0.038,0.344-0.022c0.175,0.008,0.266-0.053,0.312-0.053
        c0.046,0,0.092,0.037,0.274,0.037c0.175,0,0.274-0.129,0.411-0.083c0.129,0.046,0.175-0.091,0.365-0.091
        c0.19,0.007,0.465-0.039,0.525,0.084c0.06,0.128,0.023,0.197-0.145,0.189c-0.169-0.007-0.655-0.136-0.671-0.045
        c-0.015,0.091,0.198,0.189,0.084,0.244c-0.121,0.061-0.312,0.084-0.494,0.16c-0.183,0.076-0.146,0.205-0.382,0.25
        c-0.235,0.039-0.532,0.016-0.396,0.107c0.138,0.084,0.29,0.069,0.603,0.069c0.312,0,0.312-0.092,0.548-0.176
        c0.235-0.091,0.77-0.334,0.883-0.326c0.122,0.007,0.221,0.06,0.13,0.136c-0.101,0.084-0.298,0.099-0.396,0.161
        c-0.099,0.06-0.556,0.182-0.632,0.22c-0.068,0.046-0.099,0.137-0.008,0.137s0.632-0.229,0.906-0.281
        c0.281-0.047,0.578-0.023,0.609-0.168c0.03-0.137-0.008-0.228,0.137-0.259c0.137-0.03,0.198-0.16,0.503-0.114
        c0.297,0.038,0.28,0.152,0.525,0.076c0.243-0.076,0.084-0.107,0.327-0.236c0.244-0.129,0.73-0.381,0.479-0.396
        c-0.259-0.022-0.343,0.213-0.624,0.221c-0.274,0.008-0.99-0.069-0.86-0.183c0.129-0.107,0.198-0.144,0.389-0.167
        c0.19-0.031,0.556-0.183,0.281-0.259c-0.267-0.069-0.411,0.03-0.754,0.084c-0.343,0.045-0.708-0.039-0.967,0.136
        c-0.268,0.175-0.335,0.191-0.579,0.146C38.946,12.387,38.931,12.615,38.618,12.615z M40.689,15.593
        c-0.038,0.053-0.16,0.061-0.03,0.183c0.121,0.129,0.235,0.022,0.175,0.144c-0.054,0.114-0.068,0.213-0.045,0.32
        c0.022,0.106,0.068,0.122-0.023,0.167c-0.1,0.046-0.388-0.031-0.549,0.076c-0.159,0.114-0.197,0.099-0.38,0.237
        c-0.176,0.128-0.206,0.235-0.473,0.159c-0.259-0.076-0.213-0.159-0.35-0.068c-0.137,0.092-0.236,0.121-0.313,0.121
        c-0.069-0.007-0.251-0.258-0.281-0.106c-0.022,0.144,0,0.236,0.167,0.236c0.16,0,0.326-0.114,0.442,0.016
        c0.113,0.129,0.015,0.121,0.304,0.136c0.289,0.007,0.647-0.076,0.914-0.076c0.259,0,0.448,0.054,0.632-0.114
        c0.183-0.167-0.068-0.221,0.357-0.327c0.435-0.107,0.503-0.046,0.784-0.091c0.282-0.047,0.366-0.108,0.609-0.221
        c0.242-0.115,0.762-0.496,1.035-0.473c0.268,0.023,0.222-0.144,0.404-0.182c0.175-0.031,0.494-0.092,0.639-0.061
        c0.146,0.023,0.419-0.107,0.222-0.19c-0.205-0.076-0.616-0.13-0.982-0.038c-0.366,0.091-0.662,0.342-1.097,0.487
        c-0.434,0.145-0.791,0.342-1.059,0.258c-0.273-0.091-0.099-0.242-0.312-0.266c-0.213-0.031-0.267,0.122-0.397-0.06
        c-0.128-0.176-0.212-0.282-0.272-0.298C40.758,15.539,40.689,15.593,40.689,15.593z M46.561,22.803
        c-0.008,0.031-0.107,0.274-0.29,0.35c-0.19,0.084-0.389,0.039-0.495,0.137c-0.107,0.099-0.275,0.214-0.365,0.335
        c-0.084,0.115-0.343,0.237-0.213,0.335c0.129,0.1,0.297-0.068,0.167,0.1c-0.121,0.167-0.236,0.175-0.167,0.296
        c0.061,0.129,0.129,0.107,0.121,0.252c-0.015,0.136,0.092,0.144,0.062,0.236c-0.03,0.091-0.146,0.197,0,0.182
        c0.137-0.015,0.221-0.244,0.288-0.175c0.077,0.069,0.047,0.168,0.038,0.259c-0.015,0.091-0.136,0.191-0.105,0.29
        c0.022,0.098,0.206,0.03,0.206,0.098c0,0.077-0.168,0.122-0.252,0.275c-0.084,0.152-0.381,0.494-0.243,0.548
        c0.137,0.053,0.272,0.114,0.319-0.054c0.046-0.159,0.106-0.107,0.198-0.22c0.092-0.122,0.206-0.351,0.243-0.48
        c0.031-0.13,0.038-0.327,0.084-0.563c0.046-0.229,0.099-0.351,0.122-0.648c0.022-0.289,0.106-0.343,0.145-0.533
        c0.038-0.183-0.022-0.243,0-0.388c0.015-0.137,0.114-0.304,0.152-0.358c0.038-0.053,0.214-0.206,0.145-0.282
        C46.659,22.72,46.561,22.803,46.561,22.803z"/>
        <path fill="#808184" d="M7.355,16.613c0,0-0.403,0.388-0.335,0.137
        c0.061-0.26-0.099-0.465,0.198-0.572c0.297-0.107,0.487-0.258,0.997-0.419c0.518-0.159,0.762-0.403,1.173-0.487
        c0.419-0.076,0.609-0.365,0.838-0.349c0.229,0.014,0.113,0.174,0.472,0.19c0.365,0.015,0.594-0.313,1.065-0.351
        c0.473-0.038,0.289,0.106,0.838-0.068c0.556-0.176,0.746-0.419,0.815-0.58c0.067-0.159,0.037-0.174,0.334-0.243
        c0.297-0.068,0.511-0.335,0.328-0.35c-0.19-0.015-0.435,0.175-0.679,0.229c-0.243,0.053-0.502,0.106-0.677,0.175
        c-0.176,0.068-0.19,0.16-0.487,0.053c-0.297-0.107-0.374-0.137-0.579-0.107c-0.197,0.031-0.403,0.069-0.632,0.069
        s-0.579,0.023-0.541-0.122c0.039-0.152,0.152-0.206,0.298-0.335c0.152-0.137,0.563-0.046,0.365-0.236
        c-0.206-0.183-0.701-0.076-0.525-0.297c0.175-0.213,0.495-0.114,0.845-0.198c0.358-0.083,0.8-0.229,1.165-0.259
        c0.366-0.023,0.351-0.197,0.769-0.252c0.419-0.06,1.014-0.167,0.976-0.037c-0.03,0.129-0.519,0.251-0.358,0.358
        c0.168,0.099,0.487,0.076,0.487,0.076s0.403,0.076,0.548-0.031c0.153-0.098,0.252-0.22,0.419-0.198
        c0.16,0.023,0.838,0.061,0.738-0.122c-0.099-0.182-0.486,0.031-0.502-0.129c-0.022-0.16,0.145-0.274,0-0.327
        c-0.138-0.047-0.283,0.076-0.464,0.061c-0.183-0.008-0.305-0.198-0.114-0.366c0.189-0.16,0.189-0.305,0.091-0.388
        c-0.106-0.084-0.229-0.069-0.32-0.145c-0.084-0.068-0.175-0.213-0.053-0.251c0.122-0.046,0.686-0.396,0.997-0.381
        c0.313,0.023,0.472,0.16,0.99,0.031c1.432-0.35,3.7-1.531,5.269-1.531c0.419,0,0.686,0.046,0.968,0.016
        c0.289-0.024,1.104-0.411,1.271-0.343c0.168,0.069,0.184,0.198,0.016,0.266c-0.167,0.061-0.343,0.13-0.159,0.16
        c0.189,0.023,0.41-0.221,0.586-0.221c0.175,0,0.122,0.168,0.556,0.237c0.441,0.06,0.525-0.115,0.731-0.008
        c0.197,0.099-0.077,0.236,0.266,0.297c0.343,0.06,0.784,0,0.784,0c0.511-0.084,2.468,0.457,3,0.411
        c0.404-0.038,0.647-0.153,0.914-0.015c0.137,0.069,0.251,0.129,0.389,0.182c-0.868,0.624-2.231,1.637-3.541,2.742
        c-1.333,1.119-3.175,2.687-4.363,3.745c-0.364,0.32-0.662,0.595-0.875,0.793c-0.229,0.22-0.344,0.365-0.305,0.41v0.008
        c0.054,0.061,0.145,0.1,0.259,0.114c0.1,0.008,0.213,0,0.335-0.038c0.046-0.015,0.1-0.038,0.145-0.053
        c0.068-0.023,0.146-0.053,0.198-0.061c0.068-0.015,0.114-0.023,0.122,0.015c0.008,0.039-0.016,0.069-0.046,0.092
        c-0.053,0.053-0.1,0.099-0.053,0.213c0.022,0.045,0.045,0.083,0.061,0.122c0.046,0.084,0.084,0.152,0.084,0.259
        c0,0.06-0.023,0.114-0.038,0.152c-0.039,0.084-0.069,0.145-0.016,0.251c0.091,0.152,0.106,0.152,0.259,0.107l0.038-0.008
        c0.076-0.022,0.175-0.069,0.297-0.121c0.122-0.062,0.26-0.13,0.389-0.176c0.129-0.038,0.205-0.106,0.267-0.168
        c0.068-0.068,0.129-0.121,0.251-0.121c0.106,0,0.206-0.031,0.289-0.046c0.1-0.03,0.175-0.045,0.198-0.007
        c0.038,0.046,0.038,0.076,0.03,0.114c-0.008,0.046-0.015,0.091-0.008,0.152c0.008,0.084,0.053,0.129,0.092,0.176
        c0.038,0.045,0.061,0.076,0.022,0.121c-0.062,0.084-0.045,0.289-0.03,0.518c0.023,0.236,0.046,0.502-0.015,0.632
        c-0.069,0.137-0.122,0.251-0.146,0.35c-0.03,0.107-0.03,0.198-0.007,0.282c0.007,0.03-0.023,0.068-0.054,0.106
        c-0.076,0.092-0.152,0.176-0.016,0.313c0.146,0.152,0.32,0.228,0.396,0.266c0.017,0.007,0.031,0.015,0.031,0.015
        c0.023,0.008,0.053,0.054,0.068,0.114c0.008,0.038,0,0.092-0.022,0.146c-0.03,0.084-0.1,0.13-0.16,0.183
        c-0.062,0.038-0.114,0.083-0.16,0.144c-0.053,0.077-0.145,0.161-0.259,0.236c-0.099,0.077-0.213,0.137-0.327,0.183
        c-0.068,0.031-0.106,0.038-0.137,0.054c-0.13,0.052-0.268,0.099-0.373,0.151c-0.047-0.09-0.092-0.167-0.047-0.229
        c0.084-0.105-0.091-0.068-0.129-0.182c-0.038-0.114,0.289-0.328,0.138-0.487c-0.16-0.168-0.222-0.252-0.213-0.343
        c0.007-0.084,0.091-0.221-0.077-0.176c-0.176,0.046-0.243,0.198-0.159,0.335c0.091,0.145,0.076,0.305,0.061,0.412
        c-0.016,0.099-0.084,0.229-0.13,0.365c-0.038,0.13-0.137,0.32-0.281,0.267c-0.137-0.053-0.068-0.312-0.198-0.267
        c-0.122,0.053-0.297,0.198-0.351,0.069c-0.053-0.13,0.145-0.434,0.244-0.442c0.106-0.008,0.129-0.031,0.251-0.267
        c0.122-0.235-0.137-0.121-0.007-0.418c0.129-0.297,0.167-0.358,0.327-0.442c0.152-0.091,0.221-0.084,0.487-0.213
        c0.273-0.129,0.563-0.266,0.411-0.312c-0.145-0.054-0.236-0.046-0.518,0.13c-0.282,0.175-0.077-0.047-0.404,0.175
        c-0.327,0.213-0.41,0.533-0.593,0.639c-0.184,0.099-0.45,0.365-0.328-0.046c0.121-0.411,0.328-0.502,0.244-0.654
        c-0.076-0.161-0.092-0.114,0.022-0.335c0.114-0.222-0.016-0.32,0.205-0.457c0.213-0.138,0.48-0.145,0.351-0.214
        c-0.13-0.06-0.281,0.076-0.434-0.06c-0.152-0.145-0.19-0.237-0.244-0.419c-0.054-0.183,0.03-0.266-0.151-0.327
        c-0.184-0.062-0.298-0.145-0.252-0.244c0.046-0.099,0.35-0.236,0.007-0.213c-0.342,0.015-0.35,0.121-0.692,0
        c-0.335-0.122-0.267-0.259-0.762-0.267c-0.486-0.007-0.677,0.138-0.951-0.015c-0.267-0.145-0.281-0.281-0.479-0.244
        c-0.206,0.031-0.244-0.076-0.352-0.198c-0.098-0.121-0.372-0.411-0.897-0.357c-0.532,0.053-0.594,0.236-0.678,0.35
        c-0.091,0.114-0.183,0.266-0.19,0.373c-0.008,0.106-0.084,0.106-0.335,0.137c-0.251,0.038-0.578,0.13-0.761,0.099
        c-0.184-0.039-0.328-0.015-0.412,0.084c-0.075,0.106-0.487,0.106-0.768,0.213c-0.275,0.107-0.488,0.213-0.725,0.243
        c-0.236,0.023-0.61-0.038-0.426-0.114c0.183-0.084,0.44-0.159,0.571-0.349c0.136-0.184-0.03-0.123,0.403-0.282
        c0.441-0.153,0.7-0.427,0.799-0.488c0.1-0.061,0.167-0.076,0.518-0.16c0.351-0.076,0.64,0.092,0.633-0.137
        c-0.016-0.236-0.054-0.297-0.366-0.16c-0.312,0.137-0.524,0.259-0.845,0.365c-0.319,0.107-0.541,0.153-1.051,0.373
        c-0.519,0.221-0.692,0.351-1.036,0.549c-0.342,0.205-0.289,0.145-0.632,0.236c-0.342,0.099-0.631,0.062-0.578,0.206
        c0.046,0.152,0.351,0.205,0.076,0.334c-0.267,0.137-0.549,0.063-0.791,0.221c-0.244,0.152-0.29,0.297-0.572,0.373
        c-0.273,0.084-0.175-0.007-0.571,0.107c-0.396,0.114-1.469,0.457-2.094,0.845c-0.616,0.396-0.715,0.206-1.226,0.327
        c-0.51,0.123-0.799,0.245-0.944,0.373c-0.151,0.138-0.449,0.343-0.822,0.343c-0.381,0-0.563,0.198-1.043,0.252
        c-0.479,0.045-0.549-0.13-0.821,0.037c-0.268,0.168-0.496,0.206-0.731,0.328c-0.236,0.129-0.282,0.114-0.45,0.076
        c-0.167-0.03-0.364-0.114-0.486,0c-0.123,0.114-0.19,0.16-0.335,0.259c-0.152,0.091-0.396,0.106-0.617,0.106
        c-0.212,0-0.365,0.252-0.503,0.13c-0.137-0.122,0.198-0.281,0.48-0.365c0.273-0.092,0.35-0.198,0.579-0.198
        c0.228,0,0.289-0.061,0.608-0.13c0.312-0.061,0.533-0.045,1.013-0.342c0.479-0.298,0.937-0.351,1.18-0.351
        c0.244,0,0.221,0.168,0.471,0.016c0.253-0.153,0.078-0.099,0.436-0.229c0.365-0.137,1.005-0.404,1.355-0.464
        c0.351-0.061,0.176-0.221,0.594-0.275c0.427-0.053,0.838-0.319,1.135-0.448c0.289-0.137,0.632-0.428,0.807-0.527
        c0.174-0.106,0.465-0.197,0.511-0.274c0.046-0.075,0.137-0.235,0.022-0.22c-0.113,0.015-0.197,0.175-0.381,0.121
        c-0.183-0.052-0.121-0.144-0.251-0.129c-0.137,0.016-0.19,0.054-0.319,0.161c-0.13,0.114-0.206,0.212-0.267,0.098
        c-0.062-0.114,0.046-0.327-0.092-0.327c-0.137,0-0.152,0.099-0.304,0.007c-0.152-0.083-0.199-0.106-0.313-0.023
        c-0.114,0.092-0.244,0.184-0.427,0.191c-0.175,0.008-0.382,0.168-0.549,0.106c-0.167-0.06-0.22-0.159-0.075-0.19
        c0.145-0.038,0.327-0.03,0.373-0.175c0.046-0.152,0.312-0.16,0.548-0.441c0.229-0.282,0.533-0.487,0.274-0.495
        c-0.267,0-0.563,0.213-0.86,0.235c-0.297,0.031-0.762,0.176-0.602-0.061c0.152-0.228,0.213-0.334,0.152-0.402
        c-0.061-0.069-0.106-0.237,0.015-0.282c0.122-0.046,0.435-0.191,0.556-0.176c0.122,0.015,0.541,0.244,0.503,0.046
        c-0.038-0.19-0.259-0.084-0.335-0.19c-0.069-0.1,0.092-0.244,0.022-0.244C7.394,16.559,7.355,16.613,7.355,16.613 M5.071,17.488
        c0,0-0.351-0.022-0.351,0.099c0,0.122,0.16,0.252,0.335,0.304c0.168,0.046,0.305-0.121,0.525-0.175
        c0.214-0.061,0.396-0.22,0.343-0.304c-0.053-0.091-0.145-0.228-0.38-0.122C5.308,17.389,5.071,17.488,5.071,17.488"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M7.355,16.613
        c0,0-0.403,0.388-0.335,0.137c0.061-0.26-0.099-0.465,0.198-0.572c0.297-0.107,0.487-0.258,0.997-0.419
        c0.518-0.159,0.762-0.403,1.173-0.487c0.419-0.076,0.609-0.365,0.838-0.349c0.229,0.014,0.113,0.174,0.472,0.19
        c0.365,0.015,0.594-0.313,1.065-0.351c0.473-0.038,0.289,0.106,0.838-0.068c0.556-0.176,0.746-0.419,0.815-0.58
        c0.067-0.159,0.037-0.174,0.334-0.243c0.297-0.068,0.511-0.335,0.328-0.35c-0.19-0.015-0.435,0.175-0.679,0.229
        c-0.243,0.053-0.502,0.106-0.677,0.175c-0.176,0.068-0.19,0.16-0.487,0.053c-0.297-0.107-0.374-0.137-0.579-0.107
        c-0.197,0.031-0.403,0.069-0.632,0.069s-0.579,0.023-0.541-0.122c0.039-0.152,0.152-0.206,0.298-0.335
        c0.152-0.137,0.563-0.046,0.365-0.236c-0.206-0.183-0.701-0.076-0.525-0.297c0.175-0.213,0.495-0.114,0.845-0.198
        c0.358-0.083,0.8-0.229,1.165-0.259c0.366-0.023,0.351-0.197,0.769-0.252c0.419-0.06,1.014-0.167,0.976-0.037
        c-0.03,0.129-0.519,0.251-0.358,0.358c0.168,0.099,0.487,0.076,0.487,0.076s0.403,0.076,0.548-0.031
        c0.153-0.098,0.252-0.22,0.419-0.198c0.16,0.023,0.838,0.061,0.738-0.122c-0.099-0.182-0.486,0.031-0.502-0.129
        c-0.022-0.16,0.145-0.274,0-0.327c-0.138-0.047-0.283,0.076-0.464,0.061c-0.183-0.008-0.305-0.198-0.114-0.366
        c0.189-0.16,0.189-0.305,0.091-0.388c-0.106-0.084-0.229-0.069-0.32-0.145c-0.084-0.068-0.175-0.213-0.053-0.251
        c0.122-0.046,0.686-0.396,0.997-0.381c0.313,0.023,0.472,0.16,0.99,0.031c1.432-0.35,3.7-1.531,5.269-1.531
        c0.419,0,0.686,0.046,0.968,0.016c0.289-0.024,1.104-0.411,1.271-0.343c0.168,0.069,0.184,0.198,0.016,0.266
        c-0.167,0.061-0.343,0.13-0.159,0.16c0.189,0.023,0.41-0.221,0.586-0.221c0.175,0,0.122,0.168,0.556,0.237
        c0.441,0.06,0.525-0.115,0.731-0.008c0.197,0.099-0.077,0.236,0.266,0.297c0.343,0.06,0.784,0,0.784,0
        c0.511-0.084,2.468,0.457,3,0.411c0.404-0.038,0.647-0.153,0.914-0.015c0.137,0.069,0.251,0.129,0.389,0.182
        c-0.868,0.624-2.231,1.637-3.541,2.742c-1.333,1.119-3.175,2.687-4.363,3.745c-0.364,0.32-0.662,0.595-0.875,0.793
        c-0.229,0.22-0.344,0.365-0.305,0.41v0.008c0.054,0.061,0.145,0.1,0.259,0.114c0.1,0.008,0.213,0,0.335-0.038
        c0.046-0.015,0.1-0.038,0.145-0.053c0.068-0.023,0.146-0.053,0.198-0.061c0.068-0.015,0.114-0.023,0.122,0.015
        c0.008,0.039-0.016,0.069-0.046,0.092c-0.053,0.053-0.1,0.099-0.053,0.213c0.022,0.045,0.045,0.083,0.061,0.122
        c0.046,0.084,0.084,0.152,0.084,0.259c0,0.06-0.023,0.114-0.038,0.152c-0.039,0.084-0.069,0.145-0.016,0.251
        c0.091,0.152,0.106,0.152,0.259,0.107l0.038-0.008c0.076-0.022,0.175-0.069,0.297-0.121c0.122-0.062,0.26-0.13,0.389-0.176
        c0.129-0.038,0.205-0.106,0.267-0.168c0.068-0.068,0.129-0.121,0.251-0.121c0.106,0,0.206-0.031,0.289-0.046
        c0.1-0.03,0.175-0.045,0.198-0.007c0.038,0.046,0.038,0.076,0.03,0.114c-0.008,0.046-0.015,0.091-0.008,0.152
        c0.008,0.084,0.053,0.129,0.092,0.176c0.038,0.045,0.061,0.076,0.022,0.121c-0.062,0.084-0.045,0.289-0.03,0.518
        c0.023,0.236,0.046,0.502-0.015,0.632c-0.069,0.137-0.122,0.251-0.146,0.35c-0.03,0.107-0.03,0.198-0.007,0.282
        c0.007,0.03-0.023,0.068-0.054,0.106c-0.076,0.092-0.152,0.176-0.016,0.313c0.146,0.152,0.32,0.228,0.396,0.266
        c0.017,0.007,0.031,0.015,0.031,0.015c0.023,0.008,0.053,0.054,0.068,0.114c0.008,0.038,0,0.092-0.022,0.146
        c-0.03,0.084-0.1,0.13-0.16,0.183c-0.062,0.038-0.114,0.083-0.16,0.144c-0.053,0.077-0.145,0.161-0.259,0.236
        c-0.099,0.077-0.213,0.137-0.327,0.183c-0.068,0.031-0.106,0.038-0.137,0.054c-0.13,0.052-0.268,0.099-0.373,0.151
        c-0.047-0.09-0.092-0.167-0.047-0.229c0.084-0.105-0.091-0.068-0.129-0.182c-0.038-0.114,0.289-0.328,0.138-0.487
        c-0.16-0.168-0.222-0.252-0.213-0.343c0.007-0.084,0.091-0.221-0.077-0.176c-0.176,0.046-0.243,0.198-0.159,0.335
        c0.091,0.145,0.076,0.305,0.061,0.412c-0.016,0.099-0.084,0.229-0.13,0.365c-0.038,0.13-0.137,0.32-0.281,0.267
        c-0.137-0.053-0.068-0.312-0.198-0.267c-0.122,0.053-0.297,0.198-0.351,0.069c-0.053-0.13,0.145-0.434,0.244-0.442
        c0.106-0.008,0.129-0.031,0.251-0.267c0.122-0.235-0.137-0.121-0.007-0.418c0.129-0.297,0.167-0.358,0.327-0.442
        c0.152-0.091,0.221-0.084,0.487-0.213c0.273-0.129,0.563-0.266,0.411-0.312c-0.145-0.054-0.236-0.046-0.518,0.13
        c-0.282,0.175-0.077-0.047-0.404,0.175c-0.327,0.213-0.41,0.533-0.593,0.639c-0.184,0.099-0.45,0.365-0.328-0.046
        c0.121-0.411,0.328-0.502,0.244-0.654c-0.076-0.161-0.092-0.114,0.022-0.335c0.114-0.222-0.016-0.32,0.205-0.457
        c0.213-0.138,0.48-0.145,0.351-0.214c-0.13-0.06-0.281,0.076-0.434-0.06c-0.152-0.145-0.19-0.237-0.244-0.419
        c-0.054-0.183,0.03-0.266-0.151-0.327c-0.184-0.062-0.298-0.145-0.252-0.244c0.046-0.099,0.35-0.236,0.007-0.213
        c-0.342,0.015-0.35,0.121-0.692,0c-0.335-0.122-0.267-0.259-0.762-0.267c-0.486-0.007-0.677,0.138-0.951-0.015
        c-0.267-0.145-0.281-0.281-0.479-0.244c-0.206,0.031-0.244-0.076-0.352-0.198c-0.098-0.121-0.372-0.411-0.897-0.357
        c-0.532,0.053-0.594,0.236-0.678,0.35c-0.091,0.114-0.183,0.266-0.19,0.373c-0.008,0.106-0.084,0.106-0.335,0.137
        c-0.251,0.038-0.578,0.13-0.761,0.099c-0.184-0.039-0.328-0.015-0.412,0.084c-0.075,0.106-0.487,0.106-0.768,0.213
        c-0.275,0.107-0.488,0.213-0.725,0.243c-0.236,0.023-0.61-0.038-0.426-0.114c0.183-0.084,0.44-0.159,0.571-0.349
        c0.136-0.184-0.03-0.123,0.403-0.282c0.441-0.153,0.7-0.427,0.799-0.488c0.1-0.061,0.167-0.076,0.518-0.16
        c0.351-0.076,0.64,0.092,0.633-0.137c-0.016-0.236-0.054-0.297-0.366-0.16c-0.312,0.137-0.524,0.259-0.845,0.365
        c-0.319,0.107-0.541,0.153-1.051,0.373c-0.519,0.221-0.692,0.351-1.036,0.549c-0.342,0.205-0.289,0.145-0.632,0.236
        c-0.342,0.099-0.631,0.062-0.578,0.206c0.046,0.152,0.351,0.205,0.076,0.334c-0.267,0.137-0.549,0.063-0.791,0.221
        c-0.244,0.152-0.29,0.297-0.572,0.373c-0.273,0.084-0.175-0.007-0.571,0.107c-0.396,0.114-1.469,0.457-2.094,0.845
        c-0.616,0.396-0.715,0.206-1.226,0.327c-0.51,0.123-0.799,0.245-0.944,0.373c-0.151,0.138-0.449,0.343-0.822,0.343
        c-0.381,0-0.563,0.198-1.043,0.252c-0.479,0.045-0.549-0.13-0.821,0.037c-0.268,0.168-0.496,0.206-0.731,0.328
        c-0.236,0.129-0.282,0.114-0.45,0.076c-0.167-0.03-0.364-0.114-0.486,0c-0.123,0.114-0.19,0.16-0.335,0.259
        c-0.152,0.091-0.396,0.106-0.617,0.106c-0.212,0-0.365,0.252-0.503,0.13c-0.137-0.122,0.198-0.281,0.48-0.365
        c0.273-0.092,0.35-0.198,0.579-0.198c0.228,0,0.289-0.061,0.608-0.13c0.312-0.061,0.533-0.045,1.013-0.342
        c0.479-0.298,0.937-0.351,1.18-0.351c0.244,0,0.221,0.168,0.471,0.016c0.253-0.153,0.078-0.099,0.436-0.229
        c0.365-0.137,1.005-0.404,1.355-0.464c0.351-0.061,0.176-0.221,0.594-0.275c0.427-0.053,0.838-0.319,1.135-0.448
        c0.289-0.137,0.632-0.428,0.807-0.527c0.174-0.106,0.465-0.197,0.511-0.274c0.046-0.075,0.137-0.235,0.022-0.22
        c-0.113,0.015-0.197,0.175-0.381,0.121c-0.183-0.052-0.121-0.144-0.251-0.129c-0.137,0.016-0.19,0.054-0.319,0.161
        c-0.13,0.114-0.206,0.212-0.267,0.098c-0.062-0.114,0.046-0.327-0.092-0.327c-0.137,0-0.152,0.099-0.304,0.007
        c-0.152-0.083-0.199-0.106-0.313-0.023c-0.114,0.092-0.244,0.184-0.427,0.191c-0.175,0.008-0.382,0.168-0.549,0.106
        c-0.167-0.06-0.22-0.159-0.075-0.19c0.145-0.038,0.327-0.03,0.373-0.175c0.046-0.152,0.312-0.16,0.548-0.441
        c0.229-0.282,0.533-0.487,0.274-0.495c-0.267,0-0.563,0.213-0.86,0.235c-0.297,0.031-0.762,0.176-0.602-0.061
        c0.152-0.228,0.213-0.334,0.152-0.402c-0.061-0.069-0.106-0.237,0.015-0.282c0.122-0.046,0.435-0.191,0.556-0.176
        c0.122,0.015,0.541,0.244,0.503,0.046c-0.038-0.19-0.259-0.084-0.335-0.19c-0.069-0.1,0.092-0.244,0.022-0.244
        C7.394,16.559,7.355,16.613,7.355,16.613z M5.071,17.488c0,0-0.351-0.022-0.351,0.099c0,0.122,0.16,0.252,0.335,0.304
        c0.168,0.046,0.305-0.121,0.525-0.175c0.214-0.061,0.396-0.22,0.343-0.304c-0.053-0.091-0.145-0.228-0.38-0.122
        C5.308,17.389,5.071,17.488,5.071,17.488z"/>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
             <path fill="#808184" d="M224.377,65.943c0,0-0.104,0.01-0.046,0.168
               c0.061,0.152,0.061,0.1-0.016,0.184c-0.068,0.084,0,0.129-0.068,0.244c-0.061,0.113-0.16,0.121-0.167,0.221
               c-0.015,0.105-0.138,0.213-0.13,0.32c0,0.098-0.037,0.219,0.091,0.074c0.131-0.145,0.146-0.189,0.224-0.242
               c0.073-0.062,0.188-0.068,0.213-0.197c0.021-0.123-0.121-0.213-0.031-0.321c0.1-0.112,0.13-0.166,0.1-0.25
               c-0.039-0.085-0.1-0.115-0.076-0.177c0.016-0.053,0.008-0.112-0.016-0.105C224.422,65.869,224.377,65.943,224.377,65.943"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M224.377,65.943c0,0-0.104,0.01-0.046,0.168c0.061,0.152,0.061,0.1-0.016,0.184c-0.068,0.084,0,0.129-0.068,0.244
               c-0.061,0.113-0.16,0.121-0.167,0.221c-0.015,0.105-0.138,0.213-0.13,0.32c0,0.098-0.037,0.219,0.091,0.074
               c0.131-0.145,0.146-0.189,0.224-0.242c0.073-0.062,0.188-0.068,0.213-0.197c0.021-0.123-0.121-0.213-0.031-0.321
               c0.1-0.112,0.13-0.166,0.1-0.25c-0.039-0.085-0.1-0.115-0.076-0.177c0.016-0.053,0.008-0.112-0.016-0.105
               C224.422,65.869,224.377,65.943,224.377,65.943z"/>
             <path fill="#808184" d="M240.084,103.016c-0.015,0-0.196,0.106-0.045,0.159
               c0.149,0.061,0.267-0.242,0.198-0.273C240.168,102.878,240.084,103.016,240.084,103.016"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M240.084,103.016c-0.015,0-0.196,0.106-0.045,0.159c0.149,0.061,0.267-0.242,0.198-0.273
               C240.168,102.878,240.084,103.016,240.084,103.016z"/>
             <path fill="#808184" d="M247.356,100.664c-0.047,0.037-0.16,0.235-0.018,0.244
               c0.146,0.016,0.336-0.365,0.229-0.39C247.461,100.496,247.356,100.664,247.356,100.664"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M247.356,100.664c-0.047,0.037-0.16,0.235-0.018,0.244c0.146,0.016,0.336-0.365,0.229-0.39
               C247.461,100.496,247.356,100.664,247.356,100.664z"/>
             <path fill="#808184" d="M192.041,68.228c-0.009,0-0.022,0.008-0.038,0.016
               c-0.039,0.021-0.107,0.076-0.076,0.213c0.03,0.184,0.137,0.412,0.184,0.533c0.052,0.121,0.297,0.441,0.494,0.609
               c0.197,0.158,0.084,0.15,0.289,0.318s0.168,0.022,0.373,0.16c0.213,0.145,0.479,0.654,0.625,0.77
               c0.151,0.105-0.152,0.281,0.092,0.479c0.25,0.207,0.563,0.176,0.691,0.313c0.129,0.146,0.334,0.104,0.289,0.28
               c-0.037,0.177,0.122,0.52,0.168,0.74c0.045,0.229-0.1,0.303,0.045,0.471c0.146,0.168,0.077,0.205,0.245,0.281
               c0.167,0.076,0.251-0.043,0.326,0.229c0.075,0.267,0.351,0.448,0.494,0.83c0.146,0.381-0.046,0.471,0.185,0.639
               c0.221,0.168,0.258,0.229,0.258,0.356c0,0.138-0.045,0.352,0.084,0.449c0.139,0.107,0.32,0.275,0.412,0.51
               c0.084,0.238,0.234,0.443,0.473,0.52c0.234,0.077,0.326,0.168,0.326,0.29c0,0.13,0.054,0.212,0.252,0.449
               c0.205,0.233,0.351,0.364,0.646,0.556c0.29,0.19,0.594,0.35,0.616,0.457c0.022,0.114,0.099,0.439,0.214,0.305
               c0.113-0.139,0.167-0.313,0.258-0.223c0.092,0.084,0.077,0.223,0.207,0.123c0.121-0.1,0.213-0.068,0.342,0.021
               c0.139,0.093,0.213,0.054,0.213-0.189c0-0.252-0.105-1.134,0.016-1.369c0.123-0.236,0.168-0.412,0.138-0.609
               c-0.038-0.205,0.123-0.205,0.084-0.357c-0.03-0.16-0.146-0.244-0.213-0.297c-0.069-0.055-0.168-0.328-0.244-0.336
               c-0.075-0.016-0.137,0.023-0.297-0.037c-0.151-0.055-0.411-0.077-0.495-0.436c-0.076-0.365,0.018-0.73-0.175-0.791
               c-0.188-0.055-0.356,0.016-0.449-0.047c-0.091-0.053-0.373-0.1-0.305-0.41c0.067-0.318,0.288-0.617,0.185-0.814
               c-0.117-0.189-0.132-0.273-0.32-0.318c-0.191-0.047-0.146,0-0.244-0.191c-0.105-0.197-0.215-0.204-0.364-0.326
               c-0.146-0.13-0.032-0.352-0.176-0.366c-0.146-0.007-0.252,0.039-0.343-0.007c-0.084-0.046-0.198,0.007-0.168-0.214
               c0.039-0.228-0.099-0.298-0.153-0.298c-0.06,0-0.128,0.138-0.25,0.039c-0.121-0.098-0.235-0.258-0.304-0.184
               c-0.069,0.086-0.055,0.297-0.221,0.145c-0.169-0.145-0.199-0.426-0.344-0.493c-0.139-0.069-0.139-0.069-0.184-0.229
               c-0.047-0.15-0.526-0.435-0.67-0.57c-0.139-0.145-0.223-0.121-0.319-0.213c-0.101-0.1-0.007-0.152-0.167-0.229
               c-0.168-0.069-0.29-0.022-0.299-0.151c0-0.138,0.022-0.258-0.053-0.343c-0.076-0.092-0.176-0.046-0.221-0.159
               c-0.056-0.123-0.13-0.281-0.214-0.344c-0.075-0.061-0.151-0.168-0.342-0.13c-0.183,0.03-0.306,0.008-0.397-0.022
               c-0.082-0.037-0.426,0.139-0.547,0c-0.115-0.145-0.441-0.426-0.548-0.44C192.024,68.16,192.041,68.228,192.041,68.228
                M224.492,78.621c-0.138,0.047-0.313-0.037-0.32,0.258c-0.009,0.291-0.151,0.406-0.055,0.504
               c0.092,0.107,0.252,0.268,0.319,0.123c0.063-0.146,0.03-0.396,0.076-0.459c0.047-0.06,0.176-0.416,0.114-0.448
               C224.574,78.567,224.492,78.621,224.492,78.621 M221.796,80.341c-0.122,0.092-0.176,0.189-0.244,0.289
               c-0.062,0.092-0.214,0.297-0.203,0.396c0.014,0.1-0.018,0.243,0.137,0.105c0.149-0.137,0.188-0.166,0.287-0.305
               c0.092-0.137,0.1-0.221,0.153-0.35c0.06-0.13,0.113-0.297,0.046-0.307C221.901,80.173,221.796,80.341,221.796,80.341
                M223.92,79.703c0,0.1-0.106,0.139,0.017,0.229c0.113,0.091,0.16,0.166,0.228,0.029c0.069-0.145,0.19-0.26,0.101-0.319
               c-0.092-0.065-0.252-0.335-0.313-0.244C223.89,79.49,223.92,79.703,223.92,79.703 M219.458,71.173
               c-0.045,0.008-0.137,0.139-0.176,0.191c-0.03,0.053-0.067,0.182-0.045,0.229c0.021,0.046-0.03,0.092,0.068,0.113
               c0.1,0.022,0.197-0.029,0.235-0.084c0.038-0.062,0.114-0.062,0.138-0.189c0.016-0.137,0.016-0.355-0.068-0.365
               C219.533,71.068,219.458,71.173,219.458,71.173 M218.751,74.835c0,0-0.152-0.029-0.146,0.123
               c0.017,0.16-0.029,0.197,0.078,0.197c0.105,0.008,0.135-0.1,0.281-0.055c0.137,0.047,0.235,0.063,0.221,0
               c-0.008-0.066-0.107-0.128-0.174-0.176c-0.076-0.045-0.131-0.157-0.177-0.172C218.788,74.746,218.751,74.835,218.751,74.835
                M218.394,73.826c-0.023,0.021-0.106,0.105-0.056,0.182c0.056,0.076,0.123,0.054,0.14,0.107
               c0.021,0.053-0.092,0.158-0.008,0.182c0.084,0.031,0.129-0.016,0.174-0.006c0.039,0.015,0.09,0,0.168,0.015
               c0.068,0.022,0.229,0.007,0.145-0.077c-0.091-0.083-0.174-0.105-0.184-0.167c-0.015-0.062,0.01-0.183-0.084-0.183
               c-0.09,0-0.183-0.017-0.188-0.063C218.483,73.778,218.394,73.826,218.394,73.826 M217.615,76.511
               c-0.075-0.016-0.32-0.037-0.243,0.113c0.076,0.16,0.129,0.307,0.236,0.383c0.105,0.074,0.166,0.113,0.313,0.113
               c0.137,0,0.229-0.092,0.313-0.131c0.084-0.029,0.252,0.047,0.183-0.129c-0.067-0.175-0.122-0.252-0.19-0.304
               c-0.076-0.062-0.213-0.132-0.272-0.132C217.89,76.427,217.615,76.511,217.615,76.511 M222.481,74.076
               c-0.092,0.076-0.069,0.168-0.268,0.189c-0.205,0.023-0.289-0.074-0.381,0.039c-0.084,0.121-0.068,0.318-0.122,0.342
               c-0.054,0.031-0.092,0.023-0.114-0.105c-0.029-0.138-0.037-0.319-0.168-0.268c-0.129,0.055-0.266-0.061-0.281,0.115
               c-0.015,0.174,0.105,0.113,0.168,0.213c0.062,0.091,0.021,0.259,0.151,0.305c0.13,0.047,0.214,0.021,0.29,0.047
               c0.076,0.014,0.335-0.033,0.465,0.09c0.121,0.123,0.061,0.207,0.129,0.32c0.068,0.12,0.029,0.326,0.244,0.326
               c0.213,0,0.327-0.158,0.532-0.09c0.198,0.066,0.213,0.09,0.435,0.082c0.229,0,0.357-0.184,0.365-0.021
               c0.016,0.152-0.008,0.281-0.175,0.258c-0.161-0.028-0.245-0.145-0.358-0.067c-0.121,0.084-0.1,0.112-0.242,0.213
               c-0.139,0.106-0.139,0.131-0.327,0.093c-0.191-0.031-0.474-0.017-0.427,0.09c0.052,0.106,0.381,0.125,0.373,0.198
               c-0.01,0.076,0.304,0.206,0.266,0.365c-0.029,0.159-0.046,0.159-0.053,0.306c-0.008,0.137,0.029,0.258,0.121,0.297
               c0.092,0.037,0.215,0.114,0.281-0.008c0.076-0.13,0.038-0.326,0.176-0.326c0.146,0,0.168-0.222,0.313-0.068
               c0.146,0.152,0.533,0.174,0.609,0.281c0.084,0.1,0.16,0.373,0.234,0.418c0.084,0.045,0.223,0.084,0.252,0.092
               c0.039,0.008,0.688,0.021,0.922,0.137c0.234,0.107,0.578,0.343,0.692,0.365c0.122,0.016,0.39,0.115,0.472,0.244
               c0.092,0.129,0.229,0.1,0.26,0.334c0.039,0.229,0.215,0.49,0.259,0.672c0.038,0.174,0.146,0.25,0.168,0.318
               c0.022,0.076-0.183,0.008-0.13,0.145c0.046,0.139,0.16,0.186,0.138,0.236c-0.022,0.055,0.028,0.146-0.114,0.176
               c-0.143,0.029-0.351,0.121-0.473,0.343c-0.121,0.222-0.364,0.519-0.273,0.608c0.084,0.084,0.022,0.055,0.364,0.029
               c0.336-0.021,0.345,0.184,0.58,0.018c0.235-0.168,0.235-0.344,0.474-0.237c0.241,0.101,0.517,0.047,0.606,0.267
               c0.101,0.223,0.306,0.396,0.336,0.48c0.022,0.083,0.158,0.206,0.158,0.206s0.101-1.265,0.114-1.577
               c0.01-0.318-0.09-0.678-0.045-0.807c0.045-0.131,0.176-0.313,0.184-0.434c0.015-0.121,0.061-3.298,0.061-3.298
               s-0.334-0.235-0.595-0.235c-0.258,0.008-0.448-0.017-0.585-0.129c-0.145-0.123-0.586-0.313-0.678-0.39
               c-0.092-0.069-0.268-0.19-0.51-0.214c-0.252-0.021-0.16-0.092-0.405,0.039c-0.234,0.138-0.357,0.029-0.364,0.258
               c0,0.223,0.021,0.236-0.105,0.251c-0.139,0.009-0.404,0.046-0.437,0.101c-0.036,0.061-0.296,0.19-0.342,0.319
               c-0.054,0.121-0.038,0.236-0.128,0.317c-0.084,0.078-0.375,0.307-0.458,0.345c-0.084,0.03-0.289,0.039-0.351-0.099
               c-0.062-0.137-0.068-0.129-0.152-0.291c-0.082-0.168-0.038-0.471-0.12-0.493c-0.085-0.022-0.07,0.221-0.13,0.25
               c-0.063,0.03-0.123-0.121-0.168-0.289c-0.055-0.166-0.069-0.304-0.022-0.531c0.046-0.223,0.138-0.207,0.099-0.358
               c-0.037-0.152-0.137-0.197-0.152-0.289c-0.008-0.092,0.168-0.32-0.021-0.342c-0.197-0.023-0.236,0.061-0.404,0.014
               c-0.166-0.045-0.305-0.189-0.502-0.26C222.961,73.91,222.692,73.9,222.481,74.076 M219.413,76.246
               c-0.055-0.008-0.161-0.017-0.223,0.062c-0.053,0.084-0.205,0.092-0.221,0.259c-0.007,0.168-0.099,0.273,0.039,0.221
               c0.137-0.053,0.122-0.305,0.213-0.221c0.092,0.084,0.26,0.114,0.205,0.184c-0.047,0.067-0.457,0.137-0.334,0.221
               c0.121,0.085,0.182,0.029,0.334,0.008c0.146-0.021,0.26-0.008,0.268-0.129c0.007-0.121,0.016-0.336,0.158-0.223
               c0.138,0.107,0.115,0.223,0.252,0.19c0.138-0.038,0.268-0.06,0.313-0.06c0.038,0,0.235,0.021,0.297,0.068
               c0.068,0.036,0.206,0.045,0.318,0.143c0.114,0.107,0.153,0.244,0.268,0.199c0.121-0.053,0.229-0.16,0.106-0.274
               c-0.122-0.114-0.137-0.136-0.184-0.243c-0.045-0.113-0.016-0.268-0.183-0.273c-0.168-0.006-0.229,0.016-0.366-0.068
               c-0.129-0.084-0.197-0.273-0.371-0.176c-0.178,0.092-0.275,0.26-0.367,0.189c-0.099-0.066-0.137-0.128-0.272-0.128
               C219.533,76.193,219.413,76.246,219.413,76.246 M220.724,75.218c-0.048,0.016-0.268,0.029-0.216,0.107
               c0.056,0.082,0.168,0.127,0.253,0.156c0.084,0.032,0.197,0.055,0.243-0.016c0.038-0.067,0.177-0.342,0.038-0.356
               C220.903,75.087,220.724,75.218,220.724,75.218 M219.017,71.524c0,0-0.099,0.063-0.145,0.168
               c-0.046,0.106-0.168,0.19-0.168,0.261c0,0.067,0.008,0.228-0.016,0.271c-0.021,0.046-0.084,0.169-0.084,0.244
               c-0.008,0.068,0.047,0.168,0.068,0.215c0.023,0.037,0.1,0.113,0.023,0.145c-0.077,0.021-0.1-0.093-0.139-0.047
               c-0.045,0.038-0.091,0.176-0.029,0.191c0.054,0.008,0.121-0.055,0.145,0.015c0.023,0.067,0.045,0.19,0.053,0.138
               c0.008-0.061,0.063,0.189,0.101,0.396c0.03,0.207,0.1,0.39,0.166,0.44c0.077,0.063,0.176,0.093,0.198,0.207
               c0.031,0.113,0.199,0.281,0.236,0.229c0.03-0.047-0.176-0.352-0.205-0.436c-0.031-0.084-0.221-0.213-0.205-0.41
               c0.008-0.189-0.068-0.48,0.067-0.457c0.13,0.021,0.222,0.158,0.319,0.176c0.106,0.021,0.297,0,0.39,0.053
               c0.083,0.055,0.381,0.075,0.149-0.037c-0.219-0.115-0.157-0.313-0.342-0.336c-0.175-0.031-0.287,0.016-0.287-0.092
               s0.09-0.15,0.174-0.229c0.084-0.068,0.16-0.068,0.184-0.26c0.023-0.183,0.1-0.318-0.023-0.312
               c-0.121,0.008-0.229,0.129-0.318,0.183c-0.092,0.061-0.139,0.1-0.161,0.19c-0.021,0.091-0.06,0.045-0.12,0.168
               c-0.063,0.114-0.113,0.183-0.16,0.136c-0.046-0.052-0.115-0.091,0-0.197c0.104-0.099,0.151-0.105,0.197-0.259
               c0.038-0.159-0.053-0.319-0.093-0.374c-0.028-0.052,0-0.136,0.047-0.22c0.046-0.077,0.151-0.183,0.114-0.213
               C219.115,71.44,219.017,71.524,219.017,71.524 M216.336,75.4c-0.037,0.018-0.272,0.167-0.317-0.008
               c-0.047-0.184,0.076-0.434,0.317-0.313c0.244,0.113,0.42,0.19,0.617,0.19c0.205,0,0.389-0.017,0.524-0.017
               c0.137,0,0.358,0,0.236,0.084c-0.115,0.078-0.229,0.016-0.252,0.115c-0.021,0.098,0.063,0.457-0.055,0.318
               c-0.113-0.137-0.066-0.318-0.182-0.35c-0.115-0.023-0.137-0.008-0.297-0.031c-0.166-0.021-0.389-0.061-0.427-0.053
               C216.458,75.354,216.336,75.4,216.336,75.4 M213.899,78.363c-0.008,0.016-0.092,0.053-0.037,0.113
               c0.053,0.068,0.074,0.145,0.113,0.184c0.037,0.045,0.061,0.129,0.084-0.009c0.021-0.129,0.037-0.373-0.015-0.38
               C213.99,78.263,213.899,78.363,213.899,78.363 M214.547,77.919c0,0-0.091,0.039-0.175,0.084
               c-0.084,0.054-0.145,0.16-0.077,0.213c0.069,0.055,0.093,0.039,0.039,0.123c-0.062,0.076-0.16,0.176-0.092,0.236
               c0.067,0.059,0.092,0.021,0.159,0.021c0.062-0.007,0.076,0.084,0.091,0.122c0.024,0.039,0.077,0.222,0.123,0.184
               c0.046-0.031,0.151-0.039,0.175-0.138c0.031-0.091,0.063-0.168,0.131-0.183c0.075-0.008,0.176-0.062,0.045-0.16
               c-0.137-0.098-0.129-0.084-0.092-0.221c0.047-0.137,0.047-0.1,0.092-0.168c0.047-0.061,0.047-0.166,0.023-0.221
               c-0.018-0.055-0.063-0.213-0.131-0.129c-0.076,0.084-0.105,0.25-0.168,0.259C214.64,77.944,214.547,77.919,214.547,77.919
                M211.958,75.242c0,0,0.023,0.137-0.084,0.234c-0.1,0.105-0.145,0.113-0.145,0.268c0,0.152-0.021,0.152-0.121,0.243
               c-0.107,0.093-0.207,0.022-0.191,0.167c0.023,0.139,0.282,0.047,0.152,0.223c-0.129,0.174-0.092,0.334-0.029,0.449
               c0.068,0.113,0.357,0.036,0.418,0.021c0.055-0.021,0.189-0.021,0.131,0.092c-0.056,0.113-0.113,0.15-0.031,0.234
               c0.076,0.084,0.152,0.183,0.076,0.396c-0.068,0.215-0.168,0.334-0.145,0.403c0.016,0.067,0.016,0.204-0.047,0.325
               c-0.068,0.123-0.078,0.238-0.053,0.32c0.03,0.084,0.016,0.107,0.092,0.176c0.067,0.062,0.052,0.115,0.182,0.082
               c0.13-0.021,0.107-0.082,0.236-0.082c0.139,0,0.168-0.022,0.213,0c0.055,0.022,0.146,0.031,0.168-0.047
               c0.016-0.067-0.145-0.267-0.145-0.34c-0.009-0.084,0.03-0.115,0.075-0.27c0.046-0.145,0.207-0.196,0.091-0.305
               c-0.113-0.105-0.045-0.396-0.037-0.556c0.008-0.151,0.121-0.204,0.106-0.327c-0.007-0.113-0.037-0.228-0.099-0.365
               c-0.053-0.129-0.222-0.219-0.047-0.334c0.185-0.115,0.297-0.396,0.465-0.223c0.168,0.184,0.207,0.131,0.151,0.338
               c-0.062,0.203-0.067,0.195-0.12,0.387c-0.008,0.189,0.242,0.549,0.357,0.586c0.113,0.039,0.242-0.062,0.184,0.105
               c-0.063,0.176-0.146,0.223-0.123,0.338c0.03,0.112,0.023,0.203,0.159,0.258c0.138,0.053,0.198,0.137,0.267,0.053
               c0.069-0.076,0.106-0.235,0.154-0.297c0.045-0.063,0.066-0.084,0.15-0.076s0.236-0.037,0.304-0.037
               c0.077,0,0.114-0.146,0.146-0.189c0.037-0.048,0.084,0,0.15-0.031c0.077-0.03,0.19-0.229,0.061-0.229
               c-0.127,0-0.182,0.037-0.258,0.1c-0.066,0.061-0.105,0.076-0.166-0.055c-0.055-0.121-0.123-0.222-0.168-0.258
               c-0.047-0.047-0.244-0.139-0.138-0.223c0.099-0.084,0.272-0.121,0.197-0.235c-0.083-0.122-0.175-0.084-0.222-0.235
               c-0.037-0.16,0-0.259-0.051-0.289c-0.056-0.031-0.268-0.26-0.3-0.343c-0.03-0.077-0.099-0.054-0.149-0.146
               c-0.055-0.1-0.215-0.227-0.178-0.268c0.047-0.045,0.139,0.016,0.207,0.047c0.062,0.021,0.113,0.03,0.152-0.062
               c0.027-0.101,0.066-0.146,0.166-0.151c0.102-0.018,0.113-0.031,0.206-0.093c0.099-0.067,0.157-0.19,0.305-0.356
               c0.138-0.176,0.175-0.236,0.229-0.244c0.061,0,0.183,0.008,0.25,0.037c0.07,0.031,0.26-0.045,0.253-0.166
               c-0.009-0.123-0.062-0.289-0.281-0.236c-0.222,0.054-0.299,0.076-0.366,0.146c-0.062,0.066-0.076,0.051-0.189,0.066
               c-0.125,0.018-0.114-0.021-0.229,0.046c-0.114,0.077-0.083,0.159-0.229,0.122c-0.145-0.045-0.127-0.15-0.242-0.105
               c-0.121,0.045-0.137,0.144-0.234,0.205c-0.1,0.053-0.152,0.092-0.223,0.183c-0.066,0.092-0.016,0.183-0.189,0.122
               c-0.175-0.061-0.221,0-0.259-0.152c-0.039-0.145,0.106-0.152-0.146-0.313c-0.26-0.168-0.281-0.168-0.26-0.267
               c0.031-0.106-0.113-0.524,0.076-0.692c0.184-0.168,0.297-0.367,0.336-0.373c0.037-0.008,0.146,0.115,0.313,0.115
               c0.168,0.006,0.352-0.047,0.556-0.068c0.198-0.024,0.365-0.024,0.494,0.006c0.138,0.039,0.366-0.083,0.542-0.03
               c0.184,0.046,0.152,0.093,0.313,0.152c0.151,0.063,0.267,0.03,0.403,0.022c0.136,0,0.466,0.031,0.615-0.146
               c0.146-0.175,0.161-0.365,0.252-0.417c0.101-0.063,0.267-0.313,0.297-0.396c0.03-0.076,0.168-0.16,0.084-0.205
               c-0.084-0.047-0.146-0.168-0.272-0.084c-0.123,0.09-0.153,0.252-0.268,0.336c-0.121,0.084-0.1,0.105-0.236,0.244
               c-0.137,0.137-0.099,0.174-0.342,0.174c-0.25,0-0.313,0.008-0.418-0.031c-0.115-0.045-0.16-0.12-0.275-0.045
               c-0.114,0.068-0.075,0.152-0.221,0.076c-0.145-0.076-0.152-0.168-0.242-0.168c-0.084,0-0.414-0.121-0.558-0.076
               c-0.153,0.039-0.268,0.061-0.288,0.023c-0.031-0.039-0.1-0.121-0.223-0.16c-0.129-0.031-0.305-0.101-0.344,0
               c-0.045,0.092,0.023,0.137-0.053,0.221c-0.076,0.093-0.092,0.213-0.189,0.183c-0.092-0.022-0.037-0.159-0.15-0.075
               c-0.115,0.084,0,0.114-0.132,0.182c-0.129,0.076-0.129,0.199-0.188,0.352c-0.063,0.146-0.017,0.197-0.078,0.289
               c-0.051,0.084-0.166,0.068-0.105,0.16c0.063,0.084,0.105,0.197,0.086,0.221c-0.017,0.031,0.006,0.299-0.102,0.381
               c-0.105,0.084-0.151,0.197-0.176,0.289c-0.021,0.094-0.121,0.063-0.121,0.229C211.905,74.966,211.958,75.242,211.958,75.242
                M203.683,71.73c0,0-0.039,0.039-0.138,0.197c-0.101,0.169-0.235,0.107-0.159,0.307c0.084,0.197-0.023,0.268-0.084,0.434
               c-0.068,0.166-0.092,0.244,0.016,0.473c0.099,0.229,0.16,0.206,0.236,0.281c0.067,0.076,0.037,0.168-0.031,0.272
               c-0.067,0.106-0.166,0.222-0.092,0.321c0.084,0.105,0.32,0.021,0.274,0.158c-0.054,0.138-0.213,0.207-0.084,0.26
               c0.13,0.055,0.28-0.045,0.235,0.063c-0.053,0.105-0.121,0.312,0,0.289c0.129-0.023,0.334-0.084,0.381,0.021
               c0.045,0.105,0.092,0.152,0.031,0.26c-0.069,0.1-0.207,0.176-0.092,0.281c0.104,0.099,0.129,0.168,0.129,0.304
               c0,0.146,0.022,0.229,0.039,0.351c0.014,0.115-0.16,0.222,0.028,0.297c0.192,0.078,0.252-0.075,0.327,0.009
               c0.076,0.084,0.152,0.198,0.268,0.168c0.114-0.031,0.327-0.177,0.504-0.161c0.182,0.023,0.221,0.197,0.258,0.26
               c0.037,0.053-0.047,0.344,0.129,0.268c0.175-0.068,0.205-0.19,0.289-0.106c0.084,0.075,0.16,0.122,0.244,0.03
               c0.084-0.084,0.281-0.365,0.449-0.313c0.176,0.045,0.268,0.029,0.336,0.121c0.067,0.084,0.029,0.243,0.121,0.235
               c0.092-0.007,0.342-0.037,0.426,0c0.092,0.039,0.176,0.075,0.207,0.245c0.037,0.158,0.067,0.313,0.166,0.313
               c0.106,0,0.885-0.459,0.991-0.396c0.099,0.068,0.045,0.32,0.12,0.268c0.084-0.063,0.092-0.037,0.191-0.223
               c0.104-0.174,0.104-0.355,0.021-0.463c-0.092-0.1-0.092-0.26-0.092-0.289c0.008-0.039,0.223-0.436,0.307-0.557
               c0.082-0.123-0.16-0.236-0.115-0.404c0.047-0.158-0.008-0.234,0.092-0.318c0.099-0.076,0.054-0.229,0.145-0.273
               c0.084-0.045,0.236,0.029,0.282-0.092c0.054-0.113,0.235-0.129,0.403-0.352c0.16-0.213,0.09-0.213,0.006-0.449
               c-0.082-0.234-0.271-0.105,0.033-0.531c0.304-0.436,0.051-0.617,0.281-0.609c0.219,0,0.242,0.152,0.447,0.123
               c0.205-0.032,0.503,0.035,0.419-0.184c-0.075-0.229-0.336-0.131-0.305-0.314c0.038-0.182,0.046-0.266-0.076-0.266
               c-0.12,0-0.267,0.022-0.343-0.047c-0.076-0.061-0.357-0.189-0.221-0.388c0.145-0.196,0.289-0.168,0.19-0.312
               c-0.092-0.146-0.19-0.153-0.297-0.313c-0.099-0.149-0.214-0.235-0.281-0.373c-0.069-0.144,0.082-0.114,0.067-0.206
               c-0.016-0.1-0.145-0.15-0.168-0.175c-0.022-0.029,0.13-0.037,0.229-0.099c0.092-0.061,0.131-0.107,0.038-0.229
               c-0.084-0.123-0.251-0.268-0.381-0.297c-0.129-0.021-0.502-0.055-0.67-0.063c-0.168,0-0.206,0.03-0.326,0.063
               c-0.123,0.037-0.252,0.068-0.274,0.136c-0.016,0.069-0.053,0.39-0.053,0.479c0,0.092,0.03,0.268,0,0.365
               c-0.031,0.1,0.021,0.235-0.093,0.26c-0.114,0.021-0.221,0.054-0.206,0.205c0.016,0.145,0.069,0.213-0.007,0.26
               c-0.076,0.053-0.206,0.045-0.222,0.152c-0.022,0.104-0.016,0.166-0.03,0.295c-0.015,0.139-0.113,0.131-0.121,0.215
               c0,0.092-0.045,0.197-0.091,0.229c-0.047,0.029-0.198,0.016-0.344,0.061c-0.137,0.047-0.205,0.016-0.274,0.115
               c-0.075,0.098-0.151,0.146-0.228,0.105c-0.076-0.037-0.123-0.066-0.222-0.129c-0.091-0.063-0.229-0.063-0.298-0.1
               c-0.066-0.031-0.113-0.151-0.205-0.105c-0.099,0.045-0.334-0.046-0.402,0.158c-0.068,0.207-0.045,0.281-0.152,0.289
               c-0.105,0.017-0.114,0.01-0.268,0.031c-0.151,0.022-0.289,0.008-0.396,0.008c-0.105,0-0.281,0.1-0.372,0.137
               c-0.083,0.031-0.067,0.092-0.177,0.039c-0.105-0.061-0.213-0.145-0.297-0.237c-0.076-0.083-0.241-0.213-0.303-0.267
               c-0.063-0.053-0.162-0.182-0.19-0.266c-0.023-0.084,0-0.306-0.062-0.328C203.735,71.677,203.683,71.73,203.683,71.73
                M216.177,81.979c-0.029,0-0.197-0.022-0.335,0.166c-0.138,0.19-0.259,0.131-0.388,0.215c-0.139,0.082-0.229,0.146-0.291,0.243
               c-0.061,0.091-0.113,0.214-0.113,0.32c0.008,0.105,0.008,0.197-0.076,0.205c-0.082,0.015-0.19,0.106-0.068,0.144
               c0.121,0.03,0.185,0.017,0.305,0c0.129-0.007,0.152-0.036,0.26-0.12c0.114-0.076,0.235-0.084,0.366-0.176
               c0.129-0.085,0.136-0.189,0.228-0.268c0.092-0.074,0.26-0.229,0.26-0.258c0.008-0.031-0.047-0.082-0.055-0.236
               c-0.006-0.152,0.008-0.213,0-0.235C216.252,81.957,216.177,81.979,216.177,81.979 M217.356,80.751
               c-0.03-0.008-0.176-0.021-0.252,0.047c-0.076,0.07-0.092,0.168-0.099,0.213c-0.009,0.055,0.015,0.16,0.104,0.063
               c0.1-0.107,0.19-0.189,0.238-0.146c0.045,0.039,0.129,0.131,0.196,0.076c0.077-0.053,0.029-0.121,0.106-0.145
               c0.084-0.018,0.229,0.01,0.234-0.063c0.01-0.067,0.039-0.196-0.158-0.213C217.54,80.562,217.356,80.751,217.356,80.751
                M216.177,81.195c0,0-0.229,0.008-0.32,0.047c-0.09,0.037-0.219,0.059-0.267,0.129c-0.036,0.062-0.157,0.189-0.021,0.189
               c0.144,0.007,0.234-0.137,0.332-0.114c0.101,0.022,0.314,0.017,0.429-0.022c0.12-0.039,0.204-0.252,0.129-0.291
               C216.381,81.095,216.177,81.195,216.177,81.195 M212.454,81.332c-0.115,0.03-0.253,0.092-0.313,0.152
               c-0.063,0.068-0.121,0.197-0.084,0.281c0.039,0.076-0.121,0.123,0.168,0.105c0.289-0.015,0.266-0.105,0.418-0.06
               c0.146,0.037,0.023,0.007,0.306,0.083c0.282,0.084,0.327,0.1,0.45,0.038c0.129-0.062,0.051-0.188,0.258-0.138
               c0.197,0.054,0.183,0.031,0.326-0.022c0.146-0.054,0.123-0.083,0.199-0.077c0.076,0,0.105,0.018,0.213-0.066
               c0.105-0.076,0.176-0.106,0.221-0.152c0.046-0.053,0.107-0.15,0.138-0.077c0.029,0.077-0.008,0.106,0.076,0.131
               c0.084,0.017,0.008,0.03,0.167,0.037c0.152,0.017,0.305-0.037,0.305-0.037s0.268-0.26,0.184-0.306
               c-0.084-0.045-0.289,0.063-0.351,0.068c-0.061,0.016-0.213,0.008-0.274-0.022c-0.061-0.028-0.159-0.175-0.26-0.138
               c-0.099,0.039-0.099,0.123-0.158,0.183c-0.063,0.063-0.106,0.032-0.137,0.108c-0.039,0.066-0.01,0.158-0.139,0.174
               c-0.139,0.016-0.176-0.067-0.212-0.105c-0.047-0.029-0.106-0.061-0.168-0.029c-0.056,0.037-0.121,0.053-0.253,0.074
               c-0.135,0.023-0.135,0.076-0.288-0.021c-0.144-0.1-0.113-0.107-0.274-0.176c-0.15-0.068-0.25-0.084-0.334-0.075
               C212.554,81.263,212.454,81.332,212.454,81.332 M214.774,83.387c-0.029,0.016-0.021,0.038-0.15,0.122
               c-0.123,0.092-0.184,0.076-0.236,0.113c-0.054,0.037-0.093,0.19,0,0.184c0.1,0,0.223-0.021,0.258-0.063
               c0.039-0.029-0.029-0.053,0.078-0.099c0.105-0.054,0.213-0.243,0.168-0.282C214.845,83.333,214.774,83.387,214.774,83.387
                M213.603,83.302c0,0-0.236,0.115-0.158,0.16c0.068,0.047,0.41-0.084,0.326-0.16C213.681,83.227,213.603,83.302,213.603,83.302
                M211.342,82.39c-0.016,0.007-0.159,0.063-0.054,0.184c0.105,0.113,0.023,0.12,0.273,0.113c0.244,0,0.365,0.084,0.442,0.189
               c0.084,0.106,0.188,0.244,0.312,0.297c0.123,0.054,0.213,0.031,0.343-0.045s0.229-0.237,0.063-0.313
               c-0.16-0.076-0.184-0.046-0.261-0.168c-0.067-0.13-0.112-0.123-0.168-0.197c-0.054-0.082-0.067-0.199-0.183-0.191
               c-0.113,0.017-0.105,0.063-0.229,0.063s-0.298-0.046-0.357-0.037C211.456,82.285,211.342,82.39,211.342,82.39 M209.994,81.408
               c-0.122,0-0.236-0.016-0.306,0.068c-0.076,0.091-0.146,0.105-0.146,0.175s-0.067,0.328-0.006,0.374
               c0.06,0.037,0.084,0.084,0.174,0.053c0.084-0.029,0.175-0.029,0.403-0.053c0.236-0.016,0.205-0.092,0.373-0.107
               c0.168-0.015,0.252-0.168,0.326-0.174c0.069-0.009,0.069,0.068,0.101,0.1c0.03,0.029,0.092,0.021,0.131,0
               c0.045-0.03-0.032-0.063,0.12-0.055c0.152,0.008,0.245,0.023,0.305-0.054c0.055-0.075,0.069-0.104,0.008-0.183
               c-0.053-0.084,0.068-0.146-0.076-0.221c-0.151-0.068-0.129-0.084-0.213-0.031c-0.084,0.054-0.06-0.03-0.183-0.008
               c-0.122,0.016-0.101,0.113-0.19,0.055c-0.092-0.068-0.075-0.223-0.229-0.223c-0.152,0-0.273-0.06-0.229,0.092
               c0.039,0.146,0.123,0.16,0.197,0.222c0.068,0.062,0.129,0.146,0.047,0.175c-0.084,0.038-0.092,0.068-0.185,0.063
               c-0.099-0.008-0.075-0.03-0.151-0.139c-0.076-0.113-0.129-0.188-0.166-0.183C210.056,81.371,209.994,81.408,209.994,81.408
                M209.201,81.224c-0.028,0.018-0.144,0.039-0.228,0.189c-0.076,0.162-0.062,0.313-0.062,0.313s-0.244,0.213-0.054,0.205
               c0.197-0.009,0.273-0.017,0.342-0.017c0.076,0,0.154-0.099,0.199-0.228c0.045-0.13,0.188-0.319,0.138-0.382
               C209.483,81.25,209.201,81.224,209.201,81.224 M199.662,73.375c-0.03,0.053-0.047,0.16,0,0.221
               c0.046,0.063-0.13,0.123-0.009,0.176c0.123,0.045,0.152-0.038,0.197-0.029c0.047,0.014,0.107,0.113,0.162,0.1
               c0.059-0.016,0.09-0.146-0.017-0.168c-0.106-0.023-0.22-0.045-0.205-0.153c0.015-0.112-0.011-0.222-0.046-0.222
               C199.708,73.298,199.662,73.375,199.662,73.375 M199.593,73.892c-0.037,0.018-0.158,0.102-0.082,0.152
               c0.067,0.055-0.017,0.076,0.029,0.115c0.045,0.037,0.061,0.045,0.137,0.006c0.076-0.045,0.105-0.234,0.047-0.267
               C199.662,73.871,199.593,73.892,199.593,73.892 M200.507,75.027c-0.066,0.023-0.158,0.023-0.121,0.107
               c0.045,0.092,0.084,0.1,0.016,0.137c-0.062,0.047-0.244,0.113-0.176,0.184c0.068,0.076,0.021,0.098,0.197,0.076
               c0.168-0.023,0.176-0.063,0.244,0.016c0.068,0.068,0.075,0.228,0.145,0.318c0.063,0.084,0.023,0.098,0.039,0.168
               c0.022,0.076,0.016,0.131,0.09,0.16c0.078,0.021,0.184,0.021,0.26,0.074c0.068,0.054,0.031,0.131,0.16,0.131
               c0.13,0.008,0.389-0.077,0.26-0.113c-0.13-0.031-0.268,0-0.221-0.092c0.053-0.092,0.213-0.23,0.099-0.275
               c-0.123-0.053-0.177,0.063-0.274-0.053c-0.091-0.113-0.1-0.113-0.121-0.26c-0.024-0.145-0.039-0.342-0.092-0.439
               c-0.053-0.092-0.107-0.283-0.199-0.261c-0.099,0.022-0.06,0.093-0.136,0.101C200.599,75.003,200.507,75.027,200.507,75.027
                M202.28,75.957c-0.038,0.039-0.021,0.174-0.061,0.213c-0.037,0.045-0.214,0.068-0.138,0.123
               c0.075,0.051,0.138,0.068,0.138,0.121c0,0.062-0.037,0.183,0.076,0.16c0.113-0.016,0.037-0.131,0.174-0.1
               c0.139,0.029,0.191,0.052,0.206-0.021c0.022-0.084,0.146-0.168,0.046-0.313c-0.099-0.144-0.166-0.222-0.236-0.235
               C202.41,75.888,202.28,75.957,202.28,75.957 M200.772,79.071c0,0-0.105,0.06-0.129,0.228c-0.017,0.168,0.022,0.236-0.062,0.291
               c-0.084,0.061-0.267,0.158-0.336,0.234c-0.067,0.084-0.183,0.189,0.03,0.189c0.222-0.008,0.367-0.129,0.474-0.053
               c0.099,0.076,0.281,0.16,0.325,0.16c0.056,0,0.162,0.066,0.085,0.151c-0.068,0.084-0.235,0.16-0.015,0.196
               c0.228,0.039,0.701-0.02,0.898,0.169c0.204,0.183,0.268,0.19,0.389,0.213c0.129,0.022,0.242,0.047,0.342-0.022
               c0.106-0.061,0.084-0.105,0.289-0.076c0.207,0.039,0.328-0.037,0.594,0.039c0.261,0.084,0.32,0.137,0.578,0.244
               c0.261,0.105,0.55,0.229,0.83,0.258c0.283,0.033,1.015,0.146,1.258,0.1c0.244-0.053,0.214-0.145,0.365-0.077
               c0.151,0.077,0.273,0.146,0.373,0.207c0.09,0.056,0.328,0.101,0.486,0.146c0.16,0.037,0.215,0.183,0.189,0.028
               c-0.016-0.159-0.189-0.265-0.145-0.342c0.038-0.075,0.168-0.091,0.221,0c0.055,0.092,0.236,0.054,0.351,0.183
               c0.106,0.131,0.214,0.236,0.274,0.191c0.061-0.047,0.136-0.206,0.184-0.252c0.045-0.038,0.007-0.176-0.077-0.268
               c-0.084-0.084-0.136-0.242-0.312-0.137s-0.281,0.189-0.344,0.151c-0.054-0.046-0.168-0.13-0.234-0.267
               c-0.063-0.138-0.102-0.252-0.283-0.252s-0.448,0.146-0.669,0.086c-0.229-0.063-0.396-0.115-0.374-0.252
               c0.031-0.146,0.115-0.336,0.411-0.268c0.29,0.063,0.327,0,0.435-0.038c0.105-0.03,0.365-0.083,0.236-0.19
               c-0.123-0.104-0.223-0.158-0.381-0.104c-0.16,0.052-0.365-0.008-0.465,0.028c-0.092,0.045-0.205,0.191-0.336,0.129
               c-0.129-0.053-0.084-0.174-0.234-0.137c-0.152,0.039-0.244,0.092-0.305,0.03c-0.068-0.052,0.021-0.136-0.139-0.144
               c-0.158-0.008-0.229-0.008-0.335-0.03c-0.114-0.017-0.198-0.106-0.356-0.192c-0.162-0.09-0.223-0.149-0.281-0.004
               c-0.063,0.143-0.008,0.25-0.161,0.325c-0.146,0.084-0.196,0.038-0.344-0.007c-0.15-0.047-0.157-0.047-0.351-0.008
               c-0.188,0.029-0.517-0.031-0.654-0.047c-0.129-0.016-0.221,0.008-0.228-0.121c-0.008-0.122,0.046-0.205-0.069-0.268
               c-0.105-0.055-0.099-0.146-0.234-0.113c-0.13,0.029-0.122,0.06-0.243-0.008c-0.122-0.063-0.335-0.068-0.403-0.123
               c-0.062-0.06,0.008-0.213-0.229-0.144c-0.229,0.067-0.176,0.177-0.32,0.112c-0.137-0.061-0.152-0.092-0.259-0.098
               c-0.114-0.016-0.083-0.078-0.167-0.1C200.835,79.001,200.772,79.071,200.772,79.071 M195.931,75.88
               c-0.015,0.029-0.066,0.15-0.022,0.242c0.047,0.084,0.1,0.016,0.169,0.078c0.076,0.053,0.076,0.233,0.145,0.272
               c0.067,0.045,0.105,0.183,0.159,0.151c0.048-0.031,0.037-0.106,0.009-0.19c-0.031-0.093,0-0.114-0.076-0.206
               c-0.084-0.1-0.168-0.184-0.19-0.234c-0.022-0.054-0.045-0.152-0.114-0.152C195.931,75.841,195.931,75.88,195.931,75.88
                M195.61,75.455c-0.029,0.014-0.075,0.062-0.029,0.137c0.045,0.068,0.158,0.13,0.184,0.138c0.016,0,0.137,0.099,0.082-0.008
               c-0.06-0.106-0.15-0.382-0.189-0.373C195.619,75.354,195.61,75.455,195.61,75.455 M194.69,73.619
               c-0.03,0.039-0.03,0.016-0.017,0.129c0.017,0.107-0.022,0.107-0.039,0.146c-0.008,0.039-0.067,0.176,0.024,0.176
               c0.091,0,0.105,0.022,0.129-0.045c0.029-0.077-0.038-0.237-0.023-0.329c0.008-0.091,0.016-0.15-0.007-0.15
               S194.69,73.619,194.69,73.619 M194.927,74.365c0,0-0.092,0.152-0.084,0.289c0,0.146,0.114,0.357,0.205,0.448
               c0.092,0.101,0.244,0.252,0.305,0.106c0.055-0.137-0.045-0.221-0.106-0.35c-0.062-0.13-0.169-0.541-0.213-0.549
               C194.979,74.311,194.927,74.365,194.927,74.365 M193.782,72.087c-0.036,0.022-0.074,0.084-0.135,0.084
               c-0.056,0-0.162-0.016-0.107,0.063c0.053,0.074,0.122,0.061,0.145,0.12c0.023,0.069,0.076,0.206,0.123,0.252
               c0.045,0.045,0.113,0.083,0.158,0.159c0.039,0.084,0.039,0.176,0.107,0.207c0.061,0.029,0.092,0.061,0.113,0.008
               c0.023-0.055,0.03-0.084,0.039-0.161c0.014-0.075,0.075-0.175-0.045-0.282c-0.123-0.104-0.154-0.127-0.177-0.211
               s-0.114-0.291-0.146-0.291C193.828,72.035,193.782,72.087,193.782,72.087 M192.429,70.847c0,0-0.146,0.099,0,0.197
               c0.137,0.099,0.152,0.016,0.281,0.121c0.129,0.101,0.29,0.229,0.318,0.176c0.039-0.061-0.066-0.176-0.157-0.213
               c-0.093-0.047-0.216-0.352-0.313-0.365C192.466,70.742,192.429,70.847,192.429,70.847"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M192.041,68.228c-0.009,0-0.022,0.008-0.038,0.016c-0.039,0.021-0.107,0.076-0.076,0.213c0.03,0.184,0.137,0.412,0.184,0.533
               c0.052,0.121,0.297,0.441,0.494,0.609c0.197,0.158,0.084,0.15,0.289,0.318s0.168,0.022,0.373,0.16
               c0.213,0.145,0.479,0.654,0.625,0.77c0.151,0.105-0.152,0.281,0.092,0.479c0.25,0.207,0.563,0.176,0.691,0.313
               c0.129,0.146,0.334,0.104,0.289,0.28c-0.037,0.177,0.122,0.52,0.168,0.74c0.045,0.229-0.1,0.303,0.045,0.471
               c0.146,0.168,0.077,0.205,0.245,0.281c0.167,0.076,0.251-0.043,0.326,0.229c0.075,0.267,0.351,0.448,0.494,0.83
               c0.146,0.381-0.046,0.471,0.185,0.639c0.221,0.168,0.258,0.229,0.258,0.356c0,0.138-0.045,0.352,0.084,0.449
               c0.139,0.107,0.32,0.275,0.412,0.51c0.084,0.238,0.234,0.443,0.473,0.52c0.234,0.077,0.326,0.168,0.326,0.29
               c0,0.13,0.054,0.212,0.252,0.449c0.205,0.233,0.351,0.364,0.646,0.556c0.29,0.19,0.594,0.35,0.616,0.457
               c0.022,0.114,0.099,0.439,0.214,0.305c0.113-0.139,0.167-0.313,0.258-0.223c0.092,0.084,0.077,0.223,0.207,0.123
               c0.121-0.1,0.213-0.068,0.342,0.021c0.139,0.093,0.213,0.054,0.213-0.189c0-0.252-0.105-1.134,0.016-1.369
               c0.123-0.236,0.168-0.412,0.138-0.609c-0.038-0.205,0.123-0.205,0.084-0.357c-0.03-0.16-0.146-0.244-0.213-0.297
               c-0.069-0.055-0.168-0.328-0.244-0.336c-0.075-0.016-0.137,0.023-0.297-0.037c-0.151-0.055-0.411-0.077-0.495-0.436
               c-0.076-0.365,0.018-0.73-0.175-0.791c-0.188-0.055-0.356,0.016-0.449-0.047c-0.091-0.053-0.373-0.1-0.305-0.41
               c0.067-0.318,0.288-0.617,0.185-0.814c-0.117-0.189-0.132-0.273-0.32-0.318c-0.191-0.047-0.146,0-0.244-0.191
               c-0.105-0.197-0.215-0.204-0.364-0.326c-0.146-0.13-0.032-0.352-0.176-0.366c-0.146-0.007-0.252,0.039-0.343-0.007
               c-0.084-0.046-0.198,0.007-0.168-0.214c0.039-0.228-0.099-0.298-0.153-0.298c-0.06,0-0.128,0.138-0.25,0.039
               c-0.121-0.098-0.235-0.258-0.304-0.184c-0.069,0.086-0.055,0.297-0.221,0.145c-0.169-0.145-0.199-0.426-0.344-0.493
               c-0.139-0.069-0.139-0.069-0.184-0.229c-0.047-0.15-0.526-0.435-0.67-0.57c-0.139-0.145-0.223-0.121-0.319-0.213
               c-0.101-0.1-0.007-0.152-0.167-0.229c-0.168-0.069-0.29-0.022-0.299-0.151c0-0.138,0.022-0.258-0.053-0.343
               c-0.076-0.092-0.176-0.046-0.221-0.159c-0.056-0.123-0.13-0.281-0.214-0.344c-0.075-0.061-0.151-0.168-0.342-0.13
               c-0.183,0.03-0.306,0.008-0.397-0.022c-0.082-0.037-0.426,0.139-0.547,0c-0.115-0.145-0.441-0.426-0.548-0.44
               C192.024,68.16,192.041,68.228,192.041,68.228z M224.492,78.621c-0.138,0.047-0.313-0.037-0.32,0.258
               c-0.009,0.291-0.151,0.406-0.055,0.504c0.092,0.107,0.252,0.268,0.319,0.123c0.063-0.146,0.03-0.396,0.076-0.459
               c0.047-0.06,0.176-0.416,0.114-0.448C224.574,78.567,224.492,78.621,224.492,78.621z M221.796,80.341
               c-0.122,0.092-0.176,0.189-0.244,0.289c-0.062,0.092-0.214,0.297-0.203,0.396c0.014,0.1-0.018,0.243,0.137,0.105
               c0.149-0.137,0.188-0.166,0.287-0.305c0.092-0.137,0.1-0.221,0.153-0.35c0.06-0.13,0.113-0.297,0.046-0.307
               C221.901,80.173,221.796,80.341,221.796,80.341z M223.92,79.703c0,0.1-0.106,0.139,0.017,0.229
               c0.113,0.091,0.16,0.166,0.228,0.029c0.069-0.145,0.19-0.26,0.101-0.319c-0.092-0.065-0.252-0.335-0.313-0.244
               C223.89,79.49,223.92,79.703,223.92,79.703z M219.458,71.173c-0.045,0.008-0.137,0.139-0.176,0.191
               c-0.03,0.053-0.067,0.182-0.045,0.229c0.021,0.046-0.03,0.092,0.068,0.113c0.1,0.022,0.197-0.029,0.235-0.084
               c0.038-0.062,0.114-0.062,0.138-0.189c0.016-0.137,0.016-0.355-0.068-0.365C219.533,71.068,219.458,71.173,219.458,71.173z
                M218.751,74.835c0,0-0.152-0.029-0.146,0.123c0.017,0.16-0.029,0.197,0.078,0.197c0.105,0.008,0.135-0.1,0.281-0.055
               c0.137,0.047,0.235,0.063,0.221,0c-0.008-0.066-0.107-0.128-0.174-0.176c-0.076-0.045-0.131-0.157-0.177-0.172
               C218.788,74.746,218.751,74.835,218.751,74.835z M218.394,73.826c-0.023,0.021-0.106,0.105-0.056,0.182
               c0.056,0.076,0.123,0.054,0.14,0.107c0.021,0.053-0.092,0.158-0.008,0.182c0.084,0.031,0.129-0.016,0.174-0.006
               c0.039,0.015,0.09,0,0.168,0.015c0.068,0.022,0.229,0.007,0.145-0.077c-0.091-0.083-0.174-0.105-0.184-0.167
               c-0.015-0.062,0.01-0.183-0.084-0.183c-0.09,0-0.183-0.017-0.188-0.063C218.483,73.778,218.394,73.826,218.394,73.826z
                M217.615,76.511c-0.075-0.016-0.32-0.037-0.243,0.113c0.076,0.16,0.129,0.307,0.236,0.383c0.105,0.074,0.166,0.113,0.313,0.113
               c0.137,0,0.229-0.092,0.313-0.131c0.084-0.029,0.252,0.047,0.183-0.129c-0.067-0.175-0.122-0.252-0.19-0.304
               c-0.076-0.062-0.213-0.132-0.272-0.132C217.89,76.427,217.615,76.511,217.615,76.511z M222.481,74.076
               c-0.092,0.076-0.069,0.168-0.268,0.189c-0.205,0.023-0.289-0.074-0.381,0.039c-0.084,0.121-0.068,0.318-0.122,0.342
               c-0.054,0.031-0.092,0.023-0.114-0.105c-0.029-0.138-0.037-0.319-0.168-0.268c-0.129,0.055-0.266-0.061-0.281,0.115
               c-0.015,0.174,0.105,0.113,0.168,0.213c0.062,0.091,0.021,0.259,0.151,0.305c0.13,0.047,0.214,0.021,0.29,0.047
               c0.076,0.014,0.335-0.033,0.465,0.09c0.121,0.123,0.061,0.207,0.129,0.32c0.068,0.12,0.029,0.326,0.244,0.326
               c0.213,0,0.327-0.158,0.532-0.09c0.198,0.066,0.213,0.09,0.435,0.082c0.229,0,0.357-0.184,0.365-0.021
               c0.016,0.152-0.008,0.281-0.175,0.258c-0.161-0.028-0.245-0.145-0.358-0.067c-0.121,0.084-0.1,0.112-0.242,0.213
               c-0.139,0.106-0.139,0.131-0.327,0.093c-0.191-0.031-0.474-0.017-0.427,0.09c0.052,0.106,0.381,0.125,0.373,0.198
               c-0.01,0.076,0.304,0.206,0.266,0.365c-0.029,0.159-0.046,0.159-0.053,0.306c-0.008,0.137,0.029,0.258,0.121,0.297
               c0.092,0.037,0.215,0.114,0.281-0.008c0.076-0.13,0.038-0.326,0.176-0.326c0.146,0,0.168-0.222,0.313-0.068
               c0.146,0.152,0.533,0.174,0.609,0.281c0.084,0.1,0.16,0.373,0.234,0.418c0.084,0.045,0.223,0.084,0.252,0.092
               c0.039,0.008,0.688,0.021,0.922,0.137c0.234,0.107,0.578,0.343,0.692,0.365c0.122,0.016,0.39,0.115,0.472,0.244
               c0.092,0.129,0.229,0.1,0.26,0.334c0.039,0.229,0.215,0.49,0.259,0.672c0.038,0.174,0.146,0.25,0.168,0.318
               c0.022,0.076-0.183,0.008-0.13,0.145c0.046,0.139,0.16,0.186,0.138,0.236c-0.022,0.055,0.028,0.146-0.114,0.176
               c-0.143,0.029-0.351,0.121-0.473,0.343c-0.121,0.222-0.364,0.519-0.273,0.608c0.084,0.084,0.022,0.055,0.364,0.029
               c0.336-0.021,0.345,0.184,0.58,0.018c0.235-0.168,0.235-0.344,0.474-0.237c0.241,0.101,0.517,0.047,0.606,0.267
               c0.101,0.223,0.306,0.396,0.336,0.48c0.022,0.083,0.158,0.206,0.158,0.206s0.101-1.265,0.114-1.577
               c0.01-0.318-0.09-0.678-0.045-0.807c0.045-0.131,0.176-0.313,0.184-0.434c0.015-0.121,0.061-3.298,0.061-3.298
               s-0.334-0.235-0.595-0.235c-0.258,0.008-0.448-0.017-0.585-0.129c-0.145-0.123-0.586-0.313-0.678-0.39
               c-0.092-0.069-0.268-0.19-0.51-0.214c-0.252-0.021-0.16-0.092-0.405,0.039c-0.234,0.138-0.357,0.029-0.364,0.258
               c0,0.223,0.021,0.236-0.105,0.251c-0.139,0.009-0.404,0.046-0.437,0.101c-0.036,0.061-0.296,0.19-0.342,0.319
               c-0.054,0.121-0.038,0.236-0.128,0.317c-0.084,0.078-0.375,0.307-0.458,0.345c-0.084,0.03-0.289,0.039-0.351-0.099
               c-0.062-0.137-0.068-0.129-0.152-0.291c-0.082-0.168-0.038-0.471-0.12-0.493c-0.085-0.022-0.07,0.221-0.13,0.25
               c-0.063,0.03-0.123-0.121-0.168-0.289c-0.055-0.166-0.069-0.304-0.022-0.531c0.046-0.223,0.138-0.207,0.099-0.358
               c-0.037-0.152-0.137-0.197-0.152-0.289c-0.008-0.092,0.168-0.32-0.021-0.342c-0.197-0.023-0.236,0.061-0.404,0.014
               c-0.166-0.045-0.305-0.189-0.502-0.26C222.961,73.91,222.692,73.9,222.481,74.076z M219.413,76.246
               c-0.055-0.008-0.161-0.017-0.223,0.062c-0.053,0.084-0.205,0.092-0.221,0.259c-0.007,0.168-0.099,0.273,0.039,0.221
               c0.137-0.053,0.122-0.305,0.213-0.221c0.092,0.084,0.26,0.114,0.205,0.184c-0.047,0.067-0.457,0.137-0.334,0.221
               c0.121,0.085,0.182,0.029,0.334,0.008c0.146-0.021,0.26-0.008,0.268-0.129c0.007-0.121,0.016-0.336,0.158-0.223
               c0.138,0.107,0.115,0.223,0.252,0.19c0.138-0.038,0.268-0.06,0.313-0.06c0.038,0,0.235,0.021,0.297,0.068
               c0.068,0.036,0.206,0.045,0.318,0.143c0.114,0.107,0.153,0.244,0.268,0.199c0.121-0.053,0.229-0.16,0.106-0.274
               c-0.122-0.114-0.137-0.136-0.184-0.243c-0.045-0.113-0.016-0.268-0.183-0.273c-0.168-0.006-0.229,0.016-0.366-0.068
               c-0.129-0.084-0.197-0.273-0.371-0.176c-0.178,0.092-0.275,0.26-0.367,0.189c-0.099-0.066-0.137-0.128-0.272-0.128
               C219.533,76.193,219.413,76.246,219.413,76.246z M220.724,75.218c-0.048,0.016-0.268,0.029-0.216,0.107
               c0.056,0.082,0.168,0.127,0.253,0.156c0.084,0.032,0.197,0.055,0.243-0.016c0.038-0.067,0.177-0.342,0.038-0.356
               C220.903,75.087,220.724,75.218,220.724,75.218z M219.017,71.524c0,0-0.099,0.063-0.145,0.168
               c-0.046,0.106-0.168,0.19-0.168,0.261c0,0.067,0.008,0.228-0.016,0.271c-0.021,0.046-0.084,0.169-0.084,0.244
               c-0.008,0.068,0.047,0.168,0.068,0.215c0.023,0.037,0.1,0.113,0.023,0.145c-0.077,0.021-0.1-0.093-0.139-0.047
               c-0.045,0.038-0.091,0.176-0.029,0.191c0.054,0.008,0.121-0.055,0.145,0.015c0.023,0.067,0.045,0.19,0.053,0.138
               c0.008-0.061,0.063,0.189,0.101,0.396c0.03,0.207,0.1,0.39,0.166,0.44c0.077,0.063,0.176,0.093,0.198,0.207
               c0.031,0.113,0.199,0.281,0.236,0.229c0.03-0.047-0.176-0.352-0.205-0.436c-0.031-0.084-0.221-0.213-0.205-0.41
               c0.008-0.189-0.068-0.48,0.067-0.457c0.13,0.021,0.222,0.158,0.319,0.176c0.106,0.021,0.297,0,0.39,0.053
               c0.083,0.055,0.381,0.075,0.149-0.037c-0.219-0.115-0.157-0.313-0.342-0.336c-0.175-0.031-0.287,0.016-0.287-0.092
               s0.09-0.15,0.174-0.229c0.084-0.068,0.16-0.068,0.184-0.26c0.023-0.183,0.1-0.318-0.023-0.312
               c-0.121,0.008-0.229,0.129-0.318,0.183c-0.092,0.061-0.139,0.1-0.161,0.19c-0.021,0.091-0.06,0.045-0.12,0.168
               c-0.063,0.114-0.113,0.183-0.16,0.136c-0.046-0.052-0.115-0.091,0-0.197c0.104-0.099,0.151-0.105,0.197-0.259
               c0.038-0.159-0.053-0.319-0.093-0.374c-0.028-0.052,0-0.136,0.047-0.22c0.046-0.077,0.151-0.183,0.114-0.213
               C219.115,71.44,219.017,71.524,219.017,71.524z M216.336,75.4c-0.037,0.018-0.272,0.167-0.317-0.008
               c-0.047-0.184,0.076-0.434,0.317-0.313c0.244,0.113,0.42,0.19,0.617,0.19c0.205,0,0.389-0.017,0.524-0.017
               c0.137,0,0.358,0,0.236,0.084c-0.115,0.078-0.229,0.016-0.252,0.115c-0.021,0.098,0.063,0.457-0.055,0.318
               c-0.113-0.137-0.066-0.318-0.182-0.35c-0.115-0.023-0.137-0.008-0.297-0.031c-0.166-0.021-0.389-0.061-0.427-0.053
               C216.458,75.354,216.336,75.4,216.336,75.4z M213.899,78.363c-0.008,0.016-0.092,0.053-0.037,0.113
               c0.053,0.068,0.074,0.145,0.113,0.184c0.037,0.045,0.061,0.129,0.084-0.009c0.021-0.129,0.037-0.373-0.015-0.38
               C213.99,78.263,213.899,78.363,213.899,78.363z M214.547,77.919c0,0-0.091,0.039-0.175,0.084
               c-0.084,0.054-0.145,0.16-0.077,0.213c0.069,0.055,0.093,0.039,0.039,0.123c-0.062,0.076-0.16,0.176-0.092,0.236
               c0.067,0.059,0.092,0.021,0.159,0.021c0.062-0.007,0.076,0.084,0.091,0.122c0.024,0.039,0.077,0.222,0.123,0.184
               c0.046-0.031,0.151-0.039,0.175-0.138c0.031-0.091,0.063-0.168,0.131-0.183c0.075-0.008,0.176-0.062,0.045-0.16
               c-0.137-0.098-0.129-0.084-0.092-0.221c0.047-0.137,0.047-0.1,0.092-0.168c0.047-0.061,0.047-0.166,0.023-0.221
               c-0.018-0.055-0.063-0.213-0.131-0.129c-0.076,0.084-0.105,0.25-0.168,0.259C214.64,77.944,214.547,77.919,214.547,77.919z
                M211.958,75.242c0,0,0.023,0.137-0.084,0.234c-0.1,0.105-0.145,0.113-0.145,0.268c0,0.152-0.021,0.152-0.121,0.243
               c-0.107,0.093-0.207,0.022-0.191,0.167c0.023,0.139,0.282,0.047,0.152,0.223c-0.129,0.174-0.092,0.334-0.029,0.449
               c0.068,0.113,0.357,0.036,0.418,0.021c0.055-0.021,0.189-0.021,0.131,0.092c-0.056,0.113-0.113,0.15-0.031,0.234
               c0.076,0.084,0.152,0.183,0.076,0.396c-0.068,0.215-0.168,0.334-0.145,0.403c0.016,0.067,0.016,0.204-0.047,0.325
               c-0.068,0.123-0.078,0.238-0.053,0.32c0.03,0.084,0.016,0.107,0.092,0.176c0.067,0.062,0.052,0.115,0.182,0.082
               c0.13-0.021,0.107-0.082,0.236-0.082c0.139,0,0.168-0.022,0.213,0c0.055,0.022,0.146,0.031,0.168-0.047
               c0.016-0.067-0.145-0.267-0.145-0.34c-0.009-0.084,0.03-0.115,0.075-0.27c0.046-0.145,0.207-0.196,0.091-0.305
               c-0.113-0.105-0.045-0.396-0.037-0.556c0.008-0.151,0.121-0.204,0.106-0.327c-0.007-0.113-0.037-0.228-0.099-0.365
               c-0.053-0.129-0.222-0.219-0.047-0.334c0.185-0.115,0.297-0.396,0.465-0.223c0.168,0.184,0.207,0.131,0.151,0.338
               c-0.062,0.203-0.067,0.195-0.12,0.387c-0.008,0.189,0.242,0.549,0.357,0.586c0.113,0.039,0.242-0.062,0.184,0.105
               c-0.063,0.176-0.146,0.223-0.123,0.338c0.03,0.112,0.023,0.203,0.159,0.258c0.138,0.053,0.198,0.137,0.267,0.053
               c0.069-0.076,0.106-0.235,0.154-0.297c0.045-0.063,0.066-0.084,0.15-0.076s0.236-0.037,0.304-0.037
               c0.077,0,0.114-0.146,0.146-0.189c0.037-0.048,0.084,0,0.15-0.031c0.077-0.03,0.19-0.229,0.061-0.229
               c-0.127,0-0.182,0.037-0.258,0.1c-0.066,0.061-0.105,0.076-0.166-0.055c-0.055-0.121-0.123-0.222-0.168-0.258
               c-0.047-0.047-0.244-0.139-0.138-0.223c0.099-0.084,0.272-0.121,0.197-0.235c-0.083-0.122-0.175-0.084-0.222-0.235
               c-0.037-0.16,0-0.259-0.051-0.289c-0.056-0.031-0.268-0.26-0.3-0.343c-0.03-0.077-0.099-0.054-0.149-0.146
               c-0.055-0.1-0.215-0.227-0.178-0.268c0.047-0.045,0.139,0.016,0.207,0.047c0.062,0.021,0.113,0.03,0.152-0.062
               c0.027-0.101,0.066-0.146,0.166-0.151c0.102-0.018,0.113-0.031,0.206-0.093c0.099-0.067,0.157-0.19,0.305-0.356
               c0.138-0.176,0.175-0.236,0.229-0.244c0.061,0,0.183,0.008,0.25,0.037c0.07,0.031,0.26-0.045,0.253-0.166
               c-0.009-0.123-0.062-0.289-0.281-0.236c-0.222,0.054-0.299,0.076-0.366,0.146c-0.062,0.066-0.076,0.051-0.189,0.066
               c-0.125,0.018-0.114-0.021-0.229,0.046c-0.114,0.077-0.083,0.159-0.229,0.122c-0.145-0.045-0.127-0.15-0.242-0.105
               c-0.121,0.045-0.137,0.144-0.234,0.205c-0.1,0.053-0.152,0.092-0.223,0.183c-0.066,0.092-0.016,0.183-0.189,0.122
               c-0.175-0.061-0.221,0-0.259-0.152c-0.039-0.145,0.106-0.152-0.146-0.313c-0.26-0.168-0.281-0.168-0.26-0.267
               c0.031-0.106-0.113-0.524,0.076-0.692c0.184-0.168,0.297-0.367,0.336-0.373c0.037-0.008,0.146,0.115,0.313,0.115
               c0.168,0.006,0.352-0.047,0.556-0.068c0.198-0.024,0.365-0.024,0.494,0.006c0.138,0.039,0.366-0.083,0.542-0.03
               c0.184,0.046,0.152,0.093,0.313,0.152c0.151,0.063,0.267,0.03,0.403,0.022c0.136,0,0.466,0.031,0.615-0.146
               c0.146-0.175,0.161-0.365,0.252-0.417c0.101-0.063,0.267-0.313,0.297-0.396c0.03-0.076,0.168-0.16,0.084-0.205
               c-0.084-0.047-0.146-0.168-0.272-0.084c-0.123,0.09-0.153,0.252-0.268,0.336c-0.121,0.084-0.1,0.105-0.236,0.244
               c-0.137,0.137-0.099,0.174-0.342,0.174c-0.25,0-0.313,0.008-0.418-0.031c-0.115-0.045-0.16-0.12-0.275-0.045
               c-0.114,0.068-0.075,0.152-0.221,0.076c-0.145-0.076-0.152-0.168-0.242-0.168c-0.084,0-0.414-0.121-0.558-0.076
               c-0.153,0.039-0.268,0.061-0.288,0.023c-0.031-0.039-0.1-0.121-0.223-0.16c-0.129-0.031-0.305-0.101-0.344,0
               c-0.045,0.092,0.023,0.137-0.053,0.221c-0.076,0.093-0.092,0.213-0.189,0.183c-0.092-0.022-0.037-0.159-0.15-0.075
               c-0.115,0.084,0,0.114-0.132,0.182c-0.129,0.076-0.129,0.199-0.188,0.352c-0.063,0.146-0.017,0.197-0.078,0.289
               c-0.051,0.084-0.166,0.068-0.105,0.16c0.063,0.084,0.105,0.197,0.086,0.221c-0.017,0.031,0.006,0.299-0.102,0.381
               c-0.105,0.084-0.151,0.197-0.176,0.289c-0.021,0.094-0.121,0.063-0.121,0.229C211.905,74.966,211.958,75.242,211.958,75.242z
                M203.683,71.73c0,0-0.039,0.039-0.138,0.197c-0.101,0.169-0.235,0.107-0.159,0.307c0.084,0.197-0.023,0.268-0.084,0.434
               c-0.068,0.166-0.092,0.244,0.016,0.473c0.099,0.229,0.16,0.206,0.236,0.281c0.067,0.076,0.037,0.168-0.031,0.272
               c-0.067,0.106-0.166,0.222-0.092,0.321c0.084,0.105,0.32,0.021,0.274,0.158c-0.054,0.138-0.213,0.207-0.084,0.26
               c0.13,0.055,0.28-0.045,0.235,0.063c-0.053,0.105-0.121,0.312,0,0.289c0.129-0.023,0.334-0.084,0.381,0.021
               c0.045,0.105,0.092,0.152,0.031,0.26c-0.069,0.1-0.207,0.176-0.092,0.281c0.104,0.099,0.129,0.168,0.129,0.304
               c0,0.146,0.022,0.229,0.039,0.351c0.014,0.115-0.16,0.222,0.028,0.297c0.192,0.078,0.252-0.075,0.327,0.009
               c0.076,0.084,0.152,0.198,0.268,0.168c0.114-0.031,0.327-0.177,0.504-0.161c0.182,0.023,0.221,0.197,0.258,0.26
               c0.037,0.053-0.047,0.344,0.129,0.268c0.175-0.068,0.205-0.19,0.289-0.106c0.084,0.075,0.16,0.122,0.244,0.03
               c0.084-0.084,0.281-0.365,0.449-0.313c0.176,0.045,0.268,0.029,0.336,0.121c0.067,0.084,0.029,0.243,0.121,0.235
               c0.092-0.007,0.342-0.037,0.426,0c0.092,0.039,0.176,0.075,0.207,0.245c0.037,0.158,0.067,0.313,0.166,0.313
               c0.106,0,0.885-0.459,0.991-0.396c0.099,0.068,0.045,0.32,0.12,0.268c0.084-0.063,0.092-0.037,0.191-0.223
               c0.104-0.174,0.104-0.355,0.021-0.463c-0.092-0.1-0.092-0.26-0.092-0.289c0.008-0.039,0.223-0.436,0.307-0.557
               c0.082-0.123-0.16-0.236-0.115-0.404c0.047-0.158-0.008-0.234,0.092-0.318c0.099-0.076,0.054-0.229,0.145-0.273
               c0.084-0.045,0.236,0.029,0.282-0.092c0.054-0.113,0.235-0.129,0.403-0.352c0.16-0.213,0.09-0.213,0.006-0.449
               c-0.082-0.234-0.271-0.105,0.033-0.531c0.304-0.436,0.051-0.617,0.281-0.609c0.219,0,0.242,0.152,0.447,0.123
               c0.205-0.032,0.503,0.035,0.419-0.184c-0.075-0.229-0.336-0.131-0.305-0.314c0.038-0.182,0.046-0.266-0.076-0.266
               c-0.12,0-0.267,0.022-0.343-0.047c-0.076-0.061-0.357-0.189-0.221-0.388c0.145-0.196,0.289-0.168,0.19-0.312
               c-0.092-0.146-0.19-0.153-0.297-0.313c-0.099-0.149-0.214-0.235-0.281-0.373c-0.069-0.144,0.082-0.114,0.067-0.206
               c-0.016-0.1-0.145-0.15-0.168-0.175c-0.022-0.029,0.13-0.037,0.229-0.099c0.092-0.061,0.131-0.107,0.038-0.229
               c-0.084-0.123-0.251-0.268-0.381-0.297c-0.129-0.021-0.502-0.055-0.67-0.063c-0.168,0-0.206,0.03-0.326,0.063
               c-0.123,0.037-0.252,0.068-0.274,0.136c-0.016,0.069-0.053,0.39-0.053,0.479c0,0.092,0.03,0.268,0,0.365
               c-0.031,0.1,0.021,0.235-0.093,0.26c-0.114,0.021-0.221,0.054-0.206,0.205c0.016,0.145,0.069,0.213-0.007,0.26
               c-0.076,0.053-0.206,0.045-0.222,0.152c-0.022,0.104-0.016,0.166-0.03,0.295c-0.015,0.139-0.113,0.131-0.121,0.215
               c0,0.092-0.045,0.197-0.091,0.229c-0.047,0.029-0.198,0.016-0.344,0.061c-0.137,0.047-0.205,0.016-0.274,0.115
               c-0.075,0.098-0.151,0.146-0.228,0.105c-0.076-0.037-0.123-0.066-0.222-0.129c-0.091-0.063-0.229-0.063-0.298-0.1
               c-0.066-0.031-0.113-0.151-0.205-0.105c-0.099,0.045-0.334-0.046-0.402,0.158c-0.068,0.207-0.045,0.281-0.152,0.289
               c-0.105,0.017-0.114,0.01-0.268,0.031c-0.151,0.022-0.289,0.008-0.396,0.008c-0.105,0-0.281,0.1-0.372,0.137
               c-0.083,0.031-0.067,0.092-0.177,0.039c-0.105-0.061-0.213-0.145-0.297-0.237c-0.076-0.083-0.241-0.213-0.303-0.267
               c-0.063-0.053-0.162-0.182-0.19-0.266c-0.023-0.084,0-0.306-0.062-0.328C203.735,71.677,203.683,71.73,203.683,71.73z
                M216.177,81.979c-0.029,0-0.197-0.022-0.335,0.166c-0.138,0.19-0.259,0.131-0.388,0.215c-0.139,0.082-0.229,0.146-0.291,0.243
               c-0.061,0.091-0.113,0.214-0.113,0.32c0.008,0.105,0.008,0.197-0.076,0.205c-0.082,0.015-0.19,0.106-0.068,0.144
               c0.121,0.03,0.185,0.017,0.305,0c0.129-0.007,0.152-0.036,0.26-0.12c0.114-0.076,0.235-0.084,0.366-0.176
               c0.129-0.085,0.136-0.189,0.228-0.268c0.092-0.074,0.26-0.229,0.26-0.258c0.008-0.031-0.047-0.082-0.055-0.236
               c-0.006-0.152,0.008-0.213,0-0.235C216.252,81.957,216.177,81.979,216.177,81.979z M217.356,80.751
               c-0.03-0.008-0.176-0.021-0.252,0.047c-0.076,0.07-0.092,0.168-0.099,0.213c-0.009,0.055,0.015,0.16,0.104,0.063
               c0.1-0.107,0.19-0.189,0.238-0.146c0.045,0.039,0.129,0.131,0.196,0.076c0.077-0.053,0.029-0.121,0.106-0.145
               c0.084-0.018,0.229,0.01,0.234-0.063c0.01-0.067,0.039-0.196-0.158-0.213C217.54,80.562,217.356,80.751,217.356,80.751z
                M216.177,81.195c0,0-0.229,0.008-0.32,0.047c-0.09,0.037-0.219,0.059-0.267,0.129c-0.036,0.062-0.157,0.189-0.021,0.189
               c0.144,0.007,0.234-0.137,0.332-0.114c0.101,0.022,0.314,0.017,0.429-0.022c0.12-0.039,0.204-0.252,0.129-0.291
               C216.381,81.095,216.177,81.195,216.177,81.195z M212.454,81.332c-0.115,0.03-0.253,0.092-0.313,0.152
               c-0.063,0.068-0.121,0.197-0.084,0.281c0.039,0.076-0.121,0.123,0.168,0.105c0.289-0.015,0.266-0.105,0.418-0.06
               c0.146,0.037,0.023,0.007,0.306,0.083c0.282,0.084,0.327,0.1,0.45,0.038c0.129-0.062,0.051-0.188,0.258-0.138
               c0.197,0.054,0.183,0.031,0.326-0.022c0.146-0.054,0.123-0.083,0.199-0.077c0.076,0,0.105,0.018,0.213-0.066
               c0.105-0.076,0.176-0.106,0.221-0.152c0.046-0.053,0.107-0.15,0.138-0.077c0.029,0.077-0.008,0.106,0.076,0.131
               c0.084,0.017,0.008,0.03,0.167,0.037c0.152,0.017,0.305-0.037,0.305-0.037s0.268-0.26,0.184-0.306
               c-0.084-0.045-0.289,0.063-0.351,0.068c-0.061,0.016-0.213,0.008-0.274-0.022c-0.061-0.028-0.159-0.175-0.26-0.138
               c-0.099,0.039-0.099,0.123-0.158,0.183c-0.063,0.063-0.106,0.032-0.137,0.108c-0.039,0.066-0.01,0.158-0.139,0.174
               c-0.139,0.016-0.176-0.067-0.212-0.105c-0.047-0.029-0.106-0.061-0.168-0.029c-0.056,0.037-0.121,0.053-0.253,0.074
               c-0.135,0.023-0.135,0.076-0.288-0.021c-0.144-0.1-0.113-0.107-0.274-0.176c-0.15-0.068-0.25-0.084-0.334-0.075
               C212.554,81.263,212.454,81.332,212.454,81.332z M214.774,83.387c-0.029,0.016-0.021,0.038-0.15,0.122
               c-0.123,0.092-0.184,0.076-0.236,0.113c-0.054,0.037-0.093,0.19,0,0.184c0.1,0,0.223-0.021,0.258-0.063
               c0.039-0.029-0.029-0.053,0.078-0.099c0.105-0.054,0.213-0.243,0.168-0.282C214.845,83.333,214.774,83.387,214.774,83.387z
                M213.603,83.302c0,0-0.236,0.115-0.158,0.16c0.068,0.047,0.41-0.084,0.326-0.16C213.681,83.227,213.603,83.302,213.603,83.302z
                M211.342,82.39c-0.016,0.007-0.159,0.063-0.054,0.184c0.105,0.113,0.023,0.12,0.273,0.113c0.244,0,0.365,0.084,0.442,0.189
               c0.084,0.106,0.188,0.244,0.312,0.297c0.123,0.054,0.213,0.031,0.343-0.045s0.229-0.237,0.063-0.313
               c-0.16-0.076-0.184-0.046-0.261-0.168c-0.067-0.13-0.112-0.123-0.168-0.197c-0.054-0.082-0.067-0.199-0.183-0.191
               c-0.113,0.017-0.105,0.063-0.229,0.063s-0.298-0.046-0.357-0.037C211.456,82.285,211.342,82.39,211.342,82.39z M209.994,81.408
               c-0.122,0-0.236-0.016-0.306,0.068c-0.076,0.091-0.146,0.105-0.146,0.175s-0.067,0.328-0.006,0.374
               c0.06,0.037,0.084,0.084,0.174,0.053c0.084-0.029,0.175-0.029,0.403-0.053c0.236-0.016,0.205-0.092,0.373-0.107
               c0.168-0.015,0.252-0.168,0.326-0.174c0.069-0.009,0.069,0.068,0.101,0.1c0.03,0.029,0.092,0.021,0.131,0
               c0.045-0.03-0.032-0.063,0.12-0.055c0.152,0.008,0.245,0.023,0.305-0.054c0.055-0.075,0.069-0.104,0.008-0.183
               c-0.053-0.084,0.068-0.146-0.076-0.221c-0.151-0.068-0.129-0.084-0.213-0.031c-0.084,0.054-0.06-0.03-0.183-0.008
               c-0.122,0.016-0.101,0.113-0.19,0.055c-0.092-0.068-0.075-0.223-0.229-0.223c-0.152,0-0.273-0.06-0.229,0.092
               c0.039,0.146,0.123,0.16,0.197,0.222c0.068,0.062,0.129,0.146,0.047,0.175c-0.084,0.038-0.092,0.068-0.185,0.063
               c-0.099-0.008-0.075-0.03-0.151-0.139c-0.076-0.113-0.129-0.188-0.166-0.183C210.056,81.371,209.994,81.408,209.994,81.408z
                M209.201,81.224c-0.028,0.018-0.144,0.039-0.228,0.189c-0.076,0.162-0.062,0.313-0.062,0.313s-0.244,0.213-0.054,0.205
               c0.197-0.009,0.273-0.017,0.342-0.017c0.076,0,0.154-0.099,0.199-0.228c0.045-0.13,0.188-0.319,0.138-0.382
               C209.483,81.25,209.201,81.224,209.201,81.224z M199.662,73.375c-0.03,0.053-0.047,0.16,0,0.221
               c0.046,0.063-0.13,0.123-0.009,0.176c0.123,0.045,0.152-0.038,0.197-0.029c0.047,0.014,0.107,0.113,0.162,0.1
               c0.059-0.016,0.09-0.146-0.017-0.168c-0.106-0.023-0.22-0.045-0.205-0.153c0.015-0.112-0.011-0.222-0.046-0.222
               C199.708,73.298,199.662,73.375,199.662,73.375z M199.593,73.892c-0.037,0.018-0.158,0.102-0.082,0.152
               c0.067,0.055-0.017,0.076,0.029,0.115c0.045,0.037,0.061,0.045,0.137,0.006c0.076-0.045,0.105-0.234,0.047-0.267
               C199.662,73.871,199.593,73.892,199.593,73.892z M200.507,75.027c-0.066,0.023-0.158,0.023-0.121,0.107
               c0.045,0.092,0.084,0.1,0.016,0.137c-0.062,0.047-0.244,0.113-0.176,0.184c0.068,0.076,0.021,0.098,0.197,0.076
               c0.168-0.023,0.176-0.063,0.244,0.016c0.068,0.068,0.075,0.228,0.145,0.318c0.063,0.084,0.023,0.098,0.039,0.168
               c0.022,0.076,0.016,0.131,0.09,0.16c0.078,0.021,0.184,0.021,0.26,0.074c0.068,0.054,0.031,0.131,0.16,0.131
               c0.13,0.008,0.389-0.077,0.26-0.113c-0.13-0.031-0.268,0-0.221-0.092c0.053-0.092,0.213-0.23,0.099-0.275
               c-0.123-0.053-0.177,0.063-0.274-0.053c-0.091-0.113-0.1-0.113-0.121-0.26c-0.024-0.145-0.039-0.342-0.092-0.439
               c-0.053-0.092-0.107-0.283-0.199-0.261c-0.099,0.022-0.06,0.093-0.136,0.101C200.599,75.003,200.507,75.027,200.507,75.027z
                M202.28,75.957c-0.038,0.039-0.021,0.174-0.061,0.213c-0.037,0.045-0.214,0.068-0.138,0.123
               c0.075,0.051,0.138,0.068,0.138,0.121c0,0.062-0.037,0.183,0.076,0.16c0.113-0.016,0.037-0.131,0.174-0.1
               c0.139,0.029,0.191,0.052,0.206-0.021c0.022-0.084,0.146-0.168,0.046-0.313c-0.099-0.144-0.166-0.222-0.236-0.235
               C202.41,75.888,202.28,75.957,202.28,75.957z M200.772,79.071c0,0-0.105,0.06-0.129,0.228c-0.017,0.168,0.022,0.236-0.062,0.291
               c-0.084,0.061-0.267,0.158-0.336,0.234c-0.067,0.084-0.183,0.189,0.03,0.189c0.222-0.008,0.367-0.129,0.474-0.053
               c0.099,0.076,0.281,0.16,0.325,0.16c0.056,0,0.162,0.066,0.085,0.151c-0.068,0.084-0.235,0.16-0.015,0.196
               c0.228,0.039,0.701-0.02,0.898,0.169c0.204,0.183,0.268,0.19,0.389,0.213c0.129,0.022,0.242,0.047,0.342-0.022
               c0.106-0.061,0.084-0.105,0.289-0.076c0.207,0.039,0.328-0.037,0.594,0.039c0.261,0.084,0.32,0.137,0.578,0.244
               c0.261,0.105,0.55,0.229,0.83,0.258c0.283,0.033,1.015,0.146,1.258,0.1c0.244-0.053,0.214-0.145,0.365-0.077
               c0.151,0.077,0.273,0.146,0.373,0.207c0.09,0.056,0.328,0.101,0.486,0.146c0.16,0.037,0.215,0.183,0.189,0.028
               c-0.016-0.159-0.189-0.265-0.145-0.342c0.038-0.075,0.168-0.091,0.221,0c0.055,0.092,0.236,0.054,0.351,0.183
               c0.106,0.131,0.214,0.236,0.274,0.191c0.061-0.047,0.136-0.206,0.184-0.252c0.045-0.038,0.007-0.176-0.077-0.268
               c-0.084-0.084-0.136-0.242-0.312-0.137s-0.281,0.189-0.344,0.151c-0.054-0.046-0.168-0.13-0.234-0.267
               c-0.063-0.138-0.102-0.252-0.283-0.252s-0.448,0.146-0.669,0.086c-0.229-0.063-0.396-0.115-0.374-0.252
               c0.031-0.146,0.115-0.336,0.411-0.268c0.29,0.063,0.327,0,0.435-0.038c0.105-0.03,0.365-0.083,0.236-0.19
               c-0.123-0.104-0.223-0.158-0.381-0.104c-0.16,0.052-0.365-0.008-0.465,0.028c-0.092,0.045-0.205,0.191-0.336,0.129
               c-0.129-0.053-0.084-0.174-0.234-0.137c-0.152,0.039-0.244,0.092-0.305,0.03c-0.068-0.052,0.021-0.136-0.139-0.144
               c-0.158-0.008-0.229-0.008-0.335-0.03c-0.114-0.017-0.198-0.106-0.356-0.192c-0.162-0.09-0.223-0.149-0.281-0.004
               c-0.063,0.143-0.008,0.25-0.161,0.325c-0.146,0.084-0.196,0.038-0.344-0.007c-0.15-0.047-0.157-0.047-0.351-0.008
               c-0.188,0.029-0.517-0.031-0.654-0.047c-0.129-0.016-0.221,0.008-0.228-0.121c-0.008-0.122,0.046-0.205-0.069-0.268
               c-0.105-0.055-0.099-0.146-0.234-0.113c-0.13,0.029-0.122,0.06-0.243-0.008c-0.122-0.063-0.335-0.068-0.403-0.123
               c-0.062-0.06,0.008-0.213-0.229-0.144c-0.229,0.067-0.176,0.177-0.32,0.112c-0.137-0.061-0.152-0.092-0.259-0.098
               c-0.114-0.016-0.083-0.078-0.167-0.1C200.835,79.001,200.772,79.071,200.772,79.071z M195.931,75.88
               c-0.015,0.029-0.066,0.15-0.022,0.242c0.047,0.084,0.1,0.016,0.169,0.078c0.076,0.053,0.076,0.233,0.145,0.272
               c0.067,0.045,0.105,0.183,0.159,0.151c0.048-0.031,0.037-0.106,0.009-0.19c-0.031-0.093,0-0.114-0.076-0.206
               c-0.084-0.1-0.168-0.184-0.19-0.234c-0.022-0.054-0.045-0.152-0.114-0.152C195.931,75.841,195.931,75.88,195.931,75.88z
                M195.61,75.455c-0.029,0.014-0.075,0.062-0.029,0.137c0.045,0.068,0.158,0.13,0.184,0.138c0.016,0,0.137,0.099,0.082-0.008
               c-0.06-0.106-0.15-0.382-0.189-0.373C195.619,75.354,195.61,75.455,195.61,75.455z M194.69,73.619
               c-0.03,0.039-0.03,0.016-0.017,0.129c0.017,0.107-0.022,0.107-0.039,0.146c-0.008,0.039-0.067,0.176,0.024,0.176
               c0.091,0,0.105,0.022,0.129-0.045c0.029-0.077-0.038-0.237-0.023-0.329c0.008-0.091,0.016-0.15-0.007-0.15
               S194.69,73.619,194.69,73.619z M194.927,74.365c0,0-0.092,0.152-0.084,0.289c0,0.146,0.114,0.357,0.205,0.448
               c0.092,0.101,0.244,0.252,0.305,0.106c0.055-0.137-0.045-0.221-0.106-0.35c-0.062-0.13-0.169-0.541-0.213-0.549
               C194.979,74.311,194.927,74.365,194.927,74.365z M193.782,72.087c-0.036,0.022-0.074,0.084-0.135,0.084
               c-0.056,0-0.162-0.016-0.107,0.063c0.053,0.074,0.122,0.061,0.145,0.12c0.023,0.069,0.076,0.206,0.123,0.252
               c0.045,0.045,0.113,0.083,0.158,0.159c0.039,0.084,0.039,0.176,0.107,0.207c0.061,0.029,0.092,0.061,0.113,0.008
               c0.023-0.055,0.03-0.084,0.039-0.161c0.014-0.075,0.075-0.175-0.045-0.282c-0.123-0.104-0.154-0.127-0.177-0.211
               s-0.114-0.291-0.146-0.291C193.828,72.035,193.782,72.087,193.782,72.087z M192.429,70.847c0,0-0.146,0.099,0,0.197
               c0.137,0.099,0.152,0.016,0.281,0.121c0.129,0.101,0.29,0.229,0.318,0.176c0.039-0.061-0.066-0.176-0.157-0.213
               c-0.093-0.047-0.216-0.352-0.313-0.365C192.466,70.742,192.429,70.847,192.429,70.847z"/>
             <path fill="#808184" d="M216.413,82.414c0,0-0.084-0.101-0.068-0.252
               c0.021-0.152-0.023-0.305-0.023-0.305s0.138-0.268,0.382-0.297c0.235-0.029,0.448,0.021,0.64-0.023s0.114-0.084,0.365-0.099
               c0.251-0.007,0.381-0.138,0.447-0.054c0.07,0.084,0.283,0.146,0.033,0.205c-0.252,0.068-0.252,0.031-0.465,0.189
               c-0.207,0.161-0.261,0.236-0.502,0.245c-0.238,0.015-0.322,0.015-0.441,0.12C216.655,82.259,216.413,82.414,216.413,82.414"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M216.413,82.414c0,0-0.084-0.101-0.068-0.252c0.021-0.152-0.023-0.305-0.023-0.305s0.138-0.268,0.382-0.297
               c0.235-0.029,0.448,0.021,0.64-0.023s0.114-0.084,0.365-0.099c0.251-0.007,0.381-0.138,0.447-0.054
               c0.07,0.084,0.283,0.146,0.033,0.205c-0.252,0.068-0.252,0.031-0.465,0.189c-0.207,0.161-0.261,0.236-0.502,0.245
               c-0.238,0.015-0.322,0.015-0.441,0.12C216.655,82.259,216.413,82.414,216.413,82.414z"/>
             <path fill="#808184" d="M207.542,69.3c0.008-0.008-0.053-0.084,0.207-0.166
               c0.266-0.084,0.313-0.168,0.396-0.229c0.074-0.054,0.271-0.146,0.297-0.152c0.021,0,0.045,0.068,0.015,0.1
               s-0.084,0.066-0.084,0.129c-0.008,0.061,0.076,0.268,0.017,0.288c-0.062,0.017-0.199-0.12-0.244-0.104
               c-0.047,0.021-0.105,0.053-0.105,0.15c0,0.107,0.037,0.283-0.023,0.29c-0.061,0.009-0.106-0.106-0.176-0.157
               c-0.06-0.057-0.113-0.094-0.167-0.109C207.618,69.333,207.542,69.3,207.542,69.3"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="M207.542,69.3
               c0.008-0.008-0.053-0.084,0.207-0.166c0.266-0.084,0.313-0.168,0.396-0.229c0.074-0.054,0.271-0.146,0.297-0.152
               c0.021,0,0.045,0.068,0.015,0.1s-0.084,0.066-0.084,0.129c-0.008,0.061,0.076,0.268,0.017,0.288
               c-0.062,0.017-0.199-0.12-0.244-0.104c-0.047,0.021-0.105,0.053-0.105,0.15c0,0.107,0.037,0.283-0.023,0.29
               c-0.061,0.009-0.106-0.106-0.176-0.157c-0.06-0.057-0.113-0.094-0.167-0.109C207.618,69.333,207.542,69.3,207.542,69.3z"/>
            
            
            
            
            
          
          
          
               <path fill="#808184" d="M124.656,39.782c0,0,0.267,0.327,0.328,0.145
               c0.067-0.184,0.007-0.381-0.122-0.389c-0.129-0.007-0.343,0.023-0.343,0.099C124.52,39.72,124.656,39.782,124.656,39.782"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M124.656,39.782c0,0,0.267,0.327,0.328,0.145c0.067-0.184,0.007-0.381-0.122-0.389c-0.129-0.007-0.343,0.023-0.343,0.099
               C124.52,39.72,124.656,39.782,124.656,39.782z"/>
             <path fill="#808184" d="M139.679,2.352c-0.029,0-0.518,0-0.449,0.076
               c0.068,0.069,0.365,0.046,0.404,0.069c0.045,0.023,0.268-0.008,0.41-0.046c0.139-0.038,0.26-0.107,0.365-0.068
               c0.107,0.03,0.252-0.039,0.189,0.045c-0.062,0.084-0.373,0.137-0.41,0.183c-0.039,0.054,0.054,0.145,0.146,0.122
               c0.091-0.023,0.146-0.145,0.351-0.061c0.197,0.084,0.176,0.167,0.328,0.068c0.151-0.099,0.184-0.045,0.326-0.129
               c0.145-0.076,0.206-0.076,0.42-0.091c0.213-0.008,0.594,0.045,0.539-0.084c-0.061-0.13-0.313-0.152-0.479-0.137
               c-0.168,0.016-0.495,0.091-0.647,0.114c-0.153,0.03-0.243,0.084-0.312-0.015c-0.063-0.092-0.268-0.106-0.352-0.129
               s-0.252-0.031-0.381,0c-0.129,0.03-0.168,0.03-0.236,0.037C139.831,2.322,139.679,2.352,139.679,2.352 M146.653,2.74
               c-0.113,0.008-0.402,0.069-0.199,0.122c0.199,0.046,0.725,0,0.58-0.046C146.896,2.763,146.653,2.74,146.653,2.74 M147.552,1.841
               c-0.145,0-0.549-0.023-0.305,0.045c0.236,0.069,0.26,0.039,0.495,0.039s0.854,0.007,0.503-0.114
               C147.894,1.682,147.552,1.841,147.552,1.841 M148.45,2.168c-0.105,0.031-0.381,0.039-0.428,0.107
               c-0.053,0.068,0.076,0.13,0.176,0.137c0.105,0.015,0.556,0.023,0.775-0.007c0.229-0.039,0.283-0.229,0.168-0.259
               c-0.105-0.039-0.281-0.054-0.341-0.039C148.731,2.13,148.45,2.168,148.45,2.168 M146.797,2.223
               c-0.257,0.046-0.493,0.129-0.409,0.205c0.084,0.069,0.092,0.137,0.35,0.137c0.252,0,0.375-0.023,0.578-0.023
               c0.213,0.008,0.687-0.22,0.39-0.25c-0.289-0.032-0.457,0.106-0.524,0.037c-0.068-0.069-0.19-0.152-0.229-0.129
               C146.903,2.214,146.797,2.223,146.797,2.223 M145.237,2.604c-0.114,0-0.572-0.039-0.365,0.091
               c0.205,0.129,0.113,0.167,0.352,0.137c0.234-0.031,0.455-0.045,0.614-0.054c0.161-0.015,0.404-0.075,0.466-0.083
               c0.053-0.015,0.047-0.252-0.162-0.198c-0.205,0.045-0.537,0.038-0.615,0.045C145.442,2.558,145.237,2.604,145.237,2.604
                M143.22,2.564c-0.078,0.016-0.428,0.046-0.229,0.1c0.189,0.052,0.213,0.007,0.396,0.007c0.182,0,0.465-0.038,0.336-0.107
               C143.593,2.489,143.22,2.564,143.22,2.564 M143.913,2.168c-0.084,0.008-0.329,0.168-0.107,0.16
               c0.223-0.014,0.358,0.015,0.678,0.038c0.32,0.023,0.291-0.038,0.641-0.038c0.343,0,0.663-0.045,0.357-0.137
               c-0.306-0.091-0.776-0.129-0.373-0.168c0.404-0.045,0.418-0.06,0.479-0.098c0.063-0.031,0.068-0.297-0.121-0.214
               c-0.199,0.084-0.326,0.145-0.602,0.207c-0.268,0.052-0.184,0.052-0.403,0.128c-0.222,0.069-0.306,0.069-0.351,0.069
               C144.063,2.116,143.913,2.168,143.913,2.168"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M139.679,2.352c-0.029,0-0.518,0-0.449,0.076c0.068,0.069,0.365,0.046,0.404,0.069c0.045,0.023,0.268-0.008,0.41-0.046
               c0.139-0.038,0.26-0.107,0.365-0.068c0.107,0.03,0.252-0.039,0.189,0.045c-0.062,0.084-0.373,0.137-0.41,0.183
               c-0.039,0.054,0.054,0.145,0.146,0.122c0.091-0.023,0.146-0.145,0.351-0.061c0.197,0.084,0.176,0.167,0.328,0.068
               c0.151-0.099,0.184-0.045,0.326-0.129c0.145-0.076,0.206-0.076,0.42-0.091c0.213-0.008,0.594,0.045,0.539-0.084
               c-0.061-0.13-0.313-0.152-0.479-0.137c-0.168,0.016-0.495,0.091-0.647,0.114c-0.153,0.03-0.243,0.084-0.312-0.015
               c-0.063-0.092-0.268-0.106-0.352-0.129s-0.252-0.031-0.381,0c-0.129,0.03-0.168,0.03-0.236,0.037
               C139.831,2.322,139.679,2.352,139.679,2.352z M146.653,2.74c-0.113,0.008-0.402,0.069-0.199,0.122
               c0.199,0.046,0.725,0,0.58-0.046C146.896,2.763,146.653,2.74,146.653,2.74z M147.552,1.841c-0.145,0-0.549-0.023-0.305,0.045
               c0.236,0.069,0.26,0.039,0.495,0.039s0.854,0.007,0.503-0.114C147.894,1.682,147.552,1.841,147.552,1.841z M148.45,2.168
               c-0.105,0.031-0.381,0.039-0.428,0.107c-0.053,0.068,0.076,0.13,0.176,0.137c0.105,0.015,0.556,0.023,0.775-0.007
               c0.229-0.039,0.283-0.229,0.168-0.259c-0.105-0.039-0.281-0.054-0.341-0.039C148.731,2.13,148.45,2.168,148.45,2.168z
                M146.797,2.223c-0.257,0.046-0.493,0.129-0.409,0.205c0.084,0.069,0.092,0.137,0.35,0.137c0.252,0,0.375-0.023,0.578-0.023
               c0.213,0.008,0.687-0.22,0.39-0.25c-0.289-0.032-0.457,0.106-0.524,0.037c-0.068-0.069-0.19-0.152-0.229-0.129
               C146.903,2.214,146.797,2.223,146.797,2.223z M145.237,2.604c-0.114,0-0.572-0.039-0.365,0.091
               c0.205,0.129,0.113,0.167,0.352,0.137c0.234-0.031,0.455-0.045,0.614-0.054c0.161-0.015,0.404-0.075,0.466-0.083
               c0.053-0.015,0.047-0.252-0.162-0.198c-0.205,0.045-0.537,0.038-0.615,0.045C145.442,2.558,145.237,2.604,145.237,2.604z
                M143.22,2.564c-0.078,0.016-0.428,0.046-0.229,0.1c0.189,0.052,0.213,0.007,0.396,0.007c0.182,0,0.465-0.038,0.336-0.107
               C143.593,2.489,143.22,2.564,143.22,2.564z M143.913,2.168c-0.084,0.008-0.329,0.168-0.107,0.16
               c0.223-0.014,0.358,0.015,0.678,0.038c0.32,0.023,0.291-0.038,0.641-0.038c0.343,0,0.663-0.045,0.357-0.137
               c-0.306-0.091-0.776-0.129-0.373-0.168c0.404-0.045,0.418-0.06,0.479-0.098c0.063-0.031,0.068-0.297-0.121-0.214
               c-0.199,0.084-0.326,0.145-0.602,0.207c-0.268,0.052-0.184,0.052-0.403,0.128c-0.222,0.069-0.306,0.069-0.351,0.069
               C144.063,2.116,143.913,2.168,143.913,2.168z"/>
             <path fill="#808184" d="M199.608,72.439c-0.045,0.021-0.176,0.021-0.045,0.137
               c0.129,0.123,0.045,0.229,0.183,0.168c0.129-0.061,0.243-0.222,0.099-0.305C199.7,72.354,199.608,72.439,199.608,72.439
                M199.372,73.064c0.055,0.1,0.221,0.145,0.214,0.016c-0.017-0.139-0.03-0.199-0.114-0.177S199.372,73.064,199.372,73.064
                M199.188,72.576c-0.045,0.016-0.129,0.016-0.129,0.113c-0.008,0.1,0.053,0.176,0.107,0.123
               c0.053-0.055,0.021-0.063,0.229-0.077c0.151,0.022,0.03-0.304-0.084-0.296C199.204,72.439,199.188,72.576,199.188,72.576"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M199.608,72.439c-0.045,0.021-0.176,0.021-0.045,0.137c0.129,0.123,0.045,0.229,0.183,0.168
               c0.129-0.061,0.243-0.222,0.099-0.305C199.7,72.354,199.608,72.439,199.608,72.439z M199.372,73.064
               c0.055,0.1,0.221,0.145,0.214,0.016c-0.017-0.139-0.03-0.199-0.114-0.177S199.372,73.064,199.372,73.064z M199.188,72.576
               c-0.045,0.016-0.129,0.016-0.129,0.113c-0.008,0.1,0.053,0.176,0.107,0.123c0.053-0.055,0.021-0.063,0.229-0.077
               c0.151,0.022,0.03-0.304-0.084-0.296C199.204,72.439,199.188,72.576,199.188,72.576z"/>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <!-- europe -->
        <a class="europe" onclick='DealerShow("europe_dealer","europe");' > 
        
               <!-- pays bas -->
             <path fill="#808184" d="M119.365,23.8c-0.031-0.008-0.076-0.008-0.198,0
             c-0.267,0.015-0.313,0.022-0.519,0.007c-0.205-0.007-0.578,0.015-0.486,0.061c0.091,0.053,0.327-0.083,0.305,0.038
             c-0.016,0.115-0.16,0.107-0.168,0.206c-0.016,0.106,0.031,0.19,0.061,0.282c0.038,0.091,0.062,0.099-0.037,0.16
             c-0.092,0.053-0.328,0.137-0.313,0.03c0.015-0.114,0.281-0.35,0.137-0.35c-0.152-0.008-0.145-0.099-0.229-0.19
             c-0.084-0.099-0.159,0.015-0.183,0.182c-0.022,0.168,0.023,0.251-0.053,0.411c-0.054,0.107-0.122,0.184-0.214,0.282
             c0,0.031,0.008,0.053,0.016,0.084c0.037,0.068,0.046,0.129,0.046,0.183c0.007,0.046,0.015,0.076,0.038,0.084
             c0.083,0.037,0.137,0.205,0.159,0.289c0.023,0,0.054,0.008,0.075,0.023c0.047,0.022,0.07,0.045,0.101,0.068
             c0.046,0.046,0.091,0.076,0.197,0.091c0.054,0.016,0.123,0.016,0.19,0.016c0.16,0.007,0.313,0.015,0.357,0.106
             c0.038-0.015,0.092-0.03,0.1-0.068c0.022-0.045,0.016-0.107,0.016-0.152c-0.008-0.031-0.008-0.061-0.008-0.092
             c0.008-0.015,0-0.046-0.016-0.068c-0.015-0.054-0.03-0.115-0.015-0.198c0.022-0.137,0.183-0.114,0.312-0.099
             c0.039,0.008,0.077,0.015,0.099,0.008c0.008,0,0.016-0.016,0.016-0.031c0.016-0.046,0.023-0.099,0.067-0.152
             c0.047-0.061,0.084-0.092,0.115-0.122c0.031-0.023,0.053-0.046,0.076-0.077h-0.03c-0.046-0.007-0.091-0.007-0.138-0.03
             c-0.06-0.03-0.075-0.068-0.053-0.114c0.016-0.031,0.053-0.061,0.1-0.099c0.03-0.023,0.068-0.053,0.091-0.084
             c0.038-0.038,0.062-0.076,0.084-0.114c0.015-0.038,0.03-0.076,0.046-0.114c0.007-0.031-0.016-0.084-0.054-0.161
             c-0.023-0.029-0.046-0.068-0.062-0.113C119.365,23.921,119.357,23.854,119.365,23.8"/>
           <path fill="none" stroke="#ffffff" stroke-width="0.111" stroke-miterlimit="10" d="M119.365,23.8
             c-0.031-0.008-0.076-0.008-0.198,0c-0.267,0.015-0.313,0.022-0.519,0.007c-0.205-0.007-0.578,0.015-0.486,0.061
             c0.091,0.053,0.327-0.083,0.305,0.038c-0.016,0.115-0.16,0.107-0.168,0.206c-0.016,0.106,0.031,0.19,0.061,0.282
             c0.038,0.091,0.062,0.099-0.037,0.16c-0.092,0.053-0.328,0.137-0.313,0.03c0.015-0.114,0.281-0.35,0.137-0.35
             c-0.152-0.008-0.145-0.099-0.229-0.19c-0.084-0.099-0.159,0.015-0.183,0.182c-0.022,0.168,0.023,0.251-0.053,0.411
             c-0.054,0.107-0.122,0.184-0.214,0.282c0,0.031,0.008,0.053,0.016,0.084c0.037,0.068,0.046,0.129,0.046,0.183
             c0.007,0.046,0.015,0.076,0.038,0.084c0.083,0.037,0.137,0.205,0.159,0.289c0.023,0,0.054,0.008,0.075,0.023
             c0.047,0.022,0.07,0.045,0.101,0.068c0.046,0.046,0.091,0.076,0.197,0.091c0.054,0.016,0.123,0.016,0.19,0.016
             c0.16,0.007,0.313,0.015,0.357,0.106c0.038-0.015,0.092-0.03,0.1-0.068c0.022-0.045,0.016-0.107,0.016-0.152
             c-0.008-0.031-0.008-0.061-0.008-0.092c0.008-0.015,0-0.046-0.016-0.068c-0.015-0.054-0.03-0.115-0.015-0.198
             c0.022-0.137,0.183-0.114,0.312-0.099c0.039,0.008,0.077,0.015,0.099,0.008c0.008,0,0.016-0.016,0.016-0.031
             c0.016-0.046,0.023-0.099,0.067-0.152c0.047-0.061,0.084-0.092,0.115-0.122c0.031-0.023,0.053-0.046,0.076-0.077h-0.03
             c-0.046-0.007-0.091-0.007-0.138-0.03c-0.06-0.03-0.075-0.068-0.053-0.114c0.016-0.031,0.053-0.061,0.1-0.099
             c0.03-0.023,0.068-0.053,0.091-0.084c0.038-0.038,0.062-0.076,0.084-0.114c0.015-0.038,0.03-0.076,0.046-0.114
             c0.007-0.031-0.016-0.084-0.054-0.161c-0.023-0.029-0.046-0.068-0.062-0.113C119.365,23.921,119.357,23.854,119.365,23.8z"/>
             <!-- fin pays bas -->
        <!-- italy -->
        <path fill="#808184" d="M119.304,30.751c-0.008,0.038-0.008,0.077,0.008,0.122
             c0.022,0.054,0.068,0.099,0.099,0.13c0.076,0.076,0.13,0.136,0.046,0.22c-0.038,0.046-0.099,0.061-0.167,0.077
             c-0.069,0.014-0.138,0.037-0.122,0.084c0.008,0.045,0.038,0.053,0.068,0.068c0.061,0.03,0.122,0.053,0.13,0.175
             c0.007,0.099-0.023,0.145-0.054,0.19c-0.022,0.031-0.038,0.054-0.022,0.122l0.007,0.023c0.038,0.137,0.054,0.183,0.168,0.206
             c0.038,0.007,0.092-0.008,0.137-0.023c0.046-0.016,0.084-0.023,0.122-0.016c0.054,0.016,0.083,0.061,0.054,0.175
             c-0.023,0.092-0.016,0.191,0,0.267c0.213,0,0.19-0.061,0.427-0.183c0.244-0.129,0.318-0.388,0.57-0.358
             c0.251,0.031,0.343,0.145,0.518,0.229c0.183,0.091,0.427,0.061,0.449,0.259c0.022,0.205,0.289,0.609,0.244,0.723
             c-0.046,0.115-0.016,0.137,0.099,0.198c0.106,0.068,0.807,0.495,0.944,0.731c0.13,0.228,0.083,0.152,0.273,0.358
             c0.19,0.205,0.298,0.312,0.457,0.327c0.168,0.016,0.244,0.069,0.404,0.092c0.151,0.022,0.235,0.015,0.389,0.205
             c0.159,0.191,0.334,0.122,0.434,0.274c0.091,0.153,0,0.176,0.159,0.199c0.16,0.022,0.205,0.075,0.19,0.182
             c-0.008,0.114,0.099,0.175,0.236,0.228c0.137,0.054,0.319-0.03,0.343,0.061c0.022,0.092-0.03,0.236,0.054,0.313
             c0.084,0.069,0.197,0.342,0.235,0.487c0.03,0.137,0.046,0.213,0.076,0.244c0.03,0.03-0.121,0.106-0.183,0.159
             c-0.062,0.046-0.022,0.13-0.045,0.214c-0.023,0.083,0,0.136-0.093,0.136c-0.091,0-0.373,0.107-0.563,0.146
             c-0.19,0.045-0.206,0.015-0.42,0.075c-0.213,0.061-0.258,0.077-0.486,0.046c-0.221-0.03-0.213-0.053-0.327-0.076
             c-0.122-0.023-0.229-0.053-0.343-0.008c-0.107,0.046-0.084-0.076-0.19,0c-0.106,0.077-0.266,0.229-0.213,0.32
             c0.045,0.091,0.228,0.16,0.373,0.19c0.144,0.038,0.228,0.016,0.327,0.13c0.091,0.114,0.137,0.183,0.373,0.251
             c0.229,0.061,0.396,0.053,0.403,0.084c0.008,0.038,0.076,0.259,0.198,0.305c0.129,0.037,0.229,0.037,0.319,0.045
             c0.092,0.015,0.122,0.099,0.152-0.084c0.022-0.182,0.106-0.167,0.106-0.266c-0.007-0.107-0.229-0.175-0.152-0.35
             c0.068-0.183,0.289-0.701,0.336-0.701c0.045,0,0,0.145,0.091,0.191c0.091,0.053,0.267,0.105,0.319,0.046
             c0.054-0.069,0.023-0.252,0.101-0.282c0.067-0.023,0.243-0.13,0.243-0.236c0-0.1-0.008-0.221,0.053-0.282
             c0.063-0.061,0.236-0.046,0.33-0.129c0.082-0.077,0.119-0.297,0.014-0.427c-0.1-0.122-0.214-0.198-0.344-0.214
             c-0.129-0.014-0.159-0.182-0.105-0.259c0.063-0.068,0.176-0.539,0.319-0.547c0.146-0.015,0.046,0.091,0.19,0.137
             c0.145,0.038,0.235-0.061,0.342,0.023c0.106,0.083,0.244,0.114,0.236,0.235c-0.016,0.115,0.008,0.244,0.159,0.214
             c0.152-0.023,0.146-0.077,0.168-0.191c0.022-0.121-0.016-0.167-0.104-0.221c-0.086-0.045-0.01-0.144-0.223-0.281
             c-0.214-0.145-0.357-0.083-0.533-0.251c-0.168-0.16-0.351-0.267-0.519-0.32c-0.166-0.061-0.686-0.19-0.556-0.35
             c0.138-0.168,0.344-0.298-0.045-0.313c-0.396-0.007-0.541,0.099-0.838-0.197c-0.288-0.305-0.563-0.427-0.563-0.427
             s-0.137-0.069-0.137-0.244c0.007-0.175-0.054-0.388-0.084-0.472c-0.038-0.084-0.054-0.137-0.19-0.213
             c-0.145-0.076-0.351-0.182-0.548-0.35c-0.198-0.16-0.282-0.114-0.351-0.389c-0.068-0.266,0.19-0.35,0.114-0.502
             c-0.068-0.152-0.305-0.236-0.235-0.35c0.068-0.114,0.212-0.046,0.342-0.122c0.122-0.069,0.32-0.22,0.473-0.229
             c0.045,0,0.106-0.015,0.159-0.022c0.031-0.061,0.046-0.122,0.031-0.152c-0.016-0.024-0.031-0.046-0.039-0.062
             c-0.053-0.068-0.068-0.083-0.022-0.175c0.03-0.053,0.046-0.182,0.054-0.244c-0.076-0.022-0.16-0.045-0.244-0.06
             c-0.198-0.046-0.396-0.084-0.494-0.091c-0.1-0.008-0.176-0.023-0.229-0.061c-0.061-0.039-0.091-0.084-0.113-0.144V29.77
             c-0.016-0.038-0.031-0.076-0.054-0.099c-0.015-0.015-0.038-0.023-0.068-0.015c-0.03,0.008-0.047,0.015-0.068,0.021
             c-0.092,0.039-0.183,0.069-0.335,0.063c-0.038-0.008-0.069-0.008-0.092-0.016c-0.084-0.007-0.114-0.014-0.175,0.046
             c-0.062,0.069-0.145,0.076-0.222,0.076c-0.045,0-0.099,0-0.137,0.023c-0.038,0.016-0.046,0.053-0.054,0.084
             c-0.022,0.053-0.038,0.098-0.113,0.114c-0.046,0.016-0.106,0.031-0.145,0.054c-0.023,0.014-0.038,0.029-0.038,0.029
             c0,0.046-0.016,0.1-0.054,0.146c-0.023,0.03-0.068,0.053-0.122,0.053c-0.038,0-0.084-0.016-0.121-0.038
             c-0.062-0.031-0.13-0.061-0.183-0.008c-0.077,0.076-0.122,0.146-0.153,0.205c-0.015,0.032-0.03,0.054-0.037,0.07
             c-0.016,0.022-0.03,0.037-0.054,0.053c-0.03,0.022-0.061,0.03-0.1,0.03c-0.037,0-0.067-0.008-0.106-0.03
             c-0.022-0.016-0.038-0.039-0.061-0.062c-0.03-0.046-0.084-0.113-0.13-0.159c-0.037-0.031-0.067-0.061-0.09-0.061
             c-0.031-0.008-0.107-0.008-0.16,0.015c-0.031,0.015-0.054,0.03-0.062,0.061c-0.03,0.122-0.084,0.167-0.122,0.206l-0.016,0.007
             c-0.037,0.031-0.114,0.023-0.198,0.023c-0.045-0.008-0.091-0.008-0.129-0.008c-0.045,0-0.083,0.031-0.121,0.054
             c-0.062,0.038-0.106,0.076-0.176,0.06C119.35,30.759,119.326,30.751,119.304,30.751 M121.718,33.439
             c-0.016,0-0.213,0.091-0.138,0.107c0.069,0.015,0.106,0.022,0.183,0.015c0.077-0.008,0.176-0.138,0.106-0.167
             C121.794,33.372,121.718,33.439,121.718,33.439"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M119.304,30.751c-0.008,0.038-0.008,0.077,0.008,0.122c0.022,0.054,0.068,0.099,0.099,0.13c0.076,0.076,0.13,0.136,0.046,0.22
             c-0.038,0.046-0.099,0.061-0.167,0.077c-0.069,0.014-0.138,0.037-0.122,0.084c0.008,0.045,0.038,0.053,0.068,0.068
             c0.061,0.03,0.122,0.053,0.13,0.175c0.007,0.099-0.023,0.145-0.054,0.19c-0.022,0.031-0.038,0.054-0.022,0.122l0.007,0.023
             c0.038,0.137,0.054,0.183,0.168,0.206c0.038,0.007,0.092-0.008,0.137-0.023c0.046-0.016,0.084-0.023,0.122-0.016
             c0.054,0.016,0.083,0.061,0.054,0.175c-0.023,0.092-0.016,0.191,0,0.267c0.213,0,0.19-0.061,0.427-0.183
             c0.244-0.129,0.318-0.388,0.57-0.358c0.251,0.031,0.343,0.145,0.518,0.229c0.183,0.091,0.427,0.061,0.449,0.259
             c0.022,0.205,0.289,0.609,0.244,0.723c-0.046,0.115-0.016,0.137,0.099,0.198c0.106,0.068,0.807,0.495,0.944,0.731
             c0.13,0.228,0.083,0.152,0.273,0.358c0.19,0.205,0.298,0.312,0.457,0.327c0.168,0.016,0.244,0.069,0.404,0.092
             c0.151,0.022,0.235,0.015,0.389,0.205c0.159,0.191,0.334,0.122,0.434,0.274c0.091,0.153,0,0.176,0.159,0.199
             c0.16,0.022,0.205,0.075,0.19,0.182c-0.008,0.114,0.099,0.175,0.236,0.228c0.137,0.054,0.319-0.03,0.343,0.061
             c0.022,0.092-0.03,0.236,0.054,0.313c0.084,0.069,0.197,0.342,0.235,0.487c0.03,0.137,0.046,0.213,0.076,0.244
             c0.03,0.03-0.121,0.106-0.183,0.159c-0.062,0.046-0.022,0.13-0.045,0.214c-0.023,0.083,0,0.136-0.093,0.136
             c-0.091,0-0.373,0.107-0.563,0.146c-0.19,0.045-0.206,0.015-0.42,0.075c-0.213,0.061-0.258,0.077-0.486,0.046
             c-0.221-0.03-0.213-0.053-0.327-0.076c-0.122-0.023-0.229-0.053-0.343-0.008c-0.107,0.046-0.084-0.076-0.19,0
             c-0.106,0.077-0.266,0.229-0.213,0.32c0.045,0.091,0.228,0.16,0.373,0.19c0.144,0.038,0.228,0.016,0.327,0.13
             c0.091,0.114,0.137,0.183,0.373,0.251c0.229,0.061,0.396,0.053,0.403,0.084c0.008,0.038,0.076,0.259,0.198,0.305
             c0.129,0.037,0.229,0.037,0.319,0.045c0.092,0.015,0.122,0.099,0.152-0.084c0.022-0.182,0.106-0.167,0.106-0.266
             c-0.007-0.107-0.229-0.175-0.152-0.35c0.068-0.183,0.289-0.701,0.336-0.701c0.045,0,0,0.145,0.091,0.191
             c0.091,0.053,0.267,0.105,0.319,0.046c0.054-0.069,0.023-0.252,0.101-0.282c0.067-0.023,0.243-0.13,0.243-0.236
             c0-0.1-0.008-0.221,0.053-0.282c0.063-0.061,0.236-0.046,0.33-0.129c0.082-0.077,0.119-0.297,0.014-0.427
             c-0.1-0.122-0.214-0.198-0.344-0.214c-0.129-0.014-0.159-0.182-0.105-0.259c0.063-0.068,0.176-0.539,0.319-0.547
             c0.146-0.015,0.046,0.091,0.19,0.137c0.145,0.038,0.235-0.061,0.342,0.023c0.106,0.083,0.244,0.114,0.236,0.235
             c-0.016,0.115,0.008,0.244,0.159,0.214c0.152-0.023,0.146-0.077,0.168-0.191c0.022-0.121-0.016-0.167-0.104-0.221
             c-0.086-0.045-0.01-0.144-0.223-0.281c-0.214-0.145-0.357-0.083-0.533-0.251c-0.168-0.16-0.351-0.267-0.519-0.32
             c-0.166-0.061-0.686-0.19-0.556-0.35c0.138-0.168,0.344-0.298-0.045-0.313c-0.396-0.007-0.541,0.099-0.838-0.197
             c-0.288-0.305-0.563-0.427-0.563-0.427s-0.137-0.069-0.137-0.244c0.007-0.175-0.054-0.388-0.084-0.472
             c-0.038-0.084-0.054-0.137-0.19-0.213c-0.145-0.076-0.351-0.182-0.548-0.35c-0.198-0.16-0.282-0.114-0.351-0.389
             c-0.068-0.266,0.19-0.35,0.114-0.502c-0.068-0.152-0.305-0.236-0.235-0.35c0.068-0.114,0.212-0.046,0.342-0.122
             c0.122-0.069,0.32-0.22,0.473-0.229c0.045,0,0.106-0.015,0.159-0.022c0.031-0.061,0.046-0.122,0.031-0.152
             c-0.016-0.024-0.031-0.046-0.039-0.062c-0.053-0.068-0.068-0.083-0.022-0.175c0.03-0.053,0.046-0.182,0.054-0.244
             c-0.076-0.022-0.16-0.045-0.244-0.06c-0.198-0.046-0.396-0.084-0.494-0.091c-0.1-0.008-0.176-0.023-0.229-0.061
             c-0.061-0.039-0.091-0.084-0.113-0.144V29.77c-0.016-0.038-0.031-0.076-0.054-0.099c-0.015-0.015-0.038-0.023-0.068-0.015
             c-0.03,0.008-0.047,0.015-0.068,0.021c-0.092,0.039-0.183,0.069-0.335,0.063c-0.038-0.008-0.069-0.008-0.092-0.016
             c-0.084-0.007-0.114-0.014-0.175,0.046c-0.062,0.069-0.145,0.076-0.222,0.076c-0.045,0-0.099,0-0.137,0.023
             c-0.038,0.016-0.046,0.053-0.054,0.084c-0.022,0.053-0.038,0.098-0.113,0.114c-0.046,0.016-0.106,0.031-0.145,0.054
             c-0.023,0.014-0.038,0.029-0.038,0.029c0,0.046-0.016,0.1-0.054,0.146c-0.023,0.03-0.068,0.053-0.122,0.053
             c-0.038,0-0.084-0.016-0.121-0.038c-0.062-0.031-0.13-0.061-0.183-0.008c-0.077,0.076-0.122,0.146-0.153,0.205
             c-0.015,0.032-0.03,0.054-0.037,0.07c-0.016,0.022-0.03,0.037-0.054,0.053c-0.03,0.022-0.061,0.03-0.1,0.03
             c-0.037,0-0.067-0.008-0.106-0.03c-0.022-0.016-0.038-0.039-0.061-0.062c-0.03-0.046-0.084-0.113-0.13-0.159
             c-0.037-0.031-0.067-0.061-0.09-0.061c-0.031-0.008-0.107-0.008-0.16,0.015c-0.031,0.015-0.054,0.03-0.062,0.061
             c-0.03,0.122-0.084,0.167-0.122,0.206l-0.016,0.007c-0.037,0.031-0.114,0.023-0.198,0.023c-0.045-0.008-0.091-0.008-0.129-0.008
             c-0.045,0-0.083,0.031-0.121,0.054c-0.062,0.038-0.106,0.076-0.176,0.06C119.35,30.759,119.326,30.751,119.304,30.751z
              M121.718,33.439c-0.016,0-0.213,0.091-0.138,0.107c0.069,0.015,0.106,0.022,0.183,0.015c0.077-0.008,0.176-0.138,0.106-0.167
             C121.794,33.372,121.718,33.439,121.718,33.439z"/>
             
           
           
             <path fill="#808184" d="M120.896,34.97c-0.008,0.038-0.236,0.243-0.327,0.274
             c-0.092,0.023-0.152,0.053-0.259,0.038c-0.1-0.015-0.168,0.077-0.16,0.16c0.015,0.084,0.137,0.076,0.183,0.19
             c0.045,0.122,0,0.175,0.045,0.312c0.046,0.137-0.129,0.084-0.045,0.22c0.091,0.139,0.152,0.123,0.106,0.223
             c-0.053,0.091-0.16,0.501-0.092,0.578c0.069,0.068,0.16,0.099,0.268,0.129c0.106,0.038,0.228,0.046,0.235-0.053
             c0-0.099-0.046-0.328,0.083-0.252c0.138,0.069,0.26,0.13,0.298,0.069c0.03-0.054,0.03-0.13,0.068-0.312
             c0.03-0.183,0.075-0.266,0.046-0.45c-0.023-0.182-0.023-0.251,0.022-0.35c0.038-0.098,0.174-0.098,0.062-0.258
             c-0.123-0.161-0.107-0.236-0.16-0.343c-0.054-0.107-0.138-0.206-0.206-0.214C120.987,34.924,120.896,34.97,120.896,34.97"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M120.896,34.97c-0.008,0.038-0.236,0.243-0.327,0.274c-0.092,0.023-0.152,0.053-0.259,0.038c-0.1-0.015-0.168,0.077-0.16,0.16
             c0.015,0.084,0.137,0.076,0.183,0.19c0.045,0.122,0,0.175,0.045,0.312c0.046,0.137-0.129,0.084-0.045,0.22
             c0.091,0.139,0.152,0.123,0.106,0.223c-0.053,0.091-0.16,0.501-0.092,0.578c0.069,0.068,0.16,0.099,0.268,0.129
             c0.106,0.038,0.228,0.046,0.235-0.053c0-0.099-0.046-0.328,0.083-0.252c0.138,0.069,0.26,0.13,0.298,0.069
             c0.03-0.054,0.03-0.13,0.068-0.312c0.03-0.183,0.075-0.266,0.046-0.45c-0.023-0.182-0.023-0.251,0.022-0.35
             c0.038-0.098,0.174-0.098,0.062-0.258c-0.123-0.161-0.107-0.236-0.16-0.343c-0.054-0.107-0.138-0.206-0.206-0.214
             C120.987,34.924,120.896,34.97,120.896,34.97z"/>
           <path fill="#808184" d="M121.108,33.265c-0.038,0.015-0.076,0.053-0.069,0.137
             c0.009,0.083-0.029,0.129-0.067,0.145c-0.046,0.015-0.122,0.045-0.244,0.106c-0.129,0.061-0.206,0.099-0.213,0.152
             c0,0.053,0.045,0.114,0.016,0.16c-0.038,0.046-0.1,0.053-0.031,0.107c0.069,0.053,0.145,0.113,0.092,0.152
             c-0.054,0.045-0.092,0.076-0.038,0.107c0.053,0.03,0.076,0.083,0.076,0.114c0,0.022,0.068,0.129,0.076,0.152
             c0.007,0.023,0.007,0.076,0.062,0.099c0.053,0.023,0.106,0.023,0.137,0.069c0.037,0.038,0.053,0.076,0.084,0.015
             c0.022-0.054,0.052-0.091,0.083-0.137c0.03-0.053-0.008-0.145,0.046-0.252c0.062-0.113,0.152-0.206,0.152-0.273
             c0-0.077-0.038-0.061-0.046-0.229c0-0.16-0.045-0.297-0.038-0.388c0.008-0.091,0.016-0.259,0-0.267
             C121.162,33.226,121.108,33.265,121.108,33.265"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M121.108,33.265c-0.038,0.015-0.076,0.053-0.069,0.137c0.009,0.083-0.029,0.129-0.067,0.145
             c-0.046,0.015-0.122,0.045-0.244,0.106c-0.129,0.061-0.206,0.099-0.213,0.152c0,0.053,0.045,0.114,0.016,0.16
             c-0.038,0.046-0.1,0.053-0.031,0.107c0.069,0.053,0.145,0.113,0.092,0.152c-0.054,0.045-0.092,0.076-0.038,0.107
             c0.053,0.03,0.076,0.083,0.076,0.114c0,0.022,0.068,0.129,0.076,0.152c0.007,0.023,0.007,0.076,0.062,0.099
             c0.053,0.023,0.106,0.023,0.137,0.069c0.037,0.038,0.053,0.076,0.084,0.015c0.022-0.054,0.052-0.091,0.083-0.137
             c0.03-0.053-0.008-0.145,0.046-0.252c0.062-0.113,0.152-0.206,0.152-0.273c0-0.077-0.038-0.061-0.046-0.229
             c0-0.16-0.045-0.297-0.038-0.388c0.008-0.091,0.016-0.259,0-0.267C121.162,33.226,121.108,33.265,121.108,33.265z"/>
           
           
           
        <!-- fin italy -->
        
        <!-- suice -->
        <path fill="#808184" d="M119.776,29.146c-0.023,0.038-0.046,0.068-0.077,0.084
             c-0.03,0.015-0.067,0.022-0.113,0.037c-0.084,0.023-0.183,0.046-0.229,0.1c-0.046,0.046-0.068,0.084-0.099,0.121
             c-0.031,0.038-0.062,0.076-0.107,0.13c-0.022,0.03-0.053,0.053-0.075,0.076c-0.062,0.053-0.107,0.099-0.069,0.221
             c0.023,0.084,0.092,0.122,0.152,0.16c0.068,0.038,0.13,0.075,0.122,0.167c0,0.031,0,0.046-0.008,0.061
             c-0.008,0.084-0.015,0.153,0.023,0.198c0.045,0.046,0.037,0.091,0.029,0.152c0.023,0,0.046,0.008,0.084,0.016
             c0.016,0,0.055-0.023,0.084-0.046c0.054-0.031,0.106-0.068,0.175-0.068c0.046-0.008,0.1,0,0.145,0
             c0.062,0.007,0.122,0.007,0.13,0l0.008-0.008c0.03-0.024,0.068-0.053,0.091-0.145c0.016-0.069,0.062-0.106,0.122-0.129
             c0.076-0.031,0.176-0.031,0.222-0.023c0.037,0.008,0.083,0.038,0.136,0.083c0.055,0.054,0.107,0.123,0.146,0.175
             c0.016,0.015,0.022,0.032,0.038,0.039c0.016,0.008,0.03,0.015,0.045,0.015c0.017,0,0.031-0.007,0.047-0.015
             c0.007-0.007,0.015-0.015,0.015-0.024c0.015-0.022,0.03-0.037,0.038-0.06c0.038-0.069,0.083-0.145,0.175-0.236
             c0.106-0.099,0.206-0.053,0.289-0.008c0.038,0.015,0.062,0.031,0.084,0.031c0.024,0,0.038-0.008,0.046-0.023
             c0.023-0.023,0.03-0.053,0.03-0.077c0-0.045,0.032-0.083,0.084-0.113c0.054-0.031,0.121-0.054,0.175-0.069
             c0.023-0.008,0.03-0.023,0.038-0.046c-0.061-0.023-0.167-0.061-0.235-0.136c-0.054-0.062-0.077-0.069-0.106-0.077
             c-0.031-0.007-0.054-0.022-0.1-0.045c-0.016-0.016-0.053-0.024-0.083-0.039c-0.039-0.015-0.084-0.03-0.107-0.053
             c-0.053-0.046-0.061-0.099,0-0.176c0.062-0.067,0.1-0.136,0.114-0.174c-0.106-0.038-0.152-0.061-0.19-0.084
             c-0.038-0.014-0.053-0.023-0.122-0.031c-0.137,0-0.144-0.007-0.381-0.091l-0.061-0.022c-0.13-0.054-0.175-0.008-0.229,0.046
             c-0.053,0.045-0.113,0.106-0.235,0.121C119.905,29.168,119.822,29.161,119.776,29.146"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M119.776,29.146c-0.023,0.038-0.046,0.068-0.077,0.084c-0.03,0.015-0.067,0.022-0.113,0.037c-0.084,0.023-0.183,0.046-0.229,0.1
             c-0.046,0.046-0.068,0.084-0.099,0.121c-0.031,0.038-0.062,0.076-0.107,0.13c-0.022,0.03-0.053,0.053-0.075,0.076
             c-0.062,0.053-0.107,0.099-0.069,0.221c0.023,0.084,0.092,0.122,0.152,0.16c0.068,0.038,0.13,0.075,0.122,0.167
             c0,0.031,0,0.046-0.008,0.061c-0.008,0.084-0.015,0.153,0.023,0.198c0.045,0.046,0.037,0.091,0.029,0.152
             c0.023,0,0.046,0.008,0.084,0.016c0.016,0,0.055-0.023,0.084-0.046c0.054-0.031,0.106-0.068,0.175-0.068
             c0.046-0.008,0.1,0,0.145,0c0.062,0.007,0.122,0.007,0.13,0l0.008-0.008c0.03-0.024,0.068-0.053,0.091-0.145
             c0.016-0.069,0.062-0.106,0.122-0.129c0.076-0.031,0.176-0.031,0.222-0.023c0.037,0.008,0.083,0.038,0.136,0.083
             c0.055,0.054,0.107,0.123,0.146,0.175c0.016,0.015,0.022,0.032,0.038,0.039c0.016,0.008,0.03,0.015,0.045,0.015
             c0.017,0,0.031-0.007,0.047-0.015c0.007-0.007,0.015-0.015,0.015-0.024c0.015-0.022,0.03-0.037,0.038-0.06
             c0.038-0.069,0.083-0.145,0.175-0.236c0.106-0.099,0.206-0.053,0.289-0.008c0.038,0.015,0.062,0.031,0.084,0.031
             c0.024,0,0.038-0.008,0.046-0.023c0.023-0.023,0.03-0.053,0.03-0.077c0-0.045,0.032-0.083,0.084-0.113
             c0.054-0.031,0.121-0.054,0.175-0.069c0.023-0.008,0.03-0.023,0.038-0.046c-0.061-0.023-0.167-0.061-0.235-0.136
             c-0.054-0.062-0.077-0.069-0.106-0.077c-0.031-0.007-0.054-0.022-0.1-0.045c-0.016-0.016-0.053-0.024-0.083-0.039
             c-0.039-0.015-0.084-0.03-0.107-0.053c-0.053-0.046-0.061-0.099,0-0.176c0.062-0.067,0.1-0.136,0.114-0.174
             c-0.106-0.038-0.152-0.061-0.19-0.084c-0.038-0.014-0.053-0.023-0.122-0.031c-0.137,0-0.144-0.007-0.381-0.091l-0.061-0.022
             c-0.13-0.054-0.175-0.008-0.229,0.046c-0.053,0.045-0.113,0.106-0.235,0.121C119.905,29.168,119.822,29.161,119.776,29.146z"/>
         <!-- fin suicce -->
        
        <!-- germany -->
        <path fill="#808184" d="M124.467,23.519c-0.092-0.054,0-0.289-0.252-0.267
             c-0.267,0.023-0.411,0.062-0.381-0.03c0.029-0.091,0.373-0.259,0.183-0.343c-0.19-0.076-0.281-0.197-0.35-0.037
             c-0.068,0.152-0.092,0.19-0.206,0.152c-0.114-0.031-0.213-0.023-0.327,0.046c-0.106,0.068-0.297,0.091-0.411,0.159
             c-0.114,0.069-0.579,0.175-0.556,0.069c0.022-0.115,0.251-0.556,0.061-0.389c-0.19,0.176-0.274,0.206-0.373,0.153
             c-0.099-0.061-0.313,0.023-0.327-0.153c-0.008-0.091,0.008-0.152,0.015-0.197c-0.106-0.016-0.266-0.023-0.364-0.008
             c-0.153,0.015-0.189-0.008-0.245-0.046c-0.007-0.007-0.015-0.016-0.022-0.023h-0.007c-0.008-0.007-0.038,0-0.084,0.016
             c-0.046,0.015-0.106,0.03-0.183,0.038c0.007,0.068,0.015,0.136,0.046,0.183c0.067,0.083,0.106,0.106-0.016,0.144
             c-0.122,0.031-0.122,0.107-0.038,0.153c0.084,0.045,0.121,0.029,0.121,0.113s-0.045,0.077-0.067,0.122
             c-0.023,0.046-0.106-0.038-0.138,0.076c-0.038,0.122-0.068,0.099-0.106,0.19c-0.038,0.1-0.053,0.168-0.145,0.138
             c-0.099-0.031-0.031-0.16-0.152-0.168c-0.114,0-0.389-0.114-0.495-0.038c-0.099,0.076-0.114,0.236-0.183,0.259
             c0,0.031,0.008,0.068,0.022,0.106c0.016,0.046,0.038,0.084,0.054,0.115c0.054,0.099,0.092,0.167,0.061,0.243
             c-0.015,0.038-0.03,0.077-0.053,0.122c-0.023,0.046-0.046,0.092-0.092,0.138c-0.03,0.037-0.068,0.068-0.106,0.098
             c-0.023,0.015-0.038,0.031-0.054,0.038c0.03,0.016,0.062,0.016,0.092,0.024c0.045,0,0.076,0,0.099,0.022h0.008
             c0.038,0.023,0.046,0.061,0.008,0.114c-0.03,0.054-0.061,0.084-0.099,0.115c-0.031,0.023-0.062,0.053-0.1,0.106
             c-0.038,0.038-0.045,0.076-0.054,0.115c-0.008,0.061-0.022,0.099-0.106,0.114c-0.03,0-0.075-0.008-0.122-0.008
             c-0.083-0.015-0.189-0.03-0.197,0.008c-0.015,0.061,0,0.106,0.016,0.152c0.015,0.038,0.023,0.068,0.023,0.107v0.076
             c0,0.06,0.006,0.122-0.017,0.19c-0.03,0.091-0.137,0.13-0.198,0.137c-0.007,0.038-0.029,0.077-0.045,0.106
             c-0.016,0.031-0.03,0.062-0.038,0.084c0.038,0.015,0.068,0.015,0.099,0.015c0.092,0.008,0.16,0.016,0.183,0.122
             c0.008,0.031,0.03,0.046,0.054,0.061c0.053,0.046,0.099,0.084,0.061,0.206l-0.008,0.007c-0.038,0.115-0.053,0.153-0.144,0.214
             c0.037,0.053,0.091,0.129,0.113,0.191c0.016,0.029,0.03,0.045,0.046,0.06c0.023,0.023,0.038,0.046,0.068,0.122
             c0.039,0.099-0.008,0.182-0.045,0.228l0.015,0.015c0.03,0.023,0.062,0.054,0.106,0.077c0.054,0.037,0.069,0.076,0.084,0.107
             c0.022,0.045,0.038,0.083,0.221,0.091c0.145,0.007,0.244-0.016,0.32-0.038c0.114-0.024,0.183-0.039,0.258,0.038
             c0.062,0.061,0.107,0.083,0.153,0.098c0.037,0.016,0.076,0.031,0.106,0.062c0.007,0.007,0.007,0.015,0.015,0.022
             c0.016,0.038,0.016,0.077,0,0.115c-0.015,0.038-0.038,0.068-0.067,0.098c-0.023,0.023-0.054,0.039-0.084,0.061
             c-0.054,0.023-0.084,0.054-0.1,0.092c-0.023,0.03-0.031,0.084-0.031,0.144c0,0.084-0.037,0.122-0.084,0.168
             c-0.037,0.031-0.067,0.068-0.067,0.152c0,0.114-0.008,0.251-0.031,0.359v0.014c0.023,0.008,0.077,0.015,0.214,0
             c0.092-0.007,0.138-0.053,0.184-0.091c0.075-0.076,0.144-0.137,0.334-0.069l0.062,0.023c0.213,0.084,0.221,0.084,0.35,0.091
             c0.084,0.008,0.107,0.015,0.16,0.039c0.038,0.022,0.092,0.045,0.212,0.084c0.101,0.03,0.168,0.068,0.229,0.099
             c0.084,0.045,0.129,0.068,0.221,0.022c0.046-0.022,0.046-0.061,0.046-0.083c0-0.016,0-0.038,0.008-0.054
             c0.016-0.053,0.061-0.075,0.213-0.023c0.076,0.023,0.146,0.046,0.19,0.061c0.121,0.046,0.176,0.069,0.236,0.031
             c0.114-0.069,0.198-0.077,0.342-0.099c0.047-0.008,0.092-0.038,0.146-0.069c0.106-0.068,0.229-0.144,0.35-0.06
             c0.077,0.045,0.145,0.084,0.19,0.098c0.038,0.015,0.068,0.015,0.092,0.008c0.03-0.022,0.061-0.022,0.084-0.03
             c0.015,0,0.03,0,0.03-0.009c0.008,0-0.016,0-0.046-0.045c-0.053-0.076-0.084-0.145-0.106-0.206
             c-0.022-0.083-0.022-0.152-0.016-0.19h0.008v-0.007c0.016-0.031,0.19-0.183,0.381-0.313c0.099-0.068,0.213-0.137,0.304-0.175
             c-0.015-0.038-0.045-0.107-0.083-0.129c-0.016-0.007-0.054-0.016-0.092-0.03c-0.091-0.015-0.189-0.039-0.243-0.114
             c-0.038-0.047-0.084-0.108-0.137-0.168c-0.046-0.054-0.1-0.099-0.152-0.122c-0.069-0.03-0.114-0.061-0.146-0.099
             c-0.037-0.038-0.053-0.084-0.068-0.129c-0.007-0.008-0.007-0.031-0.015-0.046c-0.022-0.061-0.046-0.115-0.038-0.198
             c0-0.031-0.008-0.054-0.016-0.069c-0.015-0.023-0.046-0.03-0.068-0.046c-0.038-0.007-0.099-0.068-0.137-0.129
             c-0.016-0.03-0.031-0.053-0.039-0.076c-0.007-0.03-0.007-0.068,0.008-0.092c0.009-0.015,0.023-0.022,0.039-0.03
             c0.053-0.015,0.084-0.007,0.114,0c0.015,0,0.022,0,0.03-0.007c0.015-0.008,0.022-0.016,0.03-0.031
             c0.054-0.062,0.114-0.137,0.266-0.114c0.069,0.007,0.115,0,0.153-0.007c0.038-0.016,0.068-0.038,0.106-0.062
             c0.022-0.015,0.038-0.031,0.054-0.053c0.053-0.053,0.099-0.099,0.235-0.106c0.084,0,0.13-0.031,0.168-0.054
             c0.029-0.015,0.053-0.03,0.099-0.03c0.022,0,0.084-0.023,0.13-0.054c0.046-0.023,0.083-0.061,0.099-0.091
             c0.022-0.053,0.054-0.099,0.091-0.114c0.054-0.031,0.107-0.015,0.152,0.076c0.031,0.054,0.062,0.092,0.092,0.115
             c0.023,0.014,0.046,0.022,0.054,0.022c0.03-0.008,0.061-0.022,0.091-0.038c0-0.007-0.008-0.022-0.008-0.038
             c-0.007-0.046-0.007-0.107,0.008-0.152c0.008-0.046-0.008-0.099-0.023-0.16c-0.007-0.031-0.015-0.068-0.022-0.107
             c-0.008-0.045-0.03-0.068-0.084-0.099c-0.029-0.03-0.084-0.061-0.136-0.114c-0.1-0.099-0.084-0.144-0.039-0.19
             c0.008-0.015,0.023-0.023,0.031-0.031c0.022-0.023,0.053-0.046,0.06-0.084c0-0.061-0.022-0.129-0.052-0.197
             c-0.031-0.084-0.069-0.175-0.054-0.275c0.015-0.053-0.03-0.06-0.077-0.068c-0.061-0.007-0.121-0.015-0.182-0.062
             c-0.084-0.06-0.076-0.121-0.038-0.182c0.021-0.03,0.053-0.053,0.084-0.076c0.021-0.015,0.037-0.031,0.045-0.046
             c0.016-0.015,0.016-0.053,0.016-0.106c0-0.092-0.023-0.206-0.039-0.274C124.481,23.747,124.474,23.625,124.467,23.519"/>
           <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
             M124.467,23.519c-0.092-0.054,0-0.289-0.252-0.267c-0.267,0.023-0.411,0.062-0.381-0.03c0.029-0.091,0.373-0.259,0.183-0.343
             c-0.19-0.076-0.281-0.197-0.35-0.037c-0.068,0.152-0.092,0.19-0.206,0.152c-0.114-0.031-0.213-0.023-0.327,0.046
             c-0.106,0.068-0.297,0.091-0.411,0.159c-0.114,0.069-0.579,0.175-0.556,0.069c0.022-0.115,0.251-0.556,0.061-0.389
             c-0.19,0.176-0.274,0.206-0.373,0.153c-0.099-0.061-0.313,0.023-0.327-0.153c-0.008-0.091,0.008-0.152,0.015-0.197
             c-0.106-0.016-0.266-0.023-0.364-0.008c-0.153,0.015-0.189-0.008-0.245-0.046c-0.007-0.007-0.015-0.016-0.022-0.023h-0.007
             c-0.008-0.007-0.038,0-0.084,0.016c-0.046,0.015-0.106,0.03-0.183,0.038c0.007,0.068,0.015,0.136,0.046,0.183
             c0.067,0.083,0.106,0.106-0.016,0.144c-0.122,0.031-0.122,0.107-0.038,0.153c0.084,0.045,0.121,0.029,0.121,0.113
             s-0.045,0.077-0.067,0.122c-0.023,0.046-0.106-0.038-0.138,0.076c-0.038,0.122-0.068,0.099-0.106,0.19
             c-0.038,0.1-0.053,0.168-0.145,0.138c-0.099-0.031-0.031-0.16-0.152-0.168c-0.114,0-0.389-0.114-0.495-0.038
             c-0.099,0.076-0.114,0.236-0.183,0.259c0,0.031,0.008,0.068,0.022,0.106c0.016,0.046,0.038,0.084,0.054,0.115
             c0.054,0.099,0.092,0.167,0.061,0.243c-0.015,0.038-0.03,0.077-0.053,0.122c-0.023,0.046-0.046,0.092-0.092,0.138
             c-0.03,0.037-0.068,0.068-0.106,0.098c-0.023,0.015-0.038,0.031-0.054,0.038c0.03,0.016,0.062,0.016,0.092,0.024
             c0.045,0,0.076,0,0.099,0.022h0.008c0.038,0.023,0.046,0.061,0.008,0.114c-0.03,0.054-0.061,0.084-0.099,0.115
             c-0.031,0.023-0.062,0.053-0.1,0.106c-0.038,0.038-0.045,0.076-0.054,0.115c-0.008,0.061-0.022,0.099-0.106,0.114
             c-0.03,0-0.075-0.008-0.122-0.008c-0.083-0.015-0.189-0.03-0.197,0.008c-0.015,0.061,0,0.106,0.016,0.152
             c0.015,0.038,0.023,0.068,0.023,0.107v0.076c0,0.06,0.006,0.122-0.017,0.19c-0.03,0.091-0.137,0.13-0.198,0.137
             c-0.007,0.038-0.029,0.077-0.045,0.106c-0.016,0.031-0.03,0.062-0.038,0.084c0.038,0.015,0.068,0.015,0.099,0.015
             c0.092,0.008,0.16,0.016,0.183,0.122c0.008,0.031,0.03,0.046,0.054,0.061c0.053,0.046,0.099,0.084,0.061,0.206l-0.008,0.007
             c-0.038,0.115-0.053,0.153-0.144,0.214c0.037,0.053,0.091,0.129,0.113,0.191c0.016,0.029,0.03,0.045,0.046,0.06
             c0.023,0.023,0.038,0.046,0.068,0.122c0.039,0.099-0.008,0.182-0.045,0.228l0.015,0.015c0.03,0.023,0.062,0.054,0.106,0.077
             c0.054,0.037,0.069,0.076,0.084,0.107c0.022,0.045,0.038,0.083,0.221,0.091c0.145,0.007,0.244-0.016,0.32-0.038
             c0.114-0.024,0.183-0.039,0.258,0.038c0.062,0.061,0.107,0.083,0.153,0.098c0.037,0.016,0.076,0.031,0.106,0.062
             c0.007,0.007,0.007,0.015,0.015,0.022c0.016,0.038,0.016,0.077,0,0.115c-0.015,0.038-0.038,0.068-0.067,0.098
             c-0.023,0.023-0.054,0.039-0.084,0.061c-0.054,0.023-0.084,0.054-0.1,0.092c-0.023,0.03-0.031,0.084-0.031,0.144
             c0,0.084-0.037,0.122-0.084,0.168c-0.037,0.031-0.067,0.068-0.067,0.152c0,0.114-0.008,0.251-0.031,0.359v0.014
             c0.023,0.008,0.077,0.015,0.214,0c0.092-0.007,0.138-0.053,0.184-0.091c0.075-0.076,0.144-0.137,0.334-0.069l0.062,0.023
             c0.213,0.084,0.221,0.084,0.35,0.091c0.084,0.008,0.107,0.015,0.16,0.039c0.038,0.022,0.092,0.045,0.212,0.084
             c0.101,0.03,0.168,0.068,0.229,0.099c0.084,0.045,0.129,0.068,0.221,0.022c0.046-0.022,0.046-0.061,0.046-0.083
             c0-0.016,0-0.038,0.008-0.054c0.016-0.053,0.061-0.075,0.213-0.023c0.076,0.023,0.146,0.046,0.19,0.061
             c0.121,0.046,0.176,0.069,0.236,0.031c0.114-0.069,0.198-0.077,0.342-0.099c0.047-0.008,0.092-0.038,0.146-0.069
             c0.106-0.068,0.229-0.144,0.35-0.06c0.077,0.045,0.145,0.084,0.19,0.098c0.038,0.015,0.068,0.015,0.092,0.008
             c0.03-0.022,0.061-0.022,0.084-0.03c0.015,0,0.03,0,0.03-0.009c0.008,0-0.016,0-0.046-0.045
             c-0.053-0.076-0.084-0.145-0.106-0.206c-0.022-0.083-0.022-0.152-0.016-0.19h0.008v-0.007c0.016-0.031,0.19-0.183,0.381-0.313
             c0.099-0.068,0.213-0.137,0.304-0.175c-0.015-0.038-0.045-0.107-0.083-0.129c-0.016-0.007-0.054-0.016-0.092-0.03
             c-0.091-0.015-0.189-0.039-0.243-0.114c-0.038-0.047-0.084-0.108-0.137-0.168c-0.046-0.054-0.1-0.099-0.152-0.122
             c-0.069-0.03-0.114-0.061-0.146-0.099c-0.037-0.038-0.053-0.084-0.068-0.129c-0.007-0.008-0.007-0.031-0.015-0.046
             c-0.022-0.061-0.046-0.115-0.038-0.198c0-0.031-0.008-0.054-0.016-0.069c-0.015-0.023-0.046-0.03-0.068-0.046
             c-0.038-0.007-0.099-0.068-0.137-0.129c-0.016-0.03-0.031-0.053-0.039-0.076c-0.007-0.03-0.007-0.068,0.008-0.092
             c0.009-0.015,0.023-0.022,0.039-0.03c0.053-0.015,0.084-0.007,0.114,0c0.015,0,0.022,0,0.03-0.007
             c0.015-0.008,0.022-0.016,0.03-0.031c0.054-0.062,0.114-0.137,0.266-0.114c0.069,0.007,0.115,0,0.153-0.007
             c0.038-0.016,0.068-0.038,0.106-0.062c0.022-0.015,0.038-0.031,0.054-0.053c0.053-0.053,0.099-0.099,0.235-0.106
             c0.084,0,0.13-0.031,0.168-0.054c0.029-0.015,0.053-0.03,0.099-0.03c0.022,0,0.084-0.023,0.13-0.054
             c0.046-0.023,0.083-0.061,0.099-0.091c0.022-0.053,0.054-0.099,0.091-0.114c0.054-0.031,0.107-0.015,0.152,0.076
             c0.031,0.054,0.062,0.092,0.092,0.115c0.023,0.014,0.046,0.022,0.054,0.022c0.03-0.008,0.061-0.022,0.091-0.038
             c0-0.007-0.008-0.022-0.008-0.038c-0.007-0.046-0.007-0.107,0.008-0.152c0.008-0.046-0.008-0.099-0.023-0.16
             c-0.007-0.031-0.015-0.068-0.022-0.107c-0.008-0.045-0.03-0.068-0.084-0.099c-0.029-0.03-0.084-0.061-0.136-0.114
             c-0.1-0.099-0.084-0.144-0.039-0.19c0.008-0.015,0.023-0.023,0.031-0.031c0.022-0.023,0.053-0.046,0.06-0.084
             c0-0.061-0.022-0.129-0.052-0.197c-0.031-0.084-0.069-0.175-0.054-0.275c0.015-0.053-0.03-0.06-0.077-0.068
             c-0.061-0.007-0.121-0.015-0.182-0.062c-0.084-0.06-0.076-0.121-0.038-0.182c0.021-0.03,0.053-0.053,0.084-0.076
             c0.021-0.015,0.037-0.031,0.045-0.046c0.016-0.015,0.016-0.053,0.016-0.106c0-0.092-0.023-0.206-0.039-0.274
             C124.481,23.747,124.474,23.625,124.467,23.519z"/>
        
        <!-- fin germany -->
        <!-- france -->
        <path fill="#808184" d="M116.167,25.94c-0.16,0.022-0.267-0.023-0.495,0.122
               c-0.251,0.168-0.145,0.388-0.266,0.593c-0.123,0.206-0.275,0.214-0.42,0.313c-0.145,0.106-0.54,0.137-0.571,0.251
               c-0.029,0.114-0.068,0.236-0.251,0.289c-0.175,0.061-0.556,0-0.646-0.122c-0.092-0.121-0.016-0.243-0.167-0.228
               c-0.161,0.023-0.298-0.084-0.389,0.015c-0.084,0.1,0.091,0.328,0.129,0.473c0.03,0.144,0.313,0.228,0.054,0.373
               c-0.259,0.144-0.289-0.016-0.479,0.099c-0.191,0.121-0.306,0.22-0.404,0.083c-0.099-0.137-0.121-0.289-0.267-0.243
               c-0.137,0.038-0.282,0.129-0.357,0.137c-0.076,0.015-0.327-0.031-0.457,0.023c-0.13,0.052-0.396,0.136-0.297,0.251
               c0.1,0.107,0.243-0.016,0.274,0.107c0.022,0.129-0.175,0.106-0.107,0.235c0.076,0.13,0.062,0.122,0.16,0.153
               c0.1,0.037,0.297-0.153,0.435-0.008c0.137,0.145,0.396,0.175,0.487,0.198c0.092,0.015,0.151,0.022,0.251,0.144
               c0.106,0.115,0.1,0.077,0.152,0.16c0.061,0.084,0.168-0.037,0.206,0.061c0.037,0.107-0.038,0.176-0.038,0.244
               c0,0.069,0.045,0.099,0.137,0.236c0.098,0.145,0.243,0.221,0.312,0.274c0.076,0.046-0.061,0.092,0,0.167
               c0.069,0.069,0.184,0.017,0.206,0.13c0.022,0.122-0.16,0.099-0.091,0.229c0.061,0.13,0.099,0.176,0.151,0.206
               c0.061,0.023,0.267,0.182,0.122,0.16c-0.137-0.022-0.235-0.069-0.235,0.007c0,0.069-0.016,0.13-0.038,0.389
               c-0.023,0.266,0.045,0.434-0.069,0.625c-0.121,0.19-0.167,0.426-0.183,0.525c-0.016,0.076-0.084,0.174-0.221,0.266
               c0.092,0.061,0.175,0.129,0.243,0.19c0.138,0.138,0.358,0.207,0.563,0.274c0.092,0.031,0.184,0.061,0.259,0.092
               c0.062,0.03,0.099,0.046,0.13,0.053c0.152,0.069,0.19,0.084,0.351,0.045c0.068-0.014,0.114-0.052,0.145-0.083
               c0.068-0.061,0.114-0.107,0.267-0.03c0.075,0.038,0.183,0.053,0.282,0.076c0.175,0.037,0.326,0.069,0.304,0.198v0.007
               c0,0.031,0.016,0.046,0.038,0.062c0.023,0.022,0.068,0.037,0.114,0.045c0.03,0.007,0.062,0.007,0.091,0.007
               c0.046,0,0.077-0.015,0.1-0.03c0.046-0.03,0.091-0.061,0.214-0.007c0.083,0.03,0.159,0.037,0.228,0.037s0.13-0.015,0.175-0.022
               c0.046-0.015,0.107-0.015,0.176-0.023c-0.03-0.061-0.076-0.13-0.099-0.198c-0.039-0.129,0.045-0.403,0.258-0.525
               c0.214-0.122,0.351-0.244,0.396-0.221c0.046,0.03,0.472-0.008,0.633,0.038c0.159,0.046,0.281,0.144,0.418,0.19
               c0.146,0.047,0.312,0.13,0.435,0.153c0.129,0.015,0.494,0.037,0.624-0.153c0.113-0.167,0.342-0.403,0.54-0.465
               c-0.015-0.091-0.022-0.197,0-0.304c0.008-0.045,0.022-0.053,0.022-0.053c-0.015-0.008-0.037,0.008-0.067,0.015
               c-0.062,0.016-0.122,0.038-0.19,0.023c-0.168-0.03-0.191-0.098-0.244-0.274V31.97c-0.038-0.122,0-0.167,0.03-0.213
               c0.022-0.031,0.046-0.053,0.038-0.122c-0.007-0.061-0.038-0.076-0.076-0.091c-0.053-0.023-0.106-0.046-0.13-0.145
               c-0.022-0.137,0.092-0.167,0.206-0.198c0.046-0.016,0.1-0.023,0.122-0.046c0.007-0.015-0.016-0.045-0.046-0.076
               c-0.046-0.046-0.092-0.099-0.122-0.167c-0.037-0.099-0.022-0.175,0-0.243V30.66c0.008-0.038,0.016-0.075,0-0.091
               c-0.068-0.076-0.061-0.167-0.045-0.274c0-0.022,0.008-0.046,0.008-0.061c0-0.03-0.031-0.045-0.077-0.076
               c-0.075-0.038-0.159-0.091-0.197-0.213c-0.062-0.183,0.015-0.244,0.099-0.328c0.023-0.022,0.046-0.038,0.069-0.069
               c0.045-0.053,0.075-0.09,0.099-0.121c0.03-0.038,0.061-0.076,0.106-0.13c0.061-0.075,0.183-0.106,0.274-0.128
               c0.045-0.016,0.084-0.023,0.106-0.031c0.015-0.015,0.03-0.063,0.045-0.122c0.016-0.092,0.023-0.229,0.023-0.335
               c0-0.121,0.053-0.176,0.106-0.229c0.022-0.022,0.053-0.053,0.053-0.091c0-0.083,0.007-0.145,0.039-0.198
               c0.03-0.053,0.075-0.092,0.137-0.129c0.03-0.015,0.046-0.031,0.068-0.046c0.023-0.023,0.038-0.038,0.046-0.061
               c0-0.008,0.007-0.015,0-0.021v-0.008c-0.016-0.016-0.038-0.023-0.068-0.031c-0.054-0.023-0.115-0.052-0.183-0.122
               c-0.039-0.046-0.092-0.03-0.168-0.014c-0.076,0.022-0.19,0.045-0.343,0.037c-0.251-0.008-0.281-0.076-0.313-0.144
               c-0.007-0.023-0.015-0.046-0.046-0.061c-0.053-0.03-0.083-0.061-0.122-0.092c-0.045-0.037-0.091-0.076-0.167-0.099
               c-0.045-0.007-0.091,0-0.145,0c-0.113,0.015-0.229,0.023-0.335-0.091c-0.053-0.053-0.129-0.062-0.205-0.077
               c-0.114-0.014-0.252-0.037-0.403-0.167c-0.114-0.106-0.222-0.152-0.343-0.213v-0.008c-0.13-0.061-0.282-0.137-0.488-0.289
               c-0.206-0.145-0.312-0.175-0.38-0.19c-0.084-0.023-0.13-0.038-0.19-0.153C116.235,26.115,116.197,26.023,116.167,25.94"/>
             <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
               M116.167,25.94c-0.16,0.022-0.267-0.023-0.495,0.122c-0.251,0.168-0.145,0.388-0.266,0.593c-0.123,0.206-0.275,0.214-0.42,0.313
               c-0.145,0.106-0.54,0.137-0.571,0.251c-0.029,0.114-0.068,0.236-0.251,0.289c-0.175,0.061-0.556,0-0.646-0.122
               c-0.092-0.121-0.016-0.243-0.167-0.228c-0.161,0.023-0.298-0.084-0.389,0.015c-0.084,0.1,0.091,0.328,0.129,0.473
               c0.03,0.144,0.313,0.228,0.054,0.373c-0.259,0.144-0.289-0.016-0.479,0.099c-0.191,0.121-0.306,0.22-0.404,0.083
               c-0.099-0.137-0.121-0.289-0.267-0.243c-0.137,0.038-0.282,0.129-0.357,0.137c-0.076,0.015-0.327-0.031-0.457,0.023
               c-0.13,0.052-0.396,0.136-0.297,0.251c0.1,0.107,0.243-0.016,0.274,0.107c0.022,0.129-0.175,0.106-0.107,0.235
               c0.076,0.13,0.062,0.122,0.16,0.153c0.1,0.037,0.297-0.153,0.435-0.008c0.137,0.145,0.396,0.175,0.487,0.198
               c0.092,0.015,0.151,0.022,0.251,0.144c0.106,0.115,0.1,0.077,0.152,0.16c0.061,0.084,0.168-0.037,0.206,0.061
               c0.037,0.107-0.038,0.176-0.038,0.244c0,0.069,0.045,0.099,0.137,0.236c0.098,0.145,0.243,0.221,0.312,0.274
               c0.076,0.046-0.061,0.092,0,0.167c0.069,0.069,0.184,0.017,0.206,0.13c0.022,0.122-0.16,0.099-0.091,0.229
               c0.061,0.13,0.099,0.176,0.151,0.206c0.061,0.023,0.267,0.182,0.122,0.16c-0.137-0.022-0.235-0.069-0.235,0.007
               c0,0.069-0.016,0.13-0.038,0.389c-0.023,0.266,0.045,0.434-0.069,0.625c-0.121,0.19-0.167,0.426-0.183,0.525
               c-0.016,0.076-0.084,0.174-0.221,0.266c0.092,0.061,0.175,0.129,0.243,0.19c0.138,0.138,0.358,0.207,0.563,0.274
               c0.092,0.031,0.184,0.061,0.259,0.092c0.062,0.03,0.099,0.046,0.13,0.053c0.152,0.069,0.19,0.084,0.351,0.045
               c0.068-0.014,0.114-0.052,0.145-0.083c0.068-0.061,0.114-0.107,0.267-0.03c0.075,0.038,0.183,0.053,0.282,0.076
               c0.175,0.037,0.326,0.069,0.304,0.198v0.007c0,0.031,0.016,0.046,0.038,0.062c0.023,0.022,0.068,0.037,0.114,0.045
               c0.03,0.007,0.062,0.007,0.091,0.007c0.046,0,0.077-0.015,0.1-0.03c0.046-0.03,0.091-0.061,0.214-0.007
               c0.083,0.03,0.159,0.037,0.228,0.037s0.13-0.015,0.175-0.022c0.046-0.015,0.107-0.015,0.176-0.023
               c-0.03-0.061-0.076-0.13-0.099-0.198c-0.039-0.129,0.045-0.403,0.258-0.525c0.214-0.122,0.351-0.244,0.396-0.221
               c0.046,0.03,0.472-0.008,0.633,0.038c0.159,0.046,0.281,0.144,0.418,0.19c0.146,0.047,0.312,0.13,0.435,0.153
               c0.129,0.015,0.494,0.037,0.624-0.153c0.113-0.167,0.342-0.403,0.54-0.465c-0.015-0.091-0.022-0.197,0-0.304
               c0.008-0.045,0.022-0.053,0.022-0.053c-0.015-0.008-0.037,0.008-0.067,0.015c-0.062,0.016-0.122,0.038-0.19,0.023
               c-0.168-0.03-0.191-0.098-0.244-0.274V31.97c-0.038-0.122,0-0.167,0.03-0.213c0.022-0.031,0.046-0.053,0.038-0.122
               c-0.007-0.061-0.038-0.076-0.076-0.091c-0.053-0.023-0.106-0.046-0.13-0.145c-0.022-0.137,0.092-0.167,0.206-0.198
               c0.046-0.016,0.1-0.023,0.122-0.046c0.007-0.015-0.016-0.045-0.046-0.076c-0.046-0.046-0.092-0.099-0.122-0.167
               c-0.037-0.099-0.022-0.175,0-0.243V30.66c0.008-0.038,0.016-0.075,0-0.091c-0.068-0.076-0.061-0.167-0.045-0.274
               c0-0.022,0.008-0.046,0.008-0.061c0-0.03-0.031-0.045-0.077-0.076c-0.075-0.038-0.159-0.091-0.197-0.213
               c-0.062-0.183,0.015-0.244,0.099-0.328c0.023-0.022,0.046-0.038,0.069-0.069c0.045-0.053,0.075-0.09,0.099-0.121
               c0.03-0.038,0.061-0.076,0.106-0.13c0.061-0.075,0.183-0.106,0.274-0.128c0.045-0.016,0.084-0.023,0.106-0.031
               c0.015-0.015,0.03-0.063,0.045-0.122c0.016-0.092,0.023-0.229,0.023-0.335c0-0.121,0.053-0.176,0.106-0.229
               c0.022-0.022,0.053-0.053,0.053-0.091c0-0.083,0.007-0.145,0.039-0.198c0.03-0.053,0.075-0.092,0.137-0.129
               c0.03-0.015,0.046-0.031,0.068-0.046c0.023-0.023,0.038-0.038,0.046-0.061c0-0.008,0.007-0.015,0-0.021v-0.008
               c-0.016-0.016-0.038-0.023-0.068-0.031c-0.054-0.023-0.115-0.052-0.183-0.122c-0.039-0.046-0.092-0.03-0.168-0.014
               c-0.076,0.022-0.19,0.045-0.343,0.037c-0.251-0.008-0.281-0.076-0.313-0.144c-0.007-0.023-0.015-0.046-0.046-0.061
               c-0.053-0.03-0.083-0.061-0.122-0.092c-0.045-0.037-0.091-0.076-0.167-0.099c-0.045-0.007-0.091,0-0.145,0
               c-0.113,0.015-0.229,0.023-0.335-0.091c-0.053-0.053-0.129-0.062-0.205-0.077c-0.114-0.014-0.252-0.037-0.403-0.167
               c-0.114-0.106-0.222-0.152-0.343-0.213v-0.008c-0.13-0.061-0.282-0.137-0.488-0.289c-0.206-0.145-0.312-0.175-0.38-0.19
               c-0.084-0.023-0.13-0.038-0.19-0.153C116.235,26.115,116.197,26.023,116.167,25.94z"/>
             <!-- fin france -->
        <!-- belgique -->
        <path fill="#808184" d="M116.64,25.666c-0.031,0.022-0.063,0.053-0.092,0.076
        c-0.138,0.122-0.152,0.152-0.274,0.183c0.022,0.068,0.061,0.144,0.092,0.213c0.045,0.076,0.076,0.084,0.137,0.099
        c0.075,0.015,0.19,0.045,0.41,0.205c0.199,0.153,0.344,0.221,0.474,0.289c0.129,0.061,0.234,0.115,0.365,0.229
        c0.129,0.107,0.242,0.129,0.35,0.144c0.1,0.016,0.183,0.031,0.259,0.107c0.016,0.016,0.03,0.023,0.053,0.039
        c0.024-0.115,0.107-0.389,0.252-0.488c0.152-0.099,0.152-0.114,0.198-0.228v-0.015c0.015-0.046-0.008-0.069-0.03-0.084
        c-0.03-0.03-0.068-0.061-0.084-0.122c-0.008-0.038-0.038-0.038-0.084-0.038c-0.038-0.008-0.085-0.008-0.129-0.023
        c-0.076-0.015-0.084-0.069-0.068-0.129c0.007-0.031,0.022-0.069,0.046-0.1c0.022-0.045,0.053-0.098,0.045-0.121
        c-0.016-0.031-0.146-0.038-0.267-0.038c-0.067-0.008-0.145-0.008-0.205-0.015c-0.137-0.023-0.19-0.069-0.252-0.115
        c-0.022-0.023-0.045-0.046-0.083-0.061c-0.031-0.023-0.084,0-0.146,0.038c-0.075,0.046-0.175,0.099-0.304,0.099
        c-0.092,0-0.138,0.008-0.184,0.016c-0.067,0.007-0.105,0.007-0.243-0.016C116.799,25.795,116.715,25.742,116.64,25.666"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M116.64,25.666c-0.031,0.022-0.063,0.053-0.092,0.076c-0.138,0.122-0.152,0.152-0.274,0.183
        c0.022,0.068,0.061,0.144,0.092,0.213c0.045,0.076,0.076,0.084,0.137,0.099c0.075,0.015,0.19,0.045,0.41,0.205
        c0.199,0.153,0.344,0.221,0.474,0.289c0.129,0.061,0.234,0.115,0.365,0.229c0.129,0.107,0.242,0.129,0.35,0.144
        c0.1,0.016,0.183,0.031,0.259,0.107c0.016,0.016,0.03,0.023,0.053,0.039c0.024-0.115,0.107-0.389,0.252-0.488
        c0.152-0.099,0.152-0.114,0.198-0.228v-0.015c0.015-0.046-0.008-0.069-0.03-0.084c-0.03-0.03-0.068-0.061-0.084-0.122
        c-0.008-0.038-0.038-0.038-0.084-0.038c-0.038-0.008-0.085-0.008-0.129-0.023c-0.076-0.015-0.084-0.069-0.068-0.129
        c0.007-0.031,0.022-0.069,0.046-0.1c0.022-0.045,0.053-0.098,0.045-0.121c-0.016-0.031-0.146-0.038-0.267-0.038
        c-0.067-0.008-0.145-0.008-0.205-0.015c-0.137-0.023-0.19-0.069-0.252-0.115c-0.022-0.023-0.045-0.046-0.083-0.061
        c-0.031-0.023-0.084,0-0.146,0.038c-0.075,0.046-0.175,0.099-0.304,0.099c-0.092,0-0.138,0.008-0.184,0.016
        c-0.067,0.007-0.105,0.007-0.243-0.016C116.799,25.795,116.715,25.742,116.64,25.666z"/>
        <!-- fin belgique -->
        <path fill="#808184" d="M118.726,26.838l-0.008,0.007c-0.122,0.076-0.19,0.335-0.214,0.427
        c0.038,0.007,0.075,0,0.115,0c0.061-0.008,0.113-0.008,0.175,0c0.046,0.015,0.083,0.03,0.121,0.053
        c0.016-0.03,0.046-0.084,0.023-0.146c-0.016-0.053-0.03-0.068-0.046-0.083c-0.023-0.023-0.038-0.038-0.061-0.092
        C118.81,26.953,118.756,26.884,118.726,26.838"/>
        <path fill="#808184" d="M118.726,26.838l-0.008,0.007c-0.122,0.076-0.19,0.335-0.214,0.427
        c0.038,0.007,0.075,0,0.115,0c0.061-0.008,0.113-0.008,0.175,0c0.046,0.015,0.083,0.03,0.121,0.053
        c0.016-0.03,0.046-0.084,0.023-0.146c-0.016-0.053-0.03-0.068-0.046-0.083c-0.023-0.023-0.038-0.038-0.061-0.092
        C118.81,26.953,118.756,26.884,118.726,26.838z"/>
        <path fill="#808184" d="M117.386,25.011c-0.023,0.015-0.039,0.037-0.063,0.068
        c-0.175,0.191-0.266,0.244-0.38,0.343c-0.068,0.061-0.145,0.122-0.229,0.182c0.061,0.053,0.122,0.1,0.175,0.107
        c0.123,0.023,0.161,0.015,0.222,0.008c0.037,0,0.099-0.008,0.19-0.008c0.105,0,0.19-0.045,0.259-0.091
        c0.022-0.008,0.045-0.023,0.068-0.031c-0.016-0.068-0.053-0.205-0.099-0.228c-0.084-0.031-0.092-0.083-0.1-0.16
        c-0.007-0.046-0.007-0.099-0.038-0.16C117.393,25.026,117.386,25.018,117.386,25.011"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M117.386,25.011c-0.023,0.015-0.039,0.037-0.063,0.068c-0.175,0.191-0.266,0.244-0.38,0.343
        c-0.068,0.061-0.145,0.122-0.229,0.182c0.061,0.053,0.122,0.1,0.175,0.107c0.123,0.023,0.161,0.015,0.222,0.008
        c0.037,0,0.099-0.008,0.19-0.008c0.105,0,0.19-0.045,0.259-0.091c0.022-0.008,0.045-0.023,0.068-0.031
        c-0.016-0.068-0.053-0.205-0.099-0.228c-0.084-0.031-0.092-0.083-0.1-0.16c-0.007-0.046-0.007-0.099-0.038-0.16
        C117.393,25.026,117.386,25.018,117.386,25.011z"/>
        <!--  espagne portugale-->
        
        <path fill="#808184" d="M107.365,34.384c0,0.023,0,0.053,0.008,0.083
        c0.061,0.282,0.137,0.503,0.145,0.633c0.015,0.121-0.106,0.548-0.244,0.784c-0.138,0.236-0.138,0.525-0.213,0.632
        c-0.076,0.098-0.213,0.144-0.251,0.403c-0.031,0.259,0.068,0.336,0.084,0.564c0.007,0.22,0.426-0.236,0.313,0.236
        c-0.115,0.472-0.023,0.624-0.092,0.777c-0.076,0.159-0.198,0.327-0.069,0.342c0.13,0.008,0.258-0.16,0.374-0.091
        c0.121,0.068,0.373,0.106,0.479,0.06c0.068-0.03,0.16-0.099,0.259-0.136c-0.023-0.107-0.054-0.229-0.062-0.312
        c-0.007-0.107,0.03-0.153,0.076-0.19c0.023-0.024,0.046-0.046,0.046-0.093c0-0.151,0.106-0.167,0.222-0.189
        c0.022,0,0.053-0.008,0.075-0.016h0.016v-0.015c0-0.008-0.008-0.023-0.008-0.031c-0.016-0.029-0.038-0.053-0.054-0.061
        c-0.037-0.023-0.091-0.121-0.113-0.228c-0.016-0.084-0.016-0.175,0.007-0.251c0.031-0.076,0.1-0.16,0.145-0.221
        c0.031-0.031,0.092-0.023,0.069-0.038c-0.024-0.023-0.054-0.061-0.076-0.107c-0.016-0.03-0.022-0.076-0.022-0.114
        c0-0.015-0.023-0.038-0.062-0.068c-0.068-0.061-0.145-0.13-0.175-0.259c-0.031-0.107,0.015-0.153,0.083-0.168
        c0.038-0.007,0.076-0.007,0.122-0.007c0.054,0,0.114,0,0.159-0.016c0.107-0.046,0.1-0.159,0.084-0.281v-0.008
        c-0.022-0.152,0-0.183,0.046-0.251l0.008-0.015c0.016-0.031,0.023-0.077,0.038-0.115c0.008-0.053,0.021-0.099,0.046-0.137
        c0.015-0.022,0.008-0.091,0-0.167c-0.008-0.053-0.015-0.122-0.008-0.19c0-0.13,0.076-0.176,0.16-0.221
        c0.038-0.015,0.075-0.039,0.106-0.069c0.046-0.046,0.083-0.076,0.114-0.107c0.046-0.037,0.092-0.069,0.121-0.129
        c0.016-0.023,0.016-0.038,0-0.046c-0.015-0.007-0.053-0.015-0.091-0.022l-0.03-0.008c-0.114-0.023-0.122-0.069-0.13-0.145
        c0-0.015,0-0.03-0.008-0.045c-0.008-0.031-0.015-0.053-0.03-0.062c-0.015-0.014-0.045-0.022-0.099-0.037
        c-0.054-0.023-0.106,0.007-0.183,0.053c-0.031,0.015-0.068,0.03-0.106,0.054c-0.167,0.075-0.205,0.052-0.313-0.024l-0.03-0.023
        c-0.03-0.014-0.069,0.008-0.106,0.031c-0.076,0.038-0.153,0.091-0.274,0.061c-0.106-0.023-0.129-0.061-0.122-0.115
        c0.008-0.037,0.038-0.06,0.069-0.091c0.03-0.03,0.053-0.053,0.053-0.068c0,0-0.007,0.007-0.016,0.015
        c-0.045,0.015-0.113,0.06-0.205,0.122c-0.068,0.045-0.19,0.092-0.304,0.129L107.365,34.384z"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M107.365,34.384c0,0.023,0,0.053,0.008,0.083c0.061,0.282,0.137,0.503,0.145,0.633c0.015,0.121-0.106,0.548-0.244,0.784
        c-0.138,0.236-0.138,0.525-0.213,0.632c-0.076,0.098-0.213,0.144-0.251,0.403c-0.031,0.259,0.068,0.336,0.084,0.564
        c0.007,0.22,0.426-0.236,0.313,0.236c-0.115,0.472-0.023,0.624-0.092,0.777c-0.076,0.159-0.198,0.327-0.069,0.342
        c0.13,0.008,0.258-0.16,0.374-0.091c0.121,0.068,0.373,0.106,0.479,0.06c0.068-0.03,0.16-0.099,0.259-0.136
        c-0.023-0.107-0.054-0.229-0.062-0.312c-0.007-0.107,0.03-0.153,0.076-0.19c0.023-0.024,0.046-0.046,0.046-0.093
        c0-0.151,0.106-0.167,0.222-0.189c0.022,0,0.053-0.008,0.075-0.016h0.016v-0.015c0-0.008-0.008-0.023-0.008-0.031
        c-0.016-0.029-0.038-0.053-0.054-0.061c-0.037-0.023-0.091-0.121-0.113-0.228c-0.016-0.084-0.016-0.175,0.007-0.251
        c0.031-0.076,0.1-0.16,0.145-0.221c0.031-0.031,0.092-0.023,0.069-0.038c-0.024-0.023-0.054-0.061-0.076-0.107
        c-0.016-0.03-0.022-0.076-0.022-0.114c0-0.015-0.023-0.038-0.062-0.068c-0.068-0.061-0.145-0.13-0.175-0.259
        c-0.031-0.107,0.015-0.153,0.083-0.168c0.038-0.007,0.076-0.007,0.122-0.007c0.054,0,0.114,0,0.159-0.016
        c0.107-0.046,0.1-0.159,0.084-0.281v-0.008c-0.022-0.152,0-0.183,0.046-0.251l0.008-0.015c0.016-0.031,0.023-0.077,0.038-0.115
        c0.008-0.053,0.021-0.099,0.046-0.137c0.015-0.022,0.008-0.091,0-0.167c-0.008-0.053-0.015-0.122-0.008-0.19
        c0-0.13,0.076-0.176,0.16-0.221c0.038-0.015,0.075-0.039,0.106-0.069c0.046-0.046,0.083-0.076,0.114-0.107
        c0.046-0.037,0.092-0.069,0.121-0.129c0.016-0.023,0.016-0.038,0-0.046c-0.015-0.007-0.053-0.015-0.091-0.022l-0.03-0.008
        c-0.114-0.023-0.122-0.069-0.13-0.145c0-0.015,0-0.03-0.008-0.045c-0.008-0.031-0.015-0.053-0.03-0.062
        c-0.015-0.014-0.045-0.022-0.099-0.037c-0.054-0.023-0.106,0.007-0.183,0.053c-0.031,0.015-0.068,0.03-0.106,0.054
        c-0.167,0.075-0.205,0.052-0.313-0.024l-0.03-0.023c-0.03-0.014-0.069,0.008-0.106,0.031c-0.076,0.038-0.153,0.091-0.274,0.061
        c-0.106-0.023-0.129-0.061-0.122-0.115c0.008-0.037,0.038-0.06,0.069-0.091c0.03-0.03,0.053-0.053,0.053-0.068
        c0,0-0.007,0.007-0.016,0.015c-0.045,0.015-0.113,0.06-0.205,0.122c-0.068,0.045-0.19,0.092-0.304,0.129L107.365,34.384z"/>
        <path fill="#808184" d="M112.695,32.93c-0.016,0.007-0.03,0.015-0.046,0.015
        c-0.236,0.106-0.571-0.023-0.738-0.053c-0.176-0.031-0.282,0-0.533-0.008c-0.244-0.016-0.198-0.115-0.525-0.069
        s-0.609,0.031-0.891-0.038c-0.282-0.068-0.282-0.068-0.571-0.053c-0.297,0.008-0.419,0.053-0.579,0.008
        c-0.152-0.046-0.289-0.19-0.456-0.153c-0.169,0.031-0.32,0.214-0.396,0.267c-0.077,0.061-0.245-0.069-0.404,0.068
        c-0.159,0.137-0.594,0.184-0.427,0.419c0.168,0.236,0.396,0.266,0.313,0.404c-0.076,0.137-0.022,0.075-0.022,0.289
        c0,0.144-0.046,0.167-0.062,0.251h0.008c0.112-0.03,0.221-0.076,0.281-0.114c0.092-0.061,0.168-0.114,0.222-0.137
        c0.092-0.031,0.145-0.016,0.159,0.076c0.008,0.061-0.045,0.107-0.084,0.145c-0.022,0.022-0.038,0.038-0.045,0.045
        c0,0.008,0.016-0.007,0.037-0.007c0.083,0.023,0.153-0.016,0.206-0.046c0.068-0.046,0.13-0.084,0.214-0.03l0.03,0.023
        c0.076,0.053,0.099,0.068,0.213,0.015c0.038-0.015,0.068-0.038,0.099-0.054c0.099-0.053,0.168-0.091,0.267-0.06
        c0.062,0.015,0.098,0.03,0.138,0.052c0.03,0.031,0.053,0.069,0.061,0.123c0.007,0.023,0.007,0.045,0.007,0.06
        c0.008,0.031,0.008,0.046,0.055,0.054l0.029,0.008c0.039,0.007,0.084,0.014,0.123,0.037c0.06,0.031,0.083,0.084,0.037,0.176
        c-0.045,0.076-0.092,0.121-0.152,0.167c-0.03,0.023-0.061,0.053-0.099,0.091c-0.038,0.046-0.092,0.069-0.137,0.092
        c-0.054,0.03-0.106,0.061-0.106,0.13c0,0.068,0,0.129,0.007,0.182c0.008,0.091,0.016,0.175-0.015,0.229
        c-0.017,0.023-0.023,0.06-0.031,0.106c-0.015,0.046-0.022,0.099-0.053,0.145l-0.008,0.015c-0.03,0.053-0.046,0.068-0.03,0.19
        v0.008c0.022,0.16,0.038,0.305-0.146,0.38c-0.061,0.031-0.137,0.023-0.197,0.023c-0.038,0-0.076,0-0.099,0.008
        c-0.008,0-0.016,0.015-0.008,0.046c0.022,0.091,0.092,0.152,0.145,0.205c0.054,0.046,0.099,0.084,0.099,0.145
        c-0.007,0.03,0,0.053,0.008,0.076c0.015,0.031,0.039,0.053,0.053,0.069c0.054,0.046,0.009,0.099-0.061,0.182
        c-0.037,0.054-0.099,0.122-0.121,0.191c-0.022,0.053-0.022,0.129-0.008,0.191c0.016,0.083,0.046,0.152,0.068,0.166
        c0.03,0.016,0.061,0.054,0.083,0.092c0.017,0.031,0.024,0.053,0.031,0.084c0,0.031-0.007,0.061-0.038,0.084
        c-0.016,0.015-0.038,0.03-0.061,0.038c-0.031,0-0.054,0.007-0.084,0.007c-0.068,0.016-0.138,0.023-0.138,0.091
        c0,0.084-0.038,0.122-0.075,0.16c-0.023,0.031-0.046,0.054-0.046,0.115c0.008,0.075,0.037,0.198,0.061,0.297
        c0.03,0,0.068,0,0.106,0.007c0.176,0.046,0.473,0.084,0.511,0.228c0.03,0.153,0.13,0.153,0.152,0.305
        c0.015,0.153,0.061,0.206,0.129,0.32c0.076,0.122,0.358,0.358,0.518,0.198c0.152-0.16,0.297-0.503,0.518-0.472
        c0.222,0.038,0.313-0.054,0.503-0.099c0.19-0.046,0.632-0.03,0.814-0.015c0.183,0.007,0.313-0.084,0.396-0.061
        c0.083,0.03,0.152-0.031,0.281,0.007c0.13,0.039,0.13,0.107,0.236-0.129c0.114-0.236,0.351-0.571,0.533-0.594
        c0.183-0.03,0.351-0.046,0.373-0.167c0.023-0.13,0.038-0.526,0.351-0.708c0.319-0.176,0.396-0.244,0.289-0.389
        c-0.107-0.152-0.282-0.35-0.206-0.526c0.069-0.175,0.274-0.296,0.32-0.448c0.045-0.153,0.175-0.488,0.388-0.579
        c0.206-0.084,0.114-0.046,0.206-0.259c0.092-0.205,0.136-0.198,0.22-0.244c0.092-0.045,0.694-0.061,0.93-0.266
        c0.236-0.198,0.289-0.305,0.402-0.35c0.115-0.046,0.283-0.115,0.237-0.259c-0.046-0.138,0.046-0.146,0.046-0.245v-0.029
        c-0.068,0-0.145,0-0.183,0.015c-0.061,0.007-0.13,0.023-0.206,0.03c-0.084,0-0.175-0.016-0.268-0.053
        c-0.075-0.03-0.098-0.015-0.121,0c-0.029,0.023-0.075,0.053-0.159,0.046c-0.031,0-0.068,0-0.1-0.009
        c-0.061-0.007-0.122-0.029-0.159-0.06c-0.054-0.031-0.076-0.083-0.076-0.145v-0.029c0.008-0.032-0.107-0.047-0.229-0.077
        c-0.106-0.023-0.221-0.046-0.305-0.084c-0.084-0.046-0.114-0.023-0.152,0.015c-0.038,0.039-0.091,0.084-0.19,0.107
        c-0.19,0.046-0.236,0.03-0.411-0.045c-0.046-0.023-0.092-0.039-0.129-0.055c-0.076-0.029-0.16-0.06-0.252-0.09
        c-0.22-0.069-0.449-0.145-0.602-0.297C112.894,33.074,112.794,32.998,112.695,32.93 M116.273,36.112c0,0-0.38,0.191-0.457,0.312
        c-0.084,0.115-0.022,0.206,0.062,0.183c0.083-0.022,0.145-0.145,0.19-0.099c0.045,0.045-0.031,0.168,0.037,0.168
        c0.084,0.022,0.19,0.06,0.289-0.016c0.1-0.068,0.366-0.243,0.229-0.335c-0.138-0.091-0.198,0-0.229-0.061
        c-0.029-0.061,0.016-0.167-0.029-0.167C116.311,36.089,116.273,36.112,116.273,36.112 M116.921,36.021
        c-0.031,0.015-0.206,0.076-0.062,0.137c0.146,0.061,0.221-0.023,0.267,0.03s0.152,0.16,0.144,0.046
        c-0.014-0.107-0.066-0.244-0.182-0.252C116.981,35.967,116.921,36.021,116.921,36.021 M115.04,36.919
        c-0.038,0.023-0.244,0.122-0.16,0.175c0.084,0.054,0.122,0.046,0.146,0.046c0.015,0,0.189-0.244,0.137-0.259
        C115.108,36.874,115.04,36.919,115.04,36.919"/>
        <path fill="none" stroke="#FFFFFF" stroke-width="0.111" stroke-miterlimit="10" d="
        M112.695,32.93c-0.016,0.007-0.03,0.015-0.046,0.015c-0.236,0.106-0.571-0.023-0.738-0.053c-0.176-0.031-0.282,0-0.533-0.008
        c-0.244-0.016-0.198-0.115-0.525-0.069s-0.609,0.031-0.891-0.038c-0.282-0.068-0.282-0.068-0.571-0.053
        c-0.297,0.008-0.419,0.053-0.579,0.008c-0.152-0.046-0.289-0.19-0.456-0.153c-0.169,0.031-0.32,0.214-0.396,0.267
        c-0.077,0.061-0.245-0.069-0.404,0.068c-0.159,0.137-0.594,0.184-0.427,0.419c0.168,0.236,0.396,0.266,0.313,0.404
        c-0.076,0.137-0.022,0.075-0.022,0.289c0,0.144-0.046,0.167-0.062,0.251h0.008c0.112-0.03,0.221-0.076,0.281-0.114
        c0.092-0.061,0.168-0.114,0.222-0.137c0.092-0.031,0.145-0.016,0.159,0.076c0.008,0.061-0.045,0.107-0.084,0.145
        c-0.022,0.022-0.038,0.038-0.045,0.045c0,0.008,0.016-0.007,0.037-0.007c0.083,0.023,0.153-0.016,0.206-0.046
        c0.068-0.046,0.13-0.084,0.214-0.03l0.03,0.023c0.076,0.053,0.099,0.068,0.213,0.015c0.038-0.015,0.068-0.038,0.099-0.054
        c0.099-0.053,0.168-0.091,0.267-0.06c0.062,0.015,0.098,0.03,0.138,0.052c0.03,0.031,0.053,0.069,0.061,0.123
        c0.007,0.023,0.007,0.045,0.007,0.06c0.008,0.031,0.008,0.046,0.055,0.054l0.029,0.008c0.039,0.007,0.084,0.014,0.123,0.037
        c0.06,0.031,0.083,0.084,0.037,0.176c-0.045,0.076-0.092,0.121-0.152,0.167c-0.03,0.023-0.061,0.053-0.099,0.091
        c-0.038,0.046-0.092,0.069-0.137,0.092c-0.054,0.03-0.106,0.061-0.106,0.13c0,0.068,0,0.129,0.007,0.182
        c0.008,0.091,0.016,0.175-0.015,0.229c-0.017,0.023-0.023,0.06-0.031,0.106c-0.015,0.046-0.022,0.099-0.053,0.145l-0.008,0.015
        c-0.03,0.053-0.046,0.068-0.03,0.19v0.008c0.022,0.16,0.038,0.305-0.146,0.38c-0.061,0.031-0.137,0.023-0.197,0.023
        c-0.038,0-0.076,0-0.099,0.008c-0.008,0-0.016,0.015-0.008,0.046c0.022,0.091,0.092,0.152,0.145,0.205
        c0.054,0.046,0.099,0.084,0.099,0.145c-0.007,0.03,0,0.053,0.008,0.076c0.015,0.031,0.039,0.053,0.053,0.069
        c0.054,0.046,0.009,0.099-0.061,0.182c-0.037,0.054-0.099,0.122-0.121,0.191c-0.022,0.053-0.022,0.129-0.008,0.191
        c0.016,0.083,0.046,0.152,0.068,0.166c0.03,0.016,0.061,0.054,0.083,0.092c0.017,0.031,0.024,0.053,0.031,0.084
        c0,0.031-0.007,0.061-0.038,0.084c-0.016,0.015-0.038,0.03-0.061,0.038c-0.031,0-0.054,0.007-0.084,0.007
        c-0.068,0.016-0.138,0.023-0.138,0.091c0,0.084-0.038,0.122-0.075,0.16c-0.023,0.031-0.046,0.054-0.046,0.115
        c0.008,0.075,0.037,0.198,0.061,0.297c0.03,0,0.068,0,0.106,0.007c0.176,0.046,0.473,0.084,0.511,0.228
        c0.03,0.153,0.13,0.153,0.152,0.305c0.015,0.153,0.061,0.206,0.129,0.32c0.076,0.122,0.358,0.358,0.518,0.198
        c0.152-0.16,0.297-0.503,0.518-0.472c0.222,0.038,0.313-0.054,0.503-0.099c0.19-0.046,0.632-0.03,0.814-0.015
        c0.183,0.007,0.313-0.084,0.396-0.061c0.083,0.03,0.152-0.031,0.281,0.007c0.13,0.039,0.13,0.107,0.236-0.129
        c0.114-0.236,0.351-0.571,0.533-0.594c0.183-0.03,0.351-0.046,0.373-0.167c0.023-0.13,0.038-0.526,0.351-0.708
        c0.319-0.176,0.396-0.244,0.289-0.389c-0.107-0.152-0.282-0.35-0.206-0.526c0.069-0.175,0.274-0.296,0.32-0.448
        c0.045-0.153,0.175-0.488,0.388-0.579c0.206-0.084,0.114-0.046,0.206-0.259c0.092-0.205,0.136-0.198,0.22-0.244
        c0.092-0.045,0.694-0.061,0.93-0.266c0.236-0.198,0.289-0.305,0.402-0.35c0.115-0.046,0.283-0.115,0.237-0.259
        c-0.046-0.138,0.046-0.146,0.046-0.245v-0.029c-0.068,0-0.145,0-0.183,0.015c-0.061,0.007-0.13,0.023-0.206,0.03
        c-0.084,0-0.175-0.016-0.268-0.053c-0.075-0.03-0.098-0.015-0.121,0c-0.029,0.023-0.075,0.053-0.159,0.046
        c-0.031,0-0.068,0-0.1-0.009c-0.061-0.007-0.122-0.029-0.159-0.06c-0.054-0.031-0.076-0.083-0.076-0.145v-0.029
        c0.008-0.032-0.107-0.047-0.229-0.077c-0.106-0.023-0.221-0.046-0.305-0.084c-0.084-0.046-0.114-0.023-0.152,0.015
        c-0.038,0.039-0.091,0.084-0.19,0.107c-0.19,0.046-0.236,0.03-0.411-0.045c-0.046-0.023-0.092-0.039-0.129-0.055
        c-0.076-0.029-0.16-0.06-0.252-0.09c-0.22-0.069-0.449-0.145-0.602-0.297C112.894,33.074,112.794,32.998,112.695,32.93z
         M116.273,36.112c0,0-0.38,0.191-0.457,0.312c-0.084,0.115-0.022,0.206,0.062,0.183c0.083-0.022,0.145-0.145,0.19-0.099
        c0.045,0.045-0.031,0.168,0.037,0.168c0.084,0.022,0.19,0.06,0.289-0.016c0.1-0.068,0.366-0.243,0.229-0.335
        c-0.138-0.091-0.198,0-0.229-0.061c-0.029-0.061,0.016-0.167-0.029-0.167C116.311,36.089,116.273,36.112,116.273,36.112z
         M116.921,36.021c-0.031,0.015-0.206,0.076-0.062,0.137c0.146,0.061,0.221-0.023,0.267,0.03s0.152,0.16,0.144,0.046
        c-0.014-0.107-0.066-0.244-0.182-0.252C116.981,35.967,116.921,36.021,116.921,36.021z M115.04,36.919
        c-0.038,0.023-0.244,0.122-0.16,0.175c0.084,0.054,0.122,0.046,0.146,0.046c0.015,0,0.189-0.244,0.137-0.259
        C115.108,36.874,115.04,36.919,115.04,36.919z"/></a>
        <!-- fin espagne portugale -->
        
        
        
        
        
        
        
        
         </svg>
         
              </div>
        <div class="dealer_list w100">
  
            <div class="south_america_dealer africa_dealer  asie_dealer uk_dealer europe_est_dealer all_dealers">
                <div class="dealers vw17">
                  {{$t('rep')}}<br />
                  <p class="vw21 w600 dealer_pt">CMI Mulching</p>
                  819 397-2288 <br />
                  <a href="mailto:sales@cmimulching.com">sales@cmimulching.com</a><br />
                </div>
              </div>  
            
              
              <div class="australie_dealer all_dealers">
                <div class="dealers vw17">
                  {{$t('rep')}}<br />
                  <p class="vw21 w600 dealer_pt">CMI Mulching</p>
                  819 397-2288<br />
                  <a href="mailto:sales@cmimulching.com">sales@cmimulching.com</a><br />
                </div>
              </div>  
            
              <div class="europe_dealer all_dealers">
                <div class="dealers vw17">
                  {{$t('rep')}}<br />
                  <p class="vw21 w600 dealer_pt">CMI Mulching</p>
                  819 397-2288<br />
                  <a href="mailto:sales@cmimulching.com">sales@cmimulching.com</a><br />
                </div>

                <div class="dealers vw17"> 
                  <p class="vw21 w600 ">France</p><br />
               <a href="https://www.dyna-forest.com/cmi-article-17-0-132.html"  target="_blank"><img class="dealer_img_france" src="/Resources/Images/dealer/dyna_forest.png" title="" alt=""   /></a><br />
               
               <br /> <br /> <br />
                </div>
              </div>  
        </div>
    </div>
</template>
  
  <!-- <i18n src="../../public/Resources/I18N/Module.MapWorld.json"></i18n> -->
  <i18n>
    {
        "fr": {
          "rep" : "Représentant CMI"

        },
        "en": {
          "rep" : "CMI Representative"
        }
      }
  </i18n>
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"

    export default {
      name: 'MapWorld',
      data: () => ({
      }),
      mounted:
        function () {
            $(".wo").show(); 

         // this.$nextTick(function () {  });
         
        },
    methods: {
        reset: function ()  {

            DealerShow('','');

        }
    }
    }
  </script>
  