<template>
    <div class="dfci">
        <div class="col-1-1 header_applications_sylviculture">
            <div class="grid rel">
              <div class="header_txt vw38"></div>
            </div>
          </div>
          
    
    
    <div class="col-1-1 split_color ">
      <div class="grid grid-pad">
      <div class="flex">
        
      <div class="col-1-2 col-flex rel ">
        <div class="application_txtzone">
          <h1>{{$t('title')}}</h1>
          <div class="application_title vw30">{{$t('sub_title_grey')}}</div>
     <div class="application_square"></div>
          <div class="vw16 application_txt ">{{$t('li_top')}}
    <br /><br />
       </div>
       <div class="application_square"></div>
       <div class="vw16 application_txt ">{{$t('li_top2')}}
        <br /><br />
    </div>
    <div class="application_square"></div>
    <div class="vw16 application_txt ">{{$t('li_top3')}}
    
      <ul class="smalldot">
        <li>{{$t('li1')}}</li>
        <li>{{$t('li2')}}</li>
        <li>{{$t('li3')}}</li>
        <li>{{$t('li4')}}</li>
        <li>{{$t('li5')}}</li> 
        <li>{{$t('li6')}}</li>
        <li>{{$t('li7')}}</li>
    </ul>
    
    </div>
    </div>
     
    
    
         <div class="app_btn_pos">
       <router-link to="/Ecological">
          <input type="button" class="button" v-bind:value="$t('btn')">
       </router-link>
        </div>
       
      </div>
    
    
    
    <div class="col-1-2 col-flex txt-r grey_mobile mobile-col-1-1">
      <div class="application_photo">
        <div>
          <img style="display: block;" class="full" src="/Resources/Images/ligne1.jpg" alt="" />
    
        </div>
        <br /> <br /> <br />
    
        <div class="txt-l title_vid_brochure">{{$t('video')}}</div>
       
          <video class="full" controls >
            <source src="/Resources/video/dfci.mp4" type="video/mp4">
          </video>
          
    
        <div class="padding_end160"></div>
      </div>
    </div>
    
    </div>
    
     </div>  
    </div>
    
          <div class="col-1-2 "><img style="display: block;" class="full"  src="/Resources/Images/ligne3.jpg" alt=""/>
          </div>
          <div class="col-1-2 "><img style="display: block;" class="full" src="/Resources/Images/ligne.jpg" alt=""  />
       
    
     </div> 
    </div>
  </template>

  <i18n src="../../public/Resources/I18N/Screen.dfci.json"></i18n>

  
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'dfci',
      data: () => ({
     
          }),
    }
    </script>
  