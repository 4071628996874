<template>
    <div class="product">
        <div class="col-1-1 header_produits_">
            <div class="grid rel">
                <div class="header_txt vw38">{{$t('title')}}</div>
            </div>
        </div>


        <div class=" bg_a col-1-1">
            <div class="grid grid-produits">
                <div class="col-1-1 content">

                    <div class="col-1-1 center push ">
                        <div class="col-1-3 produit_list">
                            <router-link to="/C175">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/c175.png" alt="" class="produits_image">
                                    <div class="produits_thumb_etiquette hide w600">{{$t('c175')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('c175')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                       

                        <div class="col-1-3  produit_list">
                            <router-link to="/C300">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/c300.png" alt="" class="produits_image">
                                    <div class="produits_thumb_etiquette hide w600">{{$t('c300')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('c300')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        
                        <div class="col-1-3  ">
                            <router-link to="/C400L">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/c400L.png" alt="" class="produits_image_ma">
                                    <div class="produits_thumb_etiquette hide w600">{{$t('C400L')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('C400L')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                    </div>

                    <div class="col-1-1 center ">

                        <div class="col-1-3 produit_list">
                            <router-link to="/C500">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/c475.png" alt="" class="produits_image">
                                    <div class="produits_thumb_etiquette hide w600">{{$t('c500')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('c500')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-1-3 produit_list">
                            <router-link to="/S250">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/s250.png" alt="" class="produits_image_max">
                                    <div class="produits_thumb_etiquette hide w600"><span
                                            class="produits_thumb_title_up" v-html="$t('thumb_title')"></span>{{$t('s250')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('s250')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-1-3 produit_list">
                            <router-link to="/S400">
                                <div class="produits_thumb">
                                    <img src="/Resources/Images/produits/s400.png" alt="" class="produits_image_max">
                                    <div class="produits_thumb_etiquette hide w600"><span
                                            class="produits_thumb_title_up" v-html="$t('thumb_title')"></span>{{$t('s400')}}</div>
                                    <div class="overlay">
                                        <div class="produits_title w600">{{$t('s400')}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                    </div>

                </div>
            </div>



            <div class="grid grid-pad">
                <div class="col-1-1 center pushdown">
                    <p v-html="$t('note')"></p>
                </div>
                <div class="col-1-1 center pushdown">
                    <router-link to="/Contact">
                        <input type="button" class="button" v-bind:value="$t('btn_contact')">     
                    </router-link>
                    <br /><br /><br /><br />
                </div>

            </div>
        </div>




        <div class="grid grid-pad">
            <div class="col-1-1 push">
                <h2>{{$t('archives')}}</h2>
                <br />
                {{$t('archives_text')}}

            </div>
        </div>
        <div class="grid grid-archives">
            <div class="center pushdown w600">
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C175TwisterII')" target="_blank">C175 Twister II</a></div>
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C250')" target="_blank">C250</a></div>
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C400LTierIII')" target="_blank">C400L Tier III</a></div>
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C450')" target="_blank">C450</a></div>
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C550')" target="_blank">C550</a></div>
                <div class="col-1-6 mobile-col-1-3 archives"><a v-bind:href="$t('C600')" target="_blank">C600</a></div>
            </div>
            <br />
        </div>
    </div>
</template>

  <i18n src="../../public/Resources/I18N/Screen.Product.json"></i18n>
  <i18n>
    {
        "fr": {
          "C175TwisterII" :  "/Resources/Documents/Archives/1085CMI_Feuillet_C175_fr_final_web.pdf",
          "C250" :  "/Resources/Documents/Archives/1095CMI_Feuillet_C250_fr_final_web.pdf",
          "C400LTierIII" :  "/Resources/Documents/Archives/1087CMI_Feuillet_C400L fr_final_Web.pdf",
          "C450" :  "/Resources/Documents/Archives/1094CMI_Feuillet_C450_fr_final_web.pdf",
          "C550" :  "/Resources/Documents/Archives/1088CMI_Feuillet_C550_fr_final_Web.pdf",
          "C600" :  "/Resources/Documents/Archives/1026CMI_Feuillet_C600_fr_final_web.pdf"
        },
        "en": {
          "C175TwisterII" :  "/Resources/Documents/Archives/1085CMI_Feuillet_C175_final_web.pdf",
          "C250" :  "/Resources/Documents/Archives/1095CMI_Feuillet_C250_eng_final_web.pdf",
          "C400LTierIII" :  "/Resources/Documents/Archives/1087CMI_Feuillet_C400L_final_Web.pdf",
          "C450" :  "/Resources/Documents/Archives/1094CMI_Feuillet_C450_eng_final_web.pdf",
          "C550" :  "/Resources/Documents/Archives/1088CMI_Feuillet_C550_final_Web.pdf",
          "C600" :  "/Resources/Documents/Archives/1022CMI_Feuillet_C600_final_web.pdf"
        }
      }
  </i18n>
  
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'product',
      data: () => ({
          }),
    }
    </script>
  