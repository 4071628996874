<template>
    <div>
        <div class="col-1-1 header_compagnie ">
            <div class="grid rel">
               <div class="header_txt vw38">{{$t('policy.pageTitle')}}</div>
            </div>
        </div>
        <div id="contenu" class="grid grid-pad">
            <h1 style="visibility: hidden;" v-html="$t('policy.pageTitle')"></h1>
            <div class="margeSection">
                <p v-html="$t('policy.p1')"></p>
                <p v-html="$t('policy.p2')"></p>
                <p v-html="$t('policy.p3')"></p>
            </div>
          <div class="margeSection">
            <h2  v-html="$t('policy.s2Title')"></h2>
            <p  v-html="$t('policy.s2P1')"></p>
            <p  v-html="$t('policy.s2P2')"></p>
          </div>
          <div class="txt-c">
                <h2 class="txt-c" v-html="$t('policy.s3Title')"></h2>
                <p v-html="$t('policy.s3P1')"></p>
                <ul class="f-tierce" style="text-align: left; padding: 10px 20px;">
                    <li v-html="$t('policy.s3Li1')"></li>
                    <li v-html="$t('policy.s3Li2')"></li>
                    <li v-html="$t('policy.s3Li3')"></li>
                    <li v-html="$t('policy.s3Li4')"></li>
                    <li v-html="$t('policy.s3Li5')"></li>
                    <li v-html="$t('policy.s3Li6')"></li>
                </ul>
                <p v-html="$t('policy.s3P2')"></p>
          </div>
          <div class="col-1-1 margeSection">
            <div class="col-1-2">
              <h2 class="txt-c f-secondary chargeTitle" v-html="$t('policy.s4Title')"></h2>
            </div>
            <div class="col-1-2">
              <p class=" f-primary fw-700" style="text-align: center;" v-html="$t('policy.s4P1')"></p>
              <p class="mt-8" style="text-align: center;" v-html="$t('policy.s4P2')"></p>
              <p class="" style="text-align: center;" v-html="$t('policy.s4P3')"></p>
              <p class="f-nobold" style="text-align: center;" v-html="$t('policy.s4P4')"></p>
            </div>
          </div>
          <div class="margeSection">
            <p  v-html="$t('policy.s4P5')"></p>
          </div>
          <div class="margeSection">
            <h2 v-html="$t('policy.s5Title')"></h2>
            <p v-html="$t('policy.s5P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-r" v-html="$t('policy.s6Title')"></h2>
            <p v-html="$t('policy.s6P1')"></p>
          </div>
          <div class="margeSection">
            <h2 v-html="$t('policy.s7Title')"></h2>
            <p v-html="$t('policy.s7P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-c f-secondary" v-html="$t('policy.s8Title')"></h2>
            <p v-html="$t('policy.s8P1')"></p>
            <p v-html="$t('policy.s8P2')"></p>
              <ul>
                <li><p v-html="$t('policy.s8Li1')"></p></li>
                <li><p v-html="$t('policy.s8Li2')"></p></li>
                <li><p v-html="$t('policy.s8Li3')"></p></li>
              </ul>      
          </div>
          <div class="margeSection">
            <h2 class="txt-r"  v-html="$t('policy.s9Title')"></h2>
            <p v-html="$t('policy.s9P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-c" v-html="$t('policy.s10Title')"></h2>
            <p v-html="$t('policy.s10P1')"></p>
          </div>
        
        </div>
  
    </div>
  </template>

<i18n src="../../public/Resources/I18N/policy.json"></i18n>
<style scoped>
  .fw-700 {font-weight: 700;}
  .txt-r {text-align: right;}
  .txt-c {text-align: center;}
  /*policy*/
  .f-primary {color: rgb(var(--primary)) !important;}
  .f-secondary {color: rgb(var(--secondary)) !important;}
  .f-tierce {color: rgb(var(--tierce)) !important;}
  .chargeTitle {line-height: normal; text-align: center; transform: translate(0%,50%); }
  #contenu h1{padding-bottom: 0px;}
  #contenu .margeSection {padding: 15px 0px;}
  #contenu .grid-pad {padding: 0px 200px;}
  #contenu p {line-height: normal; text-align: justify; padding: 10px 0px;}
  #contenu h2 {padding: 10px 0px;}
  @media handheld, only screen and (max-width: 960px) {
    h1{font-size: 24px;}
    .grid-pad {padding: 0px 10px !important;}
    .chargeTitle { transform: none; }
    .header {height: auto;}
    .bgBannerTop{background-position-y : 0px !important;}
    .section2TextSpacing {padding-right:0px;}
    .section2textTitle-64 {font-size: 24px;}
    .section3ParStyle { margin: 10px 10px 0px 10px; }
    .section3items { margin: 10px 0px 0px 0px; padding: 0px 10px 0px 10px; }
    .section3Title { font-size: 24px; margin: 0px; font-weight: 600; line-height: 58.12px; text-align: center; }
    .imgSquare { height: 25px !important; }
    p { font-size: 18px; }
    .section4Text { font-size: 24px; padding: 0px; }
    .section5TextSlogan2 { font-size: 24px; }
    .section5TextSlogan3 { font-size: 24px; }
  }
</style>
<script>
// @ is an alias to /src
// msg="Welcome to Your Vue.js App"

export default {
  name: 'policy',
  data: () => ({
    
      }),
}
</script>