import { render, staticRenderFns } from "./Application.vue?vue&type=template&id=b996f3c0"
import script from "./Application.vue?vue&type=script&lang=js"
export * from "./Application.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../public/Resources/I18N/Shared.Application.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CCMI%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CApplication.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports