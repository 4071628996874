import { render, staticRenderFns } from "./C175.vue?vue&type=template&id=21573810"
import script from "./C175.vue?vue&type=script&lang=js"
export * from "./C175.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../public/Resources/I18N/Screen.c175.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CCMI%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CC175.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./C175.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports