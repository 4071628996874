<template>
    <div class="application">
        <div class="col-1-1 header_applications">
            <div class="grid rel">
                <div class="header_txt vw38">{{$t('shared.title')}}</div>
            </div>
        </div>

        <div class=" bg_a col-1-1">
            <div class="grid grid-app ">
                <div class="col-1-1 app appm">

                    <div class="col-1-4 applications_item">
                        <router-link to="/Dfci">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/ligne_coupe-feux.jpg" alt=""
                                    class="application_image">
                                <div class="application_thumb_etiquette2 hide w100"><p v-html="$t('shared.dfci')"></p></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600"><p v-html="$t('shared.dfci')"></p></div>
                                </div>
                            </div>
                       </router-link>
                    </div>

                    <div class="col-1-4 applications_item">
                        <router-link to="/Oil_industry">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/industrie_petroliere.jpg" alt=""
                                    class="application_image">
                                <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.oil_industry')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.oil_industry')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>

                    <div class="col-1-4 applications_item">
                        <router-link to="/Forestry">
                            <div class="application_thumb ">
                                <img src="/Resources/Images/applications/sylviculture.jpg" alt=""
                                    class="application_image">
                                <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.forestry')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.forestry')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>

                    <div class="col-1-4 applications_item">
                        <router-link to="/Agricultural">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/agricole.jpg" alt="" class="application_image">
                                <div class="application_thumb_etiquette1 hide w100" v-html="$t('shared.agricultural')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.agricultural')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>



                </div>

                <div class="col-1-1 app">

                    <div class="col-1-4 applications_item">
                        <router-link to="/Energy">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/energie_services_publics.jpg" alt=""
                                    class="application_image">
                                <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.energy')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.energy')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>


                    <div class="col-1-4 applications_item">
                        <router-link to="/Road_network">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/reseau_routier.jpg" alt="" class="application_image">
                                <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.road_network')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.road_network')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>

                    <div class="col-1-4 applications_item">

                        <router-link to="/Grinding_stumps">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/broyage.jpg" alt="" class="application_image">
                                <div class="application_thumb_etiquette1 hide w100"  v-html="$t('shared.grinding_stumps')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.grinding_stumps')"></div>
                                </div>
                            </div>
                       </router-link>

                    </div>

                    <div class="col-1-4 applications_item">
                        <router-link to="/Gestion">
                            <div class="application_thumb">
                                <img src="/Resources/Images/applications/gestion.jpg" alt="" class="application_image">
                                <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.management')"></div>
                                <div class="application_overlay">
                                    <div class="application_titles w600" v-html="$t('shared.management')"></div>
                                </div>
                            </div>
                       </router-link>
                    </div>


                    <div class="col-1-1 app">


                        <div class="col-1-4 applications_item">
                            <router-link to="/Residential_commercial">
                                <div class="application_thumb">
                                    <img src="/Resources/Images/applications/commerciale_residentielle.jpg" alt=""
                                        class="application_image">
                                    <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.residential_commercial')"></div>
                                    <div class="application_overlay">
                                        <div class="application_titles w600" v-html="$t('shared.residential_commercial')"></div>
                                    </div>
                                </div>
                           </router-link>
                        </div>
                        <!--
                        <div class="col-1-4 applications_item">
                            <router-link to="/Contact">
                                <div class="application_thumb">
                                    <img src="/Resources/Images/applications/deminage.jpg" alt=""
                                        class="application_image">
                                    <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.deminage')"></div>
                                    <div class="application_overlay">
                                        <div class="application_titles w600" v-html="$t('shared.deminage')"></div>
                                    </div>
                                </div>
                           </router-link>
                        </div>-->

                        <div class="col-1-4 applications_item">
                            <router-link to="/Recreative">
                                <div class="application_thumb">
                                    <img src="/Resources/Images/applications/recreatif.jpg" alt=""
                                        class="application_image">
                                    <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.recreative')"></div>
                                    <div class="application_overlay">
                                        <div class="application_titles w600" v-html="$t('shared.recreative')"></div>
                                    </div>
                                </div>
                           </router-link>
                        </div>

                        <div class="col-1-4 applications_item">
                            <router-link to="/Rehab_nat">
                                <div class="application_thumb">
                                    <img src="/Resources/Images/applications/reabilitation_naturelles.jpg" alt="" class="application_image">
                                    <div class="application_thumb_etiquette2 hide w100" v-html="$t('shared.natural_rehabilitation')"></div>
                                    <div class="application_overlay">
                                        <div class="application_titles w600" v-html="$t('shared.natural_rehabilitation')"></div>
                                    </div>
                                </div>
                           </router-link>
                        </div>
                    </div>

                </div>

                <div class="col-1-1 app"> </div>

                <div class="center content vw27">
                    <router-link to="/Contact">
                        <input type="button" class="button " v-bind:value="$t('shared.btn_contact')">
                   </router-link>
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</template>

  <i18n src="../../public/Resources/I18N/Shared.Application.json"></i18n>
  
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'application',
      data: () => ({
          }),
    }
    </script>
  