<template>
    <div class="contact">

        <div class="col-1-1 header_contactez">
            <div class="grid rel">
                <div class="header_txt_blk vw38">{{$t('title')}}</div>
            </div>
        </div>

        <div class="grey">
            <div class="grid grid-pad ">
                <div class="col-1-1 content">
                    <div class="col-1-2">
                        <h2>{{$t('sub_title')}}</h2>
                        <br />
                        <div class="contact_pad">
                            <p v-html="$t('contact_text')"></p>
                        </div>
                    </div>
                    <div class="col-1-2">
                        <br />
                        <div class="contact-form">
                            <form id="frmRegister" method="Post" action="">
                                <div class="input-fields">
                                    <input id="txtFirstName" name="txtFirstName" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_first_name')">
                                    <input id="txtLastName" name="txtLastName" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_name')">
                                    <input id="txtBusiness" name="txtBusiness" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_business')">
                                    <input id="txtPhone" name="txtPhone" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_phone')">
                                    <input id="txtFax" name="txtFax" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_fax')">
                                    <input id="txtStreet" name="txtStreet" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_street')">
                                    <input id="txtCity" name="txtCity" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_city')">
                                    <input id="txtZipcode" name="txtZipcode" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_zipcode')">
                                    <input id="txtState" name="txtState" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_state')">
                                    <input id="txtCountry" name="txtCountry" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_country')">
                                    <input id="txtEmail" name="txtEmail" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_email')">
                                    <input id="txtWebSite" name="txtWebSite" type="text" class="input"
                                        v-bind:placeholder="$t('form_placeholder_website')">
                                    <input id="txtPassword1" name="txtPassword1" type="password" class="input"
                                        v-bind:placeholder="$t('form_placeholder_password1')">
                                    <input id="txtPassword2" name="txtPassword2" type="password" class="input"
                                        v-bind:placeholder="$t('form_placeholder_password2')">
                                </div>

                                <div class="input-fields-radio">

                                    <label class="radiolabel" for="equipments">{{$t('equipments')}}
                                        <input type="radio" id="equipments" name="rbWork" value="1_Equipments"
                                            checked="checked">
                                        <span class="checkmark"></span>
                                    </label><br />

                                    <label class="radiolabel" for="parts">{{$t('parts')}}
                                        <input type="radio" id="parts" name="rbWork" value="2_Parts">
                                        <span class="checkmark"></span>
                                    </label><br />

                                    <label class="radiolabel" for="services">{{$t('services')}}
                                        <input type="radio" id="services" name="rbWork" value="3_Services">
                                        <span class="checkmark"></span>
                                    </label><br />

                                    <label class="radiolabel" for="warranty">{{$t('warranty')}}
                                        <input type="radio" id="warranty" name="rbWork" value="4_Warranty">
                                        <span class="checkmark"></span>
                                    </label><br />

                                </div>

                                <div class="msg">
                                    <textarea id="txtMessage" name="txtMessage"
                                        v-bind:placeholder="$t('form_placeholder_textarea')"></textarea>
                                    <div v-if="msgId == 1" class="languageSucceed vw14">{{$t('mail_sent')}}</div>
                                    <div v-if="msgId == 2" class="languageError vw14">{{$t('mail_error')}}</div>
                                    <input v-if="msgId != 1" type="buton" @click="send" class="button"
                                        v-bind:value="$t('btn_submit')">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped> 
    .languageSucceed {color:#43aa51;font-weight:700;padding:0 0 15px 0;font-style:italic;}
    .languageError {color:#ed1844;font-weight:700;padding:0 0 15px 0;font-style:italic;}
</style>
  
<i18n src="../../public/Resources/I18N/Screen.Register.json"></i18n>
  
    
<script>

    export default {
    name: 'register',
    data: () => ({
        msgId: 0

        }),
    methods: {
    send: function() {

        try{

        $.ajax({
            url:  'http://member.cmimulching.com/mailing/directMailSend.ashx',
            //url:  'http://localhost:1573/mailing/directMailSend.ashx',
            type: 'post',
            beforeSend: function( xhr ) { xhr.overrideMimeType( "application/x-www-form-urlencoded" ) },
            vm: this,
            data:$("#frmRegister").serialize(),
            success: function(){ this.vm.setMsg(1); },
            error: function(){ this.vm.setMsg(2); }
        })

        }
        catch (error) { console.error(error); }

    },
    setMsg: function(msgId){
        //console.log("msgId..." + msgId);
        this.msgId = msgId;
    }
    },
        components: {
        }
    }
</script>