<template>
    <div class="contact">
        
			<div class="col-1-1 header_contactez">
          <div class="grid rel">
            <div class="header_txt_blk vw38">{{$t('title')}}</div>
          </div>
      </div>
              
            
             
         <div class="grey">
            <div class="grid grid-pad ">
              <div class="col-1-1 content">
                <div class="col-1-2">
                  <h2>{{$t('sub_title')}}</h2>
                  <br />
                  <div class="contact_pad">
                    <p v-html="$t('contact_text')"></p>
                  </div>
                  <br /><br />
                  <!-- <h2>{{$t('officerTitle')}}</h2>
                  <br />
                  <div class="contact_pad">
                    <p v-html="$t('officerName')"></p>
                    <a href="mailto:private@cmimulching.com" v-html="$t('officerEmail')"></a>
                    <p><router-link class="policyLink" to="policy" v-html="$t('policy')"></router-link></p>
                  </div> -->
                </div>
                <div class="col-1-2">
                  <br /> 
                  <div class="contact-form">
                    <form id="frmContactUs" method="Post"  action="">
                      <div class="input-fields">
                        <input id="txtFirstName" name="txtFirstName" type="text" class="input" v-bind:placeholder="$t('form_placeholder_first_name')">
                        <input id="txtLastName" name="txtLastName" type="text" class="input" v-bind:placeholder="$t('form_placeholder_name')">
                        <input id="txtPhone" name="txtPhone" type="text" class="input" v-bind:placeholder="$t('form_placeholder_phone')">
                        <input id="txtEmail" name="txtEmail" type="text" class="input" v-bind:placeholder="$t('form_placeholder_Email')">
                        <input id="txtBusiness" name="txtBusiness" type="text" class="input" v-bind:placeholder="$t('form_placeholder_business')">
                        <input id="txtState" name="txtState"  type="text" class="input" v-bind:placeholder="$t('form_placeholder_state')">
                      </div>
                     
                      <div class="input-fields-radio">
                     
                        <label class="radiolabel" for="sales">{{$t('sales')}}
                           <input  type="radio" id="sales" name="rbDepartment" value="1_Sales" checked="checked">
                           <span class="checkmark"></span>
                         </label>

                        <label class="radiolabel" for="parts">{{$t('parts')}} 
                          <input type="radio" id="parts" name="rbDepartment" value="2_Parts">
                          <span class="checkmark"></span>
                        </label>

                        <label class="radiolabel" for="services">{{$t('services')}}
                            <input type="radio" id="services" name="rbDepartment"  value="3_Services">
                            <span class="checkmark"></span>
                        </label>

                      </div>
                      
                      <div class="msg">
                        <textarea id="txtMessage" name="txtMessage" v-bind:placeholder="$t('form_placeholder_textarea')"></textarea>
                        <div v-if="msgId == 1" class="languageSucceed vw14" >{{$t('mail_sent')}}</div>
                        <div v-if="msgId == 2" class="languageError vw14">{{$t('mail_error')}}</div>
                        <input v-if="msgId != 1" type="buton" @click="send" class="button" v-bind:value="$t('btn_submit')">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
              
        <div class="col-1-1">
          <div class="grid grid-pad rel">
            <br /><br />
          <h2>{{$t('sub_title_distribution')}}</h2>
          <br /><br />
          <div class="world_index">
      
          <a class="amerique current" @click="setMap(0)">{{$t('switch_map_an')}}</a><span v-html="$t('&nbsp;&nbsp;|&nbsp;&nbsp;')"></span><a class="world" @click="setMap(1)">{{$t('switch_map_wo')}}</a>
          </div>
    
        <br /><br /><br />
        <mapAmerica v-if="map == 0" />
        <mapWorld v-if="map == 1" />
      </div>
    </div>
  </div>
</template>
<style scoped> 
  .languageSucceed {color:#43aa51;font-weight:700;padding:0 0 15px 0;font-style:italic;}
  .languageError {color:#ed1844;font-weight:700;padding:0 0 15px 0;font-style:italic;}
  .policyLink {font-family: 'Montserrat', sans-serif; color: black;}
  .policyLink:hover {text-decoration: underline;}
</style>

  <i18n src="../../public/Resources/I18N/Screen.Contact.json"></i18n>

  
  <script>
    import MapWorld from "@/components/MapWorld.vue";
    import MapAmerica from "@/components/MapAmerica.vue";
    
    export default {
      name: 'contact',
      data: () => ({
        map: 0,
        msgId: 0
    
          }),
    methods: {
      send: function() {

        try{

          $.ajax({
            url:  'http://member.cmimulching.com/mailing/directMailSend.ashx',
            //url:  'http://localhost:1573/mailing/directMailSend.ashx',
            type: 'post',
              beforeSend: function( xhr ) { xhr.overrideMimeType( "application/x-www-form-urlencoded" ) },
            vm: this,
            data:$("#frmContactUs").serialize(),
            success: function(){ this.vm.setMsg(1); },
            error: function(){ this.vm.setMsg(2); }
          })

        }
        catch (error) { console.error(error); }

      },
      setMsg: function(msgId){
        //console.log("msgId..." + msgId);
        this.msgId = msgId;
      },
      setMap: function (map) {
        if(map == 1) {
          $(".all_dealers").hide();
          $(".wo").show(); 
          $(".world").addClass("current");
          $(".an").hide();       
          $(".amerique").removeClass("current");   
        } else {
          $(".all_dealers").hide();
          $(".an").show(); 
          $(".amerique").addClass("current");
          $(".wo").hide();
          $(".world").removeClass("current");
        }
        this.map = map;
        }
    },
        components: {
            "mapWorld": MapWorld,
            "mapAmerica": MapAmerica
        }
    }
    </script>